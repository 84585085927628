import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { ClientTypeWithEmployeeName } from 'sharedV2/types/client';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  client: ClientTypeWithEmployeeName;
};

export const ClientSearchCard: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const handleClickOnCard = () => {
    redirect(`/v2/client/${client.btgCode}`);
  };

  return (
    <Box.Opaque
      w="40vw"
      p={4}
      gap={2}
      cursor="pointer"
      _hover={useCommonHover()}
      onClick={handleClickOnCard}
    >
      <Flex>
        <Text fontSize="lg" color="rgba(70, 70, 70, 1)" fontWeight="bold">
          {TextUtils.toCapitalize(client.name)}
        </Text>
      </Flex>

      <Flex flexDirection="column">
        <Text color="rgba(70, 70, 70, 1)">Email: {client.email}</Text>
        <Text color="rgba(70, 70, 70, 1)">
          Telefone: {client.phone ? TextUtils.toPhone(client.phone) : '-'}
        </Text>
        <Text color="rgba(70, 70, 70, 1)">
          Responsável: {TextUtils.toCapitalize(client.employeeName)}
        </Text>
      </Flex>
    </Box.Opaque>
  );
};
