import { Dropdown } from 'components/elements/others';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IReduxState } from 'shared/interfaces';
import { IReduxProfile } from 'shared/interfaces/ReduxState';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface IAAISFromTable {
  email: string;
  id: string;
  name: string;
}

interface IProps {
  setCustomEmployeeId: (employeeId: string) => void;
  customTableId: string;
  isGeralManagement: boolean;
  includeAllOption?: boolean;
}

export const DropDownAAI: React.FC<IProps> = ({
  setCustomEmployeeId,
  customTableId,
  isGeralManagement,
  includeAllOption,
}) => {
  const user: IReduxProfile = useSelector(
    (state: IReduxState) => state.user.profile
  );
  const [aaisFromTable, setAAISFromTable] = useState<IAAISFromTable[]>([]);

  const getAAIFromTable = async () => {
    const response: IAAISFromTable[] = await hubApiCaptation.getAAISFromTable(
      isGeralManagement ? customTableId : user.tableId
    );
    setAAISFromTable(response.filter(aai => aai.id !== user.id));
  };

  const handleChange = (value: string) => {
    setCustomEmployeeId(value);
  };

  useEffect(() => {
    getAAIFromTable();
  }, [customTableId]);

  return (
    <Dropdown
      borderRadius="sm"
      w={['130px', '180px']}
      h="7"
      fontSize="xs"
      onChange={e => handleChange(e.target.value)}
    >
      {includeAllOption ? (
        <option value="all">Todos</option>
      ) : (
        <option value="">Assessores</option>
      )}
      {aaisFromTable.map(aai => (
        <option value={aai.id} key={aai.id}>
          {formatNameToCaptalize(aai.name)}
        </option>
      ))}
    </Dropdown>
  );
};
