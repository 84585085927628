import { SIGN_IN_SUCCESS, SIGN_OUT } from '../auth/actionTypes';

const INITIAL_STATE = {
  profile: {},
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        profile: action.payload.employee,
      };

    case SIGN_OUT:
      return {
        ...state,
        profile: {},
      };

    default:
      return state;
  }
}

export default userReducer;
