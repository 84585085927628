import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { useCaptationMeetingsContext } from 'hooks';
import { LoadingSpinner } from 'components/elements/others';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { MosaicCardsWrapper } from 'pages/Captation/components/elements/wrappers';
import { useFilterLeadsByRange } from 'pages/Captation/hooks/useFilterLeadsByRange';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import {
  LeadAAIMosaicCard,
  SelectPoolButton,
} from 'pages/Captation/components/modules/AAI';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Warning } from 'phosphor-react';
import { useQuery, UseQueryResult } from 'react-query';
import { useEmployeeMibs } from 'pages/MyMIBs/hooks/useEmployeeMibs';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import { useMibsOperations } from 'pages/MyMIBs/hooks/useMibsOperations';
import { EmployeeMibsBox } from 'pages/Captation/components/elements/others/EmployeeMibsBox';
import { queryClient } from 'App';
import { CAPTATION_QUERIES_KEYS } from 'pages/Captation/CAPTATION_QUERIES_KEYS';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { leadRange } from './data';
import { IBaseLead, IGroup, IGroupMember } from '../../interfaces';

export const LeadsMosaicAAI: React.FC = () => {
  const user = useSelector(
    (state: RootStateOrAny) => state.user.profile
  ) as IUserSelector;

  const [isLoading, setIsLoading] = useState(false);
  const [updateLeadsList, setUpdateLeadsList] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(0);
  const [selectedRange, setSelectedRange] = useState(1);
  const [activeRange, setActiveRange] = useState('0 - 50 k');
  const [currentMeetingPriceInMibs, setCurrentMeetingPriceInMibs] =
    useState<number>(0);
  const [totalNumOfLeads, setTotalNumOfLeads] = useState(0);
  const [
    purchasedMeetingWithPersonalMibsModal,
    setPurchasedMeetingWithPersonalMibsModal,
  ] = useState(false);
  const [isLoadingSelectLeadConfirmation, setIsLoadingSelectLeadConfirmation] =
    useState(false);

  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const { data: employeeMibs } = useEmployeeMibs(user.id);

  const handleMibsOperation = useMibsOperations();

  const { data: verifyIfUserIsACloser } = useQuery<boolean>(
    'groupsAndMembers',
    async () => {
      const response: IGroup[] = await hubApiCaptation.getGroups();

      const findUserInClosersGroup = response
        .filter(item => item.name === 'Closers')[0]
        .members.find((item: IGroupMember) => item.employeeId === user.id);

      return !!findUserInClosersGroup;
    },
    {
      onError: () =>
        toast.dark(
          'Não foi possível verificar seu grupo, recarregue a página e contate o suporte caso o problema persista'
        ),
      refetchOnWindowFocus: true,
    }
  );

  const { data: leads }: UseQueryResult<LeadDetailsProps[]> = useQuery<
    LeadDetailsProps[]
  >({
    queryKey: [CAPTATION_QUERIES_KEYS.leadsToBeShown],
    queryFn: async () => {
      const response = await hubApiCaptation.getPoolPublicLeads();

      if (verifyIfUserIsACloser || user.position === 'MASTER') {
        return response.data.leads;
      }

      if (verifyIfUserIsACloser !== undefined) {
        return response.data.leads.filter(
          (lead: IBaseLead) =>
            lead.meetings[0].filialName !== 'SHs' &&
            lead.meetings[0].filialName !== 'Mundo Invest'
        );
      }
    },
    onError: () => {
      toast.dark(
        'Não foi possível buscar os leads, recarregue a página e contate o suporte caso o problema persista'
      );
    },
    enabled: verifyIfUserIsACloser !== undefined,
  });

  const { leadsToBeShown } = useFilterLeadsByRange({
    leads: leads as LeadDetailsProps[],
    selectedRange,
  });

  const handleLeadRange = (range: string, allowedPool: boolean) => {
    if (!allowedPool) return;

    if (range === '5 - 50 k') {
      setSelectedRange(1);
      setActiveRange('5 - 50 k');
    }

    if (range === '50 - 100 k') {
      setSelectedRange(2);
      setActiveRange('50 - 100 k');
    }

    if (range === '100 - 300 k') {
      setSelectedRange(3);
      setActiveRange('100 - 300 k');
    }

    if (range === '300 - 500 k') {
      setSelectedRange(4);
      setActiveRange('300 - 500 k');
    }

    if (range === '500 - 1MM') {
      setSelectedRange(5);
      setActiveRange('500 - 1MM');
    }

    if (range === '1MM +') {
      setSelectedRange(6);
      setActiveRange('1MM +');
    }
  };

  const handleCloseModal = () => {
    setDisplayModal(false);
  };

  const handleSelectedLead = (index: number, meetingPriceInMibs: number) => {
    setDisplayModal(true);
    setSelectedLeadIndex(index);
    setCurrentMeetingPriceInMibs(Math.floor(meetingPriceInMibs));
  };

  const getTotalNumOfLeadsInPipe = async () => {
    try {
      const { totalMeetingsInPipe } =
        await hubApiCaptation.getTotalNumOfLeads();

      setTotalNumOfLeads(Number(totalMeetingsInPipe));
    } catch (err) {
      toast.dark(
        'Não foi possível buscar o total de leads, tente novamente em alguns minutos ou comunique o suporte'
      );
    }
  };

  const handleSelectLeadConfirmationWithoutPersonalMibs = async (
    e: BaseSyntheticEvent
  ) => {
    e.preventDefault();
    setIsLoadingSelectLeadConfirmation(true);
    const selectedLead = leadsToBeShown[selectedLeadIndex];

    if (!selectedLead) {
      return toast.dark(
        'Nao foi possivel achar o lead selecionado. Tente novamente mais tarde ou entre em contato com o suporte.'
      );
    }

    const freshMeetingStatus = await hubApiCaptation.getFreshMeetingStatus(
      selectedLead.meetings[0].id
    );

    if (freshMeetingStatus.data.status !== 'confirmed') {
      toast.dark(
        'Esta reunião já foi selecionada, escolha outra reunião ou volte mais tarde'
      );
      return;
    }

    try {
      const meetingData = {
        id: selectedLead.meetings[0].id,
        leadId: selectedLead.id,
        employeeId: selectedLead.meetings[0].employeeId,
        employeeIdParticipant: user.id,
        type: 'meeting',
        local: selectedLead.meetings[0].local,
        leadType: selectedLead.meetings[0].leadType,
        complement: selectedLead.meetings[0].complement,
        date: selectedLead.meetings[0].date,
        status: 'waiting_feedback',
        feedback: selectedLead.meetings[0].feedback,
      };

      await Promise.all([
        handleMibsOperation.mutateAsync({
          employeeId: user.id,
          mibsQuantity: String(employeeMibs && employeeMibs[0].mibs),
          operation: 'WITHDRAW',
          sourceAction: 'MEETING_PURCHASED',
          sourceWallet: 'MI',
          destinationWallet: 'EWZWallet',
        }),
        handleMibsOperation.mutateAsync({
          employeeId: user.id,
          mibsQuantity: String(
            employeeMibs && currentMeetingPriceInMibs - employeeMibs[0].mibs
          ),
          operation: 'WITHDRAW',
          sourceAction: 'MEETING_PURCHASED',
          sourceWallet: 'MI',
          destinationWallet: 'personalWallet',
        }),
      ]);

      await hubApiCaptation.pathMetting(meetingData);
      await handleGetSpecificLeadTypeArr('suitability', true);

      queryClient.invalidateQueries([CAPTATION_QUERIES_KEYS.leadsToBeShown]);

      setUpdateLeadsList(true);
      toast.dark('Lead adicionado ao seu painel de reuniões Suitability');
    } catch (error) {
      toast.dark('Falha ao adicionar, tente novamente em alguns minutos');
    }
    setIsLoadingSelectLeadConfirmation(false);
    setPurchasedMeetingWithPersonalMibsModal(false);
    setDisplayModal(false);
  };

  const handleSelectedLeadConfirmation = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);

    const selectedLead = leadsToBeShown[selectedLeadIndex];

    if (!selectedLead) {
      return toast.dark(
        'Nao foi possivel achar o lead selecionado. Tente novamente mais tarde ou entre em contato com o suporte.'
      );
    }

    if (employeeMibs && employeeMibs[0].mibs < currentMeetingPriceInMibs) {
      if (
        employeeMibs[1].mibs > 0 &&
        employeeMibs[1].mibs >= currentMeetingPriceInMibs - employeeMibs[0].mibs
      ) {
        setIsLoading(false);
        return setPurchasedMeetingWithPersonalMibsModal(true);
      }

      setIsLoading(false);
      return toast.dark(
        'Você não possui MIBs o suficiente para comprar a reuniao.'
      );
    }

    const freshMeetingStatus = await hubApiCaptation.getFreshMeetingStatus(
      selectedLead.meetings[0].id
    );

    if (freshMeetingStatus.data.status !== 'confirmed') {
      toast.dark(
        'Esta reunião já foi selecionada, escolha outra reunião ou volte mais tarde'
      );
      return;
    }

    try {
      const meetingData = {
        id: selectedLead.meetings[0].id,
        leadId: selectedLead.id,
        employeeId: selectedLead.meetings[0].employeeId,
        employeeIdParticipant: user.id,
        type: 'meeting',
        local: selectedLead.meetings[0].local,
        leadType: selectedLead.meetings[0].leadType,
        complement: selectedLead.meetings[0].complement,
        date: selectedLead.meetings[0].date,
        status: 'waiting_feedback',
        feedback: selectedLead.meetings[0].feedback,
      };

      await handleMibsOperation.mutateAsync({
        employeeId: user.id,
        mibsQuantity: String(currentMeetingPriceInMibs),
        operation: 'WITHDRAW',
        sourceAction: 'MEETING_PURCHASED',
        sourceWallet: 'MI',
        destinationWallet: 'EWZWallet',
        additionalInformations: JSON.stringify({
          lead: selectedLead,
          wallet: {
            source: 'MI',
            destination: 'EWZWallet',
          },
        }),
      });

      await hubApiCaptation.pathMetting(meetingData);
      // await handleGetSpecificLeadTypeArr('suitability', true);

      queryClient.invalidateQueries([CAPTATION_QUERIES_KEYS.leadsToBeShown]);

      setUpdateLeadsList(true);
      toast.dark('Lead adicionado ao seu painel de reuniões Suitability');
      handleCloseModal();
    } catch (error) {
      toast.dark('Falha ao adicionar, tente novamente em alguns minutos');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTotalNumOfLeadsInPipe();
  }, [updateLeadsList]);

  return (
    <PageWrapper paddingTop="2.5rem" alignItems="center">
      <Flex w="100%" maxW="1180px" flexDir="column" mt={10}>
        <Flex
          width="100%"
          flexDir={['column', 'row']}
          justify="space-between"
          gridGap="2"
          alignItems="center"
        >
          <Flex
            width="100%"
            gridGap="1.5"
            justifyContent={['center', 'flex-start']}
            flexDir="row"
            flexWrap="wrap"
          >
            {leadRange.map((range, index) => {
              const isAllowed = true;

              return (
                <SelectPoolButton
                  key={index}
                  isAllowed={isAllowed}
                  handleLeadRange={handleLeadRange}
                  range={range.text}
                  currentRange={activeRange}
                />
              );
            })}
          </Flex>
        </Flex>

        {isLoading && <LoadingSpinner />}

        <MosaicCardsWrapper
          mt={4}
          overflowY="auto"
          maxH={['calc(100vh - 300px)', 'calc(100vh - 240px)']}
          gridGap="2"
        >
          {leadsToBeShown?.length === 0 && (
            <Flex mt={10} w="100%" justifyContent="center">
              <Text fontSize="lg" fontStyle="italic" fontWeight="light">
                Sem leads nesta faixa de valor
              </Text>
            </Flex>
          )}

          {leadsToBeShown?.map((lead: LeadDetailsProps, index: number) => (
            <LeadAAIMosaicCard
              key={index}
              index={index}
              name={lead.name}
              content={{
                employeeName: lead.employeeName as string,
                details: lead.meetings,
                pl: lead.customFields,
                plBySH: lead.questions[0]?.pl || 0,
                meetingPrice: lead.meetingPrice,
              }}
              handleSelectedLead={handleSelectedLead}
            />
          ))}
        </MosaicCardsWrapper>
      </Flex>

      <ModalWrapper isOpen={displayModal} onClose={handleCloseModal}>
        <DefaultModalContentWrapper maxW={['90vw', '460px']}>
          <ModalTitle>
            Deseja adicionar o lead às suas reuniões Suitability?
          </ModalTitle>
          <Flex>
            <Text>Preço da reunião: {currentMeetingPriceInMibs} MIBs</Text>
          </Flex>
          <BaseButtonGroup>
            {totalNumOfLeads >= 130 && (
              <Tooltip
                hasArrow
                label='Você atingiu o limite de leads no funil de captação (130 leads). Com isto você não receberá novos leads via automação de distribuição e não consigirá avançar leads das abas leads lista, leads minerados, leads manuais e leads declinados. Para voltar a operar sem restrições finalize alguns leads do funil, tentando sempre manter o número de leads abaixo de 100`
                placement="top'
                color="white"
                bg="background.200"
              >
                <Flex alignSelf="center">
                  <Warning size={26} color="#e94040" weight="fill" />
                </Flex>
              </Tooltip>
            )}
            <SecondaryButton size="sm" type="button" onClick={handleCloseModal}>
              Fechar
            </SecondaryButton>
            <PrimaryButton
              size="sm"
              isLoading={isLoading}
              style={{ margin: 0 }}
              onClick={handleSelectedLeadConfirmation}
              disabled={totalNumOfLeads >= 130}
            >
              Adicionar
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={purchasedMeetingWithPersonalMibsModal}
        onClose={() =>
          setPurchasedMeetingWithPersonalMibsModal(
            !purchasedMeetingWithPersonalMibsModal
          )
        }
      >
        <DefaultModalContentWrapper maxW={['90vw', '460px']}>
          <ModalTitle>
            Essa operação irá descontar{' '}
            {employeeMibs && currentMeetingPriceInMibs - employeeMibs[0].mibs}{' '}
            MIBs da sua carteira pessoal. Deseja continuar?
          </ModalTitle>
          <BaseButtonGroup>
            {totalNumOfLeads >= 130 && (
              <Tooltip
                hasArrow
                label='Você atingiu o limite de leads no funil de captação (130 leads). Com isto você não receberá novos leads via automação de distribuição e não consigirá avançar leads das abas leads lista, leads minerados, leads manuais e leads declinados. Para voltar a operar sem restrições finalize alguns leads do funil, tentando sempre manter o número de leads abaixo de 100`
                placement="top'
                color="white"
                bg="background.200"
              >
                <Flex alignSelf="center">
                  <Warning size={26} color="#e94040" weight="fill" />
                </Flex>
              </Tooltip>
            )}
            <SecondaryButton
              size="sm"
              type="button"
              onClick={() => setPurchasedMeetingWithPersonalMibsModal(false)}
            >
              Fechar
            </SecondaryButton>
            <PrimaryButton
              size="sm"
              isLoading={isLoadingSelectLeadConfirmation}
              style={{ margin: 0 }}
              disabled={totalNumOfLeads >= 130}
              onClick={e => {
                handleSelectLeadConfirmationWithoutPersonalMibs(e);
              }}
            >
              Confirmar
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </PageWrapper>
  );
};
