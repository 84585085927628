import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';
import { errorHandler } from 'utils/errorHandler';
import { IPOProps } from 'pages/products/interfaces';

interface IIPOPropsManagement extends IPOProps {
  id: string;
}

export const IPOManagement: React.FC = () => {
  const [ipos, setIpos] = useState<IIPOPropsManagement[]>([]);
  const history = useHistory();

  const handleEditIpo = useCallback(
    id => {
      history.push(`/products/ipo/update/${id}`);
    },
    [history]
  );

  const handleClickCreateIPOButton = useCallback(() => {
    history.push('/products/ipo/create');
  }, [history]);

  const handleRemoveFilial = useCallback(
    async id => {
      try {
        await hubApiProducts.deleteIpo(id);
        setIpos(ipos.filter(item => item.id !== id));

        toast.dark('IPO deletado com sucesso.');
      } catch (error) {
        toast.dark('Ocorru um erro ao deletar o IPO, tente novamente.');
      }
    },
    [ipos]
  );

  useEffect(() => {
    hubApiProducts
      .getAllIpos()
      .then(data => {
        setIpos(data);
      })
      .catch(error => {
        errorHandler(error);
      });
  }, []);

  if (ipos.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar IPO/Subscrição"
        buttonText="Criar novo IPO ou Subscrição"
        handleCreateProduct={handleClickCreateIPOButton}
      >
        <Text color="whiteAlpha.800">
          Não existem IPO/Subscrição cadastrados.{' '}
        </Text>
      </ProductManagementLayout>
    );
  }

  return (
    <ProductManagementLayout
      title="Gerenciar IPO/Subscrição"
      buttonText="Criar novo IPO ou Subscrição"
      handleCreateProduct={handleClickCreateIPOButton}
    >
      {ipos?.map((ipo, index) => (
        <ProductManagementCard
          key={index}
          productId={ipo.id}
          title={
            ipo.title !== 'undefined'
              ? `${ipo.title} (IPO)`
              : `${ipo.ticker} (Subscrição)`
          }
          handleEditProduct={handleEditIpo}
          handleRemoveProduct={handleRemoveFilial}
        />
      ))}
    </ProductManagementLayout>
  );
};
