import { createContext, FC, useState } from 'react';
import { KanbanBaseTaskProps, KanbanColumnProps } from 'shared/interfaces';

interface IIPContext {
  currentColumns: KanbanColumnProps[];
  setCurrentColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  updateCurrentColumns: (columnsToUpdate: KanbanColumnProps[]) => void;
  sortedOrderListByNextDate: (
    taskList: KanbanBaseTaskProps[]
  ) => KanbanBaseTaskProps[];
  updateValueAtOrder: (
    orderId: string,
    value: string,
    field: 'nextContact' | 'value',
    taskId: string
  ) => void;
}

interface IPContextProviderProps {
  columns: KanbanColumnProps[];
}

export const IPContext = createContext({} as IIPContext);

export const IPContextProvider: FC<IPContextProviderProps> = ({
  children,
  columns,
}) => {
  const objectToArray = Object.entries(columns).map(
    ([id, columnList]) => columnList
  );
  const columnsBase = (verifyColumn: KanbanColumnProps[]) =>
    typeof verifyColumn === 'object' ? objectToArray : verifyColumn;

  const [currentColumns, setCurrentColumns] = useState(columnsBase(columns));

  const updateCurrentColumns = (columnsToUpdate: KanbanColumnProps[]) => {
    setCurrentColumns(columnsBase(columnsToUpdate));
  };

  const sortedOrderListByNextDate = (taskList: KanbanBaseTaskProps[]) => {
    const withContactDate = taskList
      .filter(item => !!item.nextContact)
      .sort((cur, nex) => {
        if (!cur.nextContact || !nex.nextContact) {
          return 0;
        }
        if (cur.nextContact < nex.nextContact) {
          return -1;
        }
        if (cur.nextContact > nex.nextContact) {
          return 1;
        }
        return 0;
      });

    const withoutContactDate = taskList.filter(item => !item.nextContact);

    return [...withContactDate, ...withoutContactDate];
  };

  const updateValueAtOrder = (
    orderId: string,
    value: string,
    field: 'nextContact' | 'value',
    taskId: string
  ) => {
    const findTaskToChange = currentColumns.filter(column =>
      column.tasks.find(task => task.orderId === orderId)
    );

    if (findTaskToChange.length < 1) {
      return;
    }

    const { id: idColumn } = findTaskToChange[0];

    setCurrentColumns(prev =>
      prev.map(column => {
        if (column.id === idColumn) {
          column.tasks.map((task, index) => {
            if (task.id === taskId) {
              task[field] = value;
            }
            return task;
          });
        }

        return column;
      })
    );
  };

  return (
    <IPContext.Provider
      value={{
        currentColumns,
        setCurrentColumns,
        updateCurrentColumns,
        sortedOrderListByNextDate,
        updateValueAtOrder,
      }}
    >
      {children}
    </IPContext.Provider>
  );
};
