import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import hubApi from 'services/hubApi';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';

import { BaseModalFormCard } from 'components/elements/cards';
import ConfirmForms from '../../ConfirmForms';
import { handleOnBlurClient } from '../../../../utils';

interface ComponentProps {
  title: string;
  handleShowModal: () => void;
}

export const StrategyForm: React.FC<ComponentProps> = ({
  handleShowModal,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    name: '',
    email: '',
    btgAccount: '',
  });
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false);
  const [formInfos, setFormInfos] = useState({
    clientId: '',
    risk: '',
    value: '',
    segment: '',
  });

  const handleToggleConfirmModalClick = () => {
    setToggleConfirmModal(!toggleConfirmModal);
  };

  const handleConfirmInfos = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (formInfos.clientId === '') {
      setIsLoading(false);
      return toast.dark('Informe o código do cliente');
    }

    if (formInfos.value === '') {
      setIsLoading(false);
      return toast.dark('Digite o valor financeiro');
    }

    handleToggleConfirmModalClick();
  };

  const handleOnBlurCode = async () =>
    handleOnBlurClient(
      selectedClient.btgAccount,
      setSelectedClient,
      setFormInfos,
      formInfos
    );

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleChangeClientValues = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedClient({
      ...selectedClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (formInfos.clientId === '') {
      setIsLoading(false);
      return toast.dark('Informe o código do cliente');
    }

    if (formInfos.value === '') {
      setIsLoading(false);
      return toast.dark('Digite o valor financeiro');
    }

    const segment = title.includes('Fundos Imobiliários')
      ? title.replace('Fundos Imobiliários', 'FII')
      : title;

    const newArray = {
      ...formInfos,
      segment,
    };

    await hubApi
      .searchForClientCode(selectedClient.btgAccount)
      .then(async () => {
        await hubApiRv
          .postMonthlyStrategies(newArray)
          .then(() => {
            toast.dark('Ordem enviada com sucesso');
            handleShowModal();
          })
          .catch(() => {
            setIsLoading(false);
            toast.dark(
              'Ocorreu um erro ao tentar enviar ordem. Tente novamente'
            );
          });
      })
      .catch(() => {
        setIsLoading(false);
        toast.dark('Cliente não encontrado.Verifique o código informado.');
      });
  };

  return (
    <BaseModalFormCard
      isModal
      maxW="440px"
      title={title}
      handleFormSubmit={handleConfirmInfos}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
    >
      <Stack spacing="3">
        <InputWithLabel
          label="Financeiro (minímo 20K):"
          type="text"
          placeholder="Financeiro"
          name="value"
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Cód. Cliente:"
          type="text"
          placeholder="Codigo do cliente"
          name="btgAccount"
          onChange={handleChangeClientValues}
          onBlur={handleOnBlurCode}
        />
        <InputWithLabel
          label="Nome do Cliente:"
          type="text"
          placeholder="Nome do cliente"
          name="name"
          value={selectedClient.name}
          onChange={handleChangeClientValues}
          isDisabled
        />
      </Stack>

      <ModalWrapper
        isOpen={toggleConfirmModal}
        onClose={handleToggleConfirmModalClick}
      >
        <ConfirmForms
          segment={title}
          formInfos={formInfos}
          selectedClient={selectedClient}
          handleFormSubmit={handleFormSubmit}
          handleShowModal={handleToggleConfirmModalClick}
        />
      </ModalWrapper>
    </BaseModalFormCard>
  );
};
