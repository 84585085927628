import { IWalletNames } from '../hooks/useMibsOperations';

export const TranslateWalletName = (wallet: IWalletNames): string => {
  const helperObject: Partial<Record<IWalletNames, string>> = {
    personalWallet: 'Pessoal',
    EWZWallet: 'EWZ',
  };

  return helperObject[wallet] ?? 'Not possible to translate wallet name';
};
