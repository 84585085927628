import React, { FormEvent } from 'react';
import { ButtonGroup, Stack } from '@chakra-ui/react';

import { DisplayInformation } from 'components/elements/others';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { IinitialStructuredData } from '../data';

interface ICOnfirmrStructured {
  handleShowModal: () => void;
  handleSubmitForm: (e: FormEvent) => Promise<void>;
  structuredData: IinitialStructuredData;
}

const ConfirmStructured: React.FC<ICOnfirmrStructured> = ({
  handleShowModal,
  handleSubmitForm,
  structuredData,
}) => {
  return (
    <DefaultModalContentWrapper>
      <Stack spacing="3">
        <DisplayInformation label="Operação" info={structuredData.ticker} />

        <DisplayInformation label="Estratégia" info={structuredData.strategy} />

        <DisplayInformation
          label="WhatsApp"
          info={structuredData.whatsMessage}
        />

        <ButtonGroup width="100%" justifyContent="flex-end">
          <SecondaryButton onClick={handleShowModal}>Cancelar</SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm}>Confirmar</PrimaryButton>
        </ButtonGroup>
      </Stack>
    </DefaultModalContentWrapper>
  );
};

export default ConfirmStructured;
