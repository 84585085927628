import { Flex, Text } from '@chakra-ui/react';

import { useAgreementsContext } from 'hooks';
import { formateDateAndTime } from 'utils/formatDate';
import formatStringInTitle from 'utils/formatStringInTitle';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ListAllAgreementsProps {
  agreement: IAgreement;
  onClose: () => void;
}

export const ShowOneAgreement: React.FC<ListAllAgreementsProps> = ({
  agreement,
  onClose,
}) => {
  const handleGetSpecificLeadTypeArr = useAgreementsContext();

  return (
    <DefaultModalContentWrapper maxW="724px">
      <ModalTitle>Novo Acordo</ModalTitle>

      <Flex flexDir="column" borderRadius="3">
        <Flex align="center">
          <Text
            color="rgba(70, 70, 70, 1)"
            mb="3"
            fontSize="lg"
            w="100%"
            textAlign="justify"
            borderBottom="1px solid gray"
            justify="space-between"
            fontWeight="bold"
          >
            {formatStringInTitle(agreement.title)}
          </Text>
        </Flex>
        <Flex
          minH="64px"
          p="3.5"
          borderRadius="3"
          flexDir="column"
          bg="white"
          border="1px solid #1d63ddb2"
        >
          <Text
            color="rgba(70, 70, 70, 1)"
            fontSize="sm"
            lineHeight="150%"
            textAlign="justify"
          >
            {agreement.agreement}
          </Text>
        </Flex>
        <Flex
          justify="space-between"
          maxH="100px"
          overflow="auto"
          color="rgba(70, 70, 70, 1)"
          fontSize="sm"
          mt="2"
        >
          <Text as="span" fontSize="sm" color="rgba(70, 70, 70, 1)">
            Data:{' '}
            <Text as="span" fontWeight="bold">
              {formateDateAndTime(agreement.created_at)}
            </Text>
          </Text>

          <Text color="rgba(70, 70, 70, 1)">
            Redigido por{' '}
            <Text as="span" color="rgba(70, 70, 70, 1)" fontWeight="bold">
              {formatNameToCaptalize(agreement.managerName)}
            </Text>
          </Text>
        </Flex>

        <BaseButtonGroup mt="5">
          <SecondaryButton onClick={onClose}>Fechar</SecondaryButton>
          {!agreement.accept && (
            <PrimaryButton
              onClick={() => {
                handleGetSpecificLeadTypeArr.acceptContract(agreement.id);
                onClose();
              }}
            >
              Aceitar acordo
            </PrimaryButton>
          )}
        </BaseButtonGroup>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
