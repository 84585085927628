import React, { useState, ChangeEvent, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box } from '@chakra-ui/react';

import UploadButton from 'components/elements/forms/UploadButton';

import DatePicker from 'components/elements/others/DatePicker';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { File, RevenueProps } from 'pages/Financials/interfaces';
import formatValue from 'utils/formatValue';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

import { financialTableHead, today, initialFileValue } from './data';

export const FinancialRevenue: React.FC = () => {
  const [infosTable, setInfosTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewReady, setPreviewReady] = useState(false);
  const [file, setFile] = useState(initialFileValue);
  const [date, setDate] = useState(today);

  const createFormData = () => {
    const formData = new FormData();
    formData.append('file', file as File);
    formData.append('revenueDate', String(date));

    return formData;
  };

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  async function sendPreviewFile() {
    setIsLoading(true);

    const formData = createFormData();

    await hubApiFinancial
      .sendRevenueFilePreview(formData)
      .then(response => {
        setInfosTable(response);
        setPreviewReady(true);
        setIsLoading(false);
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao fazer o upload, tente novamente.');
        setIsLoading(false);
      });
  }

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  const handleAttSubmit = async () => {
    const alreadySendFile = await hubApiFinancial.checkIfAlreadySendFile(date);

    if (alreadySendFile)
      return toast.dark('Já existe um arquivo enviado nesta data.');

    setIsLoading(true);

    const formData = createFormData();

    await hubApiFinancial
      .sendRevenueFileConfirm(formData)
      .then(() => {
        setInfosTable([]);
        setFile(initialFileValue);
        setIsLoading(false);
        setPreviewReady(false);
        toast.dark('Arquivo enviado com sucesso.');
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao enviar arquivo, tente novamente.');
        setIsLoading(false);
      });
  };

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="white"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Financeiro - Remuneração
      </MainPageTitle>

      <Flex w="100%" maxWidth={['95vw', '1080px']} flexDir="column">
        <Flex
          mb="12px"
          flexDir={['column', 'row']}
          justifyContent="left"
          alignItems={['flex-start', 'flex-end']}
          gridGap={[2, 0]}
        >
          <Flex alignItems={'flex-end'} justifyContent={'center'}>
            <Box maxWidth="200px">
              <UploadButton
                label={file.name}
                accept=".csv"
                onChange={handlePickFile}
              />
            </Box>

            <Box ml={['0px', '4px']} maxWidth="150px">
              <DatePicker
                handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
                  setDate(e.target.value)
                }
                value={date}
                full
                fullColum
                style
                type="date"
                directionRow
              />
            </Box>
          </Flex>
          <Box maxWidth="150px">
            <SecondaryButton py="17px" onClick={sendPreviewFile}>
              Enviar Preview
            </SecondaryButton>
          </Box>
        </Flex>

        <TableBase
          headData={financialTableHead}
          width={['100%', 'fit-content']}
          maxW={['95vw', '1080px']}
          minHeight="200px"
          maxH="calc(100vh - 320px)"
          overflow="auto"
        >
          {infosTable.map((info: RevenueProps, index) => (
            <TableRow key={index}>
              {Object.keys(info).map((key, idx) =>
                key === 'havings' ||
                key === 'grossRevenue' ||
                key === 'netRevenue' ||
                key === 'kickback' ? (
                  <TableData key={idx}>
                    {formatValue(Number(info[key]))}
                  </TableData>
                ) : (
                  key !== 'created_at' &&
                  key !== 'date' &&
                  key !== 'id' &&
                  key !== 'status' &&
                  key !== 'updated_at' && (
                    <TableData key={idx}>{info[key]}</TableData>
                  )
                )
              )}
            </TableRow>
          ))}
        </TableBase>

        {isLoading && (
          <Flex
            w="100%"
            justifyContent="center"
            alignItems="center"
            height="50%"
          >
            <LoadingSpinner />
          </Flex>
        )}

        <Flex justifyContent="flex-end">
          {previewReady && (
            <PrimaryButton isLoading={isLoading} onClick={handleAttSubmit}>
              Confirmar e enviar arquivo
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
