export function formatValueForSI(num: number): string {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(2)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num >= 1000000 && num < 1000000000) {
    return `${(num / 1000000).toFixed(2)}M`; // convert to M for number from > 1 million
  }
  if (num >= 1000000000 && num < 1000000000000) {
    return `${(num / 1000000000).toFixed(2)}B`; // convert to M for number from > 1 million
  }
  if (num < 900) {
    return num.toString(); // if value < 1000, nothing to do
  }
  return num.toString();
}
