import { TableRow, TableData } from 'components/elements/table';
import React, { useState } from 'react';
import { formatDate } from 'utils/formatDate';
import { ModalWrapper } from 'components/elements/wrappers/';
import { Flex } from '@chakra-ui/react';
import { OrderProps } from '../../interfaces';
import Modal from '../Modal';

interface OrderTableRowProps {
  rowData: OrderProps;
}

export const OrderTableRow: React.FC<OrderTableRowProps> = rowData => {
  const [showModal, setModal] = useState(false);

  const handleToggleModal = () => {
    setModal(!showModal);
  };

  return (
    <>
      <TableRow onClick={handleToggleModal} cursor="pointer">
        <TableData>{rowData.rowData.name}</TableData>
        <TableData>{rowData.rowData.client}</TableData>
        <TableData>{rowData.rowData.area}</TableData>
        <TableData>{formatDate(rowData.rowData.date)}</TableData>
        <TableData>{rowData.rowData.status}</TableData>
      </TableRow>
      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        {showModal === true && (
          <Flex h="100vh" justifyContent="center" alignItems="center">
            <Modal
              selectedOrder={rowData.rowData}
              handleToggleModal={handleToggleModal}
            />
          </Flex>
        )}
      </ModalWrapper>
    </>
  );
};
