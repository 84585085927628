import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import EmployeeInfosEditable from '../../../components/employeeInfos/EmployeeInfosEditable';
import hubApi from '../../../services/hubApi';
import { hubApiNotifications } from '../../../services/hubApi/classes/HubApiNotifications';
import history from '../../../services/history';
import {
  managerConfirmEmployeeForm,
  managerDenieEmployeeForm,
} from '../../../store/modules/newEmployeeForm/actions';
import { Container } from './styles';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';

const EmployeeConfirmData = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const notificationId = useSelector(
    state => state.notifications.currentNotification
  );

  const [loading, setLoading] = useState(true);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [redirectLink, setRedirectLink] = useState('/captation');

  useEffect(() => {
    async function getEmployeeInfos(employeeId) {
      const response = await hubApi.getEmployeeInfos(employeeId);

      if (!response) {
        setLoading(false);
        return;
      }

      setEmployeeInfo(response);
      setLoading(false);
    }

    getEmployeeInfos(employeeId);
  }, [employeeId]);

  useEffect(() => {
    if (history.location.state) {
      setRedirectLink('/users/search');
    }
  }, []);

  const handleDenieForm = async () => {
    try {
      dispatch(managerDenieEmployeeForm(employeeId));
      await hubApiNotifications.checkNotificationAsRead(notificationId);
    } catch (err) {
      toast.dark('Erro ao tentar cancelar formulário do colaborador.');
    }
  };

  const handleAcceptForm = async () => {
    try {
      if (history.location.state) {
        await hubApi.updateEmployeeInfoByManager(employeeId, employeeInfo);

        history.push('/users/search');
        toast.dark('Informações do colaborador salvos com sucesso.');
      } else {
        dispatch(managerConfirmEmployeeForm(employeeInfo));
        history.push(`/manager/employee/contract/${employeeId}`);
      }
    } catch (err) {
      toast.dark('Ocorreu um erro ao tentar aceitar formulário.');
    }
  };

  const handleChangeValues = e => {
    setEmployeeInfo({ ...employeeInfo, [e.target.name]: e.target.value });
  };

  const handleChangeBankValues = e => {
    setEmployeeInfo({
      ...employeeInfo,
      bankData: {
        ...employeeInfo.bankData,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeAddressValues = e => {
    setEmployeeInfo({
      ...employeeInfo,
      address: {
        ...employeeInfo.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <BackPageButton link={redirectLink} />

      <DefaultCardWrapper>
        <div className="default-card-header">
          <h4>{employeeInfo.name}</h4>
        </div>

        <EmployeeInfosEditable
          employeeInfo={employeeInfo}
          handleChangeValues={handleChangeValues}
          handleChangeAddressValues={handleChangeAddressValues}
          handleChangeBankValues={handleChangeBankValues}
          fullPage
        />
        <Flex justifyContent="flex-end">
          {!history.location.state && (
            <SecondaryButton
              onClick={handleDenieForm}
              marginRight="10px"
              background="#d11306"
              _hover={{ bg: '#bc1105' }}
            >
              Recusar Formulário
            </SecondaryButton>
          )}
          <PrimaryButton onClick={handleAcceptForm}>
            Confirmar Informações
          </PrimaryButton>
        </Flex>
      </DefaultCardWrapper>
    </Container>
  );
};

export default EmployeeConfirmData;
