import { Flex, Text } from '@chakra-ui/react';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { formatCalendaryDate, formatDate } from 'utils/formatDate';

import formatStringInTitle from 'utils/formatStringInTitle';
import { IGetTags } from 'pages/MyClients/interfaces/ProfilePanelData';
import { InvestorDataSection } from './InvestorDataSection';
import { BasicDataSection } from './BasicDataSection';
import { TagsClients } from './TagsClients';
import { PreviousAdvisorSection } from './PreviousAdvisorSection';
import { ClientFilesSection } from './ClientFilesSection';

interface ProfileProps {
  clientInfos: ClientInfosType;
  setClientInfos: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  showBackPageButton: boolean;
  setShowBackPageButton: React.Dispatch<React.SetStateAction<boolean>>;
  allTags: IGetTags[];
  tagClient: IGetTags[];
  setTagClient: React.Dispatch<React.SetStateAction<IGetTags[]>>;
  onlyVizMode: boolean;
}

export const Profile: React.FC<ProfileProps> = ({
  clientInfos,
  setClientInfos,
  showBackPageButton,
  setShowBackPageButton,
  allTags,
  tagClient,
  setTagClient,
  onlyVizMode,
}) => {
  if (!showBackPageButton) setShowBackPageButton(true);

  const birthday = new Date(clientInfos.birthDay);
  const formatedBirthday = formatDate(
    new Date(birthday.setDate(birthday.getDate() + 1)).toISOString()
  );

  const startDate = new Date(clientInfos.startDate);
  const createdAt = new Date(clientInfos.created_at);

  const formatedStartDate = clientInfos.startDate
    ? formatCalendaryDate(
        new Date(startDate.setDate(startDate.getDate() + 1)).toISOString()
      )
    : formatCalendaryDate(
        new Date(createdAt.setDate(createdAt.getDate() + 1)).toISOString()
      );

  const profilePanelData = {
    basicData: [
      {
        label: 'Código BTG',
        value: clientInfos.btgAccount,
      },

      {
        label: 'E-mail',
        value: clientInfos.email && clientInfos.email.toLowerCase(),
      },
      {
        label: 'Profissão',
        value: clientInfos.profession,
      },
      {
        label: 'Tipo',
        value: clientInfos.type === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica',
      },
      {
        label: 'Cidade/Estado',
        value: `${clientInfos.city}/${clientInfos.state}`,
      },
      {
        label: 'Aniversário',
        value: formatedBirthday.slice(0, -7),
      },
      {
        label: 'Origem do cliente',
        value: clientInfos.additionalInformation
          ? `Indicado por: ${clientInfos.additionalInformation} `
          : clientInfos.fromTypeLead,
      },
    ],
    investorData: [
      {
        label: 'Perfil',
        value: clientInfos.perfil && formatStringInTitle(clientInfos.perfil),
      },
      {
        label: 'Tipo de investidor',
        value: clientInfos.investType,
      },
      {
        label: 'Range de investimentos',
        value: clientInfos.range,
      },
      {
        label: 'Patrimônio declarado (BTG)',
        value: clientInfos.declaredPL,
      },
    ],
    pendenciesData: [
      {
        label: 'Cliente Negativo',
        value: clientInfos.balance,
        taskId: (clientInfos.balance && clientInfos.balanceId) || '',
      },
      {
        label: 'Aniversário!',
        value: clientInfos.birthday,
        taskId: (clientInfos.birthday && clientInfos.birthdayId) || '',
      },
      {
        label: 'Pesquisa NPS',
        value: clientInfos.nps,
        taskId: (clientInfos.nps && clientInfos.npsId) || '',
      },
      {
        label: 'Origem do Cliente',
        value: clientInfos.fromType,
        taskId: (clientInfos.fromType && clientInfos.fromTypeId) || '',
      },

      {
        label: 'Reunião Periódica',
        value: clientInfos.periodicMeeting,
        taskId:
          (clientInfos.periodicMeeting && clientInfos.periodicMeetingId) || '',
      },
    ],
    startDate: formatedStartDate,
  };

  return (
    <Flex
      flexDir={['column', 'column', 'row']}
      gridGap="8"
      height={['60vh', 'calc(100vh - 224px)']}
      overflowY="auto"
      className="boxClients"
      pr={2}
    >
      <Flex flexDir="column" w="100%" h="100%" justifyContent="space-between">
        <BasicDataSection
          onlyVizMode={onlyVizMode}
          profilePanelData={profilePanelData}
          clientInfos={clientInfos}
          setClientInfos={setClientInfos}
          clientId={clientInfos.contactId}
        />

        <InvestorDataSection
          onlyVizMode={onlyVizMode}
          investorData={profilePanelData.investorData}
          declaredPLAAI={clientInfos.declaredPLAAI}
          clientInfos={clientInfos}
          setClientInfos={setClientInfos}
        />
      </Flex>
      <Flex flexDir="column" justifyContent="space-between" w="100%">
        <Flex flexDir="column" justifyContent="space-between" h="100%" mb="2">
          <PreviousAdvisorSection client={clientInfos} />
          <ClientFilesSection client={clientInfos} />

          <TagsClients
            onlyVizMode={onlyVizMode}
            clientId={clientInfos.contactId}
            allTags={allTags}
            tagClient={tagClient}
            setTagClient={setTagClient}
          />
        </Flex>
        <Text textAlign="right" fontSize="smaller" color="white.300">
          Data de entrada na EWZ: {profilePanelData.startDate}
        </Text>
      </Flex>{' '}
    </Flex>
  );
};
