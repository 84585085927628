import { Flex, Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { IEvent, IEventExtended } from 'pages/Events/interfaces/Event';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BoxSelectionOfParticipants } from './BoxSelectionOfParticipants';
import { createAndUpdateSchema } from './schema';

interface ICreateAndUpdateEventModalProps {
  onclose: () => void;
  eventId?: string;
  eventData?: IEvent;
  setAllEvents: React.Dispatch<React.SetStateAction<IEventExtended[]>>;
  allEvents: IEventExtended[];
}

export const CreateAndUpdateEventModal: React.FC<ICreateAndUpdateEventModalProps> =
  ({ onclose, eventId, eventData, setAllEvents, allEvents }) => {
    const [employeesList, setEmployeesList] = useState<any[]>([]);
    const [filteredEmployeesList, setFilteredEmployeesList] = useState<any[]>(
      []
    );
    const [selectedEmployees, setSelectedEmployees] = useState<string[]>(
      eventData ? JSON.parse(String(eventData?.participants)) : []
    );
    const [isLoading, setIsLoading] = useState(false);
    const [selectAllUsers, setSelectAllUsers] = useState(false);

    const modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['clean'],
      ],
    };

    const eventType = useRef<HTMLInputElement>({} as HTMLInputElement);
    const eventName = useRef<HTMLInputElement>({} as HTMLInputElement);
    const eventLink = useRef<HTMLInputElement>({} as HTMLInputElement);
    const eventDateAndHour = useRef<HTMLInputElement>({} as HTMLInputElement);
    const eventDescription = useRef<
      React.ClassAttributes<ReactQuill> | undefined
    >({} as React.ClassAttributes<ReactQuill>);

    useEffect(() => {
      if (
        eventData &&
        JSON.parse(String(eventData.participants)).length ===
          employeesList.length
      ) {
        return setSelectAllUsers(true);
      }
      setSelectAllUsers(false);
    }, [employeesList.length]);

    useEffect(() => {
      getAllEmployees();
      if (eventType.current && eventData) {
        eventType.current.value = eventData.eventType;
      }
      if (eventName.current && eventData) {
        eventName.current.value = eventData.eventName;
      }

      if (eventLink.current && eventData) {
        eventLink.current.value = eventData.link || '';
      }
      if (eventDateAndHour.current && eventData) {
        eventDateAndHour.current.value = eventData.eventDateAndHour;
      }
      if (eventDescription.current && eventData) {
        eventDescription.current.ref = eventData.description;
      }
    }, []);

    const getAllEmployees = async () => {
      try {
        const response = await hubApiUser.getAllUsers();
        setEmployeesList(response);
        setFilteredEmployeesList(response);
        return;
      } catch (error) {
        toast.dark(
          'Não foi possível buscar os colaboradores, tente novamente em alguns minutos'
        );
      }
    };

    const handleSendData = async (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      const data = {
        eventName: eventName.current.value,
        eventType: eventType.current.value,
        link: eventLink.current.value || '',
        eventDateAndHour: eventDateAndHour.current.value,
        description: eventDescription.current
          ? String(eventDescription.current.ref)
          : '',
        participants: selectedEmployees,
      };
      const isEventValid = await createAndUpdateSchema.isValid(data);

      if (eventId) {
        try {
          if (!isEventValid) {
            await createAndUpdateSchema.validate(data).catch(err => {
              err.errors.map((error: string) => toast.dark(error));
            });
            return setIsLoading(false);
          }
          if (data) {
            const response: IEventExtended = await hubApi.patchEditEvent(
              eventId,
              data
            );
            setAllEvents(
              allEvents.map(event => {
                if (event.id === response.id) {
                  event = response;
                  return event;
                }
                return event;
              })
            );
            toast.dark('Evento atualizado com sucesso!');

            onclose();
          }
        } catch (error) {
          toast.dark(
            'Não foi possível atualizar o evento, tente novamente em alguns minutos'
          );
        }
      } else {
        try {
          if (!isEventValid) {
            await createAndUpdateSchema.validate(data).catch(err => {
              err.errors.map((error: string) => toast.dark(error));
            });
            return setIsLoading(false);
          }
          if (data) {
            const response = await hubApi.postCreateEvent(data);
            setAllEvents([...allEvents, response]);

            toast.dark(
              'Evento criado e  compartilhado com os colaboradores selecionados!'
            );

            onclose();
          }
        } catch (error) {
          toast.dark(
            'Não foi possível criar o evento, tente novamente em alguns minutos'
          );
        }
      }
      setIsLoading(false);
    };

    const handleChangeDescriptionValue = (e: string) => {
      if (eventDescription.current) {
        eventDescription.current.ref = e;
      }
    };

    const handleChangeRef = (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (event.target.name) {
        case 'eventType':
          eventType.current.value = event.target.value;
          eventType.current.name = event.target.name;
          break;

        case 'eventName':
          eventName.current.value = event.target.value;
          eventName.current.name = event.target.name;
          break;

        case 'link':
          eventLink.current.value = event.target.value;
          eventLink.current.name = event.target.name;
          break;

        default:
          eventDateAndHour.current.value = event.target.value;
          eventDateAndHour.current.name = event.target.name;
          break;
      }
    };

    return (
      <BaseModalFormCard
        isModal
        isLoading={isLoading}
        maxW="80vw"
        handleFormSubmit={handleSendData}
        handleToggleModal={onclose}
        primaryButtonText={eventId ? 'Atualizar' : 'Criar'}
        title={eventId ? 'Atualizar evento' : 'Criar evento'}
      >
        <Flex width="100%" height="100%" gap={3}>
          <Flex width="50%" height="100%" flexDirection="column">
            <InputWithLabel
              label="Nome do evento "
              name="eventName"
              placeholder="Digite o nome do evento"
              mb={2}
              ref={eventName}
              defaultValue={eventName.current.value}
              onChange={handleChangeRef}
            />
            <InputWithLabel
              label="Tipo do evento"
              name="eventType"
              mb={2}
              placeholder="Ex:Reunião Online, Reunião Presencial, Evento Presencial, Treinamento"
              ref={eventType}
              defaultValue={eventType.current.value}
              onChange={handleChangeRef}
            />
            <InputWithLabel
              label="Link do evento"
              name="link"
              mb={2}
              placeholder="Link do evento(opcional)"
              ref={eventLink}
              defaultValue={eventLink.current.value}
              onChange={handleChangeRef}
              onBlur={handleChangeRef}
            />

            <InputWithLabel
              onChange={handleChangeRef}
              label="Data e hora"
              type="datetime-local"
              fontSize="sm"
              name="eventDateAndHour"
              mb={4}
              ref={eventDateAndHour}
              defaultValue={eventDateAndHour.current.value}
            />
            <Flex flexDirection="column">
              <Text mb="6px" fontSize="sm" color="white">
                Descrição
              </Text>
              <ReactQuill
                modules={modules}
                theme="snow"
                placeholder="Digite a descrição do evento"
                ref={(ref: any) => {
                  if (eventDescription.current && ref) {
                    eventDescription.current.ref = ref.state.value;
                  }
                }}
                value={(eventDescription.current?.ref as string) ?? ''}
                onChange={handleChangeDescriptionValue}
                className="createEventDescription"
              />
            </Flex>
          </Flex>
          <BoxSelectionOfParticipants
            employeesList={employeesList}
            filteredEmployeesList={filteredEmployeesList}
            selectAllUsers={selectAllUsers}
            selectedEmployees={selectedEmployees}
            setFilteredEmployeesList={setFilteredEmployeesList}
            setSelectAllUsers={setSelectAllUsers}
            setSelectedEmployees={setSelectedEmployees}
          />
        </Flex>
      </BaseModalFormCard>
    );
  };
