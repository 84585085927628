import { ModalContent, ModalContentProps } from '@chakra-ui/react';
import * as S from '../styles';

export const ModalRoot: React.FC<ModalContentProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <ModalContent bgColor="transparent" maxW="100%" w="500px" {...rest}>
      <S.WrapperBackgroundTransparentModalContent>
        {children}
      </S.WrapperBackgroundTransparentModalContent>
    </ModalContent>
  );
};
