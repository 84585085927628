import * as yup from 'yup';

const schemaIPO = yup.object().shape({
  title: yup.string().required('Digite um titulo.'),
  ticker: yup.string().required('Informe qual o ticket desse IPO.'),
  bookingDeadlines: yup
    .string()
    .required('Informe a data limite para as reservas.'),
  disclosureOfResults: yup
    .string()
    .required('Informe a data da divulgação dos resultados.'),
  financialSettlements: yup
    .string()
    .required('Informe a data das liquidações.'),
  startOfNegotiations: yup
    .string()
    .required('Informe a data de início das negociações.'),
  priceMin: yup.string().required('Informe o preço mínimo para aplicação'),
  priceMax: yup.string().required('Informe o preço máximo para aplicação'),
  strategy: yup.string().required('Digite uma estratégia para esse IPO.'),
  whatsMessage: yup.string().required('Digite uma mensage para WhatsApp.'),
});

const schemaSubscription = yup.object().shape({
  offer: yup.string().required('Preencha o campo de oferta.'),
  ticker: yup.string().required('Informe qual o ticket desse IPO.'),
  bookingDeadlines: yup
    .string()
    .required('Informe a data limite para as reservas.'),
  financialSettlements: yup
    .string()
    .required('Informe a data das liquidações.'),
  pricePerQuote: yup.string().required('Informe o preço por cota.'),
  marketQuote: yup.string().required('Informe o preço de mercado por cota.'),
  discount: yup.string().required('Informe o desconto.'),
  dividendYeld: yup.string().required('Informe o Dividend Yeld'),
  description: yup
    .string()
    .required('Preencha o campo de descrição do produto.'),
  whatsMessage: yup.string().required('Digite uma mensagem para o WhatsApp.'),
});

export default { schemaIPO, schemaSubscription };
