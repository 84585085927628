import { useState } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { ModalTitle } from 'components/elements/texts';
import { InputWithLabel } from 'components/elements/forms';
import { PrimaryButton } from 'components/elements/buttons/PrimaryButton';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MiAaiCaptationData } from 'pages/Captation/interfaces';

interface EditPointsFormProps extends FlexProps {
  employee: MiAaiCaptationData;
  employeeRole: 'aai' | 'sh';
  updatePoints: (points: string, operationType: string, role: string) => void;
}

type Operation = 'sum' | 'sub';

export const EditLevelForm: React.FC<EditPointsFormProps> = ({
  employee,
  employeeRole,
  updatePoints,
  ...rest
}) => {
  const [level, setLevel] = useState('');

  const computePointsOperation = async () => {
    const sum: Operation = 'sum';
    try {
      const operationInfos = {
        points: '0',
        type: sum,
        nivel: level,
      };

      await hubApiCaptation.patchEmployeePoints(
        operationInfos,
        employeeRole,
        employee.id
      );

      updatePoints('0', 'sum', 'aai');

      toast.dark('Nível atualizado com sucesso');
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 404) {
        toast.dark(
          'Não foi possível atualizar a pontuação, verifique se este SH está na tabela de AAI'
        );
      } else {
        toast.dark(
          'Não foi possível atualizar a pontuação, tente novamente em alguns minutos'
        );
      }
    }
  };

  return (
    <Flex px="2" flexDir="column" alignItems="center" {...rest}>
      <ModalTitle>Nível {employeeRole.toUpperCase()}</ModalTitle>

      <InputWithLabel
        label="Escolha o Nível do AAI"
        placeholder="EX: 5"
        w="204px"
        size="sm"
        mt="2.5"
        type="number"
        onChange={e => setLevel(e.target.value)}
      />

      <PrimaryButton
        w="100%"
        mt="5"
        fontWeight="bold"
        onClick={computePointsOperation}
      >
        Atualizar o nível
      </PrimaryButton>
    </Flex>
  );
};
