import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { errorHandler } from 'utils/errorHandler';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import {
  COEprops,
  ICalendarProps,
  IPOprops,
  OFPprops,
} from 'pages/Home/interfaces';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { ProductCalendaryModal } from 'pages/Home/components/modules/modals';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IEventExtended } from 'pages/Events/interfaces/Event';
import { CalendarItemCard } from '../../elements/cards';
import { CardEvents } from '../../elements/cards/CardEvents';

import { EventModal } from '../modals/EventModal';

interface ICards {
  produtos: ICalendarProps[];
  eventos: IEventExtended[];
}

export const ProductsCalendar: React.FC = () => {
  const { id } = useSelector((state: IReduxState) => state.user.profile);
  const [toggleProductModal, setToggleProductModal] = useState(false);
  const [toggleEventModal, setToggleEventModal] = useState(false);
  const [cardsProducts, setCardsProducts] = useState<ICalendarProps[]>([]);
  const [myEventCards, setMyEventCards] = useState<IEventExtended[]>([]);
  const [eventCardSelected, setEventCardSelected] = useState(
    {} as IEventExtended
  );
  const [productCardSelected, setProductCardSelected] = useState({
    foundProduct: {} as IPOprops | COEprops | OFPprops,
    productType: '',
  });
  const allCards: any[] = [];

  const handleToggleProductModal = () =>
    setToggleProductModal(!toggleProductModal);

  const handleToggleEventModal = () => setToggleEventModal(!toggleEventModal);

  const handleGetproductsList = async () => {};

  useEffect(() => {
    handleGetproductsList();
    handleShowOnlyToEventAttendees();
  }, [id]);

  const handleShowOnlyToEventAttendees = async () => {
    try {
      const response: ICards[] = await hubApiProducts.getAllCards();

      setCardsProducts(
        response[0].produtos.sort((a, b) => {
          if (a.title.toLowerCase() === 'em captação') {
            return -1;
          }
          return 1;
        })
      );

      const myEvents: IEventExtended[] = [];
      response[0].eventos.forEach(data => {
        JSON.parse(String(data.participants)).forEach((item: string) => {
          if (item === id) {
            myEvents.push(data);
          }
        });
      });

      setMyEventCards(myEvents);
    } catch (err) {
      errorHandler(err);
    }
  };

  const sortedCards = allCards.concat(cardsProducts, myEventCards);
  sortedCards.sort((a, b) => {
    if (
      a.limitDate < b.eventDateAndHour ||
      a.limitDate < b.limitDate ||
      a.eventDateAndHour < b.eventDateAndHour ||
      a.eventDateAndHour < b.limitDate
    ) {
      return -1;
    }
    return 1;
  });

  return (
    <>
      {sortedCards.length > 0 && (
        <DefaultCardWrapper width="100%" p="2" h="49%">
          <Flex
            width="100%"
            overflowY="auto"
            px="0.5"
            height={['200px', 'auto']}
          >
            <Flex width="100%" height="fit-content" flexWrap="wrap" gridGap="2">
              {sortedCards.map(cardInfo =>
                cardInfo.eventDateAndHour ? (
                  <CardEvents
                    key={cardInfo.id}
                    CardInfos={cardInfo}
                    handleShowModal={handleToggleEventModal}
                    setEventCardSelected={setEventCardSelected}
                  />
                ) : (
                  <CalendarItemCard
                    key={cardInfo.id}
                    productCardInfos={cardInfo}
                    handleShowModal={handleToggleProductModal}
                    setCardSelected={setProductCardSelected}
                  />
                )
              )}
            </Flex>
          </Flex>
          <ModalWrapper
            isOpen={toggleProductModal}
            onClose={handleToggleProductModal}
          >
            <ProductCalendaryModal
              handleToggleModal={handleToggleProductModal}
              productInfos={productCardSelected}
            />
          </ModalWrapper>

          <ModalWrapper
            isOpen={toggleEventModal}
            onClose={handleToggleEventModal}
          >
            <EventModal
              handleToggleModal={handleToggleEventModal}
              eventCardSelected={eventCardSelected}
            />
          </ModalWrapper>
        </DefaultCardWrapper>
      )}
    </>
  );
};
