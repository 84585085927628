import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import {
  BaseModalFormCard,
  DeleteTransferOrderCard,
  TransferOrderInfosCard,
} from 'components/elements/cards';
import { ipSegments } from 'pages/IP/data';
import {
  EmployeeWithPermissionsType,
  IPTransferOrdersProps,
} from 'pages/IP/interfaces';
import { Stack } from '@chakra-ui/react';
import { DisplayInformation } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';

import { formatNameToCaptalize } from 'utils/captalizeNames';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { queryClient } from 'App';

interface IOrderInfoIpProps {
  handleShowModal: () => void;
  order: IPTransferOrdersProps;
  RemoveOrder: (id: string) => void;
}

export const OrderInfo: React.FC<IOrderInfoIpProps> = ({
  handleShowModal,
  order,
  RemoveOrder,
}) => {
  const [employeesWithIpPermissions, setEmployeesWithIpPermissions] = useState(
    []
  );
  const [employeesWithSegmentPermission, setEmployeesWithSegmentPermission] =
    useState([]);
  const [employeeIdSelected, setEmployeeIdSelected] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSecondaryButton, setIsLoadingSecondaryButton] =
    useState(false);
  const [toggleCancelOrderModal, setToggleCancelOrderModal] =
    useState<boolean>(false);
  const handleToggleCancelOrderModal = () => {
    setToggleCancelOrderModal(!toggleCancelOrderModal);
  };
  const [description, setDescription] = useState('');
  const [isOpenTransferOrderModal, setIsOpenTransferOrderModal] =
    useState<boolean>(false);

  const [meetingDate, setMeetingDate] = useState<string>('');

  useEffect(() => {
    getEmployeesWithIPPermissions();
  }, []);

  useEffect(() => {
    setEmployeesWithSegmentPermission(
      employeesWithIpPermissions.filter(
        (employee: EmployeeWithPermissionsType) => {
          return employee?.rules?.some(rule => {
            return (
              (rule.permissionsCod === 'CLA' &&
                order.segment.startsWith(ipSegments.alt)) ||
              (rule.permissionsCod === 'CLI' &&
                order.segment === ipSegments.cred) ||
              (rule.permissionsCod === 'CLC' &&
                order.segment === ipSegments.corp) ||
              (rule.permissionsCod === 'CLS' &&
                order.segment === ipSegments.health) ||
              (rule.permissionsCod === 'CLV' &&
                order.segment === ipSegments.life) ||
              (rule.permissionsCod === 'CLG' &&
                order.segment === ipSegments.agro) ||
              rule.permissionsCod === 'GTR'
            );
          });
        }
      )
    );
  }, [employeesWithIpPermissions]);

  const getEmployeesWithIPPermissions = async () => {
    try {
      const employees = await hubApiIp.listUsersWithIpPermissions();
      setEmployeesWithIpPermissions(employees);
    } catch (error) {
      toast.dark('Não foi possível buscar os assessores com permissões de IP');
    }
  };

  const handleChange = useCallback(e => {
    setDescription(e.target.value);
  }, []);

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!employeeIdSelected) {
      return toast.dark('Selecione um Colaborador.');
    }

    if (order.segment === 'vida') {
      setIsOpenTransferOrderModal(true);
    } else {
      handleTransferOrder(false);
    }
  };

  const handleTransferOrder = async (
    shouldScheduleMeeting: boolean,
    event?: FormEvent
  ) => {
    event?.preventDefault();
    setIsLoading(true);
    if (!shouldScheduleMeeting) {
      setIsLoadingSecondaryButton(true);
    }

    if (shouldScheduleMeeting && !meetingDate) {
      setIsLoading(false);
      return toast.dark('Selecione uma data para a reunião');
    }

    try {
      if (order.segment === 'vida') {
        await hubApiIp.createIPMeeting({
          orderId: order.id,
          segment: 'life',
          status: shouldScheduleMeeting ? 'callDialed' : 'noContact',
          ...(shouldScheduleMeeting && { date: meetingDate }),
          employeeId: employeeIdSelected,
        });
        await queryClient.invalidateQueries(['ipMeetings']);
      } else {
        const transferData = {
          employeeId: employeeIdSelected,
          orderId: order.id,
        };
        await hubApiIp.transferOrderToEmployee(transferData);
      }

      RemoveOrder(order.id);
      toast.dark('Ordem transferida com sucesso');
      setIsLoading(false);
      setIsLoadingSecondaryButton(false);
      handleShowModal();
    } catch (error) {
      toast.dark(
        'Não foi possível concluir a transferência desta ordem, tente novamente em alguns minutos'
      );
      setIsLoading(false);
      setIsLoadingSecondaryButton(false);
    }
  };

  const handleChangeEmployee = useCallback(employee => {
    setEmployeeIdSelected(employee);
  }, []);

  const handleDeleteOrder = useCallback(async () => {
    handleShowModal();

    try {
      await hubApiIp.deleteOrder(order.id, { description });
      handleShowModal();
      RemoveOrder(order.id);
      toast.dark('Ordem apagada com sucesso');
    } catch (error) {
      toast.dark(
        'Não foi possível apagar esta ordem, tente novamente em alguns minutos'
      );
    }
  }, [handleShowModal, order.id, RemoveOrder, description]);

  return (
    <TransferOrderInfosCard
      title="Dados da ordem"
      isLoading={isLoading}
      collaborators={employeesWithSegmentPermission}
      handleSelectCollaborator={handleChangeEmployee}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      handleToggleCancelOrderModal={handleToggleCancelOrderModal}
    >
      <Stack spacing="3">
        <DisplayInformation
          label="Nome"
          info={formatNameToCaptalize(order.name)}
        />
        <DisplayInformation
          label="Nome do solicitante"
          info={formatNameToCaptalize(order.assessorName)}
        />
        <DisplayInformation label="Telefone" info={order.phone} />
        <DisplayInformation label="E-mail" info={order.email} />

        <DisplayInformation
          label="Segmento"
          info={
            order.others?.subSegment
              ? formatNameToCaptalize(
                  `${order.segment} - ${order.others.subSegment}`
                )
              : formatNameToCaptalize(order.segment)
          }
        />

        <DisplayInformation label="Informações" info={order.information} />
      </Stack>

      <ModalWrapper
        isOpen={toggleCancelOrderModal}
        onClose={handleToggleCancelOrderModal}
      >
        <DeleteTransferOrderCard
          description={description}
          handleDeleteOrder={handleDeleteOrder}
          handleChangeValue={handleChange}
          handleToggleCancelOrderModal={handleToggleCancelOrderModal}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenTransferOrderModal}
        onClose={() => setIsOpenTransferOrderModal(false)}
      >
        <BaseModalFormCard
          isModal
          title="Transferir ordem com reunião"
          primaryButtonText="Marcar reunião"
          secondaryButtonText="Transferir ordem sem marcar reunião"
          handleFormSubmit={event => handleTransferOrder(true, event)}
          secondaryButtonEventFunction={() => handleTransferOrder(false)}
          handleToggleModal={() => setIsOpenTransferOrderModal(false)}
          isLoading={isLoading}
          isLoadingSecondaryButton={isLoadingSecondaryButton}
        >
          <DateAndHourPicker
            label="Data da Reunião"
            onChange={date => setMeetingDate(date)}
          />
        </BaseModalFormCard>
      </ModalWrapper>
    </TransferOrderInfosCard>
  );
};
