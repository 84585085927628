import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import { LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import {
  IMonthlyCommissionTotal,
  IRemunerationDetails,
} from 'pages/Remuneration/interfaces';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { formatDate } from 'utils/formatDate';
import formatValue from 'utils/formatValue';

interface ITableRevenueProps {
  clientBTGAccount: string;
  searchDate: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableRevenueDetailsModal: React.FC<ITableRevenueProps> = ({
  clientBTGAccount,
  searchDate,
  setShowModal,
}) => {
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [remunerationDetails, setRemunerationDetails] = useState<
    IRemunerationDetails[]
  >([]);
  const tableHeadersModal = ['Produto', 'Ativo', 'Comissão AAI'];

  const getCommissionDetails = async () => {
    try {
      setIsLoadingDetails(true);
      const response = await hubApiFinancial.getMonthlyCommissionDetails(
        clientBTGAccount,
        searchDate
      );
      setRemunerationDetails(response);
      setIsLoadingDetails(false);
    } catch (error) {
      setIsLoadingDetails(false);
      toast.dark('Não foi possível carregar os dados.Tente novamente');
    }
  };

  const remunerationTotal = remunerationDetails.reduce(
    (acc, currentValue) => {
      acc.total += Number(currentValue.final);
      return acc;
    },
    { total: 0 }
  );

  const formattedDate = (date: string) => {
    const dateWithFormatting = formatDate(date).split('1 de');
    return formatToCapitalized(dateWithFormatting[1]);
  };

  useEffect(() => {
    getCommissionDetails();
  }, [searchDate, clientBTGAccount]);

  return (
    <>
      {isLoadingDetails ? (
        <LoadingSpinner />
      ) : (
        <DefaultModalContentWrapper
          flexDir="column"
          width="100%"
          maxW="80vw"
          minH="220px"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <Text color="#fff" fontSize={18}>
            {formattedDate(searchDate)}
          </Text>
          <TableBase
            headData={tableHeadersModal}
            w="100%"
            maxW="100%"
            maxH="calc(100vh - 350px)"
            overflow="auto"
            p={0}
          >
            {remunerationDetails.map((data, index) => (
              <TableRow
                key={index}
                _hover={{ background: 'background.500' }}
                cursor="pointer"
                transition="400ms"
                onClick={() => setShowModal(true)}
              >
                <TableData maxW="150px">{data.product}</TableData>
                <TableData maxW="150px">{data.asset}</TableData>
                <TableData maxW="150px">{formatValue(data.final)}</TableData>
              </TableRow>
            ))}
          </TableBase>
          <Flex
            height="30px"
            w="100%"
            color="white"
            fontWeight="semibold"
            fontSize="md"
            align="flex-end"
            justify="flex-end"
          >
            <Text>Total : {formatValue(remunerationTotal.total)}</Text>
          </Flex>
        </DefaultModalContentWrapper>
      )}
    </>
  );
};
