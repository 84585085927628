import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type UseQueryParamsResponseType = {
  getParam: (key: string) => string;
  setParam: (key: string, value: string) => void;
  deleteParam: (key: string | string[]) => void;
};

export const useQueryParams = (): UseQueryParamsResponseType => {
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getParam = (key: string): string => {
    const value = query.get(key);

    if (value === null) return '';

    return value;
  };

  const setParam = (key: string, value: string): void => {
    query.set(key, value);
    history.replace({ search: query.toString() });
  };

  const deleteParam = (key: string | string[]) => {
    if (Array.isArray(key)) {
      for (const item of key) {
        query.delete(item);
      }

      history.replace({ search: query.toString() });
      return;
    }

    query.delete(key);
    history.replace({ search: query.toString() });
  };

  return {
    getParam,
    setParam,
    deleteParam,
  };
};
