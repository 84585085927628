import React from 'react';
import { Flex } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import LeadsContainer from './styles';

import { DisplayInformation } from 'components/elements/others';
import DropdownMenu from '../../../../../components/customElements/DropdownMenu';
import { DefaultCardWrapper } from 'components/elements/wrappers';

const Leads = ({ handleShowModal, item }) => {
  const handleFormSubmit = event => {
    event.preventDefault();
  };

  return (
    <DefaultCardWrapper>
      ]
      <LeadsContainer>
        <div className="">
          <div className="default-card-header">
            <h4>Dados do Lead:</h4>
          </div>
          <form onSubmit={handleFormSubmit}>
            <DisplayInformation label="Nome" info={item.nome} />
            <DisplayInformation label="Telefone" info={item.telefone} />
            <DisplayInformation label="E-mail" info={item.email} />
            <DisplayInformation label="Valor" info={item.valor} />
            <DisplayInformation label="Informações" info={item.colaborador} />
            <DropdownMenu title="Escolha o Colaborador" small />
          </form>
          <Flex justifyContent="flex-end">
            <SecondaryButton onClick={handleShowModal}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton md>Enviar Lead</PrimaryButton>
          </Flex>
        </div>
      </LeadsContainer>
    </DefaultCardWrapper>
  );
};

export default Leads;
