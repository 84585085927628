import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { useNavigation } from 'hooksV2/useNavigation';
import React, { ReactNode } from 'react';

interface ComponentProps extends FlexProps {
  label?: string;
  info: string | ReactNode;
  numLines?: number;
  inlineInfo?: boolean;
  isClient?: boolean;
  leadId?: string | null;
  leadType?: string | null;
}

export const DisplayInformation: React.FC<ComponentProps> = ({
  label,
  info,
  numLines,
  inlineInfo,
  isClient,
  leadId,
  leadType,
  ...rest
}) => {
  const { redirect } = useNavigation();

  return (
    <Flex
      flexDir={inlineInfo ? 'row' : 'column'}
      justifyContent={inlineInfo ? 'space-between' : 'initial'}
      borderBottom="1px solid"
      borderColor="background.100"
      pb="1"
      {...rest}
    >
      {label && (
        <Text
          as={inlineInfo ? 'span' : 'p'}
          color="rgba(70, 70, 70, 1)"
          fontSize="xs"
          mb="1"
        >
          {label}:
        </Text>
      )}

      {typeof info === 'string' ? (
        <Flex
          maxH="160px"
          overflowY="auto"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            color="rgba(70, 70, 70, 1)"
            fontWeight="medium"
            fontSize="xs"
            px="2"
            noOfLines={numLines}
          >
            {info}
          </Text>
          <Flex>
            {label === 'Cliente' && leadId && (
              <Text
                borderRadius={4}
                backgroundColor="primary.500"
                color="white"
                fontWeight="medium"
                fontSize="xs"
                px="2"
                py="1"
                mx={2}
                noOfLines={numLines}
                onClick={() => redirect(`/v2/lead/${leadType}/${leadId}`)}
                cursor="pointer"
              >
                Ver lead
              </Text>
            )}
            {label === 'Cliente' && (
              <Text
                borderRadius={4}
                backgroundColor="#f2f2f2"
                color="rgba(70, 70, 70, 1)"
                fontWeight="medium"
                fontSize="xs"
                px="2"
                py="1"
                mx={2}
                noOfLines={numLines}
              >
                {isClient ? 'Cliente EWZ' : 'Não é cliente EWZ'}
              </Text>
            )}
          </Flex>
        </Flex>
      ) : (
        info
      )}
    </Flex>
  );
};
