import React, { useEffect, useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import hubApi from 'services/hubApi';
import { IUserProps } from 'pages/Sign/Register/interfaces';
import { LoadingSpinner } from 'components/elements/others';
import { toast } from 'react-toastify';

interface SendFormProps {
  mainForm: IUserProps;

  handlePrevPage: (step: number, progress: number) => void;
}

const SendForm: React.FC<SendFormProps> = ({
  mainForm,

  handlePrevPage,
}) => {
  const { token } = useParams<{ token: string }>();
  const [loading, setLoading] = useState(true);
  const [sendFormStatus, setSendFormStatus] = useState('');

  useEffect(() => {
    async function sendEmployeeForm(formData: IUserProps) {
      await hubApi
        .submitNewEmployeeForm(formData)
        .then(() => {
          setSendFormStatus('Formulário enviado com sucesso.');
          setLoading(false);
        })
        .catch(err => {
          if (err.response.data.error.errorType === 'WITHOUT_PERMISSION') {
            toast.dark('O link não é mais valido, entre em contato com o RH!');
            setSendFormStatus('Ocorreu um erro ao enviar o formulário');
            setLoading(false);
          } else if (err.response.status === 422) {
            toast.dark(
              'Alguns campos não foram preenchidos corretamente, por favor, revise o formulário!'
            );
            handlePrevPage(1, 25);
          } else if (err.response.status === 409) {
            toast.dark(
              'Alguns campos não foram preenchidos corretamente, por favor, revise o formulário!'
            );
            handlePrevPage(1, 25);
          }

          setLoading(false);
        });

      return () => setLoading(false);
    }

    sendEmployeeForm({ ...mainForm, token });
  }, []);

  return (
    <Box>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Text
            w="100%"
            maxW="996px"
            textAlign="center"
            m="16px 0 0"
            color="white"
            fontSize="s"
          >
            {sendFormStatus}
          </Text>
        </>
      )}
    </Box>
  );
};

export default SendForm;
