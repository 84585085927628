import React from 'react';

import { CardToModal } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { ipProducts, altInsurance } from './data';

const Ip: React.FC = () => {
  return (
    <PageWrapper alignItems="center" paddingTop="4.5rem">
      <SectionOptionsList title="Produtos">
        {ipProducts.map((item, index: number) => (
          <CardToModal
            key={index}
            title={item.title}
            segment={item.segment}
            Modal={item.modal}
            Icon={item.icon}
            description={item.description}
          />
        ))}
      </SectionOptionsList>

      <SectionOptionsList title="Seguros Alternativos">
        {altInsurance.map((item, index: number) => (
          <CardToModal
            key={index}
            segment={item.segment}
            subSegment={item.subSegment}
            title={item.title}
            Modal={item.modal}
            Icon={item.icon}
            description={item.description}
          />
        ))}
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Ip;
