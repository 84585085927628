import React, { useState } from 'react';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  iconSrc: string;
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const BirthdayCard: React.FC<ComponentProps> = ({
  iconSrc,
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        iconSrc={iconSrc}
        title="Aniversário"
        secondaryText={assignment.name}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Aniversário"
          message={`Você confirma que parabenizou o(a) cliente ${assignment.name.toUpperCase()}?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
