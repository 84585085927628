import React from 'react';
import { toast } from 'react-toastify';

import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { SecondaryButton } from 'components/elements/buttons';
import { Flex, Text } from '@chakra-ui/react';
import { WithdrawalProps } from 'pages/RV/subpages/RvWithdrawals/interfaces';
import { errorHandler } from 'utils/';

export const OrderInfoConfirmOrder: React.FC<WithdrawalProps> = ({
  handleShow,
  infos,
  segment,
  value,
  risk,
  handleShowModal,
}) => {
  const handleChangeNewValue = async () => {
    try {
      await hubApiRv.confirmTotalValue({
        idClient: infos.idClient,
        segment,
        risk: risk || '',
      });

      toast.dark('Resgate total feito com Sucesso.');
      handleShow();
      handleShowModal();
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <DefaultModalContentWrapper maxW="500px">
      <Text mb={3} fontSize="small">
        Tem certeza que deseja resgatar o valor de
        <span> {value} </span>
        do cliente<span> {infos.nameClient} </span>
        <p>{segment}</p>
      </Text>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleShow}> Cancelar</SecondaryButton>
        <SecondaryButton onClick={handleChangeNewValue}>
          confirmar
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
