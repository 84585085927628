import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { IEmployeesDistributionInfo } from 'pages/DistributionOfMinedLeads/interfaces';
import { WarningCircle } from 'phosphor-react';
import { FiSend } from 'react-icons/fi';

interface ISendManualLeadsProps {
  setShowManualLeadDistributionModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSendEmployeeId: React.Dispatch<React.SetStateAction<string>>;
  employee: IEmployeesDistributionInfo;
}

export const SendManualLeads: React.FC<ISendManualLeadsProps> = ({
  setShowManualLeadDistributionModal,
  setSendEmployeeId,
  employee,
}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Tooltip
        hasArrow
        label="Enviar leads instantaneamente"
        placement="top"
        color="white"
        bg="background.200"
        fontStyle="italic"
        fontWeight="light"
      >
        <Box>
          <FiSend
            size={18}
            cursor="pointer"
            onClick={() => {
              setShowManualLeadDistributionModal(true);
              setSendEmployeeId(employee.employeeId);
            }}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};
