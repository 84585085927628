import React, { useEffect, useState } from 'react';

import { PageWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';
import { BackPageButton } from 'components/elements/buttons';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import { toast } from 'react-toastify';
import Board from '../../components/modules/RhCrm/Board';
import { rhColumns } from './data';

import { IRhOrderCrm } from '../../interfaces';

const Rhcrm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(rhColumns);

  useEffect(() => {
    async function listUserOrders() {
      await hubApiRH
        .listAllOrders('all')
        .then(response => {
          for (const column of columns) {
            const tasks = response.filter(
              (order: IRhOrderCrm) =>
                order.status.toLowerCase() === column.status.toLowerCase()
            );
            column.tasks = tasks;
          }
          setIsLoading(false);
        })
        .catch(error => {
          error.response?.data.message !== 'Internal server error' &&
            toast.dark(error.response?.data.message);
        });
    }

    listUserOrders();

    return () => setIsLoading(false);
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Board
          boardTitle="CRM - RH"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};

export default Rhcrm;
