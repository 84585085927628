import { useEffect, useState } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, Flex } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';

import { PageWrapper } from 'components/elements/wrappers';
import {
  IInfosMundoInvest,
  IInfosMundoInvestCpmm,
} from 'pages/GenerationalVariables/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import { BackPageButton } from 'components/elements/buttons';
import { BigNumbersMundoInvest } from '../BigNumbersMundoInvest';
import { DashCPMM } from '../DashCPMM';

export const MetricsMI: React.FC = () => {
  const [cpmm, setCpmm] = useState<IInfosMundoInvestCpmm[]>([]);
  const [bigNumbers, setBigNumbers] = useState({} as IInfosMundoInvest);
  const FullDate = new Date();

  const dates = {
    month0: new Date(FullDate.getFullYear(), FullDate.getMonth() + 1, 0)
      .toISOString()
      .split('T')[0],
    month1: new Date(FullDate.getFullYear(), FullDate.getMonth(), 0)
      .toISOString()
      .split('T')[0],

    month2: new Date(FullDate.getFullYear(), FullDate.getMonth() - 1, 0)
      .toISOString()
      .split('T')[0],

    month3: new Date(FullDate.getFullYear(), FullDate.getMonth() - 2, 0)
      .toISOString()
      .split('T')[0],

    month4: new Date(FullDate.getFullYear(), FullDate.getMonth() - 3, 0)
      .toISOString()
      .split('T')[0],

    month5: new Date(FullDate.getFullYear(), FullDate.getMonth() - 4, 0)
      .toISOString()
      .split('T')[0],
  };

  const handleGetBigNumbers = async () => {
    try {
      const response = await hubApiCaptation.getInfosMundoInvest();
      setBigNumbers(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleGetCpmm = async () => {
    try {
      const response = await hubApiCaptation.getInfosMundoInvestCpmm();
      setCpmm(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    handleGetCpmm();
    handleGetBigNumbers();
  }, []);

  useEffect(() => {
    handleSumAddUpFromTheFirstFour();
  }, [cpmm]);

  const handleSumAddUpFromTheFirstFour = () => {
    const resultado = [];
    for (let i = 0; i < cpmm.length; i++) {
      if (i >= 3) {
        resultado.push(
          (Number(cpmm[i].cpmm) +
            Number(cpmm[i - 1].cpmm) +
            Number(cpmm[i - 2].cpmm) +
            Number(cpmm[i - 3].cpmm)) /
            4
        );
      }
    }

    return resultado;
  };
  const result: number[] = handleSumAddUpFromTheFirstFour();

  return (
    <PageWrapper>
      <BackPageButton />
      <Flex h="50vh" w="1900px">
        <Carousel
          autoPlay
          infiniteLoop
          stopOnHover={false}
          interval={10000}
          showThumbs={false}
          showArrows={false}
        >
          <Box w="1900px">
            <BigNumbersMundoInvest dates={dates} bigNumbers={bigNumbers} />
          </Box>

          <Box w="1900px">
            <DashCPMM cpmm={cpmm} result={result} />
          </Box>
        </Carousel>
      </Flex>
    </PageWrapper>
  );
};
