import { AiOutlineUnorderedList } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';

export const remunerationOptions = [
  {
    title: 'Detalhado',
    icon: AiOutlineUnorderedList,
    path: '/remuneration/details',
    description: '',
    value: '',
  },
  {
    title: 'Geral',
    icon: HiOutlineDocumentReport,
    path: '/commissioning_report',
    description: '',
    value: '',
  },

  {
    title: 'Equity',
    icon: AiOutlineUnorderedList,
    path: '/remuneration/equity',
    description: '',
    value: '',
  },
  {
    title: 'Comissão por área',
    icon: AiOutlineUnorderedList,
    path: '/remuneration/by/area',
    description: '',
    value: '',
  },

  {
    title: 'Comissionamento Ilha Forte',
    icon: AiOutlineUnorderedList,
    path: '/remuneration/ilhaforte',
    description: '',
    value: '',
  },
];
