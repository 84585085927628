import React, { useEffect, useState } from 'react';

import { showToast } from 'utils';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { MaterialCard } from 'pages/Marketing/components/modules/materialsManagement';
import { MaterialProps } from 'pages/Marketing/interfaces';

interface CurrentMaterialsProps {
  signalToUpdate: boolean;
  handleSignalToUpdate: () => void;
}

export const CurrentMaterials: React.FC<CurrentMaterialsProps> = ({
  signalToUpdate,
  handleSignalToUpdate,
}) => {
  const [currentMaterials, setCurrentMaterials] = useState<MaterialProps[]>([]);

  useEffect(() => {
    getCurrentMaterials();
  }, [signalToUpdate]);

  const getCurrentMaterials = async () => {
    try {
      const obtainedMaterials = await hubApiMarketing.getMaterials();

      setCurrentMaterials(obtainedMaterials);
    } catch (error) {
      showToast(
        'Não foi possível buscar os materiais cadastrados, tente novamente em alguns minutos'
      );
    }
  };

  const handleRemoveMaterial = async (materialId: string) => {
    try {
      await hubApiMarketing.deleteMaterial(materialId);

      setCurrentMaterials(
        currentMaterials.filter(material => material.id !== materialId)
      );

      showToast('Material removido');
    } catch (error) {
      showToast(
        'Não foi possível remover o material, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <>
      {currentMaterials.map(material => (
        <MaterialCard
          key={material.id}
          material={material}
          handleSignalToUpdate={handleSignalToUpdate}
          handleRemoveMaterial={handleRemoveMaterial}
        />
      ))}
    </>
  );
};
