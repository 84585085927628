import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BackPageButton } from 'components/elements/buttons';

import { LoadingSpinner } from 'components/elements/others';
import { PageWrapper } from 'components/elements/wrappers';
import Board from '../../components/modules/marketingCRM/Board';
import { marketingColumns } from './data';

interface RouteParams {
  id: string;
}

export const MarketingCRMsManagement: React.FC = () => {
  const { id } = useParams<RouteParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(marketingColumns);

  useEffect(() => {
    const listEmployeeOrders = async () => {
      await hubApiMarketing.listEmployeeSelected(id).then(response => {
        for (const column of columns) {
          const tasks = response.filter((order: { status: string }) => {
            return order.status.toLowerCase() === column.title.toLowerCase();
          });
          column.tasks = tasks;
        }
        setIsLoading(false);
      });
    };

    listEmployeeOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Board
          boardTitle="CRM - Marketing"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};
