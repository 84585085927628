import { Flex } from '@chakra-ui/react';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const EmployeeAgendaSkeletonLoading = (): JSX.Element => {
  return (
    <PageWrapper gap={5} paddingTop="4.5rem">
      <Flex w="100%" justifyContent="space-between" gap={2}>
        <SkeletonBase h="40px" w="100%" borderRadius={3} />
        <SkeletonBase h="40px" w="100%" borderRadius={3} />
        <SkeletonBase h="40px" w="100%" borderRadius={3} />
        <SkeletonBase h="40px" w="100%" borderRadius={3} />
        <SkeletonBase h="40px" w="100%" borderRadius={3} />
      </Flex>
      <Flex>
        <SkeletonBase h="600px" w="100%" borderRadius={3} />
      </Flex>
    </PageWrapper>
  );
};
