import { Flex } from '@chakra-ui/react';
import { ModalTitle as Title } from 'components/elements/texts';

type PropsType = {
  title: string;
};

export const ModalTitle: React.FC<PropsType> = ({ title }): JSX.Element => {
  return (
    <Flex justifyContent="space-between" alignItems="baseline">
      <Title color="rgba(70, 70, 70, 1)">{title}</Title>
    </Flex>
  );
};
