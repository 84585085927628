import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';

interface ManagementSegmentCardDataProps {
  item: IOrdersProps;
}

interface IOptionsOrders {
  status: string;
  title: string;
  value: number;
}

interface IOrdersProps {
  segment: string;
  title: string;
  options: IOptionsOrders[];
}

export const ManagementSegmentCardData: React.FC<ManagementSegmentCardDataProps> =
  ({ item }) => {
    return (
      <DefaultCardWrapper
        h="390px"
        minW="270px"
        flexDir="column"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <MainPageTitle fontSize="lg" mb="0">
            {item.title}
          </MainPageTitle>
        </Flex>

        {item.options.map((option, index) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="2"
            py="1"
            px="3"
            key={`${index}-${option.title}`}
            borderRadius="4"
            width="220px"
            bgColor="#f2f2f2"
          >
            <Text color="rgba(70, 70, 70, 1)">{option.title}</Text>
            <Text color="rgba(70, 70, 70, 1)">{option.value}</Text>
          </Flex>
        ))}
      </DefaultCardWrapper>
    );
  };
