import { Tooltip as TooltipChakra, TooltipProps } from '@chakra-ui/react';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  text: string | undefined;
} & TooltipProps;

export const Tooltip: React.FC<PropsType> = ({
  text,
  children,
}): JSX.Element => {
  return (
    <TooltipChakra
      hasArrow
      label={TextUtils.toCapitalize(text || '')}
      color="white"
      bg="background.200"
    >
      <div>{children}</div>
    </TooltipChakra>
  );
};
