import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { PrimaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { getOrderTypeBySegment } from 'pages/IP/utils';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { formatNameToCaptalize } from 'utils/captalizeNames';

type PropsType = {
  order: IPOrderType;
  setIsOpenIPTransferOrderModal: (value: boolean) => void;
};

export const IPTransferOrderModal: React.FC<PropsType> = ({
  order,
  setIsOpenIPTransferOrderModal,
}): JSX.Element => {
  const [
    selectedEmployeeIdToTransferOrder,
    setSelectedEmployeeIdToTransferOrder,
  ] = useState<string>('');

  const { data: employees } = useQuery<{ id: string; name: string }[]>({
    queryKey: ['employeeWithIpPermissions'],
    queryFn: async () => {
      const response = await hubApiIp.listUsersWithIpPermissions();
      return response;
    },
  });

  const { mutateAsync: transferOrder, isLoading } = useMutation({
    mutationKey: ['transferOrder'],
    mutationFn: async () => {
      const response = await hubApiIp.transferOrderByType({
        employeeId: selectedEmployeeIdToTransferOrder,
        orderId: order.id,
        type: getOrderTypeBySegment(order.segment),
      });

      return response;
    },
    onSuccess: async () => {
      toast.dark('Ordem transferida com sucesso!');
      await queryClient.invalidateQueries(['ipMeetings']);
    },
  });

  const handleTransferOrder = async () => {
    if (!selectedEmployeeIdToTransferOrder) {
      return toast.dark('Selecione um colaborador');
    }

    await transferOrder();

    setIsOpenIPTransferOrderModal(false);
  };

  return (
    <DefaultModalContentWrapper>
      <Text>Selecione um colaborador para transferir a ordem</Text>
      <Dropdown
        mt="5"
        placeholder="Selecione um colaborador"
        onChange={event =>
          setSelectedEmployeeIdToTransferOrder(event.target.value)
        }
      >
        {employees &&
          employees.map((collaborator, index: number) => (
            <option key={index} value={collaborator.id}>
              {formatNameToCaptalize(collaborator.name)}
            </option>
          ))}
      </Dropdown>
      <Flex pt={5} justifyContent="flex-end">
        <PrimaryButton onClick={handleTransferOrder} isLoading={isLoading}>
          Transferir
        </PrimaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
