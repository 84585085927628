import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().required('Digite um nome para a carteira'),
  segments: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('O nome do segmnetos não pode ser vazio.'),
        percent: yup
          .number()
          .min(0, 'A porcentagem minima é 0')
          .max(100, 'A porcentagem maxima é 100')
          .required('Digite uma porcentagem para o segmento'),
      })
    )
    .min(1, 'A carteira deve ter pelo nenos um segmento')
    .required('A carteira deve ter pelo nenos um segmento'),
});
