import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { PageWrapper } from 'components/elements/wrappers/PageWrapper';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import {
  NewNoticeModal,
  NoticeItem,
} from '../../components/modules/managerNotices';

interface INotice {
  data: string;
  title: string;
  msg: string;
  id: string;
}

export const ManagerNotices: React.FC = () => {
  const [notices, setNotices] = useState<INotice[]>([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    hubApiUser.getAllReminderManager().then(response => {
      setNotices(response);
    });
  }, [status]);

  const handleToggleModal = () => {
    setDisplayModal(!displayModal);
  };

  const handleDragEnd = (
    result: DropResult,
    noticesArr: INotice[],
    setNoticesArr: React.Dispatch<React.SetStateAction<INotice[]>>
  ) => {
    const { source, destination } = result;

    if (!result.destination) {
      return;
    }

    const resultArray = Array.from(noticesArr);

    const [removed] = resultArray.splice(source.index, 1);
    if (destination !== undefined) {
      resultArray.splice(destination.index, 0, removed);
    }
    setNoticesArr([...resultArray]);
  };

  const handleDeleteNotice = (id: string) => {
    hubApiUser.deleteReminder(id).then(() => {
      setStatus(!status);
      toast.dark('Aviso deletado com sucesso');
    });
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />
      <MainPageTitle textAlign="center">Avisos do Gestor</MainPageTitle>

      <Flex flexDir="column" width="100%" maxW="1080px">
        <SecondaryButton alignSelf="flex-start" onClick={handleToggleModal}>
          Criar novo aviso
        </SecondaryButton>

        <DefaultCardWrapper
          flexDir="column"
          width="100%"
          maxWidth="1080px"
          py="3"
          px="2"
          mt="3"
          height="100vh"
          maxH="calc(100vh - 260px)"
        >
          <DragDropContext
            onDragEnd={result => handleDragEnd(result, notices, setNotices)}
          >
            <Droppable droppableId="droppable" key={1}>
              {provided => (
                <Flex
                  flexDir="column"
                  gridGap="2"
                  px="1"
                  overflowY="auto"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {notices.map((notice, index) => (
                    <NoticeItem
                      key={index}
                      notice={notice}
                      noticeIndex={index}
                      handleDeleteNotice={handleDeleteNotice}
                    />
                  ))}

                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </DefaultCardWrapper>
      </Flex>

      <ModalWrapper isOpen={displayModal} onClose={handleToggleModal}>
        <NewNoticeModal
          setShowReminder={handleToggleModal}
          status={status}
          setStatus={setStatus}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
