const techColumns = [
  {
    id: '1',
    title: 'Aguardando suporte',
    value: 'Aguardando suporte',
    tasks: [],
  },
  {
    id: '2',
    title: 'Em execução',
    value: 'Em execução',
    tasks: [],
  },
  {
    id: '3',
    title: 'Aguardando acesso remoto',
    value: 'Aguardando acesso remoto',
    tasks: [],
  },
  {
    id: '4',
    title: 'Finalizado',
    value: 'Finalizado',
    tasks: [],
  },
];

export { techColumns };
