import { Flex } from '@chakra-ui/react';
import { Dropdown } from 'components/elements/others';
import { contentsSegments } from 'pages/Contents/data';
import { Content } from 'pages/Contents/interfaces';

interface AreasAndThemesDropdownProps {
  selectedSegment: string;
  setSelectedSegment: React.Dispatch<React.SetStateAction<string>>;
  previousContent?: Content;
  setSelectedTheme: React.Dispatch<React.SetStateAction<string>>;
  editionMode?: boolean;
}

export const AreasAndThemesDropdown: React.FC<AreasAndThemesDropdownProps> = ({
  selectedSegment,
  setSelectedSegment,
  previousContent,
  setSelectedTheme,
  editionMode,
}) => {
  return (
    <Flex alignItems={'center'} justifyContent="space-between">
      <Dropdown
        w={['40vw', '150px']}
        onChange={e => {
          setSelectedSegment(e.target.value);
          setSelectedTheme('');
        }}
      >
        {previousContent || editionMode
          ? Object.values(contentsSegments).map(
              (item: string[]) =>
                item[0] !== 'Todos' && (
                  <option
                    key={item[0]}
                    value={item[0]}
                    selected={previousContent?.area === item[0]}
                  >
                    {item[0]}
                  </option>
                )
            )
          : Object.values(contentsSegments).map((item: string[]) => (
              <option key={item[0]} value={item[0]}>
                {item[0]}
              </option>
            ))}
      </Dropdown>
      <Dropdown
        placeholder="Selecione tema"
        w={['40vw', '150px']}
        ml={[0, 1]}
        onChange={e => setSelectedTheme(e.target.value)}
      >
        {selectedSegment !== '' &&
          Object.values(contentsSegments)
            .filter(item => item[0] === selectedSegment)[0]
            ?.map((item, index) => {
              if (index > 0) {
                return (
                  <option
                    key={item}
                    value={item}
                    selected={previousContent?.theme === item}
                  >
                    {item}
                  </option>
                );
              }
            })}
      </Dropdown>
    </Flex>
  );
};
