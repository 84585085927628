import { Flex, Text, TextProps } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Spinner } from 'componentsV2/elements/spinner';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useEffect, useRef, useState } from 'react';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { Input } from '..';

type PropsType = {
  value: string;
  onConfirmEdit: (newValue: string) => void;
  isLoading?: boolean;
  textProps?: TextProps;
};

export const InputEditableTextField: React.FC<PropsType> = ({
  value,
  onConfirmEdit,
  isLoading = false,
  textProps,
}) => {
  const [shouldShowInput, setShouldShowInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShouldShowInput(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleConfirmEdit = () => {
    onConfirmEdit(inputValue);
  };

  useEffect(() => {
    if (!isLoading) setShouldShowInput(false);
  }, [isLoading]);

  return (
    <div ref={inputRef}>
      {shouldShowInput ? (
        <Flex gap={1} alignItems="center">
          <Input.TextField
            value={inputValue}
            onChange={handleInputChange}
            h="22px"
          />
          <Box.Outline
            h="24px"
            p={2}
            cursor="pointer"
            _hover={{ bgColor: colors.BLUE }}
            onClick={handleConfirmEdit}
          >
            {isLoading ? <Spinner h="18px" w="20px" /> : <Icons.CHECK />}
          </Box.Outline>
        </Flex>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setShouldShowInput(true)}
          cursor="pointer"
        >
          <Text color="rgba(70, 70, 70, 1)" {...textProps}>
            {inputValue}
          </Text>

          <Tooltip text="Clique no texto para editar">
            <Flex>
              <Icons.PENCIL />
            </Flex>
          </Tooltip>
        </Flex>
      )}
    </div>
  );
};
