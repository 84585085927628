import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@chakra-ui/react';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase } from 'components/elements/table';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import {
  IAction,
  IFilter,
  IOrder,
  IOrderAfterExtractCancel,
  IOrderExtract,
} from 'pages/RV/subpages/RvOrders/interfaces/index';
import ModalDownloadOrder from 'pages/RV/components/modules/RvOrders/ModalDownloadOrder';
import ModalReject from 'pages/RV/components/modules/RvOrders/ModalReject';
import ModalConfirm from 'pages/RV/components/modules/RvOrders/ModalConfirm';
import { errorHandler } from 'utils';
import RvOrdersTableRow from '../../components/modules/RvOrders/RvOrdersTableRow';
import { advancedOrdersHeader } from './data';

const RvOrders: React.FC = () => {
  const [filter, setFilter] = useState<IFilter>({
    name: 'Não extraido',
    value: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalReject, setModalReject] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [ordersExtract, setOrdersExtract] = useState<IOrderExtract[]>([]);
  const [link, setLink] = useState('');
  const [modalDownloadOrder, setModalDownloadOrder] = useState(false);
  const [linkToShow, setLinkToShow] = useState('');
  const [isMainChecked, setIsMainChecked] = useState(false);
  const [formInfos, setFormInfos] = useState({
    obs: '',
  });
  const [checkOrder, setCheckOrder] = useState('Ações');
  const [checkedBoxesNumberOrder, setCheckedBoxesNumberOrder] = useState(0);
  const [checkedBoxesNumberOrderExtract, setCheckedBoxesNumberOrderExtract] =
    useState(0);
  const [isLoadingModalDownloadOrder, setIsLoadingModalDownloadOrder] =
    useState(false);
  const [isLoadingModalReject, setIsLoadingModalReject] = useState(false);
  const [isLoadingModalConfirm, setIsLoadingModalConfirm] = useState(false);
  const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const newFilter: IFilter[] = [
    {
      name: 'Não extraido',
      value: false,
    },
    {
      name: 'Extraido',
      value: true,
    },
  ];

  const actions: IAction[] = [
    {
      name: 'Confirmada',
    },
    {
      name: 'Rejeitado',
    },
  ];

  const handleChangeOrders = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event?.target.value === 'Confirmada') {
      setModalConfirm(!modalConfirm);
      setCheckOrder(event?.target.value);
    }

    if (event?.target.value === 'Rejeitado') {
      setModalReject(!modalReject);
      setCheckOrder(event?.target.value);
    }
  };

  const handleSetNotExtract = async () => {
    setIsLoading(true);
    await hubApiRv
      .getOrderRv()
      .then(response => {
        const newArray: IOrder[] = [];
        response.map((item: IOrder) => {
          return newArray.push(Object.assign(item, { isChecked: false }));
        });
        setOrders(newArray);
        if (!newArray.length) {
          toast.dark('Nenhuma ordem foi encontrada');
        }
        setIsLoading(false);
      })
      .catch(() => {
        toast.dark(
          'Ocorreu um erro ao buscar as ordens, por favor contate a equipe de T.I'
        );
        setIsLoading(false);
      });
  };

  const handleSetNotExtractSecondTime = async () => {
    await hubApiRv
      .getOrderRv()
      .then(response => {
        const newArray: IOrder[] = [];
        response.forEach((item: IOrder): void => {
          newArray.push(Object.assign(item, { isChecked: false }));
        });
        setOrders(newArray);
        setIsLoading(false);
      })
      .catch(() => {
        toast.dark(
          'Ocorreu um erro ao buscar as ordens, por favor contate a equipe de T.I'
        );
        setIsLoading(false);
      });
  };

  const handleSetExtract = async () => {
    setIsLoading(true);
    await hubApiRv
      .getAllOrdersRvExtract()
      .then(response => {
        const newArray: IOrderExtract[] = [];
        response.forEach((item: IOrderExtract): void => {
          newArray.push(Object.assign(item, { isChecked: false }));
        });
        setOrdersExtract(newArray);
        setIsLoading(false);
      })
      .catch(() => {
        toast.dark(
          'Ocorreu um erro ao buscar as ordens, por favor contate a equipe de T.I'
        );
        setIsLoading(false);
      });
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'Extraido') {
      setFilter({
        name: 'Extraido',
        value: true,
      });
    } else if (event.target.value === 'Não extraido') {
      setFilter({
        name: 'Não extraido',
        value: false,
      });
    }
  };

  useEffect(() => {
    handleSetNotExtract();
    handleSetExtract();
  }, []);

  const toggleMainCheckbox = () => {
    setIsMainChecked(!isMainChecked);
    if (filter.name === 'Extraido') {
      setCheckedBoxesNumberOrderExtract(!isMainChecked ? 1 : 0);
      const newFilteredOrders = ordersExtract.map(o => {
        o.isChecked = !isMainChecked;
        return o;
      });
      setOrdersExtract(newFilteredOrders);
    } else if (filter.name === 'Não extraido') {
      setCheckedBoxesNumberOrder(!isMainChecked ? 1 : 0);
      const newFilteredOrders = orders.map(o => {
        o.isChecked = !isMainChecked;
        return o;
      });
      setOrders(newFilteredOrders);
    }
  };

  const handleToggleCheckbox = (
    order: IOrder | IOrderExtract,
    orderType: string
  ) => {
    if (orderType === 'orders') {
      const newFilteredOrders = orders.map(o => {
        if (o.id === order.id) {
          o.isChecked = !o.isChecked;
          setCheckedBoxesNumberOrder(
            o.isChecked
              ? checkedBoxesNumberOrder + 1
              : checkedBoxesNumberOrder - 1
          );
        }
        return o;
      });
      setOrders(newFilteredOrders);
    } else {
      const newFilteredOrders = ordersExtract.map(o => {
        if (o.id === order.id) {
          o.isChecked = !o.isChecked;
          setCheckedBoxesNumberOrderExtract(
            o.isChecked
              ? checkedBoxesNumberOrderExtract + 1
              : checkedBoxesNumberOrderExtract - 1
          );
        }
        return o;
      });
      setOrdersExtract(newFilteredOrders);
    }
  };

  const handleSubmitConfirm = useCallback(async () => {
    setIsLoadingModalConfirm(true);
    await hubApiRv
      .updateOrderAfterExtract(ordersExtract)
      .then(response => {
        response.forEach((item: IOrderExtract) => {
          if (item.isChecked) {
            item.isChecked = false;
          }
        });
        setOrdersExtract(response);
        setModalConfirm(!modalConfirm);
        toast.dark('Ordem atualizada com sucesso');
        setIsLoadingModalConfirm(false);
      })
      .catch(error => {
        if (error.response?.data.message !== 'Internal server error') {
          toast.dark(error.response?.data.message);
        }
        setIsLoadingModalConfirm(false);
      });
  }, [modalConfirm, ordersExtract]);

  const handleSubmitConfirmBasket = useCallback(async () => {
    setIsLoadingModalDownloadOrder(true);
    const filteredOrders = orders.filter(o => o.isChecked);

    const ordersChecked = filteredOrders.map((order: IOrder) => {
      return { ...order, link };
    });

    await hubApiRv
      .updateOrderAfterConfirmExtract(ordersChecked)
      .then(() => {
        setFilter({
          name: 'Extraido',
          value: true,
        });
        setModalDownloadOrder(!modalDownloadOrder);
        toast.dark('Ordem atualizada com sucesso');
        handleSetNotExtractSecondTime();
        handleSetExtract();
        setIsLoadingModalDownloadOrder(false);
      })
      .catch(error => {
        if (error.response?.data.message !== 'Internal server error') {
          toast.dark(error.response?.data.message);
        }
        setIsLoadingModalDownloadOrder(false);
      });
  }, [
    handleSetExtract,
    handleSetNotExtractSecondTime,
    link,
    modalDownloadOrder,
    orders,
  ]);

  const handleSubmitCancel = useCallback(async () => {
    setIsLoadingModalReject(true);
    ordersExtract.filter((order: IOrderExtract) => {
      if (order.isChecked === true) {
        Object.assign(order, { obs: formInfos.obs });
        return order;
      }
    });

    await hubApiRv
      .updateOrderAfterExtractCancel(ordersExtract)
      .then(response => {
        response.forEach((item: IOrderAfterExtractCancel) => {
          if (item.isChecked) {
            item.isChecked = false;
          }
        });
        setOrdersExtract(response);
        setModalReject(!modalReject);
        toast.dark('Ordem atualizada com sucesso');
        formInfos.obs = '';
        setCheckOrder('Ações');
        setIsLoadingModalReject(false);
      })
      .catch(error => {
        errorHandler(error);
        setIsLoadingModalReject(false);
      });
  }, [formInfos, modalReject, ordersExtract]);

  const handleCloseModalConfirm = () => {
    setModalConfirm(!modalConfirm);
  };

  const handleCloseModalRejects = () => {
    setModalReject(!modalReject);
  };

  const handleCloseModalConfirmBasket = () => {
    setModalDownloadOrder(!modalDownloadOrder);
  };

  const handleLink = useCallback(async () => {
    await hubApiRv
      .extractXlsx(orders)
      .then(response => {
        const newLink = response.link;
        setLink(newLink);
        window.location.assign(newLink);
        setModalDownloadOrder(!modalDownloadOrder);
        const linkWithoutCom = newLink.split('com/');
        const linkWithoutBasket = linkWithoutCom[1].split('-Basket');
        setLinkToShow(linkWithoutBasket[0]);
      })
      .catch(error => {
        if (error.response?.data.message !== 'Internal server error') {
          toast.dark(error.response?.data.message);
        }
      });
  }, [modalDownloadOrder, orders]);

  return (
    <PageWrapper flexDir="column" alignItems="center">
      <MainPageTitle marginTop="50px" textAlign="center">
        Ordens de Ações/FIIs
      </MainPageTitle>
      <BackPageButton />
      <Box d="flex" w="100%" maxW="1080px" marginBottom="20px" gridGap="4">
        <Dropdown onChange={e => handleChangeFilter(e)}>
          {newFilter.map((nFilter: IFilter) => {
            return (
              <option key={nFilter.name} value={nFilter.name}>
                {nFilter.name}
              </option>
            );
          })}
        </Dropdown>
        {checkedBoxesNumberOrderExtract > 0 && filter.name === 'Extraido' && (
          <Dropdown
            title={checkOrder}
            onChange={e => handleChangeOrders(e)}
            placeholder="Selecione uma ação"
          >
            {actions.map((action: IAction, index: number) => {
              return (
                <option key={index} value={action.name}>
                  {action.name}
                </option>
              );
            })}
          </Dropdown>
        )}
        {checkedBoxesNumberOrder > 0 && filter.name === 'Não extraido' && (
          <SecondaryButton marginLeft="100px" onClick={handleLink}>
            Extrair Tabela
          </SecondaryButton>
        )}
      </Box>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <TableBase
          width="100%"
          maxW="1080px"
          overflowY="auto"
          maxH="calc(100vh - 274px)"
          headData={advancedOrdersHeader}
          isMainChecked={isMainChecked}
          toggleMainCheckbox={toggleMainCheckbox}
          isMainCheckbox
        >
          {filter.name === 'Extraido'
            ? ordersExtract.map(order => (
                <RvOrdersTableRow
                  order={order}
                  filter={filter}
                  handleToggleCheckbox={handleToggleCheckbox}
                />
              ))
            : orders?.map(order => (
                <RvOrdersTableRow
                  order={order}
                  filter={filter}
                  handleToggleCheckbox={handleToggleCheckbox}
                />
              ))}
        </TableBase>
      )}
      <ModalWrapper
        isOpen={modalDownloadOrder}
        onClose={handleCloseModalConfirmBasket}
      >
        <ModalDownloadOrder
          linkToShow={linkToShow}
          handleSubmitConfirmBasket={handleSubmitConfirmBasket}
          handleCloseModalConfirmBasket={handleCloseModalConfirmBasket}
          isLoadingModalDownloadOrder={isLoadingModalDownloadOrder}
        />
      </ModalWrapper>
      <ModalWrapper isOpen={modalReject} onClose={handleCloseModalRejects}>
        <ModalReject
          handleSubmitCancel={handleSubmitCancel}
          handleCloseModalRejects={handleCloseModalRejects}
          handleChangeValue={handleChangeValue}
          formInfos={formInfos}
          isLoadingModalReject={isLoadingModalReject}
        />
      </ModalWrapper>
      <ModalWrapper isOpen={modalConfirm} onClose={handleCloseModalConfirm}>
        <ModalConfirm
          handleCloseModalConfirm={handleCloseModalConfirm}
          handleSubmitConfirm={handleSubmitConfirm}
          isLoadingModalConfirm={isLoadingModalConfirm}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default RvOrders;
