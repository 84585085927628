import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { showToast } from 'utils';
import { formateDateAndTime } from 'utils/formatDate';
import SearchLead from 'pages/Captation/components/modules/SearchLead';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { LoadingSpinner } from 'components/elements/others';
import { BackPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

interface ILeadSummary {
  id: string;
  confirm: boolean;
  date: string;
  employeeId: string;
  employeeIdParticipant: string;
  employeeIdParticipantName: string;
  meetingStatus: string;
  status: string;
  name: string;
  phone: string[];
}

const meetingsStatus = [
  'callback',
  'suitability',
  'presentation',
  'allocation',
  'account',
];

export const ShLeadsSummary: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leadsHistory, setLeadsHistory] = useState<ILeadSummary[]>([]);
  const [searchedLeads, setSearchedLeads] = useState<ILeadSummary[]>([]);

  const handleGetLeadsHistory = async () => {
    setIsLoading(true);
    try {
      const leadsData = await hubApiCaptation.getShLeadsHistory();
      setLeadsHistory(leadsData);
      setSearchedLeads(leadsData);
    } catch (err) {
      showToast(
        'Ocorreu um erro ao tentar buscar leads. Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  const checkLeadStatus = (lead: ILeadSummary): string => {
    if (meetingsStatus.includes(lead.meetingStatus) && lead.confirm === true)
      return 'R.A';
    if (meetingsStatus.includes(lead.meetingStatus) && lead.confirm === false)
      return 'Pendente';

    if (lead.meetingStatus === 'created') return 'Reunião SH';
    if (lead.meetingStatus === 'confirmed') return 'Pool AAI';
    if (lead.meetingStatus === 'declined') return 'Declinado';
    if (lead.meetingStatus === 'returned') return 'R.N.A';

    return lead.meetingStatus;
  };

  const checkResponsible = (lead: ILeadSummary): string => {
    if (lead.employeeId === lead.employeeIdParticipant) return '-';
    if (!lead.employeeIdParticipantName) return '-';

    return lead.employeeIdParticipantName;
  };

  const checkMeetingDate = (lead: ILeadSummary) => {
    if (lead.meetingStatus === 'declined' || lead.meetingStatus === 'returned')
      return '-';

    return formateDateAndTime(lead.date);
  };

  const handleSearchLead = (searchValue: string) => {
    if (!leadsHistory) return;

    if (searchValue.trim().length < 3) {
      showToast('Digite pelo menos três caracteres para a busca');
      return;
    }

    const foundLeads = leadsHistory.filter(
      lead =>
        lead.name
          .toLowerCase()
          .trim()
          .includes(searchValue.toLowerCase().trim()) ||
        (lead.phone && lead.phone[0]?.includes(searchValue.trim()))
    );

    if (foundLeads.length === 0) {
      showToast('Não foram encontrados leads');
    } else {
      setSearchedLeads(foundLeads);
    }
  };

  useEffect(() => {
    handleGetLeadsHistory();
  }, []);

  return (
    <PageWrapper padding="36px 20px 0" flexDir="column" alignItems="center">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      <Flex width="100%" maxW="1080px" flexDir="column">
        <MainPageTitle>Histórico de leads</MainPageTitle>

        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          mb="3"
        >
          <SearchLead
            placeholder="Digite o nome ou número do lead"
            minW={['fit-content', '224px']}
            handleSearchLead={handleSearchLead}
          />

          {searchedLeads.length < leadsHistory.length && (
            <Text onClick={() => setSearchedLeads(leadsHistory)}>
              Ver todos
            </Text>
          )}
        </Flex>

        <TableBase
          p="0"
          maxH="calc(100vh - 240px)"
          overflowY="auto"
          headData={[
            'Nome do lead',
            'Telefone',
            'Status',
            'AAI Responsável',
            'Próx. Contato',
          ]}
        >
          {leadsHistory.length > 0 &&
            searchedLeads.map(lead => (
              <TableRow key={lead.id}>
                <TableData>{lead.name}</TableData>
                <TableData>
                  {lead.phone?.length > 0 ? lead.phone[0] : '-'}
                </TableData>
                <TableData>{checkLeadStatus(lead)}</TableData>
                <TableData>{checkResponsible(lead)}</TableData>
                <TableData>{checkMeetingDate(lead)}</TableData>
              </TableRow>
            ))}
        </TableBase>
        {!isLoading && leadsHistory.length === 0 && (
          <Text
            textAlign="center"
            fontWeight="medium"
            fontSize="larger"
            mt="12"
          >
            Não foram encontrados leads
          </Text>
        )}
      </Flex>
    </PageWrapper>
  );
};
