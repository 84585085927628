import React from 'react';
import { ModalContent, ModalContentProps } from '@chakra-ui/react';

export const DefaultModalContentWrapper: React.FC<ModalContentProps> = ({
  ...rest
}) => {
  return (
    <ModalContent
      padding="5"
      borderRadius="md"
      background="#fff"
      flexDir="column"
      width="100%"
      maxW={{ base: '95vw', md: '540px', lg: '640px' }}
      color="rgba(70, 70, 70, 1)"
      {...rest}
    />
  );
};
