import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { CAPTATION_QUERIES_KEYS } from '../CAPTATION_QUERIES_KEYS';
import { LeadDetailsProps } from '../interfaces/SHLeads';

export const useSHPoolLeads = (): UseQueryResult<LeadDetailsProps[]> => {
  return useQuery<LeadDetailsProps[]>({
    queryKey: [CAPTATION_QUERIES_KEYS.shPoolLeads],
    queryFn: async () => {
      const response =
        (await hubApiCaptation.getPublicLeads()) as AxiosResponse<
          LeadDetailsProps[]
        >;

      const responseFiltered = response.data.filter(
        item => item.origin !== 'leadPurchased'
      );

      return responseFiltered;
    },
  });
};
