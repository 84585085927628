import React, { ReactNode } from 'react';

import { Flex } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';

interface DisplayInfoBtnProps {
  label: string;
  info: string;
  btnText: string;
  handleShow: (
    e: ReactNode,
    segmentSelected?: string,
    value?: number,
    risk?: string
  ) => void;
}

const DisplayInfoBtn: React.FC<DisplayInfoBtnProps> = ({
  label,
  info,
  btnText,
  handleShow,
}) => {
  return (
    <Flex alignItems="center" justify="space-between">
      <DisplayInformation label={label} info={info} />
      <SecondaryButton fontSize="small" mt="3" onClick={handleShow}>
        {btnText}
      </SecondaryButton>
    </Flex>
  );
};

export default DisplayInfoBtn;
