import { Box, Flex, Switch, Tooltip } from '@chakra-ui/react';
import { IEmployeesDistributionInfo } from 'pages/DistributionOfMinedLeads/interfaces';

interface IDistributionSwitchProps {
  employeeSingleRow: IEmployeesDistributionInfo;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setEmployeeSingleRow: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo>
  >;
}

export const DistributionSwitch: React.FC<IDistributionSwitchProps> = ({
  employeeSingleRow,
  setIsSaved,
  setEmployeeSingleRow,
}) => {
  const handleDistributionSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSaved(false);
    setEmployeeSingleRow({
      ...employeeSingleRow,
      isDistributionActive: e.target.checked,
    });
  };

  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      pt={2}
    >
      <Tooltip
        hasArrow
        label="Ativar/desativar distribuição de leads"
        placement="top"
        color="white"
        bg="background.200"
        fontStyle="italic"
        fontWeight="light"
      >
        <Box>
          <Switch
            size="md"
            isChecked={employeeSingleRow.isDistributionActive}
            onChange={e => handleDistributionSwitch(e)}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};
