import { Stack } from '@chakra-ui/react';
import { InputWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { FormEvent } from 'react';

interface ModalOrderCreateActionProps {
  handleAddAction: (e: FormEvent) => void;
  onClose: () => void;
  isLoading: boolean;
  handleChangeAction: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const ModalOrderCreateAction: React.FC<ModalOrderCreateActionProps> = ({
  handleAddAction,
  onClose,
  isLoading,
  handleChangeAction,
}) => {
  return (
    <BaseModalFormCard
      isModal
      title="Adicionar Action"
      handleFormSubmit={handleAddAction}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText="Confirmar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW="510px"
    >
      <Stack>
        <InputWithLabel
          label="Nome da action"
          name="name"
          onChange={handleChangeAction}
        />
        <InputWithLabel
          label="Prioridade"
          type="number"
          name="seq"
          onChange={handleChangeAction}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
