import React, { ChangeEvent, useEffect, useState } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { BiFilterAlt } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formateDateAndTime } from 'utils/formatDate';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import formatValue from 'utils/formatValue';
import { IReduxState } from 'shared/interfaces';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import {
  IGetRoutedMeetingsRequest,
  IRoutedMeetings,
  ITableOptions,
} from './types';

export const RoutedMeetingsManagement: React.FC = () => {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [routedMeetings, setRoutedMeetings] = useState<IRoutedMeetings[]>([]);
  const [nextPageToFetch, setNextPageToFetch] = useState(1);
  const [tableIdFilter, setTableIdFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [tablesOptions, setTablesOptions] = useState<ITableOptions[]>([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state: IReduxState) => state.user.profile);

  const showTablesFilter = verifyEmployeePermission({
    user,
    areaCod: ['ASS'],
    permissionCod: ['ASS', 'GTM', 'GTR'],
  });

  const statusOptions = [
    {
      label: 'Sem feedback',
      value: 'waiting_feedback',
    },
    {
      label: 'Atrasadas',
      value: 'late',
    },
    {
      label: 'Declinadas',
      value: 'declined',
    },
    {
      label: 'Suitability',
      value: 'suitability',
    },
    {
      label: 'RNA',
      value: 'returned',
    },
  ];

  const handleStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDateFilter(event.target.value);
  };

  const handleEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setEndDateFilter(event.target.value);
  };

  const getRoutedMeetingsFilters = async () => {
    if (
      (startDateFilter !== '' && endDateFilter === '') ||
      (endDateFilter !== '' && startDateFilter === '')
    ) {
      return;
    }

    try {
      setIsLoading(true);

      const result: IGetRoutedMeetingsRequest =
        await hubApiCaptation.getRoutedMeetings(
          '1',
          tableIdFilter,
          startDateFilter,
          endDateFilter,
          statusFilter
        );

      const nextPage = result.allRoutedMeetings.length / 12 + 1;

      setActivePage(1);
      setNextPageToFetch(nextPage);
      setTotalPages(result.totalPages);
      setRoutedMeetings(result.allRoutedMeetings);
    } catch (err) {
      toast.dark(
        'Não foi possível buscar as reuniões roteadas, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const getRoutedMeetingsPagination = async () => {
    if (
      (startDateFilter !== '' && endDateFilter === '') ||
      (endDateFilter !== '' && startDateFilter === '')
    ) {
      return;
    }

    try {
      setIsLoading(true);

      let result: IGetRoutedMeetingsRequest;

      if (activePage === nextPageToFetch) {
        result = await hubApiCaptation.getRoutedMeetings(
          nextPageToFetch.toString(),
          tableIdFilter,
          startDateFilter,
          endDateFilter,
          statusFilter
        );

        const nextPage =
          (routedMeetings.length + result.allRoutedMeetings.length) / 12 + 1;

        setNextPageToFetch(nextPage);
        setTotalPages(result.totalPages);
        setRoutedMeetings(prev => [...prev, ...result.allRoutedMeetings]);
      }
    } catch (err) {
      toast.dark(
        'Não foi possível buscar as reuniões roteadas, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const getAllTables = async () => {
    try {
      const result = await hubApiCaptation.getAllTables();

      setTablesOptions(result);
    } catch (err) {
      toast.dark(
        'Não foi possível buscar as mesas, tente novamente em alguns minutos'
      );
    }
  };

  const activeFiltersCounter = () => {
    let activeFilters = 0;

    if (tableIdFilter !== '') activeFilters += 1;
    if (statusFilter !== '') activeFilters += 1;
    if (startDateFilter !== '' && endDateFilter !== '') activeFilters += 1;

    return activeFilters;
  };

  const pagination = activePage === 1 ? 0 : (activePage - 1) * 12;

  useEffect(() => {
    getAllTables();
  }, []);

  useEffect(() => {
    getRoutedMeetingsPagination();
  }, [activePage]);

  useEffect(() => {
    getRoutedMeetingsFilters();
    activeFiltersCounter();
  }, [tableIdFilter, statusFilter, endDateFilter, startDateFilter]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Reuniões Roteadas</MainPageTitle>

      <Flex w="100%" justifyContent="space-between" h="32px">
        <Flex ml="46px" mr={4} position="relative">
          {activeFiltersCounter() > 0 && (
            <Flex
              backgroundColor="red.500"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              position="absolute"
              top="-5px"
              right="-5px"
              px="5px"
            >
              <Text color="white" fontSize="x-small">
                {activeFiltersCounter()}
              </Text>
            </Flex>
          )}
          <BiFilterAlt size={28} />
        </Flex>

        <Flex w="55%" gap={2}>
          {showTablesFilter && (
            <Dropdown
              color="gray.500"
              placeholder="Selecione uma mesa"
              onChange={e => setTableIdFilter(e.target.value)}
            >
              {tablesOptions.map((item, index) => (
                <option key={`${index}-${item.name}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Dropdown>
          )}

          <Dropdown
            color="gray.500"
            placeholder="Status"
            onChange={e => setStatusFilter(e.target.value.toLowerCase())}
          >
            {statusOptions.map((item, index) => (
              <option key={`${index}-${item}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Dropdown>
        </Flex>

        <Flex
          w="100%"
          justifyContent="flex-end"
          gridGap={[0, 2]}
          mr={totalPages >= 1 ? '46px' : '0'}
          flexDirection={['column', 'row']}
        >
          <Text alignSelf="flex-end">Período:</Text>
          <DatePickerChackra
            style={{ width: 'fit-content', alignSelf: 'flex-end' }}
            handlePickDate={handleStartDate}
            value={startDateFilter}
          />

          <Text alignSelf="flex-end">até</Text>
          <DatePickerChackra
            style={{ width: 'fit-content', alignSelf: 'flex-end' }}
            handlePickDate={handleEndDate}
            value={endDateFilter}
          />
        </Flex>
      </Flex>

      <Flex w="100%" mt={2}>
        {activePage > 1 && (
          <Tooltip
            label="Página anterior"
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom-start"
          >
            <Flex
              w="50px"
              background="background.800"
              _hover={{ background: 'background.700' }}
              cursor="pointer"
              transition="300ms"
              justify="center"
              align="center"
              onClick={() => setActivePage(prev => prev - 1)}
            >
              <IoChevronBack size={20} color="white" />
            </Flex>
          </Tooltip>
        )}

        {activePage === 1 && (
          <Flex
            w="50px"
            background="background.800"
            justify="center"
            align="center"
          />
        )}

        <Flex w="100%" h="calc(100vh - 268px)" backgroundColor="background.600">
          {isLoading && (
            <Flex w="100%" alignItems="center" justify="center">
              <LoadingSpinner />
            </Flex>
          )}

          {!isLoading && routedMeetings.length > 0 && (
            <TableBase
              headData={['SH', 'AAI', 'Compra', 'PL', 'Status', 'Lead']}
              p="0"
              w="100%"
              height="fit-content"
              maxH="calc(100vh - 254px)"
              overflowY="auto"
              className="routedMeetingsTable"
            >
              {routedMeetings
                .slice(pagination, activePage * 12)
                .map((item, index) => (
                  <TableRow key={`${item.AAIName}-${index}`}>
                    <TableData
                      maxW="190px"
                      title={formatNameToCaptalize(item.SHName)}
                    >
                      {formatNameToCaptalize(item.SHName)}
                    </TableData>
                    <TableData
                      maxW="190px"
                      title={formatNameToCaptalize(item.AAIName)}
                    >
                      {formatNameToCaptalize(item.AAIName)}
                    </TableData>
                    <TableData>
                      {item.purchasedDate
                        ? formateDateAndTime(item.purchasedDate)
                        : '-'}
                    </TableData>
                    <TableData>
                      {item.PL ? formatValue(item.PL) : '-'}
                    </TableData>
                    <TableData>{item.status}</TableData>
                    <TableData
                      maxW="200px"
                      title={formatNameToCaptalize(item.clientName)}
                    >
                      {formatNameToCaptalize(item.clientName)}
                    </TableData>
                  </TableRow>
                ))}
            </TableBase>
          )}

          {!isLoading && routedMeetings.length === 0 && (
            <Flex width="100%" align="center" justify="center">
              <Text fontStyle="italic">Sem reuniões roteadas para exibir</Text>
            </Flex>
          )}
        </Flex>

        {activePage < totalPages && (
          <Tooltip
            label="Próxima página"
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom-end"
          >
            <Flex
              w="50px"
              background="background.800"
              justify="center"
              align="center"
              _hover={{ background: 'background.700' }}
              cursor="pointer"
              transition="300ms"
              onClick={() => setActivePage(prev => prev + 1)}
            >
              <IoChevronForward size={20} color="white" />
            </Flex>
          </Tooltip>
        )}

        {activePage === totalPages && (
          <Flex
            w="50px"
            background="background.800"
            justify="center"
            align="center"
          />
        )}
      </Flex>

      {routedMeetings.length > 0 && (
        <Flex w="100%" justify="center" align="center" h="40px" mt={3}>
          <Text>
            Página{' '}
            <Text as="span" color="white" fontWeight="bold">
              {activePage}
            </Text>{' '}
            de{' '}
            <Text as="span" color="white" fontWeight="bold">
              {totalPages === 0 ? 1 : totalPages}
            </Text>
          </Text>
        </Flex>
      )}
    </DefaultPageWrapper>
  );
};
