import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import {
  IInfosSH,
  ILeadTransfer,
} from 'pages/GenerationalVariables/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import { TableRowSh } from 'pages/GenerationalVariables/components/modules/TableRowSh';
import { TableLeadRow } from 'pages/GenerationalVariables/components/modules/TableLeadRow';
import { ContentTransferLeadsModal } from 'pages/GenerationalVariables/components/modules/ContentTransferLeadsModal';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { ContentUpdateFrequencyModal } from '../ContentUpdateFrequencyModal';

export const ManagementLeads: React.FC = () => {
  const [sh, setSh] = useState<IInfosSH[]>([]);
  const [discontinuedSh, setDiscontinuedSh] = useState<IInfosSH[]>([]);
  const [hiredSh, sethiredSh] = useState<IInfosSH[]>([]);
  const [leadsSh, setLeadsSh] = useState<LeadDetailsProps[]>([]);
  const [leadsShCopy, setLeadsShCopy] = useState<LeadDetailsProps[]>([]);
  const [isMainCheckBox, setIsMainCheckBox] = useState<boolean>(false);
  const [loadingUpdateFrequency, setLoadingUpdateFrequency] = useState(false);
  const [loadingTransferLead, setLoadingTransferLead] = useState(false);
  const [showModalTransferLead, setShowModalTransferLead] =
    useState<boolean>(false);
  const [shTitle, setShTitle] = useState('');
  const [shId, setShId] = useState<string>('');
  const [showModalUpdateFrequency, setShowModalUpdateFrequency] =
    useState<boolean>(false);
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [selectedLead, setSelectedLead] = useState({} as LeadDetailsProps);
  const [frequency, setFrequency] = useState('');
  const [
    getDiscontinuedEmployeesCheckbox,
    setGetDiscontinuedEmployeesCheckbox,
  ] = useState(false);
  const [loadingSHs, setLoadingSHs] = useState(false);
  const handleFilterFrequency = (frequencyProp: string) => {
    setFrequency(frequencyProp);
    const leadsFiltered: LeadDetailsProps[] = leadsSh.filter(leadSh => {
      if (leadSh.frequency === frequencyProp) {
        return leadSh;
      }
      return false;
    });

    if (leadsFiltered.length > 0) {
      setLeadsShCopy(leadsFiltered);
    }

    if (frequencyProp === '0') {
      setLeadsShCopy(leadsSh);
    }
  };

  const handleChangeFrequency = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedLead({ ...selectedLead, frequency: e.target.value });
  };

  const handleGetDiscontinuedEmployees = () => {
    setGetDiscontinuedEmployeesCheckbox(!getDiscontinuedEmployeesCheckbox);
    if (!getDiscontinuedEmployeesCheckbox === true) {
      setSh([...hiredSh, ...discontinuedSh]);
    } else {
      setSh(hiredSh);
    }
  };

  const handleUpdateFrequency = async () => {
    setLoadingUpdateFrequency(true);
    const data = {
      id: selectedLead.id,
      frequency: selectedLead.frequency,
    };

    try {
      const response: LeadDetailsProps = await hubApiCaptation.updateFrequency(
        data
      );

      const leadShFiltered = leadsSh.map(leadSh => {
        if (leadSh.id === response.id) {
          return response;
        }
        return leadSh;
      });

      setLeadsSh(leadShFiltered);
      setLeadsShCopy(leadShFiltered);
      setSelectedLead(response);
      handleShowModalUpdateFrequency();

      toast.dark('Frequência atualizada com sucesso!');
    } catch (err) {
      errorHandler(err);
    }
    setLoadingUpdateFrequency(false);
  };

  const handleShowModalUpdateFrequency = () => {
    setShowModalUpdateFrequency(!showModalUpdateFrequency);
  };

  const handleSelectLead = (lead: LeadDetailsProps) => {
    setSelectedLead(lead);
    setShowModalUpdateFrequency(!showModalUpdateFrequency);
  };

  const handleGetLeadsSh = async (id: string) => {
    setLoadingLeads(true);
    try {
      setSh(
        sh.filter(item => {
          const newSh = item;
          if (id === item.id) {
            newSh.isSelected = true;
          } else {
            newSh.isSelected = false;
          }
          return newSh;
        })
      );

      const response = await hubApiCaptation.getLeadsShInfos(id);

      const result = response.map((item: LeadDetailsProps) => {
        return Object.assign(item, { isChecked: false });
      });

      sh.forEach(s => {
        if (s.isSelected) {
          setShTitle(s.name);
        }
      });

      setIsMainCheckBox(false);

      setLeadsSh(result);
      setLeadsShCopy(result);
      setFrequency('');
    } catch (err) {
      errorHandler(err);
    }
    setLoadingLeads(false);
  };

  const handleCheckBoxMain = () => {
    const checkBoxMain = !isMainCheckBox;
    setIsMainCheckBox(checkBoxMain);

    for (let i = 0; i < leadsSh.length; i++) {
      leadsSh[i].isChecked = checkBoxMain;
    }
  };

  const handleCheckLead = (lead: LeadDetailsProps) => {
    const findLeads = leadsSh.filter(leadSh => {
      const beforeLead = leadSh;

      if (leadSh.id === lead.id) {
        beforeLead.isChecked = !beforeLead.isChecked;
      }

      if (beforeLead.isChecked === false) {
        setIsMainCheckBox(() => false);
      }

      return beforeLead;
    });

    const isAllChecked = findLeads.every(
      findLead => findLead.isChecked === true
    );
    if (isAllChecked === true) {
      setIsMainCheckBox(() => true);
    }

    setLeadsSh(findLeads);
  };

  const handleGetShInfos = async () => {
    setLoadingSHs(true);
    try {
      const response = await hubApiCaptation.getShInfos();

      let shs: IInfosSH[] = response.map((item: IInfosSH) => {
        return Object.assign(item, { isSelected: false });
      });

      shs.sort((item: IInfosSH) => {
        let result = -1;

        if (item.status === 'active') {
          result = 1;
        } else {
          result = 0;
        }

        return result;
      });

      const discontinuedShsArray: IInfosSH[] = [];

      shs = shs.filter(item => {
        if (!['not-hired', 'discontinued'].includes(item.statusemployee)) {
          return sh;
        }
        discontinuedShsArray.push(item);
        return false;
      });

      setDiscontinuedSh(discontinuedShsArray);

      sethiredSh([...shs]);
      setSh([...shs]);
    } catch (err) {
      errorHandler(err);
    }

    setLoadingSHs(false);
  };

  const handleTransferLeads = async () => {
    setLoadingTransferLead(true);

    const leadsWithIsCheckedTrue = {} as ILeadTransfer;
    const leadsTransfer: string[] = [];

    leadsSh.forEach(leadSh => {
      if (leadSh.isChecked === true) {
        return leadsTransfer.push(leadSh.id);
      }
    });

    leadsWithIsCheckedTrue.leads = leadsTransfer;

    if (!shId) {
      setLoadingTransferLead(false);
      setLoadingLeads(false);
      return toast.dark('Selecione o sh para quem irá transferir os leads!');
    }
    try {
      await hubApiCaptation.patchTransferLeads(shId, leadsWithIsCheckedTrue);

      setLoadingLeads(false);

      const result: LeadDetailsProps[] = [];

      leadsShCopy.forEach(leadShCopy => {
        if (leadShCopy.isChecked === false) {
          result.push(leadShCopy);
        }
      });

      setLeadsShCopy(result);
      setLoadingTransferLead(false);
      toast.dark('Leads transferidos com sucesso!');
    } catch (err) {
      toast.dark('Erro ao transferir os leads!');
      setLoadingTransferLead(false);
      setLoadingLeads(false);
    }
    setShowModalTransferLead(!showModalTransferLead);
    setLoadingTransferLead(false);
  };

  useEffect(() => {
    handleGetShInfos();
  }, []);
  return (
    <PageWrapper align="center" pt="7" flexDir="column">
      <Flex>
        <MainPageTitle>Gerenciamento de Leads</MainPageTitle>
        <BackPageButton />
      </Flex>

      <Flex
        w="100%"
        maxW={['95vh', '1280px']}
        gridGap={7}
        pos="inherit"
        flexDirection={['column', 'row']}
      >
        <Flex flexDir="column" align="center" w={['100%', '50%']}>
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Text fontSize="md" mb={1} color="white" fontWeight="semibold">
              SHs
            </Text>
            <Flex alignItems="flex-end">
              {!loadingSHs && (
                <>
                  <Checkbox
                    mb="1"
                    mr={1}
                    isChecked={getDiscontinuedEmployeesCheckbox}
                    onChange={handleGetDiscontinuedEmployees}
                  />
                  <Text fontSize="sm">Incluir descontinuados</Text>
                </>
              )}
            </Flex>
          </Flex>

          {!loadingSHs && (
            <TableBase
              headData={['Nome', 'leads OK', 'leads Total']}
              w="100%"
              maxH={['250px', 'calc(100vh - 276px)']}
              overflowY="auto"
            >
              {sh.map(item => (
                <TableRowSh
                  key={`${item.id}`}
                  sh={item}
                  handleGetLeadsSh={handleGetLeadsSh}
                />
              ))}
            </TableBase>
          )}

          {loadingSHs && <LoadingSpinner />}
        </Flex>

        <Flex>
          <Divider orientation="vertical" />
        </Flex>

        <Flex align="flex-end" flexDir="column" w="60%">
          <Flex gridGap={3} mb="2" w="100%">
            <Flex w="90%">
              <Text
                fontSize="md"
                mb="4"
                maxW="240px"
                isTruncated
                color="white"
                fontWeight="semibold"
              >
                Leads do(a) SH{' '}
                <Text as="span">{formatNameToCaptalize(shTitle)}</Text>
              </Text>
            </Flex>
            <Dropdown
              // placeholder="Frequência"
              h="7"
              width="150px"
              value={frequency}
              defaultValue=""
              onChange={e => handleFilterFrequency(e.target.value)}
            >
              <option value="" disabled>
                Frequência
              </option>
              <option value="0">Voltar a todos</option>
              <option value="1">Frequência - 1</option>
              <option value="2">Frequência - 2</option>
              <option value="3">Frequência - 3</option>
              <option value="4">Frequência - 4</option>
              <option value="5">Frequência - 5</option>
              <option value="6">Frequência - 6</option>
            </Dropdown>
          </Flex>

          {loadingLeads ? (
            <Flex
              align="center"
              justify="center"
              w="100%"
              h="100%"
              maxH={['250px', 'calc(100vh - 320px)']}
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <>
              <TableBase
                headData={[
                  'Nome',
                  'status',
                  'PL',
                  'Freq/Reunião',
                  'Freq. ideal',
                ]}
                p="0"
                w="100%"
                isMainCheckbox
                isMainChecked={isMainCheckBox}
                toggleMainCheckbox={handleCheckBoxMain}
                mt="3px"
                h="full"
                maxH={['250px', 'calc(100vh - 276px)']}
                overflow="auto"
                mb="2"
              >
                {leadsShCopy?.map(item => (
                  <TableLeadRow
                    key={`${item.id}`}
                    leadSh={item}
                    handleCheckLead={handleCheckLead}
                    handleSelectLead={handleSelectLead}
                  />
                ))}
              </TableBase>
            </>
          )}

          <PrimaryButton
            onClick={() => setShowModalTransferLead(!showModalTransferLead)}
          >
            Transferir
          </PrimaryButton>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showModalTransferLead}
        onClose={() => setShowModalTransferLead(!showModalTransferLead)}
      >
        <ContentTransferLeadsModal
          sh={sh}
          setShId={setShId}
          setShowModalTransferLead={setShowModalTransferLead}
          showModalTransferLead={showModalTransferLead}
          handleTransferLeads={handleTransferLeads}
          loadingTransferLead={loadingTransferLead}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showModalUpdateFrequency}
        onClose={() => setShowModalUpdateFrequency(!showModalUpdateFrequency)}
      >
        <ContentUpdateFrequencyModal
          handleUpdateFrequency={handleUpdateFrequency}
          handleChangeFrequency={handleChangeFrequency}
          selectedLead={selectedLead}
          handleShowModalUpdateFrequency={handleShowModalUpdateFrequency}
          loadingUpdateFrequency={loadingUpdateFrequency}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
