import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, useDisclosure, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import {
  DefaultPageWrapper,
  ModalWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import { OrderInfoLossClients } from 'pages/GenerationalVariables/components/modules/OrderInfoLossClients';
import { TransferOrderCardLeftClients } from 'pages/GenerationalVariables/components/modules/TransferOrderCardLeftClients';
import { leftClientsManager } from 'pages/GenerationalVariables/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { Dropdown } from 'components/elements/others';

export const LostClientsManager: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [filter, setFilter] = useState('');
  const [allLeftClients, setAllLeftClients] = useState<leftClientsManager[]>(
    []
  );
  const [allLeftClientsFiltered, setAllLeftClientsFiltered] = useState<
    leftClientsManager[]
  >([]);
  const [selectedLeftClient, setSelectedLeftClient] = useState(
    {} as leftClientsManager
  );

  const checkManagerLeftClients = async () => {
    const feedBackClient = {
      id: selectedLeftClient.id,
      status: 'confirmed deletion',
    };

    try {
      const response: leftClientsManager = await hubApiUser.patchFeedBackClient(
        feedBackClient
      );

      setAllLeftClients(
        allLeftClients.filter(allLeftClient => {
          const result = allLeftClient;

          if (allLeftClient.id === response.id) {
            result.status = response.status;
          }

          return result;
        })
      );

      setAllLeftClientsFiltered(
        allLeftClientsFiltered.filter(allLeftClientsFiltere => {
          const result = allLeftClientsFiltere;

          if (allLeftClientsFiltere.id === response.id) {
            result.status = response.status;
          }

          return result;
        })
      );

      setSelectedLeftClient({
        ...selectedLeftClient,
        status: response.status,
      });

      onClose();
      toast.dark('Dados salvos com sucesso!');
    } catch (err) {
      toast.dark('Ocorreu um erro ao salvar os dados, tente novamente');
    }
  };

  const getAllLeftClients = async () => {
    try {
      const response: leftClientsManager[] =
        await hubApiUser.getAllLeftClients();

      setAllLeftClientsFiltered(response);
      setAllLeftClients(response);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar os dados');
    }
  };

  const filterLeftClients = () => {
    if (filter === '') {
      return setAllLeftClientsFiltered(allLeftClients);
    }

    if (filter === 'pendente') {
      setAllLeftClientsFiltered(
        allLeftClients.filter(leftClient => {
          if (
            leftClient.status !== 'confirmed deletion' &&
            leftClient.inPendency
          ) {
            return leftClient;
          }
        })
      );
    }

    if (filter === 'ag assessor') {
      setAllLeftClientsFiltered(
        allLeftClients.filter(leftClient => {
          if (
            leftClient.inPendency &&
            leftClient.status === 'confirmed deletion'
          ) {
            return leftClient;
          }
        })
      );
    }

    if (filter === 'ag gestor') {
      setAllLeftClientsFiltered(
        allLeftClients.filter(leftClient => {
          if (
            !leftClient.inPendency &&
            leftClient.status !== 'confirmed deletion'
          ) {
            return leftClient;
          }
        })
      );
    }

    if (filter === 'saída confirmada') {
      setAllLeftClientsFiltered(
        allLeftClients.filter(leftClient => {
          if (
            !leftClient.inPendency &&
            leftClient.status === 'confirmed deletion'
          ) {
            return leftClient;
          }
        })
      );
    }
  };

  useEffect(() => {
    getAllLeftClients();
  }, []);

  useEffect(() => {
    filterLeftClients();
  }, [filter]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle align="center">Saída de clientes</MainPageTitle>

      <Flex w="100%" maxW="1080px" justify="flex-end">
        <Dropdown
          placeholder="Todos"
          w="200px"
          mb="3"
          onChange={e => setFilter(e.target.value)}
        >
          <option value="pendente">Pendentes</option>
          <option value="ag assessor">Ag. feedback do assessor</option>
          <option value="ag gestor">Ag. confirmação do gestor</option>
          <option value="saída confirmada">Saída confirmada</option>
        </Dropdown>
      </Flex>

      <TransferOrdersCardsWrapper>
        {allLeftClientsFiltered.length < 1 && (
          <Text
            w="100%"
            mt="20%"
            fontSize="lg"
            textAlign="center"
            fontStyle="italic"
          >
            Sem resultados
          </Text>
        )}
        {allLeftClientsFiltered.map(allLeftClient => (
          <TransferOrderCardLeftClients
            key={allLeftClient.id}
            mainText={allLeftClient.name}
            secondaryText={allLeftClient.employeeName}
            allLeftClient={allLeftClient}
            onClick={() => {
              onOpen();
              setSelectedLeftClient(allLeftClient);
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfoLossClients
          onClose={onClose}
          selectedLeftClient={selectedLeftClient}
          checkManagerLeftClients={checkManagerLeftClients}
        />
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
