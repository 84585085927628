import React, { useEffect, useState } from 'react';
import { PrimaryButton, BackPageButton } from 'components/elements/buttons';
import ModalWrapper from 'pages/User/UsersClients/components/ModalWrapper';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import CardCampaign from '../../components/modules/campaign/CardCampaign';
import ModalCreateCampaign from '../../components/modules/campaign/ModalCreateCampaign';

import { Container } from './styles';

export const Campaign = () => {
  const [showModal, setShowModal] = useState(false);
  const [listCardCampaign, setListCardCampaign] = useState([]);
  const [handleUpdate, setHandleUpdate] = useState(true);

  useEffect(() => {
    hubApiMarketing.listAllCampaign().then(response => {
      setListCardCampaign(response);
    });
  }, [handleUpdate]);

  return (
    <Container>
      <div className="title-and-dropdown">
        <div>
          <BackPageButton />
        </div>
        <h2>Visualizador de campanhas</h2>
        <div className="dropdown">
          <PrimaryButton onClick={() => setShowModal(true)}>
            Criar campanha
          </PrimaryButton>
        </div>
      </div>

      <div className="cards">
        {listCardCampaign.map((card, index) => (
          <CardCampaign
            card={card}
            key={index}
            handleUpdate={handleUpdate}
            setHandleUpdate={setHandleUpdate}
          />
        ))}
      </div>

      {showModal === true && (
        <ModalWrapper>
          <ModalCreateCampaign handleShowModal={setShowModal} />
        </ModalWrapper>
      )}
    </Container>
  );
};
