import React from 'react';
import {
  Flex,
  FlexProps,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCaptationMeetingsContext } from 'hooks';

import { SecondaryButton } from 'components/elements/buttons';

import { ContentWarperSearchLeads } from 'pages/Captation/components/elements/wrappers/ContentWarperSearchLeads';
import { ContentSearchLeads } from 'pages/Captation/components/elements/others/ContectSearchLeads';

import { IBaseLead, IBaseMeeting } from 'pages/Captation/interfaces';
import { Warning } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { MenuMobile } from './MenuMobile';
import { SearchLeadAAI } from '../../SearchLeadAAI';

interface ComponentProps extends FlexProps {
  titleSelected: string;
  handleSelectLeadType: (
    leadType:
      | 'leadstable'
      | 'leadspublic'
      | 'leadsmanual'
      | 'leadspj'
      | 'pipe'
      | 'declined'
      | 'leadspurchased'
      | 'pool',
    title: string,
    type: string
  ) => void;
  handleSelectedArrayLeads: (leads: any[]) => void;
  handleShowMeeting: (lead: IBaseMeeting) => void;
  handleShowLead: (lead: IBaseLead) => void;
  leadSearched: any[];
  handleResults: (showResus: boolean) => void;
  showResults: boolean;
  totalNumOfLeads: number;
}

export const ChooseLeadsMenu: React.FC<ComponentProps> = ({
  titleSelected,
  handleSelectLeadType,
  handleSelectedArrayLeads,
  leadSearched,
  showResults,
  handleResults,
  handleShowMeeting,
  handleShowLead,
  totalNumOfLeads,
  ...rest
}) => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const { pipe } = useCaptationMeetingsContext();
  const user = useSelector((state: IReduxState) => state.user.profile) as {
    id: string;
    position: string;
    rules: { area_cod: string; permission_cod: string }[];
  };

  // O MANAGER SO PODERAR SER O RAPHA E O THOMAS E O DESENVOLVEDOR
  const isManager =
    user.id === 'db7b29e2-e5ab-4c8f-9299-c3346ac22b89' ||
    user.id === '31c6cc7b-3c4a-4b53-8f4d-f2435e16c321' ||
    user.id === '7e2a8234-49d8-490a-b3b5-7837f1a69117';

  return (
    <Flex alignItems="center" justifyContent="space-between" {...rest}>
      <Flex
        gridGap="2"
        position={['relative', 'static']}
        width={['100%', 'auto']}
        justifyContent={['space-between', 'unset']}
      >
        {totalNumOfLeads >= 100 && (
          <Tooltip
            hasArrow
            label={
              totalNumOfLeads >= 130
                ? `Você atingiu o limite de leads no funil de captação (130 leads). Com isto não será possível avançar os leads das abas leads lista, leads minerados, leads manuais e leads declinados. Para voltar a operar sem restrições finalize alguns leads do funil, tentando sempre manter o número de leads abaixo de 100`
                : `Você tem ${totalNumOfLeads} leads no seu funil de captação. Para melhor gerenciar e organizar suas atividades, recomendamos que tenha menos de 100. Em breve vamos restringir a inserção de novos leads no funil caso você tenha 130 leads ou mais.`
            }
            placement="top"
            color="white"
            bg="background.200"
          >
            <Flex ml="-40px">
              <Warning
                size={32}
                color={totalNumOfLeads >= 130 ? '#e94040' : '#f2c240'}
                weight="fill"
              />
            </Flex>
          </Tooltip>
        )}
        <SecondaryButton
          width="100vw"
          maxWidth={['100px', '200px']}
          transition="background 0.1s ease-in"
          background={
            titleSelected === 'Pipe' ? 'background.200' : 'background.600'
          }
          onClick={() => handleSelectLeadType('pipe', 'Pipe', 'pipe')}
          fontWeight="normal"
          color={titleSelected === 'Pipe' ? 'white' : 'inherit'}
          position="relative"
          mr={['none', 'auto']}
        >
          {pipe.length > 0 && (
            <Flex
              backgroundColor="red.500"
              justifyContent="center"
              alignItems="center"
              borderRadius="full"
              position="absolute"
              top="-5px"
              right="-5px"
              padding="1px"
              px="6px"
            >
              <Text color="white" fontSize="sm">
                {pipe.length}
              </Text>
            </Flex>
          )}
          R. Atrasadas
        </SecondaryButton>

        <Flex w={['200px', '248px']} pos="relative">
          <SearchLeadAAI
            handleSelectedArrayLeads={handleSelectedArrayLeads}
            handleResults={handleResults}
          />
          {showResults && (
            <ContentWarperSearchLeads>
              {leadSearched.map(item => (
                <ContentSearchLeads
                  key={`${item.id}-${item.phone}-${item.email}`}
                  name={item.name}
                  emails={item.email}
                  phone={item.phone}
                  onClick={() => {
                    item.meetingId
                      ? handleShowMeeting(item)
                      : handleShowLead(item);
                    handleResults(false);
                  }}
                />
              ))}
            </ContentWarperSearchLeads>
          )}
        </Flex>
        {isMobile && (
          <MenuMobile
            handleSelectLeadType={handleSelectLeadType}
            titleSelected={titleSelected}
          />
        )}
      </Flex>
      {!isMobile && (
        <Flex gridGap="1.5">
          {/* <SecondaryButton */}
          {/*  transition="background 0.1s ease-in" */}
          {/*  background={ */}
          {/*    titleSelected === 'Leads Comprados' */}
          {/*      ? 'background.200' */}
          {/*      : 'background.600' */}
          {/*  } */}
          {/*  onClick={() => */}
          {/*    handleSelectLeadType( */}
          {/*      'leadspurchased', */}
          {/*      'Leads Comprados', */}
          {/*      'LeadsPurchased' */}
          {/*    ) */}
          {/*  } */}
          {/*  fontWeight="normal" */}
          {/*  color={titleSelected === 'Leads Comprados' ? 'white' : 'inherit'} */}
          {/* > */}
          {/*  Comprados */}
          {/* </SecondaryButton> */}

          <SecondaryButton
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Pool MI' ? 'background.200' : 'background.600'
            }
            onClick={() => handleSelectLeadType('pool', 'Pool MI', 'pool')}
            fontWeight="normal"
            color={titleSelected === 'Pool MI' ? 'white' : 'inherit'}
          >
            Pool MI
          </SecondaryButton>

          {/* <SecondaryButton */}
          {/*  transition="background 0.1s ease-in" */}
          {/*  background={ */}
          {/*    titleSelected === 'Leads Lista' */}
          {/*      ? 'background.200' */}
          {/*      : 'background.600' */}
          {/*  } */}
          {/*  onClick={() => */}
          {/*    handleSelectLeadType('leadstable', 'Leads Lista', 'LeadsList') */}
          {/*  } */}
          {/*  fontWeight="normal" */}
          {/*  color={titleSelected === 'Leads Lista' ? 'white' : 'inherit'} */}
          {/* > */}
          {/*  Lista */}
          {/* </SecondaryButton> */}

          {/* <SecondaryButton */}
          {/*  transition="background 0.1s ease-in" */}
          {/*  background={ */}
          {/*    titleSelected === 'Leads Mesa' */}
          {/*      ? 'background.200' */}
          {/*      : 'background.600' */}
          {/*  } */}
          {/*  onClick={() => */}
          {/*    handleSelectLeadType('leadstable', 'Leads Mesa', 'LeadsIndirect') */}
          {/*  } */}
          {/*  fontWeight="normal" */}
          {/*  color={titleSelected === 'Leads Mesa' ? 'white' : 'inherit'} */}
          {/* > */}
          {/*  Minerados */}
          {/* </SecondaryButton> */}

          <SecondaryButton
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads Manuais'
                ? 'background.200'
                : 'background.600'
            }
            onClick={() =>
              handleSelectLeadType(
                'leadsmanual',
                'Leads Manuais',
                'LeadsIndirect'
              )
            }
            fontWeight="normal"
            color={titleSelected === 'Leads Manuais' ? 'white' : 'inherit'}
          >
            Leads Manuais
          </SecondaryButton>

          <SecondaryButton
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads PJ' ? 'background.200' : 'background.600'
            }
            onClick={() =>
              handleSelectLeadType('leadspj', 'Leads PJ', 'DirectLeads')
            }
            fontWeight="normal"
            color={titleSelected === 'Leads PJ' ? 'white' : 'inherit'}
          >
            Leads PJ
          </SecondaryButton>

          <SecondaryButton
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads declinados'
                ? 'background.200'
                : 'background.600'
            }
            onClick={() =>
              handleSelectLeadType('declined', 'Leads declinados', 'declined')
            }
            fontWeight="normal"
            color={titleSelected === 'Leads declinados' ? 'white' : 'inherit'}
          >
            Leads Declinados
          </SecondaryButton>
        </Flex>
      )}
    </Flex>
  );
};
