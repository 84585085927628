import React, { ChangeEvent } from 'react';
import { Input, Flex, Text } from '@chakra-ui/react';

import DataPickerContent from './styles';

interface ComponentProps {
  label?: string;
  handlePickDate: (event: ChangeEvent<HTMLInputElement>) => void;
  full?: boolean;
  fullColum?: boolean;
  directionRow?: boolean;
  style?: any;
  type?: string;
  value?: string;
  name?: string;
  fontSize?: string;
  mb?: string | number;
}

export const DatePickerChackra: React.FC<ComponentProps> = ({
  label,
  handlePickDate,
  full,
  fullColum,
  directionRow,
  type = 'date',
  style,
  value = new Date().toString(),
  name,
  fontSize = 'small',
  mb = 0,
  ...rest
}) => {
  const alterDate = (date: string) => {
    return date.split('T')[0];
  };

  return (
    <DataPickerContent style={style}>
      <Flex
        flexDir={directionRow ? 'row' : 'column'}
        alignItems={directionRow ? 'baseline' : 'start'}
        gap={directionRow ? 2 : 0}
      >
        <Text color="rgba(70, 70, 70, 1)" fontSize={fontSize} mb="6px">
          {label}
        </Text>
        <Input
          type={type}
          {...rest}
          onChange={handlePickDate}
          value={alterDate(value)}
          name={name}
          color="rgba(70, 70, 70, 1)"
          size="sm"
          margin="0"
          bg="white"
          border="1px solid"
          focusBorderColor="primary.600"
          _hover={{ borderColor: 'primary.800' }}
          _placeholder={{ color: 'gray.400' }}
          borderColor="#1d63ddb2"
          mb={mb}
        />
      </Flex>
    </DataPickerContent>
  );
};
