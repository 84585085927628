import React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { Divider } from 'components/elements/others';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { SecondaryButton } from 'components/elements/buttons';
import history from 'services/history';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { IClients } from 'pages/manager/interfaces';

interface IComponentProps {
  client: IClients;
  leadIndex?: number;
  termSearched?: string;
}

export const SearchClientsCard: React.FC<IComponentProps> = ({
  client,
  termSearched,
}) => {
  const sendToClientPanel = () => {
    window.sessionStorage.setItem('searchClientContext', client.btgAccount);
    window.sessionStorage.setItem(
      'searchClientLastTerm',
      termSearched as string
    );

    history.push(`/user/users_clients/new/${client.btgAccount}`);
  };

  return (
    <DefaultCardWrapper flexDir="column" p="3.5">
      <Text color="white" fontWeight="bold" fontSize="md">
        {client?.name}
      </Text>

      <Divider orientation="horizontal" my="2" />

      <Stack spacing={1}>
        {client.phone && (
          <Text color="white" fontSize="smaller">
            Telefone: <Text as="strong">{client.phone}</Text>
          </Text>
        )}

        {client.email && (
          <Text color="white" fontSize="smaller">
            E-mail: <Text as="strong">{client.email.toLowerCase()}</Text>
          </Text>
        )}

        {client.employeeName && client.employeeName.trim() !== 'Erro' && (
          <Text color="white" fontSize="smaller">
            Responsável:{' '}
            <Text as="strong">
              {formatNameToCaptalize(client.employeeName)}
            </Text>
          </Text>
        )}

        <Flex justifyContent="flex-end" align="center">
          <SecondaryButton
            w="fit-content"
            onClick={sendToClientPanel}
            size="xs"
          >
            Ver painel do cliente
          </SecondaryButton>
        </Flex>
      </Stack>
    </DefaultCardWrapper>
  );
};
