export class CurrencyUtils {
  static format(value: string | number): string {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number.isNaN(Number(value)) ? 0 : Number(value));
  }

  static formatValueFromInput(value: string): number {
    const valueWithoutPrefix = value.replace('R$', '');
    const valueFormatted = valueWithoutPrefix
      .replace(/\./g, '')
      .replace(',', '.');

    return Number(valueFormatted);
  }
}
