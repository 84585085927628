import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const MainPageTitle: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      fontSize={{ base: 'md', lg: 'lg', xl: 'xl' }}
      mb="6"
      color="rgba(70, 70, 70, 1)"
      fontWeight="semibold"
      align="center"
      w="100%"
      {...rest}
    >
      {children}
    </Text>
  );
};
