import { FormEvent, useCallback, useEffect, useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import {
  IFrequency,
  IFrequencyToUpdate,
} from 'pages/GenerationalVariables/interfaces';

interface OrderInfoProps {
  frequency?: IFrequencyToUpdate;
  getFrequency: () => void;
  onClose: () => void;
}

export const CreateOrEditFrequency: React.FC<OrderInfoProps> = ({
  getFrequency,
  frequency,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formInfos, setFormInfos] = useState<IFrequency>({
    name: '',
    seq: '',
    task: '1',
    points: '1',
    status: 'ativo',
  });

  useEffect(() => {
    if (frequency) {
      isEditFrequency();
    }
  }, []);

  const isEditFrequency = async () => {
    if (frequency) {
      setFormInfos(frequency);
    }
  };

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    if (frequency) {
      try {
        await hubApiCaptation.patchFrequency(formInfos);
        getFrequency();
        setIsLoading(false);
        onClose();
        toast.dark(`frequência atualizada com sucesso`);
      } catch (err) {
        errorHandler(err);
        setIsLoading(false);
        onClose();
      }
    } else {
      try {
        await hubApiCaptation.postFrequency(formInfos);
        getFrequency();
        setIsLoading(false);
        onClose();
        toast.dark(`frequência criada com sucesso`);
      } catch (err) {
        errorHandler(err);
        onClose();
        setIsLoading(false);
      }
    }
  };

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormInfos({
        ...formInfos,
        [e.target.name]: e.target.value,
      });
    },
    [formInfos]
  );

  return (
    <BaseModalFormCard
      isModal
      title={frequency ? 'Atualizar frequência' : 'Criar frequência'}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText={
        frequency ? 'Atualizar frequência' : 'Criar frequência'
      }
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['90vw', '510px']}
    >
      <Stack spacing="2">
        <InputWithLabel
          label="Nome da frequencia"
          value={formInfos.name}
          name="name"
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Prioridade"
          type="number"
          value={formInfos.seq}
          name="seq"
          onChange={handleChangeValue}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
