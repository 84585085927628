const navbarPages = [
  { title: 'Home', path: '/v2/home' },
  { title: 'Minha Agenda', path: '/' },
  { title: 'Clientes', path: '/v2/clients' },
  { title: 'Produtos', path: '/products', permission: 'userOAProd' },
  { title: 'Ilha Forte', path: '/ip', permission: 'userOAIP' },
  { title: 'Câmbio', path: '/exchange', permission: 'userOACamb' },
];

const myClientsPages = [
  {
    title: 'Clientes',
    path: '/v2/clients',
    permission: '',
  },
  {
    title: 'Ofertas',
    path: '/offers',
    permission: '',
  },
];

const operationalPages = [
  { title: 'Processos', path: '/contents', permission: '' },
  { title: 'Marketing', path: '/marketing', permission: 'userEUMarketing' },
  // { title: 'R.H', path: '/rh', permission: 'userEURH' },
  {
    title: 'Comunicados HUB',
    path: '/mi/communicated',
    permission: 'userEURH',
  },
  // { title: 'Suporte', path: '/ti', permission: 'userEUTI' },
  { title: 'Comissionamento', path: '/remuneration', permission: '' },
  { title: 'Ordens Enviadas', path: '/advanced_orders', permission: '' },
];

const userPages = [
  { title: 'Painel de Controle', path: '/user/control_panel', permission: '' },
  { title: 'Meu Perfil', path: '/user/profile', permission: '' },
  // { title: 'Notificações', path: '/user/notifications/all', permission: '' },
];

const captationPagesDefault = [
  {
    title: 'Reuniões',
    path: '/v2/captation/meetings',
    permission: 'userOACap',
  },
  {
    title: 'Prospecção',
    path: '/v2/captation/prospect/leads',
    permission: '',
  },
];

const captationPagesOptions = {
  sh: [
    {
      title: 'Sales Hunter - Leads',
      path: '/v2/captation/sh/pool',
      permission: '',
    },
  ],
  pool: [
    {
      title: 'Pool de Assessoria',
      path: '/captation/leads_aai',
      permission: '',
    },
    // {
    //   title: 'Resultados',
    //   path: '/captation/results',
    //   permission: 'userOACap',
    // },
  ],
};

export {
  navbarPages,
  operationalPages,
  userPages,
  captationPagesDefault,
  captationPagesOptions,
  myClientsPages,
};
