import { Flex, SimpleGrid } from '@chakra-ui/react';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const ClientsSkeletonLoading = (): JSX.Element => {
  const skeletonsLength = 15;

  return (
    <PageWrapper gap={12} paddingTop="2rem">
      <Flex gap={5} justifyContent="space-between">
        <SkeletonBase h="30px" w="20%" borderRadius={3} />
        <SkeletonBase h="30px" w="20%" borderRadius={3} />
        <SkeletonBase h="30px" w="20%" borderRadius={3} />
      </Flex>

      <SimpleGrid
        columns={[1, 2, 3, 4, null, 5]}
        gap={3}
        maxH="calc(100vh - 250px)"
        overflowY="auto"
        className="thinScrollbar scrollContainer"
      >
        {Array.from({ length: skeletonsLength }).map((_, index) => (
          <SkeletonBase key={index} h="260px" borderRadius={3} />
        ))}
      </SimpleGrid>
    </PageWrapper>
  );
};
