import { FormEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box, Text, Switch } from '@chakra-ui/react';

import { PrimaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { IIpcaAndFrequency } from 'pages/GenerationalVariables/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { InformationAndBox } from '../../elements/others/InformationAndBox';
import { TitleWithBorder } from '../../elements/others/TitleWithBorder';

export const IPCASH: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [ipcaShAndFrequency, setIpcahAndFrequency] =
    useState<IIpcaAndFrequency>({} as IIpcaAndFrequency);

  const getIPCASH = async () => {
    try {
      setLoading(true);
      const response = await hubApiCaptation.getControlSh();
      setIpcahAndFrequency(response);
      setLoading(false);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar as informações');
      setLoading(false);
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIpcahAndFrequency({
      ...ipcaShAndFrequency,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateValues = useCallback(
    async (e: FormEvent) => {
      setLoading(true);
      e.preventDefault();

      try {
        const response = await hubApiCaptation.pathControlSh(
          ipcaShAndFrequency
        );
        setIpcahAndFrequency(response);
        toast.dark('Dados Atualizados com sucesso!');
      } catch (err) {
        toast.dark('Erro ao atualizar os dados');
      }
      setLoading(false);
    },
    [ipcaShAndFrequency, loading]
  );

  const handleStopFrequency = async () => {
    try {
      const response = await hubApiCaptation.pathControlSh({
        ...ipcaShAndFrequency,
        stopFrequency: !ipcaShAndFrequency.stopFrequency,
      });
      setIpcahAndFrequency(response);
      if (!ipcaShAndFrequency.stopFrequency === true) {
        toast.dark('Sucesso ao parar a frequência!');
      } else {
        toast.dark('Sucesso ao ligar a frequência!');
      }
    } catch (err) {
      toast.dark('Erro ao parar a frequência.');
    }
  };

  useEffect(() => {
    getIPCASH();
  }, []);

  if (loading) {
    return (
      <Flex align="center" justify="center" w="20%" h="50vh">
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex flexDir="column">
      <Flex flexDir="column" h="100%" justify="space-between">
        <Box w="220px">
          <TitleWithBorder label="IPCA SH" />

          <InformationAndBox
            label="Pontuação Reunião Confirmada"
            value={`${ipcaShAndFrequency.pointsMeetingConfirmed}`}
            name="pointsMeetingConfirmed"
            handleChangeValue={handleChangeValue}
            isPorcent
          />

          <InformationAndBox
            label="Penalização RNA"
            value={`${ipcaShAndFrequency.penaltyRNA}`}
            name="penaltyRNA"
            isPorcent
            handleChangeValue={handleChangeValue}
          />

          <InformationAndBox
            label="Tarefa não feita"
            value={`${ipcaShAndFrequency.taskDont}`}
            handleChangeValue={handleChangeValue}
            name="taskDont"
          />

          <InformationAndBox
            label="Lead Min"
            value={`${ipcaShAndFrequency.minLeads}`}
            handleChangeValue={handleChangeValue}
            name="minLeads"
          />

          <InformationAndBox
            label="Lead Limit"
            value={`${ipcaShAndFrequency.maxLeads}`}
            handleChangeValue={handleChangeValue}
            name="maxLeads"
          />

          <InformationAndBox
            label="Frequência"
            value={ipcaShAndFrequency.frequency}
            handleChangeValue={handleChangeValue}
            name="frequency"
          />

          <InformationAndBox
            label="Débito atual"
            value={ipcaShAndFrequency.debit}
            handleChangeValue={handleChangeValue}
            name="debit"
          />

          <Flex align="center" justifyContent="flex-end" mt="2">
            <PrimaryButton size="xs" onClick={handleUpdateValues}>
              Atualizar
            </PrimaryButton>
          </Flex>
        </Box>

        <Flex flexDir="column">
          <TitleWithBorder label="Atualização de frequência" />

          <Flex flexDir="column" justify="space-between" mt="3">
            <Flex w="100%" justify="space-between">
              <Text
                fontSize="xs"
                maxW="60%"
                lineHeight="1"
                fontWeight="semibold"
                color="gray.800"
              >
                Parar frequência
              </Text>

              <Switch
                size="md"
                isChecked={ipcaShAndFrequency.stopFrequency}
                onChange={handleStopFrequency}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
