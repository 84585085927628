import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { TransferLeadModal } from 'componentsV2/modules/captation/transferLeadModal';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { TransferToEnum } from 'services/hubApi/classes/HubApiCaptation';

export const LeadDetailsTransferLeadToSalesHunter = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const {
    filterValue: isOpenTransferLeadToSalesHunterModalFilterValue,
    handleChangeFilterValue:
      handleChangeIsOpenTransferLeadToSalesHunterModalValue,
    handleDeleteParam: handleDeleteIsOpenTransferLeadToSalesHunterModalFilter,
  } = useSimpleQueryFilter('isOpenTransferLeadToSalesHunterModal');

  const isOpenTransferLeadToSalesHunterModal =
    isOpenTransferLeadToSalesHunterModalFilterValue === 'true';

  return (
    <>
      <Button.Primary
        onClick={() =>
          handleChangeIsOpenTransferLeadToSalesHunterModalValue('true')
        }
      >
        Transferir Lead para SH
      </Button.Primary>

      <ModalWrapper
        isOpen={isOpenTransferLeadToSalesHunterModal}
        onClose={() => handleDeleteIsOpenTransferLeadToSalesHunterModalFilter()}
      >
        <TransferLeadModal
          lead={lead}
          leadType={leadType}
          transferTo={TransferToEnum.SH}
        />
      </ModalWrapper>
    </>
  );
};
