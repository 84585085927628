import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export const meetingLocalOptions: BaseDropdownOptionsType[] = [
  { label: 'Ligação', value: 'Ligação' },
  { label: 'Filial', value: 'Filial' },
  { label: 'Video Chamada', value: 'Video Chamada' },
  { label: 'Endereço', value: 'Endereço' },
];

export const handleGetAvailableMeetingStatusOptions = (
  currentMeetingStatus: MeetingStatusEnum
): BaseDropdownOptionsType[] => {
  const meetingStatusOptions: BaseDropdownOptionsType[] = [
    {
      label: 'R1 - Suitability',
      value: 'suitability',
    },
    {
      label: 'R2 - Carteira',
      value: 'presentation',
    },
    {
      label: 'Ag. Abertura',
      value: 'allocation',
    },
  ];

  const filteredOptions = meetingStatusOptions.filter(
    option => option.value !== currentMeetingStatus
  );

  return filteredOptions;
};
