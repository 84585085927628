export const PeriodicMeetingTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.008 16.628H3.992a1.584 1.584 0 01-1.584-1.584V6.332c0-.875.71-1.584 1.584-1.584h11.015c.876 0 1.585.709 1.585 1.584v8.712c0 .875-.71 1.584-1.584 1.584z"
        stroke="#39DDA2"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 7.124a1.98 1.98 0 110 3.96 1.98 1.98 0 010-3.96M13.064 14.252a1.887 1.887 0 00-.47-.686v0a1.875 1.875 0 00-1.279-.502h-3.63c-.475 0-.93.18-1.279.502v0c-.205.191-.366.425-.47.686M3.783 4.748v-.792c0-.875.71-1.584 1.584-1.584h8.265c.875 0 1.584.709 1.584 1.584v.792"
        stroke="#39DDA2"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
