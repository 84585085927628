import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

import { RealEstateFundsTableRow } from 'pages/products/components/modules/realStateFunds';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { BackPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase } from 'components/elements/table';
import { errorHandler } from 'utils/errorHandler';

const tableHeadData = [
  'Ticker',
  'Alocação',
  'P/VP',
  'Liq. Diária',
  'PL Líquido',
  'Dividend Yield',
  'Info',
  'Link',
];

export const ListRealStateFounds: React.FC = () => {
  const [estateFunds, setEstateFunds] = useState([]);

  useEffect(() => {
    hubApiProducts
      .getAllEstateFund()
      .then(data => {
        setEstateFunds(data);
      })
      .catch(error => {
        errorHandler(error);
      });
  }, []);

  if (estateFunds.length <= 0) {
    return (
      <PageWrapper flexDir="column" py="48px" alignItems="center">
        <BackPageButton />

        <MainPageTitle>Destaques dos Fundos Imobiliários</MainPageTitle>

        <Text>Não existem Fundos Imobiliários cadastrados.</Text>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper flexDir="column" py="48px" alignItems="center">
      <BackPageButton />

      <MainPageTitle>Destaques dos Fundos Imobiliários</MainPageTitle>

      <TableBase
        overflowY="auto"
        maxH="calc(100vh - 240px)"
        width="100%"
        maxW="1080px"
        headData={tableHeadData}
      >
        {estateFunds?.map((fund, index) => (
          <RealEstateFundsTableRow key={index} fund={fund} />
        ))}
      </TableBase>
    </PageWrapper>
  );
};
