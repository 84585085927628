import { Flex, Text } from '@chakra-ui/react';
import { formatToBRL } from 'brazilian-values';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';

export interface IMeetingsInfoFromMI {
  id: string;
  age: number;
  dependents: number;
  maritalStatus: string;
  activity: string;
  monthlyIncome: number;
  monthlyExpense: number;
  ageToRetire: number;
  desiredIncome: number;
  incomeType: string;
  investPerMonth: number;
  investorProfile: string;
  lifeMoment: string;
  created_at: string;
}

interface IProps {
  meetingsInfos: IMeetingsInfoFromMI;
}

export const MeetingsInfoFromMI: React.FC<IProps> = ({ meetingsInfos }) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle mb="1">
        Informações do Mundo Invest
      </ContentDividerTitle>

      <Flex flexDirection="column">
        <Text fontSize="xs">
          Idade: {meetingsInfos ? meetingsInfos.age : '-'}
        </Text>
        <Text fontSize="xs">
          Dependentes: {meetingsInfos ? meetingsInfos.dependents : '-'}
        </Text>
        <Text fontSize="xs">
          Estado Civil:{' '}
          {meetingsInfos && meetingsInfos.maritalStatus !== null
            ? formatNameToCaptalize(meetingsInfos.maritalStatus)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Atividade Profissional:{' '}
          {meetingsInfos && meetingsInfos.activity !== null
            ? formatNameToCaptalize(meetingsInfos.activity)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Renda Mensal:{' '}
          {meetingsInfos && meetingsInfos.monthlyIncome !== null
            ? formatToBRL(meetingsInfos.monthlyIncome)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Gastos Mensais:{' '}
          {meetingsInfos && meetingsInfos.monthlyExpense !== null
            ? formatToBRL(meetingsInfos.monthlyExpense)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Valor Aporte:{' '}
          {meetingsInfos && meetingsInfos.investPerMonth !== null
            ? formatToBRL(meetingsInfos.investPerMonth)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Perfil do Investidor:{' '}
          {meetingsInfos && meetingsInfos.investorProfile !== null
            ? formatNameToCaptalize(meetingsInfos.investorProfile)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Momento Financeiro:{' '}
          {meetingsInfos && meetingsInfos.lifeMoment !== null
            ? formatNameToCaptalize(meetingsInfos.lifeMoment)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Idade de Aposentadoria:{' '}
          {meetingsInfos && meetingsInfos.ageToRetire !== null
            ? meetingsInfos.ageToRetire
            : '-'}
        </Text>
        <Text fontSize="xs">
          Renda Desejada:{' '}
          {meetingsInfos && meetingsInfos.desiredIncome !== null
            ? formatToBRL(meetingsInfos.desiredIncome)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Objetivo:{' '}
          {meetingsInfos && meetingsInfos.incomeType !== null
            ? formatNameToCaptalize(meetingsInfos.incomeType)
            : '-'}
        </Text>
        <Text fontSize="xs">
          Data da Criação da Conta no Mundo Invest:{' '}
          {meetingsInfos &&
          meetingsInfos.created_at !== '' &&
          meetingsInfos.created_at !== null
            ? formateDateAndTime(meetingsInfos.created_at)
            : '-'}
        </Text>
      </Flex>
    </ContentWithVertDividerWrapper>
  );
};
