import React, { useEffect, useState } from 'react';
import { ButtonGroup, Flex, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import permissionsCods from 'shared/constants/permissionsCods';
import { IReduxState } from 'shared/interfaces';
import { permissionsAreas } from 'utils/permissionsAreas';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { CardDetailAcessIp } from 'pages/SearchUserDetails/components/cards/CardDetailAcessIp';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { Dropdown } from 'components/elements/others';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { Permissions, SubPermissions } from '../../interfaces';
import { CardDetailAcess } from '../../components/cards';

interface IAcessPage {
  employee: EmployeesProps;
}

export const AccessPage: React.FC<IAcessPage> = ({ employee }) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [permissions, setPermissions] = useState<Permissions[]>([]);
  const [permissionsAll, setPermissionsAll] = useState<Permissions[]>([]);
  const [newPermission, setNewPermission] = useState('');
  const [selectedPermission, setSelectedPermissions] = useState<
    SubPermissions[]
  >([]);
  const [noUsedPermissions, setNoUsedPermissions] = useState<Permissions[]>([]);
  const [openAddPermissions, setOpenAddPermissions] = useState(false);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = async () => {
    const permissionsUserLog = await hubApiUser.getRules(employee.id);
    setPermissions(permissionsUserLog);
    const response = await hubApiUser.getAllPermissions();

    setNoUsedPermissions(permissionsAreas(response, permissionsUserLog));
    setPermissionsAll(response);
  };

  const handleSelectedArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== '') {
      const itemSelected = noUsedPermissions.findIndex(
        item => item.cod === (event.target.value as string)
      );
      if (itemSelected !== -1) {
        setSelectedPermissions(noUsedPermissions[itemSelected].permissions);
      }
    } else {
      toast.dark('Selecione uma área!');
    }
  };

  const handleSelectedPermission = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewPermission(event.target.value);
  };

  const addNewPermission = async () => {
    if (newPermission !== '') {
      try {
        await hubApiUser.setPermission(employee.id, newPermission);
        toast.dark('Nova permissão criada com sucesso!');

        setOpenAddPermissions(!openAddPermissions);
        getPermissions();
      } catch (error) {
        toast.dark('Selecione uma área e uma permissão!');
      }
    } else {
      toast.dark('Selecione uma permissão!');
    }
  };

  return (
    <>
      <Flex
        maxH="calc(100vh - 300px)"
        flexDir="column"
        height={['60vh', 'auto']}
      >
        <Flex
          maxH="100%"
          overflowY="auto"
          flexDir="column"
          px="1"
          gridGap="2"
          mb="4"
        >
          {permissions.map(item =>
            item.cod !== permissionsCods.IP ? (
              <CardDetailAcess
                key={item.id}
                value={item}
                user={user}
                idUser={employee.id}
                permissionsAll={permissionsAll}
                updateCards={getPermissions}
              />
            ) : (
              <CardDetailAcessIp
                key={item.id}
                value={item}
                user={user}
                idUser={employee.id}
                permissionsAll={permissionsAll}
                updateCards={getPermissions}
              />
            )
          )}
        </Flex>
        {noUsedPermissions.length > 0 && (
          <Flex justifyContent="flex-end" margin="12px 0px 8px">
            <PrimaryButton
              onClick={() => setOpenAddPermissions(!openAddPermissions)}
            >
              Adicionar nova permissão
            </PrimaryButton>
          </Flex>
        )}
      </Flex>
      <ModalWrapper
        isOpen={openAddPermissions}
        onClose={() => setOpenAddPermissions(!openAddPermissions)}
      >
        <DefaultModalContentWrapper maxW={['95vw', '460px']}>
          <Stack spacing={4}>
            <Text
              color="rgba(70, 70, 70, 1)"
              fontSize={['md', 'lg']}
              fontWeight="semibold"
            >
              Adicione uma nova permissão para o usuário
            </Text>
            <Dropdown
              bg="#E7E7E7"
              placeholder="Selecione a área"
              onChange={e => handleSelectedArea(e)}
            >
              {noUsedPermissions.map((item, index) => {
                return (
                  <option key={index} value={item.cod}>
                    {item.name}
                  </option>
                );
              })}
            </Dropdown>
            <Dropdown
              bg="#E7E7E7"
              placeholder="Selecione a permissão"
              onChange={e => handleSelectedPermission(e)}
            >
              {selectedPermission.map((per, index) => {
                return (
                  <option key={index} value={per.ruleId}>
                    {per.name}
                  </option>
                );
              })}
              ;
            </Dropdown>
            <ButtonGroup justifyContent="flex-end">
              <SecondaryButton
                onClick={() => setOpenAddPermissions(!openAddPermissions)}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton onClick={addNewPermission}>
                Adicionar
              </PrimaryButton>
            </ButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
