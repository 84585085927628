import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';
import { Check } from 'phosphor-react';

export type ButtonType = { isSuccessful?: boolean } & ButtonProps;

export const PrimaryButton: React.FC<ButtonType> = ({
  isSuccessful,
  children,
  ...rest
}) => {
  return (
    <ButtonChakra
      bgColor="primaryV2.default"
      size="sm"
      color="white"
      padding={{ sm: '12px 16px' }}
      fontWeight="semibold"
      _hover={{ bg: '#085cef' }}
      border="1px solid transparent" // Make sure that Primary Button Heigth is the same as Secondary Button Heigth
      {...rest}
    >
      {isSuccessful ? <Check size={23} /> : <>{children}</>}
    </ButtonChakra>
  );
};
