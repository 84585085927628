import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';

interface HeaderIPManagementProps {
  allInfos: string;
}

export const HeaderExchangeManagement: React.FC<HeaderIPManagementProps> =
  allInfos => {
    const numberOfTransfer = allInfos.allInfos;

    return (
      <Flex mt="2" justifyContent="flex-end" alignItems="center">
        <Flex justifyContent="center" alignItems="center">
          <DefaultCardWrapper
            p="2"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="rgba(70, 70, 70, 1)" fontSize="smaller">
              Transferência de Leads:
            </Text>

            <Text color="orange.400" fontSize="initial">
              {numberOfTransfer}
            </Text>
          </DefaultCardWrapper>
        </Flex>
      </Flex>
    );
  };
