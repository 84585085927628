/* eslint no-unused-vars: off */
import React from 'react';
import { ButtonGroup, Text, ModalContentProps } from '@chakra-ui/react';

import { Textarea } from 'components/elements/forms';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { SecondaryButton } from 'components/elements/buttons';

interface ComponentProps extends ModalContentProps {
  description: string;
  handleDeleteOrder: () => Promise<void>;
  handleToggleCancelOrderModal: () => void;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const DeleteTransferOrderCard: React.FC<ComponentProps> = ({
  description,
  handleDeleteOrder,
  handleChangeValue,
  handleToggleCancelOrderModal,
  ...rest
}) => {
  return (
    <DefaultModalContentWrapper
      maxW={{ base: '400px', md: '500px', lg: '600px' }}
      {...rest}
    >
      <Text
        fontSize={{ base: 'sm', lg: 'md' }}
        color="white"
        fontWeight="semibold"
      >
        Você deseja deletar essa ordem?
      </Text>

      <Textarea
        placeholder="Descreva o motivo para deletar essa ordem..."
        name="description"
        my="4"
        rows={6}
        value={description}
        onChange={handleChangeValue}
      />

      <ButtonGroup width="100%" justifyContent="flex-end" spacing="2">
        <SecondaryButton onClick={handleToggleCancelOrderModal}>
          Cancelar
        </SecondaryButton>
        <SecondaryButton onClick={handleDeleteOrder}>
          Deletar ordem
        </SecondaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};
