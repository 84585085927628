import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { basicDate } from 'utils/formatDate';
import formatValue from 'utils/formatValue';

export const ClientsTable: React.FC = () => {
  const [nextPageToFetch, setNextPageToFetch] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<any[]>([]);

  const pagination = activePage === 1 ? 0 : (activePage - 1) * 12;

  const handleGetClientsInfo = async () => {
    try {
      setIsLoading(true);
      const response = await hubApi.getClientInfoForTable(String(activePage));
      setClients(response.clients);
      setTotalPages(response.pagesCount);
      const nextPage = response.clients.length / 12 + 1;
      setNextPageToFetch(nextPage);
    } catch (err) {
      toast.dark(
        'Não foi possível buscar os cliente, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const handleGetClientsInfoPagination = async () => {
    try {
      setIsLoading(true);

      let result: any;

      if (activePage === nextPageToFetch) {
        result = await hubApi.getClientInfoForTable(nextPageToFetch.toString());

        const nextPage = (clients.length + result.clients.length) / 12 + 1;

        setNextPageToFetch(nextPage);
        setTotalPages(result.pagesCount);
        setClients(prev => [...prev, ...result.clients]);
      }
    } catch (err) {
      toast.dark(
        'Não foi possível buscar os clientes, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetClientsInfoPagination();
  }, [activePage]);

  useEffect(() => {
    handleGetClientsInfo();
  }, []);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Clientes Novos</MainPageTitle>
      <Flex w="100%" mt={2}>
        {activePage > 1 && (
          <Tooltip
            label="Página anterior"
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom-start"
          >
            <Flex
              w="50px"
              background="background.800"
              _hover={{ background: 'background.700' }}
              cursor="pointer"
              transition="300ms"
              justify="center"
              align="center"
              onClick={() => setActivePage(prev => prev - 1)}
            >
              <IoChevronBack size={20} color="white" />
            </Flex>
          </Tooltip>
        )}

        {activePage === 1 && (
          <Flex
            w="50px"
            background="background.800"
            justify="center"
            align="center"
          />
        )}

        <Flex w="100%" h="calc(100vh - 268px)" backgroundColor="background.600">
          {isLoading && (
            <Flex w="100%" alignItems="center" justify="center">
              <LoadingSpinner />
            </Flex>
          )}

          {!isLoading && clients.length > 0 && (
            <TableBase
              headData={[
                'Nome',
                'Patrimonio',
                'Assessor',
                'Entrada',
                'PL Declarado',
              ]}
              p="0"
              w="100%"
              height="fit-content"
              maxH="calc(100vh - 254px)"
              overflowY="auto"
              className="routedMeetingsTable"
            >
              {clients.slice(pagination, activePage * 12).map((item, index) => (
                <TableRow key={index}>
                  <TableData
                    maxW="190px"
                    title={formatNameToCaptalize(item.name)}
                  >
                    {formatNameToCaptalize(item.name)}
                  </TableData>
                  <TableData maxW="190px">
                    {item.pl && Number(item.pl) > 0
                      ? formatValue(item.pl)
                      : '-'}
                  </TableData>
                  <TableData maxW="190px">
                    {formatNameToCaptalize(item.employeeName)}
                  </TableData>
                  <TableData>
                    {new Date(item.startDate) > new Date()
                      ? basicDate(item.created_at)
                      : basicDate(item.startDate)}
                  </TableData>
                  <TableData maxW="200px">
                    {item.declaredPL ? formatValue(item.declaredPL) : '-'}
                  </TableData>
                </TableRow>
              ))}
            </TableBase>
          )}

          {!isLoading && clients.length === 0 && (
            <Flex width="100%" align="center" justify="center">
              <Text fontStyle="italic">Sem reuniões roteadas para exibir</Text>
            </Flex>
          )}

          {activePage < totalPages && (
            <Tooltip
              label="Próxima página"
              cursor="pointer"
              color="blackAlpha.900"
              placement="bottom-end"
            >
              <Flex
                w="50px"
                background="background.800"
                justify="center"
                align="center"
                _hover={{ background: 'background.700' }}
                cursor="pointer"
                transition="300ms"
                onClick={() => setActivePage(prev => prev + 1)}
              >
                <IoChevronForward size={20} color="white" />
              </Flex>
            </Tooltip>
          )}

          {activePage === totalPages && (
            <Flex
              w="50px"
              background="background.800"
              justify="center"
              align="center"
            />
          )}
        </Flex>
      </Flex>
      {clients.length > 0 && (
        <Flex w="100%" justify="center" align="center" h="40px" mt={3}>
          <Text>
            Página{' '}
            <Text as="span" color="white" fontWeight="bold">
              {activePage}
            </Text>{' '}
            de{' '}
            <Text as="span" color="white" fontWeight="bold">
              {totalPages === 0 ? 1 : totalPages}
            </Text>
          </Text>
        </Flex>
      )}
    </DefaultPageWrapper>
  );
};
