import { Flex, Text } from '@chakra-ui/react';
import InputLabel from 'components/customElements/InputLabel';
import { BaseModalFormCard } from 'components/elements/cards';
import { Input } from 'components/elements/forms';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { useUpdateIPMeeting } from 'pages/IPLife/hooks/useUpdateIPMeeting';
import { FormEvent, useState } from 'react';
import { CurrencyInput } from 'components/elements/forms/CurrencyInput';

import { IPMeetingType } from 'services/hubApi';
import { toast } from 'react-toastify';

type PropsType = {
  meeting: IPMeetingType;
  setIsOpenMeetingScheduleModal: (value: boolean) => void;
  newMeetingStatus: string;
  shouldShowAnnualizedPremiumInput: boolean;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingScheduleModal: React.FC<PropsType> = ({
  meeting,
  setIsOpenMeetingScheduleModal,
  newMeetingStatus,
  shouldShowAnnualizedPremiumInput,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const { mutateAsync: updateMeeting, isLoading } = useUpdateIPMeeting();

  const [meetingDate, setMeetingDate] = useState<string>('');
  const [annualizedPremium, setAnnualizedPremium] = useState<
    string | undefined
  >('');

  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (shouldShowAnnualizedPremiumInput && !annualizedPremium) {
      return toast.dark('Digite um Prêmio Anualizado');
    }

    await updateMeeting({
      meetingId: meeting.id,
      date: meetingDate,
      status: newMeetingStatus,
      annualizedPremium:
        Number(annualizedPremium?.replaceAll(',', '.')) ??
        meeting.order.annualizedPremium,
    });

    setIsOpenMeetingScheduleModal(false);
    handleToggleOpenMeetingDetailsModal(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Marcar uma Reunião"
      handleFormSubmit={formSubmit}
      isLoading={isLoading}
      primaryButtonText="Agendar"
      secondaryButtonEventFunction={() => setIsOpenMeetingScheduleModal(false)}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Flex flexDirection="column" gap={5}>
        <DateAndHourPicker
          label="Data da Reunião"
          onChange={date => setMeetingDate(date)}
        />

        {shouldShowAnnualizedPremiumInput && (
          <Flex flexDirection="column" gap={1}>
            <Text fontSize="xs">Prêmio Anualizado</Text>
            <CurrencyInput
              value={annualizedPremium}
              onValueChange={value => {
                setAnnualizedPremium(value);
              }}
            />
          </Flex>
        )}
      </Flex>
    </BaseModalFormCard>
  );
};
