import React from 'react';
import { Input as ChackraInput, InputProps } from '@chakra-ui/react';

export const Input: React.FC<InputProps> = props => {
  return (
    <ChackraInput
      background="white"
      border="1px solid"
      borderColor="#1d63ddb2"
      focusBorderColor="primary.600"
      _placeholder={{ color: 'gray.600' }}
      _hover={{ borderColor: 'primary.800' }}
      borderRadius="6px"
      {...props}
    />
  );
};
