import { Flex } from '@chakra-ui/react';
import { useNavigation } from 'hooksV2/useNavigation';
import { CaretLeft } from 'phosphor-react';
import * as S from './styles';

export const PageWrapper: React.FC<
  S.PageWrapperPropsType & { backPageButton?: boolean }
> = ({ backPageButton = false, children, ...rest }): JSX.Element => {
  const { goBack } = useNavigation();

  return (
    <S.PageWrapperStyledComponent {...rest}>
      {backPageButton && (
        <Flex
          position="absolute"
          top="10"
          left="7"
          cursor="pointer"
          onClick={goBack}
        >
          <CaretLeft size={28} color="rgba(70, 70, 70, 1)" />
        </Flex>
      )}

      {children}
    </S.PageWrapperStyledComponent>
  );
};
