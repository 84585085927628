export type BaseDropdownOptionsType = {
  label: string;
  value: string;
};

type PropsType = {
  placeholder: string;
  options: BaseDropdownOptionsType[] | undefined;
  selected?: string;
  optionWithoutValueName?: string;
};

export const DropdownOptions: React.FC<PropsType> = ({
  placeholder,
  options,
  selected,
  optionWithoutValueName,
}): JSX.Element => {
  return (
    <>
      <option defaultValue="" value="" selected>
        {placeholder}
      </option>

      {optionWithoutValueName && (
        <option defaultValue="" value="">
          {optionWithoutValueName}
        </option>
      )}

      {options?.map((option, index) => (
        <option
          selected={selected === option.value}
          defaultValue=""
          key={index}
          value={option?.value}
        >
          {option?.label}
        </option>
      ))}
    </>
  );
};
