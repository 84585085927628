import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { Box } from 'componentsV2/elements/boxes';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useTabNavigation } from 'hooksV2/useTabNavigation';
import { User } from 'phosphor-react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { TextUtils } from 'utilsV2/Text';
import { ClientDetailsActions } from './components/actions';
import { ClientDetailsBasicData } from './components/basicData';
import { ClientDetailsCommentedAreas } from './components/commentedAreas';
import { ClientDetailsEmployeesHistory } from './components/employeesHistory';
import { ClientDetailsInvestorData } from './components/investorData';
import { ClientDetailsLastActivities } from './components/lastActivities';
import { ClientDetailsLastNotes } from './components/lastNotes';
import { ClientDetailsMovimentations } from './components/movimentations';
import { ClientDetailsMyActivities } from './components/myActivities';
import { ClientDetailsNextActivities } from './components/nextActivities';
import { ClientDetailsNotesTextArea } from './components/noteTextArea';
import { ClientDetailsSkeletonLoading } from './components/skeletonLoading';
import { ClientDetailsTabs } from './components/tabs';
import { ClientDetailsAllocationSubPage } from './subpages/allocation';
import { ClientDetailsContactSubPage } from './subpages/contact';
import { ClientDetailsNotesSubPage } from './subpages/notes';
import { ClientDetailsOffersSubPage } from './subpages/offers';
import { ClientDetailsProfileSubPage } from './subpages/profile';
import { ClientDetailsRevenueSubPage } from './subpages/revenue';

export enum ClientDetailsTabEnum {
  CONTACT = 'CONTACT',
  NOTES = 'NOTES',
  OFFERS = 'OFFERS',
  ALLOCATION = 'ALLOCATION',
  REVENUE = 'REVENUE',
  PROFILE = 'PROFILE',
}

export const ClientDetails = (): JSX.Element => {
  const { btgCode } = useParams<{ btgCode: string }>();

  const selectedTabFilter = useSimpleQueryFilter<ClientDetailsTabEnum>('tab', {
    initialValue: ClientDetailsTabEnum.CONTACT,
  });

  const { data: client, isLoading: isLoadingClient } = useQuery({
    queryKey: ['clientDetails', btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getClientByBtgCode(btgCode);
      return response;
    },
  });

  const { data: history } = useQuery({
    queryKey: ['clientDetailsEmployeesHistory', client?.btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getEmployeesHistory(
        client?.btgCode as string
      );
      return response;
    },
    enabled: client?.btgCode !== undefined,
    refetchOnWindowFocus: false,
  });

  if (isLoadingClient || !client) {
    return <ClientDetailsSkeletonLoading />;
  }

  // return (
  //   <PageWrapper paddingTop="2rem" paddingX="2rem" backPageButton>
  //     <MainPageTitle color="rgba(70, 70, 70, 1)">
  //       {TextUtils.toCapitalize(client.name)}
  //     </MainPageTitle>

  //     <Flex gap={2} h="calc(100vh - 200px)">
  //       <Flex
  //         borderRadius={5}
  //         flexDirection="column"
  //         justifyContent="start"
  //         alignItems="start"
  //         paddingX="1rem"
  //         border="1px solid gray"
  //         h="100%"
  //         w="100%"
  //         style={{ flex: 1 }}
  //         overflow="hidden"
  //         paddingY={5}
  //       >
  //         {selectedTabFilter.filterValue === ClientDetailsTabEnum.CONTACT && (
  //           <ClientDetailsContactSubPage client={client} />
  //         )}

  //         {selectedTabFilter.filterValue === ClientDetailsTabEnum.NOTES && (
  //           <ClientDetailsNotesSubPage client={client} />
  //         )}

  //         {selectedTabFilter.filterValue === ClientDetailsTabEnum.OFFERS && (
  //           <ClientDetailsOffersSubPage client={client} />
  //         )}

  //         {selectedTabFilter.filterValue ===
  //           ClientDetailsTabEnum.ALLOCATION && (
  //           <ClientDetailsAllocationSubPage client={client} />
  //         )}

  //         {selectedTabFilter.filterValue === ClientDetailsTabEnum.REVENUE && (
  //           <ClientDetailsRevenueSubPage client={client} />
  //         )}

  //         {selectedTabFilter.filterValue === ClientDetailsTabEnum.PROFILE && (
  //           <ClientDetailsProfileSubPage client={client} />
  //         )}
  //       </Flex>

  //       <ClientDetailsTabs selectedTabFilter={selectedTabFilter} />
  //     </Flex>
  //   </PageWrapper>
  // );

  return (
    <PageWrapper paddingTop="2rem" paddingX="2rem" backPageButton>
      <Flex gap={2} h="calc(100vh - 130px)" px={10} flexDirection="column">
        <Flex justifyContent="space-between" w="100%">
          <Breadcrumb
            w="100%"
            ml={3}
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                href="/v2/clients"
                color="rgba(70, 70, 70, 1)"
                _hover={{ color: 'gray' }}
              >
                Clientes EWZ
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink
                href="#"
                color="rgba(70, 70, 70, 1)"
                fontWeight="bold"
              >
                {client.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <ClientDetailsActions client={client} />
        </Flex>

        <Flex
          borderRadius={5}
          flexDirection="column"
          justifyContent="start"
          alignItems="start"
          paddingX="1rem"
          border="1px solid gray"
          h="100%"
          w="100%"
          style={{ flex: 1 }}
          overflowY="auto"
          paddingY={5}
          className="thinScrollbar"
        >
          <Grid
            overflowY="auto"
            className="slimScrollbar"
            paddingRight="1rem"
            h="100%"
            w="100%"
            style={{ flex: 1 }}
            gridTemplateAreas={`
            "data note activities"
            "data last_note areas"
            "data last_note movi"
            `}
            gridTemplateRows="180px 200px auto"
            gridTemplateColumns="350px minmax(300px, 1fr) min(350px, 400px)"
            gap={2}
          >
            <GridItem gridArea="data">
              <Box.Default flexDirection="column" gap={2} h="100%">
                <ClientDetailsBasicData client={client} />
                <ClientDetailsInvestorData client={client} />
                <ClientDetailsEmployeesHistory
                  client={client}
                  history={history}
                />
              </Box.Default>
            </GridItem>
            <GridItem gridArea="note" h="250px">
              <Box.Default h="100%">
                <ClientDetailsNotesTextArea client={client} />
              </Box.Default>
            </GridItem>
            <GridItem gridArea="last_note" mt="70px">
              <ClientDetailsLastNotes client={client} history={history} />
            </GridItem>
            <GridItem gridArea="activities">
              <Box.Default h="100%">
                <ClientDetailsMyActivities client={client} />
              </Box.Default>
            </GridItem>
            <GridItem gridArea="areas">
              <Box.Default h="100%">
                <ClientDetailsCommentedAreas client={client} />
              </Box.Default>
            </GridItem>
            <GridItem gridArea="movi">
              <Box.Default h="100%">
                <ClientDetailsMovimentations client={client} />
              </Box.Default>
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
