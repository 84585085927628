import { COEprops, IPOprops, OFPprops } from 'pages/Home/interfaces';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { IPOmodalContent } from './IPOmodalContent';
import { COEmodalContent } from './COEmodalContent';
import { OFPmodalContent } from './OPFmodalContent';

type ProductInfos = IPOprops | COEprops | OFPprops;

interface ProductCalendaryModalProps {
  productInfos: {
    foundProduct: ProductInfos;
    productType: string;
  };
  handleToggleModal: () => void;
}

export const ProductCalendaryModal: React.FC<ProductCalendaryModalProps> = ({
  productInfos,
  handleToggleModal,
}) => {
  return (
    <DefaultModalContentWrapper
      maxW={
        productInfos.productType === 'OFP' ? ['90vw', '60vw'] : ['90vw', '80vw']
      }
      maxH={['80vh', 'auto']}
    >
      {productInfos.productType === 'IPO' && (
        <IPOmodalContent
          handleToggleModal={handleToggleModal}
          productInfos={productInfos.foundProduct as IPOprops}
        />
      )}
      {productInfos.productType === 'COE' && (
        <COEmodalContent
          handleToggleModal={handleToggleModal}
          productInfos={productInfos.foundProduct as COEprops}
        />
      )}
      {productInfos.productType === 'OFP' && (
        <OFPmodalContent
          handleToggleModal={handleToggleModal}
          productInfos={productInfos.foundProduct as OFPprops}
        />
      )}
    </DefaultModalContentWrapper>
  );
};
