import React from 'react';
import { Stack } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { TextareaWithLabel } from 'components/elements/forms';

interface ComponentProps {
  handleSubmitCancel: () => void;
  handleCloseModalRejects: () => void;
  handleChangeValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  formInfos: {
    obs: string;
  };
  isLoadingModalReject: boolean;
}

const ModalReject: React.FC<ComponentProps> = ({
  handleSubmitCancel,
  handleCloseModalRejects,
  handleChangeValue,
  formInfos,
  isLoadingModalReject,
}) => {
  return (
    <BaseModalFormCard
      maxW="560px"
      title="Ordem rejeitada"
      isLoading={isLoadingModalReject}
      handleFormSubmit={handleSubmitCancel}
      handleToggleModal={handleCloseModalRejects}
      isModal
      primaryButtonText="Enviar"
      secondaryButtonText="Fechar"
    >
      <Stack>
        <TextareaWithLabel
          label="Descreva o motivo"
          placeholder="Mensagem"
          name="obs"
          value={formInfos.obs}
          onChange={handleChangeValue}
        />
      </Stack>
    </BaseModalFormCard>
  );
};

export default ModalReject;
