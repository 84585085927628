import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const MosaicCardWrapper: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      width="100%"
      maxWidth="230px"
      minH="98px"
      flexDir="column"
      background="background.700"
      padding="2"
      borderRadius="md"
      color="white"
      border="1px solid"
      borderColor="background.400"
      position="relative"
      cursor="pointer"
      transition="all 0.1s ease-in"
      _hover={{ borderColor: 'primary.900' }}
      {...rest}
    >
      {children}
    </Flex>
  );
};
