import { formatValue } from 'react-currency-input-field';
import { AiOutlineClose } from 'react-icons/ai';
import { OFPprops } from 'pages/Home/interfaces';
import { formatDate } from 'utils/formatDate';
import { ModalTitle } from 'components/elements/texts';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { CardInfo } from 'components/elements/others/CardInfo';
import { LayoutModal } from 'components/elements/others/LayoutModal';

interface OFPmodalContentProps {
  productInfos: OFPprops;
  handleToggleModal: () => void;
}

export const OFPmodalContent: React.FC<OFPmodalContentProps> = ({
  productInfos,
  handleToggleModal,
}) => {
  const bookingDeadline = formatDate(productInfos.bookingDeadlines);
  const expectedSettlement = formatDate(productInfos.expectedSettlement);

  return (
    <>
      <ModalTitle
        fontSize={{ base: 'lg', lg: 'xl' }}
        textAlign="center"
        height="5%"
      >
        Oferta pública de renda fixa: {productInfos.title.replace(/ofp/i, '')}
      </ModalTitle>
      <Flex position="absolute" top="10px" right="10px">
        <AiOutlineClose
          size={24}
          color="white"
          cursor="pointer"
          onClick={handleToggleModal}
        />
      </Flex>
      <LayoutModal
        description=""
        bonusMessage={productInfos.bonusMessage}
        typeModal="OPF"
      >
        {!productInfos.bonusMessage ? (
          <Flex w="100%" gap={2.5}>
            <SimpleGrid
              columns={1}
              spacing={2.5}
              justifyItems="flex-start"
              width="50%"
            >
              {productInfos.totalVolume && (
                <>
                  <CardInfo title="Reservas até" info={bookingDeadline} />
                  <CardInfo
                    title="Liquidação prevista"
                    info={expectedSettlement}
                  />
                </>
              )}

              <CardInfo
                title="Investimento mínimo"
                info={formatValue({
                  value: productInfos.minimumInvestment,
                  prefix: 'R$ ',
                  decimalSeparator: ',',
                  intlConfig: {
                    locale: 'pt-BR',
                    currency: 'BRL',
                  },
                })}
              />
              <CardInfo title="Amortização" info={productInfos.amortization} />
              <CardInfo title="Juros" info={productInfos.interest} />
            </SimpleGrid>
            <SimpleGrid
              columns={1}
              spacing={2.5}
              justifyItems="flex-end"
              width="50%"
            >
              <CardInfo title="Público" info={productInfos.targetAudience} />
              <CardInfo title="Rating" info={productInfos.rating} />
              <CardInfo title="Taxa Teto" info={productInfos.ceilingRate} />
              <CardInfo
                title="Vencimento da Operação"
                info={productInfos.maturityOfTheOperation}
              />

              {productInfos.totalVolume && (
                <CardInfo
                  title="Volume Total"
                  info={formatValue({
                    value: productInfos.totalVolume,
                    prefix: 'R$ ',
                    decimalSeparator: ',',
                    intlConfig: {
                      locale: 'pt-BR',
                      currency: 'BRL',
                    },
                  })}
                />
              )}
            </SimpleGrid>
          </Flex>
        ) : (
          <>
            <CardInfo title="Reservas até" info={bookingDeadline} />
            <CardInfo title="Liquidação prevista" info={expectedSettlement} />
            <CardInfo
              title="Investimento mínimo"
              info={formatValue({
                value: productInfos.minimumInvestment,
                prefix: 'R$ ',
                decimalSeparator: ',',
                intlConfig: {
                  locale: 'pt-BR',
                  currency: 'BRL',
                },
              })}
            />
            <CardInfo title="Amortização" info={productInfos.amortization} />
            <CardInfo title="Juros" info={productInfos.interest} />
            <CardInfo title="Público" info={productInfos.targetAudience} />
            <CardInfo title="Rating" info={productInfos.rating} />
            <CardInfo title="Taxa Teto" info={productInfos.ceilingRate} />
            <CardInfo
              title="Vencimento da Operação"
              info={productInfos.maturityOfTheOperation}
            />
            {productInfos.totalVolume && (
              <CardInfo
                title="Volume Total"
                info={formatValue({
                  value: productInfos.totalVolume,
                  prefix: 'R$ ',
                  decimalSeparator: ',',
                  intlConfig: {
                    locale: 'pt-BR',
                    currency: 'BRL',
                  },
                })}
              />
            )}
          </>
        )}
      </LayoutModal>
    </>
  );
};
