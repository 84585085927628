/* eslint-disable no-prototype-builtins */
import { LeadManualType } from 'sharedV2/types/leadManual';
import {
  LeadMundoInvestStatusEnum,
  LeadMundoInvestType,
} from 'sharedV2/types/leadMundoInvest';
import { LeadPJType } from 'sharedV2/types/leadPJ';
import {
  LeadParsedType,
  LeadTypeEnum,
  LeadTypes,
} from 'sharedV2/types/leadTypes';
import {
  LeadUploadStatusEnum,
  LeadUploadType,
} from 'sharedV2/types/leadUpload';

export class LeadUtils {
  public static isLeadMundoInvest(
    lead: LeadTypes | LeadParsedType
  ): lead is LeadMundoInvestType {
    return lead.hasOwnProperty('meetingPrice');
  }

  public static isLeadUpload(lead: LeadTypes): lead is LeadUploadType {
    return lead.hasOwnProperty('listName');
  }

  public static isLeadManual(lead: LeadTypes): lead is LeadManualType {
    return lead.hasOwnProperty('origin');
  }

  public static isLeadPJ(lead: LeadTypes): lead is LeadPJType {
    return lead.hasOwnProperty('razaoSocial');
  }

  static transformLeadType(type: string): LeadTypeEnum {
    // These type are used in new version (V2) of HUB

    switch (type) {
      case 'publics':
      case 'leadspublic':
      case LeadTypeEnum.MUNDO_INVEST:
        return LeadTypeEnum.MUNDO_INVEST;
      case 'tables':
      case 'consultable':
      case 'leadstable':
      case LeadTypeEnum.UPLOAD:
        return LeadTypeEnum.UPLOAD;
      case 'leadsmanual':
      case 'employee':
      case LeadTypeEnum.MANUAL:
        return LeadTypeEnum.MANUAL;
      case 'leadspj':
      case 'pj':
      case LeadTypeEnum.PJ:
        return LeadTypeEnum.PJ;
      default:
        return LeadTypeEnum.MUNDO_INVEST;
    }
  }

  static translateLeadTypeToPortuguese(leadType: LeadTypeEnum): string {
    switch (leadType) {
      case LeadTypeEnum.MUNDO_INVEST:
        return 'Mundo Invest';
      case LeadTypeEnum.UPLOAD:
        return 'Lead Lista e Minerado';
      case LeadTypeEnum.MANUAL:
        return 'Lead Manual';
      case LeadTypeEnum.PJ:
        return 'Lead PJ';
      default:
        return 'Could not translate lead type';
    }
  }

  static translateLeadStatusToPortuguese(
    leadStatus: LeadUploadStatusEnum | LeadMundoInvestStatusEnum | string
  ): string {
    switch (leadStatus) {
      case LeadUploadStatusEnum.OK:
      case LeadMundoInvestStatusEnum.OK:
        return 'Ok';
      case LeadUploadStatusEnum.FINALIZADO:
      case LeadMundoInvestStatusEnum.FINALIZADO:
        return 'Finalizado';
      case LeadUploadStatusEnum.DECLINED:
        return 'Declinado';
      case LeadMundoInvestStatusEnum.RESERVED:
        return 'Reservado';
      case LeadMundoInvestStatusEnum.RETURNED:
        return 'Retorno Agendado';
      case LeadMundoInvestStatusEnum.NO_ANSWER:
        return 'Sem resposta';
      default:
        return 'Could not translate lead status to portuguese';
    }
  }

  static translateLeadDeclinedReasonToPortuguese(
    declinedReason: string | undefined
  ): string {
    switch (declinedReason) {
      case 'btg_client':
        return 'Cliente BTG';
      case 'no_answer':
        return 'Sem Contato';
      case 'lgpd':
        return 'LGPD';
      case 'no_interest':
        return 'Sem interesse';
      case 'no_exist':
        return 'Não existe';
      case 'no_money':
        return 'Sem dinheiro';
      default:
        return '-';
    }
  }
}
