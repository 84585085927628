import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { BaseModalFormCard } from 'components/elements/cards';
import { IBaseLeadPJ, IBaseMeeting } from 'pages/Captation/interfaces';
import { useCaptationMeetingsContext } from 'hooks';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { LeadUtils } from 'utilsV2/Lead';

interface IConfirmLeadMissCallProps {
  lead: IBaseLeadPJ;
  meeting: IBaseMeeting;
  handleToggleModal: () => void;
  handleToggleMissCall: () => void;
  handleAddTextAreaQuestion: () => Promise<void>;
  handleChangeMeetingMenu: (
    selectedSegment:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account',
    selectedTitle: string,
    selectedType: string
  ) => void;
}

export const ConfirmLeadMissCall: React.FC<IConfirmLeadMissCallProps> = ({
  meeting,
  lead,
  handleToggleModal,
  handleToggleMissCall,
  handleChangeMeetingMenu,
  handleAddTextAreaQuestion,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const { mutateAsync: meetingAction } = useMeetingActions();

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await meetingAction({
        action: MeetingActionEnum.NO_ANSWER,
        leadType: LeadUtils.transformLeadType(meeting.leadType),
        meetingId: meeting.id,
      });

      await handleGetSpecificLeadTypeArr('suitability', true);
      await handleGetSpecificLeadTypeArr('pipe', true);
      handleToggleMissCall();
      handleToggleModal();
      handleChangeMeetingMenu('suitability', 'Suitability', 'meeting');
      toast.dark(
        'Feedback enviado com sucesso. A reunião voltará para o SH responsável'
      );
      handleAddTextAreaQuestion();
    } catch (err) {
      errorHandler(err);
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isLoading={isLoading}
      maxW="480px"
      title="Contato não estabelecido"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      primaryButtonText="Confirmar"
      isModal
    >
      <Text mb="3">
        Você confirma que não foi possível estabelecer contato com o lead?
      </Text>

      <Text as="span" fontWeight="semibold" fontSize="small">
        {`* Obs: Este campo NÃO é destinado para prospects que atenderam e não
        obtiveram interesse. Nesse caso, clique em "Atendeu" e depois "Declínio
        de oferta".`}
      </Text>
    </BaseModalFormCard>
  );
};
