import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Box } from 'componentsV2/elements/boxes';
import { Spinner } from 'componentsV2/elements/spinner';
import { useMutation } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { Icons } from 'sharedV2/icons';
import { ClientAdditionalInformationType } from 'sharedV2/types/client';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  information: ClientAdditionalInformationType;
};

export const ClientDetailsAdditionalInformationsCard: React.FC<PropsType> = ({
  information,
}): JSX.Element => {
  const {
    mutateAsync: deleteAdditionalInformation,
    isLoading: isLoadingDeleteAdditionalInformation,
  } = useMutation({
    mutationKey: ['deleteAdditionalInformation'],
    mutationFn: async (id: string) => {
      const response = await hubApiClients.deleteAdditionalInformation(id);
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'clientDetails',
        information.btgCode,
      ]);
      showToast('Informação adicional deletada com sucesso!');
    },
  });

  return (
    <Box.Opaque w="100%" p={3}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
          {information.question}
        </Text>
        {isLoadingDeleteAdditionalInformation ? (
          <Spinner h="15px" w="15px" />
        ) : (
          <Icons.TRASH
            onClick={() => deleteAdditionalInformation(information.id)}
          />
        )}
      </Flex>
      <Text color="rgba(70, 70, 70, 1)">{information.answer}</Text>
    </Box.Opaque>
  );
};
