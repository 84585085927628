import { Flex, FlexProps } from '@chakra-ui/react';

export const ContentWarperSearchLeads: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      pos="absolute"
      top="36px"
      left={0}
      bg="background.400"
      w="100%"
      borderRadius="0 0 6px 6px "
      minH="100px"
      maxH="270px"
      zIndex={1001}
      flexDir="column"
      overflow="auto"
      p="1"
      {...rest}
    >
      {children}
    </Flex>
  );
};
