import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import history from 'services/history';
import { PrivateRoute } from 'routes/PrivateRoute';
import { PublicRoute } from 'routes/PublicRoute';

import OfficeTables from 'pages/manager/subpages/OfficeTables';
import SearchUserDetails from 'pages/SearchUserDetails';
import SearchAllUser from 'pages/SearchAllUser';

import Rv from 'pages/RV';
import CrudStructured from 'pages/RV/components/modules/managerStructured/CrudStructured';
import {
  ActiveCustomersFunds,
  ActiveCustomersRv,
  ActiveCustomersShares,
  ManagerStructured,
  RequestedStructuredOrders,
  RVBoard,
  RvWithdrawals,
  StructuredsOnDemand,
} from 'pages/RV/subpages';

import { Login as OldLogin, RecoverPassword, Register } from 'pages/Sign';

import Home from 'pages/Home';
import { Home as NewHome } from 'pagesV2/Home';
import NotFound from 'pages/NotFound';
import ControlPanel from 'pages/ControlPanel';

import Captation from 'pages/Captation';
import {
  LeadsMosaicAAI,
  LeadsMosaicSH,
  LeadsUpload,
  ManagementAAI,
  ManagerSearchLead,
  RoutedMeetingsManagement,
  ShLeadsSummary,
} from 'pages/Captation/subpages';

import MyClients from 'pages/MyClients';

import { Contents } from 'pages/Contents';
import {
  EditContent,
  FullContent,
  MyContents,
  NewContent,
} from 'pages/Contents/subpages';

import { ClientPanels } from 'pages/MyClients/subpages';
import { Offers } from 'pages/Offers';
import {
  OfferDetailsSection,
  PublicOfferManagement,
} from 'pages/Offers/subpages';

import {
  GenerationalVariablesMundoInvest,
  GenerationalVariablesSH,
  LeadFrequencyManagement,
  ManagementGroups,
  SHManagement,
  ShSpecificNumbers,
} from 'pages/GenerationalVariables/subpages/index';

import { MosaicDiarySh } from 'pages/GenerationalVariables/subpages/MosaicDiarySh';
import { ManagementCaptationMundoInvest } from 'pages/GenerationalVariables/subpages/ManagementCaptationMundoInvest';

import { CommissionByArea } from 'pages/Remuneration/subpages/CommissionByArea';

import { TablePendencies } from 'pages/manager/subpages/TablePendencies';

import AnnouncementManagement from 'pages/HUB/subpages/AnnouncementManagement';
import Announcements from 'pages/HUB';

import { DashCPMM } from 'pages/GenerationalVariables/subpages/DashCPMM';
import { MetricsMI } from 'pages/GenerationalVariables/subpages/MetricsMI';

import { DashBigNunbvers } from 'pages/GenerationalVariables/subpages/DashBigNumbers';

import { ManagementTagClients } from 'pages/MyClients/subpages/ManagementTagClients';

import { WraperEletronicSignaure } from 'components/WraperEletronicSignaure';

import { Equity } from 'pages/Remuneration/subpages/Equity';

import { CapitationMiWeeklyConsolidation } from 'pages/GenerationalVariables/subpages/CapitationMiWeeklyConsolidation';

import { LostClientsManager } from 'pages/GenerationalVariables/subpages/LostClientsManager';

import { ManagementLeads } from 'pages/GenerationalVariables/subpages/ManagementLeads';

import { DashboardTables } from 'pages/DashboardTables';
import { DashboardTablesDesktop } from 'pages/DashboardTablesDesktop';
import { DistributionOfMinedLeadsTable } from 'pages/DistributionOfMinedLeads';
import { ContactCalendar } from 'pages/MyClients/subpages/ContactCalendar';

import { CommissionByIlhaForte } from 'pages/Remuneration/subpages/CommissionByIlhaForte';
import { TeamMeetings } from 'pages/manager/subpages/TeamMeetings';
import { Events } from 'pages/Events';
// import { Results } from 'pages/Captation/subpages/Results';
// import { FunnelResults } from 'pages/Captation/subpages/FunnelResults';
import { CustomEmployeeAgenda } from 'pages/UserAgenda/subpages';
import { SearchClients } from 'pages/manager/subpages/SearchClients';
import { ClientsTable } from 'pages/MyClients/subpages/ClientsTable';
import { ListClientesFromAreas } from 'pages/ListClientsFromAreas';
import { MyMIBs } from 'pages/MyMIBs';
import { FakeData } from 'pages/FakeData';
import { ShLeadsActivities } from 'pages/Captation/subpages/ShLeadsActivities';
import { PowerBIDashboard } from 'pages/Dashboards/subpages/dashboard';
import { Dashboards } from 'pages/Dashboards';
import { DashboardsManagement } from 'pages/Dashboards/subpages/management';
import { IPLife } from 'pages/IPLife';
import { EmployeeAgenda } from 'pagesV2/EmployeeAgenda';
import { LeadDetails } from 'pagesV2/LeadDetails';
import { SalesHunterPool } from 'pagesV2/SalesHunterPool';
import { MeetingsPool } from 'pagesV2/MeetingsPool';
import { LeadsProspectPool } from 'pagesV2/LeadsProspectPool';
import { LeadsSearch } from 'pagesV2/LeadsSearch';
import { Login } from 'pagesV2/Login';
import { ResetPassword } from 'pagesV2/ResetPassword';
import { PoolMundoInvest } from 'pagesV2/PoolMundoInvest';
import { Clients } from 'pagesV2/Clients';
import { ClientDetails } from 'pagesV2/ClientDetails';
import { ClientMeetingDetails } from 'pagesV2/ClientMeetingDetails';
import { ClientsSearch } from 'pagesV2/ClientsSearch';
import Exchange from '../pages/Exchange';
import ExchangeBoard from '../pages/Exchange/subpages/ExchangeCRM';
import ExchangeBoardManager from '../pages/Exchange/components/modules/exchangeManagement/KanbanManager';
import {
  ExchangeManagement,
  ExchangeTransferOrders,
} from '../pages/Exchange/subpages';

import { Technology } from '../pages/Technology';
import TechnologyTransfer from '../pages/Technology/subpages/TechnologyTransfer';
import TechnologyCRM from '../pages/Technology/subpages/TechnologyCRM';

import {
  CreateElectronicSignature,
  UserNotifications,
  UserProfile,
} from '../pages/User/subpages';

import Marketing from '../pages/Marketing';
import {
  Campaign,
  MarketingCRM,
  MarketingCRMsManagement,
  MarketingManagement,
  MarketingTransferOrders,
  MaterialsManagement,
} from '../pages/Marketing/subpages';

import AdvancedOrders from '../pages/AdvancedOrders';

import Ip from '../pages/IP';
import {
  AgroKanban,
  AltInsurancesKanban,
  CorporateKanban,
  HealthKanban,
  IpKanbanManager,
  IpManagement,
  IpTransferOrders,
  LifeKanban,
  RealStateKanban,
} from '../pages/IP/subpages';

import LeadTransfer from '../pages/manager/LeadTransfer';
import OfficeBranches from '../pages/manager/subpages/OfficeBranches';

import EmployeeContract from '../pages/manager/EmployeeContract';
import EmployeeConfirmData from '../pages/manager/EmployeeConfirmData';
import {
  DiscontinuedUserOrders,
  LeadsManagement,
  ManagerNotices,
} from '../pages/manager/subpages';

import { Rh } from '../pages/RH';
import Rhcrm from '../pages/RH/subpages/Kanban';
import ContractBoard from '../pages/RH/subpages/ContractBoard';
import SendMailToEmployee from '../pages/RH/subpages/SendMailToEmployee';

import SolicitationForm from '../pages/RH/subpages/SolicitationForm';

import RhTransferOrders from '../pages/RH/subpages/TransferOrders';

import Products from '../pages/products';
import {
  CoeManagement,
  CreateCOE,
  CreateInvestmentFund,
  CreateIPO,
  CreatePublicOffering,
  CreateRealEstateFound,
  InvestmentFundsManagement,
  IPOManagement,
  ListCOEs,
  ListInvestmentFunds,
  ListIPOs,
  ListPortfolios,
  ListPublicOffering,
  ListRealStateFounds,
  PortfolioManagement,
  PublicOfferingManagement,
  RealEstateManagement,
  UpdateCOE,
  UpdateInvestmentFund,
  UpdateIPO,
  UpdatePublicOffering,
  UpdateRealEstateFund,
} from '../pages/products/subpages';

import {
  FinancialCaptation,
  FinancialCommissioningReportUpload,
  FinancialCustomers,
  FinancialNegativeCustomer,
  FinancialRemunerationUpload,
  FinancialRevenue,
} from '../pages/Financials/subpages';

import Notices from '../pages/notices';
import { CreateNotice } from '../pages/notices/subpages';

import Remuneration from '../pages/Remuneration';
import CommissioningReport from '../pages/Remuneration/subpages/CommissioningReport';
import RemunerationDetails from '../pages/Remuneration/subpages/RemunerationDetails';

import RvOrders from '../pages/RV/subpages/RvOrders';

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/login" component={OldLogin} />
        <PublicRoute path="/v2/login" component={Login} />
        <PublicRoute path="/register/:managerId/:token" component={Register} />

        <PublicRoute
          exact
          path="/recover/password/:token"
          component={RecoverPassword}
        />
        <PublicRoute
          exact
          path="/v2/reset/password/:token"
          component={ResetPassword}
        />
        <PrivateRoute
          exact
          path="/users/search/all"
          component={SearchAllUser}
        />
        <PrivateRoute
          path="/users/search/:employeeId"
          component={SearchUserDetails}
        />

        <PrivateRoute path="/fakes" component={FakeData} />

        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/v2/home" component={NewHome} />
        <PrivateRoute
          exact
          path="/employee_agenda"
          component={CustomEmployeeAgenda}
        />

        {/* <PrivateRoute exact path="/" component={UserAgenda} /> */}
        <PrivateRoute exact path="/" component={EmployeeAgenda} />

        {/* Financial uploads */}
        <PrivateRoute
          path="/financial/clients"
          component={FinancialCustomers}
        />
        <PrivateRoute
          path="/financial/customer_balance"
          component={FinancialNegativeCustomer}
        />
        <PrivateRoute path="/financial/revenue" component={FinancialRevenue} />
        <PrivateRoute
          path="/financial/captation"
          component={FinancialCaptation}
        />
        <PrivateRoute
          exact
          path="/financial/remuneration/upload"
          component={FinancialRemunerationUpload}
        />
        <PrivateRoute
          exact
          path="/financial/commissioning_report/upload"
          component={FinancialCommissioningReportUpload}
        />
        <PrivateRoute exact path="/exchange" component={Exchange} />
        <PrivateRoute
          path="/orders/transfer/exchange"
          component={ExchangeTransferOrders}
        />
        <PrivateRoute
          exact
          path="/advanced_orders"
          component={AdvancedOrders}
        />
        {/* RV routes */}
        <PrivateRoute exact path="/rv" component={Rv} />
        <PrivateRoute exact path="/orders_rv" component={RvOrders} />
        <PrivateRoute
          exact
          path="/crud_structured"
          component={ManagerStructured}
        />
        <PrivateRoute
          exact
          path="/active_customers_rv"
          component={ActiveCustomersRv}
        />
        <PrivateRoute
          exact
          path="/active_customers_shares"
          component={ActiveCustomersShares}
        />
        <PrivateRoute
          exact
          path="/active_customers_founds"
          component={ActiveCustomersFunds}
        />
        <PrivateRoute exact path="/rv_transfer" component={RvWithdrawals} />
        <PrivateRoute
          exact
          path="/rv/structureds"
          component={StructuredsOnDemand}
        />

        <PrivateRoute
          exact
          path="/rv/structureds/create"
          component={CrudStructured}
        />

        <PrivateRoute
          exact
          path="/rv/structureds/create/:id"
          component={CrudStructured}
        />

        {/* Marketing routes */}
        <PrivateRoute path="/marketing" component={Marketing} />
        <PrivateRoute
          path="/board/manager/marketing/:id"
          component={MarketingCRMsManagement}
        />
        <PrivateRoute path="/campaign" component={Campaign} />
        <PrivateRoute
          path="/marketing_management"
          component={MarketingManagement}
        />
        <PrivateRoute
          exact
          path="/orders/transfer/mkt"
          component={MarketingTransferOrders}
        />
        <PrivateRoute
          exact
          path="/materials_management/marketing"
          component={MaterialsManagement}
        />

        {/* User routes */}
        <PrivateRoute path="/user/profile" component={UserProfile} />
        <PrivateRoute component={MyClients} exact path="/user/my_clients" />
        <PrivateRoute component={Clients} exact path="/v2/clients" />
        <PrivateRoute
          component={ClientDetails}
          exact
          path="/v2/client/:btgCode"
        />

        <PrivateRoute
          component={ClientMeetingDetails}
          exact
          path="/v2/client/:btgCode/meeting/:id"
        />

        <PrivateRoute
          path="/user/users_clients/new/:account"
          component={ClientPanels}
        />

        <PrivateRoute
          path="/user/notifications/all"
          component={UserNotifications}
        />
        <PrivateRoute
          component={ControlPanel}
          exact
          path="/user/control_panel"
        />
        <PrivateRoute component={MyMIBs} exact path="/user/mibs" />
        <PrivateRoute
          exact
          path="/user/eletronic_signature/create"
          component={CreateElectronicSignature}
        />
        {/* RH routes */}
        <PrivateRoute exact path="/rh" component={Rh} />
        <PrivateRoute path="/rh/solicitation" component={SolicitationForm} />
        <PrivateRoute exact path="/mi/communicated" component={Announcements} />
        <PrivateRoute
          path="/hub/announcements/manage"
          component={AnnouncementManagement}
        />

        <PrivateRoute exact path="/rh/contracts" component={ContractBoard} />
        <PrivateRoute path="/orders/transfer/rh" component={RhTransferOrders} />

        {/* T.I routes */}
        <PrivateRoute path="/ti" component={Technology} />
        <PrivateRoute
          exact
          path="/orders/transfer/ti"
          component={TechnologyTransfer}
        />

        <PrivateRoute
          path="/exchange/management"
          component={ExchangeManagement}
        />
        <PrivateRoute
          path="/board/manager/cambio/:id"
          component={ExchangeBoardManager}
        />
        {/* IP routes */}
        <PrivateRoute exact path="/ip" component={Ip} />
        <PrivateRoute path="/board/health" component={HealthKanban} />
        <PrivateRoute path="/board/life" component={LifeKanban} />
        <PrivateRoute path="/v2/board/life" component={IPLife} />
        {/* <PrivateRoute path="/board/fleet" component={FleetKanban} /> */}
        <PrivateRoute path="/board/real_state" component={RealStateKanban} />

        <PrivateRoute path="/board/corporate" component={CorporateKanban} />
        <PrivateRoute path="/board/agro" component={AgroKanban} />
        <PrivateRoute
          path="/board/alt_insurances"
          component={AltInsurancesKanban}
        />

        <PrivateRoute
          path="/areas_management"
          component={ListClientesFromAreas}
        />

        <PrivateRoute exact path="/ip_management" component={IpManagement} />
        <PrivateRoute
          exact
          path="/orders/transfer/ip"
          component={IpTransferOrders}
        />
        <PrivateRoute
          path="/board/manager/:segment/:id/:name"
          component={IpKanbanManager}
        />
        {/* CRMs routes */}
        <PrivateRoute path="/board/rv" component={RVBoard} />
        <PrivateRoute path="/board/rh" component={Rhcrm} />
        <PrivateRoute path="/board/ti" component={TechnologyCRM} />
        <PrivateRoute path="/board/exchange" component={ExchangeBoard} />
        <PrivateRoute path="/board/marketing" component={MarketingCRM} />

        {/* Captation routes */}
        <PrivateRoute exact path="/captation/meetings" component={Captation} />
        <PrivateRoute
          exact
          path="/v2/captation/meetings"
          component={MeetingsPool}
        />
        <PrivateRoute
          exact
          path="/v2/captation/mundoInvest/pool"
          component={PoolMundoInvest}
        />
        <PrivateRoute
          exact
          path="/v2/lead/:leadType/:id"
          component={LeadDetails}
        />

        <PrivateRoute
          exact
          path="/captation/leads_aai"
          component={LeadsMosaicAAI}
        />
        <PrivateRoute
          exact
          path="/captation/leads_sh"
          component={LeadsMosaicSH}
        />
        <PrivateRoute
          exact
          path="/v2/captation/sh/pool"
          component={SalesHunterPool}
        />

        <PrivateRoute
          exact
          path="/v2/captation/prospect/leads"
          component={LeadsProspectPool}
        />
        <PrivateRoute
          exact
          path="/captation/leads_sh/summary"
          component={ShLeadsSummary}
        />
        <PrivateRoute
          exact
          path="/captation/leads_sh/activities/:employeeId/:name"
          component={ShLeadsActivities}
        />
        <PrivateRoute
          exact
          path="/captation/management_aai/:origin"
          component={ManagementAAI}
        />
        <PrivateRoute
          exact
          path="/captation/leads/search"
          component={ManagerSearchLead}
        />
        <PrivateRoute exact path="/v2/leads/search" component={LeadsSearch} />

        <PrivateRoute
          exact
          path="/v2/clients/search"
          component={ClientsSearch}
        />

        <PrivateRoute
          exact
          path="/captation/clients/search"
          component={SearchClients}
        />
        <PrivateRoute
          exact
          path="/captation/upload_leads"
          component={LeadsUpload}
        />
        <PrivateRoute
          exact
          path="/captation/routed_meetings"
          component={RoutedMeetingsManagement}
        />

        {/* <PrivateRoute
          exact
          path="/captation/funnel_results"
          component={FunnelResults}
        /> */}

        {/* Products routes */}
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute
          exact
          path="/products/portfolios"
          component={ListPortfolios}
        />
        <PrivateRoute
          exact
          path="/products/portfolios/management"
          component={PortfolioManagement}
        />
        <PrivateRoute exact path="/products/coe" component={ListCOEs} />
        <PrivateRoute path="/products/coe/create" component={CreateCOE} />
        <PrivateRoute path="/products/coe/update/:id" component={UpdateCOE} />
        <PrivateRoute
          exact
          path="/products/coe/management"
          component={CoeManagement}
        />
        <PrivateRoute exact path="/products/ipo" component={ListIPOs} />
        <PrivateRoute exact path="/products/ipo/create" component={CreateIPO} />
        <PrivateRoute path="/products/ipo/update/:id" component={UpdateIPO} />
        <PrivateRoute
          exact
          path="/products/ipo/management"
          component={IPOManagement}
        />
        <PrivateRoute
          exact
          path="/products/real_estate_funds"
          component={ListRealStateFounds}
        />
        <PrivateRoute
          path="/products/real_estate_funds/create"
          component={CreateRealEstateFound}
        />
        <PrivateRoute
          path="/products/real_estate_funds/update/:id"
          component={UpdateRealEstateFund}
        />
        <PrivateRoute
          exact
          path="/products/real_estate_funds/management"
          component={RealEstateManagement}
        />
        <PrivateRoute
          exact
          path="/products/public_offering"
          component={ListPublicOffering}
        />
        <PrivateRoute
          path="/products/public_offering/create"
          component={CreatePublicOffering}
        />
        <PrivateRoute
          path="/products/public_offering/offers/:id"
          component={UpdatePublicOffering}
        />
        {/* <PrivateRoute path="/captation/results" component={Results} /> */}
        <PrivateRoute
          exact
          path="/products/public_offering/management"
          component={PublicOfferingManagement}
        />
        <PrivateRoute
          path="/products/investment_funds/type/:type"
          component={ListInvestmentFunds}
        />
        <PrivateRoute
          path="/products/funds/create"
          component={CreateInvestmentFund}
        />
        <PrivateRoute
          path="/products/funds/update/:id"
          component={UpdateInvestmentFund}
        />
        <PrivateRoute
          exact
          path="/products/funds/management"
          component={InvestmentFundsManagement}
        />

        {/* Offers management */}
        <PrivateRoute exact path="/offers" component={Offers} />

        <PrivateRoute
          exact
          path="/offers/management/:offerId"
          component={PublicOfferManagement}
        />

        <PrivateRoute
          exact
          path="/offers/details/:offerId"
          component={OfferDetailsSection}
        />

        {/* Manager and master routes */}
        <PrivateRoute
          exact
          path="/manager/office/tables"
          component={OfficeTables}
        />
        <PrivateRoute
          exact
          path="/manager/tablependencies"
          component={TablePendencies}
        />

        <PrivateRoute
          exact
          path="/manager/teammeetings"
          component={TeamMeetings}
        />

        <PrivateRoute exact path="/manager/events" component={Events} />

        <PrivateRoute
          exact
          path="/manager/office/branches"
          component={OfficeBranches}
        />
        <PrivateRoute
          path="/manager/employee/profile/:employeeId"
          component={EmployeeConfirmData}
        />
        <PrivateRoute
          exact
          path="/manager/employee/contract/:employeeId"
          component={EmployeeContract}
        />
        <PrivateRoute
          exact
          path="/manager/leads_transfer"
          component={LeadTransfer}
        />
        <PrivateRoute
          path="/manager/mail_employee"
          component={SendMailToEmployee}
        />
        <PrivateRoute path="/manager/notices" component={ManagerNotices} />
        <PrivateRoute
          exact
          path="/manager/leads_management"
          component={LeadsManagement}
        />
        <PrivateRoute
          exact
          path="/discontinued_user_orders"
          component={DiscontinuedUserOrders}
        />

        {/* other routes */}
        <PrivateRoute exact path="/notices" component={Notices} />
        <PrivateRoute exact path="/notices/create" component={CreateNotice} />
        <PrivateRoute
          exact
          path="/commissioning_report"
          component={CommissioningReport}
        />
        <PrivateRoute exact path="/remuneration" component={Remuneration} />
        <PrivateRoute
          exact
          path="/remuneration/details"
          component={RemunerationDetails}
        />
        <PrivateRoute
          exact
          path="/requested_structured_orders"
          component={RequestedStructuredOrders}
        />

        <PrivateRoute
          exact
          path="/generatinal_variables/sh"
          component={GenerationalVariablesSH}
        />

        <PrivateRoute
          exact
          path="/generational_variables/aai"
          component={GenerationalVariablesMundoInvest}
        />

        <PrivateRoute
          exact
          path="/generatinal_variables/frequency"
          component={LeadFrequencyManagement}
        />

        <PrivateRoute
          exact
          path="/generatinal_variables/managementSH"
          component={SHManagement}
        />

        <PrivateRoute
          exact
          path="/generatinal_variables/shNumbers/:shId/:date"
          component={ShSpecificNumbers}
        />

        <PrivateRoute
          exact
          path="/generatinal_variables/groups"
          component={ManagementGroups}
        />

        <PrivateRoute exact path="/mosaic/diary/SH" component={MosaicDiarySh} />

        <PrivateRoute
          exact
          path="/captation/management/MI"
          component={ManagementCaptationMundoInvest}
        />

        <PrivateRoute
          exact
          path="/remuneration/by/area"
          component={CommissionByArea}
        />

        <PrivateRoute
          exact
          path="/captation/management/BigNumbersMI"
          component={DashBigNunbvers}
        />
        <PrivateRoute
          exact
          path="/captation/management/metrics"
          component={MetricsMI}
        />

        <PrivateRoute
          exact
          path="/captation/management/cpmm"
          component={DashCPMM}
        />

        <PrivateRoute
          exact
          path="/eletronicsignature"
          component={WraperEletronicSignaure}
        />
        {/* processos */}
        <PrivateRoute exact path="/contents" component={Contents} />
        <PrivateRoute
          exact
          path="/contents/my/:userId"
          component={MyContents}
        />
        <PrivateRoute
          exact
          path="/contents/my/:userId/new"
          component={NewContent}
        />
        <PrivateRoute
          exact
          path="/contents/content/:contentId"
          component={FullContent}
        />

        <PrivateRoute
          exact
          path="/lead/management"
          component={ManagementLeads}
        />
        <PrivateRoute
          exact
          path="/contents/content/edit/:contentId"
          component={EditContent}
        />

        <PrivateRoute
          exact
          path="/user/users_clients/tags"
          component={ManagementTagClients}
        />
        <PrivateRoute
          exact
          path="/capitation/weeklyConsolidation"
          component={CapitationMiWeeklyConsolidation}
        />

        <PrivateRoute
          exact
          path="/clients/calendar"
          component={ContactCalendar}
        />

        <PrivateRoute
          exact
          path="/clients/lost/manager"
          component={LostClientsManager}
        />
        <PrivateRoute exact path="/clients/table" component={ClientsTable} />

        {/* Dashboard de mesa */}
        <PrivateRoute
          exact
          path="/dashboard/tables"
          component={DashboardTables}
        />

        <PrivateRoute
          exact
          path="/dashboard/tables/desktop"
          component={DashboardTablesDesktop}
        />

        <PrivateRoute
          exact
          path="/distribution_of_mined_leads"
          component={DistributionOfMinedLeadsTable}
        />

        <PrivateRoute
          exact
          path="/remuneration/ilhaforte"
          component={CommissionByIlhaForte}
        />

        <PrivateRoute exact path="/remuneration/equity" component={Equity} />

        <PrivateRoute
          exact
          path="/bi/:reportId/:pageName/dashboard"
          component={PowerBIDashboard}
        />

        <PrivateRoute exact path="/dashboards" component={Dashboards} />
        <PrivateRoute
          exact
          path="/dashboards/management"
          component={DashboardsManagement}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
