import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { ModalWrapper } from 'components/elements/wrappers';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  CreateAdditionalInformationRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { Icons } from 'sharedV2/icons';
import { ClientType } from 'sharedV2/types/client';
import { z } from 'zod';
import { ClientDetailsAdditionalInformationsCard } from './components/card';

const schema = z.object({
  question: z.string().min(1, { message: 'O campo pergunta é obrigatório' }),
  answer: z.string().min(1, { message: 'O campo resposta é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

type PropsType = {
  client: ClientType;
};

export const ClientDetailsAdditionalInformations: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { getParam } = useQueryParams();

  const isOpenAddAdditionalInformationModalFilter = useSimpleQueryFilter(
    'isOpenAddAdditionalInformationModal'
  );

  const isOnlyVisualizationMode =
    getParam('isOnlyVisualizationMode') === 'true';

  const {
    register,
    handleSubmit,
    reset: resetFormFields,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const {
    mutateAsync: createAdditionalInformation,
    isLoading: isLoadingCreateAdditionalInformation,
  } = useMutation({
    mutationKey: ['createAdditionalInformation'],
    mutationFn: async (data: CreateAdditionalInformationRequestType) => {
      const response = await hubApiClients.createAdditionalInformation(data);
      return response;
    },
    onSuccess: async () => {
      isOpenAddAdditionalInformationModalFilter.handleDeleteParam();
      resetFormFields();

      await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
    },
  });

  const handleCreateAdditionalInformation: SubmitHandler<FormFieldsType> =
    async data => {
      await createAdditionalInformation({
        btgCode: client.btgCode,
        ...data,
      });
    };

  return (
    <Flex flexDirection="column" gap={3} w="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
          Informações Adicionais
        </Text>
        {!isOnlyVisualizationMode && (
          <Icons.PLUS
            onClick={() =>
              isOpenAddAdditionalInformationModalFilter.handleChangeFilterValue(
                'true'
              )
            }
          />
        )}
      </Flex>

      <Flex
        w="100%"
        flexDirection="column"
        gap={3}
        overflowY="auto"
        className="thinScrollbar"
      >
        {client.additionalInformations.length === 0 && (
          <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
            Nenhuma informação adicional
          </Text>
        )}
        {client.additionalInformations.map((item, index) => (
          <ClientDetailsAdditionalInformationsCard
            key={index}
            information={item}
          />
        ))}
      </Flex>

      <ModalWrapper
        isOpen={
          isOpenAddAdditionalInformationModalFilter.filterValue === 'true'
        }
        onClose={() =>
          isOpenAddAdditionalInformationModalFilter.handleDeleteParam()
        }
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title="Adicionar nova informação" />
            <Modal.CloseIcon
              onClick={() =>
                isOpenAddAdditionalInformationModalFilter.handleDeleteParam()
              }
            />
          </Modal.Header>

          <Flex flexDirection="column" gap={2}>
            <Input.Root w="100%">
              <Input.Label text="Pergunta" />
              <Input.TextField
                placeholder="Patrimônio"
                {...register('question')}
              />
              <Input.ErrorMessage message={errors.question?.message} />
            </Input.Root>

            <Input.Root w="100%">
              <Input.Label text="Resposta" />
              <Input.TextField placeholder="5000" {...register('answer')} />
              <Input.ErrorMessage message={errors.answer?.message} />
            </Input.Root>
          </Flex>

          <Modal.Actions>
            <Modal.Action
              text="Adicionar"
              onClick={handleSubmit(handleCreateAdditionalInformation)}
              isLoading={isLoadingCreateAdditionalInformation}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
