import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'components/elements/wrappers';
import { Box, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import {
  HeaderStatsSmallBox,
  MeetingsSectionLargeBox,
} from 'pages/UserAgenda/components/elements/boxes';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import {
  MeetingsSummaryCard,
  ToDoListCard,
} from 'pages/UserAgenda/components/elements/cards';
import { NoScheduledMeetingsMessage } from 'pages/UserAgenda/components/elements/others';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import {
  AssignmentProps,
  IAssignmentsLength,
  IShowAndHideCard,
  TaskTypes,
} from 'pages/Home/interfaces/assignments/Assignment';
import { IAllAssignments } from 'pages/Home/components/modules/DayAssignments';
import { CompressAssignments } from 'pages/Home/components/elements/cards/CompressAssignments';
import { DropDownAAI } from 'pages/Captation/components/elements/funnelChart/DropdownAAI';
import { DropDownTable } from 'pages/Captation/components/elements/funnelChart/DropdownTable';
import { SecondaryButton } from 'components/elements/buttons';
import { FiExternalLink } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Warning } from 'phosphor-react';
import formatValue from 'utils/formatValue';

export interface IMeeting {
  id: string;
  leadId: string;
  employeeId: string;
  employeeIdParticipant: string;
  complement: string;
  confirm: boolean;
  date: string;
  feedback: string;
  icon: string;
  filialName?: string;
  filialId?: string;
  isPipe: boolean;
  leadType:
    | 'leadstable'
    | 'leadspublic'
    | 'leadsmanual'
    | 'leadspj'
    | 'pj'
    | 'public'
    | 'tables'
    | 'publics';
  local: string;
  name: string;
  pl?: string | null;
  status:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'created'
    | 'alocado';
  type: string;
  cost: string | number;
  created_at: string;
  updated_at: string;
  faixaFaturamento: string | null;
  contactAttempts: string;
  nomeListaPool?: string;
  typetask?: string;
  frequencyM?: string;
  btgAccount?: string;
  dataretorno?: string;
  lctpipevalue?: string;
  rdpipevalue?: string;
  lpjpipevalue?: string;
  lepipevalue?: string;
  meetingId?: string;
}

export interface IAgendaData {
  lateMeetings: IMeeting[];
  lateMeetingsPipeValue: string;
  todayMeetings: IMeeting[];
  todayMeetingsPipeValue: string;
  tomorrowMeetings: IMeeting[];
  tomorrowMeetingsPipeValue: string;
  totalMeetingsPipeValue: string;
  totalTodayContactAttempts: string;
  totalTodayDeclinedMeetings: string;
  totalTodayMeetingsActions: string;
  totalTodayMeetingsInCRM: string;
  totalTodayScheduledMeetings: string;
}

export const UserAgenda: React.FC<{ isManagement: boolean }> = ({
  isManagement,
}) => {
  const history = useHistory();
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [allAgendaData, setAllAgendaData] = useState({} as IAgendaData);
  const [userTasksFiltered, setUserTasksFiltered] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [showCard, setShowCard] = useState<IShowAndHideCard>({
    cardPreNps: false,
    cardNps: false,
    cardOrigin: false,
    cardClientsLeft: false,
  });
  const isGeralManagement =
    user.rules[0]?.permission_cod === 'GTR' || user.position === 'MASTER';
  const [allAssignments, setAllAssignments] = useState<IAllAssignments>({
    initialAssignments: [],
    nps: [],
    preNps: [],
    origin: [],
    clientsLeft: [],
  });
  const [customEmployeeId, setCustomEmployeeId] = useState('');
  const [customTableId, setCustomTableId] = useState('');
  const [assignmentsLength, setAssignmentsLength] =
    useState<IAssignmentsLength>({
      origin: 0,
      nps: 0,
      preNps: 0,
      clientsLeft: 0,
    });
  const [totalNumOfLeads, setTotalNumOfLeads] = useState(0);

  const getAllAgendaData = async (employeeId?: string) => {
    try {
      const response = await hubApiCaptation.getAgendaData(
        employeeId || user.id
      );

      response.todayMeetings = response.todayMeetings.filter(
        lead => lead.status !== 'alocado'
      );

      response.lateMeetings = response.lateMeetings.filter(
        lead => lead.status !== 'alocado'
      );

      response.tomorrowMeetings = response.tomorrowMeetings.filter(
        lead => lead.status !== 'alocado'
      );

      setAllAgendaData(response);
    } catch (err) {
      toast.dark(
        'Não foi possível buscar os dados da sua agenda, tente novamente em alguns minutos ou comunique o suporte'
      );
    }
  };

  const getTotalNumOfLeadsInPipe = async () => {
    try {
      const { totalMeetingsInPipe } =
        await hubApiCaptation.getTotalNumOfLeads();

      setTotalNumOfLeads(Number(totalMeetingsInPipe));
    } catch (err) {
      toast.dark(
        'Não foi possível buscar o total de leads, tente novamente em alguns minutos ou comunique o suporte'
      );
    }
  };

  const getAllUserTasks = async (employeeId?: string) => {
    setIsLoading(true);
    try {
      const response = await hubApiHome.listHomeAssignments(
        employeeId && employeeId
      );
      const today = new Date().toISOString().slice(0, 10);

      const filteredListByStatus = response.filter((item: any) => {
        if (
          item.typetask === 'cpf' ||
          item.typetask === 'periodicMeetingManager' ||
          item.typetask === 'periodicMeeting' ||
          item.typetask === 'personalizedMeeting' ||
          item.typetask === 'identifyManager' ||
          item.typetask === 'balanceManager' ||
          item.typetask === 'noLeads'
        ) {
          return;
        }
        if (
          item.status !== 'marcado' &&
          !item.realized &&
          item.typetask === 'meetingToday'
        ) {
          if (
            item.dateLimit &&
            new Date(item.dateLimit).toISOString().slice(0, 10) === today
          ) {
            return item;
          }

          if (
            item.created_at_pendents &&
            new Date(item.created_at_pendents).toISOString().slice(0, 10) ===
              today
          ) {
            return item;
          }

          if (item.extra) {
            const { date } = JSON.parse(JSON.stringify(item.extra));
            if (date && new Date(date).toISOString().slice(0, 10) === today) {
              return item;
            }
          }
          if (
            item.created_at &&
            new Date(item.created_at).toISOString().slice(0, 10) === today
          ) {
            return item;
          }
        } else {
          return item;
        }
      });
      totalDependencies(filteredListByStatus);

      setUserTasksFiltered(filteredListByStatus);
      const preNps: AssignmentProps[] = [];
      const origin: AssignmentProps[] = [];
      const nps: AssignmentProps[] = [];
      const clientsLeft: AssignmentProps[] = [];
      const initialAssignments: AssignmentProps[] = [];

      filteredListByStatus.forEach((data: any) => {
        if (data.typetask === TaskTypes.preNps) {
          return preNps.push(data);
        }

        if (data.typetask === TaskTypes.origin) {
          return origin.push(data);
        }

        if (data.typetask === TaskTypes.nps) {
          return nps.push(data);
        }

        if (data.typetask === TaskTypes.left) {
          return clientsLeft.push(data);
        }

        return initialAssignments.push(data);
      });
      setAllAssignments({
        nps,
        origin,
        preNps,
        initialAssignments,
        clientsLeft,
      });
    } catch (error) {
      toast.dark(
        'Não foi possível buscar suas tarefas, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const totalDependencies = (filteredListByStatus: AssignmentProps[]) => {
    const preNpsLength: AssignmentProps[] = [];
    const originLength: AssignmentProps[] = [];
    const npsLength: AssignmentProps[] = [];
    const clientsLeftLength: AssignmentProps[] = [];

    filteredListByStatus.forEach(data => {
      if (data.typetask === TaskTypes.preNps) {
        return preNpsLength.push(data);
      }

      if (data.typetask === TaskTypes.origin) {
        return originLength.push(data);
      }

      if (data.typetask === TaskTypes.nps) {
        return npsLength.push(data);
      }

      if (data.typetask === TaskTypes.left) {
        return clientsLeftLength.push(data);
      }
    });

    setAssignmentsLength({
      origin: originLength.length,
      preNps: preNpsLength.length,
      nps: npsLength.length,
      clientsLeft: clientsLeftLength.length,
    });
  };

  const handleRemoveAssignment = async (
    idTask: string,
    clientId?: string,
    isSharedContent?: boolean,
    additionalInformation?: string
  ) => {
    try {
      if (clientId) {
        if (additionalInformation?.trim() === '' || !additionalInformation) {
          return toast.dark('Digite o motivo da saída do cliente');
        }

        const objectToSend = {
          additionalInformation,
          id: clientId,
          status: 'confirmed deletion',
        };

        await hubApiHome.pathReasonAssignment(objectToSend);
      }

      await hubApiHome.deleteHomeAssignmentById(idTask);

      if (!isSharedContent) {
        toast.dark('Tarefa marcada como concluída');
      }

      const updatedList = userTasksFiltered.filter(
        (item: any) => item.idTask !== idTask && item.id !== idTask
      );
      const preNps: AssignmentProps[] = [];
      const origin: AssignmentProps[] = [];
      const nps: AssignmentProps[] = [];
      const clientsLeft: AssignmentProps[] = [];

      const initialAssignments: AssignmentProps[] = [];

      updatedList.forEach((data: any) => {
        if (data.typetask === TaskTypes.preNps) {
          return preNps.push(data);
        }

        if (data.typetask === TaskTypes.origin) {
          return origin.push(data);
        }

        if (data.typetask === TaskTypes.nps) {
          return nps.push(data);
        }

        if (data.typetask === TaskTypes.left) {
          return clientsLeft.push(data);
        }

        return initialAssignments.push(data);
      });

      setAssignmentsLength({
        origin: origin.length,
        preNps: preNps.length,
        nps: nps.length,
        clientsLeft: clientsLeft.length,
      });

      setUserTasksFiltered(updatedList);

      setAllAssignments({
        nps,
        origin,
        preNps,
        initialAssignments,
        clientsLeft,
      });
    } catch (err) {
      toast.dark('Erro ao marcar a tarefa concluída');
    }
  };

  useEffect(() => {
    getAllAgendaData();
    getAllUserTasks();
    getTotalNumOfLeadsInPipe();
    return () => setUserTasksFiltered([]);
  }, []);

  useEffect(() => {
    getAllAgendaData(customEmployeeId);
    getAllUserTasks(customEmployeeId);
    getTotalNumOfLeadsInPipe();
    return () => setUserTasksFiltered([]);
  }, [customEmployeeId]);

  return (
    <PageWrapper
      width="100%"
      padding="24px 32px 32px"
      flexDirection="column"
      justifyContent="center"
      color="white"
      height={{ base: '100%', lg: 'calc(100vh - 80px)' }}
    >
      <VStack spacing={2} h="100%">
        {!window.location.href.includes('employee_agenda') && (
          <Flex w="100%" justifyContent="space-between">
            <Flex gap={4}>
              <Text fontSize="lg" fontWeight="bold">
                Olá, {formatNameToCaptalize(user.name)}
              </Text>
              {totalNumOfLeads >= 100 && (
                <Tooltip
                  hasArrow
                  label={
                    totalNumOfLeads >= 130
                      ? `Você atingiu o limite de leads no funil de captação (130 leads). Com isto você não receberá novos leads via automação de distribuição e não consigirá avançar leads das abas leads lista, leads minerados, leads manuais e leads declinados. Para voltar a operar sem restrições finalize alguns leads do funil, tentando sempre manter o número de leads abaixo de 100`
                      : `Você tem ${totalNumOfLeads} leads no seu funil de captação. Para melhor gerenciar e organizar suas atividades, recomendamos que tenha menos de 100. Em breve vamos restringir a inserção de novos leads no funil caso você tenha mais de 150.`
                  }
                  placement="top"
                  color="white"
                  bg="background.200"
                >
                  <Flex alignSelf="flex-end">
                    <Warning
                      size={26}
                      color={totalNumOfLeads >= 130 ? '#e94040' : '#f2c240'}
                      weight="fill"
                    />
                  </Flex>
                </Tooltip>
              )}
            </Flex>
            {(user.rules[0]?.permission_cod === 'GTM' ||
              user.rules[0]?.permission_cod === 'GTR') && (
              <SecondaryButton
                onClick={() => history.push('/employee_agenda')}
                gap={1.5}
                w="fit-content"
                size="xs"
              >
                Ver agenda da assessoria
                <Box mb={0.5}>
                  <FiExternalLink size={16} />
                </Box>
              </SecondaryButton>
            )}
          </Flex>
        )}

        <Flex w="100%">
          {(isManagement || isGeralManagement) &&
            window.location.href.includes('employee_agenda') && (
              <Flex gap={2}>
                {isGeralManagement && (
                  <DropDownTable
                    setCustomTableId={setCustomTableId}
                    includeAllOption={false}
                  />
                )}
                <DropDownAAI
                  customTableId={customTableId}
                  isGeralManagement={
                    user.rules[0]?.permission_cod === 'GTR' ||
                    user.position === 'MASTER'
                  }
                  setCustomEmployeeId={setCustomEmployeeId}
                />
              </Flex>
            )}
        </Flex>
        <Flex w="100%" h="2.5rem" gap={2}>
          <HeaderStatsSmallBox
            tooltipText="Número de tentativas de contato e ligações atentidadas/não atendidas com os leads das abas leads minerados, manuais, lista e PJ no dia"
            title="Ligações"
            value={String(
              Number(allAgendaData.totalTodayContactAttempts) +
                Number(allAgendaData.totalTodayMeetingsActions)
            )}
          />
          <HeaderStatsSmallBox
            tooltipText="Quantas ações foram realizadas, como reuniões, declínios e reagendamentos no funil"
            title="Atendimentos"
            value={allAgendaData.totalTodayMeetingsActions}
          />
          <HeaderStatsSmallBox
            tooltipText="Quantas reuniões foram marcadas com os leads no dia"
            title="R. Marcadas"
            value={allAgendaData.totalTodayScheduledMeetings}
          />
          <HeaderStatsSmallBox
            tooltipText="Quantos leads avançaram no CRM (R1 -> R2 -> Ag. abertura -> Alocação) no dia"
            title="Avanço CRM"
            value={allAgendaData.totalTodayMeetingsInCRM}
          />
          <HeaderStatsSmallBox
            tooltipText="Quantidade de declínios do dia no funil"
            title="R. Declinadas"
            value={allAgendaData.totalTodayDeclinedMeetings}
          />
          <HeaderStatsSmallBox
            tooltipText="Somatório do patrimônio de todos os leads no seu funil de captação"
            title="Total Pipe"
            value={
              formatValue(Number(allAgendaData.totalMeetingsPipeValue)).split(
                ','
              )[0]
            }
          />
        </Flex>
        <Flex w="100%" h="43%" gap={2}>
          <MeetingsSectionLargeBox
            isLoading={isLoading}
            title="Reuniões Atrasadas"
            pipeValue={allAgendaData.lateMeetingsPipeValue}
            qtd={allAgendaData.lateMeetings?.length}
          >
            {allAgendaData.lateMeetings?.length === 0 && (
              <NoScheduledMeetingsMessage message="Sem reuniões atrasadas" />
            )}
            {allAgendaData.lateMeetings?.length > 0 &&
              allAgendaData.lateMeetings?.map(meeting => (
                <MeetingsSummaryCard
                  meeting={meeting as IBaseMeeting}
                  type="lateMeetings"
                  isGeralManagement={
                    (isGeralManagement || isManagement) &&
                    window.location.href.includes('employee_agenda')
                  }
                />
              ))}
          </MeetingsSectionLargeBox>

          <MeetingsSectionLargeBox
            isLoading={isLoading}
            title="Reuniões de Hoje"
            pipeValue={allAgendaData.todayMeetingsPipeValue}
            qtd={allAgendaData.todayMeetings?.length}
          >
            {allAgendaData.todayMeetings?.length === 0 && (
              <NoScheduledMeetingsMessage message="Sem reuniões agendadas para hoje" />
            )}
            {allAgendaData.todayMeetings?.length > 0 &&
              allAgendaData.todayMeetings?.map(meeting => (
                <MeetingsSummaryCard
                  meeting={meeting as IBaseMeeting}
                  type="todayMeetings"
                  isGeralManagement={
                    (isGeralManagement || isManagement) &&
                    window.location.href.includes('employee_agenda')
                  }
                />
              ))}
          </MeetingsSectionLargeBox>
        </Flex>

        <Flex w="100%" h="43%" gap={2}>
          <MeetingsSectionLargeBox
            isLoading={isLoading}
            title="Para Amanhã"
            pipeValue={allAgendaData.tomorrowMeetingsPipeValue}
            qtd={allAgendaData.tomorrowMeetings?.length}
          >
            {allAgendaData.tomorrowMeetings?.length === 0 && (
              <NoScheduledMeetingsMessage message="Sem reuniões agendadas" />
            )}
            {allAgendaData.tomorrowMeetings?.length > 0 &&
              allAgendaData.tomorrowMeetings?.map(meeting => (
                <MeetingsSummaryCard
                  meeting={meeting as IBaseMeeting}
                  type="tomorrowMeetings"
                  isGeralManagement={
                    (isGeralManagement || isManagement) &&
                    window.location.href.includes('employee_agenda')
                  }
                />
              ))}
          </MeetingsSectionLargeBox>

          <MeetingsSectionLargeBox
            isLoading={isLoading}
            title="Tarefas"
            qtd={userTasksFiltered.length}
            tasksMenu={
              <CompressAssignments
                showCard={showCard}
                assignmentsLength={assignmentsLength}
                setShowCard={setShowCard}
              />
            }
          >
            {userTasksFiltered.length === 0 && (
              <NoScheduledMeetingsMessage message="Sem tarefas para hoje" />
            )}

            {userTasksFiltered.length > 0 &&
              allAssignments.initialAssignments?.map((item, index) => (
                <ToDoListCard
                  key={`${index}-item.id`}
                  assignment={item}
                  handleRemoveItem={handleRemoveAssignment}
                />
              ))}

            {userTasksFiltered.length > 0 &&
              showCard.cardOrigin &&
              allAssignments.origin?.map((item, index) => (
                <ToDoListCard
                  key={`${index}-item.id`}
                  assignment={item}
                  handleRemoveItem={handleRemoveAssignment}
                />
              ))}

            {userTasksFiltered.length > 0 &&
              showCard.cardNps &&
              allAssignments.nps?.map((item, index) => (
                <ToDoListCard
                  key={`${index}-item.id`}
                  assignment={item}
                  handleRemoveItem={handleRemoveAssignment}
                />
              ))}

            {userTasksFiltered.length > 0 &&
              showCard.cardPreNps &&
              allAssignments.preNps?.map((item, index) => (
                <ToDoListCard
                  key={`${index}-item.id`}
                  assignment={item}
                  handleRemoveItem={handleRemoveAssignment}
                />
              ))}

            {userTasksFiltered.length > 0 &&
              showCard.cardClientsLeft &&
              allAssignments.clientsLeft?.map((item, index) => (
                <ToDoListCard
                  key={`${index}-item.id`}
                  assignment={item}
                  handleRemoveItem={handleRemoveAssignment}
                />
              ))}
          </MeetingsSectionLargeBox>
        </Flex>
      </VStack>
    </PageWrapper>
  );
};
