import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { IoChevronBack } from 'react-icons/io5';
import history from 'services/history';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { Dropdown } from 'components/elements/others';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { IEmployees, ITables } from '../../interfaces';
interface HeaderProps {
  setSelectedTableId: React.Dispatch<React.SetStateAction<string>>;
  tables: Array<ITables>;
  setEmployeeId: React.Dispatch<React.SetStateAction<string>>;
  employees: Array<IEmployees>;
  setSelectViewedMeetings: React.Dispatch<React.SetStateAction<boolean>>;
  selectViewedMeetings: boolean;
  isLoggedUserManager: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  setSelectedTableId,
  tables,
  employees,
  setEmployeeId,
  setSelectViewedMeetings,
  selectViewedMeetings,
  isLoggedUserManager,
}) => {
  return (
    <Flex
      width={['100%', '90%']}
      alignItems={'center'}
      justifyContent={'space-between'}
      color="white"
      flexDirection={['column', 'row']}
    >
      <Flex
        maxWidth={['100%', '35%']}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={5}
        mb={[5, 0]}
      >
        <IoChevronBack
          size={25}
          cursor="pointer"
          onClick={() => history.goBack()}
        />
        <Text fontSize={'xl'}>Últimas reuniões da sua equipe</Text>
      </Flex>
      <Flex
        minWidth={['100%', '58%']}
        maxWidth={['100%', '58%']}
        alignItems={'center'}
        justifyContent={['center', 'flex-end']}
        flexDirection={['column', 'row']}
        gap={['2', '5']}
      >
        {isLoggedUserManager && (
          <Dropdown
            placeholder="Selecione Mesa"
            onChange={e => setSelectedTableId(e.target.value)}
            maxWidth={200}
            minWidth={200}
          >
            {tables.map(table => (
              <option key={table.id} value={table.id}>
                {table.name}
              </option>
            ))}
          </Dropdown>
        )}

        <Dropdown
          placeholder="Selecione AAI"
          onChange={e => setEmployeeId(e.target.value)}
          maxWidth={200}
          minWidth={200}
        >
          {employees.map(employee => (
            <option key={employee.id} value={employee.id}>
              {formatNameToCaptalize(employee.name)}
            </option>
          ))}
        </Dropdown>

        {!selectViewedMeetings ? (
          <PrimaryButton
            onClick={() => setSelectViewedMeetings(true)}
            minWidth={202}
          >
            Mostrar reuniões vistas
          </PrimaryButton>
        ) : (
          <SecondaryButton
            onClick={() => setSelectViewedMeetings(false)}
            minWidth={202}
          >
            Mostrar reuniões não vistas
          </SecondaryButton>
        )}
      </Flex>
    </Flex>
  );
};
