import { Flex } from '@chakra-ui/react';
import { ClientPendencyType } from 'sharedV2/types/client';
import { TaskCard } from '../taskCard';
import { TasksInformationsAndFilters } from '../tasksInformationsAndFilters';
import { useTasksFilter } from './hooks/useTasksFilter';

type PropsType = {
  tasks: ClientPendencyType[] | undefined;
};

export const Tasks: React.FC<PropsType> = ({ tasks }): JSX.Element => {
  const { tasksFilter, setTasksFilter } = useTasksFilter();

  const filteredTasks =
    tasks &&
    tasks.filter(task => {
      const filterIndex = tasksFilter.findIndex(
        filter => filter.tasktype === task.type
      );
      return filterIndex === -1 || tasksFilter[filterIndex].showTasks;
    });

  return (
    <Flex flexDirection="column" gap={5}>
      <TasksInformationsAndFilters
        tasks={tasks}
        tasksFilter={tasksFilter}
        setTasksFilter={setTasksFilter}
      />
      <Flex
        wrap="wrap"
        gap={5}
        overflowY="auto"
        maxH="calc(100vh - 360px)"
        className="thinScrollbar"
        justifyContent="center"
      >
        {filteredTasks &&
          filteredTasks.map((task, index) => (
            <TaskCard key={index} task={task} />
          ))}
      </Flex>
    </Flex>
  );
};
