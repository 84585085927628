import formatValue from 'utils/formatValue';
import { formatValueForSI } from 'utils/formatValueForSI';

export function verifyAndFormatPl(data: string | null): string {
  if (data && formatValue(data).includes('NaN') && data.includes(' e')) {
    const splitedString = data.split('e');

    const firstString =
      splitedString[1] && splitedString[1].replace(/[^\d]+/g, '');
    const secondString =
      splitedString[2] && splitedString[2].replace(/[^\d]+/g, '');

    const firstValue = Number(firstString.substring(0, firstString.length - 2));

    const secondValue =
      secondString &&
      Number(secondString.substring(0, secondString.length - 2));

    return `Entre ${formatValueForSI(firstValue)} e ${formatValueForSI(
      secondValue || 0
    )}`;
  }

  if (data && formatValue(data).includes('NaN') && data.includes('de')) {
    const splitedString = data.split('de')[1].replace(/[^\d]+/g, '');

    const value =
      splitedString &&
      Number(splitedString.substring(0, splitedString.length - 2));

    return value > 0
      ? `Acima de ${formatValueForSI(value || 0)}`
      : 'Não informado';
  }

  if (data && formatValue(data).includes('NaN') && !data.includes('e')) {
    return data;
  }

  if (data) {
    const formatedPl = Number(data);
    if (formatedPl) return formatValueForSI(formatedPl);
  }

  return 'Não informado';
}
