import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';

export enum VisualizationModeEnum {
  MOSAIC = 'mosaic',
  TABLE = 'table',
}

export type VisualizationModeHookType = {
  handleChangeVisualizationMode: (value: VisualizationModeEnum) => void;
  visualizationModeValue: VisualizationModeEnum;
};

export const useVisualizationMode = (): VisualizationModeHookType => {
  const {
    filterValue: visualizationModeValue,
    handleChangeFilterValue: handleChangeVisualizationMode,
  } = useSimpleQueryFilter('visualizationModeValue', {
    initialValue: VisualizationModeEnum.MOSAIC,
  });

  return {
    handleChangeVisualizationMode,
    visualizationModeValue: visualizationModeValue as VisualizationModeEnum,
  };
};
