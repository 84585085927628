import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadShQuestionType } from 'sharedV2/types/leadShQuestion';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useUpdateLeadSalesHunterQuestion = (
  data?: PropsType
): UseMutationResult<void, unknown, LeadShQuestionType, unknown> => {
  return useMutation({
    mutationKey: ['updateLeadShQuestion'],
    mutationFn: async (question: LeadShQuestionType) => {
      await hubApiCaptation.updateLeadShQuestion(question);
    },
    onSuccess: data?.onSuccess,
    onError: data?.onError,
    onMutate: data?.onMutate,
    onSettled: data?.onSettled,
  });
};
