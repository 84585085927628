export enum LeadActionStatusEnum {
  REALIZADA = 'realizada',
  NAO_ATENDEU = 'não atendeu',
  AGUARDANDO = 'aguardando',
}

export type LeadActionType = {
  id: string;
  leadId: string;
  frequencySeq: number;
  actionSeq: number;
  name: string;
  status: LeadActionStatusEnum;
};
