import { FlexProps } from '@chakra-ui/react';
import React from 'react';
import * as S from '../styles';

export const BoxGlass: React.FC<FlexProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return <S.BoxGlass {...rest}>{children}</S.BoxGlass>;
};
