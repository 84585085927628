export const searchOptions = [
  { title: 'Nome', fieldName: 'name', fieldValue: '' },
  { title: 'E-mail', fieldName: 'email', fieldValue: '' },
  { title: 'Mesa', fieldName: 'tableId', fieldValue: '' },
  { title: 'Funções', fieldName: '', fieldValue: '' },
];

export const agrementsPendents = [
  { title: 'Todos', value: 'all' },
  { title: 'Acordos Pendentes', value: 'pendents' },
];

export const permissionsUsers = [
  { title: 'Assessor', value: 'assessor' },
  { title: 'Trainee', value: 'trainee' },
  { title: 'Gestor de mesa', value: 'gestor de mesa' },
  { title: 'Gestor Geral', value: 'Gestor Geral' },
  { title: 'Colaborador', value: 'colaborador' },
  { title: 'Closer Saúde', value: 'Closer Saúde' },
  {
    title: 'Closer Seguros Alternativos',
    value: 'Closer Seguros Alternativos',
  },
  { title: 'Closer Agro', value: 'Closer Agro' },
  { title: 'Closer Imobiliário', value: 'Closer Imobiliário' },
  { title: 'Closer Corporate', value: 'Closer Corporate' },
  { title: 'Closer Vida', value: 'Closer Vida' },
];
