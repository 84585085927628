import React from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { RiMoneyDollarCircleFill, RiUserLocationLine } from 'react-icons/ri';
import { FaArrowDown, FaBirthdayCake } from 'react-icons/fa';
import { IoMdChatboxes } from 'react-icons/io';
import { AiOutlineForm } from 'react-icons/ai';
import { MdMoneyOff } from 'react-icons/md';

import formatValue from 'utils/formatValue';
import { ClientSummaryCardWrapper } from 'pages/MyClients/components/elements';
import { CardsInfos } from 'pages/MyClients/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ComponentProps {
  order: CardsInfos;
  showFrequencyArrow?: boolean;
}

export const ClientSummaryCard: React.FC<ComponentProps> = ({
  order,
  showFrequencyArrow,
}): JSX.Element => {
  const havePendencies =
    order.birthday || order.nps || order.balance || order.fromType;

  return (
    <ClientSummaryCardWrapper havePendencies={havePendencies}>
      <Link
        to={`/user/users_clients/new/${order.btgAccount}`}
        style={{ textDecoration: 'none' }}
      >
        <Flex justifyContent="space-between">
          <Text color="white.100" fontWeight="bold" isTruncated>
            {formatNameToCaptalize(order.name)}
          </Text>

          {showFrequencyArrow && (
            <FaArrowDown
              title="Frequência de contato abaixo do ideal"
              color="red"
            />
          )}
        </Flex>

        <Flex justify="center">
          <Flex flexDir="column" justifyContent="space-around" height="240px">
            <Flex alignItems="center">
              <RiMoneyDollarCircleFill color="#fff" size={30} />
              <Flex flexDir="column" alignItems="center" w="100%" ml="10px">
                <Text fontSize="xs" color="gray.800">
                  Dinheiro Parado:
                </Text>
                <Text>{formatValue(order.todayBalance)}</Text>
              </Flex>
            </Flex>

            <Flex justify="center" align="center">
              <IoMdChatboxes color="#fff" size={30} />

              {order.declaredPLAAI ? (
                <Flex flexDir="column" alignItems="center" w="100%" ml="10px">
                  <Text fontSize="xs" color="gray.800">
                    Patrimônio Declarado (AAI):{' '}
                  </Text>
                  <Text>{formatValue(order.declaredPLAAI)}</Text>
                </Flex>
              ) : (
                <Flex flexDir="column" alignItems="center" w="100%" ml="10px">
                  <Text fontSize="xs" color="gray.800">
                    Patrimônio Declarado:{' '}
                  </Text>
                  <Text>{formatValue(order.declaredPL)}</Text>
                </Flex>
              )}
            </Flex>

            <Flex justify="center" align="center">
              <RiMoneyDollarCircleFill color="#fff" size={30} />
              <Flex flexDir="column" alignItems="center" w="100%" ml="10px">
                <Text fontSize="xs" color="gray.800">
                  PL:{' '}
                </Text>
                <Text>{formatValue(order.pl)}</Text>
              </Flex>
            </Flex>

            {havePendencies && (
              <Box>
                <Text
                  textAlign="center"
                  fontSize="xs"
                  color="gray.800"
                  pb="8px"
                >
                  Pendências:
                </Text>
                <HStack justify="center" mb="3">
                  {order.birthday && (
                    <FaBirthdayCake
                      size={25}
                      color="#fff"
                      title="Aniversário"
                      className="pendenciesIcon"
                    />
                  )}
                  {order.nps && (
                    <AiOutlineForm
                      size={26}
                      color="#fff"
                      title="NPS - Satisfação do Cliente"
                      className="pendenciesIcon"
                    />
                  )}
                  {order.balance && (
                    <MdMoneyOff
                      size={27}
                      color="#fff"
                      title="Saldo Negativo"
                      className="pendenciesIcon"
                    />
                  )}
                  {order.fromType && (
                    <RiUserLocationLine
                      size={26}
                      color="#fff"
                      title="Origem do Cliente"
                      className="pendenciesIcon"
                    />
                  )}
                </HStack>
              </Box>
            )}
            {/* <Box mx="auto">
              <h5>{formatValue(order.alocation[0])}</h5>
            </Box> */}
          </Flex>
        </Flex>
      </Link>
    </ClientSummaryCardWrapper>
  );
};
