import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const BaseLabelText: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize="xs" mb="2" color="gray.200" {...rest}>
      {children}
    </Text>
  );
};
