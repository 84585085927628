import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

export const chakraTheme = extendTheme({
  breakpoints,
  fonts: {
    heading: 'Archivo, system-ui, sans-serif',
    body: 'Archivo, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    '2xl': '36px',
    '3xl': '48px',
    '4xl': '56px',
    '5xl': '64px',
    '6xl': '72px',
  },
  colors: {
    background: {
      900: '#050505',
      800: '#0f0f0f',
      700: '#1a1a1a',
      600: '#242424',
      500: '#2e2e2e',
      400: '#383838',
      300: '#424242',
      200: '#4d4d4d',
      100: '#575757',
    },
    backgroundV2: {
      900: 'red',
      800: '#0f0f0f',
      700: '#1a1a1a',
      600: '#242424',
      500: '#2e2e2e',
      400: '#383838',
      300: '#424242',
      200: '#4d4d4d',
      100: '#575757',
    },
    primary: {
      100: '#6c9aec',
      200: '#588de9',
      300: '#457fe7',
      400: '#3172e4',
      500: '#1d64e2',
      600: '#1b5cce',
      700: '#1853ba',
      800: '#164aa7',
      900: '#134193',
    },
    primaryV2: {
      default: '#1D63DD',
    },
    white: {
      100: '#fafafa',
      200: '#f0f0f0',
      300: '#e5e5e5',
    },
    gray: {
      100: '#e6e6e6',
      200: '#dbdbdb',
      300: '#d1d1d1',
      400: '#c7c7c7',
      500: '#bdbdbd',
      600: '#b3b3b3',
      700: '#a8a8a8',
      800: '#9e9e9e',
      900: '#949494',
    },
  },
});
