import React, { useEffect, useState } from 'react';
import { Text, Stack, Flex } from '@chakra-ui/react';

import { formatDate } from 'utils/formatDate';
import { IUserNotifications } from 'pages/User/subpages/Notifications';
import { DefaultCardWrapper } from 'components/elements/wrappers';

interface ComponentProps {
  notification: IUserNotifications;
}

const NotificationsCard: React.FC<ComponentProps> = ({ notification }) => {
  const [notificationRead, setNotificationRead] = useState(false);

  useEffect(() => {
    if (notification.status === 'read') {
      setNotificationRead(true);
    } else {
      setNotificationRead(false);
    }
  }, [notification.status]);

  // const urls = {
  //   TI: 'board/ti',
  //   MKT: 'board/marketing',
  //   CONTRACT: '/manager/employee/profile/',
  //   RH: '/board/rh',
  //   SPECIFIC: '/board/rv',
  //   STRATEGIC: '/board/rv',
  //   STRUCTURED: '/board/rv',
  // };

  // const handleRedirectRoute = () => {
  //   if (notification.typeOrder !== undefined) {
  //     history.push(`/${urls[notification.typeOrder]}/${notification.to}`);
  //   }
  // };

  const translatedStatus = notification.status === 'read' ? 'Lida' : 'Não lida';

  let formatedDate;
  if (notification.updated_at !== undefined) {
    formatedDate = formatDate(notification.updated_at);
  }

  return (
    <DefaultCardWrapper
      flexDir="column"
      marginBottom="10px"
      color="white"
      width="100%"
      cursor="pointer"
      background={!notificationRead ? 'background.500' : 'background.600'}
      border="1px solid"
      borderColor="background.600"
      _hover={{ borderColor: 'background.300' }}
      // onClick={handleRedirectRoute}
    >
      <Stack spacing="2">
        <Text fontSize="md" fontWeight="bolder">
          {notification.title}
        </Text>
        <Text>{notification.description}</Text>
        <Flex justifyContent="space-between">
          <Text fontWeight="bolder">{formatedDate}</Text>
          <Text fontWeight="bolder">{`Status: ${translatedStatus}`}</Text>
        </Flex>
      </Stack>
    </DefaultCardWrapper>
  );
};

export default NotificationsCard;
