import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiAddToQueue, BiEditAlt, BiTrash } from 'react-icons/bi';
import { Text, Flex, Box, Tooltip } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  IGroups,
  IMembersOfGroup,
} from 'pages/GenerationalVariables/interfaces';
import { Divider } from 'components/elements/others';
import { formatToCapitalized } from 'brazilian-values';
import { AddUserToGroup } from '../AddUserToGroup';
import { CreateGroup } from '../CreateGroup';

interface GroupCardProps {
  group: IGroups;
  handleNotShowGroup: (group: IGroups) => Promise<void>;
  groups: IGroups[];
  setGroups: React.Dispatch<React.SetStateAction<IGroups[]>>;
  getGroups: () => void;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  group,
  handleNotShowGroup,
  groups,
  setGroups,
  getGroups,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleShowModalAddUser = () => {
    setOpenAddModal(!openAddModal);
  };

  const handleShowModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const deleteUser = async (member: IMembersOfGroup) => {
    if (member.groupId === group.id) {
      try {
        const groupIndex = groups.findIndex(
          oneGroup => oneGroup.id === group.id
        );

        // eslint-disable-next-line no-param-reassign
        groups[groupIndex].members = group.members?.filter(
          mebro => mebro.id !== member.id
        );

        await hubApiCaptation.deleteUserOfGroup(member.employeeId, group.id);
        setGroups([...groups]);
        toast.dark('Usuário deletado com sucesso');
      } catch (err) {
        errorHandler(err);
      }
    }
  };

  return (
    <DefaultCardWrapper
      w="100%"
      p="3"
      flexDir="column"
      backgroundColor="#f2f2f2"
    >
      <Flex w="100%" justifyContent="space-between">
        <Flex justifyContent="space-between" flexDir="column">
          <Flex>
            <Text
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
              fontSize={['small', 'md']}
            >
              {group.name}
            </Text>

            <Flex align="center" justify="center">
              <Tooltip
                hasArrow
                label={group.task}
                placement="top"
                bg="background.200"
              >
                <Box ml="3">
                  <AiOutlineInfoCircle color="rgba(70, 70, 70, 1)" />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>

          <Flex>
            <Text color="rgba(70, 70, 70, 1)" fontSize={['small', 'sm']}>
              Dono do lead: {group.owner}
            </Text>
          </Flex>
        </Flex>

        <Flex gridGap="2.5">
          <Tooltip
            hasArrow
            label="Adicionar usuário ao grupo"
            placement="top"
            bg="background.200"
          >
            <Box>
              <BiAddToQueue
                color="rgba(70, 70, 70, 1)"
                size={18}
                style={{ cursor: 'pointer' }}
                onClick={handleShowModalAddUser}
              />
            </Box>
          </Tooltip>

          <Tooltip
            hasArrow
            label="Editar informações do grupo"
            placement="top"
            bg="background.200"
          >
            <Box>
              <BiEditAlt
                size={18}
                color="rgba(70, 70, 70, 1)"
                style={{ cursor: 'pointer' }}
                onClick={handleShowModal}
              />
            </Box>
          </Tooltip>

          {/*
          <Tooltip
            hasArrow
            label="Excluir grupo"
            placement="top"
            color="rgba(70, 70, 70, 1)"
            bg="background.200"
          >
            <Box>
              <BiTrash
                size={18}
                color="#fff"
                style={{ cursor: 'pointer' }}
                onClick={() => handleNotShowGroup(group)}
              />
            </Box>
          </Tooltip>
           */}
        </Flex>
      </Flex>

      <Divider orientation="horizontal" my="1.5" />

      <Flex mt="2" gridGap="2.5" flexWrap="wrap">
        {group.members?.map((item, key) => (
          <Flex
            key={key}
            w="160px"
            alignItems="center"
            justify="space-between"
            border="1px solid"
            borderColor="rgba(70, 70, 70, 1).200"
            borderRadius="2"
            px="2"
            py="1"
            fontSize="sm"
            gridGap="2.5"
          >
            <Text maxW="150px" color="rgba(70, 70, 70, 1)" isTruncated>
              {formatToCapitalized(`${item.name}`, {
                trimTrailingWhiteSpaces: false,
              })}
            </Text>

            <Tooltip
              hasArrow
              label="Deletar usuario do grupo"
              placement="bottom"
              bg="background.200"
            >
              <Box>
                <BiTrash
                  size={15}
                  color="rgba(70, 70, 70, 1)"
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteUser(item)}
                />
              </Box>
            </Tooltip>
          </Flex>
        ))}
      </Flex>

      <ModalWrapper isOpen={openEditModal} onClose={handleShowModal}>
        <CreateGroup
          onClose={handleShowModal}
          setGroups={setGroups}
          groups={groups}
          group={group}
          getGroups={getGroups}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={openAddModal} onClose={handleShowModalAddUser}>
        <AddUserToGroup
          onClose={handleShowModalAddUser}
          group={group}
          setGroups={setGroups}
          getGroups={getGroups}
          groups={groups}
          // deleteUser={deleteUser}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};
