import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import { io } from 'socket.io-client';
import { BackPageButton } from 'components/elements/buttons';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TransferOrderCard } from 'components/elements/cards';
import { formatDate } from 'utils/formatDate';
import { useDisclosure } from '@chakra-ui/react';

import { IRhTransFerOrdersProps } from '../../interfaces';
import OrderInfo from './OrderInfo';

const RhTransferOrders: React.FC = () => {
  const [rhOrders, setRhOrders] = useState([]);

  const [ordersRhSelector, setOrdersRhSelector] =
    useState<IRhTransFerOrdersProps>({} as IRhTransFerOrdersProps);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShowModal = (clickedOrder: IRhTransFerOrdersProps) => {
    setOrdersRhSelector(clickedOrder);
  };

  useEffect(() => {
    listAllOrders();
  }, []);

  async function listAllOrders() {
    await hubApiRH
      .listAllOrders('mosaico')
      .then(response => setRhOrders(response))
      .catch(error => {
        error.response?.data.message !== 'Internal server error' &&
          toast.dark(error.response?.data.message);
      });
  }

  const removeOrder = useCallback(
    id => {
      setRhOrders(rhOrders.filter((item: any) => item.id !== id));
    },
    [rhOrders]
  );

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle color="white" textAlign="center">
        Transferência de ordens - R.H
      </MainPageTitle>

      <TransferOrdersCardsWrapper>
        {rhOrders.map((item: IRhTransFerOrdersProps, index: number) => (
          <TransferOrderCard
            key={index}
            mainText={item.employeename}
            secondaryText={formatDate(item.created_at)}
            onClick={() => {
              handleShowModal(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo
          order={ordersRhSelector}
          handleShowModal={onClose}
          removeOrder={removeOrder}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default RhTransferOrders;
