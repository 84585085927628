export const leadActions = [
  {
    label: 'Leads recebidos',
    value: 'NEWLEAD',
  },
  {
    label: 'Leads declinados',
    value: 'MEETDECLINED',
  },
  {
    label: 'Retornos agendados',
    value: 'CALLBACK',
  },
  {
    label: 'Reuniões marcadas',
    value: 'MEETCREATED',
  },
  {
    label: 'Reuniões roteadas',
    value: 'MEETCONFIRMED',
  },
  {
    label: 'Reagendamento de reuniões',
    value: 'MEETRESCHEDULED',
  },
];
