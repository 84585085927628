import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { EwzAaiCaptationData } from 'pages/Captation/interfaces';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { TableBase } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { DefaultPageWrapper } from 'components/elements/wrappers';

import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { ewzCaptationTableHead } from './data';
import { TableAAIEwz } from '../TableAAIEwz';

interface EwzCaptationProps {
  offices: {
    id: string;
    city: string;
  }[];
  initialOfficeId: string;
}

type Table = {
  id: string;
  name: string;
};

export const EwzCaptation: React.FC<EwzCaptationProps> = ({
  offices,
  initialOfficeId,
}) => {
  const [selectedOfficeId, setSelectedOfficeId] = useState('');
  const [tables, setTables] = useState<Table[]>([]);

  const [selectedTableId, setSelectedTableId] = useState('');

  const [aaiList, setAaiList] = useState<EwzAaiCaptationData[]>([]);
  const [aaiListCopy, setAaiListCopy] = useState<EwzAaiCaptationData[]>([]);

  const [noSearchResults, setNoSearchResults] = useState(false);

  const filterEmployees = () => {
    if (selectedTableId) {
      const result = aaiList.filter(aaiListOne =>
        aaiListOne.tableId.trim().includes(selectedTableId)
      );
      setAaiListCopy(result);

      if (result.length < 1) {
        setNoSearchResults(true);
      } else {
        setNoSearchResults(false);
      }
    } else {
      setAaiListCopy(aaiList);
      setNoSearchResults(false);
    }
  };

  const getInitialTables = async (): Promise<void> => {
    try {
      const tablesData = await hubApiOfficeTables.listTablesByOffice(
        initialOfficeId
      );
      setTables(tablesData);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getTablesByOffice = async (): Promise<void> => {
    setNoSearchResults(false);

    try {
      if (selectedOfficeId !== '') {
        const data = await hubApiOfficeTables.listTablesByOffice(
          selectedOfficeId
        );
        setTables(data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const getInfos = async () => {
    try {
      const data = await hubApiCaptation.getDateAAIEWZ();
      setAaiListCopy(data);
      setAaiList(data);
    } catch (err) {
      errorHandler(err);
    }
  };
  useEffect(() => {
    getInfos();
    getInitialTables();
  }, []);

  useEffect(() => {
    filterEmployees();
  }, [selectedTableId]);

  useEffect(() => {
    getTablesByOffice();
  }, [selectedOfficeId]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Gestão AAI (EWZ)</MainPageTitle>

      <Flex w="100%" justifyContent="flex-end" mb={2}>
        <Dropdown
          placeholder="Selecione um escritório"
          onChange={e => setSelectedOfficeId(e.target.value)}
        >
          {offices.map(office => (
            <option
              key={office.id}
              value={office.id}
              selected={office.id === initialOfficeId}
            >
              {office.city}
            </option>
          ))}
        </Dropdown>

        <Dropdown
          placeholder="Todos os AAIs"
          ml={1}
          onChange={e => setSelectedTableId(e.target.value)}
        >
          {tables.map(table => (
            <option key={table.id} value={table.id}>
              {table.name}
            </option>
          ))}
        </Dropdown>
      </Flex>

      <TableBase
        headData={ewzCaptationTableHead}
        p="0"
        w="100%"
        height="100vh"
        maxH="calc(100vh - 254px)"
        overflowY="auto"
      >
        {aaiListCopy.map(item => (
          <TableAAIEwz aaiList={item} key={item.id} />
        ))}
      </TableBase>

      {noSearchResults && (
        <Text mt={10}>Nenhum Assessor(a) foi encontrado nesta mesa</Text>
      )}
    </DefaultPageWrapper>
  );
};
