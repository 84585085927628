import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { KanbanBaseTaskProps } from 'shared/interfaces';
import {
  InputWithLabel,
  Textarea,
  TextareaWithLabel,
} from 'components/elements/forms';

import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { Flex, Text } from '@chakra-ui/react';

import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { closeOrderOptions, resonsOptions } from './data';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
}

interface ComponentProps {
  order: TaskProps;
  handleToggleModal: () => void;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IOrderData {
  status: string;
  boleted: boolean;
  boletValue: number;
  description: string;
  reasonNotBolet: string;
  operationValue: number;
}

const CloseOrder: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const [selectedOption, setSelectedOption] = useState('Escolha uma opção');

  const [orderData, setOrderData] = useState<IOrderData>({} as IOrderData);

  const handleSelectReason = (indication: string) => {
    setOrderData({
      ...orderData,
      reasonNotBolet: indication.toLowerCase(),
    });
  };

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);

    if (option === closeOrderOptions[0].title) {
      setOrderData({
        ...orderData,
        boleted: true,
        boletValue: 0,
        reasonNotBolet: '',
        description: '',
      });
    } else {
      setOrderData({
        ...orderData,
        boleted: false,
        boletValue: 0,
        reasonNotBolet: '',
        description: '',
      });
    }
  };

  const handleChangeTextValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOrderData({
      ...orderData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (selectedOption === 'Escolha uma opção') {
      toast.dark('Você deve escolher uma ação para finalizar ordem');
      return;
    }

    if (
      selectedOption === closeOrderOptions[1].title &&
      orderData.reasonNotBolet.trim() === ''
    ) {
      toast.dark('Você deve escolher um motivo para cancelar a ordem');
      return;
    }

    if (
      selectedOption === closeOrderOptions[0].title &&
      !orderData.boletValue
    ) {
      toast.dark('Você deve indicar o valor boletado');
      return;
    }

    const sendOrderToBackEnd = { ...orderData, status: 'Finalizada' };

    await hubApiIp
      .updateOrderStatus(order.id, sendOrderToBackEnd)
      .then(() => {
        setIsFinished(true);
        toast.dark(
          'Orderm finalizada com sucesso. A mesma será removida ao recarregar a tela'
        );
        handleToggleModal();
      })
      .catch(error => {
        error.response?.data.message !== 'Internal server error' &&
          toast.dark(error.response?.data.message);
      });
  };

  return (
    <DefaultModalContentWrapper maxW="440px">
      <ModalTitle>Finalizar ordem</ModalTitle>

      <form onSubmit={handleFormSubmit}>
        <BaseLabelText>Esta ordem será:</BaseLabelText>

        <Dropdown
          mb="4"
          placeholder="Escolha uma das opções"
          onChange={e => handleSelectOption(e.target.value)}
        >
          {closeOrderOptions.map((item, index) => (
            <option key={index} value={item.title}>
              {item.title}
            </option>
          ))}
        </Dropdown>

        {selectedOption === closeOrderOptions[1].title && (
          <>
            <Dropdown
              mb="4"
              placeholder="Escolha uma das opções"
              onChange={e => handleSelectReason(e.target.value)}
            >
              {resonsOptions.map((item, index) => (
                <option key={index} value={item.title}>
                  {item.title}
                </option>
              ))}
            </Dropdown>

            <Textarea
              mb="3"
              placeholder="Descreva o motivo do cancelameto..."
              name="description"
              value={orderData.description}
              onChange={handleChangeTextValue}
            />
          </>
        )}

        {selectedOption === closeOrderOptions[0].title && (
          <>
            <Flex flexDirection="column" gap={2}>
              <InputWithLabelAndFormatingToMoney
                label="Receita Total:"
                value={orderData.boletValue ?? 0}
                placeholder="R$ 0"
                onChange={value => {
                  setOrderData({
                    ...orderData,
                    boletValue: Number(value),
                  });
                }}
              />
              <InputWithLabelAndFormatingToMoney
                label="Valor da Operação:"
                value={orderData.operationValue ?? 0}
                placeholder="R$ 0"
                onChange={value => {
                  setOrderData({
                    ...orderData,
                    operationValue: Number(value),
                  });
                }}
              />
            </Flex>

            <Text mt="2" mb="4">
              <TextareaWithLabel
                label="Observações"
                placeholder="Observações sobre a ordem boletada"
                name="description"
                value={orderData.description}
                onChange={handleChangeTextValue}
              />
            </Text>
          </>
        )}

        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default CloseOrder;
