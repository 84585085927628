import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box } from '@chakra-ui/react';

import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others/';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { CardsInfos, ClientInfosType } from 'pages/MyClients/interfaces';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import {
  leadOrigin,
  hubLeadType,
  indicationLeadType,
  prospectLeadType,
} from './data';

interface WhereDidThisCustomerComeFromProps {
  clientInfos: ClientInfosType;
  setClientInfos: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  handleBackPage: () => void;
  onClose: () => void;
}

type LeadType =
  | 'Leads Hub'
  | 'Indicação'
  | 'Prospecção independente'
  | 'Lead Mundo Invest'
  | 'Não sei';
type HubLeadType =
  | 'Lead Mundo Invest'
  | 'Lead Minerado'
  | 'Lead Pessoal'
  | 'Lead PJ';
type IndicationLeadType =
  | 'Amigos, família'
  | 'Código do responsável'
  | 'Outro cliente';
type ProspectLeadType = 'LinkedIn' | 'Evento' | 'Instagram/Facebook' | 'Outro';
type Answer = HubLeadType | IndicationLeadType | ProspectLeadType;

export const WhereDidThisCustomerComeFrom: React.FC<WhereDidThisCustomerComeFromProps> =
  ({ handleBackPage, setClientInfos, clientInfos, onClose }): JSX.Element => {
    const [clients, setClients] = useState<CardsInfos[]>([]);

    const handleGetClients = async () => {
      try {
        const response = await hubApiUser.listMyCLientsByClient('all', 'pl');

        setClients(response);
      } catch (err) {
        toast.dark('Erro ao buscar os clientes!');
      }
    };

    useEffect(() => {
      handleGetClients();
    }, []);

    const [typeLead, setTypeLead] = useState<LeadType>();
    const [answer, setAnswer] = useState<Answer>();
    const [formInfos, setFormInfos] = useState({
      aditionalInformation: '',
    });

    const handleChangeValue = (
      e: React.ChangeEvent<HTMLInputElement> | string
    ): void => {
      if (typeof e === 'string') {
        setFormInfos({ ...formInfos, aditionalInformation: e });
      } else {
        setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
      }
    };

    const handleUpdatetypeLead = (e: LeadType): void => {
      setTypeLead(e);
    };

    const handleFormSubmit = async (
      e: FormEvent
    ): Promise<React.ReactText | void> => {
      e.preventDefault();

      if (typeLead === 'Não sei') {
        try {
          const response = await hubApiUser.updateClientsContactOrigin({
            id: clientInfos.contactId,
            fromType: typeLead,
            fromTypeLead: 'Não sei',
            additionalInformation: formInfos.aditionalInformation,
          });

          setClientInfos({
            ...clientInfos,
            fromType: response.fromType,
            fromTypeLead: response.fromTypeLead,
            additionalInformation: response.additionalInformation,
          });

          if (clientInfos.fromTypeId) {
            await hubApiHome.deleteHomeAssignmentById(clientInfos.fromTypeId);
          }

          toast.dark(`Dados atualizados com sucesso`);
          onClose();

          return;
        } catch (err) {
          return toast.dark(`Erro ao definir a origem o cliente`);
        }
      }

      if (typeLead && !answer) {
        try {
          const response = await hubApiUser.updateClientsContactOrigin({
            id: clientInfos.contactId,
            fromType: typeLead,
            fromTypeLead: 'Lead Mundo Invest',
            additionalInformation: formInfos.aditionalInformation,
          });

          setClientInfos({
            ...clientInfos,
            fromType: response.fromType,
            fromTypeLead: response.fromTypeLead,
            additionalInformation: response.additionalInformation,
          });

          if (clientInfos.fromTypeId) {
            await hubApiHome.deleteHomeAssignmentById(clientInfos.fromTypeId);
          }

          toast.dark(`Dados atualizados com sucesso`);
          onClose();

          return;
        } catch (err) {
          toast.dark(`Erro ao definir a origem o cliente`);
        }
      }

      if (!answer || !typeLead) {
        return toast.dark('Preencha todas as informações');
      }

      try {
        const response = await hubApiUser.updateClientsContactOrigin({
          id: clientInfos.contactId,
          fromType: typeLead,
          fromTypeLead: answer,
          additionalInformation: formInfos.aditionalInformation,
        });

        setClientInfos({
          ...clientInfos,
          fromType: response.fromType,
          fromTypeLead: response.fromTypeLead,
          additionalInformation: response.additionalInformation,
        });

        if (clientInfos.fromTypeId) {
          await hubApiHome.deleteHomeAssignmentById(clientInfos.fromTypeId);
        }

        toast.dark(`Dados atualizados com sucesso`);
        onClose();

        return;
      } catch (err) {
        toast.dark(`Erro ao definir a origem o cliente`);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
      // O if abaixo faz com que se o usuario aperte a tecla "esc"
      // ele nao consiga "burlar" e entrar no painel do client
      if (event.keyCode === 27) event.preventDefault();
    };

    return (
      <>
        <DefaultModalContentWrapper maxW="500px">
          <form
            onSubmit={handleFormSubmit}
            onKeyDown={event => handleKeyDown(event)}
          >
            <Flex flexDir="column">
              <ModalTitle fontSize="lg">
                Como esse cliente foi captado ?
              </ModalTitle>

              <Flex align="center" justify="space-between">
                <Dropdown
                  placeholder="Escolha uma das opções"
                  onChange={e =>
                    handleUpdatetypeLead(e.target.value as LeadType)
                  }
                >
                  {leadOrigin.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Dropdown>

                {typeLead === 'Leads Hub' && (
                  <Dropdown
                    placeholder="Qual o tipo do lead"
                    onChange={e => setAnswer(e.target.value as Answer)}
                  >
                    {hubLeadType.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Dropdown>
                )}

                {typeLead === 'Indicação' && (
                  <Flex flexDir="column">
                    <Dropdown
                      placeholder="Escolha o tipo de indicação"
                      onChange={e => setAnswer(e.target.value as Answer)}
                    >
                      {indicationLeadType.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Dropdown>

                    {(answer === 'Amigos, família' ||
                      answer === 'Outro cliente') && (
                      <Dropdown
                        mt="3"
                        maxW="230px"
                        placeholder="Escolha o cliente"
                        onChange={e => handleChangeValue(e.target.value)}
                      >
                        {clients.map(item => (
                          <option
                            key={item.name}
                            value={`${item.name}-${item.btgAccount}`}
                          >
                            {item.name} - {item.btgAccount}
                          </option>
                        ))}
                      </Dropdown>
                    )}

                    {answer === 'Código do responsável' && (
                      <Box mt="2">
                        <InputWithLabel
                          label="Codigo BTG"
                          name="aditionalInformation"
                          onChange={handleChangeValue}
                        />
                      </Box>
                    )}
                  </Flex>
                )}

                {typeLead === 'Prospecção independente' && (
                  <Flex flexDir="column">
                    <Dropdown
                      placeholder="Escolha um tipo"
                      onChange={e => setAnswer(e.target.value as Answer)}
                    >
                      {prospectLeadType.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Dropdown>

                    {answer === 'Evento' && (
                      <Box mt="2">
                        <InputWithLabel
                          label="Qual o evento?"
                          name="aditionalInformation"
                          onChange={handleChangeValue}
                        />
                      </Box>
                    )}

                    {answer === 'Outro' && (
                      <Box mt="2">
                        <InputWithLabel
                          label="Como esse cliente foi captado?"
                          name="aditionalInformation"
                          onChange={handleChangeValue}
                        />
                      </Box>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>

            <BaseButtonGroup mt="5">
              <SecondaryButton onClick={handleBackPage}>
                Cancelar
              </SecondaryButton>
              <PrimaryButton type="submit">Confirmar</PrimaryButton>
            </BaseButtonGroup>
          </form>
        </DefaultModalContentWrapper>
      </>
    );
  };
