import React, { ReactText, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Flex, ButtonGroup, Stack } from '@chakra-ui/react';

import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { ModalTitle, BaseLabelText } from 'components/elements/texts';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { InputNumberWithLabel } from 'pages/products/components/elements/forms/InputNumberWithLabel';

import {
  ModalWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { InvestmentFundProps } from 'pages/products/interfaces';

interface CreateCoeFormProps {
  investmentFundData: InvestmentFundProps;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  handleSubmitForm: (e: any) => Promise<ReactText | undefined | void>;
  nameButton: string;
}

interface SelectItensProps {
  name: string;
  title: string;
}

export const CreateInvestmentFundForm: React.FC<CreateCoeFormProps> = ({
  investmentFundData,
  setValue,
  handleSubmitForm,
  nameButton,
}) => {
  const history = useHistory();

  const [risks] = useState<SelectItensProps[]>([
    { name: 'Baixo', title: 'Baixo' },
    { name: 'Moderado', title: 'Moderado' },
    { name: 'Alto', title: 'Alto' },
  ]);

  const [types] = useState<SelectItensProps[]>([
    { name: 'Fundo de Liquidez', title: 'Fundo de Liquidez' },
    { name: 'Fundo de Renda Fixa', title: 'Fundo de Renda Fixa' },
    { name: 'Fundo Multimercado', title: 'Fundo Multimercado' },
    { name: 'Fundo de Ações', title: 'Fundo de Ações' },
    { name: 'Fundos Alternativos', title: 'Fundos Alternativos' },
    { name: 'Previdência', title: 'Previdência' },
  ]);

  const handleSetProfitability = useCallback(
    e => {
      setValue({
        ...investmentFundData,
        profitability: {
          ...investmentFundData.profitability,
          [e.target.name]: e.target.value,
        },
      });
    },
    [investmentFundData]
  );

  const handleValidatePercentage = useCallback(value => {
    if (Number(value) < 0 || Number(value) > 300) {
      toast.dark('A porcentagem deve ser entre 0% e 300%');
      return false;
    }

    return true;
  }, []);

  const handleSetPercentage = useCallback(
    e => {
      if (handleValidatePercentage(e.target.value)) {
        setValue({
          ...investmentFundData,
          [e.target.name]: e.target.value,
        });
      }
    },
    [investmentFundData, handleValidatePercentage]
  );

  const handleChangeValue = (e: {
    target: { name: string; value: string };
  }) => {
    const filds = ['name', 'nameTec', 'liquidation', 'quotation'];
    setValue({
      ...investmentFundData,
      [e.target.name]: filds.includes(e.target.name)
        ? e.target.value.toUpperCase()
        : e.target.value,
    });
  };

  const handleClickCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper maxW={['95%', '760px']}>
        <ModalTitle>Preencha os dados do fundo:</ModalTitle>
        <Flex
          w="100%"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          height={['400px', 'auto']}
          overflowY={['auto', 'unset']}
        >
          <Stack spacing="4" w={['100%', '48%']}>
            <InputWithLabel
              value={investmentFundData.name}
              label="Nome"
              placeholder="Nome"
              name="name"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <InputWithLabel
              value={investmentFundData.aplication}
              label="Aplicação"
              type="number"
              placeholder="Aplicação mínima"
              name="aplication"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <InputWithLabel
              type="text"
              value={investmentFundData.liquidation}
              label="Liquidação"
              placeholder="Liquidação resgate"
              name="liquidation"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <InputWithLabel
              w={['95%', '100%']}
              type="text"
              value={investmentFundData.quotation}
              label="Cotização"
              placeholder="Cotização resgate"
              name="quotation"
              onChange={e => handleChangeValue(e)}
            />
            <Stack
              flexDir="row"
              spacing="0"
              w={['95%', '100%']}
              justifyContent="space-between"
              gridGap="6px"
            >
              <InputNumberWithLabel
                w="100%"
                stepper={false}
                label="Taxa de adm"
                value={investmentFundData.taxaAdm}
                place="1.2% (a.a)"
                name="taxaAdm"
                onChangeCapture={e => handleSetPercentage(e)}
              />
              <InputNumberWithLabel
                w="100%"
                stepper={false}
                label="Taxa de Performance"
                value={investmentFundData.taxaActing}
                place="20%"
                name="taxaActing"
                onChangeCapture={e => handleSetPercentage(e)}
              />
            </Stack>

            <DropdownWithLabel
              w={['95%', '100%']}
              label="Selecione o risco da Carteira"
              onChange={e => handleChangeValue(e)}
              name="risck"
              value={investmentFundData.risck}
            >
              {risks.map((item, index) => {
                return (
                  <option key={index} value={item.name}>
                    {item.title}
                  </option>
                );
              })}
            </DropdownWithLabel>

            <DropdownWithLabel
              w={['95%', '100%']}
              label="Selecione um tipo de Fundo"
              onChange={e => handleChangeValue(e)}
              name="type"
              value={investmentFundData.type}
            >
              {types.map((item, index) => {
                return (
                  <option key={index} value={item.name}>
                    {item.title}
                  </option>
                );
              })}
            </DropdownWithLabel>
          </Stack>

          <Stack spacing="4" w={['100%', '48%']}>
            <InputWithLabel
              value={investmentFundData.nameTec}
              type="text"
              label="Nome Técnico"
              placeholder="Nome completo (FIC, FIA... etc)"
              name="nameTec"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <InputWithLabel
              value={investmentFundData.cnpj}
              type="text"
              placeholder="Sem digitos"
              label="CNPJ do Fundo"
              name="cnpj"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <Flex flexDir="column">
              <BaseLabelText fontSize="sm">Rentabilidade</BaseLabelText>
              <Stack
                flexDir="row"
                spacing="0"
                justifyContent="space-between"
                w={['95%', '100%']}
              >
                <InputNumberWithLabel
                  label="Mês"
                  value={investmentFundData.profitability?.month}
                  stepper={false}
                  place="%"
                  w={['95%', '100%']}
                  name="month"
                  onChangeCapture={handleSetProfitability}
                />
                <InputNumberWithLabel
                  label="Ano"
                  value={investmentFundData?.profitability?.yaer}
                  stepper={false}
                  w={['95%', '100%']}
                  place="%"
                  name="yaer"
                  onChangeCapture={handleSetProfitability}
                />
                <InputNumberWithLabel
                  label="12 Meses"
                  value={investmentFundData?.profitability?.twelveMonth}
                  stepper={false}
                  place="%"
                  name="twelveMonth"
                  onChangeCapture={handleSetProfitability}
                  w={['95%', '100%']}
                />
              </Stack>
            </Flex>

            <TextareaWithLabel
              label="Estratégia"
              value={investmentFundData.strategy}
              name="strategy"
              w={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
              placeholder="Digite a Estratégia"
              rows={5}
            />
          </Stack>
        </Flex>
        <ButtonGroup mt="12px" justifyContent="flex-end">
          <SecondaryButton onClick={handleClickCancelButton}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm}>{nameButton}</PrimaryButton>
        </ButtonGroup>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};
