/* eslint no-unused-vars: off */
import React from 'react';
import { Text } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface ClientProps {
  id: string;
  name: string;
  email: string;
  btgAccount: string;
}

interface FormInfosProps {
  clientId: string;
  risk: string;
  value: string;
  segment: string;
}

interface ComponentProps {
  segment: string;
  selectedClient: ClientProps;
  formInfos: FormInfosProps;
  interestTitle?: string;
  handleShowModal: () => void;
  handleFormSubmit: (
    event: React.FormEvent<HTMLFormElement>
  ) => Promise<React.ReactText | undefined>;
}

const ConfirmForms: React.FC<ComponentProps> = ({
  handleShowModal,
  handleFormSubmit,
  formInfos,
  selectedClient,
  segment,
  interestTitle,
}) => {
  return (
    <DefaultModalContentWrapper maxW="480px">
      {interestTitle ? (
        <Text color="white" fontWeight="medium" marginBottom="4">
          Você deseja que o cliente {selectedClient.name}, código BTG:{' '}
          {selectedClient.btgAccount}, deseja alocar {formInfos.value} em{' '}
          {segment} {interestTitle}
        </Text>
      ) : (
        <Text color="white" fontWeight="medium" marginBottom="4">
          Você deseja que o cliente {selectedClient.name}, código BTG:{' '}
          {selectedClient.btgAccount}, deseja alocar {formInfos.value} em{' '}
          {segment}
        </Text>
      )}

      <form onSubmit={handleFormSubmit}>
        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleShowModal}>
            Não
          </SecondaryButton>
          <PrimaryButton type="submit">Sim, desejo confirmar</PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default ConfirmForms;
