import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  ModalWrapper,
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { closeOrderOptions } from 'pages/Exchange/data';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { Textarea } from 'components/elements/forms';
import { Text } from '@chakra-ui/react';
import { BoardOrderInfo } from '../exchangeCRM/BoardOrderInfo';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
  description?: string;
}

interface ComponentProps {
  order: TaskProps;
  handleToggleModal: () => void;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseOrder: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const [selectedOption, setSelectedOption] = useState('Escolha uma opção');
  const [selectedReason, setSelectedReason] = useState('Escolha um motivo');
  const [toggleSubModal, setToggleSubModal] = useState(false);
  const [reasonDescription, setReasonDescription] = useState('');

  const handleToggleSubModal = () => setToggleSubModal(!toggleSubModal);

  const handleSelectReason = (indication: any) =>
    setSelectedReason(indication.title);

  const handleChangeTextValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setReasonDescription(e.target.value);

  const handleSelectOption = (option: any) => {
    setSelectedOption(option.title);

    if (option.title === closeOrderOptions[0].title) {
      setToggleSubModal(!toggleSubModal);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedOption === 'Escolha uma opção') {
      toast.dark('Você deve escolher uma ação para a ordem');
      return;
    }

    if (
      selectedOption === closeOrderOptions[1].title &&
      selectedReason === 'Escolha um motivo'
    ) {
      toast.dark('Você deve escolher um motivo para cancelar a ordem');
      return;
    }

    await hubApiExchange
      .updateOrder(order.id, {
        status: 'cancelada',
        intention: selectedReason,
        description:
          reasonDescription.trim() !== ''
            ? reasonDescription
            : order.description,
      })
      .then(() => {
        toast.dark(
          'Orderm finalizada com sucesso. A mesma será removida ao recarregar a tela'
        );

        setIsFinished(true);
        handleToggleModal();
      })
      .catch(() => toast.dark('Erro ao tentar finalizar ordem'));
  };

  return (
    <DefaultModalContentWrapper maxW="440px">
      <ModalTitle>Finalizar Ordem</ModalTitle>

      <form onSubmit={handleFormSubmit}>
        <BaseLabelText>Esta ordem será:</BaseLabelText>
        <Dropdown
          mb="4"
          placeholder="Escolha uma das opções"
          onChange={e => handleSelectOption(e.target.value)}
        >
          {closeOrderOptions.map((item, index) => (
            <option key={index} value={item.title}>
              {item.title}
            </option>
          ))}
        </Dropdown>

        {selectedOption === closeOrderOptions[1].title && (
          <Textarea
            mb="3"
            placeholder="Descreva o motivo do cancelamento"
            name="description"
            value={reasonDescription}
            onChange={handleChangeTextValue}
          />
        )}

        {selectedOption === closeOrderOptions[0].title && (
          <Text fontSize="sm" fontWeight="semibold" color="white" mt="5" mb="3">
            Você confirma que deseja finalizar essa solicitação?
          </Text>
        )}

        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
        </BaseButtonGroup>
      </form>

      <ModalWrapper isOpen={toggleSubModal} onClose={handleToggleSubModal}>
        <BoardOrderInfo
          order={order}
          handleToggleModal={handleToggleSubModal}
          /* handleToggleCloseOrder={handleToggleModal} */
          setIsFinished={setIsFinished}
          isFinishOrder
        />
      </ModalWrapper>
    </DefaultModalContentWrapper>
  );
};

export default CloseOrder;
