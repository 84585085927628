import { TableData, TableRow } from 'components/elements/table';
import { ModalWrapper } from 'components/elements/wrappers';
import {
  IEmployeesDistributionInfo,
  IPoolList,
} from 'pages/DistributionOfMinedLeads/interfaces';
import React, { useState } from 'react';
import { LeadSubmissionQtdModal } from '../../modals/LeadSubmissionQtd';
import { ManualLeadDistributionModal } from '../../modals/ManualLeadDistribution';
import { formatNameToCaptalize } from '../../../../../utils/captalizeNames';
import { DistributionSwitch } from './DistributionSwitch';
import { DropdownList } from './DropdownList';
import { QtdSendLeads } from './QtdSendLeads';
import { SaveChanges } from './SaveChanges';
import { SendManualLeads } from './SendManualLeads';

interface EmployeeRowProps {
  employee: IEmployeesDistributionInfo;
  allPoolLists: IPoolList[];
  employeesData: IEmployeesDistributionInfo[];
  setEmployeesData: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo[]>
  >;
}

export const EmployeeRow: React.FC<EmployeeRowProps> = ({
  employee,
  allPoolLists,
  employeesData,
  setEmployeesData,
}) => {
  const [leadSubmissionQtd, setLeadSubmissionQtd] = useState<number>(0);
  const [isSaved, setIsSaved] = useState(true);
  const [employeeSingleRow, setEmployeeSingleRow] = useState({
    ...employee,
  } as IEmployeesDistributionInfo);
  const [sendEmployeeId, setSendEmployeeId] = useState('');
  const [showLeadSubmissionqtdModal, setShowLeadSubmissionqtdModal] =
    useState(false);
  const [showManualLeadDistributionModal, setShowManualLeadDistributionModal] =
    useState(false);

  const handleValueChangeInLeadSubmissionModal = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmployeeSingleRow({
      ...employeeSingleRow,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <TableRow
        _hover={{ background: '#f2f2f2' }}
        transition="400ms"
        key={employeeSingleRow.employeeId}
      >
        <TableData maxWidth={200}>
          {formatNameToCaptalize(employeeSingleRow.employeeName)}
        </TableData>
        <TableData maxWidth={200}>{employeeSingleRow.tableName}</TableData>
        <TableData maxWidth={100}>{employeeSingleRow.leadsMinedQtd}</TableData>
        <TableData maxWidth={100}>{employeeSingleRow.leadsListQtd}</TableData>

        <TableData maxWidth={240} width={240}>
          <DropdownList
            setEmployeeSingleRow={setEmployeeSingleRow}
            employeeSingleRow={employeeSingleRow}
            allPoolLists={allPoolLists}
            setIsSaved={setIsSaved}
          />
        </TableData>

        <TableData>
          <QtdSendLeads
            employeeSingleRow={employeeSingleRow}
            setIsSaved={setIsSaved}
            setShowLeadSubmissionqtdModal={setShowLeadSubmissionqtdModal}
            setLeadSubmissionQtd={setLeadSubmissionQtd}
          />
        </TableData>

        <TableData maxWidth="85px" width="85px">
          <SendManualLeads
            setShowManualLeadDistributionModal={
              setShowManualLeadDistributionModal
            }
            setSendEmployeeId={setSendEmployeeId}
            employee={employee}
          />
        </TableData>

        <TableData maxWidth="85px" width="85px">
          <DistributionSwitch
            employeeSingleRow={employeeSingleRow}
            setIsSaved={setIsSaved}
            setEmployeeSingleRow={setEmployeeSingleRow}
          />
        </TableData>

        <TableData maxWidth="85px" width="85px">
          <SaveChanges
            isSaved={isSaved}
            employeeSingleRow={employeeSingleRow}
            setIsSaved={setIsSaved}
            employeesData={employeesData}
            setEmployeesData={setEmployeesData}
            setEmployeeSingleRow={setEmployeeSingleRow}
          />
        </TableData>
      </TableRow>

      <ModalWrapper
        isOpen={showLeadSubmissionqtdModal}
        onClose={() => {
          setShowLeadSubmissionqtdModal(false);
        }}
      >
        <LeadSubmissionQtdModal
          leadSubmissionQtd={employeeSingleRow.sendLeadsQtd}
          handleValueChangeInLeadSubmissionModal={
            handleValueChangeInLeadSubmissionModal
          }
          onClose={() => {
            setShowLeadSubmissionqtdModal(false);
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showManualLeadDistributionModal}
        onClose={() => setShowManualLeadDistributionModal(false)}
      >
        <ManualLeadDistributionModal
          allPoolLists={allPoolLists}
          employeeSingleRow={employeeSingleRow}
          setEmployeeSingleRow={setEmployeeSingleRow}
          onClose={() => setShowManualLeadDistributionModal(false)}
          sendEmployeeId={sendEmployeeId}
        />
      </ModalWrapper>
    </>
  );
};
