/* eslint no-unused-vars: off */
import { Flex, FlexProps } from '@chakra-ui/react';
import { useCaptationMeetingsContext } from 'hooks';
import { MeetingTypeMenuCard } from '../MeetingTypeMenuCard';

interface ComponentProps extends FlexProps {
  handleSelectMeetingType: (
    segment:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'pipe',
    title: string,
    type: string
  ) => void;
  titleSelected: string;
}

export const MeetingTypesMenu: React.FC<ComponentProps> = ({
  titleSelected,
  handleSelectMeetingType,
  ...rest
}) => {
  const { suitability, returns, presentation, allocation, openAccount } =
    useCaptationMeetingsContext();

  return (
    <Flex
      flexDir={['row', 'column']}
      gridGap="2"
      {...rest}
      width={['100%', 'auto']}
      overflowX={['auto', 'unset']}
      pb={['2', 'none']}
      className="meetingsTypesMenuCaptation"
    >
      <Flex
        gridGap={2}
        h={{
          base: '100px',
          '2xl': '150px',
        }}
      >
        <MeetingTypeMenuCard
          title="Retornos "
          leads={returns}
          onClick={() =>
            handleSelectMeetingType('callback', 'Retornos', 'return')
          }
          titleSelected={titleSelected}
        />
      </Flex>
      <>
        <MeetingTypeMenuCard
          title="R1 -  Suitability"
          leads={suitability}
          onClick={() => {
            handleSelectMeetingType(
              'suitability',
              'R1 -  Suitability',
              'meeting'
            );
          }}
          titleSelected={titleSelected}
        />

        <MeetingTypeMenuCard
          title="R2 - Carteira"
          leads={presentation}
          titleSelected={titleSelected}
          onClick={() =>
            handleSelectMeetingType('presentation', 'R2 - Carteira', 'meeting')
          }
        />
        <MeetingTypeMenuCard
          title="Aguardando abertura"
          leads={allocation}
          titleSelected={titleSelected}
          onClick={() =>
            handleSelectMeetingType(
              'allocation',
              'Aguardando abertura',
              'meeting'
            )
          }
        />
        <MeetingTypeMenuCard
          title="Aporte"
          leads={openAccount}
          titleSelected={titleSelected}
          onClick={() =>
            handleSelectMeetingType('account', 'Alocação', 'meeting')
          }
        />
      </>
    </Flex>
  );
};
