import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { Divider } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { ConsolidationAAI } from 'pages/GenerationalVariables/components/modules/ConsolidationAAI';
import { ConsolidationSh } from 'pages/GenerationalVariables/components/modules/ConsolidationSH';
import { IBigNumberMkt } from 'pages/GenerationalVariables/interfaces';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';

export const CapitationMiWeeklyConsolidation: React.FC = () => {
  const [bigNumbersMkt, setBigNumbersMkt] = useState({} as IBigNumberMkt);

  const handleGetBigNumbersMktDate = async () => {
    try {
      const response = await hubApiMarketing.getBigNumbersMkt();

      setBigNumbersMkt(response);
    } catch (err) {
      toast.dark(
        'Ocorreu um erro ao buscar os dados, entre em contato com o suporte!'
      );
    }
  };

  useEffect(() => {
    handleGetBigNumbersMktDate();
  }, []);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle align="center" mb="2">
        Captação MI - Consolidação semanal
      </MainPageTitle>

      <Flex
        flexDir="column"
        gridGap={6}
        justify="space-between"
        h="100%"
        maxH="calc(100vh - 400px)"
        w="100vw"
        maxW="1180px"
      >
        <ConsolidationSh bigNumbersMkt={bigNumbersMkt} />
        <Divider />
        <ConsolidationAAI bigNumbersMkt={bigNumbersMkt} />
      </Flex>
    </DefaultPageWrapper>
  );
};
