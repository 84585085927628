import { Flex, Box, Stack, Text } from '@chakra-ui/react';
import { TitleWithBorder } from 'pages/GenerationalVariables/components/elements/others/TitleWithBorder';
import { CurrentScore } from 'pages/GenerationalVariables/components/elements/others/CurrentScore';
import { IRAnkingSH } from 'pages/GenerationalVariables/interfaces';

interface CurrentAaiPointsProps {
  data: IRAnkingSH[];
}

export const CurrentAaiPoints: React.FC<CurrentAaiPointsProps> = ({ data }) => {
  return (
    <Flex w="100%" flexDir="column" mt={4}>
      <TitleWithBorder label="Pontuação Atual AAI" />

      <Box overflowY="auto" h="100%">
        <Flex align="flex-end" justify="flex-end" pr="1">
          <Text fontSize="xs">Pts.</Text>
          <Text fontSize="xs" ml="7" mr="2">
            Leads
          </Text>
        </Flex>

        <Stack
          maxH="176px"
          pr="1"
          spacing="1"
          overflowY="auto"
          flexDir="column"
        >
          {data.map(item => (
            <CurrentScore
              key={item.employeeId}
              name={item.employeeName}
              value={item.points}
              leadsQtd={item.leads}
            />
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};
