import { forwardRef, InputHTMLAttributes } from 'react';

type InputDateProps = InputHTMLAttributes<HTMLInputElement>;

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (props, ref) => {
    return (
      <input
        style={{}}
        type="datetime-local"
        name="date"
        ref={ref}
        className="input-light-mode"
        {...props}
      />
    );
  }
);
