import { Flex } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { FormEvent } from 'react';

interface ModalOrderDeletedProps {
  handleDeleteGroup: (e: FormEvent) => void;
  handleDeleteModal: () => void;
}

export const ModalOrderDeleted: React.FC<ModalOrderDeletedProps> = ({
  handleDeleteGroup,
  handleDeleteModal,
}) => {
  return (
    <BaseModalFormCard
      isModal
      title="Deletar frequência"
      handleFormSubmit={handleDeleteGroup}
      handleToggleModal={handleDeleteModal}
      isLoading={false}
      primaryButtonText="Confirmar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW="510px"
    />
  );
};
