import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { MainPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const Rh: React.FC = () => {
  return (
    <PageWrapper alignItems="center" justifyContent="center">
      <MainPageButton
        option={{ title: 'Solicitações e Dúvidas' }}
        path="/rh/solicitation"
        Icon={AiOutlineQuestionCircle}
        isLink
      />
    </PageWrapper>
  );
};
