import { AiOutlineClose } from 'react-icons/ai';
import { Flex } from '@chakra-ui/react';
import { COEprops } from 'pages/Home/interfaces';
import { formatDate } from 'utils/formatDate';
import { ModalTitle } from 'components/elements/texts';
import { LayoutModal } from 'components/elements/others/LayoutModal';
import { CardInfo } from 'components/elements/others/CardInfo';

interface COEmodalContentProps {
  productInfos: COEprops;
  handleToggleModal: () => void;
}

export const COEmodalContent: React.FC<COEmodalContentProps> = ({
  productInfos,
  handleToggleModal,
}) => {
  const bookingDeadline = formatDate(productInfos.COEDeadline);
  const bookingUntil = formatDate(productInfos.createdDate);

  return (
    <>
      <ModalTitle fontSize={{ base: 'lg', lg: 'xl' }} textAlign="center">
        COE: {productInfos.name.replace(/coe/i, '')}
      </ModalTitle>
      <Flex position="absolute" top="10px" right="10px">
        <AiOutlineClose
          size={24}
          color="white"
          cursor="pointer"
          onClick={handleToggleModal}
        />
      </Flex>
      <LayoutModal
        description={productInfos.strategy}
        bonusMessage={productInfos.bonusMessage}
        isStrategy
      >
        <CardInfo title="Reservas até" info={bookingUntil} />
        <CardInfo title="Vencimento do COE" info={bookingDeadline} />
      </LayoutModal>
    </>
  );
};
