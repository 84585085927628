import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Droppable } from 'react-beautiful-dnd';

import { KanbanBaseTaskProps, KanbanColumnProps } from 'shared/interfaces';
import formatValue from 'utils/formatValue';
import { sumTotalValue } from 'pages/IP/utils';

interface ComponentProps {
  columnId: string;
  column: KanbanColumnProps;
  countCards?: number;
  isSegmentImob?: KanbanBaseTaskProps[];
}

export const KanbanColumnBase: React.FC<ComponentProps> = ({
  children,
  column,
  columnId,
  countCards,
  isSegmentImob = [],
}) => {
  const [totalSumValues, setTotalSumValues] = useState<number | null>(null);

  useEffect(() => {
    setTotalSumValues(sumTotalValue(isSegmentImob));
  });

  return (
    <Flex flexDir="column" pb="8px">
      <Text
        isTruncated
        color="rgba(70, 70, 70, 1)"
        fontSize="sm"
        fontWeight="medium"
        textTransform="capitalize"
        padding="0 0 6px 6px"
      >
        {column.title} ({countCards ?? 0})
      </Text>

      <Flex
        width="100%"
        minW="216px"
        height="calc(100vh - 280px)"
        minH="180px"
        background="white"
        borderRadius="3"
        padding="6px 8px"
        gridGap={1}
      >
        <Droppable droppableId={columnId} key={columnId}>
          {provided => (
            <Flex
              flexDir="column"
              width="100%"
              minH="100%"
              overflowY="auto"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {children}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </Flex>
      {totalSumValues !== null ? (
        <Text fontSize={12} px={1} py={1}>
          Total de bens:{' '}
          {formatValue(
            totalSumValues && totalSumValues >= 0 ? totalSumValues : 0
          )}
        </Text>
      ) : null}
    </Flex>
  );
};
