import {
  Flex,
  Text,
  Avatar as ChakraAvatar,
  Image,
  Box,
  HStack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { basicDate } from 'utils/formatDate';

interface ContentSummaryCardProps {
  id: string;
  title: string;
  subtitle: string;
  area: string;
  theme: string;
  author: string;
  publicationDate: string;
  avatar: string | null;
  edit?: boolean;
  url: string | null;
}

export const ContentSummaryCard: React.FC<ContentSummaryCardProps> = ({
  id,
  title,
  subtitle,
  area,
  theme,
  author,
  publicationDate,
  avatar,
  edit,
  url,
}) => {
  const history = useHistory();

  let videoId;
  let thumbnailURL;

  if (url?.includes('vimeo')) {
    videoId = url?.split('com/')[1];
    thumbnailURL = `https://vumbnail.com/${videoId}_medium.jpg`;
  } else {
    videoId = url?.split('=')[1];
    thumbnailURL = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  }

  const handleSelectedContent = () => {
    history.push(`/contents/content/${id}`);
  };

  const sendToEditionPage = () => {
    history.push(`/contents/content/edit/${id}`);
  };

  return (
    <Flex
      position="relative"
      w="99%"
      h={{ base: 'initial', md: '130px' }}
      minH={{ base: 'initial', md: '130px' }}
      bgColor="white"
      borderRadius={6}
      _hover={{ borderColor: 'primary.100' }}
      p={3}
      cursor="pointer"
      transition="400ms"
      onClick={handleSelectedContent}
      flexDirection={['column', 'row']}
    >
      {url && (
        <Box w={['100%', '220px']} mr={4}>
          <Image
            sizes="100%"
            h="100%"
            maxH="150px"
            w="100%"
            objectFit={['contain', 'cover']}
            src={thumbnailURL}
          />
        </Box>
      )}

      <Flex flexDirection="column" position="relative" w="100%" pt={[4, 0]}>
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
          {title}
        </Text>
        <Text fontSize="sm" maxW="94%" mb={2} color="rgba(70, 70, 70, 1)">
          {subtitle}
        </Text>

        <Flex
          mt="auto"
          gridGap={1}
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <HStack spacing={2} mr="auto">
            <Tag size="sm" variant="outline" colorScheme="blue">
              <TagLabel>{area}</TagLabel>
            </Tag>
            <Tag size="sm" variant="outline" colorScheme="blue">
              <TagLabel>{theme}</TagLabel>
            </Tag>
          </HStack>
          <Flex
            alignItems="center"
            gap={2}
            justifyContent="flex-end"
            width={['100%', 'auto']}
            pt={[5, 0]}
          >
            <ChakraAvatar size="sm" name={author} src={avatar || undefined} />
            <Text fontSize="xs" fontWeight="light" fontStyle="italic">
              Por{' '}
              <Text as="span" fontWeight="bold">
                {formatNameToCaptalize(author)}
              </Text>{' '}
              em {publicationDate && basicDate(publicationDate)}
            </Text>
          </Flex>
        </Flex>

        {edit && (
          <Flex position="absolute" right={2} top={2}>
            <AiFillEdit
              size={24}
              style={{ cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                sendToEditionPage();
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
