import React from 'react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { hubApiTech } from 'services/hubApi/classes/HubApiTi';
import { ModalTitle, BaseLabelText } from 'components/elements/texts';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { BoardOrderProps } from 'pages/Technology/components/modules/technologyCRMComponent/BoardOrderInfo';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface CloseOrderProps extends BoardOrderProps {
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseOrder: React.FC<CloseOrderProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const handleFormSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    await hubApiTech
      .updateOrderStatus(order.id, {
        status: 'FINALIZADA',
      })
      .then(() => {
        setIsFinished(true);
        toast.dark(
          'Ordem finalizada com sucesso. A mesma será removida ao recarregar a tela.'
        );
        handleToggleModal();
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle>Finalizar ordem</ModalTitle>
      <BaseLabelText>
        Você confirma que deseja finalizar essa solicitação?
      </BaseLabelText>
      <form onSubmit={handleFormSubmit}>
        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default CloseOrder;
