import { useState } from 'react';

import { Flex, Box, Text } from '@chakra-ui/react';
import { OfferInOrdersPanel } from 'pages/MyClients/interfaces/OrdersPanelData';
import { formatCalendaryDate } from 'utils/formatDate';
import formatValue from 'utils/formatValue';

export interface OffersInOrdersPanelCardProps {
  offer: OfferInOrdersPanel;
}

export const OffersInOrdersPanelCard: React.FC<OffersInOrdersPanelCardProps> =
  ({ offer }): JSX.Element => {
    const [showModal, setShowModal] = useState(false);

    const checkStatusColor = (status: string) => {
      if (status === 'confirmado' || status === 'transferida')
        return 'green.500';
      if (status === 'cancelada') return 'red.500';

      return 'inherit';
    };

    return (
      <>
        <Flex
          flexDir="column"
          justifyContent="space-between"
          w="100%"
          h="188px"
          background="background.400"
          _hover={{ background: 'background.500' }}
          borderRadius={4}
          padding={3}
          borderColor="background.200"
          p="10px"
          onClick={() => setShowModal(!showModal)}
          cursor="pointer"
        >
          <Box
            paddingBottom="4px"
            mb="8px"
            borderBottom="1px"
            borderColor="background.200"
          >
            <Text color="white.300">{offer.offerName?.toUpperCase()}</Text>
          </Box>

          <Box mb="auto">
            <Text fontSize="xs">
              Data limite da oferta:{' '}
              {offer.dateLimit
                ? formatCalendaryDate(offer.dateLimit)
                : 'Não informado'}
            </Text>

            <Text fontSize="xs">
              Alocação: {formatValue(offer.allocationAmount)}
            </Text>
          </Box>

          <Box>
            <Text
              fontSize="xs"
              textAlign="right"
              color={checkStatusColor(offer.status?.toLowerCase())}
            >
              {offer.status?.toUpperCase()}
            </Text>
          </Box>
        </Flex>
      </>
    );
  };
