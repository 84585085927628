import { FormEvent, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Flex, Box, Text, Stack, Switch } from '@chakra-ui/react';
import { IReduxState } from 'shared/interfaces';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { SecondaryButton } from 'components/elements/buttons';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { EmployeeModalCard } from './EmployeeModalCard';

interface ClientsToShareModalProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  contentId: string;
}

export const ClientsToShareModal: React.FC<ClientsToShareModalProps> = ({
  onClose,
  contentId,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [employeesList, setEmployeesList] = useState<any[]>([]);
  const [filteredEmployeesList, setFilteredEmployeesList] = useState<any[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const searchClientsParameters = useRef('');

  const getAllEmployees = async () => {
    try {
      const response = await hubApiUser.getAllUsers();
      setEmployeesList(response);
      setFilteredEmployeesList(response);
      return;
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os colaboradores, tente novamente em alguns minutos'
      );
    }
  };

  const handleAddOrRemoveAllEmployees = () => {
    if (selectAllUsers) {
      setSelectAllUsers(!selectAllUsers);
      setSelectedEmployees([]);
    }

    if (!selectAllUsers) {
      setSelectAllUsers(!selectAllUsers);
      const employeesId = employeesList.map(item => item.id);

      setSelectedEmployees(employeesId);
    }
  };

  const handleShareContentToSelectedEmployees = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    const data = {
      employeeWhoSharedContent: user.name,
      contentId,
      employeesId: selectedEmployees,
    };

    try {
      await hubApiUser.shareContent(data);

      toast.dark('Conteúdo compartilhado com os colaboradores selecionados!');
      onClose(false);
    } catch (error) {
      toast.dark(
        'Não foi possível compartilhar o conteúdo, tente novamente em alguns minutos'
      );
    }

    setIsLoading(false);
  };

  const handleSearchEmployee = () => {
    const foundEmployees = employeesList.filter(employee =>
      employee.name
        .toLowerCase()
        .includes(searchClientsParameters.current.toLowerCase())
    );

    setFilteredEmployeesList(foundEmployees);
  };

  const handleBackToAllClients = () => {
    setFilteredEmployeesList(employeesList);
  };

  const handleSelectEmployee = (employeeId: string) => {
    if (employeesList.length === selectedEmployees.length + 1) {
      setSelectAllUsers(true);
    }

    setSelectedEmployees([...selectedEmployees, employeeId]);
  };

  const handleUnselectEmployee = (employeeId: string) => {
    if (employeesList.length !== selectedEmployees.length - 1) {
      setSelectAllUsers(false);
    }

    const listWithoutUnselectClient = selectedEmployees.filter(
      id => id !== employeeId
    );
    setSelectedEmployees(listWithoutUnselectClient);
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <BaseModalFormCard
      isModal
      title="Selecione os colaboradores com quem você quer compartilhar este conteúdo"
      handleFormSubmit={handleShareContentToSelectedEmployees}
      handleToggleModal={() => onClose(false)}
      isLoading={isLoading}
      primaryButtonText="Compartilhar conteúdo"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '600px']}
    >
      <Flex justify="space-between">
        <Box w="100%">
          <Flex w="100%" align="center" justify="space-between">
            <Box w="100%">
              <InputWithLabel
                label="Nome do colaborador:"
                name="name"
                autoComplete="off"
                onChange={e => {
                  searchClientsParameters.current = e.target.value;
                }}
              />
            </Box>
            <SecondaryButton mt="6" ml="2" onClick={handleSearchEmployee}>
              Pesquisar
            </SecondaryButton>
          </Flex>

          {filteredEmployeesList.length !== employeesList.length && (
            <Text
              textDecoration="underline"
              mt="1"
              mb="1"
              ml="1"
              fontSize="xs"
              _hover={{ color: 'white' }}
              cursor="pointer"
              textAlign="right"
              w="fit-content"
              onClick={handleBackToAllClients}
            >
              Voltar para todos
            </Text>
          )}

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt={
              filteredEmployeesList.length !== employeesList.length ? '0' : '6'
            }
            p="2"
            bg="background.400"
            flexDir="column"
          >
            <Flex px="2" align="center" gridGap={2} fontSize="sm">
              <Text>Marcar Todos</Text>
              <Switch
                size="sm"
                mt="2"
                id="email-alerts"
                isChecked={selectAllUsers}
                onChange={() => {
                  handleAddOrRemoveAllEmployees();
                }}
              />
            </Flex>

            <Stack p="1">
              {filteredEmployeesList.length > 0 &&
                filteredEmployeesList.map(item => (
                  <EmployeeModalCard
                    employee={item}
                    selectedEmployees={selectedEmployees}
                    handleSelectEmployee={handleSelectEmployee}
                    handleUnselectEmployee={handleUnselectEmployee}
                  />
                ))}

              {filteredEmployeesList.length === 0 && (
                <Text textAlign="center" fontStyle="italic">
                  Sem resultados
                </Text>
              )}
            </Stack>
          </DefaultCardWrapper>
        </Box>
      </Flex>
    </BaseModalFormCard>
  );
};
