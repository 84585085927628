import { Flex, Text } from '@chakra-ui/react';
import { TableBase } from 'components/elements/table';

import { IEventExtended } from 'pages/Events/interfaces/Event';

import { EventRow } from './EventRow';

interface IAllEventsTableProps {
  allEvents: IEventExtended[];
  isEventExpired: (date: string) => boolean;
  whatTypeEventIsActive: string;
  setAllEvents: React.Dispatch<React.SetStateAction<IEventExtended[]>>;
}

export const AllEventsTable: React.FC<IAllEventsTableProps> = ({
  allEvents,
  isEventExpired,
  setAllEvents,
  whatTypeEventIsActive,
}) => {
  const tableHeaders = [
    'Nome evento',
    'Data e hora',
    'Descrição',
    'link do evento',
    '',
    '',
  ];

  return (
    <>
      <TableBase
        headData={tableHeaders}
        w="100%"
        maxW={{ base: '1080px', '2xl': '1240px' }}
        maxH="480px"
        overflow="auto"
        p={0}
      >
        {allEvents.map(event => (
          <EventRow
            key={event.id}
            event={event}
            isEventExpired={isEventExpired}
            setAllEvents={setAllEvents}
            allEvents={allEvents}
          />
        ))}
      </TableBase>
      {allEvents.length === 0 && (
        <Flex
          width="100%"
          height={'50vh'}
          alignItems="center"
          justifyContent={'center'}
        >
          <Text fontStyle={'italic'}>
            {whatTypeEventIsActive === 'valid'
              ? 'Nenhum evento foi criado'
              : 'Nenhum evento no histórico'}
          </Text>
        </Flex>
      )}
    </>
  );
};
