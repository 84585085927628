import React, { useState } from 'react';
import { FcGlobe } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { OriginPendencieModal } from 'pages/Home/components/modules/modals';
import { AssignmentProps } from 'pages/Home/interfaces';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const OriginPendencyCard: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        title="Origem do cliente"
        IconElement={FcGlobe}
        secondaryText="A origem do cliente:"
        thirdText={`${assignment.name.toUpperCase()}` || ` "NOME INEXISTENTE" `}
        fourthText={`(${assignment.btgAccount}) ainda não foi informada`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <OriginPendencieModal
          assignment={assignment}
          handleToggleModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
