import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { KanbanColumnProps } from 'shared/interfaces';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { BackPageButton } from 'components/elements/buttons';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { PageWrapper } from 'components/elements/wrappers';
import { Board } from '../../components/modules/rvCrm';
import { rvColumns } from './data';

interface IUserOptions {
  id: string;
  name: string;
  orderMKCRMMkt: boolean;
}

export const RVBoard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(rvColumns);

  const [userOptions, setUserOptions] = useState<IUserOptions[]>([]);

  useEffect(() => {
    hubApiRv.listEmployeesWithRvPermission().then(response => {
      setUserOptions(response);
    });
  }, []);

  const handleChangeInterest = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const EmployeeId = event.target.value;
    setIsLoading(true);
    hubApiRv.listUserSelected(EmployeeId).then(response => {
      for (const column of columns) {
        const tasks = response.filter(
          (order: KanbanColumnProps) =>
            order.status?.toLowerCase() === column.title.toLowerCase()
        );
        column.tasks = tasks;
      }
      setIsLoading(false);
    });
    // setUserSelected(item.name);
  };

  useEffect(() => {
    async function listOrders() {
      await hubApiRv
        .listUserOrders()
        .then(response => {
          for (const column of columns) {
            const tasks = response.filter(
              (order: { status: string }) =>
                order.status.toLowerCase() === column.title.toLowerCase()
            );
            column.tasks = tasks;
          }
        })
        .catch(error => {
          errorHandler(error);
        });

      setIsLoading(false);
    }

    listOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Board
            boardTitle="CRM - RV"
            boardColumns={columns}
            setBoardColumns={setColumns}
          />

          <Box>
            <Dropdown
              placeholder="Selecione um usuario"
              mb={{ base: '2', md: '4' }}
              onChange={handleChangeInterest}
            >
              {userOptions.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Dropdown>
          </Box>
        </>
      )}
    </PageWrapper>
  );
};
