import { useQueryParams } from 'hooksV2/useQueryParams';

type UsePaginationResponseType<ItemsType> = {
  currentPage: number;
  totalPages: number;
  shouldShowBackPageIcon: boolean;
  shouldShowNextPageIcon: boolean;
  pagination: number;
  itemsSliced: ItemsType[];
  handleNextPage: () => void;
  handleBackPage: () => void;
};

type PropsType<ItemsType> = {
  items: ItemsType[];
  itemsPerPage: number;
  initalPage?: number;
};

export const usePagination = <ItemsType>(
  data: PropsType<ItemsType>
): UsePaginationResponseType<ItemsType> => {
  const { setParam, getParam } = useQueryParams();

  const currentPage = Number(getParam('currentPage')) || 1;

  const getTotalPages = (): number => {
    return Math.round(data.items.length / data.itemsPerPage);
  };

  const handleNextPage = () => {
    setParam('currentPage', (currentPage + 1).toString());
  };

  const handleBackPage = () => {
    setParam('currentPage', (currentPage - 1).toString());
  };

  const totalPages =
    data.items?.length && getTotalPages() > 0 ? getTotalPages() : 1;

  const shouldShowBackPageIcon = currentPage !== 1;
  const shouldShowNextPageIcon = currentPage < totalPages;

  const pagination =
    currentPage === 1 ? 0 : (currentPage - 1) * data.itemsPerPage;

  const itemsSliced = data.items.slice(
    pagination,
    currentPage * data.itemsPerPage
  );

  return {
    currentPage,
    totalPages,
    handleBackPage,
    handleNextPage,
    shouldShowBackPageIcon,
    shouldShowNextPageIcon,
    pagination,
    itemsSliced,
  };
};
