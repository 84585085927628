import React, { useState } from 'react';
import { IconType } from 'react-icons';

import { ModalWrapper } from 'components/elements/wrappers';
import { MainPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { ExchangeForm } from './components/modules/ExchangeForm';
import { exchangeOptions } from './data';

interface IOption {
  title: string;
  value: string;
  description?: string;
  icon?: IconType;
}

const Exchange: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<IOption>({
    title: '',
    value: '',
  });

  const handleShowModal = () => setShowModal(!showModal);

  const handleOpenModal = (person: IOption) => {
    setShowModal(!showModal);
    setSelectedPerson(person);
  };

  return (
    <PageWrapper
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
      gap={15}
    >
      {exchangeOptions.map((option, index) => (
        <MainPageButton
          key={index}
          Icon={option.icon}
          option={option}
          handleOpenModal={handleOpenModal}
        />
      ))}

      <ModalWrapper isOpen={showModal} onClose={handleShowModal}>
        <ExchangeForm
          handleShowModal={handleShowModal}
          selectedPerson={selectedPerson}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default Exchange;
