import * as Yup from 'yup';

const schema = Yup.object().shape({
  COEDeadline: Yup.string().required('Informe a data limite'),
  createdDate: Yup.string().required('Informe a data de criação'),
  name: Yup.string().required('Digite o nome do COE'),
  strategy: Yup.string().required('Digite a estratégia'),
  whatsMessage: Yup.string(),
  // .required('Digite uma mensagem para o WhatsApp.')
});

export default schema;
