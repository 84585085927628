import { useQueryParams } from 'hooksV2/useQueryParams';
import { useEffect, useRef } from 'react';

type UseScrollResponseType = {
  scrollTop: string;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  handleScrollToScrollTopPosition: () => void;
};

export const useScroll = (): UseScrollResponseType => {
  // Developers must set "scrollTop" before leave page,
  // To when user came back scroll can work without error
  // Exemple:
  // <Lead onClick={() => {
  //    const scrollTop = ScreenUtils.getScrollTop('<divScrollContainerName>');
  //    setParam('scrollTop', scrollTop.toString());
  //    goToOtherPage();
  // }}>
  //  {...}
  // </Lead>

  const { getParam } = useQueryParams();

  const scrollTop = getParam('scrollTop');

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollToScrollTopPosition = () => {
    scrollContainerRef.current?.scrollTo({
      top: Number(scrollTop),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    handleScrollToScrollTopPosition();
  }, [scrollTop]);

  return {
    scrollTop,
    handleScrollToScrollTopPosition,
    scrollContainerRef,
  };
};
