import React from 'react';
import { Flex, Input as ChakraInput, InputProps, Text } from '@chakra-ui/react';

interface ComponentProps extends InputProps {
  label: string;
  isInlineLabel?: boolean;
  ref?: React.LegacyRef<HTMLInputElement>;
  boxWidth?: string;
  textColor?: string;
}

export const InputWithLabel: React.FC<ComponentProps> = ({
  label,
  isInlineLabel,
  ref,
  boxWidth,
  textColor = 'white',
  ...rest
}) => {
  return (
    <Flex
      flexDir={isInlineLabel ? 'row' : 'column'}
      justifyContent={isInlineLabel ? 'space-between' : 'initial'}
      w={boxWidth || 'unset'}
      gap={2}
    >
      <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
        {label}
      </Text>
      <ChakraInput
        size="xs"
        color="rgba(70, 70, 70, 1)"
        margin="0"
        bg="#E7E7E7"
        borderColor="#1d63ddb2"
        focusBorderColor="primary.600"
        _hover={{ borderColor: 'primary.800' }}
        _placeholder={{ color: 'rgba(70, 70, 70, 1)', fontStyle: 'italic' }}
        ref={ref}
        {...rest}
      />
    </Flex>
  );
};
