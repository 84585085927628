import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import {
  ContentSummaryCard,
  ContentTypeButton,
} from 'pages/Contents/components';
import { Content } from 'pages/Contents/interfaces';

interface RelatedContentsProps {
  area: string;
  theme: string;
  currentContentId: string;
}

export const RelatedContents: React.FC<RelatedContentsProps> = ({
  area,
  theme,
  currentContentId,
}) => {
  const [contents, setContents] = useState<Content[]>([]);
  const [filteredContents, setFilteredContents] = useState<Content[]>([]);
  const [contentType, setContentType] = useState('all');

  const getRelatedContents = async () => {
    try {
      const response = await hubApiUser.getContentsByThemeOrArea(theme, area);
      setContents(
        response.filter((item: Content) => item.id !== currentContentId)
      );

      if (contentType === 'text') {
        setFilteredContents(
          response.filter(
            (item: Content) =>
              item.video === null && item.id !== currentContentId
          )
        );
      } else if (contentType === 'video') {
        setFilteredContents(
          response.filter(
            (item: Content) =>
              item.video !== null && item.id !== currentContentId
          )
        );
      } else {
        setFilteredContents(
          response.filter((item: Content) => item.id !== currentContentId)
        );
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os processos relacionados, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    if (contentType === 'text') {
      setFilteredContents(
        contents.filter((item: Content) => item.video === null)
      );
    } else if (contentType === 'video') {
      setFilteredContents(
        contents.filter((item: Content) => item.video !== null)
      );
    } else {
      setFilteredContents(contents);
    }
  }, [contentType]);

  useEffect(() => {
    if (area && theme) {
      getRelatedContents();
    }
  }, [area, theme]);

  return (
    <Flex flexDirection="column" gridGap={2} w="100%" mt={16} zIndex={10}>
      {contents.length > 0 && (
        <>
          <Text
            fontWeight="bold"
            fontSize="lg"
            fontStyle="italic"
            color="rgba(70, 70, 70, 1)"
            mb={6}
          >
            Processos relacionados
          </Text>

          <Flex justifyContent="center">
            <ContentTypeButton
              contentType={contentType}
              setContentType={setContentType}
            />
          </Flex>

          {filteredContents.length < 1 ? (
            <Text
              fontWeight="light"
              fontStyle="italic"
              textAlign="center"
              mt={8}
            >
              Sem processos deste tipo
            </Text>
          ) : (
            filteredContents.map((content: Content) => (
              <ContentSummaryCard
                key={content.id}
                id={content.id}
                title={content.title}
                subtitle={content.subtitle}
                area={content.area}
                theme={content.theme}
                author={content.name}
                publicationDate={content.created_at}
                avatar={content.avatar}
                url={content.video}
              />
            ))
          )}
        </>
      )}
    </Flex>
  );
};
