export const styledTheme = {
  colors: {
    primary500: '#1d64e2',
    primary600: '#1b5cce',
    primary700: '#1853ba',
    primary800: '#164aa7',
    primary900: '#134193',
    white100: '#fff',
    white200: '#d1d1d1',
    background900: '#050505',
    background800: '#0f0f0f',
    background700: '#1a1a1a',
    background600: '#242424',
    background500: '#2e2e2e',
    background400: '#383838',
    background300: '#424242',
    background200: '#4d4d4d',
    background100: '#575757',
    gray: '#696969',
    grayAlt: '#2d2d2d',
  },
  sizes: {
    small: '12px',
    smaller: '14px',
    normal: '16px',
    bigger: '20px',
    big: '24px',
    headlineSix: '28px',
    headlineFive: '36px',
    headlineFour: '48px',
    headlineThree: '56px',
    headlineTwo: '64px',
    headlineOne: '72px',
  },
  weights: {
    light: 100,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  borders: {
    minimal: '4px',
    medium: '6px',
    large: '8px',
    full: '99999px',
  },
};
