import { Flex, Text, TextProps } from '@chakra-ui/react';

type PropsType = {
  text: string;
  secondTextProps?: {
    text: string;
    clickable: boolean;
    onClick?: () => void;
  };
} & TextProps;

export const InputLabel: React.FC<PropsType> = ({
  text,
  secondTextProps,
  ...rest
}): JSX.Element => {
  return (
    <Flex justifyContent="space-between">
      <Text fontSize="sm" color="rgba(70, 70, 70, 1)" {...rest}>
        {text}
      </Text>

      {secondTextProps && (
        <Text
          fontSize="sm"
          color={
            secondTextProps.clickable
              ? 'rgba(29, 99, 221, 1)'
              : 'rgba(70, 70, 70, 1)'
          }
          cursor={secondTextProps.clickable ? 'pointer' : 'default'}
          onClick={secondTextProps.onClick ?? undefined}
          {...rest}
        >
          {secondTextProps.text}
        </Text>
      )}
    </Flex>
  );
};
