import { FormEvent, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box, Text, Stack } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { SecondaryButton } from 'components/elements/buttons';
import hubApi from 'services/hubApi';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { errorHandler } from 'utils';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import {
  ClientWithTagCard,
  ClientTagModalCard,
} from 'pages/MyClients/components/elements';

interface TagModalProps {
  selectedTag: string;
  onClose: () => void;
}

type IClient = {
  id: string;
  name: string;
  pl: string;
  declaredPL: string;
  tags: {
    tag: string;
  }[];
};

export const TagModal: React.FC<TagModalProps> = ({ selectedTag, onClose }) => {
  const [clientsWithSelectedTag, setClientsWithSelectedTag] = useState<
    IClient[]
  >([]);

  const [clientsList, setClientsList] = useState<IClient[]>([]);
  const [filteredClientsList, setFilteredClientsList] = useState<IClient[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const searchClientsParameters = useRef('');

  const getUsersWithSelectedTag = async () => {
    try {
      const response = await hubApiUser.getClientsByTag(selectedTag);
      setClientsWithSelectedTag(response);
    } catch (error) {
      toast.dark(
        'Não foi possível listar os clientes marcados com esta tag, tente novamente em alguns minutos'
      );
    }
  };

  const handleSaveTagToSelectedClients = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    if (selectedClients.length < 1) {
      toast.dark('Selecione ao menos um cliente para adicionar a tag');
      setIsLoading(false);
      return;
    }

    try {
      await hubApiUser.postAddTagToClients({
        tag: selectedTag,
        clientIds: selectedClients,
      });

      updateClientsLists();

      setIsLoading(false);
      setSelectedClients([]);
      toast.dark('Tag adicionada com sucesso!');
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
      toast.dark(
        'Não foi possível adicionar a tag, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const handleSearchClient = () => {
    if (searchClientsParameters.current === '') {
      toast.dark('Digite algum parâmetro de pesquisa');
      return;
    }
    setFilteredClientsList(
      clientsList.filter(item =>
        item.name
          .toLowerCase()
          .includes(searchClientsParameters.current.toLowerCase())
      )
    );
  };

  const getUsersClients = async () => {
    try {
      const response = await hubApi.listEmployeeClients();

      if (response.length === 0) {
        toast.dark('Nenhum cliente corresponde à sua pesquisa');
      } else {
        const clientsWithoutCurrentTag = response.filter(
          (client: IClient) => !client.tags.some(tag => tag.tag === selectedTag)
        );
        setClientsList(clientsWithoutCurrentTag);
        setFilteredClientsList(clientsWithoutCurrentTag);
      }
    } catch (err) {
      toast.dark(
        'Não foi possível listar seus clientes, tente novamente em alguns minutos'
      );
    }
  };

  const handleBackToAllClients = () => {
    setFilteredClientsList(clientsList);
  };

  const handleSelectClient = (clientId: string) => {
    setSelectedClients([...selectedClients, clientId]);
  };

  const handleUnselectClient = (clientId: string) => {
    const listWithoutUnselectClient = selectedClients.filter(
      id => id !== clientId
    );
    setSelectedClients(listWithoutUnselectClient);
  };

  const updateClientsListWithSelectedTag = (removedTagClientId: string) => {
    const clientsListWithSelectedTag = clientsWithSelectedTag.filter(
      client => client.id !== removedTagClientId
    );

    const removedTagClient = clientsWithSelectedTag.find(
      client => client.id === removedTagClientId
    ) as IClient;

    setClientsWithSelectedTag(clientsListWithSelectedTag);

    setFilteredClientsList([removedTagClient, ...filteredClientsList]);
    setClientsList([removedTagClient, ...filteredClientsList]);
  };

  const updateClientsLists = () => {
    const clientsListWithoutSelectedOnes = clientsList.filter(
      client => !selectedClients.find(clientId => clientId === client.id)
    );

    const clientsListWithSelectedTag = clientsList.filter(client =>
      selectedClients.find(clientId => clientId === client.id)
    );

    setFilteredClientsList(clientsListWithoutSelectedOnes);
    setClientsList(clientsListWithoutSelectedOnes);
    setClientsWithSelectedTag([
      ...clientsListWithSelectedTag,
      ...clientsWithSelectedTag,
    ]);
  };

  useEffect(() => {
    getUsersWithSelectedTag();
    getUsersClients();
  }, []);

  return (
    <BaseModalFormCard
      isModal
      title={`Adicionar tag "${selectedTag}" aos seus clientes`}
      handleFormSubmit={handleSaveTagToSelectedClients}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText="Adicionar tag aos clientes marcados"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '900px']}
    >
      <Flex justify="space-between">
        <Box w="90%">
          <Flex w="100%" align="center" justify="space-between">
            <Box w="75%">
              <InputWithLabel
                label="Nome do cliente:"
                name="name"
                autoComplete="off"
                onChange={e => {
                  searchClientsParameters.current = e.target.value;
                }}
              />
            </Box>
            <SecondaryButton mt="6" ml="2" onClick={handleSearchClient}>
              Pesquisar
            </SecondaryButton>
          </Flex>

          {filteredClientsList.length !== clientsList.length && (
            <Text
              textDecoration="underline"
              mt="1"
              mb="1"
              ml="1"
              fontSize="xs"
              _hover={{ color: 'white' }}
              cursor="pointer"
              textAlign="right"
              w="fit-content"
              onClick={handleBackToAllClients}
            >
              Voltar para todos
            </Text>
          )}

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt={filteredClientsList.length !== clientsList.length ? '0' : '6'}
            p="2"
            bg="background.400"
            flexDir="column"
          >
            <Stack p="1">
              {filteredClientsList.length > 0 &&
                filteredClientsList.map(item => (
                  <ClientTagModalCard
                    client={item}
                    selectedClients={selectedClients}
                    handleSelectClient={handleSelectClient}
                    handleUnselectClient={handleUnselectClient}
                  />
                ))}

              {filteredClientsList.length === 0 && (
                <Text textAlign="center" fontStyle="italic">
                  Sem resultados
                </Text>
              )}
            </Stack>
          </DefaultCardWrapper>
        </Box>

        <Flex ml="5" w="90%" flexDir="column" justify="space-between">
          <Text mt="auto" fontSize="md" color="white" fontWeight="bold">
            Clientes com a tag
          </Text>

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt="1"
            p="2"
            bg="background.400"
            flexDir="column"
          >
            <Stack p="1">
              {clientsWithSelectedTag.map(item => (
                <ClientWithTagCard
                  key={item.id}
                  clientId={item.id}
                  clientName={item.name}
                  clientPL={item.pl || '0'}
                  clientDeclaredPL={item.declaredPL || '0'}
                  tagName={selectedTag}
                  updateClientsLists={updateClientsListWithSelectedTag}
                />
              ))}
            </Stack>
          </DefaultCardWrapper>
        </Flex>
      </Flex>
    </BaseModalFormCard>
  );
};
