import { Flex, Text } from '@chakra-ui/react';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle, TitleWithSideLine } from 'components/elements/texts';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import { BuyMIBsModal } from './Components/BuyMIBsModal';
import { ManagerModal } from './Components/ManagerModal';
import { MibsHistoryItem } from './Components/MibsHistoryItem';
import { RedeemMIBsModal } from './Components/RedeemMIBsModal';
import { SHHistoryModal } from './Components/SHHistoryModal';
import { WalletInfoBox } from './Components/WalletInfoBox';
import { IMibsHistory } from './dtos';
import { useEmployeeMibsHistory } from './hooks/useEmployeeMibsHistory';
import { useEmployeeMibs } from './hooks/useEmployeeMibs';
import { useGeneralWalletMibsOperations } from './hooks/useGeneralWalletMibsOperations';
import { useIsUserOnlySH } from './hooks/useIsUserOnlySH';
import { useMibsOperations } from './hooks/useMibsOperations';

export const MyMIBs: React.FC = () => {
  const user = useSelector(
    (state: IReduxState) => state.user.profile
  ) as IUserSelector;

  // O MANAGER SO PODERAR SER O RAPHA, O THOMAS, O LUCA GALLACCI, o LUIZ CARRILHO, Gilmara E O DESENVOLVEDOR
  const isManager =
    user.id === 'db7b29e2-e5ab-4c8f-9299-c3346ac22b89' ||
    user.id === '31c6cc7b-3c4a-4b53-8f4d-f2435e16c321' ||
    user.id === '7e2a8234-49d8-490a-b3b5-7837f1a69117' ||
    user.id === '54234bae-1543-4a06-ba48-07bf265ed8ac' ||
    user.id === 'ad04d84a-3112-46c1-93e8-4d41396723a7' ||
    user.id === 'f7f0730a-4229-4bd7-ba69-ecd758b89f46';

  const [isOpenBuyMIBsModal, setIsOpenBuyMIBsModal] = useState<boolean>(false);
  const [isOpenRedeemMIBsModal, setIsOpenRedeemMIBsModal] =
    useState<boolean>(false);
  const [isOpenManagerModal, setIsOpenManagerModal] = useState<boolean>(false);
  const [isOpenSHHistoryModal, setIsOpenSHHistoryModal] =
    useState<boolean>(false);

  const [mibsHistory, setMibsHistory] = useState<IMibsHistory[]>([]);
  const [shHistoryItems, setSHHistoryItems] = useState<IMibsHistory[]>([]);

  const { data: isUserOnlySH } = useIsUserOnlySH(user);

  const { data: employeeMibs, isLoading } = useEmployeeMibs(user.id);

  const { data: mibsHistoryFetch, isLoading: isLoadingMibsHistory } =
    useEmployeeMibsHistory();

  const handleGeneralMibsOperation = useGeneralWalletMibsOperations();

  const handleMibsOperation = useMibsOperations();

  useEffect(() => {
    if (mibsHistoryFetch) {
      const onlyGhostWalletHistory = mibsHistoryFetch.filter(
        item => item.wallet === 'ghostWallet'
      );
      setSHHistoryItems(onlyGhostWalletHistory);

      const onlyPersonalAndEWZHistory = mibsHistoryFetch.filter(
        item => item.wallet !== 'ghostWallet'
      );
      setMibsHistory(onlyPersonalAndEWZHistory);
    }
  }, [mibsHistoryFetch]);

  if (isLoading) {
    return (
      <PageWrapper flexDir="column" p="5" mx="auto" overflowY="auto">
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper flexDir="column" p="5" mx="auto" overflowY="hidden">
      <BackPageButton />
      <MainPageTitle color="rgba(70, 70, 70, 1)" textAlign="center">
        Meus MIBs
      </MainPageTitle>

      <Flex alignItems="center" justifyContent="center" h="80vh">
        <Flex
          backgroundColor="white"
          p={5}
          borderRadius={5}
          w="80%"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <TitleWithSideLine fontSize={20}>Carteiras</TitleWithSideLine>
          <Flex h="25%" marginBottom={5} marginTop={2} gap={2}>
            {isUserOnlySH ? (
              <WalletInfoBox
                walletName="Carteira EWZ"
                mibs={employeeMibs ? employeeMibs[2].mibs : 0}
                isFullBox
                showReaisValue={false}
              />
            ) : (
              <>
                <WalletInfoBox
                  walletName="Carteira pessoal"
                  mibs={employeeMibs ? employeeMibs[1].mibs : 0}
                  showReaisValue={false}
                />
                <WalletInfoBox
                  walletName="Carteira EWZ"
                  mibs={employeeMibs ? employeeMibs[0].mibs : 0}
                  showReaisValue={false}
                />
                <WalletInfoBox
                  walletName="Total das Carteiras"
                  mibs={
                    employeeMibs
                      ? employeeMibs[0].mibs + employeeMibs[1].mibs
                      : 0
                  }
                  showReaisValue={false}
                />
              </>
            )}
          </Flex>
          <TitleWithSideLine fontSize={20}>
            Histórico de Movimentações
          </TitleWithSideLine>
          {mibsHistory?.length === 0 && !isLoadingMibsHistory ? (
            <Flex justifyContent="center">
              <Text color="rgba(70, 70, 70, 1)">
                Sem histórico de movimentações
              </Text>
            </Flex>
          ) : (
            <Flex
              maxH="60%"
              overflowY="auto"
              flexDirection="column"
              p={5}
              bgColor="white"
              borderRadius={5}
              marginTop={2}
              marginBottom={8}
              className="slimScrollbar"
            >
              {mibsHistory &&
                mibsHistory.map(item => <MibsHistoryItem item={item} />)}
            </Flex>
          )}

          <Flex gap={2}>
            {isManager && (
              <>
                <SecondaryButton onClick={() => setIsOpenSHHistoryModal(true)}>
                  Histórico de SH
                </SecondaryButton>
              </>
            )}

            {isManager && (
              <SecondaryButton onClick={() => setIsOpenManagerModal(true)}>
                Painel do Gestor
              </SecondaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ModalWrapper
        isOpen={isOpenBuyMIBsModal}
        onClose={() => setIsOpenBuyMIBsModal(!isOpenBuyMIBsModal)}
      >
        <BuyMIBsModal
          setIsOpenBuyMIBsModal={setIsOpenBuyMIBsModal}
          handleMibsOperation={handleMibsOperation}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenRedeemMIBsModal}
        onClose={() => setIsOpenRedeemMIBsModal(!isOpenRedeemMIBsModal)}
      >
        <RedeemMIBsModal
          setIsOpenRedeemMIBsModal={setIsOpenRedeemMIBsModal}
          handleMibsOperation={handleMibsOperation}
          personalWalletEmployeeMibs={employeeMibs ? employeeMibs[1].mibs : 0}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenManagerModal}
        onClose={() => setIsOpenManagerModal(!isOpenManagerModal)}
      >
        <ManagerModal
          handleGeneralMibsOperation={handleGeneralMibsOperation}
          setIsOpenManagerModal={setIsOpenManagerModal}
          handleMibsOperation={handleMibsOperation}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenSHHistoryModal}
        onClose={() => setIsOpenSHHistoryModal(!isOpenSHHistoryModal)}
      >
        <SHHistoryModal
          shHistoryItems={shHistoryItems}
          setIsOpenSHHistoryModal={setIsOpenSHHistoryModal}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
