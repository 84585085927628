import { useState } from 'react';
import { Flex, Stack } from '@chakra-ui/react';

import { showToast } from 'utils';
import { formateDateAndTime } from 'utils/formatDate';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { checkLeadTypeLabel } from 'pages/Captation/utils';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { LeadComplement } from 'pages/Captation/components/modules/leads';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultCardWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { useQuery } from 'react-query';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatToPhone } from 'brazilian-values';
import { SendLeadToSHModal } from '../SendLeadToSHModal';
import { SendLeadToAAIModal } from '../SendLeadToAAIModal';

interface ILeadDetailsProps {
  leadId: string;
  leadType: string;
  handleToggleLeadDetails?: () => void;
  refetchLeadsFound: () => void;
}

export const ManagerLeadDetails: React.FC<ILeadDetailsProps> = ({
  leadId,
  leadType,
  handleToggleLeadDetails,
  refetchLeadsFound,
}) => {
  const [sendToSH, setSendToSH] = useState(false);
  const [sendToAAI, setSendToAAI] = useState(false);

  const handleToggleSendToSHModal = () => setSendToSH(!sendToSH);
  const handleToggleSendToAAIModal = () => setSendToAAI(!sendToAAI);

  const { data: leadDetails, isLoading } = useQuery<LeadDetailsProps>({
    queryKey: ['leadDetails', leadId],
    queryFn: async () => {
      const response = await hubApiCaptation.getLeadById(leadType, leadId);
      return response;
    },
    onError: () => {
      showToast(
        'Não foi possivel encontrar os detalhes do lead. Tente novamente mais tarde.'
      );
    },
  });

  const { data: employeeParticipant } = useQuery<{ name: string }>({
    queryKey: [
      'employeeParticipant',
      leadDetails?.meetings?.length !== undefined &&
        leadDetails?.meetings?.length > 0 &&
        leadDetails?.meetings[0].employeeIdParticipant,
    ],
    queryFn: async () => {
      if (!leadDetails) {
        return;
      }
      const response = await hubApiCaptation.getEmployeeInfos(
        leadDetails.meetings[0].employeeIdParticipant
      );

      return response;
    },
    enabled: leadDetails && leadDetails?.meetings?.length > 0,
  });

  const checkMeetingStatus = (meeting: IBaseMeeting): string => {
    if (meeting.status === 'created') {
      return `Esse lead está no pool do SH responsável com uma reunião marcada para ${formateDateAndTime(
        meeting.date
      )}`;
    }

    if ((meeting.status as any) === 'confirmed')
      return `Esse lead está no pool de assessoria com uma reunião marcada para ${formateDateAndTime(
        meeting.date
      )}`;

    if (
      meeting.status === 'suitability' ||
      meeting.status === 'account' ||
      meeting.status === 'allocation' ||
      meeting.status === 'presentation'
    ) {
      return `Esse lead está no fluxo de reuniões de ${
        employeeParticipant?.name
      } com uma reunião marcada para ${formateDateAndTime(meeting.date)}`;
    }

    if ((meeting.status as any) === 'returned') {
      return `Esse lead foi retornado para o SH responsável como R.N.A`;
    }

    return '';
  };

  if (isLoading || !leadDetails) {
    return <LoadingSpinner height="inherit" />;
  }

  return (
    <DefaultCardWrapper flexDir="column" width="100%" maxW="490px">
      <ModalTitle>Detalhes do lead</ModalTitle>

      <Stack spacing={4}>
        <LeadComplement
          title="Nome"
          complement={formatNameToCaptalize(leadDetails.name)}
        />

        {(leadDetails.leadType || leadType) && (
          <LeadComplement
            title="Tipo de lead"
            complement={checkLeadTypeLabel(leadDetails.leadType || leadType)}
          />
        )}

        {leadDetails.phone && (
          <Flex width="100%" flexDir="row" flexWrap="wrap" gridGap="6">
            {leadDetails.phone.map((phone, index) => (
              <LeadComplement
                key={`phone-${index}`}
                title={`Telefone ${index + 1}`}
                complement={formatToPhone(
                  phone?.phone.includes('55')
                    ? phone?.phone.substring(2)
                    : phone?.phone || ''
                )}
              />
            ))}
          </Flex>
        )}

        {leadDetails.email && (
          <Flex width="100%" flexDir="row" flexWrap="wrap" gridGap="6">
            {leadDetails.email.map((email, index) => (
              <LeadComplement
                key={`email-${index}`}
                title={`E-mail ${index + 1}`}
                complement={email.email}
              />
            ))}
          </Flex>
        )}

        {employeeParticipant && employeeParticipant.name.trim() !== 'Erro' && (
          <LeadComplement
            title="Responsável"
            complement={formatNameToCaptalize(employeeParticipant.name)}
          />
        )}

        {leadDetails && leadDetails.meetings?.length > 0 && (
          <LeadComplement
            title="Status"
            complement={checkMeetingStatus(leadDetails.meetings[0])}
          />
        )}
      </Stack>

      <BaseButtonGroup mt="6" gridGap="1.5">
        <SecondaryButton
          width={['100%', 'fit-content']}
          onClick={handleToggleLeadDetails}
        >
          Voltar
        </SecondaryButton>

        {leadDetails && leadDetails.id && (
          <>
            {(leadType === 'online' || leadType === 'publics') &&
              ((leadDetails.meetings.length > 0 &&
                leadDetails.meetings[0].status === 'created') ||
                leadDetails.meetings.length === 0) && (
                <PrimaryButton
                  width={['100%', 'fit-content']}
                  onClick={handleToggleSendToSHModal}
                >
                  Transferir para um SH
                </PrimaryButton>
              )}
            {leadDetails.meetings.length > 0 &&
              leadDetails.meetings[0].status !== 'created' && (
                <PrimaryButton
                  width={['100%', 'fit-content']}
                  onClick={handleToggleSendToAAIModal}
                >
                  Transferir para um AAI
                </PrimaryButton>
              )}
          </>
        )}
      </BaseButtonGroup>

      <ModalWrapper isOpen={sendToSH} onClose={handleToggleSendToSHModal}>
        <SendLeadToSHModal
          leadType={leadType}
          currentLead={leadDetails as any}
          leadMeetings={leadDetails && leadDetails.meetings}
          handleToggleModal={handleToggleSendToSHModal}
        />
      </ModalWrapper>
      <ModalWrapper isOpen={sendToAAI} onClose={handleToggleSendToAAIModal}>
        <SendLeadToAAIModal
          refetchLeadsFound={refetchLeadsFound}
          leadType={leadType}
          leadDetails={leadDetails}
          handleToggleModal={handleToggleSendToAAIModal}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};
