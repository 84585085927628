import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white-color-100);

  form {
    margin-top: 24px;
  }

  p {
    margin-top: 12px;
    font-size: var(--smaller-text);
    color: var(--white-color-300);
    font-weight: var(--font-500);
    cursor: pointer;
    transition: all 0.2s ease-in;
    width: fit-content;

    &:hover {
      color: var(--primary-color-200);
    }
  }
`;

export const LoginPageTitle = styled.h1`
  color: var(--white-color-100);
  font-size: var(--h3-text);
  margin-bottom: 6px;
`;

export const LoginPageSubTitle = styled.h4`
  font-size: var(--normal-text);
  color: var(--gray-color-100);
`;
