import React from 'react';
import {
  Flex,
  FlexProps,
  Table,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

interface ComponentProps extends FlexProps {
  isMainCheckbox?: boolean;
  isMainChecked?: boolean;
  toggleMainCheckbox?: () => void;
}

export const TableBaseManagementSH: React.FC<ComponentProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex background="background.600" borderRadius="3" {...rest}>
      <Table px="4" color="white" variant="unstyled" width="100%" height="100%">
        <Thead>
          <Tr>
            <Tooltip
              label="Nome do SH"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                Sh
              </Th>
            </Tooltip>

            <Tooltip
              label="Tempo como SH"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                Tempo do SH
              </Th>
            </Tooltip>

            <Tooltip
              label="Leads entregues ao SH"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                Leads Entregues
              </Th>
            </Tooltip>

            <Tooltip
              label="Porcentagem das R. Marcadas são em cima dos leads entreges"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                R. Marcadas
              </Th>
            </Tooltip>

            <Tooltip
              label="Porcentagem das Roteadas são em cima das R. Marcadas"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                R. Roteadas
              </Th>
            </Tooltip>

            <Tooltip
              label="Porcentagem das chamadas atendidas são em cima das R. Roteadas"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                R. Atendidas
              </Th>
            </Tooltip>

            <Tooltip
              label="Porcentagem das Conv. para reunião realizada são em cima das Roteadas"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                Conv. para R.R.
              </Th>
            </Tooltip>

            <Tooltip
              label="Porcentagem das RNAs são em cima das Roteadas"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                RNA
              </Th>
            </Tooltip>

            <Tooltip
              label="Total de tarefas"
              cursor="pointer"
              color="blackAlpha.900"
              placement="top"
            >
              <Th
                background="background.700"
                textAlign="center"
                color="white"
                fontSize="small"
                position="sticky"
                top="0"
              >
                Tarefas Excec.
              </Th>
            </Tooltip>
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </Flex>
  );
};
