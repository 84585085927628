import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';

type Action = {
  actionSeq: string;
  frequencySeq: string;
  id: string;
  leadId: string;
  name: string;
  status: 'aguardando' | 'realizada' | 'não atendeu';
};

interface LeadActionsCheckboxProps {
  actions: Action[];
  publicLeads: LeadDetailsProps[];
}

export const LeadActionsCheckbox: React.FC<LeadActionsCheckboxProps> = ({
  actions,
  publicLeads,
}) => {
  const handleActionCheckbox = async (actionIndex: number) => {
    const action = actions[actionIndex];

    const actionToUpdate = {
      actionSeq: action.actionSeq,
      frequencySeq: action.frequencySeq,
      id: action.id,
      leadId: action.leadId,
      name: action.name,
      status: action.status,
    };

    try {
      await hubApiCaptation.patchLeadAction({
        ...actionToUpdate,
        status: 'realizada',
      });

      publicLeads.forEach(lead => {
        if (lead.id === actionToUpdate.leadId) {
          lead.actions.forEach(action => {
            if (action.id === actionToUpdate.id) {
              action.status = 'realizada';
            }
          });
        }
      });

      toast.dark('Tarefa feita!');
    } catch (error) {
      toast.dark(
        'Falha ao concluir a tarefa, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <>
      <Flex flexDir="column">
        {actions?.length > 0 ? (
          actions.map((action, index) => {
            return (
              <Checkbox
                key={action.id}
                isDisabled={
                  action.status === 'realizada' ||
                  action.status === 'não atendeu'
                }
                defaultChecked={
                  action.status === 'realizada' ||
                  action.status === 'não atendeu'
                }
                onChange={() => handleActionCheckbox(index)}
              >
                {action.name}
              </Checkbox>
            );
          })
        ) : (
          <Text>Sem tarefas</Text>
        )}
      </Flex>
    </>
  );
};
