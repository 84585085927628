import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { errorHandler } from 'utils/errorHandler';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { PageWrapper } from 'components/elements/wrappers/PageWrapper';
import { SecondaryButton } from 'components/elements/buttons/SecondaryButton';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { TableBase } from 'components/elements/table';
import ActiveCustomersRvTableRow from '../../components/modules/ActiveCustomersRv/ActiveCustomersRvTableRow';
import { advancedCustumersRvHeader } from './data';

const ActiveCustomersRv: React.FC = () => {
  const [infosRv, setInfosRv] = useState({
    data: [],
  });

  useEffect(() => {
    const getAllClients = async () => {
      await hubApiRv
        .listAllClientesCustumersRv('rv')
        .then(response => setInfosRv(response))
        .catch(error => errorHandler(error));
    };

    getAllClients();
  }, []);

  const handleExtractTable = async () => {
    await hubApiRv
      .listAllClientesCustumersRv('rv')
      .then(response => window.location.assign(`${response.link}`))
      .catch(error => errorHandler(error));
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />
      <MainPageTitle textAlign="center">
        Clientes ativos Renda Variável
      </MainPageTitle>

      <Flex flexDir="column" width="100%" maxW="1080px">
        <SecondaryButton
          mb="2"
          alignSelf="flex-end"
          onClick={handleExtractTable}
        >
          Extrair Tabela
        </SecondaryButton>
        <TableBase
          overflowY="auto"
          maxH="calc(100vh - 260px)"
          width="100%"
          headData={advancedCustumersRvHeader}
        >
          {infosRv?.data?.map((rv, index) => (
            <ActiveCustomersRvTableRow key={index} rv={rv} />
          ))}
        </TableBase>
      </Flex>
    </PageWrapper>
  );
};

export default ActiveCustomersRv;
