import styled from 'styled-components';

export const WrapperBackgroundTransparent = styled.div`
  padding: 16px;
  /* background: linear-gradient(
    125.54deg,
    rgba(154, 163, 210, 0.12) -3.36%,
    rgba(217, 217, 217, 0) 104.08%
  ); */
  background: #fff;
  border-radius: ${({ theme }) => theme.borders.minimal};
  border: 1px solid rgba(255, 255, 255, 0.15);
`;
