import React from 'react';
import { Text, FlexProps } from '@chakra-ui/react';

import { DefaultCardWrapper } from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ComponentProps extends FlexProps {
  mainText: string;
  secondaryText?: string;
  thirdText?: string;
}

export const TransferOrderCard: React.FC<ComponentProps> = ({
  mainText,
  secondaryText,
  thirdText,
  ...rest
}) => {
  return (
    <DefaultCardWrapper
      color="rgba(70, 70, 70, 1)"
      width="100%"
      height="fit-content"
      maxWidth={{ base: '100%', md: '256px' }}
      borderRadius="3"
      padding="2"
      background="#f2f2f2"
      cursor="pointer"
      flexDir="column"
      _hover={{ bg: '#ebebeb' }}
      transition="0.2s background ease-in"
      {...rest}
    >
      <Text
        color="rgba(70, 70, 70, 1)"
        fontSize="sm"
        maxW="212px"
        isTruncated
        fontWeight="semibold"
      >
        {formatNameToCaptalize(mainText)}
      </Text>
      {secondaryText && (
        <Text
          color="rgba(70, 70, 70, 1)"
          fontWeight="thin"
          fontSize="xs"
          isTruncated
          maxW="180px"
        >
          {formatNameToCaptalize(secondaryText)}
        </Text>
      )}

      {thirdText && (
        <Text
          color="rgba(70, 70, 70, 1)"
          fontWeight="thin"
          fontSize="xs"
          isTruncated
        >
          {thirdText}
        </Text>
      )}
    </DefaultCardWrapper>
  );
};
