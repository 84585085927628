import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import { useMeetingCardCustomization } from 'pages/IPLife/hooks/useMeetingCardCustomization';
import { IPMeetingType } from 'services/hubApi';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import formatValue from 'utils/formatValue';

type PropsType = {
  meeting: IPMeetingType;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
  handleChangeSelectedMeetingId: (meetingId: string) => void;
};

export const IPMeetingCard: React.FC<PropsType> = ({
  meeting,
  handleToggleOpenMeetingDetailsModal,
  handleChangeSelectedMeetingId,
}): JSX.Element => {
  const { border } = useMeetingCardCustomization(meeting);

  const correctMeetingDate = new Date(meeting.date);
  correctMeetingDate.setHours(correctMeetingDate.getHours() + 3);

  return (
    <Flex
      bgColor="white"
      w="204px"
      minH="150px"
      p={2}
      flexDirection="column"
      borderRadius={8}
      border={border}
      cursor="pointer"
      onClick={() => {
        handleChangeSelectedMeetingId(meeting.id);
        handleToggleOpenMeetingDetailsModal(true);
      }}
    >
      <Flex flexDirection="column" h="100%" justifyContent="space-between">
        <Flex flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize="sm"
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
              isTruncated
            >
              {formatNameToCaptalize(meeting.order.name)}
            </Text>
            {meeting.status === 'boleted' && (
              <Tooltip
                label="Boletada"
                cursor="pointer"
                color="blackAlpha.900"
                placement="right"
              >
                <Flex h="10px" w="10px" bgColor="green" borderRadius="50%" />
              </Tooltip>
            )}
          </Flex>

          {meeting.order.annualizedPremium && (
            <Text fontSize="xs" fontWeight="bold" color="rgba(70, 70, 70, 1)">
              <Text
                as="span"
                fontWeight="thin"
                fontStyle="italic"
                color="rgba(70, 70, 70, 1)"
              >
                PA:
              </Text>{' '}
              {formatValue(meeting.order.annualizedPremium)}
            </Text>
          )}
          <Text fontSize="xs" fontWeight="bold" color="rgba(70, 70, 70, 1)">
            <Text
              as="span"
              fontWeight="thin"
              fontStyle="italic"
              color="rgba(70, 70, 70, 1)"
            >
              Assessor:
            </Text>{' '}
            {formatNameToCaptalize(meeting.order.employee.name)}
          </Text>
          {meeting.date && (
            <Text fontSize="xs" fontWeight="bold" color="rgba(70, 70, 70, 1)">
              <Text
                as="span"
                fontWeight="thin"
                fontStyle="italic"
                color="rgba(70, 70, 70, 1)"
              >
                Data da Reunião:
              </Text>{' '}
              {format(correctMeetingDate, 'Pp', {
                locale: ptBR,
              }).replace(',', ' às')}
            </Text>
          )}
        </Flex>
        <Flex>
          <Text
            fontSize="x-small"
            fontStyle="italic"
            color="rgba(70, 70, 70, 1)"
          >
            Aberta em {formateDateAndTime(meeting.order.created_at)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
