/* eslint no-unused-vars: off */
import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { Form } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';

import { InputWithLabel } from 'components/elements/forms';
import { SpecificOrderProps } from 'pages/RV/interfaces';

import { BaseLabelText } from 'components/elements/texts';
import OrderInfosContent from './styles';

interface ComponentProps {
  order: SpecificOrderProps;
  index: number;
  handleDeleteOrder: (removedOrderId: number) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    orderIndex: number
  ) => void;
}

export const SpecificOrderInputRow: React.FC<ComponentProps> = ({
  order,
  index,
  handleDeleteOrder,
  handleChange,
}) => {
  const [radioValue, setRadioValue] = useState('COMPRA');

  const handleRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    handleChange(e, idx);
    setRadioValue(e.target.value);
  };

  return (
    <OrderInfosContent>
      <div className="order-container">
        <div className="order-row">
          <div
            className={`order-inputs ${!order.delAbled && 'order-disabled'}`}
          >
            <Flex flexDir="column" color="white" fontSize="small">
              <BaseLabelText mb="1">Tipo:</BaseLabelText>
              <Form.Check
                type="radio"
                name={`type_${index}`}
                label="Compra"
                value="COMPRA"
                onChange={e => handleRadioChange(e, index)}
                id={`form_type_${index}`}
              />
              <Form.Check
                type="radio"
                name={`type_${index}`}
                label="Venda"
                value="VENDA"
                onChange={e => handleRadioChange(e, index)}
                id={`form_type_${index}`}
              />
            </Flex>
            <div
              className={
                !order.delAbled
                  ? 'inputLabelContainer'
                  : 'deleteInputLabelContainer'
              }
            >
              <InputWithLabel
                className="inputTicker"
                label="Ticker:"
                placeholder="Ticker"
                name="ticker"
                onChange={e => handleChange(e, index)}
              />
              <InputWithLabel
                className="inputFinancial"
                label={
                  radioValue === 'COMPRA'
                    ? 'Financeiro:'
                    : 'Quantidade de ações:'
                }
                placeholder={
                  radioValue === 'COMPRA' ? 'Financeiro' : 'Quantidade de ações'
                }
                name="financial"
                onChange={e => handleChange(e, index)}
              />
            </div>
          </div>

          {order.delAbled && (
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              onClick={() => handleDeleteOrder(order.id)}
            >
              <AiOutlineDelete
                size={16}
                style={{ marginTop: '20px' }}
                color="white"
              />
            </Flex>
          )}
        </div>
      </div>
    </OrderInfosContent>
  );
};
