import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';

interface IUseFilterLeadsByRangeProps {
  leads: LeadDetailsProps[];
  selectedRange: number;
}

interface IUseFilterLeadsByRangeReturn {
  leadsToBeShown: LeadDetailsProps[];
  allowedRanges: string[];
}

const range: {
  [k: number]: number;
} = {
  1: 50,
  2: 100,
  3: 300,
  4: 500,
  5: 1,
  6: 2,
};

export const useFilterLeadsByRange = ({
  leads,
  selectedRange,
}: IUseFilterLeadsByRangeProps): IUseFilterLeadsByRangeReturn => {
  const limitValues: number[] = [];

  const getLimitValue = (message: string): number | null => {
    const values = message
      ?.replace(/R\$|mil|s|ã|õ|h|o/gi, '')
      .split('a')
      .map(item => {
        return Number(item.replace(/D|e/g, '').trim());
      });

    if (!values) return null;

    return values[1];
  };

  const filteredLeads = leads?.filter(lead => {
    const PL = lead.customFields.filter(
      (field: { fieldName: string }) =>
        field.fieldName === 'cf_faixa_de_investimentos_certo'
    )[0]?.fieldValue;

    const plByQuestion = lead.questions[0]?.pl;
    let limitValue = 0;
    const numberPlByQyuestion = Number(plByQuestion);

    if (numberPlByQyuestion <= 50000) {
      limitValue = 50;
    } else if (numberPlByQyuestion > 50000 && numberPlByQyuestion <= 100000) {
      limitValue = 100;
    } else if (numberPlByQyuestion > 100000 && numberPlByQyuestion <= 300000) {
      limitValue = 300;
    } else if (numberPlByQyuestion > 300000 && numberPlByQyuestion <= 500000) {
      limitValue = 500;
    } else if (numberPlByQyuestion > 500000 && numberPlByQyuestion <= 1000000) {
      limitValue = 1;
    } else if (numberPlByQyuestion > 1000000) {
      limitValue = 2;
    }
    if (!numberPlByQyuestion) {
      limitValue = getLimitValue(PL) || 30;
    }

    if (selectedRange === 1) {
      return (
        limitValue !== 1 &&
        limitValue !== 2 &&
        limitValue <= range[selectedRange]
      );
    }

    if (selectedRange === 5) {
      return limitValue === 1 && limitValue < 2;
    }

    if (selectedRange === 6) {
      return limitValue > 1 && limitValue <= 2;
    }

    return (
      limitValue <= range[selectedRange] &&
      limitValue > range[selectedRange - 1]
    );
  });

  const getAllowedRanges = () => {
    const ranges = [];

    const formatedLimitValues = Array.from(new Set(limitValues));
    const orderedLimitValues = formatedLimitValues.sort(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) => a - b
    );
    const lastLimitValue = orderedLimitValues[formatedLimitValues.length - 1];
    const firstLimitValue = orderedLimitValues[0];
    const secondLimitValue = orderedLimitValues[2];

    if (Number(secondLimitValue) > 1 && Number(secondLimitValue) <= 2) {
      ranges.push('5 - 50 k');
      ranges.push('50 - 100 k');
      ranges.push('100 - 300 k');
      ranges.push('300 - 500 k');
      ranges.push('500 - 1MM');
      ranges.push('1MM +');

      return ranges;
    }

    if (Number(lastLimitValue) > 500 || Number(firstLimitValue) === 1) {
      ranges.push('5 - 50 k');
      ranges.push('50 - 100 k');
      ranges.push('100 - 300 k');
      ranges.push('300 - 500 k');
      ranges.push('500 - 1MM');

      return ranges;
    }

    if (Number(lastLimitValue) > 300 && Number(lastLimitValue) <= 500) {
      ranges.push('5 - 50 k');
      ranges.push('50 - 100 k');
      ranges.push('100 - 300 k');
      ranges.push('300 - 500 k');

      return ranges;
    }

    if (Number(lastLimitValue) > 100 && Number(lastLimitValue) <= 300) {
      ranges.push('5 - 50 k');
      ranges.push('50 - 100 k');
      ranges.push('100 - 300 k');

      return ranges;
    }

    if (Number(lastLimitValue) > 50 && Number(lastLimitValue) <= 100) {
      ranges.push('5 - 50 k');
      ranges.push('50 - 100 k');

      return ranges;
    }

    ranges.push('5 - 50 k');

    return ranges;
  };

  const allowedRanges = getAllowedRanges();

  return {
    leadsToBeShown: filteredLeads,
    allowedRanges,
  };
};
