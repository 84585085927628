import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { PageWrapper } from 'components/elements/wrappers';
import CreatePublicOfferingForm from 'pages/products/components/modules/publicOffering/CreatePublicOfferingForm';

import { PublicOfferingProps } from 'pages/products/interfaces';

import { LoadingSpinner } from 'components/elements/others';
import schema from './schema';

interface RouteParams {
  id: string;
}

export const UpdatePublicOffering: React.FC = () => {
  const history = useHistory();
  const { id } = useParams() as RouteParams;

  const [checkedOfferInCaptation, setCheckedOfferInCaptation] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [publicOfferingData, setPublicOfferingData] =
    useState<PublicOfferingProps>({} as PublicOfferingProps);
  const [productTitle, setProductTitle] = useState('');

  const handleUpdatePublicOffering = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    const newArray = {
      ...publicOfferingData,
    };

    try {
      if (!checkedOfferInCaptation) {
        await schema.validate(publicOfferingData, {
          abortEarly: false,
        });
      }

      if (productTitle !== newArray.title) {
        await hubApiProducts.updateCard(newArray.id, {
          productTitle: newArray.title,
        });
      }

      hubApiProducts
        .putPublicOffering(id, newArray)
        .then(() => {
          toast.dark('Atualizado com sucesso');
          history.goBack();
          setIsLoading(false);
        })
        .catch(error => {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          toast.dark(err.message);
        });
      } else {
        toast.dark(
          'Ocorreu um erro ao criar uma Oferta Pública, por favor tente novamente.'
        );
      }
    }
  };

  useEffect(() => {
    hubApiProducts.getPublicOfferingById(id).then(data => {
      setPublicOfferingData(data);
      setLoading(false);
      setProductTitle(data.title);
    });
  }, [id]);

  return (
    <PageWrapper>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreatePublicOfferingForm
          isLoading={isLoading}
          offeringData={publicOfferingData}
          setValue={setPublicOfferingData}
          handleSubmitForm={handleUpdatePublicOffering}
          label="Atualizar Oferta Pública Renda Fixa"
          nameButton="Atualizar"
          checkedOfferInCaptation={checkedOfferInCaptation}
          setCheckedOfferInCaptation={setCheckedOfferInCaptation}
        />
      )}
    </PageWrapper>
  );
};
