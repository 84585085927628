export const ewzCaptationTableHead = [
  'AAI',
  'Tempo do AAI',
  'Clientes',
  'Frequência de Contato',
  'Ticket Médio',
  'TXR mês',
  'NNM Mês',
  'Ted-out Mês',
  'PL Total',
];

export const miCaptationTableHead = [
  'AAI',
  'Entrada do AAI',
  'Nível atual',
  'Qtd. R.R',
  'Contas Abertas',
  'PL captado',
  'Pontos',
];
