import React from 'react';
import { TableData, TableRow } from 'components/elements/table';
import { Checkbox } from '@chakra-ui/react';
import {
  IOrder,
  IFilter,
  IOrderExtract,
} from 'pages/RV/subpages/RvOrders/interfaces/index';

interface ComponentProps {
  order: IOrder | IOrderExtract;
  filter: IFilter;
  handleToggleCheckbox: (
    order: IOrder | IOrderExtract,
    orderType: string
  ) => void;
}

const RvOrdersTableRow: React.FC<ComponentProps> = ({
  order,
  filter,
  handleToggleCheckbox,
}) => {
  return (
    <TableRow key={order.id}>
      <TableData>
        <Checkbox
          isChecked={order.isChecked}
          onChange={() =>
            handleToggleCheckbox(
              order,
              filter.name === 'Não extraido' ? 'orders' : 'ordersExtract'
            )
          }
        />
      </TableData>
      <TableData>{order.btgAccount}</TableData>
      <TableData>{order.type}</TableData>
      <TableData>{order.ticker}</TableData>

      <TableData>{order.financial}</TableData>

      <TableData>{order.name}</TableData>
      <TableData>{order.status}</TableData>
    </TableRow>
  );
};

export default RvOrdersTableRow;
