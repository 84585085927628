import { Flex, FlexProps } from '@chakra-ui/react';

type PropsType = {
  shouldHaveAllBorders?: boolean;
  borderColor?: string;
} & FlexProps;

export const BoxOpaque: React.FC<PropsType> = ({
  children,
  borderColor,
  shouldHaveAllBorders = false,
  ...rest
}): JSX.Element => {
  const color = borderColor || '#1D63DDB2';

  return (
    <Flex
      background="#f2f2f2"
      borderLeft={`3px solid ${color}`}
      borderRadius={6}
      flexDirection="column"
      borderRight={shouldHaveAllBorders ? `1px solid ${color}` : 'none'}
      borderBottom={shouldHaveAllBorders ? `1px solid ${color}` : 'none'}
      borderTop={shouldHaveAllBorders ? `1px solid ${color}` : 'none'}
      {...rest}
    >
      {children}
    </Flex>
  );
};
