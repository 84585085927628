import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { RealStateFoundProps } from 'pages/products/interfaces';
import { PageWrapper } from 'components/elements/wrappers';
import { RealEstateFoundsForm } from '../../../components/modules/realStateFunds/RealEstateFundsForm';

import schema from './schema';

export const CreateRealEstateFound: React.FC = () => {
  const history = useHistory();

  const [estateFounds, setEstateFounds] = useState<RealStateFoundProps>(
    {} as RealStateFoundProps
  );

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();

      try {
        await schema.validate(estateFounds, {
          abortEarly: false,
        });

        await hubApiProducts.postCreateRealEstateFunds(estateFounds);
        toast.dark('Fundo imobiliário cadastrado com sucesso.');
        history.goBack();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o Fundo Imobiliário, por favor tente novamente.'
          );
        }
      }
    },
    [estateFounds, history]
  );

  return (
    <PageWrapper>
      <RealEstateFoundsForm
        estateFounds={estateFounds}
        setEstateFounds={setEstateFounds}
        handleSubmitForm={handleSubmitForm}
        label="Criar Fundo Imobiliário"
        buttonName="Criar fundo"
      />
    </PageWrapper>
  );
};
