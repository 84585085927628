import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Flex, Stack, Text } from '@chakra-ui/react';
import { formatToPhone } from 'brazilian-values';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import { BaseLabelText } from 'components/elements/texts';
import { IPostOrderIp } from 'pages/IP/interfaces';
import { handleBlurCLient } from 'pages/IP/utils';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface NauticalFormProps {
  handleShowModal: () => void;
  title: string;
  segment: string;
  subSegment?: string;
  description: string;
}

export const NauticalForm: React.FC<NauticalFormProps> = ({
  handleShowModal,
  title,
  segment,
  subSegment,
  description,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInfos, setFormInfos] = useState<IPostOrderIp>({
    segment,
    isClient: true,
  } as IPostOrderIp);
  const [selectedNauticalType, setSelectedNauticalType] = useState('');
  const [disabledInput, setDisabledInput] = useState(false);

  const nauticalTypes = [
    { title: 'Lancha', value: 'lancha' },
    { title: 'Jet-Ski', value: 'jet-ski' },
    { title: 'Iate', value: 'iate' },
    { title: 'Veleiro', value: 'veleiro' },
  ];

  const handleCheckClient = () => {
    setFormInfos({ ...formInfos, isClient: !formInfos.isClient });
  };

  const onBlurClient = async (event: React.FocusEvent<HTMLInputElement>) =>
    handleBlurCLient(event, setFormInfos, formInfos);

  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!handleInputDisabled(formInfos.name)) {
        setIsLoading(false);
        return toast.dark('Escreva o nome completo do cliente');
      }
      await hubApiIp.postOrderIp({
        ...formInfos,
        phone: formInfos.phone.replace(/\D/g, ''),
        nauticalType: selectedNauticalType,
        name: formatNameToCaptalize(formInfos.name),
        subSegment,
      });
      setIsLoading(false);
      handleShowModal();
      toast.dark('Ordem enviada com sucesso!');
    } catch (error) {
      if (error.response.status === 409) {
        setIsLoading(false);

        return toast.dark(
          'Já existe uma ordem deste segmento para este cliente'
        );
      }

      toast.dark(
        'Não foi possível enviar a ordem, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const handleInputDisabled = (name: string) => {
    const data = name;

    if (formInfos.isClient) {
      const verifyData = data.split(' ');
      if (verifyData[1]) {
        setDisabledInput(true);
        return true;
      }
      if (!verifyData[1]) {
        setDisabledInput(false);
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDisabledInput(formInfos.isClient);
  }, [formInfos.isClient]);

  return (
    <BaseModalFormCard
      isModal
      maxW={['95%', '800px', '95%', '800px']}
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
    >
      <Flex gridGap={10} flexDirection={['column', 'row']}>
        <Flex
          flex="1"
          flexDirection="column"
          overflowY={['auto', 'unset']}
          maxH={['200px', '600px']}
        >
          {description !== '' && <Text>{description}</Text>}
        </Flex>

        <Flex
          flex="1"
          flexDirection="column"
          overflowY={['auto', 'unset']}
          maxH={['200px', '600px']}
        >
          <Flex justifyContent="flex-end" mt={-8}>
            <Checkbox size="sm" onChange={handleCheckClient}>
              Não é cliente EWZ
            </Checkbox>
          </Flex>

          <Stack spacing="3">
            {formInfos.isClient && (
              <InputWithLabel
                label="Código do cliente:"
                placeholder="Código BTG do cliente"
                name="clientCode"
                onBlur={onBlurClient}
                width={['95%', '100%']}
              />
            )}
            <InputWithLabel
              label="Nome:"
              type="text"
              placeholder="Nome do cliente"
              name="name"
              value={formInfos.name}
              onChange={handleChangeValue}
              isDisabled={disabledInput}
              isRequired
              width={['95%', '100%']}
            />
            <InputWithLabel
              label="E-mail:"
              type="text"
              placeholder="E-mail do cliente"
              name="email"
              value={formInfos.email}
              onChange={handleChangeValue}
              isRequired
              width={['95%', '100%']}
            />
            <InputWithLabel
              label="Telefone:"
              type="text"
              placeholder="Telefone do cliente"
              name="phone"
              value={formInfos.phone && formatToPhone(formInfos.phone)}
              onChange={handleChangeValue}
              isRequired
              width={['95%', '100%']}
            />

            <Flex flexDir="column" width={['95%', '100%']}>
              <BaseLabelText>Tipo de embarcação:</BaseLabelText>

              <Dropdown
                placeholder="Selecione o tipo"
                onChange={e => setSelectedNauticalType(e.target.value)}
                isRequired
              >
                {nauticalTypes.map((item, index) => (
                  <option key={`${index}-${item.title}`} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Flex>

            <TextareaWithLabel
              label="Informações:"
              placeholder="Informações relevantes"
              name="information"
              value={formInfos.information}
              onChange={handleChangeValue}
              width={['95%', '100%']}
            />
          </Stack>
        </Flex>
      </Flex>
    </BaseModalFormCard>
  );
};
