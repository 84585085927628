import { Dropdown } from 'components/elements/others';
import {
  IEmployeesDistributionInfo,
  IPoolList,
} from 'pages/DistributionOfMinedLeads/interfaces';

interface IDropdownListProps {
  setEmployeeSingleRow: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo>
  >;
  employeeSingleRow: IEmployeesDistributionInfo;
  allPoolLists: IPoolList[];
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownList: React.FC<IDropdownListProps> = ({
  setEmployeeSingleRow,
  employeeSingleRow,
  allPoolLists,
  setIsSaved,
}) => {
  const handleSelectListDropdown = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsSaved(false);
    if (e.target.value !== '') {
      setEmployeeSingleRow({
        ...employeeSingleRow,
        poolNameList: e.target.value,
      });
    }
  };

  return (
    <Dropdown
      onChange={e => handleSelectListDropdown(e)}
      width={['200px', '100%']}
      defaultValue={
        !employeeSingleRow.poolNameList ? '' : employeeSingleRow.poolNameList
      }
    >
      <option value="" disabled>
        Selecione uma lista
      </option>
      {allPoolLists.map(pool => (
        <option value={pool.PoolName} key={pool.PoolName}>
          {pool.PoolName}
        </option>
      ))}
    </Dropdown>
  );
};
