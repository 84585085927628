import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const LeadDetailsSkeletonLoading = (): JSX.Element => {
  return (
    <PageWrapper paddingTop="2rem" paddingBottom="2rem" paddingX="5rem">
      <SkeletonBase w="100%" borderRadius={3} style={{ flex: 1 }} />
    </PageWrapper>
  );
};
