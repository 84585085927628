import React from 'react';
import { Text, TextProps, Stack } from '@chakra-ui/react';

export const MenuDetailUser: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Stack
      flexDir="row"
      spacing={0}
      alignItems="center"
      gridGap="11px"
      h="6"
      mt="12px"
      mb="24px"
    >
      <Text
        fontSize={{ base: 'md', lg: 'lg', xl: 'xl' }}
        mb="6"
        color="white"
        fontWeight="semibold"
        {...rest}
      >
        {children}
      </Text>
    </Stack>
  );
};
