import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import { BackPageButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { PortfolioExample } from '../../../components/modules/portfolio';

interface ISegmentItem {
  id?: string;
  name: string;
  percent: string | number;
}

interface IPortfolio {
  id: string;
  name: string;
  segments: ISegmentItem[];
}

export const ListPortfolios: React.FC = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [portfolioSelected, setPortfolioSelected] = useState<IPortfolio>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectPortfolio = (portifolioId: string) => {
    const foundPortfolio: IPortfolio | undefined = portfolios.find(
      (item: IPortfolio) => item.id === portifolioId
    );

    setPortfolioSelected(foundPortfolio);
  };

  const listPortfolios = async () => {
    setLoading(true);
    try {
      await hubApiProducts.getAllportifolio().then(data => {
        setPortfolios(data);
        setPortfolioSelected(data[0]);
      });
    } catch (err) {
      toast.dark(
        'Ocorreu um erro ao buscar as carteiras. Tente novamente mais tarde'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    listPortfolios();
  }, []);

  return (
    <PageWrapper justifyContent="center" pt="48px">
      <BackPageButton />

      <Flex width="100%" flexDir="column" maxW="1080px">
        <MainPageTitle alignSelf="center" mb="8">
          Carteiras
        </MainPageTitle>
        <Flex justifyContent="flex-end" alignItems="center" mb="24px">
          <Text color="rgba(70, 70, 70, 1)" mr="12px">
            Selecione a carteira:
          </Text>
          <Dropdown onChange={e => handleSelectPortfolio(e.target.value)}>
            {portfolios?.map((item: IPortfolio, index: number) => (
              <option key={index} value={item.id}>{`${item.name}`}</option>
            ))}
          </Dropdown>
        </Flex>

        {loading && <Text color="gray.200">Caregando carteiras...</Text>}

        {portfolios.length <= 0 ? (
          <Text>Não existem Carteiras cadastradas.</Text>
        ) : (
          <Flex width="100%" justifyContent="center">
            <PortfolioExample portfolio={portfolioSelected} />
          </Flex>
        )}
      </Flex>
    </PageWrapper>
  );
};
