import { Flex, Text } from '@chakra-ui/react';
import { formatToBRL } from 'brazilian-values';

import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { ResponsiveLineChart } from 'pages/GenerationalVariables/components/modules/ResponsiveLineChart';
import { WrapperBigNumbersMiGeneral } from 'pages/GenerationalVariables/components/modules/WrapperBigNumbersMiGeneral';
import { WrapperBigNumbersMiPl } from 'pages/GenerationalVariables/components/modules/WrapperBigNumbersMiPl';
import { IInfosMundoInvest } from 'pages/GenerationalVariables/interfaces';
import { formatDate } from 'utils/formatDate';

interface BigNumbersMundoInvestProps {
  dates: {
    month0: string;
    month1: string;
    month2: string;
    month3: string;
    month4: string;
    month5: string;
  };
  bigNumbers: IInfosMundoInvest;
}

export const BigNumbersMundoInvest: React.FC<BigNumbersMundoInvestProps> = ({
  dates,
  bigNumbers,
}) => {
  return (
    <DefaultPageWrapper maxW="none" backPageButton>
      <MainPageTitle textAlign="center">
        {' '}
        Mundo Invest - Big Numbers
      </MainPageTitle>

      <Flex
        w="100%"
        h="58vh"
        gridGap="5"
        align="center"
        justify="space-between"
      >
        {!bigNumbers.graphPL ? (
          <Flex minW="100%">
            <LoadingSpinner w="100%" />
          </Flex>
        ) : (
          <>
            <ResponsiveLineChart date={bigNumbers} infoDates={dates} />

            <Flex
              gridGap="3"
              align="center"
              justifyContent="center"
              flexDir="column"
            >
              <Text color="whiteAlpha.900" fontWeight="bold">
                PL
              </Text>
              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month0.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[5])}
                </Text>
              </WrapperBigNumbersMiPl>

              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month1.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[4])}
                </Text>
              </WrapperBigNumbersMiPl>

              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month2.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[3])}
                </Text>
              </WrapperBigNumbersMiPl>

              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month3.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[2])}
                </Text>
              </WrapperBigNumbersMiPl>

              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month4.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[1])}
                </Text>
              </WrapperBigNumbersMiPl>

              <WrapperBigNumbersMiPl>
                <Text>{formatDate(dates.month5.slice(0, 7)).slice(4, 30)}</Text>
                <Text fontWeight="bold" w="140px" textAlign="center">
                  {formatToBRL(bigNumbers.graphPL[0])}
                </Text>
              </WrapperBigNumbersMiPl>
            </Flex>
          </>
        )}
      </Flex>

      <Flex
        mt="3"
        borderTop="1px solid gray"
        pt="3"
        justify="center"
        justifyContent="space-between"
        w="100%"
      >
        <WrapperBigNumbersMiGeneral>
          <Text>Leads hoje</Text>
          <Text fontSize="lg" fontWeight="bold">
            {bigNumbers.leads}
          </Text>
        </WrapperBigNumbersMiGeneral>

        <WrapperBigNumbersMiGeneral>
          <Text>Em prospecção</Text>
          <Text fontSize="lg" fontWeight="bold">
            {bigNumbers.prospect}
          </Text>
        </WrapperBigNumbersMiGeneral>
        <WrapperBigNumbersMiGeneral>
          <Text>Clientes do mês</Text>
          <Text fontSize="lg" fontWeight="bold">
            {bigNumbers.clientsMonthly}
          </Text>
        </WrapperBigNumbersMiGeneral>
        <WrapperBigNumbersMiGeneral>
          <Text>Clientes ativos / total</Text>
          <Flex gridGap={1}>
            <Text fontSize="lg" fontWeight="bold" color="green.200">
              {bigNumbers.clientsActive}
            </Text>
            <Text>/</Text>
            <Text fontSize="lg" fontWeight="bold">
              {bigNumbers.clients}
            </Text>
          </Flex>
        </WrapperBigNumbersMiGeneral>

        <WrapperBigNumbersMiGeneral>
          <Text>PL médio</Text>
          <Text fontSize="lg" fontWeight="bold">
            {formatToBRL(bigNumbers.avgPL)}
          </Text>
        </WrapperBigNumbersMiGeneral>
        <WrapperBigNumbersMiGeneral>
          <Text> PL médio (ativos)</Text>
          <Text fontSize="lg" fontWeight="bold">
            {bigNumbers.graphPL &&
              formatToBRL(
                Number(bigNumbers.graphPL[bigNumbers.graphPL.length - 1]) /
                  Number(bigNumbers.clientsActive)
              )}
          </Text>
        </WrapperBigNumbersMiGeneral>

        <WrapperBigNumbersMiGeneral>
          <Text>Δ PL mês</Text>
          <Text fontSize="lg" fontWeight="bold">
            {bigNumbers.graphPL &&
              formatToBRL(
                Number(bigNumbers.graphPL[5]) - Number(bigNumbers.graphPL[4])
              )}
          </Text>
        </WrapperBigNumbersMiGeneral>

        <WrapperBigNumbersMiGeneral>
          <Text>Ted in</Text>
          <Text fontSize="lg" fontWeight="bold" color="green.200">
            {bigNumbers.graphNNM && formatToBRL(Number(bigNumbers.graphNNM[5]))}
          </Text>
        </WrapperBigNumbersMiGeneral>
        <WrapperBigNumbersMiGeneral>
          <Text>Ted out</Text>
          <Text fontSize="lg" fontWeight="bold" color="red.200">
            {formatToBRL(Number(bigNumbers.tedout))}
          </Text>
        </WrapperBigNumbersMiGeneral>
      </Flex>
    </DefaultPageWrapper>
  );
};
