import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box, Text } from '@chakra-ui/react';

import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others/';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { AssignmentProps } from 'pages/Home/interfaces';
import { CardsInfos } from 'pages/MyClients/interfaces';
import {
  leadOrigin,
  hubLeadType,
  indicationLeadType,
  prospectLeadType,
} from './data';

interface OriginPendencyModalProps {
  assignment: AssignmentProps;
  handleToggleModal: () => void;
  handleRemoveItem: (idTask: string) => void;
}

type LeadType =
  | 'Leads Hub'
  | 'Indicação'
  | 'Prospecção independente'
  | 'Lead Mundo Invest'
  | 'Não sei';
type HubLeadType =
  | 'Lead Mundo Invest'
  | 'Lead Minerado'
  | 'Lead Pessoal'
  | 'Lead PJ';
type IndicationLeadType =
  | 'Amigos, família'
  | 'Código do responsável'
  | 'Outro cliente'
  | 'Código do responsável';
type ProspectLeadType = 'LinkedIn' | 'Evento' | 'Instagram/Facebook' | 'Outro';
type Answer = HubLeadType | IndicationLeadType | ProspectLeadType;

export const OriginPendencieModal: React.FC<OriginPendencyModalProps> = ({
  assignment,
  handleToggleModal,
  handleRemoveItem,
}): JSX.Element => {
  const [clients, setClients] = useState<CardsInfos[]>([]);

  const handleGetClients = async () => {
    try {
      const response = await hubApiUser.listMyCLientsByClient('all', 'pl');

      setClients(response);
    } catch (err) {
      toast.dark('Erro ao buscar os clientes!');
    }
  };

  useEffect(() => {
    handleGetClients();
  }, []);

  const [typeLead, setTypeLead] = useState<LeadType>();
  const [answer, setAnswer] = useState<Answer>();
  const [formInfos, setFormInfos] = useState({
    aditionalInformation: '',
  });

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement> | string
  ): void => {
    if (typeof e === 'string') {
      setFormInfos({ ...formInfos, aditionalInformation: e });
    } else {
      setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
    }
  };

  const handleUpdatetypeLead = (e: LeadType): void => {
    setTypeLead(e);
  };

  const handleFormSubmit = async (
    e: FormEvent
  ): Promise<React.ReactText | void> => {
    e.preventDefault();

    if (typeLead === 'Não sei') {
      try {
        await hubApiUser.updateClientsContactOrigin({
          id: assignment.id,
          fromType: typeLead,
          fromTypeLead: 'Outro',
          additionalInformation: formInfos.aditionalInformation,
        });

        handleRemoveItem(assignment.idTask);
        return handleToggleModal();
      } catch (err) {
        return toast.dark(`Erro ao definir a origem o cliente`);
      }
    }

    if (typeLead && !answer) {
      try {
        await hubApiUser.updateClientsContactOrigin({
          id: assignment.id,
          fromType: typeLead,
          fromTypeLead: 'Lead Mundo Invest',
          additionalInformation: formInfos.aditionalInformation,
        });

        handleRemoveItem(assignment.idTask);
        return handleToggleModal();
      } catch (err) {
        toast.dark(`Erro ao definir a origem o cliente`);
      }
    }

    if (!answer || !typeLead) {
      return toast.dark('Preencha todas as informações');
    }

    try {
      await hubApiUser.updateClientsContactOrigin({
        id: assignment.id,
        fromType: typeLead,
        fromTypeLead: answer,
        additionalInformation: formInfos.aditionalInformation,
      });

      handleRemoveItem(assignment.idTask);
      handleToggleModal();
    } catch (err) {
      toast.dark(`Erro ao definir a origem o cliente`);
    }
  };

  return (
    <>
      <DefaultModalContentWrapper maxW="500px">
        <form onSubmit={handleFormSubmit}>
          <Flex flexDir="column">
            <ModalTitle fontSize="lg" mb={0}>
              {`Como o(a) cliente ${assignment.name.toUpperCase()} foi captado(a) ?`}
            </ModalTitle>

            <Text my={2} fontSize="sm" textAlign="end">
              Cod. BTG: {assignment.btgAccount}
            </Text>

            <Flex align="center" justify="space-between">
              <Dropdown
                placeholder="Escolha uma das opções"
                onChange={e => handleUpdatetypeLead(e.target.value as LeadType)}
              >
                {leadOrigin.map(item => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Dropdown>

              {typeLead === 'Leads Hub' && (
                <Dropdown
                  placeholder="Qual o tipo do lead"
                  onChange={e => setAnswer(e.target.value as Answer)}
                >
                  {hubLeadType.map(item => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Dropdown>
              )}

              {typeLead === 'Indicação' && (
                <Flex flexDir="column">
                  <Dropdown
                    placeholder="Escolha o tipo de indicação"
                    onChange={e => setAnswer(e.target.value as Answer)}
                  >
                    {indicationLeadType.map(item => (
                      <option key={item.name} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Dropdown>

                  {(answer === 'Amigos, família' ||
                    answer === 'Outro cliente') && (
                    <Dropdown
                      mt="3"
                      maxW="230px"
                      placeholder="Escolha o cliente"
                      onChange={e => handleChangeValue(e.target.value)}
                    >
                      {clients.map(item => (
                        <option
                          key={item.name}
                          value={`${item.name}-${item.btgAccount}`}
                        >
                          {item.name} - {item.btgAccount}
                        </option>
                      ))}
                    </Dropdown>
                  )}

                  {answer === 'Código do responsável' && (
                    <Box mt="2">
                      <InputWithLabel
                        label="Codigo BTG"
                        name="aditionalInformation"
                        onChange={handleChangeValue}
                      />
                    </Box>
                  )}
                </Flex>
              )}

              {typeLead === 'Prospecção independente' && (
                <Flex flexDir="column">
                  <Dropdown
                    placeholder="Escolha um tipo"
                    onChange={e => setAnswer(e.target.value as Answer)}
                  >
                    {prospectLeadType.map(item => (
                      <option key={item.name} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Dropdown>

                  {answer === 'Evento' && (
                    <Box mt="2">
                      <InputWithLabel
                        label="Qual o evento?"
                        name="aditionalInformation"
                        onChange={handleChangeValue}
                      />
                    </Box>
                  )}

                  {answer === 'Outro' && (
                    <Box mt="2">
                      <InputWithLabel
                        label="Como esse cliente foi captado?"
                        name="aditionalInformation"
                        onChange={handleChangeValue}
                      />
                    </Box>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>

          <BaseButtonGroup mt="5">
            <SecondaryButton onClick={handleToggleModal}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit">Confirmar</PrimaryButton>
          </BaseButtonGroup>
        </form>
      </DefaultModalContentWrapper>
    </>
  );
};
