import React, { useCallback, useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { Contract } from 'pages/SearchUserDetails/interfaces';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { hubApiOfficeBranches } from 'services/hubApi/classes/HubApiOfficeBranches';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { NumberInputWithLabel } from 'components/elements/forms';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import schema from './schema';

interface IContractPage {
  employee: EmployeesProps;
}

interface IOfficeProps {
  id: string;
  city: string;
  zona: string;
}

interface ITableProps {
  id: string;
  name: string;
  officeId: string;
}

interface IRvManager {
  id: string;
  name: string;
}

export const ContractPage: React.FC<IContractPage> = ({ employee }) => {
  const history = useHistory();
  const [typeFinance] = useState([
    { value: 'MINIMUM', label: 'Mínimo Garantido ' },
    { value: 'FIXED', label: 'Fixo' },
    { value: 'VARIABLE', label: 'Variável' },
  ]);

  const [descontinued, setDescontinued] = useState<boolean>(false);
  const [removeInMIModal, setRemoveInMIModal] = useState<boolean>(false);
  const [tables, setTables] = useState<ITableProps[]>([]);

  const [offices, setOffices] = useState<IOfficeProps[]>([]);
  const [rvManagers, setRvManagers] = useState<IRvManager[]>([]);

  const [contractData, setContractData] = useState<Contract>({
    BTGCode: employee.BTGCode,
    intelligence: employee.intelligence,
    salary: employee.salary,
    tableId: employee.tableId,
    mundoInvest: employee.mundoInvest,
    salaryType: employee.salaryType,
    rv: employee.rv,
    officeId: employee.officeId,
    rvManager: employee.rvManager,
    accessory: employee.accessory,
  } as Contract);

  const hadleChangeValue = useCallback(
    e => {
      setContractData({
        ...contractData,
        [e.target.name]:
          e.target.name === 'salaryType'
            ? String(e.target.value).toUpperCase()
            : e.target.value,
      });
    },
    [contractData, setContractData]
  );

  const validationDatasForm = (data: string): number => {
    if (data && data !== 'NaN') {
      return Number(data);
    }
    if (data === 'NaN') {
      return 0;
    }
    return 0;
  };

  const handleDescontinuedEmployee = async (discontinuedInMI: boolean) => {
    const data: any = {};

    if (discontinuedInMI) {
      data.discontinuedInMI = true;
    } else {
      data.discontinuedInMI = false;
    }

    try {
      await hubApiUser.deleteEmployee(employee.id, data);
      setDescontinued(!descontinued);
      setRemoveInMIModal(false);
      history.push('/users/search/all');
      toast.dark('Contrato com usuário descontinuado com sucesso!');
    } catch (error) {
      toast.dark('Ocorreu um erro ao descontinuar usuário!');
    }
  };

  const handleSelectOffice = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    hadleChangeValue(event);
    try {
      const tablesResponse = await hubApiOfficeTables.listTablesByOffice(
        event.target.value
      );
      setTables([...tablesResponse]);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar mesas da filial');
    }
  };

  useEffect(() => {
    async function getOffices() {
      try {
        const response = await hubApiOfficeBranches.listBranchOffices();
        setOffices(response);
      } catch (err) {
        toast.dark('Ocorreu um erro ao buscar as filias');
      }
    }

    async function getTables() {
      try {
        const tablesResponse = await hubApiOfficeTables.listTablesByOffice(
          contractData.officeId
        );
        setTables(tablesResponse);
      } catch (error) {
        toast.dark('Ocorreu um erro ao buscar as mesas');
      }
    }

    async function getRvManager() {
      try {
        const rvManagerResponse =
          (await hubApiRv.listEmployeesWithRvPermission()) as IRvManager[];
        setRvManagers(rvManagerResponse);
      } catch (error) {
        toast.dark('Ocorreu um erro ao buscar os gestores de RV!');
      }
    }

    getOffices();
    if (contractData.officeId) {
      getTables();
    }
    getRvManager();
  }, []);

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();

      try {
        await schema.validate(contractData, {
          abortEarly: false,
        });

        const formData = {
          BTGCode: contractData.BTGCode ? contractData.BTGCode : '',
          rvManager: contractData.rvManager ? contractData.rvManager : '',
          salary: contractData.salary ? contractData.salary : '',
          salaryType: contractData.salaryType ? contractData.salaryType : '',
          rv: contractData.rv ? contractData.rv : '',
          intelligence: contractData.intelligence
            ? contractData.intelligence
            : '',
          accessory: contractData.accessory ? contractData.accessory : '',
          mundoInvest: contractData.mundoInvest ? contractData.mundoInvest : '',
          tableId: contractData.tableId ? contractData.tableId : '',
        };

        await hubApiUser.updateEmployee(employee.id, formData);
        toast.dark('Dados do contrato atualizados com sucesso!');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark('Ocorreu um erro ao atualizar dados do usuário!');
        }
      }
    },
    [contractData]
  );

  return (
    <Flex w="100%" gridGap={6} borderRadius={4} bg="#f2f2f2" px="5" py="5">
      <Stack w="100%" flexDir="column" spacing={6} height={['55vh', 'auto']}>
        <Flex
          gridGap="12px"
          flexDirection={['column', 'row']}
          height={['500px', 'auto']}
          overflowY={['auto', 'unset']}
        >
          <Stack w="100%" spacing={4}>
            <NumberInputWithLabel
              defaultValue={validationDatasForm(contractData.BTGCode)}
              value={validationDatasForm(contractData.BTGCode)}
              label="Código BTG"
              placeholder="Código BTG"
              onChange={hadleChangeValue}
              name="BTGCode"
              width={['95%', 'auto']}
            />
            <DropdownWithLabel
              label="Filial"
              placeholder="Selecione uma filial"
              value={
                contractData.officeId === null ||
                contractData.officeId === undefined
                  ? ''
                  : contractData.officeId
              }
              onChange={handleSelectOffice}
              name="officeId"
              width={['95%', 'auto']}
            >
              {offices.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.city} - {item.zona}
                  </option>
                );
              })}
            </DropdownWithLabel>

            <NumberInputWithLabel
              label="Salário"
              defaultValue={validationDatasForm(contractData.salary)}
              value={validationDatasForm(contractData.salary)}
              placeholder="Informe o salário"
              onChange={hadleChangeValue}
              name="salary"
              width={['95%', 'auto']}
            />
            <NumberInputWithLabel
              defaultValue={validationDatasForm(contractData.rv)}
              label="Renda Variável"
              value={validationDatasForm(contractData.rv)}
              placeholder="%"
              onChange={hadleChangeValue}
              name="rv"
              width={['95%', 'auto']}
            />
            <NumberInputWithLabel
              label="Assessoria"
              defaultValue={validationDatasForm(contractData.accessory)}
              value={validationDatasForm(contractData.accessory)}
              placeholder="%"
              onChange={hadleChangeValue}
              name="accessory"
              width={['95%', 'auto']}
            />
          </Stack>

          <Stack w="100%" spacing={4}>
            <DropdownWithLabel
              width={['95%', 'auto']}
              label="Responsável de Renda Variável"
              textTransform="capitalize"
              bg="background.500"
              placeholder="Selecione um gesto de RV"
              value={
                contractData.rvManager === null ||
                contractData.rvManager === undefined
                  ? ''
                  : contractData.rvManager
              }
              onChange={hadleChangeValue}
              name="rvManager"
            >
              {rvManagers.map((item, index) => {
                return (
                  <option
                    style={{ textTransform: 'capitalize' }}
                    key={index}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                );
              })}
            </DropdownWithLabel>
            <DropdownWithLabel
              width={['95%', 'auto']}
              label="Mesa"
              bg="background.500"
              placeholder="Selecione uma mesa"
              value={
                contractData.tableId === null ||
                contractData.tableId === undefined
                  ? ''
                  : contractData.tableId
              }
              onChange={hadleChangeValue}
              name="tableId"
            >
              {tables.map(table => {
                return (
                  <option key={table.id} value={table.id}>
                    {table.name}
                  </option>
                );
              })}
            </DropdownWithLabel>

            <DropdownWithLabel
              label="Tipo de Salário"
              width={['95%', 'auto']}
              bg="background.500"
              placeholder="Selecione o mínimo garantido"
              value={
                contractData.salaryType === null ||
                contractData.salaryType === undefined
                  ? ''
                  : contractData.salaryType
              }
              onChange={hadleChangeValue}
              name="salaryType"
            >
              {typeFinance.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </DropdownWithLabel>

            <NumberInputWithLabel
              label="Inteligência"
              defaultValue={validationDatasForm(contractData.intelligence)}
              value={validationDatasForm(contractData.intelligence)}
              placeholder="%"
              onChange={hadleChangeValue}
              name="intelligence"
              width={['95%', 'auto']}
            />
            <NumberInputWithLabel
              label="Mundo Invest"
              defaultValue={validationDatasForm(contractData.mundoInvest)}
              value={validationDatasForm(contractData.mundoInvest)}
              placeholder="%"
              onChange={hadleChangeValue}
              name="mundoInvest"
              width={['95%', 'auto']}
            />
          </Stack>
        </Flex>

        <BaseButtonGroup>
          <SecondaryButton onClick={() => setDescontinued(!descontinued)}>
            Descontinuar Contrato
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm} style={{ margin: '0' }}>
            Salvar
          </PrimaryButton>
        </BaseButtonGroup>
      </Stack>

      <ModalWrapper
        isOpen={descontinued}
        onClose={() => setDescontinued(!descontinued)}
      >
        <DefaultModalContentWrapper maxW={['95vw', '420px']}>
          <Stack spacing={4}>
            <Text fontSize="lg" color="white" fontWeight="semibold">
              Você deseja descontinuar o contrato de{' '}
              <Text as="span" textTransform="capitalize">
                {employee.name}
              </Text>
              ?
            </Text>
            <BaseButtonGroup>
              <SecondaryButton onClick={() => setDescontinued(!descontinued)}>
                Não
              </SecondaryButton>
              <PrimaryButton
                onClick={() => setRemoveInMIModal(true)}
                style={{ margin: '0' }}
              >
                Sim
              </PrimaryButton>
            </BaseButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={removeInMIModal}
        onClose={() => setRemoveInMIModal(false)}
      >
        <DefaultModalContentWrapper maxW="450px">
          <ModalTitle>
            Deseja apagar este usuário da plataforma Mundo Invest?
          </ModalTitle>

          <BaseButtonGroup>
            <SecondaryButton onClick={() => handleDescontinuedEmployee(false)}>
              Não
            </SecondaryButton>
            <PrimaryButton onClick={() => handleDescontinuedEmployee(true)}>
              Sim
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </Flex>
  );
};
