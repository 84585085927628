import { ModalWrapper } from 'components/elements/wrappers';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentProps } from 'pages/Home/interfaces';
import { useState } from 'react';
import { WarningCircle } from 'phosphor-react';
import formatValue from 'utils/formatValue';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface IStuckMoneyCardProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
}

export const StuckMoneyCard: React.FC<IStuckMoneyCardProps> = ({
  assignment,
  handleRemoveItem,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const { name, stuckMoneyAmount, btgAccount } = JSON.parse(
    assignment.extra || ''
  );

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconStuckMoney={WarningCircle as any}
        title="Dinheiro parado"
        secondaryText={`Cliente ${name} está com ${formatValue(
          stuckMoneyAmount
        )} parado!`}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Dinheiro parado"
          message={`Você confirma que o dinheiro do(a) cliente ${name} já foi alocado?`}
          btgAccount={btgAccount}
          idTask={assignment.id}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
