import { Box, Flex, Input, Text, Tooltip } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formatNameToCaptalize } from 'utils/captalizeNames';

export interface IEmployee {
  id: string;
  name: string;
  employeeType: 'SH' | 'AAI';
}

interface IProps {
  customTermToSearch: string;
  setCustomTermToSearch(value: string): void;
  setSelectItem?(value: string): void;
  setEmployee?(value: IEmployee): void;
  employeeType?: 'ALL' | 'SH' | 'AAI';
  width?: string;
}

export const InputWithEmployeeDropdown: React.FC<IProps> = ({
  customTermToSearch,
  setCustomTermToSearch,
  setSelectItem,
  setEmployee,
  employeeType,
  width,
}) => {
  const [showMyTerms, setShowMyTerms] = useState<boolean>(false);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [filteredEmployees, setFilteredEmployes] = useState<IEmployee[]>([]);

  useQuery(
    'allEmployees',
    async () => {
      const NO_FILTER = '';
      let response;
      if (employeeType && (employeeType === 'AAI' || employeeType === 'SH')) {
        response = await hubApiCaptation.getEmployeesInGroup(employeeType);
      } else {
        response = await hubApi.filterEmployees('name', NO_FILTER);
      }

      setFilteredEmployes(response);
      setEmployees(response);
    },
    {
      onError: () => {
        toast.dark(
          'Não foi possivel achar o(s) usuários. Tente novamente mais tarde.'
        );
      },
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (customTermToSearch === '') {
      return setFilteredEmployes(employees);
    }
    const filter = employees.filter(employee =>
      employee.name.includes(customTermToSearch.toLowerCase())
    );
    setFilteredEmployes(filter);
  }, [customTermToSearch]);

  return (
    <>
      <Flex w={width ?? '45%'} flexDirection="column" position="relative">
        <Flex
          background="white"
          border="1px solid #1d63ddb2"
          borderRadius="6px"
          w="100%"
          justifyContent="space-between"
        >
          <Input
            size="sm"
            border="none"
            placeholder="Digite o nome do assessor"
            _placeholder={{ color: 'rgba(70, 70, 70, 1)', fontStyle: 'italic' }}
            value={customTermToSearch}
            onChange={e => {
              if (showMyTerms === false) {
                setShowMyTerms(true);
              }
              setCustomTermToSearch(e.target.value);
            }}
            focusBorderColor="none"
            _hover={{ borderColor: 'none' }}
          />
          <Flex alignItems="center" gridGap={1} pr={2}>
            {customTermToSearch !== '' && (
              <Tooltip
                hasArrow
                label="Limpar"
                color="white"
                bg="background.200"
              >
                <Box onClick={() => setCustomTermToSearch('')} cursor="pointer">
                  <AiOutlineClose />
                </Box>
              </Tooltip>
            )}

            {showMyTerms && (
              <RiArrowUpSLine
                size={22}
                color="rgba(70, 70, 70, 1)"
                cursor="pointer"
                onClick={() => setShowMyTerms(!showMyTerms)}
              />
            )}

            {!showMyTerms && (
              <RiArrowDownSLine
                size={22}
                color="rgba(70, 70, 70, 1)"
                cursor="pointer"
                onClick={() => setShowMyTerms(!showMyTerms)}
              />
            )}
          </Flex>
        </Flex>

        {showMyTerms && (
          <Flex
            background="white"
            border="1px solid #1d63ddb2"
            maxH="350px"
            flexDirection="column"
            overflowY="auto"
            position="absolute"
            w="100%"
            top={8}
            p={2}
            gap={2}
            className="slimScrollbar"
            zIndex={999}
          >
            {filteredEmployees &&
              filteredEmployees.length > 0 &&
              filteredEmployees.map(employee => (
                <Flex
                  px={2}
                  py={1}
                  cursor="pointer"
                  _hover={{ background: '#f2f2f2' }}
                  borderRadius="6px"
                  onClick={() => {
                    setEmployee && setEmployee(employee);
                    setSelectItem && setSelectItem(employee.id);
                    setCustomTermToSearch(formatNameToCaptalize(employee.name));
                    setShowMyTerms(false);
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                  key={employee.id}
                >
                  <Text fontSize="sm" color="rgba(70, 70, 70, 1)" isTruncated>
                    {formatNameToCaptalize(employee.name)}
                  </Text>
                </Flex>
              ))}
            {filteredEmployees && filteredEmployees.length === 0 && (
              <Flex
                px={2}
                py={1}
                cursor="pointer"
                borderRadius="6px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize="sm" color="rgba(70, 70, 70, 1)" isTruncated>
                  Sem resultados
                </Text>
              </Flex>
            )}
            {employees.length === 0 && (
              <Flex justifyContent="center" alignItems="center">
                <LoadingSpinner size="lg" />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};
