import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

interface IInputInfoDisplayProps extends FlexProps {
  field: string;
  data: string | undefined;
}

export const InputInfoDisplay: React.FC<IInputInfoDisplayProps> = ({
  field,
  data,
  ...rest
}) => {
  return (
    <Flex {...rest}>
      <Text
        color="rgba(70, 70, 70, 1)"
        fontWeight="bold"
        mr={['4px', '8px']}
        fontSize={['sm', 'md']}
      >
        {`${field}:`}
      </Text>
      <Text
        fontSize={['sm', 'md']}
        color="rgba(70, 70, 70, 1)"
        maxW={['180px', '290px']}
        isTruncated
      >
        {data}
      </Text>
    </Flex>
  );
};
