import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IEmployeeMibs } from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export const useEmployeeMibs = (
  userId: string,
  customQueryKey?: string
): UseQueryResult<IEmployeeMibs[]> => {
  return useQuery<IEmployeeMibs[]>(
    customQueryKey || MIBS_QUERIES_KEYS.employeeMibs,
    async () => {
      const response = await hubApiCaptation.getEmployeeMibs(userId);
      return response;
    },
    {
      onError: err => {
        const error = err as AxiosError;
        if (
          error.response?.data.error.message ===
          'No registry in Personal Wallet for this employeeId'
        ) {
          return toast.dark(
            'Colaborador não possui carteira pessoal. Por favor contate o suporte.'
          );
        }

        if (
          error.response?.data.error.message ===
          'No registry in EWZ Wallet for this employeeId'
        ) {
          return toast.dark(
            'Colaborador não possui carteira EWZ. Por favor contate o suporte.'
          );
        }

        return toast.dark('Erro ao mostrar mibs. Tente novamente mais tarde.');
      },
      refetchOnWindowFocus: true,
    }
  );
};
