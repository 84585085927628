const fictionalClient = {
  name: 'Rogério Pereira Albuquerque',
  phones: ['(11)999999999', '(11)988887777', '(11)966665555'],
  emails: ['email1@test', 'email2@test', 'email3@test'],
};

const interestOptions = [
  { title: 'Alto', value: 'high' },
  { title: 'Sem interesse', value: 'no_interest' },
  { title: 'Não atendeu', value: 'no_answer' },
  { title: 'Não existe', value: 'no_exist' },
  { title: 'Não ligar mais', value: 'no_exist' },
  { title: 'Não tem dinheiro', value: 'no_money' },
  { title: 'Cliente BTG', value: 'btg_client' },
];

const localOptions = [
  { title: 'Ligação', value: 'Ligação' },
  { title: 'Filial', value: 'Filial' },
  { title: 'Video Chamada', value: 'Video Chamada' },
  { title: 'Endereço', value: 'Endereço' },
];

const advisorOptions = [
  { title: 'Lucas' },
  { title: 'yuri' },
  { title: 'yuri' },
  { title: 'yuri' },
];

const banksOptions = [
  { title: 'Santander' },
  { title: 'Bradesco' },
  { title: 'XP' },
  { title: 'BTG' },
  { title: 'Inter' },
  { title: 'Banco do Brasil' },
  { title: 'Itaú' },
  { title: 'Clear' },
  { title: 'Modal+' },
  { title: 'Cliente EWZ' },
  { title: 'Sem banco' },
];
const segmentsOptions = [{ title: 'RD' }, { title: 'PJ' }];

const hourSchedule = [
  { title: '08h00', value: '' },
  { title: '09h00', value: '' },
  { title: '10h00', value: '' },
  { title: '11h00', value: '' },
  { title: '12h00', value: '' },
  { title: '13h00', value: '' },
  { title: '14h00', value: '' },
  { title: '15h00', value: '' },
  { title: '16h00', value: '' },
  { title: '17h00', value: '' },
  { title: '18h00', value: '' },
];

const cardInfosBase = {
  leads: [
    {
      type: 'publics',
      value: '0',
    },
    {
      type: 'pj',
      value: '0',
    },
    {
      type: 'employee',
      value: '0',
    },
    {
      type: 'personal',
      value: '0',
    },
    {
      type: 'tables',
      value: '0',
    },
  ],
  meetings: [
    {
      type: 'callback',
      value: '0',
      late: 0,
      progress: 0,
    },
    {
      type: 'suitability',
      value: '0',
      late: 0,
      progress: 0,
    },
    {
      type: 'presentation',
      value: '0',
      late: 0,
      progress: 0,
    },
    {
      type: 'allocation',
      value: '0',
      late: 0,
      progress: 0,
    },
    {
      type: 'account',
      value: '0',
      late: 0,
      progress: 0,
    },
  ],
  conversions: [
    {
      type: 'callbackToSuitability',
      value: '0',
    },
    {
      type: 'suitabilityToPresentation',
      value: '0',
    },
    {
      type: 'presentationToAlocation',
      value: '0',
    },
    {
      type: 'alocationToAccount',
      value: '0',
    },
  ],
};

export {
  fictionalClient,
  interestOptions,
  localOptions,
  hourSchedule,
  banksOptions,
  advisorOptions,
  segmentsOptions,
  cardInfosBase,
};
