import { useState } from 'react';
import { toast } from 'react-toastify';
import { Divider, Flex, Stack } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { BaseButtonGroup, ModalWrapper } from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { ILeadInfos, IQuestionProps, Task } from 'pages/Captation/interfaces';
import { LeadEmailsContent } from '../LeadEmailsContent';
import { LeadsPhonesContent } from '../LeadsPhonesContent';
import { QuestionsForm } from '../QuestionsForm';

import { DeclineOffer, ScheduleMeeting, ScheduleReturn } from '../../modals';
import { LeadTags } from '../LeadTags';
import { LeadOthersContent } from '../LeadOthersContent';

interface ManualAndSearchLeadsContentByIdProps {
  lead: ILeadInfos;
  updateTotalNumOfLeadsInPipe: () => void;
}

export const ManualAndSearchLeadsContentById: React.FC<ManualAndSearchLeadsContentByIdProps> =
  ({ lead, updateTotalNumOfLeadsInPipe }) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState<Partial<IQuestionProps>[]>([]);
    const [tasks, setTasks] = useState<Task[]>([]);
    const [interestTitle, setInterestTitle] = useState('Nivel de interesse');
    const [decline, setDecline] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const [returnModal, setReturnModal] = useState(false);

    const handleDisplayModal = () => setDisplayModal(!displayModal);
    const handleReturnModal = () => setReturnModal(!returnModal);
    const handleDecline = () => setDecline(!decline);
    const handleOpenReturnMeeting = () => {
      handleReturnModal();
      setInterestTitle('retornar');
    };

    const handleOpenScheduleMeeting = () => {
      handleDisplayModal();
      setInterestTitle('Alto');
    };

    const postTasks = async () => {
      await hubApiCaptation.postNewQuestion(response);
    };

    const handleSaveLeadInfos = async () => {
      setLoading(true);
      if (interestTitle === 'Nivel de interesse') {
        setLoading(false);
        toast.dark(
          'Selecione um nível de interesse antes de ir para o próximo lead.'
        );
        return;
      }

      try {
        setLoading(true);
      } catch (err) {
        toast.dark('Ocorreu um erro ao tentar salvar lead. Tente novamente.');
      }
    };

    return (
      <Flex flexDir="column" height="100%">
        <ModalTitle mb="1">{lead.name}</ModalTitle>

        <Flex height="100%">
          <Flex
            width="100%"
            flexDir="column"
            maxW="43%"
            overflowY="auto"
            maxH="calc(100vh - 350px)"
          >
            <Stack spacing="4" mt={5}>
              {lead?.phone && lead?.phone.length > 0 && (
                <LeadsPhonesContent phones={lead.phone} />
              )}

              {lead?.others && <LeadOthersContent others={lead.others} />}

              {lead?.email && lead?.email.length > 0 && (
                <LeadEmailsContent emails={lead.email} />
              )}

              {lead?.tag && <LeadTags tags={lead.tag} />}
            </Stack>
          </Flex>

          <Divider mx="6" orientation="vertical" borderColor="background.300" />

          <QuestionsForm
            maxHeight="calc(100vh - 402px)"
            lead={lead}
            response={response}
            setResponse={setResponse}
            tasks={tasks}
            setTasks={setTasks}
          />
        </Flex>

        <BaseButtonGroup>
          <SecondaryButton onClick={() => handleDecline()}>
            Declínio da oferta
          </SecondaryButton>

          <SecondaryButton onClick={() => handleOpenReturnMeeting()}>
            Retornar
          </SecondaryButton>

          <PrimaryButton onClick={() => handleOpenScheduleMeeting()}>
            Agendar reunião
          </PrimaryButton>
        </BaseButtonGroup>

        <ModalWrapper isOpen={decline} onClose={handleDecline}>
          <DeclineOffer
            updateTotalNumOfLeadsInPipe={updateTotalNumOfLeadsInPipe}
            handleShowModal={handleDecline}
            leadId={lead.id}
            id={lead.id}
            leadType={lead.leadType as any}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handleNextLead={() => {}}
          />
        </ModalWrapper>

        <ModalWrapper isOpen={displayModal} onClose={handleDisplayModal}>
          <ScheduleMeeting
            updateTotalNumOfLeadsInPipe={updateTotalNumOfLeadsInPipe}
            setResponse={setResponse}
            leadType={lead.leadType}
            postTasks={postTasks}
            handleShowModal={handleDisplayModal}
            id={lead.id}
            handleSaveLeadInfos={handleSaveLeadInfos}
          />
        </ModalWrapper>

        <ModalWrapper isOpen={returnModal} onClose={handleReturnModal}>
          <ScheduleReturn
            status={lead.status}
            postTasks={postTasks}
            handleShowModal={handleReturnModal}
            leadType={lead.leadType}
            id={lead.id}
          />
        </ModalWrapper>
      </Flex>
    );
  };
