import React, { ChangeEvent, FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import hubApi from 'services/hubApi';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { MainPageTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface RetrievePasswordMailProps {
  handleToggleModal: () => void;
}

const RetrievePasswordMail: React.FC<RetrievePasswordMailProps> = ({
  handleToggleModal,
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (email.trim() === '') {
        toast.dark('Digite o seu e-mail');
        return;
      }

      setLoading(true);
      await hubApi.requestResetPassword(email);

      toast.dark('E-mail enviado com sucesso.');
      setLoading(false);
    } catch (err) {
      toast.dark('Ocorreu um erro ao enviar e-mail. Tente novamente.');
      setLoading(false);
    }
  };

  return (
    <DefaultModalContentWrapper maxW="600px">
      <MainPageTitle mb={1}>Recuperar senha</MainPageTitle>

      <form onSubmit={handleFormSubmit}>
        <InputWithLabel
          label="E-mail"
          placeholder="Digite o e-mail"
          value={email}
          onChange={handleChangeValue}
        />

        <BaseButtonGroup mt={4}>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton isLoading={loading} type="submit">
            Enviar E-mail
          </PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default RetrievePasswordMail;
