import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { DefaultCardWrapper } from 'components/elements/wrappers';
import { OrderStructured } from 'pages/RV/interfaces';

interface ItemProps {
  structureds: OrderStructured;
  handleDeleteOrder: (id: string) => void;
}

export const Item: React.FC<ItemProps> = ({
  structureds,
  handleDeleteOrder,
}) => {
  return (
    <DefaultCardWrapper
      justifyContent="space-between"
      bg="background.300"
      mb="5px"
      p="3"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text color="white" fontSize="14px">
          {structureds.operation} - {structureds.ticker}
        </Text>

        <Flex justifyContent="flex-end" alignItems="center">
          <Link to={`rv/structureds/create/${structureds.id}`}>
            <EditIcon
              mr="15px"
              w="20px"
              h="20px"
              cursor="pointer"
              color="white"
            />
          </Link>
          <DeleteIcon
            onClick={() => handleDeleteOrder(structureds.id)}
            w="20px"
            h="20px"
            cursor="pointer"
            color="white"
          />
        </Flex>
      </Flex>
    </DefaultCardWrapper>
  );
};
