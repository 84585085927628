import { useMutation, UseMutationResult } from 'react-query';
import {
  hubApiCaptation,
  TransferLeadRequestType,
} from 'services/hubApi/classes/HubApiCaptation';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useTransferLead = (
  props?: PropsType
): UseMutationResult<unknown, unknown, TransferLeadRequestType> => {
  return useMutation({
    mutationKey: ['transferLead'],
    mutationFn: async (data: TransferLeadRequestType) => {
      const response = await hubApiCaptation.transferLead(data);
      return response;
    },
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
    onSuccess: props?.onSuccess,
  });
};
