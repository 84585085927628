import React, { useState } from 'react';
import { Button, Flex, Icon, InputProps, Spinner } from '@chakra-ui/react';
import { RiSendPlane2Line } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';

import { OutlineButton } from 'components/elements/buttons';
import { Input } from 'components/elements/forms';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { CloseIcon } from '@chakra-ui/icons';

interface ComponentProps extends InputProps {
  handleSearchLead?: (searchValue: string) => void;
  handleSelectedArrayLeads?: (leads: any[]) => void;
  handleResults?: (showResus: boolean) => void;
}

export const SearchLeadAAI: React.FC<ComponentProps> = ({
  handleSelectedArrayLeads,
  handleResults,
}) => {
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const handleToggleSearch = () => {
    setToggleSearch(!toggleSearch);
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClickSearch = async () => {
    try {
      setLoadingLeads(true);

      if (searchValue.length < 4) {
        setLoadingLeads(false);
        return toast.dark('Digite ao menos 4 caracteres para a busca');
      }

      const formattedSearchValue =
        searchValue.includes('@') || /[a-zA-Z]/.test(searchValue)
          ? searchValue
          : searchValue.replace(/[^0-9]/g, '');

      const searchedLead = await hubApiCaptation.searchLeadByEmployeeId(
        formattedSearchValue
      );

      if (handleSelectedArrayLeads && searchedLead.length > 0)
        handleSelectedArrayLeads(
          searchedLead?.sort((item: any, proxItem: any) => {
            let result = -1;

            if (item.name > proxItem.name) {
              result = 1;
            }
            if (item.name === proxItem.name) {
              result = 0;
            }
            return result;
          })
        );

      if (handleResults && searchedLead.length > 0) handleResults(true);

      if (searchedLead.length === 0) {
        toast.dark('Não há leads com o número digitado');
      }
    } catch (err) {
      toast.dark('Não foi possível encontrar o número digitado');
    }
    setLoadingLeads(false);
  };

  return (
    <Flex gridGap="1.5">
      <OutlineButton onClick={handleToggleSearch} p="2">
        <FaSearch size={12} />
      </OutlineButton>

      <Flex
        m="0"
        borderRadius="2"
        background="background.500"
        opacity={toggleSearch ? '0' : '1'}
        display={toggleSearch ? 'none' : 'flex'}
        transition="all 0.2s ease-in-out"
      >
        <Flex>
          <Input
            size="sm"
            px="2"
            type="text"
            placeholder="Nome, telefone ou email"
            value={searchValue}
            w={['100px', '170px']}
            onChange={handleChangeSearchValue}
          />
        </Flex>
        <Button
          size="sm"
          background="transparent"
          border="none"
          px="2"
          type="button"
          _hover={{ background: 'transparent' }}
          onClick={() => handleClickSearch()}
        >
          {loadingLeads ? (
            <Flex>
              <Spinner size="sm" />
            </Flex>
          ) : (
            <>
              <RiSendPlane2Line size={16} color="white" />
            </>
          )}
        </Button>
      </Flex>
      {!toggleSearch && (
        <Flex align="center" justify="center" ml="3">
          <Icon
            h="3"
            w="3"
            as={CloseIcon}
            cursor="pointer"
            onClick={() => {
              if (handleResults) handleResults(false);
              setSearchValue('');
              setToggleSearch(true);
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
