import { Flex, Text } from '@chakra-ui/react';
import { useTabNavigation } from 'hooksV2/useTabNavigation';
import { ClientDetailsAllocationSubPage } from 'pagesV2/ClientDetails/subpages/allocation';
import { ClientDetailsOffersSubPage } from 'pagesV2/ClientDetails/subpages/offers';
import { ClientDetailsRevenueSubPage } from 'pagesV2/ClientDetails/subpages/revenue';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsMovimentations: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { TabsDiv, selectedTab } = useTabNavigation({
    tabs: ['Receitas', 'Ofertas', 'Alocações'],
    initialTab: 'Receitas',
  });

  return (
    <Flex p={2} flexDirection="column" w="100%" gap={2}>
      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
        Movimentações do Cliente
      </Text>

      <TabsDiv />

      {selectedTab === 'Alocações' && (
        <ClientDetailsAllocationSubPage client={client} />
      )}

      {selectedTab === 'Receitas' && (
        <ClientDetailsRevenueSubPage client={client} />
      )}

      {selectedTab === 'Ofertas' && (
        <ClientDetailsOffersSubPage client={client} />
      )}
    </Flex>
  );
};
