// cada propriedade recebe uma tupla, onde a primeira posição é o nome da área e as demais posições são os temas da área
export const contentsSegments = {
  all: ['Todos'],
  sh: ['Sales Hunter', 'Processos', 'Insights'],
  hub: ['Hub', 'Captação', 'Suporte'],
  assessoria: [
    'Assessoria',
    'Captação',
    'Atendimento',
    'Insights',
    'Processos',
    'Financeiro',
  ],
  satelites: [
    'Áreas Satélites',
    'Inteligência Patrimonial',
    'Câmbio',
    'Corporate',
    'Marketing',
  ],
  rh: ['RH', 'Onboarding', 'Processos', 'Comunicação'],
};
