import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle, ModalTitle } from 'components/elements/texts';
import {
  DefaultModalContentWrapper,
  DefaultPageWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { IReduxState } from 'shared/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';

export const ListClientesFromAreas: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const history = useHistory();
  const [selectClientBtgAccount, setSelectClientBtgAccount] = useState('');
  const [isOpenModalGoToClientPage, setIsOpenModalGoToClientPage] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionsUser, setPermissionsUser] = useState<
    { name: string; value: string }[]
  >([]);
  const [segmentFilter, setSegmentFilter] = useState('');
  const [clients, setClients] = useState<
    {
      name: string;
      employeeName: string;
      startDate: string;
      id: string;
      orderId: string;
      segment: string;
      btgAccount: string;
    }[]
  >([]);
  const [filteredClients, setFilteredClients] = useState<
    {
      name: string;
      employeeName: string;
      startDate: string;
      id: string;
      orderId: string;
      segment: string;
      btgAccount: string;
    }[]
  >([]);

  const handleGetClientsFromArea = async () => {
    try {
      setIsLoading(true);
      let isGeral = false;
      user.rules.forEach(
        (item: { permission_cod: string; area_cod: string }) => {
          if (item.permission_cod === 'GTR' || item.permission_cod === 'GTM')
            isGeral = true;
        }
      );
      const response = await hubApi.getClientsFromArea({
        employeeId: user.id,
        data: { areas: user.rules, isGeral },
      });

      setPermissionsUser(response.permissionUser);
      setClients(response.clients);
      setFilteredClients(response.clients);
    } catch (err) {
      toast.dark(
        'Não foi possivel carregar os dados dos clientes. Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (segmentFilter === '') {
      setFilteredClients(clients);
      return;
    }
    const filter = clients.filter(c => c.segment === segmentFilter);
    setFilteredClients(filter);
  }, [segmentFilter]);

  useEffect(() => {
    handleGetClientsFromArea();
  }, []);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle>Lista dos Clientes das Áreas</MainPageTitle>
      <Flex justifyContent="flex-end" w="100%" mb={2}>
        <Dropdown
          color="gray.500"
          placeholder="Selecione o segmento"
          onChange={e => setSegmentFilter(e.target.value)}
        >
          {permissionsUser.map((item, index) => (
            <option key={`${index}-${item.name}`} value={item.value}>
              {item.name}
            </option>
          ))}
        </Dropdown>
      </Flex>

      {isLoading && <LoadingSpinner />}
      {!isLoading && clients.length > 0 && (
        <TableBase
          headData={['Nome', 'Assessor', 'Data de Entrada', 'Segmento']}
          p="0"
          w="100%"
          height="fit-content"
          maxH="calc(100vh - 254px)"
          overflowY="auto"
        >
          {filteredClients.map(item => (
            <TableRow
              key={item.orderId}
              _hover={{ backgroundColor: 'background.500' }}
              cursor="pointer"
              onClick={() => {
                setIsOpenModalGoToClientPage(true);
                setSelectClientBtgAccount(item.btgAccount);
              }}
            >
              <TableData maxW="190px" title={formatNameToCaptalize(item.name)}>
                {formatNameToCaptalize(item.name)}
              </TableData>
              <TableData
                maxW="190px"
                title={formatNameToCaptalize(item.employeeName)}
              >
                {formatNameToCaptalize(item.employeeName)}
              </TableData>
              <TableData maxW="190px">
                {formateDateAndTime(item.startDate).split('à')[0]}
              </TableData>
              <TableData maxW="190px">
                {formatNameToCaptalize(item.segment)}
              </TableData>
            </TableRow>
          ))}
        </TableBase>
      )}
      <ModalWrapper
        isOpen={isOpenModalGoToClientPage}
        onClose={() => setIsOpenModalGoToClientPage(false)}
      >
        <DefaultModalContentWrapper>
          <ModalTitle textAlign="center">
            Ir para a pagina do cliente
          </ModalTitle>
          <Text>Deseja ir para a pagina do cliente?</Text>
          <Flex justifyContent="flex-end" gap={2} mt={5}>
            <PrimaryButton
              w="80px"
              onClick={() =>
                history.push(
                  `/user/users_clients/new/${selectClientBtgAccount}`
                )
              }
            >
              Sim
            </PrimaryButton>
            <SecondaryButton
              w="80px"
              onClick={() => setIsOpenModalGoToClientPage(false)}
            >
              Não
            </SecondaryButton>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
