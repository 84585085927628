import React from 'react';
import { ButtonGroup, ButtonGroupProps } from '@chakra-ui/react';

export const BaseButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  ...rest
}) => {
  return (
    <ButtonGroup
      width="100%"
      justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
      flexDir={{ base: 'column', sm: 'row' }}
      mt="2"
      gridGap="2"
      // spacing="3"
      {...rest}
    >
      {children}
    </ButtonGroup>
  );
};
