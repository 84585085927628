import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IReduxState } from 'shared/interfaces';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { EmployeeAgendaHeader } from './components/header';
import { EmployeeAgendaSkeletonLoading } from './components/skeletonLoading';
import { EmployeeAgendaTable } from './components/table';

export const EmployeeAgenda = (): JSX.Element => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const { data: agendaData, isLoading } = useQuery({
    queryKey: ['agenda'],
    queryFn: async () => {
      const response = await hubApiCaptation.getAgendaData(user.id);
      return response;
    },
    onError: error => {
      handleErrorMessage(error);
    },
  });

  const meetings = agendaData?.todayMeetings || [];

  if (isLoading) {
    return <EmployeeAgendaSkeletonLoading />;
  }

  return (
    <PageWrapper gap={5} paddingTop="4.5rem">
      <EmployeeAgendaHeader agendaData={agendaData} />
      <EmployeeAgendaTable meetings={meetings} />
    </PageWrapper>
  );
};
