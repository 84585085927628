import { IAllBranchs } from 'pages/Captation/components/modules/SH/SHQuestionsForm';
import { HubApi } from '../index';

interface IBranchOffice {
  cep: string;
  city: string;
  created_at?: string;
  id: string;
  number: string;
  street: string;
  uf: string;
  updated_at?: string;
  zona: string;
  managerId: string;
}

class HubApiOfficeBranches extends HubApi {
  async listBranchOffices() {
    const response = await this.axios.get('office');
    return response.data;
  }

  async createBranchOffice(officeData: IBranchOffice) {
    const response = await this.axios.post('office', officeData);
    return response.data;
  }

  async editBranchOffice(officeData: IBranchOffice) {
    await this.axios.patch('office', officeData);
  }

  async deleteBranchOffice(officeId: string) {
    await this.axios.delete(`office/${officeId}`);
  }

  async listUsersByOffice(officeId: string) {
    const response = await this.axios.get(`employees/by/office/${officeId}`);
    return response.data;
  }
}

export const hubApiOfficeBranches = new HubApiOfficeBranches();
