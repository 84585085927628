import { Flex, Text } from '@chakra-ui/react';
import { useTabNavigation } from 'hooksV2/useTabNavigation';
import { ClientType } from 'sharedV2/types/client';
import { ClientDetailsLastActivities } from '../lastActivities';
import { ClientDetailsNextActivities } from '../nextActivities';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsMyActivities: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { TabsDiv, selectedTab } = useTabNavigation({
    tabs: ['Próximas atividades', 'Já realizadas'],
    initialTab: 'Próximas atividades',
  });

  return (
    <Flex p={2} flexDirection="column" w="100%" gap={2}>
      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
        Atividades
      </Text>
      <TabsDiv />

      {selectedTab === 'Próximas atividades' && (
        <ClientDetailsNextActivities client={client} />
      )}

      {selectedTab === 'Já realizadas' && (
        <ClientDetailsLastActivities client={client} />
      )}
    </Flex>
  );
};
