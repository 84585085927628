import styled from 'styled-components';

export const InformationContent = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  .dropbtn {
    color: white;
    font-size: 16px;
    padding-top: 03px;
    background-color: transparent;
    border: none;
    display: flex;
  }

  .info-container {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    width: 100%;
    min-width: 280px;
    max-width: 292px;
    display: none;
    position: absolute;
    top: 20px;
    padding: 12px 16px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white100};
    background: rgba(46, 46, 46, 0.96);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    p {
      font-size: var(--smaller-text);
      line-height: 150%;
    }
  }

  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .info-container:hover .dropdown-content {
    display: block;
  }
`;
