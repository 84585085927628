import React, { FormEvent, useState } from 'react';

import { toast } from 'react-toastify';
import { SimpleGrid, Stack } from '@chakra-ui/react';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import { BaseModalFormCard } from 'components/elements/cards';
import { BasicFieldsProps } from 'pages/Sign/Register/interfaces';
import { personalFieldsSchema } from '../schemas';
import { maritalStatus, degreeEducation, sexOptions } from '../data';

interface PersonalFIeldsProps extends BasicFieldsProps {
  handlePrevPage: (progress: number) => void;
}

interface IFormInfoPerson {
  motherName: string;
  dadName: string;
  dateOfBirth: string;
  sex: string;
  maritalStatus: string;
  educationLevel: string;
}

const PersonalFields: React.FC<PersonalFIeldsProps> = ({
  step,
  handleNextPage,
  handlePrevPage,
  setMainForm,
  mainForm,
}) => {
  const [formInfos, setFormInfos] = useState<IFormInfoPerson>(
    {} as IFormInfoPerson
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleChangeSex = (item: string) => {
    setFormInfos({ ...formInfos, sex: item });
  };

  const handleChangeMarital = (item: string) => {
    setFormInfos({ ...formInfos, maritalStatus: item });
  };

  const handleChangeEducation = (item: string) => {
    setFormInfos({ ...formInfos, educationLevel: item });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const validateForm = await personalFieldsSchema
      .validate(formInfos)
      .catch(err => {
        err.errors.map((error: string) => toast.dark(error));
        setIsLoading(false);
      });

    if (validateForm) {
      setMainForm({
        ...mainForm,
        ...formInfos,
        dateOfBirth: `${formInfos.dateOfBirth}T06:00:00Z`,
      });
      handleNextPage(50);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal={false}
      title={`Passo ${step} - Dados Pessoais`}
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      primaryButtonText="Próximo"
      secondaryButtonText="Anterior"
      handleToggleModal={() => handlePrevPage(0)}
    >
      <Stack spacing="3">
        <InputWithLabel
          label="Nome da Mãe"
          placeholder="Nome da mãe completo"
          name="motherName"
          value={formInfos.motherName}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Nome do Pai"
          placeholder="Nome do pai completo"
          name="dadName"
          value={formInfos.dadName}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Data de Nascimento"
          name="dateOfBirth"
          type="date"
          mb="1"
          w={['100%', '48.5%']}
          onChange={handleChangeValue}
        />

        <SimpleGrid columns={[1, 2]} spacing={3}>
          <Dropdown
            mb="4"
            placeholder="Sexo"
            onChange={e => handleChangeSex(e.target.value)}
          >
            {sexOptions.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            mb="4"
            placeholder="Estado Civil"
            onChange={e => handleChangeMarital(e.target.value)}
          >
            {maritalStatus.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            mb="4"
            placeholder="Grau de Educação"
            onChange={e => handleChangeEducation(e.target.value)}
          >
            {degreeEducation.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>
        </SimpleGrid>
      </Stack>
    </BaseModalFormCard>
  );
};

export default PersonalFields;
