import { Flex } from '@chakra-ui/react';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import {
  VisualizationModeEnum,
  VisualizationModeHookType,
} from 'hooksV2/useVisualizationMode';
import {
  FilterClientsEnum,
  SortClientsByEnum,
} from 'pagesV2/Clients/hooks/useFilterAndSortClients';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { DropdownUtils } from 'utilsV2/Dropdown';

type PropsType = {
  visualizationModeHook: VisualizationModeHookType;
  clientNameOrBtgCodeFilter: UseSimpleFilterResponseType;
  clientsFilter: UseSimpleFilterResponseType;
  clientsTagFilter: UseSimpleFilterResponseType;
  sortClientsByFilter: UseSimpleFilterResponseType<SortClientsByEnum>;
};

export const ClientsFilters: React.FC<PropsType> = ({
  visualizationModeHook,
  clientNameOrBtgCodeFilter,
  sortClientsByFilter,
  clientsFilter,
  clientsTagFilter,
}): JSX.Element => {
  const { data: tags } = useQuery({
    queryKey: ['employeeTags'],
    queryFn: async () => {
      const response = await hubApiClients.getEmployeeTags();
      return response;
    },
  });

  return (
    <Flex w="100%" justifyContent="space-between">
      <Flex gap={2}>
        <Dropdown.Root
          w="160px"
          onChange={event =>
            clientsFilter.handleChangeFilterValue(event.target.value)
          }
        >
          <Dropdown.Options
            selected={clientsFilter.filterValue}
            placeholder="Filtrar"
            optionWithoutValueName="Todos"
            options={[
              {
                label: 'Com Pendências',
                value: FilterClientsEnum.WITH_PENDENCIES,
              },
              {
                label: 'Sem Ativação',
                value: FilterClientsEnum.PL_LESS_THAN_5K,
              },
            ]}
          />
        </Dropdown.Root>

        <Dropdown.Root
          w="160px"
          onChange={event =>
            clientsTagFilter.handleChangeFilterValue(event.target.value)
          }
        >
          <Dropdown.Options
            selected={clientsTagFilter.filterValue}
            placeholder="Tag"
            optionWithoutValueName="Todos"
            options={tags?.map(tag =>
              DropdownUtils.toBaseOptions({ label: tag.name, value: tag.name })
            )}
          />
        </Dropdown.Root>
      </Flex>

      <Input.Root w="350px">
        <Input.TextField
          placeholder="Nome ou código do cliente"
          value={clientNameOrBtgCodeFilter.filterValue}
          onChange={event =>
            clientNameOrBtgCodeFilter.handleChangeFilterValue(
              event.target.value
            )
          }
          rightElement={{
            icon: Icons.X,
            onClick: () => clientNameOrBtgCodeFilter.handleDeleteParam(),
          }}
        />
      </Input.Root>

      <Flex gap={3}>
        <Dropdown.Root
          w="160px"
          onChange={event =>
            sortClientsByFilter.handleChangeFilterValue(
              event.target.value as SortClientsByEnum
            )
          }
        >
          <Dropdown.Options
            selected={sortClientsByFilter.filterValue}
            placeholder="Ordenar"
            options={[
              { label: 'Total PL', value: SortClientsByEnum.TOTAL_PL },
              { label: 'A - Z', value: SortClientsByEnum.NAME },
              {
                label: 'Patrimônio Declarado',
                value: SortClientsByEnum.DECLARED_PATRIMONY,
              },
              {
                label: 'Dinheiro Parado',
                value: SortClientsByEnum.STUCK_MONEY,
              },
            ]}
          />
        </Dropdown.Root>

        <Flex>
          <Icons.LIST
            size={32}
            color={
              visualizationModeHook.visualizationModeValue ===
              VisualizationModeEnum.TABLE
                ? colors.BLUE
                : '#b0b0b0'
            }
            onClick={() =>
              visualizationModeHook.handleChangeVisualizationMode(
                VisualizationModeEnum.TABLE
              )
            }
          />
          <Icons.GRID
            size={32}
            color={
              visualizationModeHook.visualizationModeValue ===
              VisualizationModeEnum.MOSAIC
                ? colors.BLUE
                : '#b0b0b0'
            }
            onClick={() =>
              visualizationModeHook.handleChangeVisualizationMode(
                VisualizationModeEnum.MOSAIC
              )
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
