import { ButtonProps } from '@chakra-ui/react';
import { Button } from 'componentsV2/elements/buttons';
import { SecondaryButton } from '../../buttons/secondary';

export enum ActionTypeEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

type PropsType = {
  text: string;
  actionType?: ActionTypeEnum;
  isSuccessful?: boolean;
} & ButtonProps;

export const ModalAction: React.FC<PropsType> = ({
  text,
  actionType = ActionTypeEnum.PRIMARY,
  ...rest
}): JSX.Element => {
  if (actionType === ActionTypeEnum.SECONDARY) {
    return (
      <SecondaryButton w="20%" {...rest}>
        {text}
      </SecondaryButton>
    );
  }

  return (
    <Button.Primary w="20%" {...rest}>
      {text}
    </Button.Primary>
  );
};
