import React, { useEffect, useState } from 'react';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import { KanbanTaskBase } from 'components/modules/kanbanBases';
import { ModalWrapper } from 'components/elements/wrappers';
import { rvColumns } from 'pages/RV/subpages/Kanban/data';
import { BoardOrderInfo } from '../BoardOrderInfo';
import BoardCloseOrder from '../BoardCloseOrder';

interface TaskProps extends KanbanBaseTaskProps {
  information?: string;
  employeename?: string;
  email?: string;
  phone?: string;
  segment?: string;
  clientBtgAccount?: string;
  operation?: string;
}
interface ComponentProps {
  index: number;
  task: TaskProps;
}

const Task: React.FC<ComponentProps> = ({ index, task }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLastColumn, setIsLastColumn] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (task.status?.toLowerCase() === rvColumns[3].title.toLowerCase()) {
      setIsLastColumn(true);
    }
  }, [task.status]);

  const handleToggleModal = () => setShowModal(!showModal);

  const handleToggleLastModal = () => setIsLastColumn(!isLastColumn);
  return (
    <>
      <KanbanTaskBase
        taskId={task.id}
        taskIndex={index}
        isFinished={isFinished}
        primaryText={task.segment}
        secondaryText={`Cód. cliente: ${task.clientBtgAccount}`}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BoardOrderInfo order={task} handleToggleModal={handleToggleModal} />
      </ModalWrapper>

      <ModalWrapper isOpen={isLastColumn} onClose={handleToggleLastModal}>
        <BoardCloseOrder
          order={task}
          handleToggleModal={handleToggleLastModal}
          setIsFinished={setIsFinished}
        />
      </ModalWrapper>
    </>
  );
};

export default Task;
