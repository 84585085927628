import styled, { keyframes } from 'styled-components';

export const PulseAnimation: React.FC = () => {
  const pulse = keyframes`
    0% {
      box-shadow: #29a337 0 0 0 0;
    }
    75% {
      box-shadow: #29a33700 0 0 0 14px;
    }
  `;

  const PulsingBall = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #29a337;
    animation: ${pulse} 1500ms infinite;
    margin: 0 auto;
  `;

  const Wrapper = styled.div`
    width: 43.2px;
  `;

  return (
    <Wrapper>
      <PulsingBall />
    </Wrapper>
  );
};
