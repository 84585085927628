import { Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { FormEvent } from 'react';

type PropsType = {
  submitFunction: any;
  title: string;
  description: string;
  setIsOpenMeetingConfirmModal: (value: boolean) => void;
  isLoadingUpdateMeeting: boolean;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingActionConfirmModal: React.FC<PropsType> = ({
  submitFunction,
  setIsOpenMeetingConfirmModal,
  description,
  title,
  isLoadingUpdateMeeting,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const formSubmit = async (e: FormEvent) => {
    setIsOpenMeetingConfirmModal(false);

    handleToggleOpenMeetingDetailsModal(false);
    e.preventDefault();

    await submitFunction();
  };

  return (
    <BaseModalFormCard
      isModal
      title={title}
      handleFormSubmit={formSubmit}
      isLoading={isLoadingUpdateMeeting}
      primaryButtonText="Confirmar"
      secondaryButtonEventFunction={() => setIsOpenMeetingConfirmModal(false)}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Text>{description}</Text>
    </BaseModalFormCard>
  );
};
