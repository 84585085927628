import { Badge, Flex, GridItem, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingPoolType, MeetingStatusEnum } from 'sharedV2/types/meeting';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';
import { LeadUtils } from 'utilsV2/Lead';
import { MeetingUtils } from 'utilsV2/Meeting';
import { ScreenUtils } from 'utilsV2/Screen';
import { TextUtils } from 'utilsV2/Text';
import { formateDateAndTimeNoTimezone } from '../../../../utils/formatDate';

type PropsType = {
  meeting: MeetingPoolType;
  visualizationMode: string;
  isInLateSection?: boolean;
};

export const MeetingsPoolMosaicCard: React.FC<PropsType> = ({
  meeting,
  visualizationMode,
  isInLateSection,
}): JSX.Element => {
  const { setParam, getParam } = useQueryParams();
  const { redirect } = useNavigation();

  const isMeetingDateInPast = DateUtils.isInPast(meeting.date);

  const lastViewedMeetingId = getParam('lastViewedMeetingId');

  const isLeadMundoInvest =
    meeting.leadType === 'publics' ||
    meeting.leadType === LeadTypeEnum.MUNDO_INVEST;

  const isLeadWithoutFeedback =
    isLeadMundoInvest && meeting.status === MeetingStatusEnum.WAITING_FEEDBACK;

  const handleClickOnCard = () => {
    const scrollTop = ScreenUtils.getScrollTop('scrollContainer');
    setParam('scrollTop', scrollTop.toString());
    setParam('lastViewedMeetingId', meeting.id);

    redirect(
      `/v2/lead/${LeadUtils.transformLeadType(meeting.leadType)}/${
        meeting.leadId
      }`
    );
  };

  return (
    <GridItem
      w={visualizationMode === 'kanban' ? '100%' : '233px'}
      cursor="pointer"
      _hover={useCommonHover()}
      onClick={handleClickOnCard}
    >
      <Box.Opaque
        p={4}
        borderColor={isMeetingDateInPast ? 'red' : ''}
        h="100%"
        shouldHaveAllBorders={lastViewedMeetingId === meeting.id}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={1}>
          <Text isTruncated maxW="220px" color="rgba(70, 70, 70, 1)">
            {TextUtils.toCapitalize(meeting.name)}
          </Text>

          <Flex gap={1}>
            {isLeadMundoInvest && (
              <Tooltip text="Lead Mundo Invest">
                <Flex bg="blue.300" borderRadius="50%" h="10px" minW="10px" />
              </Tooltip>
            )}

            {isLeadWithoutFeedback && (
              <Tooltip text="Lead sem Feedback">
                <Flex bg="red.300" borderRadius="50%" h="10px" minW="10px" />
              </Tooltip>
            )}
          </Flex>
        </Flex>

        <Flex flexDirection="column" mt={1} gap={3}>
          <Flex flexDirection="column">
            <Text
              fontSize={{ '2xl': 'sm', xl: 'xs' }}
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
            >
              Reunião:{' '}
              <span>
                {formateDateAndTimeNoTimezone(
                  meeting.date as unknown as string
                )}
              </span>
            </Text>

            <Text
              fontSize={{ '2xl': 'sm', xl: 'xs' }}
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
            >
              PL: <span>{CurrencyUtils.format(meeting.pipeValue)}</span>
            </Text>
          </Flex>

          {isInLateSection && (
            <Badge w="fit-content" py={1} px={3} borderRadius={10}>
              <Text fontSize="xs" color="rgba(70, 70, 70, 1)">
                {MeetingUtils.translateStatus(meeting.status)}
              </Text>
            </Badge>
          )}
        </Flex>
      </Box.Opaque>
    </GridItem>
  );
};
