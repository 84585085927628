import * as yup from 'yup';

export const finishOrderSchema = yup.object().shape({
  bank: yup.string().nullable().required('Selecione o banco.'),
  spot: yup.string().nullable().required('Preencha o campo spot.'),
  spread: yup.string().nullable().required('Preencha o campo spread.'),
  currency: yup.string().nullable().required('Selecione a moeda origem.'),
  targetCurrency: yup
    .string()
    .nullable()
    .required('Selecione a moeda destino.'),
  contractValue: yup
    .string()
    .nullable()
    .required('Preencha o campo valor do contrato.'),
});
