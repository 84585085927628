/* eslint no-unused-vars: off */
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { KanbanColumnProps } from 'shared/interfaces';

interface ComponentProps {
  boardTitle?: string;
  columns: KanbanColumnProps[];
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  handleOnDragEnd: (
    result: DropResult,
    columns: KanbanColumnProps[],
    setColumns: React.Dispatch<React.SetStateAction<any[]>>
  ) => Promise<void>;
}

export const KanbanBoardBase: React.FC<ComponentProps> = ({
  children,
  boardTitle,
  columns,
  setColumns,
  handleOnDragEnd,
}) => {
  return (
    <Flex overflowX="auto">
      <Flex w="100%" flexDir="column" alignItems="center">
        <Text
          color="rgba(70, 70, 70, 1)"
          fontWeight="semibold"
          fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
          mb="8"
        >
          {boardTitle}
        </Text>
        <Box
          width="100%"
          display="grid"
          height={{ base: '100%', md: 'calc(100vh - 220px)' }}
          gridAutoFlow="column"
          gridAutoColumns="210px"
          gridGap="2"
          padding="8px 12px 0"
          borderRadius="3"
        >
          <DragDropContext
            onDragEnd={result => handleOnDragEnd(result, columns, setColumns)}
          >
            {children}
          </DragDropContext>
        </Box>
      </Flex>
    </Flex>
  );
};
