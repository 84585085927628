import { queryClient } from 'App';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import hubApi, { UpdateIPMeetingRequestType } from 'services/hubApi';

export const useUpdateIPMeeting = (): UseMutationResult<
  unknown,
  unknown,
  UpdateIPMeetingRequestType
> => {
  return useMutation({
    mutationKey: ['updateIPMeeting'],
    mutationFn: async (data: UpdateIPMeetingRequestType) => {
      const response = await hubApi.updateIPMeeting(data);
      return response;
    },
    onSuccess: async () => {
      toast.dark('Reunião atualizada com sucesso!');
      await queryClient.invalidateQueries(['ipMeetings']);
    },
  });
};
