/* eslint-disable func-names */
import * as yup from 'yup';
import { isCPF } from 'brazilian-values';

const basicFieldsSchema = yup.object().shape({
  name: yup.string().required('Preencha o nome.'),
  rg: yup
    .string()
    // .min(8, 'O RG deve possuir no mínimo 8 dígitos e somente números.')
    // .max(9, 'O RG  deve possuir no máximo 9 dígitos e somente números.')
    .required('Preencha o campo RG.'),
  cpf: yup
    .string()
    .min(11, 'O CPF deve possuir 11 dígitos e somente números.')
    .max(11, 'O CPF deve possuir 11 dígitos e somente números.')
    .test('checkCpf', 'Digite um CPF válido.', function (value) {
      return isCPF(value as string);
    })
    .required('Preencha o campo CPF.'),
  email: yup
    .string()
    .matches(
      /(mundoinvest.com.br|ewzcapital.com.br|consultoriami.com.br|ilhaforte.com.br)/,
      'Informe o email corporativo Mundo Invest, EWZ, Ilha Forte ou Consultoria Mundo Invest'
    )
    .required('Preencha o campo e-mail.'),
  phone: yup
    .string()
    .length(
      11,
      'O telefone deve possuir ao menos 11 dígitos, DDD e ser composto somente de números.'
    )
    .required('Preencha o campo telefone.'),
});

const personalFieldsSchema = yup.object().shape({
  motherName: yup.string().required('Preencha o nome da mãe.'),
  dadName: yup.string().required('Preencha o nome do pai.'),
  dateOfBirth: yup.string().required('Preencha a data de nascimento.'),
  sex: yup.string().required('Escolha o sexo.'),
  maritalStatus: yup.string().required('Escolha o estado civil.'),
  educationLevel: yup.string().required('Escolha o nível de educação.'),
});

const locationFieldsSchema = yup.object().shape({
  nationality: yup.string().required('Preencha sua nacionalidade.'),
  city: yup.string().required('Preencha sua cidade.'),
  cep: yup.string().required('Preencha seu CEP.'),
  street: yup.string().required('Preencha sua rua.'),
  district: yup.string().required('Preencha seu bairro.'),
  number: yup.number().required('Preencha o número.'),
  complement: yup.string(),
});

const contractFieldsSchema = yup.object().shape({
  admissionDate: yup.string().required('Preencha a data de contratação.'),
  contractType: yup.string().required('Escolha o modelo de contrato.'),
  responsibility: yup.string(),
});

export {
  basicFieldsSchema,
  personalFieldsSchema,
  locationFieldsSchema,
  contractFieldsSchema,
};
