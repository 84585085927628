import React from 'react';

import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { IOthers } from 'pages/Captation/interfaces/Leads';
import { Flex } from '@chakra-ui/react';
import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';

interface ComponentProps {
  others: IOthers[];
}

export const LeadOthersContent: React.FC<ComponentProps> = ({ others }) => {
  return (
    <ContentWithVertDividerWrapper flexDir="row" gridGap="6" flexWrap="wrap">
      {Object.entries(others).map(item => (
        <Flex flexDir="column">
          {item[0] && item[1] && (
            <>
              <ContentDividerTitle>{`${item[0]}`}</ContentDividerTitle>
              <ContentDividerText>
                <Flex>{item[1]}</Flex>
              </ContentDividerText>
            </>
          )}
        </Flex>
      ))}
    </ContentWithVertDividerWrapper>
  );
};
