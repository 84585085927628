import { Flex, Text } from '@chakra-ui/react';
import ReactQuill from 'react-quill';

interface ITextEditorWithLabelProps {
  placeholder: string;
  value: string;
  label: string;
  inputName: string;
  handleBonusMessageAndStrategyOrDescriptionValue: (
    e: any,
    inputName: string
  ) => void;
  newClass?: string;
}

export const TextEditorWithLabel: React.FC<ITextEditorWithLabelProps> = ({
  placeholder,
  value,
  label,
  handleBonusMessageAndStrategyOrDescriptionValue,
  inputName,
  newClass,
}) => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <Flex flexDirection={'column'}>
      <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
        {label}
      </Text>
      <ReactQuill
        modules={modules}
        theme="snow"
        placeholder={placeholder}
        onChange={e =>
          handleBonusMessageAndStrategyOrDescriptionValue(e, inputName)
        }
        value={value}
        className={newClass ? newClass : 'boxBonusMessageAndDescription'}
      />
    </Flex>
  );
};
