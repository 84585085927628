export const addLeadsHeader = [
  'Tipo',
  'Dado Solicitado',
  'CPF',
  'Enviar',
  'Status',
];

export const hubAreasOptions = [
  { title: 'Selecione uma opção', value: 'todos' },
  { title: 'Enviados', value: 'enviados' },
  { title: 'A enviar', value: 'a enviar' },
];
