import React from 'react';

import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { IPhone } from 'pages/Captation/interfaces';
import { LeadPhoneItem } from '../LeadPhoneItem';

interface ComponentProps {
  phones: Partial<IPhone | undefined>[];
}

export const LeadsPhonesContent: React.FC<ComponentProps> = ({ phones }) => {
  const phonesSortedByCreatedAt = phones.sort(
    (a, b) =>
      new Date(a?.created_at as string).getTime() -
      new Date(b?.created_at as string).getTime()
  );

  return (
    <ContentWithVertDividerWrapper flexDir="row" gridGap="6" flexWrap="wrap">
      {phonesSortedByCreatedAt?.map(
        (item, index) =>
          item?.phone && (
            <LeadPhoneItem key={index} phone={item} index={index} />
          )
      )}
    </ContentWithVertDividerWrapper>
  );
};
