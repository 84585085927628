import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Box } from 'componentsV2/elements/boxes';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { DateUtils } from 'utilsV2/Date';
import { MeetingUtils } from 'utilsV2/Meeting';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
};

export const RecoverMeetingFromPipeModal: React.FC<PropsType> = ({
  lead,
  leadType,
}): JSX.Element => {
  const { getParam, setParam, deleteParam } = useQueryParams();

  const {
    mutateAsync: meetingAction,
    isLoading: isLoadingMeetingAction,
    isSuccess,
  } = useMeetingActions({
    onSuccess: () => {
      setTimeout(async () => {
        deleteParam([
          'isOpenRecoverMeetingFromPipeModal',
          'recoverMeetingFromPipeStatus',
          'recoverMeetingFromPipeDate',
        ]);

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
      }, 500);
    },
  });

  const optionsBase: BaseDropdownOptionsType[] = [
    {
      label: 'Suitability',
      value: 'suitability',
    },
    {
      label: 'Carteira',
      value: 'presentation',
    },
    {
      label: 'Aguardando abertura',
      value: 'allocation',
    },
    {
      label: 'Alocação',
      value: 'account',
    },
  ];

  const recoverMeetingFromPipeStatus = getParam(
    'recoverMeetingFromPipeStatus'
  ) as MeetingStatusEnum;

  const recoverMeetingFromPipeDate = getParam('recoverMeetingFromPipeDate');

  const isLoading = isLoadingMeetingAction;

  const handleRecoverMeetingFromPipe = async () => {
    if (!recoverMeetingFromPipeStatus)
      return showToast('Escolha uma etapa para a reunião');

    if (!recoverMeetingFromPipeDate)
      return showToast('Escolha uma data para a reunião');

    if (DateUtils.isInPast(recoverMeetingFromPipeDate))
      return showToast('Escolha uma data futura');

    await meetingAction({
      action: MeetingActionEnum.RECOVER_FROM_PIPE,
      meetingId: lead?.meeting?.id as string,
      leadType,
      status: recoverMeetingFromPipeStatus,
      date: recoverMeetingFromPipeDate,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Recuperar Lead" />
        <Modal.CloseIcon
          onClick={() => deleteParam('isOpenRecoverMeetingFromPipeModal')}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={2}>
        <Box.Glass flexDirection="column" alignItems="start">
          <Text>Nome: {lead?.name}</Text>
          <Text>
            Reunião Atual: {MeetingUtils.translateStatus(lead?.meeting?.status)}
          </Text>
        </Box.Glass>

        <Dropdown.Root
          value={recoverMeetingFromPipeStatus}
          onChange={event =>
            setParam('recoverMeetingFromPipeStatus', event.target.value)
          }
        >
          <Dropdown.Options
            options={optionsBase}
            placeholder="Etapa da reunião"
          />
        </Dropdown.Root>

        <Input.Root>
          <Input.Date
            value={recoverMeetingFromPipeDate}
            onChange={event =>
              setParam('recoverMeetingFromPipeDate', event.target.value)
            }
          />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Recuperar"
          onClick={handleRecoverMeetingFromPipe}
          isLoading={isLoading}
          isSuccessful={isSuccess}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
