import { Permissions } from 'pages/SearchUserDetails/interfaces';

export function permissionsAreas(
  areasPermissions: Permissions[],
  userPermissions: Permissions[]
): Permissions[] {
  const filteredNoAreas = areasPermissions.filter(
    area => userPermissions.findIndex(user => area.cod === user.cod) === -1
  );

  return filteredNoAreas;
}
