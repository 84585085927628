import { ITagsClients } from 'pages/MyClients/components/modules/clientPanels/Profile/TagsClients';
import { CardsInfos } from 'pages/MyClients/interfaces';
import { OfferInOrdersPanel } from 'pages/MyClients/interfaces/OrdersPanelData';
import {
  ICreateAgreement,
  IPutAGreement,
} from 'pages/SearchUserDetails/interfaces/Agremments';
import { HubApi } from '../index';

interface IPostReminderManager {
  forId: string;
  type: string;
  title: string;
  msg: string;
  data: string;
}

interface IAnotationMessage {
  clientId: string;
  type: string;
  msg: string;
  title: string;
  dataRetorno: string;
  anotationId?: string;
}

interface IPostRFClients {
  brokerAccountId: string;
  indexer: string;
  feeMin: string;
  maturityDaysMax: string;
  maturityDaysMin: string;
  value: string;
}

interface IPostRFCreateClients {
  account: string;
  customerName: string;
  initialBalance: string;
  institutionId: string;
  brokerOfficeId: number;
}

interface IPatchRFUpdateBalance {
  brokerAccountId: string;
  newBalance: string;
}

interface IPatchRFUpdateFilters {
  botId: string;
  feeMax: number;
  feeMin: number;
  indexer: string;
  maturityDaysMax: number;
  maturityDaysMin: number;
  maximumPurchasesTotalCost: string;
  shouldLimitPurchasesTotalCost: boolean;
}

interface ISignature {
  passwordEletronic: string;
}

type UpdateSignatureType = {
  loginPassword: string;
  newPasswordEletronic: string;
};

interface IUpdateClientsDate {
  id: string;
  document?: string;
  phone?: string;
}

interface IUpdateClientsContactOrigin {
  id: string;
  fromType: string;
  additionalInformation: string;
  fromTypeLead: string;
}

interface IPatchAgreementClient {
  id: string;
  frequencyM: string;
}

interface IPatchFeedBackClient {
  id: string;
  status: string;
}

class HubApiUser extends HubApi {
  async listMyClients() {
    const response = await this.axios.get(`clients/employee`);
    return response.data;
  }

  async getPreviousAdvisorFromClient({
    clientBTGCode,
  }: {
    clientBTGCode: string;
  }) {
    const response = await this.axios.get(
      `/clients/previousAdvisorClient?clientBTGCode=${clientBTGCode}`
    );
    return response.data;
  }

  async searchClients({
    term,
    userPosition,
    tableId,
  }: {
    term: string;
    userPosition: string;
    tableId: string;
  }) {
    const response = await this.axios.get(
      `/clients/search?term=${term}&userPosition=${userPosition}&tableId=${tableId}`
    );
    return response.data;
  }

  async postAnnotations() {
    const response = await this.axios.post(`/anotations/clients`);
    return response.data;
  }

  async getAnnotations(employeeId: string) {
    const response = await this.axios.get(
      `/anotations/clients/all/${employeeId}`
    );
    return response.data;
  }

  async patchAnnotationsViewed(data: {
    id: string;
    viewed: boolean;
    rating: number;
  }) {
    const response = await this.axios.patch(`/anotations/clients`, data);
    return response.data;
  }

  async listMyCLientsByClient(client: string, filter: string) {
    const response = await this.axios.get<CardsInfos[]>(
      `/clients/name/${client}/${filter}`
    );
    return response.data;
  }

  async listClientsInfos(account: string) {
    const response = await this.axios.get(`/clients/account/${account}`);
    return response.data;
  }

  async listClientsOrders(clientBtgCode: string) {
    const response = await this.axios.get(`clients/orders/${clientBtgCode}`);
    return response.data;
  }

  async listClientsOffers(clientBtgCode: string) {
    const response = await this.axios.get<OfferInOrdersPanel[]>(
      `client/offers/${clientBtgCode}`
    );
    return response.data;
  }

  async deletedUser(id: string) {
    const response = await this.axios.patch(`/employees/resignation/${id}`);
    return response.data;
  }

  async getAllReminderManager() {
    const response = await this.axios.get(`/notices/manager`);

    return response.data;
  }

  async postReminderManager(data: IPostReminderManager) {
    const response = await this.axios.post(`/notices/manager`, data);
    return response.data;
  }

  /* async postAnotationMessage(data: IAnotationMessage) {
    const response = await this.axios.post(`/anotationsClients`, data);
    return response.data;
  } */

  async postAnotationMessage(data: IAnotationMessage) {
    const response = await this.axios.post(`/anotations/clients`, data);
    return response.data;
  }

  async postInformalInfos(
    data: {
      clientId: string;
      question: string;
      answer: string;
    }[]
  ) {
    const response = await this.axios.post(`/clients/informal`, data);
    return response.data;
  }

  async getInformalInfos(clientId: string) {
    const response = await this.axios.get(`clients/informal/cli/${clientId}`);
    return response.data;
  }

  async updateInformalInfos(data: {
    id: string;
    clientId: string;
    question: string;
    answer: string;
  }) {
    const response = await this.axios.patch(`clients/informal`, data);
    return response.data;
  }

  async deleteInformalInfos(questionId: string) {
    const response = await this.axios.delete(`clients/informal/${questionId}`);
    return response.data;
  }

  async updateAnotationMessage(data: IAnotationMessage) {
    const response = await this.axios.patch('/anotations/clients/', data);
    return response.data;
  }

  async deleteAnotation(id: string) {
    const response = await this.axios.delete(`/anotations/clients/${id}`);
    return response.data;
  }

  async deleteReminder(id: string) {
    const response = await this.axios.delete(`/notices/manager/${id}`);
    return response.data;
  }

  async getAnotationsMessage(id: string) {
    const response = await this.axios.get(`/anotations/clients/index/${id}`);
    return response.data;
  }

  async getRFClient(code: string) {
    const response = await this.axios.get(`/investAi/list/${code}`);
    return response.data;
  }

  async postRFClients(brokerAccountId: string, data: IPostRFClients) {
    const response = await this.axios.post(
      `/investAi/createCustomerOrder/:${brokerAccountId}`,
      data
    );
    return response.data;
  }

  async postRFCreateClients(data: IPostRFCreateClients, BTGaccount: string) {
    const response = await this.axios.post(
      `/investAi/createClient/${BTGaccount}`,
      data
    );
    return response.data;
  }

  async patchRFUpdateClients(id: string) {
    const response = await this.axios.patch(`/investAi/updateStatus/${id}`);
    return response.data;
  }

  async patchRFUpdateBalance(data: IPatchRFUpdateBalance) {
    const response = await this.axios.patch(`/investAi/updateBalance`, data);
    return response.data;
  }

  async patchRFUpdateFilters(data: IPatchRFUpdateFilters) {
    await this.axios.patch(`/investAi/updateFilters`, data);
  }

  async postCompareteSignature(data: ISignature) {
    const response = await this.axios.post(
      `employees/compare/eletronicsignature`,
      data
    );

    return response.data;
  }

  async postCreateSignature(data: ISignature) {
    const response = await this.axios.post(
      `employees/create/eletronicsignature`,
      data
    );

    return response.data;
  }

  async updateSignature(data: UpdateSignatureType) {
    const response = await this.axios.patch(
      `/employees/eletronicsignature`,
      data
    );

    return response.data;
  }

  async updateClientsContactOrigin(data: IUpdateClientsContactOrigin) {
    const response = await this.axios.patch('clients/contact', data);

    return response.data;
  }

  async getClientsContactsFromType(id: string) {
    const response = await this.axios.get(`clients/contact/origin/${id}`);
    return response.data;
  }

  async getClientNotes(clientId: string) {
    const response = await this.axios.get(`/clients/notes/${clientId}`);
    return response.data;
  }

  async saveClientNotes(clientId: string, data: { notes: string }) {
    const response = await this.axios.post(`clients/notes/${clientId}`, data);
    return response.data;
  }

  async getAllUsers() {
    const response = await this.axios.get(`/employees`);
    return response.data;
  }

  // dados do SH
  async getShNumbers(id: string, date?: string) {
    // if (date) {
    const response = await this.axios.get(`/employees/sh/${id}?date=${date}`);

    return response.data;
    // }
    // const response = await this.axios.get(`/employees/sh/${id}`);
    // return response.data;
  }

  async recycleLeads(
    shId: string,
    startDate: string,
    endDate: string,
    qtdLeads: string
  ) {
    const response = await this.axios.patch(
      `dash/sh/leadsRecycling?employeeId=${shId}&startDate=${startDate}&endDate=${endDate}&qtdLeads=${qtdLeads}`
    );
    return response.data;
  }

  async getAllPermissions() {
    const response = await this.axios.get('/permissions');
    return response.data;
  }

  async getRules(id: string) {
    const response = await this.axios.get(`/employees/rules/${id}`);
    return response.data;
  }

  async setPermission(id: string, ruleId: string) {
    await this.axios.patch(`/employees/rules/${id}`, {
      ruleId,
    });
  }

  async deletePermission(employeeId: string, ruleId: string) {
    await this.axios.delete(`/employees/rules/${employeeId}/${ruleId}`);
  }

  async updateEmployee(employeeId: string, data: any) {
    await this.axios.patch(`/employees/${employeeId}`, data);
  }

  async updateBankEmployee(employeeId: string, data: any) {
    await this.axios.patch(`/employees/bank/${employeeId}`, data);
  }

  async deleteEmployee(employeeId: string, data: any) {
    await this.axios.delete(`/employees/${employeeId}`, { data });
  }

  async getClientsInUser(employeeId: string, month: string) {
    const response = await this.axios.get(
      `/employees/clients/${employeeId}?month=${month}`
    );
    return response.data;
  }

  async getClientsInUserInfo(employeeId: string, month: string) {
    const response = await this.axios.get(
      `/employees/clients/outcome/${employeeId}/${month}`
    );
    return response.data;
  }

  async updateCLientsDate(data: IUpdateClientsDate) {
    const response = await this.axios.patch(`/clients/contact`, data);

    return response.data;
  }

  async getEmployeesByTable(tableId: string) {
    const response = await this.axios.get(`/employees/table/${tableId}`);

    return response.data;
  }

  async getAllAgreement(employeeId: string) {
    const response = await this.axios.get(
      `/employees/agreements/${employeeId}`
    );

    return response.data;
  }

  async putAgreements(data: IPutAGreement, id: string) {
    const response = await this.axios.put(`/agreements/${id}`, data);

    return response.data;
  }

  async postAgreement(data: ICreateAgreement) {
    const response = await this.axios.post(`/agreements`, data);

    return response.data;
  }

  async getEmployeesPendency() {
    const response = await this.axios.get(`/pendency/table`);

    return response.data;
  }

  async patchFrequencyContact(data: IPatchAgreementClient) {
    const response = await this.axios.patch(`/clients/contact`, data);

    return response.data;
  }

  async patchFeedBackClient(data: IPatchFeedBackClient) {
    const response = await this.axios.patch(`/clients/contact`, data);

    return response.data;
  }

  async patchAgreement(id: string) {
    const response = await this.axios.patch(`/agreements/accept/${id}`);

    return response.data;
  }

  /* Users's Contents */
  async postNewContent(data: {
    title: string;
    area: string;
    theme: string;
    content: string;
    video: string | null;
  }) {
    const response = await this.axios.post(`/contents`, data);

    return response.data;
  }

  async editContent(data: {
    title: string;
    area: string;
    theme: string;
    content: string;
    video: string | null;
  }) {
    const response = await this.axios.patch(`/contents`, data);

    return response.data;
  }

  async getAllContents() {
    const response = await this.axios.get('/contents');

    return response.data;
  }

  async getMyContents(id: string) {
    const response = await this.axios.get(`/contents/employee/${id}`);

    return response.data;
  }

  async getContentById(id: string) {
    const response = await this.axios.get(`/contents/${id}`);

    return response.data;
  }

  async postAddNewTags(tags: ITagsClients[]) {
    const response = await this.axios.post(`/clients/tags`, tags);

    return response.data;
  }

  async getAllTags() {
    const response = await this.axios.get(`/clients/tags `);

    return response.data;
  }

  async getClientsByTag(tag: string) {
    const response = await this.axios.get(`/clients/tags?by=tag&value=${tag}`);

    return response.data;
  }

  async getAllClientsTags(id: string) {
    const response = await this.axios.get(`/clients/tags/clientid/${id}`);

    return response.data;
  }

  async deleteTag(tag: string) {
    const response = await this.axios.delete(`/clients/tags/${tag}`);

    return response.data;
  }

  async postAddTagToClients(data: { tag: string; clientIds: string[] }) {
    const response = await this.axios.post(`/clients/tags?byTag=true`, data);

    return response.data;
  }

  async deleteTagFromSpecificClient(tag: string, clientId: string) {
    const response = await this.axios.delete(
      `/clients/tags/${tag}?clientId=${clientId}`
    );

    return response.data;
  }

  async getContentsByThemeOrArea(theme: string, area: string) {
    const response = await this.axios.get(
      `/contents/find?theme=${theme}&area=${area}`
    );

    return response.data;
  }

  async shareContent(data: {
    employeeWhoSharedContent: string;
    contentId: string;
    employeesId: string[];
  }) {
    const response = await this.axios.post(`/contents/share`, data);

    return response.data;
  }

  async pathTurnShProfessional(id: string) {
    const response = await this.axios.patch(`group/sh/professional/${id}`);

    return response.data;
  }

  async getEquity(id: string) {
    const response = await this.axios.get(`commission/equity/all/time/${id}`);

    return response.data;
  }

  async deleteContent(id: string) {
    const response = await this.axios.delete(`/contents/${id}`);

    return response.data;
  }

  async getAllClientsAndMeet() {
    const response = await this.axios.get(`/anotations/clients`);

    return response.data;
  }

  async getAllLeftClients() {
    const response = await this.axios.get(`clients/left`);

    return response.data;
  }

  // Rotas para criação e deleção dos termos de busca de leads personalizados
  async createSearchTerm(term: string) {
    const response = await this.axios.post(`/employees/term/create`, { term });

    return response.data;
  }

  async deleteSearchTerm(term: string) {
    const response = await this.axios.patch(`/employees/term/delete`, { term });

    return response.data;
  }

  async listMySearchTerms() {
    const response = await this.axios.get(`/employees/term`);

    return response.data;
  }
}

export const hubApiUser = new HubApiUser();
