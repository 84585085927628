import { ISourceAction } from '../dtos';

export const ActionTranslated = (sourceAction: ISourceAction): string => {
  const sourceActionsTranslated = {
    NO_FEEDBACK_PENALTY: 'Penalização de Reunião sem Feedback',
    MIBS_PURCHASED: 'MIBs Comprados',
    MIBS_REDEEM: 'MIBs Resgatados',
    MEETING_PURCHASED: 'Reunião Comprada',
    MEETING_UNSUCCESSFULL: 'Reunião Não Atendida',
    LEAD_RECEIVED: 'Lead Recebido',
    MIBS_WITHDRAW: 'MIBs Retirados',
    LEAD_DECLINED_CASHBACK: 'MIBs Recebidos por Declínio de Lead',
    MEETING_SUCCESSFULL: 'Lead Roteado Atendeu',
    UPDATE_ADVISOR_COMMISSION:
      'Atualização da Comissão por Venda da Plataforma',
    NO_ANSWER: 'Lead do Pool MI não atendeu',
    LEAD_PURCHASED: 'Reunião marcada para um lead do Pool MI',
    LEAD_POOL_DECLINED: 'Declínio de lead do Pool MI',
    LEAD_POOL_NO_ACTION: 'Lead do pool MI sem ação',
    CONFIRMED_CONNECTION_B3: 'Lead se conectou com a B3',
    CONFIRMED_EXIT_CUSTOMER: 'Foi confirmada a saída do cliente',
    CONFIRMED_FROM_TYPE: 'Foi confirmada a origem do cliente',
    CONFIRMED_STUCK_MONEY: 'Foi confirma a tarefa de dinheiro parado',
    LEAD_FROM_ADVISOR_LINK: 'Lead entrou com o seu link',
    MUNDO_INVEST_PLATAFORM_SOLD: 'Lead comprou a plataforma Mundo Invest',
    ORDER_BOLETED: 'Ordem no CRM IP foi boletada',
    DONE_PRESENTATION: 'Apresentação no CRM IP feita',
    NEGATIVE_BALANCE: 'Foi confirmada a tarefa de dinheiro negativo',
    PROPOSAL_PRESENTATION: 'Apresentação Proposta no CRM IP',
    MEETING_RATING: 'Foi colocada uma avaliação na reunião da equipe',
    CONTACT_ATTEMPT: 'Tentativa de contato realizada',
  } as Record<Partial<ISourceAction>, string>;

  return (
    sourceActionsTranslated[sourceAction] ??
    'Not possible to translate source action'
  );
};
