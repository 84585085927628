import { FormEvent, useCallback, useState } from 'react';
import { BiAddToQueue, BiEditAlt, BiTrash } from 'react-icons/bi';
import { Text, Flex, Box, Tooltip, Divider } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { OutlineButton } from 'components/elements/buttons';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  ICreateActions,
  InitialFrequencyDate,
} from 'pages/GenerationalVariables/interfaces';
import { ModalOrderCreateAction } from '../../wrappers/ModalOrderCreateAction';
import { ModalOrderDeleted } from '../../wrappers/ModalOrderDeleted';
import { CreateOrEditFrequency } from '../../wrappers/CreateOrEditFrequency';

interface FrequencyCardProps {
  frequency: InitialFrequencyDate;
  getFrequency: () => void;
  setFrequencys: React.Dispatch<React.SetStateAction<InitialFrequencyDate[]>>;
  frequencys: InitialFrequencyDate[];
}

export const FrequencyCard: React.FC<FrequencyCardProps> = ({
  frequency,
  frequencys,
  getFrequency,
  setFrequencys,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddActionModal, setOpenAddActionModal] = useState(false);
  const [formInfos, setFormInfos] = useState<ICreateActions>(
    {} as ICreateActions
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeAction = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormInfos({
        ...formInfos,
        [e.target.name]: e.target.value,
      });
    },
    [formInfos]
  );

  const handleShgowModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleAddActionModal = () => {
    setOpenAddActionModal(!openAddActionModal);
  };

  const handleDeleteAction = async (id: string) => {
    setIsLoading(true);

    try {
      await hubApiCaptation.deleteAction(id);

      setIsLoading(false);
      getFrequency();
      toast.dark('Action deletada com sucesso');
    } catch (e) {
      setIsLoading(false);

      errorHandler(e);
    }

    setIsLoading(false);
  };

  const handleAddAction = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    const actions = {
      id: '',
      frequencyId: frequency.id,
      name: formInfos.name,
      seq: formInfos.seq,
    };

    try {
      await hubApiCaptation.postAction(actions);
      setIsLoading(false);
      handleAddActionModal();
      getFrequency();
      toast.dark('Action criada com sucesso');
    } catch (error) {
      setIsLoading(false);
      handleAddActionModal();
      errorHandler(error);
    }

    setIsLoading(false);
  };

  const handleDeleteGroup = async (e: FormEvent) => {
    e.preventDefault();
    const frequencyToDelete = frequency;
    frequencyToDelete.status = 'inativo';

    try {
      const response = await hubApiCaptation.patchFrequency(frequencyToDelete);

      const resultWithoutExcluded = frequencys.filter(
        oneFrequency => oneFrequency.id !== response.id
      );

      setFrequencys(resultWithoutExcluded);
      toast.dark('Frequência excluida com sucesso');
      handleDeleteModal();
    } catch (err) {
      toast.dark('Erro ao excluir a  Frequência');
      handleDeleteModal();
    }
    handleDeleteModal();
  };

  return (
    <DefaultCardWrapper
      w="100%"
      p="3"
      flexDir="column"
      backgroundColor="#f2f2f2"
    >
      <Flex w="100%" justifyContent="space-between">
        <Flex flexDir="column">
          <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" fontSize="md">
            {frequency.name}
          </Text>
          <Text
            as="span"
            fontWeight=" normal"
            fontSize="small"
            color="rgba(70, 70, 70, 1)"
          >
            Prioridade: {frequency.seq}
          </Text>
        </Flex>

        <Flex gridGap="2.5">
          <Tooltip
            hasArrow
            label="Adicionar action"
            placement="bottom"
            bg="background.200"
          >
            <Box cursor="pointer">
              <BiAddToQueue
                color="rgba(70, 70, 70, 1)"
                size={18}
                onClick={handleAddActionModal}
              />
            </Box>
          </Tooltip>
          <Tooltip
            hasArrow
            label="Editar frequência"
            placement="bottom"
            bg="background.200"
          >
            <Box cursor="pointer">
              <BiEditAlt
                size={18}
                color="rgba(70, 70, 70, 1)"
                style={{ cursor: 'pointer' }}
                onClick={handleShgowModal}
              />
            </Box>
          </Tooltip>

          <Tooltip
            hasArrow
            label="Deletar frequência"
            placement="bottom"
            bg="background.200"
          >
            <Box cursor="pointer">
              <BiTrash
                size={18}
                color="rgba(70, 70, 70, 1)"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenDeleteModal(!openDeleteModal)}
              />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>

      <Divider orientation="horizontal" my="1.5" />

      <Flex mt="2" gridGap="2.5" flexWrap="wrap">
        {frequency.action?.map((item, key) => (
          <Flex
            key={key}
            alignItems="center"
            border="1px solid"
            borderColor="rgba(70, 70, 70, 1).200"
            borderRadius="2"
            px="2"
            py="1"
            // border="1px solid red"
            fontSize="sm"
            gridGap="2.5"
          >
            <Text maxW="150px" color="rgba(70, 70, 70, 1)" isTruncated>
              {item.name}
            </Text>

            <Tooltip
              hasArrow
              label="Deletar action"
              placement="bottom"
              bg="background.200"
            >
              <Box>
                <BiTrash
                  size={15}
                  color="rgba(70, 70, 70, 1)"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteAction(item.id)}
                />
              </Box>
            </Tooltip>
          </Flex>
        ))}
      </Flex>

      <ModalWrapper isOpen={openAddActionModal} onClose={handleAddActionModal}>
        <ModalOrderCreateAction
          handleAddAction={handleAddAction}
          onClose={handleAddActionModal}
          handleChangeAction={handleChangeAction}
          isLoading={isLoading}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={openDeleteModal} onClose={handleDeleteModal}>
        <ModalOrderDeleted
          handleDeleteGroup={handleDeleteGroup}
          handleDeleteModal={handleDeleteModal}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={openEditModal} onClose={handleShgowModal}>
        <CreateOrEditFrequency
          frequency={frequency}
          onClose={handleShgowModal}
          getFrequency={getFrequency}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};
