import React, { useState } from 'react';
import { FcVoicePresentation } from 'react-icons/fc';

import { AssignmentProps } from 'pages/Home/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const ManagerContactCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        title="Contato da gerência"
        IconElement={FcVoicePresentation}
        secondaryText={assignment.msg}
        thirdText={
          assignment.creatorName
            ? `com ${assignment.creatorName} em ${assignment.data}`
            : `Em ${assignment.data}`
        }
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Contato da gerência"
          message="Você confirma que esta tarefa foi cumprida?"
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
