import { Flex, Text } from '@chakra-ui/react';
import { Divider } from 'componentsV2/elements/divider';
import { useBuildMundoInvestData } from 'pagesV2/LeadDetails/hooks/useBuildMundoInvestData';
import { LeadMundoInvestInformationBox } from '../leadMundoInvestInformationBox';

export const LeadMundoInvestInformation = (): JSX.Element => {
  const { mundoInvestData } = useBuildMundoInvestData();

  if (!mundoInvestData) {
    return <></>;
  }

  return (
    <Flex
      background="#fff"
      borderLeft="3px solid #1D63DDB2"
      borderRadius={6}
      flexDirection="column"
      p={3}
      gap={2}
      w="100%"
    >
      <Text color="rgba(70, 70, 70, 1)">Informações Adicionais</Text>
      <Flex gap={4}>
        <Flex flexDirection="column" w="calc(50% - 16px)">
          {mundoInvestData.slice(0, 7).map((item, index) => (
            <LeadMundoInvestInformationBox
              key={index}
              label={item.label}
              value={item.value}
            />
          ))}
        </Flex>
        <Divider h="100%" />
        <Flex flexDirection="column" w="calc(50% - 16px)">
          {mundoInvestData.slice(7).map((item, index) => (
            <LeadMundoInvestInformationBox
              key={index}
              label={item.label}
              value={item.value}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
