import { useState } from 'react';
import { toast } from 'react-toastify';
import { FiRefreshCw } from 'react-icons/fi';
import { Flex, Text, Avatar } from '@chakra-ui/react';
import { PendencieBox } from 'pages/DashboardTables/components/elements';
import { Employee } from 'pages/DashboardTables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatValueForSI } from 'utils/formatValueForSI';
import { LoadingSpinner } from 'components/elements/others';

interface BottomSectionEmployeeMetricsCardProps {
  employee: Employee;
  updateEmployees: () => void;
  employeesColors: {
    employeeName: string;
    color: string;
  }[];
}

export const BottomSectionEmployeeMetricsCard: React.FC<BottomSectionEmployeeMetricsCardProps> =
  ({ employee, updateEmployees, employeesColors }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateEmployees = async () => {
      setIsLoading(true);
      try {
        await updateEmployees();
      } catch (error) {
        toast.dark(
          'Não foi possível atualizar os dados, tente novamente em alguns minutos'
        );
      }

      setIsLoading(false);
    };

    return (
      <Flex
        w="100%"
        minH="31.8%"
        h="31.8%"
        bg="background.600"
        borderRadius={8}
        flexDirection="column"
        gridGap={4}
        p={6}
      >
        <Flex
          w="100%"
          h="30%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center" w="100%">
            <Avatar size="xl" name={employee.name} src={employee.avatar_url} />
            <Text color="white" fontSize="3xl" ml={6} isTruncated>
              {formatNameToCaptalize(employee.name)}
            </Text>
            <Flex
              w="10%"
              h={3}
              borderRadius={6}
              bg={
                employeesColors.find(
                  item =>
                    item.employeeName.toLowerCase() ===
                    employee.name.toLowerCase()
                )?.color
              }
              ml={12}
            />
          </Flex>
          {isLoading && <LoadingSpinner size="lg" />}
          {!isLoading && (
            <FiRefreshCw
              size={50}
              title="atualizar"
              cursor="pointer"
              onClick={handleUpdateEmployees}
            />
          )}
        </Flex>

        <Flex w="100%" h="70%" gridGap={4} alignItems="center">
          <PendencieBox
            pendencieName="Pré-NPS"
            pendencieValue={employee.pendencies['pre-nps']}
          />
          <PendencieBox
            pendencieName="Negativos"
            pendencieValue={employee.pendencies.balance}
          />
          <PendencieBox
            pendencieName="Reuniões"
            pendencieValue={employee.pendencies.meetings}
          />
          <PendencieBox
            pendencieName="Origem"
            pendencieValue={employee.pendencies.fromType}
          />
          <PendencieBox
            pendencieName="Aniversário"
            pendencieValue={employee.pendencies.birthday}
          />

          <Flex flexDirection="column">
            <Text w="100%" fontSize="29px" textAlign="left">
              NET M.I.:{' '}
              <Text as="span" color="white" fontSize="inherit">
                {formatValueForSI(Number(employee.netMI)) || 0}
              </Text>
            </Text>
            <Text w="100%" fontSize="29px" textAlign="left">
              NET TOTAL:{' '}
              <Text as="span" color="white" fontSize="inherit">
                {formatValueForSI(Number(employee.netTotal)) || 0}
              </Text>
            </Text>
            <Text w="100%" fontSize="29px" textAlign="left">
              NPS:{' '}
              <Text as="span" color="white" fontSize="inherit">
                {Number(employee.nps).toFixed(2) || ' -'}
              </Text>{' '}
            </Text>
            <Text w="100%" fontSize="29px" textAlign="left">
              NNM/mês:{' '}
              <Text as="span" color="white" fontSize="inherit">
                {formatValueForSI(Number(employee.nnm)) || 0}
              </Text>{' '}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };
