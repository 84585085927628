import React, { useState } from 'react';

import ModalWrapper from 'pages/User/UsersClients/components/ModalWrapper';

import CardToLeadsContent from './styles';

const CardToLeads = ({ item, Modal }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(!showModal);

  return (
    <CardToLeadsContent>
      <div className="lead-container" onClick={handleShowModal}>
        <h5 className="lead-container-title">{item.nome}</h5>
        <div className="lead-infos">
          <span className="lead-container-info">{item.info}</span>
          <span className="lead-container-value">R$ {item.valor}</span>
        </div>
      </div>

      {showModal && (
        <ModalWrapper>
          <Modal
            handleShowModal={handleShowModal}
            title={item.nome}
            item={item}
          />
        </ModalWrapper>
      )}
    </CardToLeadsContent>
  );
};

export default CardToLeads;
