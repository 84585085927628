import { queryClient } from 'App';
import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { EmployeeToSelect } from '../Components/ManagerModal';
import { IGeneralWalletName } from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

type IUseSendMibsInMass = {
  mibsQuantityToSendInMass: number;
  employeeListToReceiveMIBsInMass: EmployeeToSelect[];
  generalWalletName: IGeneralWalletName;
};

export const useSendMibsInMass = (): UseMutationResult<
  string[],
  unknown,
  IUseSendMibsInMass,
  unknown
> => {
  return useMutation({
    mutationKey: [MIBS_QUERIES_KEYS.sendMibsInMass],
    mutationFn: async ({
      mibsQuantityToSendInMass,
      employeeListToReceiveMIBsInMass,
      generalWalletName,
    }: IUseSendMibsInMass) => {
      const response = await hubApiCaptation.sendMIBsInMass({
        mibsQuantity: mibsQuantityToSendInMass,
        employees: employeeListToReceiveMIBsInMass,
        generalWalletName,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.employeeMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.individualEmployeeMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.generalWalletMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.employeeMibsHistory);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.generalWalletMibs);
    },
  });
};
