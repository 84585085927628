import React from 'react';
import { Box, Flex, FlexProps, Text, Tooltip } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

interface ComponentProps extends FlexProps {
  title: string;
  value: string | number;
  tooltipText?: string;
}

export const HeaderStatsSmallBox: React.FC<ComponentProps> = ({
  title,
  value,
  tooltipText,
  ...rest
}) => {
  return (
    <Flex
      backgroundColor="background.500"
      h="100%"
      w="20%"
      borderRadius={4}
      p={3}
      justify="space-between"
      {...rest}
    >
      <Flex gap={0.5}>
        <Text color="gray.200" fontWeight="semibold" alignSelf="center">
          {title}
        </Text>
        {tooltipText && (
          <Tooltip
            hasArrow
            label={tooltipText}
            placement="top"
            color="white"
            bg="background.200"
          >
            <Box>
              <AiOutlineInfoCircle size={14} />
            </Box>
          </Tooltip>
        )}
      </Flex>
      <Text fontWeight="semibold" alignSelf="center">
        {value}
      </Text>
    </Flex>
  );
};
