import { Button } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { DeclineLeadOfferModal } from 'componentsV2/modules/captation/declineLeadOfferModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';

export const LeadDetailsDeclineOffer = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();
  const [isHovered, setIsHovered] = useState(false);

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenDeclineOfferModal =
    getParam('isOpenDeclineOfferModal') === 'true';

  return (
    <>
      <Button
        onClick={() => setParam('isOpenDeclineOfferModal', 'true')}
        fontSize="0.8rem"
        color="#692525"
        backgroundColor="#FFEBEB"
        fontWeight="semibold"
        size="md"
        _hover={{ bg: '#692525', color: '#FFEBEB' }}
        justifyContent="space-between"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        width="160px"
        maxWidth="160px"
      >
        <IoMdClose color={isHovered ? '#fff' : '#692525'} size={15} />
        Declínio de Oferta
      </Button>

      <ModalWrapper
        isOpen={isOpenDeclineOfferModal}
        onClose={() => deleteParam('isOpenDeclineOfferModal')}
      >
        <DeclineLeadOfferModal
          lead={lead}
          shouldOnlyDeclineMeeting={!!lead?.meeting}
          leadType={leadType}
        />
      </ModalWrapper>
    </>
  );
};
