import { HubApi } from '../index';

interface ICreateAnnouncement {
  message: string;
  date: string;
  employeeId: string;
  anonymous: boolean;
}

interface IUpdateAnnouncement {
  id: string | undefined;
  message: string;
  title: string;
}

interface ICreateOrder {
  information: string;
  anonymous: boolean;
}

interface IUpdateOrderData {
  id: string;
  status?: string | undefined;
  reason?: string;
  employeeId?: string;
}

class HubApiRH extends HubApi {
  async createAnnouncement(data: ICreateAnnouncement) {
    const response = await this.axios.post('/communications', data);
    return response.data;
  }

  async listAnnouncements(date: string) {
    const response = await this.axios.get(`/communications/${date}`);
    return response.data;
  }

  async updateAnnouncement(data: IUpdateAnnouncement) {
    const response = await this.axios.patch('/communications', data);
    return response.data;
  }

  async deleteAnnouncement(id: string) {
    const response = await this.axios.delete(`/communications/${id}`);
    return response.data;
  }

  async createOrdeRH(data: ICreateOrder) {
    const response = await this.axios.post('/rh/requests', data);
    return response.data;
  }

  async listAllOrders(searchStatus: string) {
    const response = await this.axios.get(`orh/by/${searchStatus}`);
    return response.data;
  }

  async updateOrderData(data: IUpdateOrderData) {
    const response = await this.axios.patch('/rh/requests', data);
    return response.data;
  }

  async listUsersWithRhPermission() {
    const response = await this.axios.get('/employees/area/RH');
    return response.data;
  }
}

export const hubApiRH = new HubApiRH();
