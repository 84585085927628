export const translateEventType = (eventType: string): string => {
  const helperObj: Record<string, string> = {
    NEWLEAD: 'Leads recebido',
    MEETDECLINED: 'Lead declinado',
    CALLBACK: 'Retorno agendado',
    MEETCREATED: 'Reunião marcada',
    MEETCONFIRMED: 'Reunião roteada',
    MEETRESCHEDULED: 'Reagendamento de reunião',
  };

  return helperObj[eventType];
};
