import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { LeadsPoolMI } from 'pages/Captation/components/modules/leads/LeadsPoolMI';

export const PoolMundoInvest = (): JSX.Element => {
  // This is a new page (inside pagesV2) but with old component/arch
  // Must refactor

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <LeadsPoolMI />
    </PageWrapper>
  );
};
