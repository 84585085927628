import { HubApi } from '../index';

class HubApiRealTime extends HubApi {
  async realTimeGet() {
    const response = await this.axios.get('leads/count');
    return response.data;
  }
}

export const hubApiRealTime = new HubApiRealTime();
