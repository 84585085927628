import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IMibsHistory } from '../dtos';

export const GetTransactionResponsibleName = async ({
  item,
  user,
}: {
  item: IMibsHistory;
  user: { name: string };
}): Promise<string> => {
  if (item.transactionResponsibleId === item.employeeId) return user.name;

  const infos = await hubApiCaptation.getEmployeeInfos(
    item.transactionResponsibleId
  );

  return infos.name;
};
