import { Flex } from '@chakra-ui/react';

interface WraperCardConsolidationProps {
  w?: string;
}

export const WraperCardConsolidation: React.FC<WraperCardConsolidationProps> =
  ({ children, w, ...rest }) => {
    return (
      <Flex
        p="2"
        w={w || '150px'}
        borderRadius="4px"
        flexDir="column"
        justify="space-between"
        align="center"
        background="background.600"
        {...rest}
      >
        {children}
      </Flex>
    );
  };
