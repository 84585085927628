import { LeadContactAttemptsOperationEnum } from 'pagesV2/LeadDetails/components/leadContactAttempts';
import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';

export enum LeadActionsEnum {
  DECLINE = 'decline',
  UPDATE_CONTACT_ATTEMPTS = 'update_contact_attempts',
}

export type LeadActionPropsType = {
  leadType: LeadTypeEnum;
  action: LeadActionsEnum;
  leadId: string;
  declinedReason?: string;
  operation?: LeadContactAttemptsOperationEnum;
};

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useLeadActions = (
  props?: PropsType
): UseMutationResult<unknown, unknown, LeadActionPropsType, unknown> => {
  return useMutation({
    mutationKey: ['leadActions'],
    mutationFn: async (data: LeadActionPropsType) => {
      const response = await hubApiCaptation.leadAction(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};

export const useGlobalLeadAction = (
  props?: PropsType
): UseMutationResult<unknown, unknown, LeadActionPropsType, unknown> => {
  return useMutation({
    mutationKey: ['globalLeadActions'],
    mutationFn: async (data: LeadActionPropsType) => {
      const response = await hubApiCaptation.globalLeadAction(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};
