import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { formatValue } from 'react-currency-input-field';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { BiInfoCircle } from 'react-icons/bi';

import { TableData, TableRow } from 'components/elements/table';
import { ModalWrapper } from 'components/elements/wrappers';
import { CardToInfo } from '../CardToInfo';
interface ComponentProps {
  fund: {
    ticker: string;
    informations: string;
    segment: string;
    allocation: string;
    dailyLiquidity: string;
    pvp: string;
    netPL: string;
    dividendYield: string;
  };
}

export const RealEstateFundsTableRow: React.FC<ComponentProps> = ({ fund }) => {
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <TableRow>
      <TableData>{fund.ticker}</TableData>
      <TableData>{fund.allocation}%</TableData>
      <TableData>{fund.pvp}</TableData>
      <TableData>{fund.dailyLiquidity}</TableData>
      <TableData>
        {formatValue({
          value: fund.netPL,
          prefix: 'R$ ',
          decimalSeparator: ',',
          intlConfig: {
            locale: 'pt-BR',
            currency: 'BRL',
          },
        })}
      </TableData>
      <TableData>{fund.dividendYield}</TableData>
      <TableData
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={handleToggleModal}
        cursor="pointer"
      >
        <BiInfoCircle size={18} color="rgba(70, 70, 70, 1)" />
      </TableData>
      <TableData>
        <Flex width="100%" justifyContent="center">
          <a
            style={{ alignSelf: 'center', justifySelf: 'center' }}
            target="_blank"
            href={`https://www.fundsexplorer.com.br/funds/${fund.ticker}`}
            rel="noreferrer"
          >
            <HiOutlineExternalLink size={18} color="rgba(70, 70, 70, 1)" />
          </a>
        </Flex>
      </TableData>

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <CardToInfo ticker={fund.ticker} info={fund.informations} />
      </ModalWrapper>
    </TableRow>
  );
};
