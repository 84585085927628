import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';

interface IProps {
  title: string;
  complement: string;
}

export const MeetingComplement: React.FC<IProps> = ({ complement, title }) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle>{title}</ContentDividerTitle>
      <ContentDividerText color="white">{complement}</ContentDividerText>
    </ContentWithVertDividerWrapper>
  );
};
