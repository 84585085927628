import { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import { TitleWithSideLine } from 'components/elements/texts';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { useCaptationMeetingsContext } from 'hooks';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import { DropDownContactAttempts } from 'pages/Captation/components/elements/others/DropDownContactAttempts';
import { verifyAndFormatPl } from 'utils/verifyAndFormatPl';
import { DropDownLeadType } from 'pages/Captation/components/elements/others/DropDownLeadType';
import formatValue from 'utils/formatValue';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';

interface IComponentProps {
  handleShowMeeting: (lead: IBaseMeeting) => void;
}

export const MeetingsPipe: React.FC<IComponentProps> = ({
  handleShowMeeting,
}) => {
  const { handleGetAllPipe, pipe } = useCaptationMeetingsContext();
  const [filterPipe, setFilterPipe] = useState(pipe);

  const [attemptsFilterValue, setAttemptsFilterValue] = useState<number>(-1);
  const filterLeads =
    attemptsFilterValue === -1
      ? filterPipe
      : filterPipe.filter(item => {
          const numberContactAttempts = Number(
            item.contactAttempts ? item.contactAttempts : 0
          );

          if (numberContactAttempts === attemptsFilterValue) {
            return item;
          }

          if (numberContactAttempts >= 5 && attemptsFilterValue === 5) {
            return item;
          }
        });

  const filterLeadType = (leadType: string) => {
    if (leadType.toLocaleLowerCase().trim() === '') {
      return setFilterPipe(pipe);
    }

    if (leadType === 'lista') {
      return setFilterPipe(
        pipe.filter(item => {
          if (item.leadType === 'tables' && item.nomeListaPool) return item;
        })
      );
    }

    if (leadType === 'mined') {
      return setFilterPipe(
        pipe.filter(item => {
          if (item.leadType === 'tables' && !item.nomeListaPool) return item;
        })
      );
    }

    return setFilterPipe(
      pipe.filter(item => {
        if (item.leadType === leadType) return item;
      })
    );
  };

  const translateStatusName = (status: string) => {
    let statusName;

    switch (status) {
      case 'suitability':
        statusName = 'Suitability';
        break;
      case 'callback':
        statusName = 'Retornos';
        break;
      case 'presentation':
        statusName = 'Carteira';
        break;
      case 'allocation':
        statusName = 'Aguardando abertura';
        break;

      default:
        statusName = 'Alocação';
    }

    return statusName;
  };

  const verifyAndFormatLeadType = (leadType: string, nomeListaPool: string) => {
    let formatedLeadType = '';
    const isLeadList = nomeListaPool && leadType === 'tables';

    if (isLeadList) {
      formatedLeadType = `Lista (${nomeListaPool})`;
      return formatedLeadType;
    }

    switch (leadType) {
      case 'publics':
      case 'public':
      case LeadTypeEnum.MUNDO_INVEST:
        formatedLeadType = 'Mundo Invest';
        break;
      case 'pj':
        formatedLeadType = 'Pessoa Jurídica';
        break;
      case 'leadspj':
        formatedLeadType = 'Pessoa Jurídica';
        break;
      case 'employee':
        formatedLeadType = 'Lead Pessoal';
        break;
      default:
        formatedLeadType = 'Lead minerado';
    }

    return formatedLeadType;
  };

  let totalSum = 0;

  for (const obj of pipe) {
    const lctValue = obj.lctpipevalue
      ? parseFloat(obj.lctpipevalue.split(',')[0]?.replace('.', ''))
      : 0;
    const rdValue = obj.rdpipevalue
      ? parseFloat(obj.rdpipevalue.split(',')[0]?.replace('.', ''))
      : 0;

    totalSum += lctValue + rdValue;
  }

  useEffect(() => {
    handleGetAllPipe();
  }, []);

  return (
    <>
      <Flex gridGap={3}>
        <TitleWithSideLine>
          <Flex>
            Leads Pipe
            <Tooltip
              hasArrow
              label="Nesta seção estão todos os seus leads atrasados, ou seja, aqueles
            com reuniões agendadas atrasadas em mais de 2 dias. Para
            recuperá-los, clique no lead"
              placement="top"
              color="white"
              bg="background.200"
            >
              <Flex>
                <BiInfoCircle size={16} color="white" />
              </Flex>
            </Tooltip>
          </Flex>
        </TitleWithSideLine>
        <Text
          fontWeight="bold"
          lineHeight="120%"
          color="whiteAlpha.900"
          fontSize={{ base: 'sm', lg: 'md' }}
          alignSelf="center"
        >
          {formatValue(totalSum).split(',')[0]}
        </Text>
        <DropDownLeadType filterLeadType={filterLeadType} />
        <DropDownContactAttempts
          setAttemptsFilterValue={setAttemptsFilterValue}
        />
      </Flex>

      <TableBase
        headData={[
          'Nome',
          'Origem',
          'Pl',
          'Etapa',
          'Tentativas',
          'Reunião em atraso',
        ]}
        p="0"
        w="100%"
        overflowY="auto"
        my={3}
      >
        {filterLeads.map(meeting => {
          const isLeadWithoutFeeback =
            meeting.confirm === false &&
            (meeting.leadType === 'publics' ||
              meeting.leadType === LeadTypeEnum.MUNDO_INVEST) &&
            meeting.complement !==
              'Lead abriu conta no Mundo Invest pelo seu link.';

          const isMundoInvestLead =
            meeting.leadType === 'publics' ||
            meeting.leadType === LeadTypeEnum.MUNDO_INVEST;

          let paddingLeft = 0;

          if (!isLeadWithoutFeeback) {
            paddingLeft = 26;

            if (isMundoInvestLead || isLeadWithoutFeeback) {
              paddingLeft -= 13;
            }
          }

          return (
            <>
              <TableRow
                key={meeting.id}
                bg="background.500"
                _hover={{
                  background: 'background.300',
                  cursor: 'pointer',
                }}
                transition="ease-in-out 0.2s"
                onClick={() => {
                  handleShowMeeting(meeting);
                }}
              >
                <TableData
                  maxW="180px"
                  title={formatNameToCaptalize(meeting.name)}
                >
                  {/* {lead.leadType === 'publics' && (
                  <Tooltip
                    label="Lead Mundo Invest"
                    cursor="pointer"
                    color="blackAlpha.900"
                    placement="right"
                  >
                    <Box
                      bg="blue.300"
                      borderRadius="50%"
                      h="10px"
                      minW="10px"
                    />
                  </Tooltip>
                )} */}
                  <Flex gap="0.4rem" justifyContent="start" alignItems="center">
                    {isLeadWithoutFeeback && (
                      <Flex>
                        <Tooltip
                          label="Lead sem feedBack"
                          cursor="pointer"
                          color="blackAlpha.900"
                          placement="right"
                        >
                          <Box
                            bg="red.300"
                            borderRadius="50%"
                            h="6px"
                            minW="6px"
                          />
                        </Tooltip>
                      </Flex>
                    )}

                    {isMundoInvestLead && (
                      <Tooltip
                        label="Lead Mundo Invest"
                        cursor="pointer"
                        color="blackAlpha.900"
                        placement="right"
                      >
                        <Box
                          bg="blue.300"
                          borderRadius="50%"
                          h="6px"
                          minW="6px"
                        />
                      </Tooltip>
                    )}

                    <Flex paddingLeft={`${paddingLeft}px`}>
                      {formatNameToCaptalize(meeting.name)}
                    </Flex>
                  </Flex>
                </TableData>

                <TableData maxW="180px" isTruncated>
                  {verifyAndFormatLeadType(
                    meeting.leadType,
                    meeting.nomeListaPool as string
                  )}
                </TableData>

                <TableData maxW="160px">
                  {meeting.leadType === 'leadspj' || meeting.leadType === 'pj'
                    ? verifyAndFormatPl(meeting.faixaFaturamento)
                    : verifyAndFormatPl(meeting.pl || null)}
                </TableData>

                <TableData maxW="180px" isTruncated>
                  {translateStatusName(meeting.status)}
                </TableData>
                <TableData maxW="180px" isTruncated>
                  {meeting.contactAttempts || '0'}
                </TableData>

                <TableData maxW="180px" isTruncated color="red.500">
                  {formateDateAndTime(meeting.date)}
                </TableData>
              </TableRow>
            </>
          );
        })}
      </TableBase>
    </>
  );
};
