import { Flex } from '@chakra-ui/react';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import {
  ModalWrapper,
  DefaultCardWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { hubApiOfficeBranches } from 'services/hubApi/classes/HubApiOfficeBranches';
import { Item } from './components/Item';
import { CreateFilial } from './components/CreateFilial';

import { OfficesProps } from './interfaces';

const OfficeBranches: React.FC = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [offices, setOffices] = useState<OfficesProps[]>([]);
  // const [editableOffice, setEditableOffice] = useState<OfficesProps>(
  //   {} as OfficesProps
  // );

  useEffect(() => {
    hubApiOfficeBranches
      .listBranchOffices()
      .then(response => {
        setOffices(response);
      })
      .catch(() => {
        toast.dark('Erro ao buscar dados das filiais.');
      });
  }, []);

  const handleToggleModal = () => setDisplayModal(!displayModal);

  const handleDeleteOffice = (officeId: string) => {
    hubApiOfficeBranches
      .deleteBranchOffice(officeId)
      .then(() => {
        toast.dark('Filial excluida com sucesso.');
        hubApiOfficeBranches.listBranchOffices().then(response => {
          setOffices(response);
        });
      })
      .catch(() => {
        toast.dark('Erro ao tentar excluir filial.');
      });
  };

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />
      <MainPageTitle textAlign="center" fontSize={'xl'}>
        Filiais
      </MainPageTitle>

      <Flex w="100%" maxW="960px" flexDir="column">
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <SecondaryButton onClick={handleToggleModal}>
            Criar uma nova filial
          </SecondaryButton>
        </Flex>

        <DefaultCardWrapper
          w="100%"
          mt="16px"
          maxH="370px"
          p="16px"
          borderRadius="5px"
          flexDir="column"
          boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
        >
          <Flex
            flexDir="column"
            minH="180px"
            p="8px"
            maxH="440px"
            overflowY="auto"
          >
            {offices.map((office, key) => (
              <Item
                office={office}
                key={key}
                handleDeleteOffice={handleDeleteOffice}
                setOffices={setOffices}
              />
            ))}
          </Flex>
        </DefaultCardWrapper>
      </Flex>

      {displayModal && (
        <ModalWrapper isOpen={displayModal} onClose={handleToggleModal}>
          <CreateFilial
            handleToggleModal={handleToggleModal}
            setOffices={setOffices}
          />
        </ModalWrapper>
      )}
    </PageWrapper>
  );
};

export default OfficeBranches;
