import { useEffect, useState } from 'react';
import { Flex, Box, Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LineInfo } from 'pages/GenerationalVariables/components/elements/others/LineInfo';
import { TitleInVertical } from 'pages/GenerationalVariables/components/elements/others/TitleInVertical';
import { TitleWithBorder } from 'pages/GenerationalVariables/components/elements/others/TitleWithBorder';
import { IDeclinesAndResultsDataset } from 'pages/GenerationalVariables/interfaces';
import { LoadingSpinner } from 'components/elements/others';
import { formatValueForSI } from 'utils/formatValueForSI';

interface DeclinesAndResultsDatasetProps {
  period: {
    from: string;
    to: string;
  };
}

export const DeclinesAndResultsDataset: React.FC<DeclinesAndResultsDatasetProps> =
  ({ period }) => {
    const [data, setData] = useState<IDeclinesAndResultsDataset>(
      {} as IDeclinesAndResultsDataset
    );
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      getData();
    }, [period]);

    const getData = async () => {
      setIsLoading(true);

      try {
        const response = await hubApiCaptation.getAAIDeclinesAndResults(
          period.from,
          period.to
        );

        setData(response);
        setIsLoading(false);
      } catch (err) {
        toast.dark('Ocorreu um erro ao buscar as informações');
      }
    };

    const formatPercentage = (value: number): string => {
      return value.toFixed(2).split('.')[1] === '00'
        ? value.toFixed(2).split('.')[0]
        : value.toFixed(2);
    };

    const computeTotalDeclinesPerMeeting = (
      key: 'suitability' | 'presentation' | 'account'
    ): number => {
      if (key === 'suitability') {
        const meeting = data[key];
        const totalDeclines =
          +meeting.suitability_btg_client +
          +meeting.suitability_lgpd +
          +meeting.suitability_no_answer +
          +meeting.suitability_no_exist +
          +meeting.suitability_no_interest;

        return totalDeclines;
      }

      if (key === 'presentation') {
        const meeting = data[key];
        const totalDeclines =
          +meeting.presentation_btg_client +
          +meeting.presentation_lgpd +
          +meeting.presentation_no_answer +
          +meeting.presentation_no_interest;

        return totalDeclines;
      }

      if (key === 'account') {
        const meeting = data[key];
        const totalDeclines =
          +meeting.account_btg_client +
          +meeting.account_lgpd +
          +meeting.account_no_answer +
          +meeting.account_no_interest;

        return totalDeclines;
      }

      return 0;
    };

    const computeDeclinesPercentagePerMeeting = (
      key: 'suitability' | 'presentation' | 'account'
    ) => {
      if (key === 'suitability') {
        const meeting = data[key];
        const totalMeetings = Number(meeting.suitability);

        if (totalMeetings === 0) return '0';

        const totalDeclines = computeTotalDeclinesPerMeeting(key);
        const totalDeclinesPercentage = (totalDeclines / totalMeetings) * 100;

        return formatPercentage(totalDeclinesPercentage);
      }

      if (key === 'presentation') {
        const meeting = data[key];
        const totalMeetings = Number(meeting.presentation);

        if (totalMeetings === 0) return '0';

        const totalDeclines = computeTotalDeclinesPerMeeting(key);
        const totalDeclinesPercentage = (totalDeclines / totalMeetings) * 100;

        return formatPercentage(totalDeclinesPercentage);
      }

      if (key === 'account') {
        const meeting = data[key];
        const totalMeetings = Number(meeting.account);

        if (totalMeetings === 0) return '0';

        const totalDeclines = computeTotalDeclinesPerMeeting(key);
        const totalDeclinesPercentage = (totalDeclines / totalMeetings) * 100;

        return formatPercentage(totalDeclinesPercentage);
      }

      return '0';
    };

    const computeSpecificDeclinePercentage = (
      key: 'suitability' | 'presentation' | 'account',
      declineValue: string
    ) => {
      if (key === 'suitability') {
        const totalDeclines = computeTotalDeclinesPerMeeting(key);

        if (totalDeclines === 0 || declineValue === '0') return '0';

        const totalSpecificDeclinePercentage =
          (Number(declineValue) / totalDeclines) * 100;

        return formatPercentage(totalSpecificDeclinePercentage);
      }

      if (key === 'presentation') {
        const totalDeclines = computeTotalDeclinesPerMeeting(key);

        if (totalDeclines === 0 || declineValue === '0') return '0';

        const totalSpecificDeclinePercentage =
          (Number(declineValue) / totalDeclines) * 100;

        return formatPercentage(totalSpecificDeclinePercentage);
      }

      if (key === 'account') {
        const totalDeclines = computeTotalDeclinesPerMeeting(key);

        if (totalDeclines === 0 || declineValue === '0') return '0';

        const totalSpecificDeclinePercentage =
          (Number(declineValue) / totalDeclines) * 100;

        return formatPercentage(totalSpecificDeclinePercentage);
      }

      return '0';
    };

    return (
      <>
        {Object.keys(data).length > 0 && (
          <Flex minH={'470px'} flexDir="column" flex="1" ml="5">
            <Box mb={1}>
              <TitleWithBorder label="Declínios - Estático" />

              {isLoading ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  maxH="200px"
                  w="100%"
                >
                  <LoadingSpinner size="sm" />
                </Flex>
              ) : (
                <>
                  <LineInfo
                    title="Qtd. de Leads Gerados"
                    quantity={data?.meetings}
                  />
                  <Stack spacing={4} mb={2} mt={3}>
                    <Flex>
                      <TitleInVertical
                        minW="100px"
                        text="Suitability"
                        porcent={computeDeclinesPercentagePerMeeting(
                          'suitability'
                        )}
                      />

                      <Stack spacing="1" ml="2" w="100%">
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'suitability',
                            data.suitability.suitability_btg_client
                          )}
                          title="Qtd. Cliente BTG"
                          quantity={data.suitability.suitability_btg_client}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'suitability',
                            data.suitability.suitability_no_interest
                          )}
                          title="Qtd. Sem interesse"
                          quantity={data.suitability.suitability_no_interest}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'suitability',
                            data.suitability.suitability_no_answer
                          )}
                          title="Qtd. Não atendeu"
                          quantity={data.suitability.suitability_no_answer}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'suitability',
                            data.suitability.suitability_lgpd
                          )}
                          title="Qtd. Não ligar mais (LGPD)"
                          quantity={data.suitability.suitability_lgpd}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'suitability',
                            data.suitability.suitability_no_exist
                          )}
                          title="Qtd. Não existe"
                          quantity={data.suitability.suitability_no_exist}
                        />
                      </Stack>
                    </Flex>

                    <Flex>
                      <TitleInVertical
                        minW="80px"
                        text="Carteira"
                        porcent={computeDeclinesPercentagePerMeeting(
                          'presentation'
                        )}
                      />

                      <Stack spacing="0" ml="2" w="100%">
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'presentation',
                            data.presentation.presentation_btg_client
                          )}
                          title="Qtd. Cliente BTG"
                          quantity={data.presentation.presentation_btg_client}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'presentation',
                            data.presentation.presentation_no_interest
                          )}
                          title="Qtd. Sem interesse"
                          quantity={data.presentation.presentation_no_interest}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'presentation',
                            data.presentation.presentation_no_answer
                          )}
                          title="Qtd. Não atendeu"
                          quantity={data.presentation.presentation_no_answer}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'presentation',
                            data.presentation.presentation_lgpd
                          )}
                          title="Qtd. Não ligar mais (LGPD)"
                          quantity={data.presentation.presentation_lgpd}
                        />
                      </Stack>
                    </Flex>

                    <Flex>
                      <TitleInVertical
                        minW="80px"
                        text="Abertura"
                        porcent={computeDeclinesPercentagePerMeeting('account')}
                      />

                      <Stack spacing="0" ml="2" w="100%">
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'account',
                            data.account.account_btg_client
                          )}
                          title="Qtd. Cliente BTG"
                          quantity={data.account.account_btg_client}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'account',
                            data.account.account_no_interest
                          )}
                          title="Qtd. Sem interesse"
                          quantity={data.account.account_no_interest}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'account',
                            data.account.account_no_answer
                          )}
                          title="Qtd. Não atendeu"
                          quantity={data.account.account_no_answer}
                        />
                        <LineInfo
                          porcent={computeSpecificDeclinePercentage(
                            'account',
                            data.account.account_lgpd
                          )}
                          title="Qtd. Não ligar mais (LGPD)"
                          quantity={data.account.account_lgpd}
                        />
                      </Stack>
                    </Flex>
                  </Stack>
                </>
              )}
            </Box>

            <Box h="100%">
              <TitleWithBorder label="Resultados" />

              {isLoading ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  maxH="80px"
                  w="100%"
                >
                  <LoadingSpinner size="sm" padding="0" />
                </Flex>
              ) : (
                <Stack spacing="1" overflowY="auto">
                  <LineInfo
                    title="Contas abertas"
                    quantity={data?.results?.open_accounts}
                  />
                  <LineInfo
                    title="R.R contas abertas"
                    quantity={`${Number(data?.results?.porcentRR).toFixed(2)}%`}
                  />
                  <LineInfo
                    title="PL captado"
                    quantity={formatValueForSI(Number(data?.results?.pl))}
                  />
                  <LineInfo
                    title="Ticket médio"
                    quantity={formatValueForSI(
                      +data?.results?.pl / +data?.results?.open_accounts
                    )}
                  />
                </Stack>
              )}
            </Box>
          </Flex>
        )}
      </>
    );
  };
