import React, { useState, useEffect } from 'react';

import Board from 'pages/Exchange/components/modules/exchangeCRM/Board';
import { exchangeColumns } from './data';

import { useParams } from 'react-router';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';

const ExchangeBoardManager = () => {
  const { id } = useParams<{ id?: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(exchangeColumns);

  useEffect(() => {
    const listEmployeeOrders = async () => {
      await hubApiExchange.listEmployeeCrm(id).then(response => {
        for (let column of columns) {
          const tasks = response.filter(
            (order: any) =>
              order.status.toLowerCase() === column.value.toLowerCase()
          );
          column.tasks = tasks;
        }
        setIsLoading(false);
      });
    };

    listEmployeeOrders();
  }, []);

  return (
    <PageWrapper
      flexDir="column"
      paddingTop="48px"
      justifyContent="center"
      alignItems="center"
    >
      <BackPageButton />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Board
          boardTitle="Câmbio"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};

export default ExchangeBoardManager;
