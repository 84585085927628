import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface ComponentProps extends FlexProps {
  value: number;
  Icon: IconType;
  isMeeting?: boolean;
}

export const MeetingTypeIcon: React.FC<ComponentProps> = ({
  value,
  Icon,
  isMeeting,
  ...rest
}) => {
  return (
    <Flex position="absolute" top="7px" right="5px" {...rest}>
      <Flex position="relative" alignItems="center">
        {value > 1 && (
          <Text
            right="-2px"
            top="-3px"
            fontSize="8px"
            position="absolute"
            px="2px"
            borderRadius="50%"
            background="red.500"
          >
            {value}
          </Text>
        )}
        <Icon size={16} color={isMeeting ? '#E53E3E' : '#ffff'} />
      </Flex>
    </Flex>
  );
};
