import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { IPoolList } from 'pages/DistributionOfMinedLeads/interfaces';

interface ITableDetailsProps {
  allPoolLists: IPoolList[];
}

export const TableDetails: React.FC<ITableDetailsProps> = ({
  allPoolLists,
}) => {
  return (
    <TableContainer
      width={'100%'}
      maxHeight="200px"
      height={'200px'}
      overflowY={'auto'}
    >
      <Table variant="unstyled" size="sm">
        <Thead position="sticky" top="0" background="background.700">
          <Tr>
            <Th>Nome da mesa</Th>
            <Th textAlign="center">Qtd. leads</Th>
          </Tr>
        </Thead>
        <Tbody>
          {allPoolLists.map(poolList => (
            <Tr
              key={poolList.PoolName}
              borderBottom="1px solid"
              borderColor="background.400"
            >
              <Td isTruncated maxWidth={'90%'} width="90%">
                {poolList.PoolName}
              </Td>
              <Td textAlign="center" isTruncated maxWidth={'10%'} width="10%">
                {poolList.leadsQTD}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
