import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useNavigation } from 'hooksV2/useNavigation';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  ClientMeetingActionEnum,
  hubApiClients,
  MeetingActionRequestType,
} from 'services/hubApi/classes/HubApiClients';
import {
  ClientMeetingStatusEnum,
  ClientMeetingType,
} from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { z } from 'zod';

type PropsType = {
  meeting: ClientMeetingType;
};

const schema = z.object({
  contactType: z
    .string()
    .min(1, { message: 'O campo tipo de contato é obrigatório' }),
  clientSatisfaction: z
    .string()
    .min(1, { message: 'O campo satisfação do cliente é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const ClientMeetingDetailsActions: React.FC<PropsType> = ({
  meeting,
}): JSX.Element => {
  const { goBack } = useNavigation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const isOpenClientSatisfactionModalFilter = useSimpleQueryFilter(
    'isOpenClientSatisfactionModal'
  );

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetinAction } =
    useMutation({
      mutationKey: ['clientMeetingAction'],
      mutationFn: async (data: MeetingActionRequestType) => {
        const response = await hubApiClients.meetingAction(data);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', meeting.btgCode]);

        goBack();
      },
      onError: error => handleErrorMessage(error),
    });

  const handleConcludeMeeting: SubmitHandler<FormFieldsType> = async data => {
    await meetingAction({
      id: meeting.id,
      action: ClientMeetingActionEnum.CONCLUDED,
      clientSatisfaction: Number(data.clientSatisfaction),
      contactType: data.contactType,
    });
  };

  return (
    <Flex justifyContent="right">
      {meeting.status !== ClientMeetingStatusEnum.CONCLUDED && (
        <Button.Primary
          onClick={() =>
            isOpenClientSatisfactionModalFilter.handleChangeFilterValue('true')
          }
        >
          Concluir reunião
        </Button.Primary>
      )}

      <ModalWrapper
        isOpen={isOpenClientSatisfactionModalFilter.filterValue === 'true'}
        onClose={() => isOpenClientSatisfactionModalFilter.handleDeleteParam()}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title="Antes de concluir, informe o tipo de contato e a satisfação do cliente" />
            <Modal.CloseIcon
              onClick={() =>
                isOpenClientSatisfactionModalFilter.handleDeleteParam()
              }
            />
          </Modal.Header>

          <Flex flexDirection="column" gap={3}>
            <Dropdown.Root {...register('contactType')}>
              <Dropdown.Options
                placeholder="Tipo de Contato"
                options={[
                  { label: 'Ligação', value: 'Ligação' },
                  { label: 'Videocall', value: 'Videocall' },
                  { label: 'Presencial', value: 'Presencial' },
                ]}
              />
            </Dropdown.Root>
            <Input.ErrorMessage message={errors?.contactType?.message} />

            <Dropdown.Root {...register('clientSatisfaction')}>
              <Dropdown.Options
                placeholder="Satisfação do Cliente"
                options={[
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                ]}
              />
            </Dropdown.Root>
            <Input.ErrorMessage message={errors?.clientSatisfaction?.message} />
          </Flex>

          <Modal.Actions>
            <Modal.Action
              text="Concluir"
              onClick={handleSubmit(handleConcludeMeeting)}
              isLoading={isLoadingMeetinAction}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
