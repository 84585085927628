export default {
  GESTOR: 'GTR',
  GESTORMESA: 'GTM',
  MASTER: 'MASTER',
  RH: 'RH',
  IP: 'IP',
  FINANCEIRO: 'FI',
  COLABORADOR: 'CLT',
  ASSESSOR: 'ASS',
  TRAINEE: 'TRA',
  CORDENADOR_TRAINEE: 'COT',
  ESPECIALISTA: 'ESP',
  BROKER: 'BRK',
  CLOSER_VIDA: 'CLV',
  CLOSER_SAUDE: 'CLS',
  CLOSER_IMOBILIARIO: 'CLI',
  CLOSER_CORPORATE: 'CLC',
  CLOSER_AGRO: 'CLG',
  CLOSER_SEG_ALTERNATIVOS: 'CLA',
};
