import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IAnnotations, IClients, IMeetings } from '../../../interfaces';
import { BoxInfo } from '../../BoxInfo';
import { MeetingCardContent } from './MeetingCardContent';

interface IMeetingCardProps {
  annotation: IAnnotations;
  selectViewedMeetings: boolean;
}

export const MeetingCard: React.FC<IMeetingCardProps> = ({
  annotation,
  selectViewedMeetings,
}) => {
  const [clientsData, setClientsData] = useState<IClients[]>([]);
  const [viewType, setViewType] = useState('notViewed');

  useEffect(() => {
    const allClients = annotation.clients.map(meeting => meeting);
    if (allClients) {
      setClientsData(allClients);
    }
  }, [annotation]);

  const handleShowViewedMeetings = () => {
    if (!selectViewedMeetings) {
      return setViewType('notViewed');
    }
    if (selectViewedMeetings) {
      return setViewType('viewed');
    }
  };

  useEffect(() => {
    handleShowViewedMeetings();
  }, [selectViewedMeetings]);

  const handleMeetingSeen = (data: IMeetings) => {
    let clientName;
    let viewed;

    const filterData = clientsData.map(clientData => {
      clientData.meetings?.map(meeting => {
        if (meeting.meetingId === data.meetingId) {
          meeting.viewed = data.viewed;
          meeting.rating = data.rating;
          clientName = clientData.clientName;
          viewed = data.viewed;
          return meeting;
        }
        return meeting;
      });
      return clientData;
    });

    if (viewed) {
      toast.dark(
        `A reunião com o cliente ${clientName}, foi marcada como vista.`
      );
    } else {
      toast.dark(`A reunião com o cliente ${clientName}, foi desmarcada.`);
    }
    setClientsData(filterData);
  };

  const allHaveBeenViewed = () => {
    const allMeetingsNotViewed = clientsData.map(clientData =>
      clientData.meetings?.findIndex(meeting => !meeting.viewed)
    );
    return allMeetingsNotViewed.find(item => item === 0);
  };
  return (
    <>
      {clientsData.length ? (
        clientsData.map(data =>
          data.meetings?.map(meetingsData => {
            if (viewType === 'notViewed' && !meetingsData.viewed) {
              return (
                <MeetingCardContent
                  key={meetingsData.meetingId}
                  annotation={annotation}
                  data={data}
                  meetingsData={meetingsData}
                  handleMeetingSeen={handleMeetingSeen}
                />
              );
            }
            if (viewType === 'viewed' && meetingsData.viewed) {
              return (
                <MeetingCardContent
                  key={meetingsData.meetingId}
                  annotation={annotation}
                  data={data}
                  meetingsData={meetingsData}
                  handleMeetingSeen={handleMeetingSeen}
                />
              );
            }
          })
        )
      ) : (
        <BoxInfo textInfo="Não há reuniões para exibir, selecione outro AAI" />
      )}
      {allHaveBeenViewed() !== 0 && viewType === 'notViewed' && (
        <BoxInfo textInfo="Todas as reuniões foram marcadas como vista." />
      )}
    </>
  );
};
