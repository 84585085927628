import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { Input } from 'components/elements/forms';
import { DisplayInformation } from 'components/elements/others';
import { useIPContext } from 'pages/IP/hooks/useIPContext';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { formateDateAndTimeNoTimezone } from 'utils/formatDate';

interface NextContactDatePickerProps {
  nextContact?: string;
  orderId: string;
  taskId: string;
}

export const NextContactDatePicker: React.FC<NextContactDatePickerProps> = ({
  nextContact,
  orderId,
  taskId,
}) => {
  const { updateValueAtOrder } = useIPContext();

  const [hasSelectedMeetDate, setHasSelectedMeetDate] = useState('');
  const [editContactDate, setEditContactDate] = useState(!nextContact);
  const [contactDate, setContactDate] = useState(nextContact);

  const updateMeetDate = async (date: string) => {
    await hubApiIp.updateOrderIp(orderId, {
      nextContact: date,
    });

    updateValueAtOrder(orderId, date, 'nextContact', taskId);

    toast.dark('Data para o próximo contato definido com sucesso!');

    setEditContactDate(false);
    setHasSelectedMeetDate('');
    setContactDate(date);
  };

  if (!contactDate || editContactDate) {
    return (
      <DisplayInformation
        label="Marcar data do próximo contato"
        info={
          <Flex align="center" gap={2}>
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="datetime-local"
              defaultValue={
                contactDate
                  ? new Date(contactDate).toISOString().slice(0, 16)
                  : ''
              }
              onChange={e => setHasSelectedMeetDate(e.target.value)}
              px={2}
              fontSize={14}
            />
            {hasSelectedMeetDate && (
              <SecondaryButton
                onClick={() => updateMeetDate(hasSelectedMeetDate)}
                size="xs"
                fontWeight="normal"
                mx={{ md: 'auto', lg: 'auto 0' }}
              >
                <Flex align="center" px={2} gap={1}>
                  Salvar
                  <CheckIcon />
                </Flex>
              </SecondaryButton>
            )}
          </Flex>
        }
      />
    );
  }

  return (
    <DisplayInformation
      label="Data do próximo contato"
      info={
        <Flex align="center" gap={2} wrap="wrap" justify="space-between">
          <Text fontSize={14} px={2}>
            {formateDateAndTimeNoTimezone(contactDate)}{' '}
          </Text>

          <SecondaryButton
            onClick={() => setEditContactDate(true)}
            size="xs"
            fontWeight="normal"
          >
            <Flex align="center" px={2} gap={1}>
              Alterar
              <EditIcon />
            </Flex>
          </SecondaryButton>
        </Flex>
      }
    />
  );
};
