import { SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface DeleteModalProps {
  announcementId: string;
  closeModal: () => void;
  deleteAnnouncement: (announcementId: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  announcementId,
  closeModal,
  deleteAnnouncement,
}) => {
  return (
    <DefaultModalContentWrapper>
      <ModalTitle textAlign="center">
        Deseja realmente excluir este comunicado?
      </ModalTitle>

      <BaseButtonGroup justifyContent="center">
        <SecondaryButton onClick={closeModal}>Voltar</SecondaryButton>

        <SecondaryButton
          background="red.400"
          opacity={0.6}
          _hover={{ opacity: '0.8' }}
          onClick={() => deleteAnnouncement(announcementId)}
        >
          Excluir conteúdo
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
