import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { RiCoinsLine, RiEdit2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { errorHandler } from 'utils';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { EditPointsForm } from 'components/modules/Management';
import { IShNumbers } from 'pages/GenerationalVariables/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

import {
  DefaultModalContentWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import { IReduxState } from 'shared/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import { StarIcon } from '@chakra-ui/icons';
import { AiOutlineInfoCircle, AiOutlineStar } from 'react-icons/ai';
import { DynamicNumbersDatasetSmallVersion } from 'pages/GenerationalVariables/components/modules/shSpecificNumbers/DynamicNumbersDatasetSmallVersion';
import formatValue from 'utils/formatValue';
import { ModalTitle } from 'components/elements/texts';
import { Input } from 'components/elements/forms';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { formatToPhone } from 'brazilian-values';

export function ShSpecificNumbers(): JSX.Element {
  const user: { position: string; id: string } = useSelector(
    (state: IReduxState) => state.user.profile
  );

  const { shId } = useParams<{ shId: string }>();
  const { date } = useParams<{ date: string }>();
  const [shNumbers, setShNumbers] = useState({} as IShNumbers);
  const [isLoading, setIsLoading] = useState(false);
  const [sendLeadsLoading, setSendLeadsLoading] = useState(false);
  const [showPointsModal, setShowPointsModal] = useState(false);
  const [showSendLeadsModal, setShowSendLeadsModal] = useState(false);
  const [shPoints, setShPoints] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [sendLeadsQtd, setSendLeadsQtd] = useState('0');
  const [leadsSent, setLeadsSent] = useState<any[]>([]);
  const [startDatePl, setStartDatePl] = useState<string>(
    new Date(date).toISOString().substring(0, 10)
  );
  const [endDatePl, setEndDatePl] = useState<string>(() => {
    const currentDate = new Date(date);
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    return lastDayOfMonth.toISOString().substring(0, 10);
  });

  const [showLeadsSentModal, setShowLeadsSentModal] = useState<boolean>(false);

  const { data: shPLRouted, refetch: refetchSHPLRouted } = useQuery<{
    totalPlSh: string;
  }>({
    queryKey: ['shPLRouted', shId],
    queryFn: async () => {
      const response = await hubApiCaptation.getTotalSHPLRouted({
        employeeId: shId,
        finalDate: endDatePl,
        initialDate: startDatePl,
      });
      return response;
    },
    refetchOnWindowFocus: false,
  });

  const handleCheckShProfessional = async () => {
    try {
      await hubApiUser.pathTurnShProfessional(shNumbers.id);

      setShNumbers({ ...shNumbers, professional: !shNumbers.professional });
      toast.dark('Dados atualizados com sucesso!');
    } catch (err) {
      toast.dark(
        'Ocorreu um erro ao transformar o SH, entre em contato com o suporte!'
      );
    }
  };

  const getShNumbers = async (searchDate?: string, isUpdateData?: boolean) => {
    try {
      if (!isUpdateData) setIsLoading(true);

      if (searchDate) {
        if (
          isBefore(
            new Date(searchDate),
            new Date(shNumbers.created_at.slice(0, 7))
          )
        ) {
          toast.dark('não há dados nesse periodo');
          return;
        }

        const response = await hubApiUser.getShNumbers(shId, searchDate);

        setShNumbers(response);

        setShPoints(Number(response.points));
      } else {
        const response = await hubApiUser.getShNumbers(shId, date);

        setShNumbers(response);

        setShPoints(Number(response.points));
      }

      if (!isUpdateData) setIsLoading(false);
    } catch (error) {
      if (!isUpdateData) setIsLoading(false);
      errorHandler(error);
    }
  };

  const updatePoints = (
    points: string,
    operationType: string,
    role: string
  ) => {
    if (role === 'sh') {
      if (operationType === 'sum') {
        setShPoints(shPoints + Number(points));
      }

      if (operationType === 'sub') {
        setShPoints(shPoints - Number(points));
      }
    }
  };

  const checkHowLongUserIsSH = (timeValue: number): string => {
    if (timeValue === 1) return `${timeValue} Mês`;

    if (timeValue > 1) return `${timeValue} Meses`;

    return 'Menos de um mês';
  };

  const sendLeads = async () => {
    if (Number(sendLeadsQtd) === 0 || Number(sendLeadsQtd) > 30) {
      toast.dark('Quantidade inválida, envie no mínimo 1 e no máximo 30 leads');
      return;
    }

    setSendLeadsLoading(true);

    try {
      const leadsSentResponse = await hubApiUser.recycleLeads(
        shId,
        startDateFilter,
        endDateFilter,
        sendLeadsQtd
      );

      setLeadsSent(leadsSentResponse);
      toast.dark('Leads enviados com sucesso!');
    } catch (error: any) {
      setSendLeadsLoading(false);
      if (
        error.response.data.message ===
        'Error: NotFoundError: Zero Leads In Time Range Given'
      ) {
        toast.dark('Não foi possivel encontrar leads no período enviado');
      } else {
        toast.dark(
          'Não foi possível enviar os leads para o SH, tente novamente em alguns minutos'
        );
      }
    }
    setSendLeadsLoading(false);
  };

  const handleStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDateFilter(event.target.value);
  };

  const handleEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setEndDateFilter(event.target.value);
  };

  const handleSendLeadsQtd = (event: ChangeEvent<HTMLInputElement>) => {
    setSendLeadsQtd(event.target.value);
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getShNumbers();
    }
  }, [lastAuth]);

  useEffect(() => {
    refetchSHPLRouted();
  }, [startDatePl, endDatePl]);

  return (
    <PageWrapper paddingTop="0" flexDir="column" alignItems="center">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      <Flex
        h="80vh"
        w="100%"
        maxW="1080px"
        bg="background.600"
        borderRadius="3px"
        overflowY="auto"
        flexDir="row"
        mt={10}
        p={7}
      >
        <Flex flexDir="column" flex="1">
          <Flex gridGap="5" alignItems="center" justify="center" mb="20px">
            <Avatar size="xl" src={shNumbers.url} name={shNumbers.name} />

            <Text
              fontSize="xl"
              fontWeight="semibold"
              color="white.100"
              lineHeight="100%"
            >
              {formatNameToCaptalize(shNumbers.name)}
            </Text>

            <Tooltip
              label={
                shNumbers.professional
                  ? 'Tornar SH normal'
                  : 'Tornar SH Profissional'
              }
              cursor="pointer"
              color="blackAlpha.900"
            >
              {shNumbers.professional ? (
                <Box>
                  <Icon
                    as={StarIcon}
                    w="6"
                    h="6"
                    color="yellow.300"
                    transition="ease-in 0.2s"
                    _hover={{
                      h: '7',
                      w: '7',
                    }}
                    onClick={handleCheckShProfessional}
                    cursor="pointer"
                  />
                </Box>
              ) : (
                <Box>
                  <Icon
                    as={AiOutlineStar}
                    w="6"
                    h="6"
                    color="white"
                    transition="ease-in 0.2s"
                    _hover={{
                      h: '7',
                      w: '7',
                    }}
                    onClick={handleCheckShProfessional}
                    cursor="pointer"
                  />
                </Box>
              )}
            </Tooltip>
          </Flex>

          <Flex flexDir="column" mt={4}>
            <Text color="white.100" fontWeight="semibold">
              Tempo como Sales Hunter
            </Text>
            <Text as="span" fontWeight="normal">
              {checkHowLongUserIsSH(Number(shNumbers.create_at))}
            </Text>
          </Flex>
          <Flex
            width="100%"
            maxW="148px"
            alignItems="center"
            gridGap="3"
            mt={4}
          >
            <Text color="white.100" fontWeight="semibold">
              Pontuação
            </Text>

            {user.position.toLowerCase() === 'master' && (
              <Flex
                border="1px solid"
                borderColor="white.300"
                p="1"
                borderRadius="2"
                onClick={() => setShowPointsModal(true)}
                cursor="pointer"
              >
                <RiEdit2Fill size={12} color="#f8f8f8" />
              </Flex>
            )}
          </Flex>
          <Flex mt={2}>
            <RiCoinsLine size={24} color="#FFB32C" />
            <Text color="#FFB32C" ml={2} fontWeight="semibold">
              {new Intl.NumberFormat('de-DE').format(shPoints) || 0} (SH)
            </Text>
          </Flex>

          <Flex w="100%" flexDir="column" mt="2">
            <Text fontWeight="bold" color="white">
              PL Roteado
            </Text>

            <Text>{formatValue(Number(shPLRouted?.totalPlSh ?? 0))}</Text>
          </Flex>
          <SecondaryButton
            w="fit-content"
            mt={6}
            onClick={() => setShowSendLeadsModal(true)}
          >
            Enviar leads para o(a) SH
          </SecondaryButton>

          <Flex position="relative" mt={3} w="fit-content">
            <SecondaryButton
              onClick={() =>
                history.push(
                  `/captation/leads_sh/activities/${shId}/${shNumbers.name}`
                )
              }
              w="185.84px"
            >
              Atividades realizadas
            </SecondaryButton>
            <Flex
              w="32px"
              h="15px"
              backgroundColor="purple.700"
              borderRadius={5}
              color="white"
              fontSize="xx-small"
              justifyContent="center"
              alignItems="center"
              fontWeight="bold"
              px={2}
              pt={0.5}
              position="absolute"
              top="-5px"
              right="-7px"
            >
              NEW!
            </Flex>
          </Flex>
        </Flex>

        <DynamicNumbersDatasetSmallVersion
          shNumbers={shNumbers}
          getShNumbers={getShNumbers}
          date={date}
          isSmallVersion={false}
          setEndDatePl={setEndDatePl}
          setStartDatePl={setStartDatePl}
        />
      </Flex>

      <ModalWrapper
        isOpen={showPointsModal}
        onClose={() => setShowPointsModal(false)}
      >
        <DefaultModalContentWrapper
          flexDir="row"
          width="fit-content"
          gridGap="5"
          maxW="600px"
          height="100vh"
          maxH="220px"
        >
          <EditPointsForm
            employeeId={shId}
            employeeRole="sh"
            updatePoints={updatePoints}
          />
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showSendLeadsModal}
        onClose={() => setShowSendLeadsModal(false)}
      >
        <DefaultModalContentWrapper
          width="fit-content"
          gridGap={2}
          minW="600px"
          height="fit-content"
        >
          <Flex gap={0.5}>
            <ModalTitle mb={0}>Reciclagem de Leads</ModalTitle>
            <Tooltip
              hasArrow
              label="Envio de leads com frequência maior ou igual a 6. Selecione a quantidade e o período de entrada no sistema dos leads a serem enviados para o SH"
              placement="top"
              color="white"
              bg="background.200"
            >
              <Box>
                <AiOutlineInfoCircle size={14} />
              </Box>
            </Tooltip>
          </Flex>

          <Text>Enviar leads para {formatNameToCaptalize(shNumbers.name)}</Text>

          <Flex flexDir="column">
            <Flex gap={2} mt={4}>
              <Text fontSize="sm">Qtd. de leads:</Text>
              <Input
                type="number"
                size="10%"
                w="10%"
                borderRadius="sm"
                onChange={handleSendLeadsQtd}
              />
            </Flex>
            <Flex
              mt={2}
              w="100%"
              gridGap={[0, 2]}
              flexDirection={['column', 'row']}
            >
              <Text fontSize="sm" alignSelf="center">
                Período:
              </Text>
              <DatePickerChackra
                style={{ width: 'fit-content', alignSelf: 'flex-end' }}
                handlePickDate={handleStartDate}
                value={startDateFilter}
              />

              <Text alignSelf="center" fontSize="sm">
                até
              </Text>
              <DatePickerChackra
                style={{ width: 'fit-content', alignSelf: 'flex-end' }}
                handlePickDate={handleEndDate}
                value={endDateFilter}
              />
            </Flex>
          </Flex>
          <Flex alignSelf="flex-end" width="fit-content" gap={2} mt={6}>
            <SecondaryButton
              isDisabled={leadsSent.length === 0}
              onClick={() => setShowLeadsSentModal(true)}
            >
              Ver leads enviados
            </SecondaryButton>
            <SecondaryButton onClick={() => setShowSendLeadsModal(false)}>
              Voltar
            </SecondaryButton>
            <PrimaryButton onClick={sendLeads} isLoading={sendLeadsLoading}>
              Enviar Leads
            </PrimaryButton>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showLeadsSentModal}
        onClose={() => setShowLeadsSentModal(false)}
      >
        <DefaultModalContentWrapper
          width="fit-content"
          gridGap={2}
          minW="600px"
          height="fit-content"
        >
          <Flex gap={0.5}>
            <ModalTitle mb={0}>Leads Enviados</ModalTitle>
          </Flex>
          <TableBase
            headData={['Nome', 'Email', 'Telefone']}
            width="100%"
            maxH="400px"
            overflowY="auto"
            p="0"
          >
            {leadsSent.map(lead => (
              <TableRow>
                <TableData
                  maxW="190px"
                  title={formatNameToCaptalize(lead.name)}
                >
                  {formatNameToCaptalize(lead.name)}
                </TableData>
                <TableData maxW="190px" title={lead.email[0].email}>
                  {lead.email[0].email}
                </TableData>
                <TableData maxW="190px" title={lead.phone[0].phone}>
                  {formatToPhone(
                    lead.phone[0].phone.includes('55')
                      ? lead.phone[0].phone.substring(2)
                      : lead.phone[0].phone
                  )}
                </TableData>
              </TableRow>
            ))}
          </TableBase>

          <Flex alignSelf="flex-end" width="fit-content" gap={2} mt={6}>
            <SecondaryButton onClick={() => setShowLeadsSentModal(false)}>
              Voltar
            </SecondaryButton>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </PageWrapper>
  );
}
