import { Flex, GridItem, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { ClientAportType } from 'sharedV2/types/client';
import { formateDateAndTimeNoTimezone } from 'utils/formatDate';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  visualizationMode: string;
  aport: ClientAportType & { client: { name: string; btgCode: string } };
};

export const ClientAportCard: React.FC<PropsType> = ({
  aport,
  visualizationMode,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const isAportDateInPast = DateUtils.isInPast(aport.date);

  const handleClickOnCard = () => {
    redirect(`/v2/client/${aport.client.btgCode}`);
  };

  return (
    <GridItem
      w={visualizationMode === 'kanban' ? '100%' : '233px'}
      cursor="pointer"
      _hover={useCommonHover()}
      onClick={handleClickOnCard}
    >
      <Box.Opaque borderColor={isAportDateInPast ? 'red' : ''} p={4} h="100%">
        <Flex
          w="fit-content"
          p={1}
          borderRadius={50}
          px={2}
          mb="1"
          bgColor="red.200"
        >
          <Text color="black" fontSize="sm">
            CLIENTE BTG
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" gap={1}>
          <Text isTruncated maxW="220px" color="rgba(70, 70, 70, 1)">
            {TextUtils.toCapitalize(aport.client.name)}
          </Text>
        </Flex>

        <Flex flexDirection="column" mt={1} gap={3}>
          <Flex flexDirection="column">
            <Text
              fontSize={{ '2xl': 'sm', xl: 'xs' }}
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
            >
              Data aporte:{' '}
              <span>
                {
                  formateDateAndTimeNoTimezone(
                    aport.date as unknown as string
                  ).split('às')[0]
                }
              </span>
            </Text>

            <Text
              fontSize={{ '2xl': 'sm', xl: 'xs' }}
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
            >
              Valor aporte: <span>{CurrencyUtils.format(aport.value)}</span>
            </Text>
          </Flex>
        </Flex>
      </Box.Opaque>
    </GridItem>
  );
};
