import React, { useState } from 'react';
import { Button, Flex, InputProps, Spinner } from '@chakra-ui/react';
import { RiSendPlane2Line } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';

import { OutlineButton } from 'components/elements/buttons';
import { Input } from 'components/elements/forms';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { searchSchema } from './schema';

interface ComponentProps extends InputProps {
  handleSearchLead?: (searchValue: string) => void;
}

// const SearchLead: React.FC<ComponentProps> = ({
//   // handleSearchLead,
//   // ...rest
// })
const SearchLead: React.FC<ComponentProps> = () => {
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const handleToggleSearch = () => {
    setToggleSearch(!toggleSearch);
    setSearchValue('');
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClickSearch = async () => {
    try {
      setLoadingLeads(true);
      // const isValid = await searchSchema.isValid(searchValue);

      // if (!isValid) {
      //   return toast.dark(
      //     'O campo deve conter no minímo 8 digitos, máximo 12 e possuir somente números.'
      //   );
      // }

      const searchedLead = await hubApiCaptation.searchLeadAAI(searchValue);

      setSearchValue('');
    } catch (err) {
      toast.dark('Não foi possível encontrar o número digitado');
    }
    setLoadingLeads(false);
  };

  return (
    <Flex gridGap="1.5">
      <OutlineButton onClick={handleToggleSearch} p="2">
        <FaSearch size={12} />
      </OutlineButton>

      <Flex
        m="0"
        borderRadius="2"
        background="background.500"
        opacity={toggleSearch ? '0' : '1'}
        display={toggleSearch ? 'none' : 'flex'}
        transition="all 0.2s ease-in-out"
      >
        <Input
          size="sm"
          px="2"
          type="text"
          placeholder="Digite o número"
          value={searchValue}
          onChange={handleChangeSearchValue}
        />
        <Button
          size="sm"
          background="transparent"
          border="none"
          px="2"
          type="button"
          _hover={{ background: 'transparent' }}
          onClick={() => handleClickSearch()}
        >
          {loadingLeads ? (
            <Flex>
              <Spinner size="sm" />
            </Flex>
          ) : (
            <>
              <RiSendPlane2Line size={16} color="white" />
            </>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SearchLead;
