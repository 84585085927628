import { ModalWrapper } from 'components/elements/wrappers';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentProps } from 'pages/Home/interfaces';
import { useState } from 'react';
import { FcExpired, FcInfo } from 'react-icons/fc';
import formatValue from 'utils/formatValue';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface INoLeadCardProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
}

export const NoLeadCard: React.FC<INoLeadCardProps> = ({
  assignment,
  handleRemoveItem,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);
  return (
    <>
      <AssignmentContentCard
        IconElement={FcExpired}
        title="Sem Leads"
        secondaryText={assignment.extra || ''}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Sem Leads"
          message={assignment.extra || ''}
          idTask={assignment.id}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
