import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text, Box } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';

import { InfoTitle } from 'pages/MyClients/components/elements';
import { SecondaryButton } from 'components/elements/buttons';
import { ModalWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { Divider, Dropdown } from 'components/elements/others';
import { InputWithLabel } from 'components/elements/forms';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IGetTags } from 'pages/MyClients/interfaces/ProfilePanelData';

interface PendenciesSectionProps {
  clientId: string;
  allTags: IGetTags[];
  tagClient: IGetTags[];
  setTagClient: React.Dispatch<React.SetStateAction<IGetTags[]>>;
  onlyVizMode: boolean;
}

export interface ITagsClients {
  clientId?: string;
  tag?: string;
}

export const TagsClients: React.FC<PendenciesSectionProps> = ({
  clientId,
  allTags,
  tagClient,
  setTagClient,
  onlyVizMode,
}) => {
  const [showModalAddTagInput, seTshowModalAddTagInput] =
    useState<boolean>(false);
  const [showModalAddTag, setShowModalAddTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<ITagsClients>({} as ITagsClients);
  const [tags, setTags] = useState<ITagsClients[]>(
    tagClient.map(item => {
      return {
        clientId: item.clientId,
        tag: item.tag,
      };
    })
  );

  const handleChangeValue = (e: string) => {
    if (e.toLocaleLowerCase() === 'nova tag') {
      return seTshowModalAddTagInput(!showModalAddTagInput);
    }

    if (e.toLocaleLowerCase().trim() !== '') {
      handleAddTag({ clientId, tag: e });
    } else {
      toast.dark('Selecione uma tag');
    }
  };

  const handleChangeTagName = (e: string) => {
    setNewTag({ ...newTag, clientId, tag: e.toLocaleLowerCase() });
  };

  const handleAddNewTag = (e: FormEvent) => {
    e.preventDefault();
    handleAddTag(newTag);
    seTshowModalAddTagInput(false);
  };

  const handleAddTag = (e: ITagsClients) => {
    const existsTag = tags.filter(tag => tag.tag === e.tag);

    if (existsTag.length > 0) {
      return toast.dark('Tags não podem ser repetidas');
    }

    setTags([...tags, e]);
  };

  const handleRemoveTag = (removeTag: ITagsClients) => {
    setTags(tags.filter(tag => tag.tag !== removeTag.tag));
  };

  const handlePostAddTag = async (e: FormEvent) => {
    e.preventDefault();

    if (tags.length < 1) {
      const excludeTags = [{ clientId, tag: '' }];

      try {
        const response = await hubApiUser.postAddNewTags(excludeTags);
        setTagClient(response);
        setShowModalAddTag(!showModalAddTag);

        toast.dark('Tag(s) atualizadas com sucesso!');
      } catch (err) {
        toast.dark(
          'Ocorreu um erro ao adicionar a(s) tags! Tente novamente em alguns minutos ou entre em contato com o suporte.'
        );
      }
    } else {
      try {
        const response = await hubApiUser.postAddNewTags(tags);
        setTagClient(response);
        setShowModalAddTag(!showModalAddTag);

        toast.dark('Tag(s) atualizadas com sucesso!');
      } catch (err) {
        toast.dark(
          'Ocorreu um erro ao adicionar a(s) tags! Tente novamente em alguns minutos ou entre em contato com o suporte.'
        );
      }
    }
  };

  return (
    <Flex flexDir="column">
      <InfoTitle title="Tags" />
      <Flex pr="1" w="100%" justify="flex-end">
        {!onlyVizMode && (
          <SecondaryButton
            fontSize="xs"
            onClick={() => setShowModalAddTag(!showModalAddTag)}
          >
            Adicionar Tag
          </SecondaryButton>
        )}
      </Flex>
      <Flex
        flexWrap="wrap"
        w="100%"
        gridGap={2}
        mt="3"
        maxH="calc(100vh - 560px)"
        overflow="auto"
      >
        {tagClient.map(item => (
          <Flex
            p="1"
            px="1.5"
            gridGap={2}
            border="1px solid white"
            align="center"
            justify="center"
            borderRadius="4px"
          >
            <Text fontSize="smaller">
              {formatNameToCaptalize(item.tag ? item.tag : '')}
            </Text>
          </Flex>
        ))}
      </Flex>

      <ModalWrapper
        isOpen={showModalAddTag}
        onClose={() => setShowModalAddTag(!showModalAddTag)}
      >
        <BaseModalFormCard
          maxW={['90vw', '550px']}
          title="Adicionar Tag"
          isLoading={false}
          secondaryButtonText="Fechar"
          primaryButtonText="Salvar alterações"
          handleFormSubmit={handlePostAddTag}
          handleToggleModal={() => setShowModalAddTag(!showModalAddTag)}
          isModal
        >
          <Flex justify="space-between" flexDir="column" minH="100px">
            <Dropdown
              mb="3"
              placeholder="Selecione uma tag"
              onChange={e => handleChangeValue(e.target.value)}
            >
              {allTags.map((item, key) => (
                <option key={key} value={item.tag}>
                  {item}
                </option>
              ))}
              <option value="Nova Tag">+ Criar nova Tag</option>
            </Dropdown>

            <Divider mb="2" />

            <Text mb="2">Tags atuais:</Text>
            <Flex
              flexWrap="wrap"
              w="100%"
              gridGap={2}
              overflow="auto"
              maxH="80px"
            >
              {tags.map((item, index) => (
                <Flex
                  key={`${index}-${item}`}
                  p="1"
                  gridGap={2}
                  border="1px solid white"
                  align="center"
                  justify="center"
                  borderRadius="4px"
                >
                  <Text fontSize="smaller" isTruncated maxW="100px">
                    {formatNameToCaptalize(item.tag ? item.tag : '')}
                  </Text>
                  <Box>
                    {!onlyVizMode && (
                      <BiTrash
                        size={15}
                        color="#fff"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRemoveTag(item)}
                      />
                    )}
                  </Box>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showModalAddTagInput}
        onClose={() => seTshowModalAddTagInput(!showModalAddTagInput)}
      >
        <BaseModalFormCard
          maxW="500px"
          title="Nova tag"
          isLoading={false}
          secondaryButtonText="Fechar"
          primaryButtonText="Salvar "
          handleFormSubmit={handleAddNewTag}
          handleToggleModal={() =>
            seTshowModalAddTagInput(!showModalAddTagInput)
          }
          isModal
        >
          <InputWithLabel
            label="Digite a tag"
            name="tag"
            placeholder="Digite a nova tag"
            onChange={e =>
              handleChangeTagName(e.target.value.toLocaleLowerCase())
            }
          />
        </BaseModalFormCard>
      </ModalWrapper>
    </Flex>
  );
};
