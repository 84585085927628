import { Flex } from '@chakra-ui/react';
import { RiEdit2Fill } from 'react-icons/ri';
import { formatValue } from 'react-currency-input-field';
import { useSelector } from 'react-redux';

import { TableData, TableRow } from 'components/elements/table';
import { MiAaiCaptationData } from 'pages/Captation/interfaces';
import {
  checkDifferenceInDays,
  checkDifferenceInMonths,
} from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { IReduxState } from 'shared/interfaces';
import { formatValueForSI } from 'utils/formatValueForSI';

interface TableAAIMIProps {
  aaiList: MiAaiCaptationData;
  handleEmployeePoints: (employeeId: MiAaiCaptationData) => void;
  handleEmployeeLevel: (employeeId: MiAaiCaptationData) => void;
}

export const TableAAIMi: React.FC<TableAAIMIProps> = ({
  aaiList,
  handleEmployeePoints,
  handleEmployeeLevel,
}) => {
  const user: { position: string } = useSelector(
    (state: IReduxState) => state.user.profile
  );

  const getFormattedAaiTime = (initialDate: string): string => {
    const differenceInMonths = checkDifferenceInMonths(initialDate);

    if (differenceInMonths <= 0) {
      const differenceInDays = checkDifferenceInDays(initialDate);

      return differenceInDays <= 1 ? `1 dia` : `${differenceInDays} dias`;
    }

    if (differenceInMonths >= 12) {
      const years = ((differenceInMonths * 30) / 360).toFixed(1);

      return years === '1.0'
        ? `${years.slice(0, 1)} ano`
        : `${years.slice(2) === '0' ? years.slice(0, 1) : years} anos`;
    }

    return differenceInMonths === 1
      ? `${differenceInMonths} mês`
      : `${differenceInMonths} meses`;
  };

  const getFormattedCurrencyData = (pl: number): string => {
    const formattedPl = formatValueForSI(pl || 0);
    return formattedPl;
  };

  return (
    <>
      <TableRow key={aaiList.id}>
        <TableData>{formatNameToCaptalize(aaiList.name)}</TableData>
        <TableData>{getFormattedAaiTime(aaiList.created_at)}</TableData>
        <TableData>
          <Flex justifyContent="center" gridGap={2}>
            {aaiList.level}
            {user.position.toLowerCase() === 'master' && (
              <RiEdit2Fill
                size={16}
                cursor="pointer"
                onClick={() => handleEmployeeLevel(aaiList)}
              />
            )}
          </Flex>
        </TableData>
        <TableData>{aaiList.meetingsRealized}</TableData>
        <TableData>{aaiList.openAccounts}</TableData>
        <TableData>{getFormattedCurrencyData(aaiList.pl)}</TableData>
        <TableData>
          <Flex justifyContent="flex-end" gridGap={2}>
            {formatValue({ value: String(aaiList.points.toFixed(0)) })}

            {user.position.toLowerCase() === 'master' && (
              <RiEdit2Fill
                size={16}
                cursor="pointer"
                onClick={() => handleEmployeePoints(aaiList)}
              />
            )}
          </Flex>
        </TableData>
      </TableRow>
    </>
  );
};
