import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';

import { showToast } from 'utils';
import { ModalTitle } from 'components/elements/texts';
import { IBaseMeeting, ISearchLeadItem } from 'pages/Captation/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import {
  IEmployee,
  InputWithEmployeeDropdown,
} from 'components/customElements/InputWithDropdown';

interface IComponentProps {
  handleToggleModal: () => void;
  currentLead: ISearchLeadItem;
  leadMeetings?: IBaseMeeting[];
  leadType: string;
}

export const SendLeadToSHModal: React.FC<IComponentProps> = ({
  handleToggleModal,
  currentLead,
  leadMeetings,
  leadType,
}) => {
  const [shName, setShName] = useState<string>('');
  const [selectedSH, setSelectedSH] = useState<IEmployee>({} as IEmployee);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkLeadType = (type: string): string => {
    if (type === 'leadspublic' || type === 'online') return 'publics';
    if (type === 'leadstable') return 'tables';
    if (type === 'leadspj') return 'pj';
    if (type === 'leadsmanual') return 'employee';
    if (type === 'leadspersonal') return 'personal';

    return type;
  };

  const handleConfirmLeadTransfer = async () => {
    setIsLoading(true);
    if (selectedSH.id === undefined) {
      setIsLoading(false);
      showToast('Você precisa selecionar um responsável válido');
      return;
    }
    try {
      await hubApiCaptation.pathLeadsUpdate(checkLeadType(leadType), {
        id: currentLead.id,
        employeeId: selectedSH.id,
        status: 'ok',
        leadType: checkLeadType(leadType),
        frequency: '1',
      });

      if (leadMeetings && leadMeetings?.length > 0) {
        await hubApiCaptation.pathMetting({
          id: leadMeetings[0]?.id,
          leadId: leadMeetings[0]?.leadId,
          employeeId: selectedSH.id,
          employeeIdParticipant: leadMeetings[0]?.employeeIdParticipant,
          leadType: checkLeadType(leadMeetings[0]?.leadType),
          date: leadMeetings[0]?.date,
          status: 'returned',
          confirm: false,
        });
      }

      setIsLoading(false);
      showToast('Lead transferido com sucesso');
      handleToggleModal();
    } catch {
      setIsLoading(false);
      showToast(
        'Não foi possível transferir o lead. Tente novamente mais tarde'
      );
    }
  };

  return (
    <DefaultModalContentWrapper maxW="480px">
      <ModalTitle>Transferir lead para SH</ModalTitle>
      <Text color="white" mb="3">
        Clique em confirmar para enviar o lead para o SH{' '}
        <Text as="strong">{formatNameToCaptalize(selectedSH.name)}</Text>
      </Text>

      <Text mb="1.5" fontSize="smaller">
        Ou escolha um novo responsável:
      </Text>

      <InputWithEmployeeDropdown
        customTermToSearch={shName}
        setCustomTermToSearch={setShName}
        setEmployee={setSelectedSH}
        employeeType="SH"
        width="90%"
      />

      <BaseButtonGroup mt="5">
        <SecondaryButton onClick={handleToggleModal}>Cancelar</SecondaryButton>
        <PrimaryButton
          onClick={handleConfirmLeadTransfer}
          isLoading={isLoading}
        >
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
