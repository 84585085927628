import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalWrapper } from 'components/elements/wrappers';
import { Menu } from 'componentsV2/elements/menu';
import { Modal } from 'componentsV2/elements/modal';
import { useCreateClientMeeting } from 'hooksV2/useCreateClientMeeting';
import { useNavigation } from 'hooksV2/useNavigation';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { ClientType } from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { AddClientAportModal } from '../activities/components/addClientAportModal';
import { AddClientMeetingModal } from '../activities/components/addClientMeetingModal';
import { AddClientReminderModal } from '../activities/components/addClientReminderModal';
import { ClientDetailsContactFrequencyModal } from '../activities/components/contactFrequencyModal';
import { ClientDetailsAddTagModal } from '../tags/addTagModal';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsActions: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const isOpenAddAportModalFilter = useSimpleQueryFilter('isOpenAddAportModal');

  const isOpenAddReminderModalFilter = useSimpleQueryFilter(
    'isOpenAddReminderModal'
  );

  const isOpenAddMeetingModalFilter = useSimpleQueryFilter(
    'isOpenAddMeetingModal'
  );

  const isOpenContactFrequencyModalFilter = useSimpleQueryFilter(
    'isOpenContactFrequencyModal'
  );

  const isOpenAddTagModal = useSimpleQueryFilter('isOpenAddTagModal');

  const { mutateAsync: createMeeting, isLoading: isLoadingCreateMeeting } =
    useCreateClientMeeting({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
        await queryClient.invalidateQueries(['clientHistory', client.btgCode]);
      },
      onError: error => {
        handleErrorMessage(error);
      },
    });

  const handleCreateInstantMeeting = async () => {
    const meeting = await createMeeting({
      btgCode: client.btgCode,
      date: new Date().toISOString(),
    });

    redirect(`/v2/client/${client.btgCode}/meeting/${meeting.id}`);
  };

  const { mutateAsync: updateClient } = useUpdateClient({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
    },
  });

  const handleCheckbox = async () => {
    await updateClient({
      ...client,
      shouldCreateStuckMoneyPendency: !client.shouldCreateStuckMoneyPendency,
    });
  };

  return (
    <Flex w="100%" justifyContent="flex-end" gap={2}>
      <Flex gap={1}>
        <Checkbox
          isChecked={client.shouldCreateStuckMoneyPendency}
          size="sm"
          onChange={() => handleCheckbox()}
        />
        <Text color="rgba(70, 70, 70, 1)">
          Criar pendência de dinheiro parado
        </Text>
      </Flex>

      <SecondaryButton
        onClick={() => isOpenAddTagModal.handleChangeFilterValue('true')}
      >
        Adicionar Tag
      </SecondaryButton>

      <SecondaryButton
        onClick={() =>
          isOpenContactFrequencyModalFilter.handleChangeFilterValue('true')
        }
      >
        Frequência de Contato
      </SecondaryButton>
      <Menu.Root>
        <Menu.Button
          background="white"
          color="rgba(70, 70, 70, 1)"
          border="1px solid #1d63ddb2"
          fontWeight="semibold"
          transition="background-color .2s ease-out"
          _hover={{ bg: '#1d63ddd5', color: 'white' }}
        >
          <Text fontSize="sm" textAlign="center">
            Realizar Atividade
          </Text>
        </Menu.Button>
        <Menu.List>
          <Menu.Item
            onClick={() =>
              isOpenAddReminderModalFilter.handleChangeFilterValue('true')
            }
          >
            Criar Lembrete
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              isOpenAddMeetingModalFilter.handleChangeFilterValue('true')
            }
          >
            Agendar Reunião
          </Menu.Item>
        </Menu.List>
      </Menu.Root>
      <SecondaryButton
        onClick={handleCreateInstantMeeting}
        isLoading={isLoadingCreateMeeting}
      >
        Reunião Agora
      </SecondaryButton>
      <PrimaryButton
        onClick={() =>
          isOpenAddAportModalFilter.handleChangeFilterValue('true')
        }
      >
        Aporte
      </PrimaryButton>

      <ModalWrapper
        isOpen={isOpenAddTagModal.filterValue === 'true'}
        onClose={() => isOpenAddTagModal.handleDeleteParam()}
      >
        <ClientDetailsAddTagModal
          isOpenAddTagModalFilter={isOpenAddTagModal}
          client={client}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenContactFrequencyModalFilter.filterValue === 'true'}
        onClose={() => isOpenContactFrequencyModalFilter.handleDeleteParam()}
      >
        <ClientDetailsContactFrequencyModal
          client={client}
          isOpenContactFrequencyModalFilter={isOpenContactFrequencyModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddAportModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddAportModalFilter.handleDeleteParam()}
      >
        <AddClientAportModal
          client={client}
          isOpenAddAportModalFilter={isOpenAddAportModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddReminderModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddReminderModalFilter.handleDeleteParam()}
      >
        <AddClientReminderModal
          client={client}
          isOpenAddReminderModalFilter={isOpenAddReminderModalFilter}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenAddMeetingModalFilter.filterValue === 'true'}
        onClose={() => isOpenAddMeetingModalFilter.handleDeleteParam()}
      >
        <AddClientMeetingModal
          client={client}
          isOpenAddMeetingModalFilter={isOpenAddMeetingModalFilter}
        />
      </ModalWrapper>
    </Flex>
  );
};
