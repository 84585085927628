import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { ButtonGroup, Button, Stack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { PortifolioProps } from 'pages/products/interfaces';
import PortifolioRowValue from '../PortifolioRowValue';

interface PortifolioFormProps {
  handleToggleModal(): void;
  handleSubmit(formData: any): Promise<void>;
  handleSetNewWallet(newWallet: any): void;
  wallet?: PortifolioProps;
  label: string;
}

export const PortfolioForm: React.FC<PortifolioFormProps> = ({
  handleToggleModal,
  handleSubmit,
  handleSetNewWallet,
  wallet,
  label,
}) => {
  const [newInput, setNewInput] = useState(
    wallet
      ? wallet?.segments.map(item => {
          return {
            ...item,
          };
        })
      : []
  );

  const [name, setName] = useState(wallet?.name || '');

  const handleAddInput = () => {
    if (newInput.length !== 0) {
      const newWalletInput = newInput[newInput.length - 1].id + 1;
      const newWallet = {
        id: newWalletInput,
        name: '',
        percent: '',
      };

      setNewInput([...newInput, newWallet]);
    } else {
      setNewInput([
        {
          id: 1,
          name: '',
          percent: '',
        },
      ]);
    }
  };

  const handleRemoveInput = (removeInputID: number) => {
    const newInputArray = newInput.filter(port => port.id !== removeInputID);

    setNewInput([...newInputArray]);
  };

  const handleSetNameOfSegment = useCallback(
    async (id, value) => {
      setNewInput(
        newInput.map(item => {
          if (id === item.id) {
            return {
              ...item,
              name: value,
            };
          }
          return item;
        })
      );
    },
    [newInput]
  );

  const handleSetPercOfSegment = useCallback(
    async (id, value) => {
      try {
        if (Number(value) < 0 || Number(value) > 100) {
          toast.dark('A porcentagem deve ser entre 0 e 100');
        } else {
          setNewInput(
            newInput.map(item => {
              if (id === item.id) {
                return {
                  ...item,
                  percent: value,
                };
              }
              return item;
            })
          );
        }
      } catch (err) {
        toast.dark('A porcentagem so deve conter números');
      }
    },
    [newInput]
  );

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();
      const formData = {
        name,
        segments: newInput,
      };

      await handleSubmit(formData);
    },
    [newInput, handleSubmit, name]
  );

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper width={['95%', 'auto']}>
        <form>
          <ModalTitle>{label}</ModalTitle>
          <Stack spacing={4}>
            <InputWithLabel
              label="Nome da carteira"
              value={name}
              placeholder="Digite o Nome da Carteira"
              onChange={(e: any) => setName(e.target.value)}
            />

            {newInput.map((field, index) => (
              <PortifolioRowValue
                key={index}
                field={field}
                handleRemoveField={handleRemoveInput}
                handleSetNameOfSegment={handleSetNameOfSegment}
                handleSetPercOfSegment={handleSetPercOfSegment}
              />
            ))}

            <Button
              leftIcon={<AddIcon />}
              bg="white"
              variant="solid"
              border="1px solid #1d63ddb2"
              onClick={handleAddInput}
              _hover={{ background: '#f2f2f2' }}
            >
              Adicionar segmento
            </Button>

            <ButtonGroup justifyContent="flex-end" w="100%">
              <SecondaryButton onClick={handleToggleModal}>
                Cancelar
              </SecondaryButton>
              <PrimaryButton onClick={handleSubmitForm}>{label}</PrimaryButton>
            </ButtonGroup>
          </Stack>
        </form>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};
