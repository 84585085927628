import { AiOutlineBank } from 'react-icons/ai';
import { MdPersonOutline } from 'react-icons/md';

export const exchangeOptions = [
  {
    title: 'Pessoa Física',
    description: 'PF',
    value: 'physical',
    icon: MdPersonOutline,
  },
  {
    title: 'Pessoa Jurídica',
    description: 'PJ',
    value: 'legal',
    icon: AiOutlineBank,
  },
];

export const legalPersonOptions = [
  {
    title: 'Exportação',
    intention: 'exportacao',
  },
  {
    title: 'Importação',
    intention: 'importacao',
  },
  {
    title: 'Importação/Exportação',
    intention: 'exportacao/importacao',
  },
  {
    title: 'Serviços',
    intention: 'servicos',
  },
  {
    title: 'Hedge',
    intention: 'physical_hedge',
  },
  {
    title: 'NDF',
    intention: 'NDF',
  },
  {
    title: 'ACC/ACE/FINIMP',
    intention: 'ACC/ACE/FINIMP',
  },
  {
    title: 'Trava cambial',
    intention: 'trava_cambial',
  },
  {
    title: 'Outros',
    intention: 'others',
  },
];

export const physicalPersonOptions = [
  {
    title: 'Compra ou venda imóveis',
    intention: 'compra_venda_imoveis',
  },
  {
    title: 'Disponibilidade',
    intention: 'disponibilidade',
  },
  {
    title: 'Investimento no exterior',
    intention: 'investimento_exterior',
  },
  {
    title: 'Transferência de patrimônio',
    intention: 'transferencia_patrimonio',
  },
  {
    title: 'Papel moeda',
    intention: 'papel_moeda',
  },
  {
    title: 'Outros',
    intention: 'others',
  },
];

export const coinsOptions = [
  { title: 'Real', placeholder: 'Volume em reais' },
  { title: 'Dólar', placeholder: 'Volume em dolar' },
  { title: 'Libra', placeholder: 'Volume em libra' },
  { title: 'Euro', placeholder: 'Volume em euro' },
  { title: 'Outra', placeholder: 'Volume da moeda selecionada' },
];

export const banksOptions = [
  { title: 'Ourinvest', ewzPerc: '50', bankPerc: '50' },
  { title: 'Confidence', ewzPerc: '40', bankPerc: '60' },
  { title: 'BTG', ewzPerc: '50', bankPerc: '50' },
  { title: 'Frente Corretora', ewzPerc: '61', bankPerc: '39' },
];

export const resonsOptions = [
  { title: 'Cliente não estava ciente', value: 'customer_not_aware' },
  { title: 'Fechou com a concorrência', value: 'closed_with_competition' },
  { title: 'Outros', value: 'others' },
];

export const closeOrderOptions = [
  { title: 'Fechada', value: '' },
  { title: 'Cancelada', value: '' },
];
