import { Flex } from '@chakra-ui/react';
import { InfoBox } from 'componentsV2/elements/boxes/components/info';
import { useSalesHunterPoolContext } from 'pagesV2/SalesHunterPool/context/useSalesHunterPoolContext';
import { useEffect, useState } from 'react';
import { LeadActionStatusEnum } from 'sharedV2/types/leadAction';
import { LeadMundoInvestStatusEnum } from 'sharedV2/types/leadMundoInvest';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

type LeadsActivitiesInfomationType = {
  tasksFinished: number;
  meetingReturned: number;
  meetingsToConfirm: number;
  RNA: number;
};

export const SalesHunterPoolLeadsActivitiesNumber = (): JSX.Element => {
  const { leads } = useSalesHunterPoolContext();

  const [leadsActivitiesInfomation, setLeadsActivitiesInfomation] =
    useState<LeadsActivitiesInfomationType>({
      tasksFinished: 0,
      meetingReturned: 0,
      meetingsToConfirm: 0,
      RNA: 0,
    });

  const handleGetLeadsActivitiesInfomation = () => {
    if (!leads) {
      return;
    }

    let meetingReturned = 0;
    let meetingsToConfirm = 0;
    let RNA = 0;
    let tasksFinished = 0;

    for (const lead of leads) {
      if (lead.status === LeadMundoInvestStatusEnum.RETURNED) {
        RNA++;
      }

      if (lead.actions && lead.actions.length > 0) {
        for (const action of lead.actions) {
          if (
            action.status === LeadActionStatusEnum.REALIZADA ||
            action.status === LeadActionStatusEnum.NAO_ATENDEU
          ) {
            tasksFinished++;
          }
        }
      }

      if (lead.meeting) {
        if (lead.meeting.status === MeetingStatusEnum.CREATED) {
          meetingsToConfirm++;
        }

        if (lead.meeting.status === MeetingStatusEnum.CALLBACK) {
          meetingReturned++;
        }
      }
    }

    setLeadsActivitiesInfomation({
      RNA,
      meetingReturned,
      meetingsToConfirm,
      tasksFinished,
    });
  };

  useEffect(() => {
    handleGetLeadsActivitiesInfomation();
  }, [leads]);

  return (
    <Flex gap={2} h="40px">
      <InfoBox
        name="Tarefas realizadas"
        value={leadsActivitiesInfomation.tasksFinished}
      />
      <InfoBox
        name="Retornos"
        value={leadsActivitiesInfomation.meetingReturned}
      />
      <InfoBox
        name="Reuniões a confirmar"
        value={leadsActivitiesInfomation.meetingsToConfirm}
      />
      <InfoBox name="RNA" value={leadsActivitiesInfomation.RNA} />
    </Flex>
  );
};
