import styled from 'styled-components';

export const DropdownMenuContent = styled.div`
  .dropdown-button {
    button {
      min-width: 260px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0;
      padding: 8px 16px;
      font-size: var(--small-text);
      background: var(--gray-color-600);
      color: var(--white-color-300);
      &:focus {
        background: var(--gray-color-600);
      }
    }
    .dropdown-menu {
      background: var(--gray-color-600);
      overflow-y: auto;
      height: 200px;
      a {
        font-size: var(--small-text);
        color: var(--white-color-300);
        &:hover {
          background: none;
        }
      }
    }
  }
  .dropdown-button-sm {
    button {
      font-size: 1em;
      max-width: 100px;
      font-size: var(--smaller-text);
    }
    .dropdown-menu {
      a {
        font-size: var(--smaller-text);
      }
    }
  }
  .dropdown-button-md {
    button {
      min-width: 240px;
      font-size: var(--smaller-text);
    }
    .dropdown-menu {
      a {
        font-size: var(--smaller-text);
      }
    }
  }
`;
