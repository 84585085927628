import { ChangeEvent, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { periodValidator } from 'utils/periodValidator';
import { MainPageTitle } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import {
  DefaultCardWrapper,
  DefaultPageWrapper,
} from 'components/elements/wrappers';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IRAnkingSH } from 'pages/GenerationalVariables/interfaces';
import {
  DeclinesAndResultsDataset,
  BonificationControl,
  CurrentAaiPoints,
} from 'pages/GenerationalVariables/components/modules/generationalVariablesAAI';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

export const GenerationalVariablesMundoInvest: React.FC = () => {
  const [rankingData, setRankingData] = useState([] as IRAnkingSH[]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('2021-10-01');
  const [endDate, setEndDate] = useState('2021-11-01');
  const [periodFilter, setPeriodFilter] = useState({
    from: startDate,
    to: endDate,
  });
  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getRankingData();
    }
  }, [lastAuth]);

  useEffect(() => {
    handlePeriodFilter();
  }, [endDate]);

  const getRankingData = async () => {
    try {
      const response = await hubApiCaptation.getAAIRanking();

      setRankingData(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar as informações');
    }
  };

  const handleStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handlePeriodFilter = () => {
    if (!periodValidator(startDate, endDate)) {
      return;
    }

    setPeriodFilter({
      from: startDate,
      to: endDate,
    });
  };

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center" mb={1}>
        Variáveis Geracionais Mundo Invest - AAI
      </MainPageTitle>

      <Flex
        w="100%"
        justifyContent={'flex-end'}
        gridGap={[0, 2]}
        mb={2}
        flexDirection={['column', 'row']}
      >
        <Text alignSelf={'flex-end'}>Período:</Text>
        <DatePickerChackra
          style={{ width: 'fit-content', alignSelf: 'flex-end' }}
          handlePickDate={handleStartDate}
          value={startDate}
        />

        <Text alignSelf="flex-end">até</Text>
        <DatePickerChackra
          style={{ width: 'fit-content', alignSelf: 'flex-end' }}
          handlePickDate={handleEndDate}
          value={endDate}
        />
      </Flex>

      <DefaultCardWrapper
        flexDir={['column', 'column', 'column', 'row']}
        gridGap={6}
        w={['95vw', '100%']}
        bg="background.700"
        justifyContent="space-between"
        maxH={['auto', '700px']}
        mb={[10, 0]}
      >
        {isLoading ? (
          <Flex align="center" justify="center" w="100%" h="200px">
            <LoadingSpinner />
          </Flex>
        ) : (
          <>
            <DeclinesAndResultsDataset period={periodFilter} />

            <Flex flexDir="column" flex="2">
              <BonificationControl />
              <CurrentAaiPoints data={rankingData} />
            </Flex>
          </>
        )}
      </DefaultCardWrapper>
    </DefaultPageWrapper>
  );
};
