import { Dropdown } from 'components/elements/others';
import React from 'react';

import { InputWithLabel } from 'components/elements/forms';

import { Stack } from '@chakra-ui/react';
import { OverflowDiv, SectionTitleDivider } from '../styles';
import { position, salaryOption } from './data';

const EmployeeInfosEditable = ({
  employeeInfo,
  handleChangeValues,
  handleChangeAddressValues,
  handleChangeBankValues,
  fullPage,
  handleChangeDropDownValue,
  handleChangeDropDownSalaryOption,
}) => {
  return (
    <OverflowDiv fullPage={fullPage}>
      <Stack spacing="1">
        <InputWithLabel
          label="Nome"
          placeholder="Nome do colaborador"
          name="name"
          value={employeeInfo?.name}
          onChange={handleChangeValues}
        />

        <InputWithLabel
          label="E-mail"
          placeholder="E-mail do colaborador"
          name="email"
          value={employeeInfo?.email}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="CPF"
          placeholder="CPF do colaborador"
          name="cpf"
          value={employeeInfo?.cpf}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="CNPJ"
          placeholder="CNPJ do colaborador"
          name="cnpj"
          value={employeeInfo.cnpj ? employeeInfo.cnpj : ''}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="RG"
          placeholder="RG do colaborador"
          name="rg"
          value={employeeInfo?.rg}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Telefone"
          placeholder="Telefone do colaborador"
          name="phone"
          value={employeeInfo?.phone}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Nome da mãe"
          placeholder="Nome da Mãe"
          name="motherName"
          value={employeeInfo?.motherName}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Nome do pai"
          placeholder="Nome do pai"
          name="dadName"
          value={employeeInfo?.dadName}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Sexo"
          placeholder="Sexo"
          name="sex"
          value={employeeInfo?.sex}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Estado civil"
          placeholder="Estado civil do colaborador"
          name="maritalStatus"
          value={employeeInfo?.maritalStatus}
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Nível de educação"
          placeholder="Nível de educação do colaborador"
          name="educationLevel"
          value={employeeInfo?.educationLevel}
          onChange={handleChangeValues}
        />
      </Stack>
      <SectionTitleDivider>Endereço</SectionTitleDivider>

      <Stack spacing="1">
        <InputWithLabel
          label="CEP"
          placeholder="CEP do colaborador"
          name="cep"
          value={employeeInfo?.address?.cep}
          onChange={handleChangeAddressValues}
        />
        <InputWithLabel
          label="Rua"
          placeholder="Rua do colaborador"
          name="street"
          value={employeeInfo?.address?.street}
          onChange={handleChangeAddressValues}
        />
        <InputWithLabel
          label="Número"
          placeholder="Número do colaborador"
          name="number"
          value={employeeInfo?.address?.number}
          onChange={handleChangeAddressValues}
        />
        <InputWithLabel
          label="Bairro"
          placeholder="Bairro do colaborador"
          name="district"
          value={employeeInfo?.address?.district}
          onChange={handleChangeAddressValues}
        />
        <InputWithLabel
          label="Cidade"
          placeholder="Cidade do colaborador"
          name="city"
          value={employeeInfo?.address?.city}
          onChange={handleChangeAddressValues}
        />
        <InputWithLabel
          label="UF"
          placeholder="UF do colaborador"
          name="uf"
          value={employeeInfo?.address?.uf}
          onChange={handleChangeAddressValues}
        />
      </Stack>

      <SectionTitleDivider>Dados Bancários</SectionTitleDivider>

      <Stack spacing="1">
        <InputWithLabel
          label="Banco"
          placeholder="Banco do colaborador"
          name="bank"
          value={employeeInfo?.bank?.bank}
          onChange={handleChangeBankValues}
        />
        <InputWithLabel
          label="Agência"
          placeholder="Agência do colaborador"
          name="agency"
          value={employeeInfo?.bank?.agency}
          onChange={handleChangeBankValues}
        />
        <InputWithLabel
          label="Número da conta"
          placeholder="Número da conta do colaborador"
          name="accountNumber"
          value={employeeInfo?.bank?.accountNumber}
          onChange={handleChangeBankValues}
        />
      </Stack>
      <SectionTitleDivider>Contrato</SectionTitleDivider>

      <Dropdown
        placeholder="Selecione a posição do colaborador"
        onChange={e => handleChangeDropDownValue(e.target.value)}
        mb="2"
        h="6"
      >
        {position.map((item, key) => (
          <option key={key} value={item.value}>
            {item.title}
          </option>
        ))}
      </Dropdown>

      <Stack spacing="1">
        <InputWithLabel
          label="Cargo"
          placeholder="Cargo do colaborador"
          name="responsibility"
          onChange={handleChangeBankValues}
        />

        <InputWithLabel
          label="Tipo de contrato"
          placeholder="Tipo de contrato"
          name="contractType"
          onChange={handleChangeValues}
        />
        <InputWithLabel
          label="Salário"
          placeholder="Salário do colaborador"
          name="salary"
          onChange={handleChangeValues}
        />

        <Dropdown
          placeholder="Selecione o tipo de salário"
          onChange={e => handleChangeDropDownSalaryOption(e.target.value)}
          mb="1"
          mt="2"
          h="6"
        >
          {salaryOption.map((item, key) => (
            <option key={key} value={item.title}>
              {item.title}
            </option>
          ))}
        </Dropdown>
      </Stack>
    </OverflowDiv>
  );
};

export default EmployeeInfosEditable;
