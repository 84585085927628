import { Flex, Text, SimpleGrid, Stack } from '@chakra-ui/react';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { AlocationsChart } from 'pages/MyClients/components/others';

interface AlocationProps {
  clientProfile: string;
  investorType: string;
  chartValues: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
  showBackPageButton: boolean;
  setShowBackPageButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Alocation: React.FC<AlocationProps> = ({
  clientProfile,
  investorType,
  chartValues,
  showBackPageButton,
  setShowBackPageButton,
}) => {
  if (!showBackPageButton) setShowBackPageButton(true);

  return (
    <Flex flexDir="column" height={['60vh', 'calc(100vh - 224px)']}>
      <Flex>
        <InfoTitle title="Alocações do cliente" />

        {/* <Flex ml="20px">
          <Dropdown
            placeholder="Período"
            onChange={e => handlePeriodDropdown(e.target.value)}
            width="160px"
          >
            {periodOptions.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>
        </Flex> */}
      </Flex>

      <Stack spacing="2.5" mt="18px">
        <Text fontSize="sm" color="gray.500">
          Perfil:{' '}
          <Text
            as="strong"
            color="white.200"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {clientProfile?.toLowerCase()}
          </Text>
        </Text>
        <Text fontSize="sm" color="gray.500">
          Tipo de investidor:{' '}
          <Text
            as="strong"
            color="white.200"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {investorType}
          </Text>
        </Text>
      </Stack>

      <SimpleGrid columns={1} mt={['0px', '18px']}>
        {chartValues.length > 0 && <AlocationsChart data={chartValues} />}

        {/* <Flex flexDir="column" pl="32px">
          <InfoTitle title="Mesa de Renda Variável" />

          <Text mt="3">Em desenvolvimento...</Text>
          <Flex
            flexDir="column"
            justifyContent="space-between"
            w="60%"
            h="188px"
            border="1px"
            borderColor="background.200"
            p="10px"
            mt="18px"
          >
            <Box mb="18px">
              <Text fontSize="md" color="white.300">
                Risco 3
              </Text>
            </Box>

            <Box mb="auto">
              <Text fontSize="sm" color="white.300">
                Data de entrada: 13/02/2021
              </Text>
            </Box>

            <Box>
              <Text fontSize="sm" color="white.300">
                Valor: R$ 400.000,00
              </Text>
            </Box>


          </Flex>
        </Flex> */}
      </SimpleGrid>
    </Flex>
  );
};
