import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';

export const LeadsProspectPoolSkeletonLoading = (): JSX.Element => {
  const skeletonsLength = 10;

  const breakPoint = useCommonMediaQueries();

  const breaksPoints = {
    small: { columns: 4, maxHeight: '490px' },
    medium: { columns: 5, maxHeight: '680px' },
    big: { columns: 5, maxHeight: '680px' },
  };

  return (
    <PageWrapper paddingTop="1rem">
      <MainPageTitle>Leads Prospecção</MainPageTitle>

      <Flex flexDirection="column" gap={4} w="100%">
        <Flex w="100%" gap={2}>
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
        </Flex>

        <Flex
          w="100%"
          flexDirection="column"
          maxHeight={breaksPoints[breakPoint].maxHeight}
          overflowY="auto"
          className="thinScrollbar"
        >
          <Grid
            w="100%"
            templateColumns={`repeat(${breaksPoints[breakPoint].columns}, 1fr)`}
            gap={3}
          >
            {Array.from({ length: skeletonsLength }).map((_, index) => (
              <GridItem w="100%" overflow="hidden">
                <SkeletonBase
                  key={index}
                  h="320px"
                  w="400px"
                  borderRadius={3}
                />
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
