import React, { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { InvestmentFundProps } from 'pages/products/interfaces';
import { FundsCard } from 'pages/products/components/modules/investmentFunds/FundsCard';
import { ProductsPageLayout } from '../../../components/layouts';

interface RouteProps {
  type: string;
}

export const ListInvestmentFunds: React.FC = () => {
  const { type } = useParams() as RouteProps;
  const [funds, setFunds] = useState<InvestmentFundProps[]>([]);

  useEffect(() => {
    hubApiProducts
      .getInvertmentFundByType(type)
      .then(data => {
        setFunds(data);
      })
      .catch(() => {
        toast.dark(`Ocorreu um erro ao buscar os ${type}, tente novamente.`);
      });
  }, [type]);

  if (funds.length <= 0) {
    return (
      <ProductsPageLayout pageTitle={type}>
        <Text color="rgba(70, 70, 70, 1)">{`Não existem ${type} cadastrados.`}</Text>
      </ProductsPageLayout>
    );
  }
  return (
    <ProductsPageLayout pageTitle={type}>
      {funds.map(fund => (
        <FundsCard key={fund.id} fundsData={fund} />
      ))}
    </ProductsPageLayout>
  );
};
