import {
  SEND_EMPLOYEE_PERMISSIONS,
  DENIE_EMPLOYEE_FORM_BY_MANAGER,
  CONFIRM_EMPLOYEE_DATA_BY_MANAGER,
  RH_FEEDBACK_ABOUT_EMPLOYEE_FORM,
  RESET_EMPLOYEE_STATE,
  SEND_EMPLOYEE_CONTRACT,
} from './actionTypes';

export function sendEmployeePermissions(
  permissions,
  rvResponsibleId,
  employeePosition
) {
  return {
    type: SEND_EMPLOYEE_PERMISSIONS,
    payload: { permissions, rvResponsibleId, employeePosition },
  };
}

export function sendEmployeeContractData(contractData) {
  return {
    type: SEND_EMPLOYEE_CONTRACT,
    payload: contractData,
  };
}

export function managerConfirmEmployeeForm(employeeForm) {
  return {
    type: CONFIRM_EMPLOYEE_DATA_BY_MANAGER,
    payload: employeeForm,
  };
}

export function managerDenieEmployeeForm(employeeId) {
  return {
    type: DENIE_EMPLOYEE_FORM_BY_MANAGER,
    employeeId,
  };
}

export function resetEmployeeForm() {
  return {
    type: RESET_EMPLOYEE_STATE,
  };
}
