import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { CardInfo } from 'components/elements/others/CardInfo';
import { ModalTitle } from 'components/elements/texts';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import parser from 'html-react-parser';
import { IEvent } from 'pages/Events/interfaces/Event';
import { AiOutlineClose } from 'react-icons/ai';
import { formateDateAndTime } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface IEventModalProps {
  handleToggleModal: () => void;
  eventCardSelected: IEvent;
}

export const EventModal: React.FC<IEventModalProps> = ({
  handleToggleModal,
  eventCardSelected,
}) => {
  return (
    <DefaultModalContentWrapper width="500px" gap={3}>
      <Flex
        justifyContent="center"
        alignItems="baseline"
        height="10%"
        color="#fff"
      >
        <Flex position="absolute" top="10px" right="10px">
          <AiOutlineClose
            size={24}
            color="white"
            cursor="pointer"
            onClick={handleToggleModal}
          />
        </Flex>
        <ModalTitle>
          {formatNameToCaptalize(eventCardSelected.eventName)}
        </ModalTitle>
        <Text fontStyle="italic" color="gray.800" ml={2} textAlign="center">
          {`(${formatNameToCaptalize(eventCardSelected.eventType)})`}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="80%"
      >
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <CardInfo
            title="Data e horário"
            info={formateDateAndTime(eventCardSelected.eventDateAndHour)}
          />
          <Flex
            width="100%"
            height="150px"
            borderRadius="md"
            background="background.400"
            p={2}
            color="#fff"
            flexDirection="column"
          >
            <Text
              fontSize="md"
              color="gray.800"
              fontStyle="italic"
              pl="5px"
              height="20%"
            >
              Descrição:
            </Text>
            <Box hidth="100%" height="80%" overflowY="auto" pr={3}>
              <Text maxWidth="100%" maxHeight="100%" pl={5}>
                {parser(eventCardSelected.description)}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        height="10%"
      >
        {eventCardSelected.link && (
          <Link href={eventCardSelected.link} isExternal>
            <PrimaryButton>
              Acessar link do Evento <ExternalLinkIcon ml="3px" mt="-0.5" />
            </PrimaryButton>
          </Link>
        )}
      </Flex>
    </DefaultModalContentWrapper>
  );
};
