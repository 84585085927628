import styled from 'styled-components';

const TextAreaContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--white-color-100);
  margin-top: 8px;

  label {
    margin-bottom: 4px;
    color: var(--white-color-100);
    font-size: var(--smaller-text);
  }

  textarea {
    resize: vertical;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    font-size: var(--smaller-text);
    background: var(--gray-color-600);
    color: var(--white-color-300);
    width: 100%;
    height: 100%;
    min-height: 60px;
    padding: 8px 10px;
    border: 0;
    border-radius: 5px;
    font-size: var(--smaller-text);
    background: var(--gray-color-600);
    color: var(--white-color-100);

    &::placeholder {
      color: var(--gray-color-100);
    }
  }
`;

export default TextAreaContent;
