import { Divider, Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  client: ClientType;
  history:
    | {
        employeeName: string;
        startDate: string;
        endDate: string;
      }[]
    | undefined;
};

export const ClientDetailsEmployeesHistory: React.FC<PropsType> = ({
  client,
  history,
}): JSX.Element => {
  return (
    <Flex flexDirection="column" gap={2} p={2}>
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Histórico De Assessores
      </Text>

      <Divider />

      <Flex
        flexDirection="column"
        gap={2}
        overflowY="auto"
        className="thinScrollbar"
      >
        {history &&
          history.map((item, index) => (
            <Box.Opaque key={index} p={2}>
              <Text fontSize="sm" color="#999999">
                De {DateUtils.format(item.startDate, 'MMM/yy')} Até{' '}
                {DateUtils.format(item.endDate, 'MMM/yy')}
              </Text>
              <Text color="rgba(70, 70, 70, 1)" isTruncated maxW="250px">
                {TextUtils.toCapitalize(item.employeeName)}
              </Text>
            </Box.Opaque>
          ))}
      </Flex>
    </Flex>
  );
};
