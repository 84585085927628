import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { RiSendPlane2Line } from 'react-icons/ri';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ContentSearchLeadsProps extends FlexProps {
  name: string;
  emails?: string;

  phone?: number;
}

export const ContentSearchLeads: React.FC<ContentSearchLeadsProps> = ({
  name,
  emails,
  phone,
  ...rest
}) => {
  return (
    <Flex
      fontSize="sm"
      borderBottom="1px solid gray"
      align="center"
      justify="space-between"
      _hover={{
        background: 'background.500',
        cursor: 'pointer',
      }}
      transition="ease-in-out 0.1s"
      py="3"
      {...rest}
    >
      <Flex h="100%" flexDir="column" justify="center">
        <Flex mb="0.5" px="3">
          <Text w="50px" fontWeight="semibold">
            Nome -
          </Text>
          <Text maxW="180px" isTruncated>
            {formatNameToCaptalize(name)}
          </Text>
        </Flex>

        <Flex mb="0.5" px="3">
          <Text w="50px" fontWeight="semibold">
            Email -
          </Text>
          <Text maxW="200px" isTruncated>
            {emails}
          </Text>
        </Flex>

        <Flex px="3">
          <Text w="66px" fontWeight="semibold">
            Telefone -
          </Text>
          <Text maxW="132px" isTruncated>
            {phone}
          </Text>
        </Flex>
      </Flex>
      <RiSendPlane2Line size={16} color="white" />
    </Flex>
  );
};
