import { Flex, FlexProps } from '@chakra-ui/react';

export const BoxDefault: React.FC<FlexProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <Flex
      paddingX="1rem"
      paddingY="0.25rem"
      bgColor="#F5F5F5"
      borderRadius="5px"
      color="#464646"
      {...rest}
    >
      {children}
    </Flex>
  );
};
