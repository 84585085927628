import { Flex, Tooltip, Box } from '@chakra-ui/react';
import { TableData, TableRow } from 'components/elements/table';
import formatValue from 'utils/formatValue';

interface OrdersRowProps {
  order: {
    asset: string;
    btgClient: string;
    btgResponsible: string;
    category: string;
    codCNPJ: string;
    commission: string;
    created_at: string;
    date: string;
    final: string;
    id: string;
    nameClient: string;
    nameResponsible: string;
    net: string;
    product: string;
    revenueGross: string;
    revenueLiq: string;
    updated_at: string;
  };
}

export const OrdersRow: React.FC<OrdersRowProps> = ({ order }) => {
  return (
    <>
      <TableRow>
        <TableData maxW="150px" isTruncated>
          <Tooltip
            label={order.nameResponsible}
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom"
          >
            <Flex w="100%" align="center" justify="center">
              <Box maxW="150px" isTruncated>
                {order.nameResponsible}
              </Box>
            </Flex>
          </Tooltip>
        </TableData>

        <TableData minW="150px">{order.btgClient}</TableData>

        <TableData maxW="150px" isTruncated>
          <Tooltip
            label={order.nameClient}
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom"
          >
            <Flex w="100%" align="center" justify="center">
              <Box isTruncated>{order.nameClient}</Box>
            </Flex>
          </Tooltip>
        </TableData>

        <TableData maxW="150px" isTruncated>
          <Tooltip
            label={order.product}
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom"
          >
            <Flex w="100%" align="center" justify="center">
              <Box isTruncated>{order.product}</Box>
            </Flex>
          </Tooltip>
        </TableData>

        <TableData maxW="150px" isTruncated>
          <Tooltip
            label={order.asset}
            cursor="pointer"
            color="blackAlpha.900"
            placement="bottom"
          >
            <Flex w="100%" align="center" justify="center">
              <Box isTruncated>{order.asset}</Box>
            </Flex>
          </Tooltip>
        </TableData>

        <TableData minW="150px" isTruncated>
          <Flex w="100%" align="center" justify="center">
            <Box isTruncated>{formatValue(order.commission)}</Box>
          </Flex>
        </TableData>

        <TableData isTruncated>
          <Flex w="100%" align="center" justify="center">
            <Box isTruncated>{formatValue(order.revenueLiq)}</Box>
          </Flex>
        </TableData>

        <TableData>{formatValue(order.net)}</TableData>
        <TableData minW="150px">{formatValue(order.final)}</TableData>
      </TableRow>
    </>
  );
};
