import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { PageWrapper } from 'components/elements/wrappers/PageWrapper';
import { SecondaryButton } from 'components/elements/buttons/SecondaryButton';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { TableBase } from 'components/elements/table';
import { errorHandler } from 'utils/errorHandler';
import { advancedCustumersRvHeader } from './data';
import ActiveCustomersSharesTableRow from '../../components/modules/ActiveCustomersShares/ActiveCustomersSharesTableRow';

const ActiveCustomersShares: React.FC = () => {
  const [infosFounds, setInfosFounds] = useState({
    data: [],
  });

  useEffect(() => {
    const getAllClients = async () => {
      hubApiRv
        .listAllClientesCustumersRv('acoes')
        .then(response => setInfosFounds(response))
        .catch(error => errorHandler(error));
    };

    getAllClients();
  }, []);

  const handleExtractTable = async () => {
    await hubApiRv
      .listAllClientesCustumersRv('acoes')
      .then(response => window.location.assign(`${response.link}`))
      .catch(error => errorHandler(error));
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" py="48px">
      <BackPageButton />
      <MainPageTitle textAlign="center">Clientes ativos Ações</MainPageTitle>

      <Flex flexDir="column" width="100%" maxW="1080px">
        <SecondaryButton
          mb="2"
          alignSelf="flex-end"
          onClick={handleExtractTable}
        >
          Extrair Tabela
        </SecondaryButton>
        <TableBase
          overflowY="auto"
          maxH="calc(100vh - 260px)"
          width="100%"
          headData={advancedCustumersRvHeader}
        >
          {infosFounds.data.map((infosFound, index) => (
            <ActiveCustomersSharesTableRow
              key={index}
              infosFound={infosFound}
            />
          ))}
        </TableBase>
      </Flex>
    </PageWrapper>
  );
};

export default ActiveCustomersShares;
