/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils/errorHandler';
import { useCaptationMeetingsContext } from 'hooks';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';

import { IReduxState } from 'shared/interfaces';
import { useSelector } from 'react-redux';
import {
  interestMeeting,
  interestOptions,
  shOptions,
  tooMuchContactAttemptsOptions,
} from './data';

interface IComponentProps {
  id?: string;
  leadId?: string;
  handleAddTextAreaQuestion?: () => Promise<void>;
  leadType:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'tables'
    | 'publics'
    | 'leadspublic'
    | 'employee'
    | 'declined'
    | 'leadspurchased'
    | 'pj'
    | 'mundoInvest';
  isMeeting?: boolean;
  segment?:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'pipe';
  handleShowModal: () => void;
  handleNextLead: () => void;
  handleChangeLeadMenu?: (
    leadType: 'leadstable' | 'leadspublic' | 'leadsmanual' | 'leadspj',
    title: string,
    type: string
  ) => void;
  handleRemoveMinedLeadById?: (leadId: string) => void;
  expiredLead?: boolean;
  numberOfMeetings?: number;
  meetingStatus?: string | null;
  handleRemoveListLeadById?: (leadId: string) => void;
  tooMuchContactAttempts?: boolean;
  employeeId?: string;
  updateTotalNumOfLeadsInPipe?: () => void;
  handleNextLeadFromPool?: (
    action: 'decline' | 'purchase' | 'no_answer',
    declinedReason?: string
  ) => void;
}

export const DeclineOffer: React.FC<IComponentProps> = ({
  id,
  leadType,
  isMeeting,
  leadId,
  handleShowModal,
  handleNextLead,
  handleAddTextAreaQuestion,
  segment,
  updateTotalNumOfLeadsInPipe,
  handleChangeLeadMenu = () => {},
  handleRemoveMinedLeadById,
  meetingStatus,
  numberOfMeetings,
  handleRemoveListLeadById,
  tooMuchContactAttempts,
  employeeId,
  handleNextLeadFromPool,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState('');

  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (selectedInterest === '') {
      setIsLoading(false);
      toast.dark('Você deve escolher uma opção antes de declinar a oferta');
      return;
    }

    try {
      if (
        leadType.toLowerCase() === 'leadspublic' ||
        leadType.toLowerCase() === 'online' ||
        leadType.toLowerCase() === 'publics' ||
        leadType === 'mundoInvest'
      ) {
        await hubApiCaptation.pathLeadsUpdate('leadspublic', {
          id: isMeeting ? leadId : id,
          status: 'finalizado',
          interestLead: selectedInterest,
          isFromPoolMundoInvest: !!handleNextLeadFromPool,
          numberOfMeetings,
          meetingStatus,
        });
      } else {
        await hubApiCaptation.pathLeadsUpdate(leadType, {
          id: isMeeting ? leadId : id,
          status: 'finalizado',
          interest: selectedInterest,
        });
      }

      if (isMeeting) {
        await hubApiCaptation.pathMetting({
          leadId,
          id,
          employeeId,
          employeeIdParticipant: user.id,
          type: '',
          local: '',
          leadType,
          complement: '',
          date: new Date(),
          status: 'declined',
          feedback: selectedInterest,
        });
      }

      const dataToActionsHistory = {
        employeeId: user.id,
        leadId: leadId || id || '',
        leadType: leadType === 'mundoInvest' ? 'publics' : leadType,
        declined: true,
      };

      await hubApiCaptation.patchEmployeeActionsHistory(dataToActionsHistory);

      localStorage.removeItem(leadId || '');

      if (
        leadType.toLowerCase() === 'leadstable' ||
        leadType.toLowerCase() === 'tables'
      ) {
        handleChangeLeadMenu('leadstable', 'Leads Lista', 'LeadsList');
      } else if (leadType.toLowerCase() === 'leadsmanual') {
        handleChangeLeadMenu('leadsmanual', 'Leads Manuais', 'LeadsIndirect');
      } else if (
        (leadType as any) === 'leadstable' ||
        (leadType as any) === 'tables' ||
        (leadType as any) === 'leadspj' ||
        (leadType as any) === 'pj'
      ) {
        handleNextLead();
      }

      const isUpdate = true;

      // await handleGetSpecificLeadTypeArr(segment || 'account', isUpdate);

      toast.dark('Declínio realizado com sucesso');

      if (updateTotalNumOfLeadsInPipe) updateTotalNumOfLeadsInPipe();

      if (handleRemoveMinedLeadById && leadId) {
        handleRemoveMinedLeadById(leadId);
      }
      if (handleRemoveListLeadById && leadId) {
        handleRemoveListLeadById(leadId);
      }

      handleShowModal();
      if (handleAddTextAreaQuestion) handleAddTextAreaQuestion();
      if (handleNextLeadFromPool)
        handleNextLeadFromPool('decline', selectedInterest);
    } catch (err) {
      errorHandler(err);
      toast.dark(
        'Não foi possível realizar o declínio, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      maxW="490px"
      title="Declínio de oferta"
      isLoading={isLoading}
      handleToggleModal={handleShowModal}
      handleFormSubmit={handleFormSubmit}
      isModal
    >
      <Dropdown
        placeholder="Escolha uma das opções"
        my="1"
        onChange={e => setSelectedInterest(e.target.value)}
      >
        {!isMeeting &&
          leadType !== 'leadspublic' &&
          !tooMuchContactAttempts &&
          interestOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          ))}

        {isMeeting &&
          leadType !== 'leadspublic' &&
          !tooMuchContactAttempts &&
          interestMeeting.map((item, index) => (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          ))}

        {isMeeting &&
          leadType !== 'leadspublic' &&
          tooMuchContactAttempts &&
          tooMuchContactAttemptsOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          ))}

        {!isMeeting &&
          leadType !== 'leadspublic' &&
          tooMuchContactAttempts &&
          tooMuchContactAttemptsOptions.map((item, index) => (
            <option key={index} value={item.value}>
              {item.title}
            </option>
          ))}

        {leadType === 'leadspublic' &&
          shOptions.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            );
          })}
      </Dropdown>
    </BaseModalFormCard>
  );
};
