import React, { useEffect, useState } from 'react';

import { PageWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';
import { BackPageButton } from 'components/elements/buttons';
import { Box } from '@chakra-ui/react';
import { listUserOrders } from 'pages/IP/utils';
import { ipSegments } from 'pages/IP/data';
import { IPContextProvider } from 'pages/IP/cotext/IPContext';
import Board from '../../components/modules/kanbanBase/Board';

import { realStateColumns } from './data';

export const RealStateKanban: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(realStateColumns);

  useEffect(() => {
    async function listOrders() {
      await listUserOrders(ipSegments.cred, columns);
      setIsLoading(false);
    }

    listOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <IPContextProvider columns={columns}>
          <Box overflowY="auto">
            <Board
              boardTitle="CRM - Alavancagem Patrimonial"
              boardColumns={columns}
              setBoardColumns={setColumns}
            />
          </Box>
        </IPContextProvider>
      )}
    </PageWrapper>
  );
};
