import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { showToast } from 'utils';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  checkIfDateIsPast,
  formatDateToISO,
  formateDateAndTime,
} from 'utils/formatDate';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { queryClient } from 'App';
import {
  IEmployee,
  InputWithEmployeeDropdown,
} from 'components/customElements/InputWithDropdown';

interface IComponentProps {
  refetchLeadsFound: () => void;
  handleToggleModal: () => void;
  leadDetails: LeadDetailsProps;
  leadType: string;
}

export const SendLeadToAAIModal: React.FC<IComponentProps> = ({
  handleToggleModal,
  leadDetails,
  refetchLeadsFound,
  leadType,
}) => {
  const [scheduling, setScheduling] = useState(
    leadDetails.meetings?.length > 0 ? leadDetails.meetings[0].date : ''
  );
  const [aaiName, setAaiName] = useState<string>('');
  const [selectedAai, setSelectedAai] = useState<IEmployee>({
    id: '',
    name: '',
  } as IEmployee);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeMeetingDate: any = (value: string) => {
    const parsed = formatDateToISO(value);
    setScheduling(parsed);
  };

  const checkLeadType = (type: string): string => {
    if (type === 'leadspublic' || type === 'online') return 'publics';

    if (type === 'leadstable') return 'tables';

    if (type === 'leadspj') return 'pj';

    if (type === 'leadsmanual') return 'employee';

    if (type === 'leadspersonal') return 'personal';

    return type;
  };

  const handleTransferLeadForAii = async () => {
    setIsLoading(true);
    const dateIsPast = checkIfDateIsPast(scheduling);
    if (dateIsPast || scheduling.trim() === '') {
      setIsLoading(false);
      showToast('Escolha uma data futura para marcar uma reunião.');
      return;
    }

    if (selectedAai.id.trim() === '') {
      setIsLoading(false);
      showToast('Selecione um AAI válido para receber o lead');
      return;
    }

    if (leadDetails?.meetings?.length === 0 || !leadDetails.meetings) {
      const newMeeting = {
        leadId: leadDetails.id,
        employeeId: leadDetails.employeeId,
        employeeIdParticipant: selectedAai.id,
        leadType,
        date: scheduling,
        type: 'meeting',
        local: 'Ligação',
        status: 'suitability',
        feedback: '',
        complement: '',
        contactAttempts: '0',
      };

      await hubApiCaptation.postMetting(newMeeting);
    } else {
      const lastMeeting = leadDetails.meetings[0];
      const updatedMeeting = {
        id: lastMeeting.id,
        leadId: lastMeeting.leadId,
        employeeId: lastMeeting.employeeId,
        employeeIdParticipant: selectedAai.id,
        leadType: lastMeeting.leadType,
        date: scheduling,
        type: 'meeting',
        local: 'Ligação',
        status: 'suitability',
        feedback: lastMeeting.feedback,
        complement: lastMeeting.complement,
        confirm: false,
        contactAttempts: lastMeeting.contactAttempts,
      };
      await hubApiCaptation.pathMetting(updatedMeeting);
    }

    await hubApiCaptation.pathLeadsUpdate(checkLeadType(leadType), {
      id: leadDetails.id,
      employeeId: selectedAai.id,
      status: 'finalizado',
    });

    refetchLeadsFound();
    await queryClient.invalidateQueries(['employeeParticipant']);
    await queryClient.invalidateQueries(['leadDetails']);

    setIsLoading(false);
    showToast('Reunião marcada com sucesso para o lead');
    handleToggleModal();
  };

  return (
    <DefaultModalContentWrapper maxW="480px">
      <ModalTitle>Transferir lead para AAI</ModalTitle>
      {scheduling.trim() === '' && (
        <Text color="white" mb="3.5">
          Esse lead ainda não possui nenhuma reunião. Selecione um responsável e
          uma data.
        </Text>
      )}

      {scheduling.trim() !== '' && (
        <Text color="white" mb="3.5">
          Esse lead será enviado para o suitability do AAI{' '}
          <Text as="strong">
            {selectedAai.name.trim() !== '' ||
            selectedAai.name.trim() !== 'Erro'
              ? formatNameToCaptalize(selectedAai.name)
              : ''}
          </Text>
          , com uma reunião marcada para{' '}
          <Text as="strong">
            {scheduling.trim() !== '' ? formateDateAndTime(scheduling) : ''}
          </Text>
        </Text>
      )}

      <Text mb="1.5" fontSize="smaller">
        Escolha um novo responsável:
      </Text>
      <InputWithEmployeeDropdown
        customTermToSearch={aaiName}
        setCustomTermToSearch={setAaiName}
        setEmployee={setSelectedAai}
        employeeType="AAI"
        width="90%"
      />

      <Text mb="1.5" mt="5" fontSize="smaller">
        Ou mude a data da reunião:
      </Text>
      <DateAndHourPicker onChange={handleChangeMeetingDate} />

      <BaseButtonGroup mt="5">
        <SecondaryButton onClick={handleToggleModal}>Cancelar</SecondaryButton>
        <PrimaryButton onClick={handleTransferLeadForAii} isLoading={isLoading}>
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
