import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { LoadingSpinner } from 'components/elements/others';

export const CheckingToken = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="lg" color=" white" mb="18px">
        Verificando a Validade do Link
      </Text>
      <LoadingSpinner />
    </Flex>
  );
};
