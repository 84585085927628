import { Text, Flex } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import {
  IEmployeeSelected,
  IUsersSearh,
} from 'pages/GenerationalVariables/interfaces';

interface UsersSelectedProps {
  employee: IEmployeeSelected | IUsersSearh;
  handleTakeOfUser?: (employeeToRemove: IEmployeeSelected) => void;
}

export const UsersSelected: React.FC<UsersSelectedProps> = ({
  employee,
  handleTakeOfUser,
}) => {
  return (
    <Flex justify="space-between" px="2" py="1" borderRadius="3" bg="white">
      <Text color="rgba(70, 70, 70, 1)">
        {formatToCapitalized(`${employee.name}`, {
          trimTrailingWhiteSpaces: false,
        })}
      </Text>

      {/* <Tooltip
        hasArrow
        label="Tirar usuário do grupo"
        placement="top"
        bg="background.200"
        cursor="pointer"
      >
        <Box cursor="pointer">
          <AiOutlineDelete
            color="rgba(70, 70, 70, 1)"
            size={20}
            onClick={() => handleTakeOfUser(employee)}
          />
        </Box>
      </Tooltip> */}
    </Flex>
  );
};
