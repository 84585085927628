import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export enum SalesHunterPoolFilterDropdownEnum {
  ALL = 'all',
  MEETING = 'meeting',
  CALLBACK = 'callback',
  RETURNED = 'RETURNED',
  INHERIT = 'inherit',
  DAY = 'day',
}

type UseShPoolFilteredLeadsResponseType = {
  handleChangeFilterDropdownValue: (
    value: SalesHunterPoolFilterDropdownEnum
  ) => void;
  handleSearchLeadByPhoneOrName: (value: string) => void;
  searchLeadByPhoneOrNameValue: string;
  filteredLeads: LeadMundoInvestType[] | undefined;
  filterDropdownValue: SalesHunterPoolFilterDropdownEnum;
};

export const useShPoolFilteredLeads = (
  leads: LeadMundoInvestType[] | undefined
): UseShPoolFilteredLeadsResponseType => {
  const {
    filterValue: filterDropdownValue,
    handleChangeFilterValue: handleChangeFilterDropdownValue,
  } = useSimpleQueryFilter('filterDropdownValue');

  const {
    filterValue: filterByLeadPhoneOrNameValue,
    handleChangeFilterValue: handleSearchLeadByPhoneOrName,
  } = useSimpleQueryFilter('filterByLeadPhoneOrNameValue');

  const filterLeadsByDropdownValue = (
    filterValue: SalesHunterPoolFilterDropdownEnum
  ) => {
    switch (filterValue) {
      case SalesHunterPoolFilterDropdownEnum.ALL:
        return leads;
      case SalesHunterPoolFilterDropdownEnum.MEETING:
      case SalesHunterPoolFilterDropdownEnum.CALLBACK:
        return leads?.filter(lead => lead.meeting?.type === filterValue);
      case SalesHunterPoolFilterDropdownEnum.RETURNED:
        return leads?.filter(
          lead => lead.meeting?.status === MeetingStatusEnum.RETURNED
        );
      case SalesHunterPoolFilterDropdownEnum.INHERIT:
        return leads?.filter(lead => !lead.meeting);
      case SalesHunterPoolFilterDropdownEnum.DAY: {
        const today = new Date().toISOString().split('T')[0];
        return leads?.filter(lead =>
          lead.meeting?.date?.toString().includes(today)
        );
      }
      default:
        return leads;
    }
  };

  const filterLeadsByNameOrPhone = (
    filteredLeads: LeadMundoInvestType[] | undefined
  ) => {
    if (!filterByLeadPhoneOrNameValue) return filteredLeads;
    return filteredLeads?.filter(
      lead =>
        lead.name.toLowerCase().includes(filterByLeadPhoneOrNameValue) ||
        lead.phone.includes(filterByLeadPhoneOrNameValue)
    );
  };

  let filteredLeads: LeadMundoInvestType[] | undefined;

  filteredLeads = filterLeadsByDropdownValue(
    filterDropdownValue as SalesHunterPoolFilterDropdownEnum
  );

  filteredLeads = filterLeadsByNameOrPhone(filteredLeads);

  const leadsThatHaveMeetingCreated: LeadMundoInvestType[] = [];
  const leadsThatHaveMeetingCallback: LeadMundoInvestType[] = [];
  const leadsThatHaveMeetingRNA: LeadMundoInvestType[] = [];

  filteredLeads?.forEach(lead => {
    if (lead.meeting?.status === MeetingStatusEnum.CREATED)
      leadsThatHaveMeetingCreated.push(lead);

    if (lead.meeting?.status === MeetingStatusEnum.RETURNED)
      leadsThatHaveMeetingRNA.push(lead);

    if (
      (lead.meeting &&
        lead.meeting?.status.toLowerCase() === MeetingStatusEnum.CALLBACK) ||
      lead.meeting?.status === MeetingStatusEnum.CALLBACK_SH
    )
      leadsThatHaveMeetingCallback.push(lead);
  });

  // Order by the oldest to the newer meeting
  leadsThatHaveMeetingCreated.sort((a, b) => {
    return (
      new Date(a?.meeting?.date as Date)?.getTime() -
      new Date(b?.meeting?.date as Date)?.getTime()
    );
  });

  const allLeadsWithSomeAction = [
    ...leadsThatHaveMeetingCreated,
    ...leadsThatHaveMeetingRNA,
    ...leadsThatHaveMeetingCallback,
  ];

  const result = filteredLeads?.filter(
    item => !allLeadsWithSomeAction.some(arr => arr.id === item.id)
  );

  filteredLeads = result
    ? [...allLeadsWithSomeAction, ...result]
    : [...allLeadsWithSomeAction];

  filteredLeads = filteredLeads?.filter(lead => lead.frequency <= 5);

  return {
    handleChangeFilterDropdownValue,
    handleSearchLeadByPhoneOrName,
    filteredLeads,
    searchLeadByPhoneOrNameValue: filterByLeadPhoneOrNameValue,
    filterDropdownValue:
      filterDropdownValue as SalesHunterPoolFilterDropdownEnum,
  };
};
