import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe o nome.'),
  phone: Yup.string().required('Informe o telefone'),
  email: Yup.string().required('Informe o email'),
  position: Yup.string().required('Função é obrigatório'),
});

export default schema;
