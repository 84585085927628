import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { DefaultCardWrapper } from 'components/elements/wrappers';

interface IPortfolioItem {
  name: string;
  percent: string | number;
}

interface IPortfolio {
  id: string;
  name: string;
  segments: IPortfolioItem[];
}

interface ComponentProps {
  portfolio: IPortfolio | undefined;
}

export const PortfolioExample: React.FC<ComponentProps> = ({ portfolio }) => {
  return (
    <DefaultCardWrapper width="100%">
      <Flex width="100%" flexDir="column" color="rgba(70, 70, 70, 1)">
        {portfolio && (
          <Text
            fontSize={{ base: 'md', lg: 'lg' }}
            alignSelf="center"
            marginBottom="4"
            borderRadius="3px"
            fontWeight="bold"
            p="4px 14px"
          >
            {portfolio.name}
          </Text>
        )}

        <Flex
          width="100%"
          overflowY="auto"
          maxHeight="calc(100vh - 380px)"
          flexDir="column"
          padding="0 4px"
          gridGap="2"
        >
          {portfolio &&
            portfolio?.segments?.map((item, index: number) => (
              <Flex
                key={index}
                width="100%"
                justifyContent="space-between"
                padding="12px 12px"
                borderRadius="md"
                background="#f2f2f2"
              >
                <Text>{item.name}</Text>
                <Text>{item.percent}%</Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </DefaultCardWrapper>
  );
};
