import { Text } from '@chakra-ui/react';
import history from 'services/history';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface AccessSharedContentModalProps {
  contentId: string;
  idTask: string;
  handleRemoveItem: (
    idTask: string,
    clientId?: string,
    isSharedContent?: boolean,
    additionalInformation?: string
  ) => void;
  handleCloseModal: () => void;
  message?: string;
}

export const AccessSharedContentModal: React.FC<AccessSharedContentModalProps> =
  ({ contentId, idTask, handleCloseModal, handleRemoveItem, message }) => {
    const sendToSharedContent = () => {
      history.push(`/contents/content/${contentId}`);
    };

    return (
      <DefaultModalContentWrapper maxW="410px">
        <ModalTitle>Deseja acessar o conteúdo?</ModalTitle>

        <Text>
          {message || 'Ao acessar o conteúdo o card será removido de sua lista'}
        </Text>

        <BaseButtonGroup>
          <SecondaryButton onClick={handleCloseModal}>Cancelar</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleRemoveItem(idTask, undefined, true, undefined);
              sendToSharedContent();
            }}
          >
            Ir para o conteúdo
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultModalContentWrapper>
    );
  };
