import { Flex, Text } from '@chakra-ui/react';
import { IOrderFile } from 'pages/IP/components/modules/kanbanBase/BoardOrderInfo';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { DownloadSimple } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';

interface IProps {
  client: ClientInfosType;
}

export const ClientFilesSection: React.FC<IProps> = ({ client }) => {
  const [clientFiles, setClientFiles] = useState<IOrderFile[]>([]);

  const handleGetFiles = async () => {
    try {
      const files = await hubApiIp.getFilesByClientId(client.contactId);
      setClientFiles(files);
    } catch (err) {
      toast.dark(
        'Nao foi possivel pegar os arquivos da ordem. Tente novamente mais tarde.'
      );
    }
  };

  useEffect(() => {
    handleGetFiles();
  }, []);

  return (
    <Flex flexDir="column">
      <InfoTitle title="Arquivos do Cliente" />
      <Flex
        w="100%"
        gridGap={2}
        mt="3"
        maxH="calc(100vh - 660px)"
        overflowY="auto"
        flexDirection="column"
        className="slimScrollbar"
      >
        {clientFiles.map(file => (
          <Flex
            backgroundColor="background.400"
            p={2}
            borderRadius={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="#fff">{file.fileName}</Text>
            <a href={file.url}>
              <DownloadSimple size={20} cursor="pointer" color="#1f5bff" />
            </a>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
