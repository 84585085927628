import React from 'react';
import { Flex, FlexProps, Text, Image } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface ComponentProps extends FlexProps {
  iconSrc?: string;
  title: string;
  IconElement?: IconType;
  secondaryText?: string;
  thirdText?: string;
  fourthText?: string;
  centerContent?: boolean;
  fifthText?: string;
  isCalendarContact?: boolean;
  IconStuckMoney?: any;
}

export const AssignmentContentCard: React.FC<ComponentProps> = ({
  iconSrc,
  title,
  IconElement,
  IconStuckMoney,
  secondaryText,
  thirdText,
  centerContent,
  fourthText,
  fifthText,
  isCalendarContact,
  ...rest
}) => {
  return (
    <Flex
      width="100%"
      maxWidth={{
        base: '100%',
        lg: '246px',
        xl: '256px',
        '2xl': '256px',
      }}
      height="initial"
      minH={isCalendarContact ? '110px' : '108px'}
      maxH={isCalendarContact ? '80px' : 'initial'}
      alignItems="center"
      justifyContent="center"
      background="background.500"
      gridGap="3.5"
      py={isCalendarContact ? '3' : '3'}
      px="3"
      borderRadius="2"
      cursor="pointer"
      transition="all 0.2s ease-in"
      _hover={{ background: 'background.400' }}
      {...rest}
    >
      {IconElement ? (
        <IconElement size={34} />
      ) : IconStuckMoney ? (
        <IconStuckMoney size={34} color="#f2c240" weight="fill" />
      ) : (
        <Image src={iconSrc} alt="ícone de tarefa" width="100%" maxW="26px" />
      )}

      <Flex flexDir="column" width={centerContent ? 'fit-content' : '100%'}>
        <Text
          fontWeight="semibold"
          mb="0.5"
          color="white"
          width="100%"
          fontSize={isCalendarContact ? 'smaller' : 'md'}
        >
          {title}
        </Text>

        {secondaryText && (
          <Text fontSize={isCalendarContact ? 'xs' : 'small'} color="gray.300">
            {secondaryText}
          </Text>
        )}

        {thirdText && (
          <Text
            fontSize={isCalendarContact ? 'xs' : 'small'}
            color="gray.300"
            maxW="158px"
            isTruncated
          >
            {thirdText}
          </Text>
        )}

        {fourthText && (
          <Text fontSize={isCalendarContact ? 'xs' : 'small'} color="gray.300">
            {fourthText}
          </Text>
        )}
        {fifthText && (
          <Text fontSize={isCalendarContact ? 'xs' : 'small'} color="gray.300">
            {fifthText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
