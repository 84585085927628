import { Box, Flex, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { HeadDataType } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatTimeNoTimezone } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { IMeeting } from 'pages/UserAgenda';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { Modal } from 'componentsV2/elements/modal';
import { Divider } from 'componentsV2/elements/divider';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { DateUtils } from 'utilsV2/Date';
import { useEmployeeAgendaTableContentFunctions } from './hooks/useEmployeeAgendaTableContentFunctions';

type PropsType = {
  tableHeadData: HeadDataType[];
  meeting: IMeeting;
};

export const EmployeeAgendaTableContent: React.FC<PropsType> = ({
  tableHeadData,
  meeting,
}): JSX.Element => {
  const [isNotebookWidth] = useMediaQuery(['(max-width: 1500px)']);

  const { sendToMeeting, statusTranslated, meetingOrigin } =
    useEmployeeAgendaTableContentFunctions();

  const [isOpenRedirectToMeeting, setIsOpenRedirectToMeeting] =
    useState<boolean>(false);

  return (
    <div>
      <TableRow
        headData={tableHeadData}
        shouldHaveBorderLeft={!DateUtils.isToday(meeting.date)}
        onClick={() => setIsOpenRedirectToMeeting(true)}
        _hover={useCommonHover()}
      >
        {DateUtils.isToday(meeting.date) ? (
          <Flex bgColor="#1D63DD" borderRadius={4}>
            <Text color="white" fontWeight="bold">
              Hoje
            </Text>
          </Flex>
        ) : (
          <Flex>
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" ml="auto">
              {DateUtils.format(meeting.date, 'dd/MM/yy')}
            </Text>
            <Divider ml="auto" mr="5px" />
          </Flex>
        )}

        <Flex>
          <Box maxW={isNotebookWidth ? '140px' : '280px'}>
            <Tooltip
              hasArrow
              label={formatNameToCaptalize(meeting.name)}
              color="white"
              bg="background.200"
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {formatNameToCaptalize(meeting.name)}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
        <Text>
          {formatTimeNoTimezone(meeting.date ?? new Date().toISOString())}
        </Text>
        <Text>
          {formatValue(
            meeting.lepipevalue ||
              meeting.lctpipevalue ||
              meeting.rdpipevalue ||
              meeting.lpjpipevalue ||
              0
          )}
        </Text>
        <Text>{meetingOrigin[meeting.leadType] || '-'}</Text>
        <Text>{statusTranslated[meeting.status] || '-'}</Text>
        <Text>{meeting.local || '-'}</Text>
      </TableRow>

      <ModalWrapper
        isOpen={isOpenRedirectToMeeting}
        onClose={() => setIsOpenRedirectToMeeting(false)}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title="Deseja ir para essa reunião?" />
            <Modal.CloseIcon
              onClick={() => setIsOpenRedirectToMeeting(false)}
            />
          </Modal.Header>

          <Text>
            Deseja prosseguir para a reunião do{' '}
            {formatNameToCaptalize(meeting.name)}?
          </Text>
          <Modal.Actions>
            <Modal.Action text="Sim" onClick={() => sendToMeeting(meeting)} />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </div>
  );
};
