import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import {
  DefaultCardWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import {
  CurrentMaterials,
  InsertMaterialForm,
} from 'pages/Marketing/components/modules/materialsManagement';

export const MaterialsManagement: React.FC = () => {
  const [showInsertMaterialModal, setShowInsertMaterialModal] = useState(false);
  const [signalToUpdate, setSignalToUpdate] = useState(false);

  const handleCloseModal = () => {
    setShowInsertMaterialModal(false);
  };

  const handleSignalToUpdate = () => {
    setSignalToUpdate(!signalToUpdate);
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />

      <MainPageTitle textAlign="center">Gestão de Materiais</MainPageTitle>

      <Flex flexDir="column" width="100%" maxW="960px">
        <PrimaryButton
          px="4"
          alignSelf="flex-end"
          onClick={() => setShowInsertMaterialModal(true)}
        >
          Inserir novo material
        </PrimaryButton>

        <DefaultCardWrapper mt="3" px="2" py="3" width="100%">
          <Flex
            width="100%"
            overflowY="auto"
            maxH="calc(100vh - 300px)"
            flexDir="column"
            px="2"
            gridGap="3"
          >
            <CurrentMaterials
              handleSignalToUpdate={handleSignalToUpdate}
              signalToUpdate={signalToUpdate}
            />
          </Flex>
        </DefaultCardWrapper>
      </Flex>

      <ModalWrapper
        isOpen={showInsertMaterialModal}
        onClose={() => {
          setShowInsertMaterialModal(false);
        }}
      >
        <InsertMaterialForm
          handleCloseModal={handleCloseModal}
          signalToUpdate={handleSignalToUpdate}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
