import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';

export class DropdownUtils {
  static toBaseOptions(data: BaseDropdownOptionsType): BaseDropdownOptionsType {
    return {
      label: data.label,
      value: data.value,
    };
  }
}
