import { Flex } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import {
  IEmployeesDistributionInfo,
  IPoolList,
} from 'pages/DistributionOfMinedLeads/interfaces';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';

interface IManualLeadDistribution {
  employeeId: string;
  poolNameList: string;
  sendLeadsQtd: number;
}

interface IManualLeadDistributionModalProps {
  onClose: () => void;
  sendEmployeeId: string;
  allPoolLists: IPoolList[];
  setEmployeeSingleRow: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo>
  >;
  employeeSingleRow: IEmployeesDistributionInfo;
}

export const ManualLeadDistributionModal: React.FC<IManualLeadDistributionModalProps> =
  ({
    onClose,
    sendEmployeeId,
    allPoolLists,
    setEmployeeSingleRow,
    employeeSingleRow,
  }) => {
    const [inputData, setInputData] = useState<IManualLeadDistribution>({
      employeeId: sendEmployeeId,
      poolNameList: '',
      sendLeadsQtd: 0,
    });

    const handlePoolNameList = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setInputData({ ...inputData, poolNameList: e.target.value });
    };

    const handleManualLeadDistribution = async () => {
      const data = inputData;
      try {
        if (
          data.employeeId &&
          inputData.sendLeadsQtd !== 0 &&
          inputData.poolNameList !== ''
        ) {
          const manualLeadDistribution =
            await hubApiCaptation.postManualLeadDistribution(data);
          if (manualLeadDistribution) {
            toast.dark('Enviado com sucesso.');
            setEmployeeSingleRow({
              ...employeeSingleRow,
              leadsMinedQtd: String(
                Number(employeeSingleRow.leadsMinedQtd) + inputData.sendLeadsQtd
              ),
            });
            onClose();
          } else {
            toast.dark('Qtd. de leads insuficiente.');
          }
        } else if (inputData.sendLeadsQtd === 0) {
          toast.dark('Defina a quantidade de leads que deseja enviar.');
        } else {
          toast.dark('Escolha uma lista.');
        }
      } catch (error) {
        toast.dark(
          'Não foi possível enviar os leads.Tente novamente mais tarde.'
        );
      }
    };
    return (
      <DefaultModalContentWrapper
        w="340px"
        height="220px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ModalTitle>Envio instantâneo de leads</ModalTitle>
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
          gap="3"
        >
          <InputWithLabel
            label="Qtd. de leads"
            width={300}
            value={inputData.sendLeadsQtd}
            onChange={e =>
              setInputData({
                ...inputData,
                sendLeadsQtd: Number(e.target.value),
              })
            }
          />
          <Dropdown
            w="300px"
            onChange={e => handlePoolNameList(e)}
            defaultValue=""
          >
            <option value="" disabled>
              Selecione uma lista
            </option>
            {allPoolLists.map(poolList => (
              <option value={poolList.PoolName} key={poolList.PoolName}>
                {poolList.PoolName} - Qtd: {poolList.leadsQTD}
              </option>
            ))}
          </Dropdown>
        </Flex>
        <BaseButtonGroup pt={1}>
          <SecondaryButton onClick={onClose}>Fechar</SecondaryButton>
          <PrimaryButton onClick={() => handleManualLeadDistribution()}>
            Enviar
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultModalContentWrapper>
    );
  };
