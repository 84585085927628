import { Flex, Text } from '@chakra-ui/react';

interface DeclineAndFrequencyDataBoxProps {
  label: string;
  value: string | number;
  isSmallVersion?: boolean;
}

export const DeclineAndFrequencyDataBox: React.FC<DeclineAndFrequencyDataBoxProps> =
  ({ label, value, isSmallVersion }) => {
    return (
      <Flex
        width="100%"
        maxW="220px"
        flexDir="column"
        border="1px solid"
        textAlign="center"
        justifyContent="center"
        padding={isSmallVersion ? '1' : '2'}
        color="white.300"
      >
        <Text lineHeight="120%" mb="1.5" fontSize="smaller">
          {label}
        </Text>
        <Text
          as="span"
          fontSize={isSmallVersion ? 'md' : 'xl'}
          lineHeight="100%"
          fontWeight="bold"
        >
          {value}
        </Text>
      </Flex>
    );
  };
