import React, { useRef } from 'react';
import { Text, Link } from '@chakra-ui/react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { formatISO } from 'date-fns';

import { formatDate } from 'utils/formatDate';
import { SecondaryButton } from 'components/elements/buttons';
import { BaseButtonGroup } from 'components/elements/wrappers';
import { CardProductsListBase } from 'pages/products/components/elements/cards';
import { COEProps } from 'pages/products/interfaces';
import { LayoutModal } from 'components/elements/others/LayoutModal';
import { CardInfo } from 'components/elements/others/CardInfo';

interface CoeCardProps {
  coeData: COEProps;
}

const COECard: React.FC<CoeCardProps> = ({ coeData }) => {
  const buttonCopy = useRef<HTMLInputElement>({} as HTMLInputElement);
  const COEDeadline = formatISO(new Date(coeData.COEDeadline));
  const createdDate = formatISO(new Date(coeData.createdDate));

  const handleCopyText = async () => {
    const item = document.querySelector('#whatsMessage') as HTMLInputElement;
    item.select();
    await navigator.clipboard.writeText(item.value);
    toast.dark('Whatsapp copiado com sucesso!');
  };

  return (
    <CardProductsListBase title={coeData.name}>
      <LayoutModal
        description={coeData.strategy}
        bonusMessage={coeData.bonusMessage}
        isStrategy={true}
        maxWidth={'530px'}
      >
        <CardInfo title="Reserva até dia" info={formatDate(createdDate)} />
        <CardInfo title="Vencimento do COE" info={formatDate(COEDeadline)} />

        <BaseButtonGroup justifyContent="flex-start">
          <Link
            width={['100%', 'fit-content']}
            to={coeData.prospect_url}
            href={coeData.prospect_url}
            target="_blank"
            display="flex"
            alignItems="center"
            justifyContent="center"
            px="3"
            borderRadius="4"
            bg="none"
            border="1px solid"
            borderColor="whiteAlpha.800"
            fontSize="xs"
            _hover={{ borderColor: 'primary.400' }}
            style={{ textDecoration: 'none' }}
            color="whiteAlpha.800"
          >
            Donwload do prospecto
          </Link>
          <SecondaryButton
            width={['95%', '110px']}
            display="flex"
            alignItems="center"
            gridGap="1"
            onClick={handleCopyText}
            _hover={{ background: 'background.100' }}
          >
            <AiOutlineWhatsApp size={14} color="#ffffff" />
            <Text fontSize="xs" fontWeight="light">
              Copiar
            </Text>
          </SecondaryButton>

          <input
            type="hidden"
            defaultValue={coeData.whatsMessage}
            value={coeData.whatsMessage}
            ref={buttonCopy}
            id="whatsMessage"
          />
        </BaseButtonGroup>
      </LayoutModal>
    </CardProductsListBase>
  );
};

export default COECard;
