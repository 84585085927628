import React from 'react';
import { BaseModalFormCard } from 'components/elements/cards';

interface ComponentProps {
  handleCloseModalConfirm: () => void;
  handleSubmitConfirm: () => void;
  isLoadingModalConfirm: boolean;
}

const ModalConfirm: React.FC<ComponentProps> = ({
  handleCloseModalConfirm,
  handleSubmitConfirm,
  isLoadingModalConfirm,
}) => {
  return (
    <BaseModalFormCard
      maxW="560px"
      title="Deseja confirmar essa ordem?"
      isLoading={isLoadingModalConfirm}
      handleFormSubmit={handleSubmitConfirm}
      handleToggleModal={handleCloseModalConfirm}
      isModal
      primaryButtonText="Enviar"
      secondaryButtonText="Fechar"
    />
  );
};

export default ModalConfirm;
