/* eslint-disable no-param-reassign */
import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { getOrderTypeBySegment } from 'pages/IP/utils';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { KanbanColumnProps } from 'shared/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { OrderProps } from '../kanbanBase/BoardOrderInfo';

interface IEmployee {
  id: string;
  name: string;
}

interface IProps {
  employees: IEmployee[];
  order: OrderProps;
  setIsOpenTransferModal: (value: boolean) => void;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  column: KanbanColumnProps;
}

export const TransferOrdemModal: React.FC<IProps> = ({
  employees,
  order,
  setIsOpenTransferModal,
  setColumns,
  column,
}) => {
  const [selectedEmployeeToTransferOrder, setSelectedEmployeeToTransferOrder] =
    useState<string>('');

  const handleTransferOrder = async () => {
    try {
      await hubApiIp.transferOrderByType({
        employeeId: selectedEmployeeToTransferOrder,
        orderId: order.orderId || order.id,
        type:
          order.type === 'PJ' || order.type === 'PF'
            ? 'exchange'
            : getOrderTypeBySegment(order.segment as string),
      });
      if (column) {
        column.tasks = column.tasks.filter(task => task.id !== order.id);
      }
      setColumns(prev => [...prev, column]);
      setIsOpenTransferModal(false);
      toast.dark('Ordem transferida com sucesso!');
    } catch (err) {
      toast.dark('Error');
    }
  };

  return (
    <DefaultModalContentWrapper>
      <Text>Selecione um colaborador para transferir a ordem</Text>
      <Dropdown
        mt="5"
        placeholder="Selecione um colaborador"
        onChange={event =>
          setSelectedEmployeeToTransferOrder(event.target.value)
        }
      >
        {employees.map((collaborator, index: number) => (
          <option key={index} value={collaborator.id}>
            {formatNameToCaptalize(collaborator.name)}
          </option>
        ))}
      </Dropdown>
      <Flex pt={5}>
        <PrimaryButton onClick={handleTransferOrder}>Transferir</PrimaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
