import { Flex, Text } from '@chakra-ui/react';
import { useScroll } from 'hooksV2/useScroll';
import { useMeetingsPoolContext } from 'pagesV2/MeetingsPool/context/useMeetingsPoolContext';
import { useEffect } from 'react';
import { GetMeetingsPoolResponseType } from 'services/hubApi/classes/HubApiCaptation';
import { GetClientActivitiesResponseType } from 'services/hubApi/classes/HubApiClients';
import { MeetingPoolType } from 'sharedV2/types/meeting';
import { ClientAportCard } from '../clientAportCard';
import { MeetingsPoolMosaicCard } from '../mosaicCard';

type PropsType = {
  meetings: GetMeetingsPoolResponseType | undefined;
  visualizationMode: string;
  clientsAport: GetClientActivitiesResponseType[] | undefined;
  thereIsClientAport: boolean;
  setThereIsClientAport: (value: boolean) => void;
};

export const MeetingsPoolMosaic: React.FC<PropsType> = ({
  meetings,
  visualizationMode,
  clientsAport,
  thereIsClientAport,
  setThereIsClientAport,
}): JSX.Element => {
  const { selectedTab, selectedTabTitle } = useMeetingsPoolContext();
  const { scrollContainerRef } = useScroll();

  const itemsFiltered =
    meetings &&
    (meetings[selectedTab as keyof GetMeetingsPoolResponseType]?.meetings as (
      | MeetingPoolType
      | GetClientActivitiesResponseType
    )[]);

  useEffect(() => {
    if (
      thereIsClientAport === false &&
      selectedTab === 'account' &&
      clientsAport &&
      itemsFiltered
    ) {
      itemsFiltered.push(...clientsAport);
      setThereIsClientAport(true);
    }
  }, [selectedTab]);
  return (
    <Flex
      borderRadius={5}
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      paddingX="1rem"
      border="1px solid gray"
      h="100%"
      w="100%"
    >
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" mt={4} ml="0.5px">
        {selectedTabTitle}
      </Text>
      {itemsFiltered?.length === 0 && (
        <Flex mt={4} w="100%" justifyContent="center">
          <Text fontStyle="italic">Sem leads nesta seção</Text>
        </Flex>
      )}
      <Flex
        gap={3}
        mt={4}
        w="100%"
        overflowY="auto"
        wrap="wrap"
        maxH="calc(100vh - 230px)"
        className="thinScrollbar scrollContainer"
        ref={scrollContainerRef}
      >
        {itemsFiltered &&
          itemsFiltered.map((item, index) => {
            // É uma reunião (meeting)
            if ('leadId' in item) {
              return (
                <MeetingsPoolMosaicCard
                  key={index}
                  meeting={item}
                  visualizationMode={visualizationMode}
                  isInLateSection={false} // Deprecated
                />
              );
            }

            return (
              <ClientAportCard
                aport={item as any}
                visualizationMode={visualizationMode}
                key={index}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};
