import { useMutation, UseMutationResult } from 'react-query';
import {
  CreateMeetingRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { ClientMeetingType } from 'sharedV2/types/client';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useCreateClientMeeting = (
  props?: PropsType
): UseMutationResult<
  ClientMeetingType,
  unknown,
  CreateMeetingRequestType,
  unknown
> => {
  return useMutation({
    mutationKey: ['createMeeting'],
    mutationFn: async (data: CreateMeetingRequestType) => {
      const response = await hubApiClients.createMeeting(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};
