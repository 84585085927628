import { Flex, Text } from '@chakra-ui/react';
import { Divider } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { basicDate } from 'utils/formatDate';
import { IMibsHistory, IMibsHistoryAdditionalInformations } from '../dtos';
import { ActionTranslated } from '../utils/ActionTranslated';
import { GetTransactionResponsibleName } from '../utils/GetTransactionResponsibleName';
import { TranslateWalletName } from '../utils/TranslateWalletName';
import { MibsHistoryItemAdditionalInformationsModal } from './MibsHistoryItemAdditionalInformationsModal';

interface IProps {
  item: IMibsHistory;
}

export const MibsHistoryItem: React.FC<IProps> = ({ item }) => {
  const user = useSelector((state: IReduxState) => state.user.profile) as {
    id: string;
    position: string;
    rules: { area_cod: string; permission_cod: string }[];
    name: string;
  };

  const [
    isOpenMibsHistoryItemAdditionalInformationsModal,
    setIsOpenMibsHistoryItemAdditionalInformationsModal,
  ] = useState<boolean>(false);

  const additionalInformations: IMibsHistoryAdditionalInformations =
    item.additionalInformations && JSON.parse(item.additionalInformations);

  const { data: employeeName } = useQuery(
    ['responsibleName', item.transactionResponsibleId],
    () => GetTransactionResponsibleName({ item, user }),
    { refetchOnWindowFocus: false }
  );

  return (
    <Flex
      flexDirection="column"
      key={item.id}
      p={1}
      marginBottom={2}
      onClick={() => {
        if (
          additionalInformations &&
          item.sourceAction !== 'LEAD_PURCHASED' &&
          item.sourceAction !== 'LEAD_POOL_DECLINED' &&
          item.sourceAction !== 'NO_ANSWER'
        )
          setIsOpenMibsHistoryItemAdditionalInformationsModal(true);
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        _hover={{ backgroundColor: '#f2f2f2', transition: '200ms' }}
        cursor="pointer"
        borderRadius={3}
        p={3}
      >
        <Flex flexDirection="column">
          <Text fontSize={20} color="rgba(70, 70, 70, 1)">
            {item.sourceActionCustomTitle ??
              ActionTranslated(item.sourceAction)}
            <Text
              as="span"
              fontSize={16}
              color="rgba(70, 70, 70, 1)"
              fontStyle="italic"
              ml={2}
              fontWeight="thin"
            >
              por {formatNameToCaptalize(employeeName as string)}
            </Text>
          </Text>
          <Text display="flex" gap={1} color="rgba(70, 70, 70, 1)">
            Quantidade:{' '}
            <Text
              display="inline"
              color={
                item.transactionType === 'DEPOSIT' ? 'green.300' : 'red.300'
              }
            >
              {Math.floor(item.transactionValue)}
            </Text>
            <Text color="rgba(70, 70, 70, 1)">MIBs</Text>
          </Text>
          <Text display="flex" gap={1} color="rgba(70, 70, 70, 1)">
            Carteira:{' '}
            <Text
              display="inline"
              fontStyle="italic"
              color="rgba(70, 70, 70, 1)"
            >
              {TranslateWalletName(item.wallet)}
            </Text>
          </Text>
        </Flex>
        <Text color="rgba(70, 70, 70, 1)">
          {basicDate(item.created_at.toString())}
        </Text>
      </Flex>
      <Divider paddingTop={5} />
      <ModalWrapper
        isOpen={isOpenMibsHistoryItemAdditionalInformationsModal}
        onClose={() =>
          setIsOpenMibsHistoryItemAdditionalInformationsModal(
            !isOpenMibsHistoryItemAdditionalInformationsModal
          )
        }
      >
        <MibsHistoryItemAdditionalInformationsModal
          additionalInformations={additionalInformations}
          setIsOpenMibsHistoryItemAdditionalInformationsModal={
            setIsOpenMibsHistoryItemAdditionalInformationsModal
          }
        />
      </ModalWrapper>
    </Flex>
  );
};
