import styled from 'styled-components';

const DataPickerContent = styled.div`
  .input-date-container {
    display: flex;
    flex-direction: column;
    margin: 8px 0 12px;
    label {
      margin-bottom: 4px;
      font-size: var(--smaller-text);
      color: var(--white-color-100);
    }

    input {
      width: 30%;
      font-size: var(--smaller-text);
      border-radius: 5px;
      border: none;
      padding: 4px 6px;
      background: var(--gray-color-600);
      color: var(--white-color-300);
    }
  }

  .input-date-container-full {
    input {
      width: 100%;
    }
  }

  .input-date-container-row {
    flex-direction: row;
    margin: 0 16px 0 0;
    align-items: center;

    label {
      margin: 0 6px 0 0;
    }

    input {
      padding: 7px 8px;
    }
  }
`;

export default DataPickerContent;
