import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

import { TitleWithSideLine } from 'components/elements/texts';
import { MosaicCardsWrapper } from 'pages/Captation/components/elements/wrappers';
import { MeetingMosaicCard } from 'pages/Captation/components/elements/cards';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { useCaptationMeetingsContext } from 'hooks';
import { toast } from 'react-toastify';

interface ComponentProps {
  segment:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'pipe'
    | 'waiting_feedback';
  handleShowMeeting: (lead: IBaseMeeting) => void;
  leadInfo: IBaseMeeting;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

export const MeetingsMosaic: React.FC<ComponentProps> = ({
  segment,
  handleShowMeeting,
  setType,
}) => {
  const {
    handleGetSpecificLeadTypeArr,
    returns,
    suitability,
    presentation,
    openAccount,
    allocation,
    pipe,
  } = useCaptationMeetingsContext();
  const [leadsArr, setLeadsArr] = useState<IBaseMeeting[]>(returns);

  const checkMeetingTypeArr = async () => {
    const segmentMap: { [key: string]: IBaseMeeting[] } = {
      suitability,
      waiting_feedback: suitability,
      presentation,
      account: openAccount,
      allocation,
      default: returns,
    };

    const leads = segmentMap[segment] || segmentMap.default;
    setLeadsArr(leads.filter(item => !item.isPipe));
  };

  const handleDefineMeetingSegment = (meetingSegment: string): string => {
    const segmentMap: { [key: string]: string } = {
      callback: 'Retornos',
      suitability: 'Suitability',
      presentation: 'Apresentação de Carteira',
      allocation: 'Aguardando abertura de conta',
    };

    return segmentMap[meetingSegment] || 'Contas Abertas';
  };

  const openMeetingFromMeetingsAgenda = () => {
    const receivedLeadToOpenTheMeeting =
      window.sessionStorage.getItem('showMeeting');

    if (!receivedLeadToOpenTheMeeting) return;

    const receivedLeadId = receivedLeadToOpenTheMeeting.split('/')[0];
    const isReceivedleadInPipe =
      receivedLeadToOpenTheMeeting.split('/')[2] === 'true';

    if (isReceivedleadInPipe) {
      const lead = pipe.filter(
        (lead: IBaseMeeting) => lead.leadId === receivedLeadId
      )[0];

      if (!lead) {
        toast.dark(
          'Não encontramos a reunião deste lead em nenhuma etapa, entre em contato com o suporte'
        );
        return;
      }

      setType('meetingLead');
      handleShowMeeting(lead);
    } else if (leadsArr.length > 0) {
      const lead = leadsArr.filter(
        (lead: IBaseMeeting) => lead.leadId === receivedLeadId
      )[0];

      if (!lead) {
        toast.dark(
          'Não encontramos a reunião deste lead em nenhuma etapa, entre em contato com o suporte'
        );
        return;
      }

      setType('meetingLead');
      handleShowMeeting(lead);
    }
  };

  useEffect(() => {
    checkMeetingTypeArr();
  }, [segment, returns, suitability, presentation, openAccount, allocation]);

  useEffect(() => {
    handleGetSpecificLeadTypeArr(segment, false);
  }, [segment]);

  useEffect(() => {
    openMeetingFromMeetingsAgenda();
  }, [leadsArr]);

  return (
    <>
      <TitleWithSideLine>
        {handleDefineMeetingSegment(segment)}
      </TitleWithSideLine>

      <MosaicCardsWrapper mt="3">
        {leadsArr.length < 1 ? (
          <Text
            w="100%"
            fontWeight="light"
            fontStyle="italic"
            textAlign="center"
          >
            Sem leads nesta seção
          </Text>
        ) : (
          leadsArr.map((lead, index) => (
            <MeetingMosaicCard
              key={index}
              lead={lead}
              onClick={() => handleShowMeeting(lead)}
            />
          ))
        )}
      </MosaicCardsWrapper>
    </>
  );
};
