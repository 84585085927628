import { Flex, Text } from '@chakra-ui/react';
import { DefaultCardWrapper } from 'components/elements/wrappers';

interface HeaderIPManagementProps {
  ordersLen: number;
}

export const CardTransferLeads: React.FC<HeaderIPManagementProps> = ({
  ordersLen,
}) => {
  return (
    <Flex mt="2" justifyContent="flex-end" alignItems="center">
      <Flex justifyContent="center" alignItems="center">
        <DefaultCardWrapper
          p="2"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="white" fontSize="smaller">
            Transferência de Leads:
          </Text>

          <Text color="orange.400" fontSize="initial">
            {ordersLen}
          </Text>
        </DefaultCardWrapper>
      </Flex>
    </Flex>
  );
};
