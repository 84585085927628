/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';
import { ModalWrapper } from 'components/elements/wrappers';
import { PortifolioProps } from 'pages/products/interfaces';
import { PortfolioForm } from 'pages/products/components/modules/portfolio/PortfolioForm';

import schema from './schema';

export const PortfolioManagement: React.FC = () => {
  const [portifolios, setPortifolios] = useState<PortifolioProps[]>([]);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [portifolioUpdate, setPortifolioUpdate] = useState<PortifolioProps>(
    {} as PortifolioProps
  );
  const [update, setUpdate] = useState<boolean>(false);

  const handleToggleModal = () => {
    setDisplayModal(!displayModal);
    setUpdate(false);
  };

  const handleSetNewWallet = useCallback(
    newWallet => {
      setPortifolios([...portifolios, newWallet]);
    },
    [portifolios]
  );

  const handleDeleteWallet = useCallback(
    async id => {
      try {
        await hubApiProducts.deleteWallet(id);
        setPortifolios(portifolios.filter(item => item.id !== id));
        toast.dark('Carteira deletada com sucesso.');
      } catch (error) {
        toast.dark('Ocorre um erro ao deletar a carteira, tente novamente.');
      }
    },
    [portifolios]
  );

  const handleUpdateWallet = useCallback(
    async (portifolio: string) => {
      setDisplayModal(!displayModal);
      setUpdate(true);

      const [upPortifolio] = portifolios.filter(item => {
        return item && item.id === portifolio;
      });
      setPortifolioUpdate(upPortifolio);
    },
    [displayModal]
  );

  const handleSubmitFromOfCreateWallet = useCallback(
    async formData => {
      try {
        await schema.validate(formData, {
          abortEarly: false,
        });

        const response = await hubApiProducts.postCreateWallet(formData);

        setPortifolios([...portifolios, response]);

        setDisplayModal(false);
        setUpdate(false);
        toast.dark('Carteira criada com sucesso!');
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o Carteira, por favor tente novamente.'
          );
        }
      }
    },
    [portifolios]
  );

  const handleSubmitFromOfUpdateWallet = useCallback(
    async formData => {
      try {
        await schema.validate(formData, {
          abortEarly: false,
        });

        const response = await hubApiProducts.putUpdateWallet(
          portifolioUpdate.id,
          formData
        );

        setPortifolios(
          portifolios.map(item =>
            item.id === portifolioUpdate.id ? response : item
          )
        );

        setDisplayModal(false);
        setUpdate(false);
        toast.dark('Carteira atualizada com sucesso!');
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          toast.dark(err.message);
        } else {
          toast.dark(
            'Ocorreu um erro ao atualizar a carteira, tente novamente!'
          );
        }
      }
    },
    [portifolios, portifolioUpdate?.id]
  );

  useEffect(() => {
    async function fetchPortfolios() {
      await hubApiProducts.getAllportifolio().then(response => {
        setPortifolios(response);
      });
    }
    fetchPortfolios();
  }, []);

  if (portifolios.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar Carteiras"
        buttonText="Criar nova Carteira"
        handleCreateProduct={handleToggleModal}
      >
        <Text color="whiteAlpha.800">Não existem Carteiras cadastradas.</Text>

        {update
          ? displayModal && (
              <ModalWrapper isOpen onClose={() => {}}>
                <PortfolioForm
                  handleToggleModal={handleToggleModal}
                  handleSetNewWallet={handleSetNewWallet}
                  label="Atualizar Carteira"
                  wallet={portifolioUpdate}
                  handleSubmit={handleSubmitFromOfUpdateWallet}
                />
              </ModalWrapper>
            )
          : displayModal && (
              <ModalWrapper isOpen onClose={() => {}}>
                <PortfolioForm
                  handleToggleModal={handleToggleModal}
                  handleSetNewWallet={handleSetNewWallet}
                  label="Criar Carteira"
                  handleSubmit={handleSubmitFromOfCreateWallet}
                />
              </ModalWrapper>
            )}
      </ProductManagementLayout>
    );
  }

  return (
    <ProductManagementLayout
      title="Gerenciar Carteiras"
      buttonText="Criar nova Carteira"
      handleCreateProduct={handleToggleModal}
    >
      {portifolios.map((portifolio, index) => (
        <ProductManagementCard
          key={index}
          productId={portifolio.id}
          title={portifolio.name}
          handleEditProduct={handleUpdateWallet}
          handleRemoveProduct={handleDeleteWallet}
        />
      ))}

      {update
        ? displayModal && (
            <ModalWrapper isOpen onClose={() => {}}>
              <PortfolioForm
                handleToggleModal={handleToggleModal}
                handleSetNewWallet={handleSetNewWallet}
                label="Atualizar Carteira"
                wallet={portifolioUpdate}
                handleSubmit={handleSubmitFromOfUpdateWallet}
              />
            </ModalWrapper>
          )
        : displayModal && (
            <ModalWrapper isOpen onClose={() => {}}>
              <PortfolioForm
                handleToggleModal={handleToggleModal}
                handleSetNewWallet={handleSetNewWallet}
                label="Criar Carteira"
                handleSubmit={handleSubmitFromOfCreateWallet}
              />
            </ModalWrapper>
          )}
    </ProductManagementLayout>
  );
};
