import { useState } from 'react';
import { toast } from 'react-toastify';
import { Divider, Flex, Stack, Text, useMediaQuery } from '@chakra-ui/react';

import { ModalTitle } from 'components/elements/texts';
import { BaseButtonGroup, ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  OutlineButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { IBaseLead, IQuestionProps } from 'pages/Captation/interfaces';
import { errorHandler } from 'utils';
import { Textarea } from 'components/elements/forms';

import { ContactAttempts } from 'pages/Captation/components/elements/others';

import { useQuery } from 'react-query';
import hubApi from 'services/hubApi';
import { AxiosError } from 'axios';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { useNavigation } from 'hooksV2/useNavigation';
import { LeadUtils } from 'utilsV2/Lead';
import { LeadBillingRange } from '../LeadBillingRange';
import { LeadEmailsContent } from '../LeadEmailsContent';
import { LeadPjHeadContent } from '../LeadPjHeadContent';
import { LeadsPhonesContent } from '../LeadsPhonesContent';

import { LeadTags } from '../LeadTags';
import { DeclineOffer, ScheduleMeeting, ScheduleReturn } from '../../modals';
import { IQuestion } from '../../meetings/MeetingDetailsContent';
import { MeetingQuestions } from '../../meetings';
import { LeadOthersContent } from '../LeadOthersContent';
import { MeetingsInfoFromMI } from '../../meetings/MeetingsInfosFromMI';
import { LeadPLMundoInvest } from '../LeadPLMundoInvest';
import { LeadPricePaid } from '../LeadPricePaid';

interface CommonLeadContentProps {
  leadType:
    | 'leadstable'
    | 'leadspublic'
    | 'leadsmanual'
    | 'leadspj'
    | 'leadspersonal'
    | 'declined'
    | 'pipe'
    | 'leadspurchased'
    | 'pool';
  leadId: string;
  handleChangeLeadMenu?: (
    selectedLeadType:
      | 'leadstable'
      | 'leadspublic'
      | 'leadsmanual'
      | 'leadspj'
      | 'leadspersonal',
    selectedTitle: string,
    selectedType: string
  ) => void;
  handleRemoveMinedLeadById: (leadId: string) => void;
  handleRemoveListLeadById: (leadId: string) => void;
  updateLastClickedLeadContactAttempts: React.Dispatch<
    React.SetStateAction<number>
  >;
  setUpdatedClickedLeadContactAttemptsId: React.Dispatch<
    React.SetStateAction<string>
  >;
  totalNumOfLeads: number;
  updateTotalNumOfLeadsInPipe: () => void;
}

export interface LeadInfos extends IBaseLead {
  CNPJ: string;
  razaoSocial: string;
  cargo: string;
  faixaFaturamento?: string;
  tags?: {
    tag: string;
  }[];
  contactAttempts: string;
}

export const CommonLeadContent: React.FC<CommonLeadContentProps> = ({
  leadType,
  leadId,
  handleChangeLeadMenu,
  handleRemoveMinedLeadById,
  updateLastClickedLeadContactAttempts,
  setUpdatedClickedLeadContactAttemptsId,
  handleRemoveListLeadById,
  totalNumOfLeads,
  updateTotalNumOfLeadsInPipe,
}) => {
  const { redirect } = useNavigation();

  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [response, setResponse] = useState<Partial<IQuestionProps>[]>([]);
  const [interestTitle, setInterestTitle] = useState('Nivel de interesse');
  const [decline, setDecline] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [nextLead, setNextLead] = useState(false);
  const [updatedContactAttempts, setUpdatedContactAttempts] = useState(0);

  const { data: lead, isLoading: isLoadingLeadInfo } = useQuery(
    ['leadInfo', leadId],
    async () => {
      let leadData;

      if (leadType === 'leadsmanual' || leadType === 'leadstable') {
        leadData = await hubApiCaptation.getLeadById(leadType, leadId);
      } else if (leadType === 'leadspurchased') {
        leadData = await hubApiCaptation.getLeadById('online', leadId);
      } else {
        leadData = await hubApiCaptation.getLead(leadType);
      }

      return leadData;
    },
    {
      onError: error => {
        const err = error as AxiosError;

        if (err.response?.status === 404) {
          toast.dark('Sem leads PJ disponíveis no momento.');
        } else {
          toast.dark(
            'Um erro aconteceu ao buscar um lead PJ. Tente novamente mais tarde.'
          );
        }
      },
    }
  );

  const { data: meetingInfoFromMI } = useQuery(
    [`meetingInfoFromMI`, lead?.id],
    async () => {
      const meetingInfo = await hubApi.getMeetingInfoFromMI(
        lead.email?.[0]?.email as string
      );

      return meetingInfo.clients[0];
    },
    { enabled: !!lead }
  );

  const { data: taskResponse } = useQuery(
    ['leadQuestions', lead?.id],
    async () => {
      const questionsResponse: IQuestion[] =
        await hubApiCaptation.getQuestionResponse(lead.id);

      const existAddInfo = questionsResponse.filter(
        questionsRespons => questionsRespons.question === questionItem.question
      );

      if (existAddInfo.length > 0) {
        setQuestionItem(existAddInfo[0]);
      }

      const anotherQuestions = questionsResponse.filter(
        questionsRespons => questionsRespons.question !== questionItem.question
      );

      return anotherQuestions;
    },
    { enabled: !!lead }
  );

  const handleChangeTextAreaValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestionItem({ ...questionItem, answer: e.target.value });
  };

  const [questionItem, setQuestionItem] = useState({
    question: 'informacoesadicionaistextarea',
  } as IQuestion);

  const handleAddTextAreaQuestion = async () => {
    if (questionItem.id) {
      try {
        await hubApiCaptation.editQuestion({
          id: questionItem.idQuestion,
          question: questionItem.question,
          leadId: questionItem.leadId,
        });

        await hubApiCaptation.attQuestionResponse({
          id: questionItem.id,
          answer: questionItem.answer ? questionItem.answer : ' ',
        });
        toast.dark('Informações atualizadas com sucesso!');
      } catch (err) {
        errorHandler(err);
      }
    } else {
      try {
        if (!questionItem.answer) return;
        const response = await hubApiCaptation.postNewQuestion({
          question: questionItem.question,
          status: 'standard',
        });

        await hubApiCaptation.postResponse({
          data: [
            {
              answer: questionItem.answer,
              leadId: lead.id,
              idQuestion: response.id,
            },
          ],
        });
        toast.dark('Informações atualizadas com sucesso!');
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const postTasks = async () => {
    await hubApiCaptation.postResponse({ data: response });
  };

  const handleDisplayModal = () => setDisplayModal(!displayModal);
  const handleReturnModal = () => setReturnModal(!returnModal);
  const handleDecline = () => setDecline(!decline);

  const handleOpenReturnMeeting = () => {
    handleReturnModal();
    setInterestTitle('retornar');
  };

  const handleOpenScheduleMeeting = () => {
    handleDisplayModal();
    setInterestTitle('Alto');
  };

  const handleChangeToNextLead = () => {
    setResponse([]);

    setNextLead(!nextLead);
  };

  const handleSaveLeadInfos = async () => {
    if (interestTitle === 'Nivel de interesse') {
      toast.dark(
        'Selecione um nível de interesse antes de ir para o próximo lead.'
      );
      return;
    }

    setResponse([]);
    setNextLead(!nextLead);
  };

  if (isLoadingLeadInfo) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex flexDir="column" justifyContent="space-between" height="100%">
        <Flex height="100%" flexDir={['column', 'row']}>
          <Flex
            width={['100%', '"45%"']}
            height={['30%', '100%']}
            flexDir="column"
            overflowY="auto"
            maxH="calc(100vh - 200px)"
          >
            {leadType === 'leadspj' ? (
              <LeadPjHeadContent
                cnpj={lead.CNPJ}
                corporateName={lead.razaoSocial}
                role={lead.cargo}
                leadId={leadId || lead.id}
              />
            ) : (
              <ModalTitle mb="1">
                {lead.name && lead.name.toUpperCase()}
              </ModalTitle>
            )}
            {leadType !== 'leadspj' && (
              <SecondaryButton
                mt={2}
                p={2}
                w="50%"
                h="25px"
                onClick={() =>
                  redirect(
                    `/v2/lead/${LeadUtils.transformLeadType(leadType)}/${
                      leadId || lead.id
                    }`
                  )
                }
              >
                Ir para a pagina do lead
              </SecondaryButton>
            )}

            <Stack spacing="3.5" mt="3.5" mb={2}>
              {lead?.NomeListaPool && (
                <Text fontSize="small">
                  Lista:{' '}
                  <Text as="span" color="gray.100">
                    {lead.NomeListaPool}
                  </Text>
                </Text>
              )}
              {lead?.phone && lead?.phone.length > 0 && (
                <LeadsPhonesContent phones={lead.phone} />
              )}

              {lead?.others && <LeadOthersContent others={lead.others} />}
              {lead?.email && lead?.email.length > 0 && (
                <LeadEmailsContent emails={lead.email} />
              )}
              {lead.faixaFaturamento && (
                <LeadBillingRange billingRange={lead.faixaFaturamento} />
              )}

              {meetingInfoFromMI !== undefined &&
                meetingInfoFromMI?.id !== '' && (
                  <MeetingsInfoFromMI meetingsInfos={meetingInfoFromMI} />
                )}

              {lead.tags && lead.tags.length > 0 && (
                <LeadTags tags={lead.tags} />
              )}

              {lead.plMi && <LeadPLMundoInvest plMi={lead.plMi} />}

              {lead.price && <LeadPricePaid leadPrice={lead.price} />}

              {lead.origin && (
                <ContentWithVertDividerWrapper>
                  <ContentDividerTitle mb="1">
                    Origem do Lead
                  </ContentDividerTitle>
                  <Flex flexWrap="wrap" gridGap="1.5">
                    <Text fontSize="xs">{lead.origin}</Text>
                  </Flex>
                </ContentWithVertDividerWrapper>
              )}
            </Stack>

            <ContactAttempts
              leadType={
                leadType === 'leadspurchased' ? 'leadspublic' : leadType
              }
              id={lead.id}
              leadId={lead.id}
              currentAttempts={
                lead.contactAttempts ? lead.contactAttempts : '0'
              }
              updateContactAttempts={setUpdatedContactAttempts}
              updateLastClickedLeadContactAttempts={
                updateLastClickedLeadContactAttempts
              }
              setUpdatedClickedLeadContactAttemptsId={
                setUpdatedClickedLeadContactAttemptsId
              }
              setDecline={setDecline}
            />
          </Flex>

          {isMobile ? (
            <Divider
              my={3}
              orientation="horizontal"
              borderColor="background.100"
            />
          ) : (
            <Divider
              mx={3}
              orientation="vertical"
              borderColor="background.100"
            />
          )}

          <Flex flexDir="column" w="100%" height={['70%', 'auto']}>
            <Flex w="100%" mb={2} justify="space-between" alignItems="end">
              <Text fontSize="sm" color="white" lineHeight="initial">
                Observações:
              </Text>
              <OutlineButton
                h="6"
                fontSize="xs"
                onClick={handleAddTextAreaQuestion}
              >
                Salvar observações
              </OutlineButton>
            </Flex>

            <Textarea
              name="answer"
              mb={['1', '3']}
              minH={['115px', '220px']}
              maxH={['115px', '240px']}
              value={questionItem ? questionItem.answer : ''}
              onChange={handleChangeTextAreaValue}
            />

            <MeetingQuestions
              lead={lead}
              questions={taskResponse as IQuestion[]}
            />
            <BaseButtonGroup spacing={-1}>
              <SecondaryButton onClick={() => handleDecline()}>
                Declínio da oferta
              </SecondaryButton>

              <SecondaryButton
                onClick={() => handleOpenReturnMeeting()}
                disabled={totalNumOfLeads >= 130}
              >
                Retornar
              </SecondaryButton>

              <PrimaryButton
                onClick={() => handleOpenScheduleMeeting()}
                disabled={totalNumOfLeads >= 130}
              >
                Agendar Reunião
              </PrimaryButton>
            </BaseButtonGroup>
          </Flex>
        </Flex>
      </Flex>
      <ModalWrapper isOpen={decline} onClose={handleDecline}>
        <DeclineOffer
          updateTotalNumOfLeadsInPipe={updateTotalNumOfLeadsInPipe}
          handleShowModal={handleDecline}
          handleNextLead={handleChangeToNextLead}
          leadId={lead.id}
          id={lead.id}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          leadType={
            leadType === 'leadspurchased' ? 'leadspublic' : (leadType as any)
          }
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
          handleChangeLeadMenu={handleChangeLeadMenu}
          handleRemoveMinedLeadById={handleRemoveMinedLeadById}
          handleRemoveListLeadById={handleRemoveListLeadById}
          tooMuchContactAttempts={updatedContactAttempts >= 5}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={displayModal} onClose={handleDisplayModal}>
        <ScheduleMeeting
          updateTotalNumOfLeadsInPipe={updateTotalNumOfLeadsInPipe}
          leadInfo={lead}
          setResponse={setResponse}
          leadType={leadType === 'leadspurchased' ? 'leadspublic' : leadType}
          postTasks={postTasks}
          contactAttempts={updatedContactAttempts.toString() || '0'}
          handleShowModal={handleDisplayModal}
          id={lead.id}
          handleSaveLeadInfos={handleSaveLeadInfos}
          handleRemoveMinedLeadById={handleRemoveMinedLeadById}
          handleRemoveListLeadById={handleRemoveListLeadById}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={returnModal} onClose={handleReturnModal}>
        <ScheduleReturn
          id={lead.id}
          leadType={leadType === 'leadspurchased' ? 'leadspublic' : leadType}
          handleShowModal={handleReturnModal}
          handleChangeToNextLead={handleChangeToNextLead}
          postTasks={postTasks}
          status={lead.status}
          handleRemoveMinedLeadById={handleRemoveMinedLeadById}
          contactAttempts={updatedContactAttempts.toString() || '0'}
          handleRemoveListLeadById={handleRemoveListLeadById}
        />
      </ModalWrapper>
    </>
  );
};
