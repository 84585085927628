import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { PrimaryButton } from 'components/elements/buttons';
import { ModalWrapper } from 'components/elements/wrappers';
import { HeadDataType } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { DeclineLeadOfferModal } from 'componentsV2/modules/captation/declineLeadOfferModal';
import { DeclineOfferOptionsEnum } from 'componentsV2/modules/captation/declineLeadOfferModal/data';
import { LeadActionsEnum, useGlobalLeadAction } from 'hooksV2/useLeadActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useParseLeadByType } from 'hooksV2/useParseLeadByType';
import { LeadContactAttemptsOperationEnum } from 'pagesV2/LeadDetails/components/leadContactAttempts';
import { Phone, PhoneX, Plus } from 'phosphor-react';
import { useState } from 'react';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { Icons } from 'sharedV2/icons';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { DateUtils } from 'utilsV2/Date';
import { showToast } from 'utilsV2/showToast';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  tableHeadData: HeadDataType[];
  lead: LeadProspectType;
};

export const LeadProspectTableRow: React.FC<PropsType> = ({
  lead,
  tableHeadData,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const handleOnLeadClick = () => {
    redirect(`/v2/lead/${LeadTypeEnum.UPLOAD}/${lead.id}`);
  };

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const [isOpenDeclineOfferModal, setIsOpenDeclineOfferModal] =
    useState<boolean>(false);

  const {
    mutateAsync: globalLeadAction,
    isLoading: isLoadingGlobalLeadAction,
  } = useGlobalLeadAction({
    onSuccess: async () => {
      showToast('Tentativa de contato realizada com sucesso!');
      leadBussinessLogic.saveLastTimeContactAttemptsHaveBeenUpdated();

      await queryClient.invalidateQueries(['leadsProspect']);
    },
    onError: error => {
      handleErrorMessage(error);
    },
  });

  const handleOnDeclineLeadOfferModalClose = async () => {
    setIsOpenDeclineOfferModal(false);

    await handleIncreseLeadContactAttempts(undefined, false);
  };

  const handleIncreseLeadContactAttempts = async (
    event?: React.MouseEvent<SVGSVGElement, MouseEvent>,
    shouldOpenDeclineLeadOfferModal = true
  ) => {
    event?.stopPropagation();

    if (leadBussinessLogic.shouldBlockUpdateContactAttempts()) {
      return showToast(
        'Aguarde ao menos 30 minutos para realizar outra tentativa!'
      );
    }

    if (
      shouldOpenDeclineLeadOfferModal &&
      lead.contactAttempts + 1 >=
        LeadBussinessLogic.MAX_CONTACT_ATTEMPTS_BEFORE_DECLINE_LEAD_MODAL
    ) {
      return setIsOpenDeclineOfferModal(true);
    }

    await globalLeadAction({
      action: LeadActionsEnum.UPDATE_CONTACT_ATTEMPTS,
      operation: LeadContactAttemptsOperationEnum.INCREASE,
      leadId: lead.id,
      leadType: lead.type,
    });
  };

  return (
    <>
      <TableRow
        headData={tableHeadData}
        key={lead.id}
        onClick={handleOnLeadClick}
        _hover={useCommonHover()}
      >
        <Flex>
          <Box maxW="250px">
            <Tooltip
              hasArrow
              label={TextUtils.toCapitalize(lead.name)}
              color="white"
              bg="background.200"
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {TextUtils.toCapitalize(lead.name)}
              </Text>
            </Tooltip>
          </Box>
        </Flex>

        <Text>{lead.phone ? TextUtils.toPhone(lead.phone) : '-'}</Text>
        <Text>
          {lead.lastContactAttemptsUpdate
            ? DateUtils.formatWithTime(lead.lastContactAttemptsUpdate)
            : 'Sem Último Contato'}
        </Text>
        <Text>{lead.listName ? 'Lista' : 'Minerado'}</Text>
        <Text>{lead.contactAttempts}</Text>
        <Flex>
          <Tooltip
            hasArrow
            label="Realizar tentativa de contato"
            color="white"
            bg="background.200"
          >
            <div>
              <Icons.PHONE_X onClick={handleIncreseLeadContactAttempts} />
            </div>
          </Tooltip>
        </Flex>
      </TableRow>
      <ModalWrapper
        isOpen={isOpenDeclineOfferModal}
        onClose={() => setIsOpenDeclineOfferModal(false)}
      >
        <DeclineLeadOfferModal
          lead={useParseLeadByType({
            lead,
            leadType: lead.type,
          })}
          shouldOnlyDeclineMeeting={false}
          leadType={LeadTypeEnum.UPLOAD}
          onModalCloseCallback={handleOnDeclineLeadOfferModalClose}
          modalOptions={{
            title: 'Deseja declinar este lead por falta de contato?',
            declineOfferReasonInitialValue: DeclineOfferOptionsEnum.NO_ANSWER,
          }}
        />
      </ModalWrapper>
    </>
  );
};
