import { useEffect, useState } from 'react';

import { IRAnkingSH } from 'pages/GenerationalVariables/interfaces';

import { Flex, Text, Box, Stack } from '@chakra-ui/react';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'components/elements/others';
import { TitleWithBorder } from '../../elements/others/TitleWithBorder';
import { CurrentScore } from '../../elements/others/CurrentScore';

export const RankingOfSH: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState<IRAnkingSH[]>([]);

  const getRanking = async () => {
    try {
      setLoading(true);
      const response = await hubApiCaptation.getRankingSh();
      setRanking(response);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar as informações');
    }
    setLoading(false);
  };

  useEffect(() => {
    getRanking();
  }, []);

  if (loading) {
    return (
      <Flex align="center" justify="center" w="100%" h="50vh">
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" justify="space-between">
      <Box minW="400px" maxW="400px">
        <TitleWithBorder label="Pontuação Atual SH'S" />

        <Box>
          <Flex align="flex-end" justify="flex-end">
            <Text fontSize="small" w="40px">
              Pts.
            </Text>

            <Text fontSize="small" w="50px">
              Leads
            </Text>
          </Flex>

          <Stack
            h="100%"
            maxH="464px"
            spacing="0.5"
            overflowY="auto"
            flexDir="column"
          >
            {ranking.map((item, key) => {
              return (
                item.employeeName !== null && (
                  <CurrentScore
                    name={item.employeeName}
                    value={item.points}
                    leadsQtd={item.leads}
                    key={key}
                  />
                )
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};
