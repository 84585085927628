import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { ClientType } from 'sharedV2/types/client';
import parser from 'html-react-parser';
import { formateDateAndTime } from 'utils/formatDate';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import {
  CalendarPlus,
  Chat,
  CheckCircle,
  CurrencyCircleDollar,
  NotePencil,
  PhoneIncoming,
  PhoneOutgoing,
  Question,
} from 'phosphor-react';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadSearchType } from 'sharedV2/types/leadSearch';
import { IPostAnotations } from 'pages/IP/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  client: ClientType;
  history:
    | {
        employeeName: string;
        startDate: string;
        endDate: string;
      }[]
    | undefined;
};

export const ClientDetailsLastNotes: React.FC<PropsType> = ({
  client,
  history,
}): JSX.Element => {
  const { data: clientHistory } = useQuery({
    queryKey: ['clientHistory', client.btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getClientHistory(client.btgCode);
      return response;
    },
  });

  const { data: lead } = useQuery<LeadSearchType[]>({
    queryKey: ['leadsSearch'],
    queryFn: async () => {
      const response = await hubApiCaptation.getAllLeads(client.btgCode);
      return response;
    },
  });

  const { data: ipOrders } = useQuery<IPOrderType[]>({
    queryKey: ['ipOrders', lead?.at(0)?.id],
    queryFn: async () => {
      const response = await hubApiIp.getOrderIpByLeadId(
        lead?.at(0)?.id as string
      );
      return response;
    },
    enabled: lead?.at(0)?.id !== undefined,
  });

  const ipOrder =
    ipOrders && ipOrders.filter(order => order.segment === 'vida');

  const APOrder =
    ipOrders && ipOrders.filter(order => order.segment === 'imobiliario');

  const { data: leadHotes } = useQuery({
    queryKey: ['leadHistory', lead?.at(0)?.id],
    queryFn: async () => {
      let response = await hubApiCaptation.getLeadHistory(
        lead?.at(0)?.id as string
      );

      response = response.filter(item => 'content' in item);

      return response;
    },
    enabled: lead?.at(0)?.id !== undefined,
  });

  const { data: orderIPNotes } = useQuery<IPostAnotations[]>({
    queryKey: ['orderIPNotes'],
    queryFn: async () => {
      const clientNotes = await hubApiUser.getClientNotes(
        ipOrder?.at(0)?.id as string
      );

      return clientNotes;
    },
    enabled: ipOrder?.at(0)?.id !== undefined,
  });

  const { data: orderAPNotes } = useQuery<IPostAnotations[]>({
    queryKey: ['orderAPNotes'],
    queryFn: async () => {
      const clientNotes = await hubApiUser.getClientNotes(
        APOrder?.at(0)?.id as string
      );

      return clientNotes;
    },
    enabled: APOrder?.at(0)?.id !== undefined,
  });

  const allNotes = [
    ...(client.meetings || []),
    ...(clientHistory || []),
    ...(leadHotes || []),
    ...(orderIPNotes || []),
    ...(orderAPNotes || []),
  ];
  const sortedHistory = allNotes.sort((a, b) => {
    const dateA = new Date('createdAt' in a ? a.createdAt : a.created_at);
    const dateB = new Date('createdAt' in b ? b.createdAt : b.created_at);

    return dateB.getTime() - dateA.getTime();
  });

  const handleGetActionTranslated = (action: string): string => {
    const obj: Record<string, string> = {
      MEETING_CREATED: 'Reunião agendada',
      APORT_CREATED: 'Aporte agendado',
      REMINDER_CREATED: 'Lembrete criado',
      MEETING_CONCLUDED: 'Reunião concluída',
      CONTACT_FREQUENCY_ACTIVATED: 'Frequência de contato ativada',
      CONTACT_FREQUENCY_DEACTIVATED: 'Frequência de contato desativada',
    };

    return obj[action] || '?';
  };

  const handleGetIcon = (action: string) => {
    const obj: Record<string, JSX.Element> = {
      'Reunião agendada': <CalendarPlus size={30} color="#1D63DDB2" />,
      'Aporte agendado': <CurrencyCircleDollar size={30} color="#1D63DDB2" />,
      'Lembrete criado': <NotePencil size={30} color="#1D63DDB2" />,
      'Reunião concluída': <CheckCircle size={30} color="#1D63DDB2" />,
      'Frequência de contato ativada': (
        <PhoneOutgoing size={30} color="#1D63DDB2" />
      ),
      'Frequência de contato desativada': (
        <PhoneIncoming size={30} color="#1D63DDB2" />
      ),
    };

    return (
      obj[handleGetActionTranslated(action)] || (
        <Question size={30} color="#1D63DDB2" />
      )
    );
  };

  return (
    <Flex
      h="100%"
      border="1px solid #D6D6D6"
      borderRadius="5px"
      maxH={`calc(${(history?.length || 0) * 69}px - 16px + 680px)`}
      p={2}
      flexDirection="column"
      gap={2}
      w="100%"
    >
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Histórico de Atividades
      </Text>

      <Divider />

      <Flex
        flexDirection="column"
        gap={0}
        overflowY="auto"
        className="thinScrollbar"
      >
        {clientHistory?.length === 0 && (
          <Text color="#999999" textAlign="center" fontStyle="italic">
            Nenhuma atividade até o momento
          </Text>
        )}

        {sortedHistory &&
          sortedHistory.map(item => {
            if ('msg' in item) {
              return (
                <Box key={item.btgCode} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.created_at)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação (Nota da Ordem Ilha Forte)
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.msg)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            if ('commentedAreas' in item && item.observations) {
              return (
                <Box key={item.btgCode} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.createdAt)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação (Nota da Reunião)
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.observations)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            if ('employeeType' in item) {
              return <Text>Wrong type</Text>;
            }

            if ('content' in item) {
              return (
                <Box key={item.id} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(
                          'createdAt' in item ? item.createdAt : item.created_at
                        )}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação{' '}
                        {'created_at' in item && <Text>(Nota do Lead)</Text>}
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.content)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            if ('action' in item) {
              return (
                <Box key={item.id} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      {handleGetIcon(item.action)}

                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm" mt={-6}>
                        {formateDateAndTime(item.createdAt)}
                      </Text>
                      <Flex>
                        <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                          {handleGetActionTranslated(item.action)}
                          {'  '}
                          {item.employeeName && (
                            <span
                              style={{
                                fontWeight: 'normal',
                                fontStyle: 'italic',
                              }}
                            >
                              por {TextUtils.toCapitalize(item.employeeName)}
                            </span>
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              );
            }
          })}
      </Flex>
    </Flex>
  );
};
