import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { TitleWithSideLine } from 'components/elements/texts';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { LoadingSpinner } from 'components/elements/others';
import formatValue from 'utils/formatValue';

interface ComponentProps {
  title: string;
  qtd: number;
  children?: React.ReactNode;
  pipeValue?: string;
  tasksMenu?: React.ReactNode;
  isLoading: boolean;
}

export const MeetingsSectionLargeBox: React.FC<ComponentProps> = ({
  title,
  qtd,
  children,
  pipeValue,
  tasksMenu,
  isLoading,
}) => {
  return (
    <Flex
      w="50%"
      backgroundColor="background.500"
      borderRadius={4}
      h="100%"
      p={3}
      alignItems="baseline"
      flexDir="column"
    >
      {pipeValue && (
        <Flex w="100%">
          <TitleWithSideLine>
            {formatNameToCaptalize(title)} ({qtd})
          </TitleWithSideLine>

          <Text ml={2} color="gray.200" fontWeight="semibold">
            {formatValue(Number(pipeValue)).split(',')[0]}
          </Text>
          {tasksMenu}
        </Flex>
      )}

      {!pipeValue && (
        <Flex w="100%">
          <TitleWithSideLine>
            {formatNameToCaptalize(title)} ({qtd})
          </TitleWithSideLine>

          {tasksMenu}
        </Flex>
      )}

      <Flex
        h="100%"
        w="100%"
        overflowY="auto"
        gap={1}
        flexDir="column"
        mt={2}
        className="slimScrollbar"
      >
        {isLoading && <LoadingSpinner size="md" />}
        {!isLoading && children}
      </Flex>
    </Flex>
  );
};
