export const advancedOrdersHeader = [
  'Código do cliente',
  'Tipo de ordem',
  'Ticker',
  'Fee',
  'Preço de referência',
  'Assessor',
  'Status',
];

export const statusRequestedStructuredOrders = [
  { title: 'Selecione um status', value: 'All' },
  { title: 'Aguardando', value: 'Aguardando' },
  { title: 'Confirmada', value: 'Confirmada' },
  { title: 'Rejeitada', value: 'Rejeitada' },
];
export const actionsRequestedStructuredOrders = [
  { title: 'Selecione um status' },
  { title: 'Confirmada', value: 'Confirmada' },
  { title: 'Rejeitada', value: 'Rejeitada' },
];
