const lead_db = [
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'cartão de visita',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
  {
    nome: 'Lucas',
    telefone: '11111111',
    email: 'algo@hotmail.com',
    valor: '1560,00',
    info: 'bla bla bla bla',
    colaborador: 'Alguem',
  },
];

export default lead_db;
