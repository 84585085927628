import { Flex, Text } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { Reminder } from 'pages/MyClients/interfaces';
import { formateDateAndTime } from 'utils/formatDate';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { SecondaryButton } from 'components/elements/buttons';

interface LastContactsSectionModalProps {
  reminder: Reminder;
  isOpen: boolean;
  closeModal: () => void;
}

export const LastContactsSectionModal: React.FC<LastContactsSectionModalProps> =
  ({ reminder, isOpen, closeModal }) => {
    return (
      <ModalWrapper isOpen={isOpen} onClose={() => closeModal()}>
        <DefaultModalContentWrapper gridGap={4} maxW={['95vw', '450px']}>
          <ModalTitle textAlign="center">{reminder.title}</ModalTitle>

          <Flex flexDir="column">
            <Text fontSize="sm" mb={1} color="inherit">
              Tipo do contato:
            </Text>
            <Text fontSize="sm" color="white">
              {reminder.meetingContactType || '-'}
            </Text>
          </Flex>

          <Flex flexDir="column">
            <Text fontSize="sm" mb={1} color="inherit">
              Disclaimer da reunião:
            </Text>
            <Text fontSize="sm" color="white">
              {reminder.msg !== ' ' ? parser(reminder.msg) : '-'}
            </Text>
          </Flex>

          <Flex flexDir="column">
            <Text fontSize="sm" mb={1} color="inherit">
              Data de conclusão:
            </Text>
            <Text fontSize="sm" color="white">
              {reminder.updated_at && formateDateAndTime(reminder.updated_at)}
            </Text>
          </Flex>

          <BaseButtonGroup>
            <SecondaryButton onClick={() => closeModal()}>
              Fechar
            </SecondaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    );
  };
