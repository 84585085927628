export const advancedOrdersHeader = [
  'Nome',
  'Cliente',
  'Área',
  'Data solicitações',
  'Status',
];

export const hubAreasOptions = [
  { title: 'Câmbio', value: 'EX' },
  { title: 'Recursos Humanos', value: 'RH' },
  { title: 'Inteligência patrimonial', value: 'IP' },
  { title: 'Marketing', value: 'MK' },
  { title: 'Suporte', value: 'TI' },
  { title: 'Renda variável - Estratégica', value: 'StrategyRV' },
  { title: 'Renda variável - Estruturada', value: 'StructuredRV' },
  { title: 'Renda variável - Especifica', value: 'SpecificRV' },
  { title: 'Guru', value: 'GU' },
];
