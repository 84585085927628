import { Box, Stack } from '@chakra-ui/react';
import { IIManagementLeads } from 'pages/GenerationalVariables/interfaces';
import { getPorcentsOfLeads, formatValue } from 'utils/formatPorcentAndValue';
import { LineInfo } from '../../elements/others/LineInfo';
import { TitleWithBorder } from '../../elements/others/TitleWithBorder';

interface ManagementLeadsStaticProps {
  managementLeadsStatic: IIManagementLeads;
}

export const ManagementLeadsCurrency: React.FC<ManagementLeadsStaticProps> = ({
  managementLeadsStatic,
}) => {
  return (
    <Box w="100%">
      <TitleWithBorder label="Gestão de leads - Atual" />

      <Stack spacing="1">
        {managementLeadsStatic.frequency && (
          <>
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.leads.noContact
              )}
              title="QTD. Leads sem contato"
              quantity={formatValue(
                Number(managementLeadsStatic.leads.noContact)
              )}
            />

            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.meetings.callback
              )}
              title="Retornar"
              quantity={formatValue(
                Number(managementLeadsStatic.meetings.callback)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.leads.confirmed
              )}
              title="Confirmar reunião"
              quantity={formatValue(
                Number(managementLeadsStatic.leads.confirmed)
              )}
            />

            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency1
              )}
              title="Frequência 1"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency1)
              )}
            />

            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency2
              )}
              title="Frequência 2"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency2)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency3
              )}
              title="Frequência 3"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency3)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency4
              )}
              title="Frequência 4"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency4)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency5
              )}
              title="Frequência 5"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency5)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency6
              )}
              title="Frequência 6"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency6)
              )}
            />
            <LineInfo
              porcent={getPorcentsOfLeads(
                managementLeadsStatic.leads.generated,
                managementLeadsStatic.frequency.frequency7
              )}
              title="Frequência 7"
              quantity={formatValue(
                Number(managementLeadsStatic.frequency.frequency7)
              )}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};
