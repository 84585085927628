import { FormEvent, useEffect, useState } from 'react';

import { SimpleGrid } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import { toast } from 'react-toastify';
import { IGroups } from 'pages/GenerationalVariables/interfaces';

interface OrderInfoProps {
  onClose: () => void;
  groups: IGroups[];
  group?: IGroups;
  setGroups: React.Dispatch<React.SetStateAction<IGroups[]>>;
  getGroups: () => void;
}

export const CreateGroup: React.FC<OrderInfoProps> = ({
  onClose,
  groups,
  group,
  setGroups,
  getGroups,
}) => {
  const [createOneGroup, setCreateGroup] = useState<IGroups>({} as IGroups);

  useEffect(() => {
    if (group) {
      isEdit();
    }
  }, []);

  const isEdit = () => {
    if (group) {
      setCreateGroup(group);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    const createGroup = createOneGroup;
    createOneGroup.status = 'ativo';

    if (group) {
      try {
        await hubApiCaptation.patchGroup(createGroup);
        setIsLoading(false);
        getGroups();

        toast.dark('Grupo atualizado com sucesso!');
      } catch (err) {
        errorHandler(err);
        setIsLoading(false);
      }
    } else {
      try {
        const response = await hubApiCaptation.createGroup(createGroup);
        setIsLoading(false);
        getGroups();
        toast.dark('Grupo criado com sucesso!');
      } catch (err) {
        errorHandler(err);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
    onClose();
  };

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCreateGroup({ ...createOneGroup, [e.target.name]: e.target.value });
  };

  return (
    <BaseModalFormCard
      isModal
      title="Criar Grupo"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText="Criar grupo"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '510px']}
    >
      <SimpleGrid columns={1} spacing="2">
        <InputWithLabel
          label="Nome do grupo"
          placeholder="Nome do grupo"
          value={createOneGroup.name}
          name="name"
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Dono do lead"
          placeholder="Email do dono do lead"
          value={createOneGroup.owner}
          name="owner"
          onChange={handleChangeValue}
        />
        <TextareaWithLabel
          label="Descrição do grupo"
          placeholder="Descrição do grupo"
          value={createOneGroup.task}
          name="task"
          onChange={handleChangeValue}
        />
      </SimpleGrid>
    </BaseModalFormCard>
  );
};
