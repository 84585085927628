import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ComponentProps {
  taskId: string;
  taskIndex: number;
  isFinished: boolean;
  primaryText: string | undefined;
  secondaryText?: string | undefined;
  thirdText?: string | undefined;
  advisorName?: string | undefined;
  onClick: () => void;
  activeBorderStatus?: 'meetToday' | 'meetLate' | '';
}

export const KanbanTaskBase: React.FC<ComponentProps> = ({
  taskId,
  taskIndex,
  isFinished,
  primaryText,
  secondaryText,
  thirdText,
  onClick,
  advisorName,
  activeBorderStatus = '',
}) => {
  const borderStatusColor = {
    meetToday: 'green.400',
    meetLate: 'red.400',
  };

  return (
    <Draggable draggableId={taskId} index={taskIndex}>
      {provided => (
        <Flex
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
          onClick={onClick}
          flexDir="column"
          fontSize="xs"
          mb="6px"
          borderRadius="3"
          padding="6px 12px"
          background="white"
          border="1px solid #1d63ddb2"
          pointerEvents={isFinished ? 'none' : 'inherit'}
          cursor="pointer"
          minH="100px"
          w="196px"
          // border={activeBorderStatus !== '' ? '1px' : 0}
          // borderColor={
          //   activeBorderStatus !== ''
          //     ? borderStatusColor[activeBorderStatus]
          //     : 'transparent'
          // }
        >
          <Text isTruncated color="rgba(70, 70, 70, 1)" fontWeight="medium">
            {primaryText?.toUpperCase()}
          </Text>
          {secondaryText && (
            <Text isTruncated color="rgba(70, 70, 70, 1)" fontWeight="light">
              {formatNameToCaptalize(secondaryText)}
            </Text>
          )}
          {advisorName && (
            <Text isTruncated color="rgba(70, 70, 70, 1)" fontWeight="light">
              Assessor: {formatNameToCaptalize(advisorName)}
            </Text>
          )}

          {thirdText && (
            <Text mt="auto" isTruncated color="rgba(70, 70, 70, 1)">
              {thirdText}
            </Text>
          )}
        </Flex>
      )}
    </Draggable>
  );
};
