import { useState } from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { SecondaryButton } from 'components/elements/buttons/SecondaryButton';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface NotesProps {
  previousNotes: string;
  orderId: string;
  clientBTGCode: string;
  updateAnnotationsList: (annotation: any) => void;
}

export const Annotations: React.FC<NotesProps> = ({
  previousNotes,
  orderId,
  clientBTGCode,
  updateAnnotationsList,
}) => {
  const [notes, setNotes] = useState(previousNotes);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
    ],
  };

  const handleUpdateAnnotations = async () => {
    if (notes === previousNotes) return;

    const newAnnotation = {
      orderId,
      typeorder: 'IP',
      type: 'intern',
      msg: notes,
      clientId: '',
    };

    try {
      // get clientId with client email or client code
      if (clientBTGCode) {
        const client = await hubApiUser.listClientsInfos(clientBTGCode);
        newAnnotation.clientId = client.id;
      }

      await hubApiIp.postAnotations(newAnnotation);

      toast.dark('Anotação salva com sucesso.');
      updateAnnotationsList(newAnnotation);
    } catch (error) {
      toast.dark(
        'Não foi possível registrar anotação. Tente novamente em alguns minutos!'
      );
    }
  };

  return (
    <Flex flexDirection="column" h="full">
      <Flex>
        <ReactQuill
          modules={modules}
          theme="snow"
          placeholder=""
          onChange={setNotes}
          value={notes}
          className={
            isMobile ? 'myClientsPanelMobileModal' : 'myClientsPanelModal'
          }
        />
      </Flex>

      <SecondaryButton
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        w="100%"
        disabled={notes === previousNotes || !notes || notes === '<p><br></p>'}
        title={
          notes === previousNotes ? 'Não há alterações para salvar' : undefined
        }
        onClick={handleUpdateAnnotations}
      >
        Salvar
      </SecondaryButton>
    </Flex>
  );
};
