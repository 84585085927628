import { useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export const useTotalSystemMibs = (): UseQueryResult<{
  systemMibs: number;
}> => {
  return useQuery({
    queryKey: [MIBS_QUERIES_KEYS.totalSystemMibs],
    queryFn: async () => {
      const response = await hubApiCaptation.getTotalSystemMibs();
      return response;
    },
    onError: () => {
      toast.dark(
        'Não foi possivel buscar o total de MIBs no sistema. Tente novamente mais tarde.'
      );
    },
  });
};
