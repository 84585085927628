import { Flex, Box, Text, Stack } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { SecondaryButton } from 'components/elements/buttons';
import { FormEvent, useEffect, useState } from 'react';
import hubApi from 'services/hubApi';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import {
  IEmployeeSelected,
  IGroups,
  IUsersSearh,
} from 'pages/GenerationalVariables/interfaces';
import { CardSearhUser } from '../CardSearhUser';
import { UsersSelected } from '../../others/UsersSelected';

interface AddUserToGroupProps {
  onClose: () => void;
  group: IGroups;
  groups: IGroups[];
  getGroups: () => void;
  // deleteUser: (member: IMembersOfGroup) => Promise<void>;

  setGroups: React.Dispatch<React.SetStateAction<IGroups[]>>;
}

export const AddUserToGroup: React.FC<AddUserToGroupProps> = ({
  onClose,
  group,
  groups,
  setGroups,
  getGroups,
}) => {
  useEffect(() => {
    if (group.members) {
      isEdit();
    }
  }, []);

  const isEdit = () => {
    if (group.members) setMemberOfGroup(group.members);
  };
  const [employeeList, setEmployeesList] = useState<IUsersSearh[]>([]);

  const [memberOfGroup, setMemberOfGroup] = useState<IEmployeeSelected[]>([]);
  const [employee, setEmployee] = useState<IEmployeeSelected[]>([]);

  const allMembers = [...memberOfGroup, ...employee];

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState('');

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      await hubApiCaptation.postAddUserToGroup(employee);

      getGroups();

      onClose();
      setIsLoading(false);
      toast.dark('Informações salvas com sucesso!');
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleAddUser = (employeeSelected: IUsersSearh) => {
    const employeeSendToBackEnd = {
      groupId: group.id,
      employeeId: employeeSelected.id,
      name: employeeSelected.name,
    };

    const employeeEqual = employee.filter(
      employee => employee.employeeId === employeeSendToBackEnd.employeeId
    );

    const employeeEqualToo = memberOfGroup.filter(
      employee => employee.employeeId === employeeSendToBackEnd.employeeId
    );

    if (employeeEqual.length > 0 || employeeEqualToo.length > 0) {
      return toast.dark('Esse usuario ja foi selecionado');
    }

    toast.dark('Usuário adicionado! Confirme para salvar as alterações');
    setEmployee([...employee, employeeSendToBackEnd]);
  };

  const handleTakeOfUser = (employeeToRemove: IEmployeeSelected) => {
    const employeeSelectedToRemove = employee.filter(
      employee => employee.employeeId !== employeeToRemove.employeeId
    );

    setEmployee(employeeSelectedToRemove);
  };

  const handleSearchUser = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await hubApi.filterEmployees('name', user);

      if (response.length === 0) {
        toast.dark('Nenhum usuário correspondente');
      } else {
        setEmployeesList(response);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <BaseModalFormCard
      isModal
      title="Adicionar usuário ao grupo"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText="Confimar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW="900px"
    >
      <Flex justify="space-between">
        <Box w="90%">
          <Flex w="100%" align="center" justify="space-between">
            <Box w="75%">
              <InputWithLabel
                label="Nome do usuário:"
                name="name"
                autoComplete="off"
                onChange={e => setUser(e.target.value)}
              />
            </Box>
            <SecondaryButton
              mt="6"
              ml="2"
              onClick={handleSearchUser}
              type="submit"
            >
              Pesquisar
            </SecondaryButton>
          </Flex>

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt="3"
            p="2"
            bg="#f2f2f2"
            flexDir="column"
          >
            <Stack p="1">
              {employeeList.map((item, index) => (
                <CardSearhUser
                  key={index}
                  listEmoployee={item}
                  addUser={handleAddUser}
                />
              ))}
            </Stack>
          </DefaultCardWrapper>
        </Box>

        <Flex ml="5" w="90%" flexDir="column" justify="space-between">
          <Text mt="3" fontSize="md" color="white" fontWeight="bold">
            Usuários selecionados
          </Text>

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt="1"
            p="2"
            bg="#f2f2f2"
            flexDir="column"
          >
            <Stack>
              {allMembers.map((item, index) => (
                <UsersSelected
                  employee={item}
                  key={index}
                  handleTakeOfUser={handleTakeOfUser}
                />
              ))}
            </Stack>
          </DefaultCardWrapper>
        </Flex>
      </Flex>
    </BaseModalFormCard>
  );
};
