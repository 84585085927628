import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { ButtonGroup, Stack } from '@chakra-ui/react';

import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';

import { MaterialProps } from 'pages/Marketing/interfaces';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';

interface EditMaterialFormProps {
  material: MaterialProps;
  handleCloseModal: () => void;
  signalToUpdate: () => void;
}

export const EditMaterialForm: React.FC<EditMaterialFormProps> = ({
  material,
  handleCloseModal,
  signalToUpdate,
}) => {
  const [materialCategory, setMaterialCategory] = useState<string | null>('');
  const [materialTitle, setMaterialTitle] = useState('');
  const [materialLink, setMaterialLink] = useState('');

  useEffect(() => {
    setMaterialTitle(material.title);
    setMaterialLink(material.link);
    setMaterialCategory(material.type);
  }, []);

  const updateMaterial = async () => {
    const updatedMaterialData = {
      id: material.id,
      title: materialTitle,
      link: materialLink,
      type: materialCategory,
    };

    try {
      await hubApiMarketing.editMaterial(updatedMaterialData);

      signalToUpdate();
      handleCloseModal();
      toast.dark('Material atualizado com sucesso!');
    } catch (error) {
      toast.dark(
        'Não foi possível atualizar o material, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <DefaultModalContentWrapper maxW="500px">
      <ModalTitle>Editar material</ModalTitle>

      <Stack flexDirection="column" mb={4}>
        <DropdownWithLabel
          label="Categoria"
          defaultValue={material.type || ''}
          onChange={e => setMaterialCategory(e.target.value)}
        >
          <option value="presentation">Apresentação</option>
          <option value="documents">Documentos</option>
          <option value="images">Imagens</option>
          <option value="logos">Logos</option>
          <option value="others">Outros</option>
        </DropdownWithLabel>

        <InputWithLabel
          name="title"
          type="text"
          label="Título"
          value={materialTitle}
          placeholder="Título"
          onChange={e => setMaterialTitle(e.target.value)}
        />

        <InputWithLabel
          name="link"
          type="text"
          label="Link"
          value={materialLink}
          placeholder="http://exemplo/dowload"
          onChange={e => setMaterialLink(e.target.value)}
        />
      </Stack>

      <ButtonGroup justifyContent="flex-end">
        <SecondaryButton onClick={handleCloseModal}>Cancelar </SecondaryButton>
        <PrimaryButton onClick={updateMaterial}>Salvar edição</PrimaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};
