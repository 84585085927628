const colorsOptions = [
  '#d71b3b',
  '#1868ae',
  '#f3ca20',
  '#1fbfb8',
  '#ff6e40',
  '#9bc472',
  '#d9138a',
  '#79a7d3',
];

export function generateRandomHexColor(position = 0): string {
  const selectedColor = colorsOptions[position];

  return selectedColor;
}
