import React, { useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';

import { PendencyConfirmInput } from 'components/modules/PendencyConfirmInput';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const LeftClients: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcHighPriority}
        title="Saída de cliente"
        secondaryText={`Informe o motivo da saida do cliente: `}
        thirdText={`${assignment?.name?.toUpperCase()} `}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <PendencyConfirmInput
          title="Saída de cliente"
          message={`Informe o motivo da saída do cliente ${assignment?.name?.toUpperCase()}:`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          clientId={assignment.id}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
