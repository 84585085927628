import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import { ModalTitle } from 'components/elements/texts';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { useCaptationMeetingsContext } from 'hooks';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others';
import DatePicker from 'components/elements/others/DatePicker';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';

interface RecoverLeadModalProps {
  selectedLead: IBaseMeeting;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

type IStatus =
  | 'callback'
  | 'suitability'
  | 'presentation'
  | 'allocation'
  | 'account'
  | 'declined'
  | 'created'
  | 'pipe';

export const RecoverLeadModal: React.FC<RecoverLeadModalProps> = ({
  selectedLead,
  closeModal,
}) => {
  const {
    handleGetSpecificLeadTypeArr,
    handleRemoveMeeting,
    handleGetAllPipe,
  } = useCaptationMeetingsContext();
  const [statusSelected, setStatusSelected] = useState('');
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const optionsBase = [
    {
      name: 'Retorno',
      value: 'callback',
    },
    {
      name: 'Suitability',
      value: 'suitability',
    },
    {
      name: 'Carteira',
      value: 'presentation',
    },
    {
      name: 'Aguardando abertura',
      value: 'allocation',
    },
    {
      name: 'Alocação',
      value: 'account',
    },
  ];

  // meetingInfos.isPipe
  const handleRestoreMeeting = async () => {
    if (statusSelected === '') {
      toast.dark('Selecione uma reunião para enviar o lead');
      return;
    }

    if (date === '') {
      toast.dark('Selecione uma data para a reunião');
      return;
    }

    const meetingData = {
      id: selectedLead.id,
      leadId: selectedLead.leadId,
      employeeId: selectedLead.employeeId,
      employeeIdParticipant: selectedLead.employeeIdParticipant,
      type: 'meeting',
      local: selectedLead.local,
      leadType: selectedLead.leadType,
      complement: selectedLead.complement,
      date,
      status: statusSelected,
      feedback: '',
      interest: 'Alto',
    };

    setIsLoading(true);

    try {
      await hubApiCaptation.pathMetting(meetingData);

      handleRemoveMeeting(selectedLead.status as any, selectedLead.id);

      await hubApiCaptation.changeToPipe({
        meetingToBeUpdated: selectedLead,
        statusPipe: false,
      });

      handleGetAllPipe();

      const isUpdateArr = true;
      await handleGetSpecificLeadTypeArr(
        statusSelected as IStatus,
        isUpdateArr
      );

      toast.dark('Lead recuperado com sucesso!');
      closeModal(false);
    } catch (error) {
      toast.dark(
        'Não foi possível recuperar o lead, tente novamente em alguns minutos'
      );
    }

    setIsLoading(false);
  };

  const translateStatusName = (status: string) => {
    let statusName;

    switch (status) {
      case 'suitability':
        statusName = 'Suitability';
        break;
      case 'callback':
        statusName = 'Retornos';
        break;
      case 'presentation':
        statusName = 'Carteira';
        break;
      case 'allocation':
        statusName = 'Aguardando abertura';
        break;

      default:
        statusName = 'Alocação';
    }

    return statusName;
  };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle align="center">Recuperar Lead</ModalTitle>

      <Flex flexDir="column" align="center" gridGap={3}>
        <Flex borderBottom="1px solid gray" w="100%">
          <Text gridGap={2} fontWeight="semibold">
            Nome:{' '}
            <Text as="span" fontWeight="normal" color="white">
              {formatNameToCaptalize(selectedLead.name)}
            </Text>
          </Text>
        </Flex>

        <Flex borderBottom="1px solid gray" w="100%" fontWeight="semibold">
          <Text gridGap={2}>
            Reunião atual:{' '}
            <Text as="span" fontWeight="normal" color="white">
              {translateStatusName(selectedLead.status)}
            </Text>
          </Text>
        </Flex>
      </Flex>

      <Flex justify="space-between" flexDir="column" mt="5">
        <Flex align="center">
          <Text mr="2">Enviar lead para: </Text>
          <Dropdown
            w="98%"
            onChange={event => setStatusSelected(event.target.value)}
            placeholder="Selecione a reunião"
          >
            {optionsBase.map((item, index: number) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </Dropdown>
        </Flex>

        <Flex mt="2" align="center">
          <Text>Próxima reunião:</Text>
          <DatePicker
            handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
              setDate(e.target.value)
            }
            value={date}
            full
            fullColum
            style
            type="datetime-local"
            directionRow
          />
        </Flex>
      </Flex>

      <BaseButtonGroup mt="10">
        <SecondaryButton onClick={() => closeModal(false)}>
          Voltar
        </SecondaryButton>

        <PrimaryButton isLoading={isLoading} onClick={handleRestoreMeeting}>
          Recuperar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
