import React, { useEffect, useState } from 'react';

import { KanbanTaskBase } from 'components/modules/kanbanBases';
import { ModalWrapper } from 'components/elements/wrappers';

import { TaskProps } from 'pages/IP/interfaces';
import { formateDateAndTime } from 'utils/formatDate';
import { isPast, isToday } from 'date-fns';
import { KanbanColumnProps } from 'shared/interfaces';
import CloseOrder from '../CloseOrder';
import { BoardOrderInfo } from '../BoardOrderInfo';

interface ComponentProps {
  index: number;
  task: TaskProps;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  column: KanbanColumnProps;
}

const Task: React.FC<ComponentProps> = ({
  index,
  task,
  setColumns,
  column,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLastColumn, setIsLastColumn] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  let nextContactStatus: 'meetToday' | 'meetLate' | '' = '';

  if (task.nextContact) {
    const orderMeetDate = new Date(task.nextContact);

    if (isPast(orderMeetDate) || isToday(orderMeetDate)) {
      nextContactStatus = isPast(orderMeetDate) ? 'meetLate' : 'meetToday';
    }
  }

  useEffect(() => {
    if (
      task.status === 'fechado/ cancelado' ||
      task.status === 'closed' ||
      task.status === 'boleted'
    ) {
      setIsLastColumn(true);
    }
  }, [task.status]);

  const handleToggleModal = () => setShowModal(!showModal);

  const handleToggleLastModal = () => setIsLastColumn(!isLastColumn);

  return (
    <>
      <KanbanTaskBase
        taskId={task.id}
        taskIndex={index}
        isFinished={isFinished}
        advisorName={task.creatorname}
        primaryText={task.name || task.employeename}
        secondaryText={task?.others?.subSegment || task.information}
        thirdText={
          task.created_at && `Aberta em ${formateDateAndTime(task.created_at)}`
        }
        onClick={handleToggleModal}
        activeBorderStatus={nextContactStatus}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BoardOrderInfo
          order={task}
          handleToggleModal={handleToggleModal}
          setColumns={setColumns}
          column={column}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={isLastColumn} onClose={handleToggleLastModal}>
        <CloseOrder
          order={task}
          handleToggleModal={handleToggleLastModal}
          setIsFinished={setIsFinished}
        />
      </ModalWrapper>
    </>
  );
};

export default Task;
