import React, { useEffect, useState } from 'react';
import { Box, Flex, Link, Text, useMediaQuery } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { formatToPhone } from 'brazilian-values';
import { AiOutlineWhatsApp } from 'react-icons/ai';

import {
  checkIfDateIsPast,
  formatDateToISO,
  formateDateAndTime,
} from 'utils/formatDate';
import {
  LeadDetailsProps,
  SHQuestionsProps,
} from 'pages/Captation/interfaces/SHLeads';
import { LeadActionsCheckbox } from 'pages/Captation/components/modules/SH';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';
import { BaseButtonGroup, ModalWrapper } from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Divider } from 'components/elements/others';
import {
  LeadEmailsContent,
  LeadTags,
} from 'pages/Captation/components/modules/leads';
import {
  DeclineOffer,
  SHRouteMeeting,
  SHScheduleMeeting,
} from 'pages/Captation/components/modules/modals';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import formatValue from 'utils/formatValue';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import hubApi from 'services/hubApi';
import { SHQuestionsForm } from '../SHQuestionsForm';
import {
  IMeetingsInfoFromMI,
  MeetingsInfoFromMI,
} from '../../meetings/MeetingsInfosFromMI';

interface ILeadDetailsProps {
  lead: LeadDetailsProps;
  handleBackToAllLeads: () => void;
  selectedLead: {
    isSelected: boolean;
    leadId: string;
    thereIsTask: boolean;
  };
  setSelectedLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  setSelectedLeadData: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
  expiredLead: boolean;

  setExpiredLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  publicLeads: LeadDetailsProps[];
  setPublicLeads: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
  filteredLeads: LeadDetailsProps[];
  setFilteredLeads: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
  handleGetExpiredLead: () => Promise<void>;
}

export const LeadDetails: React.FC<ILeadDetailsProps> = ({
  lead,
  handleBackToAllLeads,
  selectedLead,
  setSelectedLead,
  setSelectedLeadData,
  expiredLead,
  setExpiredLead,
  publicLeads,
  setPublicLeads,
  setFilteredLeads,
  filteredLeads,
  handleGetExpiredLead,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [decline, setDecline] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [routeMeetingModal, setRouteMeetingModal] = useState(false);
  const [typeOfSchedule, setTypeOfSchedule] = useState<'meeting' | 'callback'>(
    'meeting'
  );
  const [currentQuestions, setCurrentQuestions] = useState<SHQuestionsProps>(
    {} as SHQuestionsProps
  );
  const [meetingInfoFromMI, setMeetingInfoFromMI] =
    useState<IMeetingsInfoFromMI>({
      id: '',
      age: 0,
      dependents: 0,
      activity: '-',
      maritalStatus: '-',
      monthlyIncome: 0,
      monthlyExpense: 0,
      ageToRetire: 0,
      desiredIncome: 0,
      incomeType: '',
      investPerMonth: 0,
      investorProfile: '',
      lifeMoment: '',
      created_at: '',
    });

  const leadPl =
    lead?.customFields[
      lead.customFields.findIndex(
        item => item.fieldName === 'cf_patrimonio_declarado'
      )
    ]?.fieldValue ?? 'Não informado';

  const formatPhone = (phone: string) => {
    return `+${phone.substring(0, 2)} ${formatToPhone(phone.substring(2))}`;
  };

  const handleDecline = () => setDecline(!decline);
  const handleDisplayModal = () => setDisplayModal(!displayModal);

  function compareObjects(obj1: SHQuestionsProps, obj2: SHQuestionsProps) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const handleSaveQuestions = async (
    scheduleType: 'meeting' | 'callback',
    saveButton?: boolean
  ) => {
    const noReadjustment = compareObjects(currentQuestions, lead.questions[0]);

    if (scheduleType !== 'callback' && !saveButton) {
      if (
        currentQuestions.pl === '' ||
        currentQuestions.pl === undefined ||
        Number(currentQuestions.pl) < 9
      ) {
        return toast.dark('Preencha o patrimônio do cliente (PL) ');
      }

      if (Number(currentQuestions.pl) > 15_000_000) {
        return toast.dark(
          'O patrimônio do cliente (PL) precisa estar abaixo de 15 milhões'
        );
      }
    }

    if (scheduleType !== 'callback' && !saveButton) {
      if (
        currentQuestions.filialName === '' ||
        currentQuestions.filialName === undefined
      ) {
        return toast.dark('Preencha o campo de filial');
      }
    }

    if (!noReadjustment) {
      try {
        const response = await hubApiCaptation.updateSHQuestions(
          currentQuestions
        );

        setSelectedLeadData([lead, (lead.questions[0] = response)]);

        toast.dark('Dados atualizados com sucesso');
      } catch {
        toast.dark('Ocorreu um erro ao tentar salvar dados');
      }
    }

    if (!saveButton) {
      setTypeOfSchedule(scheduleType);
      setDisplayModal(!displayModal);
    }
  };

  const handleToggleRouteMeetingModal = async () => {
    const noReadjustment = compareObjects(currentQuestions, lead.questions[0]);
    const scheduledMeetingDate = formatDateToISO(
      lead.meetings[lead.meetings.length - 1].date
    );
    const isMeetingLate = checkIfDateIsPast(scheduledMeetingDate);

    if (isMeetingLate) {
      return toast.dark('A reunião está atrasada, reagende para rotear');
    }

    if (
      currentQuestions.pl === '' ||
      currentQuestions.pl === undefined ||
      Number(currentQuestions.pl) < 9
    ) {
      return toast.dark('Preencha o patrimônio do cliente (PL) ');
    }

    if (Number(currentQuestions.pl) > 15_000_000) {
      return toast.dark(
        'O patrimônio do cliente (PL) precisa estar abaixo de 15 milhões'
      );
    }
    if (
      currentQuestions.filialName === '' ||
      currentQuestions.filialName === undefined
    ) {
      return toast.dark('Preencha o campo de filial');
    }

    if (!noReadjustment) {
      try {
        const response = await hubApiCaptation.updateSHQuestions(
          currentQuestions
        );

        setSelectedLeadData([lead, (lead.questions[0] = response)]);

        toast.dark('Dados atualizados com sucesso');
      } catch {
        toast.dark('Ocorreu um erro ao tentar salvar dados');
      }
    }
    setRouteMeetingModal(true);
  };

  const handleDeclineLead = async () => {
    try {
      await hubApiCaptation.pathLeadsUpdate('leadspublic', {
        id: lead.id,
        status: 'ok',
        interestLead: null,
      });

      if (expiredLead) {
        return await handleGetExpiredLead();
      }

      setSelectedLead({
        isSelected: false,
        leadId: '',
        thereIsTask: true,
      });

      setExpiredLead({
        isSelected: false,
        leadId: '',
        thereIsTask: true,
      });
      toast.dark('Declínio efetuado com sucesso!');
    } catch (err) {
      toast.dark('Erro ao declinar o lead');
    }
  };

  const handleGetMeetingsInfoFromMI = async () => {
    const response = await hubApi?.getMeetingInfoFromMI(
      lead.email?.[0]?.email as string
    );

    setMeetingInfoFromMI(response.clients[0]);
  };

  useEffect(() => {
    if (lead) {
      setCurrentQuestions({
        ...lead.questions[0],
        filialId: lead.meetings[0]?.filialId,
        filialName: lead.meetings[0]?.filialName,
      });
      handleGetMeetingsInfoFromMI();
    }
  }, [lead]);

  if (!lead) {
    return <></>;
  }

  return (
    <Flex
      w="100%"
      maxW="1180px"
      h="100vh"
      maxH="calc(100vh - 196px)"
      bg="blackAlpha.600"
      borderRadius="3px"
      overflowY="auto"
      flexDir={['column', 'row']}
      mt="72px"
      p="28px"
    >
      <Flex
        flexDir="column"
        justifyContent="space-between"
        w={['100%', '50%']}
        maxH={['50%', 0]}
        overflowY={['auto', 'unset']}
      >
        <Flex
          flexDir="column"
          flex="2"
          gridGap="2"
          justifyContent="start"
          pr={['0', '40px']}
        >
          <Box>
            <Text fontSize="xl" color="white.300" fontWeight="bold" mb={8}>
              {formatNameToCaptalize(lead.name)}
            </Text>
          </Box>

          <Flex flexDir="row" flexWrap="wrap">
            {lead.phone?.map((item, index) => (
              <ContentWithVertDividerWrapper key={`phone-${index}`}>
                <ContentDividerTitle>Telefone {index + 1}</ContentDividerTitle>
                <Flex>
                  <Text>
                    {formatToPhone(
                      item.phone.includes('55')
                        ? item.phone.substring(2)
                        : item.phone
                    )}
                  </Text>

                  <Flex ml={2}>
                    <Link
                      href={`https://wa.me/${lead.phone[0].phone}`}
                      target="_blank"
                    >
                      <AiOutlineWhatsApp
                        cursor="pointer"
                        size={26}
                        color="#25D366"
                      />
                    </Link>
                  </Flex>
                </Flex>
              </ContentWithVertDividerWrapper>
            ))}
          </Flex>

          <LeadEmailsContent emails={lead.email} />

          <ContentWithVertDividerWrapper
            flexDir="row"
            gridGap="6"
            flexWrap="wrap"
          >
            <Flex flexDir="column">
              <ContentDividerTitle>
                PL informado na plataforma Mundo Invest
              </ContentDividerTitle>
              <ContentDividerText>
                {lead.total_pl === null
                  ? 'Não informado'
                  : formatValue(lead.plMi as number)}
              </ContentDividerText>
            </Flex>
          </ContentWithVertDividerWrapper>

          <ContentWithVertDividerWrapper>
            <ContentDividerTitle>Data de entrada do lead</ContentDividerTitle>
            <Text>
              {lead.updated_at &&
                formateDateAndTime(lead.updated_at).replace(',', ' às')}
            </Text>
          </ContentWithVertDividerWrapper>

          {lead.meetings?.length > 0 &&
            (lead.meetings[0].status === 'created' ||
              lead.meetings[0].status === 'callback') && (
              <ContentWithVertDividerWrapper>
                <ContentDividerTitle>
                  {lead.meetings[0].status === 'callback'
                    ? 'Retorno'
                    : 'Reunião'}
                </ContentDividerTitle>
                <Text>
                  {lead.updated_at &&
                    formateDateAndTime(lead.meetings[0].date).replace(
                      ',',
                      ' às'
                    )}
                </Text>
              </ContentWithVertDividerWrapper>
            )}

          {lead.meetings?.length === 0 && lead.status !== 'returned' && (
            <ContentWithVertDividerWrapper>
              <ContentDividerTitle>
                Tarefas para hoje: frequência {lead.frequency}
              </ContentDividerTitle>

              <LeadActionsCheckbox
                actions={lead.actions}
                publicLeads={publicLeads}
              />
            </ContentWithVertDividerWrapper>
          )}
          <MeetingsInfoFromMI meetingsInfos={meetingInfoFromMI} />
        </Flex>

        {lead.tags?.length > 0 && (
          <Box mt={2}>
            <LeadTags tags={lead.tags} />
          </Box>
        )}
      </Flex>
      {isMobile ? (
        <Divider orientation="horizontal" my={8} />
      ) : (
        <Divider orientation="vertical" />
      )}

      <Flex
        flexDir="column"
        w={['100%', '50%']}
        maxH={['50%', 0]}
        justify="space-between"
        pl={['0', '40px']}
      >
        <Box>
          {currentQuestions && (
            <SHQuestionsForm
              currentQuestions={currentQuestions}
              handleSaveQuestions={handleSaveQuestions}
              filialName={lead.meetings[0]?.filialName}
              filialId={lead.meetings[0]?.filialId}
              setCurrentQuestions={setCurrentQuestions}
            />
          )}
        </Box>

        <BaseButtonGroup pb={4}>
          {lead.usedLeads && (
            <SecondaryButton onClick={handleDeclineLead}>
              Não atendeu
            </SecondaryButton>
          )}

          <SecondaryButton onClick={handleDecline}>
            Declínio da oferta
          </SecondaryButton>

          <SecondaryButton onClick={() => handleSaveQuestions('callback')}>
            Retornar
          </SecondaryButton>

          {lead.meetings?.length > 0 &&
          lead.meetings[lead.meetings.length - 1]?.status === 'created' ? (
            <>
              <SecondaryButton onClick={() => handleSaveQuestions('meeting')}>
                Reagendar reunião
              </SecondaryButton>
              <PrimaryButton onClick={handleToggleRouteMeetingModal}>
                Rotear reunião
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton onClick={() => handleSaveQuestions('meeting')}>
              Marcar reunião
            </PrimaryButton>
          )}
        </BaseButtonGroup>
      </Flex>

      <ModalWrapper isOpen={decline} onClose={handleDecline}>
        <DeclineOffer
          handleShowModal={handleDecline}
          expiredLead={expiredLead}
          id={lead.id}
          leadId={lead.id}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleNextLead={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          leadType="leadspublic"
          numberOfMeetings={lead.meetings.length}
          meetingStatus={
            lead.meetings.length > 0 ? lead.meetings[0].status : null
          }
        />
      </ModalWrapper>

      <ModalWrapper isOpen={displayModal} onClose={handleDisplayModal}>
        <SHScheduleMeeting
          selectedLead={selectedLead}
          setExpiredLead={setExpiredLead}
          setSelectedLead={setSelectedLead}
          meetingInfos={lead.meetings[lead.meetings?.length - 1]}
          typeOfSchedule={typeOfSchedule}
          handleShowModal={handleDisplayModal}
          leadEmployeeId={lead.employeeId}
          publicLeads={publicLeads}
          setPublicLeads={setPublicLeads}
          lead={lead}
          questionsInfo={currentQuestions}
          filteredLeads={filteredLeads}
          setFilteredLeads={setFilteredLeads}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={routeMeetingModal}
        onClose={() => setRouteMeetingModal(!routeMeetingModal)}
      >
        <SHRouteMeeting
          lead={lead}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          setExpiredLead={setExpiredLead}
          meeting={lead.meetings[lead.meetings.length - 1]}
          handleToggleModal={() => setRouteMeetingModal(!routeMeetingModal)}
          handleBackToAllLeads={handleBackToAllLeads}
        />
      </ModalWrapper>
    </Flex>
  );
};
