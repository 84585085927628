import React from 'react';
import { Flex, FlexProps, Text, Tooltip } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

interface ComponentProps extends FlexProps {
  title: string;
  Icon?: IconType;
  altIcon?: string;
  setCommentedAreas: React.Dispatch<React.SetStateAction<string[]>>;
  commentedAreas: string[];
}

export const CommentedAreasButton: React.FC<ComponentProps> = ({
  title,
  Icon,
  altIcon,
  setCommentedAreas,
  commentedAreas,
}) => {
  const handleSelectCommentedArea = () => {
    const isAreaAlreadySelected = commentedAreas.find(
      item => item.trim() === title
    );

    if (!isAreaAlreadySelected) {
      setCommentedAreas([...commentedAreas, title]);
      return;
    }

    const listWithUnselectedArea = commentedAreas.filter(
      item => item.trim() !== title
    );
    setCommentedAreas(listWithUnselectedArea);
  };

  return (
    <Tooltip
      hasArrow
      label={title}
      placement="top"
      color="white"
      bg="background.200"
      fontStyle="italic"
      fontWeight={'light'}
    >
      <Flex
        width="50px"
        flexDir="column"
        fontSize="sm"
        color="white"
        cursor="pointer"
        mr={3}
        height="50px"
        border="1px solid"
        borderColor="primary.600"
        borderRadius="2"
        position="relative"
        justifyContent="center"
        alignItems="center"
        background={
          commentedAreas.find(item => item.trim() === title)
            ? 'primary.600'
            : 'transparent'
        }
        transition="all 0.2s ease-in"
        onClick={handleSelectCommentedArea}
        _hover={{ bg: 'primary.900' }}
      >
        {Icon && <Icon size={24} />}
        {!Icon && altIcon && <Text fontWeight="bold">{altIcon}</Text>}
      </Flex>
    </Tooltip>
  );
};
