import { Flex } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import {
  useVisualizationMode,
  VisualizationModeEnum,
} from 'hooksV2/useVisualizationMode';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { IReduxState } from 'shared/interfaces';
import { ClientsFilters } from './components/filters';
import { ClientsMosaic } from './components/mosaic';
import { ClientsSkeletonLoading } from './components/skeletonLoading';
import { ClientsTable } from './components/table';
import { useFilterAndSortClients } from './hooks/useFilterAndSortClients';

export const Clients = (): JSX.Element => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const visualizationModeHook = useVisualizationMode();

  const { data: clients, isLoading: isLoadingClients } = useQuery({
    queryKey: ['clients', user.id],
    queryFn: async () => {
      const response = await hubApiClients.getMyClients();
      return response;
    },
  });

  const {
    filteredClients,
    clientNameOrBtgCodeFilter,
    sortClientsByFilter,
    clientsFilter,
    clientsTagFilter,
  } = useFilterAndSortClients(clients);

  if (isLoadingClients || !filteredClients) {
    return <ClientsSkeletonLoading />;
  }

  return (
    <PageWrapper paddingTop="2rem">
      <MainPageTitle color="rgba(70, 70, 70, 1)">Meus Clientes</MainPageTitle>

      <Flex flexDirection="column" gap={4}>
        <ClientsFilters
          sortClientsByFilter={sortClientsByFilter}
          clientsFilter={clientsFilter}
          clientsTagFilter={clientsTagFilter}
          clientNameOrBtgCodeFilter={clientNameOrBtgCodeFilter}
          visualizationModeHook={visualizationModeHook}
        />

        {visualizationModeHook.visualizationModeValue ===
          VisualizationModeEnum.MOSAIC && (
          <ClientsMosaic clients={filteredClients} />
        )}

        {visualizationModeHook.visualizationModeValue ===
          VisualizationModeEnum.TABLE && (
          <ClientsTable clients={filteredClients} />
        )}
      </Flex>
    </PageWrapper>
  );
};
