import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultCardWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

import { LoadingSpinner } from 'components/elements/others';
import { useQuery } from 'react-query';
import HubApi from '../../../../../services/hubApi';
import ConfirmFeedback from '../ConfirmFeedback';

import EmployeeInfosEditable from '../../../../../components/employeeInfos/EmployeeInfosEditable';
import { IEmployeeInfo } from './types';

type IProps = {
  employeeId: string;
  handleCloseFatherModal: () => void;
};

const EmployeeProfile: React.FC<IProps> = ({
  employeeId,
  handleCloseFatherModal,
}) => {
  const [isOpenConfirmContractModal, setIsOpenConfirmContractModal] =
    useState<boolean>(false);
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeInfo>(
    {} as IEmployeeInfo
  );

  const { isLoading: isLoadingEmployeeInfo } = useQuery({
    queryKey: ['employeeInfo', employeeId],
    queryFn: async () => {
      const response = await HubApi.getEmployeeInfos(employeeId);
      setEmployeeInfo(response);
    },
    enabled: !Object.keys(employeeInfo).includes('id'),
  });

  const sendRhFeedbackOfEmployeeForm = async (status: string | undefined) => {
    setEmployeeInfo({ ...employeeInfo, status: status ?? '' });
    setIsOpenConfirmContractModal(true);
  };

  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeInfo({ ...employeeInfo, [e.target.name]: e.target.value });
  };

  const handleChangeDropDownValue = (position: string) => {
    setEmployeeInfo({ ...employeeInfo, position });
  };

  const handleChangeDropDownSalaryOption = (salaryOption: string) => {
    setEmployeeInfo({ ...employeeInfo, salaryType: salaryOption });
  };

  const handleChangeBankValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeInfo({
      ...employeeInfo,
      bank: {
        ...employeeInfo.bank,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeAddressValues = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmployeeInfo({
      ...employeeInfo,
      address: {
        ...employeeInfo.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  if (
    isLoadingEmployeeInfo ||
    !employeeInfo ||
    Object.keys(employeeInfo).length < 1
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DefaultCardWrapper flexDir="column">
        <MainPageTitle>{employeeInfo.name} </MainPageTitle>

        <EmployeeInfosEditable
          employeeInfo={employeeInfo}
          handleChangeValues={handleChangeValues}
          handleChangeAddressValues={handleChangeAddressValues}
          handleChangeBankValues={handleChangeBankValues}
          handleChangeDropDownValue={handleChangeDropDownValue}
          handleChangeDropDownSalaryOption={handleChangeDropDownSalaryOption}
          fullPage
        />
        <BaseButtonGroup>
          <SecondaryButton
            onClick={handleCloseFatherModal}
            style={{ margin: 0 }}
          >
            Cancelar
          </SecondaryButton>
          <SecondaryButton
            onClick={() => sendRhFeedbackOfEmployeeForm(employeeInfo.id)}
            marginRight="10px"
            background="#d11306"
            _hover={{ opacity: '0.7' }}
            style={{ margin: 0 }}
            border="none"
            color="white"
          >
            Recusar Formulário
          </SecondaryButton>
          <PrimaryButton
            onClick={() => sendRhFeedbackOfEmployeeForm(undefined)}
            style={{ margin: 0 }}
          >
            Confirmar formulário
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultCardWrapper>

      <ModalWrapper
        isOpen={isOpenConfirmContractModal}
        onClose={() => setIsOpenConfirmContractModal(false)}
      >
        <ConfirmFeedback
          employeeInfo={employeeInfo}
          handleCloseAllModals={handleCloseFatherModal}
        />
      </ModalWrapper>
    </>
  );
};

export default EmployeeProfile;
