import React, { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';
import { OrderProps } from '../../interfaces';

interface IModalProps {
  selectedOrder: OrderProps;
  handleToggleModal: () => void;
}

interface IAreaEmployee {
  id: string;
  name: string;
  orderMKCRMMkt: boolean;
}

const Modal: React.FC<IModalProps> = ({ selectedOrder, handleToggleModal }) => {
  // const [title, setTitle] = useState('Colaborador');
  const [areaEmployees, setAreaEmployees] = useState<IAreaEmployee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<OrderProps>();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateEmployeeOrder = (employee: any) => {
    setSelectedEmployee(employee);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    hubApiHome
      .updateEmployeeOrder(
        selectedOrder.orderId,
        selectedEmployee,
        selectedOrder.status,
        selectedOrder.area
      )
      .then(() => {
        toast.dark(
          'Ordem transferida com sucesso, a mesma sumirá quando a pagina for recarregada'
        );
      })
      .catch(() => {
        toast.dark('Erro ao transferir a ordem.');
      });
    handleToggleModal();
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedOrder.area === 'Câmbio') {
      hubApiHome.listEmployeesExchange().then(response => {
        setAreaEmployees(response);
      });
    } else if (selectedOrder.area === 'RH') {
      hubApiHome.listEmployeesRh().then(response => {
        setAreaEmployees(response);
      });
    } else if (selectedOrder.area === 'IP') {
      hubApiHome.listEmployeesIp().then(response => {
        setAreaEmployees(response);
      });
    } else if (selectedOrder.area === 'Guru') {
      hubApiHome.listEmployeesGuru().then(response => {
        setAreaEmployees(response);
      });
    } else if (selectedOrder.area === 'TI') {
      hubApiHome.listEmployeesTi().then(response => {
        setAreaEmployees(response);
      });
    } else if (selectedOrder.area === 'MKT') {
      hubApiHome.listEmployeesMk().then(response => {
        setAreaEmployees(response);
      });
    } else if (
      selectedOrder.area === 'Estratégia RV' ||
      selectedOrder.area === 'Estruturada RV' ||
      selectedOrder.area === 'Específica RV'
    ) {
      hubApiHome.listEmployeesRv().then(response => {
        setAreaEmployees(response);
      });
    }
  }, []);

  return (
    <BaseModalFormCard
      isModal
      title="Transferir Ordem"
      handleFormSubmit={handleSubmit}
      handleToggleModal={handleToggleModal}
      isLoading={isLoading}
      primaryButtonText="Transferir"
      secondaryButtonText="Fechar"
      maxW="500px"
    >
      <Dropdown
        placeholder="Selecione"
        onChange={event => handleUpdateEmployeeOrder(event.target.value)}
      >
        {areaEmployees.map((item, key) => (
          <option key={key} value={item.id}>
            {`${item.name}`}
          </option>
        ))}
      </Dropdown>
    </BaseModalFormCard>
  );
};

export default Modal;
