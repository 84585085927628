import { Flex, Switch, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { ModalWrapper } from 'components/elements/wrappers';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { TableBase } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClientType } from 'sharedV2/types/client';
import { showToast } from 'utilsV2/showToast';
import { z } from 'zod';

type PropsType = {
  isOpenContactFrequencyModalFilter: UseSimpleFilterResponseType;
  client: ClientType;
};

const schema = z.object({
  contactFrequency: z.string().optional(),
});

type FormFieldsType = z.infer<typeof schema>;

export const ClientDetailsContactFrequencyModal: React.FC<PropsType> = ({
  isOpenContactFrequencyModalFilter,
  client,
}): JSX.Element => {
  const [isContactFrequencyActive, setIsContactFrequencyActive] =
    useState<boolean>(client.isContactFrequencyActive);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient({
      onSuccess: async () => {
        isOpenContactFrequencyModalFilter.handleDeleteParam();

        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
        await queryClient.invalidateQueries(['clientHistory', client.btgCode]);
      },
    });

  const [
    isOpenContactFrequencyGuidelineModal,
    setIsOpenContactFrequencyGuidelineModal,
  ] = useState<boolean>(false);

  const tableHeadData = [
    { columnName: 'Patrimônio Líquido', widthPercentage: 100 },
    { columnName: 'Frequência Ideal', widthPercentage: 100 },
  ];

  const handleUpdateContactFrequency: SubmitHandler<FormFieldsType> =
    async data => {
      if (
        isContactFrequencyActive &&
        (data.contactFrequency === undefined || data.contactFrequency === '')
      ) {
        return showToast(
          'Coloque um dia para podermos ativar a frequência de contato.'
        );
      }

      await updateClient({
        ...client,
        contactFrequency: Number(data.contactFrequency),
        isContactFrequencyActive,
      });
    };

  useEffect(() => {
    setValue('contactFrequency', client.contactFrequency?.toString());
  }, []);

  return (
    <>
      <Modal.Root>
        <Modal.Header>
          <Modal.Title title="Frequência de Contato" />
          <Modal.CloseIcon
            onClick={() =>
              isOpenContactFrequencyModalFilter.handleDeleteParam()
            }
          />
        </Modal.Header>

        <Flex flexDirection="column" gap={3}>
          <Flex gap={2}>
            <Text color="rgba(70, 70, 70, 1)">Ativar a frequência?</Text>
            <Switch
              isChecked={isContactFrequencyActive}
              onChange={() => setIsContactFrequencyActive(prev => !prev)}
            />
          </Flex>

          <Text color="rgba(70, 70, 70, 1)">
            Desejo que o cliente {client.name} tenha 1 contato a cada
          </Text>

          <Input.Root>
            <Input.TextField type="number" {...register('contactFrequency')} />
            <Input.ErrorMessage message={errors?.contactFrequency?.message} />
          </Input.Root>
          <Text color="rgba(70, 70, 70, 1)">
            (Coloque a quantidade de dias acima)
          </Text>
        </Flex>

        <Text
          position="absolute"
          bottom={5}
          left={5}
          color="primary.100"
          fontSize="sm"
          cursor="pointer"
          _hover={{ textDecoration: 'underline' }}
          onClick={() => setIsOpenContactFrequencyGuidelineModal(true)}
        >
          Ver diretrizes de frequência
        </Text>

        <Modal.Actions>
          <Modal.Action
            text="Atualizar"
            isLoading={isLoadingUpdateClient}
            onClick={handleSubmit(handleUpdateContactFrequency)}
          />
        </Modal.Actions>
      </Modal.Root>

      <ModalWrapper
        isOpen={isOpenContactFrequencyGuidelineModal}
        onClose={() => setIsOpenContactFrequencyGuidelineModal(false)}
      >
        <Modal.Root w="600px">
          <Modal.Header>
            <Modal.Title title="Diretrizes de contato com o cliente" />
            <Modal.CloseIcon
              onClick={() => setIsOpenContactFrequencyGuidelineModal(false)}
            />
          </Modal.Header>

          <Flex flexDirection="column" gap={4}>
            <Text color="rgba(70, 70, 70, 1)">
              Para melhorar a experiência de nossos clientes, temos que entrar
              em contato com eles com uma certa frequência. Estabelecemos que a
              frequência ideal será sempre baseada no patrimônio líquido do
              cliente. Por exemplo, se um cliente tem um patrimônio líquido de
              até 100 mil reais, então você deve entrar em contato com ele a
              cada 180 dias em média (ver tabela abaixo).
            </Text>

            <TableBase headData={tableHeadData}>
              <TableRow headData={tableHeadData}>
                <Text>0 a 100 mil</Text>
                <Text>180 dias</Text>
              </TableRow>
              <TableRow headData={tableHeadData}>
                <Text>100 a 300 mil</Text>
                <Text>90 dias</Text>
              </TableRow>
              <TableRow headData={tableHeadData}>
                <Text>300 mil a 1MM</Text>
                <Text>60 dias</Text>
              </TableRow>
              <TableRow headData={tableHeadData}>
                <Text>1MM +</Text>
                <Text>30 dias</Text>
              </TableRow>
            </TableBase>
          </Flex>

          <Modal.Actions>
            <Modal.Action
              text="Ok"
              onClick={() => setIsOpenContactFrequencyGuidelineModal(false)}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </>
  );
};
