import React, { FormEvent, useEffect, useState } from 'react';
import {
  Center,
  Divider,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { formatToBRL } from 'brazilian-values';

import { Dropdown } from 'components/elements/others';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { IChartPieData } from 'shared/interfaces/AAI';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { transformYearMonthInStringMonth } from 'utils/formatDate';
import { RiEdit2Fill } from 'react-icons/ri';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { errorHandler } from 'utils';
import { toast } from 'react-toastify';
import { DividerItens, FinancialPieChart } from '../../components/modules';
import { InputDatas, InputInfoDisplay } from '../../components/texts';

interface IAcessPage {
  employee: EmployeesProps;
}

interface IYearDate {
  total: number;
  referring_month: string;
}

interface IBankDate {
  bank: string;
  accountNumber: string;
  agency: string;
}

export const FinancialPage: React.FC<IAcessPage> = ({ employee }) => {
  const [loading, setIsLoading] = useState(false);
  const user = useSelector((state: IReduxState) => state.user.profile);

  const [updateBankDate, setUpdateBankDate] = useState({} as IBankDate);

  const handleChangeBankData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdateBankDate({
      ...updateBankDate,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const bank = {
      bank: {
        accountNumber: updateBankDate.accountNumber,
        agency: updateBankDate.agency,
        bank: updateBankDate.bank,
      },
    };

    try {
      await hubApiUser.updateBankEmployee(employee.id, bank);

      toast.dark('Dados atualizados com sucesso!');
    } catch (err) {
      errorHandler(err);
    }
    setIsLoading(false);
  };

  const [showModalUpdateBank, setShowModalUpdateBank] = useState(false);

  const [trimestre] = useState([
    { title: '2021', value: '2021' },
    { title: '2022', value: '2022' },
  ]);

  const [dataChartPie, setDataChartPie] = useState<IChartPieData[]>([
    {
      id: '1',
      label: 'Default',
      value: 100,
      color: '#FFFFFF',
    },
  ]);
  const [total, setTotal] = useState<number>(0);
  const [datasYear, setDatasYear] = useState<IYearDate[]>([]);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const setDatasYearDropdownSelected = async (year = '2021') => {
    const response = await hubApiFinancial.getDataYearFinancialEmployee(
      year,
      employee.id
    );

    setDatasYear(response.data);
  };

  const handleSelectDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDatasYearDropdownSelected(event.target.value);
  };

  useEffect(() => {
    setDatasYearDropdownSelected();

    if (employee.bank) {
      setUpdateBankDate({
        ...updateBankDate,
        accountNumber: employee.bank.accountNumber,
        agency: employee.bank.agency,
        bank: employee.bank.bank,
      });
    }
  }, []);

  return (
    <Flex
      w="100%"
      borderRadius={4}
      bg="#f2f2f2"
      gridGap={6}
      p={4}
      flexDirection={['column', 'row']}
    >
      <Stack w="100%" flexDir="column" mb="48px" spacing={6}>
        <Dropdown bg="#E7E7E7" defaultValue="2021" onChange={handleSelectDate}>
          {trimestre.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            );
          })}
        </Dropdown>

        <Stack
          spacing={1}
          bg="#E7E7E7"
          borderRadius="4"
          p={2}
          height={['200px', 'auto']}
          overflowY={['auto', 'unset']}
        >
          {datasYear.length > 0 ? (
            datasYear.map((item, index) => {
              return (
                <InputDatas
                  key={index}
                  label={transformYearMonthInStringMonth(item.referring_month)}
                  dateValue={item.referring_month}
                  valueItem={formatToBRL(item.total)}
                  id={employee.id}
                  setDataChartPie={setDataChartPie}
                  setTotal={setTotal}
                />
              );
            })
          ) : (
            <Text color="rgba(70, 70, 70, 1)">Não foram encontrado dados</Text>
          )}
        </Stack>
      </Stack>

      <Center>
        <Divider
          orientation={isMobile ? 'horizontal' : 'vertical'}
          type="gray"
        />
      </Center>

      <Flex w="100%" flexDir="column">
        <DividerItens
          mb="12px"
          orientationDivider="horizontal"
          titleDivider="Divisão da receita do ano selecionado"
        />

        <FinancialPieChart data={dataChartPie} total={total} />

        {employee.bank && (
          <>
            <DividerItens
              mb="12px"
              orientationDivider="horizontal"
              titleDivider="Dados bancários"
            />

            <Stack spacing={1}>
              <Flex justify="space-between" align="center">
                {verifyEmployeePermission({
                  user,
                  permissionCod: ['GTM', 'GTR'],
                  areaCod: ['ASS', 'RH'],
                })}
                <InputInfoDisplay
                  field="Banco"
                  data={updateBankDate.bank}
                  fontSize="sm"
                />

                <Icon
                  as={RiEdit2Fill}
                  cursor="pointer"
                  size={16}
                  onClick={() => setShowModalUpdateBank(!showModalUpdateBank)}
                />
              </Flex>
              <InputInfoDisplay
                field="Agência"
                data={updateBankDate.agency}
                fontSize="sm"
              />
              <InputInfoDisplay
                field="Número da conta"
                data={updateBankDate.accountNumber}
                fontSize="sm"
              />
            </Stack>
          </>
        )}
      </Flex>
      <ModalWrapper
        isOpen={showModalUpdateBank}
        onClose={() => setShowModalUpdateBank(!showModalUpdateBank)}
      >
        <BaseModalFormCard
          maxW="560px"
          title="Atualizar dados bancarios"
          isLoading={loading}
          handleFormSubmit={handleFormSubmit}
          handleToggleModal={() => setShowModalUpdateBank(!showModalUpdateBank)}
          isModal
        >
          <SimpleGrid columns={1} gridGap="5">
            <InputWithLabel
              label="Banco"
              name="bank"
              value={updateBankDate.bank}
              onChange={handleChangeBankData}
            />
            <InputWithLabel
              label="Agência"
              name="agency"
              value={updateBankDate.agency}
              onChange={handleChangeBankData}
            />

            <InputWithLabel
              name="accountNumber"
              label="Número da conta"
              value={updateBankDate.accountNumber}
              onChange={handleChangeBankData}
            />
          </SimpleGrid>
        </BaseModalFormCard>
      </ModalWrapper>
    </Flex>
  );
};
