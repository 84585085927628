import { Box, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'components/elements/others';
import { IFilterUsersProps } from 'pages/IP/interfaces';
import { ManagementSegmentCardData } from 'pages/IP/components/modules/Management';
import { SecondaryButton } from 'components/elements/buttons';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface SegmentCardProps {
  segmentInfos: {
    title: string;
    segment: string;
    allowedEmployees: IFilterUsersProps[];
    options: {
      title: string;
      value: number;
      status: string;
    }[];
  };
  handleSelectedEmployee: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedEmployee: string;
}

export const SegmentCard: React.FC<SegmentCardProps> = ({
  segmentInfos,
  handleSelectedEmployee,
  selectedEmployee,
}) => {
  return (
    <Flex flexDirection="column" alignItems="center" mb={6}>
      <ManagementSegmentCardData item={segmentInfos} />

      <Dropdown
        mt="3"
        placeholder={
          segmentInfos.allowedEmployees.length < 1
            ? 'Não há colaboradores nesta área'
            : 'Selecione um colaborador'
        }
        onChange={handleSelectedEmployee}
      >
        {segmentInfos.allowedEmployees.map(item => (
          <option key={item.id} value={[item.id, item.name]}>
            {formatNameToCaptalize(item.name)}
          </option>
        ))}
      </Dropdown>

      {selectedEmployee.length > 0 && (
        <Box mt="2">
          {segmentInfos.segment === 'vida' ? (
            <Link
              to={`/v2/board/life?employeeId=${selectedEmployee.split(',')[0]}`}
            >
              <SecondaryButton>Visualizar CRM</SecondaryButton>
            </Link>
          ) : (
            <Link
              to={`/board/manager/${segmentInfos.segment}/${
                selectedEmployee.split(',')[0]
              }/${selectedEmployee.split(',')[1]}`}
            >
              <SecondaryButton>Visualizar CRM</SecondaryButton>
            </Link>
          )}
        </Box>
      )}
    </Flex>
  );
};
