import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { FcSurvey } from 'react-icons/fc';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatDate } from 'utils/formatDate';

interface CalendarItemProps {
  subContent: any;
  clientName: string;
  pendecyName: string;
  IconElement?: IconType;
  iconSrc?: string;
  handleRedirectUser: (btgCode: string) => void;
}

export const CalendarItem: React.FC<CalendarItemProps> = ({
  clientName,
  subContent,
  pendecyName,
  IconElement,
  iconSrc,
  handleRedirectUser,
}) => {
  return (
    <>
      <Flex
        onClick={() => handleRedirectUser(subContent.btgAccount)}
        w="100%"
        p="2"
        px="30px"
        borderRadius="19px"
        align="center"
        border="1px solid #3071E4"
        justify="space-between"
        _hover={{
          background: '#3071E4',
        }}
        transition="ease-in-out 0.4s"
        cursor="pointer"
        h="60px"
        gridGap={2}
        overflowY="auto"
      >
        <Flex align="center" justify="center">
          {IconElement ? (
            <Icon as={IconElement} h={[5, 8]} w={[5, 8]} mr="1" />
          ) : (
            <Image src={iconSrc} alt="ícone de tarefa" h="7" w="7" mr="1" />
          )}

          <Text
            maxW={['100px', '180px']}
            minW={['100px', '180px']}
            fontSize={['sm', 'md']}
            isTruncated
          >
            {pendecyName}
          </Text>
        </Flex>

        <Flex gridGap={5} w={'500px'} mr="9">
          <Flex>
            <Text
              color="white"
              isTruncated
              minW={['80px', '120px', '280px']}
              maxW={['80px', '120px', '280px']}
              fontSize={['sm', 'md']}
            >
              {formatNameToCaptalize(clientName)}
            </Text>
          </Flex>

          <Flex>
            <Text fontSize={['sm', 'md']} fontWeight="light">
              ({subContent.btgAccount})
            </Text>
          </Flex>
        </Flex>

        <Flex>
          <Icon as={FaRegArrowAltCircleRight} h="6" w="6" />
        </Flex>
      </Flex>
    </>
  );
};
