import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { DeclineAndFrequencyDataBox } from './DeclineAndFrequencyDataBox';

interface DeclinedDatasetProps {
  declined: {
    btg_client: string;
    lgpd: string;
    no_answer: string;
    no_exist: string;
    no_interest: string;
    no_money: string;
  };
  totalDeclined: number;
  isSmallVersion?: boolean;
}

export const DeclinedDataset: React.FC<DeclinedDatasetProps> = ({
  declined,
  totalDeclined,
  isSmallVersion,
}): JSX.Element => {
  const computeSpecificDeclinePercentage = (declineAmount: number): string => {
    if (declineAmount === 0 && totalDeclined === 0) {
      return '0%';
    }

    const percentage = (declineAmount / totalDeclined) * 100;

    return `${percentage.toFixed(2)}%`;
  };

  return (
    <Flex width="100%" justifyContent="flex-end" align="center">
      <Text pr="16px">Declínio</Text>

      <SimpleGrid
        columns={3}
        gap={2}
        w="100%"
        maxW={isSmallVersion ? '450px' : '516px'}
      >
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Não existe"
          value={computeSpecificDeclinePercentage(Number(declined.no_exist))}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Sem resposta"
          value={computeSpecificDeclinePercentage(Number(declined.no_answer))}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Sem interesse"
          value={computeSpecificDeclinePercentage(Number(declined.no_interest))}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Sem dinheiro"
          value={computeSpecificDeclinePercentage(Number(declined.no_money))}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Cliente BTG"
          value={computeSpecificDeclinePercentage(Number(declined.btg_client))}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Não ligar LGPD"
          value={computeSpecificDeclinePercentage(Number(declined.lgpd))}
        />
      </SimpleGrid>
    </Flex>
  );
};
