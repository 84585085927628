import { formatToCapitalized } from 'brazilian-values';

const formatToPhone = (phone: string) => {
  const PHONE_HAVE_DDD = phone.length > 8;

  if (!PHONE_HAVE_DDD) {
    return `${phone.slice(0, 4)}-${phone.slice(4)}`;
  }

  return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
};

export class TextUtils {
  static toCapitalize(value: string): string {
    return formatToCapitalized(value, {
      trimTrailingWhiteSpaces: false,
    });
  }

  static toPhone(
    value: string,
    config?: { includeBrazilCountryCode?: boolean }
  ): string {
    if (!value) return '';

    const PHONE_ALREADY_HAVE_BRAZIL_COUNTRY_CODE =
      value.charAt(0) === '5' && value.charAt(1) === '5';

    if (
      config?.includeBrazilCountryCode &&
      !PHONE_ALREADY_HAVE_BRAZIL_COUNTRY_CODE
    )
      return `+55 ${formatToPhone(value)}`;

    if (
      config?.includeBrazilCountryCode &&
      PHONE_ALREADY_HAVE_BRAZIL_COUNTRY_CODE
    )
      formatToPhone(value);

    if (PHONE_ALREADY_HAVE_BRAZIL_COUNTRY_CODE) {
      return formatToPhone(value.slice(2));
    }

    return formatToPhone(value);
  }

  static copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value);
  }
}
