import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers/ModalWrapper';

import { DefaultModalContentWrapper } from 'components/elements/wrappers/DefaultModalContentWrapper';
import { InputWithLabel } from 'components/elements/forms/InputWithLabel';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts/ModalTitle';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

const EletronicSignature: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [formInfos, setFormInfos] = useState({
    passwordEletronic: '',
  });

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
      setShowModal(true);
    }
  }, []);

  const handleLastAuth = () => {
    localStorage.removeItem('lastAuth');

    const currentTime = Date.now();

    localStorage.setItem('lastAuth', currentTime.toString());
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await hubApiUser
      .postCompareteSignature(formInfos)
      .then(() => {
        toast.dark('Autenticado');
        handleLastAuth();
        setShowModal(!showModal);

        window.history.back();
      })
      .catch(() => {
        toast.dark('Senha incorreta');
      });
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <>
      {showModal && (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <ModalWrapper isOpen onClose={() => {}}>
          <DefaultModalContentWrapper width={['95vw', '490px']}>
            <ModalTitle color="rgba(70, 70, 70, 1)">
              Assinatura eletrônica
            </ModalTitle>
            <form onSubmit={handleFormSubmit}>
              <InputWithLabel
                label="Senha"
                type="password"
                placeholder="Digite sua assinatura"
                name="passwordEletronic"
                value={formInfos.passwordEletronic}
                onChange={handleChangeValue}
              />
              <Flex marginTop="20px" justifyContent="flex-end">
                <SecondaryButton onClick={handleCancel} marginRight="20px">
                  Cancelar
                </SecondaryButton>

                <PrimaryButton type="submit">Confirmar</PrimaryButton>
              </Flex>
            </form>
          </DefaultModalContentWrapper>
        </ModalWrapper>
      )}
    </>
  );
};

export default EletronicSignature;
