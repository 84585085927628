import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import { LoadingSpinner } from 'components/elements/others';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { ModalWrapper } from 'components/elements/wrappers';

import { IMonthlyCommissionTotal } from 'pages/Remuneration/interfaces';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { formatDate } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { TableRevenueDetailsModal } from './TableRevenueDetailsModal';

interface RevenueProps {
  clientBTGAccount: string;
}

export const Revenue: React.FC<RevenueProps> = ({ clientBTGAccount }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchDate, setSearchDate] = useState(
    String(new Date().toISOString().substring(0, 7))
  );
  const [monthlyCommissionTotal, setMonthlyCommissionTotal] = useState<
    IMonthlyCommissionTotal[]
  >([]);

  const tableHeaders = ['Mês', 'Comissão AAI'];

  const getMonthlyRemuneration = async () => {
    try {
      setIsLoading(true);
      const response = await hubApiFinancial.getMonthlyCommission(
        clientBTGAccount
      );
      if (response.length < 1) {
        toast.dark('Este cliente não possui dados de comissionamento');
        setIsLoading(false);
      }
      setMonthlyCommissionTotal(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.dark('Não foi possível carregar os dados.Tente novamente');
    }
  };

  const handleComissionDetails = (date: string) => {
    if (date) {
      setSearchDate(date);
      setShowModal(true);
    }
  };

  const formattedDate = (date: string) => {
    const dateWithFormatting = formatDate(date).split('1 de');
    return formatToCapitalized(dateWithFormatting[1]);
  };

  useEffect(() => {
    getMonthlyRemuneration();
  }, [clientBTGAccount]);
  return (
    <Flex
      alignItems="center"
      w="100%"
      height={['60vh', 'calc(100vh - 224px)']}
      justifyContent="center"
      flexDirection="column"
    >
      <Flex align="center" mt="3" justify="center" flexDir="column" w="100%">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <TableBase
              headData={tableHeaders}
              w="100%"
              height={['60vh', 'calc(100vh - 224px)']}
              maxW="100%"
              maxH="calc(100vh - 280px)"
              overflowY="auto"
              p={0}
              position="relative"
            >
              {monthlyCommissionTotal.length === 0 && (
                <Flex
                  width="100%"
                  height="90%"
                  alignItems="center"
                  justifyContent="center"
                  position="absolute"
                  color="gray"
                  fontStyle="italic"
                >
                  <Text>Não possui dados de receita</Text>
                </Flex>
              )}
              {monthlyCommissionTotal.map(data => (
                <TableRow
                  key={data.date}
                  _hover={{ background: 'background.500' }}
                  cursor="pointer"
                  transition="400ms"
                  onClick={() => handleComissionDetails(data.date)}
                >
                  <TableData maxW="150px">{formattedDate(data.date)}</TableData>
                  <TableData maxW="150px">
                    {formatValue(data.employeeCommission)}
                  </TableData>
                </TableRow>
              ))}
            </TableBase>

            <ModalWrapper
              isOpen={showModal}
              onClose={() => setShowModal(false)}
            >
              <TableRevenueDetailsModal
                clientBTGAccount={clientBTGAccount}
                searchDate={searchDate}
                setShowModal={setShowModal}
              />
            </ModalWrapper>
          </>
        )}
      </Flex>
    </Flex>
  );
};
