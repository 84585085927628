import React from 'react';
import { Link } from 'react-router-dom';

import mundoInvestLogo from 'shared/assets/images/logo-mi.svg';

interface ComponentProps {
  maxWidth?: number;
}

export const EwzLogo: React.FC<ComponentProps> = ({ maxWidth = 160 }) => {
  return (
    <Link to="/">
      <img
        style={{ width: '100%', maxWidth: `${maxWidth}px` }}
        src={mundoInvestLogo}
        alt="ewz logo"
      />
    </Link>
  );
};
