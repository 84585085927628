import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';

interface IProps {
  advisorName: string;
  startDate: string;
  endDate: string;
}

export const PreviousAdvsorClient: React.FC<IProps> = ({
  advisorName,
  endDate,
  startDate,
}) => {
  return (
    <Flex
      bgColor="#383838"
      w="100%"
      h="3.5rem"
      borderRadius="5px"
      p={2}
      flexDirection="column"
    >
      <Text fontSize={12}>
        De {startDate} Até {endDate}
      </Text>
      <Text color="white" fontSize={14}>
        {formatToCapitalized(advisorName)}
      </Text>
    </Flex>
  );
};
