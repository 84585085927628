import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import {
  Flex,
  Divider,
  Text,
  Button,
  Stack,
  ButtonGroup,
  Checkbox,
} from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { IReduxState, IRulesRedux } from 'shared/interfaces';
import { verifyPermission } from 'utils/verifyPermission';
import permissionsCods from 'shared/constants/permissionsCods';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import {
  Permissions,
  SubPermissions,
} from 'pages/SearchUserDetails/interfaces';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

import { AllowedPagesItem } from '../../modules';

interface ICardDetailAcessIp {
  value: Permissions;
  user: IReduxState;
  permissionsAll: Permissions[];
  idUser: string;
  updateCards: () => Promise<void>;
}

export const CardDetailAcessIp: React.FC<ICardDetailAcessIp> = ({
  value,
  user,
  permissionsAll,
  idUser,
  updateCards,
}) => {
  const [editPermissions, setEditPermissions] = useState(false);
  const [checksSelected, setChecksSelected] = useState<string[]>([]);
  const [currentPermissions, setCurrentPermissions] = useState<string[]>(
    value.permissions.map(permSelecteds => permSelecteds.ruleId)
  );

  const addNewPermission = async () => {
    try {
      for (let i = 0; i < checksSelected.length; i += 1) {
        const validate = currentPermissions.findIndex(
          item => item === checksSelected[i]
        );
        if (validate !== -1) {
          await hubApiUser.deletePermission(idUser, checksSelected[i]);
        } else {
          await hubApiUser.setPermission(idUser, checksSelected[i]);
        }
      }

      updateCards();
      toast.dark('Permissões atualizadas com sucesso!');
      setEditPermissions(!editPermissions);
      setChecksSelected([]);
    } catch (error) {
      toast.dark('Ocorreu um erro ao atualizar as permissões');
    }
  };

  const handleSelectedCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const verifyInclud = checksSelected.includes(event.target.value);
    if (verifyInclud) {
      setChecksSelected(
        checksSelected.filter(item => item !== event.target.value)
      );
    } else {
      setChecksSelected([...checksSelected, event.target.value]);
    }
  };

  const toggleCheckSelect = (perm: SubPermissions) => {
    const existCurrentPermission =
      currentPermissions.findIndex(item => item === perm.ruleId) !== -1;
    const existCheckSelected =
      checksSelected.findIndex(item => item === perm.ruleId) !== -1;
    if (existCheckSelected && existCurrentPermission) {
      return false;
    }
    return existCurrentPermission || existCheckSelected;
  };

  useEffect(() => {
    setCurrentPermissions(
      value.permissions.map(permSelecteds => permSelecteds.ruleId)
    );
  }, [value, permissionsAll]);

  return (
    <>
      <Flex
        flexDir="column"
        bg="background.600"
        w="100%"
        p={6}
        borderRadius="4px"
      >
        <Flex alignItems="flex-start" justifyContent="space-between" mb={4}>
          <Flex flexDir="column">
            <Text color="white" fontWeight="bold" fontSize="md">
              Área: {value.name}
            </Text>
            <Flex>
              <Text fontSize="sm" mr={1}>
                Permissão(ões):
              </Text>
              {value.permissions.map(permissionUser => (
                <Text mr={2} key={permissionUser.id} fontSize="sm">
                  {permissionUser.name}
                </Text>
              ))}
            </Flex>
          </Flex>

          {(user.position === permissionsCods.MASTER ||
            verifyPermission(user.rules as IRulesRedux[], value.cod)) && (
            <ButtonGroup spacing={1}>
              <Button
                bg="transparent"
                p="0px"
                _hover={{ background: 'transparent' }}
                onClick={() => setEditPermissions(!editPermissions)}
              >
                <FaEdit size={16} color="white" />
              </Button>
            </ButtonGroup>
          )}
        </Flex>
        <Divider />
        <Text color="gray.400" fontSize="sm" margin="12px 0px">
          Páginas permitidas nesse nível de acesso:
        </Text>
        <Flex w="100%" flexDir="row" flexWrap="wrap" gridGap="2">
          {value.permissions.map(pages => {
            return pages.pages.map((map, index) => (
              <AllowedPagesItem key={index}>{map}</AllowedPagesItem>
            ));
          })}
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={editPermissions}
        onClose={() => setEditPermissions(!editPermissions)}
      >
        <DefaultModalContentWrapper>
          <Stack spacing={4}>
            <Text color="white" fontSize="lg" fontWeight="semibold">
              Altere as permissões de {value.name} deste usuário
            </Text>

            <Stack direction="column">
              {permissionsAll.map(checkes => {
                return (
                  checkes.cod === permissionsCods.IP &&
                  checkes.permissions.map(perm => (
                    <Checkbox
                      key={perm.id}
                      value={perm.ruleId}
                      isChecked={toggleCheckSelect(perm)}
                      onChange={e => handleSelectedCheck(e)}
                      mb={0}
                    >
                      {perm.name}
                    </Checkbox>
                  ))
                );
              })}
            </Stack>

            <ButtonGroup justifyContent="flex-end">
              <SecondaryButton
                onClick={() => setEditPermissions(!editPermissions)}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton onClick={addNewPermission}>Salvar</PrimaryButton>
            </ButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
