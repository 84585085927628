import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { BackPageButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms/InputWithLabel';

import HubApi from 'services/hubApi';

import { PageWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { validEmailsList } from 'pages/RH/configs';
import { useMutation } from 'react-query';

const SendMailToEmployee: React.FC = () => {
  const [employeeEmail, setEmployeeEmail] = useState<string>('');

  const handleChangeEmailValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmployeeEmail(event.target.value);

  const { mutateAsync: sendEmailToNewEmployee, isLoading } = useMutation({
    mutationKey: ['sendEmailToNewEmployee'],
    mutationFn: async () => {
      await HubApi.sendEmailToNewEmployee(employeeEmail);
    },
    onSuccess: () => {
      toast.dark('E-mail enviado com sucesso');
      setEmployeeEmail('');
    },
    onError: () => {
      toast.dark('Erro ao tentar enviar e-mail');
    },
  });

  const validateEmployeeEmail = (): boolean => {
    return validEmailsList.includes(employeeEmail.toLowerCase().split('@')[1]);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isEmailValid = validateEmployeeEmail();

    if (!isEmailValid) {
      return toast.dark(
        'Informe o email corporativo EWZ, Mundo Invest, Ilha Forte ou Consultoria Mundo Invest'
      );
    }

    await sendEmailToNewEmployee();
  };

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'row' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />

      <BaseModalFormCard
        isModal={false}
        title="Nova Contratação"
        primaryButtonText="Enviar E-mail"
        isLoading={isLoading}
        handleFormSubmit={handleFormSubmit}
        hideSecondaryButton
        maxW="600px"
      >
        <InputWithLabel
          label="E-mail"
          placeholder="E-mail de quem será contratado"
          value={employeeEmail}
          onChange={handleChangeEmailValue}
          py="4"
        />
      </BaseModalFormCard>
    </PageWrapper>
  );
};

export default SendMailToEmployee;
