import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { Item } from 'pages/RV/components/modules/managerStructured/item';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { OrderStructured } from 'pages/RV/interfaces';

const ManagerStructureds: React.FC = () => {
  const [structureds, setStructureds] = useState<OrderStructured[]>([]);
  const [displayModal, setDisplayModal] = useState(false);

  const handleToggleModal = () => {
    setDisplayModal(!displayModal);
  };

  const getStructuredss = async () => {
    await hubApiRv
      .listAllStructureds()
      .then(response => {
        setStructureds(response);
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  useEffect(() => {
    getStructuredss();
  }, []);

  const handleDeleteOrder = async (id: string) => {
    if (window.confirm('Deseja deletar a estruturada?')) {
      try {
        await hubApiRv.deleteStructured(id).then(() => {
          getStructuredss();
          toast.dark('Estruturada deletada com sucesso.');
        });
      } catch (error) {
        toast.dark('Ocorre um erro ao deletar a Estruturada, tente novamente.');
      }
    }
  };
  return (
    <PageWrapper
      flexDir="column"
      alignItems="center"
      position="relative"
      paddingTop="48px"
    >
      <BackPageButton />
      <MainPageTitle textAlign="center">
        Crie ou Edite Estruturadas
      </MainPageTitle>

      <Flex w="100%" maxW="960px" flexDir="column">
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <SecondaryButton onClick={handleToggleModal}>
            <Link to="/rv/structureds/create">Criar Estruturada</Link>
          </SecondaryButton>
        </Flex>

        <DefaultCardWrapper
          w="100%"
          height="100vh"
          mt="16px"
          maxH="calc(100vh - 280px)"
          p="2"
          flexDir="column"
          boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
        >
          <Flex flexDir="column" height="100%" p="1.5" overflowY="auto">
            {structureds?.map((structured, key) => (
              <Item
                structureds={structured}
                key={key}
                handleDeleteOrder={handleDeleteOrder}
              />
            ))}
          </Flex>
        </DefaultCardWrapper>
      </Flex>
    </PageWrapper>
  );
};

export default ManagerStructureds;
