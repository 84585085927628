import { formatToBRL } from 'brazilian-values';

interface IProps {
  lead: any;
}

export const getMIPatrimony = ({ lead }: IProps) => {
  let MIPatrimony = '';
  MIPatrimony =
    lead.customFields?.find(
      (plMI: any) => plMI.fieldName === 'cf_patrimonio_declarado'
    )?.fieldValue || '';

  if (MIPatrimony !== '') {
    return formatToBRL(MIPatrimony);
  }
  return 'Não informado';
};
