import { Flex } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { IPoolList } from 'pages/DistributionOfMinedLeads/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TableDetails } from './Table';

interface IDetailsModalProps {
  onClose: () => void;
  allPoolLists: Array<IPoolList>;
}

interface IFrequencyDays {
  distributionDaysFrequency: string;
}

// TODO: refatorar componente, está fazendo requisições desnecessárias mesmo quando o número da frequencia nao é alterado
export const DetailsModal: React.FC<IDetailsModalProps> = ({
  onClose,
  allPoolLists,
}) => {
  const [freqDays, setFreqDays] = useState<IFrequencyDays>({
    distributionDaysFrequency: '',
  });

  useEffect(() => {
    getFrequencyDays();
  }, []);

  const getFrequencyDays = async () => {
    try {
      const frequencyDays = await hubApiCaptation.getFrequencyDays();
      setFreqDays({
        ...freqDays,
        distributionDaysFrequency: frequencyDays,
      });
    } catch (error) {
      toast.dark(
        'Não foi possível buscar o dado de frequência de dias.Tente novamente mais tarde.'
      );
    }
  };
  const patchFrequencyDays = async () => {
    if (Number(freqDays.distributionDaysFrequency) === 0) {
      toast.dark('O número de dias deve ser maior do que zero');
      return;
    }

    try {
      await hubApiCaptation.patchFrequencyDays({
        distributionDaysFrequency: Number(freqDays.distributionDaysFrequency),
      });
    } catch (error) {
      toast.dark(
        'Não foi possível atualizar a frequência de dias.Tente novamente mais tarde.'
      );
    }
  };
  return (
    <DefaultModalContentWrapper
      w="350px"
      height="400px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
      gap={1}
      color="#fff"
    >
      <ModalTitle>Frequência de envio</ModalTitle>
      <Flex
        flex={2}
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
      >
        <InputWithLabel
          label="Rodagem da automação (em dias)"
          width={300}
          type="number"
          onChange={e =>
            setFreqDays({
              distributionDaysFrequency: e.target.value,
            })
          }
          value={freqDays.distributionDaysFrequency}
        />
        <TableDetails allPoolLists={allPoolLists} />
      </Flex>
      <BaseButtonGroup>
        <SecondaryButton
          onClick={() => {
            onClose();
            patchFrequencyDays();
          }}
        >
          Fechar
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
