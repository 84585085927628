import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { BackPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import Board from 'pages/Technology/components/modules/technologyCRMComponent/Board';
import { hubApiTech } from 'services/hubApi/classes/HubApiTi';

import { techColumns } from 'pages/Technology/subpages/TechnologyCRM/data';
import { LoadingSpinner } from 'components/elements/others';

interface OrderProps {
  status: string;
}

const TechnologyCRM: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(techColumns);

  useEffect(() => {
    async function listOrders() {
      await hubApiTech
        .listUserOrders()
        .then(response => {
          for (const column of columns) {
            const tasks = response.filter(
              (order: OrderProps) =>
                order.status.toLowerCase() === column.value.toLowerCase()
            );
            column.tasks = tasks;
          }

          setIsLoading(false);
        })
        .catch(() => toast.dark('Ocorreu um erro ao buscar ordens'));
    }

    listOrders();
  }, []);

  return (
    <PageWrapper paddingTop="48px">
      <BackPageButton />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Board
          boardTitle="CRM - Tecnologia"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};

export default TechnologyCRM;
