import { Divider as ChakraDivider, DividerProps } from '@chakra-ui/react';

export const Divider: React.FC<DividerProps> = ({ ...rest }): JSX.Element => {
  return (
    <ChakraDivider
      orientation="vertical"
      borderColor="#1D63DD"
      h="70%"
      borderWidth="1px"
      borderRadius={2}
      {...rest}
    />
  );
};
