import { Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { TextEditor } from 'pages/Contents/components';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { Content } from 'pages/Contents/interfaces';

export const EditContent: React.FC = () => {
  const { contentId } = useParams<{ contentId: string }>();
  const [content, setContent] = useState({} as Content);

  const getContentToEdit = async () => {
    try {
      const response = await hubApiUser.getContentById(contentId);
      setContent(response);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar o conteúdo para edição, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    getContentToEdit();
  }, []);

  return (
    <PageWrapper flexDir="column" p="5">
      <BackPageButton />

      <Flex flexDirection="column" w="70vw" maxW="800px" mx="auto">
        <MainPageTitle textAlign="center" mt={8}>
          Editando o conteúdo
        </MainPageTitle>
        <TextEditor
          previousContent={content}
          getContentToEdit={getContentToEdit}
        />
      </Flex>
    </PageWrapper>
  );
};
