import { Flex, Tag, TagLabel } from '@chakra-ui/react';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import React from 'react';

interface ComponentProps {
  tags: { tag: string }[];
}

export const LeadTags: React.FC<ComponentProps> = ({ tags }) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle mb="1">Tags</ContentDividerTitle>
      <Flex flexWrap="wrap" gridGap="1.5">
        {tags?.map((item, index) => (
          <Tag key={index} size="sm" variant="outline" colorScheme="gray">
            <TagLabel whiteSpace="normal">{item.tag}</TagLabel>
          </Tag>
        ))}
      </Flex>
    </ContentWithVertDividerWrapper>
  );
};
