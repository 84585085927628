import React from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

import { IconCard } from '../IconCard';

interface ComponentProps {
  Icon: IconType;
  title: string;
  link: string;
  id?: string;
  information?: string;
  external?: boolean;
}

export const CardToLinkContent: React.FC<ComponentProps> = ({
  title,
  Icon,
  information,
}) => {
  return <IconCard title={title} Icon={Icon} information={information} />;
};

export const CardToLink: React.FC<ComponentProps> = ({
  information,
  external,
  link,
  title,
  Icon,
}) => {
  if (external) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <CardToLinkContent
          title={title}
          Icon={Icon}
          information={information}
          link={link}
        />
      </a>
    );
  }

  return (
    <Link to={link}>
      <CardToLinkContent
        title={title}
        Icon={Icon}
        information={information}
        link={link}
      />
    </Link>
  );
};
