import { toast } from 'react-toastify';
import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { Text } from '@chakra-ui/react';
import hubApi from 'services/hubApi';
import { IEventExtended } from 'pages/Events/interfaces/Event';

interface IDeleteEventModalProps {
  closeModal: () => void;
  eventId: string;
  setAllEvents: React.Dispatch<React.SetStateAction<IEventExtended[]>>;
  allEvents: IEventExtended[];
}

export const DeleteEventModal: React.FC<IDeleteEventModalProps> = ({
  closeModal,
  eventId,
  setAllEvents,
  allEvents,
}) => {
  const handleDeleteEvent = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await hubApi.deleteEvent(eventId);
      setAllEvents(allEvents.filter(event => event.id !== eventId));
      toast.dark('Evento excluída');
      closeModal();
    } catch (error) {
      toast.dark(
        'Não foi possível excluir  o evento, tente novamente em alguns minutos.'
      );
    }
  };

  return (
    <BaseModalFormCard
      isModal
      isLoading={false}
      maxW="480px"
      title="Excluir Evento"
      handleFormSubmit={handleDeleteEvent}
      handleToggleModal={closeModal}
      secondaryButtonText={'Voltar'}
      primaryButtonText="Excluir "
    >
      <Text mb="1.5">
        Deseja realmente excluir este evento? Todos os dados referentes a ele
        serão perdidos.
      </Text>
    </BaseModalFormCard>
  );
};
