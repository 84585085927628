import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { Divider } from 'components/elements/others';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { TagModal } from 'pages/MyClients/components/modules/TagModal';

interface ITags {
  tags: { id: string; employeeId: string; name: string }[];
}

export const TagsCard: React.FC<ITags> = ({ tags }) => {
  const [openTagModal, setOpenTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  const handleOpenTagModal = (tagName: string) => {
    setSelectedTag(tagName);
    setOpenTagModal(true);
  };

  return (
    <DefaultCardWrapper w="100%" p="3" flexDir="column">
      <Flex w="100%" justifyContent="space-between">
        <Flex justifyContent="space-between" flexDir="column">
          <Flex>
            <Text
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
              fontSize={['small', 'md']}
            >
              Tags criadas
            </Text>

            <Flex align="center" justify="center">
              <Tooltip
                hasArrow
                label="Aqui estão todas as tags que ja foram criadas tanto pela propria tela de perfil do cliente, tanto por está pagina"
                placement="top"
                color="white"
                bg="background.200"
              >
                <Box ml="3">
                  <AiOutlineInfoCircle color="white" />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" my="1.5" />

      <Flex mt="2" gridGap="2.5" flexWrap="wrap">
        {tags.map((item, key) => (
          <Flex
            key={`${item.name}-${key}`}
            alignItems="center"
            border="1px solid"
            borderColor="rgba(70, 70, 70, 1)"
            borderRadius="2"
            px="2"
            py="1"
            fontSize="sm"
            gridGap="2.5"
            cursor="pointer"
            // _hover={{ bg: 'background.400' }}
            transition="400ms"
            title="Gerenciar tag"
            // onClick={() => handleOpenTagModal(item)}
          >
            <Text maxW="150px" color="rgba(70, 70, 70, 1)" isTruncated>
              {formatNameToCaptalize(item.name)}
            </Text>
          </Flex>
        ))}
      </Flex>

      <ModalWrapper
        isOpen={openTagModal}
        onClose={() => setOpenTagModal(false)}
      >
        <TagModal
          onClose={() => setOpenTagModal(false)}
          selectedTag={selectedTag}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};
