import styled from 'styled-components';

const PrivatePageContent = styled.div`
  background: ${({ theme }) => theme.colors.background800};
  position: relative;

  .page-container-max-height {
    height: calc(100vh - 110px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px 20px;
    position: relative;

    @media screen and (max-width: 840px) {
      height: 100vh;
    }
  }

  .page-container-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px 20px;

    @media screen and (min-width: 860px) {
      height: calc(100vh - 110px);
    }
  }

  .page-container-cols {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 24px 20px;

    @media screen and (min-width: 860px) and (min-height: 667px) {
      height: calc(100vh - 125px);
    }
  }

  .page-container-center-top {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 20px;
  }

  .page-container-dir-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default PrivatePageContent;
