import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import {
  DeleteTransferOrderCard,
  TransferOrderInfosCard,
} from 'components/elements/cards';
import { DisplayInformation } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';

import { TechnologyOrderProps } from 'pages/Technology/interfaces';

import hubApi from 'services/hubApi';
import { hubApiTech } from 'services/hubApi/classes/HubApiTi';
import { formateDateAndTime } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface OrderInfoProps {
  order: TechnologyOrderProps;
  handleToggleOrderModal: () => void;
  handleRemoveOrder: (id: string) => void;
}

const OrderInfo: React.FC<OrderInfoProps> = ({
  order,
  handleRemoveOrder,
  handleToggleOrderModal,
}: OrderInfoProps) => {
  const [usersOptions, setUsersOptions] = useState([]);
  const [employeIdSelected, setEmployeeIdSelected] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toggleCancelModal, setToggleCancelModal] = useState<boolean>(false);
  const [description, setDescription] = useState('');

  const [orderOwner, setOrderOwner] = useState({
    name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    async function getOwnerInfos() {
      await hubApi
        .getEmployeeInfos(order.employeeId)
        .then(response => setOrderOwner(response));
    }

    getOwnerInfos();
  }, [order.employeeId]);

  useEffect(() => {
    hubApiTech
      .getTechonologyUsers()
      .then(response => {
        setUsersOptions(response);
      })
      .catch(error => {
        error.response?.data.message !== 'Internal server error' &&
          toast.dark(error.response?.data.message);
      });
  }, []);

  const handleChangeUser = useCallback(employeeId => {
    setEmployeeIdSelected(employeeId);
  }, []);

  const handleFormSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);

    if (!employeIdSelected) {
      return toast.dark('Selecione um colaborador');
    }

    try {
      const formData = {
        employeeId: employeIdSelected,
        orderId: order.id,
      };

      await hubApiTech.transferOrderToEmployee(formData);
      handleToggleOrderModal();
      handleRemoveOrder(order.id);
      toast.dark('Ordem transferida com sucesso!');
    } catch (error) {
      toast.dark('Ocorreu um erro ao tentar transferir a ordem');
    }

    setIsLoading(false);
  };

  const handleDeleteOrder = useCallback(async () => {
    const data = {
      description,
    };
    await hubApiTech.deleteOrder(order.id, data);
    handleToggleOrderModal();
    handleRemoveOrder(order.id);
    toast.dark('Ordem deletada com sucesso!');
  }, [order.id, handleRemoveOrder, description]);

  const handleChange = useCallback(e => {
    setDescription(e.target.value);
  }, []);

  const handleToggleCancelModal = () => {
    setToggleCancelModal(!toggleCancelModal);
  };

  return (
    <TransferOrderInfosCard
      title="Dados da ordem"
      isLoading={isLoading}
      collaborators={usersOptions}
      handleSelectCollaborator={handleChangeUser}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleOrderModal}
      handleToggleCancelOrderModal={handleToggleCancelModal}
    >
      <Stack>
        <DisplayInformation
          label="Nome"
          info={formatNameToCaptalize(orderOwner.name)}
        />
        <DisplayInformation label="E-mail" info={orderOwner.email} />
        <DisplayInformation label="Telefone" info={orderOwner.phone} />
        <DisplayInformation label="Tipo" info={order.type} />
        <DisplayInformation label="Descrição" info={order.description} />
        {order.deletedEmployee && (
          <DisplayInformation
            label="Colaborador Descontinuado"
            info={formatNameToCaptalize(order.deletedEmployee)}
          />
        )}

        <DisplayInformation
          label="Descrição"
          info={formateDateAndTime(order.created_at)}
        />
      </Stack>

      <ModalWrapper
        isOpen={toggleCancelModal}
        onClose={handleToggleCancelModal}
      >
        <DeleteTransferOrderCard
          description={description}
          handleDeleteOrder={handleDeleteOrder}
          handleChangeValue={handleChange}
          handleToggleCancelOrderModal={handleToggleCancelModal}
        />
      </ModalWrapper>
    </TransferOrderInfosCard>
  );
};

export default OrderInfo;
