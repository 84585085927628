import { Dropdown } from 'components/elements/others';

interface DropDownContactAttemptsProps {
  updateOperationType?: (e: string | number) => void;
  setAttemptsFilterValue?: (attemptValue: number) => void;
}

export const DropDownContactAttempts: React.FC<DropDownContactAttemptsProps> =
  ({ setAttemptsFilterValue, updateOperationType }) => {
    const handleOnChange = (value: string) => {
      setAttemptsFilterValue && setAttemptsFilterValue(Number(value));
      updateOperationType && updateOperationType(Number(value));
    };

    const options = [
      {
        label: 'Filtro de Tentativas',
        value: -1,
      },
      {
        label: 'Todos',
        value: -1,
      },
      {
        label: '0 Tentativas',
        value: 0,
      },
      {
        label: '1 Tentativas',
        value: 1,
      },
      {
        label: '2 Tentativas',
        value: 2,
      },
      {
        label: '3 Tentativas',
        value: 3,
      },
      {
        label: '4 Tentativas',
        value: 4,
      },
      {
        label: '5 ou Mais',
        value: 5,
      },
    ];

    return (
      <>
        <Dropdown
          borderRadius="md"
          w={['130px', '180px']}
          h="7"
          fontSize="xs"
          onChange={e => handleOnChange(e.target.value)}
        >
          {options.map(item => (
            <option value={item.value}>{item.label}</option>
          ))}
        </Dropdown>
      </>
    );
  };
