import * as yup from 'yup';

export const exchangeSchema = yup.object().shape({
  name: yup.string().required('Preencha o campo nome.'),
  phone: yup.string().required('Preencha o campo telefone'),
  email: yup.string().required('Preencha o campo e-mail.'),
  // intention: yup.string().required('Escolha a modalidade'),
  // value: yup.string().required('Preencha o campo de volume'),
  // currency: yup.string().required('Escolha a moeda origem'),
  // targetCurrency: yup.string().required('Escolha a moeda destino'),
  type: yup.string(),
  status: yup.string(),
});
