const dateNow = new Date();

export const today = `${dateNow.getFullYear().toString()}-${(
  dateNow.getMonth() + 1
)
  .toString()
  .padStart(2, '0')}-${dateNow.getDate().toString().padStart(2, '0')}`;

export const initialFileValue = {
  name: 'Clique para escolher arquivo',
};

export const btgClientsHead = [
  'Conta',
  'Nome',
  'Tipo',
  'Código do Escritório',
  'Escritório',
  'Código do Assessor',
  'Assessor',
  'Profissão',
  'Estado Civil',
  'Cidade',
  'Estado',
  'Aniversário',
  'Perfil do Cliente',
  'Tipo Investidor',
  'Faixa Cliente',
  'E-mail',
  'Qtd Ativos',
  'Qtd Fundos',
  'Qtd Renda Fixa',
  'Qtd Renda Variável',
  'Qtd Previdência',
  'Qtd Derivativos',
  'Qtd Valor em Trânsito',
  'PL Total',
  'Conta Corrente',
  'Fundos',
  'Renda Fixa',
  'Renda Variável',
  'Previdência',
  'Derivativos',
  'Valor em Trânsito',
  'Renda Anual',
  'PL Declarado',
  'Data de Abertura',
  'Data Vínculo',
  '1º Aporte',
  'Último Aporte',
  'Qtd Aportes',
  'Aportes',
  'Retiradas',
];
