import { HubApi } from '../index';

interface ICreateTiOrder {
  type: string;
  description: string;
}

interface ITransferOrderToEmployee {
  employeeId: undefined;
  orderId: string;
}

class HubApiTi extends HubApi {
  async createTiOrder(data: ICreateTiOrder) {
    const response = await this.axios.post('/orders/ti', data);
    return response.data;
  }

  async listOrdersNotTransferred() {
    const response = await this.axios.get('/orders/ti/transferred/no');
    return response.data;
  }

  async getTechonologyUsers() {
    const response = await this.axios.get('/employees/area/TI');
    return response.data;
  }

  async transferOrderToEmployee(data: ITransferOrderToEmployee) {
    const response = await this.axios.post('/orders/ti/transfer', data);
    return response.data;
  }

  async listUserOrders() {
    const response = await this.axios.get('/crm/ti/me');
    return response.data;
  }

  async updateOrderStatus(orderId: string, data: any) {
    const response = await this.axios.patch(
      `/crm/ti/order/status/${orderId}`,
      data
    );
    return response.data;
  }

  async deleteOrder(orderId: string, data: any) {
    await this.axios.patch(`/orders/ti/${orderId}`, data);
  }
}

export const hubApiTech = new HubApiTi();
