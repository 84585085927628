import { Flex } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import history from 'services/history';
import { CardDescriptionAndBonus } from './CardDescriptionAndBonus';

interface IProductCalendaryModalContentProps {
  description: string;
  bonusMessage?: string;
  isbonus?: boolean;
  isStrategy?: boolean;
  typeModal?: string;
  maxWidth?: string;
}

export const LayoutModal: React.FC<IProductCalendaryModalContentProps> = ({
  children,
  description,
  bonusMessage,
  isStrategy,
  typeModal,
  maxWidth,
}) => {
  return (
    <>
      {typeModal === 'OPF' && !bonusMessage ? (
        <Flex
          width="100%"
          alignItems="center"
          flexDirection="column"
          height="100%"
          gap={4}
          p={[0, 4]}
        >
          <Flex
            width="100%"
            maxHeight="100%"
            overflowY="auto"
            justifyContent="space-around"
            alignItems="flex-start"
          >
            {children}
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            height="10%"
          >
            <PrimaryButton onClick={() => history.push('/offers')}>
              Gerenciar meus clientes na oferta
            </PrimaryButton>
          </Flex>
        </Flex>
      ) : (
        <Flex
          width="100%"
          height={
            !bonusMessage && description === ''
              ? '380px'
              : description === ''
              ? ['300px', '500px']
              : '95%'
          }
          alignItems="flex-start"
          flexDirection="column"
          gap={4}
          p={4}
        >
          <Flex
            width="100%"
            height="90%"
            gap={2.5}
            mt={1}
            flexDirection={['column', 'row']}
          >
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              width={['100%', '40%']}
              height="100%"
              maxHeight={['150px', '100%']}
              overflowY="auto"
              gap="2.5"
            >
              {children}
            </Flex>
            <Flex
              width="100%"
              height="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              gap={3}
            >
              {description && description !== '' && (
                <CardDescriptionAndBonus
                  title={isStrategy ? 'Estratégia' : 'Descrição'}
                  info={description}
                  maxWidth={maxWidth}
                />
              )}
              {bonusMessage && bonusMessage !== '' && (
                <CardDescriptionAndBonus
                  title="Bonificação"
                  info={bonusMessage}
                  maxWidth={maxWidth}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            alignItems="flex-end"
            justifyContent="flex-end"
            width="100%"
            height="10%"
          >
            <PrimaryButton onClick={() => history.push('/offers')}>
              Gerenciar meus clientes na oferta
            </PrimaryButton>
          </Flex>
        </Flex>
      )}
    </>
  );
};
