import { create } from 'zustand';

export type MeetingFilialType = {
  id: string;
  city: string;
};

type UseScheduleFirstMeetingContextType = {
  leadPl: string;
  meetingFilial: MeetingFilialType;
  setLeadPl: (value: string) => void;
  setMeetingFilial: (value: MeetingFilialType) => void;
};

export const useScheduleFirstMeetingContext =
  create<UseScheduleFirstMeetingContextType>(set => ({
    leadPl: '',
    meetingFilial: {} as MeetingFilialType,

    setLeadPl: (value: string) => set(() => ({ leadPl: value })),
    setMeetingFilial: (value: MeetingFilialType) =>
      set(() => ({ meetingFilial: value })),
  }));
