import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { IEmployee } from 'components/customElements/InputWithDropdown';
import { ConfirmationModal } from 'componentsV2/@shared/modals/confirmationModal';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useTransferLead } from 'hooksV2/useTransferLead';
import { useQuery } from 'react-query';
import {
  hubApiCaptation,
  TransferToEnum,
} from 'services/hubApi/classes/HubApiCaptation';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { DropdownUtils } from 'utilsV2/Dropdown';
import { showToast } from 'utilsV2/showToast';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  lead: LeadParsedType;
  leadType: LeadTypeEnum;
  transferTo: TransferToEnum;
};

export const TransferLeadModal: React.FC<PropsType> = ({
  lead,
  leadType,
  transferTo,
}): JSX.Element => {
  const { deleteParam } = useQueryParams();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const {
    filterValue: selectedEmployeeId,
    handleChangeFilterValue: handleChangeSelectedEmployeeId,
  } = useSimpleQueryFilter('selectedEmployeeId');

  const {
    filterValue: meetingDate,
    handleChangeFilterValue: handleChangeMeetingDate,
  } = useSimpleQueryFilter('transferLeadMeetingDate');

  const {
    filterValue: isOpenTransferLeadConfirmationModal,
    handleChangeFilterValue: handleChangeIsOpenTransferLeadConfirmationModal,
  } = useSimpleQueryFilter('isOpenTransferLeadConfirmationModal');

  const { mutateAsync: transferLead, isLoading: isLoadingTransferLead } =
    useTransferLead({
      onError: () => showToast(`Erro ao transferir lead para um ${transferTo}`),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead.id]);
        queryClient.refetchQueries(['leadsSearch']);

        showToast('Lead transferido com sucesso!');

        deleteParam([
          'isOpenTransferLeadToSalesHunterModal',
          'isOpenTransferLeadToAAIModal',
          'selectedEmployeeId',
          'transferLeadMeetingDate',
          'isOpenTransferLeadConfirmationModal',
        ]);
      },
    });

  const handleTransferLead = async () => {
    await transferLead({
      leadId: lead.id,
      leadType,
      transferTo,
      newEmployeeId: selectedEmployeeId,
      date: meetingDate,
    });
  };

  const onModalActionClick = async () => {
    if (!selectedEmployeeId)
      return showToast(`Selecione um ${transferTo} para receber o lead`);

    if (
      leadBussinessLogic.shouldDisplayConfirmationModalWhenLeadIsBeingTransferred()
    ) {
      return handleChangeIsOpenTransferLeadConfirmationModal('true');
    }

    await handleTransferLead();
  };

  const { data: shEmployees } = useQuery<IEmployee[]>({
    queryKey: ['shEmployees'],
    queryFn: async () => {
      const response = await hubApiCaptation.getEmployeesInGroup('SH');
      return response;
    },
    enabled: transferTo === TransferToEnum.SH,
  });

  const { data: aaiEmployees } = useQuery<IEmployee[]>({
    queryKey: ['aaiEmployees'],
    queryFn: async () => {
      const response = await hubApiCaptation.getEmployeesInGroup('AAI');
      return response;
    },
    enabled: transferTo === TransferToEnum.AAI,
  });

  const employees =
    transferTo === TransferToEnum.SH ? shEmployees : aaiEmployees;

  return (
    <>
      <Modal.Root>
        <Modal.Header>
          <Modal.Title title={`Transferir Lead para ${transferTo}`} />
          <Modal.CloseIcon
            onClick={() =>
              deleteParam([
                'isOpenTransferLeadToSalesHunterModal',
                'isOpenTransferLeadToAAIModal',
              ])
            }
          />
        </Modal.Header>

        <Flex flexDirection="column" gap={4}>
          <Dropdown.Root>
            <Dropdown.OptionsWithTextFilter
              placeholder={`Pesquise por um ${transferTo}`}
              options={employees?.map(employee =>
                DropdownUtils.toBaseOptions({
                  label: TextUtils.toCapitalize(employee.name),
                  value: employee.id,
                })
              )}
              onSelectDropdownItem={handleChangeSelectedEmployeeId}
            />
          </Dropdown.Root>

          {transferTo === TransferToEnum.AAI && (
            <Input.Root>
              <Input.Label text="Data da Reunião" />
              <Input.Date
                value={meetingDate}
                onChange={event => handleChangeMeetingDate(event.target.value)}
              />
            </Input.Root>
          )}
        </Flex>

        <Modal.Actions>
          <Modal.Action
            text="Transferir"
            onClick={onModalActionClick}
            isLoading={isLoadingTransferLead}
          />
        </Modal.Actions>
      </Modal.Root>

      <ConfirmationModal
        isOpen={isOpenTransferLeadConfirmationModal === 'true'}
        onClose={() => deleteParam('isOpenTransferLeadConfirmationModal')}
        title="Atenção"
        subtitle="Esse lead já está fora do fluxo de captação, deseja trazê-lo de volta ao fluxo?"
        onConfirmAction={handleTransferLead}
        isLoading={isLoadingTransferLead}
      />
    </>
  );
};
