import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { TitleWithSideLine } from 'components/elements/texts';
import { Flex, Text } from '@chakra-ui/react';
import { LeadsPurchaseOrdersCard } from 'pages/Captation/components/modules/modals/MyOrdersModal/components/LeadsPurchaseOrdersCard';
import { LoadingSpinner } from 'components/elements/others';
import { X } from 'phosphor-react';
import { ILeadsPurchasedOrder } from 'pages/Captation/interfaces';
import { UseMutateAsyncFunction } from 'react-query';

interface IProps {
  setIsOpenMyOrdersModal(value: boolean): void;
  leadsPurchaseOrders: ILeadsPurchasedOrder[] | undefined;
  isLoadingGetLeadsPurchasedOrders: boolean;
  updateOrder: UseMutateAsyncFunction<
    any,
    unknown,
    {
      orderId: string;
      status: 'active' | 'finished';
    },
    unknown
  >;
}

export const MyOrdersModal: React.FC<IProps> = ({
  setIsOpenMyOrdersModal,
  leadsPurchaseOrders,
  isLoadingGetLeadsPurchasedOrders,
  updateOrder,
}) => {
  return (
    <DefaultModalContentWrapper minH="500px" maxH="500px" minW="70%">
      <Flex alignItems="center" gap={2}>
        <TitleWithSideLine>Minhas Ordens de Compra</TitleWithSideLine>
        <X
          size={24}
          cursor="pointer"
          onClick={() => setIsOpenMyOrdersModal(false)}
        />
      </Flex>
      {isLoadingGetLeadsPurchasedOrders && (
        <Flex
          justifyContent="center"
          alignItems="center"
          minH="500px"
          maxH="500px"
          minW="70%"
        >
          <LoadingSpinner size="lg" />
        </Flex>
      )}

      {leadsPurchaseOrders && leadsPurchaseOrders.length < 1 && (
        <Flex
          justifyContent="center"
          alignItems="center"
          minH="500px"
          maxH="500px"
          minW="70%"
          fontStyle="italic"
        >
          <Text>Sem Ordens Para Exibir</Text>
        </Flex>
      )}

      <Flex
        maxW="100%"
        flexWrap="wrap"
        overflowY="auto"
        mt={4}
        gap={3}
        className="slimScrollbar"
      >
        {isLoadingGetLeadsPurchasedOrders && <LoadingSpinner />}
        {leadsPurchaseOrders &&
          leadsPurchaseOrders.map(order => (
            <LeadsPurchaseOrdersCard
              order={order}
              key={order.id}
              updateOrder={updateOrder}
            />
          ))}
      </Flex>
    </DefaultModalContentWrapper>
  );
};
