import { HubApi } from '../index';

interface ITableOffice {
  name: string;
  officeId: string;
  managerId: string;
}

class HubApiOfficeTables extends HubApi {
  async listTablesOffice() {
    const response = await this.axios.get('table');
    return response.data;
  }

  async listGraphicsAdvisors(id: string) {
    const response = await this.axios.get(`/dashtable/aai/${id}`);
    return response.data;
  }

  async listGraphicsAdvisorsArea(id: string) {
    const response = await this.axios.get(`/dashtable/month/${id}`);
    return response.data;
  }

  async listGraphicsAdvisorsTable() {
    const response = await this.axios.get(`/graphics/captation/tables`);
    return response.data;
  }

  async listRvAdvisorsInfos(id: string) {
    const response = await this.axios.get(`graphics/dashorders/table/${id}`);
    return response.data;
  }

  async listTableInfos(id: string) {
    const response = await this.axios.get(`/graphics/captation/by/table/${id}`);
    return response.data;
  }

  async listFilial() {
    const response = await this.axios.get('/office');
    return response.data;
  }

  async listTablesByOffice(officeId: string) {
    const response = await this.axios.get(`table/${officeId}`);
    return response.data;
  }

  async createTableOffice(tableData: ITableOffice) {
    const response = await this.axios.post('table', tableData);
    return response.data;
  }

  async editTableOffice(tableId: string, officeData: ITableOffice) {
    await this.axios.patch(`table/${tableId}`, officeData);
  }

  async deleteTableOffice(tableId: string) {
    await this.axios.delete(`table/${tableId}`);
  }

  async listUsersByTable(tableId: string) {
    const response = await this.axios.get(`employees/table/${tableId}`);
    return response.data;
  }

  async listMIUsersByTable(tableId: string, type: 'mi' | 'ewz') {
    const response = await this.axios.get(`employees/aai/${type}/${tableId}`);
    return response.data;
  }

  async listMIUsers() {
    const response = await this.axios.get(`employees/aai/mi`);
    return response.data;
  }

  async dashboardData(tableId: string) {
    const response = await this.axios.get(`employees/dash/table/${tableId}`);
    return response.data;
  }

  async employeesData(tableId: string) {
    const response = await this.axios.get(
      `employees/dash/table/pendencies/${tableId}`
    );
    return response.data;
  }

  async meetingsNumbers(tableId: string) {
    const response = await this.axios.get(`dash/table/${tableId}`);
    return response.data;
  }
}

export const hubApiOfficeTables = new HubApiOfficeTables();
