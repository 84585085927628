import React, { useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { formatToDate } from 'brazilian-values';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}
export const LossPointsMeeting: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);
  return (
    <>
      {' '}
      <AssignmentContentCard
        IconElement={FcHighPriority}
        title="Perda de pontos"
        secondaryText={`Você perdeu 1000 moedas por não dar o feedback da reunião comprada do prospect:  `}
        thirdText={`${assignment.extra?.toUpperCase()}`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />{' '}
      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        {' '}
        <ConfirmationModal
          title="Reunião sem feedback"
          message={`Você perdeu pontos por não dar um feedback na reunião com o ${assignment.extra?.toUpperCase()}  as   ${formatToDate(
            new Date(assignment.created_at)
          )} ?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />{' '}
      </ModalWrapper>{' '}
    </>
  );
};
