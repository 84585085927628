import { Flex, Text } from '@chakra-ui/react';
import CurrencyInput from 'react-currency-input-field';

import { Input } from 'components/elements/forms';
import {
  DefaultSHQuestionItem,
  SHQuestionsProps,
} from 'pages/Captation/interfaces/SHLeads';

interface SHQuestionItemProps {
  question: DefaultSHQuestionItem;
  currentQuestions: SHQuestionsProps;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    propName: string
  ) => void;
  handleChangePl: (e: string, propName: string) => void;
}

export const SHQuestionItem: React.FC<SHQuestionItemProps> = ({
  question,
  currentQuestions,
  handleChangeValue,
  handleChangePl,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" p="0" w="100%">
      <Flex>
        <Text fontSize="sm" color="white" fontWeight="medium">
          {question.title}:
        </Text>
      </Flex>

      <Flex
        width="100%"
        maxW="50%"
        background="background.500"
        alignItems="center"
        pr="1"
        borderRadius="6px"
      >
        {question.identifier === 'pl' ? (
          <>
            <CurrencyInput
              allowNegativeValue={false}
              decimalsLimit={2}
              intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
              allowDecimals={false}
              onValueChange={value => {
                if (value === undefined) {
                  handleChangePl('', question.identifier);
                } else {
                  handleChangePl(value, question.identifier);
                }
              }}

              value={
                Number(currentQuestions.pl)
                  ? Number(currentQuestions[question.identifier])
                  : 0
              }

              style={{
                background: 'transparent',
                borderColor: 'transparent',
                padding: '0 8px',
                height: '32px',
                width: '100%',
              }}
            />
          </>
        ) : (
          <Input
            px="2"
            background="transparent"
            size="sm"
            type="text"
            maxH="32px"
            value={currentQuestions[question.identifier] || ''}
            onChange={event => handleChangeValue(event, question.identifier)}
          />
        )}
      </Flex>
    </Flex>
  );
};
