import React, { useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const InativeCard: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcHighPriority}
        title="Cliente inativo"
        secondaryText={`O cliente:  `}
        thirdText={`${assignment.name.toUpperCase()}`}
        fourthText={`(${assignment.btgAccount}) está a ${assignment.extra} dias sem ativar a conta`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Cliente Inativo"
          message={`Você confirma que o(a) cliente ${assignment.name.toUpperCase()} ativou a conta?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
