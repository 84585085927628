import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PublicPageLayout from 'components/layouts/PublicPageLayout';

export const PublicRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  if (!Component) return null;

  const userAuthenticated = useSelector((state: any) => state.auth);

  if (userAuthenticated.signed) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <PublicPageLayout>
          <Component {...props} />
        </PublicPageLayout>
      )}
    />
  );
};
