import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { PageWrapper } from 'components/elements/wrappers/PageWrapper';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { TableBase } from 'components/elements/table';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { ILead } from 'pages/manager/subpages/LeadsManagement/interfaces/ITableContent';
import LeadsTableRow from 'pages/manager/subpages/LeadsManagement/components/LeadsTableRow';
import { addLeadsHeader } from 'pages/manager/subpages/LeadsManagement/data';
import { Text } from '@chakra-ui/react';

export const LeadsManagement: React.FC = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState<ILead[]>(leads);

  const getLeads = async (): Promise<void> => {
    await hubApiCaptation
      .listAddedLeads()
      .then(response => {
        setLeads(response);
        setFilteredLeads(response);
      })
      .catch(error => {
        if (
          error.response &&
          error.response?.data.message !== 'Internal server error'
        ) {
          toast.dark(error.response?.data.message);
        }
      });
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <PageWrapper flexDir="column" pt="48px" alignItems="center">
      <BackPageButton />
      <MainPageTitle textAlign="center">Gestão de Leads</MainPageTitle>
      <Text fontSize="md" color="white" marginBottom="5px">
        OBS: para invalidar o lead digite 111 e clique no botão invalidar lead
      </Text>

      <TableBase
        overflowY="auto"
        maxH="calc(100vh - 240px)"
        width="100%"
        maxW="1080px"
        headData={addLeadsHeader}
      >
        {filteredLeads?.map(rowData => (
          <LeadsTableRow
            key={rowData.id}
            rowData={rowData}
            getLeads={getLeads}
          />
        ))}
      </TableBase>
    </PageWrapper>
  );
};
