import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { isCNPJ } from 'brazilian-values';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { PageWrapper } from 'components/elements/wrappers';
import { CreateInvestmentFundForm } from 'pages/products/components/modules/investmentFunds';

import { InvestmentFundProps } from 'pages/products/interfaces';

import schema from './schema';

export const CreateInvestmentFund: React.FC = () => {
  const history = useHistory();

  const [investmentFundData, setInvestmentFundData] =
    useState<InvestmentFundProps>({} as InvestmentFundProps);

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();
      try {
        await schema.validate(investmentFundData, {
          abortEarly: false,
        });

        if (!isCNPJ(investmentFundData.cnpj)) {
          toast.dark('Digite um cnpj válido.');
          return;
        }
        await hubApiProducts.postCreateInvestmentFunds(investmentFundData);
        toast.dark('Fundo criado com sucesso.');
        history.goBack();
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          err.errors.forEach(message => {
            toast.dark(message);
          });
        } else {
          const statusCode = err.message.split(' ');
          if (Number(statusCode[statusCode.length - 1]) === 400) {
            toast.dark('Já existe um fundo cadastrado com esse nome.');
          } else {
            toast.dark('Ocorreu um erro ao cadastrar o fundo, tente novamente');
          }
        }
      }
    },
    [investmentFundData, history]
  );
  return (
    <PageWrapper>
      <CreateInvestmentFundForm
        handleSubmitForm={handleSubmitForm}
        investmentFundData={investmentFundData}
        setValue={setInvestmentFundData}
        nameButton="Criar Fundo"
      />
    </PageWrapper>
  );
};
