import { Flex } from '@chakra-ui/react';

export const ModalActions: React.FC<React.ReactNode> = ({
  children,
}): JSX.Element => {
  return (
    <Flex justifyContent="end" gap={2} mt={5}>
      {children}
    </Flex>
  );
};
