import { Text } from '@chakra-ui/react';

type PropsType = {
  message: string | undefined;
};

export const InputErrorMessage: React.FC<PropsType> = ({
  message,
}): JSX.Element => {
  return (
    <>
      {message && (
        <Text color="red" fontSize="sm" fontStyle="italic">
          {message}
        </Text>
      )}
    </>
  );
};
