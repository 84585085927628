import React, { useState } from 'react';

import { SecondaryButton } from 'components/elements/buttons';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import { ButtonGroup, Flex, Stack } from '@chakra-ui/react';
import Intern from 'pages/IP/components/Intern';
// import Intern from 'IP/components/Intern';
// import Chat from 'IP/components/Chat';

interface OrderProps extends KanbanBaseTaskProps {
  employeename?: string;
  employeebtgcode?: string;
  phone?: string;
  email?: string;
  information?: string;
}

interface ComponentProps {
  order: OrderProps;
  handleToggleModal: () => void;
}

export const BoardOrderInfo: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  const [showIntern, setShowIntern] = useState(false);
  // const [showChat, setShowChat] = useState(false);

  const handleAnotIn = () => {
    setShowIntern(true);
  };

  // const handleChat = () => {
  //   setShowChat(true);
  // };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle>Dados da Solicitação</ModalTitle>

      <Stack spacing="3">
        {order.employeename && (
          <DisplayInformation label="Solicitante" info={order.employeename} />
        )}

        {order.employeebtgcode && (
          <DisplayInformation
            label="Código do solicitante"
            info={order.employeebtgcode}
          />
        )}

        {order.email && (
          <DisplayInformation label="E-mail" info={order.email} />
        )}

        {order.phone && (
          <DisplayInformation label="Telefone" info={order.phone} />
        )}

        {order.information && (
          <DisplayInformation label="Informações" info={order.information} />
        )}
      </Stack>

      <Flex width="100%" mt="4">
        <SecondaryButton onClick={handleAnotIn}>
          Anotação Interna
        </SecondaryButton>
        {/* <SecondaryButton removeMargin onClick={handleChat}>
          Chat
        </SecondaryButton> */}
      </Flex>

      <ModalWrapper isOpen={showIntern} onClose={() => setShowIntern(false)}>
        <Intern setShowIntern={setShowIntern} order={order} />
      </ModalWrapper>

      {/* {showChat === true && (
        <ModalWrapper>
          <Chat setShowChat={setShowChat} order={order} />
        </ModalWrapper>
      )} */}

      <ButtonGroup width="100%" justifyContent="flex-end">
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};

// export default BoardOrderInfo;
