import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';
import { Reminder } from 'pages/MyClients/interfaces';
import { FormEvent, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface MeetingTypeAndClientsatisfactionProps {
  reminder: Reminder;
  meetingDisclaimer: string;
  commentedAreas: string[];
  signalToUpdate: boolean;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  areInfosAndDisclaimerSaved: boolean;
  closeMeetingSection: () => void;
  handleSaveDisclaimerAndInfos: (e?: FormEvent) => Promise<void>;
}

export const MeetingTypeAndClientsatisfaction: React.FC<MeetingTypeAndClientsatisfactionProps> =
  ({
    reminder,
    meetingDisclaimer,
    commentedAreas,
    signalToUpdate,
    setSignalToUpdate,
    setShowModal,
    areInfosAndDisclaimerSaved,
    closeMeetingSection,
    handleSaveDisclaimerAndInfos,
  }) => {
    const [meetingContactType, setMeetingContactType] = useState(
      reminder.meetingContactType
    );
    const [clientSatisfaction, setClientSatisfaction] = useState(
      reminder.clientSatisfaction
    );

    const clientSatisfactionOptions = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
    ];
    const meetingContactTypeOptions = ['Ligação', 'Videocall', 'Presencial'];

    const handleConcludedReminder = async (e?: FormEvent) => {
      e?.preventDefault();
      if (!meetingContactType) {
        toast.dark('Informe o tipo de contato da reunião');
        return;
      }

      if (!clientSatisfaction) {
        toast.dark('Informe a nota de satisfação do cliente nesta reunião');
        return;
      }
      const updatedReminder = {
        id: reminder.id,
        title: reminder.title,
        clientId: reminder.clientId,
        type: reminder.type,
        msg: meetingDisclaimer === '' ? reminder.msg : meetingDisclaimer,
        dataRetorno: reminder.dataRetorno,
        meetingContactType,
        clientSatisfaction,
        realized: true,
        commentedAreas:
          commentedAreas.length > 0 ? commentedAreas.join(', ') : null,
      };

      try {
        if (!areInfosAndDisclaimerSaved) {
          await handleSaveDisclaimerAndInfos();
        }
        await hubApiUser.updateAnotationMessage(updatedReminder);

        setSignalToUpdate(!signalToUpdate);
        setShowModal(false);
        closeMeetingSection();

        toast.dark('Tarefa concluída!');
      } catch (error) {
        toast.dark(
          'Não foi possível concluir esta tarefa, tente novamente dentro de alguns minutos'
        );
      }
    };

    return (
      <BaseModalFormCard
        isModal
        width={['95vw', '360px']}
        maxH="100%"
        height="280px"
        title="Antes de salvar, informe o tipo de contato e a satisfação do cliente"
        isLoading={false}
        textAlign="center"
        handleFormSubmit={handleConcludedReminder}
        handleToggleModal={() => setShowModal(false)}
        primaryButtonText="Confirmar e salvar alterações"
      >
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-evenly"
          flexDirection={'column'}
        >
          <Flex justifyContent="center" alignItems={'center'} width={'100%'}>
            <Dropdown
              width="200px"
              onChange={e => setMeetingContactType(e.target.value)}
              defaultValue={
                reminder.meetingContactType ? reminder.meetingContactType : ''
              }
            >
              <option disabled value="">
                Tipo de contato
              </option>
              {meetingContactTypeOptions.map(item => (
                <option
                  key={item}
                  value={item}
                  selected={reminder.meetingContactType === item}
                >
                  {item}
                </option>
              ))}
            </Dropdown>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems={'center'}
            width={'100%'}
            position={'relative'}
          >
            <Dropdown
              width="200px"
              defaultValue={
                reminder.clientSatisfaction ? reminder.clientSatisfaction : ''
              }
              onChange={e => setClientSatisfaction(e.target.value)}
            >
              <option disabled value="">
                Satisfação do cliente
              </option>
              {clientSatisfactionOptions.map(item => (
                <option
                  key={item}
                  value={item}
                  selected={reminder.clientSatisfaction === item}
                >
                  {item}
                </option>
              ))}
            </Dropdown>
          </Flex>
          <Box
            align="center"
            justify="center"
            position={'absolute'}
            right={'15%'}
            top={'60%'}
          >
            <Tooltip
              hasArrow
              label={
                'Quanto você sente que seu cliente está feliz? Avalie para ter um histórico de NPS real e gerenciar melhor seus clientes.'
              }
              placement="top"
              color="white"
              bg="background.200"
            >
              <Box ml="2">
                <AiOutlineInfoCircle color="white" />
              </Box>
            </Tooltip>
          </Box>
        </Flex>
      </BaseModalFormCard>
    );
  };
