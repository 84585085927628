/* eslint-disable no-nested-ternary */
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IReduxState } from 'shared/interfaces';
import { formatMibs } from 'utils/formatMibs';
import {
  IGeneralWalletMibs,
  IHandleMibsOperation,
  ITransactionType,
} from '../dtos';
import { SensitiveTransactionModal } from './SensitiveTransactionModal';

interface IProps {
  setIsOpenEditMibsModal(value: boolean): void;
  handleMibsOperation: IHandleMibsOperation;
  employeeId: string;
  generalWalletMibs: IGeneralWalletMibs[];
}

export const EditEWZWalletMibsModal: React.FC<IProps> = ({
  setIsOpenEditMibsModal,
  handleMibsOperation,
  employeeId,
  generalWalletMibs,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile) as {
    id: string;
    position: string;
    rules: { area_cod: string; permission_cod: string }[];
  };

  const [mibsQuantity, setMibsQuantity] = useState<string>('');
  const [customTitle, setCustomTitle] = useState<string>('MIBs Bonificados');
  const [isOpenConfirmTransactionModal, setIsOpenConfirmTransactionModal] =
    useState<boolean>(false);
  const [operation, setOperation] = useState<ITransactionType>('DEPOSIT');
  const [isFromEWZGeneralWallet, setIsFromEWZGeneralWallet] =
    useState<boolean>(false);
  const [isFromMIGeneralWallet, setIsFromMIGeneralWallet] =
    useState<boolean>(false);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      mibsQuantity === '' ||
      Number.isNaN(mibsQuantity) ||
      Number(mibsQuantity) === 0
    ) {
      return toast.dark('Insira uma quantidade de MIBs para comprar.');
    }

    if (operation === 'DEPOSIT') {
      if (!isFromEWZGeneralWallet && !isFromMIGeneralWallet) {
        return toast.dark('Selecione uma carteira geral de origem.');
      }

      const wallet = isFromEWZGeneralWallet
        ? generalWalletMibs[0]
        : generalWalletMibs[1];

      if (Number(mibsQuantity) > wallet.mibs) {
        return toast.dark('Quantidade de MIBs insuficiente.');
      }
    }

    setIsOpenConfirmTransactionModal(true);
  };

  return (
    <>
      <BaseModalFormCard
        title="Enviar MIBs"
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={() => setIsOpenEditMibsModal(false)}
        isModal
        primaryButtonText="Confirmar operação"
        isLoading={handleMibsOperation.isLoading}
      >
        <Flex gap={5} flexDirection="column">
          <Flex flexDirection="column">
            <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
              Ação *
            </Text>
            <Dropdown
              onChange={e => setOperation(e.target.value as ITransactionType)}
            >
              <option value="DEPOSIT">Adicionar</option>
              <option value="WITHDRAW">Remover</option>
            </Dropdown>
          </Flex>

          <InputWithLabel
            label="Quantidade de MIBs *"
            placeholder="100"
            value={mibsQuantity}
            onChange={e => setMibsQuantity(e.target.value)}
          />

          {operation === 'DEPOSIT' && (
            <>
              <InputWithLabel
                label="Titulo customizado"
                placeholder="MIB's Bonificados"
                value={customTitle}
                onChange={e => setCustomTitle(e.target.value)}
              />
              <Flex flexDirection="column">
                <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
                  Carteira de Origem *
                </Text>
                <Flex flexDirection="column" mt={2}>
                  <Flex alignItems="baseline" gap={2}>
                    <Checkbox
                      isChecked={isFromEWZGeneralWallet}
                      onChange={() => {
                        setIsFromEWZGeneralWallet(!isFromEWZGeneralWallet);
                        setIsFromMIGeneralWallet(false);
                      }}
                      borderColor="rgba(70, 70, 70, 1)"
                    />
                    <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                      Banco Central EWZ ({formatMibs(generalWalletMibs[0].mibs)}
                      )
                    </Text>
                  </Flex>
                  <Flex alignItems="baseline" gap={2}>
                    <Checkbox
                      isChecked={isFromMIGeneralWallet}
                      onChange={() => {
                        setIsFromMIGeneralWallet(!isFromMIGeneralWallet);
                        setIsFromEWZGeneralWallet(false);
                      }}
                      borderColor="rgba(70, 70, 70, 1)"
                    />
                    <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                      Banco Central MI ({formatMibs(generalWalletMibs[1].mibs)})
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </BaseModalFormCard>
      <ModalWrapper
        isOpen={isOpenConfirmTransactionModal}
        onClose={() =>
          setIsOpenConfirmTransactionModal(!isOpenConfirmTransactionModal)
        }
      >
        <SensitiveTransactionModal
          setIsOpenFatherModal={setIsOpenEditMibsModal}
          setIsOpenConfirmTransactionModal={setIsOpenConfirmTransactionModal}
          handleFormSumit={handleMibsOperation}
          mibsQuantity={mibsQuantity}
          operation={operation}
          sourceAction={
            operation === 'DEPOSIT' ? 'MIBS_EARNED_EWZ' : 'MIBS_WITHDRAW'
          }
          destinationWallet="EWZWallet"
          sourceWallet={
            isFromEWZGeneralWallet
              ? 'EWZ'
              : isFromMIGeneralWallet
              ? 'MI'
              : 'EWZ'
          }
          employeeId={employeeId}
          customTitle={operation === 'DEPOSIT' ? customTitle : undefined}
          transactionResponsible={user.id}
        />
      </ModalWrapper>
    </>
  );
};
