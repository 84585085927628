import { Text } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { useState } from 'react';

interface PendencyConfirmInputProps {
  title: string;
  message: string;
  idTask: string;
  btgAccount?: string;
  handleRemoveItem: (
    idTask: string,
    clientId?: string,
    isSharedContent?: boolean,
    additionalInformation?: string
  ) => void;
  handleCloseModal: () => void;
  clientId: string;
}

export const PendencyConfirmInput: React.FC<PendencyConfirmInputProps> = ({
  title,
  message,
  idTask,
  btgAccount,
  handleCloseModal,
  handleRemoveItem,
  clientId,
}) => {
  const [additionalInformation, setAdditionalInformation] = useState('');

  return (
    <DefaultModalContentWrapper maxW="410px">
      <ModalTitle>{title}</ModalTitle>

      <TextareaWithLabel
        label={message}
        mb="1"
        value={additionalInformation}
        onChange={e => {
          setAdditionalInformation(e.target.value);
        }}
        isRequired
      />

      {btgAccount && (
        <Text mb={1} fontSize="sm" textAlign="end">
          Cod. BTG: {btgAccount}
        </Text>
      )}

      <BaseButtonGroup>
        <SecondaryButton onClick={handleCloseModal}>Cancelar</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            handleRemoveItem(idTask, clientId, false, additionalInformation);
            handleCloseModal();
          }}
        >
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
