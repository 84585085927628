import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const OutlineButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      size="sm"
      m="0"
      variant="outline"
      color="white"
      fontWeight="semibold"
      _hover={{ borderColor: 'gray.300', color: 'gray.300' }}
      {...rest}
    >
      {children}
    </Button>
  );
};
