import { Flex, Text } from '@chakra-ui/react';
import { Input } from 'componentsV2/elements/input';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { debounce } from 'lodash';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { Check, Coins } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export const LeadDetailsPipeValue = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();
  const { mutateAsync: updateLead } = useUpdateLead();

  const [pipeValue, setPipeValue] = useState<string | undefined>(
    lead?.pipeValue?.toString() || undefined
  );

  useEffect(() => {
    if (lead) setPipeValue(lead.pipeValue?.toString());
  }, [lead]);

  useEffect(() => {
    const handleDebouncedUpdatePipeValue = debounce(async () => {
      try {
        await updateLead({
          type: leadType,
          leadId: lead?.id as string,
          pipeValue,
        });
        toast.success('Valor atualizado com sucesso!');
      } catch (error) {
        toast.error(
          'Não foi possível atualizar o valor, tente novamente em alguns minutos.'
        );
      }
    }, 500);

    if (pipeValue !== lead?.pipeValue?.toString()) {
      handleDebouncedUpdatePipeValue();
    }

    return () => {
      handleDebouncedUpdatePipeValue.cancel();
    };
  }, [pipeValue]);

  const firstName = lead?.name ? lead.name.split(' ')[0] : 'Usuário';
  const isPipeValueEmpty =
    !pipeValue || pipeValue.trim() === '' || pipeValue === '0';

  return (
    <Flex
      backgroundColor={isPipeValueEmpty ? '#FFDFDF' : '#fff'}
      borderRadius={6}
      p={3}
      gap={2}
      w="100%"
      h="60px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center" gap={2} borderRadius={6}>
        {isPipeValueEmpty ? (
          <FaExclamation size={16} color="#000000" />
        ) : (
          <Coins size={16} />
        )}

        <Text fontWeight={600} fontSize="md" color="#000">
          Preencha o pipe do {firstName || 'Usuário'}
        </Text>
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginLeft="15px"
        backgroundColor={isPipeValueEmpty ? '#FFDFDF' : '#ECECEC'}
        borderRadius="15px"
        w="250px"
        h="50px"
      >
        <Input.Root
          w="210px"
          fontSize="0.8rem"
          h="50px"
          border="none"
          borderRadius="12px"
          backgroundColor="#ECECEC"
        >
          <Input.Currency
            placeholder="R$   ------------"
            style={{
              // flex: 1,
              height: '60px',
              width: '200px',
              flexDirection: 'row',
              border: 'none',
              backgroundColor: 'transparent',
              color: '#000000',
              fontSize: '0.8rem',
              fontWeight: 700,
            }}
            value={pipeValue}
            onValueChange={value => setPipeValue(value?.split(',')[0])}
          />
        </Input.Root>
        <Flex
          h="50px"
          p={2}
          cursor="pointer"
          backgroundColor="#DCE9FF"
          borderRadius="0 15px 15px 0"
          alignItems="center"
          justifyContent="flex-end"
          flexDirection="row"
          onClick={async () => {
            try {
              await updateLead({ type: leadType, leadId: lead?.id, pipeValue });
              toast.success('Valor atualizado com sucesso!');
            } catch (error) {
              toast.error(
                'Não foi possível atualizar o valor, tente novamente em alguns minutos.'
              );
            }
          }}
          _hover={{ bgColor: 'rgba(70, 70, 70, 0.1)' }}
        >
          <Check size={16} color="#1D63DD" />
        </Flex>
      </Flex>
    </Flex>
  );
};
