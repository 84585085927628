import { Flex, Heading, Box } from '@chakra-ui/react';
import formatStringInTitle from 'utils/formatStringInTitle';

interface InfoTitleProps {
  title: string;
}

export function InfoTitle({ title }: InfoTitleProps) {
  return (
    <Flex w="100%" alignItems="center">
      <Heading
        as="h6"
        fontSize="md"
        fontWeight="bold"
        whiteSpace="nowrap"
        color="white"
      >
        {formatStringInTitle(title)}
      </Heading>

      <Box w="100%" h="1px" bg="gray" ml="12px" opacity="0.3" />
    </Flex>
  );
}
