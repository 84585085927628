import { Flex } from '@chakra-ui/react';
import { InputWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import React, { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hubApiOfficeBranches } from 'services/hubApi/classes/HubApiOfficeBranches';
import viaCepApi from 'services/viaCepApi';
import { formatCep } from 'utils/formatCep';
import { IReduxState } from 'shared/interfaces';
import { OfficesProps } from '../../interfaces';

interface NewBranchProps {
  handleToggleModal: () => void;
  setOffices: React.Dispatch<React.SetStateAction<OfficesProps[]>>;
  office: OfficesProps;
}

export const UpdateFilial: React.FC<NewBranchProps> = ({
  handleToggleModal,
  setOffices,
  office,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state: IReduxState) => state.user.profile);

  const [officeData, setOfficeData] = useState(office);

  async function onBlurCep(
    e: React.ChangeEvent<HTMLInputElement>,
    setData: React.Dispatch<React.SetStateAction<OfficesProps>>
  ) {
    const cep = formatCep(e.target.value);

    if (!cep) return;

    const response = await viaCepApi.get(cep);

    if (!response) {
      toast.dark('Erro ao pesquisar CEP.');
      return;
    }

    setData({
      ...officeData,
      managerId: user.id,
      cep,
      city: response.city,
      street: response.street,
      zona: response.neighborhood,
      uf: response.uf,
    });
  }

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOfficeData({ ...officeData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      await hubApiOfficeBranches.editBranchOffice(officeData);

      toast.dark('Filial editada com sucesso.');

      const officesListData = await hubApiOfficeBranches.listBranchOffices();
      setOffices(officesListData);
    } catch (err) {
      toast.dark('Ocorreu um erro ao criar uma filial.');
    }
    handleToggleModal();
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Criar filial"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      isLoading={isLoading}
      primaryButtonText="Salvar"
      secondaryButtonText="Cancelar"
      maxW={['95vw', '600px']}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <InputWithLabel
          label="CEP"
          placeholder="CEP da filial"
          name="cep"
          minW="250px"
          onBlur={e => onBlurCep(e, setOfficeData)}
          value={officeData.cep}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          minW="250px"
          label="Bairro"
          placeholder="Bairro"
          name="zona"
          value={officeData.zona}
          onChange={handleChangeValue}
        />
      </Flex>

      <Flex alignItems="center" mt="3" justifyContent="space-between">
        <InputWithLabel
          minW="250px"
          label="Número"
          placeholder="Número"
          name="number"
          value={officeData.number}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          minW="250px"
          label="Rua"
          placeholder="Rua"
          name="street"
          value={officeData.street}
          onChange={handleChangeValue}
        />
      </Flex>

      <Flex alignItems="center" mt="3" justifyContent="space-between">
        <InputWithLabel
          minW="250px"
          label="UF"
          placeholder="UF"
          name="uf"
          value={officeData.uf}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          minW="250px"
          label="Cidade"
          placeholder="Cidade"
          name="city"
          value={officeData.city}
          onChange={handleChangeValue}
        />
      </Flex>
    </BaseModalFormCard>
  );
};
