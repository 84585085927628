import { Text, Flex } from '@chakra-ui/react';
import { FcOk } from 'react-icons/fc';
import { IoIosAddCircleOutline } from 'react-icons/io';
import formatValue from 'utils/formatValue';
import { formatNameToCaptalize } from 'utils/captalizeNames';

type IClient = {
  id: string;
  name: string;
  pl: string;
  declaredPL: string;
};

interface ClientTagModalCardProps {
  client: IClient;
  selectedClients: string[];
  handleUnselectClient: (id: string) => void;
  handleSelectClient: (id: string) => void;
}

export const ClientTagModalCard: React.FC<ClientTagModalCardProps> = ({
  client,
  selectedClients,
  handleSelectClient,
  handleUnselectClient,
}) => {
  return (
    <Flex
      key={client.id}
      justify="space-between"
      flexDirection="column"
      px="2"
      py="1"
      borderRadius="3"
      bg="background.600"
    >
      <Flex justify="space-between" w="100%">
        <Text color="white">{formatNameToCaptalize(client.name)}</Text>

        <Flex alignSelf="center">
          {selectedClients.some(id => id === client.id) ? (
            <FcOk
              size={20}
              title="Cliente marcado para receber a tag, clique novamente para desmarcar"
              style={{ cursor: 'pointer' }}
              onClick={() => handleUnselectClient(client.id)}
            />
          ) : (
            <IoIosAddCircleOutline
              size={20}
              color="#fff"
              style={{ cursor: 'pointer' }}
              title="Marcar cliente para receber a tag"
              onClick={() => handleSelectClient(client.id)}
            />
          )}
        </Flex>
      </Flex>

      <Text fontSize="sm" fontStyle="italic">
        Patrimônio Declarado:
        <Text as="span" fontStyle="italic">
          {' '}
          {formatValue(client.declaredPL || 0)}
        </Text>
      </Text>
      <Text fontSize="sm" fontStyle="italic">
        PL:
        <Text as="span" fontStyle="italic">
          {' '}
          {formatValue(client.pl || 0)}
        </Text>
      </Text>
    </Flex>
  );
};
