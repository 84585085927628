import { Button } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { ScheduleMeetingCallbackModal } from 'componentsV2/modules/captation/scheduleMeetingCallbackModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { ClockClockwise } from 'phosphor-react';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export const ScheduleMeetingCallback = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenScheduleMeetingCallbackModal =
    getParam('isOpenScheduleMeetingCallbackModal') === 'true';

  const isLeadMeetingInR1orR2 =
    lead.meeting?.status === MeetingStatusEnum.SUITABILITY ||
    lead.meeting?.status === MeetingStatusEnum.PRESENTATION;

  return (
    <>
      <Button
        background="#D3D3D3"
        color="#383838"
        border="1px solid transparent"
        fontWeight="light"
        size="md"
        onClick={() => setParam('isOpenScheduleMeetingCallbackModal', 'true')}
        fontSize="0.8rem"
        width="120px"
        maxWidth="120px"
        justifyContent="space-between"
      >
        <ClockClockwise size={15} />
        {isLeadMeetingInR1orR2 ? 'Reagendar' : 'Retornar'}
      </Button>

      <ModalWrapper
        isOpen={isOpenScheduleMeetingCallbackModal}
        onClose={() => deleteParam('isOpenScheduleMeetingCallbackModal')}
      >
        <ScheduleMeetingCallbackModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
