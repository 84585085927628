import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import parser from 'html-react-parser';

import { formatDate } from 'utils/formatDate';

interface IAnnoucement {
  date: string;
  message: string;
  title: string;
}

interface AnnoucementProps {
  index?: number;
  announcement: IAnnoucement;
}

export const AnnouncementCard: React.FC<AnnoucementProps> = ({
  announcement,
}) => {
  return (
    <Flex
      width="100%"
      bg="background.500"
      flexDirection="column"
      px="4"
      py="2"
      mb="2"
      borderRadius="8px"
    >
      <Flex justifyContent="space-between">
        <Text color="white" fontWeight="bold" fontSize="md">
          <Text as="span" fontWeight="semibold">
            {' '}
            {announcement?.title}
          </Text>
        </Text>

        <Text color="white" fontSize="sm" fontWeight="light" fontStyle="italic">
          {formatDate(announcement?.date)}
        </Text>
      </Flex>
      <Box>
        <Text fontSize="sm" mt="1" mb="1" whiteSpace="unset">
          {parser(announcement.message)}
        </Text>

        {/* <span>
                    Qualquer dúvida ou questionamento, abra uma solicitação pelo
                    HUB para mais esclarecimentos.
                </span> */}
      </Box>
    </Flex>
  );
};
