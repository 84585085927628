import { WrapperBackgroundTransparentModalContent } from 'componentsV2/elements/modal/styles';
import styled, { css } from 'styled-components';
import ArrowIconDown from './selectArrowIconDown.svg';
import ArrowIconUp from './selectArrowIconUp.svg';

export type SelectPropsType = {
  fontSize?: string;
  borderColor?: string;
};

export const Select = styled.select<SelectPropsType>`
  ${({ fontSize, borderColor = 'gray' }) => css`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid ${borderColor};
    padding: 6px 6px 6px 15px;
    border-radius: 6px;
    text-color: #ffffffb2;
    font-size: ${fontSize || '15px'};
    color: rgba(70, 70, 70, 1);

    appearance: none;
    background-image: url(${ArrowIconDown});
    background-repeat: no-repeat;
    background-position: right 0.3rem top 50%;
    background-size: 1.5rem auto;
  `}
`;

type DropdownWithTextFilterInputPropsType = {
  isDropdownItemsOpen: boolean;
};

export const DropdownWithTextFilterInput = styled.input<DropdownWithTextFilterInputPropsType>`
  ${({ isDropdownItemsOpen }) => css`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #ffffff80;
    padding: 10px 10px 10px 15px;
    border-radius: 6px;
    text-color: #ffffffb2;
    font-size: ${'15px'};

    appearance: none;
    background-image: url(${isDropdownItemsOpen ? ArrowIconUp : ArrowIconDown});
    background-repeat: no-repeat;
    background-position: right 0.3rem top 50%;
    background-size: 1.5rem auto;

    ::placeholder {
      color: rgb(161, 161, 161);
      font-size: 14px;
      align-items: center;
      font-style: italic;
    }
  `}
`;

export const DropdownItems = styled(
  WrapperBackgroundTransparentModalContent
).attrs({
  className: 'thinScrollbar',
})`
  ${() => css`
    opacity: 100%;
    width: 91.5%;
    max-height: 350px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: absolute;
    padding: 10px;
    z-index: 999;
    border-radius: 6px;
    top: 105px;
  `}
`;
