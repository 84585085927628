import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';
import { useUpdateIPMeeting } from 'pages/IPLife/hooks/useUpdateIPMeeting';
import { FormEvent, useState } from 'react';
import { IPMeetingType } from 'services/hubApi';

type PropsType = {
  meeting: IPMeetingType;
  setIsOpenMeetingDeclineModal: (value: boolean) => void;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingDeclineModal: React.FC<PropsType> = ({
  meeting,
  setIsOpenMeetingDeclineModal,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const { mutateAsync: updateMeeting, isLoading } = useUpdateIPMeeting();

  const [declineReason, setDeclineReason] = useState<string>('');

  const declineOptions = [
    { title: 'Sem interesse', value: 'no_interest' },
    { title: 'Não ligar mais (LGPD)', value: 'lgpd' },
    { title: 'Não tem dinheiro', value: 'no_money' },
    { title: 'Cliente BTG', value: 'btg_client' },
  ];

  const formSubmit = async (event: FormEvent) => {
    setIsOpenMeetingDeclineModal(false);
    handleToggleOpenMeetingDetailsModal(false);

    event.preventDefault();

    await updateMeeting({
      meetingId: meeting.id,
      status: 'declined',
      declinedReason: declineReason,
    });

    setIsOpenMeetingDeclineModal(false);
    handleToggleOpenMeetingDetailsModal(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Deseja declinar essa reunião"
      handleFormSubmit={formSubmit}
      isLoading={isLoading}
      primaryButtonText="Confirmar"
      secondaryButtonEventFunction={() => setIsOpenMeetingDeclineModal(false)}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Dropdown
        placeholder="Escolha uma das opções"
        onChange={event => setDeclineReason(event.target.value)}
      >
        {declineOptions.map((item, index) => (
          <option key={index} value={item.value}>
            {item.title}
          </option>
        ))}
      </Dropdown>
    </BaseModalFormCard>
  );
};
