import { Text } from '@chakra-ui/react';

interface TitleWithBorderProps {
  label: string;
}

export const TitleWithBorder: React.FC<TitleWithBorderProps> = ({ label }) => {
  return (
    <Text
      borderBottom="1px solid gray"
      color="white"
      borderWidth="100%"
      fontSize="md"
      fontWeight="bold"
      mb="2"
    >
      {label}
    </Text>
  );
};
