import { useEffect, useState } from 'react';

import { IInfosMundoInvest } from 'pages/GenerationalVariables/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import { BigNumbersMundoInvest } from '../BigNumbersMundoInvest';
import { dataCopy } from './data';

export const DashBigNunbvers: React.FC = () => {
  const [bigNumbers, setBigNumbers] = useState({} as IInfosMundoInvest);
  const FullDate = new Date();

  const dates = {
    month0: new Date(FullDate.getFullYear(), FullDate.getMonth() + 1, 0)
      .toISOString()
      .split('T')[0],
    month1: new Date(FullDate.getFullYear(), FullDate.getMonth(), 0)
      .toISOString()
      .split('T')[0],

    month2: new Date(FullDate.getFullYear(), FullDate.getMonth() - 1, 0)
      .toISOString()
      .split('T')[0],

    month3: new Date(FullDate.getFullYear(), FullDate.getMonth() - 2, 0)
      .toISOString()
      .split('T')[0],

    month4: new Date(FullDate.getFullYear(), FullDate.getMonth() - 3, 0)
      .toISOString()
      .split('T')[0],

    month5: new Date(FullDate.getFullYear(), FullDate.getMonth() - 4, 0)
      .toISOString()
      .split('T')[0],
  };

  const handleGetBigNumbers = async () => {
    try {
      const response = await hubApiCaptation.getInfosMundoInvest();
      setBigNumbers(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    handleGetBigNumbers();
  }, []);

  return (
    <>
      <BigNumbersMundoInvest dates={dates} bigNumbers={bigNumbers} />
    </>
  );
};
