import { FormEvent, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Flex, Box, Text, Stack } from '@chakra-ui/react';
import { IReduxState } from 'shared/interfaces';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { SecondaryButton } from 'components/elements/buttons';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { EmployeesModalCard } from '../EmployeesModalCard';

interface EmployeesModalProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  employeesList: any[];
  selectedEmployees: any[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<any[]>>;
  getInformationOfRemuneration?: (cancelClick?: boolean) => void;
}

type SelectedEmployees = {
  name: string;
  id: string;
};

export const EmployeesModal: React.FC<EmployeesModalProps> = ({
  onClose,
  employeesList,
  setSelectedEmployees,
  selectedEmployees,
  getInformationOfRemuneration,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [filteredEmployeesList, setFilteredEmployeesList] = useState<any[]>([]);
  const [employees, setEmployees] = useState<SelectedEmployees[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchClientsParameters = useRef('');

  const handleSearchEmployee = () => {
    const foundEmployees = employeesList.filter(employee =>
      employee.name
        .toLowerCase()
        .includes(searchClientsParameters.current.toLowerCase())
    );

    setFilteredEmployeesList(foundEmployees);
  };

  const handleBackToAllClients = () => {
    setFilteredEmployeesList(employeesList);
  };

  const handleSelectEmployee = (employeeId: string, employeeName: string) => {
    if (selectedEmployees.length === 5) {
      toast.dark('Número de assessores excedido, selecione no máximo 5');
      return;
    }

    const employeeData = {
      id: employeeId,
      name: employeeName,
    };

    setSelectedEmployees([...selectedEmployees, employeeData]);
  };

  const handleUnselectEmployee = (employeeId: string) => {
    const listWithoutUnselectClient = selectedEmployees.filter(
      item => item.id !== employeeId
    );
    setSelectedEmployees(listWithoutUnselectClient);
  };

  useEffect(() => {
    setFilteredEmployeesList(employeesList);
  }, []);

  return (
    <BaseModalFormCard
      isModal
      title="Selecione até 5 assessores de quem você quer visualizar os dados de comissão"
      handleFormSubmit={(e: FormEvent) => {
        e.preventDefault();
        if (getInformationOfRemuneration) getInformationOfRemuneration();

        onClose(false);
      }}
      isLoading={isLoading}
      primaryButtonText="Buscar dados"
      secondaryButtonEventFunction={() => {
        onClose(false);
        if (getInformationOfRemuneration) getInformationOfRemuneration(true);
      }}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Flex justify="space-between">
        <Box w="100%">
          <Flex w="100%" align="center" justify="space-between">
            <Box w="100%">
              <InputWithLabel
                label="Nome do colaborador:"
                name="name"
                autoComplete="off"
                onChange={e => {
                  searchClientsParameters.current = e.target.value;
                }}
              />
            </Box>
            <SecondaryButton mt="6" ml="2" onClick={handleSearchEmployee}>
              Pesquisar
            </SecondaryButton>
          </Flex>

          {filteredEmployeesList.length !== employeesList.length && (
            <Text
              textDecoration="underline"
              mt="1"
              mb="1"
              ml="1"
              fontSize="xs"
              _hover={{ color: 'white' }}
              cursor="pointer"
              textAlign="right"
              w="fit-content"
              onClick={handleBackToAllClients}
            >
              Voltar para todos
            </Text>
          )}

          <DefaultCardWrapper
            h="264px"
            maxH="265px"
            overflow="auto"
            mt={
              filteredEmployeesList.length !== employeesList.length ? '0' : '6'
            }
            p="2"
            bg="background.400"
            flexDir="column"
          >
            <Stack p="1">
              {filteredEmployeesList.length > 0 &&
                filteredEmployeesList.map(item => (
                  <EmployeesModalCard
                    key={item.id}
                    employee={item}
                    selectedEmployees={selectedEmployees}
                    handleSelectEmployee={handleSelectEmployee}
                    handleUnselectEmployee={handleUnselectEmployee}
                  />
                ))}

              {filteredEmployeesList.length === 0 && (
                <Text textAlign="center" fontStyle="italic">
                  Sem resultados
                </Text>
              )}
            </Stack>
          </DefaultCardWrapper>
        </Box>
      </Flex>
    </BaseModalFormCard>
  );
};
