import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LoadingSpinner } from 'components/elements/others';
import { useUpdateLeadAction } from 'hooksV2/useUpdateLeadAction';
import {
  LeadActionStatusEnum,
  LeadActionType,
} from 'sharedV2/types/leadAction';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { showToast } from 'utilsV2/showToast';

function CalendarIcon({ color }: { color: string }) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 7.25h12m-3-4.5v1.5m-6-1.5v1.5m-.6 0h7.2c.84 0 1.26 0 1.58.163a1.5 1.5 0 01.657.656c.163.32.163.74.163 1.581v7.2c0 .84 0 1.26-.163 1.581a1.5 1.5 0 01-.656.656c-.321.163-.74.163-1.58.163H5.9c-.84 0-1.261 0-1.582-.163a1.5 1.5 0 01-.656-.656c-.163-.32-.163-.741-.163-1.581v-7.2c0-.84 0-1.26.163-1.581a1.5 1.5 0 01.656-.656c.32-.163.741-.163 1.581-.163z"
        stroke={color}
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

type PropsType = {
  lead: LeadMundoInvestType | undefined;
};

export const SalesHunterPoolLeadActions: React.FC<PropsType> = ({
  lead,
}): JSX.Element => {
  const {
    mutateAsync: updateLeadAction,
    isLoading: isLoadingUpdateLeadAction,
  } = useUpdateLeadAction({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['shPoolLeads']);
      await queryClient.invalidateQueries(['leadDetails', lead?.id]);
    },
  });

  const handleUpdateLeadAction = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: LeadActionType
  ) => {
    event.stopPropagation();

    if (action.status === LeadActionStatusEnum.REALIZADA) {
      return showToast('Tarefa já concluída');
    }

    await updateLeadAction({
      ...action,
      status: LeadActionStatusEnum.REALIZADA,
    });
  };

  return (
    <Flex ml={window.location.href.includes('/sh/pool') ? 7 : 0}>
      {isLoadingUpdateLeadAction && <LoadingSpinner size="sm" h="10px" />}
      {!isLoadingUpdateLeadAction &&
        lead?.actions?.map(action => {
          return (
            <Flex
              onClick={event => handleUpdateLeadAction(event, action)}
              cursor="pointer"
            >
              <CalendarIcon
                color={
                  action.status === LeadActionStatusEnum.AGUARDANDO
                    ? '#FF607D'
                    : '#39DDA2'
                }
              />
            </Flex>
          );
        })}
    </Flex>
  );
};
