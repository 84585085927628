/* eslint no-unused-vars: off */
import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { FiTrash2 } from 'react-icons/fi';

interface INotice {
  id: string;
  data: string;
  title: string;
  msg: string;
}

interface ComponentProps {
  notice: INotice;
  noticeIndex: number;
  handleDeleteNotice: (noticeId: string) => void;
}

export const NoticeItem: React.FC<ComponentProps> = ({
  notice,
  noticeIndex,
  handleDeleteNotice,
}) => {
  return (
    <Draggable
      draggableId={`item-${notice.id}`}
      index={noticeIndex}
      key={notice.id}
    >
      {provided => (
        <Flex
          color="white"
          background="background.500"
          w="100%"
          padding="3"
          borderRadius="2"
          justifyContent="space-between"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className="notice-item"
        >
          <Text maxW="196px" fontSize="smaller" isTruncated>
            <Text as="strong">Data: </Text>
            {notice.data}
          </Text>
          <Text width="100%" maxW="196px" fontSize="smaller" isTruncated>
            <Text as="strong">Título: </Text>
            {notice.title}
          </Text>
          <Text width="100%" maxW="196px" fontSize="smaller" isTruncated>
            <Text as="strong">Conteúdo: </Text>
            {notice.msg}
          </Text>

          <FiTrash2
            size={18}
            color="#fff"
            onClick={() => handleDeleteNotice(notice.id)}
          />
        </Flex>
      )}
    </Draggable>
  );
};
