import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others';
import { Content } from 'pages/Contents/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface PermissionsModalProps {
  closeModal: () => void;
  saveContent: (permissions: any) => void;
  previousContent?: Content;
  getContentToEdit?: () => void;
}

export const PermissionsModal: React.FC<PermissionsModalProps> = ({
  closeModal,
  saveContent,
  previousContent,
  getContentToEdit,
}) => {
  const [customizedSession, setCustomizedSession] = useState(false);
  const [allPermissions, setAllPermissions] = useState([] as any);
  const [selectedPermissions, setSelectedPermissions] = useState({
    TI: [],
    IP: [],
    ASS: [],
    EX: [],
    MKT: [],
    FI: [],
    RV: [],
    PDT: [],
    RH: [],
    all: true,
  } as any);
  const [selectedAllAreasAndRoles, setSelectedAllAreasAndRoles] =
    useState(true);
  const [selectedAreaCod, setSelectedAreaCod] = useState('');

  const handleSelectedPermission = (
    permissionCod: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      selectedPermissions[selectedAreaCod]?.push(permissionCod);

      setSelectedPermissions({
        ...selectedPermissions,
      });
    } else {
      setSelectedPermissions({
        ...selectedPermissions,
        [selectedAreaCod]: selectedPermissions[selectedAreaCod]?.filter(
          (item: any) => item !== permissionCod
        ),
      });
    }
  };

  const handleCustomizedSessionClick = (checked: boolean) => {
    if (checked) {
      setCustomizedSession(true);
      setSelectedAllAreasAndRoles(false);
      setSelectedPermissions({
        ...selectedPermissions,
        all: false,
      });
    } else {
      setCustomizedSession(false);
    }
  };

  const handleSelectedAllAreasAndRoles = (checked: boolean) => {
    if (checked) {
      setSelectedAllAreasAndRoles(true);
      setSelectedPermissions({
        ...selectedPermissions,
        TI: [],
        IP: [],
        ASS: [],
        EX: [],
        MKT: [],
        FI: [],
        RV: [],
        PDT: [],
        RH: [],
        all: true,
      });
    } else {
      setSelectedAllAreasAndRoles(false);
      setSelectedPermissions({
        ...selectedPermissions,
        all: false,
      });
    }
  };

  const getAllPermissions = async () => {
    const response = await hubApiUser.getAllPermissions();
    setAllPermissions(response);
  };

  const checkPermission = (permissionCod: string) => {
    if (selectedPermissions[selectedAreaCod].includes(permissionCod)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveContent = (selectedPermissions: any) => {
    if (
      !customizedSession &&
      !Object.values(selectedPermissions).some(
        (item: any) => item.length > 0
      ) &&
      !selectedAllAreasAndRoles
    ) {
      toast.dark('Selecione uma opção de permissão para o conteúdo');
      return;
    }

    saveContent(selectedPermissions);
  };

  const handleCloseModal = async () => {
    if (previousContent?.permission) {
      let editData: any = {
        id: previousContent?.id,
        active: true,
        title: previousContent.title,
        subtitle: previousContent.subtitle,
        area: previousContent.area,
        theme: previousContent.theme,
        content: previousContent.content || ' ',
        permission: selectedPermissions,
      };

      if (previousContent.video) {
        editData = {
          ...editData,
          video: previousContent.video,
        };
      }

      try {
        await hubApiUser.editContent(editData);
        toast.dark('Permissões atualizadas com sucesso!');
      } catch {
        toast.dark(
          'Não foi possível salvar as permissões, tente novamente em alguns minutos'
        );
      }

      if (getContentToEdit) {
        getContentToEdit();
      }
      closeModal();
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    getAllPermissions();
  }, []);

  useEffect(() => {
    if (previousContent?.permission) {
      setSelectedPermissions(previousContent.permission);

      if (previousContent.permission.all) {
        setSelectedAllAreasAndRoles(true);
      } else {
        setSelectedAllAreasAndRoles(false);
      }
    }
  }, [previousContent]);

  return (
    <>
      {customizedSession ? (
        <DefaultModalContentWrapper h="400px" maxW={['95vw', 'auto']}>
          <ModalTitle textAlign="center">Personalizando o acesso</ModalTitle>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mb={6}
          >
            <Dropdown
              w="200px"
              placeholder="Selecione a área"
              onChange={e => setSelectedAreaCod(e.target.value)}
            >
              {allPermissions.map((item: any) => (
                <option key={item.id} value={item.cod}>
                  {item.name}
                </option>
              ))}
            </Dropdown>
          </Flex>

          {allPermissions
            ?.filter((item: any) => item.cod === selectedAreaCod)[0]
            ?.permissions.map((permission: any) => (
              <Checkbox
                key={permission.id}
                value={permission.cod}
                onChange={e =>
                  handleSelectedPermission(permission.cod, e.target.checked)
                }
                isChecked={checkPermission(permission.cod)}
              >
                {permission.name}
              </Checkbox>
            ))}

          <BaseButtonGroup mt="auto">
            <SecondaryButton
              onClick={() => {
                setCustomizedSession(false);
                setSelectedAreaCod('');
              }}
            >
              Voltar
            </SecondaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      ) : (
        <DefaultModalContentWrapper h="400px" maxW={['95vw', 'auto']}>
          <ModalTitle textAlign="center">
            Níveis de acesso deste conteúdo
          </ModalTitle>

          <Checkbox
            onChange={e => handleSelectedAllAreasAndRoles(e.target.checked)}
            isChecked={selectedAllAreasAndRoles}
          >
            Todas as áreas e cargos
          </Checkbox>
          <Flex alignItems="baseline">
            <Checkbox
              isChecked={Object.values(selectedPermissions).some(
                (item: any) => item.length > 0
              )}
              onChange={e => handleCustomizedSessionClick(e.target.checked)}
            >
              Personalizado
            </Checkbox>
            {Object.values(selectedPermissions).some(
              (item: any) => item.length > 0
            ) && (
              <Text
                _hover={{ textDecoration: 'underline' }}
                as="button"
                ml={2}
                fontSize="xs"
                color="primary.100"
                cursor="pointer"
                onClick={() => setCustomizedSession(true)}
              >
                gerenciar
              </Text>
            )}
          </Flex>
          {Object.values(selectedPermissions).some(
            (item: any) => item.length > 0
          ) && (
            <Flex ml={6} flexDirection="column">
              {Object.entries(selectedPermissions)
                .filter((item: any) => item[1].length > 0)
                .map((permission: any) => (
                  <Text fontSize="sm">
                    -
                    {(permission[0] === 'TI' && 'Tecnologia') ||
                      (permission[0] === 'IP' && 'Inteligência Patrimonial') ||
                      (permission[0] === 'ASS' && 'Assessoria') ||
                      (permission[0] === 'EX' && 'Câmbio') ||
                      (permission[0] === 'MKT' && 'Marketing') ||
                      (permission[0] === 'FI' && 'Financeiro') ||
                      (permission[0] === 'RV' && 'Renda Variável') ||
                      (permission[0] === 'PDT' && 'Produtos') ||
                      (permission[0] === 'RH' && 'Recursos Humanos')}
                    :
                    <Text fontSize="sm" as="span" fontStyle="italic">
                      {permission[1].map(
                        (permissionCod: any) =>
                          (permissionCod === 'CLT' && ' Colaborador,') ||
                          (permissionCod === 'GTR' && ' Gestor Geral,') ||
                          (permissionCod === 'CLI' && ' Closer Imobiliário,') ||
                          (permissionCod === 'CLS' && ' Closer Saúde,') ||
                          (permissionCod === 'CLV' && ' Closer Vida,') ||
                          (permissionCod === 'CLG' && ' Closer Agro,') ||
                          (permissionCod === 'CLA' &&
                            ' Closer Seguros Alternativos,') ||
                          (permissionCod === 'CLC' && ' Closer Corporate,') ||
                          (permissionCod === 'COT' &&
                            ' Coordenador de trainee,') ||
                          (permissionCod === 'TRA' && ' Trainee,') ||
                          (permissionCod === 'ASS' && ' Assessor,') ||
                          (permissionCod === 'GTM' && ' Gestor de Mesa,') ||
                          (permissionCod === 'BRK' && ' Broker,') ||
                          (permissionCod === 'ESP' && ' Especialista,') ||
                          (permissionCod === 'TRA' && ' Trainee,') ||
                          (permissionCod === 'TRA' && ' Trainee,')
                      )}
                    </Text>
                  </Text>
                ))}
            </Flex>
          )}

          <BaseButtonGroup mt="auto">
            {previousContent?.permission ? (
              <PrimaryButton onClick={() => handleCloseModal()}>
                Salvar permissões
              </PrimaryButton>
            ) : (
              <SecondaryButton onClick={() => handleCloseModal()}>
                Voltar
              </SecondaryButton>
            )}

            {!previousContent?.permission && (
              <PrimaryButton
                onClick={() => handleSaveContent(selectedPermissions)}
                style={{ margin: 0 }}
              >
                Salvar conteúdo
              </PrimaryButton>
            )}
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      )}
    </>
  );
};
