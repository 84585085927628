import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { DropdownMenuContent } from './styles';

interface ComponentProps {
  title: string;
  drop?: 'left' | 'right' | 'up' | 'down' | undefined;
  small?: boolean;
  md?: boolean;
  pickOption?: boolean;
  handleClick: any;
  options: any;
}

const DropdownMenu: React.FC<ComponentProps> = ({
  title,
  options,
  drop,
  small,
  md,
  pickOption,
  handleClick,
}) => {
  return (
    <DropdownMenuContent>
      <DropdownButton
        variant="secondary"
        title={title}
        drop={drop}
        className={`dropdown-button
            ${small && 'dropdown-button-sm'}
            ${md && 'dropdown-button-md'}
            `}
      >
        {options &&
          options.map((item: any, index: number) => (
            <Dropdown.Item
              key={index}
              onClick={
                pickOption
                  ? () => handleClick(item)
                  : () => handleClick(item.title || item.name, item.id)
              }
            >
              {item.title
                ? item.title
                : item.name
                ? item.name
                : `${item.city} - ${item.zona}`}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    </DropdownMenuContent>
  );
};

export default DropdownMenu;
