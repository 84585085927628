import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Center, Divider, Flex, Stack, useMediaQuery } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import formatValue from 'utils/formatValue';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { LoadingWithOverlay } from 'components/elements/others';
import { InputWithLabel } from 'components/elements/forms';

import {
  IClients,
  InfosClients,
} from 'pages/SearchUserDetails/interfaces/clients';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { InputInfoDisplay } from '../../components/texts';
import {
  DividerItens,
  InlineInfosCLients,
  PieClientsChart,
} from '../../components/modules';

interface TabItemsProps {
  employee: EmployeesProps;
}

export const ClientsPage: React.FC<TabItemsProps> = ({ employee }) => {
  const currencyDate = new Date().toISOString().slice(0, 7);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [date, setDate] = useState(currencyDate);
  const [clients, setClients] = useState<IClients[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [infosClients, setSInfosClients] = useState<InfosClients>(
    {} as InfosClients
  );

  const clientsCopy = clients;
  const sumPL = clients.reduce(
    (accumulator, client) => Number(client.pl) + accumulator,
    0
  );

  const sumFromField = (fieldName: string) => {
    return clients.reduce((acc, item: any) => {
      const fieldValue = Number(item[fieldName]) || 0;

      return acc + fieldValue;
    }, 0);
  };

  const getEmployeeWallet = useMemo(() => {
    return {
      graph: [
        {
          id: '1',
          label: 'Renda fixa',
          value: sumFromField('rv'),
          color: '#785DD1',
        },

        {
          id: '2',
          label: 'Conta corrente',
          value: sumFromField('account'),
          color: '#FEB01A',
        },

        {
          id: '3',
          label: 'Fundos',
          value: sumFromField('funds'),
          color: '#018FFB',
        },
        {
          id: '4',
          label: 'Previdência',
          value: sumFromField('prev'),
          color: '#FF4460',
        },
        {
          id: '5',
          label: 'Renda variável',
          value: sumFromField('rf'),
          color: '#75DF9B',
        },

        {
          id: '6',
          label: 'Derivativos',
          value: sumFromField('deriv'),
          color: '#000000',
        },
      ],
    };
  }, [clients]);

  const handleGetInfoCLients = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await hubApiUser.getClientsInUser(employee.id, date);

      setClients(response);
    } catch (err) {
      errorHandler(err);
    }
    setIsLoading(false);
  }, [date, isLoading]);

  const handleGetClientsInUserInfo = useCallback(async () => {
    try {
      const response = await hubApiUser.getClientsInUserInfo(employee.id, date);
      setSInfosClients(response);
    } catch (err) {
      errorHandler(err);
    }
  }, [date, isLoading]);

  useEffect(() => {
    handleGetInfoCLients();
    handleGetClientsInUserInfo();
  }, [date]);

  clientsCopy.sort((item, proxItem) => {
    let returnValue = 0;
    if (item.date > proxItem.date) {
      returnValue = 1;
    } else {
      returnValue = -1;
    }
    return returnValue;
  });

  const exitClients =
    clientsCopy.reduce(
      (acc, item) => (item.date === clientsCopy[0].date ? acc + 1 : acc),
      0
    ) -
    clientsCopy.reduce(
      (acc, item) =>
        item.date === clientsCopy[clientsCopy.length - 1].date ? acc + 1 : acc,
      0
    );

  return (
    <Flex
      w="100%"
      borderRadius={4}
      gridGap={6}
      h="calc(100vh - 264px)"
      flexDirection={['column', 'row']}
      bg="#f2f2f2"
      p={4}
    >
      <Stack w="100%" flexDir="column" mb="48px" spacing={6}>
        <InputWithLabel
          type="month"
          value={date}
          onChange={e => setDate(e.target.value)}
          label="Periodo"
        />

        <Stack spacing={1}>
          <DividerItens
            orientationDivider="horizontal"
            titleDivider="Dados por período"
          />
          <InputInfoDisplay
            field="Número de clientes"
            data={String(clients.length)}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />
          <InputInfoDisplay
            field="PL médio dos clientes"
            data={
              sumPL / clients.length > 0
                ? formatValue(sumPL / clients.length)
                : '0'
            }
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />

          <InputInfoDisplay
            field="Captação"
            data={formatValue(String(infosClients.tedin))}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />

          <InputInfoDisplay
            field="Ted-out"
            data={formatValue(String(infosClients.tedout))}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />

          <InputInfoDisplay
            field="Saída total de clientes"
            data={exitClients < 0 ? '0' : String(exitClients)}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />
        </Stack>

        <DividerItens
          orientationDivider="horizontal"
          titleDivider="Dados gerais"
        />

        <Stack spacing={1}>
          <InputInfoDisplay
            field="NPS"
            data={Number(infosClients.nps).toFixed(2)}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />

          <InputInfoDisplay
            field="Número de clientes atual"
            data={String(infosClients.totalCustomers)}
            bg="#E7E7E7"
            p="4px 8px"
            borderRadius="4px"
            fontSize="sm"
          />
        </Stack>
      </Stack>

      <Center>
        <Divider
          orientation={isMobile ? 'horizontal' : 'vertical'}
          type="gray"
        />
      </Center>

      <Flex width="100%" flexDir="column" w="100%">
        <PieClientsChart data={getEmployeeWallet.graph} total={sumPL} />

        <DividerItens orientationDivider="horizontal" titleDivider="Clientes" />

        <Stack maxH="186px" overflow="auto" pr="1">
          {clients.map((item, key) => (
            <InlineInfosCLients key={key} name={item.name} value={item.pl} />
          ))}
        </Stack>
      </Flex>

      <LoadingWithOverlay isOpen={isLoading} />
    </Flex>
  );
};
