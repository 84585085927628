import React from 'react';
import { IconType } from 'react-icons';

import { Tooltip } from '../../others';

import {
  MainPageBtnContainer,
  MainPageLinkContainer,
  IconContainer,
  Title,
} from './styles';

interface IOption {
  title: string;
  description?: string;
  value?: string;
  icon?: IconType;
  path?: string;
}

interface ComponentProps {
  option?: IOption;
  Icon: IconType;
  path?: string;
  isLink?: boolean;
  information?: string;
  handleOpenModal?: (option: any) => void;
}

export const MainPageButton: React.FC<ComponentProps> = ({
  option,
  Icon,
  handleOpenModal = () => {},
  path = '',
  isLink,
  information,
}) => {
  if (isLink)
    return (
      <MainPageLinkContainer to={path} className="main-page-btn">
        <IconContainer>
          {Icon && <Icon size={72} />}
          {information && <Tooltip information={information} />}
        </IconContainer>
        <Title>{option?.title}</Title>
      </MainPageLinkContainer>
    );

  return (
    <MainPageBtnContainer onClick={() => handleOpenModal(option)}>
      <IconContainer>
        {Icon && <Icon size={72} />}
        {information && <Tooltip information={information} />}
      </IconContainer>
      <Title>{option?.title}</Title>
    </MainPageBtnContainer>
  );
};
