import { Flex, SimpleGrid } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { Box } from 'componentsV2/elements/boxes';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { ClientDetailsAdditionalInformations } from 'pagesV2/ClientDetails/components/additionalInformations';
import { ClientDetailsBasicData } from 'pagesV2/ClientDetails/components/basicData';
import { ClientMeetingDetailsDisclaimer } from 'pagesV2/ClientMeetingDetails/components/meetingDisclaimer';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { DateUtils } from 'utilsV2/Date';
import { ClientMeetingDetailsActions } from './components/actions';
import { ClientMeetingDetailsSkeletonLoading } from './components/skeletonLoading';

export const ClientMeetingDetails = (): JSX.Element => {
  const { btgCode, id } = useParams<{ btgCode: string; id: string }>();

  const { data: meeting, isLoading: isLoadingMeeting } = useQuery({
    queryKey: ['clientMeetingDetails', id],
    queryFn: async () => {
      const response = await hubApiClients.getMeetingById(id);

      return response;
    },
  });

  const { data: client, isLoading: isLoadingClient } = useQuery({
    queryKey: ['clientDetails', btgCode],
    queryFn: async () => {
      const response = await hubApiClients.getClientByBtgCode(btgCode);
      return response;
    },
  });

  if (!meeting || isLoadingMeeting || !client || isLoadingClient) {
    return <ClientMeetingDetailsSkeletonLoading />;
  }

  return (
    <PageWrapper paddingTop="2rem" backPageButton>
      <MainPageTitle>
        Reunião - {DateUtils.format(meeting.date, 'dd/MM/yyyy')}
      </MainPageTitle>

      <Flex h="calc(100vh - 200px)">
        <Box.Glass
          paddingY={2}
          style={{ flex: 1 }}
          alignItems="start"
          overflow="hidden"
        >
          <SimpleGrid
            columns={2}
            gap={5}
            w="100%"
            h="100%"
            gridTemplateRows="100%"
          >
            <SimpleGrid
              columns={1}
              gap={6}
              gridTemplateRows="1fr minmax(100px, 1fr)"
            >
              <ClientDetailsBasicData client={client} />
              <ClientDetailsAdditionalInformations client={client} />
            </SimpleGrid>

            <SimpleGrid columns={1} gap={6} gridTemplateRows="1fr">
              <ClientMeetingDetailsDisclaimer meeting={meeting} />
              <ClientMeetingDetailsActions meeting={meeting} />
            </SimpleGrid>
          </SimpleGrid>
        </Box.Glass>
      </Flex>
    </PageWrapper>
  );
};
