import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import hubApi from 'services/hubApi';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CheckingToken } from '../components/CheckingToken';
import { LinkExpired } from '../components/LinkExpired';

import { IUserProps } from './interfaces';
import BasicFields from '../components/Register/BasicFields';
import PersonalFields from '../components/Register/PersonalFields';
import LocationFields from '../components/Register/LocationFields';
import ContractFields from '../components/Register/ContractFields';
import SendForm from '../components/Register/SendForm';
import { FINAL_STEP } from './configs/constants';

export const Register: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [mainForm, setMainForm] = useState<IUserProps>({} as IUserProps);

  const [tokenIsValid, setTokenIsValid] = useState<boolean>(true);
  const [loadingToken, setLoadingToken] = useState<boolean>(false);

  const { managerId, token } =
    useParams<{ managerId: string; token: string }>();

  const checkTokenValidity = async (tokenToCheck: string) => {
    const tokens = await hubApi.isTokenValid(tokenToCheck);

    if (tokens.revoked) {
      setLoadingToken(false);
      setTokenIsValid(false);
      return;
    }

    setLoadingToken(false);

    setMainForm({
      ...mainForm,
      token: tokens.id,
    });
  };

  useEffect(() => {
    checkTokenValidity(token);
  }, [token]);

  const handleNextStep = (progress: number) => {
    if (step === FINAL_STEP) return;
    setStep(prevStep => prevStep + 1);
    setProgressValue(progress);
  };

  const handlePreviousStep = (progress: number) => {
    setStep(prevStep => prevStep - 1);
    setProgressValue(progress);
  };

  const handleFirstSteep = (stepValue: number, progress: number) => {
    setStep(stepValue);
    setProgressValue(progress);
  };

  if (loadingToken) {
    return <CheckingToken />;
  }

  if (!tokenIsValid) {
    return <LinkExpired />;
  }

  const componentsByStep: Record<number, React.ReactElement> = {
    1: (
      <BasicFields
        step={step}
        handleNextPage={handleNextStep}
        setMainForm={setMainForm}
        mainForm={mainForm}
      />
    ),
    2: (
      <PersonalFields
        step={step}
        handleNextPage={handleNextStep}
        handlePrevPage={handlePreviousStep}
        setMainForm={setMainForm}
        mainForm={mainForm}
      />
    ),
    3: (
      <LocationFields
        step={step}
        handleNextPage={handleNextStep}
        handlePrevPage={handlePreviousStep}
        setMainForm={setMainForm}
        mainForm={mainForm}
      />
    ),
    4: (
      <ContractFields
        step={step}
        handleNextPage={handleNextStep}
        handlePrevPage={handlePreviousStep}
        setMainForm={setMainForm}
        mainForm={mainForm}
        managerId={managerId}
      />
    ),
    5: <SendForm mainForm={mainForm} handlePrevPage={handleFirstSteep} />,
  };

  return (
    <Flex
      width="100%"
      maxW="640px"
      h="100%"
      flexDir="column"
      justify="center"
      alignItems="center"
    >
      <Box w="100%" maxW="640px" mb="6px">
        <ProgressBar
          style={{ height: '0.65rem' }}
          now={progressValue}
          variant="success"
          animated
        />

        {step < FINAL_STEP && (
          <>
            <Text m="16px 0 0" color="white" fontSize="xl" fontWeight="bold">
              Bem-vindo(a) a EWZ Capital
            </Text>
            <Text mb="2" fontSize="md" color="gray.400">
              Preencha o formulário abaixo com as suas informações
            </Text>
          </>
        )}
      </Box>
      {componentsByStep[step]}
    </Flex>
  );
};
