import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { BaseModalFormCard } from 'components/elements/cards';
import { Input, InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import hubApi, { BIType } from 'services/hubApi';
import { PowerBIDescription } from '../components/powerBIDescription';
import { AddPowerBIDescriptionModal } from './AddPowerBIDescription';

type PropsType = {
  handleToggleOpenEditPowerBIModal: (value: boolean) => void;
  page: BIType;
};

export type UpdatePowerBIMutationRequestType = {
  powerBIUrl?: string;
} & BIType;

export const EditPowerBIModal: React.FC<PropsType> = ({
  handleToggleOpenEditPowerBIModal,
  page,
}): JSX.Element => {
  const [powerBIUrl, setPowerBIUrl] = useState<string>('');

  const [
    isOpenAddPowerBIDescriptionModal,
    setIsOpenAddPowerBIDescriptionModal,
  ] = useState<boolean>(false);

  const [splitedPowerBIDescription, setSplitedPowerBIDescription] = useState<
    string[]
  >(page.description.split('\\n'));

  const { mutateAsync: updatePowerBI, isLoading: isLoadingUpdatePowerBI } =
    useMutation({
      mutationKey: ['updatePowerBI'],
      mutationFn: async (data: UpdatePowerBIMutationRequestType) => {
        const response = await hubApi.updatePowerBI({ ...data });
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['powerBIManagement']);
        await queryClient.invalidateQueries(['allPowerBIsDashboards']);
        toast.dark('Power BI atualizado com sucesso!');
        handleToggleOpenEditPowerBIModal(false);
      },
      onError: () => {
        toast.dark('Erro ao atualizar o Power BI');
      },
    });

  const handleToggleIsOpenAddPowerBIDescriptionModal = (value: boolean) => {
    setIsOpenAddPowerBIDescriptionModal(value);
  };

  const handleRemovePowerBIDescription = (descriptionToRemove: string) => {
    const updatedDescriptions = splitedPowerBIDescription.filter(
      description => description !== descriptionToRemove
    );

    setSplitedPowerBIDescription(updatedDescriptions);
  };

  const handleAddPowerBIDescription = async (
    event: React.FormEvent<HTMLFormElement>,
    value: string
  ) => {
    event.preventDefault();

    setSplitedPowerBIDescription(prev => [...prev, value]);

    handleToggleIsOpenAddPowerBIDescriptionModal(false);
  };

  const handleUpdatePowerBI = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (splitedPowerBIDescription.length === 0) {
      return toast.dark('Um Power BI deve ter pelo menos uma descrição.');
    }

    await updatePowerBI({
      ...page,
      powerBIUrl,
      description: splitedPowerBIDescription.toString().replaceAll(',', '\\n'),
    });
  };

  return (
    <>
      <BaseModalFormCard
        isModal
        title="Editar informações do Power BI"
        handleFormSubmit={handleUpdatePowerBI}
        handleToggleModal={() => handleToggleOpenEditPowerBIModal(false)}
        isLoading={isLoadingUpdatePowerBI}
        primaryButtonText="Editar"
        secondaryButtonText="Cancelar"
        flexDir="column"
        width="100%"
      >
        <Flex flexDirection="column" gap={5}>
          <Text fontSize="sm" fontStyle="italic">
            {page.name}
          </Text>
          <Flex flexDirection="column" gap={5}>
            <Flex flexDirection="column" gap={2}>
              <InputWithLabel
                label="URL"
                placeholder="Power BI URL"
                value={powerBIUrl}
                onChange={event => setPowerBIUrl(event.target.value)}
              />
            </Flex>

            <PowerBIDescription
              descriptions={splitedPowerBIDescription}
              handleRemovePowerBIDescription={handleRemovePowerBIDescription}
              handleToggleIsOpenAddPowerBIDescriptionModal={
                handleToggleIsOpenAddPowerBIDescriptionModal
              }
            />
          </Flex>
        </Flex>
      </BaseModalFormCard>
      <ModalWrapper
        isOpen={isOpenAddPowerBIDescriptionModal}
        onClose={() => handleToggleIsOpenAddPowerBIDescriptionModal(false)}
      >
        <AddPowerBIDescriptionModal
          isLoading={isLoadingUpdatePowerBI}
          handleAddPowerBIDescription={handleAddPowerBIDescription}
          handleToggleIsOpenAddPowerBIDescriptionModal={
            handleToggleIsOpenAddPowerBIDescriptionModal
          }
        />
      </ModalWrapper>
    </>
  );
};
