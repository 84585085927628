import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import {
  IEmployeeSelected,
  IUsersSearh,
} from 'pages/GenerationalVariables/interfaces';

interface UsersSelectedProps {
  employee: IEmployeeSelected | IUsersSearh;
  handleTakeOfUser: (employeeToRemove: IEmployeeSelected) => void;
}

export const SelectedEmployeeCardForMultiselectionModal: React.FC<UsersSelectedProps> =
  ({ employee }) => {
    return (
      <Flex justify="space-between" px="2" py="1" borderRadius="3" bg="white">
        <Text color="rgba(70, 70, 70, 1)">
          {formatToCapitalized(`${employee.name ? employee.name : employee}`, {
            trimTrailingWhiteSpaces: false,
          })}
        </Text>
      </Flex>
    );
  };
