import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IoChevronBack } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

import history from 'services/history';
import { formatValueForSI } from 'utils/formatValueForSI';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle, ModalTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { TableBase } from 'components/elements/table';
import { ClientRow } from 'pages/Offers/components';
import { Client, INewOffer } from 'pages/Offers/interfaces';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import formatValue from 'utils/formatValue';
import { RiEdit2Fill } from 'react-icons/ri';
import { Input } from 'components/elements/forms';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';

type Offer = {
  id: string;
  offerName: string;
  tableId: string;
  tags: string[];
  fee: number;
  personalFee: {
    employeeId: string;
    fee: string;
  }[];
  isPublic?: boolean;
  dateLimit: string;
};

type ClientProps = Omit<
  Client,
  'id' | 'offerId' | 'managerBTGAccount' | 'created_at'
>;

type ITags = {
  label: string;
  value: string;
};

export const OfferDetailsSection: React.FC = () => {
  const filterOptions = [
    {
      value: 'Todos',
    },
    {
      value: 'Confirmado',
    },
    {
      value: 'Pendente',
    },
    {
      value: 'Cancelado',
    },
  ];

  const user = useSelector((state: IReduxState) => state.user.profile);
  const { offerId } = useParams<{ offerId: string }>();
  const [offer, setOffer] = useState<Offer>({} as Offer);
  const [clients, setClients] = useState<ClientProps[]>([]);
  const [filteredClients, setFilteredClients] = useState<ClientProps[]>([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [confirmedClients, setConfirmedClients] = useState(0);
  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [showAlertToSaveModal, setShowAlertToSaveModal] = useState(false);
  const [allTags, setAllTags] = useState<ITags[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITags[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showEditFeeModal, setShowEditFeeModal] = useState(false);
  const [personalFee, setPersonalFee] = useState(0);

  const tableHeaders = [
    'Cliente',
    'PL',
    'Liquidez d-2',
    'Entrada no produto',
    'Valor de alocação',
    'Anotações',
    '',
  ];

  const handleGetAlltags = async () => {
    try {
      const response = await hubApiUser.getAllTags();

      setAllTags(
        response.map((item: string) => {
          return { label: item, value: item };
        })
      );
    } catch (err) {
      toast.dark('Erro ao buscar as tags');
    }
  };

  const getOffer = async () => {
    try {
      const offerDetails: Offer = await hubApiProducts.getOfferById(offerId);

      if (offerDetails.personalFee) {
        const loggedUserHasPersonalFee = offerDetails.personalFee.some(
          item => item.employeeId === user.id
        );

        if (loggedUserHasPersonalFee) {
          setPersonalFee(() => {
            const loggedUserFee = offerDetails.personalFee.filter(
              feeInfos => feeInfos.employeeId === user.id
            )[0].fee;
            return Number(loggedUserFee);
          });
        }
      }

      setOffer(offerDetails);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os dados da oferta, tente novamente em alguns minutos'
      );
    }
  };

  const getOfferTotalNumbers = () => {
    setConfirmedClients(
      clients.filter(client => client.status === 'Confirmado').length
    );

    setAllocatedAmount(
      clients
        .filter(client => client.status === 'Confirmado')
        .map(client => Number(client.allocationAmount))
        .reduce((acc, current) => acc + current, 0)
    );
  };

  const getClientsByOfferId = async () => {
    try {
      const obtainedClients = await hubApiProducts.getClientsByOfferId(offerId);

      setClients(obtainedClients.data);

      obtainedClients.data.sort((item: ClientProps, proxItem: ClientProps) => {
        let result = -1;

        if (Number(item.pl) < Number(proxItem.pl)) {
          result = 1;
        } else if (Number(item.pl) === Number(proxItem.pl)) {
          result = 0;
        }
        return result;
      });

      setFilteredClients([...obtainedClients.data]);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os clientes para esta oferta, tente novamente em alguns minutos'
      );
    }
  };

  const applyFilter = () => {
    if (selectedFilter === 'Todos') {
      setFilteredClients(clients);

      return;
    }

    setFilteredClients(
      clients.filter(client => {
        if (selectedFilter === 'Pendente') {
          return client.status === selectedFilter || !client.status;
        }

        return client.status === selectedFilter;
      })
    );
  };

  const handleChangeFilterValue = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedFilter(event.target.value);
  };

  const filterClientsByTag = () => {
    if (selectedTags.length > 0) {
      const clientsWithSelectedTags = filteredClients.filter(client => {
        const clientsWithTag = client.tags.filter(
          tag =>
            selectedTags.findIndex(itemTag => itemTag.value === tag.tag) !== -1
        );
        if (clientsWithTag.length > 0) {
          return true;
        }
        return false;
      });
      return setFilteredClients(clientsWithSelectedTags);
    }

    setFilteredClients(clients);
    setSelectedFilter('Todos');
  };

  const getExcel = async () => {
    try {
      const data = await hubApiProducts.getClientsByOfferId(offerId, true);

      const url = window.URL.createObjectURL(new Blob([data.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv');
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.dark(
        'Não foi possível extrair a planilha, tente novamente em alguns minutos'
      );
    }
  };

  const handleCloseSection = () => {
    const itemsToBeSaved = document.querySelectorAll('.itemToBeSaved');

    if (itemsToBeSaved.length > 0) {
      setShowAlertToSaveModal(true);

      return;
    }

    history.goBack();
  };

  const handleInactiveCustomers = () => {
    if (isChecked) {
      const filteredInactiveCustomers = filteredClients.filter(
        item => item.pl !== '0'
      );
      return setFilteredClients(filteredInactiveCustomers);
    }
    return setFilteredClients(clients);
  };

  const handleSavePersonalFee = async () => {
    const updatedOffer = {
      offerName: offer.offerName,
      isPublic: offer.isPublic,
      dateLimit: offer.dateLimit,
      tableId: offer.tableId,
      fee: offer.fee,
      personalFee,
    };

    try {
      await hubApiProducts.patchOffer(offer.id, updatedOffer as INewOffer);
      setShowEditFeeModal(false);
      toast.dark('Fee personalizada salva com sucesso!');
    } catch (error) {
      toast.dark(
        'Não foi possível salvar a fee, tente novamente em alguns minutos'
      );
    }
  };

  const handleChangePersonalFee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPersonalFee(Number(e.target.value));
  };

  useEffect(() => {
    getOffer();
    getClientsByOfferId();
    handleGetAlltags();
  }, []);

  useEffect(() => {
    getOfferTotalNumbers();
  }, [clients]);

  useEffect(() => {
    applyFilter();
  }, [selectedFilter]);

  useEffect(() => {
    filterClientsByTag();
  }, [selectedTags]);

  useEffect(() => {
    handleInactiveCustomers();
  }, [isChecked]);

  return (
    <PageWrapper p={5}>
      <Flex flexDir="column" w="100%" maxW="1080px" mx="auto">
        <Flex>
          <Flex
            position="absolute"
            left="9%"
            top="18px"
            cursor="pointer"
            onClick={handleCloseSection}
          >
            <IoChevronBack size={26} color="white" />
          </Flex>
          <MainPageTitle
            textAlign="center"
            mb={0}
            color="background.50"
            fontSize="20"
          >
            Oferta - {offer.offerName}
          </MainPageTitle>
        </Flex>
        <Flex marginTop="4" mb="3" justifyContent="space-between">
          <Flex gridGap={2} w="100%" justify="space-between">
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="flex-start"
              gap={5}
            >
              <Dropdown
                w="150px"
                maxW="150px"
                placeholder={selectedFilter}
                onChange={event => handleChangeFilterValue(event)}
              >
                {filterOptions.map(item => {
                  if (item.value === selectedFilter) {
                    return;
                  }
                  return (
                    <option value={item.value} key={item.value}>
                      {item.value}
                    </option>
                  );
                })}
              </Dropdown>

              <Box zIndex={10} w="150px">
                <MultiSelect
                  className="offers"
                  options={allTags}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  labelledBy="Tags"
                  hasSelectAll={false}
                  disableSearch
                  overrideStrings={{
                    allItemsAreSelected: 'Todas foram selecionadas',
                    selectSomeItems: 'Tags',
                  }}
                />
              </Box>
            </Flex>
            <Flex
              flex={1}
              alignItems="flex-end"
              justifyContent="flex-end"
              gap="1"
            >
              <Checkbox
                mb={1}
                onChange={e => setIsChecked(e.target.checked)}
                isChecked={isChecked}
                size="md"
                mr={0.5}
              />
              <Text onClick={() => setIsChecked(!isChecked)} cursor="pointer">
                Ocultar clientes inativos
              </Text>
              <Tooltip
                hasArrow
                label="Clientes sem PL"
                placement="top"
                color="white"
                bg="background.200"
              >
                <Box mb={2}>
                  <AiOutlineInfoCircle size={14} />
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>

        {filteredClients.length > 0 ? (
          <TableBase
            headData={tableHeaders}
            w="100%"
            maxW={{ base: '1080px', '2xl': '1240px' }}
            maxH="calc(100vh - 320px)"
            overflow="auto"
            p={0}
          >
            {filteredClients.map((client, index) => (
              <ClientRow
                key={`client${index}-${client.clientBTGAccount}`}
                filteredClients={filteredClients}
                setFilteredClients={setFilteredClients}
                client={client}
                offerId={offerId}
                clients={clients}
                setClients={setClients}
              />
            ))}
          </TableBase>
        ) : (
          <Flex w="100%" align="center" justify="center" p="1">
            Sem Registros para esses filtros
          </Flex>
        )}

        <Flex mt={3} mr={1} justifyContent="space-between">
          <Box>
            <Text>
              Clientes entrantes na oferta:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {confirmedClients}
              </Text>
            </Text>

            <Text>
              Patrimônio alocado:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {formatValueForSI(allocatedAmount)}
              </Text>
            </Text>

            <Text>
              Ofertas pendentes:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {clients.filter(client => client.status === 'Pendente').length}
              </Text>
            </Text>

            <Text>
              Pendente:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {formatValueForSI(
                  clients
                    .filter(client => client.status === 'Pendente')
                    .map(client => Number(client.allocationAmount))
                    .reduce((acc, current) => acc + current, 0)
                )}
              </Text>
            </Text>
          </Box>

          <Flex gap={2} mt={4}>
            <Text fontSize="lg">
              Fee ({personalFee > 0 ? personalFee : offer.fee ? offer.fee : 0}
              %):{' '}
              <Text as="span" fontWeight="bold" color="white">
                {formatValue(
                  ((personalFee > 0 ? personalFee : offer.fee) / 100) *
                    allocatedAmount
                )}
              </Text>
            </Text>
            <Box title="Editar fee" mt={2}>
              <RiEdit2Fill
                style={{ cursor: 'pointer' }}
                size={18}
                onClick={() => setShowEditFeeModal(true)}
              />
            </Box>
          </Flex>

          <SecondaryButton onClick={getExcel} w="200px">
            Extrair Planilha
          </SecondaryButton>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showAlertToSaveModal}
        onClose={() => setShowAlertToSaveModal(false)}
      >
        <DefaultModalContentWrapper w="450px">
          <ModalTitle>Salve suas alterações antes de sair</ModalTitle>

          <BaseButtonGroup>
            <SecondaryButton onClick={() => history.goBack()}>
              Sair sem salvar
            </SecondaryButton>
            <PrimaryButton onClick={() => setShowAlertToSaveModal(false)}>
              Fechar
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showEditFeeModal}
        onClose={() => setShowEditFeeModal(false)}
      >
        <DefaultModalContentWrapper w="450px">
          <ModalTitle>Digite sua fee personalizada </ModalTitle>
          <Text as="span" fontSize="xs" color="gray.700" textAlign="end">
            * Fee padrão desta oferta: {offer.fee}%
          </Text>

          <Input
            type="number"
            value={personalFee || ''}
            onChange={handleChangePersonalFee}
          />

          <BaseButtonGroup mt={5}>
            <PrimaryButton onClick={handleSavePersonalFee}>
              Salvar
            </PrimaryButton>
            <SecondaryButton onClick={() => setShowEditFeeModal(false)}>
              Fechar
            </SecondaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </PageWrapper>
  );
};
