import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';
import { errorHandler } from 'utils/errorHandler';

import { InvestmentFundProps } from 'pages/products/interfaces';

export const InvestmentFundsManagement: React.FC = () => {
  const [funds, setFunds] = useState<InvestmentFundProps[]>([]);
  const history = useHistory();

  const handleDeleteFund = useCallback(
    async id => {
      try {
        await hubApiProducts.deleteInvertmentFund(id);
        setFunds(funds.filter(item => item.id !== id));
      } catch (error) {
        toast.dark('Ocorreu um erro ao deletar o fundo.');
      }
    },
    [funds]
  );

  const handleEditFund = useCallback(
    id => {
      history.push(`/products/funds/update/${id}`);
    },
    [history]
  );

  const handleCreatefund = useCallback(() => {
    history.push(`/products/funds/create`);
  }, [history]);

  useEffect(() => {
    hubApiProducts
      .getAllInvertmentFund()
      .then(data => {
        setFunds(data);
      })
      .catch(error => {
        errorHandler(error);
      });
  }, []);
  if (funds.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar Fundos de Investimento"
        buttonText="Criar novo Fundo"
        handleCreateProduct={handleCreatefund}
      >
        <Text color="whiteAlpha.800">
          Não existem Fundos de Investimento cadastrados.
        </Text>
      </ProductManagementLayout>
    );
  }

  return (
    <ProductManagementLayout
      title="Gerenciar Fundos de Investimento"
      buttonText="Criar novo Fundo"
      handleCreateProduct={handleCreatefund}
    >
      {funds?.map((fund, index) => (
        <ProductManagementCard
          key={index}
          productId={fund.id as string}
          title={fund.name}
          handleEditProduct={handleEditFund}
          handleRemoveProduct={handleDeleteFund}
        />
      ))}
    </ProductManagementLayout>
  );
};
