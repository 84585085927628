import { useMutation, UseMutationResult } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?: (error: unknown) => void;
};

export const useDeleteClientReminder = (
  props?: PropsType
): UseMutationResult<unknown, unknown, string, unknown> => {
  return useMutation({
    mutationKey: ['deleteReminder'],
    mutationFn: async (reminderId: string) => {
      const response = await hubApiClients.deleteReminder(reminderId);

      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });
};
