import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { IReduxState } from 'shared/interfaces';
import { ClientType } from 'sharedV2/types/client';
import { DropdownUtils } from 'utilsV2/Dropdown';
import { z } from 'zod';

type PropsType = {
  client: ClientType;
  isOpenAddTagModalFilter: UseSimpleFilterResponseType;
};

const schema = z.object({
  tag: z.string().min(1, { message: 'Escolha uma tag' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const ClientDetailsAddTagModal: React.FC<PropsType> = ({
  client,
  isOpenAddTagModalFilter,
}): JSX.Element => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient({
      onSuccess: async () => {
        isOpenAddTagModalFilter.handleDeleteParam();

        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
        await queryClient.invalidateQueries(['clients', user.id]);
      },
    });

  const { data: tags } = useQuery({
    queryKey: ['employeeTags'],
    queryFn: async () => {
      const response = await hubApiClients.getEmployeeTags();
      return response;
    },
  });

  const handleAddTag: SubmitHandler<FormFieldsType> = async data => {
    await updateClient({
      ...client,
      tags: [...client.tags, data.tag],
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Adicionar tag" />
        <Modal.CloseIcon
          onClick={() => isOpenAddTagModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={1}>
        <Dropdown.Root {...register('tag')}>
          <Dropdown.Options
            placeholder="Tag"
            options={tags?.map(tag =>
              DropdownUtils.toBaseOptions({ label: tag.name, value: tag.name })
            )}
          />
        </Dropdown.Root>
        <Input.ErrorMessage message={errors?.tag?.message} />
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Adicionar"
          isLoading={isLoadingUpdateClient}
          onClick={handleSubmit(handleAddTag)}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
