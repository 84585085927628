import * as Yup from 'yup';

const schema = Yup.object().shape({
  ticker: Yup.string().required('O ticker não pode ser vazio'),
  allocation: Yup.string().required('A alocação não pode ser vazia'),
  segment: Yup.string().required('O segmento não pode ser vazio'),
  informations: Yup.string().required(
    'O campo de informações não pode ser vazio'
  ),
  dailyLiquidity: Yup.string().required(
    'O campo de Liquidez Diária não pode ser vazio'
  ),
  pvp: Yup.string().required('O campo de PVP não pode ser vazio'),
  netPL: Yup.string().required(
    'O campo de PL Liquido informações não pode ser vazio'
  ),
  dividendYield: Yup.string().required(
    'O campo de DIVIDEND YIELD não pode ser vazio'
  ),
});

export default schema;
