import { Flex, Spinner } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import {
  TopSectionGraphic,
  TopSectionMonthSummary,
} from 'pages/DashboardTablesDesktop/components';
import {
  MonthNumbers,
  Employee,
} from 'pages/DashboardTablesDesktop/interfaces';

interface TopSectionProps {
  monthSummaryData: {
    monthsNumbers: MonthNumbers[];
    employeesNumber: Employee[];
  };
  employeesColors: {
    employeeName: string;
    color: string;
  }[];
  graphicData: any[];
  isLoading: boolean;
}

export const TopSection: React.FC<TopSectionProps> = ({
  monthSummaryData,
  employeesColors,
  graphicData,
  isLoading,
}) => {
  return (
    <Flex w="100%" h="33%" gridGap={6}>
      <Flex w="75%" bg="background.700" borderRadius={8} p={3}>
        {isLoading && (
          <Flex h="100%" align={'center'} justify="center" w="100%">
            <Spinner size="lg" speed="0.6s" color="primary.500" />
          </Flex>
        )}
        {!isLoading && (
          <TopSectionGraphic
            data={graphicData}
            employeesColors={employeesColors}
          />
        )}
      </Flex>

      <TopSectionMonthSummary monthSummaryData={monthSummaryData} />
    </Flex>
  );
};
