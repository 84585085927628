import React, { useState } from 'react';
import { isCPF } from 'brazilian-values';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { TableData, TableRow } from 'components/elements/table';
import { Input } from 'components/elements/forms/Input';
import { CheckIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import { SendButton } from '../SendButton';
import { ITableContent } from '../../interfaces/ITableContent';

const LeadsTableRow: React.FC<ITableContent> = ({ rowData, getLeads }) => {
  const [leadCPF, setLeadCPF] = useState(
    rowData.document !== null ? rowData.document : ''
  );
  const [isCPFInvalid, setIsCPFInvalid] = useState(false);
  const [isCPFSucceeded, setIsCPFSucceeded] = useState(false);
  const [isCPFSent, setIsCPFSent] = useState(false);
  const [isCPFAlreadyExists, setIsCPFAlreadyExists] = useState(false);
  const [isLeadInvalid, setIsLeadInvalid] = useState(false);

  let button:
    | React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >
    | undefined;
  let statusIcon;
  let type;
  let leadIdentification;

  if (isCPFSucceeded) {
    statusIcon = (
      <Tooltip label="CPF incluído com sucesso">
        <CheckIcon color="green" />
      </Tooltip>
    );
  } else if (isCPFInvalid || rowData.status === 'erro') {
    statusIcon = (
      <Tooltip label="CPF inválido">
        <WarningTwoIcon color="#d11306" />
      </Tooltip>
    );
  } else if (isCPFAlreadyExists) {
    statusIcon = (
      <Tooltip label="Já existe um lead com esse CPF">
        <WarningIcon color="#f4b740" />
      </Tooltip>
    );
  } else if (isLeadInvalid === true) {
    statusIcon = <Box> Lead inválido</Box>;
  } else if (leadCPF.length < 11 || leadCPF.length > 11) {
    statusIcon = <Box>Não encontrei</Box>;
  }

  const dataValidation = (key: 'cars' | 'mails', field: string): boolean => {
    return (
      rowData[key] !== null &&
      rowData[key].length !== 0 &&
      JSON.parse(rowData[key][0])[field] !== ''
    );
  };

  const handleInvalidateLead = async () => {
    await hubApiCaptation
      .invalidateLead(rowData.id)
      .then(() => {
        toast.dark('Lead invalidado.');
        setIsLeadInvalid(true);
        getLeads();
      })
      .catch(() => toast.dark('Erro ao invalidar lead.'));
  };

  if (dataValidation('cars', 'placa')) {
    type = 'Placa';
    leadIdentification = JSON.parse(rowData.cars[0]).placa;
  } else if (dataValidation('mails', 'email')) {
    type = 'E-mail';
    leadIdentification = JSON.parse(rowData.mails[0]).email;
  } else {
    type = 'Nome';
    leadIdentification = rowData.name;
  }

  const handleCPFValidation = async (): Promise<void> => {
    setIsCPFSent(true);
    if (isCPF(leadCPF)) {
      await hubApiCaptation
        .sendCPFLeads(leadCPF, rowData.id)
        .then(() => {
          toast.dark('CPF incluído com sucesso.');
          setIsCPFSucceeded(true);
          getLeads();
        })
        .catch(error => {
          if (error.response?.data.message === 'Internal server error') {
            toast.dark('Erro 500, avisar o TI');
          } else {
            toast.dark(error.response?.data.message);
            setIsCPFSent(true);
            setIsCPFAlreadyExists(true);
          }
        });
    } else {
      toast.dark('CPF inválido.');
      setIsCPFInvalid(true);
    }
  };

  if (leadCPF !== '' && isCPFInvalid === true) {
    button = (
      <SendButton disabled background="#d11306">
        CPF Inválido
      </SendButton>
    );
  } else if (leadCPF === '111') {
    button = (
      <SendButton
        background={isLeadInvalid === true ? '#920d04' : '#bc1105'}
        onClick={handleInvalidateLead}
        disabled={isLeadInvalid}
      >
        {isLeadInvalid === true ? 'Lead invalidado' : 'Invalidar lead'}
      </SendButton>
    );
  } else if (leadCPF.length < 11 || leadCPF.length > 11) {
    button = (
      <SendButton disabled background="#808080">
        Aguardando CPF
      </SendButton>
    );
  } else if (
    leadCPF.length === 11 &&
    isCPFSent === false &&
    rowData.status !== 'api'
  ) {
    button = (
      <SendButton
        onClick={() => handleCPFValidation()}
        disabled={leadCPF.length !== 11}
        background="#35d83b"
        cursor="pointer"
      >
        Enviar
      </SendButton>
    );
  } else if (
    (isCPFSent === true && isCPFInvalid === false) ||
    rowData.status === 'api'
  ) {
    button = (
      <SendButton disabled background="#949494">
        Enviado
      </SendButton>
    );
  } else if (rowData.status === 'erro') {
    button = (
      <SendButton disabled background="#949494">
        Não encontrado
      </SendButton>
    );
  }

  const handleInput = (cpf: any): void => {
    setIsCPFInvalid(false);
    setLeadCPF(cpf);
    setIsCPFSent(false);
    setIsCPFAlreadyExists(false);
  };

  return (
    <TableRow>
      <TableData>{type}</TableData>
      <TableData>{leadIdentification}</TableData>
      <TableData>
        <Input
          isDisabled={
            isCPFSucceeded || rowData.status === 'api' || isCPFAlreadyExists
          }
          placeholder="CPF sem pontos/ hífen"
          onChange={e => handleInput(e.target.value)}
          value={leadCPF}
          type="number"
        />
      </TableData>
      <TableData>{button}</TableData>
      <TableData>{statusIcon}</TableData>
    </TableRow>
  );
};

export default LeadsTableRow;
