import styled from 'styled-components';

export const OverflowDiv = styled.div`
  width: 100%;
  max-height: 360px;

  overflow-y: auto;
  margin-bottom: ${({ fullPage }) => (fullPage ? '0' : '24px')};
  padding-right: 6px;

  @media screen and (min-height: 660px) {
    max-width: ${({ fullPage }) => (fullPage ? '560px' : '420px')};
    max-height: ${({ fullPage }) => (fullPage ? '400px' : '380px')};
  }
`;

export const SectionTitleDivider = styled.h4`
  color: var(--white-color-200);
  font-size: var(--normal-text);
  border-bottom: 1px solid var(--gray-color-600);
  padding-bottom: 2px;
  margin: 12px 0 8px;
`;
