import { Box, Link } from '@chakra-ui/react';
import { IEventExtended } from 'pages/Events/interfaces/Event';

interface BtnLinkProps {
  event: IEventExtended;
  isEventExpired: (date: string) => boolean;
}

export const BtnLink: React.FC<BtnLinkProps> = ({ event, isEventExpired }) => {
  return (
    <Link href={event.link} isExternal justifyContent="center" width="80px">
      <Box
        margin="auto"
        width="80%"
        py={0.5}
        background="#1d63ddb2"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        color="white"
        fontSize="xs"
      >
        Acessar evento
      </Box>
    </Link>
  );
};
