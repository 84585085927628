import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IBoxInfoProps {
  textInfo: string;
}

export const BoxInfo: React.FC<IBoxInfoProps> = ({ textInfo }) => {
  return (
    <Flex
      width="90vw"
      height={'50vh'}
      alignItems={'center'}
      justifyContent="center"
    >
      <Text fontSize={'md'} fontStyle={'italic'}>
        {textInfo}
      </Text>
    </Flex>
  );
};
