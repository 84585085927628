import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
} from './actionTypes';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  loginDate: null,
};

function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        signed: true,
        loading: false,
        loginDate: action.payload.signDate,
      };

    case SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SIGN_OUT:
      return {
        ...state,
        token: null,
        signed: false,
        loading: false,
        loginDate: null,
      };

    default:
      return state;
  }
}

export default authReducer;
