import { ListItem, UnorderedList } from '@chakra-ui/react';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { PrimaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';

interface LeadsUploadModalProps {
  closeModal: () => void;
}

export const LeadsUploadModal: React.FC<LeadsUploadModalProps> = ({
  closeModal,
}) => {
  return (
    <DefaultModalContentWrapper>
      <ModalTitle textAlign="center">
        Instruções para o envio de leads
      </ModalTitle>

      <UnorderedList spacing={3}>
        <ListItem>O arquivo deve ter a extensão .xlsx (Excel)</ListItem>
        <ListItem>Envie no máximo 500 leads por arquivo</ListItem>
        <ListItem>
          As cinco primeiras colunas (Nome, Email, Telefone) são colunas padrão
          e NÃO podem ter seus títulos alterados, caso não deseje inserir algum
          dado não tem problema
        </ListItem>
        <ListItem>
          Você pode adicionar mais colunas depois das cinco colunas padrão
        </ListItem>
        <ListItem>
          É obrigatório ter pelo menos um telefone por lead. Os leads que não
          tiverem um telefone não será importado e dará um feedback de{' '}
          {`"Sem
          informação"`}
        </ListItem>
      </UnorderedList>

      <PrimaryButton w="20%" mt={8} ml="auto" onClick={() => closeModal()}>
        OK
      </PrimaryButton>
    </DefaultModalContentWrapper>
  );
};
