import React, { useState } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';

import { useHomePageContext } from 'hooks';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import formatValue from 'utils/formatValue';
import { ResponsiveLinePlHome } from '../ResponsiveLinePlHome';
import { CardShowUserNumbers } from '../../elements/cards';
import { BellCaptationModal } from '../BellCaptationModal';

export const CollaboratorNumbers: React.FC = () => {
  const [toggleModal, setToggleModal] = useState(false);

  const { employeeStats, employeeGraph } = useHomePageContext();

  const handleToggleModal = () => setToggleModal(!toggleModal);

  // as verificações do valor do TXR atuam como um indicador de que os dados estão quebrados
  return (
    <Flex
      width="100%"
      flexDir="column"
      justifyContent="space-between"
      gridGap="1%"
      h="49%"
    >
      <Flex gridGap="2" h={['15%', '15%', '12%', '15%']}>
        <CardShowUserNumbers
          label="Seu Net Hoje:"
          alignItems="flex-start"
          value={
            Number(employeeStats.txr) <= 0
              ? '-'
              : formatValue(employeeStats.sumpl) || formatValue(0)
          }
          isMoney={Number(employeeStats.txr) <= 0 && false}
        />
        <DefaultCardWrapper
          py="2"
          px="6"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={handleToggleModal}
          _hover={{ background: 'background.500' }}
          transition="background 0.2s ease-in"
        >
          <FiBell size={30} color="white" />
        </DefaultCardWrapper>
      </Flex>

      {employeeGraph.length > 0 && Number(employeeStats.txr) > 0 && (
        <Flex w="100%" maxW={['100%', '100%', '644px']} h="175px">
          <ResponsiveLinePlHome date={employeeGraph} />
        </Flex>
      )}

      {employeeGraph.length < 0 ||
        (Number(employeeStats.txr) <= 0 && (
          <Flex
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            gridGap={2}
          >
            <RiErrorWarningLine size={32} />
            <Text
              fontStyle="italic"
              fontSize="sm"
              color="gray.400"
              fontWeight="light"
              w="65%"
              textAlign="center"
            >
              Estamos com inconsistências geradas pelos relatórios do BTG, em
              breve as informações estarão disponíveis
            </Text>
          </Flex>
        ))}

      <Flex gridGap="2" h="15%" my={[3, 0]}>
        <CardShowUserNumbers
          label="NPS:"
          value={
            Number(employeeStats.txr) <= 0
              ? '-'
              : Number(employeeStats.nps).toFixed(2) || 0
          }
          width={['100%', '100px', '100px', '100%']}
        />
        <Flex
          width="100%"
          py="0.5"
          px="3"
          borderRadius="md"
          background="background.600"
          position="relative"
        >
          <CardShowUserNumbers
            label="TXR:"
            value={
              Number(employeeStats.txr) <= 0
                ? '-'
                : Number(employeeStats.txr).toFixed(2) || 0
            }
            isPercentage
          />
          <Tooltip
            hasArrow
            label="A TXR é o indicador que mostra a retenção dos clientes dentro da base da EWZ. Por exemplo, se um AAI capta 100MM de carteira e já sofreu 10MM de ted-out, seu TXR será de 90%."
            placement="top"
            color="white"
            bg="background.200"
          >
            <Box position="absolute" right={2} top="0.5">
              <AiOutlineInfoCircle color="white" />
            </Box>
          </Tooltip>
        </Flex>
        <CardShowUserNumbers
          label="Nº de clientes:"
          value={employeeStats.clients || 0}
        />
      </Flex>

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <BellCaptationModal handleToggleModal={handleToggleModal} />
      </ModalWrapper>
    </Flex>
  );
};
