import { Flex, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import * as S from './styles';

type RightElementPropsType = {
  icon: React.FC;
  onClick: () => void;
};

export type InputTextFieldPropsType = {
  rightElement?: RightElementPropsType;
} & InputProps;

export const InputTextField = forwardRef<
  HTMLInputElement,
  InputTextFieldPropsType
>((props, ref) => {
  return (
    <Flex
      style={{
        width: '100%',
        background: '#fff',
        borderRadius: '6px',
        border: '1px solid #1d63ddb2',
        alignItems: 'center',
        height: '100%',
        color: 'black',
      }}
    >
      <S.InputTextFieldStyledComponents {...props} ref={ref} />
      {props.rightElement && (
        <Flex
          marginRight="5px"
          cursor="pointer"
          alignItems="center"
          onClick={props.rightElement.onClick}
        >
          <props.rightElement.icon />
        </Flex>
      )}
    </Flex>
  );
});
