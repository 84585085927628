import React, { useCallback, useEffect, useState } from 'react';
import { useDisclosure, Flex } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { formateDateAndTime } from 'utils/formatDate';
import { MainPageTitle } from 'components/elements/texts';
import { BackPageButton } from 'components/elements/buttons';
import { hubApiTech } from 'services/hubApi/classes/HubApiTi';
import OrderInfo from 'pages/Technology/components/modules/technologyTransferComponent/OrderInfo';
import { InformationTiOrders } from 'pages/Technology/components/modules/InformationTiOrders';
import { ICountTiInfos } from 'pages/Technology/interfaces/TechnologyOrderProps';
import { TransferOrderCard } from 'components/elements/cards/transferOrder';
import { TechnologyOrderProps } from 'pages/Technology/interfaces';
import {
  ModalWrapper,
  TransferOrdersCardsWrapper,
  PageWrapper,
} from 'components/elements/wrappers';

const TechnologyTransfer: React.FC = () => {
  const [infosTech, setInfosTech] = useState<TechnologyOrderProps[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<TechnologyOrderProps>(
    {} as TechnologyOrderProps
  );
  const [tiInfos, setTiInfos] = useState<ICountTiInfos[]>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const getHours = (days: number, hour: number) => {
    return String(days * 24 + hour);
  };

  useEffect(() => {
    listAllOrders();
  }, []);

  async function listAllOrders() {
    await hubApiTech
      .listOrdersNotTransferred()
      .then(response => {
        setTiInfos(response.count);
        setInfosTech(response.orders);
      })
      .catch(error => {
        errorHandler(error);
      });
  }

  const handleShowModal = (orderClicked: TechnologyOrderProps) => {
    setSelectedOrder(orderClicked);
  };

  const handleRemoveOrder = useCallback(
    id => {
      setInfosTech(infosTech.filter(item => item.id !== id));
    },
    [infosTech]
  );

  return (
    <PageWrapper
      direction="column"
      alignItems="center"
      pos="relative"
      h="100%"
      pt="5"
    >
      <BackPageButton />

      <MainPageTitle textAlign="center" p={['24px', '15px']} mb="0">
        Transferência de ordens - T.I
      </MainPageTitle>

      <Flex
        w="100%"
        maxW="1080px"
        mb="2"
        justify={['flex-start', 'center']}
        align="center"
        fontSize="sm"
        flexDir={['column', 'column', 'row']}
        gridGap="5"
        height={['20vh', 'auto']}
        overflowY={['auto', 'unset']}
      >
        {tiInfos.map((item, key) => (
          <Flex
            w="100%"
            maxW="860px"
            justify="space-between"
            gridGap="2"
            mb="3"
            flexDir={['column', 'column', 'row']}
            key={key}
          >
            <InformationTiOrders
              title="SLA"
              subTitle="horas"
              value={getHours(item.sla.days, item.sla.hours) || '0'}
            />
            <InformationTiOrders
              title="TMR"
              subTitle="horas"
              value={getHours(item.tmr.days, item.tmr.hours) || '0'}
            />
            <InformationTiOrders
              title="Transferências"
              subTitle="ordens"
              value={item.transfer || '0'}
            />
            <InformationTiOrders
              title="CRM"
              subTitle="ordens"
              value={item.crm || '0'}
            />
            <InformationTiOrders
              title="Resolvidas"
              subTitle="ordens"
              value={item.resolved || '0'}
            />
            <InformationTiOrders
              title="Total"
              subTitle="ordens"
              value={item.all || '0'}
            />
          </Flex>
        ))}
      </Flex>

      <TransferOrdersCardsWrapper>
        {infosTech.map((item, index) => (
          <TransferOrderCard
            key={index}
            mainText={
              item.type === 'Demissão' ? 'Encerramento de contrato' : item.type
            }
            secondaryText={item.assessorName}
            thirdText={formateDateAndTime(item.created_at)}
            onClick={() => {
              handleShowModal(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo
          order={selectedOrder}
          handleToggleOrderModal={onClose}
          handleRemoveOrder={handleRemoveOrder}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default TechnologyTransfer;
