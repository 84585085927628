import React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { formatToCNPJ } from 'brazilian-values';
import formatValue from 'utils/formatValue';

import { InvestmentFundProps } from 'pages/products/interfaces';

import { CardInvestmentFundListBase } from 'pages/products/components/elements/cards';
import { DisplayInformation } from 'components/elements/others';
import { SecondaryButton } from 'components/elements/buttons';
import { CardInfo } from 'components/elements/others/CardInfo';

interface FundsCardProps {
  fundsData: InvestmentFundProps;
}

export const FundsCard: React.FC<FundsCardProps> = ({ fundsData }) => {
  function apenasNumeros(cnpj: string) {
    const numsStr = cnpj.replace(/[^0-9]/g, '');
    return String(numsStr);
  }

  return (
    <CardInvestmentFundListBase title={fundsData.name} risck={fundsData.risck}>
      <Flex w="100%" gridGap="20px" flexDirection={['column', 'row']}>
        <Stack
          spacing="4"
          w={['100%', '50%']}
          height={['250px', 'auto']}
          overflowY={['auto', 'unset']}
        >
          <CardInfo title="Nome Técnico" info={fundsData.nameTec} />
          <CardInfo
            title="Aplicação mínima"
            info={formatValue(fundsData.aplication)}
          />
          <CardInfo title="Liquidação" info={fundsData.liquidation} />
          <CardInfo title="Cotização" info={fundsData.quotation} />

          <CardInfo title="CNPJ" info={formatToCNPJ(fundsData.cnpj)} />
          <Stack flexDir="row" spacing="0" gridGap="12px">
            <CardInfo
              title="Taxa de administração"
              info={`${Number(fundsData.taxaAdm).toFixed(2)} %`}
            />
            <CardInfo
              title="Taxa perfomance"
              info={`${Number(fundsData.taxaActing).toFixed(2)} %`}
            />
          </Stack>
        </Stack>

        <Stack spacing="4" w={['100%', '50%']}>
          <Flex flexDir="column">
            <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
              Rentabilidade:
            </Text>

            <Flex justifyContent="space-around">
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="medium"
                fontSize="xs"
                px="2"
              >
                Mês
              </Text>
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="medium"
                fontSize="xs"
                px="2"
              >
                Ano
              </Text>
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="medium"
                fontSize="xs"
                px="2"
              >
                12 Meses
              </Text>
            </Flex>

            <Flex
              justifyContent="space-around"
              bg="white"
              p="8px"
              borderRadius="8px"
              mt="6px"
            >
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="bold"
                fontSize="md"
                px="2"
              >
                {`${Number(fundsData.profitability.month).toFixed(2)} %`}
              </Text>
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="bold"
                fontSize="md"
                px="2"
              >
                {`${Number(fundsData.profitability.yaer).toFixed(2)} %`}
              </Text>
              <Text
                color="rgba(70, 70, 70, 1)"
                fontWeight="bold"
                fontSize="md"
                px="2"
              >
                {`${Number(fundsData.profitability.twelveMonth).toFixed(2)} %`}
              </Text>
            </Flex>
          </Flex>
          {fundsData.type === 'Previdência' ? (
            <Flex width="100%" height="100%" flexDirection="column">
              <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
                Estratégia{' '}
              </Text>
              <Flex
                width="100%"
                background="white"
                borderRadius="md"
                height="275px"
                p={2}
                fontSize={['sm', 'md']}
                overflowY="auto"
              >
                {fundsData.strategy}
              </Flex>
            </Flex>
          ) : (
            <Flex flexDir="column" justify="space-between" h="100%">
              <Flex width="100%" height="100%" flexDirection="column">
                <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
                  Estratégia{' '}
                </Text>
                <Flex
                  width="100%"
                  background="white"
                  borderRadius="md"
                  height="200px"
                  p={2}
                  overflowY="auto"
                  fontSize={['sm', 'md']}
                >
                  <Text width="100%" height="100%">
                    {fundsData.strategy}
                  </Text>
                </Flex>
              </Flex>

              <Flex justify="flex-end" align="flex-end" mt={['20px', '0px']}>
                <a
                  href={`https://www.mundoinvest.com.br/ferramentas/comparador-de-fundos/especifico/${apenasNumeros(
                    fundsData.cnpj
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SecondaryButton>Ver no Mundo Invest</SecondaryButton>
                </a>
              </Flex>
            </Flex>
          )}
        </Stack>
      </Flex>
    </CardInvestmentFundListBase>
  );
};
