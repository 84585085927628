/* eslint no-unused-vars: off */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { MarketingOrderProps } from 'pages/Marketing/interfaces';
import { DisplayInformation } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import {
  DeleteTransferOrderCard,
  TransferOrderInfosCard,
} from 'components/elements/cards/transferOrder';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ComponentProps {
  order: MarketingOrderProps;
  handleToggleOrderModal: () => void;
  handleRemoveOrder: (id: string) => void;
}

interface CollaboratorProps {
  id: string;
  name: string;
}

export const OrderInfo: React.FC<ComponentProps> = ({
  handleToggleOrderModal,
  order,
  handleRemoveOrder,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [employeeIdSelected, setEmployeeIdSelected] = useState<string>('');
  const [toggleCancelOrderModal, setToggleCancelOrderModal] =
    useState<boolean>(false);
  const [usersWithIpPermissions, setUsersWithIpPermissions] = useState<
    CollaboratorProps[]
  >([]);

  useEffect(() => {
    async function listOrders() {
      await hubApiMarketing
        .listUsers()
        .then(response => setUsersWithIpPermissions(response))
        .catch(error => {
          if (error.response) {
            if (error.response?.data.message !== 'Internal server error') {
              toast.dark(error.response?.data.message);
            }
          }
        });
    }

    listOrders();
  }, []);

  const handleToggleCancelOrderModal = () =>
    setToggleCancelOrderModal(!toggleCancelOrderModal);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!employeeIdSelected) {
      setIsLoading(false);
      return toast.dark('Selecione um colaborador para transferir essa ordem');
    }

    const transferData = {
      employeeId: employeeIdSelected,
      orderId: order?.id,
    };

    try {
      await hubApiMarketing.postUsers(transferData);
      handleRemoveOrder(order.id);
      handleToggleOrderModal();
      toast.dark('Ordem transferida com sucesso');
    } catch (e) {
      toast.dark('Erro ao tentar transferir ordem');
    }

    setIsLoading(false);
  };

  const handleChangeUser = useCallback((collaboratorId: string) => {
    setEmployeeIdSelected(collaboratorId);
  }, []);

  const handleDeleteOrder = useCallback(async () => {
    handleToggleOrderModal();

    const data = {
      description,
    };

    await hubApiMarketing
      .deleteOrder(order?.id, data)
      .then(() => {
        handleToggleOrderModal();
        handleRemoveOrder(order.id);
        toast.dark('Ordem deletada com sucesso.');
      })
      .catch(error => {
        if (error.response) {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        }
      });
  }, [handleToggleOrderModal, order?.id, handleRemoveOrder, description]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setDescription(e.target.value);
    },
    []
  );

  if (!order) {
    return <></>;
  }

  return (
    <TransferOrderInfosCard
      title="Dados da ordem"
      isLoading={isLoading}
      collaborators={usersWithIpPermissions}
      handleSelectCollaborator={handleChangeUser}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleOrderModal}
      handleToggleCancelOrderModal={handleToggleCancelOrderModal}
    >
      <Stack spacing="3">
        <DisplayInformation label="Tipo da ordem" info={order.type} />
        <DisplayInformation
          label="Nome do solicitante"
          info={formatNameToCaptalize(order.name)}
        />
        {order.phone && (
          <DisplayInformation label="Telefone" info={order.phone} />
        )}
        <DisplayInformation label="E-mail" info={order.email} />

        {order.position && (
          <DisplayInformation label="Posição na EWZ" info={order.position} />
        )}
        {order.project && (
          <DisplayInformation label="Projeto" info={order.project} />
        )}
        {order.goals && (
          <DisplayInformation label="Objetivo" info={order.goals} />
        )}
        {order.title && (
          <DisplayInformation label="Título" info={order.title} />
        )}
        {order.cardType && (
          <DisplayInformation label="Tipo de cartão" info={order.cardType} />
        )}
        {order.text && <DisplayInformation label="Texto" info={order.text} />}
        {order.informations && (
          <DisplayInformation label="Informações" info={order.informations} />
        )}
        {order.publication && (
          <DisplayInformation label="Divulgação" info={order.publication} />
        )}
        {order.formatting && (
          <DisplayInformation label="Formatação" info={order.formatting} />
        )}
      </Stack>

      <ModalWrapper
        isOpen={toggleCancelOrderModal}
        onClose={handleToggleCancelOrderModal}
      >
        <DeleteTransferOrderCard
          description={description}
          handleDeleteOrder={handleDeleteOrder}
          handleChangeValue={handleChange}
          handleToggleCancelOrderModal={handleToggleCancelOrderModal}
        />
      </ModalWrapper>
    </TransferOrderInfosCard>
  );
};
