import { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { Input } from 'components/elements/forms';
import { Content } from 'pages/Contents/interfaces';
import {
  ContentTypeButton,
  AreasAndThemesDropdown,
  DeleteModal,
  PermissionsModal,
} from 'pages/Contents/components';
import { LoadingSpinner } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';

interface TextEditorProps {
  previousContent?: Content;
  getContentToEdit?: () => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  previousContent,
  getContentToEdit,
}) => {
  const userId = useSelector((state: IReduxState) => state.user.profile.id);
  const userPosition = useSelector(
    (state: IReduxState) => state.user.profile.position
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState('');
  const [selectedTheme, setSelectedTheme] = useState('');
  const [contentType, setContentType] = useState('text');
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [contentSubtitle, setContentSubtitle] = useState('');
  const [videoURL, setVideoURL] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link'],
      ['clean'],
    ],
  };

  const verifyEditMode = () => {
    if (previousContent) {
      setEditMode(true);
      setContentTitle(previousContent.title);
      setContentSubtitle(previousContent.subtitle);
      setContent(previousContent.content);
      setSelectedSegment(previousContent?.area);
      setSelectedTheme(previousContent?.theme);
    }

    if (previousContent && previousContent.video) {
      setContentType('video');
      setVideoURL(previousContent.video);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleClosePermissionsModal = () => {
    setShowPermissionsModal(false);
  };

  const redirectToMyContents = () => {
    history.push(`/contents/my/${userId}`);
  };

  const verifyContentFields = () => {
    if (contentTitle === '' || contentSubtitle === '') {
      toast.dark('Defina um título e um subtítulo para o conteúdo');
      setLoading(false);

      return;
    }

    if (content === '' && contentType === 'text') {
      toast.dark('Defina um texto para o conteúdo');
      setLoading(false);

      return;
    }

    if (selectedTheme === '' || selectedSegment === '') {
      toast.dark('Defina uma área e um tema para o conteúdo');
      setLoading(false);

      return;
    }

    if (
      contentType === 'video' &&
      !videoURL?.includes('youtube') &&
      !videoURL?.includes('vimeo')
    ) {
      toast.dark(
        'URL inválida, selecione vídeos da plataforma YouTube ou Vimeo'
      );
      setLoading(false);

      return;
    }

    if (!previousContent?.permission) {
      setShowPermissionsModal(true);

      return;
    }

    handleSaveContent(previousContent?.permission);
  };

  const handleSaveContent = async (permissions: any) => {
    setLoading(true);

    if (contentTitle === '' || contentSubtitle === '') {
      toast.dark('Defina um título e um subtítulo para o conteúdo');
      setLoading(false);

      return;
    }

    if (content === '' && contentType === 'text') {
      toast.dark('Defina um texto para o conteúdo');
      setLoading(false);

      return;
    }

    if (selectedTheme === '' || selectedSegment === '') {
      toast.dark('Defina uma área e um tema para o conteúdo');
      setLoading(false);

      return;
    }

    if (
      contentType === 'video' &&
      !videoURL?.includes('youtube') &&
      !videoURL?.includes('vimeo')
    ) {
      toast.dark(
        'URL inválida, selecione vídeos da plataforma YouTube ou Vimeo'
      );
      setLoading(false);

      return;
    }

    let postData: any = {
      title: contentTitle,
      subtitle: contentSubtitle,
      area: selectedSegment,
      theme: selectedTheme,
      content: content || ' ',
      permission: permissions,
    };

    let editData: any = {
      id: previousContent?.id,
      active: true, // propriedade para funcionalidade futura de aprovação do conteúdo pelo setor de mkt
      title: contentTitle,
      subtitle: contentSubtitle,
      area: selectedSegment,
      theme: selectedTheme,
      content: content || ' ',
      permission: permissions,
    };

    if (videoURL) {
      postData = {
        ...postData,
        video: videoURL,
      };
      editData = {
        ...editData,
        video: videoURL,
      };
    }

    try {
      if (editMode) {
        await hubApiUser.editContent(editData);
        setLoading(false);
        toast.dark('Conteúdo atualizado com sucesso!');
        redirectToMyContents();
      } else {
        await hubApiUser.postNewContent(postData);
        setLoading(false);
        toast.dark('Conteúdo enviado com sucesso!');
        redirectToMyContents();
      }
    } catch {
      setLoading(false);
      toast.dark(
        'Não foi possível enviar seu conteúdo, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    verifyEditMode();
  }, [previousContent]);

  return (
    <Flex mx="auto" flexDirection="column" justifyContent="space-between">
      <Flex mb={8}>
        <AreasAndThemesDropdown
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          previousContent={previousContent}
          setSelectedTheme={setSelectedTheme}
          editionMode
        />
      </Flex>

      <Flex
        justifyContent="center"
        position="relative"
        flexDirection={['column', 'row']}
      >
        <ContentTypeButton
          contentType={contentType}
          setContentType={setContentType}
          editionMode
        />
        {previousContent && (
          <Text
            as="button"
            _hover={{ textDecoration: 'underline' }}
            fontSize="sm"
            ml="auto"
            color="primary.100"
            cursor="pointer"
            position="absolute"
            right="0"
            onClick={() => setShowPermissionsModal(true)}
          >
            Gerenciar permissões
          </Text>
        )}
      </Flex>

      {contentType === 'video' && (
        <>
          <Text>
            URL
            <Text as="span" ml={2} fontSize="xs">
              (YouTube ou Vimeo)
            </Text>
          </Text>
          <Input
            style={{ borderRadius: '0' }}
            onChange={e => setVideoURL(e.target.value)}
            mb={1}
            isRequired
            value={videoURL || ''}
          />
        </>
      )}

      <Text>Título</Text>
      <Input
        style={{ borderRadius: '0' }}
        onChange={e => setContentTitle(e.target.value)}
        value={contentTitle}
        isRequired
        maxLength={95}
      />

      <Text mt={1}>Subtítulo</Text>
      <Input
        style={{ borderRadius: '0' }}
        onChange={e => setContentSubtitle(e.target.value)}
        value={contentSubtitle}
        isRequired
        maxLength={176}
      />

      <ReactQuill
        modules={modules}
        theme="snow"
        placeholder=""
        onChange={setContent}
        value={content || ''}
      />

      <Flex justifyContent="flex-end" gridGap={1} mt={1}>
        {userPosition === 'MASTER' && previousContent && (
          <SecondaryButton
            background="red.400"
            opacity={0.4}
            _hover={{ opacity: '0.6' }}
            onClick={() => setShowDeleteModal(true)}
          >
            Excluir conteúdo
          </SecondaryButton>
        )}
        <PrimaryButton
          w="fit-content"
          minW="140.8px"
          onClick={() => verifyContentFields()}
        >
          {loading && <LoadingSpinner size="sm" />}

          {!loading && 'Salvar conteúdo'}
        </PrimaryButton>
      </Flex>

      <ModalWrapper
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <DeleteModal
          closeModal={handleCloseDeleteModal}
          contentId={previousContent?.id || ''}
          redirectAfterDeletion={redirectToMyContents}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showPermissionsModal}
        onClose={() => setShowPermissionsModal(false)}
      >
        <PermissionsModal
          closeModal={handleClosePermissionsModal}
          saveContent={handleSaveContent}
          previousContent={previousContent}
          getContentToEdit={getContentToEdit}
        />
      </ModalWrapper>
    </Flex>
  );
};
