import { useEffect, useState } from 'react';
import { BiCommentDetail } from 'react-icons/bi';
import { RiEdit2Fill } from 'react-icons/ri';
import { FcOk } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { formatValueForSI } from 'utils/formatValueForSI';
import { TableData, TableRow } from 'components/elements/table';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { AnnotationModal, StatusModal } from 'pages/Offers/components';
import { Client } from 'pages/Offers/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';

import { formatValueWithReactCurrency } from 'utils/formatValueWithReactCurrency';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatToPhone } from 'brazilian-values';

type ClientProps = Omit<
  Client,
  'id' | 'offerId' | 'managerBTGAccount' | 'created_at'
>;

interface ClientRowProps {
  client: ClientProps;
  offerId: string;
  setClients: React.Dispatch<React.SetStateAction<ClientProps[]>>;
  clients: ClientProps[];
  filteredClients: ClientProps[];
  setFilteredClients: React.Dispatch<React.SetStateAction<ClientProps[]>>;
}

type StatusType = 'Confirmado' | 'Cancelado' | 'Pendente';

export const ClientRow: React.FC<ClientRowProps> = ({
  client,
  offerId,
  filteredClients,
  setFilteredClients,
  clients: oldClients,
  setClients,
}) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [clientStatus, setClientStatus] = useState<StatusType>(client.status);
  const [clientAnnotation, setClientAnnotation] = useState(
    client.annotation || ''
  );
  const [isSaved, setIsSaved] = useState(true);
  const [allocationValue, setAllocationValue] = useState(
    client.allocationAmount || '0'
  );

  const formattedPhone = `+55 ${
    client.phone && formatToPhone(client.phone.substring(0))
  }`;

  const saveAllChangesInARow = async () => {
    if (clientStatus === 'Confirmado' && Number(allocationValue) < 1) {
      return toast.dark(
        'Defina um valor de alocação para o cliente confirmado'
      );
    }

    const clientInfosToSave = {
      allocationAmount: Number(allocationValue),
      clientBTGAccount: client.clientBTGAccount || ' ',
      status: clientStatus,
      annotation: clientAnnotation || ' ',
    };

    try {
      await hubApiProducts.patchClientInfosInAOffer(clientInfosToSave, offerId);

      toast.dark('Alterações salvas com sucesso!');
      setIsSaved(true);

      setFilteredClients(
        filteredClients.filter(clientFilter => {
          if (
            clientInfosToSave.clientBTGAccount === clientFilter.clientBTGAccount
          ) {
            clientFilter.allocationAmount = String(
              clientInfosToSave.allocationAmount
            );
            clientFilter.clientBTGAccount = clientInfosToSave.clientBTGAccount;
            clientFilter.status = clientInfosToSave.status;
            clientFilter.annotation = clientInfosToSave.annotation;
          }
          return clientFilter;
        })
      );

      setClients(
        oldClients.filter(clientFilter => {
          if (
            clientInfosToSave.clientBTGAccount === clientFilter.clientBTGAccount
          ) {
            clientFilter.allocationAmount = String(
              clientInfosToSave.allocationAmount
            );
            clientFilter.clientBTGAccount = clientInfosToSave.clientBTGAccount;
            clientFilter.status = clientInfosToSave.status;
            clientFilter.annotation = clientInfosToSave.annotation;
          }
          return clientFilter;
        })
      );
    } catch (error) {
      toast.dark(
        'Não foi possível salvar as alterações, tente novamente em alguns minutos'
      );
    }
  };

  const handleChangeStatusValue = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsSaved(false);
    if (event.target.value === 'Cancelado') {
      setAllocationValue('0');
    }

    setClientStatus(event.target.value as StatusType);
  };

  const handleChangeAllocationValue = (event: string) => {
    setIsSaved(false);
    setAllocationValue(event);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
  };

  const handleCloseAnnotationModal = () => {
    setShowAnnotationModal(false);
  };

  const handleChangeAnnotationValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setIsSaved(false);
    setClientAnnotation(event.target.value);
  };

  const checkClientStatus = (status: string): string => {
    if (status?.toLowerCase() === 'confirmado') return 'green.300';
    if (status?.toLowerCase() === 'cancelado') return 'red.300';
    return 'yellow.300';
  };

  useEffect(() => {
    setClientStatus(client.status);
    setClientAnnotation(client.annotation || '');
    setAllocationValue(client.allocationAmount || '0');
  }, [client]);

  return (
    <>
      <TableRow _hover={{ background: 'background.500' }} transition="400ms">
        <TableData>
          <Tooltip
            label={formattedPhone.length > 4 && formattedPhone}
            placement="top-end"
            backgroundColor="background.400"
            fontSize="sm"
            closeOnClick={false}
          >
            <Text isTruncated>{formatNameToCaptalize(client.clientName)}</Text>
          </Tooltip>
        </TableData>
        <TableData>{formatValueForSI(Number(client.pl))}</TableData>
        <TableData>
          {formatValueForSI(Number(Number(client.todayBalance).toFixed(2)))}
        </TableData>
        <TableData>
          <Flex justifyContent="center" gridGap={3}>
            <Text color={checkClientStatus(clientStatus)}>
              {clientStatus || 'Pendente'}
            </Text>
            <RiEdit2Fill
              style={{ cursor: 'pointer' }}
              size={16}
              onClick={() => setShowStatusModal(true)}
            />
          </Flex>
        </TableData>
        <TableData>{formatValueWithReactCurrency(allocationValue)}</TableData>
        <TableData>
          <Flex justifyContent="center">
            <BiCommentDetail
              size={20}
              cursor="pointer"
              onClick={() => setShowAnnotationModal(true)}
            />
          </Flex>
        </TableData>
        <TableData w="110px">
          {isSaved ? (
            <Flex justifyContent="center">
              <FcOk size={20} />
            </Flex>
          ) : (
            <Text
              className="itemToBeSaved"
              as="button"
              background="background.300"
              color="white"
              fontWeight="semibold"
              _hover={{ bg: 'background.400' }}
              px={3}
              borderRadius={3}
              onClick={saveAllChangesInARow}
            >
              salvar
            </Text>
          )}
        </TableData>
      </TableRow>

      <ModalWrapper isOpen={showStatusModal} onClose={handleCloseStatusModal}>
        <StatusModal
          handleChangeStatusValue={handleChangeStatusValue}
          handleChangeAllocationValue={handleChangeAllocationValue}
          handleCloseStatusModal={handleCloseStatusModal}
          clientStatus={clientStatus}
          allocationValue={allocationValue}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showAnnotationModal}
        onClose={handleCloseAnnotationModal}
      >
        <AnnotationModal
          handleChangeAnnotationValue={handleChangeAnnotationValue}
          handleCloseAnnotationModal={handleCloseAnnotationModal}
          clientAnnotation={clientAnnotation}
        />
      </ModalWrapper>
    </>
  );
};
