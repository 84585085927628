import React from 'react';
import { Flex, Text, Divider, FlexProps } from '@chakra-ui/react';

interface DividerItensProps extends FlexProps {
  titleDivider?: string;
  orientationDivider: 'vertical' | 'horizontal' | undefined;
}

export const DividerItens: React.FC<DividerItensProps> = ({
  titleDivider,
  orientationDivider,
  ...rest
}) => {
  return (
    <Flex
      direction="row"
      alignItems="center"
      mb="24px"
      {...rest}
      gridGap="12px"
    >
      <Text fontWeight="bold">{titleDivider}</Text>
      <Divider orientation={orientationDivider} flex={1} />
    </Flex>
  );
};
