export const MIBSLogo = (): JSX.Element => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={8} cy={8} r={8} fill="#E7AE1C" />
      <circle cx={8} cy={8} r={6} fill="#EEC150" />
      <path
        d="M4.442 5.91H5.77l1.402 3.42h.06l1.402-3.42H9.96V11H8.917V7.686h-.042l-1.318 3.29h-.71L5.528 7.673h-.042V11H4.442V5.91zm7.482 0V11h-1.076V5.91h1.076z"
        fill="#FA8528"
      />
    </svg>
  );
};
