import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { TitleWithSideLine } from 'components/elements/texts';
import { ClientPendencyType } from 'sharedV2/types/client';
import { TasksFilterType } from '../tasks/hooks/useTasksFilter';

type PropsType = {
  tasks: ClientPendencyType[] | undefined;
  tasksFilter: TasksFilterType[];
  setTasksFilter: (filters: TasksFilterType[]) => void;
};

export const TasksInformationsAndFilters: React.FC<PropsType> = ({
  tasks,
  tasksFilter,
  setTasksFilter,
}): JSX.Element => {
  return (
    <Flex alignItems="center" gap={2}>
      <TitleWithSideLine>Tarefas ({tasks?.length})</TitleWithSideLine>
      {tasksFilter.map((item, index) => {
        const IsThereTaskWithThisType =
          (tasks?.filter(task => task.type === item.tasktype).length || 0) > 0;

        if (!IsThereTaskWithThisType) {
          return <></>;
        }

        return (
          <Flex w="76px" position="relative" alignItems="center" key={index}>
            <Text
              color="rgba(70, 70, 70, 1)"
              _hover={{ color: 'primary.400' }}
              transition="all 0.2s ease-in"
              fontSize="sm"
              cursor="pointer"
              onClick={() => {
                const updatedFilters = [...tasksFilter];
                updatedFilters[index] = {
                  ...updatedFilters[index],
                  showTasks: !updatedFilters[index].showTasks,
                };
                setTasksFilter(updatedFilters);
              }}
            >
              {item.name}
              {item.showTasks ? <ChevronUpIcon /> : <ChevronDownIcon />}

              {!item.showTasks && (
                <Flex
                  backgroundColor="red.500"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  position="absolute"
                  top="-12px"
                  left="-15px"
                  padding="1px"
                  px="4px"
                >
                  <Text fontSize="xx-small" color="white">
                    {tasks?.filter(task => task.type === item.tasktype).length}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
