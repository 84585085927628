import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const DefaultCardWrapper: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex padding="5" borderRadius="md" background="white" {...rest}>
      {children}
    </Flex>
  );
};
