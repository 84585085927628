import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { formatNameToCaptalize } from 'utils/captalizeNames';

import UploadButton from 'components/elements/forms/UploadButton';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadsUploadModal } from 'pages/Captation/components/modules/LeadsUpload/LeadsUploadModal';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IReduxState } from 'shared/interfaces';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { SelectEmployeesToDivideLeads } from 'pages/Captation/components/elements/cards/SelectEmployeesToDivideLeads';

type NonImportedLeads = {
  data: {
    email: string;
    name: string;
    phone: string;
    status: string;
  }[];
  link: string;
};
type SelectedEmployee = {
  id: string;
  name: string;
};

export const LeadsUpload: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [file, setFile] = useState({ name: 'Escolher o arquivo' });
  const [isLoading, setIsLoading] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState<
    SelectedEmployee[]
  >([]);

  const isLoggedUserIsTableManager = verifyEmployeePermission({
    user,
    areaCod: ['ASS'],
    permissionCod: ['GTM'],
  });
  const isLoggedUserIsGeneralManager = verifyEmployeePermission({
    user,
    areaCod: ['ASS'],
    permissionCod: ['GTR'],
  });

  const [openModalSelectuser, setOpenModalSelectUser] = useState(false);
  const [showUploadInstructionsModal, setShowUploadInstructionsModal] =
    useState(true);
  const [nonImportedLeads, setNonImportedLeads] = useState(
    {} as NonImportedLeads
  );
  const [uploadStats, setUploadStats] = useState({
    importedLeadsPercentage: '',
    notImportedLeadsPercentage: '',
  });
  const [numLeads, setNumLeads] = useState(0);

  const getEmployeesByTable = async () => {
    try {
      if (isLoggedUserIsGeneralManager) {
        const response = await hubApiUser.getAllUsers();
        setEmployeesList(response);
        return;
      }

      if (isLoggedUserIsTableManager) {
        const response = await hubApiUser.getEmployeesByTable(user.tableId);
        setEmployeesList(response);
        return;
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os assessores da mesa, tente novamente em alguns minutos'
      );
    }
  };
  const downloadStandardFile = () => {
    window.location.replace(
      'https://hub-api.s3.amazonaws.com/ExemploImportacaoLeadsMinerados_Oficial.xlsx'
    );
  };

  const downloadNonImportedLeadsFile = () => {
    window.location.replace(nonImportedLeads.link);
  };

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const handleOpenModal = () => {
    setOpenModalSelectUser(!openModalSelectuser);
  };

  const sendFile = async () => {
    setIsLoading(true);

    if (file.name === 'Escolher o arquivo') {
      setIsLoading(false);
      toast.dark('Escolha um arquivo para enviar');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file as File);
      const employeeIds: string[] = [];

      if (isLoggedUserIsTableManager || isLoggedUserIsGeneralManager) {
        if (selectedEmployees.length === 0) {
          setIsLoading(false);
          return toast.dark(
            'Selecione os colaboradores que irão receber os leads'
          );
        }
        for (let i = 0; i < selectedEmployees.length; i++) {
          employeeIds.push(selectedEmployees[i].id);
        }
      } else {
        employeeIds.push(user.id);
      }

      const response = await hubApiCaptation.postLeadsFile(
        formData,
        employeeIds
      );

      if (!response) {
        setIsLoading(false);

        return toast.dark(
          'O limite de leads do arquivo foi excedido, diminua para no máximo 500'
        );
      }

      setNumLeads(response.numLeads);

      setTimeout(
        () => getNonImportedLeads(response.numLeads),
        response.numLeads * 300
      );

      toast.dark(
        'Arquivo enviado com sucesso, aguarde alguns instantes para o processamento'
      );
    } catch (error) {
      const err = error as AxiosError;

      if (
        err.response?.data.error.message ===
        'Invalid file format, verify column names'
      ) {
        toast.dark(
          'Formato inválido, verifique os nomes das colunas do seu arquivo'
        );
      } else if (
        err.response?.data.error.message ===
        'Invalid file size, verify number of lines'
      ) {
        toast.dark('Número de leads excedido, envie no máximo 500');
      } else {
        toast.dark(
          'Não foi possível enviar seu arquivo, tente novamente em alguns minutos'
        );
      }

      setIsLoading(false);
    }
    setOpenModalSelectUser(false);
  };

  const handleCloseUploadInstructionsModal = () => {
    setShowUploadInstructionsModal(false);
  };

  const getNonImportedLeads = async (numLeads: number) => {
    try {
      const response = await hubApiCaptation.getNonImportedLeads();

      setNonImportedLeads(response);

      computeUploadStats(response.data.length, numLeads);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os leads não importados, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const computeUploadStats = (
    numLeadsNotImported: number,
    numLeads: number
  ) => {
    const notImportedLeadsPercentage =
      ((numLeadsNotImported / numLeads) * 100) % 1 !== 0
        ? ((numLeadsNotImported / numLeads) * 100).toFixed(2)
        : String((numLeadsNotImported / numLeads) * 100);
    const importedLeadsPercentage =
      (((numLeads - numLeadsNotImported) / numLeads) * 100) % 1 !== 0
        ? (((numLeads - numLeadsNotImported) / numLeads) * 100).toFixed(2)
        : String(((numLeads - numLeadsNotImported) / numLeads) * 100);

    setUploadStats({
      importedLeadsPercentage: Number.isFinite(Number(importedLeadsPercentage))
        ? importedLeadsPercentage
        : '0',
      notImportedLeadsPercentage: Number.isFinite(
        Number(notImportedLeadsPercentage)
      )
        ? notImportedLeadsPercentage
        : '0',
    });
  };

  useEffect(() => {
    getEmployeesByTable();
  }, []);

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <Flex flexDirection="column" w="80vw" maxWidth="1080px">
        <Flex>
          <MainPageTitle
            color="inherit"
            w="fit-content"
            mt={6}
            mb={1}
            fontSize={['md', 'lg']}
          >
            Upload de Leads
          </MainPageTitle>

          <Flex
            title="Clique para ver as instruções de envio"
            alignSelf="end"
            mb={4}
            ml={1}
            cursor="pointer"
            onClick={() => setShowUploadInstructionsModal(true)}
          >
            <BiInfoCircle size={16} color="white" />
          </Flex>
        </Flex>

        <Flex w="100%" maxWidth="1080px" flexDir="column">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              mb="12px"
              justifyContent="space-between"
              alignItems="flex-start"
              flexDirection="column"
              gridGap={1}
            >
              <Flex gridGap={1} alignItems="flex-end">
                <Box w="117.2px">
                  <UploadButton
                    label={file.name}
                    accept=".xlsx"
                    onChange={handlePickFile}
                  />
                </Box>
                <Flex>
                  <PrimaryButton
                    px={4}
                    fontSize="xs"
                    w="116px"
                    h="36px"
                    onClick={() => {
                      if (
                        isLoggedUserIsTableManager ||
                        isLoggedUserIsGeneralManager
                      ) {
                        handleOpenModal();
                      } else {
                        sendFile();
                      }
                    }}
                    isLoading={isLoading}
                  >
                    Enviar leads
                  </PrimaryButton>
                </Flex>
              </Flex>

              <Flex w="237.2px">
                <SecondaryButton
                  w="100%"
                  fontSize="xs"
                  fontWeight="normal"
                  onClick={downloadStandardFile}
                >
                  <AiOutlineDownload
                    size={20}
                    style={{ marginRight: '14px' }}
                  />
                  Baixar o padrão do Excel
                </SecondaryButton>
              </Flex>
            </Flex>

            {uploadStats.importedLeadsPercentage !== '' && (
              <Flex gridGap={4} mr="20px">
                <Flex flexDirection="column" alignItems="center">
                  <Text fontSize="2xl" color="white" fontWeight="bold">
                    {numLeads - nonImportedLeads.data.length}

                    <Text
                      as="span"
                      fontWeight="normal"
                      ml={1}
                      fontSize="md"
                      color="gray.800"
                    >
                      ({uploadStats.importedLeadsPercentage}%)
                    </Text>
                  </Text>
                  <Text color="inherit">Leads importados</Text>
                </Flex>
                <Flex flexDirection="column" alignItems="center">
                  <Text fontSize="2xl" color="white" fontWeight="bold">
                    {nonImportedLeads.data.length}
                    <Text
                      as="span"
                      fontWeight="normal"
                      ml={1}
                      fontSize="md"
                      color="gray.800"
                    >
                      ({uploadStats.notImportedLeadsPercentage}%)
                    </Text>
                  </Text>
                  <Text color="inherit">Leads sem sucesso</Text>
                </Flex>
              </Flex>
            )}
          </Flex>

          <MainPageTitle
            color="inherit"
            w="fit-content"
            mt={6}
            mb={1}
            fontSize={['md', 'lg']}
          >
            Leads não importados
          </MainPageTitle>
          <TableBase
            headData={['Nome', 'Telefone', 'E-mail', 'Motivo']}
            width="100%"
            maxW="1080px"
            minHeight="calc(100vh - 420px)"
            maxH="calc(100vh - 400px)"
            overflow="auto"
          >
            {nonImportedLeads.data?.map((lead, index) => (
              <TableRow key={`${lead.email}-${index}`}>
                <TableData
                  color={
                    lead.name.toLowerCase() === 'não informado'
                      ? 'red.500'
                      : 'white'
                  }
                >
                  {formatNameToCaptalize(lead.name)}
                </TableData>
                <TableData
                  color={
                    lead.phone.toLowerCase() === 'não informado'
                      ? 'red.500'
                      : 'white'
                  }
                >
                  {lead.phone}
                </TableData>
                <TableData
                  color={
                    lead.email.toLowerCase() === 'não informado'
                      ? 'red.500'
                      : 'white'
                  }
                >
                  {lead.email}
                </TableData>
                <TableData>{lead.status}</TableData>
              </TableRow>
            ))}
          </TableBase>

          {nonImportedLeads.data?.length > 0 && (
            <Flex justifyContent="flex-end">
              <SecondaryButton
                mt={1}
                onClick={downloadNonImportedLeadsFile}
                fontWeight="normal"
                fontSize="xs"
              >
                <AiOutlineDownload size={20} style={{ marginRight: '14px' }} />
                Baixar leads não importados
              </SecondaryButton>
            </Flex>
          )}
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showUploadInstructionsModal}
        onClose={() => setShowUploadInstructionsModal(false)}
      >
        <LeadsUploadModal closeModal={handleCloseUploadInstructionsModal} />
      </ModalWrapper>

      <ModalWrapper
        isOpen={openModalSelectuser}
        onClose={() => {
          setOpenModalSelectUser(false);
        }}
        closeOnOverlayClick={false}
      >
        <SelectEmployeesToDivideLeads
          onClose={setOpenModalSelectUser}
          employeesList={employeesList}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          sendFile={sendFile}
          isLoading={isLoading}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
