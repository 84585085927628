import React from 'react';
import { Flex } from '@chakra-ui/react';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { HomePageContentBox } from './components/elements/wrappers';
import {
  CaptationRanking,
  CollaboratorNumbers,
  ProductsCalendar,
  DayAssignments,
} from './components/modules';

const Home: React.FC = () => {
  return (
    <PageWrapper justifyContent="center" paddingX="1rem">
      <Flex w="100%" flexDir={{ base: 'column', md: 'row' }} gridGap="3">
        <HomePageContentBox flex="2" gridGap="2%">
          <ProductsCalendar />
          <DayAssignments />
        </HomePageContentBox>
        <HomePageContentBox flex="1" gridGap="2%">
          <CollaboratorNumbers />
          <CaptationRanking />
        </HomePageContentBox>
      </Flex>
    </PageWrapper>
  );
};

export default Home;
