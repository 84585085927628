import { LeadManualType } from 'sharedV2/types/leadManual';
import { LeadParsedType } from 'sharedV2/types/leadTypes';
import { Mapper } from 'utilsV2/mapper';

type UseLeadManualMapperResponseType = {
  mapper: Mapper<LeadManualType, LeadParsedType>;
};

export const useLeadManualMapper = (): UseLeadManualMapperResponseType => {
  const toUseMapper = (lead: LeadManualType): LeadParsedType => {
    return {
      id: lead.id,
      email: lead.email,
      phones: lead.phones,
      created_at: lead.created_at,
      employeeId: lead.employeeId,
      frequency: 0,
      meetingPrice: 0,
      name: lead.name,
      origin: lead.origin,
      pipeValue: lead.pipeValue,
      plMundoInvest: 0,
      plSalesHunter: 0,
      price: 0,
      status: lead.status,
      updated_at: lead.updated_at,
      meeting: lead?.meeting,
      contactAttempts: lead?.contactAttempts,
    };
  };

  return {
    mapper: new Mapper(toUseMapper, null),
  };
};
