import { Flex, FlexProps } from '@chakra-ui/react';

export const InputRoot: React.FC<FlexProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <Flex flexDirection="column" gap={2} {...rest}>
      {children}
    </Flex>
  );
};
