import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { AssignmentProps } from 'pages/Home/interfaces';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { TitleWithSideLine } from 'components/elements/texts';
import { PrimaryButton } from 'components/elements/buttons';
import history from 'services/history';
import {
  IAssignmentsLength,
  IShowAndHideCard,
  TaskTypes,
} from 'pages/Home/interfaces/assignments/Assignment';
import { AssignmentsCard } from '../../elements/cards';
import { CompressAssignments } from '../../elements/cards/CompressAssignments';

export interface IAllAssignments {
  initialAssignments: AssignmentProps[];
  nps: AssignmentProps[];
  preNps: AssignmentProps[];
  origin: AssignmentProps[];
  clientsLeft: AssignmentProps[];
}

export const DayAssignments: React.FC = () => {
  const [filteredAssignments, setFilteredAssignments] = useState<
    AssignmentProps[]
  >([]);

  const [allAssignments, setAllAssignments] = useState<IAllAssignments>({
    initialAssignments: [],
    nps: [],
    preNps: [],
    origin: [],
    clientsLeft: [],
  });

  const [showCard, setShowCard] = useState<IShowAndHideCard>({
    cardPreNps: false,
    cardNps: false,
    cardOrigin: false,
    cardClientsLeft: false,
  });

  const [assignmentsLength, setAssignmentsLength] =
    useState<IAssignmentsLength>({
      origin: 0,
      nps: 0,
      preNps: 0,
      clientsLeft: 0,
    });

  const listUserAssignments = async () => {
    try {
      const response = await hubApiHome.listHomeAssignments();
      const today = new Date().toISOString().slice(0, 10);
      const filteredListByStatus = response.filter((item: any) => {
        if (item.typetask === 'noFeedbackPenalty') {
          return item;
        }
        if (
          item.typetask === 'cpf' ||
          item.typetask === 'periodicMeetingManager' ||
          item.typetask === 'identifyManager' ||
          item.typetask === 'balanceManager' ||
          item.typetask === 'noLeads'
        ) {
          return;
        }
        if (
          item.status !== 'marcado' &&
          !item.realized &&
          item.typetask === 'meetingToday'
        ) {
          if (
            item.dateLimit &&
            new Date(item.dateLimit).toISOString().slice(0, 10) === today
          ) {
            return item;
          }

          if (
            item.created_at_pendents &&
            new Date(item.created_at_pendents).toISOString().slice(0, 10) ===
              today
          ) {
            return item;
          }

          if (item.extra) {
            const { date } = JSON.parse(JSON.stringify(item.extra));
            if (date && new Date(date).toISOString().slice(0, 10) === today) {
              return item;
            }
          }
          if (
            item.created_at &&
            new Date(item.created_at).toISOString().slice(0, 10) === today
          ) {
            return item;
          }
        } else {
          return item;
        }
      });
      totalDependencies(filteredListByStatus);
      setFilteredAssignments(filteredListByStatus);
      const preNps: AssignmentProps[] = [];
      const origin: AssignmentProps[] = [];
      const nps: AssignmentProps[] = [];
      const clientsLeft: AssignmentProps[] = [];
      const initialAssignments: AssignmentProps[] = [];

      filteredListByStatus.forEach((data: any) => {
        if (data.typetask === TaskTypes.preNps) {
          return preNps.push(data);
        }

        if (data.typetask === TaskTypes.origin) {
          return origin.push(data);
        }

        if (data.typetask === TaskTypes.nps) {
          return nps.push(data);
        }

        if (data.typetask === TaskTypes.left) return clientsLeft.push(data);

        return initialAssignments.push(data);
      });
      setAllAssignments({
        nps,
        origin,
        preNps,
        clientsLeft,
        initialAssignments,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const totalDependencies = (filteredListByStatus: AssignmentProps[]) => {
    const preNpsLength: AssignmentProps[] = [];
    const originLength: AssignmentProps[] = [];
    const npsLength: AssignmentProps[] = [];
    const clientLeftLength: AssignmentProps[] = [];

    filteredListByStatus.forEach(data => {
      if (data.typetask === TaskTypes.left) return clientLeftLength.push(data);

      if (data.typetask === TaskTypes.preNps) {
        return preNpsLength.push(data);
      }

      if (data.typetask === TaskTypes.origin) {
        return originLength.push(data);
      }

      if (data.typetask === TaskTypes.nps) {
        return npsLength.push(data);
      }
    });

    setAssignmentsLength({
      origin: originLength.length,
      preNps: preNpsLength.length,
      nps: npsLength.length,
      clientsLeft: clientLeftLength.length,
    });
  };

  const handleRemoveAssignment = async (
    idTask: string,
    clientId?: string,
    isSharedContent?: boolean,
    additionalInformation?: string
  ) => {
    try {
      if (clientId) {
        if (additionalInformation?.trim() === '' || !additionalInformation) {
          return toast.dark('Digite o motivo da saída do cliente');
        }

        const objectToSend = {
          additionalInformation,
          id: clientId,
          status: 'confirmed deletion',
        };

        await hubApiHome.pathReasonAssignment(objectToSend);
      }

      await hubApiHome.deleteHomeAssignmentById(idTask);

      if (!isSharedContent) {
        toast.dark('Tarefa marcada como concluída');
      }

      const updatedList = filteredAssignments.filter(
        item => item.idTask !== idTask && item.id !== idTask
      );
      const preNps: AssignmentProps[] = [];
      const origin: AssignmentProps[] = [];
      const nps: AssignmentProps[] = [];
      const clientsLeft: AssignmentProps[] = [];
      const initialAssignments: AssignmentProps[] = [];

      updatedList.forEach(data => {
        if (data.typetask === TaskTypes.preNps) {
          return preNps.push(data);
        }

        if (data.typetask === TaskTypes.left) return clientsLeft.push(data);

        if (data.typetask === TaskTypes.origin) {
          return origin.push(data);
        }

        if (data.typetask === TaskTypes.nps) {
          return nps.push(data);
        }

        return initialAssignments.push(data);
      });

      setAssignmentsLength({
        origin: origin.length,
        preNps: preNps.length,
        nps: nps.length,
        clientsLeft: clientsLeft.length,
      });

      setFilteredAssignments(updatedList);

      setAllAssignments({
        nps,
        origin,
        preNps,
        initialAssignments,
        clientsLeft,
      });
    } catch (err) {
      toast.dark('Erro ao marcar a tarefa concluída');
    }
  };

  const handleRedirectToCalendar = () => {
    history.push('/clients/calendar');
  };

  useEffect(() => {
    listUserAssignments();
    return () => setFilteredAssignments([]);
  }, []);

  return (
    <Flex
      flexDir="column"
      width="100%"
      overflowY="auto"
      h={['300px', 'full']}
      mt={['3', '0']}
    >
      <DefaultCardWrapper flexDir="column" height="100%" p="3">
        <Flex flexDir={['column', 'row']}>
          <TitleWithSideLine
            width="100%"
            whiteSpace="nowrap"
            fontSize={['12px', 'md']}
          >
            Você tem {filteredAssignments.length} tarefas para hoje
          </TitleWithSideLine>
          <CompressAssignments
            showCard={showCard}
            assignmentsLength={assignmentsLength}
            setShowCard={setShowCard}
          />
          <PrimaryButton
            ml={['0', '4']}
            size="xs"
            onClick={handleRedirectToCalendar}
            minWidth={150}
            gridGap={4}
          >
            Calendário de contato
          </PrimaryButton>
        </Flex>

        <Flex
          mt="4"
          width="100%"
          flexWrap="wrap"
          gridGap="2"
          height="fit-content"
          overflowY="auto"
        >
          {allAssignments.initialAssignments?.map((item, index) => (
            <AssignmentsCard
              key={`${index}-item.id`}
              assignment={item}
              handleRemoveItem={handleRemoveAssignment}
            />
          ))}

          {showCard.cardOrigin &&
            allAssignments.origin?.map((item, index) => (
              <AssignmentsCard
                key={`${index}-item.id`}
                assignment={item}
                handleRemoveItem={handleRemoveAssignment}
              />
            ))}

          {showCard.cardNps &&
            allAssignments.nps?.map((item, index) => (
              <AssignmentsCard
                key={`${index}-item.id`}
                assignment={item}
                handleRemoveItem={handleRemoveAssignment}
              />
            ))}

          {showCard.cardPreNps &&
            allAssignments.preNps?.map((item, index) => (
              <AssignmentsCard
                key={`${index}-item.id`}
                assignment={item}
                handleRemoveItem={handleRemoveAssignment}
              />
            ))}

          {showCard.cardClientsLeft &&
            allAssignments.clientsLeft?.map((item, index) => (
              <AssignmentsCard
                key={`${index}-item.id`}
                assignment={item}
                handleRemoveItem={handleRemoveAssignment}
              />
            ))}
        </Flex>
      </DefaultCardWrapper>
    </Flex>
  );
};
