import { Flex } from '@chakra-ui/react';

export const WrapperCalendarContact: React.FC = ({ children }) => {
  return (
    <Flex
      flexDir="column"
      w="100%"
      gridGap="2"
      pr="2"
      overflowY={{ base: 'inherit', md: 'auto' }}
      height="100vh"
      maxH="calc(100vh - 264px)"
    >
      {children}
    </Flex>
  );
};
