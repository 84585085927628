import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { RecoverMeetingFromPipeModal } from 'componentsV2/modules/captation/recoverMeetingFromPipe';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';

export const LeadDetailsRecoverMeetingFromPipe = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenRecoverMeetingFromPipeModal =
    getParam('isOpenRecoverMeetingFromPipeModal') === 'true';

  return (
    <>
      <Button.Primary
        onClick={() => setParam('isOpenRecoverMeetingFromPipeModal', 'true')}
      >
        Agendar Reunião
      </Button.Primary>

      <ModalWrapper
        isOpen={isOpenRecoverMeetingFromPipeModal}
        onClose={() => deleteParam('isOpenRecoverMeetingFromPipeModal')}
      >
        <RecoverMeetingFromPipeModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
