import { BoxDefault } from './components/boxDefault';
import { BoxGlass } from './components/boxGlass';
import { BoxOpaque } from './components/boxOpaque';
import { BoxOutline } from './components/boxOutline';

export const Box = {
  Glass: BoxGlass,
  Default: BoxDefault,
  Outline: BoxOutline,
  Opaque: BoxOpaque,
};
