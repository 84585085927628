import { Flex } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import { formatValueForSI } from 'utils/formatValueForSI';

interface BottomSectionGraphicProps {
  account: string;
  funds: string;
  rf: string;
  rv: string;
  prev: string;
  deriv: string;
}

export const BottomSectionGraphic: React.FC<BottomSectionGraphicProps> = ({
  account,
  funds,
  rf,
  rv,
  prev,
  deriv,
}) => {
  const graphicData = [
    {
      id: 'Conta Corrente',
      label: 'Conta Corrente',
      value: parseInt(account || '0'),
      color: '#2E93fA',
    },
    {
      id: 'Derivativos',
      label: 'Derivativos',
      value: parseInt(deriv || '0'),
      color: '#66DA26',
    },
    {
      id: 'Fundos',
      label: 'Fundos',
      value: parseInt(funds || '0'),
      color: '#546E7A',
    },
    {
      id: 'Previdência',
      label: 'Previdência',
      value: parseInt(prev || '0'),
      color: '#E91E63',
    },
    {
      id: 'Renda Fixa',
      label: 'Renda Fixa',
      value: parseInt(rf || '0'),
      color: '#FF9800',
    },
    {
      id: 'Renda Variável',
      label: 'Renda Variável',
      value: parseInt(rv || '0'),
      color: '#9C27B0',
    },
  ];

  return (
    <Flex w="60%" bg="background.700" borderRadius={8}>
      <ResponsivePie
        data={graphicData}
        colors={graphicData.map(item => item.color)}
        theme={{
          legends: {
            text: {
              fontSize: 32,
            },
          },
        }}
        margin={{ top: 30, right: 50, bottom: 30, left: -290 }}
        innerRadius={0.6}
        padAngle={1}
        cornerRadius={0.5}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabelsColor={{ from: 'color' }}
        valueFormat={value => formatValueForSI(value)}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: -290,
            itemsSpacing: 35,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#fff',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 24,
            symbolShape: 'square',
            data: graphicData.map(legend => ({
              id: legend.id,
              color: legend.color,
              label: `${legend.label} (${formatValueForSI(legend.value)})`,
            })),
          },
        ]}
      />
    </Flex>
  );
};
