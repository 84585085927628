import { Flex, Text } from '@chakra-ui/react';
import { formatToBRL } from 'brazilian-values';

import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { ResponsiveLineChartCPMM } from 'pages/GenerationalVariables/components/modules/ResponsiveLineChartCPMM';
import { WrapperBigNumbersMiGeneral } from 'pages/GenerationalVariables/components/modules/WrapperBigNumbersMiGeneral';
import { IInfosMundoInvestCpmm } from 'pages/GenerationalVariables/interfaces';

interface DashCPMMProps {
  cpmm: IInfosMundoInvestCpmm[];
  result: number[];
}

export const DashCPMM: React.FC<DashCPMMProps> = ({ cpmm, result }) => {
  return (
    <PageWrapper pt="34px" flexDir="column" align="center">
      <MainPageTitle mb="2"> Mundo Invest - CPMM</MainPageTitle>

      <Flex
        w="80%"
        h="65vh"
        gridGap="5"
        align="center"
        flexDir="column"
        justify="space-between"
      >
        {!cpmm.length ? (
          <Flex minW="100%">
            <LoadingSpinner w="100%" />
          </Flex>
        ) : (
          <>
            <ResponsiveLineChartCPMM date={cpmm} result={result} />

            <Flex
              mt="3"
              borderTop="1px solid gray"
              pt="3"
              justify="center"
              gridGap={40}
              w="100%"
            >
              <WrapperBigNumbersMiGeneral>
                <Text>Ted in (Atual)</Text>
                <Text fontSize="lg" fontWeight="bold">
                  {formatToBRL(cpmm[cpmm.length - 1].capt)}
                </Text>
              </WrapperBigNumbersMiGeneral>

              <WrapperBigNumbersMiGeneral>
                <Text>CPMM (Atual)</Text>
                <Text fontSize="lg" fontWeight="bold">
                  {formatToBRL(cpmm[cpmm.length - 1].cpmm)}
                </Text>
              </WrapperBigNumbersMiGeneral>

              <WrapperBigNumbersMiGeneral>
                <Text>Custo de mídia (Atual)</Text>
                <Text fontSize="lg" fontWeight="bold">
                  {formatToBRL(cpmm[cpmm.length - 1].totalCost)}
                </Text>
              </WrapperBigNumbersMiGeneral>
            </Flex>
          </>
        )}
      </Flex>
    </PageWrapper>
  );
};
