import { Box, Flex, Text } from '@chakra-ui/react';
import formatValue from 'utils/formatValue';
import { IBigNumberMkt } from 'pages/GenerationalVariables/interfaces';
import { BorderWidthSmallBorderLeftAndRigth } from '../../elements/others/BorderWidthSmallBorderLeftAndRigth';
import { WraperCardConsolidation } from '../../elements/wrappers/WraperCardConsolidation';

interface ConsolidationShProps {
  bigNumbersMkt: IBigNumberMkt;
}

export const ConsolidationAAI: React.FC<ConsolidationShProps> = ({
  bigNumbersMkt,
}) => {
  return (
    <Flex justify="space-between" align="center" gridGap={3} flexDir="column">
      <Flex w="100%" align="flex-start">
        <Box>
          <Text fontSize="xl" fontWeight="bold" marginLeft={[10, 0]}>
            AAI
          </Text>
        </Box>
      </Flex>
      <Flex flexDir="column">
        <Flex
          gridGap={3}
          h={['auto', '70px']}
          flexDirection={['column', 'row']}
        >
          <WraperCardConsolidation w="146px">
            <Text> R. Comprada</Text>
            <Text color="white"> {bigNumbersMkt.meetingsPurchased || 0}</Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="146px">
            <Text> R. Atendida</Text>
            <Text color="white"> {bigNumbersMkt.meetingsAnswer || 0} </Text>
          </WraperCardConsolidation>{' '}
          <WraperCardConsolidation w="146px">
            <Text>C. Ativas</Text>
            <Text color="white"> {bigNumbersMkt.activeAccounts || 0} </Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="146px">
            <Text>C. Inativas</Text>
            <Text color="white"> {bigNumbersMkt.inactiveAccounts || 0} </Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="146px">
            <Text>C Abertas</Text>
            <Text color="white">{bigNumbersMkt.openAccounts || 0} </Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="146px">
            <Text> Pl Captado</Text>
            <Text color="white"> {formatValue(bigNumbersMkt.pl || 0)}</Text>
          </WraperCardConsolidation>{' '}
        </Flex>
      </Flex>

      <Flex flexDir="column" align="center" mb={[10, 0]}>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Declínios
          </Text>
        </Box>

        <BorderWidthSmallBorderLeftAndRigth />

        <Flex
          gridGap={3}
          h={['auto', '70px']}
          flexDirection={['column', 'row']}
        >
          <WraperCardConsolidation w="120px">
            <Text> Cliente BTG</Text>

            {bigNumbersMkt.aai && (
              <Text color="white"> {bigNumbersMkt.aai.clientBTG}</Text>
            )}
          </WraperCardConsolidation>{' '}
          <WraperCardConsolidation w="120px">
            <Text> Não existe</Text>

            {bigNumbersMkt.aai && (
              <Text color="white"> {bigNumbersMkt.aai.noExist}</Text>
            )}
          </WraperCardConsolidation>{' '}
          <WraperCardConsolidation w="120px">
            <Text> Sem interesse</Text>
            {bigNumbersMkt.aai && (
              <Text color="white"> {bigNumbersMkt.aai.noInterest}</Text>
            )}
          </WraperCardConsolidation>
          <WraperCardConsolidation w="120px">
            <Text> Sem Dinheiro</Text>

            {bigNumbersMkt.aai && (
              <Text color="white"> {bigNumbersMkt.aai.noMoney}</Text>
            )}
          </WraperCardConsolidation>
        </Flex>
      </Flex>
    </Flex>
  );
};
