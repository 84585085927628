import { FlexProps, Text } from '@chakra-ui/react';
import { BaseLabelText } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import React from 'react';
import formatValue from 'utils/formatValue';

interface ComponentProps extends FlexProps {
  label: string;
  value: string | number;
  isMoney?: boolean;
  isPercentage?: boolean;
}

export const CardShowUserNumbers: React.FC<ComponentProps> = ({
  label,
  value,
  isMoney,
  isPercentage,
  ...rest
}) => {
  return (
    <DefaultCardWrapper
      width="100%"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      py="0.5"
      px="3"
      {...rest}
    >
      <BaseLabelText mb="0">{label}</BaseLabelText>
      <Text
        fontWeight="semibold"
        fontSize={{ base: 'md', lg: 'lg' }}
        lineHeight="initial"
      >
        {isMoney && formatValue(value)}
        {isPercentage && `${value}%`}
        {!isMoney && !isPercentage && value}
      </Text>
    </DefaultCardWrapper>
  );
};
