import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import { InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';
import ConfirmForms from '../../ConfirmForms';

import { handleOnBlurClient } from '../../../../utils';

interface ComponentProps {
  title: string;
  handleShowModal: () => void;
}

export const StrategyRvForm: React.FC<ComponentProps> = ({
  handleShowModal,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [interestTitle, setInterestTitle] = useState('Risco 1: Mínimo 35k');
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false);
  const [error, setError] = useState('');

  const [selectedClient, setSelectedClient] = useState({
    id: '',
    name: '',
    email: '',
    btgAccount: '',
  });
  const [formInfos, setFormInfos] = useState({
    clientId: '',
    risk: '',
    value: '',
    segment: '',
  });

  const interestOptions = [
    { title: 'Risco 1: Mínimo 35k' },
    { title: 'Risco 2: Mínimo 50k' },
    { title: 'Risco 3: Mínimo 60k' },
  ];

  const handleToggleConfirmModalClick = () => {
    setToggleConfirmModal(!toggleConfirmModal);
  };

  const handleOnBlur = async () => {
    handleOnBlurClient(
      selectedClient.btgAccount,
      setSelectedClient,
      setFormInfos,
      formInfos
    );
  };

  const handleConfirmInfos = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (formInfos.clientId === '') {
      setIsLoading(false);
      return toast.dark('Informe o código do cliente');
    }

    if (formInfos.value === '') {
      setIsLoading(false);
      return toast.dark('Digite o valor financeiro');
    }

    handleToggleConfirmModalClick();
  };

  const handleChangeInterest = (option: string) => {
    setInterestTitle(option);
  };

  const handleChangeClientValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedClient({
      ...selectedClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formInfos.clientId === '')
      return toast.dark('Informe o código do cliente');

    if (formInfos.value === '') return toast.dark('Informe o valor financeiro');

    const newArray = {
      ...formInfos,
      risk: interestTitle.toUpperCase(),
      segment: title.toUpperCase(),
    };

    hubApiRv
      .postMonthlyStrategies(newArray)
      .then(() => {
        toast.dark('Ordem enviada com sucesso');
        handleShowModal();
      })
      .catch(err => {
        if (err.response.status === 400) {
          setError(err.response.data.message);

          return toast.dark(
            'Ocorreu um erro ao tentar enviar ordem. Tente novamente'
          );
        }
        toast.dark('Ocorreu um erro ao tentar enviar ordem. Tente novamente');
      });
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal
      maxW="440px"
      title={title}
      handleFormSubmit={handleConfirmInfos}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
    >
      <Stack spacing="3">
        <InputWithLabel
          label="Financeiro:"
          type="text"
          placeholder="Financeiro"
          name="value"
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Cód. Cliente:"
          type="text"
          placeholder="Codigo do cliente"
          name="btgAccount"
          onChange={handleChangeClientValues}
          onBlur={handleOnBlur}
        />
        <InputWithLabel
          label="Nome do Cliente:"
          type="text"
          placeholder="Nome do cliente"
          name="name"
          value={selectedClient.name}
          isDisabled
        />
      </Stack>

      <Dropdown mt="16px" onChange={e => handleChangeInterest(e.target.value)}>
        {interestOptions.map((item, index) => (
          <option key={index} value={item.title}>
            {item.title}
          </option>
        ))}
      </Dropdown>

      <ModalWrapper
        isOpen={toggleConfirmModal}
        onClose={handleToggleConfirmModalClick}
      >
        <ConfirmForms
          handleFormSubmit={handleFormSubmit}
          formInfos={formInfos}
          handleShowModal={handleToggleConfirmModalClick}
          selectedClient={selectedClient}
          segment={title}
          interestTitle={interestTitle}
        />
      </ModalWrapper>
    </BaseModalFormCard>
  );
};
