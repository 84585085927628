import React, { useState } from 'react';
import { FcAbout } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const NoTelegramCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcAbout}
        title="Telegram"
        secondaryText="Você ainda não integrou o Telegram"
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Telegram não integrado"
          message="Você confirma que já integrou seu Telegram?"
          secondMessage=" Para cadastrar, procure dentro do Telegram @MIPlacasBot e digite /start. Quando perguntar o e-mail, escreva seu e-mail do HUB."
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
