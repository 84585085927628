import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import hubApi from 'services/hubApi';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { hubApiOfficeBranches } from 'services/hubApi/classes/HubApiOfficeBranches';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import {
  ModalWrapper,
  DefaultCardWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import { CreateTable } from '../../components/elements/OfficeTables/CreateTable';
import { MiniCard } from '../../components/elements';
import { IOffices, OfficeTablesProps, IManager } from './interfaces';

const OfficeTables: React.FC = () => {
  const [tables, setTables] = useState<OfficeTablesProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offices, setOffices] = useState<IOffices[]>([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [managersList, setManagersList] = useState<IManager[]>([]);

  useEffect(() => {
    getManagers();
    listTables();
  }, []);

  async function getManagers() {
    await hubApi
      .listEmployees()
      .then(response => {
        setManagersList(response);
      })
      .catch(() => toast.dark('Erro ao buscar informações dos aaa.'));
  }

  async function listTables() {
    setIsLoading(true);
    try {
      const tablesData = await hubApiOfficeTables.listTablesOffice();
      setTables(tablesData);
      setIsLoading(false);
      const officesData = await hubApiOfficeBranches.listBranchOffices();
      setOffices(officesData);
    } catch (err) {
      setIsLoading(false);
      toast.dark('Erro ao buscar dados das filiais.');
    }
  }

  const handleToggleModal = () => setDisplayModal(!displayModal);

  const handleDeleteTable = async (tableId: string) => {
    try {
      await hubApiOfficeTables.deleteTableOffice(tableId);

      const tablesData = await hubApiOfficeTables.listTablesOffice();
      setTables(tablesData);
      toast.dark('Mesa excluida com sucesso.');
    } catch (err) {
      toast.dark('Erro ao tentar excluir mesa.');
    }
  };

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />
      <MainPageTitle textAlign="center" fontSize={'xl'}>
        Mesas
      </MainPageTitle>

      <Flex w="100%" maxW="960px" flexDir="column">
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <PrimaryButton onClick={handleToggleModal}>
            Criar uma nova mesa
          </PrimaryButton>
        </Flex>

        <DefaultCardWrapper
          w="100%"
          mt="16px"
          maxH="370px"
          p="2"
          borderRadius="5px"
          flexDir="column"
          boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
        >
          <Flex
            flexDir="column"
            minH="180px"
            p="8px"
            maxH="440px"
            gridGap="1.5"
            overflowY="auto"
          >
            {isLoading && <LoadingSpinner />}
            {
              !isLoading &&
                tables.map((table, index) => (
                  <MiniCard
                    table={table}
                    key={index}
                    handleDeleteTable={handleDeleteTable}
                    setTables={setTables}
                    offices={offices}
                    managersList={managersList}
                  />
                ))
              // <>
              //   {tables.map((table, key) => (
              //     <MiniCard
              //       table={table}
              //       key={key}
              //       handleDeleteTable={handleDeleteTable}
              //       setTables={setTables}
              //       offices={offices}
              //       managersList={managersList}
              //     />
              //   ))}
              // </>
            }
          </Flex>
        </DefaultCardWrapper>
      </Flex>

      <ModalWrapper isOpen={displayModal} onClose={handleToggleModal}>
        <CreateTable
          handleToggleModal={handleToggleModal}
          setTables={setTables}
          offices={offices}
          managersList={managersList}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default OfficeTables;
