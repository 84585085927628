import { InputCurrency } from './components/inputCurrency';
import { InputDate } from './components/inputDate';
import { InputEditableCurrency } from './components/inputEditableCurrency';
import { InputEditableTextField } from './components/inputEditableTextField';
import { InputErrorMessage } from './components/inputErrorMessage';
import { InputLabel } from './components/inputLabel';
import { InputRoot } from './components/inputRoot';
import { InputTextAreaField } from './components/inputTextAreaField';
import { InputTextField } from './components/inputTextField';

export const Input = {
  Root: InputRoot,
  Label: InputLabel,
  TextField: InputTextField,
  TextAreaField: InputTextAreaField,
  Currency: InputCurrency,
  Date: InputDate,
  ErrorMessage: InputErrorMessage,
  EditableTextField: InputEditableTextField,
  EditableCurrency: InputEditableCurrency,
};
