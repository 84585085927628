import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Flex,
} from '@chakra-ui/react';

import { signOut } from 'store/modules/auth/actions';
import history from 'services/history';
import { FaExclamationCircle } from 'react-icons/fa';

interface IPage {
  title: string;
  path: string;
  permission: string;
}

interface ComponentProps {
  title?: string;
  menuPages: IPage[];
  enableLogout?: boolean;
  haveContract?: boolean;
}

export const SubMenu: React.FC<ComponentProps> = ({
  title,
  menuPages,
  enableLogout,
  haveContract,
}) => {
  const currentPage = useLocation();
  const dispatch = useDispatch();

  const handleRedirectRoute = (path: string) => history.push(path);

  const handleLogOut = () => {
    dispatch(signOut());
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        padding="0"
        margin="0"
        fontWeight="normal"
        fontSize={{ base: 'sm', '2xl': 'md' }}
        background="transparent"
        border="none"
        transition="color 0.15s ease-in"
        _focus={{ background: 'transparent' }}
        _hover={{ color: 'primary.400', background: 'transparent' }}
        _active={{ background: 'transparent' }}
        rightIcon={<ChevronDownIcon color="black" />}
      >
        <Flex align="center" justify="center">
          <Text color="black">{title}</Text>
          {haveContract && (
            <FaExclamationCircle style={{ marginLeft: '6px' }} size={12} />
          )}
        </Flex>
      </MenuButton>
      <MenuList
        background="#fff"
        border="1px solid rgba(70, 70, 70, 1)"
        zIndex="12"
      >
        {menuPages?.map((page, index) => (
          <MenuItem
            key={index}
            fontSize={{ base: 'sm', '2xl': 'md' }}
            _focus={{ background: 'transparent' }}
            _hover={{ background: '#f2f2f2' }}
            color={
              currentPage.pathname.includes(page.path) ? 'primary.500' : 'white'
            }
            onClick={() => handleRedirectRoute(page.path)}
          >
            {page.title === 'Meu Perfil' ? (
              <Flex align="center" w="100%" justify="space-between">
                <Text as="span" color="rgba(70, 70, 70, 1)">
                  {page.title}
                </Text>
                {haveContract && (
                  <FaExclamationCircle style={{ width: '18px' }} size={15} />
                )}
              </Flex>
            ) : (
              <Flex align="center" w="100%" gap={3}>
                <Text as="span" color="rgba(70, 70, 70, 1)">
                  {page.title}
                </Text>
              </Flex>
            )}
          </MenuItem>
        ))}
        {enableLogout && (
          <MenuItem
            _focus={{ background: 'transparent' }}
            _hover={{ background: '#f2f2f2' }}
            onClick={handleLogOut}
          >
            <Text as="span" color="rgba(70, 70, 70, 1)">
              Sair
            </Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
