import { toast } from 'react-toastify';
import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { Text } from '@chakra-ui/react';

interface DeleteOfferModalProps {
  closeModal: () => void;
  signalToUpdateOffers: () => void;
  offerId: string;
}

export const DeleteOfferModal: React.FC<DeleteOfferModalProps> = ({
  closeModal,
  signalToUpdateOffers,
  offerId,
}) => {
  const handleDeleteOffer = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await hubApiProducts.deleteOffer(offerId);

      toast.dark('Oferta excluída');
      signalToUpdateOffers();
      closeModal();
    } catch (error) {
      toast.dark(
        'Não foi possível excluir a oferta, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <BaseModalFormCard
      isModal
      isLoading={false}
      maxW="480px"
      title="Excluir oferta"
      handleFormSubmit={handleDeleteOffer}
      handleToggleModal={closeModal}
      primaryButtonText="Excluir oferta"
    >
      <Text mb="1.5">
        Deseja realmente excluir esta oferta? Todos os dados referentes a ela
        serão perdidos.
      </Text>
    </BaseModalFormCard>
  );
};
