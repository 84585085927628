import { useMutation, UseMutationResult } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { ClientMeetingType } from 'sharedV2/types/client';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useUpdateClientMeeting = (
  props?: PropsType
): UseMutationResult<unknown, unknown, ClientMeetingType, unknown> => {
  return useMutation({
    mutationKey: ['updateClientMeeting'],
    mutationFn: async (data: ClientMeetingType) => {
      const response = await hubApiClients.updateMeeting(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};
