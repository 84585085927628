import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';

export enum MeetingsPoolTabTitleEnum {
  LATE = 'R. Atrasadas',
  CALLBACK = 'Retornos',
  SUITABILITY = 'R1 - Suitability',
  PRESENTATION = 'R2 - Carteira',
  ALLOCATION = 'Aguardando Abertura',
  ACCOUNT = 'Aporte',
}

export enum MeetingsPoolTabEnum {
  CALLBACK = 'callback',
  SUITABILITY = 'suitability',
  PRESENTATION = 'presentation',
  ALLOCATION = 'allocation',
  ACCOUNT = 'account',
}

type UseMeetingsPoolContextResponseType = {
  selectedTab: MeetingsPoolTabEnum;
  selectedTabTitle: MeetingsPoolTabTitleEnum;
  handleChangeSelectedTab: (tab: MeetingsPoolTabEnum) => void;
};

export const useMeetingsPoolContext =
  (): UseMeetingsPoolContextResponseType => {
    const {
      filterValue: selectedTab,
      handleChangeFilterValue: handleChangeSelectedTab,
    } = useSimpleQueryFilter<MeetingsPoolTabEnum>('tab', {
      initialValue: MeetingsPoolTabEnum.SUITABILITY,
    });

    const getSelectedTabTitle = (): MeetingsPoolTabTitleEnum => {
      switch (selectedTab) {
        case MeetingsPoolTabEnum.CALLBACK:
          return MeetingsPoolTabTitleEnum.CALLBACK;
        case MeetingsPoolTabEnum.SUITABILITY:
          return MeetingsPoolTabTitleEnum.SUITABILITY;
        case MeetingsPoolTabEnum.PRESENTATION:
          return MeetingsPoolTabTitleEnum.PRESENTATION;
        case MeetingsPoolTabEnum.ALLOCATION:
          return MeetingsPoolTabTitleEnum.ALLOCATION;
        case MeetingsPoolTabEnum.ACCOUNT:
          return MeetingsPoolTabTitleEnum.ACCOUNT;
        default:
          return MeetingsPoolTabTitleEnum.SUITABILITY;
      }
    };

    return {
      selectedTab,
      selectedTabTitle: getSelectedTabTitle(),
      handleChangeSelectedTab,
    };
  };
