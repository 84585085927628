import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import DropdownMenu from 'components/customElements/DropdownMenu';
import InputLabel from 'components/customElements/InputLabel';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { DefaultCardWrapper } from 'components/elements/wrappers';

export const ModalCreateCampaign = ({ handleShowModal }) => {
  const [listTagsOptions, setListTagsOptions] = useState(
    'Selecione uma tag de campanha'
  );
  const [listTags, setListTags] = useState([]);
  const [campaignValue, setCampaignValue] = useState('');

  useEffect(() => {
    const listTags = async () => {
      await hubApiMarketing.listTagsCampaign().then(response => {
        setListTags(response);
      });
    };
    listTags();
  }, []);

  const handleModalCampaign = item => {
    setListTagsOptions(item.title);
  };

  const handleFormCreateCampaign = e => {
    e.preventDefault();

    if (listTagsOptions === 'Selecione uma tag de campanha') {
      return toast.dark('Selecione uma tag de campanha.');
    }

    hubApiMarketing
      .postCreateCampaign({
        employeeId: '',
        tag: listTagsOptions,
        name: campaignValue,
      })
      .then(() => {
        toast.dark('Campanha criada com sucesso.');
      })
      .catch(() => {
        toast.dark('Preencha todos os campos.');
      });
  };

  return (
    <DefaultCardWrapper>
      <form onSubmit={handleFormCreateCampaign}>
        <div className="title-dropdown">
          <DropdownMenu
            title={listTagsOptions}
            options={listTags}
            handleClick={handleModalCampaign}
            pickOption
            md
          />
        </div>
        <InputLabel
          // handleChange={handleChangeValues}
          type="text"
          label="Nome da campanha"
          placeholder="digite o nome da campanha"
          name="campaign"
          onChange={e => setCampaignValue(e.target.value)}
        />

        <Flex>
          <PrimaryButton>Enviar</PrimaryButton>

          <SecondaryButton removeMargin onClick={() => handleShowModal(false)}>
            Fechar
          </SecondaryButton>
        </Flex>
      </form>
    </DefaultCardWrapper>
  );
};

export default ModalCreateCampaign;
