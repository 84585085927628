import { Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Modal } from 'componentsV2/elements/modal';

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  onConfirmAction: () => void;
  isLoading?: boolean;
};

export const ConfirmationModal: React.FC<PropsType> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  onConfirmAction,
  isLoading = false,
}): JSX.Element => {
  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Modal.Root>
        <Modal.Header>
          <Modal.Title title={title} />
          <Modal.CloseIcon onClick={onClose} />
        </Modal.Header>

        {subtitle && <Text color="rgba(70, 70, 70, 1)">{subtitle}</Text>}

        <Modal.Actions>
          <Modal.Action
            text="Confirmar"
            onClick={onConfirmAction}
            isLoading={isLoading}
          />
        </Modal.Actions>
      </Modal.Root>
    </ModalWrapper>
  );
};
