import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const PageWrapper: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      minH="calc(100vh - 80px)"
      height="100%"
      paddingX="20px"
      background="rgba(242, 242, 242, 0.95)"
      position="relative"
      overflowX="hidden"
      {...rest}
    >
      {children}
    </Flex>
  );
};
