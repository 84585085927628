import React from 'react';
import { TableData, TableRow } from 'components/elements/table';

interface ComponentProps {
  rv: {
    btgAccount: string;
    name: string;
    segment: string;
    risk: string;
    financeiro: string;
    employeeName: string;
    employeeBTGAccount: string;
  };
}

const ActiveCustomersRvTableRow: React.FC<ComponentProps> = ({ rv }) => {
  return (
    <TableRow>
      <TableData>{rv.name}</TableData>
      <TableData>{rv.btgAccount}</TableData>
      <TableData>{rv.segment}</TableData>
      <TableData>{rv.risk}</TableData>
      <TableData>{rv.financeiro}</TableData>
      <TableData>{rv.employeeName}</TableData>
      <TableData>{rv.employeeBTGAccount}</TableData>
    </TableRow>
  );
};

export default ActiveCustomersRvTableRow;
