import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';

import { ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  IBaseLead,
  IBaseMeeting,
  IQuestionProps,
} from 'pages/Captation/interfaces';
import CurrencyFormat from 'react-currency-format';
import { useCaptationMeetingsContext } from 'hooks';
import { SecondaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { queryClient } from 'App';
import { useMutation } from 'react-query';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { QuestionManagementModal } from '../QuestionManagementModal';
import { MeetingQuestionItem } from '../MeetingQuestionItem';

interface ComponentProps {
  questions: IQuestionProps[];
  lead: IBaseLead;
  meeting?: IBaseMeeting;
}

export const MeetingQuestions: React.FC<ComponentProps> = ({
  questions,
  lead,
  meeting,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [handleShowModal, setHandleShowModal] = useState(false);
  const [questionItem, setQuestionItem] = useState({
    question: '',
    answer: '',
  });
  const [pipeValue, setPipeValue] = useState(
    lead.pipeValue !== '' && lead.pipeValue !== null ? lead.pipeValue : ''
  );
  const [previousPipeValue, setPreviousPipeValue] = useState(
    lead.pipeValue !== '' && lead.pipeValue !== null ? lead.pipeValue : ''
  );
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const handleToggleModal = () => {
    setHandleShowModal(!handleShowModal);
  };

  const handleAddQuestion = useMutation({
    mutationFn: async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const response = await hubApiCaptation.postNewQuestion({
        question: questionItem.question,
        status: 'standard',
      });

      await hubApiCaptation.postResponse({
        data: [
          {
            answer: questionItem.answer,
            leadId: lead.id,
            idQuestion: response.id,
          },
        ],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['leadQuestions', lead.id]);
      setQuestionItem({ question: '', answer: '' });
      toast.dark('Informação adicionada com sucesso');
      handleToggleModal();
    },
  });

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestionItem({ ...questionItem, [e.target.name]: e.target.value });
  };

  const leadType = () => {
    if ('NomeListaPool' in lead) {
      return 'tables';
    }
    if ('rdStatioId' in lead) {
      return 'publics';
    }
    if (lead.leadType === 'leadsmanual') {
      return 'employee';
    }
    if (lead.type === 'leadspj') {
      return 'pj';
    }

    return 'publics';
  };

  const leadTypeUpdate = (): LeadTypeEnum => {
    if ('listName' in lead) {
      return LeadTypeEnum.UPLOAD;
    }
    if ('rdStatioId' in lead) {
      return LeadTypeEnum.MUNDO_INVEST;
    }
    if (lead.leadType === 'leadsmanual') {
      return LeadTypeEnum.MANUAL;
    }
    if (lead.type === 'leadspj') {
      return LeadTypeEnum.PJ;
    }

    return LeadTypeEnum.MUNDO_INVEST;
  };

  const { mutateAsync: updateLead } = useUpdateLead();

  const updatePipeValue = async () => {
    setIsLoading(true);
    try {
      if (pipeValue !== '' && pipeValue !== previousPipeValue) {
        await updateLead({
          type: leadTypeUpdate(),
          leadId: lead?.id as string,
          pipeValue: pipeValue
            .substring(2, pipeValue.length - 3)
            .replace(/[^0-9]/g, ''),
        });
        toast.dark('Valor do pipe atualizado com sucesso!');
      }
      setPreviousPipeValue(pipeValue);

      if (meeting) {
        handleGetSpecificLeadTypeArr(meeting.status, true);
      }
    } catch (err) {
      toast.dark('Não foi possivel atualizar o valor do pipe.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (lead.pipeValue !== null && lead.pipeValue !== '') {
      setPipeValue(lead.pipeValue);
      setPreviousPipeValue(lead.pipeValue);
    }
  }, [lead.pipeValue]);

  return (
    <Flex flexDir="column" width="100%" height="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex h="100%" m={2} mt={3} gap={2} alignItems="center">
          <Text>Pipe: </Text>
          <CurrencyFormat
            style={{
              backgroundColor: '#2E2E2E',
              width: '130px',
              height: '30px',
              borderColor: '#2E2E2E',
              borderWidth: '1px',
              borderRadius: '5px',
              padding: '5px',
            }}
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale
            decimalSeparator=","
            prefix="R$"
            value={pipeValue}
            onValueChange={values => {
              const { value } = values;
              setPipeValue(value);
            }}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setPipeValue(e.target.value)}
          />
          {pipeValue !== '' && pipeValue !== previousPipeValue && (
            <SecondaryButton
              size="xs"
              w="51.02px"
              onClick={() => updatePipeValue()}
            >
              {isLoading ? <LoadingSpinner size="xs" /> : 'Salvar'}
            </SecondaryButton>
          )}
        </Flex>
        <Flex
          justifyContent="flex-end"
          gridGap="1.5"
          cursor="pointer"
          _hover={{ color: 'white' }}
          transition="300ms"
          alignItems="center"
          onClick={handleToggleModal}
        >
          <BiAddToQueue size={24} />
          <Text fontSize="sm">Adicionar informações</Text>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        mt="2"
        px="1"
        gridGap="1.5"
        overflowY="auto"
        h="100%"
        maxH="calc(100vh - 630px)"
      >
        {questions?.map((question, index: number) => (
          <MeetingQuestionItem key={index} question={question} lead={lead} />
        ))}
      </Flex>

      <ModalWrapper isOpen={handleShowModal} onClose={handleToggleModal}>
        <QuestionManagementModal
          title="Adicionar pergunta"
          questionItem={questionItem}
          handleChangeValue={handleChangeValue}
          handleFormSubmit={handleAddQuestion.mutateAsync}
          handleToggleModal={handleToggleModal}
          isLoading={isLoading || handleAddQuestion.isLoading}
        />
      </ModalWrapper>
    </Flex>
  );
};
