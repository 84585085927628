import { ModalWrapper } from 'components/elements/wrappers';
import { KanbanTaskBase } from 'components/modules/kanbanBases';
import { rhColumns } from 'pages/RH/subpages/Kanban/data';
import React, { useEffect, useState } from 'react';
import { KanbanBaseTaskProps } from 'shared/interfaces';

import { BoardOrderInfo } from '../BoardOrderInfo';
import CloseOrder from '../CloseOrder';

interface TaskProps extends KanbanBaseTaskProps {
  information?: string;
  employeename?: string;
}

interface ComponentProps {
  index: number;
  task: TaskProps;
}

const Task: React.FC<ComponentProps> = ({ index, task }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLastColumn, setIsLastColumn] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (task.status?.toLowerCase() === rhColumns[2].status.toLowerCase()) {
      setIsLastColumn(true);
    }
  }, [task.status]);

  const handleToggleModal = () => setShowModal(!showModal);

  const handleToggleLastModal = () => setIsLastColumn(!isLastColumn);

  return (
    <>
      <KanbanTaskBase
        taskId={task.id}
        taskIndex={index}
        isFinished={isFinished}
        primaryText={task.information}
        secondaryText={task.name || task.employeename}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BoardOrderInfo order={task} handleToggleModal={handleToggleModal} />
      </ModalWrapper>

      <ModalWrapper isOpen={isLastColumn} onClose={handleToggleLastModal}>
        <CloseOrder
          order={task}
          handleToggleModal={handleToggleLastModal}
          setIsFinished={setIsFinished}
        />
      </ModalWrapper>
    </>
  );
};

export default Task;
