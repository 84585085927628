import React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { Divider } from 'components/elements/others';
import { checkLeadTypeLabel } from 'pages/Captation/utils';
import { ISearchLeadItem } from 'pages/Captation/interfaces';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatToPhone } from 'brazilian-values';

interface IComponentProps {
  lead: ISearchLeadItem;
  handleToggleLeadDetails: (clickedLead: ISearchLeadItem) => void;
  leadIndex?: number;
}

export const ManagerSearchLeadCard: React.FC<IComponentProps> = ({
  handleToggleLeadDetails,
  lead,
}) => {
  return (
    <DefaultCardWrapper
      flexDir="column"
      p="3.5"
      onClick={() => handleToggleLeadDetails(lead)}
      cursor="pointer"
    >
      <Text color="white" fontSize="md">
        {formatNameToCaptalize(lead?.name)}
      </Text>

      <Divider orientation="horizontal" my="2" />

      <Stack spacing={1}>
        {lead.phone && (
          <Flex gap={1}>
            <Text fontSize="smaller">Telefone: </Text>
            <Text fontSize="smaller" color="white">
              {formatToPhone(
                lead.phone.length > 0 && lead?.phone[0].includes('55')
                  ? lead?.phone[0].substring(2)
                  : lead?.phone[0] || ''
              )}
            </Text>
          </Flex>
        )}

        {lead.leadType && (
          <Flex gap={1}>
            <Text fontSize="smaller">Tipo do lead:</Text>
            <Text fontSize="smaller" color="white">
              {checkLeadTypeLabel(lead?.leadType)}
            </Text>
          </Flex>
        )}
        {lead.employeeName && lead.employeeName.trim() !== 'Erro' && (
          <Flex gap={1}>
            <Text fontSize="smaller">Responsável:</Text>
            <Text fontSize="smaller" color="white">
              {formatNameToCaptalize(lead.employeeName)}
            </Text>
          </Flex>
        )}
      </Stack>
    </DefaultCardWrapper>
  );
};
