import {
  UseSimpleFilterResponseType,
  useSimpleQueryFilter,
} from 'hooksV2/useSimpleQueryFilter';
import { ClientType } from 'sharedV2/types/client';

type UseFilterClientsResponseType = {
  filteredClients: ClientType[] | undefined;
  clientNameOrBtgCodeFilter: UseSimpleFilterResponseType;
  clientsFilter: UseSimpleFilterResponseType;
  clientsTagFilter: UseSimpleFilterResponseType;
  sortClientsByFilter: UseSimpleFilterResponseType<SortClientsByEnum>;
};

export enum SortClientsByEnum {
  TOTAL_PL = 'TOTAL_PL',
  NAME = 'NAME',
  DECLARED_PATRIMONY = 'DECLARED_PATRIMONY',
  STUCK_MONEY = 'STUCK_MONEY',
}

export enum FilterClientsEnum {
  WITH_PENDENCIES = 'WITH_PENDENCIES',
  PL_LESS_THAN_5K = 'PL_LESS_THAN_5K',
}

export const useFilterAndSortClients = (
  clients: ClientType[] | undefined
): UseFilterClientsResponseType => {
  const clientNameOrBtgCodeFilter = useSimpleQueryFilter('clientNameOrBtgCode');
  const clientsFilter = useSimpleQueryFilter('filterClientsBy');
  const sortClientsByFilter =
    useSimpleQueryFilter<SortClientsByEnum>('sortClientsBy');
  const clientsTagFilter = useSimpleQueryFilter('filterClientsByTag');

  const filterClientsByNameOrBtgCode = () => {
    if (!clientNameOrBtgCodeFilter.filterValue) return clients;

    return clients?.filter(
      client =>
        client.name
          .toLowerCase()
          .includes(clientNameOrBtgCodeFilter.filterValue) ||
        client.btgCode.includes(clientNameOrBtgCodeFilter.filterValue)
    );
  };

  const sortClients = () => {
    if (sortClientsByFilter.filterValue === SortClientsByEnum.TOTAL_PL) {
      return clients?.sort(
        (a, b) => (b.netWorth?.total || 0) - (a.netWorth?.total || 0)
      );
    }

    if (
      sortClientsByFilter.filterValue === SortClientsByEnum.DECLARED_PATRIMONY
    ) {
      return clients?.sort(
        (a, b) => (b.netWorth?.declared || 0) - (a.netWorth?.declared || 0)
      );
    }

    if (sortClientsByFilter.filterValue === SortClientsByEnum.STUCK_MONEY) {
      return clients?.sort(
        (a, b) => (b.currentBalance || 0) - (a.currentBalance || 0)
      );
    }

    if (sortClientsByFilter.filterValue === SortClientsByEnum.NAME) {
      return clients?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }

    return clients;
  };

  const filterClients = (filteredClients: ClientType[] | undefined) => {
    if (!clientsFilter.filterValue) return filteredClients;

    if (clientsFilter.filterValue === FilterClientsEnum.WITH_PENDENCIES) {
      return filteredClients?.filter(client => client.pendencies.length > 0);
    }

    if (clientsFilter.filterValue === FilterClientsEnum.PL_LESS_THAN_5K) {
      return filteredClients?.filter(
        client => (client.netWorth?.total || 0) < 5_000
      );
    }
  };

  const filterClientsByTag = (filteredClients: ClientType[] | undefined) => {
    if (!clientsTagFilter.filterValue) return filteredClients;

    return filteredClients?.filter(client =>
      client.tags.includes(clientsTagFilter.filterValue)
    );
  };

  let filteredClients = sortClients();

  filteredClients = filterClientsByNameOrBtgCode();

  filteredClients = filterClients(filteredClients);

  filteredClients = filterClientsByTag(filteredClients);

  return {
    filteredClients,
    clientNameOrBtgCodeFilter,
    sortClientsByFilter,
    clientsFilter,
    clientsTagFilter,
  };
};
