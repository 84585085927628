import CurrencyInput from 'react-currency-input-field';
import { Flex, Text } from '@chakra-ui/react';

interface InputWithLabelAndFormatingToMoneyProps {
  label?: string;
  onChange: (event: string, name?: string) => void;
  name?: string;
  placeholder?: string;
  value?: string | number;
}

export const InputWithLabelAndFormatingToMoney: React.FC<InputWithLabelAndFormatingToMoneyProps> =
  ({ label, name, onChange, placeholder, value }) => {
    return (
      <Flex flexDir="column">
        {label && (
          <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
            {label}
          </Text>
        )}
        <Flex
          size="sm"
          color="rgba(70, 70, 70, 1)"
          margin="0"
          h="32px"
          background="white"
          border="1px solid #1d63ddb2"
          focusBorderColor="primary.600"
          _hover={{ borderColor: 'primary.800' }}
          _placeholder={{ color: 'gray.400' }}
        >
          <CurrencyInput
            allowNegativeValue={false}
            allowDecimals={false}
            defaultValue={value || 0}
            intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
            name={name}
            onValueChange={(e, name) => {
              if (e) onChange(e, name);
            }}
            placeholder={placeholder}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              padding: '0 8px',
              width: '100%',
            }}
          />
        </Flex>
      </Flex>
    );
  };
