import React from 'react';
import { TableData, TableRow } from 'components/elements/table';

interface ComponentProps {
  infosFound: {
    name: string;
    btgAccount: string;
    segment: string;
    financeiro: string;
    employeeName: string;
    employeeBTGAccount: string;
  };
}

const ActiveCustomersFundsTableRow: React.FC<ComponentProps> = ({
  infosFound,
}) => {
  return (
    <TableRow>
      <TableData>{infosFound.name}</TableData>
      <TableData>{infosFound.btgAccount}</TableData>
      <TableData>{infosFound.segment}</TableData>
      <TableData>{infosFound.financeiro}</TableData>
      <TableData>{infosFound.employeeName}</TableData>
      <TableData>{infosFound.employeeBTGAccount}</TableData>
    </TableRow>
  );
};

export default ActiveCustomersFundsTableRow;
