import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Textarea } from 'components/elements/forms';

import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import { KanbanBaseTaskProps } from 'shared/interfaces';

import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { Text } from '@chakra-ui/react';
import { closeOrderOptions } from './data';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
}

interface ComponentProps {
  order: TaskProps;
  handleToggleModal: () => void;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseOrder: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const [selectedOption, setSelectedOption] = useState('Escolha uma opção');
  const [reasonText, setReasonText] = useState('');

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleChangeReason = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setReasonText(e.target.value);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (selectedOption === 'Escolha uma opção')
      return toast.dark('Você deve escolher uma ação para finalizar a ordem');

    if (
      selectedOption === closeOrderOptions[1].title &&
      reasonText.trim() === ''
    )
      return toast.dark(
        'Você deve descrever o motivo do cancelamento da ordem'
      );

    await hubApiRH
      .updateOrderData({
        id: order.id,
        status: selectedOption.toLowerCase(),
        reason: reasonText,
      })
      .then(() => {
        setIsFinished(true);
        toast.dark(
          'Orderm finalizada com sucesso. A mesma será removida ao recarregar a tela'
        );
        handleToggleModal();
      })
      .catch(error => {
        if (error.response) {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        } else {
          toast.dark(
            'Ocorreu um erro no servidor ao tentar finalizar a ordem. Tente novamente mais tarde'
          );
        }
      });
  };

  return (
    <DefaultModalContentWrapper maxW="440px">
      <ModalTitle>Finalizar ordem</ModalTitle>

      <form onSubmit={handleFormSubmit}>
        <BaseLabelText>Esta ordem será:</BaseLabelText>

        <Dropdown
          mb="4"
          placeholder="Escolha uma das opções"
          onChange={e => handleSelectOption(e.target.value)}
        >
          {closeOrderOptions.map((item, index) => (
            <option key={index} value={item.title}>
              {item.title}
            </option>
          ))}
        </Dropdown>

        {selectedOption === closeOrderOptions[1].title && (
          <Textarea
            mb="3"
            placeholder="Descreva o motivo do cancelameto..."
            name="description"
            value={reasonText}
            onChange={handleChangeReason}
          />
        )}

        {selectedOption === closeOrderOptions[0].title && (
          <Text fontSize="sm" fontWeight="semibold" color="white" mt="5" mb="3">
            Você confirma que deseja finalizar essa solicitação?
          </Text>
        )}

        <div className="default-card-btns default-card-btns-right">
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
        </div>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default CloseOrder;
