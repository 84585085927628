import React, { useState, useEffect } from 'react';

import { ModalWrapper } from 'components/elements/wrappers';
import { exchangeColumns } from 'pages/Exchange/subpages/ExchangeCRM/data';

import { KanbanBaseTaskProps, KanbanColumnProps } from 'shared/interfaces';
import { KanbanTaskBase } from 'components/modules/kanbanBases';
import CloseOrder from '../../CloseOrder';
import { BoardOrderInfo } from '../BoardOrderInfo';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
}

interface ComponentProps {
  index: number;
  task: TaskProps;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  column: KanbanColumnProps[];
}

export const Task: React.FC<ComponentProps> = ({
  index,
  task,
  setColumns,
  column,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLastColumn, setIsLastColumn] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (task.status === exchangeColumns[4].value) {
      setIsLastColumn(true);
    }
  }, [task.status]);

  const handleToggleModal = () => setShowModal(!showModal);

  const handleToggleLastModal = () => setIsLastColumn(!isLastColumn);

  return (
    <>
      <KanbanTaskBase
        taskId={task.id}
        taskIndex={index}
        isFinished={isFinished}
        primaryText={task.type}
        secondaryText={task.name || task.title}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BoardOrderInfo
          order={task}
          handleToggleModal={handleToggleModal}
          isFinishOrder={isFinished}
          setIsFinished={setIsFinished}
          setColumns={setColumns}
          column={column}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={isLastColumn} onClose={handleToggleLastModal}>
        <CloseOrder
          order={task}
          handleToggleModal={handleToggleLastModal}
          setIsFinished={setIsFinished}
        />
      </ModalWrapper>
    </>
  );
};
