/* eslint @typescript-eslint/no-explicit-any: off */
import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import hubApi from 'services/hubApi';
import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { CardInfo } from 'components/elements/others/CardInfo';

interface ComponentProps {
  order: any;
  handleToggleModal: () => void;
}

export const OrderInfosTech: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  const [orderOwner, setOrderOwner] = useState<any>({});

  useEffect(() => {
    async function getOderOwner(employeeId: string) {
      await hubApi.getEmployeeInfos(employeeId).then(response => {
        setOrderOwner(response);
      });
    }

    getOderOwner(order.employeeId);
  }, [order.employeeId]);

  return (
    <DefaultModalContentWrapper maxW={['95vw', '490px']}>
      <ModalTitle>Dados da Ordem</ModalTitle>

      <Stack spacing="2">
        <CardInfo title="Colaborador solicitante" info={order.creatorname} />
        <CardInfo title="Tipo" info={order.type} />
        <CardInfo title="Descrição" info={order.description} />
        <CardInfo title="Email do solicitante" info={orderOwner.email} />
        {orderOwner.phone && (
          <CardInfo title="Telefone do solicitante" info={orderOwner.phone} />
        )}
      </Stack>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Fechar</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
