import { Flex, Text } from '@chakra-ui/react';

interface IEventFilterSwitchButtonProps {
  whatTypeEventIsActive: string;
  handleChangeTypeOfEvents: (e: string) => void;
}

export const EventFilterSwitchButton: React.FC<IEventFilterSwitchButtonProps> =
  ({ whatTypeEventIsActive, handleChangeTypeOfEvents }) => {
    return (
      <Flex>
        <Text
          as="span"
          px={6}
          background={
            whatTypeEventIsActive === 'valid'
              ? 'background.100'
              : 'background.500'
          }
          color={whatTypeEventIsActive === 'invalid' ? 'gray' : ' white'}
          borderTopLeftRadius="10px"
          borderBottomLeftRadius="10px"
          fontSize="sm"
          onClick={() => handleChangeTypeOfEvents('valid')}
          cursor="pointer"
        >
          Eventos atuais
        </Text>

        <Text
          as="span"
          px={6}
          background={
            whatTypeEventIsActive === 'invalid'
              ? 'background.100'
              : 'background.500'
          }
          color={whatTypeEventIsActive === 'invalid' ? 'white' : 'gray'}
          borderTopRightRadius="10px"
          borderBottomRightRadius="10px"
          fontSize="sm"
          onClick={() => handleChangeTypeOfEvents('invalid')}
          cursor="pointer"
        >
          Eventos passados
        </Text>
      </Flex>
    );
  };
