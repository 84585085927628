import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Flex,
  Text,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

import { GoCalendar } from 'react-icons/go';

import {
  FcConferenceCall,
  FcMoneyTransfer,
  FcCurrencyExchange,
  FcApproval,
} from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';

import { InfoTitle } from 'pages/MyClients/components/elements';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BaseModalFormCard } from 'components/elements/cards';
import { errorHandler } from 'utils';
import { Reminder } from 'pages/MyClients/interfaces';
import { formatToDate, formatToDateTime } from 'brazilian-values';
import { MdInsertInvitation } from 'react-icons/md';
import { ContactFrequencyGuidelineModal } from '../../myClients/ContactFrequencyGuidelineModal/ContactFrequencyGuidelineModal';

interface NewActionsSectionProps {
  clientId: string;
  signalToUpdate: boolean;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  basicData: {
    label: string;
    value: string;
  }[];
  frequencyM?: string;
  handleChangeStatusMeetingSection: () => void;
  setInstantReminder: React.Dispatch<React.SetStateAction<Reminder>>;
  onlyVizMode: boolean;
}
export const NewActionsSection: React.FC<NewActionsSectionProps> = ({
  clientId,
  signalToUpdate,
  setSignalToUpdate,
  basicData,
  frequencyM,
  handleChangeStatusMeetingSection,
  setInstantReminder,
  onlyVizMode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [actionDate, setActionDate] = useState('');
  const [actionTitle, setActionTitle] = useState('');
  const [actionDescription, setActionDescription] = useState('');
  const [days, setDays] = useState(
    frequencyM ? frequencyM.split(' | ')[0] : ''
  );
  const [lastContact, setLastContact] = useState({} as Reminder);
  const date = new Date();
  const [dateNextMeet, setDateNextMeet] = useState<Date>(date);
  const [
    showContactFrequencyGuidelineModal,
    setShowContactFrequencyGuidelineModal,
  ] = useState(false);

  const defaultActions = [
    {
      type: 'Frequência de contato',
    },
    // {
    //   type: 'Resgate',
    // },
    // {
    //   type: 'TED',
    // },
    // {
    //   type: 'Reunião',
    // },
    {
      type: 'Agendar reunião',
    },
    {
      type: 'Reunião instantânea',
    },
  ];

  const handleConfirmModal = () => {
    setShowModal(false);
    setShowModalConfirm(!showModalConfirm);
  };

  const handleGetLastContact = async () => {
    const response: Reminder[] = await hubApiUser.getAnotationsMessage(
      clientId
    );

    if (response.length > 0) {
      let firstContact = response[0];

      response.map(item => {
        if (item.realized && item.updated_at > firstContact.updated_at) {
          firstContact = item;
        }
        return firstContact;
      });
      setLastContact(firstContact);
    }
  };

  useEffect(() => {
    handleGetLastContact();
  }, []);

  const saveActionReminder = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (modalTitle === 'Frequência de contato') {
      if (days.trim() === '')
        return toast.dark('Selecione a quantidade de dias');

      if (lastContact.updated_at) {
        const DateOfLastContact = new Date(lastContact.updated_at);

        const result = DateOfLastContact.setDate(
          DateOfLastContact.getDate() + Number(days)
        );

        setDateNextMeet(new Date(result));
      } else {
        const result = date.setDate(date.getDate() + Number(days));

        setDateNextMeet(new Date(result));
      }

      try {
        await hubApiUser.patchFrequencyContact({
          id: clientId,
          frequencyM: `${days} | ${new Date().toISOString()}`,
        });

        setSignalToUpdate(!signalToUpdate);
        setShowModalConfirm(true);
      } catch (err) {
        errorHandler(err);
        setShowModal(false);
      }
    } else {
      if (actionDate === '')
        return toast.dark('Selecione uma data e um horário');
      if (actionTitle === '')
        return toast.dark('Defina um título para o lembrete');
      if (actionDescription === '')
        return toast.dark('Escreva uma breve descrição da tarefa');

      const reminderContent = {
        title: actionTitle,
        clientId,
        type: actionTitle.toUpperCase(),
        msg: actionDescription,
        dataRetorno: actionDate,
      };

      try {
        await hubApiUser.postAnotationMessage(reminderContent);

        toast.dark('Lembrete criado com sucesso!');
        setShowModal(false);
        setSignalToUpdate(!signalToUpdate);
      } catch (error) {
        toast.dark(
          'Não foi possível criar seu lembrete, tente novamente em alguns minutos'
        );
      }
    }
  };

  const handleShowModal = (action: string) => {
    if (onlyVizMode) return;
    setShowModal(true);
    setModalTitle(action);
  };

  const handleCreateInstantMeet = async () => {
    if (onlyVizMode) return;
    try {
      const instantMeeting = {
        title: 'Reunião instantânea',
        clientId,
        type: 'Reunião instantânea',
        msg: ' ',
        dataRetorno: new Date().toISOString(),
      };

      const response = await hubApiUser.postAnotationMessage(instantMeeting);

      Object.assign(response, { realized: false });

      setInstantReminder(response);
      toast.dark('Reunião instantânea criada com sucesso!');
      setSignalToUpdate(!signalToUpdate);
      handleChangeStatusMeetingSection();
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <Box
        pt="5px"
        maxHeight={'25%'}
        height={'25%'}
        width={['98%', '100%']}
        align="center"
        justify="center"
      >
        <InfoTitle title="Adicionar nova tarefa" />

        <Flex gap="1.5" mt={4} w="100%" align="center" justify="center">
          {defaultActions.map(action => (
            <Flex
              key={action.type}
              background="background.400"
              _hover={{ background: 'background.500' }}
              border="1px"
              borderColor="background.600"
              borderRadius={4}
              w={action.type === 'Frequência de contato' ? '44%' : '40%'}
              h="90px"
              flexDir="column"
              alignItems="flex-start"
              justifyContent="center"
              p={1}
              gap={1}
              cursor="pointer"
              transition="400ms"
              onClick={() => {
                if (action.type === 'Reunião instantânea') {
                  return handleCreateInstantMeet();
                }

                handleShowModal(action.type);
              }}
            >
              {action.type === 'Resgate' && <FcCurrencyExchange size={20} />}

              {action.type === 'TED' && <FcMoneyTransfer size={20} />}

              {action.type === 'Reunião' && <FcConferenceCall size={20} />}

              {action.type === 'Agendar reunião' && (
                <Flex
                  justifyContent="space-evenly"
                  alignItems={'center'}
                  width={'100%'}
                  height={'100%'}
                >
                  <GoCalendar size={20} color="white" />
                  <Text fontSize="xs" fontWeight="semibold">
                    {action.type}
                  </Text>
                </Flex>
              )}

              {action.type === 'Reunião instantânea' && (
                <Flex
                  justifyContent="space-evenly"
                  alignItems={'center'}
                  width={'100%'}
                  height={'100%'}
                >
                  <MdInsertInvitation size={20} color="white" />
                  <Text fontSize="xs" fontWeight="semibold">
                    {action.type}
                  </Text>
                </Flex>
              )}

              {action.type === 'Frequência de contato' && (
                <>
                  <Flex
                    justifyContent="center"
                    alignItems={'center'}
                    width={'100%'}
                    height={'100%'}
                  >
                    <Flex
                      height="100%"
                      width={'15%'}
                      alignItems={'center'}
                      justifyContent="center"
                    >
                      <FcApproval size={20} color="white" />
                    </Flex>
                    <Flex
                      width={'85%'}
                      height={'100%'}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems={'center'}
                    >
                      <Text fontSize="xs" fontWeight="semibold">
                        {action.type}
                      </Text>

                      <Text fontSize="xs">
                        <Text fontWeight="bold">
                          {' '}
                          {/* Frequência cadastrada: */}
                          <Text as="span" fontWeight="normal">
                            {' '}
                            a cada {frequencyM?.split(' | ')[0] || 180} dias
                          </Text>{' '}
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          ))}
        </Flex>
      </Box>

      <ModalWrapper isOpen={showModalConfirm} onClose={handleConfirmModal}>
        <BaseModalFormCard
          isModal
          title="Parabéns!"
          isLoading={false}
          handleFormSubmit={e => {
            e.preventDefault();
            handleConfirmModal();
          }}
          handleToggleModal={handleConfirmModal}
          primaryButtonText="Combinado!"
          hideSecondaryButton
          maxW="540px"
        >
          <Flex
            w="100%"
            align="center"
            textAlign="center"
            flexDir="column"
            gridGap={4}
            justify="center"
          >
            <Text fontWeight="semibold" color="whiteAlpha.800">
              Frequência de contato cadastrada com sucesso!
            </Text>
            {lastContact.updated_at ? (
              <Text>
                Identificamos que o seu último contato foi feito dia{' '}
                {formatToDateTime(new Date(lastContact.updated_at))}, portanto
                seu próximo contato será dia {formatToDate(dateNextMeet)}.
              </Text>
            ) : (
              <Text>
                Seu próximo contato será dia {formatToDate(dateNextMeet)}.
              </Text>
            )}
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>
      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(false)}>
        {modalTitle === 'Frequência de contato' ? (
          <>
            <BaseModalFormCard
              isModal
              title="Atualizar Frequência de Contato"
              isLoading={false}
              handleFormSubmit={saveActionReminder}
              handleToggleModal={() => setShowModal(false)}
              primaryButtonText="Salvar"
              maxW={['95vw', '580px']}
            >
              <Flex w="100%" align="center" flexDir="column" justify="center">
                <Text fontWeight="semibold" color="whiteAlpha.800">
                  Desejo que o cliente {basicData[0].value} tenha 01 contato a
                  cada
                </Text>

                <NumberInput
                  my="3"
                  min={0}
                  defaultValue={days}
                  onChange={valueString => setDays(valueString)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <Text>(Coloque a quantidade de dias acima)</Text>
              </Flex>
              <Text
                position="absolute"
                bottom={5}
                left={5}
                color="primary.100"
                fontSize="sm"
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => setShowContactFrequencyGuidelineModal(true)}
              >
                Ver diretrizes de frequência
              </Text>
            </BaseModalFormCard>
          </>
        ) : (
          <>
            <BaseModalFormCard
              isModal
              title={`Nova tarefa - ${modalTitle}`}
              isLoading={false}
              handleFormSubmit={saveActionReminder}
              handleToggleModal={() => setShowModal(false)}
              primaryButtonText="Criar tarefa"
              maxW={['95vw', 'auto']}
            >
              <Flex flexDir="column" mb={4}>
                <Text fontSize="sm" mb={1} color="white">
                  Selecione a data e o horário da atividade:
                </Text>
                <DateAndHourPicker onChange={setActionDate} />
              </Flex>
              <InputWithLabel
                mb={4}
                label="Título da tarefa:"
                maxLength={30}
                onChange={e => setActionTitle(e.target.value)}
              />
              <TextareaWithLabel
                rows={5}
                label="Descrição da tarefa:"
                onChange={e => setActionDescription(e.target.value)}
              />
            </BaseModalFormCard>
          </>
        )}
      </ModalWrapper>

      <ModalWrapper
        isOpen={showContactFrequencyGuidelineModal}
        onClose={() => setShowContactFrequencyGuidelineModal(false)}
      >
        <ContactFrequencyGuidelineModal
          isInMainPage={false}
          closeModal={setShowContactFrequencyGuidelineModal}
        />
      </ModalWrapper>
    </>
  );
};
