import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { CreateAndUpdateOfferModal } from 'pages/Offers/components';
import { useEffect, useState } from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { AllEventsTable } from './components/AllEventsTable';
import { Header } from './components/Header';
import { CreateAndUpdateEventModal } from './components/modals/CreateAndUpdateEventModal';
import { IEventExtended } from './interfaces/Event';

export const Events: React.FC = () => {
  const [whatTypeEventIsActive, setWhatTypeEventIsActive] = useState('valid');
  const [allEvents, setAllEvents] = useState<IEventExtended[]>([]);
  const [eventsFiltered, setEventsFiltered] = useState<IEventExtended[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    getAllEvents();
  }, []);
  useEffect(() => {
    handleFilterEvents();
  }, [whatTypeEventIsActive, allEvents]);

  const handleChangeTypeOfEvents = (e: string) => {
    setWhatTypeEventIsActive(e);
  };

  const handleFilterEvents = () => {
    if (whatTypeEventIsActive === 'valid') {
      setEventsFiltered(
        allEvents.filter(item => {
          return !isEventExpired(item.eventDateAndHour);
        })
      );
    } else {
      setEventsFiltered(
        allEvents.filter(item => {
          return isEventExpired(item.eventDateAndHour);
        })
      );
    }
  };

  const isEventExpired = (date: string) => {
    const today = new Date().toISOString();
    if (today > date) {
      return true;
    }
    return false;
  };

  const getAllEvents = async () => {
    try {
      const response = await hubApi.getAllEvents();
      setAllEvents(response);
    } catch (error) {
      toast.dark('Não foi possível mostrar todos os eventos, tente novamente.');
    }
  };

  return (
    <>
      <PageWrapper flexDir="column" p="5" w="100vw">
        <Flex flexDir="column" w="100%" maxW="1080px" mx="auto">
          <MainPageTitle textAlign="center" mt={6} mb={2} color="background.50">
            Eventos
          </MainPageTitle>

          <Header
            handleChangeTypeOfEvents={handleChangeTypeOfEvents}
            whatTypeEventIsActive={whatTypeEventIsActive}
            setShowCreateEventModal={setShowCreateModal}
          />
          <AllEventsTable
            allEvents={eventsFiltered}
            isEventExpired={isEventExpired}
            setAllEvents={setAllEvents}
            whatTypeEventIsActive={whatTypeEventIsActive}
          />
        </Flex>
      </PageWrapper>
      <ModalWrapper
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      >
        <CreateAndUpdateEventModal
          allEvents={allEvents}
          setAllEvents={setAllEvents}
          onclose={() => setShowCreateModal(false)}
        />
      </ModalWrapper>
    </>
  );
};
