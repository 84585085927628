/*   @typescript-eslint/ban-ts-comment: "off" */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MdAdd } from 'react-icons/md';

import { InputWithLabel } from 'components/elements/forms';

import { Flex, Stack, Text } from '@chakra-ui/react';
import hubApi from 'services/hubApi';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { BaseModalFormCard } from 'components/elements/cards';
import { SpecificOrderInputRow } from '../../SpecificOrderInputRow';
import { handleOnBlurClient } from '../../../../utils';

export const SpecificOrderForm = ({ handleShowModal, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    name: '',
    email: '',
    btgAccount: '',
  });
  const [formInfos, setFormInfos] = useState({
    clientId: '',
    sheres: [
      {
        id: 1,
        ticker: '',
        financial: '',
        type: '',
        delAbled: false,
      },
    ],
  });

  const handleOnBlurCode = async () => {
    handleOnBlurClient(
      selectedClient.btgAccount,
      setSelectedClient,
      setFormInfos,
      formInfos
    );
  };

  const handleAddOrder = () => {
    const newOrderId = formInfos.sheres[formInfos.sheres.length - 1].id + 1;
    const newOrder = {
      id: newOrderId,
      ticker: '',
      financial: '',
      type: '',
      delAbled: true,
    };

    setFormInfos({ ...formInfos, sheres: [...formInfos.sheres, newOrder] });
  };

  const handleDeleteOrder = removedOrderId => {
    const newOrdersArray = formInfos.sheres.filter(
      order => order.id !== removedOrderId
    );

    setFormInfos({ ...formInfos, sheres: [...newOrdersArray] });
  };

  const handleChangeClientValues = e => {
    setSelectedClient({
      ...selectedClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeRowValues = (e, orderIndex) => {
    if (e.target.name === `type_${orderIndex}`) {
      formInfos.sheres[orderIndex].type = e.target.value;
    }

    formInfos.sheres[orderIndex][e.target.name] = e.target.value;
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);

    if (formInfos.clientId === '') {
      setIsLoading(false);
      return toast.dark('Informe o código do cliente');
    }
    if (
      formInfos.sheres[0].ticker === '' ||
      formInfos.sheres[0].financial === ''
    ) {
      setIsLoading(false);
      return toast.dark('Preencha ao menos todos os campos de um ticker');
    }

    const segment = title.includes('Fundos Imobiliários')
      ? title.replace('Fundos Imobiliários', 'FII')
      : title;

    const newArray = {
      ...formInfos,
      sheres: formInfos.sheres.map(item => {
        return {
          ticker: item.ticker,
          financial: item.financial,
          type: item.type,
        };
      }),
      segment,
    };

    await hubApi
      .searchForClientCode(selectedClient.btgAccount)
      .then(async () => {
        await hubApiRv
          .postSpecificOrders(newArray)
          .then(() => {
            setIsLoading(false);
            toast.dark('Ordem enviada com sucesso');
            handleShowModal();
          })
          .catch(() => {
            setIsLoading(false);
            toast.dark(
              'Ocorreu um erro ao tentar enviar ordem. Tente novamente'
            );
          });
      })
      .catch(() => {
        setIsLoading(false);
        toast.dark('Cliente não encontrado.Verifique o código informado.');
      });
  };

  return (
    <BaseModalFormCard
      isModal
      maxW="440px"
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
    >
      <Stack spacing="3">
        <InputWithLabel
          label="Cod. cliente:"
          placeholder="Código do cliente"
          name="btgAccount"
          onChange={handleChangeClientValues}
          onBlur={handleOnBlurCode}
        />
        <InputWithLabel
          label="Nome do Cliente:"
          type="text"
          placeholder="Nome do cliente"
          name="name"
          value={selectedClient.name}
          isDisabled
        />
      </Stack>

      <Flex
        mt="4"
        flexDir="column"
        maxH="220px"
        overflowY="auto"
        overflowX="hidden"
      >
        {formInfos.sheres.map((order, index) => (
          <SpecificOrderInputRow
            key={index}
            index={index}
            order={order}
            handleDeleteOrder={handleDeleteOrder}
            handleChange={handleChangeRowValues}
          />
        ))}
      </Flex>

      <Flex
        cursor="pointer"
        alignItems="center"
        mt="2"
        onClick={handleAddOrder}
      >
        <MdAdd size={20} color="white" />
        <Text color="white" alignItems="center" fontSize="small">
          Adicionar
        </Text>
      </Flex>
    </BaseModalFormCard>
  );
};
