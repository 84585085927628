import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadActionType } from 'sharedV2/types/leadAction';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useUpdateLeadAction = (
  data?: PropsType
): UseMutationResult<void, unknown, LeadActionType, unknown> => {
  return useMutation({
    mutationKey: ['updateLeadAction'],
    mutationFn: async (action: LeadActionType) => {
      await hubApiCaptation.updateLeadAction(action);
    },
    onSuccess: data?.onSuccess,
    onError: data?.onError,
  });
};
