import { ResponsiveLine } from '@nivo/line';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface TopSectionGraphProps {
  data: any[];
  employeesColors: {
    employeeName: string;
    color: string;
  }[];
}

export const TopSectionGraphic: React.FC<TopSectionGraphProps> = ({
  data,
  employeesColors,
}) => {
  const graphicData = employeesColors?.map(item => {
    return {
      id: formatNameToCaptalize(item.employeeName),
      color: item.color,
      data: data?.map(month => {
        return {
          x: month.date.split('T')[0],
          y:
            Number(
              month?.employees?.find(
                (employee: any) =>
                  employee.employeeName.toLowerCase() ===
                  item.employeeName.toLowerCase()
              )?.pl
            ) || 0,
        };
      }) || [{ x: '2021-10-31', y: 0 }],
    };
  });

  const color = graphicData.map(item => {
    return item.color;
  });

  return (
    <ResponsiveLine
      data={graphicData}
      margin={{ top: 18, right: 250, bottom: 60, left: 80 }}
      colors={color}
      animate
      enableArea
      areaOpacity={0.1}
      enablePoints
      enableSlices="x"
      pointColor="white"
      useMesh
      areaBaselineValue={8}
      xFormat="time:%Y-%m-%d"
      xScale={{
        type: 'time',
        precision: 'month',
        useUTC: false,
        format: '%Y-%m-%d',
      }}
      yFormat=">-.2s"
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 130,
          translateY: 20,
          itemsSpacing: 2,
          itemTextColor: '#fff',
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'square',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      axisBottom={{
        format: '%b %Y',
        tickValues: 'every 1 month',
        tickPadding: 30,
        legendOffset: -12,
        tickSize: 10,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 30,
        tickRotation: 0,
        legendOffset: 10,
        format: '>-.2s',
        legendPosition: 'end',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      lineWidth={3}
      theme={{
        textColor: '#ffffff',
        fontSize: 12,
        crosshair: {
          line: {
            strokeWidth: 2,
            stroke: '#ffffff',
            strokeOpacity: 1,
          },
        },
        grid: {
          line: {
            stroke: '#2b2929',
            strokeWidth: 1,
          },
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: '#d3d3d3',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong>{point.serieId}:</strong> {point.data.yFormatted}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
