import { Flex, Text } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { PencilSimpleLine } from 'phosphor-react';
import { formatMibs } from 'utils/formatMibs';
import { transformMibsIntoReais } from '../utils/TransformMibsIntoReais';

interface IProps {
  mibs?: number;
  walletName: string;
  isLoading?: boolean;
  isManager?: boolean;
  isFullBox?: boolean;
  showReaisValue?: boolean;

  setIsOpenEditMibsModal?(value: boolean): void;
}

export const WalletInfoBox: React.FC<IProps> = ({
  mibs,
  walletName,
  isLoading,
  isManager,
  setIsOpenEditMibsModal,
  isFullBox,
  showReaisValue,
}) => {
  return (
    <Flex
      w={isFullBox ? '100%' : '50%'}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      bgColor="white"
      border="1px solid #1d63ddb2"
      p={5}
      borderRadius={5}
    >
      <Text fontSize="md" fontStyle="italic" color="rgba(70, 70, 70, 1)">
        {walletName}
      </Text>
      {isLoading ? (
        <Flex h="100px" justifyContent="center" alignItems="center">
          <LoadingSpinner size="lg" />
        </Flex>
      ) : (
        <Flex alignItems="center" w="80%" mb={5} flexDirection="column" mt={1}>
          <Flex
            alignItems="center"
            w="100%"
            justifyContent="center"
            paddingLeft={walletName === 'Carteira EWZ' && isManager ? 12 : 0}
          >
            <Text
              fontSize="2xl"
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
              textAlign="center"
            >
              {mibs && formatMibs(mibs)} MIBs
            </Text>
            {walletName === 'Carteira EWZ' && isManager && (
              <Flex paddingLeft={5} _hover={{ color: 'primary.700' }}>
                <PencilSimpleLine
                  size={32}
                  cursor="pointer"
                  onClick={() =>
                    setIsOpenEditMibsModal && setIsOpenEditMibsModal(true)
                  }
                  color="rgba(70, 70, 70, 1)"
                />
              </Flex>
            )}
          </Flex>

          {showReaisValue && (
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)" fontWeight="bold">
              R$ {mibs && transformMibsIntoReais(mibs)}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};
