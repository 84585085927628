import { Text, Flex } from '@chakra-ui/react';
import { FaArrowDown } from 'react-icons/fa';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton } from 'components/elements/buttons';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { TableData, TableRow, TableBase } from 'components/elements/table';

interface ContactFrequencyGuidelineModalProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  isInMainPage: boolean;
}

export const ContactFrequencyGuidelineModal: React.FC<ContactFrequencyGuidelineModalProps> =
  ({ closeModal, isInMainPage }) => {
    return (
      <DefaultModalContentWrapper>
        <ModalTitle textAlign="center">
          Diretrizes de contato com o cliente
        </ModalTitle>

        <Text mb={3}>
          Para melhorar a experiência de nossos clientes, temos que entrar em
          contato com eles com uma certa frequência. Estabelecemos que a
          frequência ideal será sempre baseada no <i>patrimônio líquido</i> do
          cliente. Por exemplo, se um cliente tem um patrimônio líquido de até
          100 mil reais, então você deve entrar em contato com ele a cada 180
          dias em média (ver tabela abaixo).
        </Text>

        {isInMainPage && (
          <>
            <Text>
              O percentual exibido na tela principal indica o quanto você está
              perto da meta de contato (100%) levando em conta o conjunto de
              seus clientes. Assim, se o seu percentual estiver abaixo de 100,
              significa que existem clientes em sua base que não estão recebendo
              contato de acordo com as nossas diretrizes. Para resolver isto,
              redefina a frequência de contato dos clientes com a seta vermelha
              em seus cards
            </Text>
            <Flex>
              (
              <Flex mt={1}>
                <FaArrowDown color="red" />
              </Flex>
              ) na página principal de Meus Clientes.
            </Flex>
          </>
        )}

        <TableBase
          headData={['Patrimônio Líquido', 'Frequência ideal']}
          width="100%"
          p="0"
          mt={4}
          mx="auto"
        >
          <TableRow background="background.500">
            <TableData>0 a 100 mil</TableData>
            <TableData>180 dias</TableData>
          </TableRow>
          <TableRow background="background.500">
            <TableData>100 a 300 mil</TableData>
            <TableData>90 dias</TableData>
          </TableRow>
          <TableRow background="background.500">
            <TableData>300 mil a 1MM</TableData>
            <TableData>60 dias</TableData>
          </TableRow>
          <TableRow background="background.500">
            <TableData>1MM +</TableData>
            <TableData>30 dias</TableData>
          </TableRow>
        </TableBase>

        <PrimaryButton
          w="20%"
          mt={4}
          ml="auto"
          onClick={() => closeModal(false)}
        >
          OK
        </PrimaryButton>
      </DefaultModalContentWrapper>
    );
  };
