import { CheckIcon, EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

interface EditableValueInputProps {
  defaultValue: string;
  updateItemValue: (velueItem: string) => void;
}

export const EditableValueInput: React.FC<EditableValueInputProps> = ({
  defaultValue = '0',
  updateItemValue,
}) => {
  const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue);
  const refInput = useRef<HTMLInputElement>(null);

  const isEdited = currentDefaultValue !== defaultValue;

  const handleChangeMoneyValue = (e: string) => {
    setCurrentDefaultValue(e);
  };

  return (
    <DisplayInformation
      label="Valor do Bem"
      info={
        <Flex
          justify="space-between"
          align="center"
          wrap="nowrap"
          gap={2}
          pl={2}
        >
          <CurrencyInput
            allowNegativeValue={false}
            decimalsLimit={2}
            defaultValue={currentDefaultValue || 0}
            intlConfig={{ locale: 'pt-br', currency: 'BRL' }}
            onValueChange={e => {
              if (e) handleChangeMoneyValue(e);
            }}
            ref={refInput}
            className="inputValorDoBem"
          />
          {isEdited ? (
            <SecondaryButton
              onClick={() => updateItemValue(currentDefaultValue)}
              size="xs"
              fontWeight="normal"
            >
              <Flex align="center" px={2} gap={1}>
                Salvar
                <CheckIcon color="white" />
              </Flex>
            </SecondaryButton>
          ) : (
            <SecondaryButton
              onClick={() => refInput.current?.focus()}
              size="xs"
              fontWeight="normal"
            >
              <Flex align="center" px={2} gap={1}>
                Alterar
                <EditIcon color="white" />
              </Flex>
            </SecondaryButton>
          )}
        </Flex>
      }
    />
  );
};
