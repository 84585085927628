import { useEffect, useState } from 'react';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { AllOrdersProps, IFilterUsersProps } from 'pages/IP/interfaces';

export const useSegmentDataAndEmployees = () => {
  const [noTransferedOrders, setNoTransferedOrders] = useState(0);

  const [health, setHealth] = useState({
    title: 'Saúde',
    segment: 'saude',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Em Processo',
        value: 0,
        status: 'inProcess',
      },
      {
        title: 'Dados Solicitados',
        value: 0,
        status: 'dataSolicited',
      },
      {
        title: 'Fazendo Estudo',
        value: 0,
        status: 'studying',
      },
      {
        title: 'Proposta Enviada',
        value: 0,
        status: 'sending',
      },
      {
        title: 'Boletado Hoje',
        value: 0,
        status: 'boletedToday',
      },
      {
        title: 'Fechado / Cancelado',
        value: 0,
        status: 'canceledToday',
      },
    ],
  });

  const [cred, setCred] = useState({
    title: 'Alavancagem Patrimonial',
    segment: 'imobiliario',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Call marcada ',
        value: 0,
        status: 'callMar',
      },
      {
        title: 'Apresentação de proposta',
        value: 0,
        status: 'studying',
      },
      {
        title: 'Fechamento',
        value: 0,
        status: 'waiting',
      },
      {
        title: 'Emissão',
        value: 0,
        status: 'close1',
      },
      {
        title: 'Pós Venda',
        value: 0,
        status: 'close2',
      },
      {
        title: 'Fechado / Cancelado',
        value: 0,
        status: 'canceledToday',
      },
    ],
  });

  const [life, setLife] = useState({
    title: 'Vida',
    segment: 'vida',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Tentativa de Contato ',
        value: 0,
        status: 'attemptContact',
      },
      {
        title: 'Call marcada ',
        value: 0,
        status: 'callMar',
      },
      {
        title: 'Fazendo estudo',
        value: 0,
        status: 'studying',
      },
      {
        title: 'Aguardando Apresentação',
        value: 0,
        status: 'waitingPresentation',
      },
      {
        title: 'Apresentação Realizada',
        value: 0,
        status: 'donePresentation',
      },
      {
        title: 'Fechado / Cancelado',
        value: 0,
        status: 'canceledToday',
      },
    ],
  });

  const [agro, setAgro] = useState({
    title: 'Agro',
    segment: 'agro',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Call marcada',
        value: 0,
        status: 'scheduledCall',
      },
      {
        title: 'Call realizada',
        value: 0,
        status: 'doneCall',
      },
      {
        title: 'Ag. documentos',
        value: 0,
        status: 'waitingDocuments',
      },
      {
        title: 'Validação',
        value: 0,
        status: 'validation',
      },
      {
        title: 'Fechamento',
        value: 0,
        status: 'closing',
      },
      {
        title: 'Boletado',
        value: 0,
        status: 'boleted',
      },
    ],
  });

  const [corporate, setCorporate] = useState({
    title: 'Corporate',
    segment: 'corporate',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Call marcada',
        value: 0,
        status: 'scheduledCall',
      },
      {
        title: 'Call realizada',
        value: 0,
        status: 'doneCall',
      },
      {
        title: 'Ag. documentos',
        value: 0,
        status: 'waitingDocuments',
      },
      {
        title: 'Validação',
        value: 0,
        status: 'validation',
      },
      {
        title: 'Fechamento',
        value: 0,
        status: 'closing',
      },
      {
        title: 'Boletado',
        value: 0,
        status: 'boleted',
      },
    ],
  });

  const [altInsurances, setAltInsurances] = useState({
    title: 'Seguros Alternativos',
    segment: 'seguros alternativos',
    allowedEmployees: [] as IFilterUsersProps[],
    options: [
      {
        title: 'Sem contato',
        value: 0,
        status: 'noContact',
      },
      {
        title: 'Call marcada',
        value: 0,
        status: 'scheduledCall',
      },
      {
        title: 'Call realizada',
        value: 0,
        status: 'doneCall',
      },
      {
        title: 'Ag. documentos',
        value: 0,
        status: 'waitingDocuments',
      },
      {
        title: 'Validação',
        value: 0,
        status: 'validation',
      },
      {
        title: 'Fechamento',
        value: 0,
        status: 'closing',
      },
      {
        title: 'Boletado',
        value: 0,
        status: 'boleted',
      },
    ],
  });

  useEffect(() => {
    hubApiIp.listAllInfosManager().then((response: AllOrdersProps) => {
      setNoTransferedOrders(Number(response.noTransfered));

      setLife({
        ...life,
        allowedEmployees: response.employeeLife,
        options: life.options.map(item => {
          return {
            ...item,
            value: Number(response.life[item.status]),
          };
        }),
      });

      setHealth({
        ...health,
        allowedEmployees: response.employeeHealth,
        options: health.options.map(item => {
          return {
            ...item,
            value: Number(response.health[item.status]),
          };
        }),
      });

      setCred({
        ...cred,
        allowedEmployees: response.employeeImob,
        options: cred.options.map(item => {
          return {
            ...item,
            value: Number(response.imob[item.status]),
          };
        }),
      });

      setAgro({
        ...agro,
        allowedEmployees: response.employeeAgro,
        options: agro.options.map(item => {
          return {
            ...item,
            value: Number(response.agro[item.status]),
          };
        }),
      });

      setCorporate({
        ...corporate,
        allowedEmployees: response.employeeCorporate,
        options: corporate.options.map(item => {
          return {
            ...item,
            value: Number(response.corporate[item.status]),
          };
        }),
      });

      setAltInsurances({
        ...altInsurances,
        allowedEmployees: response.employeeAlternative,
        options: altInsurances.options.map(item => {
          return {
            ...item,
            value: Number(response.alternative[item.status]),
          };
        }),
      });
    });
  }, []);

  return {
    health,
    cred,
    life,
    agro,
    corporate,
    altInsurances,
    noTransferedOrders,
  };
};
