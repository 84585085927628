import React, { useEffect, useState } from 'react';

import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { Box } from '@chakra-ui/react';
import { listUserOrders } from 'pages/IP/utils';
import { ipSegments } from 'pages/IP/data';
import Board from '../../components/modules/kanbanBase/Board';

import { lifeColumns } from './data';

export const LifeKanban: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(lifeColumns);

  useEffect(() => {
    async function listOrders() {
      await listUserOrders(ipSegments.life, columns);
      setIsLoading(false);
    }

    listOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Box overflowY="auto">
          <Board
            boardTitle="CRM - IP Vida"
            boardColumns={columns}
            setBoardColumns={setColumns}
          />
        </Box>
      )}
    </PageWrapper>
  );
};
