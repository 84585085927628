import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const ModalTitle: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      fontSize={{ base: 'md', lg: 'lg' }}
      mb="4"
      fontWeight="semibold"
      color="rgba(70, 70, 70, 1)"
      {...rest}
    >
      {children}
    </Text>
  );
};
