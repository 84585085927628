import { LeadPJType } from 'sharedV2/types/leadPJ';
import { LeadParsedType } from 'sharedV2/types/leadTypes';
import { Mapper } from 'utilsV2/mapper';

type UseLeadPJMapperResponseType = {
  mapper: Mapper<LeadPJType, LeadParsedType>;
};

export const useLeadPJMapper = (): UseLeadPJMapperResponseType => {
  const toUseMapper = (lead: LeadPJType): LeadParsedType => {
    return {
      id: lead.id,
      email: lead?.email ? lead?.email[0]?.email : '',
      created_at: lead.created_at,
      employeeId: lead.employeeId,
      frequency: 0,
      meetingPrice: 0,
      name: lead.nome,
      razaoSocial: lead.razaoSocial,
      origin: 'PJ',
      phone: lead.phone[0].phone,
      phones: lead.phone.map(item => item.phone),
      pipeValue: Number(lead.pipeValue?.split(',')[0]),
      plMundoInvest: 0,
      plSalesHunter: 0,
      price: 0,
      status: lead.status,
      updated_at: lead.updated_at,
      meeting: lead.meetings[0],
    };
  };

  return {
    mapper: new Mapper(toUseMapper, null),
  };
};
