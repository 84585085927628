export const SharedContentTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.654v2.885a.578.578 0 01-.577.576H1.577A.577.577 0 011 12.54V3.308a.577.577 0 01.577-.577h2.308M7.346 13.115L6.192 16M9.654 13.115L10.808 16M5.038 16h6.923"
        stroke="#1D63DD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77 6.77h3.46v2.307l3.462-2.308H16V1H6.77v5.77z"
        stroke="#1D63DD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
