import * as yup from 'yup';

export const contractSchema = yup.object().shape({
  officeId: yup.string().required('Escolha a filial do colaborador.'),
  tableId: yup.string().required('Escolha a mesa do colaborador.'),
  btgCode: yup.string().notRequired(),
  salary: yup.string().required('Defina o salário do colaborador.'),
  salaryOption: yup.string().required('Escolha a tipo do salário.'),
  RV: yup
    .string()
    .max(3, 'Os valores de repassem devem ser entre 0 e 100')
    .notRequired()
    .nullable(),
  guru: yup
    .string()
    .max(3, 'Os valores de repassem devem ser entre 0 e 100')
    .notRequired()
    .nullable(),
  accessory: yup
    .string()
    .max(3, 'Os valores de repassem devem ser entre 0 e 100')
    .notRequired()
    .nullable(),
  intelligency: yup
    .string()
    .max(3, 'Os valores de repassem devem ser entre 0 e 100')
    .notRequired()
    .nullable(),
});
