import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IUsersSearh } from 'pages/GenerationalVariables/interfaces';
import { formatToCapitalized } from 'brazilian-values';

interface ICardSearhUserProps {
  listEmployee: IUsersSearh;
  addUser: (employeeSelected: IUsersSearh) => void;
}

export const EmployeeCardForMultiselectionModal: React.FC<ICardSearhUserProps> =
  ({ listEmployee, addUser }) => {
    return (
      <DefaultCardWrapper
        w="100%"
        overflow="auto"
        p="2"
        bg="white"
        border="1px solid #1d63ddb2"
        align="center"
        justify="space-between"
      >
        <Flex flexDir="column">
          <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" fontSize="small">
            {formatToCapitalized(`${listEmployee.name}`, {
              trimTrailingWhiteSpaces: false,
            })}
          </Text>

          <Text fontWeight="bold" fontSize="sm" color="rgba(70, 70, 70, 1)">
            {`${listEmployee.responsibility}`}
          </Text>

          <Text fontWeight="bold" fontSize="sm" color="rgba(70, 70, 70, 1)">
            {listEmployee.email}
          </Text>
        </Flex>

        <Tooltip
          hasArrow
          label="Selecionar usuário"
          placement="top"
          bg="background.200"
          cursor="pointer"
        >
          <Box cursor="pointer" mr="6">
            <IoIosAddCircleOutline
              color="rgba(70, 70, 70, 1)"
              size={20}
              onClick={e => addUser(listEmployee)}
            />
          </Box>
        </Tooltip>
      </DefaultCardWrapper>
    );
  };
