import React, { FormEvent, useState } from 'react';
import { Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { BasicFieldsProps } from 'pages/Sign/Register/interfaces';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import { contractFieldsSchema } from '../schemas';
import { contractModel } from '../data';

interface PersonalFIeldsProps extends BasicFieldsProps {
  handlePrevPage: (progress: number) => void;
  managerId: string;
}

interface IFormInfoContractFields {
  bank: string;
  accountNumber: string;
  agency: string;
  admissionDate: string;
  position: string;
  contractType: string;
  responsibility: string;
}

const ContractFields: React.FC<PersonalFIeldsProps> = ({
  step,
  handleNextPage,
  handlePrevPage,
  setMainForm,
  mainForm,
  // managerId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formInfos, setFormInfos] = useState<IFormInfoContractFields>(
    {} as IFormInfoContractFields
  );

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleChangeContract = (item: string) => {
    setFormInfos({ ...formInfos, contractType: item });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const isValid = await contractFieldsSchema.isValid(formInfos);

      if (!isValid) {
        await contractFieldsSchema.validate(formInfos).catch(err => {
          err.errors.map((error: string) => toast.dark(error));
        });

        return setIsLoading(false);
      }

      if (formInfos.bank !== '' || formInfos.bank !== undefined) {
        if (formInfos.bank.trim() !== '208') {
          setIsLoading(false);
          return toast.dark(
            'Só aceitamos conta no banco BTG. Caso queira prosseguir no processo agora, deixe o espaço da agência, banco e conta em branco.'
          );
        }
      }

      setMainForm({
        ...mainForm,
        admissionDate: `${formInfos.admissionDate}T06:00:00Z`,
        contractType: formInfos.contractType,
        responsibility: formInfos.responsibility
          ? formInfos.responsibility
          : '-',
        bank: {
          bank: formInfos.bank ? formInfos.bank : '111',
          accountNumber: formInfos.accountNumber
            ? formInfos.accountNumber
            : '11111',
          agency: formInfos.agency ? formInfos.agency : '11111',
        },
      });
      setIsLoading(false);
      handleNextPage(100);
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <BaseModalFormCard
      isModal={false}
      title={`Passo ${step} - Contrato`}
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      primaryButtonText="Enviar formulário"
      secondaryButtonText="Anterior"
      handleToggleModal={() => handlePrevPage(50)}
    >
      <Stack width="100%" spacing={4}>
        <Flex width="100%" flexDir={['column', 'row']} gridGap="2">
          <InputWithLabel
            width="100%"
            label="Banco"
            placeholder="Código do banco"
            name="bank"
            value={formInfos.bank}
            onChange={handleChangeValue}
          />
          <InputWithLabel
            width="100%"
            label="Agência"
            placeholder="Número da agência"
            name="agency"
            value={formInfos.agency}
            onChange={handleChangeValue}
          />
        </Flex>
        <InputWithLabel
          label="Conta"
          placeholder="Número da conta corrente"
          name="accountNumber"
          value={formInfos.accountNumber}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Cargo"
          placeholder="Cargo na empresa"
          name="responsibility"
          value={formInfos.responsibility}
          onChange={handleChangeValue}
        />

        <SimpleGrid columns={[1, 2]} spacing={3}>
          <InputWithLabel
            label="Data de admissão"
            name="admissionDate"
            type="date"
            mb="1"
            onChange={handleChangeValue}
          />

          <Dropdown
            mt={['0', '6']}
            placeholder="Modelo de contrato"
            onChange={e => handleChangeContract(e.target.value)}
          >
            {contractModel.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>
        </SimpleGrid>
      </Stack>
    </BaseModalFormCard>
  );
};

export default ContractFields;
