import { HubApi } from '../index';

interface ICreateOrder {
  type: string | undefined;
  status: string;
  name: string;
  phone: string;
  email: string;
  value: string;
  description: string;
  intention: string;
  currency: string;
  targetCurrency: string;
  clientCode: string;
  isClient: boolean;
}

interface IUpdateOrder {
  status?: string | undefined;
  intention?: string;
  description?: string;
  name?: string;
  phone?: string;
  email?: string;
  employeeName?: string;
  btgCode?: string;
  type?: string;
  value?: string;
  spot?: string;
  spread?: string;
  contractValue?: string;
  currency?: string;
  targetCurrency?: string;
  bank?: string;
  employeeId?: string;
  id?: string;
}

class HubApiExchange extends HubApi {
  async createOrder(data: ICreateOrder) {
    const response = await this.axios.post('/exchange', data);
    return response.data;
  }

  async listOrdersByFilter(filterOption: string) {
    const response = await this.axios.get(`/exchange/by/${filterOption}`);
    return response.data;
  }

  async listEmployeesWithPermission() {
    const response = await this.axios.get('/employees/area/EX');
    return response.data;
  }

  async listEmployeeCrm(id: string | undefined) {
    const response = await this.axios.get(`/orders/exchange/crm/${id}`);
    return response.data;
  }

  async listManagementInfos() {
    const response = await this.axios.get('/orders/exchange/managementvalues');
    return response.data;
  }

  async listAvailableBanks() {
    const response = await this.axios.get('/exchange/bank');
    return response.data;
  }

  async searchOrderById(orderId: string) {
    const response = await this.axios.get(`/exchange/${orderId}`);
    return response.data;
  }

  async updateOrder(orderId: string, data: IUpdateOrder) {
    const response = await this.axios.patch(`/exchange/${orderId}`, data);
    return response.data;
  }
}

export const hubApiExchange = new HubApiExchange();
