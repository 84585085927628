import { Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { colors } from 'sharedV2/colors';
import { CommentedAreaType } from '..';

type PropsType = {
  area: CommentedAreaType;
  setCommentedAreas: (value: string[]) => void;
  commentedAreas: string[];
};

export const ClientMeetingDetailsCommentedAreasButton: React.FC<PropsType> = ({
  area,
  commentedAreas,
  setCommentedAreas,
}): JSX.Element => {
  const { getParam } = useQueryParams();

  const isOnlyVisualizationMode =
    getParam('isOnlyVisualizationMode') === 'true';

  const thisAreaAlreadyHaveBeenCommented = commentedAreas.includes(area.label);

  const handleUpdateMeetingCommentedAreas = async (area: string) => {
    const updatedCommentedAreas = thisAreaAlreadyHaveBeenCommented
      ? commentedAreas.filter(commentedArea => commentedArea !== area)
      : [...commentedAreas, area];

    setCommentedAreas(updatedCommentedAreas);
  };

  return (
    <Tooltip text={area.label}>
      <Box.Outline
        cursor={isOnlyVisualizationMode ? 'default' : 'cursor'}
        onClick={() =>
          !isOnlyVisualizationMode &&
          handleUpdateMeetingCommentedAreas(area.label)
        }
        justifyContent="center"
        alignItems="center"
        h="50px"
        backgroundColor={
          thisAreaAlreadyHaveBeenCommented ? colors.BLUE_HOVER : 'transparent'
        }
      >
        {area.icon && (
          <area.icon
            size={20}
            color={
              thisAreaAlreadyHaveBeenCommented ? 'white' : 'rgba(70, 70, 70, 1)'
            }
          />
        )}
        {!area.icon && area.altIcon && (
          <Text
            color={
              thisAreaAlreadyHaveBeenCommented ? 'white' : 'rgba(70, 70, 70, 1)'
            }
            fontWeight="bold"
          >
            {area.altIcon}
          </Text>
        )}
      </Box.Outline>
    </Tooltip>
  );
};
