import { Flex, Text } from '@chakra-ui/react';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { BiAddToQueue } from 'react-icons/bi';
import { FiTrash2 } from 'react-icons/fi';

type PropsType = {
  descriptions: string[];
  handleToggleIsOpenAddPowerBIDescriptionModal: (value: boolean) => void;
  handleRemovePowerBIDescription: (itemToRemove: string) => void;
};

export const PowerBIDescription: React.FC<PropsType> = ({
  descriptions,
  handleToggleIsOpenAddPowerBIDescriptionModal,
  handleRemovePowerBIDescription,
}): JSX.Element => {
  return (
    <Flex flexDir="column" gap={3}>
      <Flex
        gridGap="1.5"
        cursor="pointer"
        transition="300ms"
        alignItems="center"
        onClick={() => handleToggleIsOpenAddPowerBIDescriptionModal(true)}
      >
        <BiAddToQueue size={24} />
        <Text fontSize="sm">Adicionar informações</Text>
      </Flex>
      <Flex
        flexDirection="column"
        gap={2}
        maxH="300px"
        overflowY="auto"
        className="slimScrollbar"
      >
        {descriptions.map(item => {
          return (
            <DefaultCardWrapper
              flexDir="column"
              p="2"
              px="3"
              background="white"
              border="1px solid #1d63ddb2"
              w="100%"
            >
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                p={2}
              >
                <Text fontSize="small" color="rgba(70, 70, 70, 1)">
                  {item}
                </Text>
                <FiTrash2
                  size={14}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRemovePowerBIDescription(item)}
                />
              </Flex>
            </DefaultCardWrapper>
          );
        })}
      </Flex>
    </Flex>
  );
};
