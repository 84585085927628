import { Text, Flex } from '@chakra-ui/react';
import { FcOk } from 'react-icons/fc';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { formatNameToCaptalize } from 'utils/captalizeNames';

type Employee = {
  id: string;
  name: string;
  email: string;
  responsibility: string;
};

interface EmployeesModalCardProps {
  employee: Employee;
  selectedEmployees: any[];
  handleUnselectEmployee: (id: string) => void;
  handleSelectEmployee: (id: string, name: string) => void;
}

export const EmployeesModalCard: React.FC<EmployeesModalCardProps> = ({
  employee,
  selectedEmployees,
  handleUnselectEmployee,
  handleSelectEmployee,
}) => {
  return (
    <Flex
      key={employee.id}
      justify="space-between"
      flexDirection="column"
      px="2"
      py="1"
      borderRadius="3"
      bg="white"
    >
      <Flex justify="space-between" w="100%">
        <Text color="rgba(70, 70, 70, 1)">
          {formatNameToCaptalize(employee.name)}
        </Text>

        <Flex alignSelf="center">
          {selectedEmployees.some(item => item.id === employee.id) ? (
            <FcOk
              size={20}
              title="Colaborador marcado, clique para desmarcar"
              style={{ cursor: 'pointer' }}
              onClick={() => handleUnselectEmployee(employee.id)}
              color="rgba(70, 70, 70, 1)"
            />
          ) : (
            <IoIosAddCircleOutline
              size={20}
              color="rgba(70, 70, 70, 1)"
              style={{ cursor: 'pointer' }}
              title="Marcar colaborador para compartilhar"
              onClick={() => handleSelectEmployee(employee.id, employee.name)}
            />
          )}
        </Flex>
      </Flex>

      <Text fontSize="sm" fontStyle="italic">
        Posição:
        <Text as="span" fontStyle="italic">
          {' '}
          {employee.responsibility &&
            formatNameToCaptalize(employee.responsibility)}
          {!employee.responsibility && 'Não definido'}
        </Text>
      </Text>
      <Text fontSize="sm" fontStyle="italic">
        E-mail:
        <Text as="span" fontStyle="italic">
          {' '}
          {employee.email}
        </Text>
      </Text>
    </Flex>
  );
};
