import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import formatValue from 'utils/formatValue';

interface ComponentProps {
  user: {
    position: number | string;
    name: string;
    sumpl: number;
  };
}

export const UserRankingValuesCard: React.FC<ComponentProps> = ({ user }) => {
  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      border="1px solid"
      borderColor="background.100"
      p="2"
      borderRadius="2"
    >
      <Flex gridGap="2">
        <Text fontSize="smaller" fontWeight="medium">
          {user.position}º
        </Text>
        <Text fontSize="smaller" fontWeight="medium" textTransform="capitalize">
          {user.name.toLowerCase()}
        </Text>
      </Flex>
      <Flex>
        <Text fontWeight="semibold" fontSize="smaller">
          {formatValue(user.sumpl)}
        </Text>
      </Flex>
    </Flex>
  );
};
