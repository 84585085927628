import { MenuList as ChakraMenuList, MenuListProps } from '@chakra-ui/react';

export const MenuList: React.FC<MenuListProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <ChakraMenuList background="white" border="1px solid gray" {...rest}>
      {children}
    </ChakraMenuList>
  );
};
