import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
  useMediaQuery,
} from '@chakra-ui/react';

import hubApi from 'services/hubApi';
import { useAgreementsContext } from 'hooks';
import { ListAllAGreements } from 'components/modules/ListAllAgreements';
import { ModalWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { User } from 'pages/User/interfaces';
import {
  UserInfosCard,
  UserAvatar,
  AgreementUser,
} from 'pages/User/components/modules/Profile';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { ShSpecific } from 'pages/User/components/modules/Profile/ShSpecific';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { IReduxState } from 'shared/interfaces';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const UserProfile: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const { showModal, allAgreements, acceptContract, handleShowModal } =
    useAgreementsContext();
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  const [employee, setEmployee] = useState<User>({} as User);

  const searchEmployeeData = async () => {
    const employeeData = await hubApi.getEmployeeInfos(user.id);
    setEmployee(employeeData);
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      searchEmployeeData();
    }
  }, [lastAuth, user]);

  return (
    <PageWrapper alignItems="center" backPageButton>
      <UserAvatar avatar_url={employee.avatar_url} userName={employee.name} />

      <Tabs
        variant="unstyled"
        size="lg"
        orientation={isMobile ? 'horizontal' : 'vertical'}
        w="100vw"
        maxW={['95vw', '1124px']}
      >
        <TabPanels
          mr="12px"
          p={0}
          height="100vh"
          overflowY="auto"
          maxH={['70vh', 'calc(100vh - 236px)']}
        >
          <TabPanel>
            <UserInfosCard user={employee} />
          </TabPanel>

          <TabPanel>
            <AgreementUser allAgreements={allAgreements} />
          </TabPanel>

          {/* <TabPanel>
            <ShSpecific />
          </TabPanel> */}
        </TabPanels>

        <TabList
          w={['100%', '10%']}
          mt={['0', '4']}
          alignItems={['center', 'initial']}
          justifyContent={['space-evenly', 'initial']}
        >
          <Tab
            _selected={{ color: 'white', bg: '#1d63dde2' }}
            borderRadius={4}
            fontWeight="bold"
            bg="white"
            border="1px solid #1d63ddb2"
            fontSize="sm"
            mb="12px"
            color="rgba(70, 70, 70, 1)"
          >
            Perfil
          </Tab>

          <Tab
            _selected={{ color: 'white', bg: '#1d63dde2' }}
            borderRadius={4}
            fontWeight="bold"
            bg="white"
            border="1px solid #1d63ddb2"
            fontSize="sm"
            mb="12px"
            color="rgba(70, 70, 70, 1)"
            isDisabled={allAgreements.length < 1}
          >
            Acordos
          </Tab>

          {/* {verifyEmployeePermission({
            user,
            areaCod: ['ASS'],
            permissionCod: ['TRA'],
          }) === true && (
            <Tab
              _selected={{ color: 'white', bg: '#1d63dde2' }}
              borderRadius={4}
              fontWeight="bold"
              bg="white"
              border="1px solid #1d63ddb2"
              fontSize="sm"
              color="rgba(70, 70, 70, 1)"
              mb="12px"
            >
              SH
            </Tab>
          )} */}
        </TabList>
      </Tabs>

      <ModalWrapper
        isOpen={showModal}
        onClose={() => allAgreements.filter(agreement => !agreement.accept)}
      >
        <ListAllAGreements
          onClose={handleShowModal}
          agreements={allAgreements.filter(agreement => !agreement.accept)}
          acceptContract={acceptContract}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
