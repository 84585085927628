import { Flex } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { toast } from 'react-toastify';
import history from 'services/history';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface ISaveNoteModalProps {
  note: string;
  clientId: string;
}

export const SaveNoteModal: React.FC<ISaveNoteModalProps> = ({
  note,
  clientId,
}): JSX.Element => {
  const handleSaveNote = async () => {
    try {
      const data = {
        notes: note,
      };

      await hubApiUser.saveClientNotes(clientId, data);

      toast.dark('Anotação salva!');
    } catch (error) {
      toast.dark(
        'Não foi possível salvar sua anotação, tente novamente em alguns minutos'
      );
    }
    history.push('/user/my_clients');
  };

  return (
    <>
      <DefaultModalContentWrapper maxW="500px">
        <Flex flexDir="column">
          <ModalTitle>
            Esse cliente tem notas que não estão salvas, deseja salvar?
          </ModalTitle>
        </Flex>

        <BaseButtonGroup mt="5">
          <SecondaryButton onClick={() => history.push('/user/my_clients')}>
            Nao
          </SecondaryButton>
          <PrimaryButton onClick={handleSaveNote}>Sim</PrimaryButton>
        </BaseButtonGroup>
      </DefaultModalContentWrapper>
    </>
  );
};
