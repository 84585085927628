import { TableBase } from 'componentsV2/elements/table/base';
import { ClientType } from 'sharedV2/types/client';
import { ClientTableRow } from '../tableRow';

type PropsType = {
  clients: ClientType[];
};

export const ClientsTable: React.FC<PropsType> = ({ clients }): JSX.Element => {
  const tableHeadData = [
    {
      columnName: 'Nome',
      widthPercentage: 100,
    },
    {
      columnName: 'Dinheiro Parado',
      widthPercentage: 100,
    },
    {
      columnName: 'Patrimônio Declarado',
      widthPercentage: 100,
    },
    {
      columnName: 'PL',
      widthPercentage: 100,
    },
    {
      columnName: 'Pendências',
      widthPercentage: 100,
    },
  ];

  return (
    <TableBase
      headData={tableHeadData}
      overflowY="auto"
      maxH="calc(100vh - 250px)"
      className="thinScrollbar"
    >
      {clients.map(client => (
        <ClientTableRow client={client} tableHeadData={tableHeadData} />
      ))}
    </TableBase>
  );
};
