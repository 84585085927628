import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  IEmployeeMibs,
  IGeneralWalletMibs,
  IMibsHistory,
  ISourceAction,
} from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

const ERROR_MESSAGE_HIGHER_SPENT_MIBS_PURCHASED =
  'Value spent in mibs purchased cannot be higher than 3000 reais per month.';

type IQueryContext = {
  previousEmployeeMibs: IEmployeeMibs[];
  previousMibsHistory: IMibsHistory[];
  previousIndividualEmployeeMibs: IEmployeeMibs[];
  previousGeneralWalletMibs: IGeneralWalletMibs[];
};

export type IWalletNames =
  | 'MI'
  | 'EWZ'
  | 'GHOST'
  | 'EWZWallet'
  | 'personalWallet'
  | 'ghostWallet';

export type IMibsOperationsVariables = {
  mibsQuantity: string;
  employeeId: string;
  sourceAction: ISourceAction;
  operation: 'DEPOSIT' | 'WITHDRAW';
  sourceWallet: IWalletNames;
  customTitle?: string;
  transactionResponsible?: string;
  additionalInformations?: string;
  destinationWallet: IWalletNames;
};

export const useMibsOperations = (): UseMutationResult<
  unknown,
  unknown,
  IMibsOperationsVariables
> => {
  return useMutation({
    mutationFn: async ({
      mibsQuantity,
      employeeId,
      operation,
      sourceAction,
      sourceWallet,
      customTitle,
      transactionResponsible,
      destinationWallet,
      additionalInformations,
    }: IMibsOperationsVariables) => {
      const response = await hubApiCaptation.mibsOperations({
        employeeId,
        mibsQuantity: Number(mibsQuantity),
        operation,
        sourceAction,
        sourceWallet,
        destinationWallet,
        transactionResponsible: transactionResponsible || employeeId,
        customTitle,
        additionalInformations,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.individualEmployeeMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.generalWalletMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.employeeMibsHistory);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.generalWalletMibs);
    },
    onMutate: async response => {
      await queryClient.cancelQueries({
        queryKey: [
          MIBS_QUERIES_KEYS.employeeMibs,
          MIBS_QUERIES_KEYS.employeeMibsHistory,
          MIBS_QUERIES_KEYS.individualEmployeeMibs,
          MIBS_QUERIES_KEYS.generalWalletMibs,
        ],
      });

      const previousEmployeeMibs = queryClient.getQueryData([
        MIBS_QUERIES_KEYS.employeeMibs,
      ]);
      const previousMibsHistory = queryClient.getQueryData<IMibsHistory[]>([
        MIBS_QUERIES_KEYS.employeeMibsHistory,
      ]);

      const previousIndividualEmployeeMibs = queryClient.getQueryData<
        IMibsHistory[]
      >([MIBS_QUERIES_KEYS.individualEmployeeMibs]);

      const previousGeneralWalletMibs = queryClient.getQueryData<
        IGeneralWalletMibs[]
      >([MIBS_QUERIES_KEYS.generalWalletMibs]);

      queryClient.setQueryData<IEmployeeMibs[]>(
        [MIBS_QUERIES_KEYS.employeeMibs],
        old => {
          const newMibsValueArray = old?.map(item => {
            const newItem = { ...item };

            if (newItem.sourceWallet === response.destinationWallet) {
              if (response.operation === 'DEPOSIT') {
                newItem.mibs += Number(response.mibsQuantity);
              } else {
                newItem.mibs -= Number(response.mibsQuantity);
              }
            }
            return newItem;
          });
          return [...(newMibsValueArray ?? [])];
        }
      );

      queryClient.setQueryData<IEmployeeMibs[]>(
        [MIBS_QUERIES_KEYS.individualEmployeeMibs],
        old => {
          const newMibsValueArray = old?.map(item => {
            const newItem = { ...item };

            if (newItem.sourceWallet === response.destinationWallet) {
              if (response.operation === 'DEPOSIT') {
                newItem.mibs += Number(response.mibsQuantity);
              } else {
                newItem.mibs -= Number(response.mibsQuantity);
              }
            }
            return newItem;
          });
          return [...(newMibsValueArray ?? [])];
        }
      );

      // const newItem: Partial<IMibsHistory> = {
      //   created_at: new Date().toISOString(),
      //   additionalInformations: undefined,
      //   customTitle: response.customTitle,
      //   employeeId: response.employeeId,
      //   operation: response.operation,
      //   operationValue: Number(response.mibsQuantity),
      //   sourceAction: response.sourceAction,
      //   sourceWallet: response.sourceWallet,
      //   transactionResponsible: response.employeeId,
      //   updated_at: new Date().toISOString(),
      // };

      // queryClient.setQueryData<IMibsHistory[]>(
      //   [MIBS_QUERIES_KEYS.employeeMibsHistory],
      //   old => [newItem as IMibsHistory, ...(old ?? [])]
      // );

      queryClient.setQueryData<IGeneralWalletMibs[] | undefined>(
        [MIBS_QUERIES_KEYS.generalWalletMibs],
        old => {
          if (!old) {
            return;
          }

          const generalWallet =
            response.sourceWallet === 'EWZ' ? old[0] : old[1];

          if (response.destinationWallet === 'EWZ') {
            generalWallet.mibs += Number(response.mibsQuantity);
          } else {
            generalWallet.mibs -= Number(response.mibsQuantity);
          }

          return old;
        }
      );

      return {
        previousEmployeeMibs,
        previousMibsHistory,
        previousIndividualEmployeeMibs,
        previousGeneralWalletMibs,
      };
    },
    onError: (error: AxiosError, __, context) => {
      const queryContext = context as IQueryContext;

      queryClient.setQueryData(
        [MIBS_QUERIES_KEYS.employeeMibs],
        queryContext.previousEmployeeMibs
      );
      queryClient.setQueryData(
        [MIBS_QUERIES_KEYS.employeeMibsHistory],
        queryContext.previousMibsHistory
      );
      queryClient.setQueryData(
        [MIBS_QUERIES_KEYS.individualEmployeeMibs],
        queryContext.previousIndividualEmployeeMibs
      );
      queryClient.setQueryData(
        [MIBS_QUERIES_KEYS.generalWalletMibs],
        queryContext.previousGeneralWalletMibs
      );

      if (
        error.response?.data.error.message ===
        ERROR_MESSAGE_HIGHER_SPENT_MIBS_PURCHASED
      ) {
        toast.dark(
          'A quantia de MIBs desejada ultrapassa o limite de R$ 3.000 por mês.'
        );
      } else {
        toast.dark(
          'Ocorreu um erro ao tentar realizer a operação. Tente novamente mais tarde.'
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          MIBS_QUERIES_KEYS.employeeMibs,
          MIBS_QUERIES_KEYS.employeeMibsHistory,
          MIBS_QUERIES_KEYS.individualEmployeeMibs,
          MIBS_QUERIES_KEYS.generalWalletMibs,
        ],
      });
    },
  });
};
