import { Icons } from 'sharedV2/icons';

type PropsType = {
  onClick: () => void;
  enabled?: boolean;
};

export const ModalCloseIcon: React.FC<PropsType> = ({
  onClick,
  enabled = true,
}): JSX.Element => {
  return (
    <Icons.X
      size={24}
      cursor="pointer"
      onClick={enabled ? onClick : undefined}
    />
  );
};
