import { Flex, Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { CurrencyInput } from 'components/elements/forms/CurrencyInput';
import { ModalWrapper } from 'components/elements/wrappers';
import { FormEvent, useState } from 'react';
import { IPMeetingType } from 'services/hubApi';
import { RemoveOrderFromCRM } from '../removeOrderFromCRM';

type PropsType = {
  meeting: IPMeetingType;
  setIsOpenMeetingBoletedModal: (value: boolean) => void;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingBoletedModal: React.FC<PropsType> = ({
  meeting,
  setIsOpenMeetingBoletedModal,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const [annualizedPremium, setAnnualizedPremium] = useState<
    string | undefined
  >(meeting.order.annualizedPremium?.toString());

  const [isOpenRemoveOrderFromCRMModal, setIsOpenRemoveOrderFromCRMModal] =
    useState<boolean>(false);

  const formSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setIsOpenRemoveOrderFromCRMModal(true);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Deseja boletar essa reunião?"
      handleFormSubmit={formSubmit}
      isLoading={false}
      primaryButtonText="Confirmar"
      secondaryButtonEventFunction={() => setIsOpenMeetingBoletedModal(false)}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Flex flexDirection="column" gap={2}>
        <Text fontSize="xs">Prêmio Anualizado</Text>
        <CurrencyInput
          value={annualizedPremium}
          onValueChange={value => {
            setAnnualizedPremium(value);
          }}
        />
      </Flex>
      <ModalWrapper
        isOpen={isOpenRemoveOrderFromCRMModal}
        onClose={() => setIsOpenRemoveOrderFromCRMModal(false)}
      >
        <RemoveOrderFromCRM
          annualizedPremium={annualizedPremium}
          meeting={meeting}
          setIsOpenMeetingBoletedModal={setIsOpenMeetingBoletedModal}
          setIsOpenRemoveOrderFromCRMModal={setIsOpenRemoveOrderFromCRMModal}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>
    </BaseModalFormCard>
  );
};
