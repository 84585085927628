type UseCommonHoverResponseType = {
  bgColor: string;
  transition: string;
};

export const useCommonHover = (): UseCommonHoverResponseType => {
  return {
    bgColor: '#f2f2f2',
    transition: 'background-color 0.3s ease',
  };
};
