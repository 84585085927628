import { useState } from 'react';

import { Flex, Box, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { OrdersPanelData } from 'pages/MyClients/interfaces/OrdersPanelData';
import { formatCalendaryDate } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { OrderInfo } from '../modules/OrderInfo';
import { formatNameToCaptalize } from 'utils/captalizeNames';

export interface OrdersPanelCardProps {
  order: OrdersPanelData;
}

export const OrdersPanelCard: React.FC<OrdersPanelCardProps> = ({
  order,
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const checkStatusColor = (status: string) => {
    if (status === 'confirmada' || status === 'transferida') return 'green.500';
    if (status === 'cancelada') return 'red.500';

    return 'inherit';
  };

  return (
    <>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        w="100%"
        h="188px"
        background="background.400"
        _hover={{ background: 'background.500' }}
        borderRadius={4}
        padding={3}
        borderColor="background.200"
        p="10px"
        onClick={() => setShowModal(!showModal)}
        cursor="pointer"
      >
        <Box
          paddingBottom="4px"
          mb="8px"
          borderBottom="1px"
          borderColor="background.200"
        >
          <Text color="white.300">
            {order.code === 'EX' && 'Câmbio'}
            {order.code === 'IP' &&
              `IP - ${order.segment && formatNameToCaptalize(order.segment)}`}
            {order.code === 'StrategyRV' && 'RV - Estratégicas'}
            {order.code === 'StructuredRV' && 'RV - Estruturadas'}
            {order.code === 'SpecificRV' && 'RV - Específicas'}
          </Text>
        </Box>

        <Box mb="auto">
          <Text fontSize="xs">
            Data: {formatCalendaryDate(order.created_at)}
          </Text>

          {order.code === 'EX' && (
            <Text fontSize="xs">
              Tipo: {`${order.currency} > ${order.targetCurrency}`}
            </Text>
          )}

          {order.risk && <Text fontSize="xs">Risco: {order.risk}</Text>}

          {order.value && (
            <Text fontSize="xs">Valor: {formatValue(order.value)}</Text>
          )}
        </Box>

        <Box>
          <Text
            fontSize="xs"
            textAlign="right"
            color={checkStatusColor(order.status?.toLowerCase())}
          >
            {order.status?.toUpperCase()}
          </Text>
        </Box>
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(!showModal)}>
        <OrderInfo
          order={order}
          handleToggleModal={() => setShowModal(!showModal)}
        />
      </ModalWrapper>
    </>
  );
};
