import { Flex, Text } from '@chakra-ui/react';
import { TableBase, TableData, TableRow } from 'components/elements/table';

interface EffectivenessTableProps {
  effectivenessData: {
    delivered: string;
    marked: string;
    realized: string;
  };
  deliveredLag: string;
  markedLag: string;
  realizedLag: string;
}

export const EffectivenessTable: React.FC<EffectivenessTableProps> = ({
  effectivenessData,
  deliveredLag,
  markedLag,
  realizedLag,
}): JSX.Element => {
  return (
    <Flex align="center">
      <Text pr="16px">Efetividade</Text>

      <TableBase
        headData={['', 'Total', 'Conversão']}
        bg="background.500"
        p="0"
        w="100%"
      >
        <TableRow>
          <TableData p="1.5">Leads entregues</TableData>
          <TableData p="1.5">{effectivenessData.delivered}</TableData>
          <TableData p="1.5">{deliveredLag} %</TableData>
        </TableRow>

        <TableRow>
          <TableData p="1.5">Reuniões marcadas</TableData>
          <TableData p="1.5">{effectivenessData.marked}</TableData>
          <TableData p="1.5">{markedLag} %</TableData>
        </TableRow>

        <TableRow>
          <TableData p="1.5">Reuniões realizadas</TableData>
          <TableData p="1.5">{effectivenessData.realized}</TableData>
          <TableData p="1.5">{realizedLag} %</TableData>
        </TableRow>
      </TableBase>
    </Flex>
  );
};
