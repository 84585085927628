import { Flex, FlexProps } from '@chakra-ui/react';
import { colors } from 'sharedV2/colors';

export const BoxOutline: React.FC<FlexProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <Flex
      paddingX="1rem"
      paddingY="0.25rem"
      border={`1px solid ${colors.BLUE}`}
      borderRadius={6}
      alignItems="center"
      transition="background-color 0.3s ease"
      {...rest}
    >
      {children}
    </Flex>
  );
};
