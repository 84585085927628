import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';

import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import InputLabel from '../../../components/customElements/InputLabel';
import DropdownMenu from '../../../components/customElements/DropdownMenu';

import history from '../../../services/history';
import hubApi from '../../../services/hubApi';
import { hubApiOfficeBranches } from '../../../services/hubApi/classes/HubApiOfficeBranches';
import { hubApiOfficeTables } from '../../../services/hubApi/classes/HubApiOfficeTables';
import { sendEmployeeContractData } from '../../../store/modules/newEmployeeForm/actions';
import { contractSchema } from './schemas';

import {
  Container,
  DropdownsArea,
  SalaryArea,
  TransfersTitle,
  TransfersArea,
  TransfersAreaWithTreeInput,
} from './styles';
import { DefaultCardWrapper } from 'components/elements/wrappers';

const EmployeeContract = () => {
  const newEmployee = useSelector(state => state.employeeForm.employeeData);
  const dispatch = useDispatch();
  const { employeeId } = useParams();

  const salaryOptions = [
    { title: 'Fixo', value: 'fixed' },
    { title: 'Mínimo Garantido', value: 'minimum' },
  ];

  const [redirectLink, setRedirectLink] = useState(
    `/manager/employee/profile/${employeeId}`
  );
  const [officeBranches, setOfficeBranches] = useState([]);
  const [officeTables, setOfficeTables] = useState([]);

  const [officeTitle, setOfficeTitle] = useState('Filial');
  const [tableTitle, setTableTitle] = useState('Mesa');
  const [selectedSalary, setSelectedSalary] = useState('Opções de Salário');

  const [contractData, setContractData] = useState({
    officeId: '',
    tableId: '',
    BTGCode: '',
    salary: '',
    salaryOption: '',
    RV: '',
    guru: '',
    accessory: '',
    intelligency: '',
  });

  useEffect(() => {
    async function getEmployeeInfo(employeeId) {
      const employee = await hubApi.getEmployeeInfos(employeeId);

      if (employee.office) {
        setOfficeTitle(`${employee.office.city} - ${employee.office.zona}`);

        const tables = await hubApiOfficeTables.listTablesByOffice(
          employee.officeId
        );
        setOfficeTables(tables);

        if (employee.tableName) {
          setTableTitle(employee.tableName);
        }

        setContractData({
          ...contractData,
          officeId: employee.officeId,
          tableId: employee.tableId,
          BTGCode: employee.BTGCode,
          salary: employee.salary,
          RV: employee.RV,
          accessory: employee.accessory,
          exchange: employee.exchange,
          guru: employee.guru,
          intelligency: employee.intelligency,
          salaryOption: employee.salaryOption,
        });

        if (employee.salaryOption) {
          setSelectedSalary(
            employee.salaryOption === 'fixed' ? 'Fixo' : 'Mínimo Garantido'
          );
        }
      }
    }

    getEmployeeInfo(employeeId);
  }, []);

  useEffect(() => {
    getOfficesAndTable();
  }, [employeeId]);

  useEffect(() => {
    if (history.location.state) {
      setRedirectLink('/users/search');
    }
  }, []);

  async function getOfficesAndTable() {
    try {
      const response = await hubApiOfficeBranches.listBranchOffices();
      setOfficeBranches(response);
    } catch (err) {
      toast.dark('Occoreu um erro ao buscar as filias.');
    }
  }

  const handleChangeValue = e => {
    setContractData({ ...contractData, [e.target.name]: e.target.value });
  };

  const handleSelectSalary = item => {
    setSelectedSalary(item.title);
    setContractData({ ...contractData, salaryOption: item.value });
  };

  const handleSelectOffice = async office => {
    try {
      setOfficeTitle(`${office.city} -${office.zona} `);
      const response = await hubApiOfficeTables.listTablesByOffice(office.id);
      setOfficeTables(response);
      setContractData({ ...contractData, officeId: office.id });
    } catch (err) {
      toast.dark('Occoreu um erro ao buscar as mesas da filial selecionada.');
    }
  };

  const handleSelectTable = table => {
    try {
      setTableTitle(table.name);
      setContractData({ ...contractData, tableId: table.id });
    } catch (err) {
      toast.dark('Occoreu um erro ao selecionar mesa.');
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    try {
      const isDataValid = await contractSchema
        .validate(contractData)
        .catch(err => {
          err.errors.map(error => toast.dark(error));
        });

      if (isDataValid) {
        if (history.location.state) {
          await hubApiOfficeBranches.updateEmployeeInfoByManager(
            employeeId,
            contractData
          );
          toast.dark('Contrato do colaborador atualizado com sucesso');
          history.push('/users/search');
        } else {
          dispatch(sendEmployeeContractData(contractData));
          history.push(`/manager/employee/access/${employeeId}`);
        }
      }
    } catch (err) {
      toast.dark('Erro ao tentar enviar dados de contrato.');
    }
  };

  return (
    <Container>
      <BackPageButton link={redirectLink} />

      <DefaultCardWrapper w="689px">
        <div className="default-card-header">
          <h4>{newEmployee.name}</h4>
        </div>

        <form onSubmit={handleFormSubmit}>
          <DropdownsArea>
            <DropdownMenu
              title={officeTitle}
              options={officeBranches}
              handleClick={handleSelectOffice}
              pickOption
              small
            />
            <DropdownMenu
              title={tableTitle}
              options={officeTables}
              handleClick={handleSelectTable}
              pickOption
              small
            />
          </DropdownsArea>

          <InputLabel
            label="Código"
            type="text"
            placeholder="Código BTG"
            name="BTGCode"
            value={contractData.BTGCode}
            handleChange={handleChangeValue}
          />

          <SalaryArea>
            <InputLabel
              label="Salário"
              type="text"
              placeholder="R$"
              name="salary"
              value={contractData.salary}
              handleChange={handleChangeValue}
            />
            <DropdownMenu
              title={selectedSalary}
              options={salaryOptions}
              handleClick={handleSelectSalary}
              pickOption
              small
            />
          </SalaryArea>

          <TransfersTitle>Repasses (Valor em %):</TransfersTitle>
          <TransfersArea>
            <InputLabel
              label="Renda Variável"
              type="number"
              placeholder="%"
              name="RV"
              value={contractData.RV}
              handleChange={handleChangeValue}
            />
            <InputLabel
              label="Inteligência"
              type="number"
              placeholder="%"
              name="intelligency"
              value={contractData.intelligency}
              handleChange={handleChangeValue}
            />
          </TransfersArea>

          <TransfersAreaWithTreeInput>
            <InputLabel
              label="Assessoria"
              type="number"
              placeholder="%"
              name="accessory"
              value={contractData.accessory}
              handleChange={handleChangeValue}
            />
            <InputLabel
              label="Guru"
              type="number"
              placeholder="%"
              name="guru"
              value={contractData.guru}
              handleChange={handleChangeValue}
            />

            <InputLabel
              label="Câmbio"
              type="number"
              placeholder="%"
              name="exchange"
              value={contractData.exchange}
              handleChange={handleChangeValue}
            />
          </TransfersAreaWithTreeInput>

          <Flex justifyContent="flex-end">
            <PrimaryButton type="submit">
              Salvar e Configurar Acessos
            </PrimaryButton>
          </Flex>
        </form>
      </DefaultCardWrapper>
    </Container>
  );
};

export default EmployeeContract;
