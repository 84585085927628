import { radialGradient } from 'shared/styles/radialGradient';
import styled, { css } from 'styled-components';

export type PageWrapperPropsType = {
  gap?: number;
  alignItems?: 'start' | 'center' | 'end';
  paddingTop?: string;
  paddingBottom?: string;
  width?: string;
  heigth?: string;
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center';
  paddingX?: string;
  maxWidth?: string;
};

export const PageWrapperStyledComponent = styled.div<PageWrapperPropsType>`
  ${({
    gap,
    alignItems,
    paddingTop,
    width,
    heigth,
    flexDirection,
    justifyContent,
    paddingX,
    maxWidth,
    paddingBottom,
  }) => css`
    background: rgba(242, 242, 242, 0.95);
    display: flex;
    width: ${width || '100%'};
    max-width: ${maxWidth};
    min-height: ${heigth || 'calc(100vh - 80px)'};
    height: 100%;
    padding-left: ${paddingX || '9rem'};
    padding-right: ${paddingX || '9rem'};
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
    position: relative;
    overflow-x: hidden;
    flex-direction: ${flexDirection || 'column'};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    gap: ${(gap || 0) * 0.25}rem;
  `}
`;
