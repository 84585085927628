const realStateColumns = [
  {
    id: '1',
    title: 'Sem Contato',
    status: 'noContact',
    tasks: [],
  },
  {
    id: '2',
    title: 'R1',
    status: 'r1',
    tasks: [],
  },
  {
    id: '3',
    title: 'R2',
    status: 'r2',
    tasks: [],
  },
  {
    id: '4',
    title: 'Follow Up',
    status: 'followUp',
    tasks: [],
  },
  {
    id: '5',
    title: 'Atendimento Consórcio',
    status: 'consortium',
    tasks: [],
  },
  {
    id: '6',
    title: 'Atendimento Carta Comtemplada',
    status: 'contemplatedLetter',
    tasks: [],
  },
  {
    id: '7',
    title: 'Atendimento Financiamento',
    status: 'financing',
    tasks: [],
  },
  {
    id: '8',
    title: 'Atendimento Home Equity',
    status: 'homeEquity',
    tasks: [],
  },
  {
    id: '9',
    title: 'Fechado/Cancelado',
    status: 'closed',
    tasks: [],
  },
];

export { realStateColumns };
