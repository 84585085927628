import { toast } from 'react-toastify';
import { SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface DeleteModalProps {
  contentId: string;
  closeModal: () => void;
  redirectAfterDeletion: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  contentId,
  closeModal,
  redirectAfterDeletion,
}) => {
  const deleteContent = async () => {
    try {
      await hubApiUser.deleteContent(contentId);

      toast.dark('Conteúdo excluído');
      redirectAfterDeletion();
    } catch (error) {
      toast.dark(
        'Não foi possível excluir este conteúdo, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle textAlign="center">
        Deseja realmente excluir este conteúdo?
      </ModalTitle>

      <BaseButtonGroup justifyContent="center">
        <SecondaryButton onClick={() => closeModal()}>Voltar</SecondaryButton>

        <SecondaryButton
          background="red.400"
          opacity={0.6}
          _hover={{ opacity: '0.8' }}
          onClick={deleteContent}
        >
          Excluir conteúdo
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
