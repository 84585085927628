import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadMundoInvestStatusEnum } from 'sharedV2/types/leadMundoInvest';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';

export type UpdateLeadMutationPropsType = {
  type: LeadTypeEnum;
  leadId: string;
  status?: LeadMundoInvestStatusEnum;
  declinedReason?: string;
  pipeValue?: number | string;
  contactAttempts?: string;
  btgAccount?: string;
};

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useUpdateLead = (
  props?: PropsType
): UseMutationResult<unknown, unknown, UpdateLeadMutationPropsType> => {
  return useMutation({
    mutationKey: ['updateLead'],
    mutationFn: async (data: UpdateLeadMutationPropsType) => {
      const response = await hubApiCaptation.updateLead(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};
