export default function formatStringInTitle(value: string): string {
  return value
    .split(' ')
    .map(item =>
      item
        .split('')
        .map((sub, index) =>
          index === 0 ? sub.toUpperCase() : sub.toLowerCase()
        )
        .join('')
    )
    .join(' ');
}
