import { Flex } from '@chakra-ui/react';
import { Button } from 'componentsV2/elements/buttons';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { useNavigation } from 'hooksV2/useNavigation';
import { VisualizationModeEnum } from 'hooksV2/useVisualizationMode';
import { SalesHunterPoolFilterDropdownEnum } from 'pagesV2/SalesHunterPool/hooks/useShPoolFilteredLeads';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { SalesHunterPoolLeadsActivitiesNumber } from '../leadActivitiesNumbers';
import { SalesHunterPoolSearchLead } from '../searchLead';

type PropsType = {
  handleChangeFilterDropdownValue: (
    value: SalesHunterPoolFilterDropdownEnum
  ) => void;
  handleChangeVisualizationMode: (value: VisualizationModeEnum) => void;
  visualizationModeValue: VisualizationModeEnum;
  handleSearchLeadByPhoneOrName: (value: string) => void;
  searchLeadByPhoneOrNameValue: string;
  filterDropdownValue: SalesHunterPoolFilterDropdownEnum;
};

export const SalesHunterPoolHeader: React.FC<PropsType> = ({
  handleChangeFilterDropdownValue,
  handleChangeVisualizationMode,
  visualizationModeValue,
  handleSearchLeadByPhoneOrName,
  searchLeadByPhoneOrNameValue,
  filterDropdownValue,
}): JSX.Element => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const { redirect } = useNavigation();

  const filterDropdownOptions: BaseDropdownOptionsType[] = [
    {
      label: 'Todos',
      value: SalesHunterPoolFilterDropdownEnum.ALL,
    },
    {
      label: 'Reunião',
      value: SalesHunterPoolFilterDropdownEnum.MEETING,
    },
    {
      label: 'Retorno',
      value: SalesHunterPoolFilterDropdownEnum.CALLBACK,
    },
    {
      label: 'R.N.A',
      value: SalesHunterPoolFilterDropdownEnum.RETURNED,
    },
    {
      label: 'Sem Atividade',
      value: SalesHunterPoolFilterDropdownEnum.INHERIT,
    },
    {
      label: 'Atividades do Dia',
      value: SalesHunterPoolFilterDropdownEnum.DAY,
    },
  ];

  return (
    <>
      <SalesHunterPoolLeadsActivitiesNumber />
      <Flex justifyContent="space-between">
        <Flex gap={2} w="100%" justifyContent="space-between">
          <Flex w="100%" gap={2}>
            <Button.Primary
              h="100%"
              onClick={() =>
                redirect(
                  `/captation/leads_sh/activities/${user.id}/${user.name}`
                )
              }
            >
              Atividades realizadas
            </Button.Primary>
            <Dropdown.Root
              w="20%"
              onChange={event =>
                handleChangeFilterDropdownValue(
                  event.target.value as SalesHunterPoolFilterDropdownEnum
                )
              }
            >
              <Dropdown.Options
                selected={filterDropdownValue}
                options={filterDropdownOptions}
                placeholder="Filtrar leads"
              />
            </Dropdown.Root>
          </Flex>

          <Flex gap={2}>
            <SalesHunterPoolSearchLead
              searchLeadByPhoneOrNameValue={searchLeadByPhoneOrNameValue}
              handleSearchLeadByPhoneOrName={handleSearchLeadByPhoneOrName}
            />
            <Flex gap={2} alignItems="center">
              <Icons.LIST
                size={32}
                color={
                  visualizationModeValue === VisualizationModeEnum.TABLE
                    ? colors.BLUE
                    : 'rgba(70, 70, 70, 1)'
                }
                onClick={() =>
                  handleChangeVisualizationMode(VisualizationModeEnum.TABLE)
                }
              />
              <Icons.GRID
                size={32}
                color={
                  visualizationModeValue === VisualizationModeEnum.MOSAIC
                    ? colors.BLUE
                    : 'rgba(70, 70, 70, 1)'
                }
                onClick={() =>
                  handleChangeVisualizationMode(VisualizationModeEnum.MOSAIC)
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
