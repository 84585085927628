import { Flex, Text } from '@chakra-ui/react';
import {
  MonthNumbers,
  Employee,
} from 'pages/DashboardTablesDesktop/interfaces';
import formatValue from 'utils/formatValue';

interface TopSectionMonthSummaryProps {
  monthSummaryData: {
    monthsNumbers: MonthNumbers[];
    employeesNumber: Employee[];
  };
}

export const TopSectionMonthSummary: React.FC<TopSectionMonthSummaryProps> = ({
  monthSummaryData,
}) => {
  const deltaPL =
    monthSummaryData.monthsNumbers &&
    Number(
      monthSummaryData.monthsNumbers[monthSummaryData.monthsNumbers.length - 1]
        ?.pl || '0'
    ) -
      Number(
        monthSummaryData.monthsNumbers[
          monthSummaryData.monthsNumbers.length - 2
        ]?.pl || '0'
      );

  const monthCaptation =
    monthSummaryData.employeesNumber &&
    monthSummaryData.employeesNumber
      .map(employee => {
        if (employee.nnm) {
          return Number(employee.nnm);
        } else {
          return 0;
        }
      })
      .reduce((current, acc) => current + acc, 0);

  const tedOut =
    monthSummaryData.employeesNumber &&
    monthSummaryData.employeesNumber
      .map(employee => {
        if (employee.tedout) {
          return Number(employee.tedout);
        } else {
          return 0;
        }
      })
      .reduce((current, acc) => current + acc, 0);

  return (
    <Flex
      w="25%"
      bg="background.600"
      flexDirection="column"
      justifyContent="space-between"
      py={2}
      borderRadius={8}
    >
      <Flex w="100%" flexDirection="column" alignItems="center">
        <Text fontSize="md" color="white">
          Delta PL / mês:
        </Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={deltaPL < 0 ? 'red.400' : 'green.400'}
        >
          {monthSummaryData.monthsNumbers
            ? formatValue(deltaPL.toString())
            : '-'}
        </Text>
      </Flex>

      <Flex w="100%" flexDirection="column" alignItems="center">
        <Text fontSize="md" color="white">
          Captação do mês:
        </Text>
        <Text fontSize="lg" fontWeight="bold" color="green.400">
          {monthSummaryData.employeesNumber && monthCaptation > 0
            ? formatValue(monthCaptation.toString())
            : '-'}
        </Text>
      </Flex>

      <Flex w="100%" flexDirection="column" alignItems="center">
        <Text fontSize="md" color="white">
          Ted-out do mês:
        </Text>
        <Text fontSize="lg" fontWeight="bold" color="red.400">
          {monthSummaryData.employeesNumber && tedOut < 0
            ? formatValue(tedOut.toString())
            : '-'}
        </Text>
      </Flex>
    </Flex>
  );
};
