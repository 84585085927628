import { IChartPieData, IIncomesDataChart } from 'shared/interfaces/AAI';
import { generateRandomHexColor } from './generateRandomHexColor';

export const transformFundsArrayToChartDataPie = (
  stocksHistory: IIncomesDataChart[]
): IChartPieData[] => {
  const spaceData = stocksHistory
    .filter(item => Number(item.value) > 0 && item)
    .map((item, index) => ({
      id: item.fieldName,
      label: item.fieldName,
      value: Number(item.value),
      color: generateRandomHexColor(index),
    }));

  return spaceData;
};
