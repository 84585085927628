import React, { useEffect, useState } from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { hubApiNotifications } from 'services/hubApi/classes/HubApiNotifications';
import NotificationsCard from 'pages/User/components/modules/Notifications/NotificationsCard';
import { errorHandler } from 'utils';
import { LoadingSpinner } from 'components/elements/others';

export interface IUserNotifications {
  type: string;
  title: string;
  to: string;
  description?: string | undefined;
  idOrder?: string | undefined;
  typeOrder?:
    | 'TI'
    | 'MKT'
    | 'CONTRACT'
    | 'RH'
    | 'SPECIFIC'
    | 'STRATEGIC'
    | 'STRUCTURED';
  plusInformation?:
    | {
        newEmployeeId: string;
      }
    | undefined;
  status?: string;
  updated_at?: string;
}

export const UserNotifications: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userNotifications, setUserNotifications] = useState<
    IUserNotifications[]
  >([]);

  // const connectSocket = useCallback(() => {
  //     const socket = io(hubApi.baseURL, {
  //         query: { user_id: user.id },
  //     });

  //     return socket;
  // }, [user.id]);

  // useEffect(() => {
  //     const socket = connectSocket();

  //     socket.on('newNotifications', (order) => {
  //         setUserNotifications(order);
  //     });
  // }, []);

  useEffect(() => {
    async function getAllNotifications() {
      try {
        const response = await hubApiNotifications.getAllNotifications();
        setUserNotifications(response);
        setIsLoading(false);
      } catch (err) {
        errorHandler(err);
        setIsLoading(false);
      }
    }

    getAllNotifications();
  }, []);

  return (
    <PageWrapper flexDir="column" alignItems="center" py="48px">
      <BackPageButton />
      <MainPageTitle>Notificações</MainPageTitle>
      {isLoading && <LoadingSpinner />}

      {!isLoading && userNotifications.length <= 0 && (
        <Text fontSize="xl" marginTop="24px">
          Você não possui notificações
        </Text>
      )}

      <Flex
        flexDir="column"
        maxH="calc(100vh - 220px)"
        overflowY="auto"
        px="1.5"
      >
        {userNotifications?.map((notification, index) => (
          <NotificationsCard key={index} notification={notification} />
        ))}
      </Flex>
    </PageWrapper>
  );
};
