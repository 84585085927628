export const ClientLeftTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11v2a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h7a1 1 0 011 1v2M14 7.5H6"
        stroke="#FC8D62"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.5l-2 2 2 2"
        stroke="#FC8D62"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
