import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SectionTitleWithBorder } from 'components/elements/texts';

interface ComponentProps {
  title: string;
}

export const SectionOptionsList: React.FC<ComponentProps> = ({
  children,
  title,
}) => {
  return (
    <Flex
      w="100%"
      maxW="1180px"
      flexDir="column"
      alignItems="flex-start"
      mb="8"
    >
      <SectionTitleWithBorder>{title}</SectionTitleWithBorder>

      <Flex width="100%" gridGap="16px 20px" flexWrap="wrap">
        {children}
      </Flex>
    </Flex>
  );
};
