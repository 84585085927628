import { useCallback, useEffect, useState } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { TableBase } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableRowMICaptation } from 'pages/GenerationalVariables/components/elements/table/TableRowMICaptation';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  IInformation,
  IManagementCaptationMI,
  IOffices,
  ITables,
} from 'pages/GenerationalVariables/interfaces';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { miCaptationTableHead } from './data';

export const ManagementCaptationMundoInvest: React.FC = () => {
  const [tables, setTables] = useState<ITables[]>([]);
  const [infos, setInfos] = useState({} as IInformation);
  const [offices, setOffices] = useState<IOffices[]>([]);
  const [officeId, setOfficeId] = useState<string>(
    'eaa55d7b-8558-46cc-8314-a0db10cacc43'
  );

  const [managementCaptationMI, setManagementCaptationMI] = useState<
    IManagementCaptationMI[]
  >([]);
  const [managementCaptationMICopy, setManagementCaptationMICopy] = useState<
    IManagementCaptationMI[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeTableName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInfos({ ...infos, [e.target.name]: e.target.value });
    },
    [infos]
  );

  const handleChangeFilialId = (e: string) => {
    setOfficeId(e);
  };

  const handleChangeValue = useCallback(
    (tableName: string) => {
      setInfos({ ...infos, tableName });
    },
    [infos]
  );

  const getOffices = async () => {
    const data = await hubApiOfficeTables.listFilial();
    setOffices(data);
  };

  const getTablesByOffice = useCallback(async (): Promise<void> => {
    try {
      const data = await hubApiOfficeTables.listTablesByOffice(officeId);

      setTables(data);
    } catch (error) {
      errorHandler(error);
    }
  }, [officeId]);

  const getInfoCaptationMI = async () => {
    infos.period = new Date().toISOString().slice(0, 7);
    setIsLoading(true);
    try {
      const responses: IManagementCaptationMI[] =
        await hubApiCaptation.getCaptationMundoInvest();

      const result = responses.filter(response =>
        response.date.includes(infos.period)
      );

      setManagementCaptationMICopy(responses);
      setManagementCaptationMI(result);
    } catch (err) {
      errorHandler(err);
    }
    setIsLoading(false);
  };

  const filterInfosCaptationMi = () => {
    if (infos.tableName) {
      const response = managementCaptationMICopy.filter(
        managementCaptationMICop =>
          managementCaptationMICop.date.includes(infos.period) &&
          managementCaptationMICop.tableId.includes(infos.tableName)
      );

      setManagementCaptationMI(response);
    } else {
      const response = managementCaptationMICopy.filter(
        managementCaptationMICop =>
          managementCaptationMICop.date.includes(infos.period)
      );

      setManagementCaptationMI(response);
    }
  };

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getTablesByOffice();
      getInfoCaptationMI();
      getOffices();
      filterInfosCaptationMi();
    }
  }, [lastAuth, officeId, infos]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">
        Gestão de captação Mundo Invest
      </MainPageTitle>

      <Flex
        width="100%"
        flexDir={{ base: 'column', md: 'row' }}
        gridGap={1}
        mb={2}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Box w="100%" maxW={{ base: '196px', md: '188px' }}>
          <InputWithLabel
            label=""
            color="white"
            fontSize="small"
            onChange={handleChangeTableName}
            type="month"
            value={infos.period}
            name="period"
          />
        </Box>

        <Dropdown
          width="100%"
          maxW="196px"
          placeholder="Selecione uma filial"
          onChange={e => handleChangeFilialId(e.target.value)}
        >
          {offices.map(table => (
            <option key={table.id} value={table.id}>
              {table.city}
            </option>
          ))}
        </Dropdown>

        <Dropdown
          width="100%"
          maxW={{ base: '196px', md: '178px' }}
          placeholder="Todos os registros"
          onChange={e => handleChangeValue(e.target.value)}
        >
          {tables.map(table => (
            <option key={table.id} value={table.id}>
              {table.name}
            </option>
          ))}
        </Dropdown>
      </Flex>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {managementCaptationMI.length === 0 ? (
            <Text mt={20} color="gray" fontStyle="italic">
              Não há registros para esses filtros
            </Text>
          ) : (
            <>
              <TableBase
                headData={miCaptationTableHead}
                p="0"
                mt="2"
                w="100%"
                maxW={{ base: '1080px', '2xl': '1240px' }}
                maxH="calc(100vh - 220px)"
                overflowY="auto"
              >
                {managementCaptationMI.map((managementCaptationMi, key) => (
                  <TableRowMICaptation
                    key={`${managementCaptationMi.tableId}-${key}`}
                    managementCaptationMi={managementCaptationMi}
                  />
                ))}
              </TableBase>
            </>
          )}
        </>
      )}
    </DefaultPageWrapper>
  );
};
