import React from 'react';
import { Text } from '@chakra-ui/react';

import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { SHQuestionsProps } from 'pages/Captation/interfaces/SHLeads';
import formatValue from 'utils/formatValue';
import { IBaseMeeting } from 'pages/Captation/interfaces';

interface IMeetingSHInfosProps {
  questions: SHQuestionsProps;
  meetingInfos: IBaseMeeting;
}

type QuestionIdentifierOptions =
  | 'broker'
  | 'pl'
  | 'age'
  | 'assets'
  | 'profession'
  | 'trouble'
  | 'alreadyInvest'
  | 'disclaimer'
  | 'knowledge';

export const sHBaseQuestions = [
  { title: 'Idade', identifier: 'age' },
  { title: 'Corretora', identifier: 'broker' },
  { title: 'Perfil', identifier: 'perfil' },
  { title: 'Conhecimento', identifier: 'knowledge' },
  { title: 'Patrimônio', identifier: 'pl' },
  { title: 'Já investe', identifier: 'alreadyInvest' },
  { title: 'Ativos que investe', identifier: 'assets' },
  { title: 'Identificação da dor', identifier: 'trouble' },
  { title: 'Profissão', identifier: 'profession' },
  // { title: 'Disclaimer', identifier: 'disclaimer' },
];
export const MeetingSHInfos: React.FC<IMeetingSHInfosProps> = ({
  questions,
  meetingInfos,
}) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle mb="1">Informações</ContentDividerTitle>
      {meetingInfos.local && (
        <Text fontSize="xs">
          Tipo de reunião:{' '}
          <Text as="span" color="white">
            {meetingInfos.local}
          </Text>
        </Text>
      )}

      {sHBaseQuestions.map(
        (question, index) =>
          questions[question.identifier as QuestionIdentifierOptions] !==
            null &&
          (question.identifier === 'pl' ? (
            <Text fontSize="xs">
              {question.title}:{' '}
              <Text as="span" color="white">
                {questions.pl?.includes('k')
                  ? questions.pl
                  : formatValue(questions.pl ? questions.pl : '0')}
              </Text>
            </Text>
          ) : (
            <Text fontSize="xs">
              {question.title}:{' '}
              <Text as="span" color="white">
                {questions[question.identifier as QuestionIdentifierOptions]}
              </Text>
            </Text>
          ))
      )}
    </ContentWithVertDividerWrapper>
  );
};
