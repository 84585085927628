import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { LeadParsedType } from 'sharedV2/types/leadTypes';
import { Mapper } from 'utilsV2/mapper';

type UseLeadMundoInvestMapperResponseType = {
  mapper: Mapper<LeadMundoInvestType, LeadParsedType>;
};

export const useLeadMundoInvestMapper =
  (): UseLeadMundoInvestMapperResponseType => {
    const toUseMapper = (
      leadMundoInvest: LeadMundoInvestType
    ): LeadParsedType => {
      return {
        id: leadMundoInvest.id,
        email: leadMundoInvest.email,
        created_at: leadMundoInvest.created_at,
        employeeId: leadMundoInvest.employeeId,
        frequency: leadMundoInvest.frequency,
        meetingPrice: leadMundoInvest.meetingPrice,
        name: leadMundoInvest.name,
        origin: leadMundoInvest.origin,
        phone: leadMundoInvest.phone,
        pipeValue: leadMundoInvest.pipeValue,
        plMundoInvest: leadMundoInvest.plMundoInvest,
        plSalesHunter: leadMundoInvest.plSalesHunter,
        price: leadMundoInvest.price,
        status: leadMundoInvest.status,
        updated_at: leadMundoInvest.updated_at,
        meeting: leadMundoInvest.meeting,
        EWZClient: leadMundoInvest.EWZClient,
        btgAccount: leadMundoInvest.btgAccount,
        contactAttempts: leadMundoInvest.contactAttempts,
        questions: {
          id: leadMundoInvest?.questions?.id,
          leadId: leadMundoInvest?.questions?.leadId,
          age: leadMundoInvest?.questions?.age,
          alreadyInvest: leadMundoInvest?.questions?.alreadyInvest,
          assets: leadMundoInvest?.questions?.assets,
          broker: leadMundoInvest?.questions?.broker,
          disclaimer: leadMundoInvest?.questions?.disclaimer,
          knowledge: leadMundoInvest?.questions?.knowledge,
          perfil: leadMundoInvest?.questions?.perfil,
          pl: leadMundoInvest?.questions?.pl,
          profession: leadMundoInvest?.questions?.profession,
          trouble: leadMundoInvest?.questions?.trouble,
        },
        actions: leadMundoInvest.actions?.map(action => action),
      };
    };

    return {
      mapper: new Mapper(toUseMapper, null),
    };
  };
