import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { Employee } from 'pages/DashboardTablesDesktop/interfaces';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { formatValueForSI } from 'utils/formatValueForSI';
import { KPIsSectionBox } from 'pages/DashboardTablesDesktop/components/elements';

interface BottomSectionTableKPIsProps {
  tableName: string;
  employees: Employee[];
  tableData: any;
  tableId: string;
}

export const BottomSectionTableKPIs: React.FC<BottomSectionTableKPIsProps> = ({
  tableName,
  employees,
  tableData,
  tableId,
}) => {
  const [meetingsNumbers, setMeetingsNumbers] = useState({} as any);

  const getMeetingsNumber = async () => {
    try {
      const data = await hubApiOfficeTables.meetingsNumbers(tableId);
      setMeetingsNumbers(data);
    } catch (error) {
      toast.dark(
        'Não foi possível obter os dados das reuniões, tente novamente em alguns minutos'
      );
    }
  };

  const lateMeetings =
    (meetingsNumbers.meetingsMonthLate / meetingsNumbers.meetingsMonth) * 100;

  const net =
    employees
      .map(employee => Number(employee.netTotal || 0))
      .reduce((current, acc) => current + acc, 0) || 0;

  const nps =
    employees
      .map(employee => Number(employee.nps || 0))
      .reduce((current, acc) => current + acc, 0) / employees.length || 0;

  const txr =
    (employees
      .map(
        employee =>
          Number(employee.netTotal || 0) + Number(employee.tedout || 0)
      )
      .reduce((current, acc) => current + acc, 0) /
      net) *
      100 || 0;

  const averagePLPerClient =
    tableData.dashMonths &&
    (Number(tableData.dashMonths[tableData.dashMonths.length - 1]?.pl || '0') /
      tableData.clientsCount ||
      0);

  useEffect(() => {
    getMeetingsNumber();
  }, []);

  return (
    <Flex
      w="100%"
      h="45%"
      bg="background.600"
      flexDirection="column"
      justifyContent="space-between"
      p={2}
      borderRadius={8}
      alignItems="center"
      gridGap={2}
    >
      <Text fontSize="md" color="white">
        KPI&apos;s da mesa {tableName}
      </Text>
      <Flex w="100%" gridGap={1} flexWrap="wrap">
        <KPIsSectionBox>
          <Text fontSize="xs">Net</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {formatValueForSI(net || 0)}
          </Text>
        </KPIsSectionBox>

        <KPIsSectionBox>
          <Text fontSize="xs">NPS</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {nps.toFixed(2)}
          </Text>
        </KPIsSectionBox>

        <KPIsSectionBox>
          <Text fontSize="xs">TXR</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {txr.toFixed(2)}%
          </Text>
        </KPIsSectionBox>

        <KPIsSectionBox>
          <Text fontSize="xs">Número de Clientes</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {tableData.clientsCount || 0}
          </Text>
        </KPIsSectionBox>

        <KPIsSectionBox>
          <Text fontSize="xs">PL médio / Cliente</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {formatValueForSI(averagePLPerClient?.toFixed(2) || 0)}
          </Text>
        </KPIsSectionBox>

        <KPIsSectionBox>
          <Text fontSize="xs">Reuniões atrasadas</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {lateMeetings.toFixed(2) || 0}%
          </Text>
        </KPIsSectionBox>
      </Flex>
    </Flex>
  );
};
