import { useHistory } from 'react-router-dom';

type UseNavigationResponseType = {
  redirect: (url: string, state?: unknown) => void;
  goBack: () => void;
};

export const useNavigation = (): UseNavigationResponseType => {
  const history = useHistory();

  const redirect = (url: string, state?: unknown) => {
    history.push(url, state);
  };

  const goBack = () => {
    history.goBack();
  };

  return {
    redirect,
    goBack,
  };
};
