import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
import {
  ButtonGroup,
  Checkbox,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';

import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { InputWithLabel } from 'components/elements/forms';
import ButtonRadio from 'pages/products/components/elements/buttons/ButtonRadio';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

import { PublicOfferingProps } from 'pages/products/interfaces';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { TextEditorWithLabel } from 'pages/products/components/elements/forms/TextEditor';

interface CreateIPOFormProps {
  offeringData: PublicOfferingProps;
  setValue: React.Dispatch<React.SetStateAction<PublicOfferingProps>>;
  handleSubmitForm: (e: any) => Promise<ReactText | undefined | void>;
  label: string;
  nameButton: string;
  checkedOfferInCaptation: boolean;
  isLoading: boolean;
  setCheckedOfferInCaptation: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatePublicOfferingForm: React.FC<CreateIPOFormProps> = ({
  handleSubmitForm,
  offeringData,
  setValue,
  label,
  nameButton,
  checkedOfferInCaptation,
  setCheckedOfferInCaptation,
  isLoading,
}) => {
  const history = useHistory();
  const options = ['Geral', 'Qualificado'];

  const [showBonusMessageArea, setShowBonusMessageArea] = useState(
    !!(offeringData.bonusMessage && offeringData.bonusMessage !== '')
  );

  const handleWithTheBonus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowBonusMessageArea(e.target.checked);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'targetAudience',
    defaultValue: 'Geral',
  });

  const group = getRootProps();

  const handleBonusMessageValue = (e: any, inputName: string) => {
    if (e === '<p><br></p>') {
      setValue({
        ...offeringData,
        [inputName]: '',
      });
    } else {
      setValue({
        ...offeringData,
        [inputName]: e,
      });
    }
  };

  const handleChangeValue = useCallback(
    e => {
      const filds = ['title', 'ticker'];
      setValue({
        ...offeringData,
        [e.target.name]: filds.includes(e.target.name)
          ? e.target.value.toUpperCase()
          : e.target.value,
      });
    },
    [offeringData]
  );

  const handleChangeCurrencyValue = useCallback(
    (e, isInvestMin?: boolean) => {
      if (isInvestMin) {
        setValue({
          ...offeringData,
          minimumInvestment: e,
        });
      } else {
        setValue({
          ...offeringData,
          totalVolume: e,
        });
      }
    },
    [offeringData]
  );

  const handleChangeDatePicker = useCallback(
    e => {
      const date = new Date(e.target.value);
      const dateNow = new Date();
      if (isBefore(date, dateNow)) {
        return toast.dark('As datas não podem ser anteriores a data de hoje.');
      }
      handleChangeValue(e);
    },
    [handleChangeValue]
  );

  const handleCheckOfferInCaptation = (isChecked: boolean) => {
    if (isChecked === true) {
      setCheckedOfferInCaptation(true);
      setValue({
        ...offeringData,
        totalVolume: '',
        bookingDeadlines: '',
        expectedSettlement: '',
      });
    } else {
      setCheckedOfferInCaptation(false);
    }
  };

  const handleOnClickCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (offeringData.totalVolume === '') {
      handleCheckOfferInCaptation(true);
    }
  }, []);

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper p={4} maxW={['95vw', '55vw']}>
        <Flex
          justifyContent="space-between"
          alignItems="baseline"
          height="35px"
        >
          <ModalTitle maxWidth={['50%', 'auto']} isTruncated>
            {label}
          </ModalTitle>
          {!offeringData.title && (
            <Checkbox
              size="sm"
              onChange={e => handleCheckOfferInCaptation(e.target.checked)}
            >
              Oferta em captação
            </Checkbox>
          )}
        </Flex>
        <Flex w="100%" h="90%" flexDirection="column">
          <Flex
            w="100%"
            h={['300px', '60%']}
            overflowY={'auto'}
            justifyContent="space-evenly"
            flexDirection="column"
            pt={[8, 0]}
          >
            <InputWithLabel
              name="title"
              mb={1}
              value={offeringData.title}
              label="Título"
              placeholder="Título"
              width={['95%', '100%']}
              onChange={e => handleChangeValue(e)}
            />
            <SimpleGrid
              columns={[1, 3]}
              spacingY="8px"
              spacingX="20px"
              width={['95%', '100%']}
              height="100%"
            >
              <InputWithLabelAndFormatingToMoney
                label="Investimento Minimo"
                name="minimumInvestment"
                value={offeringData.minimumInvestment}
                onChange={e => handleChangeCurrencyValue(e, true)}
              />

              {!checkedOfferInCaptation && (
                <InputWithLabelAndFormatingToMoney
                  label="Volume Total"
                  name="totalVolume"
                  value={offeringData.totalVolume}
                  onChange={e => handleChangeCurrencyValue(e)}
                />
              )}

              <InputWithLabel
                name="amortization"
                value={offeringData.amortization}
                label="Amortização"
                placeholder="Ex.:  1 serie: Bullet (vencimento); 2 se..."
                onChange={e => handleChangeValue(e)}
              />

              <InputWithLabel
                name="interest"
                value={offeringData.interest}
                label="Juros"
                placeholder="Ex.: Semestral, sem carência"
                onChange={e => handleChangeValue(e)}
              />

              <InputWithLabel
                name="ceilingRate"
                value={offeringData.ceilingRate}
                label="Taxa Teto"
                placeholder="Ex.: 1 serie: NTN-B 2028+1,5%; 2 serie: NTN-B 20..."
                onChange={e => handleChangeValue(e)}
              />

              <InputWithLabel
                name="maturityOfTheOperation"
                value={offeringData.maturityOfTheOperation}
                label="Vencimento da operação"
                placeholder="Ex.: 1 serie: 17/04/2028; 2 serie: 14/04/2031"
                onChange={e => handleChangeValue(e)}
              />

              <InputWithLabel
                name="rating"
                value={offeringData.rating}
                label="Rating"
                placeholder="AAA (S&P)"
                onChange={e => handleChangeValue(e)}
              />

              {!checkedOfferInCaptation && (
                <DatePickerChackra
                  value={offeringData.bookingDeadlines}
                  name="bookingDeadlines"
                  label="Reservas até dia"
                  fullColum
                  handlePickDate={e => handleChangeDatePicker(e)}
                />
              )}

              {!checkedOfferInCaptation && (
                <DatePickerChackra
                  name="expectedSettlement"
                  value={offeringData.expectedSettlement}
                  label="Liquidação prevista"
                  fullColum
                  handlePickDate={e => handleChangeDatePicker(e)}
                />
              )}
              <Stack {...group} spacing="0" width="100%" mt="8px">
                <Text fontSize="small" color="white">
                  Público
                </Text>
                <Stack flexDir="row" spacing="0" width="100%">
                  {options.map(value => {
                    const radio = getRadioProps({ value });
                    return (
                      <ButtonRadio
                        key={value}
                        {...radio}
                        onChange={e => handleChangeValue(e)}
                        isChecked={offeringData.targetAudience === value}
                      >
                        {value}
                      </ButtonRadio>
                    );
                  })}
                </Stack>
              </Stack>
            </SimpleGrid>
          </Flex>
          <Flex
            w="100%"
            h="40%"
            justifyContent="space-around"
            flexDirection="column"
          >
            <>
              {showBonusMessageArea && (
                <TextEditorWithLabel
                  label="Bonificação"
                  inputName="bonusMessage"
                  placeholder="Digite mensagem de bonificação"
                  value={
                    offeringData.bonusMessage ? offeringData.bonusMessage : ''
                  }
                  handleBonusMessageAndStrategyOrDescriptionValue={
                    handleBonusMessageValue
                  }
                  newClass="boxBonusMessagePublicOffering"
                />
              )}
              <Flex
                alignItems="center"
                justifyContent="flex-end"
                gap="1"
                pt={['5px', '1px']}
              >
                <Checkbox
                  m="0"
                  isChecked={showBonusMessageArea}
                  onChange={e => handleWithTheBonus(e)}
                />
                <Text>Escrever mensagem de bonificação</Text>
              </Flex>
            </>
          </Flex>
        </Flex>
        <ButtonGroup
          justifyContent="flex-end"
          height="10%"
          width="100%"
          alignItems="flex-end"
          mt={3}
        >
          <SecondaryButton onClick={handleOnClickCancelButton}>
            Cancelar{' '}
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm} isLoading={isLoading}>
            {nameButton}
          </PrimaryButton>
        </ButtonGroup>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};

export default CreatePublicOfferingForm;
