import React from 'react';
import formatValue from '../../../../utils/formatValue';

import { Container, Item } from './styles';

const SharesDetails = ({ shares }) => {
  return (
    <Container>
      <p className="title">Ações:</p>

      <div className="items">
        <Item>
          <p className="header">Ticker</p>
          <p className="header">Financeiro</p>
          <p className="header">Tipo</p>
        </Item>
        {shares?.map(share => (
          <Item key={share.id}>
            <p>{share.ticker}</p>
            <p>{formatValue(Number(share.financial))}</p>
            <p>{share.type.toLowerCase()}</p>
          </Item>
        ))}
      </div>
    </Container>
  );
};

export default SharesDetails;
