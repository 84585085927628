import {
  SEND_EMPLOYEE_PERMISSIONS,
  SEND_EMPLOYEE_CONTRACT,
  DENIE_EMPLOYEE_FORM_BY_MANAGER,
  CONFIRM_EMPLOYEE_DATA_BY_MANAGER,
  RH_FEEDBACK_ABOUT_EMPLOYEE_FORM,
  RESET_EMPLOYEE_STATE,
} from './actionTypes';

const INITIAL_STATE = {
  acceptedByManager: false,
  acceptedByRh: false,
  employeeData: {},
  permissions: {},
};

function employeeFormReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONFIRM_EMPLOYEE_DATA_BY_MANAGER:
      return {
        ...state,
        acceptedByManager: true,
        employeeData: {
          ...action.payload,
        },
      };

    case SEND_EMPLOYEE_PERMISSIONS:
      return {
        ...state,
        employeeData: {
          ...state.employeeData,
          ...action.payload.employeePosition,
          ...action.payload.rvResponsibleId,
        },
        permissions: {
          ...action.payload.permissions,
        },
      };

    case SEND_EMPLOYEE_CONTRACT:
      return {
        ...state,
        employeeData: {
          ...state.employeeData,
          ...action.payload,
        },
      };

    case DENIE_EMPLOYEE_FORM_BY_MANAGER:
      return {
        ...state,
        acceptedByManager: false,
      };

    case RH_FEEDBACK_ABOUT_EMPLOYEE_FORM:
      return {
        ...state,
        acceptedByRh: action.payload,
      };

    case RESET_EMPLOYEE_STATE:
      return {
        acceptedByManager: false,
        acceptedByRh: false,
        employeeData: {},
        employeePermission: {},
      };

    default:
      return state;
  }
}

export default employeeFormReducer;
