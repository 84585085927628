import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import TitleStructured from './TitleStructured';

import { IinitialStructuredData } from '../data';

interface IAddTitleStructuredProps {
  structuredData: IinitialStructuredData;
  setStructuredData: React.Dispatch<
    React.SetStateAction<IinitialStructuredData>
  >;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexOf: number
  ) => void;
}

const AddTitleStructured: React.FC<IAddTitleStructuredProps> = ({
  structuredData,
  setStructuredData,
  handleChangeValue,
}) => {
  const handleAddOrder = () => {
    const newOrderId =
      structuredData.coupons[structuredData.coupons.length - 1].id + 1;
    const newOrder = {
      id: newOrderId,
      title: '',
      value: '',
      type: '',
      delAbled: true,
    };

    setStructuredData({
      ...structuredData,
      coupons: [...structuredData.coupons, newOrder],
    });
  };

  const handleDeleteOrder = (removedOrderId: number) => {
    const orderFiltereds = structuredData.coupons.filter(
      order => order.id !== removedOrderId
    );

    setStructuredData({
      ...structuredData,
      coupons: orderFiltereds,
    });
  };

  return (
    <Box>
      <Box w="100%" whiteSpace="nowrap" overflow="auto" maxH="265px">
        {structuredData.coupons.map((order, index) => (
          <TitleStructured
            key={index}
            index={index}
            order={order}
            handleDeleteOrder={handleDeleteOrder}
            handleChangeValue={handleChangeValue}
          />
        ))}
      </Box>

      <Flex
        justifyContent="flex-start"
        m="6px 0"
        fontSize="smaller"
        color="white"
        cursor="pointer"
        onClick={handleAddOrder}
      >
        <AddIcon w={3} h={3} mr="1.5" mt="0.5" />
        <span>Adicionar</span>
      </Flex>
    </Box>
  );
};

export default AddTitleStructured;
