import React, { useEffect, useState } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { LoadingSpinner } from 'components/elements/others';
import { MeetingCard } from './MeetingCard';
import { IAnnotations } from '../../interfaces';
import { BoxInfo } from '../BoxInfo';

interface IMosaicOfMeetingsProps {
  employeeId: string;
  selectViewedMeetings: boolean;
  isLoggedUserManager: boolean;
}

export const MosaicOfMeetings: React.FC<IMosaicOfMeetingsProps> = ({
  employeeId,
  selectViewedMeetings,
  isLoggedUserManager,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [annotations, setAnnotations] = useState<IAnnotations[]>([]);
  const [noMeeting, setNoMeeting] = useState(false);

  useEffect(() => {
    if (employeeId !== '') {
      getMyClientsAnnotations();
    }
  }, [employeeId]);

  const getMyClientsAnnotations = async () => {
    setIsLoading(true);
    try {
      const response = await hubApiUser.getAnnotations(employeeId);
      setAnnotations(response);
      setNoMeeting(handleGetIfTheresAMeeting(response));
      setIsLoading(false);
    } catch (error) {
      toast.dark(
        'Não foi possível obter os dados da reunião, tente novamente em alguns minutos'
      );
      setIsLoading(false);
    }
  };

  const handleGetIfTheresAMeeting = (annotations: IAnnotations[]) => {
    let haveAMeeting = false;

    annotations.forEach(annotation =>
      annotation.clients.forEach(client => {
        if (client.meetings && client.meetings?.length > 0) {
          haveAMeeting = true;
        }
      })
    );

    return haveAMeeting;
  };
  return (
    <Flex
      maxH={['55vh', 'calc(100vh - 200px)']}
      width="100%"
      justifyContent="center"
      alignContent="center"
      overflowY="auto"
      mt={23}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        !isLoading &&
        noMeeting &&
        employeeId && (
          <Grid
            templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
            gap={6}
            width={['90vw', '91%']}
          >
            {annotations.map(annotation => (
              <MeetingCard
                key={annotation.assessorId}
                annotation={annotation}
                selectViewedMeetings={selectViewedMeetings}
              />
            ))}
          </Grid>
        )
      )}
      {!employeeId && (
        <BoxInfo
          textInfo={
            isLoggedUserManager
              ? 'Selecione uma mesa e o AAI para exibir as reuniões'
              : 'Selecione um  AAI para exibir as reuniões'
          }
        />
      )}

      {!isLoading && employeeId && !noMeeting && (
        <BoxInfo textInfo="O AAI selecionado não possui reuniões." />
      )}
    </Flex>
  );
};
