/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import {
  NumberInputStepper,
  NumberInputField,
  NumberInput,
  NumberInputProps,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Text,
} from '@chakra-ui/react';

interface InputNumberWithLabelProps extends NumberInputProps {
  stepper: boolean;
  label: string;
  width?: string;
  place?: string;
}

export const InputNumberWithLabel: React.FC<InputNumberWithLabelProps> = ({
  stepper,
  label,
  width,
  place,
  ...rest
}) => {
  if (stepper) {
    return (
      <Flex flexDir="column">
        <Text mb="6px" fontSize="small" color="rgba(70, 70, 70, 1)">
          {label}
        </Text>

        <NumberInput
          w={width}
          bg="white"
          borderRadius="3px"
          size="sm"
          borderColor="transparent"
          _hover={{ borderColor: 'primary.800' }}
          {...rest}
        >
          <NumberInputField
            color="rgba(70, 70, 70, 1)"
            onChange={() => {}}
            placeholder={place}
            _hover={{ borderColor: 'primary.800' }}
            _placeholder={{ color: 'gray.400' }}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Flex>
    );
  }
  return (
    <Flex flexDir="column">
      <Text mb="6px" fontSize="small" color="rgba(70, 70, 70, 1)">
        {label}
      </Text>

      <NumberInput
        w={width}
        {...rest}
        bg="white"
        borderRadius="3px"
        size="sm"
        borderColor="#1d63ddb2"
        _hover={{ borderColor: 'primary.800' }}
      >
        <NumberInputField
          onChange={() => {}}
          color="rgba(70, 70, 70, 1)"
          placeholder={place}
          _hover={{ borderColor: 'primary.800' }}
          _placeholder={{ color: 'gray.400' }}
        />
      </NumberInput>
    </Flex>
  );
};
