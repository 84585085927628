import { Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { ModalWrapper } from 'components/elements/wrappers';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { useUpdateMeeting } from 'hooksV2/useUpdateMeeting';
import { transferLeadsObservationsToClientNotes } from 'pages/Captation/utils';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
};

type NewClientMutationPropsType = {
  btgAccount: string;
};

export const OpenAccountModal: React.FC<PropsType> = ({
  lead,
  leadType,
}): JSX.Element => {
  const { getParam, setParam, deleteParam } = useQueryParams();

  const { goBack } = useNavigation();

  const [clientWhoAlreadyHaveThisBtgCode, setClientWhoAlreadyHaveThisBtgCode] =
    useState<string>('');

  const {
    filterValue: isOpenClientFromTypeAlreadyDefinedModal,
    handleChangeFilterValue:
      handleChangeIsOpenClientFromTypeAlreadyDefinedModal,
    handleDeleteParam: handleDeleteIsOpenClientFromTypeAlreadyDefinedModal,
  } = useSimpleQueryFilter('isOpenClientFromTypeAlreadyDefinedModal');

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions();

  const { mutateAsync: updateMeeting, isLoading: isLoadingUpdateMeeting } =
    useUpdateMeeting();

  const { mutateAsync: updateLead, isLoading: isLoadingUpdateLead } =
    useUpdateLead();

  const { mutateAsync: notifyNewClient, isLoading: isLoadingNotifyNewClient } =
    useMutation({
      mutationKey: ['newClient'],
      mutationFn: async (data: NewClientMutationPropsType) => {
        await meetingAction({
          action: MeetingActionEnum.OPEN_ACCOUNT,
          leadType,
          meetingId: lead?.meeting?.id as string,
          btgAccount: data.btgAccount,
        });
      },
      onError: err => {
        const error = err as AxiosError;
        if (
          error.response?.data.message.includes(
            'Client already has from type defined'
          )
        ) {
          showToast('Esse cliente já tem um origem definida');

          // Error message Example: Client already has from type defined. Client: <clientName>
          const clientName = error.response?.data.message.split(': ')[1];
          setClientWhoAlreadyHaveThisBtgCode(clientName);

          handleChangeIsOpenClientFromTypeAlreadyDefinedModal('true');
          return;
        }

        showToast('Não foi possível abrir a conta');
      },
    });

  const isLoading =
    isLoadingMeetingAction ||
    isLoadingNotifyNewClient ||
    isLoadingUpdateMeeting ||
    isLoadingUpdateLead;

  const clientBtgCode = getParam('openAccountClientBtgCode');

  const handleOpenAccount = async () => {
    if (!clientBtgCode) return showToast('Preencha o código BTG do cliente');

    await notifyNewClient({
      btgAccount: clientBtgCode,
    });

    deleteParam('isOpenLeadOpenAccountModal');
  };

  const handleRemoveMeeting = async () => {
    await updateMeeting({
      ...lead?.meeting,
      status: MeetingStatusEnum.ALOCADO,
    });

    await updateLead({
      leadId: lead?.id as string,
      type: leadType,
      btgAccount: clientBtgCode,
    });

    await transferLeadsObservationsToClientNotes(
      lead?.id as string,
      clientBtgCode
    );

    showToast('Conta vinculada com sucesso!');

    goBack();
  };

  return (
    <>
      <Modal.Root>
        <Modal.Header>
          <Modal.Title title="Abertura de conta" />
          <Modal.CloseIcon
            onClick={() => deleteParam('isOpenLeadOpenAccountModal')}
          />
        </Modal.Header>

        <Input.Root>
          <Input.Label text="Código BTG" />

          <Input.TextField
            placeholder="Digite o código BTG do cliente"
            value={clientBtgCode}
            onChange={event =>
              setParam('openAccountClientBtgCode', event.target.value)
            }
          />
        </Input.Root>

        <Modal.Actions>
          <Modal.Action
            text="Confirmar"
            onClick={handleOpenAccount}
            isLoading={isLoading}
          />
        </Modal.Actions>
      </Modal.Root>

      <ModalWrapper
        isOpen={isOpenClientFromTypeAlreadyDefinedModal === 'true'}
        onClose={handleDeleteIsOpenClientFromTypeAlreadyDefinedModal}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title
              title={`A conta BTG informada está vinculada ao cliente ${clientWhoAlreadyHaveThisBtgCode}. Deseja prosseguir?`}
            />
            <Modal.CloseIcon
              onClick={handleDeleteIsOpenClientFromTypeAlreadyDefinedModal}
            />
          </Modal.Header>

          <Text>
            * Ao confirmar o lead será removido do seu painel de reuniões e as
            anotações salvas serão transferidas para a aba de notas do cliente
            (Meus clientes &gt; Painel do cliente)
          </Text>

          <Modal.Actions>
            <Modal.Action
              text="Confirmar"
              onClick={handleRemoveMeeting}
              isLoading={isLoading}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </>
  );
};
