import { Flex, Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';

interface CurrentScoreProps {
  name?: string;
  value?: string;
  leadsQtd?: string;
  isPorcent?: boolean;
  isInflation?: boolean;
}

export const CurrentScore: React.FC<CurrentScoreProps> = ({
  name,
  value,
  leadsQtd,
  isPorcent,
  isInflation = false,
}) => {
  return (
    <Flex
      border="1px solid gray"
      borderRadius="3"
      py="1"
      px="2"
      align="center"
      justify="space-between"
    >
      <Text fontSize="xs" color="gray.800" fontWeight="semibold">
        {name &&
          formatToCapitalized(name, {
            trimTrailingWhiteSpaces: false,
          })}
      </Text>

      <Flex>
        {isInflation ? (
          <>
            {isPorcent ? (
              <Text
                ml="10"
                fontSize="small"
                textAlign="center"
                color="gray.100"
              >
                {value} %
              </Text>
            ) : (
              <Text ml="10" fontSize="small" textAlign="right" color="gray.100">
                {value}
              </Text>
            )}
          </>
        ) : (
          <>
            <Text fontSize="small" textAlign="center" w="70px" color="gray.100">
              {Math.round(Number(value))}
            </Text>
            <Text w="30px" fontSize="small" color="gray.100" textAlign="center">
              {leadsQtd}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};
