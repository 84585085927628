import { IAgendaData } from 'pages/UserAgenda';
import formatValue from 'utils/formatValue';

type DataType = {
  name: string;
  value: string;
};

type UseEmployeeAgendaHeaderDataResponseType = {
  employeeAgendaHeaderData: DataType[];
};

export const useEmployeeAgendaHeaderData = (
  agendaData: IAgendaData | undefined
): UseEmployeeAgendaHeaderDataResponseType => {
  if (!agendaData) {
    return {
      employeeAgendaHeaderData: [],
    };
  }

  const employeeAgendaHeaderData: DataType[] = [
    {
      name: 'Ligações:',
      value: (
        Number(agendaData.totalTodayContactAttempts) +
        Number(agendaData.totalTodayMeetingsActions)
      ).toString(),
    },
    {
      name: 'Atendimentos:',
      value: agendaData.totalTodayMeetingsActions,
    },
    {
      name: 'R. Marcadas:',
      value: agendaData.totalTodayScheduledMeetings,
    },
    {
      name: 'Avanço CRM:',
      value: agendaData.totalTodayMeetingsInCRM,
    },
    {
      name: 'R. Declinadas:',
      value: agendaData.totalTodayDeclinedMeetings,
    },
    {
      name: 'T. Pipe:',
      value: formatValue(Number(agendaData.totalMeetingsPipeValue)).split(
        ','
      )[0],
    },
  ];

  return {
    employeeAgendaHeaderData,
  };
};
