import React, { useState } from 'react';
import { Flex, Input, Stack, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { formatToPhone } from 'brazilian-values';

import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { InputWithLabel } from 'components/elements/forms';
import { IBaseLead } from 'pages/Captation/interfaces';
import { Dropdown } from 'components/elements/others';

interface ComponentProps {
  leadType: string;
  handleToggleModal: () => void;
  manualLeads: IBaseLead[];
  setManualLeads: React.Dispatch<React.SetStateAction<IBaseLead[]>>;

  setFilterManual: React.Dispatch<React.SetStateAction<IBaseLead[]>>;
}

export const CreateManualLead: React.FC<ComponentProps> = ({
  leadType,
  handleToggleModal,
  manualLeads,
  setManualLeads,

  setFilterManual,
}) => {
  const originsOptions = [
    {
      value: 'Escolha uma Origem',
    },
    {
      value: 'LinkedIn',
    },
    {
      value: 'Lista Fria',
    },
    {
      value: 'Indicação',
    },
    {
      value: 'Relacionamento dia-a-dia',
    },
    {
      value: 'Outro',
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [originDropdownValue, setOriginDropdownValue] = useState<string>('');
  const [customOriginValue, setCustomOriginValue] = useState<string>('');
  const [newLead, setNewLead] = useState({
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    phone3: '',
    status: 'ok',
    reason: '',
    interest: '',
    feedback: '',
    btgAccount: '',
  });

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLead({ ...newLead, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (
      originDropdownValue === 'Escolha uma Origem' ||
      originDropdownValue === ''
    ) {
      setIsLoading(false);
      return toast.dark('Escolha uma origem para o lead');
    }

    try {
      toast.dark(
        'Estamos verificando se este prospect já não está em nossa base sendo utilizado, aguarde'
      );

      const newManualLead = await hubApiCaptation.AddNewLeadManual({
        ...newLead,
        phone: [
          {
            phone: newLead.phone1.replace(/\D/g, ''),
            leadType,
          },
          {
            phone: newLead.phone2.replace(/\D/g, ''),
            leadType,
          },
          {
            phone: newLead.phone3.replace(/\D/g, ''),
            leadType,
          },
        ],
        email: [
          {
            email: newLead.email || '',
            leadType,
          },
        ],
        phone1: newLead.phone1.replace(/\D/g, ''),
        phone2: newLead.phone2.replace(/\D/g, ''),
        phone3: newLead.phone3.replace(/\D/g, ''),
        origin:
          originDropdownValue === 'Outro'
            ? customOriginValue
            : originDropdownValue,
      });
      setNewLead({
        name: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        status: 'ok',
        reason: '',
        interest: '',
        feedback: '',
        btgAccount: '',
      });

      setManualLeads([...manualLeads, newManualLead]);
      setFilterManual([...manualLeads, newManualLead]);

      toast.dark('Lead criado com sucesso.');
      setIsLoading(false);
      handleToggleModal();
    } catch (err) {
      if (err.response?.status === 409) {
        toast.dark(
          'Este lead já existe na base de clientes, LGPD ou está em prospecção'
        );
        setIsLoading(false);
        return;
      }
      toast.dark(
        'Não foi possível enviar o lead, tente novamente em alguns minutos'
      );
      setIsLoading(false);
    }
  };

  return (
    <BaseModalFormCard
      title="Adicionar lead"
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      isModal
    >
      <Stack spacing="3.5">
        <InputWithLabel
          label="Nome"
          name="name"
          placeholder="Digite o nome"
          value={newLead.name}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="E-mail"
          name="email"
          placeholder="Digite o e-mail"
          value={newLead.email}
          onChange={handleChangeValue}
        />

        <Flex gridGap="2">
          <InputWithLabel
            label="Telefone 1"
            name="phone1"
            placeholder="Ex. 1197997792"
            value={formatToPhone(newLead.phone1)}
            onChange={handleChangeValue}
            boxWidth="100%"
          />
          <InputWithLabel
            label="Telefone 2"
            name="phone2"
            placeholder="Ex. 1197997792"
            value={formatToPhone(newLead.phone2)}
            onChange={handleChangeValue}
            boxWidth="100%"
          />
          <InputWithLabel
            label="Telefone 3"
            name="phone3"
            placeholder="Ex. 1197997792"
            value={formatToPhone(newLead.phone3)}
            onChange={handleChangeValue}
            boxWidth="100%"
          />
        </Flex>
        <Flex flexDirection="column" gap={2}>
          <Text fontSize="sm">Origem</Text>
          <Dropdown
            h="25px"
            value={originDropdownValue}
            onChange={event => {
              setOriginDropdownValue(event.target.value);
            }}
            background="background.500"
            border="none"
          >
            {originsOptions.map(origin => (
              <option value={origin.value}>{origin.value}</option>
            ))}
          </Dropdown>

          {originDropdownValue === 'Outro' && (
            <Input
              size="xs"
              color="white"
              margin="0"
              background="background.500"
              borderColor="transparent"
              focusBorderColor="primary.600"
              placeholder="Origem do Lead"
              value={customOriginValue}
              onChange={event => setCustomOriginValue(event.target.value)}
              _hover={{ borderColor: 'primary.800' }}
              _placeholder={{ color: 'gray.900', fontStyle: 'italic' }}
            />
          )}
        </Flex>
      </Stack>
    </BaseModalFormCard>
  );
};
