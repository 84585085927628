import { Flex, FlexProps } from '@chakra-ui/react';

export const KPIsSectionBox: React.FC<FlexProps> = ({ children }) => {
  return (
    <Flex
      px={12}
      py={4}
      alignItems="center"
      flexDirection="column"
      bg="background.700"
      borderRadius={8}
      w="32%"
    >
      {children}
    </Flex>
  );
};
