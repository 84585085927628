import { Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineEdit } from 'react-icons/ai';
import { InformalInfos } from 'pages/MyClients/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BaseModalFormCard } from 'components/elements/cards';

interface QuestionsAndAnswersItemProps {
  question: InformalInfos;
  signalToUpdate: boolean;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  onlyVizMode: boolean;
}

export const QuestionsAndAnswersItem: React.FC<QuestionsAndAnswersItemProps> =
  ({ question, signalToUpdate, setSignalToUpdate, onlyVizMode }) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [questionItem, setQuestionItem] = useState({
      question: question.question,
      answer: question.answer,
    });

    const handleChangeValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setQuestionItem({ ...questionItem, [e.target.name]: e.target.value });
    };

    const handleEditQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const updateQuestionData = {
        id: question.id as string,
        clientId: question.clientId,
        question: questionItem.question,
        answer: questionItem.answer,
      };

      try {
        await hubApiUser.updateInformalInfos(updateQuestionData);

        setSignalToUpdate(!signalToUpdate);
        toast.dark('Informação atualizada com sucesso');
        setShowEditModal(false);
      } catch (err) {
        toast.dark(
          'Não foi possível atualizar a informação, tente novamente em alguns minutos'
        );
      }
    };

    const handleDeleteQuestion = async () => {
      try {
        await hubApiUser.deleteInformalInfos(question.id as string);

        setSignalToUpdate(!signalToUpdate);
        toast.dark('Informação excluída com sucesso');
        setShowEditModal(false);
      } catch (err) {
        toast.dark(
          'Não foi possível excluir a informação, tente novamente em alguns minutos'
        );
      }
    };

    return (
      <>
        <Flex
          width="100%"
          borderRadius={'md'}
          maxHeight={'60px'}
          minHeight={'60px'}
          background="background.400"
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Flex justifyContent={'center'} alignItems={'center'} width={'95%'}>
            <Text w="100%" isTruncated fontSize="sm">
              {question?.question}
            </Text>

            {!onlyVizMode && (
              <AiOutlineEdit
                size={16}
                cursor="pointer"
                onClick={() => setShowEditModal(true)}
              />
            )}
          </Flex>

          <Text
            color="white.300"
            isTruncated
            fontSize="sm"
            justifyContent={'center'}
            alignItems={'center'}
            width={'95%'}
          >
            {question?.answer}
          </Text>
        </Flex>

        <ModalWrapper
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
        >
          <BaseModalFormCard
            isModal
            maxW={['95vw', '516px']}
            title="Editar informação"
            isLoading={false}
            handleFormSubmit={e => handleEditQuestion(e)}
            secondaryButtonEventFunction={handleDeleteQuestion}
            primaryButtonText="Salvar nova informação"
            secondaryButtonText="Excluir informação"
          >
            <InputWithLabel
              mb={4}
              label="Pergunta / Dado"
              name="question"
              maxLength={30}
              onChange={handleChangeValue}
              value={questionItem.question}
            />
            <InputWithLabel
              label="Resposta / Valor"
              name="answer"
              maxLength={30}
              onChange={handleChangeValue}
              value={questionItem.answer}
            />
          </BaseModalFormCard>
        </ModalWrapper>
      </>
    );
  };
