import { TableData, TableRow } from 'components/elements/table';
import { IInfosSH } from 'pages/GenerationalVariables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ITableRowProps {
  sh: IInfosSH;
  handleGetLeadsSh: (id: string) => Promise<void>;
}

export const TableRowSh: React.FC<ITableRowProps> = ({
  sh,
  handleGetLeadsSh,
}) => {
  const textColor = () => {
    if (['discontinued', 'not-hired'].includes(sh.statusemployee))
      return 'red.300';

    if (sh.status === 'inactive' || sh.status === null) return 'orange';

    return 'white';
  };

  return (
    <>
      <TableRow
        _hover={{ background: 'background.300' }}
        transition="ease-in 0.2s"
        cursor="pointer"
        bg={sh.isSelected === true ? 'background.400' : 'background.600'}
        onClick={() => handleGetLeadsSh(sh.id)}
        title={
          textColor() === 'red.300'
            ? 'SH descontinuado'
            : textColor() === 'orange'
            ? 'SH inativo'
            : 'SH ativo'
        }
      >
        <TableData maxW="160px " isTruncated color={textColor as any}>
          {formatNameToCaptalize(sh.name)}
        </TableData>

        <TableData maxW="180px " isTruncated>
          {sh.leadsOk}
        </TableData>
        <TableData maxW="180px " isTruncated>
          {sh.leads}
        </TableData>
      </TableRow>
    </>
  );
};
