import React from 'react';
import { HeadDataType } from '../../base';

type PropsType = {
  headData: HeadDataType[];
  children: React.ReactNode;
};

type UseTableColumnWidthResponseType = React.ReactElement;

export const useTableColumnWidth: React.FC<PropsType> = ({
  headData,
  children,
}): UseTableColumnWidthResponseType => {
  const renderChildrenWithWidth = () => {
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          style: {
            ...child.props.style,
            width: `${headData[index].widthPercentage}%`,
          },
        });
      }
      return child;
    });
  };

  return <>{renderChildrenWithWidth()}</>;
};
