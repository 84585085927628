import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { Divider } from 'components/elements/others';
import { ILeadsPurchasedOrder } from 'pages/Captation/interfaces';
import { useState } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { toast } from 'react-toastify';
import { basicDate } from 'utils/formatDate';
import { transformReaisInMibs } from 'utils/transformReaisInMibs';

interface IProps {
  order: ILeadsPurchasedOrder;
  updateOrder: UseMutateAsyncFunction<
    any,
    unknown,
    {
      orderId: string;
      status: 'active' | 'finished';
    },
    unknown
  >;
}

export const LeadsPurchaseOrdersCard: React.FC<IProps> = ({
  order,
  updateOrder,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const progressPercentage = Math.round(
    (order.quantityDelivered / order.quantity) * 100
  );

  const handleFinishOrder = async () => {
    setIsLoading(true);
    await updateOrder({
      orderId: order.id,
      status: 'finished',
    });
    setIsLoading(false);
  };

  return (
    <Flex
      h="160px"
      w="24%"
      p={2}
      flexDirection="column"
      backgroundColor="background.700"
      borderRadius={5}
      justifyContent="space-between"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="sm" color="white">
          Ordem - [{order.orderIdentificationId}]
        </Text>
        <Flex alignItems="center" gap={2}>
          <Tooltip
            label={
              order.status === 'active' ? 'Ordem ativa' : 'Ordem finalizada'
            }
            bg="background.200"
            placement="top"
          >
            <Flex
              w="10px"
              h="10px"
              borderRadius={5}
              backgroundColor={
                order.status === 'active' ? 'green.500' : 'red.500'
              }
            />
          </Tooltip>
          {order.status === 'active' && (
            <SecondaryButton
              size="xs"
              onClick={handleFinishOrder}
              isLoading={isLoading}
            >
              Finalizar
            </SecondaryButton>
          )}
        </Flex>
      </Flex>
      <Divider />
      <Text fontSize="xs">Data: {basicDate(order.created_at)}</Text>
      <Text fontSize="xs">Leads: {order.purchasedPLTags.toString()}</Text>
      <Text fontSize="xs">
        Qtd.: {`${order.quantity} (${order.quantityDelivered} recebidos)`}
      </Text>
      <Text fontSize="xs">
        Custo: {`${Math.floor(transformReaisInMibs(order.totalExpenses))} MIBs`}
      </Text>

      <Flex alignItems="center" gap={2}>
        <Flex>
          <Text fontSize="xs">Progresso:</Text>
        </Flex>
        <Tooltip label={`${progressPercentage}%`} placement="top" bg="none">
          <Flex w="100%" border="1px solid gray" h="8px" borderRadius={10}>
            <Flex
              w={`${progressPercentage}%`}
              backgroundColor="blue.300"
              h="7px"
              borderRadius={10}
            />
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
