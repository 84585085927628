import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MeetingType } from 'sharedV2/types/meeting';

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?:
    | ((
        error: unknown,
        variables: Partial<any>,
        context: void | undefined
      ) => void | Promise<unknown>)
    | undefined;
  onMutate?: (newItem: any) => void;
  onSettled?: () => void;
};

export const useUpdateMeeting = (
  props?: PropsType
): UseMutationResult<unknown, unknown, Partial<MeetingType>, unknown> => {
  return useMutation({
    mutationKey: ['updateMeeting'],
    mutationFn: async (data: Partial<MeetingType>) => {
      const response = await hubApiCaptation.updateMeeting(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onMutate: props?.onMutate,
    onSettled: props?.onSettled,
  });
};
