import { PrimaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { TextareaWithLabel } from 'components/elements/forms';

interface AnnotationModalProps {
  handleChangeAnnotationValue: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleCloseAnnotationModal: () => void;
  clientAnnotation: string;
}

export const AnnotationModal: React.FC<AnnotationModalProps> = ({
  handleChangeAnnotationValue,
  handleCloseAnnotationModal,
  clientAnnotation,
}) => {
  return (
    <DefaultModalContentWrapper w="450px">
      <TextareaWithLabel
        label="Anotações"
        defaultValue={clientAnnotation}
        onChange={e => handleChangeAnnotationValue(e)}
      />

      <BaseButtonGroup>
        <PrimaryButton onClick={handleCloseAnnotationModal}>
          Fechar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
