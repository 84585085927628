import React, { useEffect, useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { BackPageButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { TableBase } from 'components/elements/table';

import { IReduxState } from 'shared/interfaces';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { errorHandler } from 'utils/errorHandler';
import { OrderTableRow } from '../../components/modules/RequestStructuredOrders/TableRow';
import {
  advancedOrdersHeader,
  // statusRequestedStructuredOrders,
  actionsRequestedStructuredOrders,
} from './data';

import { OrdersStructuredProps } from './interfaces';

// estou deixando algumas coisas comentadas pois o end-point de buscar ordens não foi feito para ter filtros e o filtro estava sendo alterado de uma maneira que eu nao conseguia manipular os stados, então resolvi deixar comentado e quando a rota for atualizada eu consiga somente adaptar poucas coisas para o funcionamentod da pagina

const RequestedStructuredOrders: React.FC = () => {
  // const [orders, setOrders] = useState<OrdersStructuredProps[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<OrdersStructuredProps[]>(
    []
  );
  // const [state, setState] = useState(statusRequestedStructuredOrders);
  const [showOrder, setShowOrder] = useState(false);
  const [isMainChecked, setIsMainChecked] = useState(false);
  const userLog = useSelector((state: IReduxState) => state.user.profile);

  const getOrders = async () => {
    await hubApiRv
      .getStructuredByUserId(userLog.id)
      .then(response => {
        response.forEach((item: OrdersStructuredProps) => {
          item.isChecked = false;
        });

        // setOrders(response);
        setFilteredOrders(response);
      })
      .catch((error: string) => {
        errorHandler(error);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  // const handleSelectStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const filter = e.target.value;
  //   if (filter === 'Selecione um status') {
  //     setFilteredOrders(orders);
  //   } else {
  //     setFilteredOrders(
  //       orders.filter(
  //         order => order.status.toUpperCase() === filter.toUpperCase()
  //       )
  //     );
  //   }
  // };

  const handleSelectAction = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newStatus = e.target.value;
      if (newStatus === 'Selecione um status') {
        toast.dark('Você não pode salvar o status com essa opção');
        return;
      }
      const newArr: OrdersStructuredProps[] = [];

      filteredOrders.forEach(item => {
        if (item.isChecked === true) {
          newArr.push(item);
        }
      });
      let err = false;
      if (newArr.length > 0) {
        newArr.forEach(item => {
          if (item.status === newStatus) {
            err = true;
          }
        });
      }
      if (err) {
        setIsMainChecked(false);
        return toast.dark(
          `Uma ou mais ordens ja estavam com o status ${newStatus} e por isso não foram atualizadas.`
        );
      }

      if (newArr.length > 0) {
        try {
          await hubApiRv
            .updateStatusOfStructured(newArr, newStatus)
            .then(response => {
              const resut = filteredOrders.filter(
                ordens => ordens.isChecked === false
              );

              response.forEach((item: OrdersStructuredProps[]) => {
                Object.assign(item, { isChecked: false });
              });

              const arrayAux = [...response, ...resut];
              setFilteredOrders(arrayAux);
              setShowOrder(false);
              setIsMainChecked(false);
              toast.dark('Ordem atualizada com sucesso');
            });
        } catch (err) {
          toast.dark('A ordem não foi atualizada');
        }
      }
    },
    [filteredOrders]
  );

  const handleToggleCheckbox = (order: OrdersStructuredProps) => {
    const newFilteredOrders = filteredOrders.map(filteredOrder => {
      if (filteredOrder.id === order.id) {
        filteredOrder.isChecked = !filteredOrder.isChecked;
      }
      return filteredOrder;
    });
    setFilteredOrders(newFilteredOrders);
    const result = filteredOrders.filter(
      filteredOrders => filteredOrders.isChecked === true
    );

    if (result.length > 0) {
      setShowOrder(true);
    } else {
      setShowOrder(false);
    }

    if (result.length === newFilteredOrders.length) {
      setIsMainChecked(true);
    } else {
      setIsMainChecked(false);
    }
  };

  const toggleMainCheckbox = () => {
    setIsMainChecked(!isMainChecked);
    const newFilteredOrders = filteredOrders.map(filteredOrder => {
      filteredOrder.isChecked = !isMainChecked;
      return filteredOrder;
    });

    const result = newFilteredOrders.filter(
      filteredOrders => filteredOrders.isChecked === true
    );

    if (result.length > 0) {
      setShowOrder(true);
    } else {
      setShowOrder(false);
    }
    setFilteredOrders(newFilteredOrders);
  };

  return (
    <PageWrapper flexDir="column" alignItems="center">
      <MainPageTitle marginTop="50px" textAlign="center">
        Ordens de Estruturadas Solicitadas
      </MainPageTitle>

      <BackPageButton />

      <Flex w="90%">
        {/* <Box mr={5}>
          <Dropdown onChange={handleSelectStatus}>
            {state.map((item, key) => {
              return (
                <option key={key} value={item.title}>
                  {item.title}
                </option>
              );
            })}
          </Dropdown>
        </Box> */}

        <Box minH="35px">
          {showOrder && (
            <Dropdown onChange={handleSelectAction}>
              {actionsRequestedStructuredOrders.map((item, key) => (
                <option key={key} value={item.title}>
                  {item.title}
                </option>
              ))}
            </Dropdown>
          )}
        </Box>
      </Flex>

      <Box w="90%" mt={5}>
        <TableBase
          headData={advancedOrdersHeader}
          overflowY="auto"
          width="100%"
          isMainChecked={isMainChecked}
          isMainCheckbox
          toggleMainCheckbox={toggleMainCheckbox}
        >
          {filteredOrders.map((item, key) => (
            <OrderTableRow
              key={key}
              rowData={item}
              handleToggleCheckbox={handleToggleCheckbox}
            />
          ))}
        </TableBase>
      </Box>
    </PageWrapper>
  );
};

export default RequestedStructuredOrders;
