import React, { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils/errorHandler';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';

import { COEProps } from 'pages/products/interfaces';
import COECard from 'pages/products/components/modules/coe/COECard';
import { ProductsPageLayout } from 'pages/products/components/layouts';

export const ListCOEs: React.FC = () => {
  const [coes, setCoes] = useState<COEProps[]>([]);

  useEffect(() => {
    hubApiProducts
      .getAllCoe()
      .then(response => {
        setCoes(response);
      })
      .catch(error => {
        toast.dark('Erro ao carregar COEs.');
        errorHandler(error);
      });
  }, []);

  if (coes.length <= 0) {
    return (
      <ProductsPageLayout pageTitle="Certificado de Operações Estruturadas">
        <Text>Não existem COEs cadastrados.</Text>
      </ProductsPageLayout>
    );
  }

  return (
    <ProductsPageLayout pageTitle="Certificado de Operações Estruturadas">
      {coes.map(coe => (
        <COECard coeData={coe} key={coe.id} />
      ))}
    </ProductsPageLayout>
  );
};
