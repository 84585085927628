import styled from 'styled-components';

const InputLabelContent = styled.div`
  width: 100%;
  display: flex;
  color: var(--white-color-100);
  flex-direction: column;
  margin-bottom: 8px;

  .main-label {
    margin-bottom: 4px;
    font-size: var(--smaller-text);
  }

  .labelMarginLeft {
    margin-bottom: 4px;
    margin-left: 7px;
    font-size: var(--smaller-text);
  }

  input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    font-size: var(--smaller-text);
    background: var(--gray-color-600);
    color: var(--white-color-300);

    &.input-reminder {
      border-radius: 0;
      opacity: 0.7;
      cursor: default;
    }

    &.input-anotations {
      border-radius: 0;
      opacity: 0.7;
      cursor: default;
    }

    &::placeholder {
      color: var(--gray-color-100);
      font-size: 10px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export default InputLabelContent;
