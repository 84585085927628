import { Flex, Stack, Text } from '@chakra-ui/react';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import { transformReaisInMibs } from 'utils/transformReaisInMibs';

interface LeadAAIMosaicCardProps {
  name: string;
  index: number;
  content: {
    employeeName: string;
    details: IBaseMeeting[];
    pl: {
      fieldName: string;
      fieldValue: string;
    }[];
    plBySH?: string | number;
    meetingPrice: number;
  };
  handleSelectedLead: (index: number, meetingPriceInMibs: number) => void;
}

export const LeadAAIMosaicCard: React.FC<LeadAAIMosaicCardProps> = ({
  name,
  index,
  content,
  handleSelectedLead,
}) => {
  const confirmedTask = content.details.filter(item => {
    return (item.status as any) === 'confirmed';
  })[0];

  return (
    <Flex
      w={['100%', '228px']}
      minH="150px"
      bg="white"
      borderRadius="3px"
      border="1px solid #1d63ddb2"
      p={2}
      flexDir="column"
      cursor="pointer"
      transition="300ms"
      justify="space-between"
      _hover={{ background: '#f2f2f2' }}
      onClick={() => {
        handleSelectedLead(index, transformReaisInMibs(content.meetingPrice));
      }}
    >
      <Flex flexDir="column">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" isTruncated mb="2">
          {name.toUpperCase()}
        </Text>
        <Stack spacing="1" fontSize="sm">
          <Text color="rgba(70, 70, 70, 1)">
            Tipo da reunião:{' '}
            <Text as="span" color="rgba(70, 70, 70, 1)">
              {confirmedTask.local || 'Ligação'}
            </Text>
          </Text>

          <Text color="rgba(70, 70, 70, 1)">
            SH:{' '}
            <Text as="span" color="rgba(70, 70, 70, 1)">
              {formatNameToCaptalize(content.employeeName) || '-'}
            </Text>
          </Text>

          {content.details[0].filialName && (
            <Text color="rgba(70, 70, 70, 1)">
              Filial:{' '}
              <Text as="span" color="rgba(70, 70, 70, 1)">
                {content.details[0].filialName}
              </Text>
            </Text>
          )}

          <Text color="rgba(70, 70, 70, 1)">
            Data:{' '}
            <Text as="span" color="rgba(70, 70, 70, 1)">
              {formateDateAndTime(confirmedTask.date).replace(',', ' às')}
            </Text>
          </Text>
        </Stack>
      </Flex>

      <Flex justify="flex-end">
        <Text as="span" fontSize="sm" color="rgba(70, 70, 70, 1)">
          {Math.floor(transformReaisInMibs(content.meetingPrice))} MIBs
        </Text>
      </Flex>
    </Flex>
  );
};
