import { useState } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { ModalTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';
import { InputWithLabel } from 'components/elements/forms';
import { PrimaryButton } from 'components/elements/buttons/PrimaryButton';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MiAaiCaptationData } from 'pages/Captation/interfaces';

interface EditPointsFormProps extends FlexProps {
  employeeId?: string;
  employee?: MiAaiCaptationData;
  employeeRole: 'aai' | 'sh';
  updatePoints: (points: string, operationType: string, role: string) => void;
}

type Operation = 'sum' | 'sub';

export const EditPointsForm: React.FC<EditPointsFormProps> = ({
  employeeId,
  employee,
  employeeRole,
  updatePoints,
  ...rest
}) => {
  const [operationType, setOperationType] = useState<null | Operation>(null);
  const [operationValue, setOperationValue] = useState<null | string>(null);

  const computePointsOperation = async () => {
    try {
      if (operationValue && operationType) {
        const operationInfos = {
          points: operationValue,
          type: operationType,
          nivel: employee?.level,
        };

        if (employee) {
          await hubApiCaptation.patchEmployeePoints(
            operationInfos,
            employeeRole,
            employee.id
          );
        }
        if (employeeId) {
          await hubApiCaptation.patchEmployeePoints(
            operationInfos,
            employeeRole,
            employeeId
          );
        }

        updatePoints(operationValue, operationType, employeeRole);

        toast.dark('Pontuação atualizada com sucesso');
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 404) {
        toast.dark(
          'Não foi possível atualizar a pontuação, verifique se este SH está na tabela de AAI'
        );
      } else {
        toast.dark(
          'Não foi possível atualizar a pontuação, tente novamente em alguns minutos'
        );
      }
    }
  };

  return (
    <Flex px="2" flexDir="column" alignItems="center" {...rest}>
      <ModalTitle>Pontuação {employeeRole.toUpperCase()}</ModalTitle>

      <Dropdown
        placeholder="Selecione a operação"
        w="200px"
        mb="3"
        onChange={e => setOperationType(e.target.value as Operation)}
      >
        <option value="sum">Adicionar pontos</option>
        <option value="sub">Remover pontos</option>
      </Dropdown>

      <InputWithLabel
        label=""
        placeholder="Digite a qtd. de pontos"
        w="204px"
        size="sm"
        mt="2.5"
        type="number"
        onChange={e => setOperationValue(e.target.value)}
      />

      {operationType && (
        <PrimaryButton
          w="100%"
          mt="5"
          fontWeight="bold"
          background={operationType === 'sum' ? 'green.400' : 'red.400'}
          _hover={{
            background: operationType === 'sum' ? 'green.500' : 'red.500',
          }}
          onClick={computePointsOperation}
        >
          {operationType === 'sum' ? 'Adicionar pontos' : 'Remover pontos'}
        </PrimaryButton>
      )}
    </Flex>
  );
};
