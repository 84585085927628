import { useEffect, useState } from 'react';
import { Divider, Flex, useMediaQuery } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { InformalInfos, Reminder } from 'pages/MyClients/interfaces';
import { ClientInfosType } from 'pages/MyClients/interfaces/ProfilePanelData';
import { NewActionsSection } from './NewActionsSection';
import { NextContactsSection } from './NextContactsSection';
import { ClientDataSection } from './ClientDataSection';
import { LastContactsSection } from './LastContactsSection';
import { MeetingSection } from './MeetingSection';

interface ContactProps {
  typeClient: ClientInfosType;
  setTypeClient: React.Dispatch<React.SetStateAction<ClientInfosType>>;

  basicData: {
    label: string;
    value: string;
  }[];
  clientId: string;
  setShowBackPageButton: React.Dispatch<React.SetStateAction<boolean>>;
  onlyVizMode: boolean;
}

export const Contact: React.FC<ContactProps> = ({
  basicData,
  clientId,
  typeClient,
  setShowBackPageButton,
  setTypeClient,
  onlyVizMode,
}) => {
  const [showMeetingSection, setShowMeetingSection] = useState(false);
  const [taskTitle, setTaskTitle] = useState('');
  const [selectedReminder, setSelectedReminder] = useState({} as Reminder);
  const [clientReminders, setClientReminders] = useState<Reminder[]>([]);
  const [informalInfos, setInformalInfos] = useState<InformalInfos[]>([]);
  const [signalToUpdate, setSignalToUpdate] = useState(false);
  const [clientLastReminders, setClientLastReminders] = useState<Reminder[]>(
    []
  );
  const [
    signalToUpdateNextContactsSection,
    setSignalToUpdateNextContactsSection,
  ] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  setShowBackPageButton(true);
  useEffect(() => {
    getInformalClientInfos();
  }, [signalToUpdate, showMeetingSection]);

  useEffect(() => {
    getClientReminders();
  }, [signalToUpdate]);

  const getClientReminders = async () => {
    try {
      const response: Reminder[] = await hubApiUser.getAnotationsMessage(
        clientId
      );
      const filterOpenReminders = response.filter(
        reminder => reminder.realized
      );

      filterOpenReminders.sort((item, proItem) => {
        let result = -1;

        if (item.updated_at < proItem.updated_at) {
          result = 1;
        } else if (item.updated_at === proItem.updated_at) {
          result = 0;
        }
        return result;
      });
      setClientLastReminders([...filterOpenReminders]);
    } catch (error) {
      toast.dark('Não foi possível buscar os lembretes do cliente');
    }
  };

  const getInformalClientInfos = async () => {
    try {
      const response = await hubApiUser.getInformalInfos(clientId);

      setInformalInfos(response);
    } catch (error) {
      toast.dark('Falha ao obter as informações adicionais');
    }
  };

  const handleShowMeetingSection = (taskType?: string) => {
    if (taskType) {
      setTaskTitle(taskType);
    }

    setShowMeetingSection(!showMeetingSection);
  };

  const handleChangeStatusMeetingSection = () => {
    setTaskTitle('Reunião Instantânea');
    setShowMeetingSection(!showMeetingSection);
  };

  const handleSelectedReminder = (reminder: Reminder) => {
    setSelectedReminder(reminder);
  };

  return (
    <Flex
      flexDir={['column', 'column', 'row']}
      gridGap="8"
      height={['60vh', 'calc(100vh - 224px)']}
    >
      {showMeetingSection ? (
        <MeetingSection
          onlyVizMode={onlyVizMode}
          setShowBackPageButton={setShowBackPageButton}
          signalToUpdate={signalToUpdate}
          setSignalToUpdate={setSignalToUpdate}
          informalInfos={informalInfos}
          reminder={selectedReminder}
          closeMeetingSection={handleShowMeetingSection}
          basicData={basicData}
          taskTitle={taskTitle}
        />
      ) : (
        <Flex
          flexDir="column"
          w="100%"
          justifyContent="space-between"
          overflowY={['auto', 'unset']}
        >
          <Flex gridGap={4} h="100%" flexDir={['column', 'row']}>
            <Flex
              flexDir="column"
              gridGap={2}
              width={['100%', '50%']}
              height="100%"
              maxWidth={['100%', '50%']}
            >
              <NewActionsSection
                onlyVizMode={onlyVizMode}
                clientId={clientId}
                frequencyM={typeClient.frequencyM}
                signalToUpdate={signalToUpdateNextContactsSection}
                setSignalToUpdate={setSignalToUpdateNextContactsSection}
                basicData={basicData}
                handleChangeStatusMeetingSection={
                  handleChangeStatusMeetingSection
                }
                setInstantReminder={setSelectedReminder}
              />
              <NextContactsSection
                clientReminders={clientReminders}
                setClientReminders={setClientReminders}
                clientId={clientId}
                typeClient={typeClient}
                setTypeClient={setTypeClient}
                setSelectedReminder={handleSelectedReminder}
                showMeetingSection={handleShowMeetingSection}
                signalToUpdate={signalToUpdateNextContactsSection}
              />
              <LastContactsSection clientLastReminders={clientLastReminders} />
            </Flex>

            {!isMobile && (
              <Divider
                mt={3}
                orientation="vertical"
                height="100%"
                borderColor="background.100"
              />
            )}

            <ClientDataSection
              clientReminders={clientReminders}
              onlyVizMode={onlyVizMode}
              clientLastReminders={clientLastReminders}
              clientId={clientId}
              data={informalInfos}
              signalToUpdate={signalToUpdate}
              setSignalToUpdate={setSignalToUpdate}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
