import React from 'react';
import { BiTrash } from 'react-icons/bi';
import { Button, Stack, Text } from '@chakra-ui/react';

import { Input } from 'components/elements/forms';

interface PortifolioRowFieldProps {
  field: {
    id: number;
    name: string;
    percent: string;
  };
  handleRemoveField(removeInputID: number): void;
  handleSetNameOfSegment(id: any, value: any): Promise<void>;
  handleSetPercOfSegment(id: any, value: any): Promise<void>;
}

const PortifolioRowValue: React.FC<PortifolioRowFieldProps> = ({
  field,
  handleRemoveField,
  handleSetNameOfSegment,
  handleSetPercOfSegment,
}) => {
  return (
    <Stack>
      <Text color="rgba(70, 70, 70, 1)" fontSize="small">
        Segmento
      </Text>
      <Stack flexDir="row" spacing={0} gridGap="12px" mb="24px">
        <Input
          w="100%"
          size="sm"
          value={field.name}
          placeholder="Digite o segmento"
          onChange={e => handleSetNameOfSegment(field.id, e.target.value)}
        />

        <Input
          w="30%"
          value={field.percent}
          size="sm"
          placeholder="%"
          onChange={e => handleSetPercOfSegment(field.id, e.target.value)}
        />

        <Button
          w="40%"
          leftIcon={<BiTrash size={16} color="white" />}
          bg="red"
          variant="solid"
          size="sm"
          borderColor="transparent"
          onClick={() => handleRemoveField(field.id)}
          color="white"
        >
          Deletar
        </Button>
      </Stack>
    </Stack>
  );
};

export default PortifolioRowValue;
