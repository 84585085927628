import { Flex, TableRowProps } from '@chakra-ui/react';
import React from 'react';
import { colors } from 'sharedV2/colors';
import { HeadDataType } from '../base';
import { useTableColumnWidth } from '../hooks/useTableColumnWidth';

type PropsType = {
  headData: HeadDataType[];
  shouldHaveBorderLeft?: boolean;
} & TableRowProps;

export const TableRow: React.FC<PropsType> = ({
  children,
  headData,
  shouldHaveBorderLeft = true,
  borderLeftColor = colors.BLUE,
  ...rest
}): JSX.Element => {
  const renderChildrenWithWidth = useTableColumnWidth({
    headData,
    children,
  });

  return (
    <Flex
      w="100%"
      border="1px solid gray"
      borderRadius={5}
      justifyContent="space-between"
      className="tableRow"
      alignItems="center"
      h="40px"
      cursor="pointer"
      borderLeft={
        shouldHaveBorderLeft ? `4px solid ${borderLeftColor}` : 'none'
      }
      color="rgba(70, 70, 70, 1)"
      {...rest}
    >
      {renderChildrenWithWidth}
    </Flex>
  );
};
