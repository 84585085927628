import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { IReduxState } from 'shared/interfaces';
import { LoadingSpinner } from 'components/elements/others';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { TableRemunerationDetails } from 'pages/Remuneration/components/modules/TableRemunerationDetails/TableRemunerationDetails';
import { IRemunerationDetails } from 'pages/Remuneration/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { EmployeesModal } from 'pages/Remuneration/components/modules/RemunerationDetails/EmployeesModal';
import history from 'services/history';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';

type SelectedEmployee = {
  id: string;
  name: string;
};

const RemunerationDetails: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const todayDate = new Date().toISOString().substring(0, 10);
  const initDate = `${todayDate.substring(0, 7)}`;
  const [isLoading, setIsloading] = useState(false);
  const [remunerationDetails, setRemunerationDetails] = useState<
    IRemunerationDetails[]
  >([]);
  const [remunerationDetailsFiltred, setRemunerationDetailsFiltred] = useState<
    IRemunerationDetails[]
  >([]);

  const [searchDate, setSearchDate] = useState(initDate);
  const [employeesList, setEmployeesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<
    SelectedEmployee[]
  >([
    {
      id: user.id,
      name: user.name,
    },
  ]);
  const [lastSearchedEmployees, setLastSearchedEmployees] = useState<
    SelectedEmployee[]
  >([]);

  const isLoggedUserTableManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTM'],
    areaCod: ['ASS'],
  });

  const isLoggedUserManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTR'],
    areaCod: ['ASS'],
  });

  function handleSearchByDate(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value >= '2022-05') {
      return setSearchDate(e.target.value);
    }
    return toast.dark(
      'Data inválida. Busque por datas a partir de Maio de 2022'
    );
  }

  const getAllEmployees = async () => {
    try {
      const response = await hubApiUser.getAllUsers();

      setEmployeesList(response);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os assessores, tente novamente em alguns minutos'
      );
    }
  };

  const getEmployeesByTable = async () => {
    try {
      const response = await hubApiUser.getEmployeesByTable(user.tableId);

      setEmployeesList(response);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os assessores da mesa, tente novamente em alguns minutos'
      );
    }
  };

  const getLoggedUserCommissionData = async () => {
    setIsloading(true);

    try {
      const response = await hubApiFinancial.getCommissionDetails(searchDate, [
        user.id,
      ]);
      if (response.length < 1) {
        toast.dark('Não há dados de remuneração neste mês');
        setIsloading(false);
      }

      setIsloading(false);
      setRemunerationDetailsFiltred(response);
      setRemunerationDetails(response);
    } catch (err) {
      setIsloading(false);
      toast.dark(
        'Erro ao buscar os dados de comissão, tente novamente em alguns minutos'
      );
    }
  };

  const getInformationOfRemuneration = async (cancelClick?: boolean) => {
    if (cancelClick) {
      setSelectedEmployees(lastSearchedEmployees);
      return;
    }

    setIsloading(true);

    if (selectedEmployees.length === 0) {
      setSelectedEmployees([
        {
          id: user.id,
          name: user.name,
        },
      ]);
      setLastSearchedEmployees([
        {
          id: user.id,
          name: user.name,
        },
      ]);
      getLoggedUserCommissionData();
      setIsloading(false);
      return;
    }

    setLastSearchedEmployees(selectedEmployees);

    const emails = [];

    for (let i = 0; i < selectedEmployees.length; i++) {
      emails.push(selectedEmployees[i].id);
    }

    try {
      const response = await hubApiFinancial.getCommissionDetails(
        searchDate,
        emails
      );
      if (response.length < 1) {
        toast.dark('Não há dados de remuneração esse mês ');
        setIsloading(false);
      }

      setIsloading(false);
      setRemunerationDetailsFiltred(response);
      setRemunerationDetails(response);
    } catch (err) {
      setIsloading(false);
      toast.dark('Erro ao buscar a remuneração do mês selecionado');
    }
  };

  const getAllComission = () => {
    let total = 0;

    for (let i = 0; i < remunerationDetails.length; i++) {
      total += Number(remunerationDetails[i].final);
    }

    return total;
  };
  useEffect(() => {
    getAllComission();
  }, []);

  const freeAuthLimitInMinutes = 5;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getInformationOfRemuneration();

      if (isLoggedUserManager) {
        getAllEmployees();

        return;
      }

      if (isLoggedUserTableManager) {
        getEmployeesByTable();
      }
    }
  }, [lastAuth, searchDate]);

  return (
    <PageWrapper alignItems="center" flexDir="column" pt="26px">
      <BackPageButton />

      <MainPageTitle mb={6}>Comissionamento Detalhado</MainPageTitle>

      <Flex
        alignItems={['flex-start', 'flex-end']}
        w="90%"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        {(isLoggedUserManager || isLoggedUserTableManager) && (
          <>
            <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
              Exibindo relatórios de:{' '}
              {selectedEmployees.map((employee, index) => (
                <Text as="span" color="rgba(70, 70, 70, 1)" ml={1}>
                  {formatNameToCaptalize(employee.name)}
                  {(selectedEmployees.length === 2 && index === 0) ||
                  (selectedEmployees.length > 2 &&
                    index === selectedEmployees.length - 2)
                    ? ' e'
                    : ''}
                  {selectedEmployees.length > 2 &&
                  index < selectedEmployees.length - 2
                    ? ', '
                    : ''}
                </Text>
              ))}
              <Text
                as="span"
                color="primary.100"
                fontSize="sm"
                fontStyle="normal"
                _hover={{ textDecoration: 'underline' }}
                cursor="pointer"
                ml={3}
                onClick={() => setIsModalOpen(true)}
              >
                alterar
              </Text>
            </Text>
          </>
        )}
        <DatePickerChackra
          label="Buscar por data"
          handlePickDate={handleSearchByDate}
          value={searchDate}
          fullColum
          type="month"
        />
      </Flex>

      <Flex align="center" mt="3" justify="center" flexDir="column" w="90%">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <TableRemunerationDetails
              overflowY="auto"
              maxH="calc(100vh - 272px)"
              w="100%"
              remunerationDetails={remunerationDetails}
              remunerationDetailsFiltred={remunerationDetailsFiltred}
              setRemunerationDetailsFiltred={setRemunerationDetailsFiltred}
            />
            <Flex
              w="100%"
              mt="2"
              color="rgba(70, 70, 70, 1)"
              fontWeight="semibold"
              fontSize="md"
              align="flex-end"
              justify="flex-end"
            >
              <Text>Total : {formatValue(getAllComission())}</Text>
            </Flex>
          </>
        )}
      </Flex>

      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeOnOverlayClick={false}
      >
        <EmployeesModal
          onClose={setIsModalOpen}
          employeesList={employeesList}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          getInformationOfRemuneration={getInformationOfRemuneration}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default RemunerationDetails;
