import { Flex, Grid, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import {
  MeetingsPoolTabEnum,
  MeetingsPoolTabTitleEnum,
  useMeetingsPoolContext,
} from 'pagesV2/MeetingsPool/context/useMeetingsPoolContext';
import { GetMeetingsPoolResponseType } from 'services/hubApi/classes/HubApiCaptation';
import { GetClientActivitiesResponseType } from 'services/hubApi/classes/HubApiClients';
import { CurrencyUtils } from 'utilsV2/Currency';

const tabs = [
  {
    title: MeetingsPoolTabTitleEnum.CALLBACK,
    name: MeetingsPoolTabEnum.CALLBACK,
  },
  {
    title: MeetingsPoolTabTitleEnum.SUITABILITY,
    name: MeetingsPoolTabEnum.SUITABILITY,
  },
  {
    title: MeetingsPoolTabTitleEnum.PRESENTATION,
    name: MeetingsPoolTabEnum.PRESENTATION,
  },
  {
    title: MeetingsPoolTabTitleEnum.ALLOCATION,
    name: MeetingsPoolTabEnum.ALLOCATION,
  },
  {
    title: MeetingsPoolTabTitleEnum.ACCOUNT,
    name: MeetingsPoolTabEnum.ACCOUNT,
  },
];

type PropsType = {
  meetings: GetMeetingsPoolResponseType | undefined;
  clientsAport: GetClientActivitiesResponseType[] | undefined;
};

export const MeetingsPoolTabs: React.FC<PropsType> = ({
  meetings,
  clientsAport,
}): JSX.Element => {
  const { handleChangeSelectedTab } = useMeetingsPoolContext();

  const handleGetTotalPipeValueForEachTab = (
    tabName: MeetingsPoolTabEnum
  ): number => {
    if (!meetings) return 0;

    if (tabName === MeetingsPoolTabEnum.ACCOUNT) {
      const all = [...meetings.account.meetings, ...(clientsAport || [])];
      const unique = new Set(all);
      let total = 0;

      Array.from(unique).forEach(item => {
        if ('value' in item) {
          // Its a client aport
          total += item.value;
        } else {
          // Its a meeting
          total += item.pipeValue;
        }
      });

      return total;
    }

    return meetings[tabName].pipeValue;
  };

  return (
    <Grid w="20%" gap={2} gridTemplateRows="auto auto auto auto auto">
      {tabs.map(tab => {
        const totalPipeValue = handleGetTotalPipeValueForEachTab(tab.name);

        return (
          <Box.Glass
            key={tab.title}
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleChangeSelectedTab(tab.name)}
            flexDirection="column"
          >
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
              {tab.title}
            </Text>
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
              {CurrencyUtils.format(totalPipeValue)}
            </Text>
          </Box.Glass>
        );
      })}
    </Grid>
  );
};
