import { LeadActionType } from './leadAction';
import { LeadManualType } from './leadManual';
import {
  LeadMundoInvestStatusEnum,
  LeadMundoInvestType,
} from './leadMundoInvest';
import { LeadPJType } from './leadPJ';
import { LeadProspectType } from './leadProspect';
import { LeadShQuestionType } from './leadShQuestion';
import { LeadUploadType } from './leadUpload';
import { MeetingType } from './meeting';

export enum LeadTypeEnum {
  MUNDO_INVEST = 'mundoInvest',
  UPLOAD = 'upload',
  MANUAL = 'manual',
  PJ = 'pj',
}

export type LeadTypes =
  | LeadMundoInvestType
  | LeadUploadType
  | LeadManualType
  | LeadPJType
  | LeadParsedType
  | LeadProspectType;

export type LeadParsedType = {
  id: string;
  employeeId: string;
  name: string;
  razaoSocial?: string;
  email?: string | undefined;
  phone?: string | undefined;
  phones?: string[];
  status: LeadMundoInvestStatusEnum | string;
  price: number;
  meetingPrice: number;
  plMundoInvest: number;
  plSalesHunter: number | undefined;
  pipeValue: number | undefined;
  origin: string;
  frequency: number;
  meeting?: MeetingType;
  actions?: LeadActionType[];
  questions?: LeadShQuestionType;
  declinedReason?: string;
  btgAccount?: string | undefined;
  EWZClient?: boolean | undefined;
  sex?: string;
  state?: string;
  trader?: string;
  profession?: string;
  aumPl?: string;
  ageRange?: string;
  salaryRange?: string;
  listName?: string;
  investorProfile?: string;
  contactAttempts?: number;
  created_at: Date | string;
  updated_at: Date | string;
};
