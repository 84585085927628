import { GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface OrderCardWrapperProps {
  havePendencies: boolean;
  children: ReactNode;
}

export const ClientSummaryCardWrapper: React.FC<OrderCardWrapperProps> = ({
  havePendencies,
  children,
}) => {
  return (
    <GridItem
      m="0"
      height="265px"
      border="1px"
      borderColor={havePendencies ? '#bc1105' : '#3071e4'}
      borderRadius="5px"
      py="8px"
      px="12px"
      background="transparent"
      cursor="pointer"
      color="white.300"
      transition=" all 0.2s ease-in"
      _hover={{
        background: havePendencies ? '#bc1105' : '#3071e4',
        borderColor: havePendencies ? '#bc1105' : '#3071e4',
      }}
    >
      {children}
    </GridItem>
  );
};
