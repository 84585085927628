import { ModalWrapper } from 'components/elements/wrappers';
import { LeadNoAnswerModal } from 'componentsV2/modules/captation/leadNoAnswerModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { SecondaryButton } from '../../../../components/elements/buttons';

export const LeadDetailsNoAnswer: React.FC = (): JSX.Element => {
  const { getParam, setParam, deleteParam } = useQueryParams();

  const { lead, leadType } = useLeadDetailsContext();

  const isOpenNoAnswerModal = getParam('isOpenNoAnswerModal') === 'true';

  return (
    <>
      <SecondaryButton onClick={() => setParam('isOpenNoAnswerModal', 'true')}>
        Não Atendeu
      </SecondaryButton>

      <ModalWrapper
        isOpen={isOpenNoAnswerModal}
        onClose={() => deleteParam('isOpenNoAnswerModal')}
      >
        <LeadNoAnswerModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
