import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const SectionTitleWithBorder: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  return (
    <Text
      width="100%"
      mb="5"
      pb="3"
      color="rgba(70, 70, 70, 1)"
      borderBottom="1px solid"
      borderColor="background.200"
      fontWeight="medium"
      fontSize={{ base: 'md', md: 'lg', lg: 'xl' }}
      {...rest}
    >
      {children}
    </Text>
  );
};
