import { ChangeEvent, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { IShNumbers } from 'pages/GenerationalVariables/interfaces';
import {
  EffectivenessTable,
  DeclinedDataset,
  FrequencyDataset,
  TopBlockDataset,
} from 'pages/GenerationalVariables/components/modules/shSpecificNumbers';
import { toast } from 'react-toastify';

interface DynamicNumbersDatasetSmallVersion {
  shNumbers: IShNumbers;
  getShNumbers: (
    searchDate?: string | undefined,
    isUpdateData?: boolean | undefined
  ) => Promise<void>;
  date: string;
  isSmallVersion?: boolean;
  setStartDatePl?: (value: string) => void;
  setEndDatePl?: (value: string) => void;
}

export const DynamicNumbersDatasetSmallVersion: React.FC<DynamicNumbersDatasetSmallVersion> =
  ({
    shNumbers,
    getShNumbers,
    date,
    isSmallVersion,
    setEndDatePl,
    setStartDatePl,
  }) => {
    const initialDate = new Date(date);
    const initialMonth = String(initialDate.toISOString().substring(0, 7));
    const [selectedDate, setSelectedDate] = useState(`${initialMonth}`);
    const [endSelectedDate, setEndSelectedDate] = useState(`${initialMonth}`);

    const handleMonthPicker = async (event: ChangeEvent<HTMLInputElement>) => {
      const yearAndMonth = event.target.value;

      if (yearAndMonth > new Date().toISOString().slice(0, 7)) {
        return toast.dark('não pode selecionar data futura');
      }

      const datePL = new Date(yearAndMonth).toISOString();
      setStartDatePl &&
        setStartDatePl(new Date(datePL).toISOString().substring(0, 10));
      setSelectedDate(yearAndMonth);
      getShNumbers(yearAndMonth, true);
    };

    const handleEndDateMonthPicker = async (
      event: ChangeEvent<HTMLInputElement>
    ) => {
      const yearAndMonth = event.target.value;

      if (yearAndMonth > new Date().toISOString().slice(0, 7)) {
        return toast.dark('não pode selecionar data futura');
      }

      const currentDate = new Date(yearAndMonth);
      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      setEndDatePl &&
        setEndDatePl(lastDayOfMonth.toISOString().substring(0, 10));
      setEndSelectedDate(yearAndMonth);
    };
    return (
      <>
        <Flex
          maxW={['100%', '60%']}
          flexDir="column"
          flex="4"
          overflowY="auto"
          gridGap="4"
          pr="1"
        >
          <Flex
            width="100%"
            maxW={isSmallVersion ? '456px' : '516px'}
            alignSelf="flex-end"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex gap={2} flexDirection="column" alignItems="flex-end">
              <Flex alignItems="center" gap={2}>
                <Text>Inicio: </Text>
                <DatePickerChackra
                  style={{ width: 'fit-content', alignSelf: 'flex-start' }}
                  handlePickDate={handleMonthPicker}
                  value={selectedDate}
                  type="month"
                />
              </Flex>

              <Flex alignItems="center" gap={2}>
                <Text>Fim:</Text>
                <DatePickerChackra
                  style={{ width: 'fit-content', alignSelf: 'flex-start' }}
                  handlePickDate={handleEndDateMonthPicker}
                  value={endSelectedDate}
                  type="month"
                />
              </Flex>
            </Flex>

            <TopBlockDataset
              isSmallVersion
              conversionComputedPercentage={
                String(
                  (
                    (Number(shNumbers.meetingsRealized) /
                      Number(shNumbers.meetingsRouter)) *
                    100
                  ).toFixed(2)
                ) || '0'
              }
            />
          </Flex>

          <FrequencyDataset
            frequencyData={
              {
                aguardando: String(
                  Number(shNumbers.tasks) + Number(shNumbers.tasksPending)
                ),
                realizada: shNumbers.tasksPending,
              } || '0'
            }
            frequencyComputedPercentage={
              String(
                (
                  (Number(shNumbers.tasksPending) /
                    (Number(shNumbers.tasks) +
                      Number(shNumbers.tasksPending))) *
                  100
                ).toFixed(2)
              ) || '0'
            }
          />

          <EffectivenessTable
            effectivenessData={
              {
                delivered: shNumbers.leadsReceivedInAPeriod,
                marked: shNumbers.scheduledMeetingsInAPeriod,
                realized: shNumbers.successfulMeetingsInAPeriod,
              } || '0'
            }
            // TODO: confirmar com o Rapha essa porcentagem nos leads entregues
            deliveredLag={
              /* String(
                (
                  (Number(shNumbers.leadsReceivedInAPeriod) /
                    Number(shNumbers.scheduledMeetingsInAPeriod)) *
                  100
                ).toFixed(2)
              ) || '0' */
              '100'
            }
            markedLag={
              String(
                (
                  (Number(shNumbers.successfulMeetingsInAPeriod) /
                    Number(shNumbers.scheduledMeetingsInAPeriod)) *
                  100
                ).toFixed(2)
              ) || '0'
            }
            realizedLag={String(
              (
                (Number(shNumbers.successfulMeetingsInAPeriod) /
                  Number(shNumbers.leadsReceivedInAPeriod)) *
                100
              ).toFixed(2) || '0'
            )}
          />

          <DeclinedDataset
            isSmallVersion={isSmallVersion}
            declined={{
              btg_client: shNumbers.btgClient,
              lgpd: shNumbers.lgpd,
              no_answer: shNumbers.noAnswer,
              no_exist: shNumbers.noExist,
              no_interest: shNumbers.noInterest,
              no_money: shNumbers.noMoney,
            }}
            totalDeclined={
              Number(shNumbers.btgClient) +
              Number(shNumbers.lgpd) +
              Number(shNumbers.noAnswer) +
              Number(shNumbers.noExist) +
              Number(shNumbers.noInterest) +
              Number(shNumbers.noMoney)
            }
          />
        </Flex>
      </>
    );
  };
