import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CampaignContent = styled.div`
  /* border: 1px solid red; */
  width: 480px;
  height: 610px;
  background: #242424;
  /* border: 1px solid red; */

  border-radius: 8px;
  padding: 15px;
  margin: 1.5rem 3rem;

  strong {
    font-weight: 500;
  }

  .title-content {
    text-align: center;
    margin-bottom: 10px;

    p {
      font-size: var(--smaller-text);
    }

    .title-date-delete {
      display: flex;

      .title-and-date {
        width: 70%;
        margin-left: 55px;
      }

      .delete-card-campaign {
        width: 10%;
        display: flex;
        align-items: flex-end;
        margin-left: auto;
        padding-left: 10px;

        &:hover {
          filter: brightness(0.7);
          cursor: pointer;
        }
      }
    }
  }

  .leads {
    background: var(--gradient-gray-color);
    /* box-shadow: var(--shadow-color); */
    height: 129px;
    border-radius: 4px;
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;

    .img-group {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list-leads {
      font-size: 12px;
      .single-lead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 05px;
        padding: 03px;
        border-bottom: 1px solid #808176;
      }

      .last-leads {
        border: 0;
      }
    }
  }

  .leads-and-negative-data {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: var(--smaller-text);
    }

    div {
      width: 225px;
      height: 210px;
      /* border-radius: 4px; */
      padding: 10px 5px;

      h6 {
        text-align: center;
      }

      .executation-leads {
        background: var(--gradient-gray-color);
        /* box-shadow: var(--shadow-color); */
        width: 100%;
        height: 92%;
        padding: 02px 0px;
        border: 0;
        border-radius: 04px;

        .single-executation-lead {
          /* border: 1px solid red; */
          width: 96%;
          height: 30px;
          display: flex;
          align-items: center;
          /* border: 0; */
          margin-bottom: 3px;
          padding: 10px;
          /* border: 1px solid red; */

          strong {
            font-size: 10px;
            line-height: 11px;
            display: block;
            margin-left: 5px;
          }

          p {
            font-size: 12px;
            line-height: 11px;
            text-align: center;
          }
        }
      }
    }
  }

  .media-and-conversation {
    background: var(--gradient-gray-color);
    /* box-shadow: var(--shadow-color); */
    margin-top: 10px;
    border-radius: 4px;
    height: 80px;
    padding: 8px;

    display: grid;
    grid-template-columns: 1fr 3fr;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .media-title {
      border-right: 1px solid #808080;
      font-size: 12px;
    }

    .media-infos {
      div {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        font-size: 10px;

        p {
          text-align: center;
        }

        strong {
          font-size: 13px;
        }
      }
    }
  }
`;
