import { Flex } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { useState } from 'react';
import { AiFillCaretDown, AiOutlineClose } from 'react-icons/ai';

interface MenuMobileProps {
  titleSelected: string;
  handleSelectLeadType: (
    leadType: 'leadstable' | 'leadspublic' | 'leadsmanual' | 'leadspj' | 'pipe',
    title: string,
    type: string
  ) => void;
}

export const MenuMobile: React.FC<MenuMobileProps> = ({
  titleSelected,
  handleSelectLeadType,
}) => {
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent={'center'}
        width={'30px'}
        background={!openMenuMobile ? 'transparent' : 'background.700'}
        height={'30px'}
        borderTopRadius={'md'}
        onClick={() => setOpenMenuMobile(!openMenuMobile)}
      >
        {openMenuMobile ? (
          <AiOutlineClose color="#fff" />
        ) : (
          <AiFillCaretDown color="#fff" />
        )}
      </Flex>
      {openMenuMobile && (
        <Flex
          flexDirection={'column'}
          width={'180px'}
          height={'200px'}
          justifyContent={'space-evenly'}
          right={0}
          top={7}
          alignItems="center"
          position="absolute"
          background={'background.700'}
          zIndex={'800'}
          borderTopLeftRadius={'md'}
          borderBottomRadius={'md'}
        >
          <SecondaryButton
            width={'90%'}
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads Lista'
                ? 'background.200'
                : 'background.600'
            }
            onClick={() => {
              handleSelectLeadType('leadstable', 'Leads Lista', 'LeadsList');
              setOpenMenuMobile(false);
            }}
            fontWeight="normal"
            color={titleSelected === 'Leads Lista' ? 'white' : 'inherit'}
          >
            Leads Lista
          </SecondaryButton>

          <SecondaryButton
            width={'90%'}
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads Mesa'
                ? 'background.200'
                : 'background.600'
            }
            onClick={() => {
              handleSelectLeadType('leadstable', 'Leads Mesa', 'LeadsIndirect');
              setOpenMenuMobile(false);
            }}
            fontWeight="normal"
            color={titleSelected === 'Leads Mesa' ? 'white' : 'inherit'}
          >
            Leads Minerados
          </SecondaryButton>

          <SecondaryButton
            width={'90%'}
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads Manuais'
                ? 'background.200'
                : 'background.600'
            }
            onClick={() => {
              handleSelectLeadType(
                'leadsmanual',
                'Leads Manuais',
                'LeadsIndirect'
              );
              setOpenMenuMobile(false);
            }}
            fontWeight="normal"
            color={titleSelected === 'Leads Manuais' ? 'white' : 'inherit'}
          >
            Leads Manuais
          </SecondaryButton>

          <SecondaryButton
            width={'90%'}
            transition="background 0.1s ease-in"
            background={
              titleSelected === 'Leads PJ' ? 'background.200' : 'background.600'
            }
            onClick={() => {
              handleSelectLeadType('leadspj', 'Leads PJ', 'DirectLeads');
              setOpenMenuMobile(false);
            }}
            fontWeight="normal"
            color={titleSelected === 'Leads PJ' ? 'white' : 'inherit'}
          >
            Leads PJ
          </SecondaryButton>
        </Flex>
      )}
    </>
  );
};
