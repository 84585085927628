export interface AssignmentProps {
  id: string;
  name: string;
  dateLimit?: string;
  btgAccount: string;
  btgAccountIndicator: string | null;
  declaredPL: string;
  employeeBTGAccount: string;
  fromType: string | null;
  dataRetorno?: string;
  nps: string | null;
  clientName?: string;
  pl: string;
  extra?: string;
  created_at_pendents?: string;
  npsDay?: string;
  realized?: boolean;
  status: string;
  todayBalance: string;
  typetask: string;
  client: boolean;
  origin: boolean;
  idTask: string;
  msg: string;
  data: string;
  creatorName: string;
  title: string;
  birthday: string;
  date?: string;
  local?: string;
  complement?: string;
  startDate?: string;
  created_at: string;
  updated_at: string;
  btgCode?: string;
}

export interface IAssignmentsLength {
  preNps: number;
  nps: number;
  origin: number;
  clientsLeft: number;
}

export interface IShowAndHideCard {
  cardPreNps: boolean;
  cardNps: boolean;
  cardOrigin: boolean;
  cardClientsLeft: boolean;
}

export enum TaskTypes {
  balance = 'balance',
  birthday = 'birthday',
  nps = 'nps',
  client = 'client',
  meeting = 'meeting',
  origin = 'fromType',
  managerContact = 'noticesManager',
  inative = 'inative',
  meetingNotConfirm = 'meetingNotConfirm',
  periodicMeeting = 'periodicMeeting',
  preNps = 'pre-nps',
  left = 'left',
  personalizedMeeting = 'personalizedMeeting',
  sharedContent = 'sharedContent',
  meetingToday = 'meetingToday',
  stuckMoney = 'stuckMoney',
  noLeads = 'noLeads',
  noTelegram = 'noTelegram',
  noFeedbackPenalty = 'noFeedbackPenalty',
}
