export const rvColumns = [
  {
    id: '1',
    title: 'Sem Contato',
    status: 'sem_contato',
    tasks: [],
  },
  {
    id: '2',
    title: 'Cliente Contatado',
    status: 'cliente_contatado',
    tasks: [],
  },
  {
    id: '4',
    title: 'Aguardando E-mail',
    status: 'email',
    tasks: [],
  },
  {
    id: '6',
    title: 'Cliente incluso',
    status: 'finalizado',
    tasks: [],
  },
];
