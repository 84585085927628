import React from 'react';
import { Flex } from '@chakra-ui/react';

// import PublicPageContent from './styles';

const PublicPageLayout: React.FC = ({ children }) => {
  return (
    <Flex
      minW="100vw"
      minH="100vh"
      alignItems="center"
      justifyContent="center"
      background="background.800"
    >
      {children}
    </Flex>
  );
};

export default PublicPageLayout;
