/* eslint-disable @typescript-eslint/no-empty-function */
import { ModalWrapper } from 'components/elements/wrappers';
import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner';

interface LoadingWithOverlayProps {
  isOpen: boolean;
}

export const LoadingWithOverlay: React.FC<LoadingWithOverlayProps> = ({
  isOpen,
}) => {
  return (
    <ModalWrapper isOpen={isOpen} onClose={() => {}}>
      <LoadingSpinner />
    </ModalWrapper>
  );
};
