import React, { FormEvent, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import { BaseModalFormCard } from 'components/elements/cards';
import PerformanceRowValue from '../PerformanceRowValue';
import { IinitialStructuredData } from '../data';

import { AddItem, FloatYContainer } from './styles';

interface PerfomaceModalProps {
  handleShowModal: () => void;
  title: string;
  structuredData: IinitialStructuredData;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexOf: number
  ) => void;
  setStructuredData: React.Dispatch<
    React.SetStateAction<IinitialStructuredData>
  >;
}

const PerformanceModal: React.FC<PerfomaceModalProps> = ({
  handleShowModal,
  title,
  structuredData,
  handleChangeValue,
  setStructuredData,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formInfos, setFormInfos] = useState({
    orders: [
      {
        id: 1,
        ticker: '',
        financial: '',
        type: '',
        delAbled: false,
      },
    ],
  });

  const handleAddOrder = () => {
    const newOrderId =
      structuredData.performances[structuredData.performances.length - 1].id +
      1;
    const newOrder = {
      id: newOrderId,
      performance: '',
      income: '',
      type: '',
      delAbled: true,
    };

    setStructuredData({
      ...structuredData,
      performances: [...structuredData.performances, newOrder],
    });
  };

  const handleDeleteOrder = (removedOrderId: number) => {
    const orderFiltereds = structuredData.performances.filter(
      order => order.id !== removedOrderId
    );

    setStructuredData({
      ...structuredData,
      performances: orderFiltereds,
    });
  };

  const handleFormSubmit = (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    setFormInfos({
      orders: [
        {
          id: 1,
          ticker: '',
          financial: '',
          type: '',
          delAbled: false,
        },
      ],
    });
    setIsLoading(false);
    handleShowModal();
    toast.dark(
      'Performance/rendimento adicionados, salve as sua modificações para persistir as informações'
    );
  };

  return (
    <BaseModalFormCard
      isModal
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Confirmar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="40%"
    >
      <FloatYContainer>
        {structuredData.performances.map((order, index) => (
          <PerformanceRowValue
            key={index}
            index={index}
            order={order}
            handleDeleteOrder={handleDeleteOrder}
            handleChange={handleChangeValue}
            ticker={structuredData.ticker}
          />
        ))}
      </FloatYContainer>

      <AddItem onClick={handleAddOrder}>
        <MdAdd size={20} className="icon" />
        <span>Adicionar</span>
      </AddItem>
    </BaseModalFormCard>
  );
};

export default PerformanceModal;
