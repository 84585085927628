import { MenuItem as ChakraMenuItem, MenuItemProps } from '@chakra-ui/react';

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <ChakraMenuItem
      _focus={{}}
      _hover={{ bgColor: '#1D63DD', color: 'white' }}
      color="rgba(70, 70, 70, 1)"
      {...rest}
    >
      {children}
    </ChakraMenuItem>
  );
};
