import { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
  BaseButtonGroup,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface ClientWithTagCardProps {
  clientId: string;
  clientName: string;
  tagName: string;
  clientPL: string;
  clientDeclaredPL: string;
  updateClientsLists: (id: string) => void;
}

export const ClientWithTagCard: React.FC<ClientWithTagCardProps> = ({
  clientId,
  clientName,
  clientPL,
  clientDeclaredPL,
  tagName,
  updateClientsLists,
}) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const handleRemoveTagFromSpecificClient = async () => {
    try {
      await hubApiUser.deleteTagFromSpecificClient(tagName, clientId);

      updateClientsLists(clientId);
      setShowDeleteConfirmationModal(false);

      toast.dark('Tag removida do cliente.');
    } catch (error) {
      toast.dark(
        'Não foi possível remover a tag do cliente, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <>
      <Flex
        justify="space-between"
        flexDirection="column"
        px="2"
        py="1"
        borderRadius="3"
        bg="background.600"
      >
        <Flex justify="space-between" w="100%">
          <Text color="white">{formatNameToCaptalize(clientName)}</Text>

          <Flex alignSelf="center">
            <BiTrash
              size={18}
              color="#fff"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowDeleteConfirmationModal(true)}
              title="Remover tag deste(a) cliente"
            />
          </Flex>
        </Flex>

        <Text fontSize="sm" fontStyle="italic">
          Patrimônio Declarado:
          <Text as="span" fontStyle="italic">
            {' '}
            {formatValue(clientDeclaredPL)}
          </Text>
        </Text>
        <Text fontSize="sm" fontStyle="italic">
          PL:
          <Text as="span" fontStyle="italic">
            {' '}
            {formatValue(clientPL)}
          </Text>
        </Text>
      </Flex>

      <ModalWrapper
        isOpen={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
      >
        <DefaultModalContentWrapper>
          <ModalTitle>{`Deseja remover a tag "${tagName}" do(a) cliente ${formatNameToCaptalize(
            clientName
          )}?`}</ModalTitle>

          <BaseButtonGroup>
            <PrimaryButton
              onClick={() => setShowDeleteConfirmationModal(false)}
            >
              Cancelar
            </PrimaryButton>
            <SecondaryButton onClick={handleRemoveTagFromSpecificClient}>
              Remover tag do cliente
            </SecondaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
