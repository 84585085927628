import { BiCommentCheck, BiIdCard, BiSlideshow } from 'react-icons/bi';
import { IoCameraOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { CgPathOutline } from 'react-icons/cg';
import { GrBlockQuote } from 'react-icons/gr';
import { BsCardImage } from 'react-icons/bs';
import { VscLibrary } from 'react-icons/vsc';
import { IoMdImages } from 'react-icons/io';

import IdeaAndSuggestions from './components/modules/forms/IdeaAndSugestions';
import ArtSolicitation from './components/modules/forms/ArtSolicitation';
import BusinessCard from './components/modules/forms/BusinessCard';
import PhotoRequest from './components/modules/forms/PhotoRequest';
import TextToBlog from './components/modules/forms/TextToBlog';

export const materialsMkt = [
  {
    title: 'Logos',
    link: 'https://www.dropbox.com/sh/lbay4dt2fyjzupj/AADYLpfJIpna6mDrPPjEA2QNa?dl=0',
    icon: VscLibrary,
  },
  {
    title: 'Iscas para leads',
    link: 'https://www.dropbox.com/sh/rdjy6gccnsq0brc/AACRv28NqfNTZBHpa4ePMIqCa?dl=0',
    icon: BiSlideshow,
  },
  {
    title: 'Apresentação Institucional EWZ',
    link: 'https://www.dropbox.com/s/u0s4y4xeucmpkik/Institucional%20EWZ%20-%20PDF.pdf?dl=0',
    icon: BiSlideshow,
  },

  {
    title: 'Apresentação Padrão EWZ',
    link: 'https://www.dropbox.com/scl/fi/mb4ieo84ns0wmkko4fp8z/ewz_apresenta-o.ppt?dl=0&rlkey=veobs4z2o130ilr2cpx10cipq',
    icon: BiSlideshow,
  },
  {
    title: 'EWZ Manual',
    link: 'https://www.dropbox.com/s/rn2by19i16gx43h/ewz_manual.pdf?dl=0',
    icon: IoDocumentTextOutline,
  },
  {
    title: 'Câmbio',
    link: 'https://www.dropbox.com/s/kekfc5euluka2h3/EWZ%20C%C3%A2mbio%20%28PDF%29.pdf?dl=0',
    icon: IoDocumentTextOutline,
  },
  {
    title: 'MIV EWZ',
    link: 'https://www.dropbox.com/sh/ulpbtpdde83tcgs/AAC8vhbK53cnRb0aHasmkGWAa?dl=0',
    icon: CgPathOutline,
  },
  {
    title: 'MIV Mundo Invest',
    link: 'https://www.dropbox.com/sh/o9afpmdozrguu85/AACFw3tRVhrdOYnF7y1bTzOxa?dl=0',
    icon: CgPathOutline,
  },
  {
    title: 'Fotos Assessores',
    link: 'https://www.dropbox.com/sh/2xl4dvosncs2gi5/AAD9sx0Z0q8kqY6Cy96lIzXZa?dl=0',
    icon: IoMdImages,
  },
];

export const solicitationsMkt = [
  // {
  //   title: 'Consultoria de Imagem',
  //   modal: ImageConsulting,
  //   icon: GiSunglasses,
  // },
  {
    title: 'Solicitações de Fotos',
    modal: PhotoRequest,
    icon: IoCameraOutline,
  },
  {
    title: 'Cartão de Visitas',
    modal: BusinessCard,
    icon: BiIdCard,
  },
  {
    title: 'Solicitação/ Aprovação de Texto',
    modal: TextToBlog,
    icon: GrBlockQuote,
  },
  {
    title: 'Solicitação de Arte',
    modal: ArtSolicitation,
    icon: BsCardImage,
  },
  {
    title: 'Ideias e Sugestões',
    modal: IdeaAndSuggestions,
    icon: BiCommentCheck,
  },
];
