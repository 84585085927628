/* eslint @typescript-eslint/no-explicit-any: off */
import React from 'react';
import { Stack } from '@chakra-ui/react';

import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { CardInfo } from 'components/elements/others/CardInfo';

interface ComponentProps {
  order: any;
  handleToggleModal: () => void;
}

export const OrderInfosExchange: React.FC<ComponentProps> = ({
  handleToggleModal,
  order,
}) => {
  return (
    <DefaultModalContentWrapper maxW={['95vw', '490px']}>
      <ModalTitle>Dados da Ordem</ModalTitle>

      <Stack spacing="2">
        <CardInfo title="Nome" info={order.name} />
        <CardInfo title="Telefone" info={order.phone} />
        <CardInfo title="E-mail" info={order.email} />
        <CardInfo
          title="Tipo"
          info={order.type === 'PF' ? 'Pessoa física' : 'Pessoa jurídica'}
        />
        <CardInfo title="Modalidade" info={order.intention} />
        {order.description && (
          <CardInfo title="Informações" info={order.description} />
        )}
      </Stack>

      <BaseButtonGroup>
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
