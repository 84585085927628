import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { useUpdateIPMeeting } from 'pages/IPLife/hooks/useUpdateIPMeeting';
import { useState } from 'react';
import { IPMeetingType } from 'services/hubApi';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  meeting: IPMeetingType;
  setIsOpenMeetingStageBackModal: (value: boolean) => void;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const IPMeetingGetStageBackModal: React.FC<PropsType> = ({
  meeting,
  setIsOpenMeetingStageBackModal,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const [reason, setReason] = useState<string>('');

  const { mutateAsync: updateMeeting, isLoading: isLoadingUpdateMeeting } =
    useUpdateIPMeeting();

  const handleGetStageBackStatus = (currentStatus: string): string => {
    if (currentStatus === 'contactAttempt') return 'noContact';
    if (currentStatus === 'callDialed') return 'contactAttempt';
    if (currentStatus === 'studying') return 'callDialed';
    if (currentStatus === 'waitingPresentation') return 'studying';
    if (currentStatus === 'presentationRealized') return 'waitingPresentation';

    return '';
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!reason)
      return showToast('Preencha o motivo pelo qual deseja voltar uma etapa');

    await updateMeeting({
      meetingId: meeting.id,
      status: handleGetStageBackStatus(meeting.status),
      stageBackReason: reason,
    });

    setIsOpenMeetingStageBackModal(false);
    handleToggleOpenMeetingDetailsModal(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Voltar Etapa"
      handleFormSubmit={handleSubmit}
      isLoading={isLoadingUpdateMeeting}
      primaryButtonText="Confirmar"
      secondaryButtonEventFunction={() => setIsOpenMeetingStageBackModal(false)}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <InputWithLabel
        label="Motivo"
        value={reason}
        onChange={event => setReason(event.target.value)}
      />
    </BaseModalFormCard>
  );
};
