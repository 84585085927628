import styled from 'styled-components';

const OrderInfosContent = styled.div`
  .order-container {
    width: 100%;
  }

  .order-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--gray-color);
    margin-bottom: 8px;

    .order-inputs {
      width: 100%;
      display: flex;
      flex-direction: row;

      label:nth-child(1) {
        display: none;
      }
    }

    .order-disabled {
      label:first-child {
        display: inherit;
      }
    }

    .radio-input-container {
      display: flex;
      flex-direction: column;
      font-size: var(--smaller-text);
      color: var(--white-color-100);

      label {
        margin-bottom: 2px;
      }

      label:first-child:nth-last-child(2) {
        display: none;
      }
      .form-check-label {
        cursor: pointer;
        color: var(--white-color-300);
      }

      input {
        height: 55%;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .inputLabelContainer {
    display: flex;
    width: 80%;
    margin-left: 20px;
  }

  .deleteInputLabelContainer {
    display: flex;
    width: 74%;
    margin-left: 20px;
  }

  .inputFinancial {
    margin-left: 6px;
    width: 100%;
  }

  .inputTicker {
    width: 100%;
  }

  .delete-order-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 12px;
    margin: 0;
    font-size: var(--smaller-text);
    cursor: pointer;

    .delete-order-content {
      display: flex;
      align-items: center;
      color: var(--white-color-300);
      margin: 0;

      span {
        margin-left: 2px;
      }
    }

    @media screen and (max-width: 820px) {
      margin-top: 12px;
    }
  }
`;

export default OrderInfosContent;
