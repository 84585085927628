import { BackPageButton } from 'components/elements/buttons';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';

import { commissionByIlhaForte } from './data';

export const CommissionByIlhaForte: React.FC = () => {
  const advancedOrdersHeader = ['Produto', 'Comissão'];

  return (
    <PageWrapper flexDir="column" align="center" pt="9">
      <BackPageButton />

      <MainPageTitle align="center">Comissão por área Ilha Forte</MainPageTitle>

      <TableBase
        headData={advancedOrdersHeader}
        overflowY="auto"
        maxH="calc(100vh - 206px)"
        width="100%"
        maxW="720px"
      >
        {commissionByIlhaForte.map(item => (
          <TableRow
            key={`${item.title}-${item.value}`}
            transition="background 0.2s ease-in"
            _hover={{ background: '#f2f2f2' }}
          >
            <TableData>{item.title}</TableData>
            <TableData>{item.value}</TableData>
          </TableRow>
        ))}
      </TableBase>
    </PageWrapper>
  );
};
