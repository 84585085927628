import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { PageWrapper } from 'components/elements/wrappers';
import { CreateCOEForm } from 'pages/products/components/modules/coe/CreateCOEForm';
import { COEProps } from 'pages/products/interfaces';
import schema from './schema';

export const CreateCOE: React.FC = () => {
  const history = useHistory();
  const [coeProspectFile, setCoeProspectFile] = useState('');
  const [coeData, setCoeData] = useState<COEProps>({} as COEProps);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();

      setIsLoading(true);

      if (!coeProspectFile) {
        return toast.dark('Selecione o prospecto.');
      }
      try {
        await schema.validate(coeData, {
          abortEarly: false,
        });

        const formData = new FormData();

        formData.append('prospect', coeProspectFile);
        formData.append('COEDeadline', coeData.COEDeadline);
        formData.append('createdDate', coeData.createdDate);
        formData.append('name', coeData.name);
        formData.append('strategy', coeData.strategy);
        formData.append('whatsMessage', coeData.whatsMessage);
        formData.append('bonusMessage', coeData.bonusMessage || '');

        await hubApiProducts.postCreateCOE(formData);

        toast.dark('Coe Criado com sucesso.');
        setIsLoading(false);

        history.goBack();
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o COE, por favor tente novamente.'
          );
        }
      }
    },
    [coeData, coeProspectFile, history]
  );
  return (
    <PageWrapper>
      <CreateCOEForm
        isLoading={isLoading}
        coeData={coeData}
        setCoeProspectFile={setCoeProspectFile}
        setValue={setCoeData}
        handleSubmitForm={handleSubmitForm}
        label="Criar COE"
      />
    </PageWrapper>
  );
};
