import { useLeadManualMapper } from 'hooksV2/useLeadManualMapper';
import { useLeadMundoInvestMapper } from 'hooksV2/useLeadMundoInvestMapper';
import { useLeadPJMapper } from 'hooksV2/useLeadPJMapper';
import { useLeadUploadMapper } from 'hooksV2/useLeadUploadMapper';
import { LeadManualType } from 'sharedV2/types/leadManual';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { LeadPJType } from 'sharedV2/types/leadPJ';
import {
  LeadParsedType,
  LeadTypeEnum,
  LeadTypes,
} from 'sharedV2/types/leadTypes';
import { LeadUploadType } from 'sharedV2/types/leadUpload';

type UseParseLeadByTypeRequestType = {
  lead: LeadTypes;
  leadType: LeadTypeEnum;
};

export const useParseLeadByType = (
  data: UseParseLeadByTypeRequestType
): LeadParsedType => {
  if (data.leadType === LeadTypeEnum.UPLOAD) {
    const { mapper } = useLeadUploadMapper();

    return mapper.toUse(data.lead as unknown as LeadUploadType);
  }

  if (data.leadType === LeadTypeEnum.MANUAL) {
    const { mapper } = useLeadManualMapper();

    return mapper.toUse(data.lead as unknown as LeadManualType);
  }

  if (data.leadType === LeadTypeEnum.PJ) {
    const { mapper } = useLeadPJMapper();

    return mapper.toUse(data.lead as unknown as LeadPJType);
  }

  const { mapper } = useLeadMundoInvestMapper();

  return mapper.toUse(data.lead as LeadMundoInvestType);
};
