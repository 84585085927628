import { AxiosError } from 'axios';
import { showToast } from 'utilsV2/showToast';

export const handleErrorMessage = (error: unknown): void => {
  const err = error as AxiosError;
  if (err?.response?.data?.messageTranslated)
    return showToast(err?.response?.data?.messageTranslated);

  if (err?.response?.data.status === 404) return showToast('Não encontrado.');

  if (err?.response?.data.status >= 500)
    return showToast('Erro de servidor. Por favor contatar o suporte.');

  return showToast('Erro não tratado. Por favor contatar o suporte.');
};
