import { queryClient } from 'App';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';

export enum MeetingActionEnum {
  ROUTED = 'routed',
  RETURN = 'return',
}

export type MeetingActionPropsType = {
  action: MeetingActionEnum;
  meetingId?: string;
  leadId?: string;
  date?: string;
  complement?: string;
};

export const useMeetingAction = (): UseMutationResult<
  unknown,
  unknown,
  MeetingActionPropsType,
  unknown
> => {
  return useMutation({
    mutationKey: ['meetingAction'],
    mutationFn: async (data: MeetingActionPropsType) => {
      const response = await hubApiCaptation.meetingAction({
        ...data,
        leadType: LeadTypeEnum.MUNDO_INVEST,
      });
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['shPoolLeads']);
    },
    onError: () => {
      toast.dark('Erro ao atualizar a reuniao');
    },
  });
};
