import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';

type UseUpdateClientLeftReasonRequestType = {
  additionalInformation: string;
  id: string;
  status: 'confirmed deletion';
};

export const useUpdateClientLeftReason = (): UseMutationResult<
  unknown,
  unknown,
  UseUpdateClientLeftReasonRequestType,
  unknown
> => {
  return useMutation({
    mutationKey: ['updateClientLeftReason'],
    mutationFn: async (data: UseUpdateClientLeftReasonRequestType) => {
      const response = await hubApiHome.pathReasonAssignment(data);

      return response;
    },
    onError: () => {
      toast.dark('Erro ao atualizar o motivo da saída do cliente');
    },
  });
};
