import React from 'react';
import {
  Flex,
  Text,
  Textarea as ChakraTextarea,
  TextareaProps,
} from '@chakra-ui/react';

interface ComponentProps extends TextareaProps {
  label: string;
}

export const TextareaWithLabel: React.FC<ComponentProps> = ({
  label,
  ...rest
}) => {
  return (
    <Flex flexDir="column" w="100%">
      <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
        {label}
      </Text>
      <ChakraTextarea
        size="sm"
        padding="3"
        color="rgba(70, 70, 70, 1)"
        background="#fff"
        borderColor="#1d63ddb2"
        borderRadius="6px"
        focusBorderColor="primary.600"
        _placeholder={{ color: 'gray.400' }}
        _hover={{ borderColor: 'primary.800' }}
        {...rest}
      />
    </Flex>
  );
};
