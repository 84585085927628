import React, { useState } from 'react';
import { FcApproval } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { NewClientModal } from 'pages/Home/components/modules/modals';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const NewClientCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcApproval}
        title="Novo Cliente"
        secondaryText={`${assignment.name.toUpperCase()} (${
          assignment.btgAccount
        }) foi adicionado(a) na lista de clientes`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <NewClientModal
          assignment={assignment}
          handleToggleModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
