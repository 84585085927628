import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RiShareForwardLine } from 'react-icons/ri';
import {
  Flex,
  Text,
  Box,
  Avatar as ChakraAvatar,
  AspectRatio,
  HStack,
  Tag,
  TagLabel,
  Divider,
} from '@chakra-ui/react';
import parser from 'html-react-parser';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { basicDate } from 'utils/formatDate';
import {
  RelatedContents,
  ClientsToShareModal,
} from 'pages/Contents/components';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { Content } from 'pages/Contents/interfaces';
import { LoadingSpinner } from 'components/elements/others';
import { queryClient } from 'App';

export const FullContent: React.FC = () => {
  const { contentId } = useParams<{ contentId: string }>();

  queryClient.refetchQueries(['homeTasks']);

  const [selectedContent, setSelectedContent] = useState({} as Content);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let videoId;
  let videoURL;

  if (selectedContent?.video?.includes('vimeo')) {
    videoId = selectedContent?.video?.split('com/')[1];
    videoURL = `https://player.vimeo.com/video/${videoId}`;
  } else {
    videoId = selectedContent?.video?.split('=')[1];
    videoURL = `https://www.youtube.com/embed/${videoId}`;
  }

  const getSelectedContent = async () => {
    setIsLoading(true);

    try {
      const response = await hubApiUser.getContentById(contentId);

      setSelectedContent(response);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.dark(
        'Não foi possível exibir o conteúdo, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    getSelectedContent();
    window.scrollTo(0, 0);
  }, [contentId]);

  return (
    <PageWrapper flexDir="column">
      <BackPageButton />

      <Flex
        w="75vw"
        maxW="840px"
        mx="auto"
        mt={10}
        flexDirection="column"
        p="5"
      >
        <HStack spacing={2} mb={6}>
          <Tag size="md" variant="outline" colorScheme="blue">
            <TagLabel>{selectedContent.area}</TagLabel>
          </Tag>
          <Tag size="md" variant="outline" colorScheme="blue">
            <TagLabel>{selectedContent.theme}</TagLabel>
          </Tag>
        </HStack>

        <MainPageTitle mb={0}>{selectedContent?.title}</MainPageTitle>
        <Text mb={6} color="rgba(70, 70, 70, 1)">
          {selectedContent?.subtitle}
        </Text>

        <Flex justifyContent="center" mb={6}>
          <Divider
            justifyContent="center"
            orientation="horizontal"
            w="70%"
            borderColor="background.300"
          />
        </Flex>

        {selectedContent.video && (
          <AspectRatio maxH="450px" ratio={1}>
            <iframe title="video" src={videoURL} allowFullScreen />
          </AspectRatio>
        )}

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Box color="rgba(70, 70, 70, 1)">
            {parser(selectedContent.content || '')}
          </Box>
        )}

        <Flex
          gridGap={1}
          alignItems="center"
          justifyContent="space-between"
          mt={6}
          cursor="pointer"
          flexDirection={['column', 'row']}
        >
          <Flex alignItems="center" gridGap={2}>
            <ChakraAvatar
              size="md"
              name={selectedContent?.employee?.name}
              src={selectedContent?.employee?.avatar || undefined}
            />
            <Text
              fontSize="xs"
              fontWeight="light"
              fontStyle="italic"
              color="rgba(70, 70, 70, 1)"
            >
              Por{' '}
              <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                {formatNameToCaptalize(selectedContent?.employee?.name)}
              </Text>{' '}
              em{' '}
              {selectedContent.created_at &&
                basicDate(selectedContent.created_at)}
            </Text>
          </Flex>
          <SecondaryButton onClick={() => setIsModalOpen(true)}>
            Compartilhar
          </SecondaryButton>
        </Flex>

        <RelatedContents
          area={selectedContent?.area}
          theme={selectedContent?.theme}
          currentContentId={contentId}
        />
      </Flex>

      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ClientsToShareModal onClose={setIsModalOpen} contentId={contentId} />
      </ModalWrapper>
    </PageWrapper>
  );
};
