import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ManagementSegmentCardDataProps {
  item: IOrdersProps;
}

interface IOrdersProps {
  aguardando: string;
  aprovacao: string;
  canceled: string;
  deadlinepassado: string;
  execucao: string;
  grafica: string;
  id: string;
  name: string;
}

export const ManagementSegmentCardDMarketing: React.FC<ManagementSegmentCardDataProps> =
  ({ item }) => {
    const options = [
      {
        title: 'Aguardando',
        value: item.aguardando,
      },

      {
        title: 'Aprovação',
        value: item.aprovacao,
      },
      {
        title: 'Deadline Passado1',
        value: item.deadlinepassado,
      },

      {
        title: 'Execução',
        value: item.execucao,
      },
      {
        title: 'Grafica',
        value: item.grafica,
      },
      {
        title: 'Entregue/cancelado',
        value: item.canceled,
      },
    ];

    return (
      <DefaultCardWrapper
        h="340px"
        minW="250px"
        flexDir="column"
        alignItems="center"
      >
        <Flex alignItems="center" justifyContent="center">
          <MainPageTitle fontSize="md" maxW="200px " isTruncated mb="0">
            {formatNameToCaptalize(item.name)}
          </MainPageTitle>
        </Flex>

        {options.map((option, index) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="2"
            py="1"
            px="3"
            key={index}
            borderRadius="4"
            w="220px"
            bg="background.400"
          >
            <Text color="white">{option.title}</Text>
            <Text color="white">{option.value}</Text>
          </Flex>
        ))}
      </DefaultCardWrapper>
    );
  };
