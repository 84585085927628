import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { InputWithLabel } from 'components/elements/forms';
import { PageWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { Stack } from '@chakra-ui/react';
import { BackPageButton } from 'components/elements/buttons';
import schema from './schema';
import history from 'services/history';

export const CreateNotice: React.FC = () => {
  const [formInfos, setFormInfos] = useState({
    title: '',
    link: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    await schema
      .validate(formInfos, {
        abortEarly: false,
      })
      .then(async () => {
        await hubApiProducts
          .postCreateNotice(formInfos)
          .then(() => {
            toast.dark('Notícia criada com sucesso.');
            window.history.back();
            setIsLoading(false);
          })
          .catch(() => {
            toast.dark('Ocorreu um erro ao tentar criar a notícia.');
            setIsLoading(false);
          });
      })
      .catch(error => toast.dark(error.errors[0]));
    setIsLoading(false);
  };

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />

      <BaseModalFormCard
        isModal={false}
        title="Criar notícia"
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={() => history.push('/notices')}
        isLoading={isLoading}
        primaryButtonText="Salvar"
        secondaryButtonText="Voltar"
        maxW="600px"
      >
        <Stack spacing="2">
          <InputWithLabel
            label="Título"
            placeholder="Título da notícia"
            name="title"
            value={formInfos.title}
            onChange={handleChangeValue}
          />

          <InputWithLabel
            label="Link"
            placeholder="Link da notícia"
            name="link"
            value={formInfos.link}
            onChange={handleChangeValue}
          />
        </Stack>
      </BaseModalFormCard>
    </PageWrapper>
  );
};
