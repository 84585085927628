import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FiTrash2, FiEdit3 } from 'react-icons/fi';

import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import {
  IManager,
  IOffices,
  OfficeTablesProps,
} from 'pages/manager/subpages/OfficeTables/interfaces';
import { UpdateTable } from '../UpdateTable';

interface ItemProps {
  table: OfficeTablesProps;
  offices: IOffices[];
  managersList: IManager[];
  handleDeleteTable: (officeId: string) => void;
  setTables: React.Dispatch<React.SetStateAction<OfficeTablesProps[]>>;
}

export const MiniCard: React.FC<ItemProps> = ({
  table,
  setTables,
  handleDeleteTable,
  offices,
  managersList,
}) => {
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const handleToggleModal = () => {
    setShowModalUpdate(!showModalUpdate);
  };

  return (
    <DefaultCardWrapper
      justifyContent="space-between"
      bg="background.300"
      p="3"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text color="white" fontSize="sm">
          {table.name}
        </Text>

        <Flex justifyContent="flex-end" gridGap="2" alignItems="center">
          <FiEdit3
            size={18}
            color="#fff"
            cursor="pointer"
            onClick={handleToggleModal}
          />

          <FiTrash2
            size={18}
            color="#fff"
            cursor="pointer"
            onClick={() => handleDeleteTable(table.id)}
          />

          <ModalWrapper isOpen={showModalUpdate} onClose={handleToggleModal}>
            <Flex justifyContent="center" alignItems="center" h="100vh">
              <UpdateTable
                table={table}
                handleToggleModal={handleToggleModal}
                setTables={setTables}
                offices={offices}
                managersList={managersList}
              />
            </Flex>
          </ModalWrapper>
        </Flex>
      </Flex>
    </DefaultCardWrapper>
  );
};
