import React from 'react';
import { BsPeople } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { formatDate } from 'utils/formatDate';
import SingleContent from '../SingleContent';

import { CampaignContent, Container } from './styles';

const CardCampaign = ({ card, setHandleUpdate, handleUpdate }) => {
  const handleRemoveCardampaign = card => {
    hubApiMarketing
      .removeCardCampaign({
        id: card.id,
        status: 'inativa',
      })
      .then(() => {
        toast.dark('Requisição de Lead deletado com sucesso.');
        setHandleUpdate(!handleUpdate);
      })
      .catch(() => {
        toast.dark('Houve um erro ao tentar excluir o lead. Tente novamente.');
      });
  };

  return (
    <Container>
      <CampaignContent>
        <div className="title-content">
          <div className="title-date-delete">
            <div className="title-and-date">
              <h6>{card.name}</h6>
              <p>Início da campanha: {formatDate(card.data)}</p>
            </div>
            <div className="delete-card-campaign" />
            <FiTrash2 size={19} onClick={() => handleRemoveCardampaign(card)} />
          </div>
        </div>

        <div className="leads">
          <div className="img-group">
            <BsPeople size={45} />
          </div>
          <div className="list-leads">
            <div className="single-lead">
              <strong>Leads gerados:</strong>
              <p>{card.leadsGerados}</p>
            </div>

            <div className="single-lead">
              <strong>Leads utilizados:</strong>
              <p>{card.leadsUltili}</p>
            </div>

            <div className="single-lead">
              <strong>Leads em execução:</strong>
              <p>{card.leadsExecuting}</p>
            </div>

            <div className="single-lead last-leads">
              <strong>Leads disponíveis:</strong>
              <p>{card.leadsDisp}</p>
            </div>
          </div>
        </div>

        <div className="leads-and-negative-data">
          <div>
            <h6>Leads em execução</h6>
            <div className="executation-leads">
              <SingleContent
                call
                title="Retornos"
                content={card.leadsInExecuting.returns}
              />
              <SingleContent
                call
                title="Reuniões à confirmar"
                content={card.leadsInExecuting.meetingsInConfirm}
              />
              <SingleContent
                call
                title="Reuniões confirmadas"
                content={card.leadsInExecuting.meetingsConfirm}
              />
              <SingleContent
                call
                title="Em consideração"
                content={card.leadsInExecuting.inConsideration}
              />
              <SingleContent
                call
                title="Aguardando abertura:"
                content={card.leadsInExecuting.awaitingOpening}
              />
            </div>
          </div>
          <div>
            <h6>Dados negativados</h6>
            {/* <div className="single-executation-lead">
                            <HiEmojiSad className="icon-phone" size={16} />
                            <div className="callback-info">
                                <strong>Aguardando abertura:</strong>
                                <p>78 (12%)</p>
                            </div>
                        </div> */}
            <div className="executation-leads">
              <SingleContent
                title="Não tem dinheiro"
                content={card.leadsInExecuting.returns}
              />

              <SingleContent
                title="Não existe"
                content={card.negativeData.dontExists}
              />

              <SingleContent
                title="Não ligar mais"
                content={card.negativeData.dontCall}
              />

              <SingleContent
                title="Cliente BTG"
                content={card.negativeData.clientBtg}
              />

              <SingleContent
                title="Sem interesse"
                content={card.negativeData.noInterest}
              />
            </div>
          </div>
        </div>

        <div className="media-and-conversation">
          <div className="media-title">Mídia</div>
          <div className="media-infos">
            <div>
              <p>CPV</p>
              <strong>R$ 0</strong>
            </div>
            <div>
              <p>CPL</p>
              <strong>R$ 0</strong>
            </div>
            <div>
              <p>Total investido</p>
              <strong>R$ 0</strong>
            </div>
          </div>
        </div>

        <div className="media-and-conversation">
          <div className="media-title">Conversão</div>
          <div className="media-infos">
            <div>
              <p>
                Abertura <br />
                de contas
              </p>
              <strong>{card.convert.openingCounts}</strong>
            </div>
            <div>
              <p>
                PL <br />
                Captado
              </p>
              <strong>R$ {card.convert.plCapt}</strong>
            </div>
            <div>
              <p>
                CPM
                <br />
                (custo por MM)
              </p>
              <strong>R$ {card.convert.CPMM}</strong>
            </div>
          </div>
        </div>
      </CampaignContent>
    </Container>
  );
};

export default CardCampaign;
