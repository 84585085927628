import { Flex, Text } from '@chakra-ui/react';
import { TableBase } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';
import { useNavigation } from 'hooksV2/useNavigation';
import { usePagination } from 'hooksV2/usePagination';
import { ControlTablePages } from 'pagesV2/EmployeeAgenda/components/controlTablePages';
import { LeadDetailsDesiredFlowEnum } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  filteredLeads: LeadMundoInvestType[] | undefined;
};

export const SalesHunterPoolTable: React.FC<PropsType> = ({
  filteredLeads,
}): JSX.Element => {
  const { redirect } = useNavigation();
  const breakPoint = useCommonMediaQueries();

  const breakPoints = {
    small: { itemsPerPage: 7 },
    medium: { itemsPerPage: 15 },
    big: { itemsPerPage: 12 },
  };

  const tableHeadData = [
    {
      columnName: 'Nome',
      widthPercentage: 100,
    },
    {
      columnName: 'Status',
      widthPercentage: 100,
    },
    {
      columnName: 'Proximo Contato',
      widthPercentage: 100,
    },
    {
      columnName: 'Frequência',
      widthPercentage: 100,
    },
    {
      columnName: 'PL',
      widthPercentage: 100,
    },
  ];

  const {
    currentPage,
    handleBackPage,
    handleNextPage,
    shouldShowBackPageIcon,
    shouldShowNextPageIcon,
    itemsSliced: leadsFilteredSliced,
    totalPages,
  } = usePagination({
    items: filteredLeads as LeadMundoInvestType[],
    itemsPerPage: breakPoints[breakPoint].itemsPerPage,
  });

  return (
    <Flex flexDirection="column" alignItems="center" gap={2}>
      <TableBase headData={tableHeadData}>
        {leadsFilteredSliced &&
          leadsFilteredSliced.map(lead => (
            <TableRow
              headData={tableHeadData}
              shouldHaveBorderLeft
              onClick={() =>
                redirect(
                  `/v2/lead/mundoInvest/${lead.id}?desiredFlow=${LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL}`
                )
              }
              _hover={useCommonHover()}
            >
              <Text fontWeight="bold">{TextUtils.toCapitalize(lead.name)}</Text>
              <Text>{lead.meeting ? 'Reunião' : 'Sem Atividade'}</Text>
              <Text>
                {lead?.meeting?.date
                  ? DateUtils.formatWithTime(lead.meeting.date)
                  : '-'}
              </Text>
              <Text>
                {lead.frequency < 1 ? 'Sem atividade' : lead.frequency}
              </Text>
              <Text>{CurrencyUtils.format(lead.plMundoInvest)}</Text>
            </TableRow>
          ))}
      </TableBase>
      <ControlTablePages
        currentPage={currentPage}
        totalPages={totalPages}
        handleBackPage={handleBackPage}
        handleNextPage={handleNextPage}
        shouldShowBackPageIcon={shouldShowBackPageIcon}
        shouldShowNextPageIcon={shouldShowNextPageIcon}
      />
    </Flex>
  );
};
