import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IUsersSearh } from 'pages/GenerationalVariables/interfaces';
import { formatToCapitalized } from 'brazilian-values';

interface ICardSearhUserProps {
  listEmoployee: IUsersSearh;
  addUser: (employeeSelected: IUsersSearh) => void;
}

export const CardSearhUser: React.FC<ICardSearhUserProps> = ({
  listEmoployee,
  addUser,
}) => {
  return (
    <DefaultCardWrapper
      w="100%"
      overflow="auto"
      p="2"
      bg="white"
      align="center"
      justify="space-between"
    >
      <Flex flexDir="column">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" fontSize="small">
          {formatToCapitalized(`${listEmoployee.name}`, {
            trimTrailingWhiteSpaces: false,
          })}
        </Text>

        {listEmoployee.permissions.length > 0 && (
          <Text fontWeight="bold" fontSize="sm">
            {`${listEmoployee.permissions[0].permissions.name}`}
          </Text>
        )}

        <Text fontWeight="bold" fontSize="sm">
          {listEmoployee.email}
        </Text>
      </Flex>

      <Tooltip
        hasArrow
        label="Adicionar usuário ao grupo"
        placement="top"
        bg="background.200"
        cursor="pointer"
      >
        <Box cursor="pointer" mr="6">
          <IoIosAddCircleOutline
            color="rgba(70, 70, 70, 1)"
            size={20}
            onClick={e => addUser(listEmoployee)}
          />
        </Box>
      </Tooltip>
    </DefaultCardWrapper>
  );
};
