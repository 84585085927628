import React, { useState } from 'react';
import { FcApproval } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatDate } from 'utils/formatDate';
import history from 'services/history';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;

  isCalendarContact?: boolean;
}

export const PersonalizedMeeting: React.FC<ComponentProps> = ({
  assignment,
  isCalendarContact,
}) => {
  const handleRedirectToClient = () => {
    history.push(`/user/users_clients/new/${assignment.btgAccount}`);
  };
  return (
    <>
      <AssignmentContentCard
        IconElement={FcApproval}
        title="Reunião Personalizada"
        secondaryText={`Reunião com o cliente `}
        thirdText={`${formatNameToCaptalize(
          assignment.clientName ? assignment.clientName : ''
        )}`}
        fourthText={`no dia ${formatDate(
          assignment.dataRetorno ? assignment.dataRetorno : ''
        )}`}
        onClick={handleRedirectToClient}
        isCalendarContact={isCalendarContact}
      />

      {/* <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Reunião Periódica"
          message={` Você confirma que a reunião com o(a) cliente ${formatNameToCaptalize(
            assignment.clientName ? assignment.clientName : ''
          )}, ocorreu com sucesso?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper> */}
    </>
  );
};
