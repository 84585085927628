import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input as ChackraInput,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { IoChevronBack } from 'react-icons/io5';
import { BsGrid, BsList } from 'react-icons/bs';
import { BiFilterAlt, BiInfoCircle } from 'react-icons/bi';
import { MultiSelect } from 'react-multi-select-component';

import { showToast } from 'utils';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { CardsInfos } from 'pages/MyClients/interfaces';
import { PrimaryButton } from 'components/elements/buttons';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import {
  ClientsTableView,
  ClientSummaryCard,
} from './components/modules/myClients';
import { ContactFrequencyGuidelineModal } from './components/modules/myClients/ContactFrequencyGuidelineModal/ContactFrequencyGuidelineModal';

type FilterOptions = 'all' | 'pendencies' | 'inactives' | 'frequency';
type VisualizationOption = 'mosaic' | 'table' | '';

interface ISelected {
  label: string;
  value: string;
}

// TODO: refatorar, componente muito extenso
const MyClients: React.FC = (): JSX.Element => {
  const searchClient = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<ISelected[]>([]);
  const [visualizationMode, setVisualizationMode] =
    useState<VisualizationOption>('mosaic');
  const [infosClient, setInfosClient] = useState<CardsInfos[]>([]);
  const [filteredClients, setFilteredClients] = useState<CardsInfos[]>([]);
  const [userHasClients, setUserHasClients] = useState(true);
  const [hasFilter, setHasFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [
    showContactFrequencyGuidelineModal,
    setShowContactFrequencyGuidelineModal,
  ] = useState(false);
  const [contactFrequencyPercentage, setContactFrequencyPercentage] =
    useState(0);

  const handleGetAlltags = async () => {
    try {
      const response = await hubApiUser.getAllTags();

      setAllTags(response);
    } catch (err) {
      toast.dark('Erro ao buscar as tags');
    }
  };

  const listAllClients = async () => {
    setLoading(true);

    try {
      const clientsList = await hubApiUser.listMyCLientsByClient('all', 'PL');

      if (clientsList.length <= 0) {
        setUserHasClients(false);
      } else {
        setContactFrequencyPercentage(Number(clientsList.at(-1)));
        clientsList.pop();
        setInfosClient(clientsList);
        setFilteredClients(clientsList);
      }
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar clientes.');
    }
    setLoading(false);
  };

  const handleSearchClient = () => {
    const inputSeact = searchClient.current?.value;

    if (inputSeact) {
      if (inputSeact.length === 0) {
        showToast('Não existem clientes para fazer a pesquisa');
        return;
      }

      if (inputSeact.trim() === '') {
        showToast('Escreva um nome para fazer a pesquisa');
        return;
      }

      const foundClients = filteredClients.filter(
        client =>
          client.name.toLowerCase().includes(inputSeact.trim().toLowerCase()) ||
          client.btgAccount.toLowerCase().includes(inputSeact.trim())
      );

      if (foundClients.length === 0) {
        showToast(
          'Não foi possível encontrar clientes que correspondam o valor digitado'
        );
        return;
      }

      setFilteredClients(foundClients);

      setHasFilter(true);
    }
  };

  const handleDropdownFilter = (selectedOption: FilterOptions) => {
    if (selectedOption === 'all') {
      setFilteredClients(infosClient);
    }

    if (selectedOption === 'frequency') {
      setFilteredClients(
        infosClient.filter(
          client =>
            ((Number(client.frequencyM?.split(' | ')[0]) > 30 ||
              !client.frequencyM) &&
              Number(client.pl) > 1000000) ||
            ((Number(client.frequencyM?.split(' | ')[0]) > 60 ||
              !client.frequencyM) &&
              Number(client.pl) > 300000 &&
              Number(client.pl) <= 1000000) ||
            ((Number(client.frequencyM?.split(' | ')[0]) > 90 ||
              !client.frequencyM) &&
              Number(client.pl) > 100000 &&
              Number(client.pl) <= 300000) ||
            (Number(client.frequencyM?.split(' | ')[0]) > 180 &&
              Number(client.pl) < 100000)
        )
      );
    }

    if (selectedOption === 'pendencies') {
      setFilteredClients(
        infosClient.filter(client =>
          Object.values(client).some(value => value === true)
        )
      );
    }

    if (selectedOption === 'inactives') {
      setFilteredClients(
        infosClient.filter(client => Number(client.pl) < 5000)
      );
    }
  };

  const handleDropdownFilterRelativeInfos = (e: string) => {
    if (e === 'plDeclared') {
      filteredClients.sort((item: any, proxItem: any) => {
        let result = -1;

        if (
          Number(item.declaredPLAAI ? item.declaredPLAAI : item.declaredPL) <
          Number(
            proxItem.declaredPLAAI
              ? proxItem.declaredPLAAI
              : proxItem.declaredPL
          )
        ) {
          result = 1;
        } else if (
          Number(item.declaredPLAAI ? item.declaredPLAAI : item.declaredPL) ===
          Number(
            proxItem.declaredPLAAI
              ? proxItem.declaredPLAAI
              : proxItem.declaredPL
          )
        ) {
          result = 0;
        }

        return result;
      });
    } else if (e === 'az') {
      filteredClients.sort((item: any, proxItem: any) => {
        let result = -1;

        if (item.name > proxItem.name) {
          result = 1;
        } else if (proxItem.name === item.name) {
          result = 0;
        }

        return result;
      });
    } else if (e === 'stopMoney') {
      filteredClients.sort((item: any, proxItem: any) => {
        let result = -1;

        if (Number(item.todayBalance) < Number(proxItem.todayBalance)) {
          result = 1;
        } else if (
          Number(proxItem.todayBalance) === Number(item.todayBalance)
        ) {
          result = 0;
        }

        return result;
      });
    } else {
      filteredClients.sort((item: any, proxItem: any) => {
        let result = -1;

        if (Number(item.pl) < Number(proxItem.pl)) {
          result = 1;
        } else if (Number(proxItem.pl) === Number(item.pl)) {
          result = 0;
        }

        return result;
      });
    }

    setFilteredClients([...filteredClients]);
  };

  const handleDropdownFilterTags = () => {
    const result: CardsInfos[] = [];

    for (let i = 0; i < infosClient.length; i++) {
      for (let j = 0; j < infosClient[i].tags.length; j++)
        for (let k = 0; k < selected.length; k++) {
          if (selected[k].value === infosClient[i].tags[j].tag) {
            if (
              result.findIndex(resu => resu.id === infosClient[i].id) === -1
            ) {
              result.push(infosClient[i]);
            }
          }
        }
    }

    if (result.length === 0) {
      setFilteredClients(infosClient);
    } else {
      setFilteredClients(result);
    }
  };
  useEffect(() => {
    handleDropdownFilterTags();
  }, [selected]);

  const handleBackToAll = () => {
    setHasFilter(false);
    setFilteredClients(infosClient);

    if (searchClient.current?.value) {
      searchClient.current.value = '';
    }
  };

  useEffect(() => {
    listAllClients();
    handleGetAlltags();
    const storedVisualizationMode =
      (window.sessionStorage.getItem('visualization') as VisualizationOption) ||
      'mosaic';
    setVisualizationMode(storedVisualizationMode);
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('visualization', visualizationMode);
  }, [visualizationMode]);

  const result = allTags.map(item => {
    return { label: item, value: item };
  });

  return (
    <PageWrapper paddingTop="2rem" paddingX="2rem">
      <MainPageTitle color="white" textAlign="center">
        Meus clientes
      </MainPageTitle>

      <Flex
        width="100%"
        mb="16px"
        gridGap="3"
        align="center"
        justifyContent="space-between"
        px={10}
        flexDir={['column-reverse', 'column', 'column', 'row']}
      >
        <Flex gridGap={2} w={['100%', '30%']} alignItems="center">
          <Dropdown
            w={['100px', '150px']}
            placeholder="Status"
            onChange={e =>
              handleDropdownFilter(e.target.value as FilterOptions)
            }
          >
            <option value="pendencies">Com pendências</option>
            <option value="inactives">Sem ativação</option>
            <option value="frequency">Frequência de contato</option>
            <option value="all">Todos</option>
          </Dropdown>

          <Box zIndex={10} w={['200px', '340px']}>
            <MultiSelect
              className="offers"
              options={result}
              value={selected}
              onChange={setSelected}
              labelledBy="Tags"
              hasSelectAll={false}
              disableSearch
              overrideStrings={{
                allItemsAreSelected: 'Todas foram selecionadas',
                selectSomeItems: 'Tags',
              }}
            />
          </Box>
        </Flex>

        <Flex width={['100%', '60%']} maxW={['100%', '380px']}>
          <>
            <ChackraInput
              background="background.500"
              borderColor="transparent"
              focusBorderColor="primary.600"
              _placeholder={{ color: 'gray.600' }}
              _hover={{ borderColor: 'primary.800' }}
              borderRadius="6px"
              ref={searchClient}
              size="sm"
              placeholder="Nome ou código do cliente"
              borderBottomRightRadius={0}
              borderTopRightRadius={0}
            />

            <PrimaryButton
              onClick={handleSearchClient}
              borderBottomLeftRadius={0}
              borderTopLeftRadius={0}
            >
              Procurar
            </PrimaryButton>
          </>
        </Flex>

        <Flex
          gridGap={2}
          w="35%"
          justifyContent={['center', 'flex-end']}
          alignItems="center"
        >
          <Flex flexDirection="column" mr={[0, 6]}>
            <Flex alignSelf="center" ml={[0, 2]} onClick={() => {}}>
              <Text
                as="span"
                mx="auto"
                color="white"
                lineHeight={1}
                fontSize="xl"
              >
                {/* eslint-disable-next-line no-restricted-globals */}
                {isNaN(Number(contactFrequencyPercentage))
                  ? 0
                  : contactFrequencyPercentage}
                %
              </Text>
              <BiInfoCircle
                size={14}
                title="Clique para saber mais"
                cursor="pointer"
                onClick={() => setShowContactFrequencyGuidelineModal(true)}
              />
            </Flex>
            <Text fontSize="sm">Frequência de contato</Text>
          </Flex>
          <Dropdown
            w="150px"
            placeholder="Ordenar"
            icon={<Icon as={BiFilterAlt} />}
            onChange={e => handleDropdownFilterRelativeInfos(e.target.value)}
          >
            <option value="total">Total PL</option>
            <option value="az">A - Z</option>
            <option value="stopMoney">Dinheiro parado</option>
            <option value="plDeclared">PL declarado</option>
          </Dropdown>

          <Flex gridGap={1} alignItems="center">
            <Box mt="2px">
              <BsList
                size={24}
                color={visualizationMode === 'table' ? 'white' : '#383838'}
                cursor="pointer"
                onClick={() => setVisualizationMode('table')}
              />
            </Box>
            <BsGrid
              size={18}
              color={visualizationMode === 'mosaic' ? 'white' : '#383838'}
              cursor="pointer"
              onClick={() => setVisualizationMode('mosaic')}
            />
          </Flex>
        </Flex>
      </Flex>

      {hasFilter && (
        <Flex alignItems="center" mb={2} px="40px">
          <IoChevronBack size={16} color="white" />
          <Button
            variant="link"
            color="white.100"
            fontWeight="normal"
            fontSize="sm"
            ml={2}
            onClick={() => handleBackToAll()}
          >
            Voltar para todos
          </Button>
        </Flex>
      )}

      {loading && <LoadingSpinner />}

      {!loading && !userHasClients && (
        <Text
          fontSize={['medium', 'lg']}
          color="white.400"
          alignSelf="center"
          fontWeight="medium"
          mt="8"
        >
          Você não possui clientes em nossa base
        </Text>
      )}

      {!loading &&
        userHasClients &&
        (visualizationMode === 'mosaic' || visualizationMode === '') && (
          <SimpleGrid
            columns={[1, 2, 3, 4, 5, 6]}
            gap="3"
            maxH="calc(100vh - 256px)"
            overflowY="auto"
            px="10"
          >
            {filteredClients?.map((item, index) => {
              if (
                Number(item.pl) <= 100000 &&
                Number(item.frequencyM?.split(' | ')[0]) > 180
              ) {
                return (
                  <ClientSummaryCard
                    showFrequencyArrow
                    order={item}
                    key={item.id}
                  />
                );
              }

              if (
                Number(item.pl) > 100000 &&
                Number(item.pl) <= 300000 &&
                (Number(item.frequencyM?.split(' | ')[0]) > 90 ||
                  !item.frequencyM)
              ) {
                return (
                  <ClientSummaryCard
                    showFrequencyArrow
                    order={item}
                    key={item.id}
                  />
                );
              }

              if (
                Number(item.pl) > 300000 &&
                Number(item.pl) <= 1000000 &&
                (Number(item.frequencyM?.split(' | ')[0]) > 60 ||
                  !item.frequencyM)
              ) {
                return (
                  <ClientSummaryCard
                    showFrequencyArrow
                    order={item}
                    key={item.id}
                  />
                );
              }

              if (
                Number(item.pl) > 1000000 &&
                (Number(item.frequencyM?.split(' | ')[0]) > 30 ||
                  !item.frequencyM)
              ) {
                return (
                  <ClientSummaryCard
                    showFrequencyArrow
                    order={item}
                    key={item.id}
                  />
                );
              }
              return <ClientSummaryCard order={item} key={item.id} />;
            })}
          </SimpleGrid>
        )}

      {!loading && userHasClients && visualizationMode === 'table' && (
        <Flex width="100%" px="10">
          <ClientsTableView clients={filteredClients} />
        </Flex>
      )}

      <ModalWrapper
        isOpen={showContactFrequencyGuidelineModal}
        onClose={() => setShowContactFrequencyGuidelineModal(false)}
      >
        <ContactFrequencyGuidelineModal
          isInMainPage
          closeModal={setShowContactFrequencyGuidelineModal}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default MyClients;
