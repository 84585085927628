import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { SecondaryButton } from 'components/elements/buttons';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { ManagementSegmentCardDMarketing } from 'pages/Marketing/components/modules/ManagementSegmentCardDMarketing';
import { CardTransferLeads } from '../../components/modules/CardTransferLeads';

export const MarketingManagement: React.FC = () => {
  const [ordersLen, setOrdersLen] = useState(0);

  const [marketing, setMarketing] = useState({
    title: 'Marketing',
    crmPath: '/crm/health',
    normais: [
      {
        aguardando: '',
        aprovacao: '',
        canceled: '',
        deadlinepassado: '',
        execucao: '',
        grafica: '',
        id: '',
        name: '',
      },
    ],
    canceled: [],
  });

  const getMarketingUsersStatus = async () => {
    try {
      const response = await hubApiMarketing.listMarketingUsersStatus();

      setMarketing({
        ...marketing,
        normais: [...response.normais],
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const getTransferedNo = async () => {
    try {
      const response = await hubApiMarketing.listTransferedNo();
      setOrdersLen(response.length);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    getMarketingUsersStatus();
    getTransferedNo();
  }, []);

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '6' }}
    >
      <BackPageButton />

      <Flex justifyContent="space-between" w="80%" alignItems="center">
        <MainPageTitle mb="0">Gestão de Marketing</MainPageTitle>
        <Flex alignItems="center" justifyContent="center" />
        <CardTransferLeads ordersLen={ordersLen} />
      </Flex>

      <Flex w="100%" overflow="auto" pb="3" gridGap={5} justify="space-between">
        {marketing.normais.map((item, index) => (
          <Flex flexDir="column" align="center" key={index}>
            <ManagementSegmentCardDMarketing item={item} />

            <Box mt="2">
              <Link to={`/board/manager/marketing/${item.id}`}>
                <SecondaryButton>Visualizar CRM</SecondaryButton>
              </Link>
            </Box>
          </Flex>
        ))}
      </Flex>
    </PageWrapper>
  );
};
