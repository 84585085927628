import React, { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import hubApi from 'services/hubApi';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { IStructured } from 'pages/RV/subpages/StructuredsOnDemand/interface';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { handleOnBlurClient } from 'pages/RV/utils';

interface ComponentProps {
  structured: IStructured;
  handleToggleModal: () => void;
}

const RequestModal: React.FC<ComponentProps> = ({
  structured,
  handleToggleModal,
}) => {
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    name: '',
    email: '',
    btgAccount: '',
  });
  const [formData, setFormData] = useState({
    strategyId: structured.id,
    clientId: '',
    value: '',
  });

  function formatarMoeda(elemento: any): void {
    let valor = elemento.target.value;

    valor += '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor += '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6 && valor.length <= 10) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    } else if (valor.length < 12) {
      valor = valor.replace(
        /([0-9]{1}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g,
        '$1.$2.$3,$4'
      );
    } else {
      valor = valor.replace(
        /([0-9]{3}).([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g,
        '$1.$2.$3.$4,$5'
      );
    }

    if (valor === 'NaN')
      setFormData({
        ...formData,
        value: '0',
      });

    setFormData({
      ...formData,
      value: valor,
    });
  }

  const handleChangeClientValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedClient({
      ...selectedClient,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnBlurCode = async () =>
    handleOnBlurClient(
      selectedClient.btgAccount,
      setSelectedClient,
      setFormData,
      formData
    );

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.clientId === '')
      return toast.dark('Informe o código do cliente');

    if (formData.value === '') return toast.dark('Digite o valor financeiro');

    formData.value = formData.value
      .toString()
      .split('.')
      .join('')
      .replace(',', '.');
    await hubApi
      .searchForClientCode(selectedClient.btgAccount)
      .then(async () => {
        await hubApiRv
          .createStructuredRequest(formData)
          .then(() => {
            toast.dark('Solicitação enviada com sucesso');
            handleToggleModal();
          })
          .catch(error => {
            if (error.response?.data.message !== 'Internal server error') {
              toast.dark(error.response?.data.message);
            }
          });
      })
      .catch(() => {
        toast.dark('Cliente não encontrado.Verifique o código informado.');
      });
  };

  return (
    <DefaultModalContentWrapper maxW="550px">
      <ModalTitle>
        {`Solicitar ${structured.operation} de ${structured.ticker}`}
      </ModalTitle>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing="3">
          <InputWithLabel
            label="Financeiro:"
            placeholder="Financeiro"
            name="value"
            value={formData.value}
            onChange={formatarMoeda}
            id="valor"
          />
          <InputWithLabel
            label="Cód. Cliente:"
            placeholder="Código BTG do cliente"
            name="btgAccount"
            value={selectedClient.btgAccount}
            onChange={handleChangeClientValues}
            onBlur={handleOnBlurCode}
          />
          <InputWithLabel
            label="Nome do Cliente:"
            placeholder="Nome do cliente"
            value={selectedClient.name}
            isDisabled
          />
        </Stack>
        <BaseButtonGroup marginTop="20px">
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Solicitar estruturada</PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default RequestModal;
