import { Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import history from 'services/history';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface MeetingTodayCardModalProps {
  idTask: string;
  handleRemoveItem: (
    idTask: string,
    clientId?: string,
    isSharedContent?: boolean,
    additionalInformation?: string
  ) => void;
  handleCloseModal: () => void;
}

export const MeetingTodayCardModal: React.FC<MeetingTodayCardModalProps> = ({
  idTask,
  handleCloseModal,
  handleRemoveItem,
}) => {
  const goToMeeting = () => {
    history.push(`/captation/meetings/`);
  };
  return (
    <DefaultModalContentWrapper maxW="410px">
      <ModalTitle>Deseja acessar área de reuniões?</ModalTitle>

      <Text color="white" fontWeight="medium">
        Ao acessar a reunião o card será removido de sua lista
      </Text>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleCloseModal}>Cancelar</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            handleRemoveItem(idTask, undefined, false, undefined);
            goToMeeting();
          }}
        >
          Ir para área de reuniões
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
