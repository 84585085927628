import { useState } from 'react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';

import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { toast } from 'react-toastify';
import { LoadingSpinner } from 'components/elements/others';
import { useMutation } from 'react-query';
import hubApi from 'services/hubApi';
import { queryClient } from 'App';
import { IEmployeeInfo } from '../EmployeeProfile/types';

type IProps = {
  employeeInfo: IEmployeeInfo;
  handleCloseAllModals: () => void;
};

const ConfirmFeedback: React.FC<IProps> = ({
  employeeInfo,
  handleCloseAllModals,
}) => {
  const [isOpenApproveInMIModal, setIsOpenApproveInMIModal] =
    useState<boolean>(false);

  const { mutateAsync: handleCreateEmployee, isLoading } = useMutation({
    mutationKey: ['sendEmployeeContract'],
    mutationFn: async ({
      employeeContract,
    }: {
      employeeContract: IEmployeeInfo | any;
    }) => {
      await hubApi.sendRhFeedbackOfEmployeeForm(
        employeeContract,
        employeeInfo.id
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['rhContracts']);
      handleCloseAllModals();
      toast.dark('Colaborar criado com sucesso!');
    },
  });

  const sendRhFeedbackOfEmployeeForms = async (sendToMI: boolean) => {
    const employeeContract = {
      address: {
        cep: employeeInfo.address.cep,
        city: employeeInfo.address.city,
        district: employeeInfo.address.district,
        number: employeeInfo.address.number,
        street: employeeInfo.address.street,
        uf: employeeInfo.address.uf,
      },
      admissionDate: employeeInfo.admissionDate,
      bank: {
        accountNumber: employeeInfo.bank.accountNumber,
        agency: employeeInfo.bank.agency,
        bank: employeeInfo.bank.bank,
      },
      contractType: employeeInfo.contractType,
      cpf: employeeInfo.cpf,
      dadName: employeeInfo.dadName,
      dateOfBirth: employeeInfo.dateOfBirth,
      educationLevel: employeeInfo.educationLevel,
      email: employeeInfo.email,
      maritalStatus: employeeInfo.maritalStatus,
      motherName: employeeInfo.motherName,
      name: employeeInfo.name,
      national: employeeInfo.national,
      phone: employeeInfo.phone,
      position: employeeInfo.position,
      responsibility: employeeInfo.responsibility,
      rg: employeeInfo.rg,
      salary: employeeInfo.salary,
      salaryType: employeeInfo.salaryType,
      sex: employeeInfo.sex,
      approveInMI: sendToMI,
    };

    try {
      await handleCreateEmployee({
        employeeContract,
      });
    } catch (err) {
      toast.dark(
        'Erro ao confirmar o formulario. Tente novamante ou entre em contato com o suporte.'
      );
    }
  };

  return (
    <>
      <DefaultModalContentWrapper maxW="450px">
        <ModalTitle>Você confirma essa ação?</ModalTitle>

        <BaseButtonGroup>
          <SecondaryButton onClick={handleCloseAllModals}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton onClick={() => setIsOpenApproveInMIModal(true)}>
            {isLoading ? <LoadingSpinner size="sm" /> : 'Confirmar'}
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultModalContentWrapper>

      <ModalWrapper
        isOpen={isOpenApproveInMIModal}
        onClose={() => setIsOpenApproveInMIModal(false)}
      >
        <DefaultModalContentWrapper maxW="450px">
          <ModalTitle>
            Deseja adicionar este usuário na plataforma Mundo Invest?
          </ModalTitle>

          <BaseButtonGroup>
            <SecondaryButton
              onClick={() => sendRhFeedbackOfEmployeeForms(false)}
            >
              Não
            </SecondaryButton>
            <PrimaryButton onClick={() => sendRhFeedbackOfEmployeeForms(true)}>
              Sim
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};

export default ConfirmFeedback;
