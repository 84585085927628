import React, { useState } from 'react';
import { FcSurvey } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { formatToDate } from 'brazilian-values';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const NpsCard: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcSurvey}
        title="Pesquisa NPS"
        secondaryText={`O cliente ${assignment.name.toUpperCase()} (${
          assignment.btgAccount
        }) receberá a pesquisa de NPS amanhã`}
        thirdText={`Data: ${
          assignment.created_at_pendents
            ? formatToDate(new Date(assignment.created_at_pendents))
            : ''
        }`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Pesquisa NPS"
          message={`Você confirma que o(a) cliente ${assignment.name.toUpperCase()} foi avisado(a) sobre a pesquisa?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
