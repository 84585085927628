import React, { useState, ChangeEvent, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import {
  BaseButtonGroup,
  DefaultCardWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import { PrimaryButton, BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { InputWithLabel } from 'components/elements/forms/InputWithLabel';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BaseModalFormCard } from 'components/elements/cards';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export const CreateElectronicSignature: React.FC = () => {
  const [formInfos, setFormInfos] = useState({
    passwordEletronic: '',
  });
  const [loginPassword, setLoginPassword] = useState<string>('');
  const [newElectronicPassword, setNewElectronicPassword] =
    useState<string>('');

  const [
    isOpenChangeElectronicPasswordModal,
    setIsOpenChangeElectronicPasswordModal,
  ] = useState<boolean>(false);

  const {
    mutateAsync: updateElectronicPassword,
    isLoading: isLoadingUpdateElectronicPassword,
  } = useMutation({
    mutationKey: ['changeElectronicPassword'],
    mutationFn: async () => {
      const response = await hubApiUser.updateSignature({
        loginPassword,
        newPasswordEletronic: newElectronicPassword,
      });

      return response;
    },
    onSuccess: () => {
      toast.dark('Senha eletrônica alterada com sucesso!');

      setIsOpenChangeElectronicPasswordModal(false);
    },
    onError: err => {
      const error = err as AxiosError;
      if (
        error.response?.data.error.message ===
        'Employee password does not match'
      ) {
        toast.dark('Senha de login incorreta.');
      }

      if (
        error.response?.data.error.message ===
        'Electronic signature does not have been changed'
      ) {
        toast.dark('Escolha uma senha eletrônica diferente.');
      }
    },
  });

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleSubmitChangeElectronicPassword = async (event: FormEvent) => {
    event.preventDefault();

    if (!loginPassword || !newElectronicPassword) {
      return toast.dark('Por favor preencher todos os campos.');
    }

    if (newElectronicPassword.length !== 6) {
      return toast.dark('A senha eletrônica deve conter 6 digitos');
    }

    await updateElectronicPassword();
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await hubApiUser.postCreateSignature(formInfos);

      toast.dark('Senha criada com sucesso');
    } catch (err) {
      toast.dark('Ocorreu um erro ao salvar a assinatura eletronica');
    }
  };

  return (
    <PageWrapper alignItems="center" flexDir="column" justifyContent="center">
      <BackPageButton />
      <DefaultCardWrapper w={['95vw', '540px']} flexDir="column">
        <MainPageTitle textAlign="center" justifyContent="center">
          Criar senha eletrônica
        </MainPageTitle>

        <form onSubmit={handleFormSubmit}>
          <InputWithLabel
            label="Senha"
            type="text"
            placeholder="Senha eletrônica"
            name="passwordEletronic"
            value={formInfos.passwordEletronic}
            onChange={handleChangeValue}
          />
          <Text
            fontStyle="italic"
            fontSize="sm"
            mt={1}
            cursor="pointer"
            onClick={() => setIsOpenChangeElectronicPasswordModal(true)}
          >
            Alterar senha eletrônica
          </Text>

          <BaseButtonGroup>
            <PrimaryButton mt="4" type="submit">
              Salvar
            </PrimaryButton>
          </BaseButtonGroup>
        </form>
      </DefaultCardWrapper>

      <ModalWrapper
        isOpen={isOpenChangeElectronicPasswordModal}
        onClose={() => setIsOpenChangeElectronicPasswordModal(false)}
      >
        <BaseModalFormCard
          handleFormSubmit={handleSubmitChangeElectronicPassword}
          isLoading={isLoadingUpdateElectronicPassword}
          isModal
          title="Alterar senha eletrônica"
          primaryButtonText="Confirmar"
        >
          <Flex flexDirection="column" gap={3}>
            <InputWithLabel
              label="Senha de login"
              type="password"
              placeholder="Senha de login"
              value={loginPassword}
              onChange={event => setLoginPassword(event.target.value)}
            />

            <InputWithLabel
              label="Nova senha eletrônica"
              type="text"
              placeholder="Nova senha eletrônica"
              value={newElectronicPassword}
              onChange={event => setNewElectronicPassword(event.target.value)}
            />
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>
    </PageWrapper>
  );
};
