import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LoadingSpinner } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import { AddEmployeeAccessModal } from 'pages/Dashboards/modals/AddEmployeeAccess';
import { EditPowerBIModal } from 'pages/Dashboards/modals/EditPowerBI';
import { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdEdit, MdGroup } from 'react-icons/md';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import hubApi, { BIType } from 'services/hubApi';
import { EmployeeWithAccessCard } from '../employeeWithAccessCard';
import { PowerBIPageCard } from '../powerBIPageCard';

type PropsType = {
  page: BIType;
};

export type DeletePowerBIMutationRequestType = {
  powerBIId: string;
};

export const PowerBIManagementCard: React.FC<PropsType> = ({
  page,
}): JSX.Element => {
  const [isOpenEditPowerBIModal, setIsOpenEditPowerBIModal] =
    useState<boolean>(false);

  const [isOpenAddEmployeeAccessModal, setIsOpenAddEmployeeAccessModal] =
    useState<boolean>(false);

  const handleToggleOpenEditPowerBIModal = (value: boolean) => {
    setIsOpenEditPowerBIModal(value);
  };

  const handleToggleOpenAddEmployeeAccessModal = (value: boolean) => {
    setIsOpenAddEmployeeAccessModal(value);
  };

  const { mutateAsync: deletePowerBI, isLoading: isLoadingDeletePowerBI } =
    useMutation({
      mutationKey: 'deletePowerBIPage',
      mutationFn: async (data: DeletePowerBIMutationRequestType) => {
        const response = await hubApi.deletePowerBIPage({
          powerBIId: data.powerBIId,
        });
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['powerBIManagement']);
        await queryClient.invalidateQueries(['allPowerBIsDashboards']);
        toast.dark('Pagina deletada com sucesso!');
      },
    });

  return (
    <Flex flexDirection="column" bgColor="#fff" p={5} borderRadius={3}>
      <Flex gap={5} minH="250px">
        <PowerBIPageCard page={page} key={page.id} border="1px solid #3071e4" />
        <Flex flexDirection="column" gap={2} w="70%">
          <Flex justifyContent="space-between">
            <Text mt={2} fontWeight="bold" color="rgba(70, 70, 70, 1)">
              Colaboradores com acesso ao BI
            </Text>
            {isLoadingDeletePowerBI ? (
              <Flex gap={2} alignItems="center">
                <LoadingSpinner h="10px" size="sm" />
              </Flex>
            ) : (
              <Flex gap={2} alignItems="center">
                <Tooltip
                  hasArrow
                  label="Adicionar colaborador para acessar o BI"
                  color="white"
                  bg="background.200"
                >
                  <Flex>
                    <MdGroup
                      cursor="pointer"
                      size={26}
                      onClick={() =>
                        handleToggleOpenAddEmployeeAccessModal(true)
                      }
                      color="rgba(70, 70, 70, 1)"
                    />
                  </Flex>
                </Tooltip>

                <Tooltip
                  hasArrow
                  label="Editar informações do BI"
                  color="white"
                  bg="background.200"
                >
                  <Flex>
                    <MdEdit
                      onClick={() => handleToggleOpenEditPowerBIModal(true)}
                      cursor="pointer"
                      size={23}
                      color="rgba(70, 70, 70, 1)"
                    />
                  </Flex>
                </Tooltip>

                <Tooltip
                  hasArrow
                  label="Apagar BI"
                  color="white"
                  bg="background.200"
                >
                  <Flex>
                    <FaTrash
                      size={19}
                      cursor="pointer"
                      onClick={() => deletePowerBI({ powerBIId: page.id })}
                      color="rgba(70, 70, 70, 1)"
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            )}
          </Flex>

          <Flex
            gap={2}
            wrap="wrap"
            h="200px"
            overflowY="auto"
            className="thinScrollbar"
          >
            {page.accessBi.length === 0 && (
              <Flex
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  fontStyle="italic"
                  fontWeight="thin"
                  fontSize="sm"
                  color="rgba(70, 70, 70, 1)"
                >
                  Sem colaboradores com acesso a este BI
                </Text>
              </Flex>
            )}
            {page.accessBi.map(item => {
              return (
                <EmployeeWithAccessCard page={page} employee={item.employee} />
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <ModalWrapper
        isOpen={isOpenEditPowerBIModal}
        onClose={() => handleToggleOpenEditPowerBIModal(false)}
      >
        <EditPowerBIModal
          page={page}
          handleToggleOpenEditPowerBIModal={handleToggleOpenEditPowerBIModal}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenAddEmployeeAccessModal}
        onClose={() => handleToggleOpenAddEmployeeAccessModal(false)}
      >
        <AddEmployeeAccessModal
          page={page}
          handleToggleOpenAddEmployeeAccessModal={
            handleToggleOpenAddEmployeeAccessModal
          }
        />
      </ModalWrapper>
    </Flex>
  );
};
