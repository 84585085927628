import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 10px;
  .title {
    margin-bottom: 8px;
    color: var(--white-color-200);
    font-size: var(--smaller-text);
  }

  @media screen and (min-width: 1024px) {
    .items {
      max-height: 200px;
      overflow-y: auto;
      margin-right: 6px;
      border-radius: 4px;
      background: var(--gray-color-800);
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  color: var(--white-color-200);
  font-size: var(--smaller-text);

  .header {
    font-weight: var(--font-500);
    color: var(--white-color-100);
    background: var(--gray-color-700);
  }

  p {
    border: 1px solid var(--gray-color-600);
    width: 100%;
    padding: 4px 0px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
`;
