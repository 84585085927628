import { Text, Tooltip } from '@chakra-ui/react';
import { queryClient } from 'App';
import { LoadingSpinner } from 'components/elements/others';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { FiTrash2 } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import hubApi, { BIType } from 'services/hubApi';
import { formatNameToCaptalize } from 'utils/captalizeNames';

export type DeletePowerBIAccessMutationRequestType = {
  employeeId: string;
  biId: string;
};

type PropsType = {
  page: BIType;
  employee: { id: string; name: string };
};

export const EmployeeWithAccessCard: React.FC<PropsType> = ({
  employee,
  page,
}): JSX.Element => {
  const {
    mutate: deletePowerBIAccess,
    isLoading: isLoadingDeletePowerBIAccess,
  } = useMutation({
    mutationKey: ['deletePowerBIAccess'],
    mutationFn: async (data: DeletePowerBIAccessMutationRequestType) => {
      const response = await hubApi.deleteAccessBI(data);
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['powerBIManagement']);
      await queryClient.invalidateQueries(['allPowerBIsDashboards']);

      toast.dark('Retirada a permissão com sucesso!');
    },
    onError: () => {
      toast.dark(
        'Não foi possivel retirar a permissão. Tente novamente mais tarde.'
      );
    },
  });

  return (
    <DefaultCardWrapper
      background="white"
      border="1px solid gray"
      maxW="150px"
      h="40px"
      key={employee.id}
      alignItems="center"
      gap={2}
    >
      <Tooltip
        hasArrow
        label={formatNameToCaptalize(employee.name)}
        color="white"
        bg="background.200"
      >
        <Text fontSize="small" color="rgba(70, 70, 70, 1)" isTruncated w="100%">
          {formatNameToCaptalize(employee.name)}
        </Text>
      </Tooltip>

      {isLoadingDeletePowerBIAccess ? (
        <LoadingSpinner h="10px" size="sm" />
      ) : (
        <FiTrash2
          size={16}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            deletePowerBIAccess({
              biId: page.id,
              employeeId: employee.id,
            })
          }
        />
      )}
    </DefaultCardWrapper>
  );
};
