import React, { FormEvent, useState } from 'react';
import { Avatar, Center, Divider, Flex, Input, Stack } from '@chakra-ui/react';
import {
  formatToCEP,
  formatToCNPJ,
  formatToCPF,
  formatToPhone,
  formatToRG,
} from 'brazilian-values';
import { useSelector } from 'react-redux';
import { RiEdit2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { IReduxState } from 'shared/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { errorHandler } from 'utils';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { AiOutlineCamera } from 'react-icons/ai';
import hubApi from 'services/hubApi';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { DividerItens } from '../../components/modules';
import { InputInfoDisplay } from '../../components/texts';

interface ProfilePageProps {
  employee: EmployeesProps;
  searchEmployeeData: (userId?: string | undefined) => Promise<void>;
}

export const ProfilePage: React.FC<ProfilePageProps> = ({
  employee,
  searchEmployeeData,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [editEmail, setEditEmail] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editData, setEditData] = useState({
    email: employee.email,
    position: employee.position,
    phone: employee.phone,
  });

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await hubApiUser.updateEmployee(employee.id, {
        ...(employee.email !== editData.email && { email: editData.email }),
        ...(employee.phone !== editData.phone && { phone: editData.phone }),
        ...(employee.position !== editData.position && {
          position: editData.position,
        }),
      });
      setEditEmail(false);
      setEditPosition(false);
      setEditPhone(false);
      toast.dark('Dados atualizados com sucesso!');
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleUploadImg = async (e: any) => {
    const data = new FormData();

    data.append('filename', e.target.files[0]);
    await hubApi.sendAvatar(data, employee.id);
    await searchEmployeeData();
  };

  return (
    <Flex
      w="100%"
      bg="#f2f2f2"
      borderRadius={4}
      borderWidth="1px"
      p={[4, 7]}
      gridGap={[0, 6]}
      flexDirection={['column', 'row']}
    >
      <Stack w="100%" flexDir="column" mb={['10px', '48px']}>
        <Flex position="relative" mt={['0px', '36px']} mr={['-10px', '102px']}>
          <Avatar size="2xl" mb="24px" src={employee?.avatar_url} />
          <label>
            <Flex
              bg="gray"
              opacity="0.8"
              _hover={{ opacity: '1' }}
              borderRadius="100%"
              w={{ base: '30px', lg: '36px' }}
              h={{ base: '30px', lg: '36px' }}
              align="center"
              justify="center"
              position="absolute"
              bottom="15px"
              right="170px"
              cursor="pointer"
              color="white"
            >
              <AiOutlineCamera size={24} className="icon" />
              <Input type="file" hidden onChange={handleUploadImg} />
            </Flex>
          </label>
        </Flex>
        <Flex align="center" justify="space-between">
          <InputInfoDisplay
            field="Telefone"
            data={formatToPhone(editData?.phone)}
          />

          {verifyEmployeePermission({
            user,
            areaCod: ['RH'],
            permissionCod: ['CLT', 'GTR'],
          }) && (
            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => setEditPhone(!editPhone)}
            />
          )}
        </Flex>
        <Flex align="center" justify="space-between">
          <InputInfoDisplay
            field="E-mail"
            data={editData.email?.toLowerCase()}
          />

          {verifyEmployeePermission({
            user,
            areaCod: ['RH'],
            permissionCod: ['CLT', 'GTR'],
          }) && (
            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => setEditEmail(!editEmail)}
            />
          )}
        </Flex>

        <Flex align="center" justify="space-between">
          <InputInfoDisplay
            field="Cargo"
            data={formatNameToCaptalize(editData.position)}
          />
          {verifyEmployeePermission({
            user,
            areaCod: ['RH'],
            permissionCod: ['CLT', 'GTR'],
          }) && (
            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => setEditPosition(!editPosition)}
            />
          )}
        </Flex>

        <InputInfoDisplay field="CPF" data={formatToCPF(employee?.cpf)} />
        {employee?.cnpj && (
          <InputInfoDisplay field="CNPJ" data={formatToCNPJ(employee?.cnpj)} />
        )}
        {employee.rg ||
          (employee.address && (
            <InputInfoDisplay
              field="RG"
              data={formatToRG(employee?.rg, employee?.address.uf)}
            />
          ))}
      </Stack>

      <Center>
        <Divider orientation="vertical" type="gray" />
      </Center>

      <Flex w="100%" flexDir="column">
        <Stack flexDir="column">
          <InputInfoDisplay
            field="Nome da mãe"
            data={formatNameToCaptalize(employee?.motherName)}
          />
          <InputInfoDisplay
            field="Nome do pai"
            data={formatNameToCaptalize(employee?.dadName)}
          />
          <InputInfoDisplay
            field="Sexo"
            data={formatNameToCaptalize(employee?.sex)}
          />
          <InputInfoDisplay
            field="Estado Civil"
            data={formatNameToCaptalize(employee?.maritalStatus)}
          />
          <InputInfoDisplay
            field="Nível de educação"
            data={formatNameToCaptalize(employee?.educationLevel)}
          />
        </Stack>

        {employee.address && (
          <>
            <DividerItens
              orientationDivider="horizontal"
              titleDivider="Endereço"
              mt="24px"
            />

            <Stack flexDir="column">
              <InputInfoDisplay
                field="CEP"
                data={formatToCEP(employee?.address.cep)}
              />
              <InputInfoDisplay
                w="100%"
                field="Rua"
                data={`${formatNameToCaptalize(employee?.address.street)}, ${
                  employee?.address.number
                }`}
              />
              <InputInfoDisplay
                w="100%"
                field="Bairro"
                data={formatNameToCaptalize(employee?.address.district)}
              />
              <InputInfoDisplay
                w="100%"
                field="Cidade"
                data={`${formatNameToCaptalize(
                  employee?.address.city
                )} - ${employee?.address.uf.toUpperCase()}`}
              />
            </Stack>
          </>
        )}
      </Flex>

      <ModalWrapper isOpen={editEmail} onClose={() => setEditEmail(!editEmail)}>
        <BaseModalFormCard
          maxW={['95vw', '480px']}
          isLoading={false}
          title="Alterar E-mail"
          handleFormSubmit={handleFormSubmit}
          handleToggleModal={() => setEditEmail(!editEmail)}
          primaryButtonText="Confirmar"
          isModal
        >
          <Stack spacing="2">
            <InputWithLabel
              label="E-mail:"
              placeholder="Digite o E-mail"
              value={editData.email}
              onChange={e =>
                setEditData({ ...editData, email: e.target.value })
              }
            />
          </Stack>
        </BaseModalFormCard>
      </ModalWrapper>

      <ModalWrapper
        isOpen={editPosition}
        onClose={() => setEditPosition(!editPosition)}
      >
        <BaseModalFormCard
          maxW={['95vw', '480px']}
          isLoading={false}
          title="Alterar Cargo"
          handleFormSubmit={handleFormSubmit}
          handleToggleModal={() => setEditPosition(!editPosition)}
          primaryButtonText="Confirmar"
          isModal
        >
          <Stack spacing="2">
            <InputWithLabel
              label="Cargo:"
              placeholder="Digite o Cargo"
              value={editData.position}
              onChange={e =>
                setEditData({ ...editData, position: e.target.value })
              }
            />
          </Stack>
        </BaseModalFormCard>
      </ModalWrapper>

      <ModalWrapper isOpen={editPhone} onClose={() => setEditPhone(!editPhone)}>
        <BaseModalFormCard
          maxW={['95vw', '480px']}
          isLoading={false}
          title="Alterar Telefone"
          handleFormSubmit={handleFormSubmit}
          handleToggleModal={() => setEditPhone(!editPhone)}
          primaryButtonText="Confirmar"
          isModal
        >
          <Stack spacing="2">
            <InputWithLabel
              label="Telefone:"
              placeholder="Digite o Telefone"
              value={editData.phone}
              onChange={e =>
                setEditData({ ...editData, phone: e.target.value })
              }
            />
          </Stack>
        </BaseModalFormCard>
      </ModalWrapper>
    </Flex>
  );
};
