import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query/devtools';

import { chakraTheme, styledTheme } from 'shared/styles/themes';
import { StyledToastContainer } from 'shared/styles/StyledToastContainer';
import { AgreementProvider } from 'contexts/agreementContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { persistor, store } from './store';

import Routes from './routes';

import './shared/styles/App.scss';

export const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ChakraProvider theme={chakraTheme}>
            <ThemeProvider theme={styledTheme}>
              <AgreementProvider>
                <Routes />
                <StyledToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  limit={1}
                  rtl={false}
                  newestOnTop
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                />
                <ReactQueryDevtools initialIsOpen={false} />
              </AgreementProvider>
            </ThemeProvider>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
