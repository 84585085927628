import { Stack } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';

interface ContentUpdateFrequencyModalProps {
  handleChangeFrequency: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectedLead: LeadDetailsProps;
  handleShowModalUpdateFrequency: () => void;
  handleUpdateFrequency: () => Promise<void>;
  loadingUpdateFrequency: boolean;
}

export const ContentUpdateFrequencyModal: React.FC<ContentUpdateFrequencyModalProps> =
  ({
    handleChangeFrequency,
    selectedLead,
    handleShowModalUpdateFrequency,
    handleUpdateFrequency,
    loadingUpdateFrequency,
  }) => {
    return (
      <DefaultModalContentWrapper width="100%" maxW="300px">
        <ModalTitle>Atualizar frequência</ModalTitle>

        <Stack>
          <InputWithLabel
            type="number"
            label="Digite a frequência"
            onChange={handleChangeFrequency}
            value={selectedLead.frequency}
          />
        </Stack>

        <BaseButtonGroup>
          <SecondaryButton onClick={handleShowModalUpdateFrequency}>
            Voltar
          </SecondaryButton>

          <PrimaryButton
            isLoading={loadingUpdateFrequency}
            onClick={handleUpdateFrequency}
          >
            Atualizar
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultModalContentWrapper>
    );
  };
