export const GET_UNREAD_NOTIFICATIONS_REQUEST =
  '@notifications/GET_UNREAD_NOTIFICATIONS_REQUEST';

export const GET_UNREAD_NOTIFICATIONS_SUCCESS =
  '@notifications/GET_UNREAD_NOTIFICATIONS_SUCCESS';

export const GET_ALL_NOTIFICATIONS = '@notifications/GET_ALL_NOTIFICATIONS';

export const SET_CURRENT_NOTIFICATION =
  '@notifications/SET_CURRENT_NOTIFICATION';
