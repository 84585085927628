import { Flex } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import {
  TopSectionGraphic,
  TopSectionMonthSummary,
} from 'pages/DashboardTables/components';
import { MonthNumbers, Employee } from 'pages/DashboardTables/interfaces';

interface TopSectionProps {
  monthSummaryData: {
    monthsNumbers: MonthNumbers[];
    employeesNumber: Employee[];
  };
  employeesColors: {
    employeeName: string;
    color: string;
  }[];
  graphicData: any[];
  isLoading: boolean;
}

export const TopSection: React.FC<TopSectionProps> = ({
  monthSummaryData,
  employeesColors,
  graphicData,
  isLoading,
}) => {
  return (
    <Flex w="100%" h="33%" gridGap={6}>
      <Flex w="75%" bg="background.700" borderRadius={8} p={3}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <TopSectionGraphic
            data={graphicData}
            employeesColors={employeesColors}
          />
        )}
      </Flex>

      <TopSectionMonthSummary monthSummaryData={monthSummaryData} />
    </Flex>
  );
};
