import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useQuery } from 'react-query';
import { Flex } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import {
  useVisualizationMode,
  VisualizationModeEnum,
} from 'hooksV2/useVisualizationMode';
import { SalesHunterPoolHeader } from './components/header';
import { useSalesHunterPoolContext } from './context/useSalesHunterPoolContext';
import { useShPoolFilteredLeads } from './hooks/useShPoolFilteredLeads';
import { SalesHunterPoolTable } from './components/table';
import { SalesHunterPoolMosaic } from './components/mosaic';
import { SalesHunterPoolSkeletonLoading } from './components/skeletonLoading';

export const SalesHunterPool = (): JSX.Element => {
  const { leads, setLeads } = useSalesHunterPoolContext();

  const { isLoading: isLoadingShPoolLeads } = useQuery({
    queryKey: ['shPoolLeads'],
    queryFn: async () => {
      const response = await hubApiCaptation.getSHPoolLeads();
      return response;
    },
    onSuccess: leadsResponse => {
      setLeads(leadsResponse);
    },
  });

  const isLoading = isLoadingShPoolLeads;

  const { handleChangeVisualizationMode, visualizationModeValue } =
    useVisualizationMode();

  const {
    handleChangeFilterDropdownValue,
    handleSearchLeadByPhoneOrName,
    filteredLeads,
    searchLeadByPhoneOrNameValue,
    filterDropdownValue,
  } = useShPoolFilteredLeads(leads);

  if (isLoading) {
    return <SalesHunterPoolSkeletonLoading />;
  }

  return (
    <PageWrapper paddingTop="1rem">
      <MainPageTitle color="rgba(70, 70, 70, 1)">Seus Leads</MainPageTitle>
      <Flex flexDirection="column" gap={5}>
        <SalesHunterPoolHeader
          handleChangeFilterDropdownValue={handleChangeFilterDropdownValue}
          handleChangeVisualizationMode={handleChangeVisualizationMode}
          visualizationModeValue={visualizationModeValue}
          handleSearchLeadByPhoneOrName={handleSearchLeadByPhoneOrName}
          searchLeadByPhoneOrNameValue={searchLeadByPhoneOrNameValue}
          filterDropdownValue={filterDropdownValue}
        />

        {visualizationModeValue === VisualizationModeEnum.MOSAIC && (
          <SalesHunterPoolMosaic filteredLeads={filteredLeads} />
        )}

        {visualizationModeValue === VisualizationModeEnum.TABLE && (
          <SalesHunterPoolTable filteredLeads={filteredLeads} />
        )}
      </Flex>
    </PageWrapper>
  );
};
