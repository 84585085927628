import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

import { TitleWithSideLine } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { errorHandler } from 'utils/errorHandler';
import {
  RankingMainValuesCard,
  UserRankingValuesCard,
} from '../../elements/cards';

export const CaptationRanking: React.FC = () => {
  const [rankingList, setRankingList] = useState({
    captation: 0,
    tedout: 0,
    ranking: [] as any,
  });

  useEffect(() => {
    async function listRanking() {
      await hubApiHome
        .listCaptationRanking()
        .then(response => {
          setRankingList(response);
        })
        .catch(error => {
          errorHandler(error);
        });
    }

    listRanking();

    return () => setRankingList({ captation: 0, tedout: 0, ranking: [] });
  }, []);

  return (
    <DefaultCardWrapper
      width="100%"
      // maxW={{ base: 'inherit', md: '470px' }}
      p="3"
      flexDir="column"
      h="49%"
    >
      <TitleWithSideLine>Ranking de Captação</TitleWithSideLine>

      {!rankingList.ranking[0]?.position && (
        <Flex
          h="100%"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          gridGap={2}
        >
          <RiErrorWarningLine size={32} />
          <Text
            fontStyle="italic"
            fontSize="sm"
            color="gray.400"
            fontWeight="light"
            w="65%"
            textAlign="center"
          >
            Estamos com inconsistências geradas pelos relatórios do BTG, em
            breve as informações estarão disponíveis
          </Text>
        </Flex>
      )}

      {rankingList.ranking[0]?.position && (
        <>
          <Flex
            flexDir="column"
            gridGap="1"
            px="1"
            my="4"
            overflowY="auto"
            maxH="240px"
          >
            {rankingList.ranking.map((user: any, index: any) => (
              <UserRankingValuesCard key={index} user={user} />
            ))}
          </Flex>

          <Flex flexDir={{ base: 'column', md: 'row' }} gridGap="2">
            <RankingMainValuesCard
              title="Sua captação do mês:"
              value={rankingList.captation}
              positive
            />

            <RankingMainValuesCard
              title="Seu ted-out do mês:"
              value={rankingList.tedout}
            />
          </Flex>
        </>
      )}
    </DefaultCardWrapper>
  );
};
