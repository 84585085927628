import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { InputWithLabel } from 'components/elements/forms';

import { Container, DeleteContainer } from './styles';

interface iTitleStructured {
  order: {
    id: number;
    title: string;
    value: string;
    type: string;
    delAbled: boolean;
  };
  index: number;
  handleDeleteOrder: (removedOrderId: number) => void;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexOf: number
  ) => void;
}

const TitleStructured: React.FC<iTitleStructured> = ({
  order,
  index,
  handleDeleteOrder,
  handleChangeValue,
}) => {
  return (
    <Container rowIndex={index}>
      <div className="order-row">
        <div className={`order-inputs ${!order.delAbled && 'order-disabled'}`}>
          <InputWithLabel
            name="title"
            value={order.title}
            type="text"
            label="Título:"
            placeholder="Ex: Cupom"
            onChange={e => handleChangeValue(e, index)}
          />

          <InputWithLabel
            name="value"
            value={order.value}
            label="Valor:"
            type="text"
            onChange={e => handleChangeValue(e, index)}
            placeholder="Ex: 3%"
          />
        </div>

        <DeleteContainer
          delAble={order.delAbled}
          onClick={() => handleDeleteOrder(order.id)}
        >
          <div className="delete-order-content">
            <AiOutlineDelete size={16} />
          </div>
        </DeleteContainer>
      </div>
    </Container>
  );
};

export default TitleStructured;
