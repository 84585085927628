import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AiOutlineEdit } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { ModalWrapper, DefaultCardWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IBaseLead, IQuestionProps } from 'pages/Captation/interfaces';
import { queryClient } from 'App';
import { useMutation } from 'react-query';
import { QuestionManagementModal } from '../QuestionManagementModal';
import { DeleteQuestionModal } from '../DeleteQuestionModal';

interface ComponentProps {
  question: IQuestionProps;
  lead: IBaseLead;
}

export const MeetingQuestionItem: React.FC<ComponentProps> = ({
  question,
  lead,
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleExcludeModal, setToggleExcludeModal] = useState(false);
  const [questionItem, setQuestionItem] = useState({
    question: question.question,
    answer: question.answer,
  });

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestionItem({ ...questionItem, [e.target.name]: e.target.value });
  };

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  const handleToggleExcludeModal = () => {
    setToggleExcludeModal(!toggleExcludeModal);
  };

  const handleEditQuestion = useMutation({
    mutationFn: async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await hubApiCaptation.editQuestion({
        id: question.idQuestion,
        question: questionItem.question,
        leadId: question.leadId,
      });

      await hubApiCaptation.attQuestionResponse({
        id: question.id,
        answer: questionItem.answer,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['leadQuestions', lead.id]);
      toast.dark('Atualizado com sucesso');
      handleToggleModal();
    },
  });

  const handleDeleteQuestion = useMutation({
    mutationFn: async () => {
      await hubApiCaptation.deleteResponse(question.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['leadQuestions', lead.id]);
      handleToggleExcludeModal();
      toast.dark('Deletado com sucesso');
    },
  });

  return (
    <>
      <DefaultCardWrapper
        flexDir="column"
        p="2"
        px="3"
        background="background.500"
      >
        <Flex width="100%" justifyContent="space-between" mb="2">
          <Text fontWeight="medium" fontSize="sm" color="white">
            {question.question}
          </Text>
          <Flex alignItems="center" gridGap="2">
            <AiOutlineEdit
              size={14}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setQuestionItem(question);
                handleToggleModal();
              }}
            />
            <FiTrash2
              size={14}
              style={{ cursor: 'pointer' }}
              onClick={handleToggleExcludeModal}
            />
          </Flex>
        </Flex>
        <Flex>
          <Text fontSize="small">{question.answer}</Text>
        </Flex>
      </DefaultCardWrapper>

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <QuestionManagementModal
          title="Editar pergunta"
          isLoading={handleEditQuestion.isLoading}
          questionItem={questionItem}
          handleFormSubmit={handleEditQuestion.mutateAsync}
          handleChangeValue={handleChangeValue}
          handleToggleModal={handleToggleModal}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={toggleExcludeModal}
        onClose={handleToggleExcludeModal}
      >
        <DeleteQuestionModal
          handleToggleModal={handleToggleExcludeModal}
          handleDeleteQuestion={handleDeleteQuestion.mutateAsync}
          isLoading={handleDeleteQuestion.isLoading}
        />
      </ModalWrapper>
    </>
  );
};
