import { ModalWrapper } from 'components/elements/wrappers';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentProps } from 'pages/Home/interfaces';
import { useState } from 'react';
import { RiCoinLine } from 'react-icons/ri';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface INoFeedbackPenaltyCardProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
}

export const NoFeedbackPenaltyCard: React.FC<INoFeedbackPenaltyCardProps> = ({
  assignment,
  handleRemoveItem,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const handleToggleModal = () => setToggleModal(!toggleModal);

  const employeeName = assignment.extra
    ? formatNameToCaptalize(assignment.extra)
    : 'Nome não encontrado';

  return (
    <>
      <AssignmentContentCard
        IconElement={RiCoinLine}
        title="Penalidade de Reunião sem Feedback"
        secondaryText={`Nome do lead: ${employeeName}`}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Penalidade de Reunião sem Feedback"
          message={`Nome do lead: ${employeeName}`}
          idTask={assignment.id}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
