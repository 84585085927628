import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box } from '@chakra-ui/react';

import UploadButton from 'components/elements/forms/UploadButton';

import { errorHandler } from 'utils/errorHandler';
import {
  PrimaryButton,
  SecondaryButton,
  BackPageButton,
} from 'components/elements/buttons';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import {
  formatDate,
  checkIfMeetingIsNear as getDifferenceInMinutes,
} from 'utils/formatDate';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { File, CaptationProps } from 'pages/Financials/interfaces';

import history from 'services/history';
import { captationTableHead, initialFileValue } from './data';

export const FinancialCaptation: React.FC = () => {
  const [infosTable, setInfosTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewReady, setPreviewReady] = useState(false);
  const [file, setFile] = useState(initialFileValue);

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const handleSendPreview = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('btg', file as File);

      await hubApiFinancial.sendCaptationFilePreview(formData);

      const filePreview = await hubApiFinancial.getCaptationFilePreview();

      setInfosTable(filePreview);
      setIsLoading(false);
      setPreviewReady(true);
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
      setPreviewReady(false);
    }
  };

  const handleConfirmFile = async () => {
    setIsLoading(true);

    try {
      await hubApiFinancial.sendCaptationFileConfirm({ status: 'importado' });

      toast.dark('Arquivo enviado com sucesso.');
      setInfosTable([]);
      setIsLoading(false);
      setPreviewReady(false);
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
    }
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="rgba(70, 70, 70, 1)"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Financeiro - Captação
      </MainPageTitle>

      <Flex
        w="100%"
        maxWidth={['95vw', '1080px']}
        flexDir="column"
        alignItems={['flex-start']}
      >
        <Flex
          mb="12px"
          flexDir={['column', 'row']}
          justifyContent={'left'}
          alignItems={['flex-start', 'flex-end']}
          gridGap={[2, 0]}
        >
          <Box maxWidth="200px">
            <UploadButton
              label={file.name}
              accept=".csv"
              onChange={handlePickFile}
            />
          </Box>

          <Box ml={['0px', '10px']} maxWidth="150px">
            <SecondaryButton
              py="17px"
              isLoading={isLoading}
              onClick={handleSendPreview}
            >
              Enviar Preview
            </SecondaryButton>
          </Box>
        </Flex>

        <TableBase
          headData={captationTableHead}
          width={['100%', 'fit-content']}
          maxW={['95vw', '1080px']}
          minH="200px"
          maxH="calc(100vh - 350px)"
          overflow="auto"
        >
          {infosTable.map((info: CaptationProps, index) => (
            <TableRow key={index}>
              {Object.keys(info).map((key, idx) =>
                key === 'date' ? (
                  <TableData minW="190px" w="100%" key={idx}>
                    {formatDate(info[key])}
                  </TableData>
                ) : (
                  key !== 'created_at' &&
                  key !== 'id' &&
                  key !== 'status' &&
                  key !== 'updated_at' && (
                    <TableData minW="200px" w="100%" key={idx}>
                      {info[key]}
                    </TableData>
                  )
                )
              )}
            </TableRow>
          ))}
        </TableBase>

        <Flex justifyContent="flex-end">
          {previewReady && (
            <PrimaryButton
              mt="3"
              isLoading={isLoading}
              onClick={handleConfirmFile}
            >
              Confirmar e enviar arquivo
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
