import { Flex, Text } from '@chakra-ui/react';
import {
  ClientPendencyType,
  ClientPendencyTypeEnum,
} from 'sharedV2/types/client';

type UseGetComponentForStuckMoneyTaskResponseType = {
  component: JSX.Element;
};

export const useGetComponentForStuckMoneyTask = (
  task: ClientPendencyType
): UseGetComponentForStuckMoneyTaskResponseType => {
  if (task.type !== ClientPendencyTypeEnum.STUCK_MONEY) {
    return {
      component: <></>,
    };
  }

  // const { name, btgAccount } = JSON.parse(task.extra || '');

  const component = (
    <Flex flexDirection="column" gap={1}>
      <Text color="rgba(70, 70, 70, 1)">
        Você confirma que o dinheiro do(a) cliente {task.client.name} já foi
        alocado?
      </Text>
      <Text
        color="rgba(70, 70, 70, 1)"
        w="100%"
        textAlign="right"
        fontSize="sm"
      >
        Cod. BTG: {task.client.btgCode}
      </Text>
    </Flex>
  );

  return {
    component,
  };
};
