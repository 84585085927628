import { useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { DisplayInformation } from 'components/elements/others';
import { EditableValueInput } from 'pages/IP/components/elements';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { toast } from 'react-toastify';
import { useIPContext } from 'pages/IP/hooks/useIPContext';

interface SpecificOrderSummaryProps {
  segment?: string;
  value?: string;
  reason?: string;
  infos: {
    [key: string]: string;
  };
  orderId: string;
  taskId: string;
}

export const SpecificOrderSummary: React.FC<SpecificOrderSummaryProps> = ({
  infos,
  value,
  reason,
  segment = '',
  orderId,
  taskId,
}) => {
  const { updateValueAtOrder } = useIPContext();

  const [itemValue, setItemValue] = useState(value ?? '0');

  const handleUpdateValueItem = async (valueItem: string) => {
    try {
      await hubApiIp.updateOrderIp(orderId, {
        value: valueItem,
      });

      updateValueAtOrder(orderId, valueItem, 'value', taskId);
      setItemValue(valueItem);

      toast.dark('Valor do bem atualizado com sucesso!');
    } catch (error) {
      toast.dark('Falha ao atualizar Valor do bem!');
    }
  };

  return (
    <Stack spacing="3">
      {segment === 'agro' && (
        <>
          <DisplayInformation
            label="Tipo"
            info={
              infos?.personType === 'pf' ? 'Pessoal Física' : 'Pessoa Jurídica'
            }
          />
          <DisplayInformation
            label="Opção"
            info={infos?.agroOption === 'especulacao' ? 'Especulação' : 'Hedge'}
          />
        </>
      )}

      {infos?.corporateOption && (
        <>
          <DisplayInformation
            label="Tipo"
            info={
              infos?.personType === 'pf' ? 'Pessoal Física' : 'Pessoa Jurídica'
            }
          />
          <DisplayInformation
            label="Opção"
            info={formatNameToCaptalize(infos.corporateOption)}
          />
        </>
      )}

      {segment === 'saude' && (
        <DisplayInformation
          label="Motivo"
          info={reason === 'reduction' ? 'Redução' : 'Novo'}
        />
      )}

      {(infos?.subSegment === 'auto / frota' || segment === 'imobiliario') && (
        <EditableValueInput
          defaultValue={itemValue}
          updateItemValue={handleUpdateValueItem}
        />
      )}

      {infos?.subSegment === 'empresarial' && (
        <DisplayInformation
          label="Tipo de Empresa"
          info={formatNameToCaptalize(infos.companyType)}
        />
      )}

      {infos?.subSegment === 'responsabilidade civil' && (
        <DisplayInformation
          label="Tipo de Responsabilidade Civil"
          info={formatNameToCaptalize(infos.civilLiabilityType)}
        />
      )}

      {infos?.subSegment === 'náutico' && (
        <DisplayInformation
          label="Tipo de Embarcação"
          info={formatNameToCaptalize(infos.nauticalType)}
        />
      )}

      {infos?.subSegment === 'máquinas e equipamentos' && (
        <DisplayInformation
          label="Segmento de Atuação ou Tipo de Maquinário"
          info={formatNameToCaptalize(infos.machineryOrSegmentType)}
        />
      )}

      {infos?.subSegment === 'viagem' && (
        <>
          <DisplayInformation
            label="Destino"
            info={formatNameToCaptalize(infos.travelDestiny)}
          />

          <DisplayInformation
            label="Período (dias)"
            info={formatNameToCaptalize(infos.travelPeriodInDays)}
          />
        </>
      )}

      {infos?.subSegment === 'residencial' && (
        <DisplayInformation
          label="Tipo"
          info={formatNameToCaptalize(infos.residenceType)}
        />
      )}
    </Stack>
  );
};
