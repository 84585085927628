import { Flex, FlexProps, Divider } from '@chakra-ui/react';
import React from 'react';

export const ContentWithVertDividerWrapper: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex>
      <Divider orientation="vertical" borderColor="background.100" />
      <Flex flexDir="column" color="white" pl="2" {...rest}>
        {children}
      </Flex>
    </Flex>
  );
};
