import { Dropdown } from 'components/elements/others';
import { IGeneralWalletName } from '../dtos';

interface IProps {
  setSelectItem(value: IGeneralWalletName): void;
}

export const GeneralWalletModal: React.FC<IProps> = ({ setSelectItem }) => {
  const data: Array<Record<string, IGeneralWalletName | string>> = [
    { name: 'EWZ', value: 'EWZ' },
    { name: 'Mundo Invest', value: 'MI' },
  ];

  return (
    <Dropdown
      w="90%"
      onChange={e => setSelectItem(e.target.value as IGeneralWalletName)}
    >
      {data.map(item => (
        <option value={item.value}>{item.name}</option>
      ))}
    </Dropdown>
  );
};
