import React, { useState } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { errorHandler } from 'utils';
import { useCaptationMeetingsContext } from 'hooks';
import hubApi from 'services/hubApi';
import { IReduxState } from 'shared/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { transferLeadsObservationsToClientNotes } from 'pages/Captation/utils';

interface IClientOpenAccountProps {
  handleShowModal: () => void;
  meetingInfos: IBaseMeeting;
  handleAddTextAreaQuestion: () => Promise<void>;
}

export const ClientOpenAccount: React.FC<IClientOpenAccountProps> = ({
  handleShowModal,
  meetingInfos,
  handleAddTextAreaQuestion,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const [btgAcc, setBtgAcc] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showBtgCodeConfirmationModal, setShowBtgCodeConfirmationModal] =
    useState(false);
  const [clientWithProvidedBtgCode, setClientWithProvidedBtgCode] =
    useState('');

  const removeLeadFromMeetingsPanel = async () => {
    setIsButtonLoading(true);
    try {
      const meetingData = {
        leadId: meetingInfos.leadId,
        id: meetingInfos.id,
        employeeId: meetingInfos.employeeId,
        employeeIdParticipant: user.id,
        type: 'meeting',
        local: '',
        leadType: meetingInfos.leadType,
        complement: '',
        date: new Date(),
        status: 'alocado',
        feedback: '',
        isPipe: false,
      };

      await hubApiCaptation.pathMetting(meetingData);
      await hubApiCaptation.pathLeadsUpdate(meetingInfos.leadType, {
        id: meetingInfos.leadId,
        btgAccount: btgAcc,
      });

      await transferLeadsObservationsToClientNotes(meetingInfos.leadId, btgAcc);
      await handleGetSpecificLeadTypeArr(meetingInfos.status, true);

      toast.dark('Conta vinculada com sucesso');

      setShowBtgCodeConfirmationModal(false);
      handleShowModal();
    } catch (err) {
      toast.dark(
        'Não foi possível finalizar o lead, tente novamente em alguns minutos'
      );
    }

    setIsButtonLoading(false);
  };

  const btgCodeValidation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await hubApiCaptation.notifyNewBtgAccount(btgAcc, {
        nomeListaPool: meetingInfos.nomeListaPool || '',
        leadType: meetingInfos.leadType,
      });

      handleFormAccount();
    } catch (err: any) {
      if (
        err.response?.data.error.message.includes(
          'Client already has from type defined.'
        )
      ) {
        const client = err.response?.data.error.message.split(': ')[1];

        setClientWithProvidedBtgCode(client);
        setShowBtgCodeConfirmationModal(true);
      } else if (err.response?.status === 404) {
        toast.dark(
          'Não foi possível encontrar o código do cliente. O código pode demorar até 48 horas para ser atualizado na base de dados. '
        );
      } else {
        errorHandler(err);
      }
    }

    setIsLoading(false);
  };

  const handleFormAccount = async () => {
    try {
      await hubApi.searchForClientCode(btgAcc);

      const meetingData = {
        leadId: meetingInfos.leadId,
        id: meetingInfos.id,
        employeeId: meetingInfos.employeeId,
        employeeIdParticipant: user.id,
        type: 'meeting',
        local: '',
        leadType: meetingInfos.leadType,
        complement: '',
        date: new Date(),
        status: 'account',
        feedback: '',
      };

      await hubApiCaptation.pathMetting(meetingData);
      await hubApiCaptation.pathLeadsUpdate(meetingInfos.leadType, {
        id: meetingInfos.leadId,
        btgAccount: btgAcc,
      });

      await transferLeadsObservationsToClientNotes(meetingInfos.leadId, btgAcc);

      localStorage.removeItem(meetingInfos.leadId);

      const isUpdateArr = true;
      await handleGetSpecificLeadTypeArr('account', isUpdateArr);
      await handleGetSpecificLeadTypeArr(meetingInfos.status, isUpdateArr);
      handleAddTextAreaQuestion();
      handleShowModal();
      toast.dark('Conta BTG registrada com sucesso');
    } catch (err: any) {
      if (err.response?.status === 404) {
        toast.dark(
          'Não foi possível encontrar o código do cliente. O código pode demorar até 48 horas para ser atualizado na base de dados. '
        );
      } else {
        errorHandler(err);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <BaseModalFormCard
        maxW="480px"
        isLoading={isLoading}
        title="Abertura de conta"
        handleFormSubmit={btgCodeValidation}
        handleToggleModal={handleShowModal}
        primaryButtonText="Confirmar"
        isModal
      >
        <Stack spacing="2">
          <InputWithLabel
            label="Código BTG:"
            placeholder="Digite o código BTG do cliente"
            value={btgAcc}
            onChange={e => setBtgAcc(e.target.value)}
          />
        </Stack>
      </BaseModalFormCard>
      <ModalWrapper
        isOpen={showBtgCodeConfirmationModal}
        onClose={() => setShowBtgCodeConfirmationModal(false)}
      >
        <DefaultModalContentWrapper width="500px" gap={3}>
          <Flex
            justifyContent="center"
            alignItems="baseline"
            height="10%"
            color="#fff"
            flexDir="column"
            gap={2}
          >
            <Text>
              {`A conta BTG informada está vinculada ao cliente ${clientWithProvidedBtgCode}. Deseja prosseguir?`}
            </Text>
            <Text fontSize="xs" color="gray.500">
              * Ao confirmar o lead será removido do seu painel de reuniões e as
              anotações salvas serão transferidas para a aba de notas do cliente
              (Meus clientes &gt; Painel do cliente)
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            height="10%"
            gap={2}
          >
            <SecondaryButton
              onClick={() => setShowBtgCodeConfirmationModal(false)}
            >
              Não
            </SecondaryButton>

            {isButtonLoading && (
              <PrimaryButton onClick={removeLeadFromMeetingsPanel}>
                <LoadingSpinner size="sm" />
              </PrimaryButton>
            )}

            {!isButtonLoading && (
              <PrimaryButton onClick={removeLeadFromMeetingsPanel}>
                Sim
              </PrimaryButton>
            )}
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
