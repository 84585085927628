import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IHandleMibsOperation } from '../dtos';
import { SensitiveTransactionModal } from './SensitiveTransactionModal';

interface IProps {
  setIsOpenRedeemMIBsModal(value: boolean): void;
  handleMibsOperation: IHandleMibsOperation;
  personalWalletEmployeeMibs: number;
}

export const RedeemMIBsModal: React.FC<IProps> = ({
  setIsOpenRedeemMIBsModal,
  handleMibsOperation,
  personalWalletEmployeeMibs,
}) => {
  const [mibsQuantity, setMibsQuantity] = useState<string>('');
  const [isOpenConfirmTransactionModal, setIsOpenConfirmTransactionModal] =
    useState<boolean>(false);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (mibsQuantity === '' || Number.isNaN(mibsQuantity)) {
      return toast.dark('Insira uma quantidade de MIBs para comprar.');
    }

    if (Number(mibsQuantity) > personalWalletEmployeeMibs) {
      return toast.dark('Quantidade de MIBs insuficientes.');
    }

    setIsOpenConfirmTransactionModal(true);
  };

  return (
    <BaseModalFormCard
      title="Resgatar MIBs"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={() => setIsOpenRedeemMIBsModal(false)}
      isModal
      primaryButtonText="Resgatar"
      isLoading={handleMibsOperation.isLoading}
    >
      <InputWithLabel
        label="Quantidade de MIBs"
        placeholder="100"
        value={mibsQuantity}
        onChange={e => setMibsQuantity(e.target.value)}
      />
      <ModalWrapper
        isOpen={isOpenConfirmTransactionModal}
        onClose={() =>
          setIsOpenConfirmTransactionModal(!isOpenConfirmTransactionModal)
        }
      >
        <SensitiveTransactionModal
          handleFormSumit={handleMibsOperation}
          setIsOpenFatherModal={setIsOpenRedeemMIBsModal}
          setIsOpenConfirmTransactionModal={setIsOpenConfirmTransactionModal}
          mibsQuantity={mibsQuantity}
          operation="WITHDRAW"
          sourceAction="MIBS_REDEEM"
          sourceWallet="MI"
          destinationWallet="personalWallet"
        />
      </ModalWrapper>
    </BaseModalFormCard>
  );
};
