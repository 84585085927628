import { Flex } from '@chakra-ui/react';
import { ClientDetailsRevenueTable } from 'pagesV2/ClientDetails/components/revenueTable';
import { useQuery } from 'react-query';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsRevenueSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { data } = useQuery({
    queryKey: ['clientRevenue', client.btgCode],
    queryFn: async () => {
      const response = await hubApiFinancial.getMonthlyCommission(
        client.btgCode
      );

      return response;
    },
  });

  return (
    <Flex w="100%">
      <ClientDetailsRevenueTable data={data} client={client} />
    </Flex>
  );
};
