import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { BackPageButton } from 'components/elements/buttons';

interface DefaultPageWrapperProps extends FlexProps {
  backPageButton: boolean;
}

export const DefaultPageWrapper: React.FC<DefaultPageWrapperProps> = ({
  children,
  backPageButton,
  ...rest
}) => {
  return (
    <Flex
      minH="calc(100vh - 80px)"
      height="100%"
      paddingX="20px"
      background="rgba(242, 242, 242, 0.95)"
      position="relative"
      overflowX="hidden"
    >
      {backPageButton && <BackPageButton />}

      <Flex
        w="90%"
        maxW={{ base: '1080px', '2xl': '1240px' }}
        mx="auto"
        mt={8}
        alignItems="center"
        flexDirection="column"
        {...rest}
      >
        {children}
      </Flex>
    </Flex>
  );
};
