import { Tr, TableRowProps } from '@chakra-ui/react';
import React from 'react';

export const TableRow: React.FC<TableRowProps> = ({ children, ...rest }) => {
  return (
    <Tr borderBottom="1px solid" color="rgba(70, 70, 70, 1)" {...rest}>
      {children}
    </Tr>
  );
};
