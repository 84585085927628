import { Flex, Link, Text, useMediaQuery } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Box } from 'componentsV2/elements/boxes';
import { Divider } from 'componentsV2/elements/divider';
import { Modal } from 'componentsV2/elements/modal';
import { IEventExtended } from 'pages/Events/interfaces/Event';
import { useState } from 'react';
import { formateDateAndTime } from 'utils/formatDate';
import parser from 'html-react-parser';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { Button } from 'componentsV2/elements/buttons';
import { useGetEventDateInformation } from './hooks/useGetEventDateInformation';

type PropsType = {
  event: IEventExtended;
};

function CalendarIcon() {
  return (
    <svg
      width={19}
      height={21}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.167 7.375h16.666m-4.166-6.25v2.083M5.333 1.125v2.083m-.833 0h10c1.167 0 1.75 0 2.196.227.392.2.71.519.91.91.227.447.227 1.03.227 2.197v10c0 1.166 0 1.75-.226 2.195-.2.393-.52.711-.911.91-.446.228-1.029.228-2.196.228h-10c-1.167 0-1.75 0-2.196-.227-.392-.2-.71-.518-.91-.91-.227-.446-.227-1.03-.227-2.196v-10c0-1.167 0-1.75.227-2.196.2-.392.518-.71.91-.91.446-.228 1.03-.228 2.196-.228z"
        stroke="#1D63DD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const ReminderCard: React.FC<PropsType> = ({ event }): JSX.Element => {
  const [isNotebookWidth] = useMediaQuery('(max-width: 1500px)');

  const [isReminderCardDetailsModalOpen, setIsReminderCardDetailsModalOpen] =
    useState<boolean>(false);

  const { handleGetDay, handleGetMonth } = useGetEventDateInformation();

  return (
    <Flex
      minW={isNotebookWidth ? '30%' : '23.5%'}
      border="1px solid gray"
      borderRadius="4px"
      alignItems="center"
      h="90px"
      gap={2}
      p={2}
      cursor="pointer"
      onClick={() => setIsReminderCardDetailsModalOpen(true)}
      _hover={useCommonHover()}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w="20%"
      >
        <Text
          fontWeight="bold"
          color="white"
          bgColor="#1D63DD"
          borderRadius={2}
          fontSize={{ xl: 'xs', '2xl': 'sm' }}
          px={2}
        >
          {handleGetMonth(event.eventDateAndHour)}
        </Text>
        <Text
          fontWeight="bold"
          color="rgba(70, 70, 70, 1)"
          fontSize={{ xl: '30px', '2xl': '2xl' }}
          lineHeight="40px"
        >
          {handleGetDay(event.eventDateAndHour)}
        </Text>
      </Flex>

      <Divider />

      <Flex flexDirection="column" justifyContent="center" w="75%">
        <Text color="rgba(70, 70, 70, 1)" fontSize={{ xl: 'md', '2xl': 'lg' }}>
          {event.eventType}
        </Text>
        <Text
          color="rgba(70, 70, 70, 1)"
          fontSize={{ xl: 'md', '2xl': 'lg' }}
          fontWeight="bold"
          lineHeight="100%"
        >
          {event.eventName}
        </Text>
      </Flex>

      <Flex h="100%" alignItems="flex-start" w="5%">
        <CalendarIcon />
      </Flex>

      <ModalWrapper
        isOpen={isReminderCardDetailsModalOpen}
        onClose={() => setIsReminderCardDetailsModalOpen(false)}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title={`${event.eventName} (${event.eventType})`} />
            <Modal.CloseIcon
              onClick={() => setIsReminderCardDetailsModalOpen(false)}
            />
          </Modal.Header>

          <Flex flexDirection="column" gap={5}>
            <Flex flexDirection="column" gap={3}>
              <Box.Glass flexDirection="column" gap={1}>
                <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
                  Data e horário
                </Text>
                <Text fontSize="sm">
                  {formateDateAndTime(event.eventDateAndHour)}
                </Text>
              </Box.Glass>

              <Box.Glass flexDirection="column" gap={1}>
                <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
                  Descrição
                </Text>
                <Text>{parser(event.description)}</Text>
              </Box.Glass>
            </Flex>

            <Link href={event.link} isExternal>
              <Button.Primary w="100%">Acessar link do Evento</Button.Primary>
            </Link>
          </Flex>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
