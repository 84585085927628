import { Flex, Text } from '@chakra-ui/react';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { LeadDetailsDesiredFlowEnum } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { CellSignalHigh, CurrencyCircleDollar } from 'phosphor-react';
import { useState } from 'react';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { Icons } from 'sharedV2/icons';
import { CurrencyUtils } from 'utilsV2/Currency';
import { ScreenUtils } from 'utilsV2/Screen';
import { TextUtils } from 'utilsV2/Text';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { SalesHunterPoolLeadActions } from '../leadActions';
import { formateDateAndTimeNoTimezone } from '../../../../utils/formatDate';

function MeetingIcon() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.638 14.003H3.362a1.334 1.334 0 01-1.334-1.334V5.332C2.028 4.595 2.625 4 3.362 4h9.276c.737 0 1.334.596 1.334 1.333v7.337c0 .737-.598 1.334-1.334 1.334z"
        stroke="#1D63DD"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.999a1.667 1.667 0 110 3.334A1.667 1.667 0 018 6M11.001 12.002a1.59 1.59 0 00-.396-.578v0a1.58 1.58 0 00-1.076-.422H6.47c-.4 0-.783.15-1.076.422v0a1.59 1.59 0 00-.396.578M3.186 3.998v-.667c0-.737.597-1.333 1.334-1.333h6.96c.736 0 1.333.596 1.333 1.333v.667"
        stroke="#1D63DD"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

type PropsType = {
  lead: LeadMundoInvestType;
};

export const SalesHunterPoolLeadCard: React.FC<PropsType> = ({
  lead,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const { setParam, getParam } = useQueryParams();

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const lastViewedLeadId = getParam('lastViewedLeadId');

  const meetingDate = lead?.meeting
    ? new Date(lead?.meeting?.date).toISOString()
    : new Date().toISOString();

  const [
    copyLeadPhoneToClipboardTooltipText,
    setCopyLeadPhoneToClipboardTooltipText,
  ] = useState<string>('Copiar telefone');

  const handleLeadCardClick = () => {
    const scrollTop = ScreenUtils.getScrollTop('scrollContainer');
    setParam('scrollTop', scrollTop.toString());
    setParam('lastViewedLeadId', lead.id);

    redirect(
      `/v2/lead/mundoInvest/${lead.id}?desiredFlow=${LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL}`
    );
  };

  return (
    <Box.Opaque
      h="100%"
      p={3}
      cursor="pointer"
      onClick={handleLeadCardClick}
      shouldHaveAllBorders={lastViewedLeadId === lead.id}
      _hover={useCommonHover()}
    >
      <Text
        color="rgba(70, 70, 70, 1)"
        fontSize="lg"
        fontWeight="bold"
        isTruncated
      >
        {TextUtils.toCapitalize(lead.name)}
      </Text>
      <Flex flexDirection="column" mt={1} gap={1}>
        <Flex flexDirection="column" gap={2}>
          {lead?.meeting?.date ? (
            <Flex alignItems="center" gap={2}>
              <MeetingIcon />
              <Flex flexDirection="column">
                <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                  Reunião
                </Text>
                <Text color="rgba(70, 70, 70, 1)">
                  {lead?.meeting.status === MeetingStatusEnum.RETURNED
                    ? 'R.N.A'
                    : formateDateAndTimeNoTimezone(meetingDate)}
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex alignItems="center" gap={2}>
              <CellSignalHigh size={24} color="#1D63DD" />

              <Flex flexDirection="column">
                <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                  Frequência
                </Text>
                <Text color="rgba(70, 70, 70, 1)">
                  {lead.frequency < 1 ? 'Sem atividade' : lead.frequency}
                </Text>
              </Flex>
            </Flex>
          )}

          <Flex alignItems="center" gap={2}>
            <Icons.PHONE />

            <Flex flexDirection="column">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                Telefone
              </Text>
              <Tooltip text={copyLeadPhoneToClipboardTooltipText}>
                <Text
                  color="rgba(70, 70, 70, 1)"
                  onClick={event => {
                    event.stopPropagation();
                    TextUtils.copyToClipboard(lead.phone);
                    setCopyLeadPhoneToClipboardTooltipText('Copiado!');

                    setTimeout(() => {
                      setCopyLeadPhoneToClipboardTooltipText('Copiar telefone');
                    }, 2000);
                  }}
                >
                  {TextUtils.toPhone(lead.phone)}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>

          <Flex alignItems="center" gap={2}>
            <CurrencyCircleDollar size={24} color="#1D63DD" />

            <Flex flexDirection="column">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                PL:
              </Text>
              <Text color="rgba(70, 70, 70, 1)">
                {CurrencyUtils.format(lead.plMundoInvest)}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {leadBussinessLogic.shouldDisplayLeadActions() && (
          <SalesHunterPoolLeadActions lead={lead} />
        )}
      </Flex>
    </Box.Opaque>
  );
};
