/* eslint @typescript-eslint/no-explicit-any: off */
import React from 'react';
import { Stack } from '@chakra-ui/react';

import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { CardInfo } from 'components/elements/others/CardInfo';

interface ComponentProps {
  order: any;
  handleToggleModal: () => void;
}

export const OrderInfosMkt: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  return (
    <DefaultModalContentWrapper maxW={['95vw', '490px']}>
      <ModalTitle>Dados da ordem</ModalTitle>

      <Stack spacing="2">
        <CardInfo title="Tipo da ordem" info={order.type} />

        {order.phone && <CardInfo title="Telefone" info={order.phone} />}

        <CardInfo title="E-mail" info={order.email} />

        {order.position && (
          <CardInfo title="Posição na EWZ" info={order.position} />
        )}
        {order.project && <CardInfo title="Projeto" info={order.project} />}
        {order.goals && <CardInfo title="Objetivo" info={order.goals} />}
        {order.title && <CardInfo title="Título" info={order.title} />}
        {order.cardType && (
          <CardInfo title="Tipo de cartão" info={order.cardType} />
        )}
        {order.text && <CardInfo title="Texto" info={order.text} />}
        {order.informations && (
          <CardInfo title="Informações" info={order.informations} />
        )}
        {order.publication && (
          <CardInfo title="Divulgação" info={order.publication} />
        )}
        {order.formatting && (
          <CardInfo title="Formatação" info={order.formatting} />
        )}
      </Stack>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Fechar</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
