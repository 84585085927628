import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__close-button {
    color: rgba(70, 70, 70, 1);
  }

  .Toastify__toast {
    background: #f2f2f2;
    color: rgba(70, 70, 70, 1);
  }

  .Toastify__toast {
    font-size: var(--smaller-text);
  }

  .Toastify__progress-bar {
    background: var(--primary-color-200);
  }
`;
