import { Divider, Flex, Text } from '@chakra-ui/react';
import { PulseAnimation } from 'pages/Home/components/elements/others/PulseAnimation';

type PropsType = {
  title: string;
  subtitle: string;
};

export const ProductReminderCard: React.FC<PropsType> = ({
  title,
  subtitle,
}): JSX.Element => {
  return (
    <Flex
      minW="25%"
      w="100%"
      border="1px solid gray"
      borderRadius="4px"
      alignItems="center"
      h="90px"
      gap={2}
      p={2}
    >
      <Flex w="20%" h="100%" alignItems="center">
        <Flex
          w="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <PulseAnimation />
        </Flex>

        <Divider
          orientation="vertical"
          borderColor="#1D63DD"
          h="70%"
          borderWidth="1px"
          borderRadius={2}
        />
      </Flex>

      <Flex w="75%" flexDirection="column" justifyContent="center">
        <Text>{subtitle}</Text>
        <Text
          color="rgba(70, 70, 70, 1)"
          fontSize="xl"
          fontWeight="bold"
          lineHeight="100%"
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};
