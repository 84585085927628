import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { TableBase } from 'componentsV2/elements/table/base';
import { usePagination } from 'hooksV2/usePagination';
import { IMeeting } from 'pages/UserAgenda';
import { ControlTablePages } from '../controlTablePages';
import { EmployeeAgendaTableContent } from '../tableContent';

type PropsType = {
  meetings: IMeeting[];
};

export const EmployeeAgendaTable: React.FC<PropsType> = ({
  meetings,
}): JSX.Element => {
  const [isNotebookWidth, isSmallHeight] = useMediaQuery([
    '(max-width: 1500px)',
    '(max-height: 720px)',
  ]);

  const {
    currentPage,
    handleBackPage,
    handleNextPage,
    shouldShowBackPageIcon,
    shouldShowNextPageIcon,
    itemsSliced: meetingsSliced,
    totalPages,
  } = usePagination({
    items: meetings,
    itemsPerPage: isSmallHeight ? 8 : 12,
  });

  const tableHeadData = [
    {
      columnName: 'Data',
      widthPercentage: isNotebookWidth ? 100 : 50,
    },
    {
      columnName: 'Reunião',
      widthPercentage: 150,
    },
    {
      columnName: 'Horário',
      widthPercentage: 100,
    },
    {
      columnName: 'Pipe',
      widthPercentage: 100,
    },
    {
      columnName: 'Origem',
      widthPercentage: 100,
    },
    {
      columnName: 'Estágio',
      widthPercentage: 100,
    },
    {
      columnName: 'Tipo Reunião',
      widthPercentage: 100,
    },
  ];

  return (
    <Flex flexDirection="column" alignItems="center" gap={4}>
      {meetingsSliced.length > 0 && (
        <>
          <TableBase headData={tableHeadData}>
            {meetingsSliced.map((meeting, index) => (
              <EmployeeAgendaTableContent
                key={index}
                tableHeadData={tableHeadData}
                meeting={meeting}
              />
            ))}
          </TableBase>
          <ControlTablePages
            currentPage={currentPage}
            totalPages={totalPages}
            handleBackPage={handleBackPage}
            handleNextPage={handleNextPage}
            shouldShowBackPageIcon={shouldShowBackPageIcon}
            shouldShowNextPageIcon={shouldShowNextPageIcon}
          />
        </>
      )}
      {meetingsSliced.length <= 0 && (
        <Flex mt={10}>
          <Text fontStyle="italic">Você não tem reuniões agendadas</Text>
        </Flex>
      )}
    </Flex>
  );
};
