import { IBaseMeeting } from 'pages/Captation/interfaces';
import { useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { DECLINED_LEADS_QUERIES_KEYS } from './DECLINED_LEADS_QUERIES_KEYS';

interface IDeclinedMeeting extends IBaseMeeting {
  declineReason: string;
}

export const useDeclinedLeads = (): UseQueryResult<IDeclinedMeeting[]> => {
  return useQuery<IDeclinedMeeting[]>(
    DECLINED_LEADS_QUERIES_KEYS.declinedLeads,
    async () => {
      const response: IDeclinedMeeting[] =
        await hubApiCaptation.getDeclinedLeadsByEmployee();
      const filter = response.filter(item => item.leadType !== 'publics');
      return filter;
    },
    {
      onError: () =>
        toast.dark(
          'Erro ao mostrar leads declinados. Tente novamente mais tarde!'
        ),
      refetchOnWindowFocus: false,
    }
  );
};
