import { HubApi } from '../index';

interface IPostMonthlyStrategies {
  segment: string;
  clientId: string;
  risk: string;
  value: string;
}

interface IPostSpecificOrders {
  sheres: {
    ticker: string;
    financial: string;
    type: string;
  }[];
  segment: string;
  clientId: string;
}

interface ICreateStructuredRequest {
  strategyId: string;
  clientId: string;
  value: string;
}

interface IStructuredStrategy {
  performances: {
    performance: string;
    income: string;
  }[];
  coupons: {
    title: string;
    value: string;
  }[];
  strategy: string;
  operation: string;
  ticker: string;
  whatsMessage: string;
  fee: string;
  referencePrice?: string;
}

interface ITransferedToParams {
  orderId: string;
  toEmployeeId: string;
}

interface IAttClosedDay {
  code: string;
  value: string;
}

interface IUpdateOrderStatus {
  type: string | undefined;
  status: string;
}

interface IUpdateRiskOrder {
  formInfos: string;
  risk: string;
  segment: string;
}

interface IValue {
  idClient: string | undefined;
  segment: string;
  risk: string;
  value?: string;
}

interface IOrderExtract {
  btgAccount: string;
  type: string;
  ticker: string;
  financial: string;
  name: string;
  status: string;
}

class HubApiRv extends HubApi {
  async postMonthlyStrategies(data: IPostMonthlyStrategies) {
    const response = await this.axios.post('/orders/rv/strategic', data);
    return response.data;
  }

  async postAnotations(data: any) {
    const response = await this.axios.post('/orders/anotations/', data);
    return response.data;
  }

  async postSpecificOrders(data: IPostSpecificOrders) {
    const response = await this.axios.post('/orders/rv/specific', data);
    return response.data;
  }

  async createStructuredRequest(data: ICreateStructuredRequest) {
    const response = await this.axios.post('/orders/rv/structured', data);
    return response.data;
  }

  async createStructuredStrategy(data: IStructuredStrategy) {
    const response = await this.axios.post(
      '/orders/rv/structured/strategy',
      data
    );
    return response.data;
  }

  async editStructuredById(data: IStructuredStrategy) {
    const response = await this.axios.put(
      '/orders/rv/structured/strategy',
      data
    );
    return response.data;
  }

  async listUserOrders() {
    const response = await this.axios.get('/orders/rv/me');
    return response.data;
  }

  async listEmployeesRv() {
    const response = await this.axios.get('orders/rv/employees');
    return response.data;
  }

  async listMsgCard(id: string) {
    const response = await this.axios.get(`/orders/anotations/${id}`);
    return response.data;
  }

  async listUsersRv() {
    const response = await this.axios.get('/orders/rv/employees');
    return response.data;
  }

  async listStructuredsTable() {
    const response = await this.axios.get(
      '/orders/rv/structured/strategy/closing'
    );
    return response.data;
  }

  async transferedTo(data: string | undefined, params: ITransferedToParams) {
    const response = await this.axios.patch(
      `orders/rv/transferedTo/${data}`,
      params
    );
    return response.data;
  }

  async listEmployeesWithRvPermission() {
    const response = await this.axios.get('/employees/area/RV');
    return response.data;
  }

  async listAllStructureds() {
    const response = await this.axios.get('/orders/rv/structured/strategy');
    return response.data;
  }

  async listAllStructuredsById(id: string) {
    const response = await this.axios.get(
      `/orders/rv/structured/strategy/${id}`
    );
    return response.data;
  }

  async attClosedDay(data: IAttClosedDay) {
    const response = await this.axios.patch(
      '/orders/rv/structured/strategy/closing',
      data
    );
    return response.data;
  }

  async deleteStructured(id: string) {
    const response = await this.axios.patch(
      `/orders/rv/structured/strategy/delete/${id}`
    );
    return response.data;
  }

  async listAllClientesCustumersRv(segment: string) {
    const response = await this.axios.get(
      `/orders/rv/strategic/clientTable/${segment}`
    );
    return response.data;
  }

  async listUserSelected(id: string) {
    const response = await this.axios.get(`/orders/rv/me/${id}`);
    return response.data;
  }

  async updateOrderStatus(orderId: string, data?: IUpdateOrderStatus) {
    const response = await this.axios.patch(
      `/orders/rv/status/${orderId}`,
      data
    );
    return response.data;
  }

  async updatedSheresSpecifc(data: any) {
    const response = await this.axios.patch(`/orders/rv/specific/share`, data);
    return response.data;
  }

  async updateRiskOrder(data: IUpdateRiskOrder) {
    const response = await this.axios.patch(
      `/orders/rv/strategic/client`,
      data
    );
    return response.data;
  }

  async allClients() {
    const response = await this.axios.get('/orders/rv/strategic/client');
    return response.data;
  }

  async getClientById(id: string) {
    const response = await this.axios.get(`/orders/rv/strategic/client/${id}`);
    return response.data;
  }

  async getStructuredByUserId(userId: string) {
    const response = await this.axios.get(`/orders/rv/me/structured/${userId}`);
    return response.data;
  }

  async updateStructuredStatus(orderId: string, newStatus: string) {
    const response = await this.axios.patch(`/orders/rv/status/${orderId}`, {
      type: 'STRUCTURED',
      status: newStatus,
    });
    return response.data;
  }

  async updateStatusOfStructured(order: any, newStatus: string) {
    const response = await this.axios.patch(
      `/orders/rv/update/status/${newStatus}`,
      order
    );

    return response.data;
  }

  async useHasStructuredOnDemand() {
    const response = await this.axios.get(
      '/orders/rv/structured/strategy/boolean'
    );
    return response.data;
  }

  async attParcialValue(infos: IValue) {
    const response = await this.axios.patch(
      '/orders/rv/strategic/client/retiredParcial',
      infos
    );
    return response.data;
  }

  async confirmTotalValue(infos: any) {
    const response = await this.axios.patch(
      '/orders/rv/strategic/client/retiredTotal',
      infos
    );
    return response.data;
  }

  async getOrderRv() {
    const response = await this.axios.get(`/orders/rv/order_specifc`);
    return response.data;
  }

  async getAllOrdersRvExtract() {
    const response = await this.axios.get(`orders/rv/show/specific`);
    return response.data;
  }

  async extractXlsx(data: any) {
    const response = await this.axios.post(`/orders/rv/extractAll`, data);
    return response.data;
  }

  async updateOrdersExtract(data: IOrderExtract) {
    const response = await this.axios.post(`/orders/rv/extractAll`, data);
    return response.data;
  }

  async updateOrderAfterExtract(data: any) {
    const response = await this.axios.patch(`/orders/rv/update/specific`, data);
    return response.data;
  }

  async updateOrderAfterExtractCancel(data: any) {
    const response = await this.axios.patch(
      `orders/rv/update/canceled/specific`,
      data
    );
    return response.data;
  }

  async updateOrderAfterConfirmExtract(data: any) {
    const response = await this.axios.patch(
      `orders/rv/update/confirm/order/specific`,
      data
    );

    return response.data;
  }
}

export const hubApiRv = new HubApiRv();
