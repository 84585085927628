import { PrimaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';

interface StatusModalProps {
  handleChangeStatusValue: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleChangeAllocationValue: (event: string) => void;
  handleCloseStatusModal: () => void;
  clientStatus: string;
  allocationValue: string;
}

export const StatusModal: React.FC<StatusModalProps> = ({
  handleChangeStatusValue,
  clientStatus,
  allocationValue,
  handleChangeAllocationValue,
  handleCloseStatusModal,
}) => {
  return (
    <DefaultModalContentWrapper w="450px">
      <Dropdown
        placeholder="Selecione uma opção"
        onChange={event => handleChangeStatusValue(event)}
        mb={4}
      >
        <option value="Confirmado">Confirmar entrada</option>
        <option value="Pendente">Pendente</option>
        <option>Cancelado</option>
      </Dropdown>

      {clientStatus !== 'Cancelado' &&
        clientStatus !== '' &&
        clientStatus !== null && (
          <InputWithLabelAndFormatingToMoney
            label="Valor da alocação"
            value={Number(allocationValue)}
            onChange={handleChangeAllocationValue}
          />
        )}

      <BaseButtonGroup>
        <PrimaryButton onClick={handleCloseStatusModal}>Fechar</PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
