export const formatarMoeda = (value: any) => {
  if (value !== 'R$' && value !== 'R$ ' && value !== 'R$ NaN') {
    value = `${value}`;
    value = parseInt(value.replace(/[\D]+/g, ''));
    value += '';
    value = value.replace(/([0-9]{3})$/g, '.$1');

    if (value.length > 6 && value.length <= 9) {
      value = value.replace(/([0-9]{3}),([0-9]{3}$)/g, '.$1.$2');
    }

    if (value.length > 9) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})/g, '.$1.$2.$3');
    }
    if (value == 'NaN') value = '';

    return `${value}`;
  }
  return '';
};
