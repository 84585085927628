import React from 'react';

import { NewsBar } from 'components/modules/NewsBar';
import Navbar from '../../modules/Navbar';

import PrivatePageContent from './styles';

const PrivatePageLayout: React.FC = ({ children }) => {
  return (
    <PrivatePageContent>
      <Navbar />
      <NewsBar />
      {children}
    </PrivatePageContent>
  );
};

export default PrivatePageLayout;
