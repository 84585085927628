import { queryClient } from 'App';
import { useMutation, UseMutationResult } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';

export const useRemoveHomeTask = (): UseMutationResult<
  unknown,
  unknown,
  string,
  unknown
> => {
  return useMutation({
    mutationKey: ['removeHomePendencie'],
    mutationFn: async (pendencyId: string) => {
      const response = await hubApiClients.deletePendency(pendencyId);
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['homePendencies']);
    },
  });
};
