import { Box, Flex, Stack } from '@chakra-ui/react';

import { getPorcentsOfLeads, formatValue } from 'utils/formatPorcentAndValue';
import { IIManagementLeads } from 'pages/GenerationalVariables/interfaces';
import { TitleInVertical } from '../../elements/others/TitleInVertical';
import { TitleWithBorder } from '../../elements/others/TitleWithBorder';
import { LineInfo } from '../../elements/others/LineInfo';

interface ManagementLeadsStaticProps {
  managementLeadsStatic: IIManagementLeads;
  sumOfValues?: number;
}

export const ManagementLeadsStatic: React.FC<ManagementLeadsStaticProps> = ({
  managementLeadsStatic,
  sumOfValues,
}) => {
  return (
    <Box w="100%">
      <TitleWithBorder label="Gestão de leads - Estático" />

      <Flex>
        {managementLeadsStatic.leads && (
          <TitleInVertical
            porcent={getPorcentsOfLeads(
              managementLeadsStatic.leads.generated,
              sumOfValues
            )}
            text="Declínio"
            mb="84px"
            minW="94px"
          />
        )}

        <Stack spacing="1" ml="2" w="100%">
          {managementLeadsStatic.leads && (
            <>
              <LineInfo
                porcent=""
                title="Qtd. de Leads Gerados"
                quantity={formatValue(
                  Number(managementLeadsStatic.leads.generated)
                )}
              />

              <Flex flexDir="column" py="1.5" gridGap="0.5">
                <LineInfo
                  porcent={getPorcentsOfLeads(
                    managementLeadsStatic.leads.generated,
                    managementLeadsStatic.declined.no_money
                  )}
                  title="Qtd. Sem Dinheiro"
                  quantity={formatValue(
                    Number(managementLeadsStatic.declined.no_money)
                  )}
                />

                <LineInfo
                  porcent={getPorcentsOfLeads(
                    managementLeadsStatic.leads.generated,
                    managementLeadsStatic.declined.btg_client
                  )}
                  title="Qtd. Cliente BTG"
                  quantity={formatValue(
                    Number(managementLeadsStatic.declined.btg_client)
                  )}
                />

                <LineInfo
                  porcent={getPorcentsOfLeads(
                    managementLeadsStatic.leads.generated,
                    managementLeadsStatic.declined.no_interest
                  )}
                  title="Qtd. Sem interesse"
                  quantity={formatValue(
                    Number(managementLeadsStatic.declined.no_interest)
                  )}
                />

                <LineInfo
                  porcent={getPorcentsOfLeads(
                    managementLeadsStatic.leads.generated,
                    managementLeadsStatic.declined.lgpd
                  )}
                  title="Qtd. LGPD não ligar mais"
                  quantity={formatValue(
                    Number(managementLeadsStatic.declined.lgpd)
                  )}
                />
                <LineInfo
                  porcent={getPorcentsOfLeads(
                    managementLeadsStatic.leads.generated,
                    managementLeadsStatic.declined.no_exist
                  )}
                  title="Qtd. Não existe"
                  quantity={formatValue(
                    Number(managementLeadsStatic.declined.no_exist)
                  )}
                />
              </Flex>

              <LineInfo
                porcent={getPorcentsOfLeads(
                  managementLeadsStatic.leads.generated,
                  managementLeadsStatic.meetings.marked
                )}
                title="Qtd. Reuniões marcadas"
                quantity={formatValue(
                  Number(managementLeadsStatic.meetings.marked)
                )}
              />

              {/* <Flex flexDir="column" ml="3" mb="1"> */}
              <LineInfo
                porcent={getPorcentsOfLeads(
                  managementLeadsStatic.meetings.marked,
                  managementLeadsStatic.meetings.realized
                )}
                title="Qtd. Reunões realizadas"
                quantity={formatValue(
                  Number(managementLeadsStatic.meetings.realized)
                )}
              />
              <LineInfo
                porcent={getPorcentsOfLeads(
                  managementLeadsStatic.meetings.marked,
                  managementLeadsStatic.leads.rna
                )}
                title="RNA"
                quantity={formatValue(Number(managementLeadsStatic.leads.rna))}
              />
              <LineInfo
                porcent={getPorcentsOfLeads(
                  managementLeadsStatic.meetings.marked,
                  managementLeadsStatic.meetings.declined
                )}
                title="Declinadas"
                quantity={formatValue(
                  Number(managementLeadsStatic.meetings.declined)
                )}
              />

              <LineInfo
                porcent={getPorcentsOfLeads(
                  managementLeadsStatic.meetings.marked,
                  managementLeadsStatic.meetings.fluxo
                )}
                title="No Fluxo"
                quantity={formatValue(
                  Number(managementLeadsStatic.meetings.fluxo)
                )}
              />
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};
