import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainPageBtnContainer = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: rgba(70, 70, 70, 1);

  @media screen and (min-width: 560px) {
    width: 196px;
  }

  @media screen and (min-width: 840px) {
    width: 256px;
  }
`;

export const MainPageLinkContainer = styled(Link)`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 560px) {
    width: 196px;
  }

  @media screen and (min-width: 840px) {
    width: 256px;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  border-radius: 5px;
  background: transparent;
  border: 1px solid var(--primary-color-100);
  box-shadow: 3px 2px 14px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid var(--primary-color-200);
    background: var(--primary-color-200);
  }

  svg {
    max-width: 36px;
    color: var(--rgba(70, 70, 70, 1)-color-100);
  }

  .info-container {
    position: absolute;
    top: 8px;
    right: 12px;
  }

  @media screen and (min-width: 560px) {
    max-height: 196px;

    .info-container {
      position: absolute;
      top: 8px;
      right: 12px;
    }

    svg {
      max-width: 56px;
    }
  }

  @media screen and (min-width: 840px) {
    max-height: 256px;

    .info-container {
      position: absolute;
      top: 8px;
      right: 12px;
    }

    svg {
      color: var(--rgba(70, 70, 70, 1)-color-100);
      max-width: 72px;
    }
  }
`;

export const Title = styled.h4`
  text-align: center;
  color: var(--rgba(70, 70, 70, 1)-color-100);
  font-size: var(--h5-text);
  margin-top: 20px;
  text-align: center;
  text-decoration: none;

  @media screen and (min-width: 620px) {
    font-size: var(--h4-text);
  }
`;
