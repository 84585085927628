import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export const ContentDividerText: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  return (
    <Text fontSize="small" color="gray.100" {...rest} whiteSpace="normal">
      {children}
    </Text>
  );
};
