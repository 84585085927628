import axios from 'axios';

class ViaCepApi {
  baseURL;

  axiosClient;

  constructor() {
    this.baseURL = 'https://viacep.com.br/ws/';
    this.axiosClient = axios.create({ baseURL: this.baseURL });
  }

  async get(cep: string) {
    const response = await this.axiosClient.get(`${this.baseURL}/${cep}/json`);

    if (response.data.erro) return;

    return {
      street: response.data.logradouro,
      neighborhood: response.data.bairro,
      city: response.data.localidade,
      uf: response.data.uf,
    };
  }
}

export default new ViaCepApi();
