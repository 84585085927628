import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { GiMagnifyingGlass } from 'react-icons/gi';

const NotFound: React.FC = () => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="100vw"
      h="100vh"
      background="background.900"
      color="white.100"
    >
      <Text>404: Página não encontrada</Text>

      <Flex justifyContent="center" alignItems="center" p={5}>
        <Box mr={5}>
          <GiMagnifyingGlass size={60} />
        </Box>
        <Text>Parece que a página que você está acessando não existe</Text>
      </Flex>
    </Flex>
  );
};

export default NotFound;
