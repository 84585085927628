import React, { FormEvent, useState } from 'react';

import { Text, Flex } from '@chakra-ui/react';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import parser from 'html-react-parser';

import { ModalWrapper, DefaultCardWrapper } from 'components/elements/wrappers';
import { IAnnoucementManegement } from 'pages/RH/interfaces';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import AnnouncementForm from 'pages/HUB/subpages/AnnouncementForm';
import { DeleteModal } from 'pages/HUB/components/DeleteModal';

interface AnnouncementProps {
  announcement: IAnnoucementManegement;
  handleDeleteAnnouncement: (announcementId: string) => void;
  handleFormSubmit: (event: FormEvent, isEdid?: boolean) => void;
  announcements: IAnnoucementManegement[];
  setAnnouncements: React.Dispatch<
    React.SetStateAction<IAnnoucementManegement[]>
  >;
}

const Item: React.FC<AnnouncementProps> = ({
  announcement,
  handleDeleteAnnouncement,
  announcements,
  setAnnouncements,
}) => {
  const [editAnnouncement, setEditAnnouncement] = useState(announcement);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeTitleValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEditAnnouncement({
      ...editAnnouncement,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeValue = (message: any) => {
    setEditAnnouncement({
      ...editAnnouncement,
      message,
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (editAnnouncement.title.trim() === '') {
        setIsLoading(false);
        return toast.dark('Você deve preencher o campo de informações');
      }
      if (editAnnouncement.message.trim() === '') {
        setIsLoading(false);
        return toast.dark('Você deve preencher o campo de informações');
      }
      const response = await hubApiRH.updateAnnouncement({
        id: editAnnouncement.id,
        message: editAnnouncement.message,
        title: editAnnouncement.title,
      });
      setIsLoading(false);
      setIsOpen(!isOpen);

      toast.dark('Comunicado editado com sucesso');

      const annoc = announcements.filter(announ => announ.id !== response.id);

      setAnnouncements([...annoc, response]);
    } catch (err) {
      toast.dark('Erro ao editar comunicado');
      setIsLoading(false);
    }
  };

  return (
    <DefaultCardWrapper
      justifyContent="space-between"
      bg="background.500"
      mb="5px"
      p="3"
    >
      <Flex flexDir="column" justifyContent="space-between" w="100%">
        <Flex align="center" justify="space-between">
          <Text color="white" fontWeight="bold" fontSize="md">
            <Text as="span" fontWeight="semibold">
              {' '}
              {announcement?.title}
            </Text>
          </Text>

          <Flex gridGap="3">
            <BiEditAlt
              size={20}
              style={{ cursor: 'pointer' }}
              color="white"
              onClick={() => setIsOpen(!isOpen)}
            />

            <BiTrash
              size={20}
              style={{ cursor: 'pointer' }}
              color="white"
              onClick={() => setShowDeleteModal(true)}
            />
          </Flex>
        </Flex>

        <Text fontSize="sm" mr="10px">
          {parser(announcement.message)}
        </Text>
      </Flex>

      <ModalWrapper isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
        <AnnouncementForm
          isLoading={isLoading}
          announcement={editAnnouncement}
          handleChangeValue={handleChangeValue}
          handleFormSubmit={handleSubmit}
          handleChangeTitleValue={handleChangeTitleValue}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <DeleteModal
          announcementId={announcement.id}
          deleteAnnouncement={handleDeleteAnnouncement}
          closeModal={handleCloseDeleteModal}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};

export default Item;
