import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { InputWithLabel } from 'components/elements/forms';
import { NoticeProps } from 'pages/notices/interfaces';
import { BaseModalFormCard } from 'components/elements/cards';
import { Stack } from '@chakra-ui/react';
import schema from './schema';

interface UpdateNoticeProps {
  notice: NoticeProps;
  handleModalUpdate: (notice: NoticeProps) => void;
  handleToggleModal: () => void;
}

export const UpdateNotice: React.FC<UpdateNoticeProps> = ({
  notice,
  handleModalUpdate,
  handleToggleModal,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formInfos, setFormInfos] = useState({
    title: notice.title,
    link: notice.link,
  });

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    await schema
      .validate(formInfos, {
        abortEarly: false,
      })

      .then(async () => {
        await hubApiProducts
          .updateNotice(notice.id, {
            title: formInfos.title,
            link: formInfos.link,
          })
          .then(() => {
            toast.dark('Noticia atualizada com sucesso.');
            handleModalUpdate(notice);
            handleToggleModal();
            setIsLoading(false);
          })
          .catch(() => {
            toast.dark('Ocorreu um erro ao tentar atualizar a notícia.');
            setIsLoading(false);
          });
      })
      .catch(error => toast.dark(error.errors[0]));
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Atualizar notícia"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      isLoading={isLoading}
      primaryButtonText="Salvar"
      secondaryButtonText="Cancelar"
    >
      <Stack spacing="2">
        <InputWithLabel
          label="Título"
          placeholder="Título da notícia"
          name="title"
          value={formInfos.title}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Link"
          placeholder="Link da notícia"
          name="link"
          value={formInfos.link}
          onChange={handleChangeValue}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
