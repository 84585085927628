import { Flex, Text, Image } from '@chakra-ui/react';
import {
  FcHighPriority,
  FcApproval,
  FcGlobe,
  FcSurvey,
  FcConferenceCall,
} from 'react-icons/fc';
import partyIcon from 'shared/assets/images/assignments/party-icon.svg';

interface PendencieBoxProps {
  pendencieName: string;
  pendencieValue: number;
}

export const PendencieBox: React.FC<PendencieBoxProps> = ({
  pendencieName,
  pendencieValue,
}) => {
  return (
    <Flex
      w="14%"
      h="80%"
      bg="background.700"
      flexDirection="column"
      py={5}
      px={1}
      borderRadius={8}
      alignItems="center"
      justifyContent="center"
    >
      <Flex mt={2}>
        {pendencieName === 'Pré-NPS' && <FcSurvey size={18} />}
        {pendencieName === 'Negativos' && <FcHighPriority size={18} />}
        {pendencieName === 'Reuniões' && <FcConferenceCall size={18} />}
        {pendencieName === 'Origem' && <FcGlobe size={18} />}
        {pendencieName === 'Aniversário' && (
          <Image src={partyIcon} alt="ícone de tarefa" width="100%" h="18px" />
        )}
      </Flex>

      <Text w="100%" textAlign="center" fontSize="10">
        {pendencieName}
      </Text>
      <Text w="100%" textAlign="center" color="white" fontSize="10">
        {pendencieValue}
      </Text>
    </Flex>
  );
};
