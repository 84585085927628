import { Flex } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { locationToCreate, thingsToCreate } from './data';

export const FakeData: React.FC = () => {
  const history = useHistory();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    if (environment !== 'test') {
      history.push('/');
    }
  }, [environment]);

  const [thingToCreate, setThingToCreate] = useState<string>('');
  const [location, setLocation] = useState<string>('');

  const { mutateAsync: createFakeData, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await hubApi.createFakeData({
        thingToCreate,
        location,
      });

      return response;
    },
    onSuccess: response => {
      toast.dark(`Lead criado com o nome ${response.name}`);
    },
  });

  const handleSubmit = async () => {
    if (!thingToCreate || !location) {
      return toast.dark('Preencha todos os campos do formulario');
    }

    await createFakeData();
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" py="48px">
      <MainPageTitle>Fake Data</MainPageTitle>

      <Flex justifyContent="center" alignItems="center" w="100vw">
        <Flex
          flexDirection="column"
          gap={5}
          backgroundColor="background.300"
          borderRadius={3}
          p={5}
          w="40%"
        >
          <DropdownWithLabel
            label="O que você deseja criar:"
            onChange={event => setThingToCreate(event.target.value)}
            value={thingToCreate}
          >
            {thingsToCreate.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </DropdownWithLabel>

          <DropdownWithLabel
            label="Localização:"
            onChange={event => {
              setLocation(event.target.value);
            }}
            value={location}
          >
            {thingToCreate &&
              locationToCreate[thingToCreate].map(item => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
          </DropdownWithLabel>

          <PrimaryButton onClick={handleSubmit}>
            {isLoading ? (
              <LoadingSpinner size="sm" color="gray.500" />
            ) : (
              'Criar'
            )}
          </PrimaryButton>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
