import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useNavigation } from 'hooksV2/useNavigation';
import { useParseLeadByType } from 'hooksV2/useParseLeadByType';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import hubApi from 'services/hubApi';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { Icons } from 'sharedV2/icons';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { showToast } from 'utilsV2/showToast';
import { LeadDetailsFooter } from './components/footer';
// import { LeadDetailsHeader } from './components/header';
import { LeadInformations } from './components/leadInformations';
import { LeadDetailsSkeletonLoading } from './components/skeletonLoading';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from './context/useLeadDetailsContext';

export const LeadDetails = (): JSX.Element => {
  const { getParam } = useQueryParams();
  const { leadType, id } = useParams<{ leadType: LeadTypeEnum; id: string }>();
  const { redirect } = useNavigation();

  const {
    lead: leadInPageContext,
    setLead: setLeadInPageContext,
    setLeadType: setLeadTypeInContext,
    setResponsibleEmployee: setResponsibleEmployeeInPageContext,
    // setMundoInvestInformation: setMundoInvestInformationInPageContext,
    setDesiredFlow: setDesiredFlowInPageContext,
  } = useLeadDetailsContext();

  const desiredFlow = getParam('desiredFlow') as
    | LeadDetailsDesiredFlowEnum
    | undefined;

  const { isLoading: isLoadingLeadDetails } = useQuery({
    queryKey: ['leadDetails', id],
    queryFn: async () => {
      const response = await hubApiCaptation.getLeadInformation({
        leadType,
        leadId: id,
      });

      return response;
    },
    onSuccess: leadResponse => {
      if (!leadResponse) {
        showToast(
          'Informações do lead não foram encontradas, entre em contato com o suporte'
        );

        redirect('/v2/captation/meetings?tab=suitability');
        return;
      }

      const LeadParsedType = useParseLeadByType({
        lead: leadResponse,
        leadType,
      });

      setLeadInPageContext(LeadParsedType);
    },
  });

  // const { isLoading: isLoadingMundoInvestLeadInformation } = useQuery({
  //   queryKey: ['mundoInvestLeadInformation', leadInPageContext?.email],
  //   queryFn: async () => {
  //     const response = await hubApi.getMeetingInfoFromMI(
  //       leadInPageContext?.email as string
  //     );

  //     return response.clients[0];
  //   },
  //   onSuccess: data => {
  //     setMundoInvestInformationInPageContext(data ?? {});
  //   },
  //   enabled: !!leadInPageContext && !!leadInPageContext?.email,
  // });

  const { isLoading: isLoadingLeadResponsibleEmployee } = useQuery({
    queryKey: [
      'leadResponsibleEmployee',
      leadInPageContext &&
        'employeeId' in leadInPageContext &&
        leadInPageContext?.employeeId,
    ],
    queryFn: async () => {
      if (leadInPageContext && !('employeeId' in leadInPageContext)) {
        return;
      }

      const response = await hubApi.getEmployeeInfos(
        leadInPageContext?.employeeId
      );

      return response;
    },
    onSuccess: leadResponsibleEmployeeResponse => {
      if (!leadResponsibleEmployeeResponse) return;

      setResponsibleEmployeeInPageContext(leadResponsibleEmployeeResponse);
    },
    enabled:
      !!leadInPageContext &&
      !!(
        leadInPageContext &&
        'employeeId' in leadInPageContext &&
        leadInPageContext?.employeeId
      ),
  });

  const isLoading = isLoadingLeadDetails || isLoadingLeadResponsibleEmployee;

  useEffect(() => {
    setLeadTypeInContext(leadType);
  }, [leadType]);

  useEffect(() => {
    setDesiredFlowInPageContext(desiredFlow || undefined);
  }, [desiredFlow]);

  if (isLoading) {
    return <LeadDetailsSkeletonLoading />;
  }

  return (
    <>
      <PageWrapper paddingTop="2rem" paddingBottom="2rem" paddingX="5rem">
        <Breadcrumb display="flex" separator=">">
          <BreadcrumbItem color="#000" _hover={{ color: '#000' }}>
            <BreadcrumbLink
              color="#000"
              href="/v2/captation/meetings"
              _hover={{ color: '#000' }}
            >
              Lead Manual
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem color="#000" _hover={{ color: '#000' }} isCurrentPage>
            <BreadcrumbLink color="#000" _hover={{ color: '#000' }}>
              {formatNameToCaptalize(
                (leadInPageContext?.razaoSocial || leadInPageContext.name) ??
                  '-'
              )}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Box.Glass
          style={{ flex: 1, background: 'transparent' }}
          borderColor="transparent"
          flexDirection="column"
          alignItems="start"
          gap={5}
          justifyContent="initial"
          paddingY="1.5rem"
        >
          {/* <LeadDetailsHeader /> */}
          <LeadInformations />
          <LeadDetailsFooter />
        </Box.Glass>
      </PageWrapper>
    </>
  );
};
