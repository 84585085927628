export const getPorcentsOfLeads = (
  qntLeadsGerados: string | number | any,
  valor: string | number | any
) => {
  if (valor === '0' && qntLeadsGerados === '0') {
    return '0.00';
  }

  return ((valor * 100) / qntLeadsGerados).toFixed(2);
};

export const formatValue = (valor: number) => {
  return valor.toLocaleString('pt-BR');
};
