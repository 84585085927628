import {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { BiAddToQueue } from 'react-icons/bi';
import { Flex } from '@chakra-ui/react';

import { ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { Dropdown } from 'components/elements/others';
import { IBaseLead, IQuestionProps, Task } from 'pages/Captation/interfaces';
import { QuestionItem } from '../QuestionItem';
import { CreateNewQuestionLead } from '../../modals';

const profileOptions = ['Conservador', 'Moderado', 'Agressivo', 'Trader'];
const knowlegmentLevel = [
  'Leigo total',
  'Conhecimento básico',
  'Acha que sabe',
  'Conhecimento moderado',
  'Conhecimento avançado',
];

interface QuestionsFormProps {
  lead: IBaseLead;
  response: Partial<IQuestionProps>[];
  setResponse: Dispatch<SetStateAction<Partial<IQuestionProps>[]>>;
  tasks: Task[];
  setTasks: Dispatch<SetStateAction<Task[]>>;
  maxHeight: string;
}

export const QuestionsForm: React.FC<QuestionsFormProps> = ({
  lead,
  response,
  setResponse,
  tasks,
  setTasks,
  maxHeight = 'calc(100vh - 432px)',
}) => {
  const [isDelete, setIsDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [idPerfil, setIdPerfil] = useState('');
  const [idConhecimento, setIdConhecimento] = useState('');

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleSelectProfile = (e: BaseSyntheticEvent) => {
    handleResponse(idPerfil, e.target.value);
  };

  const handleSelectKnowledge = (e: BaseSyntheticEvent) => {
    handleResponse(idConhecimento, e.target.value);
  };

  const handleResponse = (id: string, res: string) => {
    const index = response.findIndex(
      (item: Partial<IQuestionProps>) => item.idQuestion === id
    );
    if (index !== -1) {
      setResponse(
        response.map((item: Partial<IQuestionProps>) => {
          if (item.idQuestion === id) {
            return {
              ...item,
              answer: res,
            };
          }
          return item;
        })
      );
    } else {
      setResponse([
        ...response,
        {
          leadId: lead.id,
          idQuestion: id,
          answer: res,
        },
      ]);
    }
  };

  useEffect(() => {
    hubApiCaptation.getVerifyQuestion().then(() => {
      hubApiCaptation.getQuestion().then(response => {
        setTasks(response);
        const perfil = response.find(
          (r: Partial<IQuestionProps>) => r.question === 'Perfil'
        );
        setIdPerfil(perfil?.id);
        const conhecimento = response.find(
          (r: Partial<IQuestionProps>) => r.question === 'Conhecimento'
        );
        setIdConhecimento(conhecimento?.id);
      });
    });
  }, [lead]);

  const handleDeleteQuestion = async (task: Task) => {
    await hubApiCaptation.deleteQuestion(task.id).then(() => {
      const index = tasks.findIndex((t: Task) => t.id === task.id);
      tasks.splice(index, 1);
      setIsDelete(!isDelete);
    });
  };

  return (
    <Flex width="100%" flexDir="column">
      <Flex width="100%" mb="4" alignItems="center">
        <Flex width="100%" gridGap="2" alignItems="center">
          <Dropdown placeholder="Perfil" onChange={handleSelectProfile}>
            {profileOptions?.map((item, index) => (
              <option key={`profile-${index}`} value={item}>
                {item}
              </option>
            ))}
          </Dropdown>

          <Dropdown placeholder="Conhecimento" onChange={handleSelectKnowledge}>
            {knowlegmentLevel?.map((item, index) => (
              <option key={`knowlegment-${index}`} value={item}>
                {item}
              </option>
            ))}
          </Dropdown>
        </Flex>

        <Flex
          _hover={{ color: 'white' }}
          transition="300ms"
          alignItems="flex-start"
        >
          <BiAddToQueue
            size={26}
            style={{ cursor: 'pointer' }}
            onClick={handleShowModal}
          />
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        overflowY="auto"
        maxH={maxHeight}
        gridGap="1.5"
        paddingX="1"
      >
        {tasks.map((task: Task) => (
          <QuestionItem
            key={task.id}
            question={task}
            setResponse={handleResponse}
            handleDeleteQuestion={handleDeleteQuestion}
          />
        ))}
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={handleShowModal}>
        <CreateNewQuestionLead
          questions={tasks}
          setQuestions={setTasks}
          handleToggleModal={handleShowModal}
        />
      </ModalWrapper>
    </Flex>
  );
};
