import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';

export enum DeclineOfferOptionsEnum {
  NO_INTEREST = 'no_interest',
  NO_EXIST = 'no_exist',
  LGPD = 'lgpd',
  NO_MONEY = 'no_money',
  BTG_CLIENT = 'btg_client',
  EWZ_CLIENT = 'ewz_client',
  NO_ANSWER = 'no_answer',
  PJ2 = 'PJ2',
}

export const declineMeetingOptions: BaseDropdownOptionsType[] = [
  { label: 'Sem interesse', value: DeclineOfferOptionsEnum.NO_INTEREST },
  { label: 'Não existe', value: DeclineOfferOptionsEnum.NO_EXIST },
  { label: 'Não ligar mais (LGPD)', value: DeclineOfferOptionsEnum.LGPD },
  { label: 'Não tem dinheiro', value: DeclineOfferOptionsEnum.NO_MONEY },
  { label: 'Cliente BTG', value: DeclineOfferOptionsEnum.BTG_CLIENT },
  { label: 'Cliente EWZ', value: DeclineOfferOptionsEnum.EWZ_CLIENT },
  { label: 'Não atendeu', value: DeclineOfferOptionsEnum.NO_ANSWER },
];
