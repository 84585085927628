import React, { useEffect, useState } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import { SecondaryButton } from 'components/elements/buttons';
import TextArea from 'components/customElements/TextArea';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';

const Intern = ({ setShowIntern, order }) => {
  const [anotationEx, setAnotationEx] = useState(false);
  const [anotationIn, setAnotationIn] = useState(false);
  const [anotationMsg, setAnotationMsg] = useState('');
  const [anotationsIntern, setAnotationsIntern] = useState([]);

  const handleAnotIn = () => {
    setAnotationIn(!anotationIn);
  };

  useEffect(() => {
    hubApiIp.listMsgInt(order.id).then(response => {
      setAnotationsIntern(response);
    });
  }, []);

  const handleInSubmit = () => {
    hubApiIp
      .postAnotations({
        orderId: order.id,
        typeorder: 'IP',
        type: 'intern',
        msg: anotationMsg,
      })
      .then(response => {
        setShowIntern(false);
        toast.dark('Anotação interna Atualizada com sucesso.');
      });
  };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle>Mensagem Interna</ModalTitle>

      <div className="btn-anotations">
        {anotationIn === true && anotationEx === false ? (
          <>
            <TextArea onChange={e => setAnotationMsg(e.target.value)} />
            <SecondaryButton removeMargin onClick={handleInSubmit}>
              Enviar
            </SecondaryButton>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="content">
        {anotationsIntern.map(
          (msg, index) =>
            msg.type.toUpperCase() === 'INTERN' && (
              <DisplayInformation key={index} label="Nota" info={msg.msg} />
            )
        )}
      </div>
      <ButtonGroup width="100%" justifyContent="flex-end" spacing="3">
        <SecondaryButton removeMargin onClick={() => setShowIntern(false)}>
          Fechar
        </SecondaryButton>
        <SecondaryButton removeMargin onClick={handleAnotIn}>
          Criar
        </SecondaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};

export default Intern;
