import React from 'react';
import { Text, Flex, Select, SelectProps } from '@chakra-ui/react';

import { DropdownContainer } from '../Dropdown/styles';

interface SelectWithLabelProps extends SelectProps {
  label: string;
  fontSize?: string;
  labelInLine?: boolean;
}

export const DropdownWithLabel: React.FC<SelectWithLabelProps> = ({
  label,
  fontSize = 'small',
  labelInLine,
  children,
  ...rest
}) => {
  return (
    <DropdownContainer>
      <Flex
        flexDir={labelInLine ? 'row' : 'column'}
        gap={labelInLine ? 2 : 0}
        alignItems={labelInLine ? 'baseline' : 'start'}
      >
        <Text mb="6px" fontSize={fontSize} color="rgba(70, 70, 70, 1)">
          {label}
        </Text>
        <Select
          placeholder="Selecione uma opção"
          borderRadius="3px"
          size="sm"
          {...rest}
          bg="white"
          border="1px solid"
          borderColor="#1d63ddb2"
          _hover={{ borderColor: 'primary.800' }}
          _placeholder={{ color: 'gray.400' }}
          color="rgba(70, 70, 70, 1)"
        >
          {children}
        </Select>
      </Flex>
    </DropdownContainer>
  );
};
