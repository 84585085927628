import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { IInfosSH } from 'pages/GenerationalVariables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ContentTransferLeadsModalProps {
  setShowModalTransferLead: React.Dispatch<React.SetStateAction<boolean>>;
  sh: IInfosSH[];
  setShId: React.Dispatch<React.SetStateAction<string>>;
  loadingTransferLead: boolean;
  showModalTransferLead: boolean;
  handleTransferLeads: () => Promise<void | any>;
}

export const ContentTransferLeadsModal: React.FC<ContentTransferLeadsModalProps> =
  ({
    setShowModalTransferLead,
    showModalTransferLead,
    setShId,
    sh,
    handleTransferLeads,
    loadingTransferLead,
  }) => {
    return (
      <>
        <DefaultModalContentWrapper width="100%" maxW={['95vw', '490px']}>
          <ModalTitle>Transferir leads</ModalTitle>

          <Dropdown
            placeholder="Escolha o SH"
            ml={1}
            onChange={e => setShId(e.target.value)}
          >
            {sh.map(item => {
              if (item.status === 'active') {
                return (
                  <option key={item.id} value={item.id}>
                    {formatNameToCaptalize(item.name)}
                  </option>
                );
              }
            })}
          </Dropdown>

          <BaseButtonGroup>
            <SecondaryButton
              onClick={() => setShowModalTransferLead(!showModalTransferLead)}
            >
              Voltar
            </SecondaryButton>

            <PrimaryButton
              isLoading={loadingTransferLead}
              onClick={handleTransferLeads}
              style={{ margin: 0 }}
            >
              Transferir
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </>
    );
  };
