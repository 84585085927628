import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { TitleWithSideLine } from 'components/elements/texts';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { IMibsHistory } from '../dtos';
import { MibsHistoryItem } from './MibsHistoryItem';

interface IProps {
  shHistoryItems: IMibsHistory[];
  setIsOpenSHHistoryModal: (value: boolean) => void;
}

export const SHHistoryModal: React.FC<IProps> = ({
  shHistoryItems,
  setIsOpenSHHistoryModal,
}) => {
  const [
    differenceInMibsBetweenOldestAndNewestMonthItem,
    setDifferenceInMibsBetweenOldestAndNewestMonthItem,
  ] = useState<number>(0);

  const getSHMonthMibsDifference = () => {
    const atualMonthItems = shHistoryItems.filter(item => {
      const itemDate = new Date(item.created_at);
      return (
        itemDate.getMonth() === new Date().getMonth() &&
        itemDate.getFullYear() === new Date().getFullYear()
      );
    });

    if (atualMonthItems.length > 0) {
      atualMonthItems.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      const oldestMonthItem = atualMonthItems[0];
      const newestMonthItem = atualMonthItems[atualMonthItems.length - 1];

      setDifferenceInMibsBetweenOldestAndNewestMonthItem(
        Number(
          (
            oldestMonthItem.walletMibsAfterTransaction -
            newestMonthItem.walletMibsAfterTransaction
          ).toFixed(2)
        )
      );
    }
  };

  useEffect(() => {
    getSHMonthMibsDifference();
  }, []);

  return (
    <DefaultModalContentWrapper
      maxW={{ base: '95vw', md: '540px', lg: '900px' }}
      minH="400px"
      justifyContent="space-between"
    >
      <Flex flexDirection="column" gap={10}>
        <Flex flexDirection="column" alignItems="center">
          <TitleWithSideLine>Métricas</TitleWithSideLine>
          <Flex
            backgroundColor="white"
            p={3}
            borderRadius={3}
            alignItems="center"
            gap={2}
            border="1px solid #1d63ddb2"
          >
            <Text color="rgba(70, 70, 70, 1)">
              Diferença do Mês:{' '}
              {differenceInMibsBetweenOldestAndNewestMonthItem}
            </Text>
            <Tooltip
              hasArrow
              label="A Diferença do Mês é a subtração da quantidade de MIBs que você tinha quando mês começou com a quantidade que você tem agora."
              placement="top"
              color="white"
              bg="background.200"
            >
              <Box>
                <AiOutlineInfoCircle size={14} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>

        <Flex flexDirection="column">
          <TitleWithSideLine>Histórico do SH</TitleWithSideLine>
          <Flex
            maxH="420px"
            overflowY="auto"
            flexDirection="column"
            p={5}
            bgColor="white"
            borderRadius={5}
            marginTop={2}
            marginBottom={8}
            className="slimScrollbar"
          >
            {shHistoryItems &&
              shHistoryItems.map(item => <MibsHistoryItem item={item} />)}
          </Flex>
        </Flex>
      </Flex>

      <Flex justifyContent="end">
        <SecondaryButton onClick={() => setIsOpenSHHistoryModal(false)}>
          Fechar
        </SecondaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
