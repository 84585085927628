import styled from 'styled-components';

export const SingleContentContainer = styled.div`
  max-height: 30px;
  display: flex;
  border: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  padding: 05px;

  .single-executation-lead {
    padding: 10px 0;
    background-color: none;
    border-bottom: 1px solid #808176;
  }
`;
