import { useQuery, UseQueryResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export const useIsUserOnlySH = (user: IUserSelector): UseQueryResult => {
  return useQuery<boolean>(MIBS_QUERIES_KEYS.isUserOnlySH, async () => {
    const [isSH, isAAI] = await Promise.all([
      hubApiCaptation.checkSh('SH', user.id),
      hubApiCaptation.checkSh('AAI', user.id),
    ]);

    return isSH && isAAI ? false : isSH;
  });
};
