/* eslint @typescript-eslint/no-explicit-any: off */
import React from 'react';
import { Stack } from '@chakra-ui/react';

import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { CardInfo } from 'components/elements/others/CardInfo';
import { formatToBRL } from 'brazilian-values';

interface ComponentProps {
  order: any;
  handleToggleModal: () => void;
}

export const OrderInfosIp: React.FC<ComponentProps> = ({
  handleToggleModal,
  order,
}) => {
  return (
    <DefaultModalContentWrapper maxW={['95vw', '490px']}>
      <ModalTitle>Dados da Ordem</ModalTitle>

      <Stack spacing="2">
        <CardInfo title="Cliente" info={order.name} />
        <CardInfo title="Nome do Assessor" info={order.creatorname} />
        <CardInfo title="Telefone" info={order.phone} />
        <CardInfo title="E-mail" info={order.email} />
        {order.value && (
          <CardInfo title="Valor" info={formatToBRL(order.value)} />
        )}
        <CardInfo title="Informações" info={order.information} />
      </Stack>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Fechar</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
