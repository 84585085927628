import React, { useState } from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { showToast } from 'utils';
import { Dropdown } from 'components/elements/others';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { BiInfoCircle } from 'react-icons/bi';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

import { ModalTitle } from 'components/elements/texts';
import { useSelector } from 'react-redux';
import SearchLead from '../../SearchLead';
import { IReduxState } from '../../../../../../shared/interfaces';

type VisualizationOption = 'mosaic' | 'table';

type FilterOptions =
  | 'all'
  | 'meeting'
  | 'callback'
  | 'return'
  | 'declined'
  | 'inherit'
  | 'day';

interface IShPoolMenu {
  handleSearchLead: (searchValue: string) => void;
  publicLeads: LeadDetailsProps[];
  setFilteredLeads: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
  setVisualizationMode: (
    value: React.SetStateAction<VisualizationOption>
  ) => void;
  handleGetExpiredLead: () => void;
  loadingMinedLead: boolean;
}

export const ShPoolMenu: React.FC<IShPoolMenu> = ({
  handleSearchLead,
  setVisualizationMode,
  publicLeads,
  setFilteredLeads,
  handleGetExpiredLead,
  loadingMinedLead,
}) => {
  const user: { id: string; name: string } = useSelector(
    (state: IReduxState) => state.user.profile
  );
  const history = useHistory();
  const [showUploadInstructionsModal, setShowUploadInstructionsModal] =
    useState(false);

  const [showTodayLeads, setShowTodayLeads] = useState(false);

  const handleSelectTodayLeads = (isTodayLeads: boolean) => {
    setShowTodayLeads(isTodayLeads);
    let filteredArr: LeadDetailsProps[] = [];
    const filterType = new Date().toISOString().slice(0, 10);

    if (isTodayLeads) {
      filteredArr = publicLeads.filter(
        lead => lead.meetings[0]?.date.slice(0, 10) === filterType
      );

      setFilteredLeads(filteredArr);
    } else {
      setFilteredLeads(publicLeads);
    }
    if (filteredArr.length <= 0 && isTodayLeads) {
      setFilteredLeads(publicLeads);
      return showToast('Não Possui reuniões disponiveis hoje');
    }
  };

  const handleSelectFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (publicLeads.length <= 0) return;

    const filterType = event.target.value as FilterOptions;

    if (filterType === 'all') return setFilteredLeads(publicLeads);

    let filteredArr: LeadDetailsProps[] = [];

    if (filterType === 'meeting' || filterType === 'callback') {
      filteredArr = publicLeads.filter(
        lead => lead.meetings[0]?.type === filterType
      );
    }

    if (filterType === 'declined') {
      filteredArr = publicLeads.filter(
        lead => lead.meetings[0]?.status === filterType
      );
    }

    if (filterType === 'inherit') {
      filteredArr = publicLeads.filter(lead => lead.meetings?.length === 0);
    }

    if (filterType === 'day') {
      const currentDate = new Date().toISOString().split('T')[0];

      filteredArr = publicLeads.filter(lead =>
        lead.meetings[0]?.date.includes(currentDate)
      );
    }

    if (filteredArr.length <= 0)
      return showToast('Não foi possível encontar leads');

    setFilteredLeads(filteredArr);
  };

  return (
    <Flex
      width="100%"
      justify="space-between"
      margin={['15px 0 12px', '24px 0 12px']}
      flexDir={['column-reverse', 'row']}
    >
      <Flex gridGap="2" flexDir={['column', 'column', 'row']} mt={['3', '0']}>
        {!showTodayLeads ? (
          <PrimaryButton
            minWidth={160}
            onClick={() => handleSelectTodayLeads(true)}
          >
            Reuniões para hoje
          </PrimaryButton>
        ) : (
          <SecondaryButton
            minWidth={160}
            onClick={() => handleSelectTodayLeads(false)}
          >
            Voltar para todos
          </SecondaryButton>
        )}

        <Dropdown placeholder="Filtrar leads" onChange={handleSelectFilter}>
          <option value="all">Todos</option>
          <option value="meeting">Reunião</option>
          <option value="callback">Retorno</option>
          <option value="declined">R.N.A</option>
          <option value="inherit">Sem atividade</option>
          <option value="day">Atividades do dia</option>
        </Dropdown>

        <Dropdown
          placeholder="Tipo de visualização"
          onChange={e =>
            setVisualizationMode(e.target.value as 'table' | 'mosaic')
          }
        >
          <option value="table">Por tabela</option>
          <option value="mosaic">Por mosaico</option>
        </Dropdown>

        <SearchLead
          placeholder="Digite o nome ou número do lead"
          minW={['fit-content', '224px']}
          handleSearchLead={handleSearchLead}
        />

        <Flex position="relative">
          <SecondaryButton
            onClick={() =>
              history.push(
                `/captation/leads_sh/activities/${user.id}/${user.name}`
              )
            }
          >
            Atividades realizadas
          </SecondaryButton>
          <Flex
            w="32px"
            h="15px"
            backgroundColor="purple.700"
            borderRadius={5}
            color="white"
            fontSize="xx-small"
            justifyContent="center"
            alignItems="center"
            fontWeight="bold"
            px={2}
            pt={0.5}
            position="absolute"
            top="-5px"
            right="-7px"
          >
            NEW!
          </Flex>
        </Flex>
      </Flex>

      <Flex gridGap={5} align="center" justify="center">
        {/* <Link */}
        {/*  to="/captation/leads_sh/summary" */}
        {/*  style={{ alignSelf: 'flex-end' }} */}
        {/* > */}
        {/*  <Text */}
        {/*    color="gray.500" */}
        {/*    fontSize="smaller" */}
        {/*    borderBottom="1px solid" */}
        {/*    borderColor="gray.500" */}
        {/*    alignSelf="flex-end" */}
        {/*    mt="2" */}
        {/*  > */}
        {/*    Ver histórico de leads */}
        {/*  </Text> */}
        {/* </Link> */}

        <Flex>
          <PrimaryButton
            h={['6', '4']}
            mt="2"
            onClick={handleGetExpiredLead}
            isLoading={loadingMinedLead}
          >
            Minerar leads
          </PrimaryButton>
          <Flex
            alignSelf="end"
            h="6"
            justify="center"
            cursor="pointer"
            onClick={() => setShowUploadInstructionsModal(true)}
          >
            <Icon as={BiInfoCircle} ml="1" size={16} color="white" />
          </Flex>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showUploadInstructionsModal}
        onClose={() => {
          setShowUploadInstructionsModal(!showUploadInstructionsModal);
        }}
      >
        <DefaultModalContentWrapper width={['95vw', 'auto']}>
          <ModalTitle textAlign="center">Minerar Leads</ModalTitle>
          <Text>
            {`Adicionamos a funcionalidade de "Minerar leads" que consiste em
            utilizar um lead que passou do limite de frequencia e que não teve
            um contato.`}
          </Text>

          <PrimaryButton
            w="20%"
            mt={8}
            ml="auto"
            onClick={() => {
              setShowUploadInstructionsModal(!showUploadInstructionsModal);
            }}
          >
            OK
          </PrimaryButton>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </Flex>
  );
};
