import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Checkbox, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { formatToPhone } from 'brazilian-values';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { IPostOrderIp } from 'pages/IP/interfaces';
import { handleBlurCLient } from 'pages/IP/utils';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { queryClient } from 'App';

interface RealEstateAndFleetFormProps {
  handleShowModal: () => void;
  title: string;
  segment: string;
  subSegment?: string;
  description: string;
  initialFormData?: {
    name: string;
    email: string;
    phone: string;
  };
  leadId?: string;
  leadType?: string;
  takeOffLeadAction?: () => void;
  isLoadingTakeOffLead?: boolean;
}

export const RealEstateAndFleetForm: React.FC<RealEstateAndFleetFormProps> = ({
  handleShowModal,
  title,
  segment,
  subSegment,
  description,
  leadId,
  initialFormData,
  takeOffLeadAction,
  isLoadingTakeOffLead,
  leadType,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const isFromIpPage = window.location.href.includes('ip');

  const [isLoading, setIsLoading] = useState(false);
  const [openTakeOffLead, setOpenTakeOffLead] = useState(false);

  const [openRealEstateModal, setOpenRealEstateModal] = useState(false);
  const [formInfos, setFormInfos] = useState<IPostOrderIp>({
    isClient: isFromIpPage,
    segment,
    leadId,
    leadType,
    name: initialFormData?.name,
    email: initialFormData?.email,
    phone: initialFormData?.phone,
  } as IPostOrderIp);
  const [disabledInput, setDisabledInput] = useState(false);

  const handleCheckClient = () => {
    setFormInfos({
      ...formInfos,
      isClient: !formInfos.isClient,
    });
  };

  const onBlurClient = async (event: React.FocusEvent<HTMLInputElement>) =>
    handleBlurCLient(event, setFormInfos, formInfos);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({
      ...formInfos,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeValueCurrency = (e: string) => {
    setFormInfos({
      ...formInfos,
      value: e,
    });
  };

  const handleInputDisabled = (name: string) => {
    const data = name;

    if (formInfos.isClient) {
      const verifyData = data.split(' ');
      if (verifyData[1]) {
        setDisabledInput(true);
        return true;
      }
      if (!verifyData[1]) {
        setDisabledInput(false);
        return false;
      }
    } else {
      return true;
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!handleInputDisabled(formInfos.name)) {
        setIsLoading(false);
        return toast.dark('Escreva o nome completo do cliente');
      }

      if (!formInfos.information) {
        setIsLoading(false);
        return toast.dark('Escreva alguma informação');
      }

      if (subSegment) {
        await hubApiIp.postOrderIp({
          ...formInfos,
          isClient:
            formInfos.clientCode !== '' || formInfos.clientCode !== null,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formatNameToCaptalize(formInfos.name),
          subSegment,
        });
      } else {
        await hubApiIp.postOrderIp({
          ...formInfos,
          isClient:
            formInfos.clientCode !== '' || formInfos.clientCode !== null,
          phone: formInfos.phone.replace(/\D/g, ''),
          name: formInfos.name,
        });
      }

      if (leadId) {
        await hubApiCaptation.createEventsCaptation({
          employeeId: user.id,
          employeeType: 'AAI',
          eventType: 'CREATE_PJ2_AP_ORDER',
          leadId,
          status: 'ok',
        });

        await queryClient.invalidateQueries(['leadHistory', leadId]);
        await queryClient.invalidateQueries(['ipOrders', leadId]);
      }

      if (title === 'Crédito') {
        setIsLoading(false);
        return setOpenRealEstateModal(true);
      }

      if (takeOffLeadAction) {
        setIsLoading(false);
        setOpenTakeOffLead(true);
      }

      setIsLoading(false);
      // handleShowModal();

      const newWindow = window.open(
        'https://linktr.ee/alavancagempatrimonial',
        '_blank'
      );
      newWindow?.focus();

      toast.dark('Ordem enviada com sucesso!');
    } catch (error) {
      if (error.response.status === 409) {
        setIsLoading(false);

        return toast.dark(
          'Já existe uma ordem deste segmento para este cliente'
        );
      }

      toast.dark(
        'Não foi possível enviar a ordem, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDisabledInput(formInfos.isClient);
  }, [formInfos.isClient]);

  return (
    <>
      <BaseModalFormCard
        isModal
        maxW={['95%', '800px', '95%', '800px']}
        title={title}
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={handleShowModal}
        isLoading={isLoading}
        primaryButtonText="Solicitar"
        secondaryButtonText="Cancelar"
      >
        <Flex gridGap={10} flexDirection={['column', 'row']}>
          <Flex
            flex="1"
            flexDirection="column"
            overflowY={['auto', 'unset']}
            maxH={['200px', '600px']}
          >
            {description !== '' && <Text>{description}</Text>}
          </Flex>

          <Flex
            flex="1"
            flexDirection="column"
            overflowY={['auto', 'unset']}
            maxH={['200px', '600px']}
          >
            <Flex justifyContent="flex-end" mt={-8}>
              <Checkbox
                size="sm"
                onChange={handleCheckClient}
                isChecked={!formInfos.isClient}
              >
                Não é cliente EWZ
              </Checkbox>
            </Flex>

            <Stack spacing="3">
              {formInfos.isClient && (
                <InputWithLabel
                  label="Código do cliente:"
                  placeholder="Código BTG do cliente"
                  name="clientCode"
                  onBlur={onBlurClient}
                  width={['95%', '100%']}
                />
              )}
              <InputWithLabel
                label="Nome:"
                type="text"
                placeholder="Nome do cliente"
                name="name"
                value={formInfos.name}
                onChange={handleChangeValue}
                isDisabled={disabledInput}
                width={['95%', '100%']}
                isRequired
              />
              <InputWithLabel
                label="E-mail:"
                type="text"
                placeholder="E-mail do cliente"
                name="email"
                value={formInfos.email}
                onChange={handleChangeValue}
                isRequired
                width={['95%', '100%']}
              />
              <InputWithLabel
                label="Telefone:"
                type="text"
                placeholder="Telefone do cliente"
                name="phone"
                value={formInfos.phone && formatToPhone(formInfos.phone)}
                onChange={handleChangeValue}
                isRequired
                width={['95%', '100%']}
              />
              <Box width={['95%', '100%']} fontSize={14}>
                <InputWithLabelAndFormatingToMoney
                  label="Valor:"
                  value={Number(formInfos.value) ?? 0}
                  placeholder="R$ 0"
                  onChange={handleChangeValueCurrency}
                />
              </Box>
              <TextareaWithLabel
                label="Informações:"
                placeholder="Informações relevantes"
                name="information"
                value={formInfos.information}
                onChange={handleChangeValue}
                width={['95%', '100%']}
              />
            </Stack>
          </Flex>
        </Flex>
      </BaseModalFormCard>

      <ModalWrapper isOpen={openTakeOffLead} onClose={() => {}}>
        <DefaultModalContentWrapper width="400px" height="180px">
          <Flex
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            color="rgba(70, 70, 70, 1)"
          >
            <Text textAlign="center" fontWeight="bold" fontSize="20px">
              Deseja retirar o lead do fluxo de captação?
            </Text>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap={2}
            >
              <SecondaryButton
                onClick={() => {
                  setOpenTakeOffLead(false);
                  handleShowModal();
                }}
              >
                Não
              </SecondaryButton>
              <PrimaryButton
                onClick={takeOffLeadAction}
                isLoading={isLoadingTakeOffLead}
              >
                Sim
              </PrimaryButton>
            </Flex>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper isOpen={openRealEstateModal} onClose={() => {}}>
        <DefaultModalContentWrapper width="400px" height="180px">
          <Flex
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
            color="rgba(70, 70, 70, 1)"
          >
            <Text textAlign="center" fontWeight="bold" fontSize="20px">
              Sua ordem foi enviada com sucesso!
            </Text>
            <Text textAlign="center" fontSize="md">
              Agora você só precisa agendar um horário para a reunião desse
              cliente clicando nesse link
            </Text>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap={2}
            >
              <Link isExternal href="https://linktr.ee/ALAVANCAGEMPATRIMONIAL">
                <PrimaryButton
                  onClick={() => {
                    setOpenRealEstateModal(false);
                    handleShowModal();
                    toast.dark('Ordem enviada com sucesso!');
                  }}
                >
                  Agendar horário
                </PrimaryButton>
              </Link>
              <SecondaryButton
                onClick={() => {
                  setOpenRealEstateModal(false);
                  handleShowModal();
                }}
              >
                Fechar
              </SecondaryButton>
            </Flex>
          </Flex>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
