import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

import { ManagementSegmentCardExhange } from 'pages/Exchange/components/modules/ManagementSegmentCardExhange';
import { HeaderExchangeManagement } from 'pages/Exchange/components/modules/HeaderExchangeManagement/HeaderExchangeManagement';
import { SecondaryButton, BackPageButton } from 'components/elements/buttons';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { WraperEletronicSignaure } from 'components/WraperEletronicSignaure';

interface IResponseManagementValues {
  title: string;
  crmPath: string;
  leads: string;
  normais: {
    BTGCode: string;
    aberturaconta: string;
    canceled: string;
    contatoinicial: string;
    cotacao: string;
    finalized: string;
    id: string;
    name: string;
    semcontato: string;
  }[];
  canceled: never[];
}

export const ExchangeManagement: React.FC = () => {
  const [exchange, setExchange] = useState<IResponseManagementValues>({
    title: 'Câmbio',
    crmPath: '/crm/exchange',
    leads: '0',
    normais: [
      {
        BTGCode: '',
        aberturaconta: '',
        canceled: '',
        contatoinicial: '',
        cotacao: '',
        finalized: '',
        id: '',
        name: '',
        semcontato: '',
      },
    ],
    canceled: [],
  });

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getManagementInfos();
    }
  }, [lastAuth]);

  const getManagementInfos = async () => {
    try {
      const response = await hubApiExchange.listManagementInfos();

      setExchange({
        ...exchange,
        leads: response.leads,
        normais: [...response.normais],
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <PageWrapper
      alignItems="center"
      position="relative"
      paddingTop="48px"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '6' }}
    >
      <BackPageButton />

      <Flex justifyContent="space-between" w="80%" alignItems="center">
        <MainPageTitle mb="0">Gestão de Câmbio</MainPageTitle>
        <Flex alignItems="center" justifyContent="center" />
        <HeaderExchangeManagement allInfos={exchange.leads} />
      </Flex>
      <Flex
        w="100%"
        gridGap="9"
        overflow="auto"
        align="center"
        pb="5"
        justify="center"
        wrap={{ base: 'wrap', md: 'nowrap' }}
      >
        {exchange.normais.map((item, index) => (
          <Flex align="center" flexDir="column">
            <ManagementSegmentCardExhange key={index} item={item} />
            <Box mt="6">
              <Link to={`/board/manager/cambio/${item.id}`}>
                <SecondaryButton>Visualizar CRM</SecondaryButton>
              </Link>
            </Box>
          </Flex>
        ))}
      </Flex>
    </PageWrapper>
  );
};
