import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import { IGestaoSH } from 'pages/GenerationalVariables/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { TableRowSH } from 'pages/GenerationalVariables/components/elements/table/TableRowSH';
import { LoadingWithOverlay } from 'components/elements/others';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { InputWithLabel } from 'components/elements/forms';
import { MainPageTitle } from 'components/elements/texts';
import { TableBaseManagementSH } from 'pages/GenerationalVariables/components/elements/table/TableBaseManagementSH';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

export const SHManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [tableContent, setTableContent] = useState<IGestaoSH[]>([]);
  const currentMonth = new Date().toISOString().slice(0, 7);
  const date = new Date();
  const oneMonthBeforeCurrentMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    0
  )
    .toISOString()
    .slice(0, 7);
  const [startDate, setStartDate] = useState(oneMonthBeforeCurrentMonth);
  const [endDate, setEndDate] = useState(currentMonth);

  const getRankingSh = async () => {
    setLoading(true);
    try {
      const response = await hubApiCaptation.getRankingSH(startDate, endDate);
      setTableContent(response);
    } catch (err) {
      toast.dark('Erro ao buscar informações');
    }
    setLoading(false);
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getRankingSh();
    }
  }, [lastAuth, startDate, endDate]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Gestão SH</MainPageTitle>

      <Flex
        justify="flex-end"
        align="end"
        gap="1rem"
        flexDir={['column', 'column', 'row']}
        w="100%"
        mb={1}
      >
        <Flex flexDir={['column', 'column', 'row']} align="end" gap="0.5rem">
          <Text color="white">Inicio</Text>
          <InputWithLabel
            label=""
            color="white"
            fontSize="small"
            onChange={e => setStartDate(e.target.value)}
            type="month"
            value={startDate}
            name="date"
          />
        </Flex>

        <Flex flexDir={['column', 'column', 'row']} align="end" gap="0.5rem">
          <Text color="white">Fim</Text>
          <InputWithLabel
            label=""
            color="white"
            fontSize="small"
            onChange={e => setEndDate(e.target.value)}
            type="month"
            value={endDate}
            name="date"
          />
        </Flex>
      </Flex>

      <TableBaseManagementSH
        w="100%"
        maxW={{ base: '1080px', '2xl': '1240px' }}
        minHeight="200px"
        height="100vh"
        maxH="calc(100vh - 254px)"
        overflow="auto"
      >
        {tableContent.map((content, key) => (
          <TableRowSH
            key={`${content.id}-${key}`}
            tableContent={content}
            initDate={startDate}
          />
        ))}
      </TableBaseManagementSH>

      <LoadingWithOverlay isOpen={loading} />
    </DefaultPageWrapper>
  );
};
