import { FormEvent, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { showToast } from 'utilsV2/showToast';
import { formatToPhone } from 'brazilian-values';

interface EditModalCpf {
  onClose: () => void;
  clientId: string;
  updateCPF?: boolean;
  updatePhone?: boolean;
  updateName?: boolean;
  clientInfos: ClientInfosType;
  value?: string;
  updatePl?: boolean;
  setClientInfos: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  declaredPLAAI?: string;
}

export const ModalEditInfos: React.FC<EditModalCpf> = ({
  onClose,
  clientId,
  updateCPF,
  updatePhone,
  setClientInfos,
  clientInfos,
  updateName,
  declaredPLAAI,
  value,
  updatePl,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInfo, setFormInfo] = useState(value || declaredPLAAI || '');

  const formatValuePL = (value: string) => {
    const formatValue = value.replace(',', '.');
    return formatValue;
  };
  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    if (updateCPF) {
      const updateClientsDate = {
        id: clientId,
        document: formInfo,
      };

      try {
        const response = await hubApiUser.updateCLientsDate(updateClientsDate);

        if (clientInfos.cpfId) {
          await hubApiHome.deleteHomeAssignmentById(clientInfos.cpfId);
        }

        setIsLoading(false);

        setClientInfos({
          ...clientInfos,
          document: response.document,
        });

        toast.dark('Atualizado com sucesso!');
        onClose();
      } catch (err) {
        toast.dark('Erro ao atualizar informações');
        setIsLoading(false);
      }
    }

    if (updateName) {
      const updateClientsDate = {
        id: clientId,
        completeName: formInfo,
        name: formInfo,
      };

      try {
        const response = await hubApiUser.updateCLientsDate(updateClientsDate);

        setIsLoading(false);

        setClientInfos({
          ...clientInfos,
          completeName: response.completeName,
          name: response.completeName,
        });

        toast.dark('Atualizado com sucesso!');
        onClose();
      } catch (err) {
        toast.dark('Erro ao atualizar informações');
        setIsLoading(false);
      }
    }

    if (updatePhone) {
      const updateClientsDate = {
        id: clientId,
        phone: formInfo,
      };

      try {
        const response = await hubApiUser.updateCLientsDate(updateClientsDate);

        setIsLoading(false);

        setClientInfos({
          ...clientInfos,
          phone: response.phone,
        });

        toast.dark('Atualizado com sucesso!');
        onClose();
      } catch (err) {
        toast.dark('Erro ao atualizar informações');
        setIsLoading(false);
      }
    }

    if (updatePl) {
      const updateClientsDate = {
        id: clientId,
        declaredPLAAI: formatValuePL(formInfo),
      };

      try {
        const response = await hubApiUser.updateCLientsDate(updateClientsDate);

        setIsLoading(false);

        setClientInfos({
          ...clientInfos,
          declaredPLAAI: response.declaredPLAAI,
        });

        toast.dark('Atualizado com sucesso!');
        onClose();
      } catch (err) {
        toast.dark('Erro ao atualizar informações');
        setIsLoading(false);
      }
    }
  };
  return (
    <BaseModalFormCard
      isModal
      title={
        updateCPF
          ? 'Atualizar CPF'
          : updateName
          ? 'Atualizar nome'
          : updatePl
          ? 'Atualizar patrimônio'
          : 'Atualizar Telefone'
      }
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={onClose}
      isLoading={isLoading}
      primaryButtonText="Salvar"
      secondaryButtonText="Cancelar"
      maxW={['95vw', '510px']}
    >
      <Stack>
        {updateCPF || updateName || updatePhone ? (
          <InputWithLabel
            label={updateCPF ? 'CPF' : updateName ? 'Nome' : 'Telefone'}
            name={updateCPF ? 'cpf' : updateName ? 'name' : 'phone'}
            type={updateName ? 'text' : 'number'}
            onChange={e => {
              if (updatePhone && e.target.value.length > 11) {
                return showToast('Numero de telefone invalido');
              }

              setFormInfo(e.target.value);
            }}
            value={formInfo}
            placeholder={updateName ? 'Digite o nome' : 'Digite apenas números'}
          />
        ) : (
          updatePl && (
            <InputWithLabelAndFormatingToMoney
              label="PL"
              name="declaredPLAAI"
              onChange={e => setFormInfo(e)}
              placeholder="Digite apenas números"
              value={formInfo}
            />
          )
        )}
      </Stack>
    </BaseModalFormCard>
  );
};
