import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useMutation } from 'react-query';
import {
  AportActionEnum,
  AportActionRequestType,
  hubApiClients,
  UpdateAportRequestType,
} from 'services/hubApi/classes/HubApiClients';
import { ClientAportType } from 'sharedV2/types/client';

type PropsType = {
  aport: ClientAportType;
  isOpenAportDetailsModalFilter: UseSimpleFilterResponseType;
};

export const AportDetailsModal: React.FC<PropsType> = ({
  aport,
  isOpenAportDetailsModalFilter,
}): JSX.Element => {
  const { mutateAsync: aportAction, isLoading: isLoadingAportAction } =
    useMutation({
      mutationKey: ['aportAction'],
      mutationFn: async (data: AportActionRequestType) => {
        const response = await hubApiClients.aportAction(data);

        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', aport.btgCode]);
      },
    });

  const { mutateAsync: updateAport, isLoading: isLoadingUpdateAport } =
    useMutation({
      mutationKey: ['aportAction'],
      mutationFn: async (data: UpdateAportRequestType) => {
        const response = await hubApiClients.updateAport(data);

        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', aport.btgCode]);
      },
    });

  const handleConcludeAport = async () => {
    await aportAction({
      action: AportActionEnum.CONCLUDED,
      id: aport.id,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Aporte" />
        <Modal.CloseIcon
          onClick={() => isOpenAportDetailsModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={2}>
        <Input.Root>
          <Input.Label text="Descrição" />
          <Input.EditableTextField
            value={aport.description}
            isLoading={isLoadingUpdateAport}
            onConfirmEdit={newDescription =>
              updateAport({
                ...aport,
                description: newDescription,
              })
            }
          />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Valor" />
          <Input.EditableCurrency
            value={aport.value.toString()}
            isLoading={isLoadingUpdateAport}
            onConfirmEdit={newValue =>
              updateAport({
                ...aport,
                value: Number(newValue),
              })
            }
          />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Data" />
          <Input.Date
            value={aport.date.slice(0, 16)}
            onChange={event =>
              updateAport({
                ...aport,
                date: event.target.value,
              })
            }
          />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Concluir"
          isLoading={isLoadingAportAction}
          onClick={handleConcludeAport}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
