/* eslint-disable import/no-duplicates */
import { ptBR } from 'date-fns/locale';

import {
  differenceInMinutes,
  differenceInSeconds,
  format,
  parseISO,
} from 'date-fns';

enum DateDifferenceEnum {
  IN_MINUTES = 'IN_MINUTES',
  IN_SECONDS = 'IN_SECONDS',
}

export class DateUtils {
  static isInPast(date: Date | string): boolean {
    if (new Date(date).getTime() < new Date().getTime()) return true;

    return false;
  }

  static isToday(date: Date | string): boolean {
    return new Date(date).toDateString() === new Date().toDateString();
  }

  static differenceInMinutes(
    date: Date | string,
    dateDifference: DateDifferenceEnum = DateDifferenceEnum.IN_MINUTES
  ): number {
    const now = new Date();

    if (dateDifference === DateDifferenceEnum.IN_SECONDS)
      return differenceInSeconds(parseISO(date.toString()), now);

    return differenceInMinutes(parseISO(date.toString()), now);
  }

  static format(date: Date | string, pattern: string): string {
    return format(new Date(date), pattern, { locale: ptBR });
  }

  static formatWithTime(dateProp: string | Date): string {
    if (!dateProp) return '';

    return format(new Date(dateProp), 'Pp', {
      locale: ptBR,
    }).replace(',', ' às');
  }
}
