import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { errorHandler } from 'utils';
import { IReduxState } from 'shared/interfaces';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';

export interface IGraphHome {
  y: string;
  x: string;
}

export interface ICaptation {
  month: string;
  net: string;
}

export interface IEmployeeStats {
  sumwt: string;
  sumpl: string;
  nps: string;
  txr: string;
  clients: string;
  captation: ICaptation[];
}

export interface HomePageContextProps {
  employeeGraph: IGraphHome[];
  employeeStats: IEmployeeStats;
}

const HomePageContext = createContext({} as HomePageContextProps);

const HomePageContextProvider: React.FC = ({ children }) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const auth = useSelector((state: IReduxState) => state.auth);
  const [employeeStats, setEmployeeStats] = useState({} as IEmployeeStats);
  const [employeeGraph, setEmployeeGraph] = useState<IGraphHome[]>([]);

  const listEmployeeDataGraph = useCallback(async () => {
    try {
      const response = await hubApiHome.getEmployeeGraph();
      setEmployeeGraph(response);
    } catch (err) {
      errorHandler(err);
    }
  }, [user]);

  const listEmployeeNumbers = useCallback(async () => {
    try {
      const response = await hubApiHome.listEmployeeStats();
      setEmployeeStats(response);
    } catch (err) {
      errorHandler(err);
    }
  }, [user]);

  useEffect(() => {
    if (auth.signed && user) {
      listEmployeeDataGraph();
      listEmployeeNumbers();
    }
  }, [user]);

  return (
    <HomePageContext.Provider value={{ employeeGraph, employeeStats }}>
      {children}
    </HomePageContext.Provider>
  );
};

export { HomePageContext, HomePageContextProvider };
