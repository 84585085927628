import { useHistory } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { AiOutlineEdit, AiOutlineFileSearch } from 'react-icons/ai';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { Client, Offer } from 'pages/Offers/interfaces';
import { formatValueForSI } from 'utils/formatValueForSI';

interface AllOffersTableProps {
  offers: Offer[];
  handleDeleteOffer: (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    offerId: string
  ) => void;
  isLoggedUserManager: boolean;
  showEditOfferModal: (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    offer: Offer
  ) => void;
  isLoggedUserCanEditOrDeleteOffer: boolean;
}

export const AllOffersTable: React.FC<AllOffersTableProps> = ({
  offers,
  handleDeleteOffer,
  isLoggedUserManager,
  showEditOfferModal,
  isLoggedUserCanEditOrDeleteOffer,
}) => {
  const history = useHistory();

  const tableHeaders = [
    'Nome da oferta',
    'Vencimento',
    'Tipo da oferta',
    'Número de clientes',
    'Total PL',
    '',
    '',
    '',
  ];

  const sendToManagementPage = (offerId: string) => {
    history.push(`/offers/management/${offerId}`);
  };

  const sendToDetailsPage = (offerId: string) => {
    history.push(`/offers/details/${offerId}`);
  };

  const getAndFormatTotalPl = (clients: Partial<Client>[]): string => {
    const totalPl = clients.reduce(
      (acc, current) => acc + Number(current.allocationAmount),
      0
    );

    const formattedToShortNotation = formatValueForSI(totalPl);

    return formattedToShortNotation;
  };

  const formatDate = (date: string | null) => {
    if (date) {
      const splitedDate = date.split('-');

      return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
    }

    return '-';
  };

  const isOfferExpired = (offer: Offer) => {
    const today = new Date().toISOString().slice(0, 10);

    if (offer.dateLimit === '' || !offer.dateLimit) {
      const validOffer = !!offer.inCaptation;

      return !validOffer;
    }
    return today > offer.dateLimit;
  };

  const handleCheckIfUserCanSeeOffer = (offer: Offer) => {
    let canSee = false;

    if (!offer.isPublic) return canSee;

    if (offer.isMine || offer.isPublic || isLoggedUserManager) canSee = true;

    return canSee;
  };

  return (
    <TableBase
      headData={tableHeaders}
      w="100%"
      maxW={{ base: '1080px', '2xl': '1240px' }}
      maxH="480px"
      overflow="auto"
      p={0}
    >
      {offers.map(offer => (
        <TableRow
          key={offer.id}
          _hover={{ background: 'background.500' }}
          cursor="pointer"
          transition="400ms"
          title={(isOfferExpired(offer) && 'Oferta vencida') || ''}
          color={isOfferExpired(offer) ? 'red.400' : 'inherit'}
          onClick={() => sendToDetailsPage(offer.id)}
        >
          <TableData maxW="240px">{offer.offerName}</TableData>
          <TableData color={isOfferExpired(offer) ? 'red.400' : 'inherit'}>
            {formatDate(offer.dateLimit)}
          </TableData>
          <TableData>{offer.isPublic ? 'Pública' : 'Privada'}</TableData>

          <TableData>
            {
              offer.clients.filter(client => client.status === 'Confirmado')
                .length
            }
          </TableData>
          <TableData>{getAndFormatTotalPl(offer.clients)}</TableData>

          {handleCheckIfUserCanSeeOffer(offer) && (
            <TableData>
              <Flex
                justifyContent="center"
                onClick={event => {
                  event.stopPropagation();
                  sendToManagementPage(offer.id);
                }}
              >
                <AiOutlineFileSearch size={20} />
              </Flex>
            </TableData>
          )}
          {!offer.isPublic && <TableData />}

          {(offer.isMine || isLoggedUserCanEditOrDeleteOffer) && (
            <TableData>
              <AiOutlineEdit
                size={20}
                onClick={e => showEditOfferModal(e, offer)}
              />
            </TableData>
          )}

          {(!offer.isPublic ||
            (offer.isPublic && offer.isMine) ||
            isLoggedUserCanEditOrDeleteOffer) && (
            <TableData>
              <BiTrash
                size={20}
                onClick={e => handleDeleteOffer(e, offer.id)}
              />
            </TableData>
          )}
        </TableRow>
      ))}
    </TableBase>
  );
};
