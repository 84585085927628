import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { BiHide, BiShow } from 'react-icons/bi';
import {
  Stack,
  Text,
  InputRightElement,
  Button,
  InputGroup,
  Input as ChakraInput,
} from '@chakra-ui/react';

import { signInRequest } from 'store/modules/auth/actions';
import { PrimaryButton } from 'components/elements/buttons';
import { Input } from 'components/elements/forms';
import {
  ModalWrapper,
  DefaultCardWrapper,
  BaseButtonGroup,
} from 'components/elements/wrappers';
import RetrivePasswordMail from '../components/RetrivePasswordMail';

import loginSchema from './schema';
import { LoginContainer, LoginPageTitle, LoginPageSubTitle } from './styles';

export const Login: React.FC = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });

  const handleToggleModal = () => setShowModal(!showModal);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (loginForm.email === '' && loginForm.password === '') {
      toast.dark('Digite seu email e senha para entrar');
      setLoading(false);

      return;
    }

    try {
      const formIsValid = await loginSchema.validate(loginForm).catch(err => {
        err.errors.map((error: string) => toast.dark(error));
        setLoading(false);
      });

      if (formIsValid) {
        const { email, password } = loginForm;
        dispatch(signInRequest(email, password));

        setLoading(false);
      }
    } catch (err) {
      toast.dark('Ocorreu um erro durante o login. Tente novamente.');
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <DefaultCardWrapper
        flexDirection="column"
        w="620px"
        bgGradient="linear(to bottom right, #393939, #1d1d1d)"
      >
        <LoginPageTitle>Bem-vindo ao HUB EWZ</LoginPageTitle>
        <LoginPageSubTitle>
          Faça login com o seu usuário e senha
        </LoginPageSubTitle>

        <form onSubmit={handleFormSubmit}>
          <Stack spacing={3}>
            <Input
              name="email"
              value={loginForm.email}
              onChange={handleChangeValue}
              placeholder="Digite o seu e-mail EWZ"
            />
            <InputGroup size="md">
              <ChakraInput
                background="background.500"
                borderColor="transparent"
                focusBorderColor="primary.600"
                _placeholder={{ color: 'gray.600' }}
                _hover={{ borderColor: 'primary.800' }}
                type={show ? 'text' : 'password'}
                name="password"
                value={loginForm.password}
                onChange={handleChangeValue}
                placeholder="Digite a sua senha"
              />
              <InputRightElement width="fit-content" px="2">
                <Button h="1.5rem" size="sm" bg="#393939" onClick={handleClick}>
                  {show ? <BiHide size={20} /> : <BiShow size={20} />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Stack>

          <Text onClick={handleToggleModal} onKeyDown={handleToggleModal}>
            Esqueci minha senha
          </Text>

          <BaseButtonGroup>
            <PrimaryButton type="submit" size="sm" isLoading={loading}>
              Entrar no HUB
            </PrimaryButton>
          </BaseButtonGroup>
        </form>
      </DefaultCardWrapper>

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <RetrivePasswordMail handleToggleModal={handleToggleModal} />
      </ModalWrapper>
    </LoginContainer>
  );
};
