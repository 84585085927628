import React, { useEffect } from 'react';

import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { uuid } from 'uuidv4';
import {
  FcCalendar,
  FcConferenceCall,
  FcCurrencyExchange,
  FcSurvey,
} from 'react-icons/fc';

import { formateDateAndTime } from 'utils/formatDate';
import formatStringInTitle from 'utils/formatStringInTitle';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { ClientInfosType, Reminder } from 'pages/MyClients/interfaces';

interface NextContactsSectionProps {
  showMeetingSection: (taskType: string) => void;
  clientId: string;
  typeClient: ClientInfosType;
  signalToUpdate: boolean;
  setTypeClient: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  setSelectedReminder: (reminder: Reminder) => void;
  setClientReminders: React.Dispatch<React.SetStateAction<Reminder[]>>;
  clientReminders: Reminder[];
}

export const NextContactsSection: React.FC<NextContactsSectionProps> = ({
  showMeetingSection,
  setSelectedReminder,
  setClientReminders,
  clientReminders,
  clientId,
  signalToUpdate,
  typeClient,
  setTypeClient,
}) => {
  useEffect(() => {
    getClientReminders();
  }, [signalToUpdate, typeClient.frequencyM]);

  const getClientReminders = async () => {
    try {
      const response: Reminder[] = await hubApiUser.getAnotationsMessage(
        clientId
      );

      const lastContactUpdated = response
        .filter(reminder => reminder.realized)
        .reverse();

      const filterOpenReminders = response.filter(
        reminder => !reminder.realized
      );

      const arrayTest: Reminder[] = [];

      if (typeClient.frequencyM && typeClient.frequencyM !== null) {
        const [days, dataFrequency] = typeClient.frequencyM.split(' | ');

        let dateOfMeeting: Date = new Date();
        let lastContact: Date = new Date(dataFrequency);

        if (lastContactUpdated[0]) {
          lastContact = new Date(lastContactUpdated[0].updated_at);
        }

        for (let i = 0; i < 3; i++) {
          if (dateOfMeeting !== new Date()) {
            dateOfMeeting = new Date(
              lastContact.setDate(lastContact.getDate() + parseFloat(days))
            );
          } else {
            dateOfMeeting = new Date(
              lastContact.setDate(dateOfMeeting.getDate() + parseFloat(days))
            );
          }

          arrayTest.push({
            clientId: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            dataRetorno: dateOfMeeting.toISOString(),
            id: uuid(),
            msg: '',
            fake: true,
            realized: false,
            commentedAreas: '',
            title: 'REUNIÃO PERIÓDICA',
            type: 'REUNIÃO PERIÓDICA',
          });
        }

        setClientReminders([...filterOpenReminders, ...arrayTest]);
      } else {
        const startDate = new Date(typeClient.startDate);
        let dateOfMeeting: Date = startDate;

        while (dateOfMeeting < new Date()) {
          dateOfMeeting = new Date(
            dateOfMeeting.setDate(dateOfMeeting.getDate() + 182)
          );
        }

        arrayTest.push({
          clientId: '',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          dataRetorno: dateOfMeeting.toISOString(),
          id: uuid(),
          msg: '',
          fake: true,
          realized: false,
          commentedAreas: '',
          title: 'REUNIÃO PERIÓDICA',
          type: 'REUNIÃO PERIÓDICA',
        });

        for (let i = 0; i < 2; i++) {
          dateOfMeeting = new Date(
            dateOfMeeting.setMonth(dateOfMeeting.getMonth() + 6)
          );

          arrayTest.push({
            clientId: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            dataRetorno: dateOfMeeting.toISOString(),
            id: uuid(),
            msg: '',
            fake: true,
            realized: false,
            commentedAreas: '',
            title: 'REUNIÃO PERIÓDICA',
            type: 'REUNIÃO PERIÓDICA',
          });
        }
        setClientReminders([...filterOpenReminders, ...arrayTest]);
      }

      const clientOriginInfos = await hubApiUser.getClientsContactsFromType(
        typeClient.btgAccount
      );

      setTypeClient(clientOriginInfos);
    } catch (error) {
      toast.dark('Não foi possível buscar os lembretes do cliente');
    }
  };

  const handleReminderClick = (reminderType: string, reminder: Reminder) => {
    showMeetingSection(reminderType);
    setSelectedReminder(reminder);
  };
  return (
    <Flex flexDir="column" maxHeight="40%" height="40%" pt={2}>
      <InfoTitle title="Próximos contatos" />
      <SimpleGrid
        justifyItems="center"
        columns={[1, 2]}
        spacing={1}
        width={['98%', '100%']}
        height="90%"
        gap="1.5"
        mt={4}
        maxH="calc(100vh - 530px)"
        overflowY="auto"
        alignContent="flex-start"
        className="boxClients"
        pr={1}
      >
        {clientReminders?.map(data => (
          <Flex
            key={`${data.id}-${data.dataRetorno}`}
            background={!data.fake ? 'background.400' : 'background.700'}
            _hover={!data.fake ? { background: 'background.500' } : {}}
            borderRadius={4}
            padding="4"
            border={!data.fake ? '1px solid' : 'none'}
            borderColor={!data.fake ? 'blue.400' : 'none'}
            alignItems="center"
            cursor={!data.fake ? 'pointer' : 'normal'}
            transition="400ms"
            width="98%"
            maxW="100%"
            onClick={() => {
              !data.fake && handleReminderClick(data.type, data);
            }}
          >
            {(data.type.toLowerCase().includes('pesquisa nps') && (
              <FcSurvey size={28} color="white" />
            )) ||
              (data.type.toLowerCase().includes('reunião') && (
                <FcConferenceCall size={28} />
              )) ||
              (data.type.toLowerCase().includes('resgate') && (
                <FcCurrencyExchange size={28} />
              )) || <FcCalendar size={28} />}

            <Box ml={2} maxW="150px">
              <Text fontSize="sm" color="white.300" isTruncated>
                {formatStringInTitle(data.type)}
              </Text>
              <Text fontSize="xs">{formateDateAndTime(data.dataRetorno)}</Text>
            </Box>
          </Flex>
        ))}

        {clientReminders.length === 0 && (
          <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
            <Text fontWeight="light" fontSize="sm" textAlign="center">
              Não há contatos agendados
            </Text>
          </Flex>
        )}
      </SimpleGrid>
    </Flex>
  );
};
