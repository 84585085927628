import React, { useState } from 'react';
import {
  Checkbox,
  Divider,
  Flex,
  GridItem,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { toast } from 'react-toastify';
import parser from 'html-react-parser';
import formatValue from 'utils/formatValue';
import { basicDate } from 'utils/formatDate';
import { ModalWrapper } from 'components/elements/wrappers';
import { BaseModalFormCard } from 'components/elements/cards';
import { Input } from 'components/elements/forms';
import { IAnnotations, IClients, IMeetings } from '../../../../interfaces';

interface IMeetingCardContentProps {
  meetingsData: IMeetings;
  annotation: IAnnotations;
  data: IClients;
  handleMeetingSeen: (data: IMeetings) => void;
}

export const MeetingCardContent: React.FC<IMeetingCardContentProps> = ({
  meetingsData,
  annotation,
  data,
  handleMeetingSeen,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(meetingsData.viewed);
  const [isOpenMeetingRatingModal, setIsOpenMeetingRatingModal] =
    useState<boolean>(false);

  const [meetingRating, setMeetingRating] = useState<string>('');

  const updateAnnotationsViewed = async (data: {
    id: string;
    viewed: boolean;
    rating: number;
  }) => {
    try {
      await hubApiUser.patchAnnotationsViewed(data);
    } catch (error) {
      toast.dark(
        'Não foi possível definir como "visto" esta reunião. Tente novamente em alguns minutos'
      );
    }
  };

  const handleSetViewed = (
    event: React.FormEvent<HTMLFormElement>,
    meetingData: IMeetings
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const ratingNumber = Number(meetingRating);

    if (
      ratingNumber < 0 ||
      ratingNumber > 5 ||
      !Number.isInteger(ratingNumber)
    ) {
      setIsLoading(false);

      return toast.dark(
        'A avaliação da reunião deve estar entre 0 e 5 e deve ser um número inteiro.'
      );
    }
    setIsChecked(true);
    handleMeetingSeen({ ...meetingData, viewed: true, rating: ratingNumber });
    updateAnnotationsViewed({
      id: meetingData?.meetingId,
      viewed: true,
      rating: ratingNumber,
    });
    setIsLoading(false);
  };

  return (
    <GridItem
      w="100%"
      height={480}
      background="background.600"
      borderRadius="md"
      p="3"
    >
      <Flex
        height="20%"
        flexDirection="column"
        width="100%"
        justifyContent="space-evenly"
      >
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          mb={-0.5}
        >
          <Text fontSize="sm" isTruncated width="210px">
            Assessor:{' '}
            <Text fontWeight="regular" fontSize="sm" as="span" color="#fff">
              {formatNameToCaptalize(annotation.assessor)}
            </Text>
          </Text>
          <Text fontSize="sm" color="#fff" fontWeight="regular">
            {basicDate(meetingsData.created_at)}
          </Text>
        </Flex>
        <Text fontSize="sm" isTruncated width="310px">
          Cliente:{' '}
          <Text fontWeight="regular" fontSize="sm" as="span" color="#fff">
            {formatNameToCaptalize(data.clientName)}
          </Text>
        </Text>
        <Text fontSize="sm">
          PL:{' '}
          <Text fontWeight="regular" fontSize="sm" as="span" color="#fff">
            {formatValue(Number(data.pl))}
          </Text>{' '}
        </Text>
        <Text fontSize="sm">
          NPS:{' '}
          <Text fontWeight="regular" fontSize="sm" as="span" color="#fff">
            {!data.nps ? 'Não informado' : data.nps}
          </Text>
        </Text>
      </Flex>
      <Divider borderColor="background.300" my="4" w="80%" mx="auto" />
      <Flex
        w="100%"
        height="38%"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Text
          w="100%"
          height="15%"
          fontSize="sm"
          alignItems="center"
          fontWeight="regular"
          mb={1}
        >
          Disclaimer da reunião:
        </Text>
        <Flex
          w="100%"
          height="80%"
          overflowX="auto"
          background="background.500"
          borderRadius="6px"
        >
          <Text fontSize="sm" color="#fff" fontWeight="light" p={2}>
            {parser(meetingsData.disclaimer || '')}
          </Text>
        </Flex>
      </Flex>

      <Stack
        pt={1}
        direction="column"
        height="38%"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Text fontSize="sm">Áreas comentadas :</Text>
        <Flex
          width="100%"
          height="30%"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="wrap"
          gap={1}
        >
          {meetingsData.commentedAreas?.split(',').length ? (
            meetingsData.commentedAreas?.split(',').map(commentedArea => (
              <Flex
                width="165px"
                maxWidth="165px"
                height="20px"
                alignItems="center"
                justifyContent="flex-start"
                gap={1}
                key={commentedArea}
              >
                <AiFillCheckCircle size={16} color="green" />
                <Text fontSize="sm" color="#fff" fontWeight="light" as="span">
                  {commentedArea}
                </Text>
              </Flex>
            ))
          ) : (
            <Flex width="100%" alignItems="center" gap={1}>
              <AiFillCloseCircle size={16} color="red" />
              <Text fontSize="sm" color="#fff" fontWeight="light" as="span">
                Nenhuma área comentada
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex
          width="100%"
          height="70%"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-end"
          mt={0}
        >
          <Text fontSize="sm" mb={1}>
            Tipo da reunião:{' '}
            <Text as="span" color="#fff" fontSize="sm">
              {!meetingsData.meetingType ? '-' : meetingsData.meetingType}
            </Text>{' '}
          </Text>
          {meetingsData.rating && (
            <Text fontSize="sm">
              Avaliação da reunião:{' '}
              <Text as="span" color="#fff" fontSize="sm">
                {meetingsData.rating}
              </Text>{' '}
            </Text>
          )}

          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize="sm" mb={2}>
              Avaliação de satisfação:{' '}
              <Text as="span" fontSize="sm" color="#fff">
                {!meetingsData.clientSatisfaction
                  ? '-'
                  : `${meetingsData.clientSatisfaction}/10`}
              </Text>{' '}
            </Text>
            <Checkbox
              mb={4}
              size="md"
              colorScheme="blue"
              isChecked={isChecked}
              onChange={() => {
                if (!isChecked) setIsOpenMeetingRatingModal(true);
              }}
            >
              <Text fontSize="sm">Visto</Text>
            </Checkbox>
          </Flex>
        </Flex>
      </Stack>
      <ModalWrapper
        isOpen={isOpenMeetingRatingModal}
        onClose={() => setIsOpenMeetingRatingModal(false)}
      >
        <BaseModalFormCard
          isModal
          handleFormSubmit={event => handleSetViewed(event, meetingsData)}
          handleToggleModal={() => setIsOpenMeetingRatingModal(false)}
          isLoading={isLoading}
          title="Avaliação da Reunião"
          primaryButtonText="Avaliar"
        >
          <Flex flexDirection="column" gap={2}>
            <Text>Como deseja avaliar essa reunião?</Text>

            <UnorderedList mb={2}>
              <ListItem fontSize="sm" fontStyle="italic">
                0 - nenhuma informação adicionada
              </ListItem>
              <ListItem fontSize="sm" fontStyle="italic">
                1 - foi adicionada uma única informação sobre uma oferta
              </ListItem>
              <ListItem fontSize="sm" fontStyle="italic">
                2 - foi adicionada uma informação simples sobre o cliente
              </ListItem>
              <ListItem fontSize="sm" fontStyle="italic">
                3 - assessor trouxe uma única informação relevante do cliente
              </ListItem>
              <ListItem fontSize="sm" fontStyle="italic">
                4 - assessor trouxe mais de 1 a 3 informações relevantes do
                cliente
              </ListItem>
              <ListItem fontSize="sm" fontStyle="italic">
                5 - assessor trouxe mais que 3 informações relevantes do cliente
              </ListItem>
            </UnorderedList>

            <Input
              type="number"
              placeholder="Ex.: 4"
              value={meetingRating}
              onChange={event => setMeetingRating(event.target.value)}
            />
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>
    </GridItem>
  );
};
