import React from 'react';
import { Text } from '@chakra-ui/react';

import history from 'services/history';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import { AssignmentProps } from 'pages/Home/interfaces';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface ComponentProps {
  handleToggleModal: () => void;
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
}

export const NewClientModal: React.FC<ComponentProps> = ({
  handleToggleModal,
  assignment,
  handleRemoveItem,
}) => {
  const sendToClientPage = () => {
    history.push(`user/users_clients/new/${assignment.btgAccount}`);
  };

  return (
    <DefaultModalContentWrapper maxW="410px">
      <ModalTitle>Novo Cliente</ModalTitle>

      <Text color="white" fontWeight="medium" mb="1.5">
        {assignment.name.toUpperCase()} está na sua lista de clientes!
      </Text>

      <Text mb={1} fontSize="sm" textAlign="end">
        Cod. BTG: {assignment.btgAccount}
      </Text>

      <BaseButtonGroup>
        <SecondaryButton
          onClick={() => {
            handleRemoveItem(assignment.idTask);
            sendToClientPage();
          }}
        >
          Ver cliente
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            handleRemoveItem(assignment.idTask);
            handleToggleModal();
          }}
        >
          Marcar como visto
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
