import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { RouteMeetingModal } from 'componentsV2/modules/captation/routeMeetingModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';

export const LeadDetailsRouteMeeeting = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenRouteMeetingModal =
    getParam('isOpenRouteMeetingModal') === 'true';

  return (
    <>
      <Button.Primary
        onClick={() => setParam('isOpenRouteMeetingModal', 'true')}
      >
        Rotear Reunião
      </Button.Primary>

      <ModalWrapper
        isOpen={isOpenRouteMeetingModal}
        onClose={() => deleteParam('isOpenRouteMeetingModal')}
      >
        <RouteMeetingModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
