import { ResponsiveLine } from '@nivo/line';

import { IInfosMundoInvest } from 'pages/GenerationalVariables/interfaces';

interface ResposiveLineChartProps {
  date: IInfosMundoInvest;
  infoDates: {
    month0: string;
    month1: string;
    month2: string;
    month3: string;
    month4: string;
    month5: string;
  };
}

export const ResponsiveLineChart: React.FC<ResposiveLineChartProps> = ({
  date,
  infoDates,
}) => {
  const data = [
    {
      id: 'PL',
      color: '#219c36',
      data: [
        {
          x: infoDates.month5,
          y: Number(date.graphPL[0]),
        },
        {
          x: infoDates.month4,
          y: Number(date.graphPL[1]),
        },
        {
          x: infoDates.month3,
          y: Number(date.graphPL[2]),
        },
        {
          x: infoDates.month2,
          y: Number(date.graphPL[3]),
        },
        {
          x: infoDates.month1,
          y: Number(date.graphPL[4]),
        },
        {
          x: infoDates.month0,
          y: Number(date.graphPL[5]),
        },
      ],
    },
    {
      id: 'NNM',
      color: '#053164',
      data: [
        {
          x: infoDates.month5,
          y: Number(date.graphNNM[0]),
        },
        {
          x: infoDates.month4,
          y: Number(date.graphNNM[1]),
        },
        {
          x: infoDates.month3,
          y: Number(date.graphNNM[2]),
        },
        {
          x: infoDates.month2,
          y: Number(date.graphNNM[3]),
        },
        {
          x: infoDates.month1,
          y: Number(date.graphNNM[4]),
        },
        {
          x: infoDates.month0,
          y: Number(date.graphNNM[5]),
        },
      ],
    },
  ];

  const color = data.map(item => {
    return item.color;
  });

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 24, right: 34, bottom: 46, left: 60 }}
      colors={color}
      animate
      enableArea
      areaOpacity={0.1}
      enablePoints
      enableSlices="x"
      pointColor="white"
      useMesh
      xFormat="time:%Y-%m-%d"
      xScale={{
        type: 'time',
        precision: 'month',
        useUTC: false,
        format: '%Y-%m-%d',
      }}
      yFormat=">-.2s"
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 15,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'square',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      axisBottom={{
        format: '%b %Y',
        tickValues: 'every 1 month',
        legendOffset: -12,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 10,
        format: '>-.2s',
        legendPosition: 'end',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      lineWidth={3}
      theme={{
        textColor: '#ffffff',
        fontSize: 16,
        crosshair: {
          line: {
            strokeWidth: 2,
            stroke: '#ffffff',
            strokeOpacity: 1,
          },
        },
        grid: {
          line: {
            stroke: '#2b2929',
            strokeWidth: 1,
          },
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: '#d3d3d3',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong>{point.serieId}:</strong> {point.data.yFormatted}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
