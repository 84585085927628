import { Flex } from '@chakra-ui/react';

export const WrapperBigNumbersMiGeneral: React.FC = ({ children }) => {
  return (
    <Flex
      p="4"
      borderRadius="3"
      bg="background.600"
      flexDir="column"
      align="center"
      justify="center"
      color="whiteAlpha.900"
      minW="150px"
      fontSize="lg"
    >
      {children}
    </Flex>
  );
};
