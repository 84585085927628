import { Text, Flex } from '@chakra-ui/react';

interface ContentTypeButtonProps {
  contentType: string;
  setContentType: React.Dispatch<React.SetStateAction<string>>;
  editionMode?: boolean;
}

export const ContentTypeButton: React.FC<ContentTypeButtonProps> = ({
  contentType,
  setContentType,
  editionMode,
}) => {
  return (
    <Flex>
      {!editionMode && (
        <Text
          as="span"
          px={4}
          background={contentType === 'all' ? 'primary.100' : 'white'}
          color={contentType === 'all' ? 'white' : 'rgba(70, 70, 70, 1)'}
          borderTopLeftRadius="10px"
          borderBottomLeftRadius="10px"
          fontSize="sm"
          onClick={() => setContentType('all')}
          cursor="pointer"
        >
          Todos
        </Text>
      )}
      <Text
        as="span"
        px={4}
        background={contentType === 'text' ? 'primary.100' : 'white'}
        color={contentType === 'text' ? 'white' : 'rgba(70, 70, 70, 1)'}
        borderTopLeftRadius={!editionMode ? '0' : '10px'}
        borderBottomLeftRadius={!editionMode ? '0' : '10px'}
        fontSize="sm"
        onClick={() => setContentType('text')}
        cursor="pointer"
      >
        Texto
      </Text>
      <Text
        as="span"
        px={4}
        background={contentType === 'video' ? 'primary.100' : 'white'}
        color={contentType === 'video' ? 'white' : 'rgba(70, 70, 70, 1)'}
        borderTopRightRadius="10px"
        borderBottomRightRadius="10px"
        fontSize="sm"
        onClick={() => setContentType('video')}
        cursor="pointer"
      >
        Vídeo
      </Text>
    </Flex>
  );
};
