import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';

import { formatValueForSI } from 'utils/formatValueForSI';

interface PieProps {
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
}

export const AlocationsChart: React.FC<PieProps> = ({ data }) => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  // const [sideTooltip, setSideTooltip] = useState<{
  //   label: string;
  //   value?: string;
  // }>({
  //   label: 'Selecione um ativo',
  // });

  // const handleChartClick = (label: string, value?: number) => {
  //   if (value) {
  //     const formattedValue = formatValue(value);
  //     setSideTooltip({
  //       label,
  //       value: formattedValue,
  //     });
  //     return;
  //   }
  //   const getValueByProvidedLabel = data.filter(item => item.label === label)[0]
  //     .value;
  //   const formattedValue = formatValue(getValueByProvidedLabel);
  //   setSideTooltip({
  //     label,
  //     value: formattedValue,
  //   });
  // };

  return (
    <Flex height="100%" justifyContent="center" alignItems="center" w={'100%'}>
      <Flex
        h={['50vh', '45vh']}
        w={['80%', '50vw']}
        position="relative"
        mt={['0', '4']}
      >
        <ResponsivePie
          data={data}
          colors={data.map(item => item.color)}
          theme={{
            legends: {
              text: {
                fontSize: '14px',
                fontWeight: 'bolder',
              },
            },
          }}
          margin={{
            top: isMobile ? 50 : 30,
            right: isMobile ? 0 : 50,
            bottom: 30,
            left: isMobile ? 0 : -50,
          }}
          innerRadius={0.6}
          padAngle={1}
          cornerRadius={0.5}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          // onClick={node => handleChartClick(node.label as string, node.value)}
          // arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 0]] }}
          arcLinkLabelsColor={{ from: 'color' }}
          valueFormat={value => formatValueForSI(value)}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          legends={[
            {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: -50,
              translateY: isMobile ? -30 : 50,
              itemsSpacing: 14,
              itemWidth: isMobile ? 100 : 100,
              itemHeight: isMobile ? 10 : 18,
              itemTextColor: '#fff',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: isMobile ? 15 : 18,
              symbolShape: 'circle',
              // onClick: item => handleChartClick(item.label as string),
              data: data.map(legend => ({
                id: legend.id,
                color: legend.color,
                label: `${legend.label} (${formatValueForSI(legend.value)})`,
              })),
            },
          ]}
        />
        <Box
          pos="absolute"
          top="10"
          right={['30px', '130px']}
          bottom="50px"
          left="30px"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          pointerEvents="none"
          color="white"
        >
          <Text fontSize={['md', 'xs']} fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="bold">
            {formatValueForSI(
              data
                .map(item => item.value)
                .reduce((total, newItem) => total + newItem)
            )}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
