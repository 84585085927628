import { Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { formatToBRL, formatToPhone } from 'brazilian-values';
import { LeadScheduleReturn } from 'pages/Captation/components/modules/modals/LeadScheduleReturn';
import { useCaptationMeetingsContext } from 'hooks';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../../components/elements/buttons';
import {
  BaseButtonGroup,
  ModalWrapper,
} from '../../../../../../components/elements/wrappers';
import { TitleWithSideLine } from '../../../../../../components/elements/texts';
import { ILeadInfos } from '../../../../interfaces';
import { formatNameToCaptalize } from '../../../../../../utils/captalizeNames';
import formatValue from '../../../../../../utils/formatValue';
import { DeclineOffer, ScheduleMeeting } from '../../../modules/modals';
import { IMeetingsInfoFromMI } from '../../../modules/meetings/MeetingsInfosFromMI';
import { hubApiCaptation } from '../../../../../../services/hubApi/classes/HubApiCaptation';
import { formateDateAndTime } from '../../../../../../utils/formatDate';
import { LoadingSpinner } from '../../../../../../components/elements/others';

interface LeadFromPoolMI extends ILeadInfos {
  id: string;
  age: number;
  dependents: number;
  maritalStatus: string;
  activity: string;
  monthlyIncome: number;
  monthlyExpense: number;
  ageToRetire: number;
  desiredIncome: number;
  incomeType: string;
  investPerMonth: number;
  investorProfile: string;
  lifeMoment: string;
  created_at: string;
  plMundoInvest: number;
}

export type LeadMundoInvestPoolActionType =
  | 'decline'
  | 'purchase'
  | 'no_answer'
  | 'callback';

interface IPoolMILeadCard {
  lead: LeadFromPoolMI;
  leadAction: (action: LeadMundoInvestPoolActionType) => void;
  isLoading: boolean;
}

export const PoolMILeadCard = ({
  lead,
  leadAction,
  isLoading,
}: IPoolMILeadCard): JSX.Element => {
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const [showScheduleMeetingModal, setShowScheduleMeetingModal] =
    useState(false);
  const [showDeclineLeadModal, setShowDeclineLeadModal] = useState(false);
  const [isOpenMeetingCallbackModal, setIsOpenMeetingCallbackModal] =
    useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [meetingInfoFromMI, setMeetingInfoFromMI] =
    useState<IMeetingsInfoFromMI>({
      id: '',
      age: 0,
      dependents: 0,
      activity: '-',
      maritalStatus: '-',
      monthlyIncome: 0,
      monthlyExpense: 0,
      ageToRetire: 0,
      desiredIncome: 0,
      incomeType: '',
      investPerMonth: 0,
      investorProfile: '',
      lifeMoment: '',
      created_at: '',
    });

  const handleCallbackMeetingAction = () => {
    setIsOpenMeetingCallbackModal(true);
  };

  const handleScheduleMeetingAction = () => {
    setShowScheduleMeetingModal(true);
  };

  const handleNoAnswerAction = () => {
    leadAction('no_answer');
    setActionInProgress(true);
  };

  const handleDeclineAction = () => {
    setShowDeclineLeadModal(true);
  };

  const handleGetMeetingsInfoFromMI = async () => {
    const response = await hubApiCaptation.getMeetingInfoFromMI(
      typeof lead.email === 'string' ? lead.email : ''
    );

    const filteredInfos =
      response.clients[0] &&
      Object.fromEntries(
        Object.entries(response.clients[0]).filter(
          ([_, value]) => value !== null
        )
      );

    setMeetingInfoFromMI(filteredInfos as any);
  };

  useEffect(() => {
    if (lead?.email !== undefined) handleGetMeetingsInfoFromMI();
    setActionInProgress(false);
  }, [lead]);

  return (
    <Flex
      w="620px"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="space-between"
      borderRadius={8}
      px={6}
      pt={4}
      pb={4}
      h="98%"
    >
      {(isLoading || !lead) && <LoadingSpinner />}

      {!isLoading && (
        <>
          <Flex flexDirection="column" gap={2} h="86%">
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                fontSize="xx-large"
                fontWeight="bold"
                isTruncated
                mb={4}
                color="rgba(70, 70, 70, 1)"
              >
                {formatNameToCaptalize(lead?.name ?? '')}
              </Text>
            </Flex>

            <TitleWithSideLine>Contato</TitleWithSideLine>
            <Text fontSize="sm" fontWeight="thin">
              E-mail:{' '}
              <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                {lead.email}
              </Text>
            </Text>
            <Text mb={2} fontSize="sm" fontWeight="thin">
              Telefone:{' '}
              <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                {typeof lead.phone === 'string' &&
                  formatToPhone(
                    (lead.phone as string).includes('55')
                      ? (lead.phone as string).substring(2)
                      : (lead.phone as string)
                  )}
              </Text>
            </Text>

            <TitleWithSideLine>Informações do Mundo Invest</TitleWithSideLine>
            <Flex
              flexDirection="column"
              gap={1}
              maxH="45%"
              overflow="auto"
              className="slimScrollbar"
            >
              <Text fontSize="sm" fontWeight="thin">
                Patrimônio:{' '}
                <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                  {formatValue(lead.plMundoInvest)}
                </Text>
              </Text>

              {meetingInfoFromMI?.age && (
                <Text
                  fontSize="sm"
                  fontWeight="thin"
                  color="rgba(70, 70, 70, 1)"
                >
                  Idade:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.age}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.dependents >= 0 && (
                <Text fontSize="sm" fontWeight="thin">
                  Dependentes:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.dependents}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.maritalStatus && (
                <Text fontSize="sm" fontWeight="thin">
                  Estado Civil:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.maritalStatus}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.activity && (
                <Text fontSize="sm" fontWeight="thin">
                  Atividade Profissional:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.activity}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.monthlyIncome >= 0 && (
                <Text fontSize="sm" fontWeight="thin">
                  Renda Mensal:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatToBRL(meetingInfoFromMI?.monthlyIncome || 0)}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.monthlyExpense >= 0 && (
                <Text fontSize="sm" fontWeight="thin">
                  Gastos Mensais:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatToBRL(meetingInfoFromMI?.monthlyExpense || 0)}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.investPerMonth >= 0 && (
                <Text fontSize="sm" fontWeight="thin">
                  Valor Aporte:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatToBRL(meetingInfoFromMI?.investPerMonth || 0)}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.investorProfile && (
                <Text fontSize="sm" fontWeight="thin">
                  Perfil do Investidor:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatNameToCaptalize(
                      meetingInfoFromMI?.investorProfile || ''
                    )}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.lifeMoment && (
                <Text fontSize="sm" fontWeight="thin">
                  Momento Financeiro:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatNameToCaptalize(meetingInfoFromMI?.lifeMoment || '')}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.ageToRetire && (
                <Text fontSize="sm" fontWeight="thin">
                  Idade de Aposentadoria:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.ageToRetire}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.desiredIncome && (
                <Text fontSize="sm" fontWeight="thin">
                  Renda Desejada:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatToBRL(meetingInfoFromMI?.desiredIncome || 0)}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.incomeType && (
                <Text fontSize="sm" fontWeight="thin">
                  Objetivo:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {formatNameToCaptalize(meetingInfoFromMI?.incomeType || '')}
                  </Text>
                </Text>
              )}

              {meetingInfoFromMI?.created_at && (
                <Text fontSize="sm" fontWeight="thin">
                  Conta criada em:{' '}
                  <Text as="span" fontWeight="bold" color="rgba(70, 70, 70, 1)">
                    {meetingInfoFromMI?.created_at &&
                      formateDateAndTime(
                        meetingInfoFromMI?.created_at as string
                      )}
                  </Text>
                </Text>
              )}
            </Flex>
          </Flex>
          <BaseButtonGroup width="full" mt={4}>
            <SecondaryButton
              width="100%"
              zIndex={5}
              onClick={handleDeclineAction}
            >
              Declínio da oferta
            </SecondaryButton>
            <SecondaryButton
              disabled={actionInProgress}
              width="100%"
              zIndex={5}
              onClick={handleNoAnswerAction}
            >
              Não atendeu
            </SecondaryButton>
            <SecondaryButton
              width="100%"
              zIndex={5}
              onClick={handleCallbackMeetingAction}
            >
              Retornar
            </SecondaryButton>
            <PrimaryButton
              width="100%"
              zIndex={5}
              onClick={handleScheduleMeetingAction}
            >
              Agendar reunião
            </PrimaryButton>
          </BaseButtonGroup>

          <ModalWrapper
            isOpen={showScheduleMeetingModal}
            onClose={() => setShowScheduleMeetingModal(false)}
          >
            <ScheduleMeeting
              updateTotalNumOfLeadsInPipe={() => {}}
              setResponse={() => {}}
              leadType="leadspublic"
              postTasks={'' as any}
              handleShowModal={() => setShowScheduleMeetingModal(false)}
              id={lead.id}
              handleSaveLeadInfos={'' as any}
              handleNextLeadFromPool={leadAction}
            />
          </ModalWrapper>

          <ModalWrapper
            isOpen={isOpenMeetingCallbackModal}
            onClose={() => setIsOpenMeetingCallbackModal(false)}
          >
            <LeadScheduleReturn
              lead={lead as any}
              handleToggleOpenLeadScheduleReturnModal={
                setIsOpenMeetingCallbackModal
              }
              afterOperationCompletedCallbackFunction={() => {
                leadAction('callback');

                handleGetSpecificLeadTypeArr('callback', true);
              }}
            />
          </ModalWrapper>

          <ModalWrapper
            isOpen={showDeclineLeadModal}
            onClose={() => setShowDeclineLeadModal(false)}
          >
            <DeclineOffer
              leadType="publics"
              handleShowModal={() => setShowDeclineLeadModal(false)}
              handleNextLead={() => {}}
              id={lead.id}
              handleNextLeadFromPool={leadAction}
            />
          </ModalWrapper>
        </>
      )}
    </Flex>
  );
};
