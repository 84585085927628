import { ModalAction } from './components/modalAction';
import { ModalActions } from './components/modalActions';
import { ModalCloseIcon } from './components/modalCloseIcon';
import { ModalHeader } from './components/modalHeader';
import { ModalRoot } from './components/modalRoot';
import { ModalTitle } from './components/modalTitle';

export const Modal = {
  Title: ModalTitle,
  Root: ModalRoot,
  Actions: ModalActions,
  Action: ModalAction,
  CloseIcon: ModalCloseIcon,
  Header: ModalHeader,
};
