import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const ClientDetailsSkeletonLoading = (): JSX.Element => {
  return (
    <PageWrapper paddingTop="2rem" backPageButton>
      <SkeletonBase w="100%" h="calc(100vh - 200px)" />
    </PageWrapper>
  );
};
