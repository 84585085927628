import { Flex } from '@chakra-ui/react';
import { ICards } from 'services/hubApi/classes/HubApiProducts';
import { ProductReminderCard } from '../productReminderCard';
import { ReminderCard } from '../reminderCard';

type PropsType = {
  cards: ICards[] | undefined;
};

export const ReminderCards: React.FC<PropsType> = ({ cards }): JSX.Element => {
  return (
    <Flex gap={5} overflowX="auto" className="slimScrollbar" paddingBottom={2}>
      {cards &&
        cards[0].produtos.map((event, index) => (
          <ProductReminderCard
            key={index}
            title={event.productTitle}
            subtitle={event.title}
          />
        ))}

      {cards &&
        cards[0].eventos.map((event, index) => (
          <ReminderCard key={index} event={event} />
        ))}
    </Flex>
  );
};
