import React from 'react';

import { TableBase, TableData, TableRow } from 'components/elements/table';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { formateDateAndTime } from 'utils/formatDate';

interface IShLeadsTableProps {
  publicLeads: LeadDetailsProps[];
  handleSelectedLead: (lead: LeadDetailsProps) => void;
}

export const ShLeadsTable: React.FC<IShLeadsTableProps> = ({
  publicLeads,
  handleSelectedLead,
}) => {
  const checkLeadStatus = (lead: LeadDetailsProps): string => {
    if (lead.meetings[lead.meetings.length - 1]?.type === 'meeting')
      return 'Reunião';
    if (lead.meetings[lead.meetings.length - 1]?.type === 'callback')
      return 'Reunião';
    if (lead.meetings[lead.meetings.length - 1]?.type === '') return 'R.N.A';

    return 'Sem atividade';
  };

  const checkLeadPl = (lead: LeadDetailsProps): string => {
    const leadPl = lead.customFields?.find(
      field => field.fieldName === 'cf_faixa_de_investimentos_certo'
    )?.fieldValue;

    if (leadPl?.includes('R$')) {
      if (leadPl.includes('milhão') || leadPl.includes('milhões')) {
        let formated = `${leadPl.replace(/R\$|mil|mil|h|ã|o|h|õ|es/gi, '')}M`;

        if (formated.includes('500')) {
          formated = formated.replace(/500/gi, '500 K');
        }

        return formated;
      }

      return `${leadPl.replace(/R\$|mil/gi, '')}K`;
    }

    return 'Sem capital';
  };

  const checkLeadFrequency = (lead: LeadDetailsProps): string => {
    if (lead.meetings?.length === 0) {
      if (lead.frequency === '0') {
        return 'HOT LEAD';
      }
      return lead.frequency;
    }

    return '-';
  };

  return (
    <TableBase
      headData={['Nome', 'Status', 'Próx. Contato', 'Frequência', 'PL']}
      width="100%"
      maxH="calc(100vh - 220px)"
      overflowY="auto"
      p="0"
    >
      {publicLeads?.map(lead => (
        <TableRow
          key={lead.id}
          transition="all 0.1s ease-in"
          cursor="pointer"
          _hover={{
            background: 'background.400',
          }}
          onClick={() => handleSelectedLead(lead)}
        >
          <TableData>{lead.name}</TableData>
          <TableData>{checkLeadStatus(lead)}</TableData>
          <TableData>
            {lead.meetings?.length > 0
              ? formateDateAndTime(lead.meetings[0].date).replace(',', ' às')
              : '-'}
          </TableData>
          <TableData>{checkLeadFrequency(lead)}</TableData>
          <TableData>{checkLeadPl(lead)}</TableData>
        </TableRow>
      ))}
    </TableBase>
  );
};
