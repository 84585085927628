import React from 'react';
import { toast } from 'react-toastify';

export function errorHandler(error: any): React.ReactText {
  if (error.response) {
    if (error.response?.data.message !== 'Internal server error') {
      return toast.dark(error.response?.data.message);
    }
  }
  return toast.dark('Ocorreu um erro no servidor. Tente novamente mais tarde.');
}
