import { Flex, Text } from '@chakra-ui/react';
import { Input } from 'components/elements/forms';

interface InformationAndBoxProps {
  label?: string;
  value?: string;
  name?: string;
  isPorcent?: boolean;
  readyOnly?: boolean;
  stringWith?: string;
  handleChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InformationAndBox: React.FC<InformationAndBoxProps> = ({
  label,
  value,
  name,
  handleChangeValue,
  isPorcent,
  readyOnly,
  stringWith,
}) => {
  return (
    <Flex mt="2" align="center" justify="space-between" w="100%">
      <Text
        fontSize="xs"
        maxW="60%"
        lineHeight="1"
        fontWeight="semibold"
        color="gray.800"
      >
        {label}
      </Text>

      <Flex
        background="background.300"
        color="white"
        w="82px"
        borderRadius="6"
        _hover={{ bg: 'background.400' }}
        alignItems="center"
        justifyContent="center"
      >
        {isPorcent && (
          <Flex align="center" justify="space-between">
            <Input
              value={value}
              name={name}
              background="transparent"
              w="10"
              h="7"
              p="0"
              isReadOnly={readyOnly}
              onChange={handleChangeValue}
              fontSize="xs"
              textAlign="center"
            />
            <Text>%</Text>
          </Flex>
        )}
        {!stringWith && !isPorcent && (
          <Input
            value={value}
            background="transparent"
            w="100%"
            h="7"
            p="0"
            isReadOnly={readyOnly}
            name={name}
            onChange={handleChangeValue}
            height="30px"
            textAlign="center"
            fontSize="xs"
          />
        )}

        {stringWith && (
          <Flex align="center" justify="space-between">
            <Input
              value={value}
              name={name}
              background="transparent"
              w="8"
              h="7"
              isReadOnly={readyOnly}
              p="0"
              onChange={handleChangeValue}
              fontSize="xs"
              textAlign="center"
            />
            <Text fontSize="xs">{stringWith}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
