import React from 'react';
import { Box, Text, BoxProps, Stack } from '@chakra-ui/react';

interface CardProductsList extends BoxProps {
  title?: string;
  secondeTitle?: string;
  type?: string;
}

export const CardProductsListBase: React.FC<CardProductsList> = ({
  title,
  children,
  secondeTitle,
  type,
  ...rest
}) => {
  return (
    <Box
      w="100%"
      maxW="880px"
      flexDirection="column"
      borderRadius="2"
      color="white"
      bg="background.600"
      p={5}
      boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
      {...rest}
    >
      <Stack
        flexDir="row"
        spacing={0}
        mb={['10px', '24px']}
        maxH={'800px'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          color="white"
          fontWeight="bold"
          maxW={['60%', '80%']}
          isTruncated
          lineHeight="100%"
          fontSize={['sm', 'lg']}
        >
          {title}
          {type && (
            <Text
              as="span"
              color="gray.700"
              ml={2}
              fontSize={['xs', 'sm']}
              fontStyle="italic"
            >
              ({type})
            </Text>
          )}
        </Text>
        {secondeTitle && (
          <Text
            fontSize={['xs', 'md']}
            borderColor="white"
            border="1px solid"
            p="2px"
            borderRadius="3"
            isTruncated
            maxW={['30%', '30%']}
          >
            {secondeTitle}
          </Text>
        )}
      </Stack>
      {children}
    </Box>
  );
};
