import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { InputWithLabel } from 'components/elements/forms';

import ViaCepApi from 'services/viaCepApi';
import { formatCep } from 'utils/formatCep';
import { BaseModalFormCard } from 'components/elements/cards';
import { SimpleGrid } from '@chakra-ui/react';
import { BasicFieldsProps } from 'pages/Sign/Register/interfaces';
import { locationFieldsSchema } from '../schemas';

interface PersonalFIeldsProps extends BasicFieldsProps {
  handlePrevPage: (progress: number) => void;
}

interface IFormInfoLocatioFields {
  nationality: string;
  city: string;
  uf: string;
  cep: string;
  street: string;
  district: string;
  number: string;
  complement: string;
}

const LocationFields: React.FC<PersonalFIeldsProps> = ({
  step,
  handleNextPage,
  handlePrevPage,
  setMainForm,
  mainForm,
}) => {
  const [formInfos, setFormInfos] = useState<IFormInfoLocatioFields>(
    {} as IFormInfoLocatioFields
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    const validateForm = await locationFieldsSchema
      .validate(formInfos)
      .catch(err => {
        err.errors.map((error: string) => toast.dark(error));
      });
    setIsLoading(false);

    if (validateForm) {
      setMainForm({
        ...mainForm,
        national: formInfos.nationality,
        address: {
          cep: formInfos.cep,
          street: formInfos.street,
          district: formInfos.district,
          number: formInfos.number,
          city: formInfos.city,
          uf: formInfos.uf,
          complement: formInfos.complement,
        },
      });
      handleNextPage(75);
      setIsLoading(false);
    }
  };

  async function onBlurCep(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setStateFormInfo: React.Dispatch<
      React.SetStateAction<IFormInfoLocatioFields>
    >
  ) {
    const cep = formatCep(e.target.value);

    if (!cep) return;

    const response = await ViaCepApi.get(cep);

    if (!response) {
      toast.dark('Erro ao pesquisar CEP.');
      return;
    }

    setStateFormInfo({
      ...formInfos,
      cep,
      city: response.city,
      street: response.street,
      district: response.neighborhood,
      uf: response.uf,
    });
  }

  return (
    <BaseModalFormCard
      isModal={false}
      title={`Passo ${step} - Localidade`}
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      primaryButtonText="Próximo"
      secondaryButtonText="Anterior"
      handleToggleModal={() => handlePrevPage(25)}
    >
      <SimpleGrid columns={[1, 1, 2]} spacing={6}>
        <InputWithLabel
          label="CEP"
          placeholder="CEP"
          name="cep"
          value={formInfos.cep}
          onChange={handleChangeValue}
          onBlur={e => onBlurCep(e, setFormInfos)}
        />

        <InputWithLabel
          label="Complemento"
          placeholder="Complemento"
          name="complement"
          value={formInfos.complement}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Rua"
          placeholder="Rua"
          name="street"
          value={formInfos.street}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Número"
          placeholder="Número"
          name="number"
          value={formInfos.number}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Cidade"
          placeholder="Cidade"
          name="city"
          value={formInfos.city}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="UF"
          placeholder="UF"
          name="uf"
          value={formInfos.uf}
          onChange={handleChangeValue}
        />

        <InputWithLabel
          label="Bairro"
          placeholder="Bairro"
          name="district"
          value={formInfos.district}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Nacionalidade"
          placeholder="Nacionalidade"
          name="nationality"
          value={formInfos.nationality}
          onChange={handleChangeValue}
        />
      </SimpleGrid>
    </BaseModalFormCard>
  );
};

export default LocationFields;
