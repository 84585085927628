import React from 'react';

import { KanbanColumnProps } from 'shared/interfaces';
import { KanbanColumnBase } from 'components/modules/kanbanBases';

import Task from 'pages/Technology/components/modules/technologyCRMComponent/Task';

interface ColumnProps {
  columnId: string;
  column: KanbanColumnProps;
}

const Column: React.FC<ColumnProps> = ({ column, columnId }) => {
  return (
    <KanbanColumnBase column={column} columnId={columnId}>
      {column.tasks.map((task, index) => (
        <Task key={task.id} index={index} task={task} />
      ))}
    </KanbanColumnBase>
  );
};

export default Column;
