import { Flex, Text } from '@chakra-ui/react';

interface FilterOffersSwitchButtonProps {
  witchTypeOfferIsActive: string;
  handleChangeTypeOfOffers: (e: string) => void;
}
export const FilterOffersSwitchButton: React.FC<FilterOffersSwitchButtonProps> =
  ({ handleChangeTypeOfOffers, witchTypeOfferIsActive }) => {
    return (
      <Flex>
        <Text
          as="span"
          px={6}
          background={
            witchTypeOfferIsActive === 'valid'
              ? 'background.100'
              : 'background.500'
          }
          color={witchTypeOfferIsActive === 'invalid' ? 'gray' : ' white'}
          borderTopLeftRadius="10px"
          borderBottomLeftRadius="10px"
          fontSize="sm"
          onClick={() => handleChangeTypeOfOffers('valid')}
          cursor="pointer"
        >
          Ofertas ativas
        </Text>

        <Text
          as="span"
          px={6}
          background={
            witchTypeOfferIsActive === 'invalid'
              ? 'background.100'
              : 'background.500'
          }
          color={witchTypeOfferIsActive === 'invalid' ? 'white' : 'gray'}
          borderTopRightRadius="10px"
          borderBottomRightRadius="10px"
          fontSize="sm"
          onClick={() => handleChangeTypeOfOffers('invalid')}
          cursor="pointer"
        >
          Ofertas vencidas
        </Text>
      </Flex>
    );
  };
