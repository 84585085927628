import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useAuth } from 'hooksV2/useAuth';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'O campo email é obrigatório' })
    .email({ message: 'O campo email deve conter um email válido' }),
});

type FormFieldsType = z.infer<typeof schema>;

type PropsType = {
  handleDeleteIsOpenRecoverPasswordModalFilterValue: () => void;
};

export const SendRecoverPasswordEmailLogin: React.FC<PropsType> = ({
  handleDeleteIsOpenRecoverPasswordModalFilterValue,
}): JSX.Element => {
  const { sendRecoverPasswordEmailMutation } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleSendRecoverPasswordEmail: SubmitHandler<FormFieldsType> =
    async data => {
      await sendRecoverPasswordEmailMutation.mutateAsync(data.email);

      handleDeleteIsOpenRecoverPasswordModalFilterValue();
    };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Recuperar senha" />
        <Modal.CloseIcon
          onClick={handleDeleteIsOpenRecoverPasswordModalFilterValue}
        />
      </Modal.Header>

      <Input.Root>
        <Input.TextField placeholder="Email" {...register('email')} />
        <Input.ErrorMessage message={errors.email?.message} />
      </Input.Root>

      <Modal.Actions>
        <Modal.Action
          text="Enviar email"
          isLoading={sendRecoverPasswordEmailMutation.isLoading}
          onClick={handleSubmit(handleSendRecoverPasswordEmail)}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
