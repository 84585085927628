import { colors } from 'sharedV2/colors';
import styled, { css } from 'styled-components';
import { WrapperBackgroundTransparent } from '../wrappers/backgroundTransparent/styles';

type PropsType = {
  w?: string;
  justifyContent?: 'space-between' | 'center';
  alignItems?: 'center';
  flexDirection?: 'row' | 'column';
  gap?: number;
  h?: string;
  cursor?: 'pointer';
  paddingY?: string;
  position?: string;
  borderColor?: string;
};

export const BoxGlass = styled(WrapperBackgroundTransparent)<PropsType>`
  ${({
    w,
    h,
    justifyContent,
    alignItems,
    flexDirection,
    gap,
    cursor,
    paddingY,
    position,
    borderColor = colors.GRAY,
  }) => css`
    display: flex;
    height: ${h || '100%'};
    gap: ${(gap || 0) * 0.25}rem;
    justify-content: ${justifyContent || 'space-between'};
    flex-direction: ${flexDirection || 'row'};
    align-items: ${alignItems || 'center'};
    border: 1px solid ${borderColor};
    padding-top: ${paddingY || '0.25rem'};
    padding-bottom: ${paddingY || '0.25rem'};
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 5px;
    width: ${w || '100%'};
    cursor: ${cursor};
    position: ${position};
  `}
`;
