/* eslint @typescript-eslint/no-explicit-any: off */
import React from 'react';

import { SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { CardInfo } from 'components/elements/others/CardInfo';

interface ComponentProps {
  order: any;
  handleToggleModal: () => void;
}

export const OrderInfosRh: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  return (
    <DefaultModalContentWrapper maxW={['95vw', '490px']}>
      <ModalTitle>Dados da Ordem</ModalTitle>
      <CardInfo title="Solicitações / Dúvidas" info={order.information} />
      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Fechar</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
