import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useAuth } from 'hooksV2/useAuth';
import { useNavigation } from 'hooksV2/useNavigation';
import { useToken } from 'hooksV2/useToken';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { showToast } from 'utilsV2/showToast';
import { z } from 'zod';

const schema = z
  .object({
    password: z.string().min(1, { message: 'O campo senha é obrigatório' }),
    confirmPassword: z
      .string()
      .min(1, { message: 'O campo confirmar senha é obrigatório' }),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['confirmPassword'],
        message: 'As senhas devem ser iguais',
      });
    }
  });

type FormFieldsType = z.infer<typeof schema>;

export const ResetPassword = (): JSX.Element => {
  const { token: tokenId } = useParams<{ token: string }>();

  const { redirect } = useNavigation();

  const { isTokenValid } = useToken();

  const { resetPasswordMutation } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const checkIsTokenValid = async () => {
    const isValid = await isTokenValid(tokenId);

    if (!isValid) {
      showToast('Esse link expirou. Solicite um novo!');
      redirect('/v2/login');
    }
  };

  const handleResetPassword: SubmitHandler<FormFieldsType> = async data => {
    await resetPasswordMutation.mutateAsync({
      tokenId,
      newPassword: data.password,
      confirmNewPassword: data.confirmPassword,
    });
  };

  useEffect(() => {
    checkIsTokenValid();
  }, [tokenId]);

  return (
    <PageWrapper heigth="100vh" justifyContent="center" alignItems="center">
      <Box.Glass w="650px" paddingY="1rem">
        <Flex flexDirection="column" gap={5} w="100%">
          <Text color="white" fontSize="xl">
            Redefinição de Senha
          </Text>

          <Input.Root>
            <Input.Label text="Nova senha" />
            <Input.TextField
              placeholder="Nova senha"
              type="password"
              {...register('password')}
            />
            <Input.ErrorMessage message={errors.password?.message} />
          </Input.Root>

          <Input.Root>
            <Input.Label text="Confirme a nova senha" />
            <Input.TextField
              placeholder="Confirme a nova senha"
              type="password"
              {...register('confirmPassword')}
            />
            <Input.ErrorMessage message={errors.confirmPassword?.message} />
          </Input.Root>

          <Flex w="100%" justifyContent="flex-end">
            <Button.Primary
              onClick={handleSubmit(handleResetPassword)}
              isLoading={resetPasswordMutation.isLoading}
            >
              Redefinir
            </Button.Primary>
          </Flex>
        </Flex>
      </Box.Glass>
    </PageWrapper>
  );
};
