import React from 'react';
import { KanbanColumnBase } from 'components/modules/kanbanBases';

import { KanbanColumnProps } from 'shared/interfaces';
import Task from '../Task';

interface ComponentProps {
  columnId: string;
  column: KanbanColumnProps;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
}

const Column: React.FC<ComponentProps> = ({ column, columnId, setColumns }) => {
  const isSegmentImob = column.tasks.filter(
    task => task.segment === 'imobiliario'
  );

  return (
    <KanbanColumnBase
      column={column}
      columnId={columnId}
      countCards={column.tasks.length}
      isSegmentImob={isSegmentImob}
    >
      {column.tasks.map((task, index) => (
        <Task
          key={task.id}
          index={index}
          task={task}
          setColumns={setColumns}
          column={column}
        />
      ))}
    </KanbanColumnBase>
  );
};

export default Column;
