import React from 'react';

import { Checkbox } from '@chakra-ui/react';
import { TableData, TableRow } from 'components/elements/table';
import { OrdersStructuredProps } from 'pages/RV/subpages/RequestedStructuredOrders/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface OrderTableRowProps {
  rowData: OrdersStructuredProps;

  handleToggleCheckbox: (order: OrdersStructuredProps) => void;
}

export const OrderTableRow: React.FC<OrderTableRowProps> = ({
  rowData,
  handleToggleCheckbox,
}) => {
  return (
    <TableRow>
      <TableData>
        <Checkbox
          isChecked={rowData.isChecked}
          onChange={() => handleToggleCheckbox(rowData)}
        />
      </TableData>

      <TableData>{rowData.clientBtgAccount}</TableData>
      <TableData>{rowData.orderType}</TableData>
      <TableData>{rowData.strategy.ticker}</TableData>
      <TableData>{rowData.strategy.fee}</TableData>
      <TableData>{rowData.strategy.referencePrice}</TableData>
      <TableData>{formatNameToCaptalize(rowData.assessorName)}</TableData>
      <TableData>{formatNameToCaptalize(rowData.status)}</TableData>
    </TableRow>
  );
};
