import InputCurrencyField, {
  CurrencyInputProps,
} from 'react-currency-input-field';

export const CurrencyInput: React.FC<CurrencyInputProps> = (
  rest
): JSX.Element => {
  return (
    <InputCurrencyField
      style={{
        backgroundColor: 'white',
        width: '100%',
        height: '32px',
        borderColor: '#1d63ddb2',
        borderWidth: '1px',
        borderRadius: '6px',
        paddingLeft: '15px',
      }}
      groupSeparator="."
      decimalSeparator=","
      allowNegativeValue={false}
      prefix="R$"
      decimalScale={2}
      {...rest}
    />
  );
};
