/* eslint no-unused-vars: off */
/* eslint no-shadow: off */
import React from 'react';
import partyIcon from 'shared/assets/images/assignments/party-icon.svg';

import { AssignmentProps } from 'pages/Home/interfaces';
import { TaskTypes } from 'pages/Home/interfaces/assignments/Assignment';
import { NoTelegramCard } from 'pages/Home/components/elements/cards/assignments/NoTelegramCard';
import { MeetingTodayCard } from 'pages/Home/components/elements/cards/assignments/MeetingTodayCard';
import {
  BirthdayCard,
  ManagerContactCard,
  NegativeClientCard,
  NewClientCard,
  NpsCard,
  OriginPendencyCard,
  SharedContentCard,
} from '..';
import { InativeCard } from '../InativeCard';

import { LossPointsMeeting } from '../LossPointsMeeting';

import { PeriodicMeeting } from '../PeriodicMeeting';
import { PreNps } from '../PreNps';
import { LeftClients } from '../LeftClients';
import { PersonalizedMeeting } from '../PersonalizedMeeting';
import { StuckMoneyCard } from '../StuckMoneyCard';
import { NoLeadCard } from '../NoLeadCard';
import { NoFeedbackPenaltyCard } from '../NoFeedbackPenaltyCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const AssignmentsCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  return (
    <>
      {assignment.typetask === TaskTypes.sharedContent && (
        <SharedContentCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}
      {assignment.typetask === TaskTypes.preNps && (
        <PreNps
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}
      {assignment.typetask === TaskTypes.left && (
        <LeftClients
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.nps && (
        <NpsCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.periodicMeeting && (
        <PeriodicMeeting
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.inative && (
        <InativeCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.birthday && (
        <BirthdayCard
          iconSrc={partyIcon}
          assignment={assignment}
          isCalendarContact={isCalendarContact}
          handleRemoveItem={handleRemoveItem}
        />
      )}

      {assignment.typetask === TaskTypes.noTelegram && (
        <NoTelegramCard
          assignment={assignment}
          isCalendarContact={isCalendarContact}
          handleRemoveItem={handleRemoveItem}
        />
      )}

      {assignment.typetask === TaskTypes.personalizedMeeting && (
        <PersonalizedMeeting
          assignment={assignment}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.balance && (
        <NegativeClientCard
          assignment={assignment}
          isCalendarContact={isCalendarContact}
          handleRemoveItem={handleRemoveItem}
        />
      )}
      {assignment.typetask === TaskTypes.client && (
        <NewClientCard
          assignment={assignment}
          isCalendarContact={isCalendarContact}
          handleRemoveItem={handleRemoveItem}
        />
      )}
      {assignment.typetask === TaskTypes.origin && (
        <OriginPendencyCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.managerContact && (
        <ManagerContactCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.meetingNotConfirm && (
        <LossPointsMeeting
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.meetingToday && (
        <MeetingTodayCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          isCalendarContact={isCalendarContact}
        />
      )}

      {assignment.typetask === TaskTypes.stuckMoney && (
        <StuckMoneyCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
        />
      )}

      {assignment.typetask === TaskTypes.noLeads && (
        <NoLeadCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
        />
      )}

      {assignment.typetask === TaskTypes.noFeedbackPenalty && (
        <NoFeedbackPenaltyCard
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
        />
      )}
    </>
  );
};
