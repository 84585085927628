import styled from 'styled-components';

interface IRowIndex {
  rowIndex: number;
}

export const Container = styled.div`
  width: 100%;
  padding: 0;

  .order-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;

    div {
      margin-right: 8px;
    }

    .order-inputs {
      width: 100%;
      display: flex;
      flex-direction: row;

      label {
        display: ${({ rowIndex }: IRowIndex) => rowIndex !== 0 && 'none'};
      }
    }

    .order-disabled {
      margin-right: 76px;

      label:first-child {
        opacity: 100%;
      }
    }

    .radio-input-container {
      width: 100%;
      max-width: 70px;
      display: flex;
      flex-direction: column;
      font-size: ${({ theme }) => theme.sizes.smaller};
      color: ${({ theme }) => theme.colors.white200};

      label {
        margin-bottom: 2px;
      }

      label:first-child:nth-last-child(2) {
        display: none;
      }
      .form-check-label {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.white200};
      }

      input {
        height: 55%;
      }
    }
  }

  .delete-order-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    font-size: ${({ theme }) => theme.sizes.smaller};
    cursor: pointer;

    .delete-order-content {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.white200};
      margin: 0;

      span {
        margin-left: 2px;
      }
    }

    @media screen and (max-width: 820px) {
      margin-top: 6px;
    }
  }
`;
