import { Box, Flex, Stack, Switch, Text } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { EmployeeModalCard } from 'pages/Contents/components';
import { useRef } from 'react';

interface BoxSelectionOfParticipantsProps {
  employeesList: any[];
  filteredEmployeesList: any[];
  selectedEmployees: string[];
  selectAllUsers: boolean;
  setSelectAllUsers: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEmployees: React.Dispatch<React.SetStateAction<string[]>>;
  setFilteredEmployeesList: React.Dispatch<React.SetStateAction<any[]>>;
}

export const BoxSelectionOfParticipants: React.FC<BoxSelectionOfParticipantsProps> =
  ({
    employeesList,
    filteredEmployeesList,
    selectedEmployees,
    selectAllUsers,
    setSelectAllUsers,
    setSelectedEmployees,
    setFilteredEmployeesList,
  }) => {
    const searchClientsParameters = useRef('');
    const handleSearchEmployee = () => {
      const foundEmployees = employeesList.filter(employee =>
        employee.name
          .toLowerCase()
          .includes(searchClientsParameters.current.toLowerCase())
      );

      setFilteredEmployeesList(foundEmployees);
    };

    const handleAddOrRemoveAllEmployees = () => {
      if (selectAllUsers) {
        setSelectAllUsers(!selectAllUsers);
        setSelectedEmployees([]);
      }

      if (!selectAllUsers) {
        setSelectAllUsers(!selectAllUsers);
        const employeesId = employeesList.map(item => item.id);
        setSelectedEmployees(employeesId);
      }
    };
    const handleBackToAllClients = () => {
      setFilteredEmployeesList(employeesList);
    };

    const handleSelectEmployee = (employeeId: string) => {
      if (employeesList.length === selectedEmployees.length + 1) {
        setSelectAllUsers(true);
      }
      setSelectedEmployees([...selectedEmployees, employeeId]);
    };

    const handleUnselectEmployee = (employeeId: string) => {
      if (employeesList.length !== selectedEmployees.length - 1) {
        setSelectAllUsers(false);
      }

      const listWithoutUnselectClient = selectedEmployees.filter(
        id => id !== employeeId
      );
      setSelectedEmployees(listWithoutUnselectClient);
    };
    return (
      <>
        <Flex width="50%" height="100%">
          <Flex justify="space-between" width="100%" height="100%">
            <Box w="100%">
              <Flex w="100%" align="center" justify="space-between">
                <Box w="100%">
                  <InputWithLabel
                    label="Participantes"
                    placeholder="Digite o nome do colaborador ou selecione abaixo"
                    name="name"
                    autoComplete="off"
                    onChange={e => {
                      searchClientsParameters.current = e.target.value;
                    }}
                  />
                </Box>
                <SecondaryButton mt="6" ml="2" onClick={handleSearchEmployee}>
                  Pesquisar
                </SecondaryButton>
              </Flex>

              {filteredEmployeesList.length !== employeesList.length && (
                <Text
                  textDecoration="underline"
                  mt="1"
                  mb="1"
                  ml="1"
                  fontSize="xs"
                  _hover={{ color: 'white' }}
                  cursor="pointer"
                  textAlign="right"
                  w="fit-content"
                  onClick={handleBackToAllClients}
                >
                  Voltar para todos
                </Text>
              )}

              <DefaultCardWrapper
                h="100%"
                maxH="405px"
                overflow="auto"
                mt={
                  filteredEmployeesList.length !== employeesList.length
                    ? '0'
                    : '6'
                }
                p="2"
                bg="#f2f2f2"
                flexDir="column"
              >
                <Flex px="2" align="center" gridGap={2} fontSize="sm">
                  <Text>Marcar Todos</Text>
                  <Switch
                    size="sm"
                    mt="2"
                    id="email-alerts"
                    isChecked={selectAllUsers}
                    onChange={() => {
                      handleAddOrRemoveAllEmployees();
                    }}
                  />
                </Flex>

                <Stack p="1">
                  {filteredEmployeesList.length > 0 &&
                    filteredEmployeesList
                      .sort(item =>
                        JSON.stringify(
                          Object.values(selectedEmployees)
                        ).includes(item.id)
                          ? -1
                          : 1
                      )
                      .map(item => (
                        <EmployeeModalCard
                          employee={item}
                          selectedEmployees={selectedEmployees}
                          handleSelectEmployee={handleSelectEmployee}
                          handleUnselectEmployee={handleUnselectEmployee}
                          eventSection
                        />
                      ))}

                  {filteredEmployeesList.length === 0 && (
                    <Text textAlign="center" fontStyle="italic">
                      Sem resultados
                    </Text>
                  )}
                </Stack>
              </DefaultCardWrapper>
            </Box>
          </Flex>
        </Flex>
      </>
    );
  };
