export const initialFileValue = {
  name: 'Clique para escolher arquivo',
};

export const captationTableHead = [
  'Conta',
  'Nome',
  'Mercado',
  'Descrição',
  'Ativo',
  'Quantidade',
  'Captação',
  'Data',
  'Tipo',
  'Assessor',
  'Escritório',
];
