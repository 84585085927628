import { KanbanBoardBase } from 'components/modules/kanbanBases';
import React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { KanbanBoardProps, KanbanColumnProps } from 'shared/interfaces';
import { MainPageTitle } from 'components/elements/texts';

import { sortOrderListByNextDate } from 'pages/IP/utils';
import Column from '../Column';

const Board: React.FC<KanbanBoardProps> = ({
  boardTitle,
  boardColumns,
  setBoardColumns,
}) => {
  const handleOnDragEnd = async (
    result: DropResult,
    columns: KanbanColumnProps[],
    setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>
  ) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const column = columns[Number(source.droppableId)];
      const copiedItems = [...column.tasks];

      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      const isSegmentImob =
        column.tasks.length > 0 &&
        column.tasks.find(task => task.segment === 'imobiliario');

      const newColumn = {
        ...columns,
        [source.droppableId]: {
          ...column,
          tasks: isSegmentImob
            ? sortOrderListByNextDate(copiedItems)
            : copiedItems,
        },
      };

      setColumns(newColumn);
    }

    if (source.droppableId !== destination.droppableId) {
      try {
        const sourceColumn = columns[Number(source.droppableId)];
        const destinationColumn = columns[Number(destination.droppableId)];

        const sourceItems = [...sourceColumn.tasks];
        const destinationItems = [...destinationColumn.tasks];

        const [removed] = sourceItems.splice(source.index, 1);

        removed.status = columns[Number(destination.droppableId)].status;

        destinationItems.splice(destination.index, 0, removed);

        const isSegmentImob =
          sourceItems.length &&
          sourceItems.find(task => task.segment === 'imobiliario') &&
          destinationItems.length &&
          destinationItems.find(task => task.segment === 'imobiliario');

        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            tasks: sourceItems,
          },
          [destination.droppableId]: {
            ...destinationColumn,
            tasks: isSegmentImob
              ? sortOrderListByNextDate(destinationItems)
              : destinationItems,
          },
        });

        const statusData = {
          status: columns[Number(destination.droppableId)].status,
          boleted: false,
        };

        if (statusData.status === 'boleted') {
          await hubApiIp.updateOrderStatus(result.draggableId, {
            ...statusData,
            boleted: true,
          });
        } else {
          await hubApiIp.updateOrderStatus(result.draggableId, statusData);
        }
      } catch (err) {
        toast.dark('Ocorreu um erro ao tentar atualizar status da ordem.');
      }
    }
  };

  return (
    <>
      <MainPageTitle textAlign="center">{boardTitle}</MainPageTitle>

      <KanbanBoardBase
        handleOnDragEnd={handleOnDragEnd}
        columns={boardColumns}
        setColumns={setBoardColumns}
      >
        {Object.entries(boardColumns).map(([columnId, column]) => (
          <Column
            key={columnId}
            columnId={columnId}
            column={column}
            setColumns={setBoardColumns}
          />
        ))}
      </KanbanBoardBase>
    </>
  );
};

export default Board;
