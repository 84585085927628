import styled from 'styled-components';

const Icon = styled.img`
  width: ${props => (props.width ? props.width : 'initial')};
`;

interface IMoneyBagIconProps {
  width?: string;
}

export const DescriptionIcon: React.FC<IMoneyBagIconProps> = ({ width }) => {
  return (
    <Icon
      width={width}
      alt="description-icon"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAACbklEQVR4nO3aMU9UQRTF8TO7AjEI0S1MWB6FBQnQSGvsaTWho9OvYAwWJFDSySeAigYiIXQWttZiBw0NaCUE7CgYCxcDjxCS9W7O5c75VVuQeZP575vsZABERERERERESpIsB3t1lMdSwkcAMwCGLMfu1sH63tru+4m37HncpmE1UGfxvwGYhZPFB4Dz0/M3kwvfl9nzuI1ZgM43v2U1npXOKz7vNYJZAPzddvzJyXUEywButp2rEuA6gmUAtzxHKCIA4DdC+ACp/tlZhPABgOQ6QgEBAM8RwgdI+d8nlxHCBwB8RygiAOA3QvgAV5fbY4TwAQDfEYoIAPiNED7A9QX2FyF8AGTfEcIHaPQ3TSJMLewu9WR+vRjUk4fVIID03xEy0mIvIoQP8OTlUzQHmvAaIXyAvsf9aM89w6PxYSSD7SgjLU6v7K9azc/svyJe/8j57r+KY7udTNYu/BvgnQKQKQCZApApAJkCkCkAmQKQKQCZApApANkD1oO/rn/CydFP1uOvaVUjeDE3S3k27Q3wsvgAcHzIm4u2IDJagFY1wnr0Da2qTXu27gO6pPuAIBSATAHIijwHMH/31xV5DmD+7q/TFkRW5DmA+bu/TueALukcEIQCkCkAWZHngDrdB5DpPqBgRZ4D6nQfcA/pHBCEApApAFmR5wDdB0D3AZe0BZEVeQ7QfUAAOgcEoQBkCkCmAGQKQKYAZApApgBkCkBmGeC34VjenVoNZBngi+FYvmV8thrKLMBFxgcAJ1bjOfarkfHOajCzADujaS9nPAewCeDMalxHzpCx0bjA9FaVDtmTEREREREREbmf/gC/aMXZfG5gmQAAAABJRU5ErkJggg=="
    />
  );
};
