import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { InformationContent } from './styles';

interface ComponentProps {
  information: string;
}

export const Tooltip: React.FC<ComponentProps> = ({ information }) => {
  return (
    <InformationContent>
      <div className="info-container">
        <button type="button" className="dropbtn">
          <AiOutlineInfoCircle size={16} />
        </button>
        <div className="dropdown-content">
          <p>{information}</p>
        </div>
      </div>
    </InformationContent>
  );
};
