import { Flex, Text, FlexProps } from '@chakra-ui/react';

interface TitleInVerticalProps extends FlexProps {
  text: string;
  porcent: string;
}

export const TitleInVertical: React.FC<TitleInVerticalProps> = ({
  text,
  porcent,
  ...rest
}) => {
  return (
    <Flex align="center" justify="center" w="20px" ml="-7">
      <Flex
        minW="150px"
        align="center"
        justify="center"
        transform="rotate(-90deg)"
        borderBottom="1px solid gray"
        {...rest}
      >
        <Text color="white" fontSize="xs" whiteSpace="nowrap">
          {text}{' '}
          <Text
            as="span"
            ml={1}
            fontSize="xs"
            fontWeight="bold"
            color="gray.700"
          >
            {porcent}%
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
