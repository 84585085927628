import { Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IHandleMibsOperation } from '../dtos';
import { transformMibsIntoReais } from '../utils/TransformMibsIntoReais';
import { SensitiveTransactionModal } from './SensitiveTransactionModal';

interface IProps {
  setIsOpenBuyMIBsModal(value: boolean): void;
  handleMibsOperation: IHandleMibsOperation;
}

export const BuyMIBsModal: React.FC<IProps> = ({
  setIsOpenBuyMIBsModal,
  handleMibsOperation,
}) => {
  const [mibsQuantity, setMibsQuantity] = useState<string>('');
  const [mibsInReal, setMibsInReal] = useState<string>('');
  const [isOpenConfirmTransactionModal, setIsOpenConfirmTransactionModal] =
    useState<boolean>(false);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (mibsQuantity === '' || Number.isNaN(mibsQuantity)) {
      return toast.dark('Insira uma quantidade de MIBs para comprar.');
    }

    setIsOpenConfirmTransactionModal(true);
  };

  useEffect(() => {
    const response = transformMibsIntoReais(Number(mibsQuantity));
    setMibsInReal(response);
  }, [mibsQuantity]);

  return (
    <>
      <BaseModalFormCard
        title="Comprar MIBs"
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={() => setIsOpenBuyMIBsModal(false)}
        isModal
        primaryButtonText="Comprar"
        isLoading={handleMibsOperation.isLoading}
      >
        <InputWithLabel
          label="Quantidade de MIBs"
          placeholder="100"
          value={mibsQuantity}
          onChange={e => setMibsQuantity(e.target.value)}
        />
        <Text mb="6px" fontSize="sm" color="#FFF" mt={2}>
          R$ {mibsInReal}
        </Text>
      </BaseModalFormCard>
      <ModalWrapper
        isOpen={isOpenConfirmTransactionModal}
        onClose={() =>
          setIsOpenConfirmTransactionModal(!isOpenConfirmTransactionModal)
        }
      >
        <SensitiveTransactionModal
          setIsOpenFatherModal={setIsOpenBuyMIBsModal}
          setIsOpenConfirmTransactionModal={setIsOpenConfirmTransactionModal}
          handleFormSumit={handleMibsOperation}
          mibsQuantity={mibsQuantity}
          operation="DEPOSIT"
          sourceAction="MIBS_PURCHASED"
          sourceWallet="MI"
          destinationWallet="personalWallet"
        />
      </ModalWrapper>
    </>
  );
};
