/* eslint no-unused-vars: off */
import React from 'react';
import { ButtonGroup, ModalContentProps, Text } from '@chakra-ui/react';

import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ComponentProps extends ModalContentProps {
  title: string;
  isLoading: boolean;
  collaborators: { name: string; id: string }[];
  handleToggleModal?: () => void;
  handleToggleCancelOrderModal?: () => void;
  handleSelectCollaborator: (collaboratorId: string) => void;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const TransferOrderInfosCard: React.FC<ComponentProps> = ({
  children,
  title,
  isLoading,
  collaborators,
  handleFormSubmit,
  handleToggleModal,
  handleSelectCollaborator,
  handleToggleCancelOrderModal,
  ...rest
}) => {
  return (
    <DefaultModalContentWrapper {...rest}>
      <Text
        color="rgba(70, 70, 70, 1)"
        fontSize={{ base: 'md', lg: 'lg' }}
        mb="4"
        fontWeight="semibold"
      >
        {title}
      </Text>

      {children}

      <Dropdown
        mt="5"
        placeholder="Selecione um colaborador"
        onChange={event => handleSelectCollaborator(event.target.value)}
      >
        {collaborators.map((collaborator, index: number) => (
          <option key={index} value={collaborator.id}>
            {formatNameToCaptalize(collaborator.name)}
          </option>
        ))}
      </Dropdown>

      <form onSubmit={handleFormSubmit}>
        <ButtonGroup width="100%" mt="5" justifyContent="flex-end" spacing="2">
          <SecondaryButton size="sm" type="button" onClick={handleToggleModal}>
            Fechar
          </SecondaryButton>
          <SecondaryButton
            size="sm"
            type="button"
            onClick={handleToggleCancelOrderModal}
          >
            Deletar ordem
          </SecondaryButton>

          <PrimaryButton size="sm" isLoading={isLoading} type="submit">
            Transferir ordem
          </PrimaryButton>
        </ButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};
