import React from 'react';
import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { BiDonateHeart } from 'react-icons/bi';
import { RiHealthBookLine } from 'react-icons/ri';
import { AiOutlineSafety } from 'react-icons/ai';
import 'react-quill/dist/quill.snow.css';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { BaseButtonGroup } from 'components/elements/wrappers';

import ReactQuill from 'react-quill';
import { CommentedAreasButton } from './CommentedAreasButton';

interface MeetingDisclaimerSectionProps {
  setCommentedAreas: React.Dispatch<React.SetStateAction<string[]>>;
  commentedAreas: string[];
  setMeetingDisclaimer: React.Dispatch<React.SetStateAction<string>>;
  meetingDisclaimer: string;
  closeMeetingSection: () => void;
  handleSaveDisclaimerAndInfos: () => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onlyVizMode: boolean;
}

export const MeetingDisclaimerSection: React.FC<MeetingDisclaimerSectionProps> =
  ({
    setCommentedAreas,
    commentedAreas,
    setMeetingDisclaimer,
    meetingDisclaimer,
    closeMeetingSection,
    handleSaveDisclaimerAndInfos,
    setShowModal,
    onlyVizMode,
  }) => {
    const [isMobile] = useMediaQuery('(max-width: 30em)');
    const modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
      ],
    };

    return (
      <>
        <Flex
          w={['98%', '100%']}
          flexDir="column"
          justifyContent="space-between"
        >
          <Flex flexDir="column" w="100%" h="100%" gridGap={4}>
            <InfoTitle title="Disclaimer da reunião" />
            <Flex
              alignItems="center"
              overflowX={['auto', 'unset']}
              className="boxClients"
            >
              <Text mr={2} fontSize="sm">
                Áreas comentadas:{' '}
              </Text>

              <Flex>
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Vida"
                  Icon={BiDonateHeart}
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Renda Variável"
                  altIcon="RV"
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Renda Fixa"
                  altIcon="RF"
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Avenue"
                  altIcon="AV"
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Saúde"
                  Icon={RiHealthBookLine}
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Mundo Invest"
                  altIcon="MI"
                />
                <CommentedAreasButton
                  setCommentedAreas={setCommentedAreas}
                  commentedAreas={commentedAreas}
                  title="Seguros Alternativos"
                  Icon={AiOutlineSafety}
                />
              </Flex>
            </Flex>

            <Flex>
              <ReactQuill
                modules={modules}
                theme="snow"
                placeholder=""
                onChange={setMeetingDisclaimer}
                value={meetingDisclaimer || ''}
                className="myClientsPanelMeetingDisclaimer"
              />
            </Flex>
          </Flex>

          <Flex mt={5} w="100%" flexDir="column" gridGap={4}>
            <BaseButtonGroup gridGap={[2, 0]} mt={['80px', 'auto']}>
              <SecondaryButton onClick={closeMeetingSection}>
                Voltar
              </SecondaryButton>
              {!onlyVizMode && (
                <>
                  <SecondaryButton
                    onClick={handleSaveDisclaimerAndInfos}
                    style={{ margin: isMobile ? 0 : '' }}
                  >
                    Salvar informações
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => setShowModal(true)}
                    style={{ margin: isMobile ? 0 : '' }}
                  >
                    Concluir tarefa
                  </PrimaryButton>
                </>
              )}
            </BaseButtonGroup>
          </Flex>
        </Flex>
      </>
    );
  };
