export const PreNPSTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.375 5.383v0c0 .475.237.871.633 1.188l4.75 3.246a3.236 3.236 0 003.563 0l4.75-3.167c.316-.396.554-.792.554-1.267v0A1.42 1.42 0 0015.2 3.958H3.8a1.42 1.42 0 00-1.425 1.425z"
        stroke="#A342ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 5.542v7.916c0 .871.712 1.584 1.583 1.584h11.084c.87 0 1.583-.713 1.583-1.584V5.542M2.84 14.576l4.825-4.824M11.368 9.785l4.792 4.791"
        stroke="#A342ED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
