import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonGroup, Stack } from '@chakra-ui/react';

import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';

import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';

interface InsertMaterialFormProps {
  handleCloseModal: () => void;
  signalToUpdate: () => void;
}

export const InsertMaterialForm: React.FC<InsertMaterialFormProps> = ({
  handleCloseModal,
  signalToUpdate,
}) => {
  const [materialCategory, setMaterialCategory] = useState('');
  const [materialTitle, setMaterialTitle] = useState('');
  const [materialLink, setMaterialLink] = useState('');

  const saveNewMaterial = async () => {
    const newMaterialData = {
      title: materialTitle,
      link: materialLink,
      type: materialCategory,
    };

    try {
      await hubApiMarketing.postNewMaterial(newMaterialData);

      signalToUpdate();
      handleCloseModal();
      toast.dark('Material adicionado com sucesso!');
    } catch (error) {
      toast.dark(
        'Não foi possível adicionar o novo material, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <DefaultModalContentWrapper maxW={['95vw', '500px']}>
      <ModalTitle>Inserir novo material</ModalTitle>

      <Stack flexDirection="column" mb={4}>
        <DropdownWithLabel
          label="Categoria"
          onChange={e => setMaterialCategory(e.target.value)}
        >
          <option value="presentation">Apresentação</option>
          <option value="documents">Documentos</option>
          <option value="images">Imagens</option>
          <option value="logos">Logos</option>
          <option value="others">Outros</option>
        </DropdownWithLabel>

        <InputWithLabel
          name="title"
          type="text"
          label="Título"
          placeholder="Título"
          onChange={e => setMaterialTitle(e.target.value)}
        />

        <InputWithLabel
          name="link"
          type="text"
          label="Link"
          placeholder="http://exemplo/dowload"
          onChange={e => setMaterialLink(e.target.value)}
        />
      </Stack>

      <ButtonGroup justifyContent="flex-end">
        <SecondaryButton onClick={handleCloseModal}>Cancelar </SecondaryButton>
        <PrimaryButton onClick={saveNewMaterial}>
          Inserir novo material
        </PrimaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};
