import React, { useEffect, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import {
  Avatar as ChakraAvatar,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';

import hubApi from 'services/hubApi';
import { Input } from 'components/elements/forms';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';

interface UserAvatarProps {
  avatar_url: string;
  userName: string;
  searchEmployeeData?: () => void;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  avatar_url,
  userName,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [preview, setPreview] = useState(avatar_url);
  useEffect(() => {
    setPreview(avatar_url);
  }, [preview, avatar_url]);

  const avatarSize = useBreakpointValue({ base: 'xl' });

  const handleUploadImg = async (e: any) => {
    const data = new FormData();

    data.append('filename', e.target.files[0]);
    await hubApi.sendAvatar(data, user.id);
  };

  return (
    <Flex position="relative" mt="36px" mr={['0px', '102px']}>
      <ChakraAvatar
        name={userName}
        src={preview}
        size={avatarSize}
        mb={['0px', '24px']}
        bg="primary.100"
      />

      <label>
        <Flex
          bg="gray"
          opacity="0.8"
          _hover={{ opacity: '1' }}
          borderRadius="100%"
          w={{ base: '30px', lg: '36px' }}
          h={{ base: '30px', lg: '36px' }}
          align="center"
          justify="center"
          position="absolute"
          bottom={['-10px', '20px']}
          right={['-5px', '0px']}
          cursor="pointer"
          color="white"
        >
          <AiOutlineCamera size={24} className="icon" />
          <Input type="file" hidden onChange={handleUploadImg} />
        </Flex>
      </label>
    </Flex>
  );
};
