import { ChevronUpIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import {
  IAssignmentsLength,
  IShowAndHideCard,
} from 'pages/Home/interfaces/assignments/Assignment';
import React, { SetStateAction } from 'react';

interface CompressAssignmentsProps {
  showCard: IShowAndHideCard;
  assignmentsLength: IAssignmentsLength;
  setShowCard: React.Dispatch<SetStateAction<IShowAndHideCard>>;
}

export const CompressAssignments: React.FC<CompressAssignmentsProps> = ({
  showCard,
  assignmentsLength,
  setShowCard,
}) => {
  const showCardAssignment = (showCardName: boolean, cardName: string) => {
    if (!showCardName) {
      setShowCard({ ...showCard, [cardName]: true });
    } else {
      setShowCard({ ...showCard, [cardName]: false });
    }
  };

  return (
    <>
      <Flex
        gridGap={4}
        justifyContent="space-between"
        alignItems="center"
        ml={['0', '4']}
      >
        {assignmentsLength.origin !== 0 && (
          <Flex width="76px" position="relative" alignItems="center">
            <Text
              color="gray.200"
              _hover={{ color: 'primary.400' }}
              transition="all 0.2s ease-in"
              fontSize="sm"
              cursor="pointer"
              onClick={() =>
                showCardAssignment(showCard.cardOrigin, 'cardOrigin')
              }
            >
              Origem{' '}
              {showCard.cardOrigin ? (
                <ChevronUpIcon />
              ) : (
                <Flex
                  backgroundColor="red.500"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  position="absolute"
                  top="-5px"
                  right={
                    assignmentsLength.origin >= 1 &&
                    assignmentsLength.origin <= 9
                      ? '8px'
                      : assignmentsLength.origin > 9 &&
                        assignmentsLength.origin < 100
                      ? '3px'
                      : assignmentsLength.origin >= 100
                      ? '-5px'
                      : ''
                  }
                  padding="1px"
                  px="6px"
                >
                  <Text color="gray.200" fontSize="xs">
                    {assignmentsLength.origin > 99
                      ? `${99}+`
                      : assignmentsLength.origin}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        )}

        {assignmentsLength.preNps !== 0 && (
          <Flex width="94px" position="relative" alignItems="center">
            <Text
              _hover={{ color: 'primary.400' }}
              transition="all 0.2s ease-in"
              color="gray.200"
              fontSize="sm"
              cursor="pointer"
              onClick={() =>
                showCardAssignment(showCard.cardPreNps, 'cardPreNps')
              }
            >
              Pré NPS{' '}
              {showCard.cardPreNps ? (
                <ChevronUpIcon />
              ) : (
                <Flex
                  backgroundColor="red.500"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  position="absolute"
                  top="-5px"
                  right={
                    assignmentsLength.preNps >= 1 &&
                    assignmentsLength.preNps <= 9
                      ? '20px'
                      : assignmentsLength.preNps > 9 &&
                        assignmentsLength.preNps < 100
                      ? '14px'
                      : assignmentsLength.preNps >= 100
                      ? '5px'
                      : ''
                  }
                  padding="1px"
                  px="6px"
                >
                  <Text color="gray.200" fontSize="xs">
                    {assignmentsLength.preNps > 99
                      ? `${99}+`
                      : assignmentsLength.preNps}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        )}

        {assignmentsLength.nps !== 0 && (
          <Flex width="72px" position="relative" alignItems="center">
            <Text
              _hover={{ color: 'primary.400' }}
              transition="all 0.2s ease-in"
              color="white"
              fontSize="sm"
              cursor="pointer"
              onClick={() => showCardAssignment(showCard.cardNps, 'cardNps')}
            >
              NPS{' '}
              {showCard.cardNps ? (
                <ChevronUpIcon />
              ) : (
                <Flex
                  backgroundColor="red.500"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  position="absolute"
                  top="-5px"
                  right={
                    assignmentsLength.nps >= 1 && assignmentsLength.nps <= 9
                      ? '22px'
                      : assignmentsLength.nps > 9 && assignmentsLength.nps < 100
                      ? '15px'
                      : assignmentsLength.nps >= 100
                      ? '8px'
                      : ''
                  }
                  padding="1px"
                  px="6px"
                >
                  <Text color="white" fontSize="xs">
                    {assignmentsLength.nps > 99
                      ? `${99}+`
                      : assignmentsLength.nps}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        )}

        {assignmentsLength.clientsLeft !== 0 && (
          <Flex width="145px" position="relative" alignItems="center">
            <Text
              _hover={{ color: 'primary.400' }}
              transition="all 0.2s ease-in"
              color="white"
              fontSize="sm"
              cursor="pointer"
              onClick={() =>
                showCardAssignment(
                  showCard.cardClientsLeft || false,
                  'cardClientsLeft'
                )
              }
            >
              Saída de Cliente{' '}
              {showCard.cardClientsLeft ? (
                <ChevronUpIcon />
              ) : (
                <Flex
                  backgroundColor="red.500"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  position="absolute"
                  top="-5px"
                  right={4}
                  padding="1px"
                  px="6px"
                >
                  <Text color="white" fontSize="xs">
                    {assignmentsLength.clientsLeft &&
                    assignmentsLength.clientsLeft > 99
                      ? `${99}+`
                      : assignmentsLength.clientsLeft}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        )}
      </Flex>
    </>
  );
};
