import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { SecondaryButton } from 'components/elements/buttons';
import { ReactNode, useState } from 'react';
import hubApi from 'services/hubApi';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { toast } from 'react-toastify';
import {
  IEmployeeSelected,
  IUsersSearh,
} from 'pages/GenerationalVariables/interfaces';
import { EmployeeCardForMultiselectionModal } from 'components/elements/cards/EmployeeCardForMultiselectionModal';
import { SelectedEmployeeCardForMultiselectionModal } from 'components/elements/cards/SelectedEmployeeCardForMultiselectionModal';
import { useQuery } from 'react-query';
import { LoadingSpinner } from '../LoadingSpinner';

interface EmployeesMultiselectionModalProps {
  customElement?: ReactNode;
  employeeList: any[];
  setEmployeeList: (data: any) => void;
  handleFormSubmit: (data: any) => void;
  onClose: () => void;
  isLoading: boolean;
  modalTitle: string;
  secondaryTitle: string;
  primaryButtonText: string;
  secondaryTitleColor?: string;
  actionOnUserSelection?: () => void;
}

export const EmployeesMultiselectionModal: React.FC<EmployeesMultiselectionModalProps> =
  ({
    customElement,
    handleFormSubmit,
    employeeList,
    setEmployeeList,
    onClose,
    isLoading,
    modalTitle,
    secondaryTitle,
    primaryButtonText,
    secondaryTitleColor = 'rgba(70, 70, 70, 1)',
    actionOnUserSelection,
  }) => {
    const [user, setUser] = useState('');

    const handleAddUser = (employeeSelected: IUsersSearh) => {
      if (actionOnUserSelection) {
        actionOnUserSelection();
      }

      const employeeSendToBackEnd = {
        employeeId: employeeSelected.id,
        name: employeeSelected.name,
      };

      const employeeEqual = employeeList.filter(
        employee => employee.employeeId === employeeSendToBackEnd.employeeId
      );

      if (employeeEqual.length > 0) {
        return toast.dark('Esse usuário ja foi selecionado');
      }

      setEmployeeList(
        [...employeeList, employeeSendToBackEnd].filter(
          (item: any) => typeof item !== 'string'
        )
      );
    };

    const handleTakeOfUser = (employeeToRemove: IEmployeeSelected) => {
      const employeeSelectedToRemove = employeeList.filter(
        employee => employee.employeeId !== employeeToRemove.employeeId
      );

      setEmployeeList(employeeSelectedToRemove);
    };

    const {
      data: employeeSearchList,
      refetch: refecthEmployeeSearchList,
      isLoading: isLoadingEmployeeSearchList,
      isRefetching,
    } = useQuery<IUsersSearh[]>(
      'employeeSearchList',
      async () => {
        const response = await hubApi.filterEmployees('name', user);

        return response;
      },
      {
        onError: () => {
          toast.dark(
            'Não foi possivel achar o(s) usuários. Tente novamente mais tarde.'
          );
        },
        refetchOnWindowFocus: true,
      }
    );

    return (
      <BaseModalFormCard
        isModal
        title={modalTitle}
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={onClose}
        isLoading={isLoading}
        primaryButtonText={primaryButtonText}
        secondaryButtonText="Cancelar"
        flexDir="column"
        width="100%"
        maxW="900px"
      >
        <Flex mb={4}>{customElement}</Flex>
        <Flex justify="space-between">
          <Box w="90%">
            <Flex w="100%" align="center" alignItems="end">
              <Box w="85%">
                <InputWithLabel
                  label="Nome do usuário:"
                  name="name"
                  autoComplete="off"
                  onChange={e => setUser(e.target.value)}
                />
              </Box>
              <SecondaryButton
                mt="6"
                ml="2"
                onClick={() => {
                  refecthEmployeeSearchList();
                }}
                size="xs"
              >
                Pesquisar
              </SecondaryButton>
            </Flex>

            <DefaultCardWrapper
              h="264px"
              maxH="265px"
              overflow="auto"
              mt="3"
              p="2"
              bg="#f2f2f2"
              flexDir="column"
            >
              {isLoadingEmployeeSearchList || isRefetching ? (
                <LoadingSpinner />
              ) : (
                <Stack p="1">
                  {employeeSearchList &&
                    employeeSearchList.map((item, index) => (
                      <EmployeeCardForMultiselectionModal
                        key={index}
                        listEmployee={item}
                        addUser={handleAddUser}
                      />
                    ))}
                </Stack>
              )}
            </DefaultCardWrapper>
          </Box>

          <Flex ml="5" w="90%" flexDir="column" justifyContent="end">
            <Text fontSize="sm" color={secondaryTitleColor} fontStyle="italic">
              {secondaryTitle}
            </Text>

            <DefaultCardWrapper
              h="264px"
              maxH="265px"
              overflow="auto"
              mt="1"
              p="2"
              bg="#f2f2f2"
              flexDir="column"
            >
              <Stack>
                {employeeList.map((item, index) => (
                  <SelectedEmployeeCardForMultiselectionModal
                    employee={item}
                    key={index}
                    handleTakeOfUser={handleTakeOfUser}
                  />
                ))}
              </Stack>
            </DefaultCardWrapper>
          </Flex>
        </Flex>
      </BaseModalFormCard>
    );
  };
