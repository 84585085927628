import { Flex, Text } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { DownloadSimple } from 'phosphor-react';
import { IOrderFile } from '../kanbanBase/BoardOrderInfo';

interface IProps {
  setIsOpenOrderFilesModal: (value: boolean) => void;
  orderFiles: IOrderFile[];
}

export const OrderFilesModal: React.FC<IProps> = ({
  setIsOpenOrderFilesModal,
  orderFiles,
}) => {
  return (
    <DefaultModalContentWrapper>
      <Text>Arquivos da Ordem</Text>
      <Flex
        flexDirection="column"
        gap={2}
        mt={2}
        maxH="500px"
        overflowY="auto"
        className="slimScrollbar"
      >
        {orderFiles.map(file => (
          <Flex
            backgroundColor="background.400"
            p={2}
            borderRadius={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="#fff">{file.fileName}</Text>
            <a href={file.url}>
              <DownloadSimple size={20} cursor="pointer" color="#1f5bff" />
            </a>
          </Flex>
        ))}
      </Flex>
      <Flex pt={5} gap={3} justifyContent="flex-end">
        <SecondaryButton onClick={() => setIsOpenOrderFilesModal(false)}>
          Fechar
        </SecondaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
