import styled from 'styled-components';

export const WrapperBackgroundTransparentModalContent = styled.div`
  padding: 20px;
  /* background: linear-gradient(108.96deg, #252b3c -18.81%, #000000 110.35%),
    linear-gradient(0deg, #424242, #424242); */
  background: #fff;
  border-radius: ${({ theme }) => theme.borders.minimal};
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
