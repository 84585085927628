import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Stack, Text } from '@chakra-ui/react';

import techOptions from 'pages/Technology/data';
import { ModalTitle } from 'components/elements/texts';
import { TextareaWithLabel } from 'components/elements/forms';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others/Dropdown';
import { hubApiTech } from 'services/hubApi/classes/HubApiTi';
import {
  BaseButtonGroup,
  DefaultCardWrapper,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const Technology: React.FC = () => {
  const [techTitle, setTechTitle] = useState('Selecione uma opção');
  const [linkToSuport, setLinkToSuport] = useState(false);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [formInfos, setFormInfos] = useState({
    description: '',
    type: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  const handleLinkToSuport = () => {
    setLinkToSuport(!linkToSuport);
  };

  const handleChangeTech = (value: { target: { value: string } }) => {
    if (value.target.value) {
      setTechTitle(value.target.value);
    } else {
      setTechTitle('Selecione uma opção');
    }
  };

  const handleChangeValue = (e: {
    target: { name: string; value: string };
  }) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);

    if (techTitle === 'Selecione uma opção') {
      toast.dark('Selecione uma opção de serviço');
      setIsLoading(false);
      return;
    }

    if (formInfos.description.trim() === '') {
      toast.dark('Preencha o campo de informações');
      setIsLoading(false);
      return;
    }

    try {
      const newOrder = {
        ...formInfos,
        type: techTitle,
      };

      await hubApiTech.createTiOrder(newOrder).then(() => {
        handleToggleModal();
      });

      toast.dark('Solicitação enviada com sucesso');
      setIsLoading(false);
      setFormInfos({
        description: '',
        type: '',
      });
    } catch {
      setIsLoading(false);
      toast.dark('Ocorreu um erro ao tentar enviar solicitação.');
    }
  };

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <DefaultCardWrapper flexDir="column" width="100%" maxW="510px">
        <ModalTitle>Solicitação de Serviços - Suporte</ModalTitle>

        <form onSubmit={handleFormSubmit}>
          <Stack spacing="3">
            <Dropdown
              title={techTitle}
              bg="background.500"
              onChange={handleChangeTech}
              placeholder="Selecione uma opção de serviço"
            >
              {techOptions.map(item => (
                <option value={item.title}>{item.title}</option>
              ))}
            </Dropdown>

            <TextareaWithLabel
              label="Informações"
              placeholder="Informações sobre a solicitação..."
              name="description"
              onChange={handleChangeValue}
              rows={9}
              value={formInfos.description}
            />
          </Stack>

          <BaseButtonGroup mt="3">
            <PrimaryButton type="submit" isLoading={isLoading}>
              Enviar solicitação
            </PrimaryButton>
          </BaseButtonGroup>
        </form>
      </DefaultCardWrapper>

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <DefaultModalContentWrapper flexDir="column" width="100%" maxW="490px">
          <ModalTitle>Deseja falar com o suporte agora ?</ModalTitle>

          <div>
            <Text mb="1" color="white">
              Quick tips:
            </Text>
            <Text fontSize="smaller" color="gray.400">
              * Já tentou reiniciar seu hub?{' '}
            </Text>
            <Text fontSize="smaller" color="gray.400">
              * Já tentou reiniciar seu PC?{' '}
            </Text>
          </div>

          <BaseButtonGroup>
            <a
              href="https://whereby.com/suporte-ewz?utm_source=onboarding&utm_content=link"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton onClick={handleLinkToSuport}>Sim</PrimaryButton>
            </a>
            <SecondaryButton onClick={handleToggleModal}>Não</SecondaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </PageWrapper>
  );
};
