import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';

import { RealStateFoundProps } from 'pages/products/interfaces';

export const RealEstateManagement: React.FC = () => {
  const history = useHistory();

  const [estateFunds, setStateFunds] = useState<RealStateFoundProps[]>([]);

  const habndleDeleteFund = useCallback(
    async id => {
      await hubApiProducts.deleteRealEstateFunds(id);
      setStateFunds(estateFunds.filter(fund => fund.id !== id));
    },
    [estateFunds]
  );

  const handleCreateRealStateFund = useCallback(() => {
    history.push('/products/real_estate_funds/create');
  }, [history]);

  const handleUpdateRealStateFund = useCallback(
    id => {
      history.push(`/products/real_estate_funds/update/${id}`);
    },
    [history]
  );

  useEffect(() => {
    hubApiProducts.getAllEstateFund().then(data => {
      setStateFunds(data);
    });
  }, []);

  if (estateFunds.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar Fundos Imobiliários"
        buttonText="Criar novo Fundo Imobiliário"
        handleCreateProduct={handleCreateRealStateFund}
      >
        <Text color="whiteAlpha.800">
          Não existem Fundos Imobiliários cadastrados.
        </Text>
      </ProductManagementLayout>
    );
  }

  return (
    <ProductManagementLayout
      title="Gerenciar Fundos Imobiliários"
      buttonText="Criar novo Fundo Imobiliário"
      handleCreateProduct={handleCreateRealStateFund}
    >
      {estateFunds?.map((fund, index) => (
        <ProductManagementCard
          key={index}
          productId={fund.id as string}
          title={fund.ticker}
          handleEditProduct={handleUpdateRealStateFund}
          handleRemoveProduct={habndleDeleteFund}
        />
      ))}
    </ProductManagementLayout>
  );
};
