import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { useHistory } from 'react-router-dom';

import { CarouselDashboard } from 'pages/DashboardTables/components';
import { SelectTablesModal } from 'pages/DashboardTables/components/elements';

type Table = {
  id: string;
  name: string;
};

export const DashboardTables: React.FC = () => {
  const [showDashboardCarousel, setShowDashboardCarousel] = useState(false);
  const [selectTablesModal, setSelectTablesModal] = useState(true);
  const [selectedTables, setSelectedtables] = useState<Table[]>([]);

  const history = useHistory();

  const handleSelectedTable = (
    tableId: string,
    tableName: string,
    isChecked: boolean
  ) => {
    let updatedSelectedTables = [];
    if (isChecked) {
      const selectedTableData: Table = {
        name: tableName,
        id: tableId,
      };
      updatedSelectedTables = [...selectedTables, selectedTableData];
      setSelectedtables(updatedSelectedTables);
    } else {
      updatedSelectedTables = selectedTables.filter(
        item => item.id !== tableId
      );
      setSelectedtables(updatedSelectedTables);
    }
  };

  const handleCloseModal = () => {
    history.goBack();
  };

  return (
    <>
      {showDashboardCarousel && (
        <Carousel
          autoPlay
          infiniteLoop
          stopOnHover={false}
          interval={15000}
          showThumbs={false}
          transitionTime={900}
        >
          {selectedTables.map((item, index) => (
            <CarouselDashboard
              key={item.id}
              tableId={item.id}
              tableName={item.name}
              dataFetchDelay={index * 10000}
            />
          ))}
        </Carousel>
      )}
      {selectTablesModal && (
        <SelectTablesModal
          isOpen={selectTablesModal}
          onClose={handleCloseModal}
          handleSelectedTable={handleSelectedTable}
          showDashboardCarousel={setShowDashboardCarousel}
          selectedTables={selectedTables}
          showTablesModal={setSelectTablesModal}
        />
      )}
    </>
  );
};
