const exchangeColumns = [
  {
    id: '1',
    title: 'Sem contato',
    value: 'sem-contato',
    tasks: [],
  },
  {
    id: '2',
    title: 'Contato inicial',
    value: 'conv-inicial',
    tasks: [],
  },
  {
    id: '3',
    title: 'Cotação',
    value: 'cotacao',
    tasks: [],
  },
  {
    id: '4',
    title: 'Abertura de conta',
    value: 'abertura-conta',
    tasks: [],
  },
  {
    id: '5',
    title: 'Cancelada/ Fechada',
    value: 'cancelada/fechada',
    tasks: [],
  },
];

export { exchangeColumns };
