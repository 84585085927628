import { Stack, ButtonGroup } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface IModalCreateOrUpdateAgreement {
  agreement?: IAgreement;
  employeeId: string;
  setAllAgreement: React.Dispatch<React.SetStateAction<IAgreement[]>>;
  allAgreement: IAgreement[];
  onClose: () => void;
}

export const ModalCreateOrUpdateAgreement: React.FC<IModalCreateOrUpdateAgreement> =
  ({ agreement, employeeId, allAgreement, setAllAgreement, onClose }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['clean'],
      ],
    };

    useEffect(() => {
      if (agreement) {
        setTitle(agreement.title);
        setMessage(agreement.agreement);
      }
    }, [agreement]);

    const handleFormSubmit = async (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);

      if (agreement) {
        try {
          const agreementToSend = {
            title,
            agreement: message,
          };

          if (!agreementToSend.title || !agreementToSend.agreement) {
            setIsLoading(false);
            return toast.dark('Preencha todos os campos');
          }

          const response = await hubApiUser.putAgreements(
            agreementToSend,
            agreement.id
          );

          const agreementsWithoutUpdated = allAgreement.findIndex(
            agreementItem => agreementItem.id === response.id
          );

          setAllAgreement(
            allAgreement.map((item, index) => {
              const result = item;
              if (index === agreementsWithoutUpdated) {
                result.title = response.title;
                result.agreement = response.agreement;
              }

              return result;
            })
          );

          setIsLoading(false);
          toast.dark('Acordo atualizado com sucesso');
          onClose();
        } catch (err) {
          toast.dark('Erro ao atualizar acordo');
          onClose();
          setIsLoading(false);
        }
      } else {
        try {
          const agreementToSend = {
            title,
            agreement: message,
            employeeId,
          };

          if (!agreementToSend.title || !agreementToSend.agreement) {
            setIsLoading(false);
            return toast.dark('Preencha todos os campos');
          }

          const response = await hubApiUser.postAgreement(agreementToSend);

          setAllAgreement([response, ...allAgreement]);
          setIsLoading(false);

          onClose();
          toast.dark('Acordo criado com sucesso');
        } catch (err) {
          toast.dark('Erro ao criar acordo');
          onClose();
          setIsLoading(false);
        }
      }
    };
    return (
      <DefaultModalContentWrapper>
        <ModalTitle>
          {agreement ? 'Atualizar acordo' : 'Criar acordo'}
        </ModalTitle>
        <form onSubmit={handleFormSubmit}>
          <Stack spacing={4}>
            <InputWithLabel
              label="Titulo"
              placeholder="Informe o título do acordo"
              name="title"
              maxLength={70}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />

            <ReactQuill
              modules={modules}
              theme="snow"
              placeholder=""
              onChange={e => setMessage(e)}
              value={message}
            />

            <ButtonGroup justifyContent="flex-end">
              <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
              <PrimaryButton isLoading={isLoading} type="submit">
                {agreement ? 'Atualizar acordo' : 'Criar acordo'}
              </PrimaryButton>
            </ButtonGroup>
          </Stack>
        </form>
      </DefaultModalContentWrapper>
    );
  };
