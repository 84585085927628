import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const MosaicCardsWrapper: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      overflowY="auto"
      flexWrap="wrap"
      gridGap="1"
      flexDir="row"
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
};
