import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

import { Tooltip } from 'components/elements/others';

interface ComponentProps extends FlexProps {
  title: string;
  information?: string;
  Icon: IconType;
  description?: string;
}

export const IconCard: React.FC<ComponentProps> = ({
  title,
  information,
  Icon,
  description,
  ...rest
}) => {
  return (
    <Flex
      width="108px"
      flexDir="column"
      fontSize="sm"
      color="rgba(70, 70, 70, 1)"
      cursor="pointer"
      {...rest}
      title={description || title}
    >
      <Flex
        width="100%"
        height="100vh"
        maxW="104px"
        maxH="104px"
        border="1px solid"
        borderColor="primary.600"
        borderRadius="2"
        position="relative"
        justifyContent="center"
        alignItems="center"
        background="transparent"
        transition="all 0.2s ease-in"
        _hover={{ bg: 'primary.600', border: '1px solid primary.600' }}
      >
        {Icon && <Icon size={32} />}
        {information && <Tooltip information={information} />}
      </Flex>

      <Text
        color="rgba(70, 70, 70, 1)"
        textAlign="center"
        fontSize="small"
        mt="3"
      >
        {title}
      </Text>
    </Flex>
  );
};
