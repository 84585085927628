import { Flex, Text } from '@chakra-ui/react';
import { SatelliteAreas } from 'pages/DashboardTablesDesktop/interfaces';

interface BottomSectionSatelliteAreasProps {
  satelliteAreasData: SatelliteAreas;
}

export const BottomSectionSatelliteAreas: React.FC<BottomSectionSatelliteAreasProps> =
  ({ satelliteAreasData }) => {
    return (
      <Flex
        w="40%"
        bg="background.600"
        flexDirection="column"
        py={2}
        gridGap={1}
        borderRadius={8}
        alignItems="center"
      >
        <Text w="100%" color="white" textAlign="center" fontSize="md">
          Áreas Satélites
        </Text>

        <Flex
          p={2}
          alignItems="center"
          flexDirection="column"
          bg="background.700"
          borderRadius={8}
          w="90%"
        >
          <Text fontSize="xs">Indicações Câmbio</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {satelliteAreasData.exchangeCount || 0}/mês
          </Text>
        </Flex>

        <Flex
          p={2}
          alignItems="center"
          flexDirection="column"
          bg="background.700"
          borderRadius={8}
          w="90%"
        >
          <Text fontSize="xs">Indicações IP</Text>
          <Text fontSize="xs" color="white" fontWeight="bold">
            {satelliteAreasData.ipCount || 0}/mês
          </Text>
        </Flex>
      </Flex>
    );
  };
