export const checkLeadTypeLabel = (leadType: string): string => {
  if (leadType === 'leadspj' || leadType === 'pj') return 'Lead PJ';

  if (
    leadType === 'leadstable' ||
    leadType === 'table' ||
    leadType === 'tables'
  )
    return 'Lead Minerado';

  if (
    leadType === 'online' ||
    leadType === 'publics' ||
    leadType === 'leadspublic'
  )
    return 'Lead Público';

  return leadType;
};
