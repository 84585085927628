import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import employeeForm from './newEmployeeForm/sagas';
import notifications from './notifications/sagas';

function* rootSagas() {
  return yield all([auth, employeeForm, notifications]);
}

export default rootSagas;
