import React, { useState } from 'react';
import { BaseModalFormCard } from 'components/elements/cards';
import { toast } from 'react-toastify';

import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { InputWithLabel } from 'components/elements/forms';
import { errorHandler } from 'utils/errorHandler';

interface ComponentProps {
  questions: any[];
  setQuestions: any;
  handleToggleModal: () => void;
}

export const CreateNewQuestionLead: React.FC<ComponentProps> = ({
  questions,
  setQuestions,
  handleToggleModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');

  const handleCreateQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (newQuestion.trim() === '') {
      setIsLoading(false);
      return toast.dark('Preencha o campo da pergunta');
    }

    try {
      const newQuestionData = {
        question: newQuestion,
        status: 'standard',
      };

      const createdQuestion = await hubApiCaptation.postNewQuestion(
        newQuestionData
      );

      setQuestions([...questions, createdQuestion]);
      toast.dark('Pergunta criada com sucesso');

      handleToggleModal();
    } catch (err) {
      errorHandler(err);
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      maxW="490px"
      title="Criar pergunta"
      isLoading={isLoading}
      handleToggleModal={handleToggleModal}
      handleFormSubmit={handleCreateQuestion}
      isModal
    >
      <InputWithLabel
        label="Pergunta"
        placeholder="Digite a sua pergunta"
        value={newQuestion}
        onChange={e => setNewQuestion(e.target.value)}
      />
    </BaseModalFormCard>
  );
};
