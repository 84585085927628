import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { MainPageTitle } from 'components/elements/texts';
import { PrimaryButton } from 'components/elements/buttons';
import { TableBase } from 'components/elements/table';
import { LoadingSpinner } from 'components/elements/others';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { periodValidator } from 'utils/periodValidator';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { IOrderProps } from './interfaces';
import { advancedOrdersHeader, hubAreasOptions } from './data';
import { OrderRowValues } from './components/modules/OrderRowValues';

const AdvancedOrders: React.FC = () => {
  const currencyDate = new Date();

  const aMonthAgo = new Date(currencyDate.setMonth(currencyDate.getMonth() - 1))
    .toISOString()
    .slice(0, 10);

  const currencyMonth = new Date(
    currencyDate.setMonth(currencyDate.getMonth() + 1)
  )
    .toISOString()
    .slice(0, 10);
  const [orders, setOrders] = useState<IOrderProps[]>([]);
  const [startDate, setStartDate] = useState(aMonthAgo);
  const [endDate, setEndDate] = useState(currencyMonth);
  const [areaSelected, setAreaSelected] = useState('all');
  const [thereIsActiveSearch, setThereIsActiveSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLastOrder();
  }, []);

  const getLastOrder = async () => {
    setIsLoading(true);

    if (startDate === '' || endDate === '') {
      setIsLoading(false);
      toast.dark('Selecione o período de busca');
      return;
    }

    if (!periodValidator(startDate, endDate)) {
      setIsLoading(false);
      return;
    }

    try {
      const lastOrders = await hubApiHome.listLastOrders(
        startDate,
        endDate,
        areaSelected
      );

      setThereIsActiveSearch(true);
      setOrders(lastOrders);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.dark(
        'Não foi possível buscar as ordens, tente novamente em alguns minutos'
      );
    }
  };

  const handleSelectMeeting = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAreaSelected(event.target.value);
  };

  return (
    <PageWrapper paddingTop="2.5rem" alignItems="center">
      <MainPageTitle textAlign="center">Ordens enviadas</MainPageTitle>

      <Flex
        width="100%"
        maxW={['95vw', '1180px']}
        flexDir="column"
        gap={[5, 0]}
      >
        <Flex
          width="100%"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="center"
          gridGap={1}
        >
          <DropdownWithLabel
            mb={{ base: '2', md: '4' }}
            onChange={handleSelectMeeting}
            label="Selecione uma área"
            placeholder=""
          >
            {hubAreasOptions.map((item, index) => (
              <option key={`${index}-${item.title}`} value={item.value}>
                {item.title}
              </option>
            ))}
          </DropdownWithLabel>

          <DatePickerChackra
            handlePickDate={e => setStartDate(e.target.value)}
            value={startDate}
            label="Data inicial"
          />

          <DatePickerChackra
            handlePickDate={e => setEndDate(e.target.value)}
            value={endDate}
            label="Data final"
          />

          <PrimaryButton
            alignSelf="center"
            mt={2}
            onClick={getLastOrder}
            minW="80.8px"
          >
            {isLoading ? <LoadingSpinner size="xs" /> : 'Buscar'}
          </PrimaryButton>
        </Flex>

        {orders.length <= 0 && thereIsActiveSearch && (
          <Text textAlign="center" my={{ base: '2', md: '8' }}>
            Não há ordens enviadas neste período
          </Text>
        )}

        {orders.length > 0 && (
          <TableBase
            headData={advancedOrdersHeader}
            overflowY="auto"
            maxH={['95vw', 'calc(100vh - 286px)']}
            width="100%"
          >
            {orders?.map((order, index) => (
              <OrderRowValues key={`${index}-${order.name}`} order={order} />
            ))}
          </TableBase>
        )}
      </Flex>
    </PageWrapper>
  );
};

export default AdvancedOrders;
