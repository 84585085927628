import React from 'react';
import { Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface ComponentProps {
  handleDeleteQuestion: () => void;
  handleToggleModal: () => void;
  isLoading: boolean;
}

export const DeleteQuestionModal: React.FC<ComponentProps> = ({
  handleDeleteQuestion,
  handleToggleModal,
  isLoading,
}) => {
  return (
    <DefaultModalContentWrapper maxW="490px">
      <ModalTitle>Excluir pergunta</ModalTitle>

      <Text color="white" mb="3">
        Você confirma que deseja excluir essa pergunta?
      </Text>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Cancelar</SecondaryButton>
        <PrimaryButton onClick={handleDeleteQuestion} isLoading={isLoading}>
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
