import React from 'react';
import { Flex } from '@chakra-ui/react';

import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';

interface ComponentProps {
  pageTitle: string;
}

export const ProductsPageLayout: React.FC<ComponentProps> = ({
  children,
  pageTitle,
}) => {
  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />
      <MainPageTitle>{pageTitle}</MainPageTitle>

      <Flex
        overflowY="auto"
        maxH="calc(100vh - 220px)"
        flexDir="column"
        px="2"
        w="100%"
        alignItems="center"
        gridGap="3"
      >
        {children}
      </Flex>
    </PageWrapper>
  );
};
