import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Text } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';
import parser from 'html-react-parser';

import { InfoTitle } from 'pages/MyClients/components/elements';
import { InformalInfos, Reminder } from 'pages/MyClients/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { BaseModalFormCard } from 'components/elements/cards';
import { SecondaryButton } from 'components/elements/buttons';
import { errorHandler } from 'utils';
import { formatToDate } from 'brazilian-values';
import { QuestionsAndAnswersItem } from './QuestionAndAnswersItem';

interface ClientDataSectionProps {
  clientId: string;
  data: InformalInfos[];
  signalToUpdate: boolean;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  clientReminders: Reminder[];
  clientLastReminders: Reminder[];
  onlyVizMode: boolean;
}

export const ClientDataSection: React.FC<ClientDataSectionProps> = ({
  clientId,
  data,
  signalToUpdate,
  clientReminders,
  clientLastReminders,
  setSignalToUpdate,
  onlyVizMode,
}) => {
  const [showModalDisclaimer, setShowModalDisclaimer] = useState(false);
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [disclaimer, setDisclaimer] = useState<Reminder[]>([]);
  const [questionItem, setQuestionItem] = useState({
    question: '',
    answer: '',
  });

  const [commentedAreasItem, setCommentedAreasItem] = useState([
    {
      id: '',
      commentedAreas: '',
      dataRetorno: '',
    },
  ]);

  useEffect(() => {
    handleGetLastContact();
  }, []);

  useEffect(() => {
    handleGetCommentedAreas();
  }, [clientReminders, clientLastReminders]);

  const handleGetCommentedAreas = () => {
    const commentedAreas: any[] = [];
    clientReminders.forEach(client => {
      if (client.commentedAreas !== '' && client.commentedAreas !== null) {
        commentedAreas.push({
          id: client.id,
          commentedAreas: client.commentedAreas,
          dataRetorno: client.dataRetorno,
        });
      }
    });

    clientLastReminders.forEach(client => {
      if (client.commentedAreas !== '' && client.commentedAreas !== null) {
        commentedAreas.push({
          id: client.id,
          commentedAreas: client.commentedAreas,
          dataRetorno: client.dataRetorno,
        });
      }
    });
    setCommentedAreasItem(commentedAreas);
  };

  const handleGetLastContact = async () => {
    try {
      const response: Reminder[] = await hubApiUser.getAnotationsMessage(
        clientId
      );

      setDisclaimer(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  const addNewQuestionAndAnswer = () => {
    setShowNewQuestionModal(true);
  };

  const handleQuestionAndAnswerInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionItem({ ...questionItem, [e.target.name]: e.target.value });
  };

  const handleAddNewQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await hubApiUser.postInformalInfos([
        {
          ...questionItem,
          clientId,
        },
      ]);

      setSignalToUpdate(!signalToUpdate);
      toast.dark('Informação adicionada com sucesso');
      setShowNewQuestionModal(false);
    } catch (err) {
      toast.dark(
        'Não foi possível adicionar a nova informação, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <>
      <Flex
        width={['100%', '50%']}
        height="100%"
        maxWidth={['98%', '50%']}
        flexDir="column"
        justify="space-between"
        mt={1}
      >
        <Flex
          flexDir="column"
          width="100%"
          height="100%"
          maxHeight="90%"
          justifyContent="flex-start"
          gap={3}
        >
          <Flex
            flexDir="column"
            width="100%"
            height="100%"
            maxHeight={['200px', '25%']}
          >
            <Flex>
              <InfoTitle title="Áreas comentadas" />
              <Box mr={-1} _hover={{ color: 'white' }} transition="400ms" />
            </Flex>

            <Flex
              w="100%"
              flexDir="column"
              height="100%"
              maxHeight="100%"
              pr="1"
              overflowY="auto"
              gridGap="1.5"
              mt={4}
              className="boxClients"
            >
              {commentedAreasItem.length ? (
                commentedAreasItem.map(item => (
                  <Box
                    background="background.400"
                    borderRadius="md"
                    p={2}
                    fontSize="sm"
                    color="#fff"
                  >
                    <Flex justifyContent="space-between" gap={5}>
                      <Text as="span"> {item.commentedAreas}</Text>
                      <Text as="span">
                        {formatToDate(new Date(item.dataRetorno))}
                      </Text>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Text fontStyle="italic" textAlign="center">
                  Sem áreas comentadas
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex
            flexDir="column"
            height="100%"
            maxHeight={['300px', '75%']}
            width="100%"
          >
            <Flex>
              <InfoTitle title="Informações adicionais" />
              {!onlyVizMode && (
                <Box mr={-1} _hover={{ color: 'white' }} transition="400ms">
                  <BiAddToQueue
                    size={26}
                    cursor="pointer"
                    onClick={addNewQuestionAndAnswer}
                  />
                </Box>
              )}
            </Flex>

            <Flex
              w="100%"
              flexDir="column"
              height="100%"
              maxHeight="100%"
              pr="1"
              overflowY="auto"
              gridGap="1.5"
              mt={4}
              className="boxClients"
            >
              {data.map(item => (
                <QuestionsAndAnswersItem
                  onlyVizMode={onlyVizMode}
                  key={`${item.id}-${item.clientId}`}
                  question={item}
                  signalToUpdate={signalToUpdate}
                  setSignalToUpdate={setSignalToUpdate}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          align="flex-end"
          justify="center"
          height="100%"
          maxHeight="10%"
          mt={[3, 0]}
        >
          <SecondaryButton
            fontWeight="bold"
            onClick={() => setShowModalDisclaimer(!showModalDisclaimer)}
          >
            Ver resumo de disclaimer
          </SecondaryButton>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showModalDisclaimer}
        onClose={() => setShowModalDisclaimer(!showModalDisclaimer)}
      >
        <BaseModalFormCard
          isModal
          title="Resumo de disclaimer"
          isLoading={false}
          handleFormSubmit={e => {
            e.preventDefault();
            setShowModalDisclaimer(!showModalDisclaimer);
          }}
          handleToggleModal={() => setShowModalDisclaimer(!showModalDisclaimer)}
          primaryButtonText="Fechar"
          hideSecondaryButton
          maxW={['95vw', '540px']}
        >
          <Flex
            flexDir="column"
            pr="2"
            gridGap="9"
            maxH="300px"
            overflow="auto"
          >
            {disclaimer.map(item => (
              <Flex
                borderBottom="1px solid gray"
                flexDir="column"
                key={`${item.id}-${item.clientId}`}
              >
                <Flex
                  align="center"
                  color="whiteAlpha.900"
                  fontWeight="bold"
                  fontSize="md"
                  justify="space-between"
                  mb="2"
                >
                  <Text>{item.title}</Text>
                  <Flex>
                    <Text>{formatToDate(new Date(item.dataRetorno))}</Text>
                  </Flex>
                </Flex>

                <Flex textAlign="justify">
                  <Text>{parser(item.msg)}</Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showNewQuestionModal}
        onClose={() => setShowNewQuestionModal(false)}
      >
        <BaseModalFormCard
          isModal
          maxW={['95vw', '516px']}
          title="Adicionar nova informação"
          isLoading={false}
          handleFormSubmit={e => handleAddNewQuestion(e)}
          handleToggleModal={() => setShowNewQuestionModal(false)}
          primaryButtonText="Salvar nova informação"
        >
          <InputWithLabel
            mb={4}
            label="Pergunta / Dado"
            name="question"
            maxLength={30}
            onChange={handleQuestionAndAnswerInput}
          />
          <InputWithLabel
            label="Resposta / Valor"
            name="answer"
            onChange={handleQuestionAndAnswerInput}
          />
        </BaseModalFormCard>
      </ModalWrapper>
    </>
  );
};
