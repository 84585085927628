import { Flex, Text } from '@chakra-ui/react';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { formatMibs } from 'utils/formatMibs';

interface IProps {
  leadPrice: number;
}

export const LeadPricePaid: React.FC<IProps> = ({ leadPrice }) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle mb="1">Preço pago pelo Lead</ContentDividerTitle>
      <Flex flexWrap="wrap" gridGap="1.5">
        <Text fontSize="xs">
          {formatMibs(Math.floor(leadPrice / 0.1))} MIBs
        </Text>
      </Flex>
    </ContentWithVertDividerWrapper>
  );
};
