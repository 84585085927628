import React from 'react';
import {
  Flex,
  FlexProps,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Checkbox,
} from '@chakra-ui/react';

interface ComponentProps extends FlexProps {
  headData: string[];
  isMainCheckbox?: boolean;
  isMainChecked?: boolean;
  toggleMainCheckbox?: () => void;
}

export const TableBase: React.FC<ComponentProps> = ({
  children,
  headData,
  isMainCheckbox,
  isMainChecked,
  toggleMainCheckbox,
  ...rest
}) => {
  return (
    <Flex background="white" borderRadius="3" {...rest}>
      <Table px="4" color="white" variant="unstyled" width="100%" height="100%">
        <Thead>
          <Tr>
            {isMainCheckbox && (
              <Th
                background="white"
                textAlign="center"
                color="rgba(70, 70, 70, 1)"
                fontSize="small"
                position="sticky"
                zIndex={5}
                top="0"
              >
                <Checkbox
                  isChecked={isMainChecked}
                  onChange={toggleMainCheckbox}
                />
              </Th>
            )}
            {headData?.map((item, index) => (
              <Th
                key={index}
                background="white"
                textAlign="center"
                color="rgba(70, 70, 70, 1)"
                fontSize="small"
                position="sticky"
                zIndex={5}
                top="0"
              >
                {item}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </Flex>
  );
};
