import React from 'react';
import { Flex } from '@chakra-ui/react';

import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

interface ComponentProps {
  title: string;
  buttonText: string;
  handleCreateProduct: () => void;
  handleToggleProductModal?: () => void;
}

export const ProductManagementLayout: React.FC<ComponentProps> = ({
  children,
  title,
  buttonText,
  handleCreateProduct,
  handleToggleProductModal,
}) => {
  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />

      <MainPageTitle textAlign="center">{title}</MainPageTitle>

      <Flex flexDir="column" width="100%" maxW="960px">
        <PrimaryButton
          px="4"
          alignSelf="flex-end"
          onClick={handleCreateProduct || handleToggleProductModal}
        >
          {buttonText}
        </PrimaryButton>

        <DefaultCardWrapper mt="3" px="2" py="3" width="100%">
          <Flex
            width="100%"
            overflowY="auto"
            maxH="calc(100vh - 300px)"
            flexDir="column"
            px="2"
            gridGap="3"
          >
            {children}
          </Flex>
        </DefaultCardWrapper>
      </Flex>
    </PageWrapper>
  );
};
