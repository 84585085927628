import { formatValue } from 'react-currency-input-field';

import { formatDate } from 'utils/formatDate';
import { CardProductsListBase } from 'pages/products/components/elements/cards';
import { IPOProps } from 'pages/products/interfaces';
import { useEffect, useState } from 'react';
import { CardInfo } from 'components/elements/others/CardInfo';
import { LayoutModal } from 'components/elements/others/LayoutModal';

interface IPOCardProps {
  ipoData: IPOProps;
}

const IPOCard: React.FC<IPOCardProps> = ({ ipoData }) => {
  const [productType, setProductType] = useState('');

  const verifyProductType = () => {
    if (ipoData.title !== 'undefined') {
      setProductType('IPO');
    }

    if (ipoData.title === 'undefined') {
      setProductType('SUB');
    }
  };

  useEffect(() => {
    verifyProductType();
  }, []);

  return (
    <CardProductsListBase
      title={productType === 'IPO' ? ipoData.title : ipoData.ticker}
      secondeTitle={ipoData.ticker}
      type={productType === 'IPO' ? 'IPO' : 'Subscrição'}
    >
      <LayoutModal
        description={
          productType === 'IPO'
            ? ipoData.strategy
            : productType === 'SUB'
            ? ipoData.description
            : ''
        }
        bonusMessage={ipoData.bonusMessage}
        isStrategy={productType === 'IPO' && true}
        maxWidth={'530px'}
      >
        {ipoData.offer && <CardInfo title="Oferta" info={ipoData.offer} />}

        <CardInfo
          title="Reservas até dia"
          info={formatDate(ipoData.bookingDeadlines)}
        />

        {productType === 'IPO' && (
          <>
            <CardInfo
              title="Divulgação de resultado"
              info={formatDate(ipoData.disclosureOfResults)}
            />

            <CardInfo
              title="Início das negociações"
              info={formatDate(ipoData.startOfNegotiations)}
            />
          </>
        )}

        <CardInfo
          title="Liquidações financeiras"
          info={formatDate(ipoData.financialSettlements)}
        />
        {productType === 'IPO' && (
          <CardInfo
            title="Preço"
            info={`${formatValue({
              value: ipoData.priceMin,
              prefix: 'R$ ',
              decimalSeparator: ',',
              intlConfig: {
                locale: 'pt-BR',
                currency: 'BRL',
              },
            })} à ${formatValue({
              value: ipoData.priceMax,
              prefix: 'R$ ',
              decimalSeparator: ',',
              intlConfig: {
                locale: 'pt-BR',
                currency: 'BRL',
              },
            })}`}
          />
        )}

        {productType === 'SUB' && (
          <>
            <CardInfo
              title="Preço por cota"
              info={formatValue({
                value: ipoData.pricePerQuote,
                prefix: 'R$ ',
                decimalSeparator: ',',
                intlConfig: {
                  locale: 'pt-BR',
                  currency: 'BRL',
                },
              })}
            />
            <CardInfo
              title="Cota à mercado"
              info={formatValue({
                value: ipoData.marketQuote,
                prefix: 'R$ ',
                decimalSeparator: ',',
                intlConfig: {
                  locale: 'pt-BR',
                  currency: 'BRL',
                },
              })}
            />
          </>
        )}

        {productType === 'SUB' && (
          <>
            <CardInfo title="Desconto" info={ipoData.discount} />
            <CardInfo
              title="DY (últimos 12 meses)"
              info={`${ipoData.dividendYeld}%`}
            />
          </>
        )}
      </LayoutModal>
    </CardProductsListBase>
  );
};

export default IPOCard;
