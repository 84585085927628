import { IPMeetingType } from 'services/hubApi';

type ResponseType = {
  border: string;
};

export const useMeetingCardCustomization = (
  meeting: IPMeetingType
): ResponseType => {
  const meetingCreatedAtDate = new Date(meeting.created_at);
  let border = '1px solid #1d63ddb2';

  if (meeting.status === 'boleted') {
    return {
      border,
    };
  }

  const isMeetingCreatedATwoDaysAgoInNoContact =
    meeting.status === 'noContact' &&
    new Date() >
      new Date(
        meetingCreatedAtDate.setDate(meetingCreatedAtDate.getDate() + 2)
      );

  if (!meeting.date && !isMeetingCreatedATwoDaysAgoInNoContact) {
    return {
      border,
    };
  }

  const isMeetingDateInPast =
    meeting.status !== 'noContact' &&
    meeting.date &&
    new Date().getTime() > new Date(meeting.date).getTime();

  const isMeetingWithin24Hours =
    meeting.date &&
    (new Date(meeting.date).getTime() - new Date().getTime()) /
      (1000 * 60 * 60) <=
      24;

  if (isMeetingWithin24Hours) {
    border = '1px solid orange';
  }

  if (isMeetingCreatedATwoDaysAgoInNoContact || isMeetingDateInPast) {
    border = '1px solid red';
  }

  return {
    border,
  };
};
