import { Flex } from '@chakra-ui/react';
import {
  BottomSectionGraphic,
  BottomSectionSatelliteAreas,
  BottomSectionTableKPIs,
  BottomSectionEmployeeMetricsCard,
} from 'pages/DashboardTables/components';
import { SatelliteAreas, Employee } from 'pages/DashboardTables/interfaces';

interface BottomSectionProps {
  graphicData: {
    account: string;
    funds: string;
    rf: string;
    rv: string;
    prev: string;
    deriv: string;
  };
  satelliteAreasData: SatelliteAreas;
  employees: Employee[];
  updateEmployees: () => void;
  employeesColors: {
    employeeName: string;
    color: string;
  }[];
  tableName: string;
  tableData: any;
  tableId: string;
}

export const BottomSection: React.FC<BottomSectionProps> = ({
  graphicData,
  satelliteAreasData,
  employees,
  updateEmployees,
  employeesColors,
  tableName,
  tableData,
  tableId,
}) => {
  return (
    <Flex w="100%" h="52%" gridGap={6}>
      <Flex
        w="40%"
        flexDirection="column"
        bg="background.700"
        borderRadius={8}
        gridGap={6}
        overflowY="auto"
      >
        {employees.map(item => (
          <BottomSectionEmployeeMetricsCard
            key={item.btgCode}
            employee={item}
            updateEmployees={updateEmployees}
            employeesColors={employeesColors}
          />
        ))}
      </Flex>

      <Flex w="60%" gridGap={6} flexDirection="column">
        <Flex w="100%" gridGap={6} h="50%">
          <BottomSectionSatelliteAreas
            satelliteAreasData={satelliteAreasData}
          />

          <BottomSectionGraphic
            account={graphicData?.account}
            deriv={graphicData?.deriv}
            funds={graphicData?.funds}
            rf={graphicData?.rf}
            prev={graphicData?.prev}
            rv={graphicData?.rv}
          />
        </Flex>

        <BottomSectionTableKPIs
          tableId={tableId}
          tableData={tableData}
          tableName={tableName}
          employees={employees}
        />
      </Flex>
    </Flex>
  );
};
