import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { TransferOrderCard } from 'components/elements/cards';

import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import formatValue from 'utils/formatValue';
import { useDisclosure } from '@chakra-ui/react';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { errorHandler } from 'utils';
import OrderInfo from '../../components/modules/rvWithdrawals/OrderInfo';

interface IInfoRvProps {
  btgAccount: string;
  financeiro: number;
  id: string;
  name: string;
}

export const RvWithdrawals: React.FC = () => {
  const [infosRv, setInfosRV] = useState<IInfoRvProps[]>([]);
  const [orderToModal, setOrderToModal] = useState<IInfoRvProps>(
    {} as IInfoRvProps
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSetOrder = (order: IInfoRvProps) => {
    setOrderToModal(order);
  };

  // useEffect(() => {
  //     const socket = io(hubApi.baseURL, {
  //         query: {
  //             user_id: 'TI',
  //         },
  //     });

  //     socket.on('newOrder', (order) => {
  //         setInfosRV([...infosRv, order]);
  //     });
  // }, [infosRv]);

  useEffect(() => {
    listAllOrders();
  }, []);

  async function listAllOrders() {
    await hubApiRv
      .allClients()
      .then(response => {
        setInfosRV(response);
      })
      .catch(error => {
        errorHandler(error);
      });
  }

  // const removeOrder = useCallback(
  //     (id) => {
  //         setInfosRV(infosRv.filter((item) => item.id !== id));
  //     },
  //     [infosRv]
  // );

  return (
    <PageWrapper flexDir="column" alignItems="center" paddingTop="48px">
      <BackPageButton />

      <MainPageTitle textAlign="center">
        Seus clientes na mesa RV{' '}
      </MainPageTitle>

      <TransferOrdersCardsWrapper>
        {infosRv.map((item, index) => (
          <TransferOrderCard
            key={index}
            mainText={item.name}
            secondaryText={`Código BTG: ${item.btgAccount}`}
            thirdText={`Financeiro: ${formatValue(item.financeiro)}`}
            onClick={() => {
              handleSetOrder(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo handleShowModal={onClose} order={orderToModal} />
      </ModalWrapper>
    </PageWrapper>
  );
};
