import { Flex } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { TitleWithSideLine } from 'components/elements/texts';
import { ModalWrapper } from 'components/elements/wrappers';
import { MosaicLeadCard } from 'pages/Captation/components/elements/cards';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MyOrdersModal } from 'pages/Captation/components/modules/modals/MyOrdersModal';
import { IBaseLead, ILeadsPurchasedOrder } from 'pages/Captation/interfaces';
import { toast } from 'react-toastify';
import { queryClient } from 'App';
import { LeadInfos } from '../CommonLeadContent';

interface IProps {
  handleShowLead(value: IBaseLead): void;
}

export const LeadsPurchased: React.FC<IProps> = ({ handleShowLead }) => {
  const [isOpenBuyLeadsModal, setIsOpenBuyLeadsModal] =
    useState<boolean>(false);
  const [isOpenMyOrdersModal, setIsOpenMyOrdersModal] =
    useState<boolean>(false);

  const { data: leadsPurchased, isLoading } = useQuery<LeadInfos[]>(
    'leadsPurchased',
    async () => {
      const response = await hubApiCaptation.getLeadsPurchased();
      return response;
    }
  );

  const {
    data: leadsPurchaseOrders,
    isLoading: isLoadingGetLeadsPurchasedOrders,
  } = useQuery<ILeadsPurchasedOrder[]>('leadsPurchaseOrders', async () => {
    const response = await hubApiCaptation.getLeadsPurchasedOrders();
    return response;
  });

  const { mutateAsync: updateOrder } = useMutation({
    mutationFn: async ({
      orderId,
      status,
    }: {
      orderId: string;
      status: 'active' | 'finished';
    }) => {
      const response = await hubApiCaptation.updateLeadPurchaseOrder({
        orderId,
        status,
      });
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['leadsPurchaseOrders']);
      toast.dark('Ordem de compra finalizada com sucesso');
    },
    onError: () => {
      toast.dark('Erro ao finalizar ordem de compra.');
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex flexDirection="column" h={isOpenBuyLeadsModal ? '100%' : ''}>
      <Flex gap={2}>
        <TitleWithSideLine>Leads Comprados</TitleWithSideLine>
        <PrimaryButton size="xs" onClick={() => setIsOpenMyOrdersModal(true)}>
          Minhas Ordens
        </PrimaryButton>
      </Flex>
      <Flex
        gap={1}
        wrap="wrap"
        overflowY="auto"
        maxH="480px"
        justifyContent={isOpenBuyLeadsModal ? 'center' : 'inherit'}
        p={4}
      >
        {!isOpenBuyLeadsModal &&
          leadsPurchased &&
          leadsPurchased.map(item => (
            <MosaicLeadCard
              key={item.id}
              name={item.name}
              phone={item.phone[0]?.phone}
              email={item.email[0]?.email}
              onClick={() => {
                handleShowLead(item);
              }}
              contactAttempts={item.contactAttempts}
              mundoInvestPL={item.plMi}
            />
          ))}
      </Flex>

      <ModalWrapper
        isOpen={isOpenMyOrdersModal}
        onClose={() => setIsOpenMyOrdersModal(!isOpenMyOrdersModal)}
      >
        <MyOrdersModal
          setIsOpenMyOrdersModal={setIsOpenMyOrdersModal}
          leadsPurchaseOrders={leadsPurchaseOrders}
          isLoadingGetLeadsPurchasedOrders={isLoadingGetLeadsPurchasedOrders}
          updateOrder={updateOrder}
        />
      </ModalWrapper>
    </Flex>
  );
};
