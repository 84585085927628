import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';

interface IComponentProps extends FlexProps {
  label: string;
  info: string;
}

export const DisplayInformationInline: React.FC<IComponentProps> = ({
  label,
  info,
  ...rest
}) => {
  return (
    <Flex {...rest}>
      <Text color="rgba(70, 70, 70, 1)" fontSize="medium">
        {label}:{' '}
        <Text as="span" color="rgba(70, 70, 70, 1)" fontWeight="medium">
          {info}
        </Text>
      </Text>
    </Flex>
  );
};
