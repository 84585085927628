import React, { ReactText, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Checkbox, Flex, Stack, Text } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import DownloadInput from 'pages/products/components/elements/buttons/DownloadInput';
import { InputWithLabel } from 'components/elements/forms';
import { COEProps } from 'pages/products/interfaces';
import { ModalTitle } from 'components/elements/texts';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { TextEditorWithLabel } from 'pages/products/components/elements/forms/TextEditor';

interface CreateCoeFormProps {
  coeData: COEProps;
  setCoeProspectFile: React.Dispatch<React.SetStateAction<any>>;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  handleSubmitForm: (e: any) => Promise<ReactText | undefined | void>;
  label: string;
  isLoading: boolean;
}

export const CreateCOEForm: React.FC<CreateCoeFormProps> = ({
  coeData,
  setCoeProspectFile,
  setValue,
  handleSubmitForm,
  label,
  isLoading,
}) => {
  const [showBonusMessageArea, setShowBonusMessageArea] = useState(
    !!(coeData.bonusMessage && coeData.bonusMessage !== '')
  );

  const handleWithTheBonus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowBonusMessageArea(e.target.checked);
  };
  const history = useHistory();

  const handleChangeValue = useCallback(
    e => {
      setValue({
        ...coeData,
        [e.target.name]:
          e.target.name === 'name'
            ? e.target.value.toUpperCase()
            : e.target.value,
      });
    },
    [coeData]
  );

  const handleChangeDatePicker = useCallback(
    e => {
      if (e.target.name === 'COEDeadline' || e.target.name === 'createdDate') {
        const date = new Date(e.target.value);

        const dateNow = new Date();

        if (isBefore(date, dateNow)) {
          return toast.dark(
            'A data para o prazo de reseva do COE não pode ser anterior a de hoje.'
          );
        }

        handleChangeValue(e);
      }
    },
    [handleChangeValue]
  );

  const handleBonusMessageAndStrategyValue = (e: any, inputName: string) => {
    if (e === '<p><br></p>') {
      setValue({
        ...coeData,
        [inputName]: '',
      });
    } else {
      setValue({
        ...coeData,
        [inputName]: e,
      });
    }
  };

  const handleClickCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper width={['95%', 'auto']}>
        <ModalTitle>{label}</ModalTitle>
        <Flex flexDirection="column" width="100%" height="100%" gap={2}>
          <InputWithLabel
            value={coeData.name}
            label="Nome"
            name="name"
            placeholder="Digite o nome do COE"
            onChange={handleChangeValue}
          />

          <Flex
            width="100%"
            pb={2}
            height="100%"
            alignItems="flex-start"
            gap={5}
            flexDirection="column"
          >
            <Flex width="100%" height="50%" gap={3}>
              <Stack direction="column" width="50%">
                <DatePickerChackra
                  value={coeData.createdDate}
                  label="Reservas até dia"
                  handlePickDate={handleChangeDatePicker}
                  name="createdDate"
                />
                <DatePickerChackra
                  value={coeData.COEDeadline}
                  label="Vencimento do COE"
                  handlePickDate={handleChangeDatePicker}
                  name="COEDeadline"
                />
              </Stack>
              <Stack direction="column" gridGap={5} width="50%">
                <InputWithLabel
                  name="whatsMessage"
                  label="Mensagem Whatsapp"
                  value={coeData.whatsMessage}
                  placeholder="Digite a mensagem"
                  onChange={handleChangeValue}
                />

                <DownloadInput
                  setFile={setCoeProspectFile}
                  label="Upload de Prospecto"
                />
              </Stack>
            </Flex>

            <Flex
              justifyContent="flex-start"
              width="100%"
              height="50%"
              gap={3}
              flexDirection={['column', 'row']}
              overflowY={['auto', 'unset']}
            >
              <Stack width={['100%', '50%']} height={['50%', '100%']}>
                <TextEditorWithLabel
                  label="Estratégia"
                  inputName="strategy"
                  placeholder="Digite a estratégia"
                  value={coeData.strategy ? coeData.strategy : ''}
                  handleBonusMessageAndStrategyOrDescriptionValue={
                    handleBonusMessageAndStrategyValue
                  }
                />
              </Stack>

              <Stack
                width={['100%', '50%']}
                height={['50%', '100%']}
                direction="column"
              >
                {showBonusMessageArea && (
                  <TextEditorWithLabel
                    label="Bonificação"
                    inputName="bonusMessage"
                    placeholder="Digite mensagem de bonificação"
                    value={coeData.bonusMessage ? coeData.bonusMessage : ''}
                    handleBonusMessageAndStrategyOrDescriptionValue={
                      handleBonusMessageAndStrategyValue
                    }
                  />
                )}
                <Flex alignItems="center" justifyContent="flex-end" gap="2">
                  <Checkbox
                    m="0"
                    isChecked={showBonusMessageArea}
                    onChange={e => handleWithTheBonus(e)}
                  />
                  <Text fontSize={['sm', 'md']}>
                    Escrever mensagem de bonificação
                  </Text>
                </Flex>
              </Stack>
            </Flex>
          </Flex>

          <ButtonGroup display="flex" justifyContent="flex-end">
            <SecondaryButton onClick={handleClickCancelButton}>
              Cancelar{' '}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmitForm} isLoading={isLoading}>
              {label}
            </PrimaryButton>
          </ButtonGroup>
        </Flex>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};
