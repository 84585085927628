import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useCreateMeeting } from 'hooksV2/useCreateMeeting';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { ITables } from 'pages/Captation/components/elements/funnelChart/DropdownTable';
import { Advisor } from 'pages/Captation/interfaces';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { MeetingFilialType } from 'pagesV2/LeadDetails/context/useScheduleFirstMeetingContext';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RootStateOrAny, useSelector } from 'react-redux';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { DateUtils } from 'utilsV2/Date';
import { DropdownUtils } from 'utilsV2/Dropdown';
import { MeetingUtils } from 'utilsV2/Meeting';
import { showToast } from 'utilsV2/showToast';
import {
  handleGetAvailableMeetingStatusOptions,
  meetingLocalOptions,
} from './data';

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
  status?: MeetingStatusEnum;
  filial?: MeetingFilialType;
};

export const ScheduleMeetingModal: React.FC<PropsType> = ({
  lead,
  leadType,
  status,
  filial,
}): JSX.Element => {
  const user = useSelector((state: RootStateOrAny) => state.user.profile);

  const [selectedTableId, setSelectedTableId] = useState<string>(user.tableId);

  const { desiredFlow } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();
  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: async () => {
        showToast('Reunião agendada com sucesso!');
        deleteParam([
          'isOpenScheduleMeetingModal',
          'scheduleMeetingAdvisorId',
          'scheduleMeetingLocal',
          'scheduleMeetingDate',
          'scheduleMeetingComplement',
        ]);

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['shPoolLeads']);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
    });

  const { mutateAsync: createMeeting, isLoading: isLoadingCreateMeeting } =
    useCreateMeeting({
      onSuccess: async () => {
        showToast('Reunião agendada com sucesso!');
        deleteParam([
          'isOpenScheduleMeetingModal',
          'scheduleMeetingAdvisorId',
          'scheduleMeetingLocal',
          'scheduleMeetingDate',
          'scheduleMeetingComplement',
        ]);

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['shPoolLeads']);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);

        queryClient.refetchQueries(['leadsProspect']);
      },
    });

  const { data: tables } = useQuery<ITables[]>({
    queryKey: ['tables'],
    queryFn: async () => {
      const response = await hubApiOfficeTables.listTablesOffice();
      return response;
    },
  });

  const { data: advisors } = useQuery<Advisor[]>({
    queryKey: ['advisors', selectedTableId],
    queryFn: async () => {
      const response = await hubApiOfficeTables.listUsersByTable(
        selectedTableId
      );

      return response;
    },
  });

  const selectedAdvisor =
    advisors?.find(item => item.id === getParam('scheduleMeetingAdvisorId')) ||
    user;

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  useEffect(() => {
    if (selectedAdvisor.id === user.id)
      setParam('scheduleMeetingAdvisorId', user.id);
  }, [selectedAdvisor]);

  const isLoading = isLoadingMeetingAction || isLoadingCreateMeeting;

  const meetingDate = getParam('scheduleMeetingDate');
  const meetingLocal = getParam('scheduleMeetingLocal');
  const meetingAdvisorId = getParam('scheduleMeetingAdvisorId');
  const meetingComplement = getParam('scheduleMeetingComplement');
  const {
    filterValue: meetingStatus,
    handleChangeFilterValue: handleChangeMeetingStatusFilter,
  } = useSimpleQueryFilter<MeetingStatusEnum>('scheduleMeetingStatus', {
    initialValue: MeetingUtils.getNextStatus(
      lead?.meeting?.status as MeetingStatusEnum
    ),
  });

  const handleScheduleMeeting = async () => {
    if (!meetingDate) return showToast('Selecione uma data para a reunião');

    if (DateUtils.isInPast(meetingDate))
      return showToast('Escolha uma data futura para a reunião');

    if (!meetingLocal) return showToast('Escolha um local para a reunião');

    if (!meetingAdvisorId)
      return showToast('Escolha um assessor para a reunião');

    if (!lead?.meeting) {
      await createMeeting({
        leadId: lead?.id as string,
        leadType,
        date: meetingDate,
        local: meetingLocal,
        employeeId: meetingAdvisorId,
        complement: meetingComplement,
        status: status || MeetingStatusEnum.SUITABILITY,
        filialId: filial?.id,
        filialName: filial?.city,
      });

      return;
    }

    await meetingAction({
      action: MeetingActionEnum.SCHEDULE,
      leadType,
      meetingId: lead?.meeting?.id || '',
      leadId: lead?.id as string,
      employeeIdParticipant: meetingAdvisorId,
      complement: meetingComplement,
      local: meetingLocal,
      date: meetingDate,
      isFromProspectPage: getParam('isFromProspectPage') === 'true',
      status: meetingStatus,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Agendar nova reunião" />
        <Modal.CloseIcon
          onClick={() => deleteParam('isOpenScheduleMeetingModal')}
        />
      </Modal.Header>

      <Flex gap={2} flexDirection="column">
        <Input.Root>
          <Input.Date
            value={meetingDate}
            onChange={event =>
              setParam('scheduleMeetingDate', event.target.value)
            }
          />
        </Input.Root>

        <Dropdown.Root
          value={meetingLocal}
          onChange={event =>
            setParam('scheduleMeetingLocal', event.target.value)
          }
        >
          <Dropdown.Options
            options={meetingLocalOptions}
            placeholder="Tipo de reunião"
          />
        </Dropdown.Root>

        {!isSalesHunterDesiredFlow && (
          <>
            <Dropdown.Root
              value={selectedTableId}
              onChange={event => setSelectedTableId(event.target.value)}
            >
              <Dropdown.Options
                options={tables?.map(table =>
                  DropdownUtils.toBaseOptions({
                    label: formatNameToCaptalize(table.name),
                    value: table.id,
                  })
                )}
                selected={selectedTableId}
                placeholder="Mesas"
              />
            </Dropdown.Root>
            <Dropdown.Root
              value={meetingAdvisorId}
              onChange={event =>
                setParam('scheduleMeetingAdvisorId', event.target.value)
              }
            >
              <Dropdown.Options
                options={advisors?.map(advisor =>
                  DropdownUtils.toBaseOptions({
                    label: formatNameToCaptalize(advisor.name),
                    value: advisor.id,
                  })
                )}
                selected={meetingAdvisorId}
                placeholder="Assessor"
              />
            </Dropdown.Root>
            <Dropdown.Root
              value={meetingStatus}
              onChange={event =>
                handleChangeMeetingStatusFilter(
                  event.target.value as MeetingStatusEnum
                )
              }
            >
              <Dropdown.Options
                options={handleGetAvailableMeetingStatusOptions(
                  lead?.meeting?.status as MeetingStatusEnum
                )}
                selected={meetingStatus}
                placeholder="Etapa"
              />
            </Dropdown.Root>
          </>
        )}
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Agendar"
          onClick={handleScheduleMeeting}
          isLoading={isLoading}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
