/* eslint import/no-duplicates: off */
import { ptBR } from 'date-fns/locale';
import {
  differenceInDays,
  differenceInMinutes,
  differenceInMonths,
  format,
  isPast,
  parseISO,
  sub,
} from 'date-fns';

interface DateArgumentsProps {
  years: number | undefined;
  months: number | undefined;
  weeks: number | undefined;
  days: number | undefined;
  hours: number | undefined;
  minutes: number | undefined;
  seconds: number | undefined;
}

export function formatDate(date: string): string {
  const parsed = parseISO(date);

  return format(parsed, 'PPP', { locale: ptBR });
}

export function formateDateAndTime(date: string): string {
  const parsed = parseISO(date);

  const formattedString = format(parsed, 'Pp', {
    locale: ptBR,
  }).replace(',', ' às');

  return formattedString;
}

export function formateDateAndTimeNoTimezone(date: string): string {
  const [dataPart, horaPart] = date.split('T');
  const [ano, mes, dia] = dataPart.split('-');
  const [hora, minutos] = horaPart.split(':');
  const formattedString = `${dia}/${mes}/${ano} às ${hora}:${minutos}`;

  return formattedString;
}

export function formatTime(date: string): string {
  const parsed = parseISO(date);

  const formattedTime = format(parsed, 'p', {
    locale: ptBR,
  });

  return formattedTime;
}

export function formatTimeNoTimezone(date: string): string {
  const [dataPart, horaPart] = date.split('T');
  const [hora, minutos] = horaPart.split(':');
  const formattedString = `${hora}:${minutos}`;

  return formattedString;
}

/* dd/mm/aaaa */
export function basicDate(date: string): string {
  const parsed = parseISO(date);

  const formattedString = format(parsed, 'Pp', { locale: ptBR }).split(',')[0];

  return formattedString;
}

export function formatDateToISO(date: string): string {
  return new Date(date).toISOString();
}

export function checkIfDateIsPast(compareDate: string): boolean {
  return isPast(parseISO(compareDate));
}

export function formatCalendaryDate(date: string): string {
  const parsed = new Date(date);

  return `${parsed
    .getDate()
    .toString()
    .padStart(2, '0')} ${parsed.toLocaleString('pt-BR', {
    month: 'short',
  })} de ${parsed.getUTCFullYear()}`;
}

export function createIntervalDate(dateArguments: DateArgumentsProps): {
  today: string;
  subDays: string;
} {
  const result = sub(new Date(), dateArguments);

  const today = new Date().toISOString().split('T')[0];
  const subDays = result.toISOString().split('T')[0];

  return { today, subDays };
}

export function formatDateToRhSearch(): string {
  const date = new Date();

  const year = date.getFullYear();
  const day = date.getDate();
  const monthNumber =
    date.getMonth() === 0 ? date.getMonth() + 1 : date.getMonth();
  const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;

  return `${year}-${month}-${day}`;
}

export function checkIfMeetingIsNear(meetingDate: string): number {
  const parsed = parseISO(meetingDate);

  const now = new Date();
  const difference = differenceInMinutes(parsed, now);

  return difference;
}

export function checkDifferenceInDays(date: string): number {
  const now = new Date();
  const parsed = parseISO(date);
  const differenceDays = differenceInDays(now, parsed);

  return differenceDays;
}

export function checkDifferenceInMonths(date: string): number {
  const now = new Date();
  const parsed = parseISO(date);
  const differenceMonths = differenceInMonths(now, parsed);

  return differenceMonths;
}

export function transformYearMonthInStringMonth(date: string): string {
  const newDate = date.split('-');
  const parseDateMonth = format(
    new Date(`${newDate[1]},${newDate[0]}`),
    'LLLL',
    {
      locale: ptBR,
    }
  );

  return parseDateMonth;
}

export function checkIfThereIsAlreadyTwoDaysDifference(
  data: any
): void | boolean {
  const currencyDate = new Date();

  const dateOfMeeting = new Date(data.date);

  const daysOfDifference = differenceInDays(currencyDate, dateOfMeeting);

  if (daysOfDifference >= 2) {
    return true;
  }
  return false;
}

export function checkOneDayLate(data: any): void | boolean {
  const currencyDate = new Date();

  const dateOfMeeting = new Date(data.date);

  const daysOfDifference = differenceInDays(currencyDate, dateOfMeeting);

  if (daysOfDifference >= 1) {
    return true;
  }
  return false;
}
