import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { PageWrapper } from 'components/elements/wrappers';
import CreateIPOForm from 'pages/products/components/modules/ipo/CreateIPOForm';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { IPOProps } from 'pages/products/interfaces';
import schema from './schema';

export const CreateIPO: React.FC = () => {
  const history = useHistory();
  const [prospect, setProspect] = useState('');
  const [ipoData, setIpoData] = useState<IPOProps>({} as IPOProps);
  const [productType, setProductType] = useState('IPO');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();

      setIsLoading(true);

      if (Number(ipoData.priceMax) < Number(ipoData.priceMin)) {
        setIsLoading(false);
        return toast.dark(
          'O valor máximo da aplicação deve ser maior que o valor mínimo.'
        );
      }

      try {
        if (productType === 'IPO') {
          await schema.schemaIPO.validate(ipoData, {
            abortEarly: false,
          });
        }

        if (productType === 'SUB') {
          await schema.schemaSubscription.validate(ipoData, {
            abortEarly: false,
          });
        }

        const formData = new FormData();

        formData.append('prospect', prospect);
        formData.append('title', ipoData.title);
        formData.append('ticker', ipoData.ticker);
        formData.append('bookingDeadlines', ipoData.bookingDeadlines);
        formData.append('financialSettlements', ipoData.financialSettlements);
        formData.append('priceMax', ipoData.priceMax);
        formData.append('priceMin', ipoData.priceMin);
        formData.append('disclosureOfResults', ipoData.disclosureOfResults);
        formData.append('ramboMessage', ipoData.ramboMessage);
        formData.append('startOfNegotiations', ipoData.startOfNegotiations);
        formData.append('strategy', ipoData.strategy);
        formData.append('whatsMessage', ipoData.whatsMessage);

        formData.append('offer', ipoData.offer);
        formData.append('pricePerQuote', ipoData.pricePerQuote);
        formData.append('marketQuote', ipoData.marketQuote);
        formData.append('discount', ipoData.discount);
        formData.append('dividendYeld', ipoData.dividendYeld);
        formData.append('description', ipoData.description);
        formData.append('bonusMessage', ipoData.bonusMessage || '');

        await hubApiProducts
          .postCreateIPO(formData)
          .then(() => {
            toast.dark('IPO criado com sucesso.');
            history.goBack();
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            if (error.response.data !== 'Internal server error') {
              toast.dark(error.response?.data.message);
            }
          });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o COE, por favor tente novamente.'
          );
        }
      }
    },
    [ipoData, history, prospect]
  );

  return (
    <PageWrapper>
      <CreateIPOForm
        isLoading={isLoading}
        ipoData={ipoData}
        handleSubmitForm={handleSubmitForm}
        setIPOProspectFile={setProspect}
        setValue={setIpoData}
        title="IPO"
        nameButton="Criar IPO"
        productType={productType}
        setProductType={setProductType}
      />
    </PageWrapper>
  );
};
