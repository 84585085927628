const techOptions = [
  { title: 'Encontrei um bug no HUB', value: 'high' },
  { title: 'Problemas com computador', value: 'return' },
  { title: 'Problemas com e-mail', value: 'no_interest' },
  { title: 'Problemas com internet', value: 'no_answer' },
  { title: 'Feedback Mundo Invest', value: 'feedback_mi' },
  { title: 'Outros', value: 'no_exist' },
];

export default techOptions;
