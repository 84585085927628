import {
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import { InputWithLabel } from 'components/elements/forms';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { useEffect, useState } from 'react';
import { RiCoinsLine } from 'react-icons/ri';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { transformReaisInMibs } from 'utils/transformReaisInMibs';
import { useSelector } from 'react-redux';
import { queryClient } from 'App';
import {
  ModalTitle,
  TitleWithSideLine,
} from '../../../../../../components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from '../../../../../../components/elements/wrappers';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../../../components/elements/buttons';
import { IReduxState } from '../../../../../../shared/interfaces';

interface IProps {
  setIsOpenBuyLeadsModal: (value: boolean) => void;
  isManagement?: boolean;
  poolMISession?: boolean;
  primaryButtonAction?: (ddd: string, rangePL: string) => void;
  isLoadingPoolMISession?: boolean;
  employeeMIBs?: number;
  estimateTotalToPay?: number;
  setEstimateTotalToPay?: (estimate: number) => void;
  rangeSlider: IRangeSlider;
}

interface ILeadsPricingVariables {
  declinedLeadCashbackPercentage: number;
  efficiencyPercentage: number;
  pricePerLead: number;
  pricePerMillion: Record<IPLTag, number>;
  maxLeadsPrices: Record<IPLTag, number>;
}

interface IRangeSlider {
  [key: number]: string;
}

export type IPLTag =
  | 'MI 50k'
  | 'MI 100k'
  | 'MI 250k'
  | 'MI 500k'
  | 'MI 750k'
  | 'MI 1kk'
  | 'MI 5kk'
  | 'MI 5kk+'
  | 'ALPHA';

const labelStyles = {
  mt: '2',
  ml: '-3.5',
  fontSize: 'sm',
  color: 'rgba(70, 70, 70, 1)',
};

export const BuyLeadsModal: React.FC<IProps> = ({
  setIsOpenBuyLeadsModal,
  isManagement,
  poolMISession = false,
  primaryButtonAction = () => {},
  isLoadingPoolMISession,
  employeeMIBs,
  estimateTotalToPay,
  setEstimateTotalToPay,
  rangeSlider,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [sliderRange, setSliderRange] = useState<number[]>([0, 12]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leadsQuantity, setLeadsQuantity] = useState('');
  const [employees, setEmployees] = useState<
    { id: string; name: string; employeeType: 'SH' | 'AAI' }[]
  >([]);
  const [employee, setEmployee] = useState<string>('');
  const [phoneDDD, setPhoneDDD] = useState('');
  const [estimateToPayInPurchaseOrder, setEstimateToPayInPurchaseOrder] =
    useState(0);
  const [openWarningsModal, setOpenWarningsModal] = useState(false);

  const { data: leadsPricingVariables } = useQuery<ILeadsPricingVariables>(
    ['leadsPricingVariables'],
    async () => {
      const response = await hubApiCaptation.getLeadsPricingVariables();
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const maxLeadsPrices = leadsPricingVariables?.maxLeadsPrices;

  const handleGetLeadsValueRange = (): string[] => {
    const leadsValue: string[] = [];

    let lastPLTagInSliderRange = sliderRange[1];
    const firstPLTagInSliderRange = sliderRange[0] === 0 ? 0 : sliderRange[0];

    while (lastPLTagInSliderRange >= firstPLTagInSliderRange) {
      const plTag = rangeSlider[lastPLTagInSliderRange];
      leadsValue.push(plTag);
      lastPLTagInSliderRange -= 12;
    }

    return leadsValue.reverse();
  };

  const handlerTranslateLeadsValueRange = (): string => {
    const values = handleGetLeadsValueRange();

    return `Leads de ${
      sliderRange[0] === 0 ? '0' : values[0]?.split('MI')[1]
    } até ${values?.at(-1)?.split('MI')[1]}`;
  };

  const handleFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (isManagement && employee === '') {
        setIsLoading(false);
        return toast.dark(`Selecione um colaborador.`);
      }

      if (leadsQuantity === '' || Number(leadsQuantity) === 0) {
        setIsLoading(false);
        return toast.dark(
          `Selecione a quantidade de leads que deseja comprar.`
        );
      }

      if (
        estimateTotalToPay &&
        employeeMIBs &&
        employeeMIBs < estimateTotalToPay
      ) {
        setIsLoading(false);
        return toast.dark(`Sem mibs o suficiente para comprar os leads.`);
      }

      const leadsValue = handleGetLeadsValueRange();

      await hubApiCaptation.leadsPurchased({
        employee: {
          name: employee ? employee.split('|')[1] : user.name,
          id: user.id,
        },
        leadsValue,
        quantity: Number(leadsQuantity),
      });

      await queryClient.invalidateQueries(['leadsPurchaseOrders']);

      setIsOpenBuyLeadsModal(false);
      toast.dark('Ordem de compra aberta com sucesso!');
    } catch (err) {
      return toast.dark(`Erro ao comprar leads. Tente novamente mais tarde.`);
    }
    setIsLoading(false);
  };

  const handleActionButton = () => {
    setOpenWarningsModal(false);
    primaryButtonAction(phoneDDD, handlerTranslateLeadsValueRange() as any);
  };

  const handleCalculateEstimateToPay = (qtd: string) => {
    if (!maxLeadsPrices) {
      return;
    }
    const firstPLTag = rangeSlider[sliderRange[0]];

    const maxPriceFirstPLTag = maxLeadsPrices[firstPLTag as IPLTag];

    const lastPLTag = rangeSlider[sliderRange[1]];
    const maxPriceLastPLTag = maxLeadsPrices[lastPLTag as IPLTag];

    const sum = ((maxPriceFirstPLTag + maxPriceLastPLTag) / 2) * Number(qtd);

    if (poolMISession) {
      setEstimateTotalToPay && setEstimateTotalToPay(transformReaisInMibs(sum));
    } else {
      setEstimateToPayInPurchaseOrder(transformReaisInMibs(sum));
    }
  };

  const handleGetEmployees = async () => {
    const response = await hubApiCaptation.listAllEmployees();
    setEmployees(response);
  };

  useEffect(() => {
    if (poolMISession) {
      setLeadsQuantity('1');
      handleCalculateEstimateToPay('1');
    }
    handleCalculateEstimateToPay(leadsQuantity);
  }, [sliderRange]);

  useEffect(() => {
    if (poolMISession) {
      handleCalculateEstimateToPay('1');
    }
    handleCalculateEstimateToPay(leadsQuantity);
  }, [leadsQuantity, leadsPricingVariables]);

  useEffect(() => {
    if (isManagement) handleGetEmployees();
    if (poolMISession) {
      handleCalculateEstimateToPay('1');
    }
    handleCalculateEstimateToPay(leadsQuantity);
  }, []);

  return (
    <Flex
      w="100%"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="space-between"
      borderRadius={8}
      px={6}
      pt={4}
      pb={4}
      h="calc(100vh - 400px)"
    >
      <Flex flexDirection="column" gap={3}>
        <TitleWithSideLine>Captação Pool Mundo Invest</TitleWithSideLine>

        {isManagement && (
          <DropdownWithLabel
            mb={{ base: '2', md: '4' }}
            onChange={e => setEmployee(e.target.value)}
            label="Selecione um colaborador para receber os leads"
            placeholder=""
          >
            <option key="" value="">
              Selecione um colaborador
            </option>
            {employees.map((item, index) => (
              <option
                key={`${index}-${item.id}`}
                value={`${item.id}|${item.name}`}
              >
                {formatNameToCaptalize(item.name)}
              </option>
            ))}
          </DropdownWithLabel>
        )}

        <Flex flexDirection="column" gap={5} my={5}>
          <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
            {poolMISession
              ? 'Selecione a faixa de PL dos leads que serão exibidos:'
              : 'Selecione a faixa de PL dos leads:'}
          </Text>
          <RangeSlider
            defaultValue={[
              Object.keys(rangeSlider)[0] as unknown as number,
              Object.keys(rangeSlider)[1] as unknown as number,
            ]}
            step={12}
            max={
              Object.keys(rangeSlider)[
                Object.keys(rangeSlider).length - 1
              ] as unknown as number
            }
            onChangeEnd={val => {
              setSliderRange(val);
            }}
          >
            {Object.keys(rangeSlider).map(key => {
              const value = Number(key);
              const tag =
                key === '0' && poolMISession
                  ? '0'
                  : rangeSlider[value]?.split('MI')[1];

              return (
                <RangeSliderMark value={value} {...labelStyles}>
                  {tag}
                </RangeSliderMark>
              );
            })}
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} background="#939393" />
            <RangeSliderThumb index={1} background="#939393" />
          </RangeSlider>
        </Flex>

        <Flex justifyContent="center">
          <Text fontWeight="bold" fontSize="lg" color="rgba(70, 70, 70, 1)">
            {handlerTranslateLeadsValueRange()}
          </Text>
        </Flex>

        {poolMISession && (
          <Flex flexDirection="column">
            <InputWithLabel
              label="DDD (opcional): "
              placeholder="11"
              type="text"
              mb={5}
              onChange={e => {
                setPhoneDDD(e.target.value);
              }}
              maxLength={2}
            />
            <Flex alignItems="center" gap={2} mb={2}>
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                Estimativa de preço por lead (MIBs)
              </Text>
              <RiCoinsLine size={20} color="#FFB32C" />
            </Flex>
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Não atendeu:{' '}
              <Text color="rgba(70, 70, 70, 1)" as="span">
                {Number.isNaN(estimateTotalToPay || 0)
                  ? 0
                  : Math.floor((estimateTotalToPay || 0) * 0.1)}
              </Text>
            </Text>
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Reunião agendada:{' '}
              <Text color="rgba(70, 70, 70, 1)" as="span">
                {Number.isNaN(estimateTotalToPay || 0)
                  ? 0
                  : Math.floor((estimateTotalToPay || 0) * 0.5)}
              </Text>
            </Text>
          </Flex>
        )}

        {!poolMISession && (
          <Flex flexDirection="column">
            <InputWithLabel
              label="Quantidade: "
              placeholder="5"
              type="number"
              mb={5}
              onChange={e => {
                setLeadsQuantity(e.target.value);
                handleCalculateEstimateToPay(e.target.value);
              }}
            />
            <Flex alignItems="center" gap={2}>
              <Text fontSize="lg" color="#fff">
                Estimativa em MIBs:{' '}
                {Number.isNaN(estimateToPayInPurchaseOrder)
                  ? 0
                  : Math.floor(estimateToPayInPurchaseOrder || 0)}
              </Text>
              <RiCoinsLine size={24} color="#FFB32C" />
            </Flex>
          </Flex>
        )}
      </Flex>
      <BaseButtonGroup mt="auto">
        {poolMISession ? (
          <PrimaryButton
            onClick={() => setOpenWarningsModal(true)}
            isLoading={isLoadingPoolMISession}
          >
            Iniciar captação
          </PrimaryButton>
        ) : (
          <>
            <SecondaryButton onClick={() => setIsOpenBuyLeadsModal(false)}>
              Voltar
            </SecondaryButton>
            <PrimaryButton
              onClick={event => handleFormSubmit(event)}
              isLoading={isLoading}
            >
              Abrir ordem de compra
            </PrimaryButton>
          </>
        )}
      </BaseButtonGroup>
      <ModalWrapper
        isOpen={openWarningsModal}
        onClose={() => setOpenWarningsModal(false)}
      >
        <DefaultModalContentWrapper maxW="500px" gap={1}>
          <ModalTitle>Lembre-se:</ModalTitle>
          <Text>- Não armazenar dados dos usuários que não atenderem</Text>
          <Text>- Não enviar whatsapp para as pessoas</Text>
          <Text>
            - Caso o cliente atenda e não tenha interesse, decline o lead
          </Text>

          <BaseButtonGroup alignItems="flex-end">
            <SecondaryButton onClick={() => setOpenWarningsModal(false)}>
              Voltar
            </SecondaryButton>
            <PrimaryButton onClick={handleActionButton} mt={6}>
              Ok, entendi
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </Flex>
  );
};
