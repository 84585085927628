import React, { useState, ChangeEvent, useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import formatValue from 'utils/formatValue';
import UploadButton from 'components/elements/forms/UploadButton';
import DatePicker from 'components/elements/others/DatePicker';
import {
  SecondaryButton,
  PrimaryButton,
  BackPageButton,
} from 'components/elements/buttons';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { errorHandler } from 'utils/errorHandler';
import { File, CustomerProps } from 'pages/Financials/interfaces';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';

import history from 'services/history';
import { btgClientsHead, today, initialFileValue } from './data';

export const FinancialCustomers: React.FC = () => {
  const [infosTable, setInfosTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewReady, setPreviewReady] = useState(false);
  const [date, setDate] = useState(today);
  const [file, setFile] = useState(initialFileValue);

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const handleSendPreview = async () => {
    const formData = new FormData();
    formData.append('btg', file as File);

    setIsLoading(true);

    await hubApiFinancial
      .postClientsFile(formData, String(date))
      .then(async () => {
        await hubApiFinancial
          .getClientsPreview()
          .then(response => {
            setInfosTable(response);
            setIsLoading(false);
            setPreviewReady(true);
          })
          .catch(() => {
            toast.dark(
              'Ocorreu um erro ao buscar preview, tente enviar o arquivo novamente.'
            );
            setIsLoading(false);
          });
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao enviar preview. Tente novamente');
        setIsLoading(false);
      });
  };

  const handleConfirmFile = async () => {
    const alreadySendFile = await hubApiFinancial.checkIfAlreadySendFile(date);

    if (alreadySendFile)
      return toast.dark('Já existe um arquivo enviado nesta data.');

    await hubApiFinancial
      .attClientsFile({ status: 'importado' })
      .then(() => {
        toast.dark('Arquivo enviado com sucesso');
        setInfosTable([]);
        setPreviewReady(false);
        setFile(initialFileValue);
      })
      .catch(error => errorHandler(error));
  };

  const formatDate = (date: string) => {
    const parsedDate = new Date(date);

    return `${parsedDate.getFullYear().toString()}/${(parsedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${parsedDate
      .getDate()
      .toString()
      .padStart(2, '0')}-${parsedDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${parsedDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="white"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Financeiro - Clientes
      </MainPageTitle>

      <Flex w="100%" maxWidth={['95vw', '1080px']} flexDir="column">
        <Flex
          mb="12px"
          flexDir={['column', 'row']}
          justifyContent="left"
          alignItems={['flex-start', 'flex-end']}
          gridGap={[2, 0]}
        >
          <Flex alignItems={'flex-end'} justifyContent={'center'}>
            <Box maxWidth="200px">
              <UploadButton
                label={file.name}
                accept=".csv"
                onChange={handlePickFile}
              />
            </Box>

            <Box ml={['0px', '4px']} maxWidth="150px">
              <DatePicker
                handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
                  setDate(e.target.value)
                }
                value={date}
                full
                fullColum
                style
                type="date"
                directionRow
              />
            </Box>
          </Flex>
          <Box maxWidth="150px">
            <SecondaryButton
              isLoading={isLoading}
              py="17px"
              onClick={handleSendPreview}
            >
              Enviar Preview
            </SecondaryButton>
          </Box>
        </Flex>

        <TableBase
          headData={btgClientsHead}
          width={['100%', 'fit-content']}
          maxW={['95vw', '1080px']}
          minHeight="200px"
          maxH="calc(100vh - 320px)"
          overflow="auto"
        >
          {infosTable.map((info: CustomerProps, index) => (
            <TableRow key={index}>
              {Object.keys(info).map((key, index) =>
                key === 'birthday' ||
                key === 'startDate' ||
                key === 'bond' ||
                key === 'firstContribution' ||
                key === 'lastContribution' ? (
                  <TableData minW="200px" key={index}>
                    {formatDate(info[key])}
                  </TableData>
                ) : key === 'pl' ||
                  key === 'account' ||
                  key === 'funds' ||
                  key === 'rf' ||
                  key === 'rv' ||
                  key === 'transitValue' ||
                  key === 'annualIncome' ||
                  key === 'declaredPL' ||
                  key === 'contribution' ||
                  key === 'withdrawal' ? (
                  <TableData minW="200px" key={index}>
                    {formatValue(Number(info[key]))}
                  </TableData>
                ) : (
                  key !== 'created_at' &&
                  key !== 'date' &&
                  key !== 'id' &&
                  key !== 'status' &&
                  key !== 'updated_at' && (
                    <TableData minW="200px" key={index}>
                      {info[key]}
                    </TableData>
                  )
                )
              )}
            </TableRow>
          ))}
        </TableBase>

        <Flex justifyContent="flex-end">
          {previewReady && (
            <PrimaryButton
              mt="3"
              isLoading={isLoading}
              onClick={handleConfirmFile}
            >
              Confirmar e enviar arquivo
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
