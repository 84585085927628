/* eslint-disable no-nested-ternary */
import { Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import { IHandleMibsOperation, ISourceAction } from '../dtos';
import { IWalletNames } from '../hooks/useMibsOperations';

interface IProps {
  handleFormSumit: IHandleMibsOperation;
  mibsQuantity: string;
  operation: 'WITHDRAW' | 'DEPOSIT';
  sourceAction: ISourceAction;
  sourceWallet: IWalletNames;
  destinationWallet: IWalletNames;
  employeeId?: string;
  customTitle?: string;
  transactionResponsible?: string;
  setIsOpenFatherModal(value: boolean): void;
  setIsOpenConfirmTransactionModal(value: boolean): void;
}

export const SensitiveTransactionModal: React.FC<IProps> = ({
  setIsOpenConfirmTransactionModal,
  handleFormSumit,
  mibsQuantity,
  setIsOpenFatherModal,
  operation,
  sourceAction,
  sourceWallet,
  employeeId,
  customTitle,
  transactionResponsible,
  destinationWallet,
}) => {
  const user = useSelector(
    (state: IReduxState) => state.user.profile
  ) as IUserSelector;

  const primaryButtonText =
    sourceAction === 'MIBS_REDEEM'
      ? 'Tenho certeza, vender MIBs'
      : sourceAction === 'MIBS_PURCHASED'
      ? 'Tenho certeza, Comprar MIBs'
      : 'Tenho certeza';

  const modalDescription =
    sourceAction === 'MIBS_REDEEM'
      ? 'Aviso importante! Este resgate trocará seus MIBs da carteira pessoal por valor financeiro no final do mês, pagos junto com sua remuneração, só confirme se tiver certeza'
      : sourceAction === 'MIBS_PURCHASED'
      ? 'Aviso importante! Esta compra de MIBs será descontado da sua remuneração, só confirme se tiver certeza'
      : 'Aviso importante! Esta ação é irreversível, só confirme se tiver certeza';

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleFormSumit.mutateAsync({
      employeeId: employeeId || user.id,
      mibsQuantity,
      operation,
      sourceWallet,
      sourceAction,
      customTitle,
      transactionResponsible,
      destinationWallet,
    });

    setIsOpenConfirmTransactionModal(false);
    setIsOpenFatherModal(false);
  };

  return (
    <BaseModalFormCard
      title="Deseja confirmar essa operação?"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={() => setIsOpenConfirmTransactionModal(false)}
      isModal
      primaryButtonText={primaryButtonText}
      isLoading={handleFormSumit.isLoading}
    >
      <Text color="#fff">{modalDescription}</Text>
    </BaseModalFormCard>
  );
};
