import { useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IMibsHistory } from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export const useEmployeeMibsHistory = (): UseQueryResult<IMibsHistory[]> => {
  return useQuery<IMibsHistory[]>(
    MIBS_QUERIES_KEYS.employeeMibsHistory,
    async () => {
      const response =
        (await hubApiCaptation.getMibsHistory()) as IMibsHistory[];

      return response;
    },
    {
      onError: () =>
        toast.dark('Erro ao mostrar mibs. Tente novamente mais tarde.'),
      refetchOnWindowFocus: true,
    }
  );
};
