import React, { ReactText, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Checkbox, Flex, Stack, Text } from '@chakra-ui/react';

import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { InputWithLabel } from 'components/elements/forms';
import DownloadInput from 'pages/products/components/elements/buttons/DownloadInput';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

import { IPOProps } from 'pages/products/interfaces';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { InputNumberWithLabel } from 'pages/products/components/elements/forms/InputNumberWithLabel';
import { TextEditorWithLabel } from 'pages/products/components/elements/forms/TextEditor';

interface CreateIPOFormProps {
  ipoData: IPOProps;
  setIPOProspectFile: React.Dispatch<React.SetStateAction<any>>;
  setValue: React.Dispatch<React.SetStateAction<IPOProps>>;
  handleSubmitForm: (e: any) => Promise<ReactText | undefined | void>;
  title: string;
  nameButton: string;
  productType?: string;
  setProductType?: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

// TODO: REFATORAR EXIBIÇÃO DOS CARDS

const CreateIPOForm: React.FC<CreateIPOFormProps> = ({
  handleSubmitForm,
  ipoData,
  setValue,
  setIPOProspectFile,
  productType,
  setProductType,
  isLoading,
}) => {
  const history = useHistory();

  const [showBonusMessageArea, setShowBonusMessageArea] = useState(
    !!(ipoData.bonusMessage && ipoData.bonusMessage !== '')
  );

  const handleChangeValue = useCallback(
    e => {
      const filds = ['title', 'ticker'];
      setValue({
        ...ipoData,
        [e.target.name]: filds.includes(e.target.name)
          ? e.target.value.toUpperCase()
          : e.target.value,
      });
    },
    [ipoData]
  );

  const handleBonusMessageAndStrategyOrDescriptionValue = (
    e: any,
    inputName: string
  ) => {
    if (e === '<p><br></p>') {
      setValue({
        ...ipoData,
        [inputName]: '',
      });
    } else {
      setValue({
        ...ipoData,
        [inputName]: e,
      });
    }
  };

  const handleWithTheBonus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowBonusMessageArea(e.target.checked);
  };

  const handleChangeCurrencyPrice = useCallback(
    (e: any, fieldName: string) => {
      setValue({
        ...ipoData,
        [fieldName]: e,
      });
    },
    [ipoData]
  );

  const handleChangePrice = useCallback(
    e => {
      if (!!ipoData.priceMax && e.target.name === 'priceMin') {
        if (Number(ipoData.priceMax) < Number(e.target.value)) {
          return toast.dark(
            'O Preço máximo não deve ser menor que o Preço mínimo'
          );
        }
      }

      handleChangeValue(e);
    },
    [handleChangeValue, ipoData]
  );

  const handleChangeDatePicker = useCallback(
    e => {
      const date = new Date(e.target.value);
      const dateNow = new Date();
      if (isBefore(date, dateNow)) {
        return toast.dark('As datas não podem ser anteriores a data de hoje.');
      }
      handleChangeValue(e);
    },
    [handleChangeValue]
  );

  const handleOnClickCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper maxW={['95%', '760px']}>
        {productType && (
          <Flex justifyContent="center" mb={6}>
            <Text
              as="span"
              px={4}
              w="128.8px"
              textAlign="center"
              background={productType === 'IPO' ? '#2a5dea' : 'background.500'}
              color={productType === 'IPO' ? 'white' : 'white'}
              fontWeight={productType === 'IPO' ? 'bold' : 'inherit'}
              borderTopLeftRadius="10px"
              borderBottomLeftRadius="10px"
              fontSize="sm"
              onClick={() => {
                setValue({} as IPOProps);
                setProductType && setProductType('IPO');
              }}
              cursor="pointer"
              pt={1}
              pb={0.5}
            >
              IPO
            </Text>
            <Text
              as="span"
              px={4}
              background={productType === 'SUB' ? '#2a5dea' : 'background.500'}
              color={productType === 'SUB' ? 'white' : 'white'}
              fontWeight={productType === 'SUB' ? 'bold' : 'inherit'}
              borderTopRightRadius="10px"
              borderBottomRightRadius="10px"
              fontSize="sm"
              onClick={() => {
                setValue({} as IPOProps);
                setProductType && setProductType('SUB');
              }}
              cursor="pointer"
              pt={1}
              pb={0.5}
            >
              SUBSCRIÇÃO
            </Text>
          </Flex>
        )}
        {/* a presença de um title indica que o formulário está sendo exibido para um update de IPO */}
        {((productType === 'IPO' && ipoData.title !== 'undefined') ||
          (!ipoData.title && productType === 'IPO') ||
          (!productType && ipoData.title && ipoData.title !== 'undefined') ||
          (!productType &&
            ipoData.title !== 'undefined' &&
            ipoData.title === '')) && (
          <Flex
            w="100%"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
            height={['500px', 'auto']}
            overflowY={['auto', 'unset']}
          >
            <Stack flexDirection="column" w={['95%', '48%']}>
              <InputWithLabel
                name="title"
                value={ipoData.title}
                type="text"
                label="Título"
                placeholder="Título"
                onChange={e => handleChangePrice(e)}
              />

              <InputWithLabel
                name="ticker"
                value={ipoData.ticker}
                type="text"
                label="Ticker"
                placeholder="Ticker"
                onChange={e => handleChangePrice(e)}
              />

              <DatePickerChackra
                value={ipoData.bookingDeadlines}
                label="Reservas até dia"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="bookingDeadlines"
              />

              <DatePickerChackra
                value={ipoData.disclosureOfResults}
                label="Divulgação de resultado"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="disclosureOfResults"
              />
              <DatePickerChackra
                value={ipoData.startOfNegotiations}
                label="Início das negociações"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="startOfNegotiations"
              />
              <DatePickerChackra
                value={ipoData.financialSettlements}
                label="Liquidações financeiras"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="financialSettlements"
              />

              <InputWithLabel
                name="whatsMessage"
                value={ipoData.whatsMessage}
                label="Mensagem Whats"
                placeholder="Digite a mensagem que vai ser copiada"
                onChange={e => handleChangeValue(e)}
              />
              <DownloadInput
                label="Upload de Prospecto"
                setFile={setIPOProspectFile}
              />
            </Stack>

            <Stack flexDir="column" w={['95%', '48%']}>
              <Flex
                justifyContent="space-between"
                w="100%"
                gridGap={5}
                pt={[2, 0]}
              >
                <InputWithLabelAndFormatingToMoney
                  label="Preço mínimo"
                  name="priceMin"
                  value={ipoData.priceMin}
                  placeholder="Preço mínimo"
                  onChange={e => handleChangeCurrencyPrice(e, 'priceMin')}
                />

                <InputWithLabelAndFormatingToMoney
                  label="Preço máximo"
                  name="priceMax"
                  placeholder="Preço máximo"
                  value={ipoData.priceMax}
                  onChange={e => handleChangeCurrencyPrice(e, 'priceMax')}
                />
              </Flex>
              <TextEditorWithLabel
                label="Estratégia"
                inputName="strategy"
                placeholder="Digite a estratégia"
                value={ipoData.strategy ? ipoData.strategy : ''}
                handleBonusMessageAndStrategyOrDescriptionValue={
                  handleBonusMessageAndStrategyOrDescriptionValue
                }
              />
              {showBonusMessageArea && (
                <TextEditorWithLabel
                  label="Bonificação"
                  inputName="bonusMessage"
                  placeholder="Digite mensagem de bonificação"
                  value={ipoData.bonusMessage ? ipoData.bonusMessage : ''}
                  handleBonusMessageAndStrategyOrDescriptionValue={
                    handleBonusMessageAndStrategyOrDescriptionValue
                  }
                />
              )}

              <Flex alignItems="center" justifyContent="flex-end" gap="2">
                <Checkbox
                  m="0"
                  isChecked={showBonusMessageArea}
                  onChange={e => handleWithTheBonus(e)}
                />
                <Text>Escrever mensagem de bonificação</Text>
              </Flex>
            </Stack>
          </Flex>
        )}

        {((ipoData.title === 'undefined' && !productType) ||
          (!ipoData.title && productType === 'SUB')) && (
          <Flex
            w="100%"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
            height={['500px', 'auto']}
            overflowY={['auto', 'unset']}
          >
            <Stack flexDirection="column" w={['95%', '48%']}>
              <InputWithLabel
                name="ticker"
                value={ipoData.ticker}
                type="text"
                label="Ticker"
                placeholder="Ticker"
                onChange={e => handleChangePrice(e)}
              />

              <InputWithLabel
                name="offer"
                value={ipoData.offer}
                type="text"
                label="Oferta"
                placeholder="Oferta"
                onChange={e => handleChangePrice(e)}
              />

              <DatePickerChackra
                value={ipoData.bookingDeadlines}
                label="Reservas até dia"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="bookingDeadlines"
              />

              <DatePickerChackra
                value={ipoData.financialSettlements}
                label="Liquidações financeiras"
                handlePickDate={e => handleChangeDatePicker(e)}
                name="financialSettlements"
              />

              <Flex justifyContent="space-between" w="100%" gridGap={[2, 5]}>
                <InputWithLabelAndFormatingToMoney
                  label="Preço por cota"
                  name="pricePerQuote"
                  value={ipoData.pricePerQuote}
                  placeholder="Ex: 1.000"
                  onChange={e => handleChangeCurrencyPrice(e, 'pricePerQuote')}
                />

                <InputWithLabelAndFormatingToMoney
                  label="Cota à mercado"
                  name="marketQuote"
                  value={ipoData.marketQuote}
                  placeholder="Ex: 1.000"
                  onChange={e => handleChangeCurrencyPrice(e, 'marketQuote')}
                />
              </Flex>

              <Flex
                justifyContent="space-between"
                w="100%"
                gridGap={[2, 5]}
                alignItems={['flex-end', 'center']}
              >
                <InputNumberWithLabel
                  w="100%"
                  stepper={false}
                  label="Desconto (%)"
                  value={ipoData.discount}
                  place="Ex: 10"
                  name="discount"
                  onChange={e => handleChangeCurrencyPrice(e, 'discount')}
                />

                <InputNumberWithLabel
                  w="100%"
                  stepper={false}
                  label="DY (últimos 12 meses) (%)"
                  value={ipoData.dividendYeld}
                  place="Ex: 8.2"
                  name="dividendYeld"
                  onChange={e => handleChangeCurrencyPrice(e, 'dividendYeld')}
                />
              </Flex>
              <InputWithLabel
                name="whatsMessage"
                value={ipoData.whatsMessage}
                label="Mensagem Whats"
                placeholder="Digite a mensagem que vai ser copiada"
                onChange={e => handleChangeValue(e)}
              />
            </Stack>

            <Stack flexDir="column" w={['95%', '48%']} pt={[2, 0]}>
              <TextEditorWithLabel
                label="Descrição"
                inputName="description"
                placeholder="Digite a descrição"
                value={ipoData.description ? ipoData.description : ''}
                handleBonusMessageAndStrategyOrDescriptionValue={
                  handleBonusMessageAndStrategyOrDescriptionValue
                }
              />

              {showBonusMessageArea && (
                <TextEditorWithLabel
                  label="Bonificação"
                  inputName="bonusMessage"
                  placeholder="Digite mensagem de bonificação"
                  value={ipoData.bonusMessage ? ipoData.bonusMessage : ''}
                  handleBonusMessageAndStrategyOrDescriptionValue={
                    handleBonusMessageAndStrategyOrDescriptionValue
                  }
                />
              )}

              <Flex alignItems="center" justifyContent="flex-end" gap="1">
                <Checkbox
                  m="0"
                  isChecked={showBonusMessageArea}
                  onChange={e => handleWithTheBonus(e)}
                />
                <Text>Escrever mensagem de bonificação</Text>
              </Flex>
            </Stack>
          </Flex>
        )}
        <ButtonGroup justifyContent="flex-end" pt={4}>
          <SecondaryButton onClick={handleOnClickCancelButton}>
            Cancelar{' '}
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmitForm} isLoading={isLoading}>
            {productType === 'IPO' && 'Criar IPO'}
            {productType === 'SUB' && 'Criar Subscrição'}
            {productType !== 'IPO' &&
              productType !== 'SUB' &&
              'Salvar alterações'}
          </PrimaryButton>
        </ButtonGroup>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};

export default CreateIPOForm;
