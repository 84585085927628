import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useUpdateClientMeeting } from 'hooksV2/useUpdateClientMeeting';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { FcOk } from 'react-icons/fc';
import { ClientMeetingType } from 'sharedV2/types/client';

type PropsType = {
  meeting: ClientMeetingType;
};

export const ClientMeetingDetailsObservations: React.FC<PropsType> = ({
  meeting,
}): JSX.Element => {
  const { getParam } = useQueryParams();

  const isOnlyVisualizationMode =
    getParam('isOnlyVisualizationMode') === 'true';

  const {
    mutateAsync: updateMeeting,
    isLoading: isLoadingUpdateMeeting,
    isSuccess,
    isError,
  } = useUpdateClientMeeting({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['clientMeetingDetails', meeting.id]);
    },
  });

  const [observations, setObservations] = useState<string>(
    meeting.observations || ''
  );
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const handleChangeObservationText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isOnlyVisualizationMode) {
      return;
    }

    setObservations(event.target.value);
    setIsTyping(true);
  };

  useEffect(() => {
    const handleDebouncedUpdateMeetingObservations = debounce(async () => {
      await updateMeeting({
        ...meeting,
        observations,
      });

      setIsTyping(false);
    }, 1000);

    if (observations !== meeting.observations) {
      handleDebouncedUpdateMeetingObservations();
    }

    return () => {
      handleDebouncedUpdateMeetingObservations.cancel();
    };
  }, [observations]);

  const isInfosSaved = isSuccess && !isTyping;
  const showFailMessage = isError && !isTyping;
  const isSaving = isTyping || isLoadingUpdateMeeting;

  return (
    <Flex h="100%" flexDirection="column">
      {isSaving && (
        <Text
          align="right"
          fontStyle="italic"
          color="primary.200"
          fontSize="smaller"
        >
          salvando informações...
        </Text>
      )}
      {showFailMessage && (
        <Text
          align="right"
          fontStyle="italic"
          color="red.400"
          fontSize="smaller"
        >
          estamos com problemas para salvar as informações
        </Text>
      )}
      {isInfosSaved && (
        <Flex gap={1} align="center" justify="right">
          <Text
            align="right"
            fontStyle="italic"
            color="#4CAF50"
            fontSize="smaller"
          >
            informações salvas
          </Text>
          <FcOk size={16} />
        </Flex>
      )}

      <Input.Root w="100%" h="100%">
        <Input.TextAreaField
          placeholder="Observações:"
          h="100%"
          value={observations}
          onChange={handleChangeObservationText}
          cursor="not-allowed"
        />
      </Input.Root>
    </Flex>
  );
};
