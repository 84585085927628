import {
  Flex,
  FlexProps,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { useHistory } from 'react-router-dom';
import { BIType } from 'services/hubApi';

type PropsType = {
  page: BIType;
} & FlexProps;

export const PowerBIPageCard: React.FC<PropsType> = ({
  page,
  ...rest
}): JSX.Element => {
  const history = useHistory();

  return (
    <Flex
      backgroundColor="#fff"
      borderRadius={5}
      py={4}
      px={6}
      w="30%"
      minW="259px"
      minH="100%"
      maxH="259px"
      flexDirection="column"
      justifyContent="space-between"
      gap={1}
      {...rest}
      transition="300ms"
    >
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Tooltip
            hasArrow
            label={page.name}
            placement="top"
            bg="background.200"
            cursor="pointer"
          >
            <Text
              color="rgba(70, 70, 70, 1)"
              fontWeight="bold"
              fontSize={20}
              maxW="100%"
              isTruncated
            >
              {page.name}
            </Text>
          </Tooltip>
        </Flex>
        <Flex
          minH="120px"
          maxH="120px"
          overflowY="auto"
          className="thinScrollbar"
          py={1}
          borderRadius={7}
        >
          <UnorderedList>
            {page.description.split('\\n').map(item => {
              return (
                <ListItem
                  key={item}
                  marginBottom={1}
                  fontWeight="thin"
                  fontStyle="italic"
                  maxW="280px"
                  fontSize="sm"
                  color="rgba(70, 70, 70, 1)"
                >
                  {item}
                </ListItem>
              );
            })}
          </UnorderedList>
        </Flex>
      </Flex>

      <PrimaryButton
        h="20%"
        onClick={() => {
          history.push(`/bi/${page.reportId}/${page.pageName}/dashboard`);
        }}
        mt={4}
      >
        Acessar Dados
      </PrimaryButton>
    </Flex>
  );
};
