const CorporateColumns = [
  {
    id: '1',
    title: 'Sem Contato',
    status: 'noContact',
    tasks: [],
  },
  {
    id: '2',
    title: 'Call marcada',
    status: 'scheduledCall',
    tasks: [],
  },
  {
    id: '3',
    title: 'Call realizada',
    status: 'doneCall',
    tasks: [],
  },
  {
    id: '4',
    title: 'Aguardando documentos',
    status: 'waitingDocuments',
    tasks: [],
  },
  {
    id: '5',
    title: 'Validação',
    status: 'validation',
    tasks: [],
  },
  {
    id: '6',
    title: 'Fechamento',
    status: 'closing',
    tasks: [],
  },

  {
    id: '7',
    title: 'Boletado',
    status: 'boleted',
    tasks: [],
  },
];

export { CorporateColumns };
