import styled from 'styled-components';

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

export default ModalWrapper;
