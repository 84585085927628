import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { Textarea } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { closeOrderOptions } from './data';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
}

interface ComponentProps {
  order: TaskProps;
  handleToggleModal: () => void;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseOrder: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const [selectedOption, setSelectedOption] = useState('Escolha uma opção');
  const [reason, setReason] = useState('');

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleChangeReason = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setReason(e.target.value);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedOption === 'Escolha uma opção') {
      toast.dark('Você deve escolher uma ação para finalizar a ordem');
      return;
    }

    if (selectedOption === closeOrderOptions[1].title && reason.trim() === '') {
      toast.dark('Você deve descrever o motivo do cancelamento da ordem');
      return;
    }

    await hubApiMarketing
      .updateOrderData(order.id, {
        status: selectedOption.toLowerCase(),
        description: reason,
      })
      .then(() => {
        setIsFinished(true);
        toast.dark(
          'Orderm finalizada com sucesso. A mesma será removida ao recarregar a tela'
        );
        handleToggleModal();
      })
      .catch(error => {
        if (error.response) {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        } else {
          toast.dark(
            'Ocorreu um erro no servidor ao tentar finalizar a ordem. Tente novamente mais tard'
          );
        }
      });
  };

  return (
    <DefaultModalContentWrapper maxW="440px">
      <ModalTitle>Finalizar ordem</ModalTitle>

      <form onSubmit={handleFormSubmit}>
        <BaseLabelText>Esta ordem será:</BaseLabelText>
        <Dropdown
          mb="4"
          placeholder="Escolha uma das opções"
          onChange={e => handleSelectOption(e.target.value)}
        >
          {closeOrderOptions.map((item, index) => (
            <option key={index} value={item.title}>
              {item.title}
            </option>
          ))}
        </Dropdown>

        {selectedOption === closeOrderOptions[1].title && (
          <Textarea
            mb="3"
            placeholder="Descreva o motivo do cancelameto..."
            name="description"
            value={reason}
            onChange={handleChangeReason}
          />
        )}

        {selectedOption === closeOrderOptions[0].title && (
          <Text fontSize="sm" fontWeight="semibold" color="white" mt="5" mb="3">
            Você confirma que deseja finalizar essa solicitação?
          </Text>
        )}

        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};

export default CloseOrder;
