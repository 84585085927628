import { Client } from 'pages/Offers/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IQuestionProps } from 'pages/Captation/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';

export const transferLeadsObservationsToClientNotes = async (
  clientId: string,
  clientBTGCode: string
): Promise<void> => {
  const client: Client = await hubApiUser.searchForClientCode(clientBTGCode);

  const additionalTextInfomationQuestions: IQuestionProps[] = [];
  const differentTypeOfLeadQuestions: IQuestionProps[] = [];

  const leadQuestions: IQuestionProps[] =
    await hubApiCaptation.getQuestionResponse(clientId);

  leadQuestions.forEach(question => {
    if (question.question === 'informacoesadicionaistextarea') {
      additionalTextInfomationQuestions.push(question);
    } else {
      differentTypeOfLeadQuestions.push(question);
    }
  });
  let mostRecentAdditionalTextInfomationQuestion: IQuestionProps | undefined;
  additionalTextInfomationQuestions.forEach(async item => {
    mostRecentAdditionalTextInfomationQuestion = item;
    if (
      item.updated_at > mostRecentAdditionalTextInfomationQuestion.updated_at
    ) {
      mostRecentAdditionalTextInfomationQuestion = item;
    }
  });

  if (mostRecentAdditionalTextInfomationQuestion) {
    await hubApiUser.saveClientNotes(client.id, {
      notes: `<p>${mostRecentAdditionalTextInfomationQuestion.answer} - <i>(Nota da Prospcção)</i></p>`,
    });
  }

  differentTypeOfLeadQuestions.forEach(async question => {
    await hubApiUser.saveClientNotes(client.id, {
      notes: `<p>${question.question}: ${question.answer} - <i>(Nota da Prospcção)</i></p>`,
    });
  });
};
