import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Flex, Stack } from '@chakra-ui/react';

import { useCaptationMeetingsContext } from 'hooks';
import { errorHandler } from 'utils/errorHandler';
import { checkIfDateIsPast, formatDateToISO } from 'utils/formatDate';
import { Dropdown } from 'components/elements/others';
import { TextareaWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { Advisor, IBaseMeeting } from 'pages/Captation/interfaces';
import { localOptions } from 'pages/Captation/data';

import { formatNameToCaptalize } from 'utils/captalizeNames';
import { scheduleMeetingSchema } from './schema';

interface ScheduleNextSaleMeetingProps {
  handleShowModal: () => void;
  meetingId: string;
  handleAddTextAreaQuestion: () => Promise<void>;
  meetingInfos: IBaseMeeting;
  currentSegment:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'pipe';
}

export const ScheduleNextSaleMeeting: React.FC<ScheduleNextSaleMeetingProps> =
  ({
    handleShowModal,
    meetingId,
    currentSegment,
    meetingInfos,
    handleAddTextAreaQuestion,
  }) => {
    const user = useSelector((state: RootStateOrAny) => state.user.profile);
    const { handleGetSpecificLeadTypeArr, handleRemoveMeeting } =
      useCaptationMeetingsContext();

    const [localTitle, setLocalTitle] = useState('Local');
    const [scheduling, setScheduling] = useState('');
    const [complement, setComplement] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [advisorsList, setAdvisorsList] = useState<Advisor[]>([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState({
      name: user.name,
      id: user.id,
    });

    const checkNextSegment = (
      actualSegment:
        | 'callback'
        | 'suitability'
        | 'presentation'
        | 'allocation'
        | 'account'
        | 'declined'
        | 'pipe'
    ) => {
      if (actualSegment === 'callback') return 'suitability';
      if (actualSegment === 'suitability') return 'presentation';
      if (actualSegment === 'presentation') return 'allocation';
      if (actualSegment === 'allocation') return 'account';
      return actualSegment;
    };

    useEffect(() => {
      async function getAdvisors() {
        if (checkNextSegment(currentSegment) === 'account') return;

        try {
          const tableAdvisors = await hubApiOfficeTables.listUsersByTable(
            user.tableId
          );

          setAdvisorsList(tableAdvisors);
        } catch (err) {
          toast.dark('Erro ao buscar lista de assessores disponíveis.');
        }
      }

      getAdvisors();
    }, [user.tableId]);

    const handleSelectAdvisor = (event: BaseSyntheticEvent) => {
      const foundAdvisor: any = advisorsList?.find(
        (advisor: any) => advisor.id === event.target.value
      );

      if (foundAdvisor) {
        setSelectedAdvisor({ id: foundAdvisor.id, name: foundAdvisor.name });
      }
    };

    const handleChangeMeetingDate: any = (value: string) => {
      const parsed = formatDateToISO(value);

      setScheduling(parsed);
    };

    const handleFormSubmit = async (e: BaseSyntheticEvent) => {
      e.preventDefault();
      setIsLoading(true);

      const dateIsPast = checkIfDateIsPast(scheduling);
      if (dateIsPast || scheduling.trim() === '') {
        toast.dark('Escolha uma data futura para marcar uma reunião.');
        setIsLoading(false);
        return;
      }

      try {
        const meetingData = {
          id: meetingId,
          leadId: meetingInfos.leadId,
          employeeId: meetingInfos.employeeId,
          employeeIdParticipant:
            selectedAdvisor.id || meetingInfos.employeeIdParticipant,
          type: 'meeting',
          local: localTitle,
          leadType: meetingInfos.leadType,
          complement,
          date: scheduling,
          status: checkNextSegment(currentSegment),
          feedback: '',
          interest: 'Alto',
          isPipe: false,
        };

        const isMeetingValid = await scheduleMeetingSchema.isValid(meetingData);

        if (!isMeetingValid) {
          await scheduleMeetingSchema.validate(meetingData).catch(err => {
            err.errors.map((error: string) => toast.dark(error));
          });
          setIsLoading(false);
          return;
        }

        await hubApiCaptation.pathMetting(meetingData);

        handleRemoveMeeting(currentSegment, meetingId);

        const isUpdateArr = true;
        await handleGetSpecificLeadTypeArr(
          checkNextSegment(currentSegment),
          isUpdateArr
        );

        toast.dark('Reunião marcada com sucesso');

        const dataToActionsHistory = {
          employeeId: user.id,
          leadId: meetingInfos.leadId,
          leadType: meetingInfos.leadType,
          successful: true,
        };

        await hubApiCaptation.patchEmployeeActionsHistory(dataToActionsHistory);

        localStorage.removeItem(meetingInfos.leadId);

        handleAddTextAreaQuestion();
        handleShowModal();
      } catch (err) {
        errorHandler(err);

        if (
          err.response.data.error.message ===
          'Already exists a meeting register for this lead'
        ) {
          toast.dark(
            'Já existe uma reunião para este lead, verifique com o gestor'
          );
        } else {
          toast.dark(
            'Não foi possível agendar a reunião, tente novamente em alguns minutos'
          );
        }
      }
      setIsLoading(false);
    };

    return (
      <BaseModalFormCard
        maxW="510px"
        title="Agendar nova reunião"
        isLoading={isLoading}
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={handleShowModal}
        primaryButtonText="Agendar reunião"
        isModal
      >
        <Stack spacing="4">
          <DateAndHourPicker onChange={handleChangeMeetingDate} label="Data" />

          <Flex mt="2" flexDir={{ base: 'column', md: 'row' }} gridGap="2">
            <Dropdown
              placeholder="Tipo de reunião"
              onChange={e => setLocalTitle(e.target.value)}
            >
              {localOptions.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
            </Dropdown>

            <Dropdown
              placeholder={formatNameToCaptalize(selectedAdvisor.name)}
              onChange={handleSelectAdvisor}
            >
              {advisorsList?.map(item => {
                if (item.name === selectedAdvisor.name) {
                  return <> </>;
                }

                return (
                  <option key={item.id} value={item.id}>
                    {formatNameToCaptalize(item.name)}
                  </option>
                );
              })}
            </Dropdown>
          </Flex>

          <TextareaWithLabel
            label="Complemento:"
            placeholder="Informações sobre a reunião"
            name="Complement"
            rows={4}
            onChange={e => setComplement(e.target.value)}
          />
        </Stack>
      </BaseModalFormCard>
    );
  };
