import React, { useState } from 'react';
import { AssignmentProps } from 'pages/Home/interfaces';
import { AssignmentContentCard } from '../AssignmentContentCard';
import { ModalWrapper } from 'components/elements/wrappers';
import { FcConferenceCall } from 'react-icons/fc';
import { MeetingTodayCardModal } from 'pages/Home/components/modules/modals/MeetingTodayCardModal';
import { formateDateAndTime } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface MeetingTodayCardProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const MeetingTodayCard: React.FC<MeetingTodayCardProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const { date, name } = JSON.parse(assignment.extra || '');

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcConferenceCall}
        title="Reuniões"
        secondaryText={`Reunião com o cliente: ${formatNameToCaptalize(name)}`}
        thirdText={`Data: ${formateDateAndTime(date)}`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <MeetingTodayCardModal
          idTask={assignment.id}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
