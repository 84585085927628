import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  position: relative;

  @media screen and (min-width: 604px) {
    height: calc(100vh - 80px);
  }
`;

export const DropdownsArea = styled.div`
  display: flex;
  margin: 16px 0 12px;

  div {
    &:nth-child(2) {
      margin-left: 12px;
    }
  }
`;

export const SalaryArea = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    &:nth-child(2) {
      margin: 0 0 8px 6px;
    }
  }
`;

export const TransfersTitle = styled.div`
  padding-bottom: 4px;
  margin: 4px 0 10px;
  border-bottom: 1px solid var(--white-color-300);
  color: var(--white-color-100);
  font-size: var(--smaller-text);
`;

export const TransfersArea = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  text-align: left;
`;

export const TransfersAreaWithTreeInput = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: left;
`;
