import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

import { errorHandler } from 'utils/errorHandler';
import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';

import { PublicOfferingProps } from 'pages/products/interfaces';

interface PublicOfferingManagementProps extends PublicOfferingProps {
  id: string;
}

export const PublicOfferingManagement: React.FC = () => {
  const [publicOffering, setPublicOffering] = useState<
    PublicOfferingManagementProps[]
  >([]);
  const history = useHistory();

  const handleEditPublicOffering = useCallback(
    id => {
      history.push(`/products/public_offering/offers/${id}`);
    },
    [history]
  );

  const handleClickCreateButton = useCallback(() => {
    history.push('/products/public_offering/create');
  }, [history]);

  const handleRemovePublicOffering = useCallback(
    async id => {
      try {
        await hubApiProducts.deletePublicOffering(id);
        setPublicOffering(publicOffering.filter(item => item.id !== id));
        toast.dark('Deletado com sucesso.');
      } catch (error) {
        errorHandler(error);
      }
    },
    [publicOffering]
  );

  const handleDisableProductCaptation = async (productId: string) => {
    try {
      await hubApiProducts.updateCard(productId, {
        title: 'Desativado',
      });

      const product = publicOffering.filter(item => item.id === productId)[0];

      const updatedProduct = {
        ...product,
        isActive: false,
      };

      await hubApiProducts.putPublicOffering(productId, updatedProduct);

      listAllPublicOffering();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleActiveProductCaptation = async (productId: string) => {
    try {
      await hubApiProducts.updateCard(productId, {
        title: 'Em captação',
      });

      const product = publicOffering.filter(item => item.id === productId)[0];

      const updatedProduct = {
        ...product,
        isActive: true,
      };

      await hubApiProducts.putPublicOffering(productId, updatedProduct);

      listAllPublicOffering();
    } catch (error) {
      errorHandler(error);
    }
  };

  const listAllPublicOffering = async () => {
    hubApiProducts
      .getAllIPublicOffering()
      .then(data => {
        setPublicOffering(data);
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  useEffect(() => {
    listAllPublicOffering();
  }, []);

  if (publicOffering.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar Oferta Pública Renda Fixa"
        buttonText="Criar nova oferta"
        handleCreateProduct={handleClickCreateButton}
      >
        <Text color="whiteAlpha.800">
          Não existem Ofertas Públicas Renda Fixa cadastradas.
        </Text>
      </ProductManagementLayout>
    );
  }
  return (
    <ProductManagementLayout
      title="Gerenciar Oferta Pública Renda Fixa"
      buttonText="Criar nova oferta"
      handleCreateProduct={handleClickCreateButton}
    >
      {publicOffering.map((item, index) => {
        if (item.totalVolume === '') {
          return (
            <ProductManagementCard
              key={index}
              productId={item.id}
              title={item.title}
              handleEditProduct={handleEditPublicOffering}
              handleRemoveProduct={handleRemovePublicOffering}
              isActive={item.isActive}
              handleDisableProductCaptation={handleDisableProductCaptation}
              handleActiveProductCaptation={handleActiveProductCaptation}
            />
          );
        } else {
          return (
            <ProductManagementCard
              key={index}
              productId={item.id}
              title={item.title}
              handleEditProduct={handleEditPublicOffering}
              handleRemoveProduct={handleRemovePublicOffering}
            />
          );
        }
      })}
    </ProductManagementLayout>
  );
};
