import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import history from 'services/history';
import hubApi from 'services/hubApi';
import { PageWrapper } from 'components/elements/wrappers';
import { InputWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { LinkExpired } from '../components/LinkExpired';
import { CheckingToken } from '../components/CheckingToken';
import recoverPasswordSchema from './schema';

export const RecoverPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [loadingToken, setLoadingToken] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  useEffect(() => {
    async function checkTokenValidity(validToken: string) {
      // const isExpired = await hubApi.checkIfTokenIsValid(validToken);

      // if (isExpired.revoked) {
      //   setLoadingToken(false);
      //   setTokenIsValid(false);
      //   setIsLoading(false);
      //   return;
      // }
      setIsLoading(false);
      setLoadingToken(false);
      setTokenIsValid(true);
    }
    checkTokenValidity(token);
  }, [token]);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const formIsValid = await recoverPasswordSchema
        .validate(formData)
        .catch(err => {
          err.errors.map((error: string) => toast.dark(error));
        });

      if (formIsValid) {
        setIsLoading(true);

        // await hubApi.resetPassword({ ...formData, token });

        toast.dark(
          'Senha redefinida com sucesso. Faça o login com a nova senha.'
        );
        setIsLoading(false);
        history.push('/login');
      }
    } catch (err) {
      setIsLoading(false);
      toast.dark('Ocorreu um erro ao tentar redefinir senha. Tente novamente.');
    }
  };

  if (loadingToken) {
    return <CheckingToken />;
  }

  return (
    <>
      {!tokenIsValid ? (
        <LinkExpired />
      ) : (
        <PageWrapper
          alignItems="center"
          justifyContent="center"
          position="relative"
          flexDir={{ base: 'column', md: 'column' }}
          gridGap={{ base: '32px', md: '48px' }}
        >
          <BaseModalFormCard
            isModal={false}
            title="Redefinição de Senha"
            handleFormSubmit={handleFormSubmit}
            isLoading={isLoading}
            primaryButtonText="Salvar"
            secondaryButtonText="Cancelar"
            minW={['250px', '500px']}
          >
            <form onSubmit={handleFormSubmit}>
              <Stack spacing={3}>
                <InputWithLabel
                  label="Senha"
                  placeholder="Digite a nova senha"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChangeValue}
                />

                <InputWithLabel
                  label="Confirmar nova senha"
                  placeholder="Digite a senha novamente"
                  type="password"
                  name="passwordConfirmation"
                  value={formData.passwordConfirmation}
                  onChange={handleChangeValue}
                />
              </Stack>
            </form>
          </BaseModalFormCard>
        </PageWrapper>
      )}
    </>
  );
};
