import { CheckIcon, SearchIcon } from '@chakra-ui/icons';
import { InputRightAddon, Tooltip } from '@chakra-ui/react';

interface TooltipSearchProps {
  search: () => void;
  labelTooltip: string;
  showSearchIcon: boolean;
  setShowSearchIcon: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TooltipSearch: React.FC<TooltipSearchProps> = ({
  search,
  labelTooltip,
  showSearchIcon,
  setShowSearchIcon,
}) => {
  return (
    <Tooltip
      label={labelTooltip}
      cursor="pointer"
      color="blackAlpha.900"
      placement="top"
    >
      <InputRightAddon
        w="1rem"
        ml="-1"
        justifyContent="center"
        background="white"
        borderColor="transparent"
        _placeholder={{ color: 'gray.600' }}
        h="7"
        cursor="pointer"
        border="1px solid red"
        onClick={() => {
          search();
          setShowSearchIcon(false);
        }}
      >
        {showSearchIcon ? (
          <SearchIcon color="black" />
        ) : (
          <CheckIcon color="green.400" />
        )}
      </InputRightAddon>
    </Tooltip>
  );
};
