import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import { IRhTransFerOrdersProps } from 'pages/RH/interfaces';
import {
  DeleteTransferOrderCard,
  TransferOrderInfosCard,
} from 'components/elements/cards/transferOrder';
import { DisplayInformation } from 'components/elements/others';
import { Stack } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface OrderInfoProps {
  order: IRhTransFerOrdersProps;
  handleShowModal: () => void;
  removeOrder: (id: string) => void;
}

interface CollaboratorProps {
  id: string;
  name: string;
}

const OrderInfo: React.FC<OrderInfoProps> = ({
  handleShowModal,
  order,
  removeOrder,
}) => {
  const [usersOptions, setUsersOptions] = useState<CollaboratorProps[]>([]);
  const [employeIdSelected, setEmployeeIdSelected] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userInfor = useSelector((state: any) => state.user.profile);
  const [toggleCancelOrderModal, setToggleCancelOrderModal] =
    useState<boolean>(false);

  const [description, setDescription] = useState('');
  useEffect(() => {
    async function listUsersWithPermission() {
      await hubApiRH
        .listUsersWithRhPermission()
        .then(response => setUsersOptions(response))
        .catch(error => {
          if (error.response) {
            if (error.response?.data.message !== 'Internal server error') {
              toast.dark(error.response?.data.message);
            }
          }
        });
    }

    listUsersWithPermission();
  }, []);

  const handleChangeUser = useCallback((colaboratorId: string) => {
    setEmployeeIdSelected(colaboratorId);
  }, []);

  const handleToggleCancelOrderModal = () => {
    setToggleCancelOrderModal(!toggleCancelOrderModal);
  };

  const handleFormSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    if (!employeIdSelected) {
      setIsLoading(false);
      return toast.dark('Selecione um colaborador');
    }

    try {
      const formData = {
        id: order.id,
        employeeId: employeIdSelected,
        status: 'aguardando',
      };

      await hubApiRH.updateOrderData(formData);
      setIsLoading(false);
      removeOrder(order.id);
      toast.dark('Ordem transferida com sucesso');
      handleShowModal();
    } catch (error) {
      setIsLoading(false);
      toast.dark(`Ocorreu um erro ao tentar transferir a ordem`);
    }
  };

  const handleDeleteOrder = useCallback(async () => {
    handleShowModal();

    const formData = {
      id: order.id,
      employeeId: userInfor.id,
      status: 'deletada',
      reason: description,
    };

    await hubApiRH
      .updateOrderData(formData)
      .then(() => {
        handleShowModal();
        removeOrder(order.id);
        toast.dark('Ordem deletada com sucesso.');
      })
      .catch(err => {
        if (err.response) {
          if (err.response.data.message !== 'Internal server error') {
            toast.dark(err.response.data.message);
          }
        }
      });
  }, [handleShowModal, userInfor, order.id, removeOrder, description]);

  const handleChange = useCallback(e => {
    setDescription(e.target.value);
  }, []);
  return (
    <TransferOrderInfosCard
      title="Dados da ordem"
      isLoading={isLoading}
      collaborators={usersOptions}
      handleSelectCollaborator={handleChangeUser}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      handleToggleCancelOrderModal={handleToggleCancelOrderModal}
    >
      <Stack spacing="3">
        {order.employeename && (
          <DisplayInformation
            label="Solicitante"
            info={formatNameToCaptalize(order.employeename)}
          />
        )}

        {order.email && (
          <DisplayInformation label="E-mail" info={order.email} />
        )}

        {order.phone && (
          <DisplayInformation label="Telefone" info={order.phone} />
        )}

        {order.information && (
          <DisplayInformation label="Descrição" info={order.information} />
        )}
      </Stack>

      <ModalWrapper
        isOpen={toggleCancelOrderModal}
        onClose={handleToggleCancelOrderModal}
      >
        <DeleteTransferOrderCard
          description={description}
          handleDeleteOrder={handleDeleteOrder}
          handleChangeValue={handleChange}
          handleToggleCancelOrderModal={handleToggleCancelOrderModal}
        />
      </ModalWrapper>
    </TransferOrderInfosCard>
  );
};

export default OrderInfo;
