import styled from 'styled-components';

export const AddItem = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 0px;
  font-size: ${({ theme }) => theme.sizes.smaller};
  color: ${({ theme }) => theme.colors.white200};
  cursor: pointer;
`;

export const FloatYContainer = styled.div`
  white-space: nowrap;
  overflow: auto;
  max-height: 220px;
  padding-right: 6px;
`;
