import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { IReduxState } from 'shared/interfaces';
import { CardToLink } from 'components/elements/cards';

import {
  MainPageTitle,
  SectionTitleWithBorder,
} from 'components/elements/texts';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { geralOptionsAlt, menuBIsAlt, menuOptionsAlt } from './panelData';

const ControlPanel: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const isManager =
    user.id === 'db7b29e2-e5ab-4c8f-9299-c3346ac22b89' ||
    user.id === '31c6cc7b-3c4a-4b53-8f4d-f2435e16c321' ||
    user.id === '7e2a8234-49d8-490a-b3b5-7837f1a69117' ||
    user.id === '54234bae-1543-4a06-ba48-07bf265ed8ac' ||
    user.position === 'MASTER';

  return (
    <PageWrapper alignItems="center" paddingTop="2.5rem">
      <Flex flexDir="column" width="100%" maxW="1080px">
        <MainPageTitle mb="32px" color="rgba(70, 70, 70, 1)">
          Painel de Controle
        </MainPageTitle>

        {geralOptionsAlt.map((optionsGeneral, key) => {
          return (
            <Flex
              flexDir="column"
              key={key}
              sx={{
                '&:nth-of-type(1) >p': {
                  marginTop: '0',
                },
              }}
            >
              <SectionTitleWithBorder mt="12">
                {optionsGeneral.sectionTitle}
              </SectionTitleWithBorder>
              <Flex flexWrap="wrap" gridGap="16px 20px" key={key}>
                {optionsGeneral.pages.map((page, idx: number) => {
                  return (
                    <CardToLink
                      key={`panel-item-${idx}`}
                      title={page.title}
                      link={page.path}
                      Icon={page.Icon}
                      external={page.path.includes('https')}
                    />
                  );
                })}
              </Flex>
            </Flex>
          );
        })}

        {isManager &&
          menuBIsAlt.map((optionsGeneral, key) => {
            return (
              <Flex
                flexDir="column"
                key={key}
                sx={{
                  '&:nth-of-type(1) >p': {
                    marginTop: '0',
                  },
                }}
              >
                <SectionTitleWithBorder mt="12">
                  {optionsGeneral.sectionTitle}
                </SectionTitleWithBorder>
                <Flex flexWrap="wrap" gridGap="16px 20px" key={key}>
                  {optionsGeneral.pages.map((page, idx: number) => {
                    return (
                      <CardToLink
                        key={`panel-item-${idx}`}
                        title={page.title}
                        link={page.path}
                        Icon={page.Icon}
                        external={page.path.includes('https')}
                      />
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}

        {menuOptionsAlt.map((section, index: number) => {
          return (
            <Flex
              flexDir="column"
              key={index}
              sx={{
                '&:nth-of-type(1) >p': {
                  marginTop: '0',
                },
              }}
            >
              <Flex flexWrap="wrap" gridGap="16px 20px" key={index}>
                {section.map(sec => {
                  const pagesFromSection = sec.pages.filter(page =>
                    verifyEmployeePermission({
                      user,
                      areaCod: page.area,
                      permissionCod: page.permission,
                    })
                  );
                  if (pagesFromSection.length === 0) {
                    return;
                  }
                  return (
                    <>
                      <SectionTitleWithBorder mt="12">
                        {sec.sectionTitle}
                      </SectionTitleWithBorder>
                      {pagesFromSection.map((page, idx: number) => {
                        return (
                          <CardToLink
                            key={`panel-item-${idx}`}
                            title={page.title}
                            link={page.path}
                            Icon={page.Icon}
                          />
                        );
                      })}
                    </>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </PageWrapper>
  );
};

export default ControlPanel;
