import { HubApi } from '../index';

class HubApiGraphics extends HubApi {
  async listTableGowth() {
    const response = await this.axios.get(`/graphics/growthTable/`);
    return response.data;
  }

  async listTableGowthNet(date: string) {
    const response = await this.axios.get(`/graphics/growthTable/info/${date}`);
    return response.data;
  }

  async listGraphicTableGrowth(yearMonth: string) {
    const response = await this.axios.get(
      `/graphics/growthTable/graphic/${yearMonth}`
    );
    return response.data;
  }

  async listTableGowthId(id: string) {
    const response = await this.axios.get(`/graphics/growthTable/graph/${id}`);
    return response.data;
  }

  async listTableAdvisors(id: string) {
    const response = await this.axios.get(
      `graphics/growthTable/info/unique/${id}`
    );
    return response.data;
  }

  async listTableGowthNetId(date: string, id: string) {
    const response = await this.axios.get(
      `/graphics/growthTable/infos/${date}/${id}`
    );
    return response.data;
  }

  async listGraphicTableGrowthId(yearMonth: string, id: string) {
    const response = await this.axios.get(
      `/graphics/growthTable/rightInfo/${yearMonth}/${id}`
    );
    return response.data;
  }

  async listCaptureNet(date: string) {
    const response = await this.axios.get(`/graphics/captMonth/net/${date}`);
    return response.data;
  }

  async listCaptureNetManager(id: string, date: string) {
    const response = await this.axios.get(
      `/graphics/captMonth/netTable/${id}/${date}`
    );
    return response.data;
  }

  async listCaptureTedout(date: string) {
    const response = await this.axios.get(`/graphics/captMonth/tedout/${date}`);
    return response.data;
  }

  async listCaptureTedoutManager(id: string, date: string) {
    const response = await this.axios.get(
      `/graphics/captMonth/tedoutTable/${id}/${date}`
    );
    return response.data;
  }

  async listCaptureOfTheMonthGraphic(date: string) {
    const response = await this.axios.get(
      `/graphics/captMonth/graphic/${date}`
    );
    return response.data;
  }

  async listCaptureOfTheMonthGraphicManager(id: string, date: string) {
    const response = await this.axios.get(
      `/graphics/captMonth/graphicTable/${id}/${date}`
    );
    return response.data;
  }

  async listAllocationInfos() {
    const response = await this.axios.get(`/graphics/alocation/info`);
    return response.data;
  }

  async listAllocationInfosId(id: string) {
    const response = await this.axios.get(`graphics/alocation/infos/${id}`);
    return response.data;
  }

  async listAllocationGraphic() {
    const response = await this.axios.get(`/graphics/alocation`);
    return response.data;
  }

  async listRevenueGraphic() {
    const response = await this.axios.get(`/graphics/income`);
    return response.data;
  }

  async listClientsAiiEmployee(id: string) {
    const response = await this.axios.get(`/clients/aai/${id}`);
    return response.data;
  }

  async listClientsAiiUsers() {
    const response = await this.axios.get(`employees/manager/table`);
    return response.data;
  }

  async listClientsAllUsers() {
    const response = await this.axios.get(`employees/by/sector/ASSESSOR`);
    return response.data;
  }

  async listClientsAll(date: string, id: string) {
    const response = await this.axios.get(`/clients/aai/all/${date}/${id}`);
    return response.data;
  }
}

export const hubApiGraphics = new HubApiGraphics();
