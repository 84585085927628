import { formatToPhone } from 'brazilian-values';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';
import { MeetingUtils } from 'utilsV2/Meeting';
import { LeadInformationBoxRequestType } from '../components/leadInformationBox';
import { useLeadDetailsContext } from '../context/useLeadDetailsContext';
import { formateDateAndTimeNoTimezone } from '../../../utils/formatDate';

type UseBuildLeadDataResponseType = {
  data: LeadInformationBoxRequestType[];
};

export const useBuildLeadData = (): UseBuildLeadDataResponseType => {
  const { lead, responsibleEmployee, leadType } = useLeadDetailsContext();

  const meetingDate = lead?.meeting
    ? new Date(lead?.meeting?.date).toISOString()
    : new Date().toISOString();

  const leadMundoInvestData: LeadInformationBoxRequestType[] = [
    {
      label: 'Telefone',
      value: formatToPhone(lead?.phone || ''),
    },
    {
      label: 'Email',
      value: lead?.email || '',
    },
    {
      label: 'SH Responsável',
      value: formatNameToCaptalize(responsibleEmployee?.name),
    },
    {
      label: 'Patrimônio (Mundo Invest)',
      value: formatValue(lead?.plMundoInvest || 0),
    },
  ];

  if (lead.meeting) {
    leadMundoInvestData.push({
      label: 'Informações da Reunião',
      value: [
        `Status: ${MeetingUtils.translateStatus(lead?.meeting?.status)}`,
        `Data: ${formateDateAndTimeNoTimezone(meetingDate)}`,
        `Tipo: ${lead?.meeting?.local}`,
      ],
    });
  }

  let leadCommonData: LeadInformationBoxRequestType[] = [];

  if (lead.phones) {
    lead.phones.forEach((phone, index) => {
      if (!phone || phone === '[object Object]') return;

      leadCommonData.push({
        label: `Telefone (${index + 1})`,
        value: formatToPhone(phone),
      });
    });
  }

  leadCommonData = [
    lead?.phone && {
      label: 'Telefone Principal',
      value: formatToPhone(lead.phone),
    },
    ...leadCommonData,

    lead?.email && {
      label: 'Email',
      value: lead.email,
    },

    responsibleEmployee?.name && {
      label: 'SH Responsável',
      value: formatNameToCaptalize(responsibleEmployee.name),
    },

    lead?.sex && {
      label: 'Sexo',
      value: lead.sex === 'M' ? 'Masculino' : 'Feminino',
    },

    lead?.listName && {
      label: 'Lista',
      value: lead.listName,
    },

    lead?.investorProfile && {
      label: 'Suitability',
      value: lead.investorProfile,
    },

    lead?.aumPl && {
      label: 'Aum PL',
      value: lead.aumPl,
    },

    lead?.state && {
      label: 'Estado',
      value: lead.state,
    },

    lead?.trader && {
      label: 'Trader',
      value: lead.trader,
    },

    lead?.profession && {
      label: 'Profissão',
      value: lead.profession,
    },

    lead?.ageRange && {
      label: 'Faixa Etária',
      value: lead.ageRange,
    },

    lead?.salaryRange && {
      label: 'Faixa Salário',
      value: lead.salaryRange,
    },

    lead?.meeting && {
      label: 'Informações da Reunião',
      value: [
        `Status: ${MeetingUtils.translateStatus(lead?.meeting?.status)}`,
        `Data: ${formateDateAndTimeNoTimezone(meetingDate)}`,
        `Tipo: ${lead?.meeting?.local}`,
      ],
      fullBox: true,
    },
  ].filter(Boolean) as LeadInformationBoxRequestType[];

  const data =
    leadType === LeadTypeEnum.MUNDO_INVEST
      ? leadMundoInvestData
      : leadCommonData;

  return {
    data,
  };
};
