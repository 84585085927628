import { Flex, FlexProps, Text } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { DescriptionIcon } from '../../DescriptionIcon';
import { MoneyBagIcon } from '../../MoneyBagIcon';

interface ICardDescriptionAndBonusProps extends FlexProps {
  title: string;
  info: string;
}

export const CardDescriptionAndBonus: React.FC<ICardDescriptionAndBonusProps> =
  ({ title, info, ...rest }) => {
    return (
      <Flex
        width="100%"
        background="background.400"
        color="#fff"
        borderRadius="md"
        flexDirection="column"
        alignItems="flex-start"
        p={2}
        height={['115px', '218px']}
        {...rest}
      >
        <Flex width="100%" py="5px">
          {title === 'Descrição' || title === 'Estratégia' ? (
            <DescriptionIcon width="20px" />
          ) : (
            <MoneyBagIcon width="20px" />
          )}
          <Text fontSize="md" color="gray.800" fontStyle="italic" pl="5px">
            {title}
          </Text>
        </Flex>
        <Flex pl="35px" width="100%" height="100%" overflowY="auto">
          <Text height="100%" width="100%" fontSize="sm" pr="10px">
            {parser(info)}
          </Text>
        </Flex>
      </Flex>
    );
  };
