import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Flex, Stack } from '@chakra-ui/react';

import { IReduxState } from 'shared/interfaces';
import { OrderInfoProps } from 'pages/Exchange/interfaces';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import { DisplayInformation } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import {
  DeleteTransferOrderCard,
  TransferOrderInfosCard,
} from 'components/elements/cards';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatToBRL } from 'brazilian-values';

export const OrderInfo: React.FC<OrderInfoProps> = ({
  handleToggleOrderModal,
  order,
  handleRemoveOrder,
}) => {
  const [collaborators, setCollaborators] = useState([]);
  const [employeeIdSelected, setEmployeeIdSelected] = useState('');
  const [handleModal, setHandleModal] = useState(false);
  const [description, setDescription] = useState('');

  const userInfos = useSelector((state: IReduxState) => state.user.profile);

  useEffect(() => {
    async function listEmployeesWithPermission() {
      await hubApiExchange
        .listEmployeesWithPermission()
        .then(response => setCollaborators(response))
        .catch(error => {
          error.response?.data.message !== 'Internal server error' &&
            toast.dark(error.response?.data.message);
        });
    }

    listEmployeesWithPermission();
  }, []);

  const handleToggleCancelModal = () => setHandleModal(!handleModal);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!employeeIdSelected) {
      return toast.dark('Selecione um colaborador');
    }

    try {
      const formData = {
        ...order,
        employeeId: employeeIdSelected,
        status: 'sem-contato',
      };

      await hubApiExchange.updateOrder(order.id, formData);

      handleRemoveOrder(order.id);
      toast.dark('Ordem transferida com sucesso');
      handleToggleOrderModal();
    } catch (error) {
      toast.dark('Ocorreu um erro ao tentar transferir a ordem');
    }
  };

  const handleChangeCollaborator = useCallback(employeeId => {
    setEmployeeIdSelected(employeeId);
  }, []);

  const handleDeleteOrder = useCallback(async () => {
    handleToggleOrderModal();
    const formData = {
      ...order,
      status: 'deletada',
      employeeId: userInfos.id,
      description,
    };
    await hubApiExchange
      .updateOrder(order.id, formData)
      .then(() => {
        handleToggleOrderModal();
        handleRemoveOrder(order.id);
        toast.dark('Ordem deletada com sucesso.');
      })
      .catch(error => {
        error.response?.data.message !== 'Internal server error' &&
          toast.dark(error.response?.data.message);
      });
  }, [
    handleToggleOrderModal,
    handleRemoveOrder,
    description,
    order,
    userInfos,
  ]);

  const handleChangeDescriptionValue = useCallback(e => {
    setDescription(e.target.value);
  }, []);

  return (
    <TransferOrderInfosCard
      maxW="760px"
      title="Dados da ordem"
      isLoading={false}
      collaborators={collaborators}
      handleSelectCollaborator={handleChangeCollaborator}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleOrderModal}
      handleToggleCancelOrderModal={handleToggleCancelModal}
    >
      <Flex
        mb="2"
        width="100%"
        flexDir={{ base: 'column', md: 'row' }}
        gridGap="6"
      >
        <Stack width="100%" spacing="3">
          <DisplayInformation label="Nome" info={order.name} />
          <DisplayInformation
            label="Nome do solicitante"
            info={formatNameToCaptalize(order.employeemanagername)}
          />
          <DisplayInformation label="Telefone" info={order.phone} />
          <DisplayInformation label="E-mail" info={order.email} />
        </Stack>

        <Stack width="100%" spacing="3">
          <DisplayInformation label="Valor" info={formatToBRL(order.value)} />
          <DisplayInformation
            label="Tipo"
            info={order.type === 'PF' ? 'Pessoa física' : 'Pessoa jurídica'}
          />
          <DisplayInformation label="Intenção" info={order.intention} />
          {order.description && (
            <DisplayInformation label="Informações" info={order.description} />
          )}
        </Stack>
      </Flex>

      <ModalWrapper isOpen={handleModal} onClose={handleToggleCancelModal}>
        <DeleteTransferOrderCard
          description={description}
          handleDeleteOrder={handleDeleteOrder}
          handleChangeValue={handleChangeDescriptionValue}
          handleToggleCancelOrderModal={handleToggleCancelModal}
        />
      </ModalWrapper>
    </TransferOrderInfosCard>
  );
};
