import { LeadActionType } from './leadAction';
import { LeadShQuestionType } from './leadShQuestion';
import { MeetingType } from './meeting';

export enum LeadMundoInvestStatusEnum {
  OK = 'ok',
  FINALIZADO = 'finalizado',
  RESERVED = 'reserved',
  RETURNED = 'returned',
  NO_ANSWER = 'no_answer',
}

export type LeadMundoInvestType = {
  id: string;
  employeeId: string;
  name: string;
  email: string;
  phone: string;
  status: LeadMundoInvestStatusEnum;
  price: number;
  meetingPrice: number;
  plMundoInvest: number;
  plSalesHunter: number | undefined;
  pipeValue: number | undefined;
  stepTrackingMundoInvest: string;
  origin: string;
  frequency: number;
  purchasedProductsInMundoInvest?: any;
  meeting?: MeetingType;
  actions?: LeadActionType[];
  questions: LeadShQuestionType;
  declinedReason?: string;
  btgAccount: string | undefined;
  EWZClient: boolean | undefined;
  contactAttempts: number;
  lastContactAttemptsUpdate?: Date | undefined;
  created_at: Date;
  updated_at: Date;
};
