import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Flex, Text } from '@chakra-ui/react';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import {
  TopSection,
  BottomSection,
} from 'pages/DashboardTablesDesktop/components';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { Employee } from 'pages/DashboardTablesDesktop/interfaces';
import { PrimaryButton } from 'components/elements/buttons';

interface CarouselDashboardProps {
  tableId: string;
  tableName: string;
  dataFetchDelay: number;
  handleSelectTablesModalOpen: () => void;
}

export const CarouselDashboard: React.FC<CarouselDashboardProps> = ({
  tableId,
  tableName,
  dataFetchDelay,
  handleSelectTablesModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardDataByTableId, setDashboardDataByTableId] = useState(
    [] as any
  );
  const [employeesNumbersByTableId, setEmployeesNumbersByTableId] = useState(
    [] as Employee[]
  );
  const [employeesColors, setEmployeesColors] = useState([] as any);

  const definesEmployeesColors = () => {
    const colorsArr: any = [];

    employeesNumbersByTableId.map(employee => {
      const employeeColor = {
        employeeName: employee.name,
        color: `#${Math.floor(Math.random() * 2 ** 24)
          .toString(16)
          .padStart(6, '0')}`,
      };

      colorsArr.push(employeeColor);
    });

    setEmployeesColors(colorsArr);
  };

  const getDashboardDataByTableId = async () => {
    setIsLoading(true);
    try {
      if (tableId !== '') {
        const data = await hubApiOfficeTables.dashboardData(tableId);
        setDashboardDataByTableId(data);
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os dados da mesa, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  const getEmployeesNumbersByTableId = async () => {
    setIsLoading(true);
    try {
      if (tableId !== '') {
        const data = await hubApiOfficeTables.employeesData(tableId);
        setEmployeesNumbersByTableId(data);
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os assessores da mesa, tente novamente em alguns minutos'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getDashboardDataByTableId();
      getEmployeesNumbersByTableId();
    }, dataFetchDelay);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    definesEmployeesColors();
  }, [employeesNumbersByTableId]);

  return (
    <DefaultPageWrapper
      maxW="none"
      maxH="94vh"
      gridGap={6}
      backPageButton={false}
      pb={7}
    >
      <Flex
        w="100%"
        h="5%"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Text fontSize={['2xl']}>Mesa {tableName}</Text>
        <PrimaryButton onClick={handleSelectTablesModalOpen}>
          Alterar mesas
        </PrimaryButton>
      </Flex>

      <TopSection
        monthSummaryData={{
          monthsNumbers: dashboardDataByTableId.dashMonths,
          employeesNumber: employeesNumbersByTableId,
        }}
        employeesColors={employeesColors}
        graphicData={dashboardDataByTableId.dashMonths}
        isLoading={isLoading}
      />

      <BottomSection
        graphicData={dashboardDataByTableId.alocation}
        satelliteAreasData={{
          exchangeCount: dashboardDataByTableId.satelliteAreas?.exchangeCount,
          ipCount: dashboardDataByTableId.satelliteAreas?.ipCount,
        }}
        employees={employeesNumbersByTableId}
        updateEmployees={getEmployeesNumbersByTableId}
        employeesColors={employeesColors}
        tableName={tableName}
        tableData={dashboardDataByTableId}
        tableId={tableId}
      />
    </DefaultPageWrapper>
  );
};
