import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { LoadingSpinner } from 'components/elements/others';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { toast } from 'react-toastify';
import { useCaptationMeetingsContext } from 'hooks';
import { showToast } from 'utils';
import { DeclinedLeadsList } from 'pages/Captation/components/modules/leads/DeclinedLeadsList';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { IBaseLead, IBaseMeeting, ILeadInfos } from './interfaces';
import {
  CommonLeadContent,
  ManualAndSearchLeadsContentById,
  ManualLeadsMosaic,
  MinedLeadMosaic,
} from './components/modules/leads';
import {
  MeetingDetailsContent,
  MeetingsMosaic,
} from './components/modules/meetings';
import {
  ChooseLeadsMenu,
  MeetingTypesMenu,
} from './components/modules/mainPage';
import { MeetingsPipe } from './components/modules/meetings/MeetingsPipe';
import { LeadsList } from './components/modules/leads/LeadsList';
import { LeadsPurchased } from './components/modules/leads/LeadsPuchased';
import { LeadsPoolMI } from './components/modules/leads/LeadsPoolMI';

const Captation: React.FC = () => {
  const [showResults, setShowResults] = useState(false);
  const [leadSearched, setLeadSearched] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleSelected, setTitleSelected] = useState('Retornos');
  const [type, setType] = useState('return');
  const [leadAnswered, setLeadAnswered] = useState<boolean>(false);
  const [segment, setSegment] = useState<
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'pipe'
    | 'declined'
  >(
    window.sessionStorage.getItem('showMeeting')
      ? window.sessionStorage.getItem('showMeeting')?.split('/')[1]
      : ('callback' as any)
  );
  const [leadType, setLeadType] = useState<
    | 'leadstable'
    | 'leadspublic'
    | 'leadsmanual'
    | 'leadspj'
    | 'leadspersonal'
    | 'pipe'
    | 'declined'
    | 'leadspurchased'
    | 'pool'
  >('leadspj');
  const [leadInfos, setLeadInfos] = useState<IBaseMeeting | IBaseLead>(
    {} as IBaseMeeting
  );
  const { handleGetAllPipe } = useCaptationMeetingsContext();
  const [leadInfo, setLeadInfo] = useState<IBaseMeeting>({} as IBaseMeeting);
  const [minedLeads, setMinedLeads] = useState<IBaseLead[]>([]);
  const [leadsList, setLeadsList] = useState<IBaseLead[]>([]);
  const [minedLeadsCurrentPage, setMinedLeadsCurrentPage] = useState(1);
  const [leadsListCurrentPage, setLeadsListCurrentPage] = useState(1);
  const [lastClickedMinedLeadCardId, setLastClickedMinedLeadCardId] =
    useState('');
  const [lastClickedListLeadCardId, setLastClickedListLeadCardId] =
    useState('');
  const [minedLeadsMosaicScrollPosition, setMinedLeadsMosaicScrollPosition] =
    useState(0);
  const [listLeadsMosaicScrollPosition, setListLeadsMosaicScrollPosition] =
    useState(0);
  const [
    updatedClickedLeadContactAttempts,
    setUpdatedClickedLeadContactAttempts,
  ] = useState(0);
  const [
    updatedClickedLeadContactAttemptsId,
    setUpdatedClickedLeadContactAttemptsId,
  ] = useState('');
  const [totalNumOfLeads, setTotalNumOfLeads] = useState(0);
  const [lastTermSearched, setLastTermSearched] = useState('');
  const [lastTermSearchedMined, setLastTermSearchedMined] = useState('');
  const lastSavedCustomTermToSearch =
    window.sessionStorage.getItem('listaLeadsCurrentCustomFilter') || '';

  const handleResults = (showres: boolean) => {
    setShowResults(showres);
  };
  const handleMeeting = useCallback(
    (
      selectedSegment:
        | 'callback'
        | 'suitability'
        | 'presentation'
        | 'allocation'
        | 'account'
        | 'pipe'
        | 'declined',
      selectedTitle: string,
      selectedType: string
    ) => {
      setSegment(selectedSegment);
      setTitleSelected(selectedTitle);
      setType(selectedType);
    },
    []
  );

  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const handleLeadsMenu = useCallback(
    (
      selectedLeadType:
        | 'leadstable'
        | 'leadspublic'
        | 'leadsmanual'
        | 'leadspj'
        | 'leadspersonal'
        | 'pipe'
        | 'declined'
        | 'leadspurchased'
        | 'pool',
      selectedTitle: string,
      selectedType: string
    ) => {
      setLeadType(selectedLeadType);
      setTitleSelected(selectedTitle);
      setType(selectedType);
    },
    []
  );

  const handleShowLead = (lead: IBaseLead) => {
    setLeadInfos(lead);
    setType('indirectLeads');
  };

  // TODO: refatorar
  const handleShowMeeting = (lead: IBaseMeeting) => {
    const receivedLeadToOpenTheMeeting =
      window.sessionStorage.getItem('showMeeting');

    if (receivedLeadToOpenTheMeeting) {
      const newSegment =
        receivedLeadToOpenTheMeeting?.split('/')[2] === 'true'
          ? 'pipe'
          : (receivedLeadToOpenTheMeeting?.split('/')[1] as any);
      setSegment(newSegment);

      let leadSectionInPanel;

      switch (newSegment) {
        case 'callback':
          leadSectionInPanel = 'Retornos ';
          break;
        case 'suitability':
          leadSectionInPanel = 'R1 -  Suitability';
          break;
        case 'presentation':
          leadSectionInPanel = 'R2 - Carteira';
          break;
        case 'allocation':
          leadSectionInPanel = 'Aguardando abertura';
          break;
        case 'account':
          leadSectionInPanel = 'Alocação';
          break;
        default:
          leadSectionInPanel = 'Pipe';
      }

      setTitleSelected(leadSectionInPanel);

      window.sessionStorage.removeItem('showMeeting');
    }
    setLeadInfos(lead);
    setLeadInfo(lead);
    setType('meetingLead');
  };

  const handleLeadAnsweredCall = async () => {
    const updatedMeeting = {
      id: leadInfo.id,
      leadId: leadInfo.leadId,
      employeeId: leadInfo.employeeId,
      employeeIdParticipant: leadInfo.employeeIdParticipant,
      leadType: leadInfo.leadType,
      date: leadInfo.date,
      status: 'suitability',
      confirm: true,
    };

    try {
      const response = await hubApiCaptation.pathMetting(updatedMeeting);

      setLeadInfo(response);
      setLeadAnswered(true);
    } catch (err) {
      toast.dark('Erro ao atualizar se o lead atendeu!');
    }
  };

  if (isLoading) {
    return (
      <PageWrapper alignItems="center" justifyContent="center">
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  const handleGetListLeads = async (term?: string) => {
    let page = leadsListCurrentPage + 1;
    let shouldConcatLeadsResult = true;

    if (!term && lastTermSearched !== '') {
      setLeadsListCurrentPage(1);
      page = 1;
      shouldConcatLeadsResult = false;
    }

    if (term && lastTermSearched !== term) {
      setLastTermSearched(term);
      setLeadsListCurrentPage(1);
      page = 1;
      shouldConcatLeadsResult = false;
    }

    try {
      const responseData = await hubApiCaptation.getLeadsList(page, term);

      if (responseData.length < 1) {
        showToast('Todos os leads da lista já foram carregados');
        return;
      }

      if (shouldConcatLeadsResult) {
        setLeadsList(prev => [...prev, ...responseData]);
      } else {
        setLeadsList(responseData);
      }

      if (page > 1) {
        setLeadsListCurrentPage(page);
      }
    } catch {
      showToast(
        'Ocorreu um erro ao tentar buscar leads da lista. Tente novamente mais tarde.'
      );
    }
  };

  const handleGetMinedLeads = async (page: number, term?: string) => {
    let pageComputed = page;
    let shouldConcatLeadsResult = true;

    if (!term && lastTermSearchedMined !== '' && pageComputed >= 2) {
      setMinedLeadsCurrentPage(1);
      pageComputed = 1;
      shouldConcatLeadsResult = false;
    }

    if (term && lastTermSearchedMined !== term) {
      setMinedLeadsCurrentPage(1);
      pageComputed = 1;
      shouldConcatLeadsResult = false;
    }

    try {
      const responseData = await hubApiCaptation.getMinedLeads(
        pageComputed,
        term
      );

      if (
        responseData.length < 1 &&
        type === 'LeadsIndirect' &&
        leadType === 'leadstable'
      ) {
        showToast('Todos os leads minerados já foram carregados');
        return;
      }

      if (shouldConcatLeadsResult) {
        setMinedLeads([...minedLeads, ...responseData]);
      } else {
        setMinedLeads(responseData);
      }

      if (pageComputed > 1) {
        setMinedLeadsCurrentPage(pageComputed);
      }

      if (term || term === '') {
        setLastTermSearchedMined(term);
      }
    } catch {
      showToast(
        'Ocorreu um erro ao tentar buscar leads minerados. Tente novamente mais tarde.'
      );
    }
  };

  const handleRemoveMinedLeadById = (leadId: string) => {
    setMinedLeads(minedLeads.filter(lead => lead.id !== leadId));
  };

  const handleRemoveListLeadById = (leadId: string) => {
    setLeadsList(leadsList.filter(lead => lead.id !== leadId));
  };

  const getTotalNumOfLeadsInPipe = async () => {
    try {
      const { totalMeetingsInPipe } =
        await hubApiCaptation.getTotalNumOfLeads();

      setTotalNumOfLeads(Number(totalMeetingsInPipe));
    } catch (err) {
      toast.dark(
        'Não foi possível buscar o total de leads, tente novamente em alguns minutos ou comunique o suporte'
      );
    }
  };

  useEffect(() => {
    if (lastSavedCustomTermToSearch !== '') {
      handleGetListLeads(lastSavedCustomTermToSearch);
      handleGetMinedLeads(minedLeadsCurrentPage, lastSavedCustomTermToSearch);
    } else {
      handleGetMinedLeads(minedLeadsCurrentPage);
      handleGetMinedLeads(minedLeadsCurrentPage);
    }

    // para atualizar o valor no botao de cada etapa já no primeiro carregamento da página (pensar em como melhorar)
    handleGetSpecificLeadTypeArr('suitability', true);
    handleGetSpecificLeadTypeArr('presentation', true);
    handleGetSpecificLeadTypeArr('allocation', true);
    handleGetSpecificLeadTypeArr('account', true);
    handleGetSpecificLeadTypeArr('callback', true);

    handleGetAllPipe();
    const getStatus = async () => {
      setIsLoading(false);
    };

    getStatus();
    getTotalNumOfLeadsInPipe();
  }, []);

  const handleSelectedArrayLeads = (leads: any[]) => {
    setLeadSearched(leads);
  };

  return (
    <PageWrapper alignItems="center" justifyContent="center">
      <Flex
        gridGap="2"
        flexDir="column"
        width={{ base: '100%', lg: '100vw' }}
        maxW={{ base: '95vw', lg: '1180px' }}
        mt="32px"
      >
        <ChooseLeadsMenu
          width="100%"
          totalNumOfLeads={totalNumOfLeads}
          justifySelf="flex-end"
          alignSelf="flex-end"
          titleSelected={titleSelected}
          handleSelectLeadType={handleLeadsMenu}
          handleSelectedArrayLeads={handleSelectedArrayLeads}
          handleShowMeeting={handleShowMeeting}
          handleShowLead={handleShowLead}
          leadSearched={leadSearched}
          handleResults={handleResults}
          showResults={showResults}
        />

        <Flex flexDir={['column-reverse', 'row']} gridGap="2">
          <MeetingTypesMenu
            width="100%"
            maxWidth={['100vw', '200px']}
            minWidth="200px"
            titleSelected={titleSelected}
            handleSelectMeetingType={handleMeeting}
          />

          <DefaultCardWrapper
            flexDir="column"
            py="3"
            px="4"
            width={['100%', '82.4%']}
            minW="82.4%"
            h={{
              base: '532px',
              '2xl': '782px',
            }}
          >
            {type === 'pipe' && (
              <MeetingsPipe handleShowMeeting={handleShowMeeting} />
            )}

            {(type === 'meeting' || type === 'return') && (
              <MeetingsMosaic
                segment={segment}
                handleShowMeeting={handleShowMeeting}
                leadInfo={leadInfo}
                setType={setType}
              />
            )}

            {type === 'meetingLead' && (
              <MeetingDetailsContent
                updateTotalNumOfLeadsInPipe={getTotalNumOfLeadsInPipe}
                meetingInfos={leadInfo as IBaseMeeting}
                leadAnswered={leadAnswered}
                setLeadAnswered={setLeadAnswered}
                segment={segment}
                handleChangeMeetingMenu={handleMeeting}
                handleLeadAnsweredCall={handleLeadAnsweredCall}
                totalNumOfLeads={totalNumOfLeads}
              />
            )}

            {(type === 'DirectLeads' || type === 'indirectLeads') && (
              <CommonLeadContent
                updateTotalNumOfLeadsInPipe={getTotalNumOfLeadsInPipe}
                leadType={leadType}
                leadId={leadInfos.id}
                handleChangeLeadMenu={handleLeadsMenu}
                handleRemoveMinedLeadById={handleRemoveMinedLeadById}
                handleRemoveListLeadById={handleRemoveListLeadById}
                updateLastClickedLeadContactAttempts={
                  setUpdatedClickedLeadContactAttempts
                }
                setUpdatedClickedLeadContactAttemptsId={
                  setUpdatedClickedLeadContactAttemptsId
                }
                totalNumOfLeads={totalNumOfLeads}
              />
            )}

            {type === 'declined' && (
              <DeclinedLeadsList handleShowMeeting={handleShowMeeting} />
            )}

            {type === 'LeadsPurchased' && (
              <LeadsPurchased handleShowLead={handleShowLead} />
            )}

            {type === 'pool' && <LeadsPoolMI />}

            {type === 'LeadsList' && leadType === 'leadstable' && (
              <LeadsList
                leadType={leadType}
                handleShowLead={handleShowLead}
                minedLeadsList={leadsList}
                updateMinedLeadsList={handleGetListLeads}
                currentMinedLeadsListPage={leadsListCurrentPage}
                updateLastClickedMinedLeadCardId={setLastClickedListLeadCardId}
                lastClickedMinedLeadCardId={lastClickedListLeadCardId}
                updateSectionScrollPosition={setListLeadsMosaicScrollPosition}
                sectionScrollPosition={listLeadsMosaicScrollPosition}
                updatedClickedLeadContactAttempts={
                  updatedClickedLeadContactAttempts
                }
                updatedClickedLeadContactAttemptsId={
                  updatedClickedLeadContactAttemptsId
                }
              />
            )}

            {type === 'LeadsIndirect' && leadType === 'leadstable' && (
              <MinedLeadMosaic
                leadType={leadType}
                handleShowLead={handleShowLead}
                minedLeadsList={minedLeads}
                updateMinedLeadsList={handleGetMinedLeads}
                currentMinedLeadsListPage={minedLeadsCurrentPage}
                updateLastClickedMinedLeadCardId={setLastClickedMinedLeadCardId}
                updateSectionScrollPosition={setMinedLeadsMosaicScrollPosition}
                sectionScrollPosition={minedLeadsMosaicScrollPosition}
                lastClickedMinedLeadCardId={lastClickedMinedLeadCardId}
                updatedClickedLeadContactAttempts={
                  updatedClickedLeadContactAttempts
                }
                updatedClickedLeadContactAttemptsId={
                  updatedClickedLeadContactAttemptsId
                }
              />
            )}

            {type === 'LeadsIndirect' && leadType !== 'leadstable' && (
              <ManualLeadsMosaic
                leadType={leadType}
                handleShowLead={handleShowLead}
              />
            )}

            {type === 'search' && (
              <ManualAndSearchLeadsContentById
                lead={leadInfos as ILeadInfos}
                updateTotalNumOfLeadsInPipe={getTotalNumOfLeadsInPipe}
              />
            )}
          </DefaultCardWrapper>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default Captation;
