import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { RiUserLocationLine } from 'react-icons/ri';

import { FaArrowDown, FaBirthdayCake } from 'react-icons/fa';
import { AiOutlineForm } from 'react-icons/ai';
import { MdMoneyOff } from 'react-icons/md';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { CardsInfos } from 'pages/MyClients/interfaces';
import formatValue from 'utils/formatValue';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface ClientsTableViewProps {
  clients: CardsInfos[];
}

export const ClientsTableView: React.FC<ClientsTableViewProps> = ({
  clients,
}) => {
  const history = useHistory();

  const handleSelectedClient = (btgAccount: string) => {
    history.push(`/user/users_clients/new/${btgAccount}`);
  };

  return (
    <TableBase
      headData={[
        'Nome',
        'Pendências',
        'Dinheiro Parado',
        'Patrimônio Declarado',
        'PL',
      ]}
      width="100%"
      maxH="calc(100vh - 230px)"
      overflowY="auto"
      p="0"
      mx="auto"
    >
      {clients?.map(client => (
        <TableRow
          key={client.id}
          transition="all 0.1s ease-in"
          cursor="pointer"
          _hover={{
            background: 'background.400',
          }}
          onClick={() => handleSelectedClient(client.btgAccount)}
        >
          <TableData display="flex" justifyContent="center" alignItems="center">
            {(((Number(client.frequencyM?.split(' | ')[0]) > 30 &&
              Number(client.pl) > 1000000) ||
              (Number(client.frequencyM?.split(' | ')[0]) > 60 &&
                Number(client.pl) > 300000 &&
                Number(client.pl) <= 1000000) ||
              (Number(client.frequencyM?.split(' | ')[0]) > 90 &&
                Number(client.pl) > 100000 &&
                Number(client.pl) <= 300000) ||
              (Number(client.frequencyM?.split(' | ')[0]) > 180 &&
                Number(client.pl) < 100000)) && (
              <>
                <Flex mr="auto">
                  <FaArrowDown
                    title="Frequência de contato abaixo do ideal"
                    color="red"
                  />
                </Flex>
                <Text maxW="300px" mr="auto" isTruncated>
                  {formatNameToCaptalize(client.name)}
                </Text>
              </>
            )) || (
              <Text maxW="300px" isTruncated>
                {formatNameToCaptalize(client.name)}
              </Text>
            )}
          </TableData>
          <TableData>
            <Flex gridGap={1} justifyContent="center">
              {client.birthday && (
                <FaBirthdayCake
                  size={17}
                  color="#fff"
                  title="Aniversário"
                  className="pendenciesIcon"
                />
              )}
              {client.nps && (
                <AiOutlineForm
                  size={18}
                  color="#fff"
                  title="NPS - Satisfação do Cliente"
                  className="pendenciesIcon"
                />
              )}
              {client.balance && (
                <MdMoneyOff
                  size={19}
                  color="#fff"
                  title="Saldo Negativo"
                  className="pendenciesIcon"
                />
              )}
              {client.fromType && (
                <RiUserLocationLine
                  size={18}
                  color="#fff"
                  title="Origem do Cliente"
                  className="pendenciesIcon"
                />
              )}
              {!client.birthday &&
                !client.nps &&
                !client.balance &&
                !client.fromType && <Text>-</Text>}
            </Flex>
          </TableData>
          <TableData>{formatValue(client.todayBalance)}</TableData>
          <TableData>{formatValue(client.declaredPL)}</TableData>
          <TableData>{formatValue(client.pl)}</TableData>
        </TableRow>
      ))}
    </TableBase>
  );
};
