import React, { useState } from 'react';
import parser from 'html-react-parser';

import { Text, Flex, Stack, FlexProps } from '@chakra-ui/react';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiFillEdit,
} from 'react-icons/ai';

import { ModalWrapper } from 'components/elements/wrappers';

import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { formateDateAndTime } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { ModalCreateOrUpdateAgreement } from '../../wrappers/ModalCreateOrUpdateAgreement';

interface CardListAgreementProps extends FlexProps {
  agreement: IAgreement;
  allAgreement: IAgreement[];
  setAllAgreement: React.Dispatch<React.SetStateAction<IAgreement[]>>;
}

export const CardListAgreement: React.FC<CardListAgreementProps> = ({
  agreement,
  setAllAgreement,
  allAgreement,
}: CardListAgreementProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Flex flexDir="column" w="98%">
      <Stack
        flexDir="row"
        spacing={0}
        justifyContent="space-between"
        fontWeight="bold"
        mb="18px"
        pb="1"
        borderBottom="1px solid gray"
      >
        <Flex alignItems="center" gridGap="3">
          {!agreement.accept && (
            <Flex
              p="1"
              borderRadius="3"
              border="1px solid"
              borderColor="rgba(70, 70, 70, 1)"
              cursor="pointer"
              onClick={() => setShowModal(!showModal)}
            >
              <AiFillEdit color="rgba(70, 70, 70, 1)" size={13} />
            </Flex>
          )}
          <Text fontSize="lg" fontWeight="bold" color="rgba(70, 70, 70, 1)">
            {formatNameToCaptalize(agreement.title)}
          </Text>
        </Flex>
        <Flex alignItems="center" mb="6px">
          <Text
            mr="8px"
            fontSize="medium"
            color="rgba(70, 70, 70, 1)"
            fontWeight="bold"
          >
            <Text as="span" fontWeight="normal" color="rgba(70, 70, 70, 1)">
              Data de criação
            </Text>{' '}
            {formateDateAndTime(agreement.created_at)}
          </Text>

          {agreement.accept ? (
            <AiOutlineCheckCircle color="#33FC12" />
          ) : (
            <AiOutlineCloseCircle color="#CB0000" />
          )}
        </Flex>
      </Stack>
      <Flex
        borderRadius="8px"
        bg="white"
        mb="6px"
        p="4"
        birder="1px solid red"
        justify="space-between"
        color="rgba(70, 70, 70, 1)"
      >
        <Text
          w="100%"
          fontSize="sm"
          lineHeight="150%"
          minH="64px"
          textAlign="justify"
        >
          {parser(agreement.agreement)}{' '}
        </Text>
      </Flex>
      <Flex
        justify={agreement.accept ? 'space-between' : 'flex-end'}
        align="center"
      >
        {agreement.accept && (
          <Text
            fontSize="sm"
            as="span"
            lineHeight="14,14px"
            color="rgba(70, 70, 70, 1)"
          >
            Acordo assinado dia{' '}
            <Text fontWeight="bold" as="span">
              {formateDateAndTime(agreement.updated_at)}
            </Text>
          </Text>
        )}

        <Text textAlign="right" fontSize="small" color="rgba(70, 70, 70, 1)">
          Redigido por
          <Text as="span" lineHeight="14,14px" fontWeight="semibold">
            {' '}
            {formatNameToCaptalize(agreement.managerName)}
          </Text>
        </Text>
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(!showModal)}>
        <ModalCreateOrUpdateAgreement
          setAllAgreement={setAllAgreement}
          allAgreement={allAgreement}
          agreement={agreement}
          employeeId={agreement.employeeId}
          onClose={() => setShowModal(!showModal)}
        />
      </ModalWrapper>
    </Flex>
  );
};
