import React, { FormEvent } from 'react';
import { toast } from 'react-toastify';
import { ButtonGroup, Stack } from '@chakra-ui/react';

import formatValue from 'utils/formatValue';
import { KanbanBaseTaskProps } from 'shared/interfaces';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import { errorHandler } from 'utils';

// import SharesDetailsFinally from '../SharesDetailsFinally';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
  sheres?: Array<string>;
  description?: string;
  strategy?: {
    operation?: string;
    ticker?: string;
  };
  segment?: string;
  clientName?: string;
  clientBtgAccount?: string;
  clientEmail?: string;
  value?: string;
  risk?: string;
}

interface ComponentProps {
  order: TaskProps;
  handleToggleModal: () => void;
  setIsFinished: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoardCloseOrder: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setIsFinished,
}) => {
  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await hubApiRv
      .updateOrderStatus(order.id)
      .then(() => {
        toast.dark(
          'Orderm finalizada com sucesso. A mesma será removida ao recarregar a tela'
        );
        setIsFinished(true);
        handleToggleModal();
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  return (
    <DefaultModalContentWrapper>
      {order.type === 'STRUCTURED' ? (
        <ModalTitle>
          {order.strategy?.operation} de {order.strategy?.ticker}
        </ModalTitle>
      ) : (
        <ModalTitle>{order.segment}</ModalTitle>
      )}

      <Stack spacing="2">
        {order.assessorName && (
          <DisplayInformation
            label="Nome do Assessor"
            info={order.assessorName}
          />
        )}

        {order.clientName && (
          <DisplayInformation label="Nome do cliente" info={order.clientName} />
        )}

        {order.clientBtgAccount && (
          <DisplayInformation
            label="Código do cliente"
            info={order.clientBtgAccount}
          />
        )}

        {order.clientEmail && (
          <DisplayInformation
            label="E-mail do cliente"
            info={order.clientEmail}
          />
        )}

        {order.value && (
          <DisplayInformation
            label="Financeiro"
            info={formatValue(order.value)}
          />
        )}
        {order.risk && <DisplayInformation label="Risco" info={order.risk} />}

        <form onSubmit={handleFormSubmit}>
          <ButtonGroup width="100%" mt="3" justifyContent="flex-end">
            <SecondaryButton type="button" onClick={handleToggleModal}>
              Fechar
            </SecondaryButton>
            <PrimaryButton type="submit">Finalizar ordem</PrimaryButton>
          </ButtonGroup>
        </form>
      </Stack>
    </DefaultModalContentWrapper>
  );
};

export default BoardCloseOrder;
