import { Flex } from '@chakra-ui/react';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { useEffect, useState } from 'react';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { PreviousAdvsorClient } from './PreviousAdvisorCard';

interface IProps {
  client: ClientInfosType;
}

interface IAdvisor {
  id: string;
  name: string;
  formattedStartDate: string;
  formattedEndDate: string;
}

export const PreviousAdvisorSection: React.FC<IProps> = ({ client }) => {
  const [previousAdvisor, setPreviousAdvisor] = useState<IAdvisor[]>([]);

  const handleGetPreviousAdvisor = async () => {
    const advisors: IAdvisor[] = await hubApiUser.getPreviousAdvisorFromClient({
      clientBTGCode: client.btgAccount,
    });
    setPreviousAdvisor(advisors);
  };

  useEffect(() => {
    handleGetPreviousAdvisor();
  }, []);

  return (
    <Flex flexDir="column">
      <InfoTitle title="Histórico de assessores" />
      <Flex
        flexWrap="wrap"
        w="100%"
        gridGap={2}
        mt="3"
        maxH="calc(100vh - 560px)"
        overflow="auto"
      >
        {previousAdvisor.map(advisor => (
          <PreviousAdvsorClient
            advisorName={advisor.name}
            key={advisor.id}
            endDate={advisor.formattedEndDate}
            startDate={advisor.formattedStartDate}
          />
        ))}
      </Flex>
    </Flex>
  );
};
