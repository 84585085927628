import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { FiLink } from 'react-icons/fi';
import { toast } from 'react-toastify';
// import { io } from 'socket.io-client';
// import { useSelector } from 'react-redux';
import { formatToCapitalized } from 'brazilian-values';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { formatValue } from 'react-currency-input-field';
import { ContainerMarque } from './styles';

interface News {
  type: string;
  title: string;
  link: string;
}

export const NewsBar: React.FC = () => {
  const [notices, setNotices] = useState<News[]>([]);

  // const user = useSelector(state => state.user.profile);

  // const createClinetIo = useCallback(() => {
  // const client = io(hubApiProducts.baseURL, {
  //     query: { user_id: 'HOME' },
  // });
  // return client;
  // }, [user.id]);

  // useEffect(() => {
  //     const client = createClinetIo();
  //     client.on('noticia', (noticeOk) => {
  //         setTeste(noticeOk);
  //     });
  // }, [createClinetIo]);

  useEffect(() => {
    hubApiProducts
      .getNotices()
      .then(response => {
        setNotices(response);
      })
      .catch(() => {
        toast.dark(
          'Ocorreu um erro ao buscar todas notícias, por favor tente novamente.'
        );
      });
  }, []);

  return (
    <>
      {notices.length > 0 && (
        <ContainerMarque>
          <Marquee className="marquee" pauseOnHover speed={50}>
            {notices.map((notice, index) => (
              <div className="main" key={index}>
                <div className="notice" key={index}>
                  {notice.type === 'notices' && notice.link !== '' ? (
                    <a href={notice.link} target="_blank" rel="noreferrer">
                      <FiLink size={10} />
                      <p>{notice.title}</p>
                    </a>
                  ) : (
                    <>{!notice.link && <p>{notice.title}</p>}</>
                  )}

                  {notice.type === 'captation' && (
                    <>
                      <p>
                        {formatToCapitalized(notice.title, {
                          trimTrailingWhiteSpaces: false,
                        })}{' '}
                        Tocou o sino e captou{' '}
                        {formatValue({
                          value: notice.link,
                          prefix: 'R$: ',
                        })}{' '}
                      </p>
                    </>
                  )}
                </div>
              </div>
            ))}
          </Marquee>
        </ContainerMarque>
      )}
    </>
  );
};
