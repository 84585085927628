/* eslint no-unused-vars: off */
import React from 'react';
import { FlexProps } from '@chakra-ui/react';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultCardWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface FormCardContent extends FlexProps {
  title: string;
  isLoading: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
  secondaryButtonEventFunction?: () => void;
  primaryButtonDisabled?: boolean;
  handleToggleModal?: () => void;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoadingSecondaryButton?: boolean;
}

interface ComponentProps extends FormCardContent {
  isModal: boolean;
}

const FormCardContent: React.FC<FormCardContent> = ({
  children,
  title,
  isLoading,
  handleToggleModal,
  handleFormSubmit,
  secondaryButtonEventFunction,
  hideSecondaryButton,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  isLoadingSecondaryButton,
}) => {
  return (
    <>
      <ModalTitle color="rgba(70, 70, 70, 1)">{title}</ModalTitle>

      <form onSubmit={handleFormSubmit}>
        {children}

        <BaseButtonGroup mt="4">
          {!hideSecondaryButton && (
            <SecondaryButton
              size="sm"
              type="button"
              onClick={secondaryButtonEventFunction || handleToggleModal}
              isLoading={isLoadingSecondaryButton}
            >
              {secondaryButtonText}
            </SecondaryButton>
          )}
          <PrimaryButton
            size="sm"
            isLoading={isLoading}
            type="submit"
            style={{ margin: 0 }}
            isDisabled={primaryButtonDisabled}
          >
            {primaryButtonText}
          </PrimaryButton>
        </BaseButtonGroup>
      </form>
    </>
  );
};

export const BaseModalFormCard: React.FC<ComponentProps> = ({
  children,
  title,
  isLoading,
  handleToggleModal,
  handleFormSubmit,
  secondaryButtonEventFunction,
  hideSecondaryButton,
  isModal,
  isLoadingSecondaryButton,
  primaryButtonText = 'Enviar',
  secondaryButtonText = 'Cancelar',
  primaryButtonDisabled = false,
  ...rest
}) => {
  if (isModal) {
    return (
      <DefaultModalContentWrapper {...rest}>
        <FormCardContent
          title={title}
          handleFormSubmit={handleFormSubmit}
          handleToggleModal={handleToggleModal}
          secondaryButtonText={secondaryButtonText}
          primaryButtonText={primaryButtonText}
          secondaryButtonEventFunction={secondaryButtonEventFunction}
          hideSecondaryButton={hideSecondaryButton}
          isLoading={isLoading}
          primaryButtonDisabled={primaryButtonDisabled}
          isLoadingSecondaryButton={isLoadingSecondaryButton}
        >
          {children}
        </FormCardContent>
      </DefaultModalContentWrapper>
    );
  }
  return (
    <DefaultCardWrapper flexDir="column" width="100%" {...rest}>
      <FormCardContent
        title={title}
        handleFormSubmit={handleFormSubmit}
        handleToggleModal={handleToggleModal}
        secondaryButtonEventFunction={secondaryButtonEventFunction}
        secondaryButtonText={secondaryButtonText}
        primaryButtonText={primaryButtonText}
        hideSecondaryButton={hideSecondaryButton}
        isLoading={isLoading}
        isLoadingSecondaryButton={isLoadingSecondaryButton}
      >
        {children}
      </FormCardContent>
    </DefaultCardWrapper>
  );
};
