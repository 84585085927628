import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { showToast } from 'utils';
import { Input } from 'components/elements/forms';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { ManagerLeadDetails } from 'pages/Captation/components/modules/manager';
import { ManagerSearchLeadCard } from 'pages/Captation/components/elements/cards';
import { ISearchLeadItem } from 'pages/Captation/interfaces';
import { useQuery } from 'react-query';

export const ManagerSearchLead: React.FC = () => {
  const [openLeadDetails, setOpenLeadDetails] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedLead, setSelectedLead] = useState<ISearchLeadItem>(
    {} as ISearchLeadItem
  );

  const {
    data: leadsFound,
    refetch: refetchLeads,
    isLoading,
    isRefetching,
  } = useQuery<ISearchLeadItem[]>({
    queryKey: ['leadsFound'],
    queryFn: async () => {
      let searchParam;

      if (searchValue.includes('@')) {
        searchParam = searchValue.replace(/\s/g, '');
      } else if (/\d/.test(searchValue) && !searchValue.includes('@')) {
        searchParam = searchValue.replace(/[\s()-]/g, '');
      } else {
        searchParam = searchValue;
      }

      const response = await hubApiCaptation.searchLead(searchParam);
      return response;
    },
    enabled: false,
    cacheTime: 0,
    onSuccess: () => {
      setSearchValue('');
    },
    onError: () => {
      showToast(
        'Não foi possível encontrar os leads. Tente novamente mais tarde.'
      );
    },
  });

  const toggleLeadDetails = (clickedLead: ISearchLeadItem) => {
    setSelectedLead(clickedLead);
    setOpenLeadDetails(!openLeadDetails);
  };

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleSearchLead = async () => {
    if (searchValue.length < 4) {
      showToast('Dígite pelo menos 4 caracteres para uma busca mais precisa');
      return;
    }

    refetchLeads();
  };

  return (
    <PageWrapper
      minH="calc(100vh - 80px)"
      flexDir="column"
      alignItems="center"
      padding="48px 20px 0"
    >
      <BackPageButton />

      <MainPageTitle textAlign="center">Pesquisa de leads</MainPageTitle>

      {openLeadDetails && selectedLead && (
        <ManagerLeadDetails
          refetchLeadsFound={refetchLeads}
          leadId={selectedLead.id}
          leadType={selectedLead.leadType}
          handleToggleLeadDetails={() => setOpenLeadDetails(!openLeadDetails)}
        />
      )}

      {!openLeadDetails && (
        <>
          <Flex
            width="100%"
            maxW="640px"
            flexDir="row"
            alignItems="center"
            gridGap="2"
            mt="3.5"
          >
            <Input
              placeholder="Digite o nome, telefone ou email do lead"
              value={searchValue}
              onChange={handleChangeSearchValue}
            />
            <PrimaryButton
              h="40px"
              isLoading={isLoading || isRefetching}
              onClick={handleSearchLead}
            >
              Buscar
            </PrimaryButton>
          </Flex>

          {!leadsFound && (
            <Flex mt={10}>
              <Text fontStyle="italic">
                Busque um lead por nome, telefone ou email para ver detalhes
              </Text>
            </Flex>
          )}

          {leadsFound && leadsFound.length > 0 && (
            <DefaultCardWrapper
              width="100%"
              maxW="720px"
              background="background.700"
              p="2.5"
              mt="8"
            >
              <Flex
                width="100%"
                flexDir="column"
                maxH="calc(100vh - 312px)"
                gridGap="2"
                overflowY="auto"
                pr="1.5"
              >
                {leadsFound.map(lead => (
                  <ManagerSearchLeadCard
                    key={lead.id}
                    lead={lead}
                    handleToggleLeadDetails={toggleLeadDetails}
                  />
                ))}
              </Flex>
            </DefaultCardWrapper>
          )}
        </>
      )}
    </PageWrapper>
  );
};
