import { Flex } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface ILeadSubmissionQtdModalProps {
  leadSubmissionQtd: number;
  handleValueChangeInLeadSubmissionModal: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onClose: () => void;
}

export const LeadSubmissionQtdModal: React.FC<ILeadSubmissionQtdModalProps> = ({
  leadSubmissionQtd,
  handleValueChangeInLeadSubmissionModal,
  onClose,
}) => {
  return (
    <DefaultModalContentWrapper
      w="350px"
      height="200px"
      flexDirection={'column'}
      alignItems="center"
      justifyContent={'space-around'}
      gap={1}
    >
      <ModalTitle>Quantidade de leads</ModalTitle>
      <Flex
        flex={2}
        width="100%"
        flexDirection={'column'}
        alignItems="center"
        justifyContent={'space-between'}
        gap={3}
      >
        <InputWithLabel
          label="Qtd. de leads"
          width={300}
          type="number"
          name="sendLeadsQtd"
          placeholder={!leadSubmissionQtd ? '0' : ''}
          value={leadSubmissionQtd}
          onChange={handleValueChangeInLeadSubmissionModal}
        />
      </Flex>
      <BaseButtonGroup>
        <PrimaryButton onClick={onClose}>Fechar</PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
