import React, { useCallback, useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils/errorHandler';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { ProductManagementLayout } from 'pages/products/components/layouts';
import { ProductManagementCard } from 'pages/products/components/elements/cards';

import { COEProps } from 'pages/products/interfaces';

interface ICOEProps extends COEProps {
  name: string;
  id: string;
}

export const CoeManagement: React.FC = () => {
  const history = useHistory();
  const [coes, setCoes] = useState<ICOEProps[]>([]);

  const handleClickCreateCoeButton = useCallback(() => {
    history.push('/products/coe/create');
  }, [history]);

  const handleClickEditCoeButton = useCallback(
    id => {
      history.push(`/products/coe/update/${id}`);
    },
    [history]
  );

  const handleDeleteCoe = useCallback(
    async id => {
      try {
        await hubApiProducts.deleteCoe(id);
        setCoes(coes.filter(coe => coe.id !== id));
        toast.dark('COE Deleteado com sucesso.');
      } catch (error) {
        toast.dark('Ocorreu um erro ao deletar o COE.');
      }
    },
    [coes]
  );

  useEffect(() => {
    hubApiProducts
      .getAllCoe()
      .then(data => {
        setCoes(data);
      })
      .catch(error => {
        errorHandler(error);
      });
  }, []);

  if (coes.length <= 0) {
    return (
      <ProductManagementLayout
        title="Gerenciar COEs"
        buttonText="Criar novo COE"
        handleCreateProduct={handleClickCreateCoeButton}
      >
        <Text color="white">Não existem COEs cadastrados.</Text>
      </ProductManagementLayout>
    );
  }

  return (
    <ProductManagementLayout
      title="Gerenciar COEs"
      buttonText="Criar novo COE"
      handleCreateProduct={handleClickCreateCoeButton}
    >
      {coes?.map((coe, index) => (
        <ProductManagementCard
          key={index}
          productId={coe.id}
          title={coe.name}
          handleEditProduct={handleClickEditCoeButton}
          handleRemoveProduct={handleDeleteCoe}
        />
      ))}
    </ProductManagementLayout>
  );
};
