import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { Dropdown } from 'components/elements/others';
import {
  InfoTitle,
  OffersInOrdersPanelCard,
  OrdersPanelCard,
} from 'pages/MyClients/components/elements';
import { areaOptions, periodOptions } from 'pages/MyClients/interfaces';
import {
  OfferInOrdersPanel,
  OrdersPanelData,
} from 'pages/MyClients/interfaces/OrdersPanelData';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

type OrdersPanelProps = {
  orders: Array<OrdersPanelData>;
  showBackPageButton: boolean;
  setShowBackPageButton: React.Dispatch<React.SetStateAction<boolean>>;
  clientBTGAccount: string;
};

export const Orders: React.FC<OrdersPanelProps> = ({
  orders,
  showBackPageButton,
  setShowBackPageButton,
  clientBTGAccount,
}) => {
  const [orderFiltredByMonth, setOrderFiltredByMonth] =
    useState<OrdersPanelData[]>(orders);
  const [monthAux, setMonthAux] = useState(0);
  const [areaAux, setAreaAux] = useState('');
  const [offers, setOffers] = useState<OfferInOrdersPanel[]>([]);

  if (!showBackPageButton) setShowBackPageButton(true);

  const getOffers = async () => {
    try {
      const response = await hubApiUser.listClientsOffers(clientBTGAccount);
      setOffers(response);
    } catch (error) {
      const axiosError = error as AxiosError;

      const errorMessage = axiosError.response?.data.error.message as string;

      if (errorMessage.includes('Client has no offers available')) {
        toast.dark('Este cliente não entrou em nenhuma oferta até o momento');
      } else {
        toast.dark(
          'Não foi possível buscar as ofertas do cliente, tente novamente em alguns minutos'
        );
      }

      setOffers([]);
    }
  };

  const handleAreaDropdown = (area: string) => {
    if (area === 'Ofertas') {
      setAreaAux(area);
      return;
    }
    if (area === '' && monthAux === 0) {
      setAreaAux(area);
      return setOrderFiltredByMonth(orders);
    }

    if (monthAux === 0) {
      const result = orders.filter(oneOrder => oneOrder.area === area);

      if (!result.length) {
        return toast.dark('Não há ordens nesta área');
      }

      setOrderFiltredByMonth(result);
      setAreaAux(area);
    } else {
      setAreaAux(area);
      const result = orders.filter(
        oneOrder =>
          new Date(oneOrder.created_at).getMonth() + 1 === Number(monthAux)
      );

      if (result.length && area) {
        const resultArea = result.filter(
          oneOrderByArea => oneOrderByArea.area === area
        );

        return setOrderFiltredByMonth(resultArea);
      }
      setOrderFiltredByMonth(result);
    }
  };

  const handlePeriodDropdown = (month: string) => {
    if (areaAux === '' && month === '') {
      return setOrderFiltredByMonth(orders);
    }

    if (areaAux === '') {
      setMonthAux(Number(month));
      const result = orders.filter(
        oneOrder =>
          new Date(oneOrder.created_at).getMonth() + 1 === Number(month)
      );
      return setOrderFiltredByMonth(result);
    }

    if (month === '') {
      if (areaAux === '') {
        setOrderFiltredByMonth(orders);
      } else {
        const resultArea = orders.filter(
          oneOrderByArea => oneOrderByArea.area === areaAux
        );
        setOrderFiltredByMonth(resultArea);
        setMonthAux(0);
      }
    } else {
      setMonthAux(Number(month));

      const result = orders.filter(
        oneOrder =>
          new Date(oneOrder.created_at).getMonth() + 1 === Number(month)
      );

      if (result.length) {
        const resultArea = result.filter(
          oneOrderByArea => oneOrderByArea.area === areaAux
        );

        return setOrderFiltredByMonth(resultArea);
      }
      setOrderFiltredByMonth(result);
    }
  };

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <Flex
      flexDir="column"
      width="100%"
      height={['60vh', 'calc(100vh - 224px)']}
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <InfoTitle title="Ordens do cliente" />

        <Flex w="100%" gridGap="2" justifyContent="flex-end">
          <Dropdown
            placeholder="Todas as áreas"
            onChange={e => handleAreaDropdown(e.target.value)}
          >
            {areaOptions?.map(item => (
              <option key={item.title} value={item.title}>
                {item.title}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            placeholder="Todos os periodos"
            onChange={e => handlePeriodDropdown(e.target.value)}
          >
            {periodOptions?.map(item => (
              <option key={item.title} value={item.value}>
                {item.title}
              </option>
            ))}
          </Dropdown>
        </Flex>
      </Flex>

      <SimpleGrid
        columns={orderFiltredByMonth.length > 0 ? [1, 1, 2, 3, 4] : [0]}
        spacing="15px"
        mt="24px"
        p="3"
        h="100%"
        maxH="calc(100vh - 300px)"
        overflow="auto"
      >
        {monthAux === 0 &&
          areaAux === '' &&
          [...offers, ...orderFiltredByMonth].map((item: any) => {
            if (item.id) {
              return <OrdersPanelCard key={item.id} order={item} />;
            }
            return <OffersInOrdersPanelCard key={item.offerId} offer={item} />;
          })}

        {areaAux === 'Ofertas' &&
          offers.length > 0 &&
          offers.map(offer => (
            <OffersInOrdersPanelCard key={offer.offerId} offer={offer} />
          ))}

        {areaAux === 'Ofertas' && offers.length < 1 && (
          <Text
            whiteSpace="nowrap"
            fontWeight="light"
            textAlign="center"
            alignSelf="center"
          >
            Este cliente não está em nenhuma oferta
          </Text>
        )}

        {orderFiltredByMonth.length > 0 &&
          areaAux !== 'Ofertas' &&
          areaAux !== '' &&
          orderFiltredByMonth?.map(order => (
            <OrdersPanelCard key={order.id} order={order} />
          ))}

        {orderFiltredByMonth.length < 1 &&
          areaAux !== '' &&
          areaAux !== 'Ofertas' && (
            <Text
              whiteSpace="nowrap"
              fontWeight="light"
              textAlign="center"
              alignSelf="center"
            >
              Não há ordens para este cliente
            </Text>
          )}
        {orderFiltredByMonth.length < 1 && offers.length < 1 && areaAux === '' && (
          <Text
            whiteSpace="nowrap"
            fontWeight="light"
            textAlign="center"
            alignSelf="center"
          >
            Não há ordens ou ofertas para este cliente
          </Text>
        )}
      </SimpleGrid>
    </Flex>
  );
};
