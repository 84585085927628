import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { MiAaiCaptationData } from 'pages/Captation/interfaces';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { TableBase } from 'components/elements/table';
import { EditPointsForm } from 'components/modules/Management';
import { MainPageTitle } from 'components/elements/texts';
import { Dropdown } from 'components/elements/others';

import {
  DefaultModalContentWrapper,
  ModalWrapper,
  DefaultPageWrapper,
} from 'components/elements/wrappers';

import { EditLevelForm } from 'components/modules/Management/EditLevelForm';
import { miCaptationTableHead } from './data';
import { TableAAIMi } from '../TableAAiMI';

type Table = {
  id: string;
  name: string;
};
type Offices = {
  id: string;
  city: string;
};

interface MundoInvestCaptationProps {
  offices: Offices[];
}

export const MundoInvestCaptation: React.FC<MundoInvestCaptationProps> = ({
  offices,
}) => {
  const [selectedOfficeId, setSelectedOfficeId] = useState('');

  const [tables, setTables] = useState<Table[]>([]);
  const [selectedTableId, setSelectedTableId] = useState('');

  const [showUpdatePoints, setShowUpdatePoints] = useState(false);
  const [aaiList, setAaiList] = useState<MiAaiCaptationData[]>([]);
  const [aaiListCopy, setAaiListCopy] = useState<MiAaiCaptationData[]>([]);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    {} as MiAaiCaptationData
  );

  const listAllTables = async (): Promise<void> => {
    try {
      const tablesData = await hubApiOfficeTables.listTablesByOffice(
        selectedOfficeId
      );

      setTables(tablesData);
    } catch (err) {}
  };

  const getAllAaiListByTable = async (): Promise<void> => {
    setNoSearchResults(false);

    try {
      const data = await hubApiOfficeTables.listMIUsers();

      setAaiList(data);
      setAaiListCopy(data);
    } catch (error) {
      setAaiList([]);
      errorHandler(error);
    }
  };

  const filterEmployees = () => {
    if (selectedTableId) {
      const result = aaiList.filter(aaiListOne =>
        aaiListOne.tableId?.trim().includes(selectedTableId)
      );
      setAaiListCopy(result);

      if (result.length < 1) {
        setNoSearchResults(true);
      } else {
        setNoSearchResults(false);
      }
    } else {
      setAaiListCopy(aaiList);
      setNoSearchResults(false);
    }
  };

  const handleEmployeePoints = (employeeId: MiAaiCaptationData) => {
    setSelectedEmployeeId(employeeId);
    setShowModal(true);
  };
  const handleEmployeeLevel = (employeeId: MiAaiCaptationData) => {
    setSelectedEmployeeId(employeeId);
    setShowUpdatePoints(true);
  };

  useEffect(() => {
    getAllAaiListByTable();
    listAllTables();
  }, []);

  useEffect(() => {
    listAllTables();
  }, [selectedOfficeId, offices]);

  useEffect(() => {
    filterEmployees();
  }, [selectedTableId]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Gestão AAI (MI)</MainPageTitle>

      <Flex w="100%" justifyContent="flex-end" mb={2}>
        <Dropdown
          placeholder="Selecione um escritório"
          onChange={e => setSelectedOfficeId(e.target.value)}
        >
          {offices.map(office => (
            <option key={office.id} value={office.id}>
              {office.city}
            </option>
          ))}
        </Dropdown>

        <Dropdown
          placeholder="Todos os AAIs"
          ml={1}
          onChange={e => setSelectedTableId(e.target.value)}
        >
          {tables.map(table => (
            <option key={table.id} value={table.id}>
              {table.name}
            </option>
          ))}
        </Dropdown>
      </Flex>

      <TableBase
        headData={miCaptationTableHead}
        p="0"
        w="100%"
        height="100vh"
        maxH="calc(100vh - 254px)"
        overflowY="auto"
      >
        {aaiListCopy?.map(item => (
          <TableAAIMi
            aaiList={item}
            key={item.id}
            handleEmployeePoints={handleEmployeePoints}
            handleEmployeeLevel={handleEmployeeLevel}
          />
        ))}
      </TableBase>

      {noSearchResults && (
        <Text mt={20} textAlign="center" fontWeight="light" fontStyle="italic">
          Nenhum Assessor(a) foi encontrado nesta mesa
        </Text>
      )}

      <ModalWrapper
        isOpen={showUpdatePoints}
        onClose={() => setShowUpdatePoints(false)}
      >
        <DefaultModalContentWrapper
          flexDir="row"
          width="fit-content"
          maxW="600px"
          minH="220px"
        >
          <EditLevelForm
            employee={selectedEmployeeId}
            employeeRole="aai"
            updatePoints={getAllAaiListByTable}
          />
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(false)}>
        <DefaultModalContentWrapper
          flexDir="row"
          width="fit-content"
          maxW="600px"
          minH="220px"
        >
          <EditPointsForm
            employee={selectedEmployeeId}
            employeeRole="aai"
            updatePoints={getAllAaiListByTable}
          />
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
