import { Flex, Text } from '@chakra-ui/react';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import formatValue from 'utils/formatValue';

interface IProps {
  plMi: number;
}

export const LeadPLMundoInvest: React.FC<IProps> = ({ plMi }) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle mb="1">Patrimônio Mundo Invest</ContentDividerTitle>
      <Flex flexWrap="wrap" gridGap="1.5">
        <Text fontSize="xs">{formatValue(plMi)}</Text>
      </Flex>
    </ContentWithVertDividerWrapper>
  );
};
