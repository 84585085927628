import { useQueryParams } from 'hooksV2/useQueryParams';

export type UseSimpleFilterResponseType<T = string> = {
  filterValue: T;
  handleChangeFilterValue: (value: T) => void;
  handleDeleteParam: () => void;
};

type UseSimpleQueryFilterOptions<T = string> = {
  initialValue?: T;
};

export const useSimpleQueryFilter = <T = string>(
  filterName: string,
  options?: UseSimpleQueryFilterOptions<T>
): UseSimpleFilterResponseType<T> => {
  const { setParam, getParam, deleteParam } = useQueryParams();

  const filterValue = getParam(filterName) as unknown as T;

  if (!filterValue && options?.initialValue) {
    setParam(filterName, options?.initialValue as unknown as string);
  }

  const handleChangeFilterValue = (value: T) => {
    if (!value) return deleteParam(filterName);

    if (typeof value !== 'string') return;

    setParam(filterName, value);
  };

  const handleDeleteParam = () => {
    deleteParam(filterName);
  };

  return {
    filterValue,
    handleChangeFilterValue,
    handleDeleteParam,
  };
};
