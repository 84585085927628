import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useCreateClientMeeting } from 'hooksV2/useCreateClientMeeting';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClientType } from 'sharedV2/types/client';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { z } from 'zod';

type PropsType = {
  client: ClientType;
  isOpenAddMeetingModalFilter: UseSimpleFilterResponseType;
};

const schema = z.object({
  date: z.string().min(1, { message: 'O campo data é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const AddClientMeetingModal: React.FC<PropsType> = ({
  client,
  isOpenAddMeetingModalFilter,
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const { mutateAsync: createMeeting, isLoading: isLoadingCreateMeeting } =
    useCreateClientMeeting({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
        await queryClient.invalidateQueries(['clientHistory', client.btgCode]);

        isOpenAddMeetingModalFilter.handleDeleteParam();
      },
      onError: error => {
        handleErrorMessage(error);
      },
    });

  const handleAddMeeting: SubmitHandler<FormFieldsType> = async data => {
    await createMeeting({
      btgCode: client.btgCode,
      ...data,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Agendar reunião" />
        <Modal.CloseIcon
          onClick={() => isOpenAddMeetingModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={4}>
        <Input.Root>
          <Input.Label text="Data" />
          <Input.Date {...register('date')} />
          <Input.ErrorMessage message={errors.date?.message} />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Agendar"
          isLoading={isLoadingCreateMeeting}
          onClick={handleSubmit(handleAddMeeting)}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
