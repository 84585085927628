import { Flex, Text } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper, ModalWrapper } from 'components/elements/wrappers';
import { useQueryParams } from 'hooks/useQueryParams';
import { useState } from 'react';
import { useQuery } from 'react-query';
import hubApi, { IPMeetingType } from 'services/hubApi';
import { IPMeetingCard } from './components/meetingCard';
import { IPMeetingDetailsModal } from './modals/meetingsDetails';

export const IPLife = (): JSX.Element => {
  const [isOpenMeetingDetailsModal, setIsOpenMeetingDetailsModal] =
    useState<boolean>(false);
  const [selectedMeetingId, setSelectedMeetingId] = useState<string>('');

  const queryParams = useQueryParams();

  const { data: ipMeetings, isLoading: isLoadingIPMeetings } = useQuery({
    queryKey: ['ipMeetings', queryParams.get('employeeId') ?? undefined],
    queryFn: async () => {
      const response = await hubApi.getIPMeetings({
        employeeId: queryParams.get('employeeId') ?? undefined,
      });
      return response;
    },
  });

  const handleToggleOpenMeetingDetailsModal = (value: boolean) => {
    setIsOpenMeetingDetailsModal(value);
  };

  const handleChangeSelectedMeetingId = (meetingId: string) => {
    setSelectedMeetingId(meetingId);
  };

  if (isLoadingIPMeetings) {
    return (
      <DefaultPageWrapper backPageButton>
        <LoadingSpinner />
      </DefaultPageWrapper>
    );
  }

  const selectedMeeting = ipMeetings
    ?.map(item =>
      item.meetings.find(meeting => meeting.id === selectedMeetingId)
    )
    .find(item => item);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle>CRM - IP</MainPageTitle>

      <Flex gap={2}>
        {ipMeetings &&
          ipMeetings.map(item => {
            return (
              <Flex flexDirection="column">
                <Text color="rgba(70, 70, 70, 1)" fontSize="sm">
                  {item.title} ({item.meetings.length})
                </Text>
                <Flex
                  bgColor="white"
                  minH="700px"
                  maxH="700px"
                  minW="230px"
                  borderRadius={5}
                  p={3}
                  overflowY="auto"
                  flexDirection="column"
                  className="thinScrollbar"
                  gap={3}
                >
                  {item.meetings.map(meeting => {
                    return (
                      <IPMeetingCard
                        meeting={meeting}
                        handleToggleOpenMeetingDetailsModal={
                          handleToggleOpenMeetingDetailsModal
                        }
                        handleChangeSelectedMeetingId={
                          handleChangeSelectedMeetingId
                        }
                      />
                    );
                  })}
                </Flex>
              </Flex>
            );
          })}
      </Flex>
      <ModalWrapper
        isOpen={isOpenMeetingDetailsModal}
        onClose={() => handleToggleOpenMeetingDetailsModal(false)}
      >
        <IPMeetingDetailsModal
          meeting={selectedMeeting as IPMeetingType}
          handleToggleOpenMeetingDetailsModal={
            handleToggleOpenMeetingDetailsModal
          }
        />
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
