import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { Box } from '@chakra-ui/react';
import { IPTransferOrdersProps } from 'pages/IP/interfaces';

import { AgroColumns } from 'pages/IP/subpages/AgroKanban/data';
import { CorporateColumns } from 'pages/IP/subpages/CorporateKanban/data';
import { AltInsurancesColumns } from 'pages/IP/subpages/AltInsurancesKanban/data';
import { healthColumns } from 'pages/IP/subpages/HealthKanban/data';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { lifeColumns } from '../../LifeKanban/data';
import { realStateColumns } from '../../RealStateKanban/data';
import Board from '../../../components/modules/kanbanBase/Board';

export const IpKanbanManager: React.FC = () => {
  const { segment, id, name } =
    useParams<{ segment: string; id: string; name: string }>();

  const [segmentTest, setSegmentTest] = useState<string>('');

  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(() => {
    if (segment === 'saude') {
      setSegmentTest('Saúde');
      return healthColumns;
    }
    if (segment === 'imobiliario') {
      setSegmentTest('Alavancagem Patrimonial');
      return realStateColumns;
    }
    if (segment === 'vida') {
      setSegmentTest('Vida');
      return lifeColumns;
    }
    if (segment === 'agro') {
      setSegmentTest('Agro');
      return AgroColumns;
    }
    if (segment === 'corporate') {
      setSegmentTest('Corporate');
      return CorporateColumns;
    }
    if (segment === 'seguros alternativos') {
      setSegmentTest('Seguros Alternativos');
      return AltInsurancesColumns;
    }

    return [];
  });

  useEffect(() => {
    const listEmployeeOrders = async () => {
      await hubApiIp.listIpManager(segment, id).then(response => {
        for (const column of columns) {
          const tasks = response.filter(
            (order: IPTransferOrdersProps) =>
              (order.status === 'noContact' &&
                column.title === 'Sem Contato') ||
              (order.status === 'inProcess' &&
                column.title === 'Em Processo') ||
              (order.status === 'requestedData' &&
                column.title === 'Dados Solicitados') ||
              (order.status === 'studying' &&
                column.title === 'Apresentação de Proposta') ||
              (order.status === 'sentOffer' &&
                column.title === 'Proposta Enviada') ||
              (order.status === 'closed' &&
                column.title === 'Fechado/ Cancelado') ||
              (order.status === 'scheduledCall' &&
                column.title === 'Call Marcada') ||
              (order.status === 'doneCall' &&
                column.title === 'Call realizada') ||
              (order.status === 'waitingDocuments' &&
                column.title === 'Aguardando documentos') ||
              (order.status === 'validation' && column.title === 'Validação') ||
              (order.status === 'closing' && column.title === 'Fechamento') ||
              (order.status === 'boleted' && column.title === 'Boletado') ||
              (order.status === 'attemptContact' &&
                column.title === 'Tentativa de contato') ||
              (order.status === 'waitingPresentation' &&
                column.title === 'Aguardando Apresentação') ||
              (order.status === 'donePresentation' &&
                column.title === 'Apresentação Realizada') ||
              (order.status === 'waiting' && column.title === 'Fechamento') ||
              (order.status === 'closing1' && column.title === 'Emissão') ||
              (order.status === 'closing2' && column.title === 'Pós Venda') ||
              (order.status === 'boleted' && column.title === 'Boletado Hoje')
          );
          column.tasks = tasks;
        }
        setIsLoading(false);
      });
    };

    listEmployeeOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Box overflowY="auto">
          <Board
            boardTitle={`CRM ${segmentTest} - ${formatNameToCaptalize(name)}`}
            boardColumns={columns}
            setBoardColumns={setColumns}
          />
        </Box>
      )}
    </PageWrapper>
  );
};
