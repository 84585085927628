import { Grid, GridItem } from '@chakra-ui/react';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useEffect, useRef } from 'react';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { SalesHunterPoolLeadCard } from '../leadCard';

type PropsType = {
  filteredLeads: LeadMundoInvestType[] | undefined;
};

export const SalesHunterPoolMosaic: React.FC<PropsType> = ({
  filteredLeads,
}): JSX.Element => {
  const breakPoint = useCommonMediaQueries();

  const { getParam } = useQueryParams();

  const scrollTop = getParam('scrollTop');
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const breakPoints = {
    small: { columns: 4 },
    medium: { columns: 5 },
    big: { columns: 6 },
  };

  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      top: Number(scrollTop),
      behavior: 'smooth',
    });
  }, [scrollTop]);

  return (
    <Grid
      ref={scrollContainerRef}
      templateColumns={`repeat(${breakPoints[breakPoint].columns}, 1fr)`}
      gap={3}
      maxH="calc(100vh - 290px)"
      overflowY="auto"
      className="thinScrollbar scrollContainer"
    >
      {filteredLeads &&
        filteredLeads.map((lead, index) => (
          <GridItem w="100%" overflow="hidden" key={index}>
            <SalesHunterPoolLeadCard lead={lead} />
          </GridItem>
        ))}
    </Grid>
  );
};
