import * as yup from 'yup';

export const scheduleMeetingSchema = yup.object().shape({
  complement: yup.string(),
  local: yup
    .string()
    .test('different-local', 'Selecione um local', data => {
      if (data === 'Local') return false;
      return true;
    })
    .required('Selecione o local da reunião'),
  date: yup.string().required('Selecione a data da reunião'),
});
