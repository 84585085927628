import React from 'react';
import { Text } from '@chakra-ui/react';
import { BaseLabelText } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';

import formatValue from 'utils/formatValue';

interface ComponentProps {
  title: string;
  value: number;
  positive?: boolean;
}

export const RankingMainValuesCard: React.FC<ComponentProps> = ({
  title,
  value,
  positive,
}) => {
  return (
    <DefaultCardWrapper
      width="100%"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      background="background.700"
      p="3"
    >
      <BaseLabelText mb="0">{title}</BaseLabelText>
      <Text
        fontWeight="bold"
        fontSize={{ base: 'lg', md: 'md', lg: 'lg' }}
        color={positive ? 'green.400' : 'red.400'}
        whiteSpace="nowrap"
      >
        {formatValue(value)}
      </Text>
    </DefaultCardWrapper>
  );
};
