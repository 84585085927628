export const position = [
  {
    title: 'Assessor',
    value: 'ASSESSOR',
  },
  {
    title: 'Trainee',
    value: 'TRAINEE',
  },
  {
    title: 'Marketing',
    value: 'MKT',
  },
  {
    title: 'RH',
    value: 'RH',
  },
  {
    title: 'Tecnologia',
    value: 'TECNOLOGIA',
  },
  {
    title: 'Financeiro',
    value: 'FINANCEIRO',
  },
  {
    title: 'Câmbio',
    value: 'CÂMBIO',
  },
  {
    title: 'Inteligencia patrimonial',
    value: 'IP',
  },
  {
    title: 'Outros',
    value: 'OUTROS',
  },
];
export const salaryOption = [
  {
    title: 'fixo',
  },
  {
    title: 'mínimo garantido',
  },
];
