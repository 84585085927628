import { SIGN_OUT } from '../auth/actionTypes';
import {
  GET_UNREAD_NOTIFICATIONS_REQUEST,
  GET_UNREAD_NOTIFICATIONS_SUCCESS,
  SET_CURRENT_NOTIFICATION,
} from './actionTypes';

const INITIAL_STATE = {
  unread: [],
  currentNotification: null,
  loading: false,
};

function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unread: action.payload.unreadNotifications,
        loading: false,
      };

    case SET_CURRENT_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.payload,
      };

    case SIGN_OUT:
      return {
        ...state,
        unread: [],
      };

    default:
      return state;
  }
}

export default notificationsReducer;
