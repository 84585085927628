import { Text } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

interface ConfirmationModalProps {
  title: string;
  message: string;
  idTask: string;
  btgAccount?: string;
  secondMessage?: string;
  handleRemoveItem: (idTask: string) => void;
  handleCloseModal: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  idTask,
  btgAccount,
  handleCloseModal,
  handleRemoveItem,
  secondMessage,
}) => {
  return (
    <DefaultModalContentWrapper maxW="410px">
      <ModalTitle>{title}</ModalTitle>

      <Text color="white" fontWeight="medium">
        {message}
      </Text>
      <Text color="white" fontWeight="medium">
        {secondMessage}
      </Text>

      {btgAccount && (
        <Text mb={1} fontSize="sm" textAlign="end">
          Cod. BTG: {btgAccount}
        </Text>
      )}

      <BaseButtonGroup mt={4}>
        <SecondaryButton onClick={handleCloseModal}>Cancelar</SecondaryButton>
        <PrimaryButton
          onClick={() => {
            handleRemoveItem(idTask);
            handleCloseModal();
          }}
        >
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
