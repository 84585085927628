import React from 'react';
import { formatValue } from 'react-currency-input-field';
import { Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { CardProductsListBase } from 'pages/products/components/elements/cards';
import { DisplayInformation } from 'components/elements/others/DisplayInformation';

import { formatDate } from 'utils/formatDate';

import { PublicOfferingProps } from 'pages/products/interfaces';
import { LayoutModal } from 'components/elements/others/LayoutModal';
import { CardInfo } from 'components/elements/others/CardInfo';
interface PublicOfferingCardProps {
  publicOfferingData: PublicOfferingProps;
}

const PublicOfferingCard: React.FC<PublicOfferingCardProps> = ({
  publicOfferingData,
}) => {
  return (
    <CardProductsListBase
      title={publicOfferingData.title}
      type={publicOfferingData.totalVolume === '' ? 'em captação' : ''}
    >
      <LayoutModal
        description={''}
        bonusMessage={publicOfferingData.bonusMessage}
        typeModal={'OPF'}
        maxWidth={'530px'}
      >
        {!publicOfferingData.bonusMessage ? (
          <>
            <SimpleGrid
              columns={1}
              spacing={2.5}
              justifyItems="flex-start"
              width={'48%'}
            >
              {publicOfferingData.totalVolume && (
                <>
                  <CardInfo
                    title="Reservas até dia"
                    info={formatDate(publicOfferingData.bookingDeadlines)}
                  />
                  <CardInfo
                    title="Liquidação prevista"
                    info={formatDate(publicOfferingData.expectedSettlement)}
                  />
                </>
              )}

              <CardInfo
                title="Investimento Minimo"
                info={formatValue({
                  value: publicOfferingData.minimumInvestment,
                  prefix: 'R$ ',
                  decimalSeparator: ',',
                  intlConfig: {
                    locale: 'pt-BR',
                    currency: 'BRL',
                  },
                })}
              />
              <CardInfo
                title="Amortização"
                info={publicOfferingData.amortization}
              />
              <CardInfo title="Juros" info={publicOfferingData.interest} />
            </SimpleGrid>
            <SimpleGrid
              columns={1}
              spacing={2.5}
              justifyItems="flex-end"
              width={'48%'}
            >
              <CardInfo
                title="Público"
                info={publicOfferingData.targetAudience}
              />
              <CardInfo title="Rating" info={publicOfferingData.rating} />
              <CardInfo
                title="Taxa Teto"
                info={publicOfferingData.ceilingRate}
              />
              <CardInfo
                title="Vencimento da Operação"
                info={publicOfferingData.maturityOfTheOperation}
              />

              {publicOfferingData.totalVolume && (
                <CardInfo
                  title="Volume Total"
                  info={formatValue({
                    value: publicOfferingData.totalVolume,
                    prefix: 'R$ ',
                    decimalSeparator: ',',
                    intlConfig: {
                      locale: 'pt-BR',
                      currency: 'BRL',
                    },
                  })}
                />
              )}
            </SimpleGrid>
          </>
        ) : (
          <>
            {publicOfferingData.totalVolume && (
              <>
                <CardInfo
                  title="Reservas até dia"
                  info={formatDate(publicOfferingData.bookingDeadlines)}
                />
                <CardInfo
                  title="Liquidação prevista"
                  info={formatDate(publicOfferingData.expectedSettlement)}
                />
              </>
            )}

            <CardInfo
              title="Investimento Minimo"
              info={formatValue({
                value: publicOfferingData.minimumInvestment,
                prefix: 'R$ ',
                decimalSeparator: ',',
                intlConfig: {
                  locale: 'pt-BR',
                  currency: 'BRL',
                },
              })}
            />
            <CardInfo
              title="Amortização"
              info={publicOfferingData.amortization}
            />
            <CardInfo title="Juros" info={publicOfferingData.interest} />
            <CardInfo
              title="Público"
              info={publicOfferingData.targetAudience}
            />
            <CardInfo title="Rating" info={publicOfferingData.rating} />
            <CardInfo title="Taxa Teto" info={publicOfferingData.ceilingRate} />
            <CardInfo
              title="Vencimento da Operação"
              info={publicOfferingData.maturityOfTheOperation}
            />

            {publicOfferingData.totalVolume && (
              <CardInfo
                title="Volume Total"
                info={formatValue({
                  value: publicOfferingData.totalVolume,
                  prefix: 'R$ ',
                  decimalSeparator: ',',
                  intlConfig: {
                    locale: 'pt-BR',
                    currency: 'BRL',
                  },
                })}
              />
            )}
          </>
        )}
      </LayoutModal>
    </CardProductsListBase>
  );
};

export default PublicOfferingCard;
