import { FiPhone, FiTrash2 } from 'react-icons/fi';
import { HiEmojiSad, HiOutlineEmojiSad } from 'react-icons/hi';
import { SingleContentContainer } from './styles';

const SingleContent = ({ title, content, call }) => {
  return (
    <SingleContentContainer>
      <div className="single-executation-lead">
        {call && <FiPhone className="icon-phone" size={16} />}
        {!call && <HiOutlineEmojiSad className="icon-phone" size={16} />}
        <strong>{title}:</strong>
        <p>{content}</p>
      </div>
    </SingleContentContainer>
  );
};

export default SingleContent;
