import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const HomePageContentBox: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      width="100%"
      height={{ base: 'inherit', md: '100vh' }}
      maxH={{ base: 'inherit', md: '80vh' }}
      flexDir="column"
      justifyContent="space-between"
      gridGap="3"
      {...rest}
    >
      {children}
    </Flex>
  );
};
