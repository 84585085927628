import { Text } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import { TableData, TableRow } from 'components/elements/table';
import { ModalWrapper } from 'components/elements/wrappers';
import parser from 'html-react-parser';
import { IEventExtended } from 'pages/Events/interfaces/Event';
import { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { formateDateAndTime } from 'utils/formatDate';
import { CreateAndUpdateEventModal } from '../../modals/CreateAndUpdateEventModal';
import { DeleteEventModal } from '../../modals/DeleteEventModal';
import { BtnLink } from './BtnLink';

interface IEventRowProps {
  event: IEventExtended;
  isEventExpired: (date: string) => boolean;
  setAllEvents: React.Dispatch<React.SetStateAction<IEventExtended[]>>;
  allEvents: IEventExtended[];
}

export const EventRow: React.FC<IEventRowProps> = ({
  event,
  isEventExpired,
  setAllEvents,
  allEvents,
}) => {
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <TableRow
        key={event.id}
        _hover={{ background: '#f2f2f2' }}
        cursor="pointer"
        transition="400ms"
        title={
          (isEventExpired(event.eventDateAndHour) && 'Eventos passados') || ''
        }
        color={isEventExpired(event.eventDateAndHour) ? 'red.400' : 'inherit'}
      >
        <TableData width={'200px'} maxW="200px">
          {formatToCapitalized(event.eventName)}
        </TableData>

        <TableData width={'200px'} maxW="200px">
          {formateDateAndTime(event.eventDateAndHour)}
        </TableData>

        <TableData width={'200px'} maxW="200px">
          {parser(event.description)}
        </TableData>
        <TableData width={'140px'} maxW="140px">
          {event.link ? (
            <BtnLink event={event} isEventExpired={isEventExpired} />
          ) : (
            <Text
              fontStyle={'italic'}
              color={
                isEventExpired(event.eventDateAndHour) ? 'red.400' : 'inherit'
              }
            >
              Não possui link
            </Text>
          )}
        </TableData>

        <TableData width={'60px'} maxW="60px">
          <AiOutlineEdit size={20} onClick={() => setShowEditModal(true)} />
        </TableData>

        <TableData width={'60px'} maxW="60px">
          <BiTrash size={20} onClick={() => setShowDeleteEventModal(true)} />
        </TableData>
      </TableRow>

      <ModalWrapper
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
      >
        <CreateAndUpdateEventModal
          key={event.id}
          onclose={() => setShowEditModal(false)}
          eventId={event.id}
          eventData={event}
          allEvents={allEvents}
          setAllEvents={setAllEvents}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showDeleteEventModal}
        onClose={() => setShowDeleteEventModal(false)}
      >
        <DeleteEventModal
          closeModal={() => setShowDeleteEventModal(false)}
          eventId={event.id}
          setAllEvents={setAllEvents}
          allEvents={allEvents}
        />
      </ModalWrapper>
    </>
  );
};
