import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { Reminder } from 'pages/MyClients/interfaces';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface AdditionalInfoProps {
  reminder: Reminder;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  signalToUpdate: boolean;
  setShowNewQuestionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  reminder,
  setSignalToUpdate,
  signalToUpdate,
  setShowNewQuestionModal,
}) => {
  const [questionItem, setQuestionItem] = useState({
    question: '',
    answer: '',
  });

  const handleQuestionAndAnswerInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionItem({ ...questionItem, [e.target.name]: e.target.value });
  };

  const handleAddNewQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await hubApiUser.postInformalInfos([
        {
          ...questionItem,
          clientId: reminder.clientId,
        },
      ]);

      setSignalToUpdate(!signalToUpdate);
      toast.dark('Informação adicionada com sucesso');
      setShowNewQuestionModal(false);
    } catch (err) {
      toast.dark(
        'Não foi possível adicionar a nova informação, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <BaseModalFormCard
      isModal
      maxW={['95vw', '516px']}
      title="Adicionar nova informação"
      isLoading={false}
      handleFormSubmit={e => handleAddNewQuestion(e)}
      handleToggleModal={() => setShowNewQuestionModal(false)}
      primaryButtonText="Salvar nova informação"
    >
      <InputWithLabel
        mb={4}
        label="Pergunta / Dado"
        name="question"
        maxLength={30}
        onChange={handleQuestionAndAnswerInput}
      />
      <InputWithLabel
        label="Resposta / Valor"
        name="answer"
        onChange={handleQuestionAndAnswerInput}
      />
    </BaseModalFormCard>
  );
};
