export const ClientFromTypeTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.635a6.826 6.826 0 00-13 0h13z"
        stroke="#E377C2"
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.902A4.451 4.451 0 107.5 1a4.451 4.451 0 000 8.902z"
        stroke="#E377C2"
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.953 7.173S8.584 7.9 7.5 7.9s-1.453-.727-1.453-.727M11.87 4.604h-.03A4.33 4.33 0 018.59 3.14a4.33 4.33 0 01-3.25 1.464c-.752 0-1.459-.191-2.075-.527a4.453 4.453 0 018.605.527z"
        stroke="#E377C2"
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
