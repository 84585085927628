import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { PageWrapper } from 'components/elements/wrappers';
import CreatePublicOfferingForm from 'pages/products/components/modules/publicOffering/CreatePublicOfferingForm';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';

import { PublicOfferingProps } from 'pages/products/interfaces';

import schema from './schema';

export const CreatePublicOffering: React.FC = () => {
  const [checkedOfferInCaptation, setCheckedOfferInCaptation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [publicData, setPublicData] = useState<PublicOfferingProps>(
    {} as PublicOfferingProps
  );

  const history = useHistory();

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (Number(publicData.minimumInvestment) < 1000) {
      setIsLoading(false);
      return toast.dark('O valor está muito baixo');
    }

    if (!checkedOfferInCaptation) {
      if (!publicData.bookingDeadlines) {
        setIsLoading(false);
        return toast.dark('Preencha a data limite para reservas');
      }

      if (!publicData.expectedSettlement) {
        setIsLoading(false);
        return toast.dark('Preencha a previsão para liquidação');
      }

      if (!publicData.totalVolume) {
        setIsLoading(false);
        return toast.dark('Preencha o volume total');
      }
    }

    const newArray = {
      ...publicData,
    };

    try {
      await schema.validate(publicData, {
        abortEarly: false,
      });

      hubApiProducts
        .postCreatePublicOffering(newArray)
        .then(() => {
          toast.dark('criado com sucesso.');
          setIsLoading(false);
          history.goBack();
        })
        .catch(error => {
          setIsLoading(false);
          if (error.response?.data.message !== 'Internal server error')
            toast.dark(
              error.response?.data.message ||
                'Ocorreu um erro ao criar uma Oferta Pública, por favor tente novamente em alguns minutos.'
            );
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          toast.dark(err.message);
        });
      } else {
        toast.dark(
          'Ocorreu um erro ao criar uma Oferta Pública, por favor tente novamente em alguns minutos.'
        );
      }
    }
  };

  return (
    <PageWrapper>
      <CreatePublicOfferingForm
        isLoading={isLoading}
        nameButton="Criar"
        offeringData={publicData}
        setValue={setPublicData}
        handleSubmitForm={handleSubmitForm}
        label="Criar Oferta Pública Renda Fixa"
        checkedOfferInCaptation={checkedOfferInCaptation}
        setCheckedOfferInCaptation={setCheckedOfferInCaptation}
      />
    </PageWrapper>
  );
};
