import { Flex, useMediaQuery } from '@chakra-ui/react';
import { queryClient } from 'App';
import { SecondaryButton } from 'components/elements/buttons';
import { IPostAnotations } from 'pages/IP/interfaces';
import { useState } from 'react';
import { useMutation } from 'react-query';

import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';

type PropsType = {
  order: IPOrderType;
};

export const IPOrderAnnotations: React.FC<PropsType> = ({
  order,
}): JSX.Element => {
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
    ],
  };

  const [note, setNote] = useState<string>('');

  const { mutate: createOrderNote, isLoading: isLoadingCreateOrderNote } =
    useMutation({
      mutationKey: ['createOrderNote'],
      mutationFn: async (data: IPostAnotations) => {
        const response = await hubApiIp.postAnotations(data);
        return response;
      },
      onSuccess: async () => {
        toast.dark('Anotação criada com sucesso!');
        await queryClient.invalidateQueries(['orderNotes']);
      },
      onError: () => {
        toast.dark('Falha ao criar a anotação. Tente novamente mais tarde.');
      },
    });

  return (
    <Flex flexDirection="column">
      <Flex>
        <ReactQuill
          modules={modules}
          theme="snow"
          placeholder=""
          onChange={text => {
            setNote(text);
          }}
          value={note}
          className={
            isMobile ? 'myClientsPanelMobileModal' : 'myClientsPanelModal'
          }
        />
      </Flex>

      <SecondaryButton
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        w="100%"
        isLoading={isLoadingCreateOrderNote}
        onClick={() => {
          createOrderNote({
            orderId: order.id,
            typeorder: 'IP',
            type: 'intern',
            msg: note,
            btgCode:
              order.isClient && order.clientCode ? order.clientCode : undefined,
          });
        }}
      >
        Salvar
      </SecondaryButton>
    </Flex>
  );
};
