import { Flex, Text, VStack } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { InfoTitle, ModalEditInfos } from 'pages/MyClients/components/elements';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { useState } from 'react';
import { RiEdit2Fill } from 'react-icons/ri';
import formatValue from 'utils/formatValue';

interface InvestorDataSectionProps {
  investorData: any;
  declaredPLAAI?: string;
  clientInfos: ClientInfosType;
  setClientInfos: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  onlyVizMode: boolean;
}

export const InvestorDataSection: React.FC<InvestorDataSectionProps> = ({
  investorData,
  declaredPLAAI,
  clientInfos,
  setClientInfos,
  onlyVizMode,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Flex mt="32px" flexDir="column">
      <InfoTitle title="Dados do investidor" />

      <VStack mt="18px" spacing="3.5" alignItems="baseline">
        {investorData.map((data: any) => (
          <Text
            key={data.label}
            lineHeight="shorter"
            fontSize="sm"
            fontWeight="medium"
            color={data.value === null ? 'red' : 'white.200'}
          >
            {data.label === 'Patrimônio declarado (BTG)' ? (
              <>
                <Text as="span" color="gray.500" mr="6px" fontWeight="normal">
                  {data.label}:
                </Text>{' '}
                {data.value === null ? 'PENDENTE' : formatValue(data.value)}
              </>
            ) : (
              <>
                <Text as="span" color="gray.500" mr="6px" fontWeight="normal">
                  {data.label}:
                </Text>{' '}
                {data.value === null ? 'PENDENTE' : data.value}
              </>
            )}
          </Text>
        ))}

        <Flex align="center" justify="space-between" w="100%">
          <Flex align="center" justify="center">
            <Text
              as="span"
              color="gray.500"
              mr="6px"
              lineHeight="shorter"
              fontSize="sm"
            >
              Patrimônio declarado (AAI):
            </Text>

            <Text
              as="span"
              color={declaredPLAAI ? 'white.200' : 'red'}
              fontSize="sm"
              mr="6px"
            >
              {formatValue(Number(declaredPLAAI)) || 'PENDENTE'}
            </Text>
          </Flex>

          {!onlyVizMode && (
            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => setShowModal(!showModal)}
            />
          )}

          <ModalWrapper
            isOpen={showModal}
            onClose={() => setShowModal(!showModal)}
          >
            <ModalEditInfos
              clientInfos={clientInfos}
              setClientInfos={setClientInfos}
              updatePl
              declaredPLAAI={declaredPLAAI}
              clientId={clientInfos.contactId}
              onClose={() => setShowModal(!showModal)}
            />
          </ModalWrapper>
        </Flex>
      </VStack>
    </Flex>
  );
};
