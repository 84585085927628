import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';
import { useEffect } from 'react';
import { LoadingSpinner } from 'components/elements/others';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { useScroll } from 'hooksV2/useScroll';
import { useInView } from 'react-intersection-observer';
import { LeadProspectCard } from '../leadCard';

type PropsType = {
  filteredLeads: LeadProspectType[] | undefined;
  handleRefetchLeadsProspect: () => void;
  isFetchingNextPageLeadsProspect: boolean;
};

export const LeadsProspectMosaic: React.FC<PropsType> = ({
  filteredLeads,
  handleRefetchLeadsProspect,
  isFetchingNextPageLeadsProspect,
}): JSX.Element => {
  const breakPoint = useCommonMediaQueries();
  const { scrollContainerRef } = useScroll();

  const breaksPoints = {
    small: { columns: 4 },
    medium: { columns: 5 },
    big: { columns: 5 },
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetchingNextPageLeadsProspect) {
      handleRefetchLeadsProspect();
    }
  }, [inView, handleRefetchLeadsProspect, isFetchingNextPageLeadsProspect]);

  return (
    <Flex
      w="100%"
      flexDirection="column"
      maxH="calc(100vh - 270px)"
      overflowY="auto"
      className="thinScrollbar scrollContainer"
      ref={scrollContainerRef}
    >
      <Grid
        w="100%"
        templateColumns={`repeat(${breaksPoints[breakPoint].columns}, 1fr)`}
        gap={3}
      >
        {filteredLeads &&
          filteredLeads.map((lead, index) => (
            <GridItem w="100%" overflow="hidden" key={index}>
              <LeadProspectCard lead={lead} />
            </GridItem>
          ))}
      </Grid>

      {!isFetchingNextPageLeadsProspect && (
        <div ref={ref} style={{ visibility: 'hidden' }}>
          texto para renderizar a div
        </div>
      )}

      {isFetchingNextPageLeadsProspect && <LoadingSpinner mt={5} />}
    </Flex>
  );
};
