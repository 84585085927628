import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { InputWithEmployeeDropdown } from 'components/customElements/InputWithDropdown';
import { BaseModalFormCard } from 'components/elements/cards';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import hubApi, { BIType } from 'services/hubApi';

type PropsType = {
  page: BIType;
  handleToggleOpenAddEmployeeAccessModal: (value: boolean) => void;
};

export const AddEmployeeAccessModal: React.FC<PropsType> = ({
  handleToggleOpenAddEmployeeAccessModal,
  page,
}): JSX.Element => {
  const [employeeName, setEmployeeName] = useState<string>('');
  const [employeeId, setEmployeeId] = useState<string>('');

  const { mutate: createAccessBI, isLoading: isLoadingCreateAccessBI } =
    useMutation({
      mutationKey: ['createAccessBI'],
      mutationFn: async () => {
        const response = await hubApi.createAccessBI({
          biId: page.id,
          employeeId,
          parentPowerBIId: page.reportId,
        });
        return response;
      },
      onError: error => {
        const err = error as AxiosError;
        if (err?.response?.data.messageTranslated) {
          toast.dark(err?.response?.data.messageTranslated);
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['powerBIManagement']);
        await queryClient.invalidateQueries(['allPowerBIsDashboards']);

        handleToggleOpenAddEmployeeAccessModal(false);
      },
    });

  const handleAddEmployeeAccess = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    createAccessBI();
  };

  return (
    <BaseModalFormCard
      isModal
      title="Adicionar colaborador"
      handleFormSubmit={handleAddEmployeeAccess}
      handleToggleModal={() => handleToggleOpenAddEmployeeAccessModal(false)}
      isLoading={isLoadingCreateAccessBI}
      primaryButtonText="Adicionar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW="500px"
    >
      <InputWithEmployeeDropdown
        width="70%"
        customTermToSearch={employeeName}
        setCustomTermToSearch={setEmployeeName}
        setSelectItem={setEmployeeId}
      />
    </BaseModalFormCard>
  );
};
