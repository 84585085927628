import { IEventExtended } from 'pages/Events/interfaces/Event';
import { ICalendarProps } from 'pages/Home/interfaces';
import { INewOffer } from 'pages/Offers/interfaces';
import { HubApi } from '../index';

interface INotice {
  title: string;
  link: string;
}

export interface ICards {
  produtos: ICalendarProps[];
  eventos: IEventExtended[];
}

class HubApiProducts extends HubApi {
  async getAllportifolio() {
    const { data } = await this.axios.get('/products/wallet');
    return data;
  }

  async getAllCoe() {
    const { data } = await this.axios.get('/products/coe');
    return data;
  }

  async getCoeById(id: string) {
    const { data } = await this.axios.get(`/products/coe/${id}`);
    return data;
  }

  async getAllEstateFund() {
    const { data } = await this.axios.get('/products/funds/realestate');
    return data;
  }

  async getAllInvertmentFund() {
    const { data } = await this.axios.get('/products/funds/investments');
    return data;
  }

  async getRealEstateFundById(id: string) {
    const { data } = await this.axios.get(`/products/funds/realestate/${id}`);
    return data;
  }

  async getInvertmentFundById(id: string) {
    const { data } = await this.axios.get(`/products/funds/investments/${id}`);
    return data;
  }

  async getInvertmentFundByType(type: string) {
    const { data } = await this.axios.get(
      `/products/funds/investments/type/${type}`
    );
    return data;
  }

  async getIpoById(id: string) {
    const { data } = await this.axios.get(`/products/ipo/${id}`);
    return data;
  }

  async getPublicOfferingById(id: string) {
    const { data } = await this.axios.get(`/products/public/offers/${id}`);
    return data;
  }

  async getAllIpos() {
    const response = await this.axios.get('/products/ipo');
    return response.data;
  }

  async getAllIPublicOffering() {
    const response = await this.axios.get('/products/public/offers');
    return response.data;
  }

  async getAllCards() {
    const { data } = await this.axios.get<ICards[]>('/cards');
    return data;
  }

  async updateCard(productId: string, data: any) {
    await this.axios.patch(`/cards/${productId}`, data);
  }

  async postCreateIPO(ipoData: FormData) {
    const response = await this.axios.post('/products/ipo', ipoData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async postCreatePublicOffering(data: any) {
    const response = await this.axios.post('/products/public/offers', data);
    return response;
  }

  async postCreateCOE(formData: any) {
    const { data } = await this.axios.post('/products/coe', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async postCreateWallet(walletData: FormData) {
    const { data } = await this.axios.post('/products/wallet', walletData);
    return data;
  }

  async postCreateRealEstateFunds(formData: any) {
    const { data } = await this.axios.post(
      '/products/funds/realestate',
      formData
    );
    return data;
  }

  async postCreateInvestmentFunds(formData: any) {
    const { data } = await this.axios.post(
      '/products/funds/investments',
      formData
    );
    return data;
  }

  async putUpdateWallet(id: string, formData: any) {
    const { data } = await this.axios.put(`/products/wallet/${id}`, formData);
    return data;
  }

  async putUpdateRealEstateFund(id: string, formData: any) {
    const { data } = await this.axios.put(
      `/products/funds/realestate/${id}`,
      formData
    );

    return data;
  }

  async putUpdateInnvestmentFunds(id: string, formData: any) {
    const { data } = await this.axios.put(
      `/products/funds/investments/${id}`,
      formData
    );
    return data;
  }

  async putUpdateIpo(id: string, formData: any) {
    const { data } = await this.axios.put(`/products/ipo/${id}`, formData);

    return data;
  }

  async putPublicOffering(id: string, formData: any) {
    const { data } = await this.axios.put(
      `/products/public/offers/${id}`,
      formData
    );
    return data;
  }

  async putUpdateCoe(id: string, formData: any) {
    const { data } = await this.axios.put(`/products/coe/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async deleteWallet(id: string) {
    await this.axios.delete(`/products/wallet/${id}`);
  }

  async deleteInvertmentFund(id: string) {
    await this.axios.delete(`/products/funds/investments/${id}`);
  }

  async deleteIpo(id: string) {
    await this.axios.delete(`/products/ipo/${id}`);
  }

  async deletePublicOffering(id: string) {
    await this.axios.delete(`/products/public/offers/${id}`);
  }

  async deleteRealEstateFunds(id: string) {
    await this.axios.delete(`/products/funds/realestate/${id}`);
  }

  async deleteCoe(id: string) {
    await this.axios.delete(`/products/coe/${id}`);
  }

  /** noticias */

  async getNotices() {
    const { data } = await this.axios.get(`/notices/status`);
    return data;
  }

  async deleteNotice(id: string) {
    const response = await this.axios.delete(`/notices/${id}`);
    return response;
  }

  async postCreateNotice(formData: INotice) {
    const { data } = await this.axios.post('/notices', formData);
    return data;
  }

  async updateNotice(id: string, data: INotice) {
    const response = await this.axios.patch(`/notices/${id}`, data);
    return response;
  }

  async postCreateNoticeByCaptation(formData: INotice) {
    const { data } = await this.axios.post('/notices/captation', formData);
    return data;
  }

  // Public and Private AAI offers
  async getUserOffers() {
    const { data } = await this.axios.get(`/offers`);
    return data;
  }

  async getOfferById(offerId: string) {
    const { data } = await this.axios.get(`/offers?id=${offerId}`);
    return data;
  }

  async getClientsByOfferId(offerId: string, getCSV?: boolean) {
    const data = await this.axios.get(
      `/offers/clients/${offerId}${getCSV ? `?csv=true` : ''}`
    );
    return data;
  }

  async getAAIListByOfferId(
    offerId: string,
    officeId?: string,
    tableId?: string
  ) {
    if (!officeId) {
      const { data } = await this.axios.get(
        `/offers/management/${offerId}${tableId ? `?tableId=${tableId}` : ''}`
      );
      return data;
    }

    const { data } = await this.axios.get(
      `/offers/management/${offerId}${officeId ? `?officeId=${officeId}` : ''}${
        tableId ? `&tableId=${tableId}` : ''
      }`
    );
    return data;
  }

  async postNewOffer(offer: { offerName: string; isPublic: boolean }) {
    const { data } = await this.axios.post('/offers', offer);
    return data;
  }

  async patchClientInfosInAOffer(
    infos: {
      allocationAmount: number;
      clientBTGAccount: string;
      status: 'Confirmado' | 'Cancelado' | 'Pendente';
      annotation: string;
    },
    offerId: string
  ) {
    const { data } = await this.axios.patch(
      `/offers/clients/${offerId}`,
      infos
    );
    return data;
  }

  async deleteOffer(offerId: string) {
    const { data } = await this.axios.delete(`/offers/${offerId}`);
    return data;
  }

  async patchOffer(offerId: string, offer: INewOffer) {
    const { data } = await this.axios.patch(`/offers/${offerId}`, offer);
    return data;
  }
}

export const hubApiProducts = new HubApiProducts();
