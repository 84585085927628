import { IMeetingsInfoFromMI } from 'pages/Captation/components/modules/meetings/MeetingsInfosFromMI';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { create } from 'zustand';

type ResponsibleEmployeeType = { id: string; name: string; email: string };

export enum LeadDetailsDesiredFlowEnum {
  SALES_HUNTER_POOL = 'SALES_HUNTER_POOL',
  LEADS_SEARCH = 'LEADS_SEARCH',
}

type UseLeadDetailsContextType = {
  lead: LeadParsedType;
  leadType: LeadTypeEnum;
  responsibleEmployee: ResponsibleEmployeeType;
  mundoInvestInformation: IMeetingsInfoFromMI;
  desiredFlow: LeadDetailsDesiredFlowEnum | undefined;
  setLead: (lead: LeadParsedType) => void;
  setResponsibleEmployee: (employee: ResponsibleEmployeeType) => void;
  setMundoInvestInformation: (data: IMeetingsInfoFromMI) => void;
  setLeadType: (type: LeadTypeEnum) => void;
  setDesiredFlow: (value: LeadDetailsDesiredFlowEnum | undefined) => void;
};

export const useLeadDetailsContext = create<UseLeadDetailsContextType>(set => ({
  lead: {} as LeadParsedType,
  responsibleEmployee: {} as ResponsibleEmployeeType,
  mundoInvestInformation: {} as IMeetingsInfoFromMI,
  leadType: LeadTypeEnum.MUNDO_INVEST,
  desiredFlow: undefined,

  setLead: (lead: LeadParsedType) => set(() => ({ lead })),
  setResponsibleEmployee: (employee: ResponsibleEmployeeType) =>
    set(() => ({ responsibleEmployee: employee })),
  setMundoInvestInformation: (data: IMeetingsInfoFromMI) =>
    set(() => ({ mundoInvestInformation: data })),
  setLeadType: (type: LeadTypeEnum) => set(() => ({ leadType: type })),
  setDesiredFlow: (value: LeadDetailsDesiredFlowEnum | undefined) =>
    set(() => ({ desiredFlow: value })),
}));
