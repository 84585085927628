import { Flex, useMediaQuery } from '@chakra-ui/react';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const SalesHunterPoolSkeletonLoading = (): JSX.Element => {
  const [isNotebookWidth] = useMediaQuery(['(max-width: 1500px)']);

  const skeletonsLength = isNotebookWidth ? 8 : 18;

  return (
    <PageWrapper paddingTop="4.5rem">
      <Flex flexDirection="column" gap={5}>
        <Flex w="100%" justifyContent="space-between" gap={2}>
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
          <SkeletonBase h="40px" w="100%" borderRadius={3} />
        </Flex>
        <Flex w="100%" gap={2} justifyContent="space-between">
          <Flex w="100%" gap={2}>
            <SkeletonBase h="40px" w="20%" borderRadius={3} />
            <SkeletonBase h="40px" w="20%" borderRadius={3} />
            <SkeletonBase h="40px" w="5%" borderRadius={3} />
          </Flex>

          <SkeletonBase h="40px" w="20%" borderRadius={3} />
        </Flex>

        <Flex gap={2} wrap="wrap">
          {Array.from({ length: skeletonsLength }).map((_, index) => (
            <SkeletonBase
              key={index}
              h="210px"
              w={isNotebookWidth ? '24.39%' : '16%'}
              borderRadius={3}
            />
          ))}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
