import React from 'react';
import { FlexProps, Text } from '@chakra-ui/react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdSpeakerPhone } from 'react-icons/md';

import { DefaultCardWrapper } from 'components/elements/wrappers';
import {
  MeetingTypeIcon,
  MeetingTypeTag,
} from 'pages/Captation/components/elements/others';
import formatValue from 'utils/formatValue';
import { IBaseMeeting } from 'pages/Captation/interfaces';

interface ComponentProps extends FlexProps {
  title: string;
  value?: string;
  titleSelected: string;
  percentage?: string;
  late?: number;
  progress?: number;
  leads: IBaseMeeting[];
}

export const MeetingTypeMenuCard: React.FC<ComponentProps> = ({
  title,
  value,
  percentage,
  titleSelected,
  late,
  progress,
  leads,
  ...rest
}) => {
  let totalSum = 0;

  for (const obj of leads) {
    const lctValue = parseFloat(
      obj.lctpipevalue?.split(',')[0]?.replaceAll('.', '') ?? '0'
    );
    const rdValue = parseFloat(
      obj.rdpipevalue?.split(',')[0]?.replaceAll('.', '') ?? '0'
    );
    const pjValue = parseFloat(
      obj.lpjpipevalue?.split(',')[0]?.replaceAll('.', '') ?? '0'
    );
    const leValue = parseFloat(
      obj.lepipevalue?.split(',')[0]?.replaceAll('.', '') ?? '0'
    );

    totalSum += lctValue + rdValue + pjValue + leValue;
  }

  return (
    <DefaultCardWrapper
      width="100%"
      h={{
        base: '100px',
        '2xl': '150px',
      }}
      flexDir="column"
      position="relative"
      alignItems="center"
      justifyContent="center"
      color={titleSelected === title ? 'white' : 'inherit'}
      py="4"
      px="3"
      bg={titleSelected === title ? 'background.200' : 'background.600'}
      transition="all 0.15s ease-in"
      cursor="pointer"
      _hover={{ bg: 'background.400' }}
      {...rest}
    >
      {percentage && <MeetingTypeTag text={`${percentage}%`} />}
      {late && late > 0 && (
        <MeetingTypeIcon value={late} Icon={FaRegCalendarAlt} isMeeting />
      )}
      {progress && progress > 0 && (
        <MeetingTypeIcon value={progress} Icon={MdSpeakerPhone} />
      )}
      {title?.toLowerCase() === 'retornos' && (
        <Text textAlign="center">Retornos</Text>
      )}

      {title?.toLowerCase() !== 'retornos' && (
        <>
          <Text mb="0" fontSize="md" textAlign="center">
            {title}
          </Text>
          <Text
            fontWeight="bold"
            lineHeight="120%"
            color="whiteAlpha.900"
            fontSize={{ base: 'sm', lg: 'md' }}
          >
            {formatValue(totalSum).split(',')[0]}
          </Text>
        </>
      )}
    </DefaultCardWrapper>
  );
};
