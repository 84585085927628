import { Box, Flex, Text } from '@chakra-ui/react';
import { InfoTitle } from 'pages/MyClients/components/elements';
import { Reminder } from 'pages/MyClients/interfaces';
import { LastContactsSectionCard } from './LastContactsSectionCard';

interface LastContactsSection {
  clientLastReminders: Reminder[];
}

export const LastContactsSection: React.FC<LastContactsSection> = ({
  clientLastReminders,
}) => {
  return (
    <Box width="100%" maxHeight="25%" height="25%">
      <InfoTitle title="Últimos contatos" />

      <Flex
        mt={4}
        gap="1.5"
        overflowY="auto"
        pb="1"
        width={['98%', '100%']}
        height={['300px', '100%']}
        className="boxClients"
        flexDirection="column"
        alignItems="flex-start"
      >
        {clientLastReminders.length > 0 &&
          clientLastReminders.map(reminder => (
            <LastContactsSectionCard
              reminder={reminder}
              key={`${reminder.id}-${reminder.dataRetorno}`}
            />
          ))}

        {clientLastReminders.length === 0 && (
          <Flex w="100%" h="60%" justifyContent="center" alignItems="center">
            <Text fontWeight="light" fontSize="sm" textAlign="center">
              Não há últimos contatos registrados
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
