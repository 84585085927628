/* eslint-disable no-restricted-globals */
import { TableData, TableRow } from 'components/elements/table';
import { EwzAaiCaptationData } from 'pages/Captation/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';
import {
  checkDifferenceInDays,
  checkDifferenceInMonths,
} from 'utils/formatDate';

interface TableAAIEwzProps {
  aaiList: EwzAaiCaptationData;
}

export const TableAAIEwz: React.FC<TableAAIEwzProps> = ({ aaiList }) => {
  const getFormattedAaiTime = (initialDate: string): string => {
    const differenceInMonths = checkDifferenceInMonths(initialDate);

    if (differenceInMonths <= 0) {
      const differenceInDays = checkDifferenceInDays(initialDate);

      return differenceInDays <= 1 ? `1 dia` : `${differenceInDays} dias`;
    }

    if (differenceInMonths >= 12) {
      const years = ((differenceInMonths * 30) / 360).toFixed(1);

      return years === '1.0'
        ? `${years.slice(0, 1)} ano`
        : `${years.slice(2) === '0' ? years.slice(0, 1) : years} anos`;
    }

    return differenceInMonths === 1
      ? `${differenceInMonths} mês`
      : `${differenceInMonths} meses`;
  };

  return (
    <>
      <TableRow key={aaiList.id}>
        <TableData maxW="180px " isTruncated>
          {formatNameToCaptalize(aaiList.name)}
        </TableData>
        <TableData>{getFormattedAaiTime(aaiList.created_at)}</TableData>
        <TableData>{aaiList.openAccounts}</TableData>
        <TableData>
          {aaiList.frequencyContact === null
            ? '-'
            : `${aaiList.frequencyContact}%`}
        </TableData>

        <TableData>
          {formatValue(Number(aaiList.pl) / Number(aaiList.openAccounts))}
        </TableData>
        <TableData>
          {(
            ((Number(aaiList.pl) + Number(aaiList.tedout)) /
              Number(aaiList.pl)) *
            100
          ).toFixed(2)}
        </TableData>
        <TableData>{formatValue(Number(aaiList.tedin))}</TableData>
        <TableData>{formatValue(Number(aaiList.tedout))}</TableData>
        <TableData>{formatValue(Number(aaiList.pl))}</TableData>
      </TableRow>
    </>
  );
};
