import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import { PageWrapper } from 'components/elements/wrappers';
import { CreateInvestmentFundForm } from 'pages/products/components/modules/investmentFunds/CreateInvestmentFundForm';
import { InvestmentFundProps } from 'pages/products/interfaces';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import schema from './schema';

interface RouteProps {
  id: string;
}

export const UpdateInvestmentFund: React.FC = () => {
  const [investmentFundData, setInvestmentFundData] =
    useState<InvestmentFundProps>({} as InvestmentFundProps);
  const { id } = useParams() as RouteProps;
  const history = useHistory();

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();
      try {
        await schema.validate(investmentFundData, {
          abortEarly: false,
        });

        await hubApiProducts.putUpdateInnvestmentFunds(id, investmentFundData);
        toast.dark('Fundo atualizado com sucesso.');
        history.goBack();
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          err.errors.forEach(message => {
            toast.dark(message);
          });
        } else {
          const statusCode = err.message.split(' ');
          if (Number(statusCode[statusCode.length - 1]) === 400) {
            toast.dark('Já existe um fundo cadastrado com esse nome.');
          } else {
            toast.dark('Ocorreu um erro ao cadastrar o fundo, tente novamente');
          }
        }
      }
    },
    [investmentFundData, history, id]
  );

  const getUpdateInvestment = async () => {
    await hubApiProducts
      .getInvertmentFundById(id)
      .then(data => {
        setInvestmentFundData(data);
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao buscar o Fundo, tente novamente.');
      });
  };

  useEffect(() => {
    getUpdateInvestment();
  }, []);

  return (
    <PageWrapper>
      <CreateInvestmentFundForm
        handleSubmitForm={handleSubmitForm}
        investmentFundData={investmentFundData}
        setValue={setInvestmentFundData}
        nameButton="Atualizar Fundo"
      />
    </PageWrapper>
  );
};
