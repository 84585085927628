import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SendButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      w="150px"
      h="30px"
      color="background.700"
      border="none"
      borderRadius="3px"
      fontSize="15px"
      {...rest}
    >
      {children}
    </Button>
  );
};
