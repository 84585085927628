import { Text, FlexProps, Flex } from '@chakra-ui/react';

import { DefaultCardWrapper } from 'components/elements/wrappers';
import { leftClientsManager } from 'pages/GenerationalVariables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatDate } from 'utils/formatDate';

interface ComponentProps extends FlexProps {
  mainText: string;
  secondaryText: string;
  allLeftClient: leftClientsManager;
}

export const TransferOrderCardLeftClients: React.FC<ComponentProps> = ({
  mainText,
  secondaryText,
  allLeftClient,
  ...rest
}) => {
  return (
    <DefaultCardWrapper
      color="white"
      width="100%"
      height="fit-content"
      maxWidth={{ base: '100%', md: '256px' }}
      borderRadius="3"
      padding="2"
      background="background.500"
      cursor="pointer"
      flexDir="column"
      _hover={{ bg: 'background.400' }}
      transition="0.2s background ease-in"
      {...rest}
    >
      <Flex justify="space-between">
        <Text
          color="white"
          fontSize="md"
          maxW="212px"
          isTruncated
          fontWeight="semibold"
        >
          {formatNameToCaptalize(mainText)}
        </Text>
      </Flex>
      {secondaryText && (
        <Text color="gray.300" fontWeight="thin" fontSize="sm" isTruncated>
          {formatNameToCaptalize(secondaryText)}
        </Text>
      )}

      {allLeftClient.created_at && (
        <Text color="gray.300" fontWeight="thin" fontSize="sm" isTruncated>
          Data da saida - {formatDate(allLeftClient.created_at)}
        </Text>
      )}

      <Flex gridGap={1} fontSize="sm" color="gray.800" align="flex-end" mt="1">
        <Text fontWeight="light" fontStyle="italic">
          Status:
        </Text>
        {allLeftClient.status === 'confirmed deletion' &&
          !allLeftClient.inPendency && (
            <Text fontSize="sm" color="green.500" isTruncated maxW="180px">
              Saída Confirmada
            </Text>
          )}

        {allLeftClient.status !== 'confirmed deletion' &&
          !allLeftClient.inPendency && (
            <Text fontSize="sm" color="orange.500" isTruncated maxW="180px">
              Ag. confirmação do gestor
            </Text>
          )}

        {allLeftClient.status === 'confirmed deletion' &&
          allLeftClient.inPendency && (
            <Text fontSize="sm" color="orange.500" isTruncated maxW="180px">
              Ag. feedback do assessor
            </Text>
          )}

        {allLeftClient.status !== 'confirmed deletion' &&
          allLeftClient.inPendency && (
            <Text fontSize="sm" color="red.500" isTruncated maxW="180px">
              Pendente
            </Text>
          )}
      </Flex>
    </DefaultCardWrapper>
  );
};
