import React from 'react';
import { useSelector } from 'react-redux';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from '@chakra-ui/react';

import permissionsCods from 'shared/constants/permissionsCods';
import { IReduxState, IRulesRedux } from 'shared/interfaces';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import {
  AccessPage,
  ClientsPage,
  ContractPage,
  FinancialPage,
  ProfilePage,
} from 'pages/SearchUserDetails/subpages';
import { Agreement } from 'pages/SearchUserDetails/subpages/Agreement';
import { Equity } from 'pages/Remuneration/subpages/Equity';

interface TabItemsProps {
  employee: EmployeesProps;
  searchEmployeeData: (userId?: string | undefined) => Promise<void>;
}

export const TabItens: React.FC<TabItemsProps> = ({
  employee,
  searchEmployeeData,
}) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const validationPermission = (permission: string[]) => {
    const permVali =
      user.rules?.findIndex((item: IRulesRedux) => {
        return (
          permission.findIndex(value => value === item.permission_cod) !== -1
        );
      }) !== -1;

    return permVali;
  };

  return (
    <Tabs
      variant="unstyled"
      size="lg"
      orientation={isMobile ? 'horizontal' : 'vertical'}
      w="100vw"
      maxW={['95vw', '1024px']}
    >
      <TabPanels
        mr={[0, '12px']}
        height="100%"
        overflowY="auto"
        maxH={['65vh', 'calc(100vh - 186px)']}
      >
        <TabPanel w="100%">
          <ProfilePage
            employee={employee}
            searchEmployeeData={searchEmployeeData}
          />
        </TabPanel>

        {(validationPermission([permissionsCods.RH]) === true ||
          user.position === permissionsCods.MASTER) && (
          <TabPanel>
            <ContractPage employee={employee} />
          </TabPanel>
        )}
        {(user.position === permissionsCods.MASTER ||
          validationPermission([
            permissionsCods.GESTOR,
            permissionsCods.FINANCEIRO,
            permissionsCods.GESTORMESA,
          ]) === true) && (
          <TabPanel>
            <FinancialPage employee={employee} />
          </TabPanel>
        )}
        {(user.position === permissionsCods.MASTER ||
          validationPermission([
            permissionsCods.GESTOR,
            permissionsCods.FINANCEIRO,
            permissionsCods.GESTORMESA,
            permissionsCods.RH,
          ]) === true) && (
          <TabPanel>
            <ClientsPage employee={employee} />
          </TabPanel>
        )}
        {(user.position === permissionsCods.MASTER ||
          validationPermission([permissionsCods.GESTOR, permissionsCods.RH]) ===
            true) && (
          <TabPanel>
            <AccessPage employee={employee} />
          </TabPanel>
        )}
        {(user.position === permissionsCods.MASTER ||
          validationPermission([
            permissionsCods.GESTOR,
            permissionsCods.FINANCEIRO,
            permissionsCods.GESTORMESA,
            permissionsCods.RH,
          ]) === true) && (
          <TabPanel>
            <Agreement employee={employee} />
          </TabPanel>
        )}

        {(user.position === permissionsCods.MASTER ||
          validationPermission([
            permissionsCods.GESTOR,
            permissionsCods.FINANCEIRO,
            permissionsCods.RH,
          ]) === true) && (
          <TabPanel>
            <Equity employee={employee} />
          </TabPanel>
        )}
      </TabPanels>

      <TabList
        w={['100%', '10%']}
        mt={4}
        gap={[2, 0]}
        overflowY={['auto', 'unset']}
        height={['8vh', 'auto']}
      >
        <Tab
          borderRadius={4}
          fontWeight="bold"
          mb={['5px', '12px']}
          fontSize="sm"
          bg="#f2f2f2"
          color="rgba(70, 70, 70, 1)"
        >
          Perfil
        </Tab>
        {(validationPermission([permissionsCods.RH]) === true ||
          user.position === permissionsCods.MASTER) && (
          <Tab
            borderRadius={4}
            fontWeight="bold"
            mb={['5px', '12px']}
            fontSize="sm"
            bg="#f2f2f2"
            color="rgba(70, 70, 70, 1)"
          >
            Contrato
          </Tab>
        )}
        {(validationPermission([
          permissionsCods.GESTOR,
          permissionsCods.FINANCEIRO,
          permissionsCods.GESTORMESA,
        ]) ||
          user.position === permissionsCods.MASTER) && (
          <Tab
            borderRadius={4}
            fontWeight="bold"
            mb={['5px', '12px']}
            fontSize="sm"
            bg="#f2f2f2"
            color="rgba(70, 70, 70, 1)"
          >
            Financeiro
          </Tab>
        )}
        <Tab
          borderRadius={4}
          fontWeight="bold"
          mb={['5px', '12px']}
          fontSize="sm"
          bg="#f2f2f2"
          color="rgba(70, 70, 70, 1)"
        >
          Clientes
        </Tab>
        {(user.position === permissionsCods.MASTER ||
          validationPermission([permissionsCods.GESTOR, permissionsCods.RH]) ===
            true) && (
          <Tab
            borderRadius={4}
            fontWeight="bold"
            fontSize="sm"
            mb={['5px', '12px']}
            bg="#f2f2f2"
            color="rgba(70, 70, 70, 1)"
          >
            Acessos
          </Tab>
        )}

        {(user.position === permissionsCods.MASTER ||
          validationPermission([
            permissionsCods.GESTOR,
            permissionsCods.GESTORMESA,
            permissionsCods.RH,
          ]) === true) && (
          <Tab
            borderRadius={4}
            fontWeight="bold"
            fontSize="sm"
            mb={['5px', '12px']}
            bg="#f2f2f2"
            color="rgba(70, 70, 70, 1)"
          >
            Acordos
          </Tab>
        )}

        {(user.position === permissionsCods.MASTER ||
          validationPermission([permissionsCods.GESTOR, permissionsCods.RH]) ===
            true) && (
          <Tab
            borderRadius={4}
            fontWeight="bold"
            bg="#f2f2f2"
            fontSize="sm"
            mb={['5px', '12px']}
            color="rgba(70, 70, 70, 1)"
          >
            Equity
          </Tab>
        )}
      </TabList>
    </Tabs>
  );
};
