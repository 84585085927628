import React, { useEffect, useState } from 'react';
import { BiPaperPlane } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { PageWrapper } from 'components/elements/wrappers';
import { CardToLink, CardToModal } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';

import { SpecificOrderForm } from './components/modules/forms';
import { hubApiRv } from '../../services/hubApi/classes/HubApiRv';
import { monthStrategiesOption } from './data';

const Rv: React.FC = () => {
  const [
    allowStructuredOnDemandNavigation,
    setAllowStructuredOnDemandNavigation,
  ] = useState(false);

  useEffect(() => {
    const hasStructuredOnDemand = async () => {
      await hubApiRv.useHasStructuredOnDemand().then(response => {
        setAllowStructuredOnDemandNavigation(response);
      });
    };
    const structuredOnDemandCard = document.getElementById(
      'structuredOnDemandCard'
    );

    structuredOnDemandCard?.addEventListener('click', showToast);

    hasStructuredOnDemand();
  }, []);

  const showToast = () => {
    if (allowStructuredOnDemandNavigation === false) {
      toast.dark('Não há Estruturadas On Demand do dia');
    }
  };

  return (
    <PageWrapper
      minH={{ md: '100%' }}
      flexDir="column"
      alignItems="center"
      gridGap="16px"
      paddingTop="36px"
    >
      <SectionOptionsList title="Estratégias mensais">
        {monthStrategiesOption.map((item, index) => (
          <CardToModal
            key={index}
            title={item.title}
            information={item.information}
            Modal={item.modal}
            Icon={item.icon}
          />
        ))}
      </SectionOptionsList>

      <SectionOptionsList title="Ordens on Demand">
        <CardToModal
          title="Ações/Fundos Imobiliários"
          Modal={SpecificOrderForm}
          Icon={BiPaperPlane}
        />
        <CardToModal
          title="Termos"
          Modal={SpecificOrderForm}
          Icon={BiPaperPlane}
        />

        {/* {allowStructuredOnDemandNavigation && ( */}
        <CardToLink
          title="Estruturadas On Demand"
          link="/rv/structureds"
          information="Para o assessor que deseja comprar/vender um ativo específico"
          Icon={BiPaperPlane}
          id="structuredOnDemandCard"
        />
        {/* // )} */}
      </SectionOptionsList>

      <SectionOptionsList title="Meus clientes">
        <CardToLink
          title="Retiradas"
          link="/rv_transfer"
          Icon={RiHandCoinLine}
        />
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Rv;
