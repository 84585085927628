import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { IReduxState } from 'shared/interfaces';

import { Stack } from '@chakra-ui/react';
import { errorHandler } from 'utils';
import schema from './schema';

interface ComponentProps {
  title: string;
  handleShowModal: () => void;
}

const TextRequest: React.FC<ComponentProps> = ({ handleShowModal, title }) => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formInfos, setFormInfos] = useState({
    name: user.name,
    type: '',
    phone: user.phone,
    email: user.email,
    position: '',
    cardType: '',
    publication: '',
    informations: '',
    goals: '',
    title: '',
    text: '',
    project: '',
    formatting: '',
  });

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const newArray = {
      ...formInfos,
      type: title,
    };

    schema
      .validate(formInfos, {
        abortEarly: false,
      })
      .then(async () => {
        await hubApiMarketing
          .postMarketingInfos(newArray)
          .then(() => {
            setIsLoading(false);
            toast.dark('Solicitação enviada com sucesso');
            handleShowModal();
          })
          .catch(error => {
            setIsLoading(false);
            if (error.response.status === 409) {
              toast.dark(
                'Já existe uma ordem deste tipo em aberto, verifique o andamento com o responsável'
              );
            } else {
              errorHandler(error);
            }
          });
      })
      .catch(e => {
        if (e.errors.length > 2) {
          toast.dark(
            'Alguns campos não foram preenchidos, verifique e tente novamente.'
          );
        } else {
          e.errors.forEach((erro: string) => toast.dark(erro));
        }
        setIsLoading(false);
      });
  };
  return (
    <BaseModalFormCard
      isModal
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Enviar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '510px']}
    >
      <Stack spacing="4">
        <InputWithLabel
          label="Título:"
          type="text"
          placeholder="Título do texto"
          name="title"
          value={formInfos.title}
          onChange={handleChangeValue}
        />

        <TextareaWithLabel
          label="Texto:"
          placeholder="Descreva a sua ideia ou sugestão..."
          name="text"
          value={formInfos.text}
          onChange={handleChangeValue}
        />
      </Stack>
    </BaseModalFormCard>
  );
};

export default TextRequest;
