import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Text, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { Dropdown } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase, TableRow, TableData } from 'components/elements/table';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { formatValueForSI } from 'utils/formatValueForSI';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { formatNameToCaptalize } from 'utils/captalizeNames';

type Offices = {
  id: string;
  city: string;
};

type Offer = {
  id: string;
  offerName: string;
  tableId: string | null;
  tags: string[];
};

type Table = {
  id: string;
  name: string;
};

type AAI = {
  AAI: string;
  clientsCount: number;
  incomingClients: number;
  table: string | null;
  totalPlAllocated: string;
};

export const PublicOfferManagement: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const [offer, setOffer] = useState({} as Offer);
  const [AAIList, setAAIList] = useState<AAI[]>([]);
  const [offices, setOffices] = useState<Offices[]>([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState('');
  const [tables, setTables] = useState<Table[]>([]);
  const [selectedTableId, setSelectedTableId] = useState('');

  const tableHeaders = [
    'AAI',
    'Mesa',
    'Clientes Totais',
    'Clientes Entrantes',
    'Total de Alocação',
  ];

  useEffect(() => {
    getOffices();
    getOffer();
  }, []);

  useEffect(() => {
    getAAIList();
  }, [offer, selectedOfficeId, selectedTableId]);

  useEffect(() => {
    getTablesByOffice();
  }, [selectedOfficeId]);

  const getOffer = async () => {
    try {
      const offerDetails: Offer = await hubApiProducts.getOfferById(offerId);

      setOffer(offerDetails);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os dados da oferta, tente novamente em alguns minutos'
      );
    }
  };

  const getAAIList = async () => {
    try {
      if (offer.tableId) {
        const obtainedAAIList = await hubApiProducts.getAAIListByOfferId(
          offerId,
          '',
          offer.tableId
        );
        setAAIList(obtainedAAIList);
      } else {
        const obtainedAAIList = await hubApiProducts.getAAIListByOfferId(
          offerId,
          selectedOfficeId,
          selectedTableId
        );
        setAAIList(obtainedAAIList);
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os dados da oferta, tente novamente em alguns minutos'
      );
    }
  };

  const getOffices = async () => {
    const data = await hubApiOfficeTables.listFilial();
    setOffices(data);
  };

  const getTablesByOffice = async (): Promise<void> => {
    try {
      if (selectedOfficeId !== '') {
        const data = await hubApiOfficeTables.listTablesByOffice(
          selectedOfficeId
        );
        setTables(data);
      }
    } catch (error) {
      setTables([]);
      toast.dark(
        'Não foi possível obter as mesas, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <PageWrapper p={5}>
      <Flex flexDir="column" w="100%" maxW="1080px" mx="auto">
        <BackPageButton />

        <Flex alignItems="baseline" justifyContent="space-between">
          <MainPageTitle
            textAlign="center"
            mt={6}
            mb={offer.tableId ? '3' : '0'}
            color="background.50"
          >
            Gestão de Ofertas - {offer.offerName}
          </MainPageTitle>

          {!offer.tableId && (
            <Flex mb={3} gridGap={2}>
              <Dropdown
                placeholder="Selecione a filial"
                onChange={e => setSelectedOfficeId(e.target.value)}
              >
                {offices.map(office => (
                  <option key={office.id} value={office.id}>
                    {office.city}
                  </option>
                ))}
              </Dropdown>

              <Dropdown
                placeholder="Selecione a mesa"
                ml={1}
                onChange={e => setSelectedTableId(e.target.value)}
              >
                {tables.map(table => (
                  <option key={table.id} value={table.id}>
                    {table.name}
                  </option>
                ))}
              </Dropdown>
            </Flex>
          )}
        </Flex>

        <TableBase
          headData={tableHeaders}
          w="100%"
          maxW={{ base: '1080px', '2xl': '1240px' }}
          maxH="calc(100vh - 280px)"
          overflow="auto"
          p={0}
        >
          {AAIList.map((aai, index) => (
            <TableRow
              key={`${aai.AAI}-${index}`}
              _hover={{ background: 'background.500' }}
              transition="400ms"
            >
              <TableData>{formatNameToCaptalize(aai.AAI)}</TableData>
              <TableData>{aai.table}</TableData>
              <TableData>{aai.clientsCount}</TableData>
              <TableData>{aai.incomingClients}</TableData>
              <TableData>
                {formatValueForSI(Number(aai.totalPlAllocated))}
              </TableData>
            </TableRow>
          ))}
        </TableBase>

        <Flex
          mt={4}
          mr={1}
          justifyContent={offer.tags?.length > 0 ? 'space-between' : 'flex-end'}
        >
          <Flex>
            {offer.tags?.length > 0 && <Text>Tags da oferta: </Text>}
            {offer.tags?.map(tag => (
              <Text
                ml={2}
                px={2}
                border="1px solid white"
                borderRadius={4}
                h="fit-content"
              >
                {tag}
              </Text>
            ))}
          </Flex>
          <Box>
            <Text>
              Clientes na oferta:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {AAIList.reduce(
                  (acc, current) => acc + current.clientsCount,
                  0
                )}
              </Text>
            </Text>

            <Text>
              Total de clientes entrantes:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {AAIList.reduce(
                  (acc, current) => acc + current.incomingClients,
                  0
                )}
              </Text>
            </Text>

            <Text>
              Alocação Total:{' '}
              <Text as="span" fontWeight="bold" color="white">
                {formatValueForSI(
                  AAIList.reduce(
                    (acc, current) => acc + Number(current.totalPlAllocated),
                    0
                  )
                )}
              </Text>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
