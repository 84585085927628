import { Flex, useMediaQuery } from '@chakra-ui/react';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const HomeSkeletonLoading = (): JSX.Element => {
  const [isLowerThan720] = useMediaQuery('(max-height: 720px)');

  const skeletonsLength = isLowerThan720 ? 6 : 15;

  return (
    <PageWrapper gap={12} paddingTop="4.5rem">
      <Flex gap={5}>
        <SkeletonBase h="90px" w="100%" borderRadius={3} />
        <SkeletonBase h="90px" w="100%" borderRadius={3} />
        <SkeletonBase h="90px" w="100%" borderRadius={3} />
        <SkeletonBase h="90px" w="100%" borderRadius={3} />
      </Flex>

      <Flex wrap="wrap" gap={5} w="100%" justifyContent="center">
        {Array.from({ length: skeletonsLength }).map((_, index) => (
          <SkeletonBase key={index} h="150px" w="300px" borderRadius={3} />
        ))}
      </Flex>
    </PageWrapper>
  );
};
