import React from 'react';
import { Modal, ModalOverlay, ModalProps } from '@chakra-ui/react';

export const ModalWrapper: React.FC<ModalProps> = ({ children, ...rest }) => {
  return (
    <Modal scrollBehavior="inside" {...rest} isCentered>
      <ModalOverlay backdropFilter="saturate(120%) blur(3px)">
        {children}
      </ModalOverlay>
    </Modal>
  );
};
