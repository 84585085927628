import React from 'react';
import { MdContentCopy } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { Button, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

interface ComponentProps {
  textToCopy: string;
  buttonIcon: string;
}

const CopyToClipboardBtn: React.FC<ComponentProps> = ({
  textToCopy,
  buttonIcon,
}) => {
  const handleCopyText = () => {
    navigator.clipboard.writeText(textToCopy);
    toast.dark('Mensagem copiada');
  };

  return (
    <Button
      onClick={handleCopyText}
      background="background.300"
      color="white"
      fontWeight="semibold"
      size="sm"
      _hover={{ bg: 'background.400' }}
    >
      {buttonIcon === 'whatsapp' ? (
        <AiOutlineWhatsApp size={12} />
      ) : (
        <MdContentCopy size={12} />
      )}
      <Text marginLeft="8px">Copiar</Text>
    </Button>
  );
};

export default CopyToClipboardBtn;
