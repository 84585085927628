import React, { useEffect, useState } from 'react';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { Dropdown } from 'components/elements/others';
import { TextareaWithLabel } from 'components/elements/forms';
import {
  DefaultSHQuestionItem,
  SHQuestionsProps,
} from 'pages/Captation/interfaces/SHLeads';
import { SecondaryButton } from 'components/elements/buttons';
import { hubApiOfficeBranches } from '../../../../../../services/hubApi/classes/HubApiOfficeBranches';
import { SHQuestionItem } from '../SHQuestionItem';

import { defaultSHQuestions, knowlegmentLevel, profileOptions } from './data';

interface SHQuestionsFormProps {
  maxHeight?: string;
  currentQuestions: SHQuestionsProps;
  setCurrentQuestions: React.Dispatch<React.SetStateAction<SHQuestionsProps>>;
  filialName?: string;
  filialId?: string;
  handleSaveQuestions?: (
    scheduleType: 'meeting' | 'callback',
    saveButton?: boolean
  ) => void;
}

export interface IAllBranchs {
  cep: string;
  city: string;
  created_at: string;
  id: string;
  number: string;
  street: string;
  uf: string;
  updated_at: string;
  zona: string;
}

export const SHQuestionsForm: React.FC<SHQuestionsFormProps> = ({
  maxHeight = 'calc(100vh - 348px)',
  currentQuestions,
  setCurrentQuestions,
  filialName,
  filialId,
  handleSaveQuestions,
}) => {
  useEffect(() => {
    getAllBranchs();
  }, []);

  const [allBranchs, setAllBranchs] = useState<IAllBranchs[]>([]);
  const handleChangeQuestionItemValue = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    propName: string
  ) => {
    if (e.target.value) {
      setCurrentQuestions({
        ...currentQuestions,
        [propName]: e.target.value,
      });
    } else {
      setCurrentQuestions({
        ...currentQuestions,
        [propName]: '',
      });
    }
  };

  const handleChangeQuestionItemValuePl = (e: string, propName: string) => {
    setCurrentQuestions({
      ...currentQuestions,
      [propName]: e,
    });
  };

  const getAllBranchs = async () => {
    try {
      const response: IAllBranchs[] =
        await hubApiOfficeBranches.listBranchOffices();
      const FilteredResponse = response.filter(
        data =>
          data.city !== 'Tatuí' &&
          data.city !== 'Itapeva' &&
          data.city !== 'Cuiabá' &&
          data.city !== 'Dourados' &&
          data.city !== 'Franca'
      );
      setAllBranchs(FilteredResponse);
    } catch (error) {
      toast.dark('Não foi possível concluir a solicitação das filiais');
    }
  };

  const handleSelectedBranchName = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    if (event.target.value && event.target.value !== 'Todas filiais') {
      const obj = JSON.parse(event.target.value);
      setCurrentQuestions({
        ...currentQuestions,
        filialId: obj?.id,
        filialName: obj?.cityName,
      });
    } else if (event.target.value === 'Todas filiais') {
      setCurrentQuestions({
        ...currentQuestions,
        filialId: '',
        filialName: 'Todas filiais',
      });
    } else {
      setCurrentQuestions({
        ...currentQuestions,
        filialId: '',
        filialName: '',
      });
    }
  };
  return (
    <Flex width="100%" flexDir="column">
      <Flex
        width="100%"
        mb="4"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Flex gridGap="2">
          <Dropdown
            onChange={event => handleChangeQuestionItemValue(event, 'perfil')}
          >
            {!currentQuestions.perfil && (
              <option selected key="default" value="">
                Perfil
              </option>
            )}

            {profileOptions?.map((item, index) => {
              if (currentQuestions.perfil === item) {
                return (
                  <option
                    selected
                    key={`profile-${index}-${item}`}
                    value={item}
                  >
                    {item}
                  </option>
                );
              }
              return (
                <option key={`profile-${item}-${index}`} value={item}>
                  {item}
                </option>
              );
            })}
            {profileOptions?.map((item, index) => (
              <option key={`profile-${index}-${item}`} value={item}>
                {item}
              </option>
            ))}
          </Dropdown>

          <Dropdown
            onChange={event =>
              handleChangeQuestionItemValue(event, 'knowledge')
            }
          >
            {!currentQuestions.knowledge && (
              <option selected key="default" value="">
                Conhecimento
              </option>
            )}

            {knowlegmentLevel?.map((item, index) => {
              if (currentQuestions.knowledge === item) {
                return (
                  <option
                    selected
                    key={`knowlegment-${index}-${item}`}
                    value={item}
                  >
                    {item}
                  </option>
                );
              }
              return (
                <option key={`knowlegment-${item}-${index}`} value={item}>
                  {item}
                </option>
              );
            })}

            {knowlegmentLevel?.map((item, index) => (
              <option key={`knowlegment-${index}-${item}`} value={item}>
                {item}
              </option>
            ))}
          </Dropdown>
        </Flex>
      </Flex>

      <Flex
        flexDir="column"
        overflowY="auto"
        maxH={maxHeight}
        gridGap="1.5"
        paddingX="1"
      >
        <Flex justifyContent="space-between" alignItems="center" p="0" w="100%">
          <Text fontSize="sm" color="white" fontWeight="medium">
            Filial:
          </Text>
          <Dropdown
            width={255}
            onChange={event => handleSelectedBranchName(event)}
          >
            {!filialName && (
              <option selected key="default" value="">
                Selecione a cidade
              </option>
            )}
            <option key="all-branch" value="Todas filiais">
              Todas filiais
            </option>

            {allBranchs?.map(item => {
              if (filialName === item.city && filialId === item.id) {
                return (
                  <option
                    selected
                    key={`branch-${item}-${item.id}`}
                    value={JSON.stringify({
                      id: item?.id,
                      cityName: item.city,
                    })}
                  >
                    {item.city}
                  </option>
                );
              }
              return (
                <option
                  key={`branch-${item.id}-${item}`}
                  value={JSON.stringify({
                    id: item?.id,
                    cityName: item.city,
                  })}
                >
                  {item.city}
                </option>
              );
            })}
          </Dropdown>
        </Flex>
        <VStack spacing={2} mb="2">
          {defaultSHQuestions.map((question, index) => (
            <SHQuestionItem
              key={`${question.identifier}-${index}`}
              question={question as DefaultSHQuestionItem}
              currentQuestions={currentQuestions}
              handleChangeValue={handleChangeQuestionItemValue}
              handleChangePl={handleChangeQuestionItemValuePl}
            />
          ))}
        </VStack>
        <Flex mt={2} position="relative">
          <TextareaWithLabel
            label="Disclaimer da reunião:"
            resize="none"
            fontSize="sm"
            rows={4}
            value={currentQuestions.disclaimer || ''}
            onChange={event =>
              handleChangeQuestionItemValue(event, 'disclaimer')
            }
          />

          <SecondaryButton
            size="xs"
            position="absolute"
            right={0}
            onClick={() =>
              handleSaveQuestions && handleSaveQuestions('meeting', true)
            }
          >
            Salvar informações
          </SecondaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
