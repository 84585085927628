import React, { useEffect, useState } from 'react';

import { CardToLink } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';

import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { errorHandler } from 'utils';
import { fundsProducts, otherProducts } from './data';

const Products: React.FC = () => {
  const [hasIpoContent, setHasIpoContent] = useState(false);
  const [hasPublicOfferingContent, setHasPublicOfferingContent] =
    useState(false);
  const [hasCoeContent, setHasCoeContent] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ipoData = await hubApiProducts.getAllIpos();
        setHasIpoContent(ipoData && ipoData.length > 0);

        const publicOfferingData = await hubApiProducts.getAllIPublicOffering();
        setHasPublicOfferingContent(
          publicOfferingData && publicOfferingData.length > 0
        );

        const coeData = await hubApiProducts.getAllCoe();
        setHasCoeContent(coeData && coeData.length > 0);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, []);

  return (
    <PageWrapper alignItems="center" paddingTop="4.5rem">
      <SectionOptionsList title="Outros">
        {otherProducts.map((item, index: number) => {
          if (
            (item.title === 'IPO e Subscrição' && !hasIpoContent) ||
            (item.title === 'COE' && !hasCoeContent) ||
            (item.title === 'Oferta Pública Renda Fixa' &&
              !hasPublicOfferingContent)
          ) {
            return null;
          }
          return (
            <CardToLink
              external={
                item.title === 'Apresentações Gestoras' ||
                item.title === 'Apresentação de Produtos' ||
                item.title === 'Reunião RV' ||
                item.title === 'Café com Diamantes'
              }
              key={index}
              title={item.title}
              link={item.link}
              Icon={item.icon}
            />
          );
        })}
      </SectionOptionsList>

      <SectionOptionsList title="Fundos de Investimentos">
        {fundsProducts.map((item, index: number) => (
          <CardToLink
            key={index}
            title={item.title}
            link={item.link}
            Icon={item.icon}
          />
        ))}
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Products;
