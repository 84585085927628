import React, { useState } from 'react';
import { IOrderProps } from 'pages/AdvancedOrders/interfaces';
import { TableData, TableRow } from 'components/elements/table';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { ModalWrapper } from 'components/elements/wrappers';
import { hubAreasCode } from 'pages/AdvancedOrders/data';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formatDate } from 'utils/formatDate';
import {
  OrderInfosExchange,
  OrderInfosIp,
  OrderInfosMkt,
  OrderInfosRh,
  OrderInfosTech,
} from '../modals';

interface ComponentProps {
  order: IOrderProps;
}

export const OrderRowValues: React.FC<ComponentProps> = ({ order }) => {
  const [selectedOrder, setSelectedOrder] = useState({});
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleSelectOrder = async (orderCode: string, orderId: string) => {
    const response = await hubApiHome.listHomeOrdersModal(orderCode, orderId);
    setSelectedOrder(response[0]);
  };

  const handleToggleModal = () => setToggleModal(!toggleModal);

  const handleOnClickRow = () => {
    if (order.status.toLowerCase() === 'deletada') return;

    handleSelectOrder(order.code, order.orderId);
    handleToggleModal();
  };

  return (
    <>
      <TableRow
        transition="background 0.15s ease-in"
        _hover={{ background: 'background.300' }}
        cursor={
          order.status?.toLowerCase() !== 'deletada' ? 'pointer' : 'inherit'
        }
        onClick={handleOnClickRow}
      >
        <TableData isTruncated maxW="186px">
          {formatNameToCaptalize(order.name)}
        </TableData>
        <TableData>{order.area}</TableData>
        <TableData>{formatDate(order.updated_at)}</TableData>
        <TableData>{formatNameToCaptalize(order.status)}</TableData>
      </TableRow>

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        {order.code === hubAreasCode.RH && (
          <OrderInfosRh
            order={selectedOrder}
            handleToggleModal={handleToggleModal}
          />
        )}
        {order.code === hubAreasCode.TI && (
          <OrderInfosTech
            order={selectedOrder}
            handleToggleModal={handleToggleModal}
          />
        )}
        {order.code === hubAreasCode.MK && (
          <OrderInfosMkt
            order={selectedOrder}
            handleToggleModal={handleToggleModal}
          />
        )}
        {order.code === hubAreasCode.IP && (
          <OrderInfosIp
            order={selectedOrder}
            handleToggleModal={handleToggleModal}
          />
        )}
        {order.code === hubAreasCode.EXCHANGE && (
          <OrderInfosExchange
            order={selectedOrder}
            handleToggleModal={handleToggleModal}
          />
        )}
      </ModalWrapper>
    </>
  );
};
