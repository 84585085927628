import React, { useEffect, useState } from 'react';

import { errorHandler } from 'utils/errorHandler';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { PageWrapper } from 'components/elements/wrappers/PageWrapper';
import { SecondaryButton } from 'components/elements/buttons/SecondaryButton';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { MainPageTitle } from 'components/elements/texts/MainPageTitle';
import { TableBase } from 'components/elements/table';
import { advancedCustumersRvHeader } from './data';
import ActiveCustomersFundsTableRow from '../../components/modules/ActiveCustomersFunds/ActiveCustomersFundsTableRow';

const ActiveCustomersFunds: React.FC = () => {
  const [infosFounds, setInfosFounds] = useState({
    data: [],
  });

  useEffect(() => {
    const getAllClients = async () => {
      await hubApiRv
        .listAllClientesCustumersRv('fii')
        .then(response => setInfosFounds(response))
        .catch(error => errorHandler(error));
    };

    getAllClients();
  }, []);

  const handleExtractTable = async () => {
    await hubApiRv
      .listAllClientesCustumersRv('fii')
      .then(response => window.location.assign(`${response.link}`))
      .catch(error => errorHandler(error));
  };

  return (
    <PageWrapper flexDir="column" alignItems="center" pt="48px">
      <BackPageButton />
      <MainPageTitle textAlign="center">
        Clientes ativos Fundos Imobiliários
      </MainPageTitle>
      <SecondaryButton
        marginLeft="970px"
        marginBottom="10px"
        onClick={handleExtractTable}
      >
        Extrair Tabela
      </SecondaryButton>
      <TableBase
        overflowY="auto"
        maxH="calc(100vh - 260px)"
        width="100%"
        maxW="1080px"
        headData={advancedCustumersRvHeader}
      >
        {infosFounds?.data?.map((infosFound, index) => (
          <ActiveCustomersFundsTableRow key={index} infosFound={infosFound} />
        ))}
      </TableBase>
    </PageWrapper>
  );
};

export default ActiveCustomersFunds;
