import React from 'react';
import { Flex } from '@chakra-ui/react';
import { DefaultCardWrapper } from './DefaultCardWrapper';

export const TransferOrdersCardsWrapper: React.FC = ({ children }) => {
  return (
    <DefaultCardWrapper w="100%" maxW="1080px" padding="3">
      <Flex
        w="100%"
        flexWrap="wrap"
        gridGap="2"
        borderRadius="3"
        alignContent="flex-start"
        overflowY={{ base: 'auto', md: 'auto' }}
        height="100%"
        maxH={['50vh', 'calc(100vh - 264px)']}
      >
        {children}
      </Flex>
    </DefaultCardWrapper>
  );
};
