import { useCallback, useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { BackPageButton } from 'components/elements/buttons';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { OrdersRowPendencies } from 'pages/manager/components/modules/OrdersRowPendencies';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { AbstractValues } from 'pages/manager/components/modules/AbstractValues';
import {
  IEmployees,
  ITableEmployeePendenciesDiary,
} from 'pages/manager/interfaces';
import { IReduxState } from 'shared/interfaces';

import { errorHandler } from 'utils';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { ITables } from 'pages/GenerationalVariables/interfaces';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { tableHeader } from './data';

export const TablePendencies: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [tableId, setTableId] = useState('');
  const [selectedOfficeId, setSelectedOfficeId] = useState('');
  const [offices, setOffices] = useState<ITableEmployeePendenciesDiary[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tables, setTables] = useState<ITables[]>([]);
  const [employees, setEmployees] = useState<IEmployees[]>([]);
  const [employeesFiltered, setEmployeesFiltered] = useState<IEmployees[]>([]);

  const filterEmployee = () => {
    if (tableId) {
      setEmployeesFiltered(
        employees.filter(employee => employee.tableId === tableId)
      );
    } else {
      setEmployeesFiltered(employees);
    }
  };

  const handlePendenciesEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await hubApiUser.getEmployeesPendency();

      setEmployees(response);
      setEmployeesFiltered(response);
    } catch (err) {
      errorHandler(err);
    }
    setIsLoading(false);
  };

  const handleListFilial = async () => {
    const data = await hubApiOfficeTables.listFilial();
    setOffices(data);
  };

  const handleChangeValue = useCallback(
    (id: string) => {
      setTableId(id);
    },
    [tableId]
  );

  const handleListTablesByOffice = useCallback(async () => {
    try {
      const data = await hubApiOfficeTables.listTablesByOffice(
        selectedOfficeId
      );
      setTables(data);
    } catch (error) {
      errorHandler(error);
    }
  }, [selectedOfficeId]);
  useEffect(() => {
    filterEmployee();
  }, [tableId]);

  useEffect(() => {
    handlePendenciesEmployees();
  }, []);

  useEffect(() => {
    handleListTablesByOffice();
    handleListFilial();
  }, [selectedOfficeId]);

  const getAllPendencies = () => {
    let fromType = 0;
    let birthday = 0;
    let negative = 0;
    let nps = 0;
    let inative = 0;
    let meetPendency = 0;

    for (let i = 0; i < employeesFiltered.length; i++) {
      fromType += employeesFiltered[i].fromType;
      birthday += employeesFiltered[i].birthday;
      negative += employeesFiltered[i].balance;
      nps += employeesFiltered[i].nps;
      inative += employeesFiltered[i].inative;
      meetPendency +=
        employeesFiltered[i].personalizedMeeting +
        employeesFiltered[i].periodicMeeting;
    }

    return {
      fromType,
      birthday,
      negative,
      nps,
      inative,
      meetPendency,
    };
  };

  return (
    <PageWrapper alignItems="center" flexDir="column" pt="48px">
      <BackPageButton />
      <MainPageTitle textAlign="center">Pendências do dia</MainPageTitle>

      {verifyEmployeePermission({
        user,
        areaCod: ['ASS'],
        permissionCod: ['GTR'],
      }) && (
        <Flex
          align={['center', 'center', 'center', 'flex-end']}
          justify={['center', 'center', 'center', 'flex-end']}
          flexDir={['column', 'column', 'column', 'row']}
          gridGap={3}
          mb="2"
          w="95%"
        >
          <Dropdown
            placeholder="Selecione um escritório"
            onChange={e => setSelectedOfficeId(e.target.value)}
          >
            {offices.map((office: any) => (
              <option key={office.id} value={office.id}>
                {office.city}
              </option>
            ))}
          </Dropdown>
          <Dropdown
            width="100%"
            placeholder="Selecione uma mesa"
            mt="2"
            onChange={e => handleChangeValue(e.target.value)}
          >
            {tables.map(table => (
              <option key={table.id} value={table.id}>
                {table.name}
              </option>
            ))}
          </Dropdown>
        </Flex>
      )}

      <Flex align="center" justify="center" flexDir="column" w="95%">
        <TableBase
          headData={tableHeader}
          overflowY="auto"
          maxH="calc(100vh - 300px)"
          w="100%"
        >
          {employeesFiltered?.map(employee => (
            <OrdersRowPendencies employee={employee} key={employee.id} />
          ))}
        </TableBase>
        {isLoading && <LoadingSpinner />}

        <AbstractValues getAllPendencies={getAllPendencies} />
      </Flex>
    </PageWrapper>
  );
};
