import React from 'react';
import { Stack } from '@chakra-ui/react';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import { SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { formateDateAndTime } from 'utils/formatDate';

interface OrderProps extends KanbanBaseTaskProps {
  type?: string;
  description?: string;
  assessorName?: string;
  btgCode?: string;
  creatorname?: string;
  orderType?: string;
  email?: string;
  created_at?: string;
  phone?: string;
  deletedEmployee?: string;
}

export interface BoardOrderProps {
  order: OrderProps;
  handleToggleModal: () => void;
}

export const BoardOrderInfo: React.FC<BoardOrderProps> = ({
  order,
  handleToggleModal,
}) => {
  return (
    <DefaultModalContentWrapper>
      <ModalTitle>Dados da Solicitação</ModalTitle>

      <Stack spacing="3">
        {order.creatorname && (
          <DisplayInformation
            label="Colaborador solicitante"
            info={order.creatorname}
          />
        )}

        {order.type && <DisplayInformation label="Tipo" info={order.type} />}

        {order.description && (
          <DisplayInformation label="Descrição" info={order.description} />
        )}

        {order.email && (
          <DisplayInformation label="Email do solicitante" info={order.email} />
        )}

        {order.created_at && (
          <DisplayInformation
            label="Horario em que a ordem foi aberta"
            info={formateDateAndTime(order.created_at)}
          />
        )}

        {order.phone && (
          <DisplayInformation
            label="Telefone do solicitante"
            info={order.phone}
          />
        )}
        {order.deletedEmployee && (
          <DisplayInformation
            label="Usuário descontinuado"
            info={order.deletedEmployee}
          />
        )}
      </Stack>

      <BaseButtonGroup mt="3" justifyContent="space-between">
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
