import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import formatStringInTitle from 'utils/formatStringInTitle';

interface ManagementSegmentCardDataProps {
  item: IOrdersProps;
}

interface IOrdersProps {
  BTGCode: string;
  aberturaconta: string;
  canceled: string;
  contatoinicial: string;
  cotacao: string;
  finalized: string;
  id: string;
  name: string;
  semcontato: string;
}

export const ManagementSegmentCardExhange: React.FC<ManagementSegmentCardDataProps> =
  ({ item }) => {
    const options = [
      {
        title: 'aberturaconta',
        value: item.aberturaconta,
      },
      {
        title: 'canceled',
        value: item.canceled,
      },
      {
        title: 'contatoinicial',
        value: item.contatoinicial,
      },
      {
        title: 'finalized',
        value: item.finalized,
      },
      {
        title: 'cotacao',
        value: item.cotacao,
      },

      {
        title: 'semcontato',
        value: item.semcontato,
      },
    ];

    return (
      <DefaultCardWrapper minW="270px" flexDir="column" alignItems="center">
        <Flex alignItems="center" justifyContent="center">
          <MainPageTitle fontSize="lg" maxW="200px" isTruncated mb="0">
            {formatStringInTitle(item.name)}
          </MainPageTitle>
        </Flex>

        {options.map((option, index) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="2"
            py="1"
            px="3"
            key={index}
            borderRadius="4"
            width="220px"
            bg="#f2f2f2"
          >
            <Text color="rgba(70, 70, 70, 1)">{option.title}</Text>
            <Text color="rgba(70, 70, 70, 1)">{option.value}</Text>
          </Flex>
        ))}
      </DefaultCardWrapper>
    );
  };
