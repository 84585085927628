import React from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { KanbanBoardProps, KanbanColumnProps } from 'shared/interfaces';
import { KanbanBoardBase } from 'components/modules/kanbanBases';
import Column from '../Column';

const Board: React.FC<KanbanBoardProps> = ({
  boardTitle,
  boardColumns,
  setBoardColumns,
}) => {
  const handleOnDragEnd = async (
    result: DropResult,
    columns: KanbanColumnProps[],
    setColumns: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const column = columns[Number(source.droppableId)];
      const copiedItems = [...column.tasks];

      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          tasks: copiedItems,
        },
      });
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[Number(source.droppableId)];
      const destinationColumn = columns[Number(destination.droppableId)];

      const sourceItems = [...sourceColumn.tasks];
      const destinationItems = [...destinationColumn.tasks];

      const [removed] = sourceItems.splice(source.index, 1);

      removed.status =
        columns[Number(destination.droppableId)].title.toLowerCase();

      destinationItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          tasks: sourceItems,
        },
        [destination.droppableId]: {
          ...destinationColumn,
          tasks: destinationItems,
        },
      });

      await hubApiMarketing.updateOrderData(result.draggableId, {
        status: columns[Number(destination.droppableId)].title.toLowerCase(),
      });
    }
  };

  return (
    <KanbanBoardBase
      boardTitle={boardTitle}
      handleOnDragEnd={handleOnDragEnd}
      columns={boardColumns}
      setColumns={setBoardColumns}
    >
      {Object.entries(boardColumns).map(([columnId, column]) => (
        <Column key={columnId} columnId={columnId} column={column} />
      ))}
    </KanbanBoardBase>
  );
};

export default Board;
