import { Flex } from '@chakra-ui/react';
import { IEmployeesDistributionInfo } from 'pages/DistributionOfMinedLeads/interfaces';
import { RiEdit2Fill } from 'react-icons/ri';

interface IQtdSendLeadsProps {
  employeeSingleRow: IEmployeesDistributionInfo;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLeadSubmissionqtdModal: React.Dispatch<React.SetStateAction<boolean>>;
  setLeadSubmissionQtd: React.Dispatch<React.SetStateAction<number>>;
}

export const QtdSendLeads: React.FC<IQtdSendLeadsProps> = ({
  employeeSingleRow,
  setIsSaved,
  setShowLeadSubmissionqtdModal,
  setLeadSubmissionQtd,
}) => {
  return (
    <Flex
      justifyContent="center"
      gridGap={2}
      width="100%"
      height="100%"
      alignItems="center"
    >
      {!employeeSingleRow.sendLeadsQtd ? 0 : employeeSingleRow.sendLeadsQtd}
      <RiEdit2Fill
        style={{ cursor: 'pointer' }}
        size={16}
        onClick={() => {
          setIsSaved(false);
          setShowLeadSubmissionqtdModal(true);
          setLeadSubmissionQtd(employeeSingleRow.sendLeadsQtd);
        }}
      />
    </Flex>
  );
};
