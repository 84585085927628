import { Flex, Text } from '@chakra-ui/react';
import { ClientMeetingType } from 'sharedV2/types/client';
import { ClientMeetingDetailsCommentedAreas } from './components/commentedAreas';
import { ClientMeetingDetailsObservations } from './components/observations';

type PropsType = {
  meeting: ClientMeetingType;
};

export const ClientMeetingDetailsDisclaimer: React.FC<PropsType> = ({
  meeting,
}): JSX.Element => {
  return (
    <Flex flexDirection="column" gap={2} h="100%">
      <Text color="white" fontWeight="bold">
        Áreas Comentadas
      </Text>

      <Flex flexDirection="column" gap={5} h="100%">
        <ClientMeetingDetailsCommentedAreas meeting={meeting} />
        <ClientMeetingDetailsObservations meeting={meeting} />
      </Flex>
    </Flex>
  );
};
