/* eslint no-unused-vars: off */
import React, { useState } from 'react';
import { IconType } from 'react-icons';

import { ModalWrapper } from 'components/elements/wrappers';
import { IconCard } from '../IconCard';

interface ModalProps {
  handleShowModal: () => void;
  title: string;
  segment: string;
  subSegment?: string;
  description: string;
}

interface ComponentProps {
  title: string;
  segment?: string;
  subSegment?: string;
  Modal: React.FC<ModalProps>;
  information?: string;
  Icon: IconType;
  description?: string;
}

export const CardToModal: React.FC<ComponentProps> = ({
  title,
  segment,
  subSegment,
  Modal,
  Icon,
  information,
  description,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleToggleModal = () => setShowModal(!showModal);

  return (
    <>
      <IconCard
        onClick={handleToggleModal}
        title={title}
        Icon={Icon}
        information={information}
        description={description}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <Modal
          handleShowModal={handleToggleModal}
          title={title}
          segment={segment || ''}
          subSegment={subSegment}
          description={description || ''}
        />
      </ModalWrapper>
    </>
  );
};
