import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import employeeFormReducer from './newEmployeeForm/reducer';
import notificationsReducer from './notifications/reducer';

export default combineReducers({
  user: userReducer,
  auth: authReducer,
  employeeForm: employeeFormReducer,
  notifications: notificationsReducer,
});
