import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BiTrash, BiEditAlt } from 'react-icons/bi';

import { ModalWrapper } from 'components/elements/wrappers';
import { MaterialProps } from 'pages/Marketing/interfaces';
import {
  EditMaterialForm,
  RemoveMaterialModal,
} from 'pages/Marketing/components/modules/materialsManagement';

interface MaterialCardProps {
  material: MaterialProps;
  handleSignalToUpdate: () => void;
  handleRemoveMaterial: (materialId: string) => Promise<void>;
}

export const MaterialCard: React.FC<MaterialCardProps> = ({
  material,
  handleSignalToUpdate,
  handleRemoveMaterial,
}) => {
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);
  const [showRemoveMaterialModal, setShowRemoveMaterialModal] = useState(false);

  const handleCloseEditMaterialModal = () => {
    setShowEditMaterialModal(false);
  };

  const handleCloseRemoveMaterialModal = () => {
    setShowRemoveMaterialModal(false);
  };

  return (
    <>
      <Flex
        width="100%"
        padding="4"
        background="#f2f2f2"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="md"
      >
        <Text
          maxW="90%"
          color="rgba(70, 70, 70, 1)"
          fontWeight="semibold"
          isTruncated
        >
          {material.title}
        </Text>
        <Flex gridGap="12px">
          <BiEditAlt
            size={20}
            color="rgba(70, 70, 70, 1)"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowEditMaterialModal(true)}
          />
          <BiTrash
            size={20}
            color="rgba(70, 70, 70, 1)"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowRemoveMaterialModal(true)}
          />
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showEditMaterialModal}
        onClose={() => {
          setShowEditMaterialModal(false);
        }}
      >
        <EditMaterialForm
          material={material}
          handleCloseModal={handleCloseEditMaterialModal}
          signalToUpdate={handleSignalToUpdate}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showRemoveMaterialModal}
        onClose={() => setShowRemoveMaterialModal(false)}
      >
        <RemoveMaterialModal
          material={material}
          handleRemoveMaterial={handleRemoveMaterial}
          closeRemoveMaterialModal={handleCloseRemoveMaterialModal}
        />
      </ModalWrapper>
    </>
  );
};
