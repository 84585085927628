import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';

export const ClientsSearchSkeletonLoading = (): JSX.Element => {
  const breakPoint = useCommonMediaQueries();

  const breakPoints = {
    small: { skeletons: 2 },
    medium: { skeletons: 3 },
    big: { skeletons: 3 },
  };

  return (
    <>
      {Array.from({ length: breakPoints[breakPoint].skeletons }).map(
        (_, index) => (
          <SkeletonBase key={index} w="40vw" h="180px" borderRadius={6} />
        )
      )}
    </>
  );
};
