import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text, Checkbox } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { BiAddToQueue } from 'react-icons/bi';
import { useSelector } from 'react-redux';

import { IReduxState } from 'shared/interfaces';

import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import EletronicSignature from 'components/EletronicSignature';
import {
  ContentSummaryCard,
  ContentTypeButton,
} from 'pages/Contents/components';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { LoadingSpinner } from 'components/elements/others';
import { Content } from 'pages/Contents/interfaces';

export const MyContents: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const { userId } = useParams<{ userId: string }>();
  const [contentType, setContentType] = useState('all');

  const [myContents, setMyContents] = useState<Content[]>([]);
  const [filteredContents, setFilteredContents] = useState<Content[]>([]);
  const [showAllContentsInHub, setShowAllContentsInHub] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getContents = async () => {
    setIsLoading(true);

    try {
      if (showAllContentsInHub) {
        const allContentsInHub = await hubApiUser.getAllContents();

        setMyContents(allContentsInHub);
        setFilteredContents(allContentsInHub);
      } else {
        const myContentsResp = await hubApiUser.getMyContents(userId);

        setMyContents(myContentsResp);
        setFilteredContents(myContentsResp);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.dark(
        'Não foi possível buscar seus processos, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    if (contentType === 'text') {
      setFilteredContents(
        myContents.filter((item: Content) => item.video === null)
      );
    } else if (contentType === 'video') {
      setFilteredContents(
        myContents.filter((item: Content) => item.video !== null)
      );
    } else {
      setFilteredContents(myContents);
    }
  }, [contentType]);

  useEffect(() => {
    getContents();
  }, [showAllContentsInHub]);

  return (
    <PageWrapper flexDir="column">
      <EletronicSignature />
      <BackPageButton />

      <Flex
        w={['100%', '70vw']}
        maxW={['95vw', '800px']}
        mx="auto"
        mt={14}
        flexDirection="column"
      >
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems={['flex-end', 'initial']}
          flexDirection={['column', 'row']}
        >
          <MainPageTitle color="inherit">
            Meus processos adicionados
          </MainPageTitle>

          <Link
            to={`/contents/my/${userId}/new`}
            style={{ textDecoration: 'none' }}
          >
            <PrimaryButton gridGap={2}>
              <BiAddToQueue size={24} />
              Adicionar novo
            </PrimaryButton>
          </Link>
        </Flex>

        <Flex
          mt={[3, 6]}
          justifyContent={user.position === 'MASTER' ? 'flex-start' : 'center'}
          alignItems={['center', 'flex-start']}
          flexDirection={['column-reverse', 'row']}
          gap={[2, 0]}
        >
          <ContentTypeButton
            contentType={contentType}
            setContentType={setContentType}
          />

          {user.position === 'MASTER' && (
            <Checkbox
              size="md"
              ml={['0', 'auto']}
              onChange={e => setShowAllContentsInHub(e.target.checked)}
              mb={0}
            >
              Ver processos de todos os assessores
            </Checkbox>
          )}
        </Flex>

        {!isLoading && (
          <Flex
            h="calc(100vh - 296px)"
            maxH="700px"
            mt={2}
            flexDirection="column"
            gridGap={6}
            overflowY="auto"
          >
            {filteredContents.length > 0 ? (
              filteredContents.map((content: Content) => (
                <>
                  <ContentSummaryCard
                    id={content.id}
                    title={content.title}
                    subtitle={content.subtitle || 'Descrição do conteúdo'}
                    area={content.area}
                    theme={content.theme}
                    author={content.employee.name}
                    publicationDate={content.created_at}
                    avatar={content.employee.avatar}
                    edit
                    url={content.video}
                  />
                </>
              ))
            ) : (
              <Text
                w="100%"
                textAlign="center"
                mt={8}
                fontStyle="italic"
                fontWeight="light"
              >
                Sem processos criados
              </Text>
            )}
          </Flex>
        )}

        {isLoading && <LoadingSpinner height="calc(100vh - 320px)" />}
      </Flex>
    </PageWrapper>
  );
};
