import { LeadParsedType } from 'sharedV2/types/leadTypes';
import { LeadUploadType } from 'sharedV2/types/leadUpload';
import { Mapper } from 'utilsV2/mapper';

type UseLeadUploadMapperResponseType = {
  mapper: Mapper<LeadUploadType, LeadParsedType>;
};

export const useLeadUploadMapper = (): UseLeadUploadMapperResponseType => {
  const toUseMapper = (lead: LeadUploadType): LeadParsedType => {
    const phones = [];
    if (lead.others) {
      for (const key in lead.others) {
        if (key.includes('Telefone')) {
          phones.push(lead.others[key]);
        }
      }
    }

    return {
      id: lead.id,
      email: lead.email,
      created_at: lead.created_at,
      employeeId: lead.employeeId,
      frequency: 0,
      meetingPrice: 0,
      name: lead.name,
      origin: 'Lista',
      phone: lead.phone,
      phones,
      pipeValue: lead.pipeValue,
      plMundoInvest: 0,
      plSalesHunter: 0,
      price: 0,
      status: lead.status,
      updated_at: lead.updated_at,
      meeting: lead.meeting,
      sex: lead?.others?.Sexo || undefined,
      profession: lead?.others?.Profissao || undefined,
      trader: lead?.others?.Trader || undefined,
      state: lead?.others?.Estado || undefined,
      aumPl: lead?.others?.Aum_PL || undefined,
      ageRange: lead?.others?.Faixa_Etaria,
      salaryRange: lead?.others?.Faixa_Salario,
      listName: lead?.listName,
      investorProfile: lead?.others?.Suitability,
      contactAttempts: lead?.contactAttempts,
    };
  };

  return {
    mapper: new Mapper(toUseMapper, null),
  };
};
