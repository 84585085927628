import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { LeadDetailsDesiredFlowEnum } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { LeadSearchType } from 'sharedV2/types/leadSearch';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { LeadUtils } from 'utilsV2/Lead';
import { MeetingUtils } from 'utilsV2/Meeting';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  lead: LeadSearchType;
};

export const LeadsSearchCard: React.FC<PropsType> = ({ lead }): JSX.Element => {
  const { redirect } = useNavigation();

  const handleClickOnCard = () => {
    redirect(
      `/v2/lead/${lead.type}/${lead.id}?desiredFlow=${LeadDetailsDesiredFlowEnum.LEADS_SEARCH}`
    );
  };

  const getLeadStatus = (): string => {
    if (
      lead.meeting?.status === MeetingStatusEnum.CREATED ||
      lead.meeting?.status === MeetingStatusEnum.CONFIRMED
    ) {
      return 'Mosaico SH';
    }

    return MeetingUtils.translateStatus(lead.meeting?.status);
  };

  return (
    <Box.Opaque
      w="40vw"
      p={4}
      gap={2}
      cursor="pointer"
      _hover={useCommonHover()}
      onClick={handleClickOnCard}
    >
      <Flex>
        <Text fontSize="lg" color="rgba(70, 70, 70, 1)" fontWeight="bold">
          {TextUtils.toCapitalize(lead.name)}
        </Text>
      </Flex>

      <Flex flexDirection="column">
        <Text color="rgba(70, 70, 70, 1)">Email: {lead.email}</Text>
        <Text color="rgba(70, 70, 70, 1)">
          Telefone: {TextUtils.toPhone(lead.phone)}
        </Text>
        <Text color="rgba(70, 70, 70, 1)">Status: {getLeadStatus()}</Text>
        <Text color="rgba(70, 70, 70, 1)">
          Origem: {LeadUtils.translateLeadTypeToPortuguese(lead.type)}
        </Text>
        <Text color="rgba(70, 70, 70, 1)">
          Responsável: {TextUtils.toCapitalize(lead.employee.name)}
        </Text>
      </Flex>
    </Box.Opaque>
  );
};
