import React, { useCallback, ReactText } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex, Stack, ButtonGroup } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';

import { RealStateFoundProps } from 'pages/products/interfaces';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';

interface RealEstateFoundFormProps {
  estateFounds: RealStateFoundProps;
  setEstateFounds: React.Dispatch<React.SetStateAction<RealStateFoundProps>>;
  handleSubmitForm: (e: any) => Promise<ReactText | undefined | void>;
  label: string;
  buttonName: string;
}

export const RealEstateFoundsForm: React.FC<RealEstateFoundFormProps> = ({
  estateFounds,
  setEstateFounds,
  handleSubmitForm,
  label,
  buttonName,
}) => {
  const history = useHistory();

  const handleChangevalue = useCallback(
    e => {
      setEstateFounds({
        ...estateFounds,
        [e.target.name]: e.target.value,
      });
    },
    [estateFounds]
  );

  const handleChangeCurrencyValue = useCallback(
    e => {
      setEstateFounds({
        ...estateFounds,
        netPL: e,
      });
    },
    [estateFounds]
  );

  const handleParsePercentage = useCallback(
    e => {
      if (e.target.value < 0 || e.target.value > 100) {
        toast.dark('A Porcentagem deve ser entre 0% e 100%');
      } else {
        handleChangevalue(e);
      }
    },
    [handleChangevalue]
  );

  const handleClickCancelButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ModalWrapper isOpen onClose={() => {}}>
      <DefaultModalContentWrapper width={['95%', 'auto']}>
        <ModalTitle>{label}</ModalTitle>

        <Flex flexDir="column" gridGap="8px">
          <Stack flexDir="row" spacing="0" gridGap="8px">
            <InputWithLabel
              name="ticker"
              value={estateFounds.ticker}
              label="Ticker"
              placeholder="Ticker"
              onChange={handleChangevalue}
            />
            <InputWithLabel
              name="allocation"
              value={estateFounds.allocation}
              type="number"
              label="Alocação (%)"
              placeholder="15%"
              onChange={handleParsePercentage}
            />
            <InputWithLabel
              name="segment"
              value={estateFounds.segment}
              label="Segmento"
              placeholder="Shopping, Laje, etc"
              onChange={handleChangevalue}
            />
          </Stack>
          <Stack
            flexDir="column"
            spacing="0"
            gridGap="8px"
            height={'200px'}
            overflowY={['auto', 'unset']}
          >
            <InputWithLabel
              name="pvp"
              value={estateFounds.pvp}
              label="P/VP"
              placeholder="1.23B"
              onChange={handleChangevalue}
            />
            <InputWithLabel
              name="dailyLiquidity"
              value={estateFounds.dailyLiquidity}
              type="number"
              label="Liquidez Diária"
              placeholder="15.000 (Negocioacoes)"
              onChange={handleChangevalue}
            />

            <InputWithLabelAndFormatingToMoney
              label="PL Liquido (R$)"
              name="netPL"
              value={estateFounds.netPL}
              placeholder="390 MI"
              onChange={e => handleChangeCurrencyValue(e)}
            />
            <InputWithLabel
              name="dividendYield"
              value={estateFounds.dividendYield}
              label="DIVIDEND YIELD(%)"
              type="number"
              placeholder="0.77"
              onChange={handleParsePercentage}
            />
          </Stack>

          <TextareaWithLabel
            name="informations"
            value={estateFounds.informations}
            label="Informações"
            placeholder="Informações relevantes"
            onChange={handleChangevalue}
            rows={6}
          />
          <ButtonGroup justifyContent="flex-end">
            <SecondaryButton onClick={handleClickCancelButton}>
              Cancelar{' '}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmitForm}>
              {buttonName}
            </PrimaryButton>
          </ButtonGroup>
        </Flex>
      </DefaultModalContentWrapper>
    </ModalWrapper>
  );
};
