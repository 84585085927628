import React, { useState } from 'react';
import { ButtonGroup, Flex, Stack } from '@chakra-ui/react';

import { SecondaryButton } from 'components/elements/buttons';
import { KanbanBaseTaskProps } from 'shared/interfaces';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import Intern from '../../../../../IP/components/Intern';
// import Chat from '../../../../../IP/components/Chat';

interface OrderProps extends KanbanBaseTaskProps {
  name?: string;
  type?: string;
  assessorName?: string;
  cardType?: string;
  btgCode?: string;
  phone?: string;
  email?: string;
  position?: string;
  project?: string;
  goals?: string;
  text?: string;
  informations?: string;
  publication?: string;
  formatting?: string;
}

interface ComponentProps {
  order: OrderProps;
  handleToggleModal: () => void;
}

export const BoardOrderInfo: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  const [showIntern, setShowIntern] = useState(false);
  // const [showChat, setShowChat] = useState(false);

  const handleAnotIn = () => {
    setShowIntern(true);
  };

  // const handleChat = () => {
  //   setShowChat(true);
  // };

  return (
    <DefaultModalContentWrapper>
      <ModalTitle>Dados da ordem</ModalTitle>

      <Stack spacing="3">
        {order.type && (
          <DisplayInformation label="Tipo da ordem" info={order.type} />
        )}
        {order.name && !order.assessorName && (
          <DisplayInformation label="Nome do solicitante" info={order.name} />
        )}

        <DisplayInformation
          label="Nome do solicitante"
          info={order.assessorName || order.name || ''}
        />

        {order.phone && (
          <DisplayInformation label="Telefone" info={order.phone} />
        )}
        {order.email && (
          <DisplayInformation label="E-mail" info={order.email} />
        )}

        {order.position && (
          <DisplayInformation label="Posição na EWZ" info={order.position} />
        )}
        {order.project && (
          <DisplayInformation label="Projeto" info={order.project} />
        )}
        {order.goals && (
          <DisplayInformation label="Objetivo" info={order.goals} />
        )}
        {order.title && (
          <DisplayInformation label="Título" info={order.title} />
        )}
        {order.cardType && (
          <DisplayInformation label="Tipo de cartão" info={order.cardType} />
        )}
        {order.text && <DisplayInformation label="Texto" info={order.text} />}
        {order.informations && (
          <DisplayInformation label="Informações" info={order.informations} />
        )}
        {order.publication && (
          <DisplayInformation label="Divulgação" info={order.publication} />
        )}
        {order.formatting && (
          <DisplayInformation label="Formatação" info={order.formatting} />
        )}
      </Stack>

      <Flex width="100%" mt="4">
        <SecondaryButton onClick={handleAnotIn}>
          Anotação Interna
        </SecondaryButton>
        {/* <SecondaryButton removeMargin onClick={handleChat}>
          Chat
        </SecondaryButton> */}
      </Flex>

      <ModalWrapper isOpen={showIntern} onClose={() => setShowIntern(false)}>
        <Intern setShowIntern={setShowIntern} order={order} />
      </ModalWrapper>

      {/* {showChat === true && (
        <ModalWrapper>
          <Chat setShowChat={setShowChat} order={order} />
        </ModalWrapper>
      )} */}

      <ButtonGroup width="100%" justifyContent="flex-end">
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};
