export const interestOptions = [
  { title: 'Sem interesse', value: 'no_interest' },
  { title: 'Não existe', value: 'no_exist' },
  { title: 'Não ligar mais (LGPD)', value: 'lgpd' },
  { title: 'Não tem dinheiro', value: 'no_money' },
  { title: 'Cliente BTG', value: 'btg_client' },
  { title: 'Cliente EWZ', value: 'ewz_client' },
  { title: 'Não atendeu', value: 'no_answer' },
];

export const shOptions = [
  { title: 'Sem interesse', value: 'no_interest' },
  { title: 'Não existe', value: 'no_exist' },
  { title: 'Não ligar mais (LGPD)', value: 'lgpd' },
  { title: 'Não tem dinheiro', value: 'no_money' },
  { title: 'Cliente BTG', value: 'btg_client' },
  { title: 'Cliente EWZ', value: 'ewz_client' },
];

export const interestMeeting = [
  { title: 'Sem interesse', value: 'no_interest' },
  { title: 'Não ligar mais (LGPD)', value: 'lgpd' },
  { title: 'Não tem dinheiro', value: 'no_money' },
  { title: 'Cliente BTG', value: 'btg_client' },
  { title: 'Cliente EWZ', value: 'ewz_client' },
];

export const tooMuchContactAttemptsOptions = [
  { title: 'Sem interesse', value: 'no_interest' },
  { title: 'Não atendeu', value: 'no_answer' },
  { title: 'Não existe', value: 'no_exist' },
  { title: 'Não ligar mais (LGPD)', value: 'lgpd' },
  { title: 'Não tem dinheiro', value: 'no_money' },
  { title: 'Cliente BTG', value: 'btg_client' },
  { title: 'Cliente EWZ', value: 'ewz_client' },
];
