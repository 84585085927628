import { Flex, Stack, Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';

import { formateDateAndTime } from 'utils/formatDate';
import formatStringInTitle from 'utils/formatStringInTitle';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import parser from 'html-react-parser';

interface ListAllAgreementsProps {
  agreements: IAgreement[];
  onClose: () => void;
  acceptContract: (id: string) => void;
}

export const ListAllAGreements: React.FC<ListAllAgreementsProps> = ({
  agreements,
  onClose,
  acceptContract,
}) => {
  return (
    <DefaultModalContentWrapper
      maxW={['95vw', '724px']}
      p="6"
      background="background.700"
    >
      <ModalTitle>Todos os acordos</ModalTitle>

      <Stack
        maxH="420px"
        pt="2"
        px="1"
        spacing="9"
        flexDir="column"
        overflowY="auto"
      >
        {agreements.map((item, key) => (
          <Flex flexDir="column" borderRadius="3" key={key}>
            <Flex
              align="flex-end"
              justifyContent="space-between"
              w="100%"
              borderBottom="1px solid gray"
              pb="1.5"
              mb="3"
            >
              <Text
                color="whiteAlpha.900"
                fontSize="lg"
                lineHeight="100%"
                justify="space-between"
                fontWeight="bold"
              >
                {formatStringInTitle(item.title)}
              </Text>
              <PrimaryButton size="xs" onClick={() => acceptContract(item.id)}>
                Aceitar acordo
              </PrimaryButton>
            </Flex>
            <Flex
              minH="64px"
              p="3"
              borderRadius="3"
              flexDir="column"
              bg="background.600"
            >
              <Text fontSize="sm" color="white.200" lineHeight="150%">
                {parser(item.agreement)}
              </Text>
            </Flex>
            <Flex
              justify="space-between"
              maxH="100px"
              overflow="auto"
              color="whiteAlpha.900"
              fontSize="sm"
              p="1"
            >
              <Text as="span" fontSize="sm" color="whiteAlpha.900">
                Data:{' '}
                <Text as="span" fontWeight="bold">
                  {formateDateAndTime(item.created_at)}
                </Text>
              </Text>

              <Text>
                Redigido por{' '}
                <Text as="span" color="whiteAlpha.900" fontWeight="bold">
                  {formatNameToCaptalize(item.managerName)}
                </Text>
              </Text>
            </Flex>

            <BaseButtonGroup />
          </Flex>
        ))}
      </Stack>
      <BaseButtonGroup mt="3" justifyContent="flex-start">
        <SecondaryButton onClick={onClose}>Aceitar depois</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
