import { Text, Stack } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { MaterialProps } from 'pages/Marketing/interfaces';

interface RemoveMaterialModalProps {
  material: MaterialProps;
  handleRemoveMaterial: (materialId: string) => Promise<void>;
  closeRemoveMaterialModal: () => void;
}

export const RemoveMaterialModal: React.FC<RemoveMaterialModalProps> = ({
  material,
  handleRemoveMaterial,
  closeRemoveMaterialModal,
}) => {
  return (
    <DefaultModalContentWrapper maxW="410px">
      <Stack spacing={5}>
        <Text fontSize="md" color="white">
          {`Deseja remover o material "${material.title}"?`}
        </Text>

        <BaseButtonGroup>
          <SecondaryButton
            onClick={() => {
              handleRemoveMaterial(material.id);
              closeRemoveMaterialModal();
            }}
          >
            Sim
          </SecondaryButton>
          <PrimaryButton onClick={closeRemoveMaterialModal}>Não</PrimaryButton>
        </BaseButtonGroup>
      </Stack>
    </DefaultModalContentWrapper>
  );
};
