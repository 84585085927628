import { Flex, Text } from '@chakra-ui/react';
import { CaretLeft, CaretRight } from 'phosphor-react';

type PropsType = {
  shouldShowBackPageIcon: boolean;
  shouldShowNextPageIcon: boolean;
  currentPage: number;
  totalPages: number;
  handleBackPage: () => void;
  handleNextPage: () => void;
};

export const ControlTablePages: React.FC<PropsType> = ({
  currentPage,
  handleBackPage,
  handleNextPage,
  shouldShowBackPageIcon,
  shouldShowNextPageIcon,
  totalPages,
}): JSX.Element => {
  return (
    <Flex alignItems="center">
      <Flex visibility={shouldShowBackPageIcon ? 'visible' : 'hidden'}>
        <CaretLeft
          size={28}
          onClick={() => handleBackPage()}
          cursor="pointer"
          color="rgba(70, 70, 70, 1)"
        />
      </Flex>

      <Text color="rgba(70, 70, 70, 1)">
        {currentPage} de {totalPages}
      </Text>
      <Flex visibility={shouldShowNextPageIcon ? 'visible' : 'hidden'}>
        <CaretRight
          color="rgba(70, 70, 70, 1)"
          size={28}
          onClick={() => handleNextPage()}
          cursor="pointer"
        />
      </Flex>
    </Flex>
  );
};
