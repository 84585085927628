import * as yup from 'yup';

const recoverPasswordSchema = yup.object().shape({
  password: yup.string().required('Digite a sua nova senha'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas diferentes')
    .required('Confirme a sua nova senha'),
});

export default recoverPasswordSchema;
