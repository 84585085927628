import React, { useState, useEffect } from 'react';

import { KanbanBaseTaskProps } from 'shared/interfaces';
import { KanbanTaskBase } from 'components/modules/kanbanBases';
import { ModalWrapper } from 'components/elements/wrappers';
import CloseOrder from 'pages/Technology/components/modules/technologyCRMComponent/CloseOrder';
import { BoardOrderInfo } from 'pages/Technology/components/modules/technologyCRMComponent/BoardOrderInfo';

import { techColumns } from 'pages/Technology/subpages/TechnologyCRM/data';
import { formateDateAndTime } from 'utils/formatDate';

interface TaskProps extends KanbanBaseTaskProps {
  type?: string;
  assessorName?: string;
  created_at?: string;
}

interface TaskComponentProps {
  index: number;
  task: TaskProps;
}

const Task: React.FC<TaskComponentProps> = ({ index, task }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLastColumn, setIsLastColumn] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (task.status?.toLowerCase() === techColumns[3].title.toLowerCase()) {
      setIsLastColumn(true);
    }
  }, [task.status]);

  const handleToggleModal = () => setShowModal(!showModal);

  const handleToggleLastModal = () => setIsLastColumn(!isLastColumn);

  return (
    <>
      <KanbanTaskBase
        taskId={task.id}
        taskIndex={index}
        isFinished={isFinished}
        primaryText={task.type}
        secondaryText={task.name || task.assessorName}
        thirdText={task.created_at ? formateDateAndTime(task.created_at) : ''}
        onClick={handleToggleModal}
      />

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BoardOrderInfo order={task} handleToggleModal={handleToggleModal} />
      </ModalWrapper>

      <ModalWrapper isOpen={isLastColumn} onClose={handleToggleLastModal}>
        <CloseOrder
          order={task}
          handleToggleModal={handleToggleLastModal}
          setIsFinished={setIsFinished}
        />
      </ModalWrapper>
    </>
  );
};

export default Task;
