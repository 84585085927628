const contractModel = [
  { title: 'CLT', value: 'CLT' },
  { title: 'MEI', value: 'MEI' },
  { title: 'Estágio', value: 'ASSOCIADO' },
  { title: 'Trainee', value: 'ASSOCIADO' },
  { title: 'Associado', value: 'ASSOCIADO' },
];

const position = [
  { title: 'Trainee', value: 'TRAINEE' },
  { title: 'Assessor', value: 'ASSESSOR' },
  { title: 'R.H', value: 'RH' },
  { title: 'T.I', value: 'TI' },
  { title: 'Marketing', value: 'MK' },
  { title: 'Operacional', value: 'OPERACIONAL' },
];

const location = [
  { title: 'São Paulo', value: 'sao paulo' },
  { title: 'Rio de Janeiro', value: 'rio de janeiro' },
  { title: 'Teresópolis', value: 'teresopolis' },
  { title: 'Ribeirão Preto', value: 'ribeirao preto' },
  { title: 'Tatuí', value: 'tatui' },
  { title: 'São José dos Campos', value: 'sao jose dos campos' },
  { title: 'Porto Alegre', value: 'porto alegre' },
];

const maritalStatus = [
  { title: 'Solteiro', value: 'solteiro' },
  { title: 'Casado', value: 'casado' },
  { title: 'Viúvo', value: 'viúvo' },
  { title: 'Divorciado', value: 'divorciado' },
  { title: 'Separado', value: 'separado' },
];

const degreeEducation = [
  { title: 'Ensino Médio Completo', value: 'medio' },
  { title: 'Ensino Superior Completo', value: 'superior' },
  { title: 'Pós Graduação', value: 'pos' },
  { title: 'Mestrado', value: 'mestrado' },
  { title: 'Doutorado', value: 'doutorado' },
];

const sexOptions = [
  { title: 'Masculino', value: 'masculino' },
  { title: 'Feminino', value: 'feminino' },
];

export {
  contractModel,
  position,
  location,
  maritalStatus,
  degreeEducation,
  sexOptions,
};
