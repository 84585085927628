import { Flex } from '@chakra-ui/react';
import { Input } from 'componentsV2/elements/input';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { Icons } from 'sharedV2/icons';

type PropsType = {
  handleSearchLeadByPhoneOrName: (value: string) => void;
  searchLeadByPhoneOrNameValue: string;
};

export const SalesHunterPoolSearchLead: React.FC<PropsType> = ({
  handleSearchLeadByPhoneOrName,
  searchLeadByPhoneOrNameValue,
}): JSX.Element => {
  const { setParam, getParam, deleteParam } = useQueryParams();

  const toggleSearch = getParam('toggleSearch');

  const handleToggleSearch = () => {
    if (toggleSearch === 'open') {
      deleteParam('filterByLeadPhoneOrNameValue');
      return deleteParam('toggleSearch');
    }

    setParam('toggleSearch', 'open');
  };

  return (
    <Flex gap={2}>
      <Flex
        bgColor="rgba(29, 99, 221, 1)"
        justifyContent="center"
        alignItems="center"
        py={1}
        px={3}
        borderRadius="6px"
        cursor="pointer"
        onClick={handleToggleSearch}
      >
        <Icons.SEARCH />
      </Flex>

      {toggleSearch === 'open' && (
        <Input.Root>
          <Input.TextField
            style={{ flex: 1 }}
            placeholder="Nome ou Número"
            value={searchLeadByPhoneOrNameValue}
            onChange={event =>
              handleSearchLeadByPhoneOrName(event.target.value)
            }
          />
        </Input.Root>
      )}
    </Flex>
  );
};
