import React, { useState, useEffect } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { Flex, Stack } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation, LoadingSpinner } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import formatValue from 'utils/formatValue';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import {
  IOrderInfoProps,
  IOrderOwnerProps,
} from 'pages/RV/subpages/RvWithdrawals/interfaces';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { errorHandler } from 'utils';
import DisplayInfoBtn from '../../../DisplayInfo';
import Withdrawal from '../Withdrawal';

const OrderInfo: React.FC<IOrderInfoProps> = ({ handleShowModal, order }) => {
  const [handleShowWithdrawal, setHandleShowWithdrawal] = useState(false);
  const [segment, setSegment] = useState('');
  const [value, setValue] = useState(0);
  const [risk, setRisk] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [orderOwner, setOrderOwner] = useState<IOrderOwnerProps>(
    {} as IOrderOwnerProps
  );

  const handleShow = (
    segmentSelected?: string,
    values?: number,
    risks?: string
  ) => {
    setHandleShowWithdrawal(!handleShowWithdrawal);

    if (segmentSelected) {
      setSegment(segmentSelected);
    }
    if (values) {
      setValue(values);
    }
    if (risks) {
      setRisk(risks);
    }
  };

  useEffect(() => {
    getOwnerInfos();
  }, [order.employeeId]);

  async function getOwnerInfos() {
    setIsLoading(true);
    try {
      const ClientResponse = await hubApiRv.getClientById(order.id);
      setOrderOwner(ClientResponse);
      setIsLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <DefaultModalContentWrapper maxW="520px">
      <ModalTitle d="flex" justifyContent="space-between">
        <Flex>Dados da Ordem</Flex>
        <SecondaryButton
          background="transparent"
          type="button"
          onClick={handleShowModal}
        >
          <AiOutlineClose />
        </SecondaryButton>
      </ModalTitle>

      <Stack spacing="2">
        <DisplayInformation label="Nome" info={orderOwner.nameClient} />
        <DisplayInformation label="Código BTG" info={order.btgAccount} />
        {orderOwner.rv?.map((itens, index) => (
          <DisplayInfoBtn
            label={`Segmento (${itens.risk})`}
            key={index}
            info={`${itens.segment}: ${formatValue(itens.financeiro)}`}
            btnText="Resgatar"
            handleShow={() =>
              handleShow(itens.segment, itens.financeiro, itens.risk)
            }
          />
        ))}

        {orderOwner.fii?.map((itens, index) => (
          <DisplayInfoBtn
            label="Segmento"
            key={index}
            info={`${itens.segment}: ${formatValue(itens.financeiro)}`}
            btnText="Resgatar"
            handleShow={() => handleShow(itens.segment, itens.financeiro)}
          />
        ))}

        {orderOwner.acoes?.map((itens, index) => {
          return (
            <DisplayInfoBtn
              label="Segmento"
              key={index}
              info={`${itens.segment}: ${formatValue(itens.financeiro)}`}
              btnText="Resgatar"
              handleShow={() => handleShow(itens.segment, itens.financeiro)}
            />
          );
        })}

        <ModalWrapper isOpen={handleShowWithdrawal} onClose={handleShowModal}>
          <Withdrawal
            handleShow={handleShow}
            infos={orderOwner}
            segment={segment}
            value={formatValue(value)}
            risk={risk}
            handleShowModal={handleShowModal}
          />
        </ModalWrapper>
      </Stack>
    </DefaultModalContentWrapper>
  );
};

export default OrderInfo;
