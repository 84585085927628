import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Checkbox, Flex } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { InputWithLabel } from 'components/elements/forms';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { INewOffer, Offer } from '../interfaces';

interface CreateOfferModalProps {
  closeModal: () => void;
  signalToUpdateOffers: () => void;
  isManager: boolean;
  isTableManager: boolean;
  tableId: string;
  offerEdited?: Offer;
}

export const CreateAndUpdateOfferModal: React.FC<CreateOfferModalProps> = ({
  closeModal,
  signalToUpdateOffers,
  isManager,
  isTableManager,
  tableId,
  offerEdited,
}) => {
  const [offer, setOffer] = useState({
    offerName: offerEdited?.offerName ? offerEdited?.offerName : '',
    offerAccessLevel: offerEdited?.isPublic
      ? 'publicOffer'
      : !offerEdited?.isPublic
      ? 'privateOffer'
      : '',
    isTableOffer: !!offerEdited?.tableId,
    selectedDate: offerEdited?.dateLimit ? offerEdited?.dateLimit : '',
    fee: offerEdited?.fee,
  });

  const handleCreateOffer = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (offer.offerName === '')
      return toast.dark('Digite o nome da nova oferta');
    if (offer.selectedDate === '' && offerEdited?.dateLimit !== '')
      return toast.dark('Selecione uma data limite para a oferta');
    if (isManager && offer.offerAccessLevel === '')
      return toast.dark('Selecione o nível de acesso da nova oferta');

    let newOffer: INewOffer = {
      offerName: offer.offerName,
      isPublic: offer.offerAccessLevel === 'publicOffer',
      dateLimit: offer.selectedDate,
      fee: offer.fee,
    };

    if (offer.isTableOffer) {
      newOffer = {
        offerName: offer.offerName,
        isPublic:
          offer.isTableOffer || offer.offerAccessLevel !== 'publicOffer',
        dateLimit: offer.selectedDate,
        tableId,
        fee: offer.fee,
      };
    }

    if (offerEdited?.id) {
      newOffer = {
        offerName: offer.offerName,
        isPublic: offer.offerAccessLevel === 'publicOffer',
        dateLimit: offer.selectedDate,
        tableId: offer.isTableOffer ? tableId : '',
        fee: offer.fee,
      };
    }

    try {
      if (offerEdited?.offerName) {
        await hubApiProducts.patchOffer(offerEdited.id, newOffer);

        toast.dark('Oferta atualizada com sucesso!');
        signalToUpdateOffers();
        closeModal();
        return;
      }
      await hubApiProducts.postNewOffer(newOffer);

      toast.dark('Oferta criada com sucesso!');
      signalToUpdateOffers();
      closeModal();
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage = axiosError.response?.data.error.message as string;

      if (errorMessage.includes('Already exist')) {
        toast.dark('Já existe uma oferta com este nome');
      } else {
        toast.dark(
          'Não foi possível criar a nova oferta, tente novamente em alguns minutos'
        );
      }
    }
  };

  const handleTableCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setOffer({
        ...offer,
        isTableOffer: true,
        offerAccessLevel: 'publicOffer',
      });
    } else {
      setOffer({
        ...offer,
        isTableOffer: false,
        offerAccessLevel: '',
      });
    }
  };

  const handleCheckDropDown = () => {
    if (isTableManager && !offer.isTableOffer) return true;

    if (offer.isTableOffer) return false;

    if (isManager) return true;

    if (isTableManager && !offer.isTableOffer) return false;
  };

  const handleOption = () => {
    if (isManager) return true;

    if (isTableManager && !offer.isTableOffer) return false;
  };

  const handleCheckBox = () => {
    if (!isTableManager && !isManager) return false;

    return true;
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    setOffer({ ...offer, [e.target.name]: e.target.value });
  };

  const handleChangeOfferName = (e: string) => {
    setOffer({ ...offer, offerName: e.toUpperCase() });
  };

  const handleChangeOfferFee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setOffer({ ...offer, fee: Number(e.target.value) });
  };

  return (
    <BaseModalFormCard
      isModal
      isLoading={false}
      maxW="480px"
      title={offerEdited?.offerName ? 'Atualizar oferta' : 'Criar nova oferta'}
      handleFormSubmit={handleCreateOffer}
      handleToggleModal={closeModal}
      primaryButtonText={
        offerEdited?.offerName ? 'Atualizar oferta' : 'Criar oferta'
      }
    >
      <InputWithLabel
        label="Nome da oferta"
        name="offerName"
        mb={2}
        value={offer.offerName}
        onChange={e => handleChangeOfferName(e.target.value)}
      />

      {handleCheckBox() && (
        <Flex justifyContent="flex-end">
          <Checkbox
            size="sm"
            title="Ao selecionar esta opção, a oferta será criada apenas para a mesa da qual você é o gestor"
            onChange={e => handleTableCheckbox(e)}
            isChecked={offer.isTableOffer}
          >
            Oferta da mesa
          </Checkbox>
        </Flex>
      )}

      <DatePickerChackra
        handlePickDate={handleChangeValue}
        label="Oferta válida até:"
        fontSize="sm"
        name="selectedDate"
        mb={4}
        value={offer.selectedDate}
      />

      {handleCheckDropDown() && (
        <DropdownWithLabel
          label="Nível de acesso"
          fontSize="sm"
          mb={2}
          name="offerAccessLevel"
          onChange={e => handleChangeValue(e)}
          placeholder={
            offer.offerAccessLevel === ''
              ? 'Selecione'
              : offer.offerAccessLevel === 'publicOffer'
              ? 'Pública'
              : 'Privada'
          }
        >
          {offer.offerAccessLevel === '' && (
            <>
              <option key="privateOffer" value="privateOffer">
                Privada
              </option>

              <>
                {handleOption() && (
                  <option key="publicOffer" value="publicOffer">
                    Pública
                  </option>
                )}
              </>
            </>
          )}

          {offer.offerAccessLevel === 'privateOffer' ? (
            <>
              {handleOption() && (
                <option key="publicOffer" value="publicOffer">
                  Pública
                </option>
              )}
            </>
          ) : (
            <option key="privateOffer" value="privateOffer">
              Privada
            </option>
          )}
        </DropdownWithLabel>
      )}

      <InputWithLabel
        label="Fee (%)"
        name="offerFee"
        type="number"
        mb={2}
        value={offer.fee || ''}
        onChange={handleChangeOfferFee}
      />
    </BaseModalFormCard>
  );
};
