import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import { io } from 'socket.io-client';

// import hubApi from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TransferOrderCard } from 'components/elements/cards';
import { useDisclosure } from '@chakra-ui/react';
import { IPTransferOrdersProps } from 'pages/IP/interfaces';
import { BackPageButton } from 'components/elements/buttons';

import { formatNameToCaptalize } from 'utils/captalizeNames';
import { OrderInfo } from './OrderInfo';

export const IpTransferOrders: React.FC = () => {
  const [infosIp, setInfosIp] = useState<IPTransferOrdersProps[]>([]);
  const [orderIpSelector, setOrderIpSelector] = useState<IPTransferOrdersProps>(
    {} as IPTransferOrdersProps
  );

  useEffect(() => {
    listOrders();
  }, []);

  const listOrders = async () => {
    try {
      const orders = await hubApiIp.listOrderIp();
      setInfosIp(orders);
    } catch (error) {
      toast.dark('Não foi possível buscar as ordens');
    }
  };

  const RemoveOrder = useCallback(
    id => {
      setInfosIp(
        infosIp.filter((item: IPTransferOrdersProps) => item.id !== id)
      );
    },
    [infosIp]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShowModal = (clickedOrder: IPTransferOrdersProps) => {
    setOrderIpSelector(clickedOrder);
  };

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle textAlign="center">
        Transferência de Ordens - PJ2
      </MainPageTitle>

      <TransferOrdersCardsWrapper>
        {infosIp.map((item, key) => (
          <TransferOrderCard
            key={`${key}-${item.name}`}
            mainText={item.name}
            secondaryText={
              item.others?.subSegment
                ? formatNameToCaptalize(
                    `${item.segment} - ${item.others.subSegment}`
                  )
                : formatNameToCaptalize(item.segment)
            }
            onClick={() => {
              handleShowModal(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo
          order={orderIpSelector}
          handleShowModal={onClose}
          RemoveOrder={RemoveOrder}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
