import React, { ChangeEvent } from 'react';

import DataPickerContent from './styles';

interface ComponentProps {
  label?: string;
  handlePickDate: (event: ChangeEvent<HTMLInputElement>) => void;
  full?: boolean;
  fullColum?: boolean;
  directionRow?: boolean;
  style?: any;
  type: string;
  value?: string;
}

const DatePicker: React.FC<ComponentProps> = ({
  label,
  handlePickDate,
  full,
  fullColum,
  directionRow,
  style,
  type,
  value,
  ...rest
}) => {
  return (
    <DataPickerContent style={style}>
      <div
        className={`input-date-container 
            ${full && 'input-date-container-full'} 
            ${full && 'input-date-container-row'}
            ${fullColum && 'input-date-container-full'}`}
      >
        <label>{label}</label>
        <input type={type} {...rest} onChange={handlePickDate} />
      </div>
    </DataPickerContent>
  );
};

export default DatePicker;
