import { Flex, Text } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { TitleWithSideLine } from 'components/elements/texts';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { IMibsHistoryAdditionalInformations } from '../dtos';
import { IWalletNames } from '../hooks/useMibsOperations';

interface IProps {
  additionalInformations: IMibsHistoryAdditionalInformations;
  setIsOpenMibsHistoryItemAdditionalInformationsModal(value: boolean): void;
}

const sourceWalletNameTranslated: Record<IWalletNames, string> = {
  EWZWallet: 'Carteira EWZ',
  ghostWallet: 'Carteira EWZ (SH)',
  personalWallet: 'Carteira Pessoal',
  EWZ: 'Banco Central EWZ',
  GHOST: 'Banco Central GHOST',
  MI: 'Banco Central MI',
};

export const MibsHistoryItemAdditionalInformationsModal: React.FC<IProps> = ({
  additionalInformations,
  setIsOpenMibsHistoryItemAdditionalInformationsModal,
}) => {
  return (
    <DefaultModalContentWrapper>
      <Flex justifyContent="center">
        <TitleWithSideLine fontSize={20}>
          Informações Adicionais
        </TitleWithSideLine>
      </Flex>
      <Flex mt={4} flexDirection="column" gap={5}>
        {additionalInformations.wallet && (
          <Flex flexDirection="column" gap={2}>
            <Text color="rgba(70, 70, 70, 1)" fontSize="md">
              Informações da Carteira
            </Text>
            <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
              Destino:{' '}
              {
                sourceWalletNameTranslated[
                  additionalInformations.wallet.destination
                ]
              }
            </Text>
          </Flex>
        )}
        {additionalInformations.order && (
          <Flex flexDirection="column" gap={2}>
            <Text color="rgba(70, 70, 70, 1)" fontSize="md">
              Informações da Ordem
            </Text>
            <Text color="rgba(70, 70, 70, 1)" fontSize="sm">
              ID: {additionalInformations.order.orderIdentificationId}
            </Text>
          </Flex>
        )}
        {additionalInformations.lead && (
          <Flex flexDirection="column" gap={2}>
            <Text color="rgba(70, 70, 70, 1)" fontSize="md">
              Informações do Lead
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              Email:{' '}
              {(additionalInformations.lead.email &&
                additionalInformations.lead?.email[0]?.email) ??
                'Não encontrado'}
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              PL: {additionalInformations.lead?.plMi ?? 'Não encontrado'}
            </Text>
          </Flex>
        )}
        {additionalInformations.commission && (
          <Flex flexDirection="column" gap={2}>
            <Text color="rgba(70, 70, 70, 1)" fontSize="md">
              Informações de Comissão
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              Comissão Antiga: {additionalInformations.commission.old}
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              Comissão Nova: {additionalInformations.commission.new}
            </Text>
            <Text color="rgba(70, 70, 70, 1)">
              PL: {additionalInformations.lead?.plMi ?? 'Não encontrado'}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="end" mt={3}>
        <SecondaryButton
          onClick={() =>
            setIsOpenMibsHistoryItemAdditionalInformationsModal(false)
          }
        >
          Fechar
        </SecondaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
