import { Flex, Text } from '@chakra-ui/react';
import { IEmployeesDistributionInfo } from 'pages/DistributionOfMinedLeads/interfaces';
import { FcOk } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';

interface ISaveChangesProps {
  isSaved: boolean;
  employeeSingleRow: IEmployeesDistributionInfo;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  employeesData: IEmployeesDistributionInfo[];
  setEmployeesData: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo[]>
  >;
  setEmployeeSingleRow: React.Dispatch<
    React.SetStateAction<IEmployeesDistributionInfo>
  >;
}

export const SaveChanges: React.FC<ISaveChangesProps> = ({
  isSaved,
  employeeSingleRow,
  setIsSaved,
  employeesData,
  setEmployeesData,
  setEmployeeSingleRow,
}) => {
  const handleEditEmployeeData = async () => {
    if (!employeeSingleRow.poolNameList) {
      return toast.dark('Selecione uma lista.');
    }

    if (!employeeSingleRow.sendLeadsQtd) {
      return toast.dark('Defina o número de leads a serem enviados.');
    }

    if (employeeSingleRow.id) {
      try {
        await hubApiCaptation.patchLeadDistributionConfig({
          id: employeeSingleRow.id,
          isDistributionActive: employeeSingleRow.isDistributionActive,
          employeeId: employeeSingleRow.employeeId,
          tableId: employeeSingleRow.tableId,
          sendLeadsQtd: employeeSingleRow.sendLeadsQtd,
          distributionDaysFrequency:
            employeeSingleRow.distributionDaysFrequency,
          poolNameList: employeeSingleRow.poolNameList,
        });
        setEmployeesData(
          employeesData.filter(employee => {
            if (employee.id === employeeSingleRow.id) {
              employee = employeeSingleRow;
              return employee;
            }
            return employee;
          })
        );
        toast.dark('Atualizado com sucesso.');
        setIsSaved(true);
      } catch (err) {
        setIsSaved(false);
        toast.dark(
          'Não foi possível salvar esta alteração.Tente novamente mais tarde.'
        );
      }
    } else {
      try {
        const response = await hubApiCaptation.postLeadDistributionConfig({
          isDistributionActive: employeeSingleRow.isDistributionActive,
          employeeId: employeeSingleRow.employeeId,
          tableId: employeeSingleRow.tableId,
          sendLeadsQtd: employeeSingleRow.sendLeadsQtd,
          distributionDaysFrequency:
            employeeSingleRow.distributionDaysFrequency,
          poolNameList: employeeSingleRow.poolNameList,
        });
        setEmployeesData(
          employeesData.filter(employee => {
            if (employee.employeeId === response.employeeId) {
              employee = response;
              return employee;
            }
            return employee;
          })
        );
        setEmployeeSingleRow(response);
        toast.dark('Criado com sucesso.');
        setIsSaved(true);
      } catch (err) {
        setIsSaved(false);
        toast.dark(
          'Não foi possível salvar esta alteração.Tente novamente mais tarde.'
        );
      }
    }
  };
  return (
    <Flex
      width={'100%'}
      height="100%"
      alignItems={'center'}
      justifyContent="center"
    >
      {isSaved ? (
        <FcOk size={20} />
      ) : (
        <Text
          className="itemToBeSaved"
          as="button"
          background="background.300"
          color="white"
          fontWeight="semibold"
          _hover={{ bg: 'background.400' }}
          px={3}
          borderRadius={3}
          onClick={handleEditEmployeeData}
        >
          Salvar
        </Text>
      )}
    </Flex>
  );
};
