import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useCreateMeeting } from 'hooksV2/useCreateMeeting';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { DateUtils } from 'utilsV2/Date';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
};

export const ScheduleMeetingCallbackModal: React.FC<PropsType> = ({
  lead,
  leadType,
}): JSX.Element => {
  const { getParam, setParam, deleteParam } = useQueryParams();

  const isFromProspectPage = getParam('isFromProspectPage') === 'true';

  const { mutateAsync: updateMeeting, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: async () => {
        showToast('Retorno marcado com sucesso!');
        deleteParam([
          'isOpenScheduleMeetingCallbackModal',
          'scheduleMeetingCallbackDate',
          'scheduleMeetingCallbackComplement',
        ]);

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
    });

  const { mutateAsync: createMeeting, isLoading: isLoadingCreateMeeting } =
    useCreateMeeting({
      onSuccess: async () => {
        showToast('Reunião agendada com sucesso!');
        deleteParam([
          'isOpenScheduleMeetingModal',
          'scheduleMeetingAdvisorId',
          'scheduleMeetingLocal',
          'scheduleMeetingDate',
          'scheduleMeetingComplement',
        ]);

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['shPoolLeads']);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
    });

  const meetingDate = getParam('scheduleMeetingCallbackDate');
  const meetingComplement = getParam('scheduleMeetingCallbackComplement');

  const isLoading = isLoadingCreateMeeting || isLoadingMeetingAction;

  const handleScheduleMeetingCallback = async () => {
    if (!meetingDate) return showToast('Selecione uma data para a reunião');

    if (DateUtils.isInPast(meetingDate))
      return showToast('Escolha uma data futura para a reunião');

    if (!lead?.meeting) {
      await createMeeting({
        leadId: lead?.id as string,
        leadType,
        date: meetingDate,
        local: 'Ligação',
        employeeId: lead?.employeeId as string,
        complement: meetingComplement,
        status: MeetingStatusEnum.CALLBACK,
      });

      return;
    }

    await updateMeeting({
      action: MeetingActionEnum.SCHEDULE_CALLBACK,
      leadType,
      meetingId: lead?.meeting?.id,
      date: meetingDate,
      complement: meetingComplement,
      isFromProspectPage,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Agendar retorno" />
        <Modal.CloseIcon
          onClick={() => deleteParam('isOpenScheduleMeetingCallbackModal')}
        />
      </Modal.Header>

      <Flex gap={2} flexDirection="column">
        <Input.Root>
          <Input.Label text="Data" />

          <Input.Date
            value={meetingDate}
            onChange={event =>
              setParam('scheduleMeetingCallbackDate', event.target.value)
            }
          />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Complemento" />

          <Input.TextAreaField
            placeholder="Informações sobre a reunião"
            value={meetingComplement}
            onChange={event =>
              setParam('scheduleMeetingCallbackComplement', event.target.value)
            }
          />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Agendar"
          onClick={handleScheduleMeetingCallback}
          isLoading={isLoading}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
