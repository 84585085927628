import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AllOffersSection } from 'pages/Offers/components';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { Offer } from 'pages/Offers/interfaces';
import { Flex } from '@chakra-ui/react';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const Offers: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [offersFiltered, setOffersFiltered] = useState<Offer[]>([]);
  const [witchTypeOfferIsActive, setWitchTypeOfferIsActive] = useState('valid');
  const [signalToUpdateOffers, setSignalToUpdateOffers] = useState(false);

  useEffect(() => {
    getOffers();
  }, [signalToUpdateOffers]);

  const handleFilterOffers = () => {
    if (witchTypeOfferIsActive === 'valid') {
      setOffersFiltered(
        offers.filter(offer => {
          return !isOfferExpired(offer);
        })
      );
    } else {
      setOffersFiltered(
        offers.filter(offer => {
          return isOfferExpired(offer);
        })
      );
    }
  };

  useEffect(() => {
    handleFilterOffers();
  }, [witchTypeOfferIsActive, offers]);

  const handleChangeTypeOfOffers = (e: string) => {
    setWitchTypeOfferIsActive(e);
  };

  const isOfferExpired = (offer: Offer) => {
    const today = new Date().toISOString().slice(0, 10);

    if (offer.dateLimit === '' || !offer.dateLimit) {
      const validOffer = !!offer.inCaptation;

      return !validOffer;
    }
    return today > offer.dateLimit;
  };

  const getOffers = async () => {
    try {
      const obtainedOffers = await hubApiProducts.getUserOffers();

      const orderedOffers = obtainedOffers.sort(
        (item: Offer, proxItem: Offer) => {
          let result = 0;

          if (isOfferExpired(item) && !isOfferExpired(proxItem)) {
            result = 1;
          }
          if (!isOfferExpired(item) && !isOfferExpired(proxItem)) {
            result = -1;
          }

          return result;
        }
      );

      setOffers([...orderedOffers]);
    } catch (error) {
      toast.dark(
        'Não foi possível obter suas ofertas, recarregue a página para tentar novamente'
      );
    }
  };

  const handleSignalToUpdateOffers = () => {
    setSignalToUpdateOffers(!signalToUpdateOffers);
  };

  return (
    <PageWrapper>
      <Flex flexDir="column" p="5" w="100vw" maxW="1080px" mx="auto">
        <AllOffersSection
          handleSignalToUpdateOffers={handleSignalToUpdateOffers}
          offers={offersFiltered}
          handleChangeTypeOfOffers={handleChangeTypeOfOffers}
          witchTypeOfferIsActive={witchTypeOfferIsActive}
        />
      </Flex>
    </PageWrapper>
  );
};
