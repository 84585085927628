import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import { IBaseLead } from 'pages/Captation/interfaces';
import { MosaicLeadCard } from 'pages/Captation/components/elements/cards';
import { TitleWithSideLine } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import {
  MosaicCardsWrapper,
  MosaicCardWrapper,
} from 'pages/Captation/components/elements/wrappers';
import { PrimaryButton } from 'components/elements/buttons';
import { DropDownContactAttempts } from 'pages/Captation/components/elements/others/DropDownContactAttempts';
import { CustomLeadsSearchTerms } from 'pages/Captation/components/elements/others';

interface IMinedLeadMosaicProps {
  leadType: string;
  handleShowLead: (lead: IBaseLead) => void;
  minedLeadsList: IBaseLead[];
  updateMinedLeadsList: (page: number, term: string) => void;
  currentMinedLeadsListPage: number;
  updateLastClickedMinedLeadCardId: React.Dispatch<
    React.SetStateAction<string>
  >;
  updateSectionScrollPosition: React.Dispatch<React.SetStateAction<number>>;
  sectionScrollPosition: number;
  lastClickedMinedLeadCardId: string;
  updatedClickedLeadContactAttempts: number;
  updatedClickedLeadContactAttemptsId: string;
}

export const MinedLeadMosaic: React.FC<IMinedLeadMosaicProps> = ({
  handleShowLead,
  minedLeadsList,
  updateMinedLeadsList,
  currentMinedLeadsListPage,
  updateLastClickedMinedLeadCardId,
  lastClickedMinedLeadCardId,
  updateSectionScrollPosition,
  sectionScrollPosition,
  updatedClickedLeadContactAttempts,
  updatedClickedLeadContactAttemptsId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatedMinedLeadsList, setUpdatedMinedLeadsList] =
    useState<IBaseLead[]>(minedLeadsList);
  const [customTermToSearch, setCustomTermToSearch] = useState(
    window.sessionStorage.getItem('minedLeadsCurrentCustomFilter') || ''
  );

  const [attemptsFilterValue, setAttemptsFilterValue] = useState<number>(-1);
  const filterLeads =
    attemptsFilterValue === -1
      ? updatedMinedLeadsList
      : updatedMinedLeadsList.filter(item => {
          const numberContactAttempts = Number(
            item.contactAttempts ? item.contactAttempts : 0
          );

          if (numberContactAttempts === attemptsFilterValue) {
            return item;
          }

          if (numberContactAttempts >= 5 && attemptsFilterValue === 5) {
            return item;
          }
        });

  const history = useHistory();

  const sendToLeadsUploadPage = () => {
    history.push('/captation/upload_leads');
  };

  const handleSelectedLeadAndScrollPosition = (lead?: IBaseLead) => {
    const scrollPosition =
      document.querySelector('.scrollContainer')?.scrollTop || 0;

    if (lead) {
      const leadId: string = lead.leadId ? lead.leadId : lead.id;
      updateSectionScrollPosition(scrollPosition);
      updateLastClickedMinedLeadCardId(leadId);
      handleShowLead({ ...lead, id: leadId });
    } else {
      updateSectionScrollPosition(scrollPosition);
    }
  };

  const handleUpdateLastClickedCard = () => {
    const updatedList: IBaseLead[] = minedLeadsList.map(lead => {
      const leadId: string = lead.leadId ? lead.leadId : lead.id;
      if (leadId === updatedClickedLeadContactAttemptsId) {
        lead.contactAttempts = updatedClickedLeadContactAttempts.toString();
        return lead;
      }
      return lead;
    });

    setUpdatedMinedLeadsList(updatedList);
  };

  const getPhone = (lead: any) => {
    let phone;
    if (lead.phone[0]?.phone) {
      phone = lead.phone[0]?.phone;
    } else {
      phone = lead.phone;
    }

    return phone;
  };

  const getEmail = (lead: any) => {
    let email;
    if (lead.email) {
      email = lead.email[0]?.email;
    } else {
      email = lead.email;
    }

    return email;
  };

  const applyCustomFilterSearch = () => {
    if (customTermToSearch.length < 2) {
      setUpdatedMinedLeadsList(minedLeadsList);

      return;
    }
    const filteredLeads = minedLeadsList.filter(item => {
      return JSON.stringify(Object.values(item))
        .toLowerCase()
        .includes(customTermToSearch.toLowerCase());
    });
    setUpdatedMinedLeadsList(filteredLeads);
  };

  useEffect(() => {
    if (sectionScrollPosition > 0) {
      const scrollSection = document.querySelector('.scrollContainer');

      scrollSection?.scrollTo({
        top: sectionScrollPosition,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    handleUpdateLastClickedCard();
  }, [updatedClickedLeadContactAttempts]);

  useEffect(() => {
    window.sessionStorage.setItem(
      'minedLeadsCurrentCustomFilter',
      customTermToSearch
    );
    applyCustomFilterSearch();
  }, [customTermToSearch]);

  useEffect(() => {
    if (customTermToSearch !== '') {
      handleUpdateLastClickedCard();
      applyCustomFilterSearch();

      return;
    }
    handleUpdateLastClickedCard();
    setUpdatedMinedLeadsList(minedLeadsList);
  }, [minedLeadsList]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex
        gridGap="5"
        alignItems="center"
        flexDirection={['column-reverse', 'row']}
      >
        <TitleWithSideLine>Leads Minerados</TitleWithSideLine>

        <CustomLeadsSearchTerms
          width="70%"
          setCustomTermToSearch={setCustomTermToSearch}
          customTermToSearch={customTermToSearch}
          isAnEmptyList={filterLeads.length === 0}
        />

        <PrimaryButton
          size="xs"
          w={['40%', '30%']}
          onClick={sendToLeadsUploadPage}
        >
          Upload de Leads
        </PrimaryButton>

        <DropDownContactAttempts
          setAttemptsFilterValue={setAttemptsFilterValue}
        />
      </Flex>

      <MosaicCardsWrapper
        mt="3"
        className="scrollContainer"
        onMouseLeave={() => handleSelectedLeadAndScrollPosition()}
      >
        <MosaicCardWrapper
          alignItems="center"
          justifyContent="center"
          onClick={() =>
            updateMinedLeadsList(
              currentMinedLeadsListPage + 1,
              customTermToSearch
            )
          }
          bg="background.400"
          color="white.300"
          _hover={{ bg: 'background.300', color: 'white' }}
          border="none"
        >
          <Flex alignItems="center" gridGap="1">
            <BiAddToQueue size={32} />
            <Text>Carregar mais leads</Text>
          </Flex>
        </MosaicCardWrapper>
        {filterLeads
          .filter((item: IBaseLead, index: number) => {
            const leadId: string = item.leadId ? item.leadId : item.id;
            return (
              index ===
              filterLeads.findIndex(element => {
                const leadIdElement: string = element.leadId
                  ? element.leadId
                  : element.id;
                return leadIdElement === leadId;
              })
            );
          })
          .map((item, index) => (
            <MosaicLeadCard
              key={index}
              name={item.name}
              phone={getPhone(item)}
              email={getEmail(item)}
              onClick={() => {
                handleSelectedLeadAndScrollPosition(item);
              }}
              borderColor={
                item.leadId === lastClickedMinedLeadCardId ||
                item.id === lastClickedMinedLeadCardId
                  ? 'primary.900'
                  : 'background.400'
              }
              contactAttempts={item.contactAttempts}
            />
          ))}
      </MosaicCardsWrapper>
    </>
  );
};
