import { Flex } from '@chakra-ui/react';

export const InfosUserWrapper: React.FC = ({ children }) => {
  return (
    <Flex
      border="1px solid gray"
      gridGap={1.5}
      py="1"
      px="3"
      color="whiteAlpha.900"
      fontWeight="semibold"
      borderRadius="5"
      width={['60%', 'auto']}
    >
      {children}
    </Flex>
  );
};
