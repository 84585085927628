import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper, ModalWrapper } from 'components/elements/wrappers';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { useState } from 'react';
import { PrimaryButton } from 'components/elements/buttons';
import { AddPowerBIModal } from 'pages/Dashboards/modals/AddPowerBIModal';
import { PowerBIManagementCard } from 'pages/Dashboards/components/powerBIManagementCard';
import { BiAddToQueue } from 'react-icons/bi';

export const DashboardsManagement = (): JSX.Element => {
  const [isOpenAddPowerBIModal, setIsOpenAddPowerBIModal] =
    useState<boolean>(false);

  const handleToggleOpenAddPowerBIModal = (value: boolean) => {
    setIsOpenAddPowerBIModal(value);
  };

  const { data: powerBIManagement } = useQuery({
    queryKey: ['powerBIManagement'],
    queryFn: async () => {
      const response = await hubApi.getPowerBIManagement();
      return response;
    },
    onError: () => {
      toast.dark('Erro ao dados do Power BI.');
    },
  });

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle color="rgba(70, 70, 70, 1)">
        Gerenciamento de BIs
      </MainPageTitle>

      <Flex flexDirection="column" w="100%" h="100%" gap={5}>
        <Flex justifyContent="flex-end">
          <PrimaryButton
            onClick={() => handleToggleOpenAddPowerBIModal(true)}
            gap={2}
          >
            <BiAddToQueue size={22} />
            Adicionar novo BI
          </PrimaryButton>
        </Flex>
        <Flex
          flexDirection="column"
          overflowY="auto"
          maxHeight="75vh"
          className="thinScrollbar"
          gap={4}
        >
          {powerBIManagement?.dashboards.length === 0 && (
            <Text textAlign="center" fontStyle="italic" fontWeight="thin">
              Sem Power BIs para gerenciar
            </Text>
          )}
          {powerBIManagement &&
            powerBIManagement.dashboards.map(page => {
              return <PowerBIManagementCard page={page} />;
            })}
        </Flex>
      </Flex>
      <ModalWrapper
        isOpen={isOpenAddPowerBIModal}
        onClose={() => handleToggleOpenAddPowerBIModal(false)}
      >
        <AddPowerBIModal
          handleToggleOpenAddPowerBIModal={handleToggleOpenAddPowerBIModal}
        />
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
