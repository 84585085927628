import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { useLeadDetailsContext } from '../context/useLeadDetailsContext';

type UseBuildMundoInvestDataResponseType = {
  mundoInvestData:
    | { label: string; value: string | number | undefined }[]
    | undefined;
};

export const useBuildMundoInvestData =
  (): UseBuildMundoInvestDataResponseType => {
    const { mundoInvestInformation } = useLeadDetailsContext();

    const mundoInvestData = [
      {
        label: 'Idade',
        value: mundoInvestInformation?.age ?? '-',
      },
      {
        label: 'Estado Civil',
        value: formatNameToCaptalize(
          mundoInvestInformation?.maritalStatus ?? '-'
        ),
      },
      {
        label: 'Atividade Profissional',
        value: formatNameToCaptalize(mundoInvestInformation?.activity ?? '-'),
      },
      {
        label: 'Idade de Aposentadoria',
        value: mundoInvestInformation?.ageToRetire ?? '-',
      },
      {
        label: 'Momento Financeiro',
        value: formatNameToCaptalize(mundoInvestInformation?.lifeMoment ?? '-'),
      },
      {
        label: 'Objetivo',
        value: formatNameToCaptalize(mundoInvestInformation?.incomeType ?? '-'),
      },
      {
        label: 'Criação da Conta no MI',
        value: mundoInvestInformation?.created_at
          ? formateDateAndTime(mundoInvestInformation.created_at)
          : '-',
      },
      {
        label: 'Dependências',
        value: mundoInvestInformation?.dependents,
      },
      {
        label: 'Perfil do Investidor',
        value: formatNameToCaptalize(
          mundoInvestInformation?.investorProfile ?? '-'
        ),
      },
      {
        label: 'Renda Mensal',
        value: formatValue(mundoInvestInformation?.monthlyIncome ?? 0),
      },
      {
        label: 'Renda Desejada',
        value: formatValue(mundoInvestInformation?.desiredIncome ?? 0),
      },
      {
        label: 'Valor Aporte',
        value: formatValue(mundoInvestInformation?.investPerMonth ?? 0),
      },
      {
        label: 'Gastos Mensais',
        value: formatValue(mundoInvestInformation?.monthlyExpense ?? 0),
      },
    ];

    return {
      mundoInvestData: mundoInvestInformation.id ? mundoInvestData : undefined,
    };
  };
