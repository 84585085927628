import React, { useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { Divider } from 'components/elements/others';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import {
  ICalendarProps,
  IPOprops,
  COEprops,
  OFPprops,
} from 'pages/Home/interfaces';
import { PulseAnimation } from 'pages/Home/components/elements/others/PulseAnimation';
import { MoneyBagAnimation } from '../../others/MoneyBagAnimation';

interface ComponentProps {
  productCardInfos: ICalendarProps;
  handleShowModal: () => void;
  setCardSelected: React.Dispatch<
    React.SetStateAction<{
      foundProduct: IPOprops | COEprops | OFPprops;
      productType: string;
    }>
  >;
}

export const CalendarItemCard: React.FC<ComponentProps> = ({
  productCardInfos,
  handleShowModal,
  setCardSelected,
}) => {
  const parseDate = new Date(
    `${productCardInfos.limitDate?.split('T')[0]}T00:00:00`
  );

  const handleClick = useCallback(async () => {
    let foundProduct;

    if (productCardInfos.productType.toUpperCase() === 'COE') {
      foundProduct = await hubApiProducts.getCoeById(
        productCardInfos.productId
      );
    } else if (productCardInfos.productType.toUpperCase() === 'IPO') {
      foundProduct = await hubApiProducts.getIpoById(
        productCardInfos.productId
      );
    } else if (productCardInfos.productType.toUpperCase() === 'OFP') {
      foundProduct = await hubApiProducts.getPublicOfferingById(
        productCardInfos.productId
      );
    }

    setCardSelected({
      foundProduct,
      productType: productCardInfos.productType,
    });
    handleShowModal();
  }, [productCardInfos, setCardSelected, handleShowModal]);

  const verifyIfDateIsEqual = useCallback((dateLeft, dateRight) => {
    return (
      dateLeft.getFullYear() === dateRight.getFullYear() &&
      dateLeft.getMonth() === dateRight.getMonth() &&
      dateLeft.getDate() === dateRight.getDate()
    );
  }, []);

  return (
    <DefaultCardWrapper
      width="100%"
      maxH="100px"
      maxW={['100%', '49%', '100%', '49%']}
      background="background.400"
      px="4"
      alignItems="center"
      py="3"
      gridGap="3"
      cursor="pointer"
      onClick={handleClick}
      position="relative"
    >
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        {productCardInfos.title.toLowerCase() !== 'em captação' && (
          <>
            <Flex
              justifyContent="center"
              alignItems="center"
              background="background.600"
              borderRadius="sm"
              px="2.5"
              py="0.5"
            >
              <Text fontWeight="semibold" fontSize="x-small">
                {parseDate
                  .toLocaleString('default', { month: 'short' })
                  .toUpperCase()}
              </Text>
            </Flex>

            <Text
              fontSize={{ base: 'lg', md: '2xl' }}
              lineHeight="100%"
              mt="2"
              fontWeight="bold"
              color={
                verifyIfDateIsEqual(new Date(), parseDate)
                  ? 'orange.400'
                  : 'white'
              }
            >
              {parseDate
                .toLocaleString('default', {
                  day: 'numeric',
                })
                .toUpperCase()
                .padStart(2, '0')}
            </Text>
          </>
        )}

        {productCardInfos.title.toLowerCase() === 'em captação' && (
          <PulseAnimation />
        )}
      </Flex>

      <Divider orientation="vertical" />

      <Flex flexDir="column" justifyContent="center" width="80%">
        <Text fontSize="sm" fontWeight="semibold" color="gray.400">
          {productCardInfos.title}
        </Text>
        <Text
          color="white"
          fontWeight="bold"
          fontSize={{ base: 'md', md: 'md', lg: 'lg' }}
          width="100%"
          maxW={{ base: '90%', md: '274px', xl: '274px', '2xl': '384px' }}
          isTruncated
        >
          {productCardInfos.productTitle !== 'undefined'
            ? productCardInfos.productTitle
            : '-'}
        </Text>
      </Flex>
      {productCardInfos.bonusMessage && <MoneyBagAnimation />}
    </DefaultCardWrapper>
  );
};
