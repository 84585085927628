let dateNow = new Date();

export const today = `${dateNow.getFullYear().toString()}-${(
  dateNow.getMonth() + 1
)
  .toString()
  .padStart(2, '0')}-${dateNow.getDate().toString().padStart(2, '0')}`;

export const initialFileValue = {
  name: 'Clique para escolher arquivo',
};

export const financialTableHead = [
  'Categoria',
  'Produto',
  'Ativo',
  'Código/CNPJ',
  'Tipo Receita',
  'Receita Bruta',
  'Receita Liquida',
  'Comissão',
];
