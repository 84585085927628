export const StuckMoneyTaskIcon = (): JSX.Element => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.079 13.214a6.05 6.05 0 000-8.691 6.312 6.312 0 00-8.845 0 6.05 6.05 0 000 8.691"
        stroke="#E0B55A"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M4.158 15.938h11.135H4.158z"
        stroke="#E0B55A"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.656 5.499v.87M9.656 12.458v-.87M11.387 7.587a1.301 1.301 0 00-1.296-1.218h-.953a1.221 1.221 0 00-.297 2.406l1.63.41a1.222 1.222 0 01-.297 2.406h-.953c-.692 0-1.252-.54-1.297-1.22"
        stroke="#E0B55A"
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
