import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { useCaptationMeetingsContext } from 'hooks';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';

interface ContactAttemptsProps {
  currentAttempts: string;
  leadType: string;
  leadId: string;
  meetingStatus?:
    | 'suitability'
    | 'callback'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'created'
    | 'pipe';
  id: string;
  setDecline: React.Dispatch<React.SetStateAction<boolean>>;
  updateContactAttempts?: React.Dispatch<React.SetStateAction<number>>;
  updateLastClickedLeadContactAttempts?: React.Dispatch<
    React.SetStateAction<number>
  >;
  setUpdatedClickedLeadContactAttemptsId?: React.Dispatch<
    React.SetStateAction<string>
  >;
  setUpdatedContactAttempts?: React.Dispatch<React.SetStateAction<number>>;
}

export const ContactAttempts: React.FC<ContactAttemptsProps> = ({
  currentAttempts,
  leadType,
  leadId,
  meetingStatus,
  id,
  updateContactAttempts,
  updateLastClickedLeadContactAttempts,
  setUpdatedClickedLeadContactAttemptsId,
  setDecline,
  setUpdatedContactAttempts,
}) => {
  const [numOfAttempts, setNumOfAttempts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedCurrentAttempts, setUpdatedCurrentAttempts] = useState(0);
  const [showDeclineConfirmationModal, setShowDeclineConfirmationModal] =
    useState(false);
  const [lastOperationType, setLastOperationType] = useState('');
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();
  const user = useSelector((state: IReduxState) => state.user.profile);

  const handleSaveNumOfAttempts = async () => {
    if (numOfAttempts !== updatedCurrentAttempts) {
      setUpdatedCurrentAttempts(numOfAttempts);

      try {
        const dataToActionsHistory = {
          employeeId: user.id,
          leadId,
          leadType,
          attempts: numOfAttempts <= Number(currentAttempts) ? -1 : 1,
        };

        await hubApiCaptation.patchEmployeeActionsHistory(dataToActionsHistory);
      } catch (error) {
        toast.dark(
          'Não foi possível atualizar o número de tentativas de contato, tente novamente em alguns minutos'
        );
        return;
      }

      try {
        if (meetingStatus) {
          // a presença do meetingStatus indica que se trata de uma atualização da meeting, não do lead em si, e que o id recebido é o id da meeting
          await hubApiCaptation.pathMetting({
            id,
            contactAttempts: numOfAttempts,
          });

          handleGetSpecificLeadTypeArr(meetingStatus, true);

          if (lastOperationType === 'plusOne') {
            localStorage.setItem(leadId, Date.now().toString());
          } else {
            localStorage.removeItem(leadId);
          }

          return;
        }

        await hubApiCaptation.pathLeadsUpdate(leadType, {
          id,
          contactAttempts: numOfAttempts.toString(),
        });

        if (
          updateContactAttempts &&
          updateLastClickedLeadContactAttempts &&
          setUpdatedClickedLeadContactAttemptsId
        ) {
          updateContactAttempts(numOfAttempts);
          updateLastClickedLeadContactAttempts(numOfAttempts);
          setUpdatedClickedLeadContactAttemptsId(id);
        }

        if (lastOperationType === 'plusOne') {
          localStorage.setItem(leadId, Date.now().toString());
        } else {
          localStorage.removeItem(leadId);
        }
      } catch (error) {
        toast.dark(
          'Não foi possível atualizar o número de tentativas de contato, tente novamente em alguns minutos'
        );
      }
    }
  };

  const handleNumOfAttempts = (operation: string) => {
    if (operation === 'minusOne' && numOfAttempts > 0) {
      setUpdatedContactAttempts && setUpdatedContactAttempts(prev => prev - 1);
      setNumOfAttempts(prev => prev - 1);
    }

    if (operation === 'plusOne') {
      const shouldBlock = verifiesLastCallAttempt();

      if (shouldBlock) {
        toast.dark(
          'Aguarde ao menos 30 minutos para realizar outra tentativa!'
        );
        return;
      }

      setUpdatedContactAttempts && setUpdatedContactAttempts(prev => prev + 1);
      setNumOfAttempts(prev => {
        if (prev + 1 >= 5) {
          setShowDeclineConfirmationModal(true);
        }
        return prev + 1;
      });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    await handleSaveNumOfAttempts();
    setIsLoading(false);
  };

  const verifiesLastCallAttempt = () => {
    const requiredElapsedMinutes = 30;
    const lastCallAttempt = localStorage.getItem(leadId);
    let timeDiff = 0;

    if (lastCallAttempt) {
      timeDiff =
        getDifferenceInMinutes(new Date(+lastCallAttempt).toISOString()) * -1;
    }

    if (lastCallAttempt && timeDiff < requiredElapsedMinutes) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setNumOfAttempts(Number(currentAttempts));
    setUpdatedContactAttempts &&
      setUpdatedContactAttempts(Number(currentAttempts));
    setUpdatedCurrentAttempts(Number(currentAttempts));
  }, [currentAttempts]);

  useEffect(() => {
    handleSave();
  }, [numOfAttempts]);

  return (
    <>
      <Flex mt="auto" justifyContent="center">
        <Text>Tentativas de contato</Text>

        <Flex ml={3}>
          <Flex
            borderTopLeftRadius={4}
            borderBottomLeftRadius={4}
            backgroundColor="background.200"
            px={3}
            color="white"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            _hover={{ backgroundColor: 'background.100' }}
            transition="350ms"
            onClick={() => {
              handleNumOfAttempts('minusOne');
              setLastOperationType('minusOne');
            }}
          >
            <Text as="button" fontWeight="bold">
              -
            </Text>
          </Flex>
          <Flex
            backgroundColor="background.900"
            justifyContent="center"
            alignItems="center"
            px={2}
            color={
              numOfAttempts > 0 && numOfAttempts < 5
                ? 'red.200'
                : numOfAttempts >= 5
                ? 'red.400'
                : 'inherit'
            }
            w={8}
          >
            {numOfAttempts || 0}
          </Flex>
          <Flex
            borderTopRightRadius={4}
            borderBottomRightRadius={4}
            backgroundColor="background.200"
            px={2.5}
            color="white"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            _hover={{ backgroundColor: 'background.100' }}
            transition="350ms"
            onClick={
              isLoading === false
                ? () => {
                    handleNumOfAttempts('plusOne');
                    setLastOperationType('plusOne');
                  }
                : () => null
            }
          >
            <Text as="button" fontWeight="bold" mb={0.5}>
              +
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showDeclineConfirmationModal}
        onClose={() => setShowDeclineConfirmationModal(false)}
      >
        <DefaultModalContentWrapper>
          <ModalTitle>
            Deseja declinar este lead por falta de contato?
          </ModalTitle>

          <BaseButtonGroup mt="5">
            <SecondaryButton
              onClick={() => setShowDeclineConfirmationModal(false)}
            >
              Não
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setShowDeclineConfirmationModal(false);
                setDecline(true);
              }}
            >
              Sim
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
