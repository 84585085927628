export const defaultSHQuestions = [
  { title: 'Corretora', identifier: 'broker' },
  { title: 'Patrimônio', identifier: 'pl' },
  { title: 'Idade', identifier: 'age' },
  { title: 'Ativos que investe', identifier: 'assets' },
  { title: 'Profissão', identifier: 'profession' },
  { title: 'Identificação da dor', identifier: 'trouble' },
  // { title: 'Já investe', identifier: 'alreadyInvest' },
];

export const profileOptions = [
  'Conservador',
  'Moderado',
  'Agressivo',
  'Trader',
];

export const knowlegmentLevel = [
  'Leigo total',
  'Conhecimento básico',
  'Acha que sabe',
  'Conhecimento moderado',
  'Conhecimento avançado',
];
