import { useState } from 'react';
import { Flex, Text, useMediaQuery } from '@chakra-ui/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { toast } from 'react-toastify';
import parser from 'html-react-parser';
import { SecondaryButton } from 'components/elements/buttons/SecondaryButton';
import { TitleWithSideLine } from 'components/elements/texts';
import { formatCalendaryDate } from 'utils/formatDate';

interface NotesProps {
  previousNotes: any[];
  clientId: string;
  note: string;
  onlyVizMode: boolean;

  setNoteIsSaved(arg: boolean): void;

  setHaveNewNote(arg: boolean): void;

  setNote(arg: string): void;
}

export const Notes: React.FC<NotesProps> = ({
  previousNotes,
  clientId,
  setNoteIsSaved,
  setHaveNewNote,
  note,
  setNote,
  onlyVizMode,
}) => {
  const [previousNote, setPreviousNote] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 30em)');

  const modules = {
    toolbar: [
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };

  const handleSaveContent = async () => {
    if (note === previousNotes[0]?.notes || note === previousNote) return;

    const data = {
      notes: note,
    };

    try {
      await hubApiUser.saveClientNotes(clientId, data);

      setPreviousNote(note);

      previousNotes.unshift({ ...data, updated_at: new Date().toISOString() });
      setNoteIsSaved(true);

      toast.dark('Anotação salva!');
    } catch (error) {
      toast.dark(
        'Não foi possível salvar sua anotação, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between">
        <ReactQuill
          modules={modules}
          theme="snow"
          placeholder=""
          onChange={content => {
            setNote(content);
            setHaveNewNote(true);
          }}
          value={note || ''}
          className={isMobile ? 'myClientsPanelMobile' : 'myClientsPanel'}
        />

        <Flex flexDirection="column" w="38%">
          <TitleWithSideLine>Anotações Anteriores</TitleWithSideLine>
          {previousNotes.length > 0 && (
            <Flex
              flexDirection="column"
              mt={4}
              h="calc(100vh - 292px)"
              overflowY="auto"
            >
              {previousNotes.map(previousNote => (
                <Flex
                  key={previousNote.id}
                  gap={1}
                  flexDirection="column"
                  alignItems="center"
                  mb={6}
                >
                  <Flex
                    backgroundColor="background.700"
                    borderRadius={8}
                    w="fit-content"
                    px={4}
                    py={1}
                  >
                    <Text color="gray.500" fontSize="xs">{`${
                      previousNote.updated_at &&
                      formatCalendaryDate(previousNote.updated_at)
                    }`}</Text>
                  </Flex>

                  <Flex gap={2}>
                    <Text wordBreak="break-all">
                      {!previousNote.typeOrder &&
                        previousNote.notes &&
                        parser(previousNote.notes)}
                      {previousNote.typeOrder && parser(previousNote.msg)}
                      {previousNote.hasOwnProperty('clientSatisfaction') &&
                        previousNote.msg.trim() && (
                          <Text fontStyle="italic">
                            {parser(previousNote.msg)} - (Disclaimer de reunião)
                          </Text>
                        )}
                      {previousNote.hasOwnProperty('clientSatisfaction') &&
                        !previousNote.msg.trim() && (
                          <Text fontStyle="italic">
                            {previousNote.title} - (Disclaimer de reunião)
                          </Text>
                        )}
                    </Text>
                    {previousNote.typeOrder && (
                      <Text fontStyle="italic">(CRM - IP)</Text>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
          {previousNotes.length === 0 && (
            <Flex h="100%" justifyContent="center" alignItems="center">
              <Text fontStyle="italic">Nenhuma anotação</Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      {!onlyVizMode && (
        <SecondaryButton
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          w="60%"
          disabled={
            note === previousNotes[0]?.notes ||
            note === '' ||
            note === previousNote
          }
          title={
            note === previousNotes[0]?.notes
              ? 'Não há alterações para salvar'
              : undefined
          }
          onClick={() => handleSaveContent()}
        >
          Salvar
        </SecondaryButton>
      )}
    </Flex>
  );
};
