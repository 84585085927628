import { Flex, Text } from '@chakra-ui/react';

import { TableData, TableRow } from 'components/elements/table';
import { IEmployees } from 'pages/manager/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface IOrdersRowPendencies {
  employee: IEmployees;
}

export const OrdersRowPendencies: React.FC<IOrdersRowPendencies> = ({
  employee,
}) => {
  const total =
    employee.fromType +
    employee.birthday +
    employee.inative +
    employee.noticesManager +
    employee.nps +
    employee.personalizedMeeting +
    employee.periodicMeeting +
    employee.balance;

  return (
    <TableRow>
      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        <Flex align="center" justify="center">
          <Text maxW="186px" isTruncated>
            {formatNameToCaptalize(employee.name)}
          </Text>
        </Flex>
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.frequencyContact ? `${employee.frequencyContact}%` : '-'}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.fromType || 0}
      </TableData>
      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.birthday || 0}
      </TableData>
      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.balance || 0}
      </TableData>
      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.nps || 0}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.inative || 0}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {employee.personalizedMeeting + employee.periodicMeeting || 0}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {total || 0}
      </TableData>
    </TableRow>
  );
};
