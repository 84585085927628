import { Flex } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { SkeletonBase } from 'componentsV2/elements/skeleton/base';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const MeetingsPoolSkekeletonLoading = (): JSX.Element => {
  return (
    <PageWrapper alignItems="center" paddingTop="2rem">
      <Flex gap={2} w="95vw" mt="38px">
        {Array.from({ length: 6 }).map(() => (
          <SkeletonBase
            minH="700px"
            maxH="700px"
            w="100%"
            borderRadius={5}
            p={3}
          />
        ))}
      </Flex>
    </PageWrapper>
  );
};
