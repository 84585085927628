import { Flex, Text } from '@chakra-ui/react';
import { FcOk } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { formatNameToCaptalize } from 'utils/captalizeNames';

type Employee = {
  id: string;
  name: string;
  email: string;
  responsibility: string;
};

interface EmployeeModalCardProps {
  employee: Employee;
  selectedEmployees: string[];
  handleUnselectEmployee: (id: string) => void;
  handleSelectEmployee: (id: string) => void;
  eventSection?: boolean;
}

export const EmployeeModalCard: React.FC<EmployeeModalCardProps> = ({
  employee,
  selectedEmployees,
  handleUnselectEmployee,
  handleSelectEmployee,
  eventSection,
}) => {
  return (
    <Flex
      key={employee.id}
      justify="space-between"
      flexDirection="column"
      px={2}
      py={1}
      borderRadius={3}
      bg="white"
    >
      <Flex justify="space-between" w="100%">
        <Text color="rgba(70, 70, 70, 1)">
          {formatNameToCaptalize(employee.name)}
        </Text>

        <Flex alignSelf="center">
          {selectedEmployees?.some(id => id === employee.id) ? (
            <FcOk
              size={20}
              title="Colaborador marcado, clique para desmarcar"
              style={{ cursor: 'pointer' }}
              onClick={() => handleUnselectEmployee(employee.id)}
            />
          ) : (
            <IoIosAddCircleOutline
              size={20}
              color="rgba(70, 70, 70, 1)"
              style={{ cursor: 'pointer' }}
              title="Marcar colaborador para compartilhar"
              onClick={() => {
                if (eventSection) {
                  toast.dark(
                    'Colaborador selecionado movido para o topo da lista'
                  );
                }
                handleSelectEmployee(employee.id);
              }}
            />
          )}
        </Flex>
      </Flex>

      <Text fontSize="sm" fontStyle="italic">
        Posição:
        <Text as="span" fontStyle="italic">
          {' '}
          {employee.responsibility &&
            formatNameToCaptalize(employee.responsibility)}
          {!employee.responsibility && 'Não definido'}
        </Text>
      </Text>
      <Text fontSize="sm" fontStyle="italic">
        E-mail:
        <Text as="span" fontStyle="italic">
          {' '}
          {employee.email}
        </Text>
      </Text>
    </Flex>
  );
};
