import { IMeeting } from 'pages/UserAgenda';
import { useHistory } from 'react-router-dom';
import { LeadUtils } from 'utilsV2/Lead';

type UseEmployeeAgendaTableContentFunctionsResponseType = {
  sendToMeeting: (meeting: IMeeting) => void;
  statusTranslated: Record<string, string>;
  meetingOrigin: Record<string, string>;
};

export const useEmployeeAgendaTableContentFunctions =
  (): UseEmployeeAgendaTableContentFunctionsResponseType => {
    const history = useHistory();

    const sendToMeeting = (meeting: IMeeting) => {
      history.push(
        `/v2/lead/${LeadUtils.transformLeadType(meeting.leadType)}/${
          meeting.leadId
        }`
      );
    };

    const statusTranslated: Record<string, string> = {
      suitability: 'R1',
      presentation: 'R2',
      callback: 'Retorno',
      allocation: 'Ag. Alocação',
      account: 'Ag. Abertura',
      alocado: 'Alocado',
    };

    const meetingOrigin: Record<string, string> = {
      publics: 'Mundo Invest',
      leadspublic: 'Mundo Invest',
      leadspurchased: 'Mundo Invest',
      online: 'Mundo Invest',
      leadsmanual: 'Lead Manual',
      employee: 'Lead Pessoal',
      leadspj: 'Lead PJ',
      leadstable: 'Lead Minerado',
      tables: 'Lead Minerado',
    };

    return {
      sendToMeeting,
      statusTranslated,
      meetingOrigin,
    };
  };
