import React, { FormEvent, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { IReduxState } from 'shared/interfaces';
import { errorHandler } from 'utils';
import {
  formatDateToRhSearch,
  checkIfMeetingIsNear as getDifferenceInMinutes,
} from 'utils/formatDate';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';
import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import {
  DefaultCardWrapper,
  PageWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

import { IAnnoucementManegement } from 'pages/RH/interfaces';
import { MainPageTitle } from 'components/elements/texts';
import history from 'services/history';
import Item from '../../components/elements/Item';
import AnnouncementForm from '../AnnouncementForm';

const AnnouncementManagement: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [formInfos, setFormInfos] = useState({
    message: '',
    title: '',
    date: new Date().toISOString(),
    employeeId: user.id,
    anonymous: true,
    type: 'HUB',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [announcements, setAnnouncements] = useState<IAnnoucementManegement[]>(
    []
  );

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      const searchDate = formatDateToRhSearch();
      listAnnouncements(searchDate, setAnnouncements);
    }
  }, [lastAuth]);

  const handleChangeTitleValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleChangeValue = (message: any) => {
    setFormInfos({ ...formInfos, message });
  };

  const handleDeleteAnnouncement = async (announcementId: string) => {
    try {
      await hubApiRH.deleteAnnouncement(announcementId);

      const result = announcements.filter(
        announcement => announcement.id !== announcementId
      );

      setAnnouncements(result);
      toast.dark('Comunicado removido com sucesso!');
    } catch (err) {
      errorHandler(err);
    }
  };

  const listAnnouncements = async (
    date: string,
    setData: React.Dispatch<React.SetStateAction<IAnnoucementManegement[]>>
  ) => {
    try {
      const response = await hubApiRH.listAnnouncements(date);

      if (!response.length) {
        return toast.dark('Não há comunicado(s)');
      }
      setData(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (formInfos.title.trim() === '') {
        setIsLoading(false);
        return toast.dark('Você deve preencher o campo de informações');
      }

      if (formInfos.message.trim() === '') {
        setIsLoading(false);
        return toast.dark('Você deve preencher o campo de informações');
      }

      const response = await hubApiRH.createAnnouncement(formInfos);
      setAnnouncements([...announcements, response]);
      onClose();
      toast.dark('Comunicado criado com sucesso');
      setFormInfos({ ...formInfos, message: '', title: '' });

      setIsLoading(false);
    } catch (err) {
      toast.dark('Erro ao criar comunicado');
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper
      alignItems="center"
      position="relative"
      padding="48px 0 0"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '0' }}
    >
      <BackPageButton />

      <MainPageTitle textAlign="center">
        Gerenciamento de comunicados - HUB
      </MainPageTitle>

      <Flex w="100%" flexDir="column" maxW={['90vw', '760px']}>
        <Flex alignItems="flex-end" justifyContent="flex-end">
          <PrimaryButton onClick={onOpen} fontSize="14px" maxW="250px">
            Criar novo comunicado
          </PrimaryButton>
        </Flex>
        <DefaultCardWrapper
          w="100%"
          mt="16px"
          p="0"
          borderRadius="5px"
          flexDir="column"
          boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
          background="inherit"
          minH="180px"
          pr={1}
          maxH="calc(100vh - 260px)"
          overflowY="auto"
        >
          {announcements.map(announcement => (
            <Item
              key={announcement.id}
              announcement={announcement}
              handleDeleteAnnouncement={handleDeleteAnnouncement}
              handleFormSubmit={handleFormSubmit}
              announcements={announcements}
              setAnnouncements={setAnnouncements}
            />
          ))}
        </DefaultCardWrapper>
      </Flex>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <AnnouncementForm
          isLoading={isLoading}
          handleFormSubmit={handleFormSubmit}
          formInfos={formInfos}
          handleChangeValue={handleChangeValue}
          handleChangeTitleValue={handleChangeTitleValue}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default AnnouncementManagement;
