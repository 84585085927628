import { Flex, Text } from '@chakra-ui/react';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { DropdownWithLabel } from 'components/elements/others/DropdownWithLabel';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { translateEventType } from 'pages/Captation/utils/translateEventType';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { formateDateAndTime } from 'utils/formatDate';
import { formatToPhone } from 'brazilian-values';
import { useParams } from 'react-router-dom';
import formatValue from 'utils/formatValue';
import { leadActions } from './data';

type ILeadActivities = {
  id: string;
  name: string;
  email: string;
  phone: string;
  eventType: string;
  created_at: string;
  plMi: number;
};

interface RouteParams {
  employeeId: string;
  name: string;
}

export const ShLeadsActivities: React.FC = () => {
  const { employeeId, name } = useParams<RouteParams>();

  const [leadAction, setLeadAction] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [leadsPLTotal, setLeadsPLTotal] = useState<number>(0);

  const {
    data: leadActivities,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery<ILeadActivities[]>({
    queryKey: ['leadsActivities'],
    queryFn: async () => {
      const response = await hubApiCaptation.getShLeadActivities({
        employeeId,
        date,
        eventType: leadAction,
      });
      return response;
    },
    enabled: leadAction !== '' && date !== '',
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (leadAction !== '' && date !== '') refetch();
  }, [leadAction, date]);

  useEffect(() => {
    if (leadActivities) {
      const plTotal = leadActivities.reduce((acc, item) => acc + item.plMi, 0);
      setLeadsPLTotal(plTotal);
    }
  }, [leadActivities]);

  return (
    <PageWrapper padding="36px 20px 0" flexDir="column" alignItems="center">
      <BackPageButton />

      <Flex width="100%" maxW="1080px" flexDir="column" gap={2}>
        <Flex alignItems="center" mb={4} gap={2} justifyContent="center">
          <MainPageTitle w="fit-content" mb={0}>
            Atividades Realizadas
          </MainPageTitle>
          <Text fontStyle="italic" mt={1.5}>
            - {formatNameToCaptalize(name)}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Flex gap={5} alignItems="center">
            <DropdownWithLabel
              label="Atividade"
              labelInLine
              onChange={e => setLeadAction(e.target.value)}
            >
              {leadActions.map(item => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </DropdownWithLabel>

            <DatePickerChackra
              handlePickDate={e => setDate(e.target.value)}
              value={date}
              label="Data"
              directionRow
            />
            {(isLoading || isRefetching) && (
              <LoadingSpinner h="100%" size="md" />
            )}
          </Flex>

          <Flex gap={4}>
            <Text fontSize="md">
              <Text color="#fff" as="span" fontWeight="bold" fontSize="lg">
                {(leadActivities && leadActivities.length) ?? 0}{' '}
              </Text>

              {leadActivities && leadActivities.length === 1 ? 'Lead' : 'Leads'}
            </Text>

            <Text fontSize="md">
              PL Total:{' '}
              <Text color="#fff" as="span" fontWeight="bold" fontSize="lg">
                {formatValue(leadsPLTotal)}
              </Text>{' '}
            </Text>
          </Flex>
        </Flex>

        <TableBase
          p="0"
          maxH="calc(100vh - 240px)"
          overflowY="auto"
          headData={['Nome do lead', 'Email', 'Telefone', 'Atividade', 'Data']}
        >
          {leadActivities &&
            leadActivities.map(item => (
              <TableRow key={item.id}>
                <TableData>{formatNameToCaptalize(item.name)}</TableData>
                <TableData>{item.email}</TableData>
                <TableData>
                  {formatToPhone(
                    item.phone.includes('55')
                      ? item.phone.substring(2)
                      : item.phone
                  )}
                </TableData>
                <TableData>{translateEventType(item.eventType)}</TableData>
                <TableData>{formateDateAndTime(item.created_at)}</TableData>
              </TableRow>
            ))}
        </TableBase>
        {(leadActivities === undefined || leadActivities.length === 0) && (
          <Flex justifyContent="center" mt={12}>
            <Text fontStyle="italic">Sem leads para esses filtros</Text>
          </Flex>
        )}
      </Flex>
    </PageWrapper>
  );
};
