import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export const ContentDividerTitle: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  return (
    <Text fontWeight="medium" color="gray.900" fontSize="sm" {...rest}>
      {children}
    </Text>
  );
};
