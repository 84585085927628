import { Flex, Grid, Image } from '@chakra-ui/react';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useAuth } from 'hooksV2/useAuth';
import { useZodHookFormResolver } from 'hooksV2/useZodHookFormResolver';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import Background from 'shared/assets/images/background.jpg';
import Logo from 'shared/assets/images/logo.png';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { ModalWrapper } from 'components/elements/wrappers';
import { SendRecoverPasswordEmailLogin } from './components/sendRecoverPasswordEmail';

const schema = z.object({
  email: z
    .string()
    .min(1, { message: 'O campo email é obrigatório' })
    .email({ message: 'O campo email deve conter um email válido' }),
  password: z.string().min(1, { message: 'O campo senha é obrigatório' }),
});

type FormFieldsType = z.infer<typeof schema>;

export const Login = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    filterValue: isOpenRecoverPasswordModalFilterValue,
    handleChangeFilterValue: handleChangeIsOpenRecoverPasswordModalFilterValue,
    handleDeleteParam: handleDeleteIsOpenRecoverPasswordModalFilterValue,
  } = useSimpleQueryFilter('isOpenRecoverPasswordModal');

  const { dispatchLogin } = useAuth({
    setIsLoading: () => setIsLoading(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFieldsType>({
    resolver: useZodHookFormResolver(schema),
  });

  const handleLogin: SubmitHandler<FormFieldsType> = async data => {
    try {
      setIsLoading(true);
      dispatchLogin(data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper heigth="100vh" paddingX="0rem">
      <Grid gridTemplateColumns="1fr minmax(40%, 45%)">
        <Flex
          backgroundColor="white"
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap={10}
        >
          <Image
            src={Logo}
            w={{ xl: '300px', '2xl': '440px' }}
            h={{ xl: '55px', '2xl': '75px' }}
          />

          <Flex w="50%" direction="column" gap={4}>
            <Input.Root w="100%">
              <Input.Label text="Email" />
              <Input.TextField
                placeholder="Email"
                h="40px"
                {...register('email')}
              />
              <Input.ErrorMessage message={errors.email?.message} />
            </Input.Root>

            <Input.Root w="100%">
              <Input.Label
                text="Senha"
                secondTextProps={{
                  text: 'Esquece sua senha?',
                  clickable: true,
                  onClick: () =>
                    handleChangeIsOpenRecoverPasswordModalFilterValue('true'),
                }}
              />
              <Input.TextField
                type="password"
                placeholder="Senha"
                h="40px"
                {...register('password')}
              />
              <Input.ErrorMessage message={errors.password?.message} />
            </Input.Root>

            <Button.Primary
              h="50px"
              onClick={handleSubmit(handleLogin)}
              isLoading={isLoading}
            >
              Entrar
            </Button.Primary>
          </Flex>
        </Flex>
        <Image
          src={Background}
          w="100%"
          maxHeight="100vh"
          maxW="100%"
          objectFit="cover"
        />
      </Grid>
      <ModalWrapper
        isOpen={isOpenRecoverPasswordModalFilterValue === 'true'}
        onClose={handleDeleteIsOpenRecoverPasswordModalFilterValue}
      >
        <SendRecoverPasswordEmailLogin
          handleDeleteIsOpenRecoverPasswordModalFilterValue={
            handleDeleteIsOpenRecoverPasswordModalFilterValue
          }
        />
      </ModalWrapper>
    </PageWrapper>
  );

  // return (
  //   <PageWrapper
  //     paddingTop="2.5rem"
  //     heigth="100vh"
  //     justifyContent="center"
  //     alignItems="center"
  //   >
  //     <Box.Glass w="650px" paddingY="1rem">
  //       <Flex flexDirection="column" gap={4} w="100%">
  //         <img
  //           style={{
  //             width: '160px',
  //             marginBottom: '20px',
  //             marginLeft: 'auto',
  //             marginRight: 'auto',
  //           }}
  //           src={mundoInvestLogo}
  //           alt="ewz logo"
  //         />
  //         <Flex flexDirection="column" gap={2}>
  //           <Text color="rgba(70, 70, 70, 1)" fontSize="xl">
  //             Bem vindo ao HUB
  //           </Text>
  //           <Text color="rgba(70, 70, 70, 1)">
  //             Faça login com o seu email e senha
  //           </Text>
  //         </Flex>
  //
  //         <Flex w="100%" flexDirection="column" gap={3}>
  //           <Input.Root w="100%">
  //             <Input.TextField
  //               placeholder="Email"
  //               h="40px"
  //               {...register('email')}
  //             />
  //             <Input.ErrorMessage message={errors.email?.message} />
  //           </Input.Root>
  //
  //           <Input.Root>
  //             <Input.TextField
  //               placeholder="Senha"
  //               h="40px"
  //               type="password"
  //               {...register('password')}
  //             />
  //             <Input.ErrorMessage message={errors.password?.message} />
  //           </Input.Root>
  //         </Flex>
  //
  //         <SendRecoverPasswordEmailLogin />
  //
  //         <Flex justifyContent="end">
  //           <Button.Primary
  //             onClick={handleSubmit(handleLogin)}
  //             isLoading={isLoading}
  //           >
  //             Entrar
  //           </Button.Primary>
  //         </Flex>
  //       </Flex>
  //     </Box.Glass>
  //   </PageWrapper>
  // );
};
