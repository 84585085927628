import { Dropdown } from 'components/elements/others';

interface IOptionsProps {
  value: string;
  label: string;
}

interface IProps {
  filterLeadType: (value: string) => void;
  optionsProps?: IOptionsProps[];
}

export const DropDownLeadType: React.FC<IProps> = ({
  filterLeadType,
  optionsProps,
}: IProps) => {
  const optionsDefault = [
    {
      value: '',
      label: 'Filtro de origem',
    },
    {
      value: 'lista',
      label: 'Leads Lista',
    },
    {
      value: 'employee',
      label: 'Leads Manuais',
    },
    {
      value: 'mined',
      label: 'Leads Minerados',
    },
    {
      value: 'pj',
      label: 'Leads PJ',
    },
    {
      value: 'publics',
      label: 'Leads Mundo Invest',
    },
  ];

  const options = optionsProps?.length !== 0 ? optionsProps : optionsDefault;

  const handleOnChange = (e: string) => {
    filterLeadType(e);
  };

  return (
    <Dropdown
      borderRadius="md"
      w={['130px', '180px']}
      h="7"
      fontSize="xs"
      onChange={e => handleOnChange(e.target.value)}
    >
      {options?.map(item => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Dropdown>
  );
};

DropDownLeadType.defaultProps = {
  optionsProps: [],
};
