import { Flex } from '@chakra-ui/react';

import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { TextEditor } from 'pages/Contents/components';

export const NewContent: React.FC = () => {
  return (
    <PageWrapper flexDir="column" p="5">
      <BackPageButton />

      <Flex flexDirection="column" w={'70vw'} mx="auto">
        <MainPageTitle textAlign="center" mt={8}>
          Novo conteúdo
        </MainPageTitle>
        <TextEditor />
      </Flex>
    </PageWrapper>
  );
};
