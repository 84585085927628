import { Text } from '@chakra-ui/react';

interface InlineKeyValueInfoProps {
  label: string;
  value: string;
}

export const InlineKeyValueInfo: React.FC<InlineKeyValueInfoProps> = ({
  label,
  value,
}) => {
  return (
    <Text
      lineHeight="shorter"
      fontSize="sm"
      fontWeight="medium"
      color="white.200"
    >
      <Text as="span" color="gray.800" mr="6px" fontWeight="normal">
        {label}:
      </Text>{' '}
      {value}
    </Text>
  );
};
