import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { MutableRefObject } from 'react';

export type HeadDataType = {
  columnName: string;
  widthPercentage: number;
};

type PropsType = {
  headData: HeadDataType[];
  tableRef?: MutableRefObject<HTMLDivElement | null>;
} & FlexProps;

export const TableBase: React.FC<PropsType> = ({
  children,
  headData,
  tableRef,
  ...rest
}): JSX.Element => {
  return (
    <Flex flexDirection="column" w="100%" {...rest} gap={2} ref={tableRef}>
      {' '}
      <Flex justifyContent="space-between">
        {headData.map((item, index) => (
          <Text
            key={index}
            color="rgba(70, 70, 70, 1)"
            fontWeight="bold"
            w={`${item.widthPercentage}%`}
            textAlign="center"
          >
            {item.columnName}
          </Text>
        ))}
      </Flex>
      <Flex flexDirection="column" gap={2}>
        {children}
      </Flex>
    </Flex>
  );
};
