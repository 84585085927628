import { useMutation } from 'react-query';
import hubApi from 'services/hubApi';

type UseTokenResponseType = {
  isTokenValid: (tokenId: string) => Promise<boolean>;
};

export const useToken = (): UseTokenResponseType => {
  const { mutateAsync: mutateIsTokenValid } = useMutation({
    mutationKey: ['isTokenValid'],
    mutationFn: async (tokenId: string) => {
      const response = await hubApi.isTokenValid(tokenId);
      return response;
    },
  });

  const isTokenValid = async (tokenId: string): Promise<boolean> => {
    const response = await mutateIsTokenValid(tokenId);
    if (response.revoked) return false;

    return true;
  };

  return {
    isTokenValid,
  };
};
