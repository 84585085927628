import { Flex, Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export const TitleWithSideLine: React.FC<TextProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex width="100%" gridGap="12px" alignItems="center">
      <Text
        width="100%"
        maxW="fit-content"
        color="rgba(70, 70, 70, 1)"
        fontWeight="semibold"
        {...rest}
      >
        {children}
      </Text>
      <Flex width="100%" height="1px" background="background.200" />
    </Flex>
  );
};
