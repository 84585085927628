export const advancedCustumersRvHeader = [
  'Nome Cliente',
  'Código BTG Cliente',
  'Segmento',
  'Financeiro',
  'Assessor',
  'Código BTG Assessor',
];

export const hubAreasOptions = [
  { title: 'Câmbio', value: 'EX' },
  { title: 'RH', value: 'RH' },
  { title: 'Inteligência patrimonial', value: 'IP' },
  { title: 'Marketing', value: 'MK' },
  { title: 'TI', value: 'TI' },
  { title: 'Renda variável - Estruturadas', value: 'StrategyRV' },
  { title: 'Renda variável-Especifica', value: 'SpecificRV' },
];
