import { useEffect, useState } from 'react';

import { BackPageButton } from 'components/elements/buttons';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'components/elements/wrappers';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { errorHandler } from 'utils';
import { Flex } from '@chakra-ui/react';

interface ICommissionValue {
  id: string;
  name: string;
  value: string;
}

export const CommissionByArea: React.FC = () => {
  const advancedOrdersHeader = ['Produto', 'Comissão BTG / EWZ'];
  const [commission, setCommission] = useState<ICommissionValue[]>([]);

  const listCommissionByBtg = async () => {
    try {
      const response = await hubApiFinancial.getComissionByBtg();

      setCommission(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    listCommissionByBtg();
  }, []);

  return (
    <PageWrapper flexDir="column" align="center" pt="9">
      <BackPageButton />

      <MainPageTitle align="center">Comissão por área</MainPageTitle>

      <TableBase
        headData={advancedOrdersHeader}
        overflowY="auto"
        maxH="calc(100vh - 206px)"
        width="100%"
        maxW="720px"
      >
        {commission.map((item, key) => (
          <TableRow
            key={`${item.id}-${key}`}
            transition="background 0.2s ease-in"
            _hover={{ background: '#f2f2f2' }}
          >
            <TableData>{item.name}</TableData>
            <TableData>{item.value}</TableData>
          </TableRow>
        ))}
      </TableBase>
    </PageWrapper>
  );
};
