import { Flex, Text } from '@chakra-ui/react';

interface LineInfoProps {
  title: string;
  quantity?: string;
  porcent?: string;
  // ml?: boolean;
}

export const LineInfo: React.FC<LineInfoProps> = ({
  title,
  quantity,
  porcent,
  // ml,
  ...rest
}) => {
  return (
    <Flex align="center" justify="space-between" {...rest}>
      <Text fontSize="xs" fontWeight="medium" color="white">
        {title}
      </Text>

      <Flex>
        {porcent && (
          <Text
            mr={4}
            width="100vw"
            maxW="64px"
            textAlign="right"
            fontSize="xs"
            color="gray.500"
          >
            ({porcent}%)
          </Text>
        )}
        <Text
          fontSize="xs"
          width="100vw"
          maxW="44px"
          textAlign="right"
          color="gray.500"
        >
          {quantity}
        </Text>
      </Flex>
    </Flex>
  );
};
