import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';
import 'react-quill/dist/quill.snow.css';
import {
  InfoTitle,
  InlineKeyValueInfo,
} from 'pages/MyClients/components/elements';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { InformalInfos, Reminder } from 'pages/MyClients/interfaces';
import { ModalWrapper } from 'components/elements/wrappers';
import { MeetingDisclaimerSection } from 'pages/MyClients/components/modules/clientPanels/Contact/MeetingDisclaimerSection';
import { MeetingTypeAndClientsatisfaction } from '../Modals/MeetingTypeAndClientsatisfaction';
import { AdditionalInfo } from '../Modals/AdditionalInfo';

interface MeetingSectionProps {
  basicData: {
    label: string;
    value: string;
  }[];
  taskTitle: string;
  reminder: Reminder;
  informalInfos: InformalInfos[];
  closeMeetingSection: () => void;
  signalToUpdate: boolean;
  setSignalToUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBackPageButton: React.Dispatch<React.SetStateAction<boolean>>;
  onlyVizMode: boolean;
}

// TODO: melhorar componentização e responsividade
export const MeetingSection: React.FC<MeetingSectionProps> = ({
  closeMeetingSection,
  basicData,
  taskTitle,
  reminder,
  informalInfos,
  signalToUpdate,
  setSignalToUpdate,
  setShowBackPageButton,
  onlyVizMode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [meetingDisclaimer, setMeetingDisclaimer] = useState(reminder.msg);
  const [commentedAreas, setCommentedAreas] = useState<string[]>(
    reminder.commentedAreas?.split(',') || []
  );
  const [areInfosAndDisclaimerSaved, setAreInfosAndDisclaimerSaved] =
    useState(false);

  setShowBackPageButton(false);

  const handleSaveDisclaimerAndInfos = async (e?: FormEvent) => {
    e?.preventDefault();

    const reminderUpdateData = {
      id: reminder.id,
      title: reminder.title,
      clientId: reminder.clientId,
      type: reminder.type,
      // meetingContactType,
      // clientSatisfaction,
      dataRetorno: reminder.dataRetorno,
      realized: false,
      commentedAreas:
        commentedAreas.length > 0 ? commentedAreas.join(', ') : null,
      msg: meetingDisclaimer === '' ? reminder.msg : meetingDisclaimer,
    };

    try {
      await hubApiUser.updateAnotationMessage(reminderUpdateData);

      setAreInfosAndDisclaimerSaved(true);
      setSignalToUpdate(!signalToUpdate);

      toast.dark('Informações e disclaimer registrados com sucesso');
    } catch (error) {
      toast.dark(
        'Não foi possível registrar as alterações, tente novamente em alguns minutos'
      );
    }
  };

  const addNewQuestionAndAnswer = () => {
    setShowNewQuestionModal(true);
  };

  return (
    <>
      <Flex
        flexDir={['column', 'row']}
        w="100%"
        gridGap={10}
        overflowY={'auto'}
      >
        <Flex w={['98%', '80%']} h="50%" gridGap="8" flexDir="column">
          <Flex flexDir="column" w="100%" gridGap={4}>
            <InfoTitle title={taskTitle} />

            <VStack spacing={3} align="baseline">
              {basicData.map(info => (
                <InlineKeyValueInfo label={info.label} value={info.value} />
              ))}
            </VStack>
          </Flex>

          <Flex mt={2} w="100%" py="2px" flexDir="column" gridGap={4}>
            <Flex gridGap={2}>
              <InfoTitle title="Informações adicionais" />
              <Box _hover={{ color: 'white' }} transition="400ms">
                {!onlyVizMode && (
                  <BiAddToQueue
                    size={28}
                    cursor="pointer"
                    onClick={addNewQuestionAndAnswer}
                  />
                )}
              </Box>
            </Flex>
            <Flex
              overflowY="auto"
              flexDir="column"
              maxH="calc(100vh - 550px)"
              gridGap={1.5}
              alignItems="center"
              className="boxClients"
            >
              {informalInfos.map((item: InformalInfos) => (
                <Flex
                  width={'95%'}
                  borderRadius="md"
                  maxHeight="60px"
                  minHeight="60px"
                  background="background.400"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Text w="90%" isTruncated fontSize="sm">
                      {item.question}
                    </Text>
                  </Flex>
                  <Text color="white.300" w="90%" isTruncated fontSize="sm">
                    {item.answer}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>

        <MeetingDisclaimerSection
          onlyVizMode={onlyVizMode}
          closeMeetingSection={closeMeetingSection}
          commentedAreas={commentedAreas}
          meetingDisclaimer={meetingDisclaimer}
          setCommentedAreas={setCommentedAreas}
          handleSaveDisclaimerAndInfos={handleSaveDisclaimerAndInfos}
          setMeetingDisclaimer={setMeetingDisclaimer}
          setShowModal={setShowModal}
        />
      </Flex>
      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(false)}>
        <MeetingTypeAndClientsatisfaction
          areInfosAndDisclaimerSaved={areInfosAndDisclaimerSaved}
          closeMeetingSection={closeMeetingSection}
          commentedAreas={commentedAreas}
          handleSaveDisclaimerAndInfos={handleSaveDisclaimerAndInfos}
          meetingDisclaimer={meetingDisclaimer}
          reminder={reminder}
          setShowModal={setShowModal}
          setSignalToUpdate={setSignalToUpdate}
          signalToUpdate={signalToUpdate}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={showNewQuestionModal}
        onClose={() => setShowNewQuestionModal(false)}
      >
        <AdditionalInfo
          reminder={reminder}
          setShowNewQuestionModal={setShowNewQuestionModal}
          setSignalToUpdate={setSignalToUpdate}
          signalToUpdate={signalToUpdate}
        />
      </ModalWrapper>
    </>
  );
};
