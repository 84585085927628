import { useQuery, UseQueryResult } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IGeneralWalletMibs } from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export const useGeneralWalletMibs = (): UseQueryResult<
  IGeneralWalletMibs[]
> => {
  return useQuery<IGeneralWalletMibs[]>(
    MIBS_QUERIES_KEYS.generalWalletMibs,
    async () => {
      const response = await hubApiCaptation.getGeneralWalletMibs();
      return response;
    },
    {
      onError: () =>
        toast.dark(
          'Erro ao mostrar quantidade de mibs das carteiras gerais. Tente novamente mais tarde.'
        ),
      refetchOnWindowFocus: true,
    }
  );
};
