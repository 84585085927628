import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { IReduxState } from 'shared/interfaces';

import { MainPageTitle } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import { PrimaryButton } from 'components/elements/buttons';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import {
  ContentSummaryCard,
  ContentTypeButton,
  AreasAndThemesDropdown,
} from 'pages/Contents/components';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { Content } from './interfaces';

export const Contents: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [contentType, setContentType] = useState('all');
  const [allContents, setAllContents] = useState<Content[]>([]);
  const [filteredContents, setFilteredContents] = useState<Content[]>([]);
  const [selectedSegment, setSelectedSegment] = useState('Todos');
  const [selectedTheme, setSelectedTheme] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getAllContents = async () => {
    setIsLoading(true);

    try {
      const response = await hubApiUser.getAllContents();

      setAllContents(response);
      setFilteredContents(response);

      setIsLoading(false);
    } catch (error) {
      toast.dark(
        'Não foi possível obter os processos, tente novamente em alguns minutos'
      );
      setIsLoading(false);
    }
  };

  const filterBySegmentAndTheme = () => {
    if (
      (selectedSegment === 'Todos' || selectedSegment === '') &&
      contentType === 'text'
    ) {
      setFilteredContents(
        allContents.filter((item: Content) => item.video === null)
      );
      return;
    }

    if (
      (selectedSegment === 'Todos' || selectedSegment === '') &&
      contentType === 'video'
    ) {
      setFilteredContents(
        allContents.filter((item: Content) => item.video !== null)
      );
      return;
    }

    if (
      (selectedSegment === 'Todos' || selectedSegment === '') &&
      contentType === 'all'
    ) {
      setFilteredContents(allContents);
      return;
    }

    if (selectedTheme === '' && contentType === 'text') {
      setFilteredContents(
        allContents.filter(
          (item: Content) =>
            item.area === selectedSegment && item.video === null
        )
      );
      return;
    }

    if (selectedTheme !== '' && contentType === 'text') {
      setFilteredContents(
        allContents.filter(
          (item: Content) =>
            item.area === selectedSegment &&
            item.theme === selectedTheme &&
            item.video === null
        )
      );
      return;
    }

    if (selectedTheme === '' && contentType === 'video') {
      setFilteredContents(
        allContents.filter(
          (item: Content) =>
            item.area === selectedSegment && item.video !== null
        )
      );
      return;
    }

    if (selectedTheme !== '' && contentType === 'video') {
      setFilteredContents(
        allContents.filter(
          (item: Content) =>
            item.area === selectedSegment &&
            item.theme === selectedTheme &&
            item.video !== null
        )
      );
      return;
    }

    if (selectedTheme === '' && contentType === 'all') {
      setFilteredContents(
        allContents.filter((item: Content) => item.area === selectedSegment)
      );
      return;
    }

    if (selectedTheme !== '' && contentType === 'all') {
      setFilteredContents(
        allContents.filter(
          (item: Content) =>
            item.area === selectedSegment && item.theme === selectedTheme
        )
      );
    }
  };

  useEffect(() => {
    getAllContents();
  }, []);

  useEffect(() => {
    if (contentType === 'text') {
      setFilteredContents(
        allContents.filter((item: Content) => item.video === null)
      );
    } else if (contentType === 'video') {
      setFilteredContents(
        allContents.filter((item: Content) => item.video !== null)
      );
    } else {
      setFilteredContents(allContents);
    }
  }, [contentType]);

  useEffect(() => {
    filterBySegmentAndTheme();
  }, [selectedSegment, selectedTheme, contentType]);

  return (
    <PageWrapper alignItems="center">
      <Flex flexDirection="column" maxW="840px">
        <MainPageTitle textAlign="center" mt={6}>
          Processos
        </MainPageTitle>

        <Flex flexDirection={{ base: 'column', md: 'row' }} gap={[3, 0]}>
          <AreasAndThemesDropdown
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            setSelectedTheme={setSelectedTheme}
          />

          <Link
            to={`/contents/my/${user.id}`}
            style={{ textDecoration: 'none', marginLeft: 'auto' }}
          >
            <PrimaryButton>Meus processos</PrimaryButton>
          </Link>
        </Flex>

        <Flex mt={[5, 10]} justifyContent="center">
          <ContentTypeButton
            contentType={contentType}
            setContentType={setContentType}
          />
        </Flex>

        {!isLoading && (
          <Flex
            h="calc(100vh - 340px)"
            maxH="700px"
            mt={2}
            flexDirection="column"
            gridGap={6}
            overflowY="auto"
          >
            {filteredContents.length > 0 ? (
              filteredContents.map((content: Content) => {
                const isAllowed =
                  content.permission &&
                  Object.entries(content.permission).some((item: any[]) => {
                    return verifyEmployeePermission({
                      user,
                      permissionCod: item[1],
                      areaCod: item[0],
                    });
                  });

                if (
                  isAllowed ||
                  !content.permission ||
                  content.permission.all
                ) {
                  return (
                    <ContentSummaryCard
                      id={content.id}
                      title={content.title}
                      subtitle={content.subtitle || 'Descrição do conteúdo'}
                      area={content.area}
                      theme={content.theme}
                      author={content.employee.name}
                      publicationDate={content.updated_at}
                      avatar={content.employee.avatar}
                      url={content.video}
                    />
                  );
                }
              })
            ) : (
              <Text
                w="100%"
                textAlign="center"
                mt={8}
                fontStyle="italic"
                fontWeight="light"
              >
                Sem processos criados
              </Text>
            )}
          </Flex>
        )}

        {isLoading && <LoadingSpinner height="calc(100vh - 320px)" />}
      </Flex>
    </PageWrapper>
  );
};
