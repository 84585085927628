import { Flex } from '@chakra-ui/react';
import { IoChevronBack } from 'react-icons/io5';
import history from 'services/history';
import { ITables } from 'pages/GenerationalVariables/interfaces';
import { IPoolList } from 'pages/DistributionOfMinedLeads/interfaces';
import { PrimaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { ModalWrapper } from 'components/elements/wrappers';
import { Dropdown } from 'components/elements/others';
import { DetailsModal } from '../modals/Details';

interface IHeaderProps {
  setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  tables: ITables[];
  setTableId: React.Dispatch<React.SetStateAction<string>>;
  showDetailsModal: boolean;
  allPoolLists: IPoolList[];
  handleGetAllEmployees: () => void;
}

export const Header: React.FC<IHeaderProps> = ({
  setShowDetailsModal,
  tables,
  setTableId,
  showDetailsModal,
  allPoolLists,
  handleGetAllEmployees,
}) => {
  const handleSelectedTableId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setTableId(e.target.value);
    } else {
      handleGetAllEmployees();
    }
  };

  return (
    <>
      <Flex
        position="absolute"
        left={{ base: '3%', md: '4%' }}
        top="39px"
        cursor="pointer"
        onClick={() => history.goBack()}
      >
        <IoChevronBack size={26} color="white" />
      </Flex>
      <Flex
        marginTop={['5', '4']}
        mb="3"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        gridGap={[8, 0]}
      >
        <MainPageTitle textAlign="center" mb={0} color="background.50">
          Distribuição de leads minerados
        </MainPageTitle>
        <Flex gap="3">
          <Dropdown
            w={['180px', '200px']}
            onChange={e => handleSelectedTableId(e)}
            defaultValue=""
          >
            <option value="" disabled>
              Selecione a mesa
            </option>
            {tables.map(table => (
              <option key={table.id} value={table.id}>
                {table.name}
              </option>
            ))}
          </Dropdown>
          <PrimaryButton onClick={() => setShowDetailsModal(true)}>
            Configurar automação
          </PrimaryButton>
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showDetailsModal}
        onClose={() => {
          setShowDetailsModal(false);
        }}
      >
        <DetailsModal
          allPoolLists={allPoolLists}
          onClose={() => {
            setShowDetailsModal(false);
          }}
        />
      </ModalWrapper>
    </>
  );
};
