type UseGetEventDateInformationResponseType = {
  handleGetMonth: (date: Date | string) => string;
  handleGetDay: (date: Date | string) => string;
};

export const useGetEventDateInformation =
  (): UseGetEventDateInformationResponseType => {
    const handleGetMonth = (date: Date | string): string => {
      return new Date(date)
        .toLocaleString('default', { month: 'short' })
        .toUpperCase();
    };

    const handleGetDay = (date: Date | string) => {
      return new Date(date)
        .toLocaleString('default', {
          day: 'numeric',
        })
        .toUpperCase()
        .padStart(2, '0');
    };

    return {
      handleGetDay,
      handleGetMonth,
    };
  };
