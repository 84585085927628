import { MenuButton } from './components/menuButton';
import { MenuItem } from './components/menuItem';
import { MenuList } from './components/menuList';
import { MenuRoot } from './components/menuRoot';

export const Menu = {
  Root: MenuRoot,
  Button: MenuButton,
  List: MenuList,
  Item: MenuItem,
};
