import styled from 'styled-components';

export const ContainerMarque = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background400} !important;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 78px;
  left: 0;

  a {
    display: flex;
    align-items: center;

    :hover {
      color: ${({ theme }) => theme.colors.primary500} !important;
    }
  }

  p,
  a {
    color: white;
    font-size: 11px;
    margin: 0 03px;
  }

  p {
    margin: 0 10px;
    font-size: 11px;
  }

  a > p {
    :hover {
      color: ${({ theme }) => theme.colors.primary500} !important;
    }
    font-size: 11px;
  }

  span {
    color: #bdbdbd;
  }

  .main {
    padding: 4px;
    background: ${({ theme }) => theme.colors.background600} !important;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .notice {
    padding: 0 30px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .overlay::before,
  .overlay::after {
    background: ${({ theme }) => theme.colors.background600} !important;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }
`;
