import { useEffect, useMemo, useState } from 'react';
import { IoChevronBack } from 'react-icons/io5';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MosaicCardsWrapper } from 'pages/Captation/components/elements/wrappers';
import { LeadDetails } from 'pages/Captation/components/modules/SH/LeadDetails';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import {
  LeadSHMosaicCard,
  ShLeadsTable,
  ShPoolMenu,
} from 'pages/Captation/components/modules/SH';
import { InfosUserWrapper } from 'pages/Captation/components/modules/mainPage/InfosUserWrapper';
import { useSHPoolLeads } from 'pages/Captation/hooks/useSHPoolLeads';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

type VisualizationOption = 'mosaic' | 'table';

export function LeadsMosaicSH(): JSX.Element {
  const [visualizationMode, setVisualizationMode] =
    useState<VisualizationOption>('mosaic');
  const [leadSearch] = useState('');
  const [filteredLeads, setFilteredLeads] = useState<LeadDetailsProps[]>([]);
  const [publicLeads, setPublicLeads] = useState<LeadDetailsProps[]>([]);
  const [loadingMinedLead, setLoadingMinedLead] = useState(false);
  const [cardSelected, setCardSelected] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedLeadData, setSelectedLeadData] = useState(
    [] as LeadDetailsProps[]
  );

  const [expiredLead, setExpiredLead] = useState({
    isSelected: false,
    leadId: '',
    thereIsTask: true,
  });

  const [selectedLead, setSelectedLead] = useState({
    isSelected: false,
    leadId: '',
    thereIsTask: true,
  });

  const { data: shPoolLeads, isLoading } = useSHPoolLeads();

  const handleGetExpiredLead = async () => {
    setLoadingMinedLead(true);
    try {
      const response = await hubApiCaptation.getExpiredLeads();
      if (!response || response.length === 0) {
        toast.dark('Sem leads disponíveis');
        setLoadingMinedLead(false);
        return;
      }

      setSelectedLeadData([response]);

      setExpiredLead({
        isSelected: true,
        leadId: response.id,
        thereIsTask: response.meetings.length > 0,
      });
    } catch (err) {
      toast.dark('Erro ao buscar leads');
    }
    setLoadingMinedLead(false);
  };

  const handleBackToAllLeads = (cardId?: string) => {
    if (cardId) {
      setCardSelected(cardId);
    }
    setSelectedLead({
      isSelected: false,
      leadId: '',
      thereIsTask: true,
    });

    setExpiredLead({
      isSelected: false,
      leadId: '',
      thereIsTask: true,
    });
  };

  const handleSelectedLead = (lead: LeadDetailsProps): void => {
    setSelectedLead({
      isSelected: true,
      leadId: lead.id,
      thereIsTask: lead.meetings.length > 0,
    });
  };

  const handleSearchLead = (searchValue: string) => {
    if (!publicLeads) return;

    if (searchValue.trim().length < 3) {
      toast.dark('Digite pelo menos três caracteres para a busca');
      return;
    }

    const leadFound = publicLeads.find(
      lead =>
        lead.phone[0]?.phone.trim().includes(searchValue.trim()) ||
        lead.name
          .trim()
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
    );

    if (leadFound) {
      setSelectedLead({
        isSelected: true,
        leadId: leadFound.id,
        thereIsTask: leadFound.meetings.length > 0,
      });

      setSelectedLeadData([leadFound]);
    } else {
      toast.dark('Não foi possível encontrar lead');
    }
  };

  const handleSortLead = useMemo(() => {
    const created: LeadDetailsProps[] = [];
    const returned: LeadDetailsProps[] = [];
    const callbacks: LeadDetailsProps[] = [];
    for (let i = 0; i < filteredLeads.length; i++) {
      if (filteredLeads[i].meetings.length > 0) {
        if (
          filteredLeads[i].meetings[0].status.toLocaleLowerCase() === 'created'
        ) {
          created.push(filteredLeads[i]);
        }

        if (
          filteredLeads[i].meetings[0].status.toLocaleLowerCase() ===
            'callback' ||
          filteredLeads[i].meetings[0].status.toLocaleLowerCase() ===
            'callbacksh'
        ) {
          callbacks.push(filteredLeads[i]);
        }
      } else if (filteredLeads[i].status === 'returned') {
        returned.push(filteredLeads[i]);
      }
    }

    const newArr: LeadDetailsProps[] = [...created, ...returned, ...callbacks];

    const result = filteredLeads.filter(
      item => newArr.findIndex(arr => arr.id === item.id) === -1
    );
    return [...newArr, ...result];
  }, [filteredLeads]);

  const handleGetInfosOfLeadAndMeeting = () => {
    let meetingReturned = 0;
    let meetingsToConfirm = 0;
    let RNA = 0;
    let tasksFinished = 0;

    for (let i = 0; i < publicLeads.length; i++) {
      if (publicLeads[i].status.toLocaleLowerCase() === 'returned') {
        RNA++;
      }

      for (let k = 0; k < publicLeads[i].actions.length; k++) {
        if (
          publicLeads[i].actions[k].status.toLocaleLowerCase() ===
            'realizada' ||
          publicLeads[i].actions[k].status.toLocaleLowerCase() === 'não atendeu'
        ) {
          tasksFinished++;
        }
      }

      for (let j = 0; j < publicLeads[i].meetings.length; j++) {
        if (
          publicLeads[i].meetings[j].status.toLocaleLowerCase() === 'created'
        ) {
          meetingsToConfirm++;
        }

        if (
          publicLeads[i].meetings[j].status.toLocaleLowerCase() === 'callback'
        ) {
          meetingReturned++;
        }
      }
    }

    return {
      tasksFinished,
      RNA,
      meetingsToConfirm,
      meetingReturned,
    };
  };

  useEffect(() => {
    setSelectedLeadData(
      publicLeads.filter(lead => lead.id === selectedLead.leadId)
    );
    document.querySelector('.scrollContainer')?.scrollTo(0, scrollPosition);
  }, [selectedLead, publicLeads]);

  useEffect(() => {
    setFilteredLeads(
      publicLeads.filter(lead =>
        lead.name.toLowerCase().includes(leadSearch.toLowerCase())
      )
    );
  }, [leadSearch]);

  useEffect(() => {
    if (shPoolLeads) {
      setPublicLeads(shPoolLeads);
      setFilteredLeads(shPoolLeads);
    }
  }, [shPoolLeads]);

  return (
    <PageWrapper alignItems="center">
      {(expiredLead.isSelected || selectedLead.isSelected) && (
        <>
          <Flex
            onClick={() => handleBackToAllLeads(selectedLeadData[0].id)}
            position="absolute"
            left={{ base: '3%', md: '4%' }}
            top="20px"
            cursor="pointer"
          >
            <IoChevronBack size={26} color="white" />
          </Flex>

          <LeadDetails
            selectedLead={selectedLead}
            setSelectedLeadData={setSelectedLeadData}
            setExpiredLead={setExpiredLead}
            setSelectedLead={setSelectedLead}
            lead={selectedLeadData[0]}
            expiredLead={expiredLead.isSelected}
            handleBackToAllLeads={handleBackToAllLeads}
            publicLeads={publicLeads}
            setPublicLeads={setPublicLeads}
            filteredLeads={filteredLeads}
            setFilteredLeads={setFilteredLeads}
            handleGetExpiredLead={handleGetExpiredLead}
          />
        </>
      )}

      {!expiredLead.isSelected && !selectedLead.isSelected && (
        <Flex w="100%" maxW="1180px" flexDir="column" mt={[2, 6]}>
          <MainPageTitle
            my="2"
            justifyContent="center"
            align="center"
            color="gray.400"
          >
            Seus Leads
          </MainPageTitle>

          <Flex
            mt={['0', '2']}
            flexDir={['column', 'column', 'row']}
            gridGap={[2, 3]}
            align="center"
            justify="center"
            height={['200px', 'auto']}
          >
            <InfosUserWrapper>
              <Text> Tarefas realizadas:</Text>
              <Text> {handleGetInfosOfLeadAndMeeting().tasksFinished}</Text>
            </InfosUserWrapper>

            <InfosUserWrapper>
              <Text> Retornos:</Text>
              <Text> {handleGetInfosOfLeadAndMeeting().meetingReturned}</Text>
            </InfosUserWrapper>

            <InfosUserWrapper>
              <Text> Reuniões a confirmar:</Text>
              <Text>{handleGetInfosOfLeadAndMeeting().meetingsToConfirm}</Text>
            </InfosUserWrapper>

            <InfosUserWrapper>
              <Text> RNA:</Text>
              <Text> {handleGetInfosOfLeadAndMeeting().RNA}</Text>
            </InfosUserWrapper>
          </Flex>

          <ShPoolMenu
            handleSearchLead={handleSearchLead}
            publicLeads={publicLeads}
            setFilteredLeads={setFilteredLeads}
            setVisualizationMode={setVisualizationMode}
            handleGetExpiredLead={handleGetExpiredLead}
            loadingMinedLead={loadingMinedLead}
          />

          {isLoading && <LoadingSpinner />}

          {!isLoading && publicLeads.length === 0 && (
            <Text textAlign="center" mt={40} fontSize="lg">
              Sem resultados
            </Text>
          )}

          {publicLeads.length > 0 && visualizationMode === 'mosaic' && (
            <MosaicCardsWrapper
              mt={2}
              gridGap="2"
              overflowY="auto"
              className="scrollContainer"
              maxH={['calc(100vh - 600px)', 'calc(100vh - 296px)']}
            >
              {handleSortLead?.map(lead => (
                <LeadSHMosaicCard
                  key={lead.id}
                  setSelectedLead={setSelectedLead}
                  lead={lead}
                  Icon={FaRegCalendarAlt}
                  cardSelected={cardSelected}
                  setScrollPosition={setScrollPosition}
                />
              ))}
            </MosaicCardsWrapper>
          )}

          {publicLeads.length > 0 && visualizationMode === 'table' && (
            <ShLeadsTable
              publicLeads={filteredLeads}
              handleSelectedLead={handleSelectedLead}
            />
          )}
        </Flex>
      )}
    </PageWrapper>
  );
}
