import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface ComponentProps {
  message: string;
}

export const NoScheduledMeetingsMessage: React.FC<ComponentProps> = ({
  message,
}) => {
  return (
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
      <Text fontSize="sm" fontStyle="italic" color="gray.800">
        {message}
      </Text>
    </Flex>
  );
};
