import {
  Calendar,
  Chats,
  Check,
  CurrencyCircleDollar,
  GridFour,
  IconProps,
  List,
  MagnifyingGlass,
  Money,
  PencilSimple,
  Phone,
  PhoneX,
  Plus,
  SignOut,
  Trash,
  TrendDown,
  UserCircle,
  UserFocus,
  UserPlus,
  Warning,
  X,
} from 'phosphor-react';

export const Icons = {
  PHONE: ({
    color = '#1D63DD',
    size = 24,
    ...props
  }: IconProps): JSX.Element => <Phone color={color} size={size} {...props} />,
  SEARCH: ({ color = '#fff', size = 24, ...props }: IconProps): JSX.Element => (
    <MagnifyingGlass color={color} size={size} {...props} />
  ),
  LIST: ({
    color = '#000',
    size = 24,
    cursor = 'pointer',
    ...props
  }: IconProps): JSX.Element => (
    <List color={color} size={size} cursor={cursor} {...props} />
  ),
  GRID: ({
    color = '#000',
    size = 24,
    cursor = 'pointer',
    ...props
  }: IconProps): JSX.Element => (
    <GridFour color={color} size={size} cursor={cursor} {...props} />
  ),
  CALENDAR: ({
    color = '#1D63DD',
    size = 24,
    ...props
  }: IconProps): JSX.Element => (
    <Calendar size={size} color={color} {...props} />
  ),
  PHONE_X: ({
    color = '#1D63DD',
    size = 24,
    ...props
  }: IconProps): JSX.Element => <PhoneX size={size} color={color} {...props} />,
  USER_CIRCLE: ({
    color = '#1D63DD',
    size = 24,
    ...props
  }: IconProps): JSX.Element => (
    <UserCircle size={size} color={color} {...props} />
  ),
  USER_PLUS: ({
    color = '#000',
    size = 24,
    ...props
  }: IconProps): JSX.Element => (
    <UserPlus size={size} color={color} {...props} />
  ),
  X: ({ color = '#000', size = 20, ...props }: IconProps): JSX.Element => (
    <X size={size} color={color} cursor="pointer" {...props} />
  ),
  CIRCLE_DOLLAR: ({
    color = '#000',
    size = 26,
    ...props
  }: IconProps): JSX.Element => (
    <CurrencyCircleDollar size={size} color={color} {...props} />
  ),
  CHATS: ({ color = '#000', size = 26, ...props }: IconProps): JSX.Element => (
    <Chats size={size} color={color} {...props} />
  ),
  WARN: ({ color = '#000', size = 26, ...props }: IconProps): JSX.Element => (
    <Warning size={size} color={color} {...props} />
  ),
  MONEY: ({ color = '#000', size = 26, ...props }: IconProps): JSX.Element => (
    <Money size={size} color={color} {...props} />
  ),
  EXIT: ({ color = '#000', size = 26, ...props }: IconProps): JSX.Element => (
    <SignOut size={size} color={color} {...props} />
  ),
  USER_FOCUS: ({
    color = '#000',
    size = 26,
    ...props
  }: IconProps): JSX.Element => (
    <UserFocus size={size} color={color} {...props} />
  ),
  CHART_DOWN: ({
    color = '#000',
    size = 26,
    ...props
  }: IconProps): JSX.Element => (
    <TrendDown size={size} color={color} {...props} />
  ),
  TRASH: ({ color = '#000', size = 16, ...props }: IconProps): JSX.Element => (
    <Trash size={size} color={color} cursor="pointer" {...props} />
  ),
  PLUS: ({ color = '#000', size = 20, ...props }: IconProps): JSX.Element => (
    <Plus size={size} color={color} cursor="pointer" {...props} />
  ),
  PENCIL: ({ color = '#000', size = 20, ...props }: IconProps): JSX.Element => (
    <PencilSimple size={size} color={color} {...props} />
  ),
  CHECK: ({ color = '#000', size = 20, ...props }: IconProps): JSX.Element => (
    <Check size={size} color={color} {...props} />
  ),
};
