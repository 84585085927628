import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';

import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { BackPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import {
  EwzCaptation,
  MundoInvestCaptation,
} from 'pages/Captation/components/modules/AAI';

interface RouteParams {
  origin: string;
}

interface Offices {
  id: string;
  city: string;
}

interface LoggedUser {
  officeId: string;
  tableId: string;
}

export const ManagementAAI: React.FC = () => {
  const user: LoggedUser = useSelector(
    (state: RootStateOrAny) => state.user.profile
  );
  const { origin } = useParams<RouteParams>();
  const [offices, setOffices] = useState<Offices[]>([] as Offices[]);

  useEffect(() => {
    getOffices();
  }, []);

  const getOffices = async () => {
    const data = await hubApiOfficeTables.listFilial();
    setOffices(data);
  };

  return (
    <>
      {origin.toLowerCase() === 'mi' && (
        <MundoInvestCaptation offices={offices} />
      )}

      {origin.toLowerCase() === 'ewz' && (
        <EwzCaptation offices={offices} initialOfficeId={user.officeId} />
      )}
    </>
  );
};
