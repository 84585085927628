import { KanbanColumnBase } from 'components/modules/kanbanBases';

import React from 'react';
import { KanbanColumnProps } from 'shared/interfaces';
import Task from '../Task';

interface ComponentProps {
  columnId: string;
  column: KanbanColumnProps;
}

const Column: React.FC<ComponentProps> = ({ column, columnId }) => {
  return (
    <KanbanColumnBase column={column} columnId={columnId}>
      {column.tasks.map((task, index) => (
        <Task key={task.id} index={index} task={task} />
      ))}
    </KanbanColumnBase>
  );
};

export default Column;
