import { Flex } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import { useDeleteClientReminder } from 'hooksV2/useDeleteClientReminder';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useMutation } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { ClientRemindersType } from 'sharedV2/types/client';

type PropsType = {
  reminder: ClientRemindersType;
  isOpenReminderDetailsModalFilter: UseSimpleFilterResponseType;
};

export const ReminderDetailsModal: React.FC<PropsType> = ({
  reminder,
  isOpenReminderDetailsModalFilter,
}): JSX.Element => {
  const { mutateAsync: updateReminder, isLoading: isLoadingUpdateReminder } =
    useMutation({
      mutationKey: ['updateReminder'],
      mutationFn: async (data: ClientRemindersType) => {
        const response = await hubApiClients.updateReminder(data);

        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientDetails',
          reminder.btgCode,
        ]);
      },
    });

  const { mutateAsync: deleteReminder, isLoading: isLoadingDeleteReminder } =
    useDeleteClientReminder({
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'clientDetails',
          reminder.btgCode,
        ]);

        isOpenReminderDetailsModalFilter.handleDeleteParam();
      },
    });

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title={reminder.title} />
        <Modal.CloseIcon
          onClick={() => isOpenReminderDetailsModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={2}>
        <Input.Root>
          <Input.Label text="Descrição" />
          <Input.EditableTextField
            value={reminder.description}
            isLoading={isLoadingUpdateReminder}
            onConfirmEdit={newDescription =>
              updateReminder({
                ...reminder,
                description: newDescription,
              })
            }
          />
        </Input.Root>

        <Input.Root>
          <Input.Label text="Data" />
          <Input.Date
            value={reminder.date}
            onChange={event =>
              updateReminder({
                ...reminder,
                date: event.target.value,
              })
            }
          />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Concluir"
          isLoading={isLoadingDeleteReminder}
          onClick={() => deleteReminder(reminder.id)}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
