import React from 'react';
import { MdSpeakerPhone } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Box, Flex, FlexProps, Text, Tooltip } from '@chakra-ui/react';

import {
  checkOneDayLate,
  formateDateAndTimeNoTimezone,
} from 'utils/formatDate';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { formatToCapitalized } from 'brazilian-values';
import { getMIPatrimony } from 'pages/Captation/components/modules/utils';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingTypeIcon } from '../../others';
import { MosaicCardWrapper } from '../../wrappers';

interface ComponentProps extends FlexProps {
  lead: IBaseMeeting;
}

export const MeetingMosaicCard: React.FC<ComponentProps> = ({
  lead,
  ...rest
}) => {
  const getPatrimony = () => {
    if (lead.rdpipevalue) {
      return `R$ ${lead.rdpipevalue}`;
    }

    if (lead.lctpipevalue) {
      return `R$ ${lead.lctpipevalue}`;
    }

    if (lead.lpjpipevalue) {
      return `R$ ${lead.lpjpipevalue}`;
    }

    if (lead.lepipevalue) {
      return `R$ ${lead.lepipevalue}`;
    }

    if (getMIPatrimony({ lead }) !== 'Não informado') {
      return getMIPatrimony({ lead });
    }

    return 'Não informado';
  };

  return (
    <MosaicCardWrapper
      {...rest}
      borderColor={checkOneDayLate(lead) ? 'red.400' : 'background.400'}
      _hover={
        checkOneDayLate(lead)
          ? { borderColor: 'red.500' }
          : { borderColor: 'primary.900' }
      }
      minH="98px"
    >
      <Flex justify="space-between">
        <Text fontWeight="semibold" fontSize="smaller" mb="2" isTruncated>
          {(lead.name && formatToCapitalized(lead.name)) || '-'}
        </Text>
        <Flex gridGap={1}>
          {lead.confirm === false &&
            (lead.leadType === 'publics' ||
              lead.leadType === LeadTypeEnum.MUNDO_INVEST) &&
            lead.complement !==
              'Lead abriu conta no Mundo Invest pelo seu link.' && (
              <Tooltip
                label="Lead sem feedBack"
                cursor="pointer"
                color="blackAlpha.900"
                placement="right"
              >
                <Box bg="red.300" borderRadius="50%" h="10px" minW="10px" />
              </Tooltip>
            )}
          {(lead.leadType === 'publics' ||
            lead.leadType === LeadTypeEnum.MUNDO_INVEST) && (
            <Tooltip
              label="Lead Mundo Invest"
              cursor="pointer"
              color="blackAlpha.900"
              placement="right"
            >
              <Box bg="blue.300" borderRadius="50%" h="10px" minW="10px" />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <Text
        fontSize="smaller"
        flexDir="row"
        color="gray.300"
        fontWeight="medium"
      >
        <Text as="span" color="gray.900" fontWeight="light">
          {lead.return ? 'Retorno' : 'Reunião'}:{' '}
        </Text>
        {formateDateAndTimeNoTimezone(lead.date)}
      </Text>
      <Text
        fontSize="smaller"
        flexDir="row"
        color="gray.300"
        fontWeight="medium"
      >
        <Text as="span" color="gray.900" fontWeight="light">
          PL:{' '}
        </Text>
        {getPatrimony()}
      </Text>
      <Flex width="100%" justifyContent="flex-end">
        {lead.icon === 'late' && (
          <MeetingTypeIcon
            p="0"
            m="0"
            position="inherit"
            alignSelf="flex-end"
            mt="3"
            mb="1.5"
            Icon={FaRegCalendarAlt}
            value={0}
            isMeeting
          />
        )}

        {lead.icon === 'progress' && (
          <MeetingTypeIcon
            p="0"
            m="0"
            position="inherit"
            alignSelf="flex-end"
            mt="3"
            mb="1.5"
            Icon={MdSpeakerPhone}
            value={0}
          />
        )}
      </Flex>
    </MosaicCardWrapper>
  );
};
