import { BiBuildingHouse, BiCar, BiDonateHeart } from 'react-icons/bi';
import { BsBuilding, BsHouse, BsHouseDoor } from 'react-icons/bs';
import { VscLaw } from 'react-icons/vsc';
import { IoIosAirplane } from 'react-icons/io';
import { AiOutlineSafety } from 'react-icons/ai';

import { RiHealthBookLine, RiShip2Line, RiTruckLine } from 'react-icons/ri';
import { MdBusiness, MdBusinessCenter, MdGavel } from 'react-icons/md';

import {
  BasicForm,
  BusinessForm,
  CivilLiabilityForm,
  CondominiumForm,
  CorporateForm,
  HealthForm,
  MachineryForm,
  NauticalForm,
  RealEstateAndFleetForm,
  ResidencialForm,
  TravelForm,
} from './components/modules/forms';

export const ipSegments = {
  life: 'vida',
  health: 'saude',
  cred: 'imobiliario',
  agro: 'agro',
  corp: 'corporate',
  fleet: 'frota',
  alt: 'seguros alternativos',
};

export const ipSegmentsNamed = {
  vida: 'Vida',
  saude: 'Saúde',
  imobiliario: 'Crédito',
  agro: 'Agro',
  corporate: 'Corporativo',
  frota: 'Frota',
  'seguros alternativos': 'Seguros alternativos',
};

export const ipProducts = [
  {
    title: 'IP',
    segment: ipSegments.life,
    modal: BasicForm,
    icon: BiDonateHeart,
    description:
      'Nosso núcleo estuda toda estrutura patrimonial do investidor, mostrando as melhores formas para proteger a si mesmo e seus sucessores. Estamos com as maiores seguradoras do país, bem como para grandes patrimônios oferecemos também um auxílio jurídico de alto padrão.',
  },
  {
    title: 'Saúde',
    segment: ipSegments.health,
    modal: HealthForm,
    icon: RiHealthBookLine,
    description:
      'Sabemos que o investimento em plano de saúde é caro, por isso nós buscamos os planos com melhores benefícios e com um preço mais atrativo. Trabalhamos com grandes operadoras de saúde para que o cliente sempre tenha o melhor à disposição.',
  },
  {
    title: 'Alavancagem Patrimonial',
    segment: ipSegments.cred,
    modal: RealEstateAndFleetForm,
    icon: BsBuilding,
    description:
      'Auxiliamos investidores a adquirirem imóveis por meio de financiamento imobiliário ou consórcio. Nós fazemos um atendimento personalizado para entender exatamente o tipo de aquisição e a melhor forma de ajuda-lo. ',
  },
  {
    title: 'Corporate',
    segment: ipSegments.corp,
    modal: CorporateForm,
    icon: MdBusiness,
    description:
      'A área Corporate é responsável por atender as necessidades das empresas como um todo, tanto para soluções de liquidez entendendo o melhor produto para o cliente de acordo com suas necessidades, garantias e prazos. Atuamos também na parte de Cash Management onde atuamos no auxilio em conta pj, folha de pagamento, fluxo de caixa entre outros.',
  },
  /* {
    title: 'Agro',
    segment: ipSegments.agro,
    modal: AgroForm,
    icon: RiPlantLine,
    description:
      'A Mesa Agro EWZ Capital atende os clientes com necessidades no segmento trazendo o melhor atendimento.Trabalhamos diretamente com revendas de insumos, produtores rurais, pecuaristas e clientes que tenham interesse em ganhar dinheiro com commodities sem fazer parte dessa área. Abordamos esses diferentes perfis com duas frentes de produtos, que seriam as linhas de créditos fornecidas pelo BTG Pactual e que dão margem de negociação, além do mercado de capitais com operações utilizando opções ou contratos futuros através de estratégias como Hedge para fazendeiros de soja e trava de alta para produtor de boi que acredita que o boi irá subir. Isso tudo sempre entendendo a demanda do cliente e participando junto ao mesmo conforme forem sendo efetivadas as operações.',
  }, */
];

export const altInsurance = [
  {
    title: 'Auto / Frota',
    segment: ipSegments.alt,
    subSegment: 'auto / frota',
    modal: RealEstateAndFleetForm,
    icon: BiCar,
    description:
      'Para clientes que querem adquirir automóveis com uma carta de crédito.',
  },
  {
    title: 'Residencial',
    segment: ipSegments.alt,
    subSegment: 'residencial',
    modal: ResidencialForm,
    icon: BsHouseDoor,
    description:
      'É um tipo de seguro compreensivo, pois oferece coberturas como: incêndio, queda de raios e explosão e fumaça. É destinado para a residências individuais, casas e apartamentos, habituais ou de veraneio. Adicionalmente são oferecidas coberturas de danos causados por alagamento, queimadas em zona rural, vendaval, impacto de veículos, queda de aeronaves, danos elétricos, responsabilidade civil familiar, perda ou pagamento de aluguel e desmoronamento. É comum oferecerem pacote de serviços como chaveiro, encanador/eletricista, reparo em máquina de lavar roupa, louça, geladeira, fogão, instalação do ventilador de teto, varal, prateleira e suporte para TV, limpeza na caixa d’água e calhas',
  },
  {
    title: 'Condomínio',
    segment: ipSegments.alt,
    subSegment: 'condomínio',
    modal: CondominiumForm,
    icon: BiBuildingHouse,
    description:
      'Seguro para condomínios verticais e horizontais, comerciais, mistos, de escritórios/consultórios e flats. É um seguro completo que protegem o condomínio, o síndico, e os colaboradores, além da contratação de serviços emergenciais gratuitos.\n*Importante: o seguro é considerado uma despesa ordinária e obrigatória.',
  },
  {
    title: 'Fiança Locatícia - Aluguel',
    segment: ipSegments.alt,
    subSegment: 'fiança locatícia (aluguel)',
    modal: BasicForm,
    icon: BsHouse,
    description:
      'Este seguro substitui o fiador e garante ao proprietário do imóvel o recebimento dos aluguéis e encargos vencidos e não pagos pelo inquilino. Ele normalmente cobre pagamento do aluguel / multa moratória, Condomínio e IPTU, Pinturas externas e internas, multa por rescisão contratual, água/luz, gás canalizado.',
  },
  {
    title: 'Empresarial',
    segment: ipSegments.alt,
    subSegment: 'empresarial',
    modal: BusinessForm,
    icon: MdBusinessCenter,
    description:
      'Forma de assegurar em casos de danos que ocorreram no estabelecimento comercial. Normalmente cobre incêndio, roubos, explosões, enchentes, entre outros. São diversas formas, sendo que uma das principais coberturas são de lucros cessantes, perda/pagamento de aluguel e patrimonial.',
  },
  {
    title: 'Responsabilidade Civil',
    segment: ipSegments.alt,
    subSegment: 'responsabilidade civil',
    modal: CivilLiabilityForm,
    icon: VscLaw,
    description:
      'É uma forma de garantir ao segurado, que é responsável por danos causados a terceiros, o reembolso e/ou indenização que foi condenado a título de reparação. Ela pode ser profissional, Ambientais, Carga, Transportador aéreo, cibernéticos.',
  },
  {
    title: 'D & O',
    segment: ipSegments.alt,
    subSegment: 'D & O',
    modal: BasicForm,
    icon: AiOutlineSafety,
    description:
      'É uma forma de proteger diretores e executivos caso eles sejam acionados judicialmente por atos de gestão praticados no exercício de suas atribuições.',
  },
  {
    title: 'Náutico',
    segment: ipSegments.alt,
    subSegment: 'náutico',
    modal: NauticalForm,
    icon: RiShip2Line,
    description:
      'É uma proteção da embarcação e dos passageiros. São direcionados para lanchas, jet skis, iates e veleiros por todo território nacional.',
  },
  {
    title: 'Caminhões',
    segment: ipSegments.alt,
    subSegment: 'caminhões',
    modal: BasicForm,
    icon: RiTruckLine,
    description:
      'É semelhante ao seguro de automóvel, sendo que faz a cobertura total, danos a terceiros, lucros cessantes por paralisação do veículo, acidentes pessoais de passageiros, incêncio/roubo/furto.',
  },
  {
    title: 'Máquinas e Equipamentos',
    segment: ipSegments.alt,
    subSegment: 'máquinas e equipamentos',
    modal: MachineryForm,
    icon: MdGavel,
    description:
      'É um produto para cobrir máquinas e equipamentos utilizados na construção civil, industriais, medição, médicos/odontológicos, entre outros. Cobre danos físicos, subtração do bem, danos elétricos e responsabilidade civil.',
  },
  {
    title: 'Viagem',
    segment: ipSegments.alt,
    subSegment: 'viagem',
    modal: TravelForm,
    icon: IoIosAirplane,
    description:
      'É um serviço que oferece assistência médica e outras coberturas a pessoas que estão em trânsito, nacional ou internacional. Além de ser um “seguro saúde temporário”, cobre despesa com bagagem extraviada, despesas jurídicas, translado do corpo e regresso sanitário.',
  },
];
