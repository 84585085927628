/* eslint consistent-return: off */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { Dropdown } from 'components/elements/others';

import { errorHandler } from 'utils';
import schema from './schema';

interface ComponentProps {
  title: string;
  handleShowModal: () => void;
}

const BusinessCard: React.FC<ComponentProps> = ({ handleShowModal, title }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropdownTitle, setDropdownTitle] = useState(
    'Qual cartão você deseja?'
  );
  const [formInfos, setFormInfos] = useState({
    name: '',
    type: '',
    phone: '',
    email: '',
    position: '',
    cardType: '',
    publication: '',
    informations: '',
    goals: '',
    title: '',
    text: '',
    project: '',
    formatting: '',
    function: '',
    finished: false,
    transferred: false,
  });

  const dropdownOptions = [
    { title: ' Cartões de visitas simples', value: 'simple' },
    { title: ' Cartões de visitas private', value: 'private' },
  ];

  const handleSelectOption = (optionValue: string) => {
    const foundOption = dropdownOptions.find(
      option => option.value === optionValue
    );

    if (foundOption) {
      setDropdownTitle(foundOption.title);
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (dropdownTitle === 'Qual cartão você deseja?') {
      setIsLoading(false);
      return toast.dark('Escolha um tipo de cartão');
    }

    const newArray = {
      ...formInfos,
      type: title,
      cardType: dropdownTitle,
    };

    schema
      .validate(formInfos, {
        abortEarly: false,
      })
      .then(async () => {
        await hubApiMarketing
          .postMarketingInfos(newArray)
          .then(() => {
            setIsLoading(false);
            toast.dark('Solicitação enviada com sucesso');
            handleShowModal();
          })
          .catch(error => {
            setIsLoading(false);
            if (error.response.status === 409) {
              toast.dark(
                'Já existe uma ordem deste tipo em aberto, verifique o andamento com o responsável'
              );
            } else {
              errorHandler(error);
            }
          });
      })
      .catch(e => {
        if (e.errors.length > 2) {
          toast.dark(
            'Alguns campos não foram preenchidos, verifique e tente novamente.'
          );
        } else {
          e.errors.forEach((error: string) => toast.dark(error));
        }
        setIsLoading(false);
      });
  };

  return (
    <BaseModalFormCard
      isModal
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '760px']}
    >
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        gridGap={{ base: '2', md: '4', lg: '8' }}
      >
        <Stack spacing="4" width="100%">
          <InputWithLabel
            label="Nome:"
            type="text"
            placeholder="Como ficará seu nome no cartão"
            name="name"
            value={formInfos.name}
            onChange={handleChangeValue}
          />
          <InputWithLabel
            label="Telefone:"
            type="number"
            placeholder="Somente números com DDD"
            name="phone"
            value={formInfos.phone}
            onChange={handleChangeValue}
          />
          <InputWithLabel
            label="E-mail:"
            type="text"
            placeholder="Seu e-mail da EWZ"
            name="email"
            value={formInfos.email}
            onChange={handleChangeValue}
          />
          <InputWithLabel
            label="Função:"
            type="text"
            placeholder="Qual a sua função na EWZ"
            name="position"
            value={formInfos.position}
            onChange={handleChangeValue}
          />
        </Stack>

        <Flex width="100%" flexDir="column">
          <Dropdown
            size="sm"
            mt={{ base: '3', lg: '27px' }}
            onChange={e => handleSelectOption(e.target.value)}
            placeholder="Selecione um tipo de cartão"
          >
            {dropdownOptions?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.title}
              </option>
            ))}
          </Dropdown>

          <Flex mt="5" flexDir="column" color="white">
            <Flex mb="4" flexDir="column">
              <Text fontSize="sm" fontWeight="semibold" mb="1">
                Cartões de visitas simples
              </Text>
              <Text fontSize="sm" color="gray.300">
                - Custo gratuito para o assessor
              </Text>
              <Text fontSize="sm" color="gray.300">
                - Prazo de 10 dias úteis
              </Text>
            </Flex>

            <Flex flexDir="column">
              <Text fontSize="sm" fontWeight="semibold" mb="1">
                Cartões de visitas private
              </Text>
              <Text fontSize="sm" color="gray.300">
                - Custo de aproximadamente R$2,80 por cartão
              </Text>
              <Text fontSize="sm" color="gray.300">
                - Mínimo de 100 cartões
              </Text>
              <Text fontSize="sm" color="gray.300">
                - Prazo: junção de 8 demandas soltamos a ordem
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </BaseModalFormCard>
  );
};

export default BusinessCard;
