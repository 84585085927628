import React, { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Flex,
  ListItem,
  Stack,
  Switch,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { SecondaryButton } from 'components/elements/buttons';
import { KanbanBaseTaskProps, KanbanColumnProps } from 'shared/interfaces';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { ipSegmentsNamed } from 'pages/IP/data';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { IPostAnotations } from 'pages/IP/interfaces';
import { Annotations } from 'pages/IP/components/elements';
import parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { formateDateAndTime } from 'utils/formatDate';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { SpecificOrderSummary } from '../SpecificOrderSummary';
import { NextContactDatePicker } from '../NextContactDatePicker';
import { TransferOrdemModal } from '../../TransferOrdemModal';
import { FileSaveModal } from '../../FileSaveModal';
import { OrderFilesModal } from '../../OrderFIlesModal';

export interface IOrderFile {
  clientId: string;
  created_at: string;
  fileKey: string;
  fileName: string;
  id: string;
  orderId: string;
  url: string;
  updated_at: string;
}

export interface OrderProps extends KanbanBaseTaskProps {
  creatorname?: string;
  employeebtgcode?: string;
  phone?: string;
  email?: string;
  information?: string;
  value?: string;
  segment?: string;
  reason?: string;
  clientCode?: string;
  btgCode?: string;
  isClient?: boolean;
}

interface ComponentProps {
  order: OrderProps;
  handleToggleModal: () => void;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  column: KanbanColumnProps;
}

export interface IEmployeeWithIpPermission {
  position: string;
  BTGCode?: string;
  email: string;
  id: string;
  name: string;
  officeId?: string;
  phone: string;
  rules: IEmployeeRule[];
}

export interface IEmployeeRule {
  id: string;
  permissionsCod: string;
  areasCod: string;
  employeesId: string;
  created_at: string;
  updated_at: string;
}

export const BoardOrderInfo: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
  setColumns,
  column,
}) => {
  const history = useHistory();
  const [orderFiles, setOrderFiles] = useState<IOrderFile[]>([]);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const [isOpenOrderFilesModal, setIsOpenOrderFilesModal] = useState(false);
  const [employeesWithIpPermissions, setEmployeesWithIpPermissions] = useState<
    IEmployeeWithIpPermission[]
  >([]);
  const [annotationMsgList, setAnnotationMsgList] = useState<IPostAnotations[]>(
    []
  );
  const [originalMsgList, setOriginalMsgList] = useState<IPostAnotations[]>([]);
  const [onlyIPNotes, setOnlyIPNotes] = useState(false);
  const [clientId, setClientId] = useState('');

  const getEmployeesWithIPPermissions = async () => {
    try {
      const employees = await hubApiIp.listUsersWithIpPermissions();
      setEmployeesWithIpPermissions(employees);
    } catch (error) {
      toast.dark('Não foi possível buscar os assessores com permissões de IP');
    }
  };

  useEffect(() => {
    getEmployeesWithIPPermissions();
  }, []);

  const handleClientNotes = async () => {
    const clientsNotesArray: IPostAnotations[] = [];
    let clientNotes: IPostAnotations[];

    if (order.clientCode) {
      const { id } = await hubApiUser.listClientsInfos(
        order.clientCode as string
      );
      setClientId(id);
      clientNotes = await hubApiUser.getClientNotes(id);
    } else {
      clientNotes = await hubApiUser.getClientNotes(order.orderId);
    }

    clientNotes.forEach(cl => {
      clientsNotesArray.push(cl);
    });
    setAnnotationMsgList(clientsNotesArray);
    setOriginalMsgList(clientsNotesArray);
  };

  const sendToClientPanel = () => {
    window.sessionStorage.setItem(
      'searchClientContext',
      order.clientCode as string
    );

    history.push(`/user/users_clients/new/${order.clientCode as string}`);
  };

  const orderSegment = order.segment as keyof typeof ipSegmentsNamed;
  const orderSegmentName = order.segment && ipSegmentsNamed[orderSegment];

  const updateAnnotationsList = (annotation: IPostAnotations) => {
    setAnnotationMsgList([annotation, ...annotationMsgList]);
    setOriginalMsgList([annotation, ...annotationMsgList]);
  };

  const handleGetFiles = async () => {
    try {
      const filesFromOrder = await hubApiIp.getFilesByClientId(clientId);
      setOrderFiles(filesFromOrder);
    } catch (err) {
      toast.dark(
        'Nao foi possivel pegar os arquivos da ordem. Tente novamente mais tarde.'
      );
    }
  };

  useEffect(() => {
    handleClientNotes();
  }, []);

  useEffect(() => {
    if (clientId) {
      handleGetFiles();
    }
  }, [clientId]);

  useEffect(() => {
    if (onlyIPNotes) {
      setAnnotationMsgList(
        annotationMsgList.filter(note => note.type === 'intern')
      );
    } else {
      setAnnotationMsgList(originalMsgList);
    }
  }, [onlyIPNotes]);

  return (
    <DefaultModalContentWrapper
      maxW="90%"
      overflowY={{ base: 'scroll', md: 'inherit' }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <ModalTitle>
          {`Dados da Ordem -
          ${order.segment && orderSegmentName}
          ${
            (order.others?.subSegment &&
              `(${formatNameToCaptalize(order.others.subSegment)})`) ||
            ''
          }
        `}
        </ModalTitle>
        {order.isClient && (
          <Flex gap={3} alignItems="flex-start">
            <Switch onChange={() => setOnlyIPNotes(!onlyIPNotes)} />
            <Text>Notas somente da Área IP</Text>
          </Flex>
        )}
      </Flex>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        gap={4}
        justify="center"
        mb={4}
        maxH="460px"
      >
        <Stack spacing="3" w={{ base: 'full', md: '30%' }}>
          <Flex
            flexDirection="column"
            gap={3}
            overflowY="auto"
            className="slimScrollbar"
          >
            {order.name && (
              <DisplayInformation
                label="Cliente"
                info={order.name}
                isClient={order.isClient}
              />
            )}

            {order.creatorname && (
              <DisplayInformation
                label="Nome do Assessor"
                info={formatNameToCaptalize(order.creatorname)}
              />
            )}

            {order.phone && (
              <DisplayInformation label="Telefone" info={order.phone} />
            )}

            {order.email && (
              <DisplayInformation label="E-mail" info={order.email} />
            )}

            {order.information && (
              <DisplayInformation
                label="Informações"
                info={order.information}
              />
            )}

            {order.created_at && (
              <DisplayInformation
                label="Data da Abertura"
                info={`Aberta em ${formateDateAndTime(order.created_at)}`}
              />
            )}

            <SpecificOrderSummary
              infos={order.others}
              segment={order.segment}
              value={order.value}
              reason={order.reason}
              orderId={order.orderId}
              taskId={order.id}
            />

            {order.segment === 'imobiliario' && (
              <NextContactDatePicker
                nextContact={order.nextContact ?? ''}
                orderId={order.orderId}
                taskId={order.id}
              />
            )}
          </Flex>

          <Flex gap={2}>
            <Flex align="center" gap={2} flexDirection="column" w="50%">
              <SecondaryButton
                w="100%"
                onClick={() => setIsOpenTransferModal(true)}
                size="xs"
              >
                Transferir Ordem
              </SecondaryButton>
              {order.isClient && (
                <SecondaryButton
                  w="100%"
                  onClick={() => setIsOpenFileModal(true)}
                  size="xs"
                >
                  Fazer upload de arquivo
                </SecondaryButton>
              )}
            </Flex>
            <Flex flexDirection="column" gap={2} w="50%">
              {order.isClient && (
                <SecondaryButton w="100%" onClick={sendToClientPanel} size="xs">
                  Ver painel do cliente
                </SecondaryButton>
              )}

              {order.isClient && orderFiles.length > 0 && (
                <SecondaryButton
                  w="100%"
                  size="xs"
                  onClick={() => setIsOpenOrderFilesModal(true)}
                >
                  Ver Arquivos da Ordem
                </SecondaryButton>
              )}
            </Flex>
          </Flex>
        </Stack>

        <DisplayInformation
          w={{ base: 'full', md: '40%' }}
          label="Anotações"
          info={
            <Annotations
              updateAnnotationsList={updateAnnotationsList}
              previousNotes={annotationMsgList.at(0)?.msg ?? ''}
              orderId={order.orderId}
              clientBTGCode={order.clientCode as string}
            />
          }
          borderBottom="none"
        />

        <DisplayInformation
          w={{ base: 'full', md: '30%' }}
          label="Anotações anteriores"
          borderBottom="none"
          info={
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              h="100%"
            >
              <Stack overflowY="scroll" w="full" className="slimScrollbar">
                <UnorderedList spacing={2}>
                  {annotationMsgList.map(note => (
                    <ListItem key={note.orderId}>
                      <Flex gap={2}>
                        {note.notes && (
                          <Text wordBreak="break-all">
                            <Text fontStyle="italic">
                              (Nota No Painel Do Cliente)
                            </Text>
                            {parser(note.notes)}{' '}
                          </Text>
                        )}
                      </Flex>
                      {!note.notes && parser(note.msg)}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Stack>
            </Flex>
          }
        />
      </Stack>
      <ButtonGroup width="100%" justifyContent="flex-end">
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </ButtonGroup>

      <ModalWrapper
        isOpen={isOpenTransferModal}
        onClose={() => setIsOpenTransferModal(!isOpenTransferModal)}
      >
        <TransferOrdemModal
          employees={employeesWithIpPermissions}
          order={order}
          setIsOpenTransferModal={setIsOpenTransferModal}
          setColumns={setColumns}
          column={column}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenFileModal}
        onClose={() => setIsOpenFileModal(!isOpenFileModal)}
      >
        <FileSaveModal
          order={order}
          clientId={clientId}
          setIsOpenFileModal={setIsOpenFileModal}
          handleGetFiles={handleGetFiles}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={isOpenOrderFilesModal}
        onClose={() => setIsOpenOrderFilesModal(!isOpenOrderFilesModal)}
      >
        <OrderFilesModal
          setIsOpenOrderFilesModal={setIsOpenOrderFilesModal}
          orderFiles={orderFiles}
        />
      </ModalWrapper>
    </DefaultModalContentWrapper>
  );
};
