import { Box, Flex, Text } from '@chakra-ui/react';
import formatValue from 'utils/formatValue';
import { IBigNumberMkt } from 'pages/GenerationalVariables/interfaces';
import { BorderWidthSmallBorderLeftAndRigth } from '../../elements/others/BorderWidthSmallBorderLeftAndRigth';
import { WraperCardConsolidation } from '../../elements/wrappers/WraperCardConsolidation';

interface ConsolidationShProps {
  bigNumbersMkt: IBigNumberMkt;
}

export const ConsolidationSh: React.FC<ConsolidationShProps> = ({
  bigNumbersMkt,
}) => {
  return (
    <Flex justify="space-between" align="center" flexDir="column">
      <Flex w="100%" align="flex-start" mt={[10, 0]}>
        <Box>
          <Text fontSize="xl" fontWeight="bold" marginLeft={[10, 0]}>
            SH
          </Text>
        </Box>
      </Flex>
      <Flex flexDir="column">
        <Flex
          gridGap={3}
          h={['auto', '70px']}
          flexDirection={['column', 'row']}
        >
          <WraperCardConsolidation w="150px">
            <Text>Leads Entregues</Text>
            <Text color="white"> {bigNumbersMkt.leads || 0}</Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="150px">
            <Text>Leads Contatado</Text>
            <Text color="white"> {bigNumbersMkt.contact || 0}</Text>
          </WraperCardConsolidation>
          <WraperCardConsolidation w="150px">
            <Text>Leads Roteados</Text>
            <Text color="white">{bigNumbersMkt.meetingsRouter || 0} </Text>
          </WraperCardConsolidation>

          <WraperCardConsolidation w="150px">
            <Text> Pl Roteado</Text>
            <Text color="white">
              {' '}
              {formatValue(bigNumbersMkt.plRouter || 0)}
            </Text>
          </WraperCardConsolidation>
        </Flex>
      </Flex>

      <Flex flexDir="column" align="center" mt="3">
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Declínios
          </Text>
        </Box>

        <BorderWidthSmallBorderLeftAndRigth />

        <Flex
          gridGap={3}
          h={['auto', '70px']}
          flexDirection={['column', 'row']}
        >
          <WraperCardConsolidation w="170px">
            <Text> ClienteBTG</Text>

            {bigNumbersMkt.sh && (
              <Text color="white"> {bigNumbersMkt.sh.clientBTG}</Text>
            )}
          </WraperCardConsolidation>
          <WraperCardConsolidation w="170px">
            <Text> Não ligar novamente</Text>

            {bigNumbersMkt.sh && (
              <Text color="white"> {bigNumbersMkt.sh.noCallAgain || 0}</Text>
            )}
          </WraperCardConsolidation>
          <WraperCardConsolidation w="170px">
            <Text> Não existe</Text>

            {bigNumbersMkt.sh && (
              <Text color="white"> {bigNumbersMkt.sh.noCallAgain || 0}</Text>
            )}
          </WraperCardConsolidation>
          <WraperCardConsolidation w="170px">
            <Text> Sem interesse</Text>

            {bigNumbersMkt.sh && (
              <Text color="white"> {bigNumbersMkt.sh.noInterest || 0}</Text>
            )}
          </WraperCardConsolidation>
          <WraperCardConsolidation w="170px">
            <Text> Sem dinheiro</Text>

            {bigNumbersMkt.sh && (
              <Text color="white"> {bigNumbersMkt.sh.noMoney || 0}</Text>
            )}
          </WraperCardConsolidation>
        </Flex>
      </Flex>
    </Flex>
  );
};
