import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { ModalTitle } from 'components/elements/texts';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { DisplayInformation } from 'components/elements/others';
import { WithdrawalProps } from 'pages/RV/subpages/RvWithdrawals/interfaces';
import { errorHandler } from 'utils';
import {
  ModalWrapper,
  DefaultModalContentWrapper,
  BaseButtonGroup,
} from 'components/elements/wrappers';
import { OrderInfoConfirmOrder } from '../OrderInfoConfirmOrder';

const Withdrawal: React.FC<WithdrawalProps> = ({
  handleShow,
  infos,
  segment,
  value,
  risk,
  handleShowModal,
}) => {
  const [confirmShow, setConfirmShow] = useState(false);
  const [newValue, setNewValue] = useState('');

  const [parcial, setParcial] = useState(false);

  const handleShowParcial = () => setParcial(!parcial);
  const handleConfirmShow = () => setConfirmShow(!confirmShow);
  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleChangeNewValue = async () => {
    try {
      await hubApiRv.attParcialValue({
        idClient: infos.idClient,
        segment,
        risk: risk || '',
        value: String(newValue),
      });

      toast.dark('Resgate parcial concluído');
      handleShow();
      handleShowModal();
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <DefaultModalContentWrapper maxW="520px">
      <ModalTitle d="flex" justifyContent="space-between">
        <Flex>Retirada de cliente da mesa de Renda Variável</Flex>
        <SecondaryButton
          background="transparent"
          type="button"
          onClick={handleShow}
        >
          <AiOutlineClose />
        </SecondaryButton>
      </ModalTitle>

      <Stack spacing="2">
        <DisplayInformation label="Nome" info={infos.nameClient} />
        {infos.btgAccount && (
          <DisplayInformation label="Código BTG" info={infos.btgAccount} />
        )}

        <DisplayInformation label="Estratégia" info={segment} />
        <DisplayInformation label="Valor" info={value} />
        <DisplayInformation label="Risco" info={risk} />

        <BaseButtonGroup>
          <SecondaryButton onClick={handleShowParcial}>
            Resgate parcial
          </SecondaryButton>

          <SecondaryButton onClick={handleConfirmShow}>
            Resgate Total
          </SecondaryButton>
        </BaseButtonGroup>
        {parcial && (
          <Box w="35%">
            <InputWithLabel
              type="number"
              placeholder="R$ 1.000,00"
              label="Digite o valor para resgate"
              onChange={e => handleValue(e)}
            />

            <SecondaryButton mt={3} onClick={handleChangeNewValue}>
              Confirmar
            </SecondaryButton>
          </Box>
        )}

        <ModalWrapper isOpen={confirmShow} onClose={handleShowModal}>
          <OrderInfoConfirmOrder
            handleShow={handleConfirmShow}
            infos={infos}
            segment={segment}
            value={value}
            risk={risk}
            handleShowModal={handleShowModal}
          />
        </ModalWrapper>
      </Stack>
    </DefaultModalContentWrapper>
  );
};

export default Withdrawal;
