import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { hubApiNotifications } from '../../../services/hubApi/classes/HubApiNotifications';

import { GET_UNREAD_NOTIFICATIONS_REQUEST } from './actionTypes';

import { getUnreadNotificationsSuccess } from './actions';

export function* listUnreadNotificationsRequest() {
  try {
    const response = yield hubApiNotifications.getUnreadNotifications();

    yield put(getUnreadNotificationsSuccess(response));
  } catch (err) {
    toast.dark('Ocorreu um erro ao buscar notificações. Tente novamente.');
  }
}

export default all([
  takeLatest(GET_UNREAD_NOTIFICATIONS_REQUEST, listUnreadNotificationsRequest),
]);
