/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Stack } from '@chakra-ui/react';

import { InputWithLabel } from 'components/elements/forms';
import { DisplayInformation, Dropdown } from 'components/elements/others';
import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import { KanbanBaseTaskProps, KanbanColumnProps } from 'shared/interfaces';
import { coinsOptions } from 'pages/Exchange/data';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { formateDateAndTime } from 'utils/formatDate';
import { TransferOrdemModal } from 'pages/IP/components/modules/TransferOrdemModal';
import { finishOrderSchema } from './schema';

interface OrderProps extends KanbanBaseTaskProps {
  name?: string;
  phone?: string;
  email?: string;
  employeeName?: string;
  btgCode?: string;
  type?: string;
  intention?: string;
  description?: string;
  value?: string;
  spot?: string;
  spread?: string;
  contractValue?: string;
  currency?: string;
  targetCurrency?: string;
  bank?: string;
  employeemanagername?: string;
  created_at?: string;
}

interface ComponentProps {
  order: OrderProps;
  handleToggleModal: () => void;
  isFinishOrder: boolean;
  setIsFinished: (value: boolean) => void;
  setColumns?: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
  column?: KanbanColumnProps[];
}

export const BoardOrderInfo: React.FC<ComponentProps> = ({
  handleToggleModal,
  order,
  isFinishOrder,
  setIsFinished,
  setColumns,
  column,
}) => {
  const [editedOrder, setEditedOrder] = useState(order);
  const [availableBanks, setAvailableBanks] = useState([]);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [
    employeesWithExchangePermissions,
    setEmployeesWithExchangePermissions,
  ] = useState<any[]>([]);

  const getEmployeeWithExchangePermissions = async () => {
    const employees = await hubApiExchange.listEmployeesWithPermission();
    setEmployeesWithExchangePermissions(employees);
  };

  useEffect(() => {
    async function getOrder(orderId: string) {
      await hubApiExchange
        .searchOrderById(orderId)
        .then(response => {
          setEditedOrder(response);
        })
        .catch(error => {
          error.response?.data.message !== 'Internal server error' &&
            toast.dark(error.response?.data.message);
        });
    }

    getOrder(order.id);
  }, [order.id]);

  useEffect(() => {
    async function listBanks() {
      await hubApiExchange
        .listAvailableBanks()
        .then(response => {
          setAvailableBanks(response);
        })
        .catch(error => {
          error.response?.data.message !== 'Internal server error' &&
            toast.dark(error.response?.data.message);
        });
    }

    listBanks();
    getEmployeeWithExchangePermissions();
  }, []);

  const handleSelectBank = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEditedOrder({ ...editedOrder, bank: event.target.value });
  };

  const handleSelectOrigin = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEditedOrder({ ...editedOrder, currency: event.target.value });
  };

  const handleSelectDestiny = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEditedOrder({ ...editedOrder, targetCurrency: event.target.value });
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedOrder({ ...editedOrder, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await hubApiExchange
      .updateOrder(editedOrder.id, editedOrder)
      .then(() => toast.dark('Informações salvas com sucesso'))
      .catch(error => {
        error.response?.data.message !== 'Internal server error' &&
          toast.dark(error.response?.data.message);
      });
  };

  const handleFinishOrder = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    finishOrderSchema
      .validate(editedOrder)
      .then(async () => {
        await hubApiExchange
          .updateOrder(editedOrder.id, {
            ...editedOrder,
            status: 'fechada',
          })
          .then(() => {
            toast.dark(
              'Ordem finalizada com sucesso. A mesma será removida ao recarregar a tela'
            );

            setIsFinished(true);
            /* handleToggleCloseOrder(false); */
          })
          .catch(error => {
            error.response?.data.message !== 'Internal server error' &&
              toast.dark(error.response?.data.message);
          });
      })
      .catch(e => toast.dark(e.errors[0]));
  };

  return (
    <DefaultModalContentWrapper width="100%" maxW={['95vw', '960px']}>
      <ModalTitle>Dados da Ordem</ModalTitle>

      <form onSubmit={isFinishOrder ? handleFinishOrder : handleFormSubmit}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          gridGap="6"
        >
          <Stack width="100%" spacing="3">
            {order.name && (
              <DisplayInformation label="Nome do cliente" info={order.name} />
            )}

            {order.phone && (
              <DisplayInformation label="Telefone" info={order.phone} />
            )}

            {order.email && (
              <DisplayInformation label="E-mail" info={order.email} />
            )}

            {order.created_at && (
              <DisplayInformation
                label="Horario que foi aberto"
                info={formateDateAndTime(order.created_at)}
              />
            )}

            {order.employeemanagername && (
              <DisplayInformation
                label="Nome do solicitante"
                info={order.employeemanagername}
              />
            )}

            {order.btgCode && (
              <DisplayInformation
                label="Código do solicitante"
                info={order.btgCode}
              />
            )}

            {order.type && (
              <DisplayInformation
                label="Tipo"
                info={order.type === 'PF' ? 'Pessoa física' : 'Pessoa jurídica'}
              />
            )}

            {order.intention && (
              <DisplayInformation label="Modalidade" info={order.intention} />
            )}

            {order.description && (
              <DisplayInformation
                label="Informações"
                info={order.description}
              />
            )}
            <Flex align="center">
              <SecondaryButton
                w="fit-content"
                onClick={() => setIsOpenTransferModal(true)}
                size="xs"
              >
                Transferir Ordem
              </SecondaryButton>
            </Flex>
          </Stack>

          <Stack width="100%" spacing="3">
            <InputWithLabel
              label="Volume da ordem (R$):"
              placeholder="Valor em reais"
              name="value"
              value={editedOrder.value}
              onChange={handleChangeValue}
            />

            <InputWithLabel
              label="Spot (R$):"
              placeholder="Valor em reais"
              name="spot"
              value={editedOrder.spot ? editedOrder.spot : ''}
              onChange={handleChangeValue}
            />

            <InputWithLabel
              label="Spread total (%):"
              placeholder="Valor em porcentagem"
              name="spread"
              value={editedOrder.spread ? editedOrder.spread : ''}
              onChange={handleChangeValue}
            />

            <InputWithLabel
              label="Valor do contrato (R$):"
              placeholder="Valor em reais"
              name="contractValue"
              value={editedOrder.contractValue ? editedOrder.contractValue : ''}
              onChange={handleChangeValue}
            />
          </Stack>

          <Stack width="100%" spacing="3">
            <Box>
              <BaseLabelText mb="7px">Moeda origem:</BaseLabelText>
              <Dropdown
                placeholder={editedOrder.currency}
                onChange={handleSelectOrigin}
              >
                {coinsOptions?.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Box>
            <Box>
              <BaseLabelText mb="7px">Moeda destino:</BaseLabelText>
              <Dropdown
                placeholder={editedOrder.targetCurrency}
                onChange={handleSelectDestiny}
              >
                {coinsOptions?.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Box>
            <Box>
              <BaseLabelText mb="7px">Banco:</BaseLabelText>
              <Dropdown
                placeholder="Selecione um banco"
                onChange={handleSelectBank}
              >
                {availableBanks?.map(
                  (item: { id: string; name: string }, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  )
                )}
              </Dropdown>
            </Box>
          </Stack>
        </Flex>

        <BaseButtonGroup>
          <SecondaryButton type="button" onClick={handleToggleModal}>
            {isFinishOrder ? 'Cancelar' : 'Fechar'}
          </SecondaryButton>
          <PrimaryButton type="submit">
            {isFinishOrder ? 'Finalizar ordem' : 'Salvar alterações'}
          </PrimaryButton>
        </BaseButtonGroup>
      </form>
      <ModalWrapper
        isOpen={isOpenTransferModal}
        onClose={() => setIsOpenTransferModal(!isOpenTransferModal)}
      >
        <TransferOrdemModal
          employees={employeesWithExchangePermissions}
          order={order}
          column={column as any}
          setColumns={setColumns as any}
          setIsOpenTransferModal={setIsOpenTransferModal}
        />
      </ModalWrapper>
    </DefaultModalContentWrapper>
  );
};
