import React, { useRef, useState } from 'react';
import {
  Flex,
  FlexProps,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  InputGroup,
  Input as ChackraInput,
} from '@chakra-ui/react';
import { IRemunerationDetails } from 'pages/Remuneration/interfaces';
import { CloseIcon } from '@chakra-ui/icons';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { OrdersRow } from '../OrdersRow';
import { TooltipSearch } from '../TooltipSearch';

interface ComponentProps extends FlexProps {
  remunerationDetailsFiltred: IRemunerationDetails[];
  remunerationDetails: IRemunerationDetails[];
  setRemunerationDetailsFiltred: React.Dispatch<
    React.SetStateAction<IRemunerationDetails[]>
  >;
}

export const TableRemunerationDetails: React.FC<ComponentProps> = ({
  remunerationDetailsFiltred,
  remunerationDetails,
  setRemunerationDetailsFiltred,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchIconCodClient, setShowSearchIconCodClient] = useState(true);
  const [showSearchIconClientName, setShowSearchIconClientName] =
    useState(true);
  const [showSearchIconProduct, setShowSearchIconProduct] = useState(true);
  const [showSearchIconAsset, setShowSearchIconAsset] = useState(true);

  const codRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const prodRef = useRef<HTMLInputElement>(null);
  const actiRef = useRef<HTMLInputElement>(null);

  const searchByCode = () => {
    const code = codRef.current?.value;

    if (code) {
      setRemunerationDetailsFiltred(
        remunerationDetailsFiltred.filter(remunerationDetail =>
          remunerationDetail.btgClient.includes(code)
        )
      );
    }
  };

  const searchByNameClient = () => {
    const name = nameRef.current?.value;

    if (name) {
      setRemunerationDetailsFiltred(
        remunerationDetailsFiltred.filter(remunerationDetail =>
          remunerationDetail.nameClient.includes(name.toUpperCase())
        )
      );
    }
  };

  const searchByProduct = () => {
    const prod = prodRef.current?.value;

    if (prod) {
      setRemunerationDetailsFiltred(
        remunerationDetailsFiltred.filter(remunerationDetail =>
          remunerationDetail.product.includes(prod.toUpperCase())
        )
      );
    }
  };

  const handleSearchByActive = () => {
    const acti = actiRef.current?.value;

    if (acti) {
      setRemunerationDetailsFiltred(
        remunerationDetailsFiltred.filter(remunerationDetail =>
          remunerationDetail.asset.includes(acti.toUpperCase())
        )
      );
    }
  };

  const handleRemoveFilters = () => {
    if (codRef.current?.value) {
      codRef.current.value = '';
    }
    if (nameRef.current?.value) {
      nameRef.current.value = '';
    }
    if (prodRef.current?.value) {
      prodRef.current.value = '';
    }

    if (actiRef.current?.value) {
      actiRef.current.value = '';
    }

    setShowSearchIconCodClient(true);
    setShowSearchIconClientName(true);
    setShowSearchIconProduct(true);
    setShowSearchIconAsset(true);
    setRemunerationDetailsFiltred(remunerationDetails);
  };

  return (
    <Flex background="white" borderRadius="3" {...rest}>
      <Table
        px="4"
        color="rgba(70, 70, 70, 1)"
        variant="unstyled"
        width="100%"
        height="100%"
      >
        <Thead>
          <Tr>
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              minW={10}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                h="36px"
                justify="center"
                align="center"
              />
              Assessor
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              w="160px"
              minW={120}
            >
              <Flex
                borderBottom="1px solid #666666"
                mb="2"
                w="100%"
                justify="center"
                align="center"
              >
                <Flex w="90%" align="center" mb="2" justify="center">
                  <InputGroup>
                    <ChackraInput
                      background="#f2f2f2"
                      borderColor="transparent"
                      focusBorderColor="primary.600"
                      _placeholder={{ color: 'gray.600' }}
                      _hover={{ borderColor: 'primary.800' }}
                      borderRadius="6px"
                      px="1"
                      autoComplete="off"
                      h="7"
                      ref={codRef}
                      type="text"
                    />

                    <TooltipSearch
                      search={searchByCode}
                      labelTooltip="Filtrar pelo código"
                      showSearchIcon={showSearchIconCodClient}
                      setShowSearchIcon={setShowSearchIconCodClient}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
              Cod. cliente
            </Th>
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              w="160px"
              minW={120}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                justify="center"
                align="center"
              >
                <Flex w="90%" mb="2">
                  <InputGroup>
                    <ChackraInput
                      background="#f2f2f2"
                      borderColor="transparent"
                      autoComplete="off"
                      focusBorderColor="primary.600"
                      _placeholder={{ color: 'gray.600' }}
                      _hover={{ borderColor: 'primary.800' }}
                      borderRadius="6px"
                      px="1"
                      h="7"
                      ref={nameRef}
                      type="text"
                    />

                    <TooltipSearch
                      search={searchByNameClient}
                      labelTooltip="Filtrar pelo nome do cliente"
                      showSearchIcon={showSearchIconClientName}
                      setShowSearchIcon={setShowSearchIconClientName}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
              Cliente
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              w="160px"
              minW={120}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                justify="center"
                align="center"
              >
                <Flex w="90%" mb="2">
                  <InputGroup>
                    <ChackraInput
                      background="#f2f2f2"
                      borderColor="transparent"
                      autoComplete="off"
                      focusBorderColor="primary.600"
                      _placeholder={{ color: 'gray.600' }}
                      _hover={{ borderColor: 'primary.800' }}
                      borderRadius="6px"
                      px="1"
                      h="7"
                      ref={prodRef}
                      type="text"
                    />
                    <TooltipSearch
                      search={searchByProduct}
                      labelTooltip="Filtrar pelo produto"
                      showSearchIcon={showSearchIconProduct}
                      setShowSearchIcon={setShowSearchIconProduct}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
              Produto
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              w="160px"
              minW={120}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                justify="center"
                align="center"
              >
                <Flex w="90%" mb="2">
                  <InputGroup>
                    <ChackraInput
                      background="#f2f2f2"
                      borderColor="transparent"
                      autoComplete="off"
                      focusBorderColor="primary.600"
                      _placeholder={{ color: 'gray.600' }}
                      _hover={{ borderColor: 'primary.800' }}
                      borderRadius="6px"
                      px="1"
                      h="7"
                      ref={actiRef}
                      type="text"
                    />

                    <TooltipSearch
                      search={handleSearchByActive}
                      labelTooltip="Filtrar pelo ativo"
                      showSearchIcon={showSearchIconAsset}
                      setShowSearchIcon={setShowSearchIconAsset}
                    />
                  </InputGroup>
                </Flex>
              </Flex>
              Ativo
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              minW={180}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                justify="center"
                align="center"
              >
                <SecondaryButton
                  h="7"
                  mt={0}
                  onClick={handleRemoveFilters}
                  mb={2}
                >
                  {isLoading && <LoadingSpinner />}
                  {!isLoading && 'Limpar filtros'}
                </SecondaryButton>
              </Flex>
              Comissão L. EWZ
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              minW={150}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                h="36px"
                justify="center"
                align="center"
              />
              R. Líquida
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="small"
              position="sticky"
              top="0"
              px="1"
              py="2"
              minW={90}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                h="36px"
                justify="center"
                align="center"
              />
              Net
            </Th>{' '}
            <Th
              background="white"
              textAlign="center"
              color="rgba(70, 70, 70, 1)"
              fontSize="xs"
              position="sticky"
              top="0"
              px="1"
              py="2"
              w="160px"
              minW={120}
            >
              <Flex
                w="100%"
                borderBottom="1px solid #666666"
                mb="2"
                h="36px"
                justify="center"
                align="center"
              />
              Comissão AAI
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {remunerationDetailsFiltred?.map(order => (
            <OrdersRow order={order} key={order.id} />
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};
