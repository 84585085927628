import { Flex, Text } from '@chakra-ui/react';
import { formatToBRL, formatToCapitalized } from 'brazilian-values';

interface InlineInfosCLientsProps {
  name: string;
  value: string;
}

export const InlineInfosCLients: React.FC<InlineInfosCLientsProps> = ({
  name,
  value,
}) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      borderBottom="1px solid gray"
      color="whiteAlpha.900"
      fontWeight="bold"
      px="0.5"
      fontSize="sm"
    >
      <Text maxW="264px" isTruncated>
        {formatToCapitalized(name, {
          trimTrailingWhiteSpaces: false,
        })}
      </Text>

      <Text>{formatToBRL(value)}</Text>
    </Flex>
  );
};
