import React, { FormEvent, useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatToBRL } from 'brazilian-values';
import { toast } from 'react-toastify';

import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { MainPageTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultCardWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { errorHandler } from 'utils';
import { useParams } from 'react-router-dom';
import AddTitleStructured from './AddTitleStructured';
import ConfirmStructured from './ConfirmStructured';
import PerformanceModal from './PerformanceModal';

import schema from './schema';
import { IinitialStructuredData } from './data';

import { PercentSign, TextFieldsContainer } from './styles';

const CreateStructured: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  const [togglePerformances, setTogglePerformances] = useState(false);
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false);

  const [structuredData, setStructuredData] = useState<IinitialStructuredData>({
    strategy: '',
    operation: '',
    ticker: '',
    whatsMessage: '',
    fee: '',
    referencePrice: '',
    performances: [
      {
        id: 0,
        performance: '',
        income: '',
        type: '',
        delAbled: false,
      },
    ],
    coupons: [
      {
        id: 0,
        title: '',
        value: '',
        type: '',
        delAbled: false,
      },
    ],
  });

  useEffect(() => {
    if (id) {
      hubApiRv.listAllStructuredsById(id).then(response => {
        setStructuredData(response);
      });
    }
  }, []);

  const [isReferencePriceInitialValue, setIsReferencePriceInitialValue] =
    useState(true);

  const handleTogglePerformances = () =>
    setTogglePerformances(!togglePerformances);

  const handleToggleConfirmModal = () => {
    schema
      .validate(structuredData)
      .then(() => setToggleConfirmModal(!toggleConfirmModal))
      .catch(e => toast.dark(e.errors[0]));
  };

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const fields = ['operation', 'ticker'];
    if (e.target.value === '' && e.target.name === 'referencePrice') {
      setIsReferencePriceInitialValue(true);
    } else if (e.target.value !== '' && e.target.name === 'referencePrice') {
      setIsReferencePriceInitialValue(false);
    }
    setStructuredData({
      ...structuredData,

      [e.target.name]: fields.includes(e.target.name)
        ? e.target.value.toUpperCase()
        : e.target.name === 'referencePrice'
        ? formatarMoeda(e.target.value, isReferencePriceInitialValue)
        : e.target.value,
    });
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    const newStructured = {
      ...structuredData,
      performances: structuredData.performances.map(item => ({
        performance: item.performance,
        income: item.income,
      })),
      coupons: structuredData.coupons.map(item => ({
        title: item.title,
        value: item.value,
      })),
    };

    if (id) {
      const newStructured = {
        ...structuredData,
        performances: structuredData.performances.map(item => ({
          performance: item.performance,
          income: item.income,
        })),
        coupons: structuredData.coupons.map(item => ({
          title: item.title,
          value: item.value,
        })),
      };

      await hubApiRv
        .editStructuredById(newStructured)
        .then(() => {
          handleToggleConfirmModal();
          window.history.back();
          toast.dark('Estruturada Atualizada com sucesso.');
        })
        .catch(error => {
          error.response?.data.message !== 'Internal server error' &&
            toast.dark(error.response?.data.message);
        });
    } else {
      await hubApiRv
        .createStructuredStrategy(newStructured)
        .then(() => {
          handleToggleConfirmModal();
          window.history.back();
          toast.dark('Estruturada criada com sucesso.');
        })
        .catch(error => {
          errorHandler(error);
        });
    }
  };

  const handleSetCoupons = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    indexOf: number
  ) => {
    setStructuredData({
      ...structuredData,
      coupons: structuredData.coupons.map((info, index) => {
        if (index === indexOf) {
          return {
            ...info,
            [e.target.name]: e.target.value,
          };
        }

        return info;
      }),
    });
  };

  const handleSetPerformance = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    indexOf: number
  ) => {
    setStructuredData({
      ...structuredData,
      performances: structuredData.performances.map((info, index) => {
        if (index === indexOf) {
          return {
            ...info,
            [e.target.name]: e.target.value,
          };
        }

        return info;
      }),
    });
  };

  function formatarMoeda(value: any, isReferencePriceInitialValue: boolean) {
    if (isReferencePriceInitialValue) {
      value += '';
      value = `${value.replace('.', ',')}`;
      return formatToBRL(value);
    }
    if (value !== 'R$' && value !== 'R$ ' && value !== 'R$ NaN') {
      value = `${value}`;
      value = parseInt(value.replace(/[\D]+/g, ''));
      value += '';
      value = value.replace(/([0-9]{2})$/g, ',$1');
      if (value.length > 6 && value.length <= 9) {
        value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
      }

      if (value.length > 9) {
        value = value.replace(/(\d{1})(\d{3})(\d{3})/g, '$1.$2.$3');
      }
      if (value == 'NaN') value = '';

      return `R$ ${value}`;
    }
    return '';
  }

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />

      <DefaultCardWrapper flexDir="column">
        <MainPageTitle mb="2">Criar Estruturada</MainPageTitle>

        <Flex flex-direction={{ base: 'column', md: 'row' }} margin-top="16px">
          <Box w="100%" mr="16px">
            <Flex
              flex-direction="row"
              align-items="center"
              borderBottom=" 1px solid gray"
              paddingBottom="12px"
            >
              <InputWithLabel
                name="operation"
                value={structuredData.operation}
                type="text"
                label="Operação:"
                onChange={handleChangeValue}
                placeholder="Ex: Rab, Fence, Seagull, etc"
              />

              <Flex
                width="15px"
                height="35px"
                margin="30px 10px 0 "
                align-items="center"
                justifyContent="center"
              >
                <Text fontSize="smaller" color="white">
                  de
                </Text>
              </Flex>
              <InputWithLabel
                name="ticker"
                value={structuredData.ticker}
                type="text"
                label="Ticker:"
                onChange={handleChangeValue}
                placeholder="Ex: PETR3, AZUL4, etc"
              />
            </Flex>

            <Text fontSize="smaller" color="white" m="16px 0 8px">
              Características do produto:
            </Text>

            <AddTitleStructured
              structuredData={structuredData}
              setStructuredData={setStructuredData}
              handleChangeValue={handleSetCoupons}
            />
          </Box>

          <TextFieldsContainer>
            <Flex justifyContent="space-between">
              <InputWithLabel
                label="Fee:"
                placeholder="Ex: 1,2"
                name="fee"
                type="number"
                value={structuredData.fee}
                onChange={handleChangeValue}
                w="160px"
              />

              <PercentSign>%</PercentSign>
              <InputWithLabel
                name="referencePrice"
                value={structuredData.referencePrice}
                type="text"
                label="Preço de referência:"
                onChange={handleChangeValue}
                placeholder="Ex: R$ 1000,00"
              />
            </Flex>

            <Box mt="2">
              <TextareaWithLabel
                label="Estratégia"
                name="strategy"
                placeholder="Digite a estratégia..."
                rows={6}
                value={structuredData.strategy}
                onChange={handleChangeValue}
              />
            </Box>

            <Box mt="2" mb="1">
              <TextareaWithLabel
                label="Mensagem Whatsapp"
                name="whatsMessage"
                placeholder="Digite a mensagem..."
                rows={6}
                value={structuredData.whatsMessage}
                onChange={handleChangeValue}
              />
            </Box>
          </TextFieldsContainer>
        </Flex>

        <BaseButtonGroup>
          <SecondaryButton onClick={handleTogglePerformances}>
            Adicionar performance/rendimento
          </SecondaryButton>

          <PrimaryButton onClick={handleToggleConfirmModal}>
            {id ? 'Atualizar estruturada' : 'Criar estruturada'}
          </PrimaryButton>
        </BaseButtonGroup>
      </DefaultCardWrapper>

      <ModalWrapper
        isOpen={togglePerformances}
        onClose={handleTogglePerformances}
      >
        <PerformanceModal
          title="Performance / Rendimento"
          structuredData={structuredData}
          setStructuredData={setStructuredData}
          handleChangeValue={handleSetPerformance}
          handleShowModal={handleTogglePerformances}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={toggleConfirmModal}
        onClose={handleToggleConfirmModal}
      >
        <ConfirmStructured
          handleSubmitForm={handleSubmitForm}
          structuredData={structuredData}
          handleShowModal={handleToggleConfirmModal}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};

export default CreateStructured;
