import { useState } from 'react';

export type TasksFilterType = {
  name: string;
  tasktype: string;
  showTasks: boolean;
};

type UseTasksFilterResponseType = {
  tasksFilter: TasksFilterType[];
  setTasksFilter: React.Dispatch<React.SetStateAction<TasksFilterType[]>>;
};

export const useTasksFilter = (): UseTasksFilterResponseType => {
  const initialFilters: TasksFilterType[] = [
    {
      name: 'Origem',
      tasktype: 'missingOrigin',
      showTasks: false,
    },
    {
      name: 'NPS',
      tasktype: 'nps',
      showTasks: false,
    },
  ];

  const [tasksFilter, setTasksFilter] =
    useState<TasksFilterType[]>(initialFilters);

  return {
    tasksFilter,
    setTasksFilter,
  };
};
