import React, { useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';

import formatValue from 'utils/formatValue';
import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const NegativeClientCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
  isCalendarContact,
}) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <>
      <AssignmentContentCard
        IconElement={FcHighPriority}
        title="Cliente negativo"
        secondaryText={`O cliente ${assignment?.name?.toUpperCase()}(${
          assignment?.btgAccount
        }) está negativo em ${formatValue(assignment?.todayBalance || 0)}`}
        onClick={handleToggleModal}
        isCalendarContact={isCalendarContact}
      />

      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Cliente negativo"
          message={`Você confirma que o(a) cliente ${assignment?.name?.toUpperCase()} não está negativo(a)?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper>
    </>
  );
};
