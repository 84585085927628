import { MeetingType } from './meeting';

export enum LeadUploadStatusEnum {
  OK = 'ok',
  FINALIZADO = 'finalizado',
  DECLINED = 'declined',
}

export enum LeadUploadTypeEnum {
  LIST = 'list',
  MINED = 'mined',
}

export type LeadUploadType = {
  id: string;
  employeeId: string;
  name: string;
  email: string;
  phone: string;
  status: LeadUploadStatusEnum;
  type: LeadUploadTypeEnum;
  contactAttempts: number;
  listName?: string;
  pipeValue?: number;
  btgAccount?: string;
  declinedReason?: string;
  lastContactAttemptsUpdate?: Date;
  others?: {
    Sexo: string;
    Aum_PL: string;
    Estado: string;
    Trader: string;
    Profissao: string;
    Suitability: string;
    Faixa_Etaria: string;
    Faixa_Salario: string;
  } & Record<string, string>;
  meeting?: MeetingType;
  created_at: Date;
  updated_at: Date;
};

export type LeadUploadPoolType = LeadUploadType & {
  lastContactAttemptsUpdate?: Date;
};
