import { all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  DENIE_EMPLOYEE_FORM_BY_MANAGER,
  RH_FEEDBACK_ABOUT_EMPLOYEE_FORM,
} from './actionTypes';

import HubApi from '../../../services/hubApi';
import history from '../../../services/history';

export async function denieEmployeeFormManagerByManager({ employeeId }) {
  try {
    await HubApi.denieEmployeeFormByManager(employeeId);
    toast.dark('Formulário do colaborador cancelado.');
    history.push('/user/control_panel');
  } catch (err) {
    toast.dark(
      'Ocorreu um erro ao tentar deletar formulário. Tente novamente.'
    );
  }
}

export async function rhRetriveEmployeeForm({
  payload: { employeeForm, taskId },
}) {
  try {
    await HubApi.sendRhFeedbackOfEmployeeForm(employeeForm, taskId);
    toast.dark('Ação realizada com sucesso.');
    history.push('/user/control_panel');
  } catch (err) {
    toast.dark(
      'Ocorreu um erro ao tentar enviar informações do colaborador. Tente novamente.'
    );
  }
}

export default all([
  takeLatest(RH_FEEDBACK_ABOUT_EMPLOYEE_FORM, rhRetriveEmployeeForm),
  takeLatest(DENIE_EMPLOYEE_FORM_BY_MANAGER, denieEmployeeFormManagerByManager),
]);
