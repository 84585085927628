import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Board from 'pages/Exchange/components/modules/exchangeCRM/Board';

import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';

import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { exchangeColumns } from './data';

const ExchangeBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(exchangeColumns);

  useEffect(() => {
    async function listOrders() {
      await hubApiExchange
        .listOrdersByFilter('all')
        .then(response => {
          for (const column of columns) {
            const tasks = response.filter(
              (order: any) =>
                order.status.toLowerCase() === column.value.toLowerCase()
            );
            column.tasks = tasks;
          }
          setIsLoading(false);
        })
        .catch(() => toast.dark('Ocorreu um erro ao tentar buscar ordens'));
    }

    listOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Board
          boardTitle="CRM - Câmbio"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};

export default ExchangeBoard;
