import { Input } from 'components/elements/forms';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { BiTrash } from 'react-icons/bi';
import { MdAddCircle } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface CustomLeadsFilterProps {
  width: string | Array<string | number>;
  setCustomTermToSearch: React.Dispatch<React.SetStateAction<string>>;
  customTermToSearch: string;
  isAnEmptyList: boolean;
}

export const CustomLeadsSearchTerms: React.FC<CustomLeadsFilterProps> = ({
  width,
  setCustomTermToSearch,
  customTermToSearch,
  isAnEmptyList,
}) => {
  const [showMyTerms, setShowMyTerms] = useState(false);
  const [showDeleteCustomTermModal, setShowDeleteCustomTermModal] =
    useState(false);
  const [selectedTermToDeletion, setSelectedTermToDeletion] = useState('');
  const [myCustomTerms, setMyCustomTerms] = useState([] as string[]);

  const applySelectedTerm = (filter: string) => {
    setCustomTermToSearch(filter);
    setShowMyTerms(false);
  };

  const getMyCustomTerms = async () => {
    try {
      const result = await hubApiUser.listMySearchTerms();
      setMyCustomTerms(result);
    } catch (err) {
      toast.dark(
        'Não foi possível buscar seus termos de busca, tente novamente em alguns minutos'
      );
    }
  };

  const deleteCustomTerm = async (filter: string) => {
    try {
      await hubApiUser.deleteSearchTerm(filter.toLowerCase());

      toast.dark('Termo de busca excluído');
      setMyCustomTerms(
        myCustomTerms.filter(item => item !== selectedTermToDeletion)
      );
      setShowDeleteCustomTermModal(false);
    } catch (err) {
      toast.dark(
        'Não foi possível excluir o termo, tente novamente em alguns minutos'
      );
    }
  };

  const saveNewTerm = async (filter: string) => {
    const myNewCustomFiltersList = [filter, ...myCustomTerms];

    try {
      await hubApiUser.createSearchTerm(filter.toLowerCase());

      toast.dark('Termo de busca salvo com sucesso!');
      setMyCustomTerms(myNewCustomFiltersList);
    } catch (err) {
      toast.dark(
        'Não foi possível salvar o termo, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    getMyCustomTerms();
  }, []);

  return (
    <>
      <Flex w={width} flexDirection="column" position="relative">
        <Flex
          background="background.500"
          borderRadius="6px"
          w="100%"
          justifyContent="space-between"
        >
          <Input
            size="sm"
            placeholder="Digite um termo de busca"
            _placeholder={{ color: 'gray.700', fontStyle: 'italic' }}
            value={customTermToSearch}
            onChange={e => {
              setCustomTermToSearch(e.target.value);
              setShowMyTerms(false);
            }}
            focusBorderColor="none"
            _hover={{ borderColor: 'none' }}
          />
          <Flex alignItems="center" gridGap={1} pr={2}>
            {customTermToSearch !== '' && (
              <Tooltip
                hasArrow
                label="Limpar"
                color="white"
                bg="background.200"
              >
                <Box onClick={() => setCustomTermToSearch('')} cursor="pointer">
                  <AiOutlineClose />
                </Box>
              </Tooltip>
            )}
            {customTermToSearch &&
              !myCustomTerms.includes(customTermToSearch.toLowerCase()) && (
                <Tooltip
                  hasArrow
                  label="Salvar termo para buscas futuras"
                  color="white"
                  bg="background.200"
                >
                  <Box
                    onClick={() => saveNewTerm(customTermToSearch)}
                    cursor="pointer"
                  >
                    <MdAddCircle />
                  </Box>
                </Tooltip>
              )}
            {showMyTerms && (
              <RiArrowUpSLine
                size={22}
                color="white"
                cursor="pointer"
                onClick={() => setShowMyTerms(!showMyTerms)}
              />
            )}

            {!showMyTerms && (
              <RiArrowDownSLine
                size={22}
                color="white"
                cursor="pointer"
                onClick={() => setShowMyTerms(!showMyTerms)}
              />
            )}
          </Flex>
        </Flex>

        {isAnEmptyList && customTermToSearch !== '' && (
          <Flex position="absolute" top="40px" left="5px">
            <Text fontSize="xs" color="red.500" fontStyle="italic">
              Sem resultados para a busca
            </Text>
          </Flex>
        )}

        {showMyTerms && (
          <Flex
            position="absolute"
            zIndex={1}
            top="100%"
            minH="70px"
            maxH="350px"
            w="100%"
            background="background.500"
            borderRadius="6px"
            p={4}
            justifyContent="center"
            alignItens="center"
            flexDirection="column"
            gridGap={2}
            overflowY="auto"
          >
            {myCustomTerms.length < 1 && (
              <Text fontSize="xs" fontStyle="italic" alignSelf="center">
                Não há termos de busca adicionados
              </Text>
            )}
            {myCustomTerms.length > 0 &&
              myCustomTerms.map(term => (
                <Flex
                  px={2}
                  py={1}
                  cursor="pointer"
                  _hover={{ background: 'background.600' }}
                  borderRadius="6px"
                  onClick={() => applySelectedTerm(term)}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="sm" color="white" isTruncated>
                    {term}
                  </Text>
                  <Box
                    _hover={{ color: 'white' }}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedTermToDeletion(term);
                      setShowDeleteCustomTermModal(true);
                    }}
                  >
                    <BiTrash />
                  </Box>
                </Flex>
              ))}
          </Flex>
        )}
      </Flex>

      <ModalWrapper
        isOpen={showDeleteCustomTermModal}
        onClose={() => setShowDeleteCustomTermModal(false)}
      >
        <DefaultModalContentWrapper maxW="410px">
          <ModalTitle textAlign="center" mb={2}>
            Deseja apagar este termo de busca?
          </ModalTitle>
          <Text textAlign="center">{selectedTermToDeletion}</Text>

          <BaseButtonGroup mt={4}>
            <SecondaryButton
              onClick={() => setShowDeleteCustomTermModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={() => deleteCustomTerm(selectedTermToDeletion)}
            >
              Apagar
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
