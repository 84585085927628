import { HubApi } from '../index';

interface IPostMarketingInfos {
  type: string;
  name: string;
  phone: string;
  email: string;
  position: string | null;
  cardType: string | null;
  publication: string | null;
  informations: string | null;
  goals: string | null;
  title: string | null;
  text: string | null;
  project: string | null;
  formatting: string | null;
  function?: string;
  finished?: boolean;
  transferred?: boolean;
}

interface IRemoveCardCampaign {
  id: string;
  statys: string;
}

interface IPostUsers {
  employeeId: string;
  orderId: string;
}

interface IUpdateOrderData {
  status: string;
  description?: string;
}

interface IDeleteOrder {
  description: string;
}

interface IPostCreateCampaign {
  employeeId: string;
  tag: string;
  name: string;
}

class HubApiMarketing extends HubApi {
  async postMarketingInfos(data: IPostMarketingInfos) {
    const response = await this.axios.post('/orders/mk', data);
    return response.data;
  }

  async listMarketingInfos() {
    const response = await this.axios.get('/orders/mk/transferred/no');
    return response.data;
  }

  async removeCardCampaign(data: IRemoveCardCampaign) {
    const response = await this.axios.patch(`/campaign/${data.id}`, data);
    return response.data;
  }

  async listMarketingUsersStatus() {
    const response = await this.axios.get('/orders/mk/managementvalues');
    return response.data;
  }

  async listUsers() {
    const response = await this.axios.get('/employees/area/MKT');
    return response.data;
  }

  async listEmployeeSelected(id: string) {
    const response = await this.axios.get(`/crm/mk/me/${id}`);
    return response.data;
  }

  async listTransferedNo() {
    const response = await this.axios.get(`/orders/mk/transferred/no`);
    return response.data;
  }

  async postUsers(data: IPostUsers) {
    const response = await this.axios.post('/orders/mk/transfer', data);
    return response.data;
  }

  async listEmployeeOrders() {
    const response = await this.axios.get('/crm/mk/me');
    return response.data;
  }

  async updateOrderData(orderId: string, data: IUpdateOrderData) {
    await this.axios.patch(`/crm/mk/order/status/${orderId}`, data);
  }

  async deleteOrder(orderId: string, data: IDeleteOrder) {
    await this.axios.patch(`orders/mk/${orderId}`, data);
  }

  async listAllCampaign() {
    const response = await this.axios.get('/campaign/tag');
    return response.data;
  }

  async listTagsCampaign() {
    const response = await this.axios.get('/campaign/tags');
    return response.data;
  }

  async postCreateCampaign(data: IPostCreateCampaign) {
    const response = await this.axios.post('/campaign', data);
    return response.data;
  }

  // Gestão de materiais
  async getMaterials() {
    const response = await this.axios.get('/marketing');
    return response.data;
  }

  async postNewMaterial(data: { title: string; link: string; type: string }) {
    const response = await this.axios.post('/marketing', data);
    return response.data;
  }

  async getBigNumbersMkt() {
    const response = await this.axios.get('/dash/bignumber/mkt');
    return response.data;
  }

  async editMaterial(data: {
    id: string;
    title: string;
    link: string;
    type: string | null;
  }) {
    const response = await this.axios.patch('/marketing', data);
    return response.data;
  }

  async deleteMaterial(materialId: string) {
    await this.axios.patch(`/marketing/${materialId}`);
  }
}

export const hubApiMarketing = new HubApiMarketing();

// force
