export const leadOrigin = [
  { name: 'Lead Mundo Invest' },
  { name: 'Leads Hub' },
  { name: 'Indicação' },
  { name: 'Prospecção independente' },
  { name: 'Não sei' },
];

export const hubLeadType = [
  { name: 'Lead Mundo Invest' },
  { name: 'Lead Minerado' },
  { name: 'Lead pessoal' },
  { name: 'Lead PJ' },
];

export const indicationLeadType = [
  { name: 'Outro cliente' },
  { name: 'Amigos, família' },
  { name: 'Código do responsável' },
];

export const prospectLeadType = [
  { name: 'LinkedIn' },
  { name: 'Evento' },
  { name: ' Instagram/Facebook' },
  { name: 'Coldcall' },
  { name: 'Outro' },
];
