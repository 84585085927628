import React, { useState } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { errorHandler } from 'utils';
import { IReduxState } from 'shared/interfaces';
import { checkIfDateIsPast, formatDateToISO } from 'utils/formatDate';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { TextareaWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { Dropdown } from 'components/elements/others';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { localOptions } from 'pages/Captation/data';
import {
  LeadDetailsProps,
  SHQuestionsProps,
} from 'pages/Captation/interfaces/SHLeads';

interface ISHScheduleMeetingProps {
  meetingInfos: IBaseMeeting;
  questionsInfo: SHQuestionsProps;
  typeOfSchedule: 'meeting' | 'callback';
  handleShowModal: () => void;
  selectedLead: {
    isSelected: boolean;
    leadId: string;
    thereIsTask: boolean;
  };
  setSelectedLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  leadEmployeeId: string;
  setExpiredLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  publicLeads: LeadDetailsProps[];
  setPublicLeads: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
  lead: LeadDetailsProps;
  filteredLeads: LeadDetailsProps[];
  setFilteredLeads: React.Dispatch<React.SetStateAction<LeadDetailsProps[]>>;
}

export const SHScheduleMeeting: React.FC<ISHScheduleMeetingProps> = ({
  handleShowModal,
  meetingInfos,
  typeOfSchedule,
  selectedLead,
  setSelectedLead,
  leadEmployeeId,
  setExpiredLead,
  publicLeads,
  setPublicLeads,
  lead,
  filteredLeads,
  setFilteredLeads,
  questionsInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scheduling, setScheduling] = useState('');
  const [complement, setComplement] = useState('');
  const [localTitle, setLocalTitle] = useState('Local');

  const user = useSelector((state: IReduxState) => state.user.profile);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMeetingDate: any = (value: string) => {
    const parsed = formatDateToISO(value);

    setScheduling(parsed);
  };
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setIsLoading(true);

    const dateIsPast = checkIfDateIsPast(scheduling);
    if (dateIsPast || scheduling.trim() === '') {
      toast.dark('Escolha uma data futura para marcar uma reunião.');
      setIsLoading(false);
      return;
    }

    if (typeOfSchedule === 'meeting') {
      if (localTitle === 'Local') {
        toast.dark('Selecione um local para a reunião');
        setIsLoading(false);
        return;
      }
    }

    try {
      const meetingData = {
        leadId: lead.id,
        employeeId: user.id,
        employeeIdParticipant: user.id,
        type: typeOfSchedule === 'callback' ? 'callbackSH' : 'meeting',
        local: localTitle,
        leadType: 'publics',
        complement,
        date: scheduling,
        status: typeOfSchedule === 'callback' ? 'callbackSH' : 'created',
        feedback: '',
        contactAttempts: '2',
        filialName: questionsInfo.filialName,
        filialId: questionsInfo.filialId,
        // eslint-disable-next-line no-unneeded-ternary
        isMined: meetingInfos ? false : true,
        leadEmployeeId: meetingInfos ? '' : leadEmployeeId,
      };

      if (meetingInfos) {
        await hubApiCaptation.pathMetting({
          ...meetingData,
          id: meetingInfos.id,
        });
      } else {
        await hubApiCaptation.postMetting(meetingData);
      }

      await hubApiCaptation.pathLeadsUpdate('publics', {
        id: lead.id,
      });

      handleShowModal();
      toast.dark(
        typeOfSchedule === 'callback'
          ? 'Retorno marcado com sucesso'
          : 'Reunião marcada com sucesso'
      );

      setSelectedLead({ ...selectedLead, isSelected: false });
      setExpiredLead({ ...selectedLead, isSelected: false });

      const { data } = await hubApiCaptation.getPublicLeads();
      setPublicLeads(data);
      setFilteredLeads(data);
    } catch (err) {
      errorHandler(err);
    }
    setIsLoading(false);
  };

  const checkModalTitle = () => {
    if (typeOfSchedule === 'meeting') {
      if (meetingInfos?.status && meetingInfos.status === 'created') {
        return 'Reagendar reunião';
      }
      return 'Agendar nova reunião';
    }
    return 'Agendar retorno';
  };

  return (
    <BaseModalFormCard
      isModal
      maxW="510px"
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      title={checkModalTitle()}
      primaryButtonText="Agendar"
      // primaryButtonText={
      //   typeOfSchedule === 'meeting' ? 'Agendar reunião' : 'Agendar retorno'
      // }
    >
      <Stack spacing={4}>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gridGap="3"
          alignItems="flex-end"
        >
          <DateAndHourPicker onChange={handleChangeMeetingDate} label="Data" />

          {typeOfSchedule === 'meeting' && (
            <Dropdown
              size="sm"
              mb="0.5"
              placeholder="Tipo de reunião"
              onChange={e => setLocalTitle(e.target.value)}
            >
              {localOptions.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              ))}
              <option value="Vídeo Login MI">Vídeo Login MI</option>
            </Dropdown>
          )}
        </Flex>

        <TextareaWithLabel
          label="Complemento:"
          placeholder="Informações sobre a reunião"
          name="Complement"
          rows={4}
          onChange={e => setComplement(e.target.value)}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
