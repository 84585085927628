import { Flex, Text } from '@chakra-ui/react';

interface InformationTiOrdersProps {
  title: string;
  value: string;
  subTitle: string;
}

export const InformationTiOrders: React.FC<InformationTiOrdersProps> = ({
  value,
  title,
  subTitle,
}) => {
  return (
    <Flex
      width="100%"
      maxW={{ base: '100%', md: '156px' }}
      py="2"
      px="4"
      flexDir="column"
      align="center"
      justify="center"
      borderRadius="3"
      bg="background.700"
    >
      <Text color="whiteAlpha.900" fontSize="larger" fontWeight="bold">
        {title}
      </Text>
      <Text color="whiteAlpha.800">
        {value} {subTitle}
      </Text>
    </Flex>
  );
};
