import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BackPageButton } from 'components/elements/buttons';
import { PageWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';
import Board from '../../components/modules/marketingCRM/Board';

import { marketingColumns } from './data';

export const MarketingCRM: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(marketingColumns);

  useEffect(() => {
    async function listUserOrders() {
      await hubApiMarketing
        .listEmployeeOrders()
        .then(response => {
          for (const column of columns) {
            const tasks = response.filter(
              (order: { status: string }) =>
                order.status.toLowerCase() === column.title.toLowerCase()
            );
            column.tasks = tasks;
          }

          setIsLoading(false);
        })
        .catch(() => toast.dark('Ocorreu um erro ao buscar ordens'));
    }

    listUserOrders();
  }, []);

  return (
    <PageWrapper position="relative" justifyContent="center" paddingTop="6">
      <BackPageButton />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Board
          boardTitle="CRM - Marketing"
          boardColumns={columns}
          setBoardColumns={setColumns}
        />
      )}
    </PageWrapper>
  );
};
