import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { formatDateToISO, formateDateAndTime } from 'utils/formatDate';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { TextareaWithLabel } from 'components/elements/forms';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { queryClient } from 'App';
import { CAPTATION_QUERIES_KEYS } from 'pages/Captation/CAPTATION_QUERIES_KEYS';

interface IRouteSHMeetingProps {
  lead: { plSh: number };
  meeting?: IBaseMeeting;
  handleToggleModal: () => void;
  handleBackToAllLeads: () => void;
  selectedLead: {
    isSelected: boolean;
    leadId: string;
    thereIsTask: boolean;
  };
  setSelectedLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  setExpiredLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
}

export const SHRouteMeeting: React.FC<IRouteSHMeetingProps> = ({
  meeting,
  handleToggleModal,
  handleBackToAllLeads,
  setSelectedLead,
  selectedLead,
  setExpiredLead,
  lead,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [toggleScheduling, setToggleScheduling] = useState(false);
  const [scheduling, setScheduling] = useState('');
  const [meetingComplement, setMeetingComplement] = useState(
    meeting?.complement || ''
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMeetingDate: any = (value: string) => {
    const parsed = formatDateToISO(value);

    setScheduling(parsed);
  };

  const handleRouteMeeting = async () => {
    setIsLoading(true);

    try {
      const meetingData = {
        id: meeting?.id,
        leadId: meeting?.leadId,
        employeeId: meeting?.employeeId,
        employeeIdParticipant: meeting?.employeeIdParticipant,
        type: 'meeting',
        local: meeting?.local,
        leadType: 'publics',
        date: scheduling.trim() !== '' ? scheduling : meeting?.date,
        status: 'confirmed',
        filialId: meeting?.filialId,
        filialName: meeting?.filialName,
        complement: meetingComplement,
      };

      await hubApiCaptation.pathMetting(meetingData);
      await hubApiCaptation.pathLeadsUpdate('publics', {
        id: meeting?.leadId,
        plSh: lead.plSh,
        status: 'finalizado',
      });

      toast.dark('Reunião roteada com sucesso');
      queryClient.invalidateQueries([CAPTATION_QUERIES_KEYS.shPoolLeads]);
      handleBackToAllLeads();
      setSelectedLead({ ...selectedLead, isSelected: false });
      setExpiredLead({ ...selectedLead, isSelected: false });
    } catch (err) {
      errorHandler(err);
    }

    setIsLoading(false);
  };

  return (
    <DefaultModalContentWrapper maxW="460px">
      <ModalTitle>Rotear reunião</ModalTitle>

      {toggleScheduling && (
        <DateAndHourPicker onChange={handleChangeMeetingDate} label="Data" />
      )}

      <Text
        fontSize="large"
        fontWeight="semibold"
        mb="4"
        mt={toggleScheduling ? 6 : 0}
      >
        Você confirma que deseja rotear essa reunião para{' '}
        {scheduling.trim() !== ''
          ? formateDateAndTime(scheduling)
          : formateDateAndTime(String(meeting?.date))}
        ?
      </Text>

      <TextareaWithLabel
        label="Complemento:"
        placeholder="Informações sobre a reunião"
        name="Complement"
        mb="2.5"
        rows={4}
        value={meetingComplement}
        onChange={e => setMeetingComplement(e.target.value)}
      />

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Cancelar</SecondaryButton>
        <SecondaryButton onClick={() => setToggleScheduling(!toggleScheduling)}>
          Mudar data
        </SecondaryButton>
        <PrimaryButton isLoading={isLoading} onClick={handleRouteMeeting}>
          Confirmar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
