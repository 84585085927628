export enum MeetingStatusEnum {
  CREATED = 'created',
  CONFIRMED = 'confirmed',
  CALLBACK = 'callback',
  CALLBACK_SH = 'callbackSH',
  DECLINED = 'declined',
  ALOCADO = 'alocado',
  SUITABILITY = 'suitability',
  PRESENTATION = 'presentation',
  ALLOCATION = 'allocation',
  ACCOUNT = 'account',
  RETURNED = 'returned',
  WAITING_FEEDBACK = 'waiting_feedback',
  OCUPADO = 'ocupado',
}

export type MeetingType = {
  id: string;
  leadId: string;
  date: Date;
  local: string;
  complement: string;
  employeeId: string;
  employeeIdParticipant: string;
  status: MeetingStatusEnum;
  feedback: string;
  type: string;
  leadType: string;
  name: string;
  cost: string | undefined;
  confirm: boolean;
  isPipe: boolean;
  contactAttempts: string | undefined;
  return: boolean;
  filialName: string | undefined;
  filialId: string | undefined;
};

export type MeetingPoolType = MeetingType & { pipeValue: number };
