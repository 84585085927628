import React from 'react';
import { Flex } from '@chakra-ui/react';
import { formatToCNPJ } from 'brazilian-values';

import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';
import { useNavigation } from 'hooksV2/useNavigation';
import { LeadUtils } from 'utilsV2/Lead';
import { SecondaryButton } from 'components/elements/buttons';

interface ComponentProps {
  corporateName: string;
  cnpj: string;
  role?: string;
  leadId?: string;
}

export const LeadPjHeadContent: React.FC<ComponentProps> = ({
  corporateName,
  cnpj,
  role,
  leadId,
}) => {
  const { redirect } = useNavigation();

  return (
    <Flex flexDir="column">
      <ModalTitle mb="2">{corporateName?.toUpperCase()}</ModalTitle>
      <SecondaryButton
        mt={2}
        p={2}
        w="50%"
        h="25px"
        onClick={() =>
          redirect(`/v2/lead/${LeadUtils.transformLeadType('pj')}/${leadId}`)
        }
        mb="4"
      >
        Ir para a pagina do lead
      </SecondaryButton>
      <Flex gridGap="3" flexWrap="wrap">
        <ContentWithVertDividerWrapper>
          <ContentDividerTitle>CNPJ:</ContentDividerTitle>
          <ContentDividerText>{cnpj && formatToCNPJ(cnpj)}</ContentDividerText>
        </ContentWithVertDividerWrapper>
        <ContentWithVertDividerWrapper isTruncated maxW="148px">
          <ContentDividerTitle>Cargo:</ContentDividerTitle>
          <ContentDividerText>{role}</ContentDividerText>
        </ContentWithVertDividerWrapper>
        <ContentWithVertDividerWrapper>
          <ContentDividerTitle>Tipo:</ContentDividerTitle>
          <ContentDividerText>Leads PJ</ContentDividerText>
        </ContentWithVertDividerWrapper>
      </Flex>
    </Flex>
  );
};
