import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface ComponentProps {
  text: string;
}

export const MeetingTypeTag: React.FC<ComponentProps> = ({ text }) => {
  return (
    <Flex
      position="absolute"
      top="-12px"
      minW="48px"
      justifyContent="center"
      left="-28px"
      textAlign="center"
      fontWeight="semibold"
      background="background.400"
      p="1"
      borderRadius="2"
      fontSize="sm"
    >
      <Text>{text}</Text>
    </Flex>
  );
};
