import React from 'react';

import {
  ContentDividerTitle,
  ContentDividerText,
} from 'pages/Captation/components/elements/texts';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';

interface ComponentProps {
  complement: string;
  title?: string;
}

export const LeadComplement: React.FC<ComponentProps> = ({
  complement,
  title = 'Complemento',
}) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle>{title}</ContentDividerTitle>
      <ContentDividerText color="white">{complement}</ContentDividerText>
    </ContentWithVertDividerWrapper>
  );
};
