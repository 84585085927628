import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { ScheduleMeetingModal } from 'componentsV2/modules/captation/scheduleMeetingModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useScheduleFirstMeetingContext } from 'pagesV2/LeadDetails/context/useScheduleFirstMeetingContext';
import { CalendarBlank } from 'phosphor-react';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { showToast } from 'utils';

export const LeadDetailsScheduleMeeeting = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();
  const { leadPl, meetingFilial } = useScheduleFirstMeetingContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenScheduleMeetingModal =
    getParam('isOpenScheduleMeetingModal') === 'true';

  const isSalesHunterDesiredFlow =
    getParam('desiredFlow') === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const handleClickScheduleMeetingButton = () => {
    if (isSalesHunterDesiredFlow && !(leadPl || lead.questions?.pl))
      return showToast('Preencha o patrimonio do lead (PL)');

    if (isSalesHunterDesiredFlow && !meetingFilial.id)
      return showToast('Escolha uma filial');

    setParam('isOpenScheduleMeetingModal', 'true');
  };

  return (
    <>
      <Button.Primary
        onClick={handleClickScheduleMeetingButton}
        fontSize="0.8rem"
        size="md"
        width="160px"
        maxWidth="160px"
        justifyContent="space-between"
        _hover={{ bg: '#fff', color: '#085cef' }}
      >
        <CalendarBlank size={15} />
        Agendar Reunião
      </Button.Primary>

      <ModalWrapper
        isOpen={isOpenScheduleMeetingModal}
        onClose={() => deleteParam('isOpenScheduleMeetingModal')}
      >
        <ScheduleMeetingModal
          lead={lead}
          leadType={leadType}
          status={
            isSalesHunterDesiredFlow
              ? MeetingStatusEnum.CREATED
              : MeetingStatusEnum.SUITABILITY
          }
          filial={meetingFilial}
        />
      </ModalWrapper>
    </>
  );
};
