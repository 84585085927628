import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;

  label {
    font-size: var(--smaller-text);
    color: var(--white-color-300);
    margin-bottom: 4px;
  }
`;

export const Content = styled.div`
  display: flex;

  input {
    border: none;
    padding: 7.5px 12px;
    border-radius: 5px;
    font-size: var(--smaller-text);
    color: rgba(70, 70, 70, 1);
    background: white;
    border: 1px solid #1d63ddb2;
  }
`;
