import React, { useEffect, useState } from 'react';

import { hubApiHome } from 'services/hubApi/classes/HubApiHome';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { TableBase } from 'components/elements/table';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { advancedOrdersHeader } from './data';
import { filterEmployeeProps, IEmoployeeOrdersProps } from './interfaces';
import { OrderTableRow } from './components/TableRow';

const DiscontinuedUserOrders: React.FC = () => {
  const [filteredEmployees, serFilteredEmployees] = useState<
    filterEmployeeProps[]
  >([]);

  const [employeeOrders, setEmployeeOrders] = useState<IEmoployeeOrdersProps>(
    {} as IEmoployeeOrdersProps
  );

  // const [areaSelected, setAreaSelected] = useState('all'); poderemos usar popsivelmente no futuro
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getEmployees() {
      await hubApiHome.listEmployees().then(response => {
        // getEmployeeOrders(response[0].idEmployee)
        //  setEmployees(response);

        if (response.length) {
          getEmployeeOrders(response[0].idEmployee);
          serFilteredEmployees(response);
        } else {
          toast.dark('Nenhuma ordem foi encontrada');
        }
      });
    }
    getEmployees();
  }, []);

  // const handleSelectArea = (area) => {
  //     var areaEmployees = employees.filter(
  //         (employee) => employee.position === area
  //     );
  //     serFilteredEmployees(areaEmployees);
  // };

  const getEmployeeOrders = async (item: string) => {
    setIsLoading(true);
    await hubApiHome.listEmployeeOrders(item).then(response => {
      setEmployeeOrders(response);
      setIsLoading(false);
    });
    setIsLoading(false);
  };

  return (
    <PageWrapper flexDir="column" py="48px" alignItems="center">
      <BackPageButton />

      <MainPageTitle textAlign="center">
        Ordens de Usuários Descontinuados
      </MainPageTitle>
      <Flex flexDir="column" margin=" 12px 0 24px" w="80%">
        <Flex margin=" 12px 0 24px">
          <Dropdown onChange={event => getEmployeeOrders(event.target.value)}>
            {filteredEmployees.map((item, key) => (
              <option key={key} value={item.idEmployee}>
                {' '}
                {`${formatNameToCaptalize(item.name)}`}
              </option>
            ))}
          </Dropdown>
        </Flex>

        {isLoading && <LoadingSpinner />}

        <TableBase
          overflowY="auto"
          maxH="400px"
          width="100%"
          headData={advancedOrdersHeader}
        >
          {employeeOrders.orderStrategyRV &&
            employeeOrders.orderStrategyRV.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}

          {employeeOrders.orderStructuredRV &&
            employeeOrders.orderStructuredRV.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}
          {employeeOrders.ordersExchange &&
            employeeOrders.ordersExchange.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}
          {employeeOrders.ordersFormGuru &&
            employeeOrders.ordersFormGuru.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}

          {employeeOrders.ordersIP &&
            employeeOrders.ordersIP.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}

          {employeeOrders.ordersMK &&
            employeeOrders.ordersMK.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}

          {employeeOrders.ordersTI &&
            employeeOrders.ordersTI.map((ordem, key) => {
              return <OrderTableRow rowData={ordem} key={key} />;
            })}
        </TableBase>
      </Flex>
    </PageWrapper>
  );
};

export default DiscontinuedUserOrders;
