import React from 'react';
import { Select, SelectProps } from '@chakra-ui/react';

import { DropdownContainer } from './styles';

export const Dropdown: React.FC<SelectProps> = ({ children, ...rest }) => {
  return (
    <DropdownContainer>
      <Select
        borderColor="rgba(70, 70, 70, 1)"
        color="rgba(70, 70, 70, 1)"
        mb="7px"
        size="sm"
        {...rest}
      >
        {children}
      </Select>
    </DropdownContainer>
  );
};
