import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { showToast } from 'utils';
import { Input } from 'components/elements/forms';
import { MainPageTitle } from 'components/elements/texts';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { SearchClientsCard } from 'pages/manager/components/elements';
import { IClients } from 'pages/manager/interfaces';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';

export const SearchClients: React.FC = () => {
  const user = useSelector((state: IReduxState) => state.user.profile);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [clientsFound, setClientsFound] = useState<IClients[]>([]);
  const [showNoClientsFoundMessage, setShowNoClientsFoundMessage] =
    useState(false);

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClient = async (lastTermSearched?: string) => {
    setIsLoading(true);
    setShowNoClientsFoundMessage(false);

    if (searchValue.length < 3 && !lastTermSearched) {
      setIsLoading(false);
      showToast('Dígite pelo menos 3 caracteres para uma busca mais precisa');
      return;
    }

    try {
      if (lastTermSearched) {
        const leadData = await hubApiUser.searchClients({
          term: lastTermSearched,
          userPosition:
            user.position === 'MASTER' ? 'GTR' : user.rules[0].permission_cod,
          tableId: user.tableId,
        });

        if (leadData.length === 0) {
          setShowNoClientsFoundMessage(true);
        }

        window.sessionStorage.removeItem('searchClientLastTerm');
        setClientsFound(leadData);
        setSearchValue(lastTermSearched);
      } else {
        const leadData = await hubApiUser.searchClients({
          term: searchValue,
          userPosition:
            user.position === 'MASTER' ? 'GTR' : user.rules[0].permission_cod,
          tableId: user.tableId,
        });

        if (leadData.length === 0) {
          setShowNoClientsFoundMessage(true);
        }

        window.sessionStorage.setItem('searchClientLastTerm', searchValue);
        setClientsFound(leadData);
      }
    } catch (err) {
      if (err.message === 'Network Error') {
        showToast(
          'Ocorreu um erro ao buscar clientes relacionados. Tente novamente mais tarde'
        );
      } else {
        showToast('Não foi possível encontrar clientes para esta busca');
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const lastTermSearched = window.sessionStorage.getItem(
      'searchClientLastTerm'
    );

    if (lastTermSearched) {
      handleSearchClient(lastTermSearched);
    }
  }, []);

  return (
    <PageWrapper
      minH="calc(100vh - 80px)"
      flexDir="column"
      alignItems="center"
      padding="48px 20px 0"
    >
      <BackPageButton />

      <MainPageTitle textAlign="center">Pesquisa de Clientes</MainPageTitle>

      <Flex
        width="100%"
        maxW="640px"
        flexDir="row"
        alignItems="center"
        gridGap="2"
        mt="3.5"
      >
        <Input
          placeholder="Digite nome, email ou telefone do cliente"
          value={searchValue}
          onChange={handleChangeSearchValue}
        />
        <PrimaryButton
          size="md"
          type="button"
          isLoading={isLoading}
          onClick={() => handleSearchClient()}
        >
          Buscar
        </PrimaryButton>
      </Flex>

      {showNoClientsFoundMessage && (
        <Text fontStyle="italic" mt="12">
          Não foram encontrados clientes para este parâmetro de busca
        </Text>
      )}

      {clientsFound.length > 0 && (
        <DefaultCardWrapper
          width="100%"
          maxW="720px"
          background="background.700"
          p="2.5"
          mt="8"
        >
          <Flex
            width="100%"
            flexDir="column"
            maxH="calc(100vh - 312px)"
            gridGap="2"
            overflowY="auto"
            pr="1.5"
          >
            {clientsFound.map(client => (
              <SearchClientsCard
                key={client.id}
                client={client}
                termSearched={searchValue}
              />
            ))}
          </Flex>
        </DefaultCardWrapper>
      )}
    </PageWrapper>
  );
};
