import { queryClient } from 'App';
import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import {
  IGeneralWalletMibs,
  IGeneralWalletName,
  IGeneralWalletSourceAction,
  ITransactionType,
} from '../dtos';
import { MIBS_QUERIES_KEYS } from './MIBS_QUERIES_KEYS';

export type IGeneralWalletMibsOperationVariables = {
  mibsQuantity: string;
  operation: ITransactionType;
  name: IGeneralWalletName;
  employeeId: string;
  sourceAction: IGeneralWalletSourceAction;
  transactionResponsible?: string;
};

export const useGeneralWalletMibsOperations = (): UseMutationResult<
  unknown,
  unknown,
  IGeneralWalletMibsOperationVariables
> => {
  return useMutation({
    mutationFn: async ({
      mibsQuantity,
      operation,
      name,
      employeeId,
      sourceAction,
      transactionResponsible,
    }: IGeneralWalletMibsOperationVariables) => {
      const response = await hubApiCaptation.generalMibsOperations({
        name,
        mibsQuantity: Number(mibsQuantity),
        operation,
        employeeId,
        sourceAction,
        transactionResponsible,
      });
      return response;
    },
    onMutate: async response => {
      await queryClient.cancelQueries({
        queryKey: [MIBS_QUERIES_KEYS.generalWalletMibs],
      });

      const previousGeneralWalletMibs = queryClient.getQueryData([
        MIBS_QUERIES_KEYS.generalWalletMibs,
      ]);

      queryClient.setQueryData<IGeneralWalletMibs[]>(
        [MIBS_QUERIES_KEYS.generalWalletMibs],
        (old: IGeneralWalletMibs[] | undefined) => {
          if (!old) {
            return [];
          }

          const newArray = old.map(item => {
            const newItem = { ...item };

            if (newItem.sourceWallet === response.name) {
              if (response.operation === 'DEPOSIT') {
                newItem.mibs += Number(response.mibsQuantity);
              } else {
                newItem.mibs -= Number(response.mibsQuantity);
              }
            }

            return newItem;
          });

          return newArray;
        }
      );

      return { previousGeneralWalletMibs };
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [MIBS_QUERIES_KEYS.generalWalletMibs],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.individualEmployeeMibs);
      queryClient.invalidateQueries(MIBS_QUERIES_KEYS.generalWalletMibs);
    },
  });
};
