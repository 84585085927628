import { Stack } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { OrdersPanelData } from 'pages/MyClients/interfaces';
import { formatDate } from 'utils/formatDate';

interface OrderInfoProps {
  order: OrdersPanelData;
  handleToggleModal: () => void;
}

export const OrderInfo: React.FC<OrderInfoProps> = ({
  order,
  handleToggleModal,
}) => {
  return (
    <DefaultModalContentWrapper maxW="490px">
      <ModalTitle>Àrea - {order.area}</ModalTitle>

      <Stack spacing="2">
        {order.type && (
          <DisplayInformation label="Tipo" info={String(order.type)} />
        )}
        {order.currency && (
          <DisplayInformation
            label="Tipo da moeda"
            info={String(order.currency)}
          />
        )}
        {order.risk && (
          <DisplayInformation label="Rísco" info={String(order.risk)} />
        )}
        {order.segment && (
          <DisplayInformation label="Segmento" info={String(order.segment)} />
        )}

        {order.targetCurrency && (
          <DisplayInformation
            label="Moeda destino"
            info={String(order.targetCurrency)}
          />
        )}

        {order.value && (
          <DisplayInformation label="Valor" info={String(order.value)} />
        )}
        {order.status && (
          <DisplayInformation label="Status" info={String(order.status)} />
        )}
        {order.created_at && (
          <DisplayInformation
            label="Data"
            info={formatDate(order.created_at)}
          />
        )}
      </Stack>

      <BaseButtonGroup>
        <SecondaryButton onClick={handleToggleModal}>Fechar</SecondaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
