import { Flex, Text } from '@chakra-ui/react';
import { FcApproval, FcSurvey } from 'react-icons/fc';
import history from 'services/history';
import { formatDate } from 'utils/formatDate';
import partyIcon from 'shared/assets/images/assignments/party-icon.svg';
import { WrapperCalendarContact } from '../../elements/WrapperCalendarContact';
import { WrapperCardContact } from '../../elements/WrapperCardContact';
import { CalendarItem } from '../CalendarItem';
import { TaskTypes } from 'pages/Home/interfaces/assignments/Assignment';

interface ContentCalendarProps {
  allArraysCopy: any[];
}

export const ContentCalendar: React.FC<ContentCalendarProps> = ({
  allArraysCopy,
}) => {
  const handleRedirectUser = (btgClient: string) => {
    history.push(`/user/users_clients/new/${btgClient}`);
  };

  return (
    <WrapperCalendarContact>
      {allArraysCopy.length < 1 && (
        <Flex w="100%" h="80%" align="center" justify="center">
          <Text
            w="100%"
            fontWeight="light"
            mt="16"
            fontStyle="italic"
            textAlign="center"
          >
            Não há pendencias com esses filtros
          </Text>
        </Flex>
      )}

      {allArraysCopy.map((item, key) => {
        return (
          <>
            <WrapperCardContact key={`${item.id}-${key}`}>
              <Flex bg="background.700" p="1.5" px="4" borderRadius="full">
                <Text>{formatDate(new Date(item.data).toISOString())}</Text>
              </Flex>

              {item.content.map((subContent: any) => {
                if (subContent.id) {
                  return (
                    <CalendarItem
                      subContent={subContent}
                      clientName={subContent.name || subContent.clientName}
                      pendecyName={
                        subContent.typetask === TaskTypes.birthday
                          ? 'Aniversário'
                          : subContent.typetask === TaskTypes.managerContact
                          ? 'Reunião'
                          : subContent.typetask ===
                            TaskTypes.personalizedMeeting
                          ? 'Reunião personalizada'
                          : subContent.typetask === TaskTypes.periodicMeeting
                          ? 'Reunião periódica'
                          : subContent.typetask === TaskTypes.preNps
                          ? 'Pré-NPS'
                          : subContent.typetask === TaskTypes.nps
                          ? 'Pesquisa NPS'
                          : ''
                      }
                      IconElement={
                        subContent.typetask === TaskTypes.managerContact
                          ? FcApproval
                          : subContent.typetask === TaskTypes.periodicMeeting
                          ? FcApproval
                          : subContent.typetask ===
                            TaskTypes.personalizedMeeting
                          ? FcApproval
                          : subContent.typetask === TaskTypes.preNps
                          ? FcSurvey
                          : subContent.typetask === TaskTypes.nps
                          ? FcSurvey
                          : FcSurvey
                      }
                      iconSrc={
                        subContent.typetask === TaskTypes.birthday
                          ? partyIcon
                          : ''
                      }
                      handleRedirectUser={handleRedirectUser}
                    />
                  );
                }

                return (
                  <CalendarItem
                    subContent={subContent}
                    clientName={subContent.name}
                    pendecyName="Reunião periódica futura"
                    IconElement={FcApproval}
                    handleRedirectUser={handleRedirectUser}
                  />
                );
              })}
            </WrapperCardContact>
          </>
        );
      })}
    </WrapperCalendarContact>
  );
};
