import React, { useState, useEffect } from 'react';

import { errorHandler } from 'utils';
import { formatDateToRhSearch } from 'utils/formatDate';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';

import { DefaultCardWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { AnnouncementCard } from 'pages/HUB/components/elements/AnnouncementCard';
import { Text } from '@chakra-ui/react';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

const Announcements: React.FC = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    async function getAnnouncements() {
      try {
        const searchDate = formatDateToRhSearch();
        const responseData = await hubApiRH.listAnnouncements(searchDate);
        setAnnouncements(responseData);
      } catch (err) {
        errorHandler(err);
      }
    }

    getAnnouncements();
  }, []);

  return (
    <PageWrapper alignItems="center" paddingTop="2.5rem">
      <BackPageButton />

      <MainPageTitle>Comunicados MI</MainPageTitle>

      <DefaultCardWrapper
        flexDir="column"
        width="100%"
        maxW="760px"
        p="0"
        px={1}
        overflowY="auto"
        minH="300px"
        background="transparent"
        maxH="calc(100vh - 220px)"
      >
        {announcements.map((announcement, index) => (
          <AnnouncementCard key={index} announcement={announcement} />
        ))}

        {announcements.length < 1 && (
          <Text
            w="100%"
            fontWeight="light"
            mt="16"
            fontStyle="italic"
            textAlign="center"
          >
            Não há comunicados recentes!
          </Text>
        )}
      </DefaultCardWrapper>
    </PageWrapper>
  );
};

export default Announcements;
