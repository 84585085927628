import { useCallback, useEffect, useState } from 'react';
import { Flex, Stack } from '@chakra-ui/react';

import { showToast } from 'utils';
import { ModalWrapper } from 'components/elements/wrappers';
import { SecondaryButton } from 'components/elements/buttons';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { CardListAgreement } from 'pages/SearchUserDetails/components/cards';
import { ModalCreateOrUpdateAgreement } from 'pages/SearchUserDetails/components/wrappers/ModalCreateOrUpdateAgreement';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

interface AgreementProps {
  employee: EmployeesProps;
}

export const Agreement: React.FC<AgreementProps> = ({ employee }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [allAgreement, setAllAgreement] = useState<IAgreement[]>([]);

  const handleToggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const handleGetAllAgreements = useCallback(async () => {
    try {
      const response = await hubApiUser.getAllAgreement(employee.id);

      setAllAgreement(response);
    } catch (err) {
      showToast('Erro ao buscar os acordos');
    }
  }, []);

  useEffect(() => {
    handleGetAllAgreements();
  }, []);

  return (
    <>
      <Flex
        w="100%"
        flexDir="column"
        height={['100vh', 'auto']}
        maxH={['60vh', 'auto']}
      >
        <SecondaryButton
          width="fit-content"
          mb="20px"
          onClick={handleToggleModal}
        >
          Criar Acordo
        </SecondaryButton>

        <Stack maxH="calc(100vh - 280px)" spacing={12} overflowY="auto">
          {allAgreement.map((item, key) => (
            <CardListAgreement
              key={key}
              agreement={item}
              setAllAgreement={setAllAgreement}
              allAgreement={allAgreement}
            />
          ))}
        </Stack>
      </Flex>

      <ModalWrapper isOpen={modal} onClose={handleToggleModal}>
        <ModalCreateOrUpdateAgreement
          setAllAgreement={setAllAgreement}
          allAgreement={allAgreement}
          employeeId={employee.id}
          onClose={handleToggleModal}
        />
      </ModalWrapper>
    </>
  );
};
