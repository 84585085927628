import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { ProductsPageLayout } from '../../../components/layouts';
import PublicOfferingCard from '../../../components/modules/publicOffering/PublicOfferingCard';

import { PublicOfferingProps } from '../../../interfaces';

export const ListPublicOffering: React.FC = () => {
  const [publicOffering, setPublicOffering] = useState<PublicOfferingProps[]>(
    []
  );

  useEffect(() => {
    hubApiProducts
      .getAllIPublicOffering()
      .then(response => {
        setPublicOffering(response);
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao listar, tente novamente.');
      });
  }, []);

  if (publicOffering.length <= 0) {
    return (
      <ProductsPageLayout pageTitle="Oferta Pública Renda Fixa">
        <Text>Não existem Oferta Pública Renda Fixa cadastradas.</Text>
      </ProductsPageLayout>
    );
  }

  return (
    <ProductsPageLayout pageTitle="Oferta Pública Renda Fixa">
      {publicOffering.map(publicOffer => (
        <PublicOfferingCard
          key={publicOffer.id}
          publicOfferingData={publicOffer}
        />
      ))}
    </ProductsPageLayout>
  );
};
