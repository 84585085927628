import { SimpleGrid } from '@chakra-ui/react';
import { ClientType } from 'sharedV2/types/client';
import { ClientCard } from '../card';

type PropsType = {
  clients: ClientType[];
};

export const ClientsMosaic: React.FC<PropsType> = ({
  clients,
}): JSX.Element => {
  return (
    <SimpleGrid
      columns={[1, 2, 3, 4, null, 5]}
      gap={3}
      maxH="calc(100vh - 250px)"
      overflowY="auto"
      className="thinScrollbar scrollContainer"
    >
      {clients.map(client => (
        <ClientCard key={client.btgCode} client={client} />
      ))}
    </SimpleGrid>
  );
};
