import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import history from 'services/history';

import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { IReduxState } from 'shared/interfaces';

import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { BiInfoCircle } from 'react-icons/bi';

interface UserCardProps {
  user: EmployeesProps;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const [hasPermission, setHasPermission] = useState(false);
  const permissions = Array.from(
    new Set(
      user.permissions?.map(item => {
        return item.permissions.name;
      })
    )
  );

  const employee = useSelector((state: IReduxState) => state.user.profile);

  const handleSeeUserInfos = () => {
    if (!hasPermission) return;
    history.push(`/users/search/${user.id}`);
  };

  const checkIfUserHasPermission = () => {
    setHasPermission(
      verifyEmployeePermission({
        user: employee,
        permissionCod: ['GTR', 'GTM', 'CLT'],
        areaCod: ['ASS', 'FI', 'RH'],
      })
    );
  };

  useEffect(() => {
    checkIfUserHasPermission();
  }, []);

  return (
    <Flex
      bg="#f2f2f2"
      borderRadius="5px"
      flexDir="column"
      mb="12px"
      p="12px 10px"
      borderColor="transparent"
      borderWidth="1px"
      onClick={handleSeeUserInfos}
      _hover={{
        borderColor: hasPermission ? 'primary.500' : 'background.500',
        cursor: hasPermission ? 'pointer' : 'inherit',
        transition: '0.3s',
      }}
    >
      <Stack spacing="2">
        <Flex w="100%" justifyContent="space-between">
          <Text
            color="rgba(70, 70, 70, 1)"
            fontSize="md"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {user.name}
          </Text>

          {user.agreements !== 'ok' &&
            user.agreements !== null &&
            hasPermission && (
              <Tooltip
                label={
                  user.agreements !== 'ok' &&
                  user.agreements !== null &&
                  'Acordo(s) pendente(s)'
                }
                color="blackAlpha.900"
                placement="right"
              >
                <Flex cursor="pointer">
                  <BiInfoCircle size={16} color="rgba(70, 70, 70, 1)" />
                </Flex>
              </Tooltip>
            )}
        </Flex>

        <Flex>
          {user.position && (
            <Text
              textTransform="capitalize"
              color="rgba(70, 70, 70, 1)"
            >{`${user.position.toLowerCase()}: `}</Text>
          )}

          {user.BTGCode && <Text ml="4px">{user.BTGCode}</Text>}
        </Flex>
        <Text
          mt="12px"
          color="rgba(70, 70, 70, 1)"
        >{`Telefone: ${user.phone}`}</Text>
        <Text
          mt="12px"
          color="rgba(70, 70, 70, 1)"
        >{`E-mail: ${user.email}`}</Text>
        <Text mt="12px" color="rgba(70, 70, 70, 1)">{`Funções: ${
          permissions.length > 0
            ? permissions.map(item => `${item}`)
            : 'Não definido'
        }`}</Text>
      </Stack>
    </Flex>
  );
};

export default UserCard;
