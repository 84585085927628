import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDisclosure } from '@chakra-ui/hooks';

import { OrderInfo } from 'pages/Exchange/components/modules/transferOrders';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import { TransferOrderCard } from 'components/elements/cards';
import { ExchangeOrderProps } from 'pages/Exchange/interfaces';
import { BackPageButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';

export const ExchangeTransferOrders: React.FC = () => {
  const [infosCambio, setInfosCambio] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedOrder, setSelectedOrder] = useState<ExchangeOrderProps>(
    {} as ExchangeOrderProps
  );

  const handleShowModal = (clickedOrder: ExchangeOrderProps) => {
    setSelectedOrder(clickedOrder);
  };

  useEffect(() => {
    listOrders();
  }, []);

  async function listOrders() {
    await hubApiExchange
      .listOrdersByFilter('mosaico')
      .then(response => setInfosCambio(response));
  }

  const handleRemoveOrder = useCallback(
    id => {
      setInfosCambio(
        infosCambio.filter((item: { id: string }) => item.id !== id)
      );
    },
    [infosCambio]
  );

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle color="white" textAlign="center">
        Transferência de Ordens - Câmbio
      </MainPageTitle>

      <TransferOrdersCardsWrapper>
        {infosCambio.map((item: ExchangeOrderProps, index: number) => (
          <TransferOrderCard
            key={index}
            mainText={item.type}
            secondaryText={item.name}
            onClick={() => {
              handleShowModal(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo
          order={selectedOrder}
          handleToggleOrderModal={onClose}
          handleRemoveOrder={handleRemoveOrder}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
