import { Flex, Text } from '@chakra-ui/react';

interface TopBlockDatasetProps {
  conversionComputedPercentage: string;
  isSmallVersion?: boolean;
}

export const TopBlockDataset: React.FC<TopBlockDatasetProps> = ({
  conversionComputedPercentage,
  isSmallVersion,
}): JSX.Element => {
  return (
    <Flex flexDir="column" align="center" maxW="40%">
      <Text
        px="0"
        color="white.300"
        textAlign="center"
        lineHeight="120%"
        fontSize={isSmallVersion ? 'sm' : 'md'}
        mb="1"
      >
        Conversão para reuniões realizadas
      </Text>
      <Text
        color="white.300"
        fontWeight="bold"
        fontSize={isSmallVersion ? 'xl' : '2xl'}
        lineHeight="100%"
      >
        {conversionComputedPercentage} %
      </Text>
    </Flex>
  );
};
