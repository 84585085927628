import { useCallback } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { Divider } from 'components/elements/others';
import { DefaultCardWrapper } from 'components/elements/wrappers';
import { IEventExtended } from 'pages/Events/interfaces/Event';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import IconCalendar from './IconCalendar.png';

interface ICardEventsProps {
  CardInfos: IEventExtended;
  handleShowModal: () => void;
  setEventCardSelected: React.Dispatch<React.SetStateAction<IEventExtended>>;
}

export const CardEvents: React.FC<ICardEventsProps> = ({
  CardInfos,
  handleShowModal,
  setEventCardSelected,
}) => {
  const handleClick = (CardInfo: IEventExtended) => {
    setEventCardSelected(CardInfo);
    return handleShowModal();
  };

  const parseDate = new Date(
    `${CardInfos.eventDateAndHour?.split('T')[0]}T00:00:00`
  );
  const verifyIfDateIsEqual = useCallback((dateLeft, dateRight) => {
    return (
      dateLeft.getFullYear() === dateRight.getFullYear() &&
      dateLeft.getMonth() === dateRight.getMonth() &&
      dateLeft.getDate() === dateRight.getDate() &&
      dateLeft.getHours() === dateRight.getHours() &&
      dateLeft.getMinutes() === dateRight.getMinutes()
    );
  }, []);
  return (
    <DefaultCardWrapper
      width="100%"
      maxH="100px"
      maxW={['100%', '49%', '100%', '49%']}
      background="background.400"
      px="4"
      alignItems="center"
      py="3"
      gridGap="3"
      cursor="pointer"
      onClick={() => handleClick(CardInfos)}
      position="relative"
    >
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <>
          <Flex
            justifyContent="center"
            alignItems="center"
            background="background.600"
            borderRadius="sm"
            px="2.5"
            py="0.5"
          >
            <Text fontWeight="semibold" fontSize="x-small">
              {parseDate
                .toLocaleString('default', { month: 'short' })
                .toUpperCase()}
            </Text>
          </Flex>

          <Text
            fontSize={{ base: 'lg', md: '2xl' }}
            lineHeight="100%"
            mt="2"
            fontWeight="bold"
            color={
              verifyIfDateIsEqual(new Date(), parseDate)
                ? 'orange.400'
                : 'white'
            }
          >
            {parseDate
              .toLocaleString('default', {
                day: 'numeric',
              })
              .toUpperCase()
              .padStart(2, '0')}
          </Text>
        </>
      </Flex>
      <Divider orientation="vertical" />
      <Box position="absolute" top="13px" right="13px">
        <img src={IconCalendar} alt="ícone de calendário" width="20px" />
      </Box>
      <Flex flexDir="column" justifyContent="center" width="80%">
        <Text fontSize="sm" fontWeight="semibold" color="gray.400">
          {formatNameToCaptalize(CardInfos.eventType)}
        </Text>
        <Text
          color="white"
          fontWeight="bold"
          fontSize={{ base: 'md', md: 'md', lg: 'lg' }}
          width="100%"
          maxW={{ base: '90%', xl: '274px', '2xl': '384px' }}
          isTruncated
        >
          {CardInfos.eventName.toUpperCase()}
        </Text>
      </Flex>
    </DefaultCardWrapper>
  );
};
