import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import UploadButton from 'components/elements/forms/UploadButton';
import { LoadingSpinner } from 'components/elements/others';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { OrderProps } from '../kanbanBase/BoardOrderInfo';

interface IProps {
  order: OrderProps;
  clientId: string;
  setIsOpenFileModal: (value: boolean) => void;
  handleGetFiles: () => void;
}

export const FileSaveModal: React.FC<IProps> = ({
  order,
  clientId,
  setIsOpenFileModal,
  handleGetFiles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState<any>({
    name: 'Escolha um arquivo',
  });

  const handleFileUpload = async () => {
    if (fileSelected.name === 'Escolha um arquivo') {
      return toast.dark('Selecione um arquivo para fazer upload.');
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('filename', fileSelected);
      await hubApiIp.saveFileCRM(order.orderId, clientId, formData);
      handleGetFiles();
      toast.dark('Upload do arquivo feito com sucesso!');
      setIsOpenFileModal(false);
    } catch (err) {
      toast.dark(
        'Não foi possivel fazer o upload do arquivo. Tente novamente mais tarde.'
      );
    }
    setIsLoading(false);
  };

  return (
    <DefaultModalContentWrapper>
      <Text>Selecione um arquivo para fazer upload</Text>
      <UploadButton
        label={fileSelected.name}
        onChange={e => e.target?.files && setFileSelected(e.target?.files[0])}
      />
      <Flex pt={5} gap={3} justifyContent="flex-end">
        <SecondaryButton onClick={() => setIsOpenFileModal(false)}>
          Fechar
        </SecondaryButton>
        <PrimaryButton onClick={handleFileUpload} w="150px">
          {isLoading ? <LoadingSpinner size="sm" /> : 'Fazer Upload'}
        </PrimaryButton>
      </Flex>
    </DefaultModalContentWrapper>
  );
};
