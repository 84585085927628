import { PageWrapper } from 'componentsV2/elements/wrappers/page/index';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { handleErrorMessage } from 'utils/handleErrorMessage';
import { ReminderCards } from './components/reminderCards';
import { HomeSkeletonLoading } from './components/skeletonLoading';
import { Tasks } from './components/tasks';

export const Home = (): JSX.Element => {
  const { data: pendencies, isLoading: isLoadingPendencies } = useQuery({
    queryKey: ['homePendencies'],
    queryFn: async () => {
      const response = await hubApiClients.getPendencies();
      return response;
    },
    onError: error => handleErrorMessage(error),
  });

  const { data: cards, isLoading: isLoadingCards } = useQuery({
    queryKey: ['homeCards'],
    queryFn: async () => {
      const response = await hubApiProducts.getAllCards();

      return response;
    },
    onError: error => handleErrorMessage(error),
  });

  const isLoading = isLoadingCards || isLoadingPendencies;

  if (isLoading) {
    return <HomeSkeletonLoading />;
  }

  return (
    <PageWrapper gap={12} paddingTop="4.5rem">
      <ReminderCards cards={cards} />
      <Tasks tasks={pendencies} />
    </PageWrapper>
  );
};
