import { AssignmentProps } from 'pages/Home/interfaces';
import { HubApi } from '../index';

interface IAttHomeAssignments {
  id: string;
  status: string;
}

interface IUpdateEmployeeOrder {
  area: string;
  client: string;
  date: string;
  name: string;
  orderId: string;
  status: string;
  id: string;
}

class HubApiHome extends HubApi {
  async listHomeAssignments(employeeId?: string) {
    if (employeeId) {
      const response = await this.axios.get<AssignmentProps[]>(
        `/task?employeeId=${employeeId}`
      );
      return response.data;
    }
    const response = await this.axios.get<AssignmentProps[]>(`/task`);
    return response.data;
  }

  async deleteHomeAssignmentById(id: string) {
    const response = await this.axios.delete(`/pendency/${id}`);
    return response.data;
  }

  async listGraphicOffices() {
    const response = await this.axios.get(`/graphics/captation/offices/`);
    return response.data;
  }

  async listLastOrders(initial: string, end: string, area: string) {
    const response = await this.axios.get(
      `/orders/home/${initial}/${end}/${area}/all`
    );
    return response.data;
  }

  async listHomeLastOrders() {
    const response = await this.axios.get(`/orders/home/15`);
    return response.data;
  }

  async listHomeOrdersModal(code: string, id: string) {
    const response = await this.axios.get(`/orders/home/unique/${code}/${id}`);
    return response.data;
  }

  async listEmployeeStats() {
    const response = await this.axios.get('/clients/stats');
    return response.data;
  }

  async listCaptationRanking() {
    const response = await this.axios.get('/clients/ranking');
    return response.data;
  }

  async listEmployees() {
    const response = await this.axios.get('/employeesresignation/employees');
    return response.data;
  }

  async listEmployeeOrders(id: string) {
    const response = await this.axios.get(`/employeesresignation/orders/${id}`);
    return response.data;
  }

  async listEmployeesExchange() {
    const response = await this.axios.get(`/employees/area/EX`);
    return response.data;
  }

  async listEmployeesRh() {
    const response = await this.axios.get(`/employees/area/RH`);
    return response.data;
  }

  async listEmployeesGuru() {
    const response = await this.axios.get(`/employees/by/permission/guru`);
    return response.data;
  }

  async listEmployeesIp() {
    const response = await this.axios.get(`/employees/area/IP`);
    return response.data;
  }

  async listEmployeesTi() {
    const response = await this.axios.get(`/employees/area/TI`);
    return response.data;
  }

  async listEmployeesMk() {
    const response = await this.axios.get(`/employees/area/MKT`);
    return response.data;
  }

  async listEmployeesRv() {
    const response = await this.axios.get(`/employees/area/RV`);
    return response.data;
  }

  async updateEmployeeOrder(
    id: string,
    employeeid: IUpdateEmployeeOrder | undefined,
    status: string,
    type: string
  ) {
    const response = await this.axios.patch(
      `/employeesresignation/orders/${id}/${employeeid}`,
      { status, type }
    );
    return response.data;
  }

  async attHomeAssignments(data: IAttHomeAssignments) {
    const response = await this.axios.patch(`/clients/negative`, data);
    return response.data;
  }

  async pathReasonAssignment(data: {
    additionalInformation?: string;
    id?: string;
    status?: string;
  }) {
    const response = await this.axios.patch(`/clients/contact`, data);
    return response.data;
  }

  async getEmployeeGraph() {
    const response = await this.axios.get(`/clients/graph`);
    return response.data;
  }
}

export const hubApiHome = new HubApiHome();
