import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { IPOProps } from 'pages/products/interfaces';
import { ProductsPageLayout } from 'pages/products/components/layouts';
import { toast } from 'react-toastify';
import IPOCard from '../../../components/modules/ipo/IPOCard';

export const ListIPOs: React.FC = () => {
  const [ipos, setIpos] = useState<IPOProps[]>([]);

  useEffect(() => {
    hubApiProducts
      .getAllIpos()
      .then(response => {
        setIpos(response);
      })
      .catch(() => {
        toast.dark('Não foi possivel carregar as IPOs');
      });
  }, []);

  if (ipos.length <= 0) {
    return (
      <ProductsPageLayout pageTitle="IPOs">
        <Text>Não existem IPOs cadastrados.</Text>
      </ProductsPageLayout>
    );
  }

  return (
    <ProductsPageLayout pageTitle="IPOs e Subscrições">
      {ipos.map(ipo => (
        <IPOCard ipoData={ipo} key={ipo.id} />
      ))}
    </ProductsPageLayout>
  );
};
