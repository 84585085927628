import {
  MenuButtonProps,
  MenuButton as ChakraMenuButton,
  Flex,
} from '@chakra-ui/react';

export const MenuButton: React.FC<
  MenuButtonProps & { shouldHaveHover?: boolean }
> = ({ children, shouldHaveHover = true, ...rest }): JSX.Element => {
  return (
    <ChakraMenuButton
      as={Flex}
      border="none"
      bgColor="transparent"
      outline="none"
      boxShadow="none"
      alignItems="center"
      px={2}
      color="rgba(70, 70, 70, 1)"
      cursor="pointer"
      justifyContent="center"
      _hover={shouldHaveHover ? { bgColor: '#1D63DD', color: 'white' } : {}}
      _focus={{ outline: 'none' }}
      borderRadius={5}
      {...rest}
    >
      {children}
    </ChakraMenuButton>
  );
};
