import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import { NoticeProps } from 'pages/notices/interfaces';
import React, { useState } from 'react';
import { UpdateNotice } from '../UpdateNotice';

interface ItemProps {
  notice: NoticeProps;
  handleDeleteNotice: (id: string) => void;
  handleModalUpdate: (notice: NoticeProps) => void;
}

export const Item: React.FC<ItemProps> = ({
  notice,
  handleModalUpdate,
  handleDeleteNotice,
}) => {
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <DefaultCardWrapper
      justifyContent="space-between"
      bg="background.300"
      mb="5px"
      p="3"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text color="white" fontSize="14px">
          {notice.title}
        </Text>

        <Flex justifyContent="flex-end" alignItems="center">
          <EditIcon
            mr="15px"
            w="20px"
            h="20px"
            cursor="pointer"
            color="white"
            onClick={handleToggleModal}
          />

          <DeleteIcon
            onClick={() => handleDeleteNotice(notice.id)}
            w="20px"
            h="20px"
            cursor="pointer"
            color="white"
          />

          <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
            <Flex justifyContent="center" alignItems="center" h="100vh">
              <UpdateNotice
                notice={notice}
                handleModalUpdate={handleModalUpdate}
                handleToggleModal={handleToggleModal}
              />
            </Flex>
          </ModalWrapper>
        </Flex>
      </Flex>
      <Flex />
    </DefaultCardWrapper>
  );
};
