import React, { useState } from 'react';
import { Checkbox, Flex, Image, Text } from '@chakra-ui/react';
import {
  FcAbout,
  FcApproval,
  FcConferenceCall,
  FcExpired,
  FcGlobe,
  FcHighPriority,
  FcReading,
  FcSurvey,
  FcVoicePresentation,
} from 'react-icons/fc';
import { AssignmentProps } from 'pages/Home/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import partyIcon from 'shared/assets/images/assignments/party-icon.svg';
import { ModalWrapper } from 'components/elements/wrappers';
import {
  AccessSharedContentModal,
  NewClientModal,
  OriginPendencieModal,
} from 'pages/Home/components/modules/modals';
import { ConfirmationModal } from 'components/modules/Pendencies';
import { PendencyConfirmInput } from 'components/modules/PendencyConfirmInput';
import { formatToDate } from 'brazilian-values';
import { MeetingTodayCardModal } from 'pages/Home/components/modules/modals/MeetingTodayCardModal';
import { WarningCircle } from 'phosphor-react';
import { RiCoinsLine } from 'react-icons/ri';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const ToDoListCard: React.FC<ComponentProps> = ({
  assignment,
  handleRemoveItem,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsCheckBoxChecked(false);
  };

  let cardMessage;
  let icon: React.ReactNode;
  let modal: React.ReactNode;

  switch (assignment.typetask) {
    case 'sharedContent':
      cardMessage = `${formatNameToCaptalize(
        JSON.parse(assignment.extra || '').employeeWhoSharedContent
      )} compartilhou um conteúdo com você!`;
      icon = <FcReading />;
      modal = (
        <AccessSharedContentModal
          contentId={JSON.parse(assignment.extra || '').contentId}
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
          message="Ao acessar o conteúdo a tarefa será marcada como concluída"
        />
      );
      break;
    case 'pre-nps':
      cardMessage = 'Pré NPS:';
      icon = <FcSurvey />;
      modal = (
        <ConfirmationModal
          title="Pesquisa PRÉ NPS"
          message={`Você confirma que o(a) cliente ${assignment.name.toUpperCase()} foi avisado(a) sobre a pesquisa?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'left':
      cardMessage = 'Informe o motivo da saida do cliente:';
      icon = <FcHighPriority />;
      modal = (
        <PendencyConfirmInput
          title="Saída de cliente"
          message={`Informe o motivo `}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          clientId={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'nps':
      cardMessage = 'Pesquisa NPS amanhã:';
      icon = <FcSurvey />;
      modal = (
        <ConfirmationModal
          title="Pesquisa NPS"
          message={`Você confirma que o(a) cliente ${assignment.name.toUpperCase()} foi avisado(a) sobre a pesquisa?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'inative':
      cardMessage = 'Cliente inativo:';
      icon = <FcHighPriority />;
      modal = (
        <ConfirmationModal
          title="Cliente Inativo"
          message={`Você confirma que o(a) cliente ${assignment.name.toUpperCase()} ativou a conta?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'birthday':
      cardMessage = 'Aniversário do cliente:';
      icon = (
        <Image src={partyIcon} alt="ícone de tarefa" width="100%" maxW="26px" />
      );
      modal = (
        <ConfirmationModal
          title="Aniversário"
          message={`Você confirma que parabenizou o(a) cliente ${assignment.name.toUpperCase()}?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'birthdayManager':
      cardMessage = 'Aniversário do cliente:';
      icon = (
        <Image src={partyIcon} alt="ícone de tarefa" width="100%" maxW="26px" />
      );
      modal = (
        <ConfirmationModal
          title="Aniversário"
          message={`Você confirma que parabenizou o(a) cliente ${assignment.name.toUpperCase()}?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'noTelegram':
      cardMessage = 'Telegram: você ainda não integrou o Telegram';
      icon = <FcAbout />;
      modal = (
        <ConfirmationModal
          title="Telegram não integrado"
          message="Você confirma que já integrou seu Telegram?"
          secondMessage=" Para cadastrar, procure dentro do Telegram @EWZNotificacoesBot e clique /start na mensagem. Quando perguntar o e-mail, escreva seu e-mail do HUB."
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'balance':
      cardMessage = 'Cliente negativo:';
      icon = <FcHighPriority />;
      modal = (
        <ConfirmationModal
          title="Cliente negativo"
          message={`Você confirma que o(a) cliente ${assignment?.name?.toUpperCase()} não está negativo(a)?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'client':
      cardMessage = 'Novo Cliente:';
      icon = <FcApproval />;
      modal = (
        <NewClientModal
          assignment={assignment}
          handleToggleModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'fromType':
      cardMessage = 'Origem do cliente:';
      icon = <FcGlobe />;
      modal = (
        <OriginPendencieModal
          assignment={assignment}
          handleRemoveItem={handleRemoveItem}
          handleToggleModal={handleCloseModal}
        />
      );
      break;
    case 'noticesManager':
      cardMessage = `Contato da gerência: com ${
        assignment.creatorName || 'gestor'
      } em ${assignment.data}`;
      icon = <FcVoicePresentation />;
      modal = (
        <ConfirmationModal
          title="Contato da gerência"
          message="Você confirma que esta tarefa foi cumprida?"
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'meetingNotConfirm':
      cardMessage = `Perda de pontos: reunião com ${assignment.extra?.toUpperCase()} sem feedback`;
      icon = <FcHighPriority />;
      modal = (
        <ConfirmationModal
          title="Reunião sem feedback"
          message={`Você perdeu pontos por não dar um feedback na reunião com o ${assignment.extra?.toUpperCase()}  as   ${formatToDate(
            new Date(assignment.created_at)
          )} ?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'meetingToday':
      cardMessage = 'Reunião hoje com o cliente:';
      icon = <FcConferenceCall />;
      modal = (
        <MeetingTodayCardModal
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'stuckMoney':
      cardMessage = `Dinheiro parado: `;
      icon = <WarningCircle size={20} color="#f2c240" weight="fill" />;
      modal = (
        <ConfirmationModal
          title="Dinheiro parado"
          message={`Você confirma que o(a) dinheiro do cliente ${
            JSON.parse(assignment.extra || '').name
          } já foi alocado?`}
          btgAccount={assignment.btgCode}
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    case 'noLeads':
      cardMessage = `Sem Leads: ${assignment.extra || ''}`;
      icon = <FcExpired />;
      modal = (
        <ConfirmationModal
          title="Sem Leads"
          message={assignment.extra || ''}
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;

    case 'noFeedbackPenalty':
      cardMessage = `MIBs descontados: Reunião sem feedback`;
      icon = <RiCoinsLine color="#FFB32C" />;
      modal = (
        <ConfirmationModal
          title="Reunião sem feedback"
          message={assignment.extra || ''}
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
        />
      );
      break;
    default:
      cardMessage = '';
      icon = <FcGlobe />;
      modal = (
        <AccessSharedContentModal
          contentId={JSON.parse(assignment.extra || '').contentId}
          idTask={assignment.id}
          handleCloseModal={handleCloseModal}
          handleRemoveItem={handleRemoveItem}
          message="Ao acessar o conteúdo a tarefa será marcada como concluída"
        />
      );
  }

  const handleCheckBox = () => {
    if (!isCheckBoxChecked) {
      setIsCheckBoxChecked(true);
      setIsModalOpen(true);
    } else {
      setIsCheckBoxChecked(false);
    }
  };

  let clientName = assignment.name
    ? formatNameToCaptalize(assignment.name)
    : assignment.clientName
    ? formatNameToCaptalize(assignment.clientName)
    : '';

  if (
    (clientName === '' && assignment.typetask === 'meetingToday') ||
    (clientName === '' && assignment.typetask === 'stuckMoney')
  ) {
    const { name } = JSON.parse(assignment.extra as string);
    clientName = formatNameToCaptalize(name);
  }

  return (
    <>
      <Flex
        w="100%"
        h="34px"
        backgroundColor="background.300"
        borderRadius={4}
        alignItems="center"
        gap={2}
        px={2}
      >
        <Flex justify="center" py={1} align="center" mt={1.5}>
          {!window.location.href.includes('employee_agenda') && (
            <Checkbox
              isChecked={isCheckBoxChecked}
              size="md"
              onChange={handleCheckBox}
            />
          )}
        </Flex>
        <Text
          fontSize="sm"
          color="gray.500"
          textDecoration={isCheckBoxChecked ? 'line-through' : 'normal'}
        >
          {cardMessage}{' '}
          <Text as="span" color="white">
            {`${clientName} ${
              assignment.btgAccount ? `(${assignment.btgAccount})` : ''
            }`}
          </Text>
        </Text>
        <Flex ml="auto">{icon}</Flex>
      </Flex>

      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modal}
      </ModalWrapper>
    </>
  );
};
