import { ResponsiveLine } from '@nivo/line';
import { formatToBRL } from 'brazilian-values';

interface dates {
  x: string;
  y: string;
}

interface ResposiveLineChartProps {
  date: dates[];
}

export const ResponsiveLinePlHome: React.FC<ResposiveLineChartProps> = ({
  date,
}) => {
  const minValue = date.map(item => {
    return Math.min(Number(item.y));
  });
  let minValueTest = minValue[0];

  minValue.forEach(item => {
    if (minValueTest > Number(item.toFixed(2))) {
      minValueTest = item;
    }
  });

  const data = [
    {
      id: 'NET',
      color: '#3071e4',
      data: date.map(item => {
        return {
          x: item.x.split('T')[0],
          y: Number(item.y),
        };
      }),
    },
  ];

  const color = data.map(item => {
    return item.color;
  });

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 8, right: 18, bottom: 26, left: 40 }}
      colors={color}
      animate
      enableArea
      areaOpacity={0.1}
      enablePoints
      enableSlices="x"
      pointColor="white"
      useMesh
      areaBaselineValue={minValueTest}
      xFormat="time:%Y-%m-%d"
      xScale={{
        type: 'time',
        precision: 'month',
        useUTC: false,
        format: '%Y-%m-%d',
      }}
      yFormat=">-.2f"
      axisBottom={{
        format: '%b',
        tickValues: 'every 1 month',
        legendOffset: -12,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 10,
        tickValues: 4,
        format: '>-.2s',
        legendPosition: 'end',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      lineWidth={3}
      theme={{
        textColor: '#ffffff',
        fontSize: 12,
        crosshair: {
          line: {
            strokeWidth: 1,
            stroke: '#777777',
            strokeOpacity: 0.5,
          },
        },
        grid: {
          line: {
            stroke: '#2b2929',
            strokeWidth: 0.3,
          },
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: '#2e2e2e',
              padding: '9px 12px',
              borderRadius: '6px',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: '#ffffff',
                  padding: '3px 0',
                  fontWeight: 'bold',
                }}
              >
                {formatToBRL(point.data.yFormatted)}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};
