export const tableHeader = [
  'Nome',
  'Frequência de Contato',
  'Origem do cliente',
  'Aniversário',
  'Saldo negativo',
  'Nps',
  'Cliente inativo',
  'Reuniões pendentes',
  'total',
];
