import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Modal } from 'componentsV2/elements/modal';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';

import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';

type PropsType = {
  lead: LeadParsedType | undefined;
  leadType: LeadTypeEnum;
};

export const LeadNoAnswerModal: React.FC<PropsType> = ({
  lead,
  leadType,
}): JSX.Element => {
  const { deleteParam } = useQueryParams();
  const { goBack } = useNavigation();

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);

        goBack();
      },
    });

  const isLoading = isLoadingMeetingAction;

  const handleLeadNoAnswer = async () => {
    await meetingAction({
      action: MeetingActionEnum.NO_ANSWER,
      leadType,
      meetingId: lead?.meeting?.id as string,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Contato não estabelecido" />
        <Modal.CloseIcon onClick={() => deleteParam('isOpenNoAnswerModal')} />
      </Modal.Header>

      <Flex flexDirection="column" gap={2}>
        <Text>
          Você confirma que não foi possível estabelecer contato com o lead?
        </Text>

        <Text fontWeight="bold" fontSize="sm">
          {`
          * Obs: Este campo NÃO é destinado para prospects que atenderam e não obtiveram interesse. Nesse caso, clique em "Atendeu" e depois "Declínio de oferta".
        `}
        </Text>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Confimar"
          onClick={handleLeadNoAnswer}
          isLoading={isLoading}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
