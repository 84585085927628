import { BsGraphUp } from 'react-icons/bs';

import { StrategyForm, StrategyRvForm } from './components/modules/forms';

export const monthStrategiesOption = [
  {
    title: 'Estratégias RV (Ações + Estruturadas)',
    modal: StrategyRvForm,
    icon: BsGraphUp,
    information:
      'Para os clientes que desejam alocar o recurso de forma contínua em Ações e Operações estruturadas.',
  },
  {
    title: 'Estratégia Fundos Imobiliários',
    modal: StrategyRvForm,
    icon: BsGraphUp,
    information:
      'Para os clientes que desejam alocar os recursos na carteira de Fundos Imobiliários',
  },
  {
    title: 'Estratégia Ações',
    modal: StrategyForm,
    icon: BsGraphUp,
    information:
      'Para os clientes que desejam alocar os recursos na carteira de Ações, sem alocações em Estruturadas.',
  },
];
