import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { Input } from 'componentsV2/elements/input';
import { InputTypeEnum } from 'componentsV2/elements/input/components/styles';
import { useUpdateLeadSalesHunterQuestion } from 'hooksV2/useUpdateLeadSalesHunterQuestion';
import { debounce } from 'lodash';
import { IAllBranchs } from 'pages/Captation/components/modules/SH/SHQuestionsForm';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import {
  MeetingFilialType,
  useScheduleFirstMeetingContext,
} from 'pagesV2/LeadDetails/context/useScheduleFirstMeetingContext';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { hubApiOfficeBranches } from 'services/hubApi/classes/HubApiOfficeBranches';
import { LeadShQuestionType } from 'sharedV2/types/leadShQuestion';
import { DropdownUtils } from 'utilsV2/Dropdown';

export const LeadDetailsQuestionsForm = (): JSX.Element => {
  const { lead } = useLeadDetailsContext();
  const {
    setLeadPl: setLeadPlInScheduleFirstMeetingContext,
    setMeetingFilial: setMeetingFilialInScheduleFirstMeetingContext,
  } = useScheduleFirstMeetingContext();

  const [leadQuestions, setLeadQuestions] = useState<LeadShQuestionType>(
    lead.questions as LeadShQuestionType
  );

  const profileOptions: BaseDropdownOptionsType[] = [
    {
      label: 'Conservador',
      value: 'Conservador',
    },
    {
      label: 'Moderado',
      value: 'Moderado',
    },
    {
      label: 'Agressivo',
      value: 'Agressivo',
    },
    {
      label: 'Trader',
      value: 'Trader',
    },
  ];

  const knowlegmentLevelOptions: BaseDropdownOptionsType[] = [
    {
      label: 'Leigo total',
      value: 'Leigo total',
    },
    {
      label: 'Conhecimento básico',
      value: 'Conhecimento básico',
    },
    {
      label: 'Acha que sabe',
      value: 'Acha que sabe',
    },
    {
      label: 'Conhecimento moderado',
      value: 'Conhecimento moderado',
    },
    {
      label: 'Conhecimento avançado',
      value: 'Conhecimento avançado',
    },
  ];

  const defaultShQuestions: {
    title: string;
    identifier: keyof LeadShQuestionType;
    inputType: InputTypeEnum;
  }[] = [
    { title: 'Corretora', identifier: 'broker', inputType: InputTypeEnum.TEXT },
    { title: 'Patrimônio', identifier: 'pl', inputType: InputTypeEnum.TEXT },
    { title: 'Idade', identifier: 'age', inputType: InputTypeEnum.NUMBER },
    {
      title: 'Ativos que investe',
      identifier: 'assets',
      inputType: InputTypeEnum.TEXT,
    },
    {
      title: 'Profissão',
      identifier: 'profession',
      inputType: InputTypeEnum.TEXT,
    },
    {
      title: 'Identificação da dor',
      identifier: 'trouble',
      inputType: InputTypeEnum.TEXT,
    },
  ];

  const { mutateAsync: updateLeadShQuestion } =
    useUpdateLeadSalesHunterQuestion({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead.id]);
      },
    });

  const { data: branchs } = useQuery<IAllBranchs[]>({
    queryKey: ['branchs'],
    queryFn: async () => {
      const response = await hubApiOfficeBranches.listBranchOffices();
      return response;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (JSON.stringify(leadQuestions) === JSON.stringify(lead.questions)) {
      return;
    }

    const handleDebounced = debounce(() => {
      updateLeadShQuestion(leadQuestions);
    }, 50);

    handleDebounced();

    return () => {
      handleDebounced.cancel();
    };
  }, [leadQuestions]);

  return (
    <Flex w="100%" flexDirection="column" gap={4} fontSize="0.8rem">
      <Flex w="100%" gap={2}>
        <Dropdown.Root
          w="calc(50% - 0.5rem)"
          onChange={event => {
            updateLeadShQuestion({
              ...leadQuestions,
              perfil: event.target.value,
            });
          }}
        >
          <Dropdown.Options
            selected={leadQuestions.perfil}
            options={profileOptions}
            placeholder="Perfil"
          />
        </Dropdown.Root>

        <Dropdown.Root
          w="50%"
          onChange={event => {
            updateLeadShQuestion({
              ...leadQuestions,
              knowledge: event.target.value,
            });
          }}
        >
          <Dropdown.Options
            selected={leadQuestions.knowledge}
            options={knowlegmentLevelOptions}
            placeholder="Conhecimento"
          />
        </Dropdown.Root>
      </Flex>
      <Flex flexDirection="column" w="100%" gap={2}>
        <Flex w="100%">
          <Text w="50%" color="rgba(70, 70, 70, 1)" fontSize="0.8rem">
            Filial
          </Text>
          <Dropdown.Root
            w="50%"
            onChange={event => {
              const parsedEvent = JSON.parse(
                event.target.value || '{}'
              ) as MeetingFilialType;

              setMeetingFilialInScheduleFirstMeetingContext({ ...parsedEvent });
            }}
          >
            <Dropdown.Options
              options={
                branchs &&
                branchs.map(branch =>
                  DropdownUtils.toBaseOptions({
                    label: branch.city,
                    value: JSON.stringify({ id: branch.id, city: branch.city }),
                  })
                )
              }
              placeholder="Selecione uma cidade"
            />
          </Dropdown.Root>
        </Flex>

        {defaultShQuestions.map((question, index) => (
          <Flex w="100%" key={index}>
            <Text w="50%" color="rgba(70, 70, 70, 1)" fontSize="0.8rem">
              {question.title}
            </Text>
            {question.identifier === 'pl' ? (
              <Input.Root w="50%" fontSize="0.8rem">
                <Input.Currency
                  value={leadQuestions[question.identifier]}
                  onValueChange={value => {
                    setLeadQuestions({
                      ...leadQuestions,
                      [question.identifier]: value,
                    });

                    if (value) setLeadPlInScheduleFirstMeetingContext(value);
                  }}
                />
              </Input.Root>
            ) : (
              <Input.Root w="50%" fontSize="0.8rem">
                <Input.TextField
                  fontSize="0.8rem"
                  type={question.inputType}
                  value={leadQuestions[question.identifier]}
                  onChange={event =>
                    setLeadQuestions({
                      ...leadQuestions,
                      [question.identifier]: event.target.value,
                    })
                  }
                />
              </Input.Root>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
