import React from 'react';
import {
  Flex,
  NumberInput as Input,
  NumberInputField,
  NumberInputFieldProps,
  Text,
} from '@chakra-ui/react';

interface ComponentProps extends NumberInputFieldProps {
  label: string;
  placeholder: string;
  defaultValue: number;
}

export const NumberInputWithLabel: React.FC<ComponentProps> = ({
  label,
  defaultValue,
  ...rest
}) => {
  return (
    <Flex flexDir="column" {...rest}>
      <Text mb="6px" fontSize="small" color="rgba(70, 70, 70, 1)">
        {label}
      </Text>
      <Input
        bg="#f2f2f2"
        borderColor="transparent"
        borderRadius="4px"
        focusBorderColor="primary.600"
        defaultValue={defaultValue}
      >
        <NumberInputField
          h="32px"
          color="rgba(70, 70, 70, 1)"
          bg="#E7E7E7"
          fontSize="sm"
          _placeholder={{ color: 'gray.600' }}
          _hover={{ borderColor: 'primary.800' }}
          {...rest}
        />
      </Input>
    </Flex>
  );
};
