import { ResponsiveLine } from '@nivo/line';
import { IInfosMundoInvestCpmm } from 'pages/GenerationalVariables/interfaces';

interface ResposiveLineChartProps {
  date: IInfosMundoInvestCpmm[];
  result: number[];
}

export const ResponsiveLineChartCPMM: React.FC<ResposiveLineChartProps> = ({
  date,
  result,
}) => {
  const handleResult = () => {
    const resultado: any[] = [];
    for (let i = 0; i < date.length; i++) {
      if (i >= 3) {
        resultado.push({
          x: date[i].date.split('T')[0],
          y: result,
        });
      }
    }

    return resultado;
  };

  const data = [
    {
      id: 'CPMM',
      color: '#053164',
      data: handleResult(),
      // data: date.map((item, key) => {
      //   return {
      //     x: item.date.split('T')[0],
      //     y: item.cpmm,
      //   };
      // }),
    },
  ];

  const color = data.map(item => {
    return item.color;
  });

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 24, right: 44, bottom: 46, left: 60 }}
      colors={color}
      animate
      useMesh
      areaOpacity={0.1}
      enablePoints
      enableSlices="x"
      // enablePointLabel
      pointColor="white"
      xFormat="time:%Y-%m-%d"
      curve="catmullRom"
      xScale={{
        type: 'time',
        precision: 'day',
        useUTC: false,
        format: '%Y-%m-%d',
      }}
      yFormat=">-.2s"
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 15,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'square',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      axisBottom={{
        format: '%b %Y',
        tickValues: 'every 14 days',
        legendOffset: -12,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 10,
        format: '>-.2s',
        legendPosition: 'end',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: 'white',
              padding: '1px 12px',
              border: '1px solid #ccc',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <strong>{point.serieId}</strong> [{point.data.yFormatted}]
              </div>
            ))}
          </div>
        );
      }}
      lineWidth={3}
      theme={{
        textColor: '#ffffff',
        fontSize: 16,
        crosshair: {
          line: {
            strokeWidth: 2,
            stroke: '#ffffff',
            strokeOpacity: 1,
          },
        },
        grid: {
          line: {
            stroke: '#2b2929',
            strokeWidth: 1,
          },
        },
      }}
    />
  );
};
