import { IReduxState } from 'shared/interfaces';
import permissionsCods from 'shared/constants/permissionsCods';
import { IReduxProfile } from 'shared/interfaces/ReduxState';

interface IParams {
  user: IReduxState | IReduxProfile;
  permissionCod?: string[];
  areaCod?: string[];
}

export default ({ user, permissionCod, areaCod }: IParams): boolean => {
  if (user.position?.toUpperCase() === permissionsCods.MASTER) {
    return true;
  }
  if (
    areaCod &&
    user.rules?.findIndex(rule => areaCod.includes(rule.area_cod)) === -1
  ) {
    return false;
  }
  if (
    permissionCod &&
    user.rules?.findIndex(rule =>
      permissionCod.includes(rule.permission_cod)
    ) === -1
  ) {
    return false;
  }
  return true;
};
