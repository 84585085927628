import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils/index';
import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { BaseModalFormCard } from 'components/elements/cards';
import { IReduxState } from 'shared/interfaces';
import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';

interface ComponentProps {
  handleToggleModal: () => void;
}

export const BellCaptationModal: React.FC<ComponentProps> = ({
  handleToggleModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInfos, setFormInfos] = useState({
    title: '',
    link: '',
  });

  const user = useSelector((state: IReduxState) => state.user.profile);

  useEffect(() => {
    setFormInfos({ ...formInfos, title: `${user.name} tocou o sino` });
  }, [user]);

  const handleChangeValue = (event: string) => {
    setFormInfos({
      ...formInfos,
      link: event,
    });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();

    if (formInfos.link.trim().length < 7) {
      setIsLoading(false);
      return toast.dark(
        'Você só pode tocar o sino se captar mais de 1.000.000,00'
      );
    }

    try {
      await hubApiProducts.postCreateNoticeByCaptation(formInfos);
      toast.dark('Parábens pela boleta!');
      handleToggleModal();
    } catch (err) {
      errorHandler(err);
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      maxW="420px"
      title="Informações da boleta"
      primaryButtonText="Tocar o sino"
      secondaryButtonText="Cancelar"
      handleToggleModal={handleToggleModal}
      handleFormSubmit={handleFormSubmit}
      isLoading={isLoading}
      isModal
    >
      <InputWithLabelAndFormatingToMoney
        label="Valor captado:"
        name="link"
        placeholder="Valor"
        onChange={handleChangeValue}
      />
    </BaseModalFormCard>
  );
};
