import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { BackPageButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';
import { IStructured } from 'pages/RV/subpages/StructuredsOnDemand/interface';
import StructuredCard from '../../components/modules/structuredsOnDemand/StructuredCard';

export const StructuredsOnDemand: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [structureds, setStructureds] = useState<IStructured[]>([]);

  useEffect(() => {
    async function listStructureds() {
      await hubApiRv
        .listAllStructureds()
        .then(response => {
          setStructureds(response);
          setIsLoading(false);
        })
        .catch(() => {
          toast.dark(
            'Ocorreu um erro ao buscar estruturadas. Tente sair e voltar para a página ou recarregar a mesma'
          );
        });
    }

    listStructureds();
  }, []);

  return (
    <PageWrapper flexDir="column" alignItems="center" paddingTop="48px">
      <BackPageButton />

      <MainPageTitle textAlign="center">Estruturadas do Dia</MainPageTitle>

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Flex
          maxW="1080px"
          justifyContent="center"
          flexDir="column"
          alignItems="center"
        >
          {structureds.map(structured => (
            <StructuredCard key={structured.id} structured={structured} />
          ))}
        </Flex>
      )}
    </PageWrapper>
  );
};
