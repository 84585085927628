import React from 'react';

import TextAreaContent from './styles';

const CustomTextArea = ({ label, handleChange, ...rest }) => {
  return (
    <TextAreaContent>
      {label && <label>{label}:</label>}
      <div className="custom-area">
        <textarea onChange={handleChange} {...rest}></textarea>
      </div>
    </TextAreaContent>
  );
};

export default CustomTextArea;
