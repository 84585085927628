import React from 'react';

import InputLabelContent from './styles';

const InputLabel = ({
  label,
  handleChange,
  labelMarginLeft = false,
  ...rest
}) => {
  return (
    <InputLabelContent>
      <label
        className={labelMarginLeft === true ? 'labelMarginLeft' : 'main-label'}
      >
        {label}
      </label>
      <input
        onChange={handleChange ? e => handleChange(e) : () => {}}
        {...rest}
      />
    </InputLabelContent>
  );
};

export default InputLabel;
