import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { RealStateFoundProps } from 'pages/products/interfaces';
import { PageWrapper } from 'components/elements/wrappers';
import { RealEstateFoundsForm } from 'pages/products/components/modules/realStateFunds/RealEstateFundsForm';
import { LoadingSpinner } from 'components/elements/others';
import schema from './schema';

interface RouteParams {
  id: string;
}

export const UpdateRealEstateFund: React.FC = () => {
  const history = useHistory();
  const { id } = useParams() as RouteParams;
  const [estateFounds, setEstateFounds] = useState<RealStateFoundProps>(
    {} as RealStateFoundProps
  );
  const [loading, setLoading] = useState<boolean>(true);

  const handleSubmitForm = useCallback(
    async e => {
      e.preventDefault();

      try {
        await schema.validate(estateFounds, {
          abortEarly: false,
        });

        delete estateFounds.id;

        await hubApiProducts
          .putUpdateRealEstateFund(id, estateFounds)
          .then(() => {
            toast.dark('Fundo imobiliário atualizado com sucesso.');
            history.goBack();
          })
          .catch(error => {
            if (error.response?.data.message !== 'Internal server error')
              toast.dark(error.response?.data.message);
          });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o Fundo Imobiliário, por favor tente novamente.'
          );
        }
      }
    },
    [estateFounds, history, id]
  );

  useEffect(() => {
    hubApiProducts
      .getRealEstateFundById(id)
      .then(data => {
        setEstateFounds(data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response?.data.message !== 'Internal server error')
          toast.dark(error.response?.data.message);
      });
  }, [id]);

  return (
    <PageWrapper>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <RealEstateFoundsForm
          estateFounds={estateFounds}
          setEstateFounds={setEstateFounds}
          handleSubmitForm={handleSubmitForm}
          label="Atualizar Fundo Imobiliário"
          buttonName="Atualizar fundo"
        />
      )}
    </PageWrapper>
  );
};
