import { Flex, Text } from '@chakra-ui/react';

interface ICardInfoProps {
  title: string;
  info: string;
}

export const CardInfo: React.FC<ICardInfoProps> = ({ title, info }) => {
  return (
    <Flex
      borderRadius="md"
      background="white"
      alignItems="center"
      justifyContent="space-around"
      flexDirection="column"
      w="100%"
      p={2}
      color="rgba(70, 70, 70, 1)"
    >
      <Text
        fontSize={['xs', 'sm']}
        fontWeight="bold"
        width="100%"
        maxWidth="100%"
        textAlign="center"
      >
        {title}
      </Text>
      <Text fontSize="xs" width="100%" maxWidth="100%" textAlign="center">
        {info}
      </Text>
    </Flex>
  );
};
