import * as Yup from 'yup';

const schema = Yup.object().shape({
  officeId: Yup.string().required('Selecione a Filial').nullable(),
  tableId: Yup.string().required('Selecione a Mesa').nullable(),
  // salaryType: Yup.string().required('Selecione o tipo de Salário'),
  // salary: Yup.number().required('Informe o Salário').nullable(),
  // rv: Yup.number().required('Informe a Renda Variável').nullable(),
  // intelligence: Yup.number().required('Informe a Inteligência').nullable(),
  // accessory: Yup.number().required('Informe a Assessoria').nullable(),
  // mundoInvest: Yup.number().required('Informe o Mundo Invest').nullable(),
  // rvManager: Yup.string().required('Informe o Responsável de Renda Variável'),
});

export default schema;
