import { Flex, Text } from '@chakra-ui/react';

type PropsType = {
  label: string;
  value: string | number | undefined;
};

export const LeadMundoInvestInformationBox: React.FC<PropsType> = ({
  label,
  value,
}): JSX.Element => {
  return (
    <Flex gap={1}>
      <Text color="rgba(70, 70, 70, 1)" fontSize="0.8rem">
        {label}:{' '}
        <span
          style={{
            fontSize: '0.8rem',
            fontWeight: 'normal',
            color: 'rgba(70, 70, 70, 1)',
          }}
        >
          {value}
        </span>
      </Text>
    </Flex>
  );
};
