import { Flex, Input, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { AxiosError } from 'axios';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { ModalWrapper } from 'components/elements/wrappers';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { PowerBIDescription } from '../components/powerBIDescription';
import { AddPowerBIDescriptionModal } from './AddPowerBIDescription';

type PropsType = {
  handleToggleOpenAddPowerBIModal: (value: boolean) => void;
};

export type CreatePowerBIMutationRequestType = {
  description: string;
  PowerBIUrl: string;
};

export const AddPowerBIModal: React.FC<PropsType> = ({
  handleToggleOpenAddPowerBIModal,
}): JSX.Element => {
  const [powerBIUrl, setPowerBIUrl] = useState<string>('');
  const [descriptions, setDescriptions] = useState<string[]>([]);

  const [
    isOpenAddPowerBIDescriptionModal,
    setIsOpenAddPowerBIDescriptionModal,
  ] = useState<boolean>(false);

  const { mutate: createPowerBI, isLoading: isLoadingCreatePowerBI } =
    useMutation({
      mutationKey: ['createPowerBI'],
      mutationFn: async (data: CreatePowerBIMutationRequestType) => {
        const response = await hubApi.createPowerBI(data);
        return response;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['powerBIManagement']);
        await queryClient.invalidateQueries(['allPowerBIsDashboards']);

        toast.dark('Power BI adicionado com sucesso!');

        handleToggleOpenAddPowerBIModal(false);
      },
      onError: error => {
        const err = error as AxiosError;

        if (err?.response?.status === 409)
          toast.dark('Esse Power BI ja existe.');

        toast.dark('Erro ao criar Power BI. Contacte os desenvolvedores.');
      },
    });

  const handleToggleIsOpenAddPowerBIDescriptionModal = (value: boolean) => {
    setIsOpenAddPowerBIDescriptionModal(value);
  };

  const handleAddPowerBIDescription = (
    event: React.FormEvent<HTMLFormElement>,
    value: string
  ) => {
    event.preventDefault();

    setDescriptions(prev => [...prev, value]);

    handleToggleIsOpenAddPowerBIDescriptionModal(false);
  };

  const handleRemovePowerBIDescription = (descriptionToRemove: string) => {
    const filteredList = descriptions.filter(
      description => description !== descriptionToRemove
    );

    setDescriptions(filteredList);
  };

  const handleAddPowerBI = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!powerBIUrl) return toast.dark('Preencha o campo da URL');

    if (descriptions.length === 0)
      return toast.dark('Adicione pelo menos uma descrição');

    createPowerBI({
      PowerBIUrl: powerBIUrl,
      description: descriptions.toString().replaceAll(',', '\\n'),
    });
  };

  return (
    <>
      <BaseModalFormCard
        isModal
        title="Adicionar Power BI"
        handleFormSubmit={handleAddPowerBI}
        handleToggleModal={() => handleToggleOpenAddPowerBIModal(false)}
        isLoading={isLoadingCreatePowerBI}
        primaryButtonText="Adicionar"
        secondaryButtonText="Cancelar"
        flexDir="column"
        width="100%"
      >
        <Flex flexDirection="column" gap={5}>
          <Flex flexDirection="column" gap={2}>
            <InputWithLabel
              label="URL"
              placeholder="Power BI URL"
              value={powerBIUrl}
              onChange={event => setPowerBIUrl(event.target.value)}
            />
          </Flex>

          <PowerBIDescription
            descriptions={descriptions}
            handleRemovePowerBIDescription={handleRemovePowerBIDescription}
            handleToggleIsOpenAddPowerBIDescriptionModal={
              handleToggleIsOpenAddPowerBIDescriptionModal
            }
          />
        </Flex>
      </BaseModalFormCard>
      <ModalWrapper
        isOpen={isOpenAddPowerBIDescriptionModal}
        onClose={() => handleToggleIsOpenAddPowerBIDescriptionModal(false)}
      >
        <AddPowerBIDescriptionModal
          isLoading={false}
          handleAddPowerBIDescription={handleAddPowerBIDescription}
          handleToggleIsOpenAddPowerBIDescriptionModal={
            handleToggleIsOpenAddPowerBIDescriptionModal
          }
        />
      </ModalWrapper>
    </>
  );
};
