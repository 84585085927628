import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';

export type CreateLeadManualPropsType = {
  name: string;
  email?: string;
  phones?: string[];
  origin: string;
};

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?: (error: unknown) => void;
};

export const useCreateLeadManual = (
  props?: PropsType
): UseMutationResult<unknown, unknown, CreateLeadManualPropsType, unknown> => {
  return useMutation({
    mutationKey: ['createLeadManual'],
    mutationFn: async (data: CreateLeadManualPropsType) => {
      const response = await hubApiCaptation.createLeadManual(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });
};
