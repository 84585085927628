const healthColumns = [
  {
    id: '1',
    title: 'Sem Contato',
    status: 'noContact',
    tasks: [],
  },
  {
    id: '2',
    title: 'Em Processo',
    status: 'inProcess',
    tasks: [],
  },
  {
    id: '3',
    title: 'Dados Solicitados',
    status: 'requestedData',
    tasks: [],
  },
  {
    id: '4',
    title: 'Fazendo Estudo',
    status: 'studying',
    tasks: [],
  },
  {
    id: '5',
    title: 'Proposta Enviada',
    status: 'sentOffer',
    tasks: [],
  },
  {
    id: '6',
    title: 'Boletado Hoje',
    status: 'boleted',
    tasks: [],
  },
  {
    id: '7',
    title: 'Fechado/ Cancelado',
    status: 'closed',
    tasks: [],
  },
];

export { healthColumns };
