import React from 'react';
import { Stack } from '@chakra-ui/react';

import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { UseMutateAsyncFunction } from 'react-query';

interface ComponentProps {
  title: string;
  isLoading: boolean;
  questionItem: { question: string; answer: string };
  handleFormSubmit: UseMutateAsyncFunction<
    void,
    unknown,
    React.FormEvent<HTMLFormElement>,
    unknown
  >;
  handleToggleModal: () => void;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const QuestionManagementModal: React.FC<ComponentProps> = ({
  title,
  questionItem,
  handleFormSubmit,
  handleToggleModal,
  handleChangeValue,
  isLoading = false,
}) => {
  return (
    <BaseModalFormCard
      maxW="560px"
      title={title}
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      isModal
    >
      <Stack spacing="2">
        <InputWithLabel
          label="Pergunta"
          name="question"
          placeholder="Digite a pergunta"
          value={questionItem.question}
          onChange={handleChangeValue}
        />
        <TextareaWithLabel
          label="Resposta"
          name="answer"
          rows={6}
          placeholder="Digite a resposta"
          value={questionItem.answer}
          onChange={handleChangeValue}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
