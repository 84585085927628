import { useState } from 'react';
import { RiEdit2Fill } from 'react-icons/ri';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { SecondaryButton } from 'components/elements/buttons';
import { ModalWrapper } from 'components/elements/wrappers';
import { ClientInfosType } from 'pages/MyClients/interfaces';
import { ModalEditInfos, InfoTitle } from 'pages/MyClients/components/elements';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface BasicDataSectionProps {
  profilePanelData: {
    basicData: {
      label: string;
      value: string;
    }[];
  };
  clientInfos: ClientInfosType;
  setClientInfos: React.Dispatch<React.SetStateAction<ClientInfosType>>;
  clientId: string;
  onlyVizMode: boolean;
}

export const BasicDataSection: React.FC<BasicDataSectionProps> = ({
  profilePanelData,
  clientInfos,
  setClientInfos,
  clientId,
  onlyVizMode,
}) => {
  const [showModalEditCpf, setShowModalEditCpf] = useState(false);
  const [showModalEditPhone, setShowModalEditPhone] = useState(false);
  const [showModalEditName, setShowModalEditName] = useState(false);

  return (
    <Flex flexDir="column">
      <InfoTitle title="Dados básicos" />

      <VStack mt="18px" spacing="2.5" alignItems="baseline">
        <Flex align="center" justify="space-between" w="100%">
          <Flex align="center" justify="space-between">
            <Text
              as="span"
              color="gray.500"
              mr="6px"
              lineHeight="shorter"
              fontSize="sm"
            >
              Nome do cliente:
            </Text>{' '}
            <Text
              as="span"
              fontSize="sm"
              color="white.200"
              fontWeight="medium"
              mr="6px"
            >
              {formatNameToCaptalize(clientInfos.completeName)}
            </Text>{' '}
          </Flex>

          {!onlyVizMode && (
            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => setShowModalEditName(!showModalEditName)}
            />
          )}

          <ModalWrapper
            isOpen={showModalEditName}
            onClose={() => setShowModalEditName(!showModalEditName)}
          >
            <ModalEditInfos
              clientInfos={clientInfos}
              setClientInfos={setClientInfos}
              updateName
              value={clientInfos.completeName}
              clientId={clientId}
              onClose={() => setShowModalEditName(!showModalEditName)}
            />
          </ModalWrapper>
        </Flex>

        {profilePanelData?.basicData.map(data => (
          <Text
            key={data.label}
            lineHeight="shorter"
            fontSize="sm"
            fontWeight="medium"
            color={!data.value || data.value === null ? 'red' : 'white.200'}
          >
            <Text as="span" color="gray.500" mr="6px" fontWeight="normal">
              {data.label}:
            </Text>{' '}
            {!data.value ? 'PENDENTE' : data.value}
          </Text>
        ))}

        <Flex align="center" justify="center">
          <Text
            as="span"
            color="gray.500"
            mr="6px"
            lineHeight="shorter"
            fontSize="sm"
          >
            CPF:
          </Text>{' '}
          <Text as="span" color="white.200" fontWeight="medium" mr="6px">
            {clientInfos.document && 'Enviado'}
          </Text>{' '}
          {!clientInfos.document && !onlyVizMode && (
            <SecondaryButton
              p={2.5}
              h={1}
              fontSize="sm"
              onClick={() => setShowModalEditCpf(!showModalEditCpf)}
            >
              Atualizar
            </SecondaryButton>
          )}
          <ModalWrapper
            isOpen={showModalEditCpf}
            onClose={() => setShowModalEditCpf(!showModalEditCpf)}
          >
            <ModalEditInfos
              clientInfos={clientInfos}
              setClientInfos={setClientInfos}
              updateCPF
              clientId={clientId}
              onClose={() => setShowModalEditCpf(!showModalEditCpf)}
            />
          </ModalWrapper>
        </Flex>

        <Flex align="center" justify="center">
          <Text
            as="span"
            color="gray.500"
            mr="6px"
            lineHeight="shorter"
            fontSize="sm"
          >
            Telefone:
          </Text>{' '}
          <Text as="span" mr="6px" color="white.200" fontWeight="medium">
            {clientInfos?.phone}
          </Text>{' '}
          {!clientInfos.phone && (
            <SecondaryButton
              p={2.5}
              h={2}
              fontSize="sm"
              onClick={() => setShowModalEditPhone(!showModalEditPhone)}
            >
              Atualizar
            </SecondaryButton>
          )}
          <ModalWrapper
            isOpen={showModalEditPhone}
            onClose={() => setShowModalEditPhone(!showModalEditPhone)}
          >
            <ModalEditInfos
              clientInfos={clientInfos}
              setClientInfos={setClientInfos}
              updatePhone
              clientId={clientId}
              onClose={() => setShowModalEditPhone(!showModalEditPhone)}
            />
          </ModalWrapper>
        </Flex>
      </VStack>
    </Flex>
  );
};
