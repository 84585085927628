import { HeadDataType } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { ClientType } from 'sharedV2/types/client';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { useNavigation } from 'hooksV2/useNavigation';
import { TextUtils } from 'utilsV2/Text';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { Flex, Text } from '@chakra-ui/react';
import { CurrencyUtils } from 'utilsV2/Currency';
import { colors } from 'sharedV2/colors';
import { usePendencyToIcon } from 'pagesV2/Clients/hooks/usePendencyToIcon';

type PropsType = {
  client: ClientType;
  tableHeadData: HeadDataType[];
};

export const ClientTableRow: React.FC<PropsType> = ({
  client,
  tableHeadData,
}): JSX.Element => {
  const { redirect } = useNavigation();
  const pendencyToIcon = usePendencyToIcon();

  const doesClientHavePendencies = client.pendencies.length > 0;

  return (
    <TableRow
      headData={tableHeadData}
      key={client.btgCode}
      onClick={() => redirect(`/v2/client/${client.btgCode}`)}
      _hover={useCommonHover()}
      borderLeftColor={doesClientHavePendencies ? colors.RED : colors.BLUE}
    >
      <Flex maxW="320px">
        <Tooltip text={TextUtils.toCapitalize(client.name)}>
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {TextUtils.toCapitalize(client.name)}
          </Text>
        </Tooltip>
      </Flex>

      <Text>
        {client.currentBalance
          ? CurrencyUtils.format(client.currentBalance)
          : '-'}
      </Text>
      <Text>
        {client.netWorth?.declared
          ? CurrencyUtils.format(client.netWorth?.declared)
          : '-'}
      </Text>
      <Text>
        {client.netWorth?.total
          ? CurrencyUtils.format(client.netWorth.total)
          : '-'}
      </Text>
      <Flex>
        {doesClientHavePendencies ? (
          <>
            {client.pendencies.map((pendency, index) => {
              const { icon, tooltipText } = pendencyToIcon[pendency.type];
              return (
                <Tooltip key={index} text={tooltipText}>
                  <Flex>{icon}</Flex>
                </Tooltip>
              );
            })}
          </>
        ) : (
          <Text>-</Text>
        )}
      </Flex>
    </TableRow>
  );
};
