import styled from 'styled-components';

const LeadTransferContainer = styled.div`
  .page-leads-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title-leads {
      padding: 24px 15px;

      h4 {
        color: var(--white-color-100);
      }
    }

    @media screen and (min-width: 594px) {
      height: calc(100vh - 120px);
    }
  }

  .lead-title {
    display: flex;
  }

  .page-lead-content {
    width: 100%;
    max-width: 1100px;
    max-height: calc(100vh -80px);
    border-radius: 5px;
    padding: 12px;
    background: var(--black-color-100);
  }

  .leads-transfer {
    display: grid;
    grid-template-columns: repeat(5, minmax(130px, 1fr));
    grid-template-rows: repeat(5, minmax(20px, 1fr));
    grid-gap: 5px;

    @media screen and (max-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 950px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 593px) {
      display: grid;
      grid-template-columns: 1fr;
    }

    .lead-content {
      width: 100%;
      height: 90px;
      display: flex;
    }
  }
`;

export default LeadTransferContainer;
