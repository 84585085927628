import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';
import { BiSlideshow } from 'react-icons/bi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { CgPathOutline } from 'react-icons/cg';
import { VscLibrary } from 'react-icons/vsc';
import { IoMdImages } from 'react-icons/io';

import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { CardToModal, CardToLink } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';
import { MaterialProps } from 'pages/Marketing/interfaces';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { solicitationsMkt } from './data';

const Marketing: React.FC = () => {
  const [currentMaterials, setCurrentMaterials] = useState<MaterialProps[]>([]);

  useEffect(() => {
    getCurrentMaterials();
  }, []);

  const getCurrentMaterials = async () => {
    try {
      const obtainedMaterials = await hubApiMarketing.getMaterials();

      setCurrentMaterials(obtainedMaterials);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar os materiais cadastrados, tente novamente em alguns minutos'
      );
    }
  };

  return (
    <PageWrapper alignItems="center" paddingTop="2.5rem">
      <SectionOptionsList title="Downloads/Materiais">
        {currentMaterials?.map((option, index) => (
          <CardToLink
            external
            key={index}
            title={option.title}
            link={option.link}
            Icon={
              (option.type === 'documents' && IoDocumentTextOutline) ||
              (option.type === 'images' && IoMdImages) ||
              (option.type === 'presentation' && BiSlideshow) ||
              (option.type === 'logos' && VscLibrary) ||
              CgPathOutline
            }
          />
        ))}
        {currentMaterials?.length < 1 && (
          <Text w="100%" textAlign="center">
            Não há materiais cadastrados
          </Text>
        )}
      </SectionOptionsList>

      <SectionOptionsList title="Solicitações">
        {solicitationsMkt.map((option, index) => (
          <CardToModal
            key={index}
            title={option.title}
            Modal={option.modal}
            Icon={option.icon}
          />
        ))}
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Marketing;
