import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      size="sm"
      color="white"
      background="primary.700"
      padding={{ sm: '12px 16px' }}
      fontWeight="semibold"
      _hover={{ bg: 'primary.800' }}
      {...rest}
    >
      {children}
    </Button>
  );
};
