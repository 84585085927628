import React, { useCallback, useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
// import { io } from 'socket.io-client';
// import hubApi from 'services/hubApi';
import { MarketingOrderProps } from 'pages/Marketing/interfaces';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { TransferOrderCard } from 'components/elements/cards';
import { MainPageTitle } from 'components/elements/texts';
import { BackPageButton } from 'components/elements/buttons';
import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import { OrderInfo } from '../../components/modules/transferOrders';

export const MarketingTransferOrders: React.FC = () => {
  const [infosMkt, setInfosMkt] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedOrder, setSelectedOrder] = useState<MarketingOrderProps>(
    {} as MarketingOrderProps
  );

  // useEffect(() => {
  //     connectSocket(setInfosMkt, infosMkt);
  // }, [infosMkt]);

  // function connectSocket(setInfosMkt, infosMkt) {
  //     const socket = io(hubApi.baseURL, { query: { user_id: 'MK' } });

  //     socket.on('newOrder', (order) => {
  //         setInfosMkt([...infosMkt, order]);
  //     });
  // }

  const handleShowModal = (clickedOrder: MarketingOrderProps) => {
    setSelectedOrder(clickedOrder);
  };

  useEffect(() => {
    listOrders();
  }, []);

  async function listOrders() {
    hubApiMarketing
      .listMarketingInfos()
      .then(response => setInfosMkt(response));
  }

  const handleRemoveOrder = useCallback(
    (id: string) => {
      setInfosMkt(infosMkt.filter((item: { id: string }) => item.id !== id));
    },
    [infosMkt]
  );

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle color="rgba(70, 70, 70, 1)" textAlign="center">
        Transferência de Ordens - Marketing
      </MainPageTitle>

      <TransferOrdersCardsWrapper>
        {infosMkt.map((item: MarketingOrderProps, index: number) => (
          <TransferOrderCard
            key={index}
            mainText={item.type}
            secondaryText={item.name}
            onClick={() => {
              handleShowModal(item);
              onOpen();
            }}
          />
        ))}
      </TransferOrdersCardsWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <OrderInfo
          order={selectedOrder}
          handleToggleOrderModal={onClose}
          handleRemoveOrder={handleRemoveOrder}
        />
      </ModalWrapper>
    </PageWrapper>
  );
};
