import { FcEndCall } from 'react-icons/fc';
import { FlexProps, Stack, Text, Icon, Flex } from '@chakra-ui/react';
import { formatToCapitalized, formatToPhone } from 'brazilian-values';

import formatValue from 'utils/formatValue';
import { MosaicCardWrapper } from '../../wrappers';

interface ComponentProps extends FlexProps {
  name: string;
  phone?:
    | any
    | {
        phone: string;
      }
    | string;
  email?:
    | {
        email: string;
      }
    | string;
  contactAttempts: string;
  mundoInvestPL?: number;
}

export const MosaicLeadCard: React.FC<ComponentProps> = ({
  name,
  phone,
  email,
  contactAttempts,
  mundoInvestPL,
  ...rest
}) => {
  let phoneString = '';
  if (typeof phone !== 'string' && phone?.length > 0) {
    phoneString = phone[0]?.phone;
  }
  return (
    <MosaicCardWrapper {...rest}>
      <Flex justify="space-between">
        <Text fontWeight="semibold" fontSize="smaller" mb="2" isTruncated>
          {name && formatToCapitalized(name)}
        </Text>
        {contactAttempts && Number(contactAttempts) > 0 && (
          <Icon
            as={FcEndCall}
            w={5}
            h={5}
            title={`${contactAttempts} ${
              Number(contactAttempts) > 1 ? 'tentativas' : 'tentativa'
            } de contato com este lead`}
          />
        )}
      </Flex>

      <Stack spacing="1">
        {typeof phone === 'string' && (
          <Text
            fontSize="smaller"
            flexDir="row"
            fontWeight="medium"
            color="gray.300"
          >
            <Text as="span" color="gray.900" fontWeight="light">
              Telefone:{' '}
            </Text>
            {typeof phone === 'string' ? phone : formatToPhone(phoneString)}
          </Text>
        )}

        {email && (
          <Text
            fontSize="smaller"
            flexDir="row"
            fontWeight="medium"
            color="gray.300"
            isTruncated
          >
            <Text as="span" color="gray.900" fontWeight="light">
              E-mail:{' '}
            </Text>
            {typeof email !== 'string' ? email.email : email}
          </Text>
        )}

        {mundoInvestPL && (
          <Text
            fontSize="smaller"
            flexDir="row"
            fontWeight="medium"
            color="gray.300"
            isTruncated
          >
            <Text as="span" color="gray.900" fontWeight="light">
              PL Mundo Invest:{' '}
            </Text>
            {formatValue(mundoInvestPL)}
          </Text>
        )}
      </Stack>
    </MosaicCardWrapper>
  );
};
