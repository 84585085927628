import styled from 'styled-components';

export const TextFieldsContainer = styled.div`
  width: 100%;
  position: relative;
  div {
    &:nth-child(1) {
      margin: 0;
    }
  }

  .feeAndReferencePriceContainer {
    display: flex;
    justify-content: space-between;
  }

  .fee {
    width: 160px;
  }
`;

export const PercentSign = styled.span`
  color: ${({ theme }) => theme.colors.white100};
  font-size: var(--small-text);
  position: absolute;
  top: 29px;
  left: 137px;
`;
