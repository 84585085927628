import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BiAddToQueue } from 'react-icons/bi';
import { Flex, Text } from '@chakra-ui/react';
import { IReduxState } from 'shared/interfaces';
import { PrimaryButton } from 'components/elements/buttons';
import {
  AllOffersTable,
  CreateAndUpdateOfferModal,
  DeleteOfferModal,
} from 'pages/Offers/components';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { Offer } from 'pages/Offers/interfaces';
import { MainPageTitle } from 'components/elements/texts';
import { ModalWrapper } from 'components/elements/wrappers';
import { FilterOffersSwitchButton } from './FilterOffersSwitchButton';

interface AllOffersSectionProps {
  offers: Offer[];
  handleSignalToUpdateOffers: () => void;
  handleChangeTypeOfOffers: (e: string) => void;
  witchTypeOfferIsActive: string;
}

export const AllOffersSection: React.FC<AllOffersSectionProps> = ({
  handleSignalToUpdateOffers,
  offers,
  handleChangeTypeOfOffers,
  witchTypeOfferIsActive,
}) => {
  const user: IReduxState = useSelector(
    (state: IReduxState) => state.user.profile
  );
  const [offerId, setOfferId] = useState('');
  const [showCreateOfferModal, setShowCreateOfferModal] = useState(false);
  const [showDeleteOfferModal, setShowDeleteOfferModal] = useState(false);
  const [offerEdited, setOfferEdited] = useState<Offer>();

  const handleOpenEditOffersModal = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    offer: Offer
  ) => {
    event.stopPropagation();
    setOfferEdited(offer);
    setShowCreateOfferModal(!showCreateOfferModal);
  };

  const isLoggedUserManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTR', 'ESP'],
    areaCod: ['ASS', 'PDT'],
  });

  const isLoggedUserCanEditOrDeleteOffer = verifyEmployeePermission({
    user,
    permissionCod: ['GTR', 'ESP'],
    areaCod: ['ASS', 'PDT'],
  });

  const isLoggedUserTableManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTM', 'ESP'],
    areaCod: ['ASS', 'PDT'],
  });

  const handleDeleteOffer = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    selectedOfferId: string
  ) => {
    event.stopPropagation();
    setOfferId(selectedOfferId);
    setShowDeleteOfferModal(true);
  };

  const handleCloseCreateOfferModal = () => {
    setShowCreateOfferModal(false);
  };

  const handleCloseDeleteOfferModal = () => {
    setShowDeleteOfferModal(false);
  };

  const handleOpenModalCreateOffer = () => {
    setShowCreateOfferModal(true);
    setOfferEdited({} as Offer);
  };

  return (
    <>
      <Flex flexDir="column" w="100%">
        <MainPageTitle textAlign="center" mt={6} mb={2} color="background.50">
          Ofertas
        </MainPageTitle>

        <Flex justifyContent="flex-end" mb={2}>
          <PrimaryButton onClick={handleOpenModalCreateOffer}>
            <BiAddToQueue color="white" size={22} />
            <Text as="span" ml={2}>
              Criar nova oferta
            </Text>
          </PrimaryButton>
        </Flex>

        <Flex align="center" justify="center" mb="5">
          <FilterOffersSwitchButton
            handleChangeTypeOfOffers={handleChangeTypeOfOffers}
            witchTypeOfferIsActive={witchTypeOfferIsActive}
          />
        </Flex>

        <AllOffersTable
          showEditOfferModal={handleOpenEditOffersModal}
          offers={offers}
          isLoggedUserManager={isLoggedUserManager}
          handleDeleteOffer={handleDeleteOffer}
          isLoggedUserCanEditOrDeleteOffer={isLoggedUserCanEditOrDeleteOffer}
        />
      </Flex>

      <ModalWrapper
        isOpen={showCreateOfferModal}
        onClose={handleCloseCreateOfferModal}
      >
        <CreateAndUpdateOfferModal
          closeModal={handleCloseCreateOfferModal}
          signalToUpdateOffers={handleSignalToUpdateOffers}
          isManager={isLoggedUserManager}
          isTableManager={isLoggedUserTableManager}
          tableId={user.tableId || ''}
          offerEdited={offerEdited}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showDeleteOfferModal}
        onClose={handleCloseDeleteOfferModal}
      >
        <DeleteOfferModal
          closeModal={handleCloseDeleteOfferModal}
          signalToUpdateOffers={handleSignalToUpdateOffers}
          offerId={offerId}
        />
      </ModalWrapper>
    </>
  );
};
