import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useNavigation } from 'hooksV2/useNavigation';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { Icons } from 'sharedV2/icons';
import { ClientMeetingStatusEnum, ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsLastActivities: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const { redirect } = useNavigation();

  const lastMeetings = client.meetings.filter(
    meeting => meeting.status === ClientMeetingStatusEnum.CONCLUDED
  );

  const handleGoToMeetingPage = (meetingId: string) => {
    redirect(
      `/v2/client/${client.btgCode}/meeting/${meetingId}?isOnlyVisualizationMode=true`
    );
  };

  return (
    <Flex flexDirection="column" gap={2}>
      {lastMeetings.length === 0 && (
        <Text fontStyle="italic" color="#999999" textAlign="center">
          Nenhuma atividade realizada
        </Text>
      )}

      <Flex
        overflowX="auto"
        className="thinScrollbar"
        maxW="calc(100vw - 900px)"
        gap={2}
        p={2}
      >
        {lastMeetings.map((meeting, index) => (
          <Box.Opaque
            p={3}
            gap={4}
            flexDirection="row"
            alignItems="center"
            _hover={useCommonHover()}
            cursor="pointer"
            bgColor="white"
            minW="220px"
            key={index}
            onClick={() => handleGoToMeetingPage(meeting.id)}
          >
            <Icons.CALENDAR color="rgba(70, 70, 70, 1)" size={25} />
            <Flex flexDirection="column">
              <Text color="rgba(70, 70, 70, 1)">Reunião</Text>
              <Text fontSize="sm">
                {DateUtils.formatWithTime(meeting.date)}
              </Text>
            </Flex>
          </Box.Opaque>
        ))}
      </Flex>
    </Flex>
  );
};
