import { all, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { SIGN_IN_REQUEST, SIGN_OUT, PERSIST_REHYDRATE } from './actionTypes';

import history from '../../../services/history';

import hubApi from '../../../services/hubApi';
import { hubApiOfficeTables } from '../../../services/hubApi/classes/HubApiOfficeTables';
import { hubApiOfficeBranches } from '../../../services/hubApi/classes/HubApiOfficeBranches';
import { hubApiNotifications } from '../../../services/hubApi/classes/HubApiNotifications';
import { hubApiCaptation } from '../../../services/hubApi/classes/HubApiCaptation';
import { hubApiProducts } from '../../../services/hubApi/classes/HubApiProducts';
import { hubApiRealTime } from '../../../services/hubApi/classes/HubRealTime';
import { hubApiIp } from '../../../services/hubApi/classes/HubApiIp';
import { hubApiExchange } from '../../../services/hubApi/classes/HubApiCambio';
import { hubApiTech } from '../../../services/hubApi/classes/HubApiTi';
import { hubApiDashboards } from '../../../services/hubApi/classes/HubApiDashboards';

import { hubApiGuru } from '../../../services/hubApi/classes/HupApiGuru';

import { hubApiFinancial } from '../../../services/hubApi/classes/HubApiFinancial';
import { hubApiRv } from '../../../services/hubApi/classes/HubApiRv';
import { hubApiMarketing } from '../../../services/hubApi/classes/HubApiMarketing';
import { hubApiRH } from '../../../services/hubApi/classes/HubApiRH';
import { hubApiHome } from '../../../services/hubApi/classes/HubApiHome';
import { hubApiGraphics } from '../../../services/hubApi/classes/HubApiGraphics';

import { signInSuccess, signInFailure, signOut } from './actions';
import { hubApiUser } from '../../../services/hubApi/classes/HubApiUser';

function setHeadersOnApi(token) {
  hubApi.setHeaders(token);
  hubApiClients.setHeaders(token);
  hubApiProducts.setHeaders(token);
  hubApiNotifications.setHeaders(token);
  hubApiOfficeTables.setHeaders(token);
  hubApiOfficeBranches.setHeaders(token);
  hubApiCaptation.setHeaders(token);
  hubApiRealTime.setHeaders(token);
  hubApiIp.setHeaders(token);
  hubApiExchange.setHeaders(token);
  hubApiTech.setHeaders(token);
  hubApiDashboards.setHeaders(token);
  hubApiGuru.setHeaders(token);
  hubApiFinancial.setHeaders(token);
  hubApiRv.setHeaders(token);
  hubApiHome.setHeaders(token);
  hubApiMarketing.setHeaders(token);
  hubApiRH.setHeaders(token);
  hubApiGraphics.setHeaders(token);
  hubApiUser.setHeaders(token);
}

export function* signIn({ payload: { email, password, callback } }) {
  try {
    const response = yield hubApi.login(email, password).catch(error => {
      toast.dark('E-mail ou senha invalidos');
      callback();
    });

    const { employee, token, rules } = response.data;

    const employeeData = {
      ...employee,
      rules,
    };

    const today = new Date();
    const signDay = today.getDay();

    setHeadersOnApi(token);

    yield put(signInSuccess(token, employeeData, signDay));

    history.push('/');
  } catch (err) {
    yield put(signInFailure());
  }
}

export function signOutRequest() {
  history.push('/v2/login');
}

export function* setToken({ payload }) {
  if (!payload) {
    return;
  }

  const today = new Date();
  const day = today.getDay();

  if (payload.auth.loginDate !== null && payload.auth.loginDate !== day) {
    yield put(signOut());
    return;
  }

  try {
    const { token } = payload.auth;

    if (token) {
      setHeadersOnApi(token);
    }
  } catch (err) {}
}

export default all([
  takeLatest(PERSIST_REHYDRATE, setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest(SIGN_OUT, signOutRequest),
]);
