import { useCallback, useState } from 'react';
import { Stack, Text, Flex, Box } from '@chakra-ui/react';
import { formatToCapitalized } from 'brazilian-values';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';

import { formateDateAndTime } from 'utils/formatDate';
import { ModalWrapper } from 'components/elements/wrappers';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { ShowOneAgreement } from './ShowOneAgreement';

interface IAgreementUser {
  allAgreements: IAgreement[];
}

export const AgreementUser: React.FC<IAgreementUser> = ({ allAgreements }) => {
  const [showModal, setShowModal] = useState(false);
  const [agreementSelected, setAgreementSelected] = useState({} as IAgreement);

  const handleToggleModal = useCallback(
    (agreement: IAgreement) => {
      setShowModal(!showModal);

      setAgreementSelected(agreement);
    },
    [agreementSelected, showModal]
  );

  return (
    <Flex
      flexDir="column"
      gridGap="3.5"
      overflowY="auto"
      maxH={['65vh', 'calc(100vh - 286px)']}
    >
      {allAgreements.map((agreement, key) => (
        <Box
          key={key}
          background="white"
          padding="4"
          borderRadius="3"
          cursor="pointer"
          onClick={() => handleToggleModal(agreement)}
        >
          <Stack
            flexDir="row"
            spacing={0}
            justifyContent="space-between"
            mb="16px"
            gridGap="64px"
            borderBottom="1px solid gray"
          >
            <Text
              fontSize="lg"
              color="rgba(70, 70, 70, 1)"
              maxW="600px"
              fontWeight="bold"
              lineHeight="150%"
              textAlign="justify"
            >
              {formatToCapitalized(agreement.title, {
                trimTrailingWhiteSpaces: false,
              })}
            </Text>

            <Flex alignItems="center" justify="center">
              <Text
                mr="8px"
                fontSize="medium"
                color="rgba(70, 70, 70, 1)"
                fontWeight="bold"
              >
                <Text as="span" fontWeight="normal" color="rgba(70, 70, 70, 1)">
                  Data:
                </Text>{' '}
                {formateDateAndTime(agreement.created_at)}
              </Text>

              {agreement.accept ? (
                <AiOutlineCheckCircle color="#33FC12" />
              ) : (
                <AiOutlineCloseCircle color="#CB0000" />
              )}
            </Flex>
          </Stack>

          <Flex
            justify={agreement.accept ? 'space-between' : 'flex-end'}
            align="center"
          >
            {agreement.accept && (
              <Text
                fontSize="sm"
                as="span"
                lineHeight="14,14px"
                color="rgba(70, 70, 70, 1)"
              >
                Acordo assinado dia{' '}
                <Text
                  fontWeight="semibold"
                  as="span"
                  color="rgba(70, 70, 70, 1)"
                >
                  {formateDateAndTime(agreement.updated_at)}
                </Text>
              </Text>
            )}

            <Text
              textAlign="right"
              fontSize="small"
              color="rgba(70, 70, 70, 1)"
            >
              Redigido por
              <Text
                as="span"
                lineHeight="14,14px"
                fontWeight="semibold"
                color="rgba(70, 70, 70, 1)"
              >
                {' '}
                {formatNameToCaptalize(agreement.managerName)}
              </Text>
            </Text>
          </Flex>
        </Box>
      ))}
      <ModalWrapper isOpen={showModal} onClose={() => setShowModal(!showModal)}>
        <ShowOneAgreement
          agreement={agreementSelected}
          onClose={() => setShowModal(!showModal)}
        />
      </ModalWrapper>
    </Flex>
  );
};
