import { Checkbox, Flex } from '@chakra-ui/react';
import { formatToBRL, formatToDateTime } from 'brazilian-values';
import { TableData, TableRow } from 'components/elements/table';
import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';

import { RiEdit2Fill } from 'react-icons/ri';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { unformatValue } from 'utils/unformatValue';

interface ITableLeadRowProps {
  leadSh: LeadDetailsProps;
  handleCheckLead: (lead: LeadDetailsProps) => void;
  handleSelectLead: (lead: LeadDetailsProps) => void;
}

export const TableLeadRow: React.FC<ITableLeadRowProps> = ({
  leadSh,
  handleCheckLead,
  handleSelectLead,
}) => {
  const handleCheckStatus = () => {
    let newStatus = '';

    if (Number(leadSh.frequency) < -50 || leadSh.status === 'returned') {
      newStatus = 'Retornado';
    } else if (leadSh.meetings.length > 0 && leadSh.status === 'ok') {
      newStatus = 'Agendado';
    } else if (leadSh.status === 'ok' || leadSh.meetings.length < 1) {
      newStatus = 'Sem contato';
    }

    return newStatus;
  };

  const checkValueAtCustomFields = (
    fields: { fieldName: string; fieldValue: string }[]
  ) => {
    if (!fields || fields.length < 1) {
      return '-';
    }

    const rightInvestments = fields.find(
      field => field.fieldName === 'cf_faixa_de_investimentos_certo'
    );

    if (rightInvestments) {
      return rightInvestments.fieldValue;
    }

    const patrimony = fields.find(
      field => field.fieldName === 'cf_patrimonio_declarado'
    );

    if (patrimony) {
      return patrimony.fieldValue;
    }

    return `-`;
  };

  const checkValuePl = (value: string) => {
    if (!value) return;

    if (Number(value) > 0) {
      const justNumbers = unformatValue(value);

      return formatToBRL(justNumbers);
    }

    return value;
  };

  return (
    <>
      <TableRow>
        <TableData>
          <Checkbox
            display="inline-block"
            isChecked={leadSh.isChecked}
            onChange={() => handleCheckLead(leadSh)}
          />
        </TableData>

        <TableData maxW="170px " isTruncated>
          {leadSh.name ? formatNameToCaptalize(String(leadSh.name)) : '-'}
        </TableData>

        <TableData maxW="180px " isTruncated>
          {handleCheckStatus()}
        </TableData>

        <TableData maxW="220px">
          {leadSh.questions[0]?.pl
            ? checkValuePl(leadSh.questions[0]?.pl)
            : checkValueAtCustomFields(leadSh.customFields)}
        </TableData>

        <TableData maxW="180px " isTruncated>
          {leadSh.meetings.length > 0
            ? formatToDateTime(new Date(leadSh.meetings[0]?.date))
            : '-'}
        </TableData>

        <TableData minW="100px" isTruncated>
          <Flex w="100%" justify="space-between" align="center">
            {leadSh.frequency ? leadSh.frequency : '-'}

            <RiEdit2Fill
              size={16}
              cursor="pointer"
              onClick={() => handleSelectLead(leadSh)}
            />
          </Flex>
        </TableData>
      </TableRow>
    </>
  );
};
