import { Box, Flex, Text } from '@chakra-ui/react';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import {
  ArrowCounterClockwise,
  Calendar,
  CalendarCheck,
  CalendarPlus,
  CalendarX,
  Chat,
  CheckCircle,
  CheckSquareOffset,
  PaperPlaneTilt,
  Phone,
  PhoneCall,
  PhoneX,
  Plus,
  PlusCircle,
  Question,
  ShoppingBag,
} from 'phosphor-react';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formateDateAndTime } from 'utils/formatDate';
import parser from 'html-react-parser';
import { TextUtils } from 'utilsV2/Text';
import { IPostAnotations } from 'pages/IP/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { ClientNotesType } from 'sharedV2/types/client';
import { LeadDetailsHeader } from '../header';

export const LeadDetailsHistory = (): JSX.Element => {
  const { lead } = useLeadDetailsContext();

  const { data: ipOrders } = useQuery<IPOrderType[]>({
    queryKey: ['ipOrders', lead.id],
    queryFn: async () => {
      const response = await hubApiIp.getOrderIpByLeadId(lead.id);
      return response;
    },
  });

  const ipOrder =
    ipOrders && ipOrders.filter(order => order.segment === 'vida');

  const APOrder =
    ipOrders && ipOrders.filter(order => order.segment === 'imobiliario');

  const { data: history } = useQuery({
    queryKey: ['leadHistory', lead.id],
    queryFn: async () => {
      const response = await hubApiCaptation.getLeadHistory(lead.id);

      return response;
    },
  });

  const { data: clientNotes } = useQuery<ClientNotesType[]>({
    queryKey: ['clientNotes', lead.btgAccount],
    queryFn: async () => {
      let response = await hubApiClients.getClientHistory(
        lead.btgAccount as string
      );
      response = response.filter(item => 'content' in item);
      return response as ClientNotesType[];
    },
    enabled: lead.btgAccount !== null,
  });

  const { data: orderIPNotes } = useQuery<IPostAnotations[]>({
    queryKey: ['orderIPNotes'],
    queryFn: async () => {
      const clientNotes = await hubApiUser.getClientNotes(
        ipOrder?.at(0)?.id as string
      );

      return clientNotes;
    },
    enabled: (ipOrder?.length || 0) > 0,
  });

  const { data: orderAPNotes } = useQuery<IPostAnotations[]>({
    queryKey: ['orderAPNotes'],
    queryFn: async () => {
      const clientNotes = await hubApiUser.getClientNotes(
        APOrder?.at(0)?.id as string
      );

      return clientNotes;
    },
    enabled: (APOrder?.length || 0) > 0,
  });

  const allNotes = [
    ...(history || []),
    ...(orderIPNotes || []),
    ...(orderAPNotes || []),
    ...(clientNotes || []),
  ];
  const sortedHistory = allNotes.sort((a, b) => {
    const dateA = new Date('from' in a ? a.createdAt : a.created_at);
    const dateB = new Date('from' in b ? b.createdAt : b.created_at);

    return dateB.getTime() - dateA.getTime();
  });

  const handleGetIcon = (eventType: string) => {
    const obj: Record<string, JSX.Element> = {
      'Reunião agendada': <CalendarPlus size={30} color="#1D63DDB2" />,
      'Reunião reagendada': <Calendar size={30} color="#1D63DDB2" />,
      'Tentativa de contato (↑)': <Phone size={30} color="#1D63DDB2" />,
      'Tentativa de contato (↓)': <Phone size={30} color="#1D63DDB2" />,
      'Lead entrou no sistema': <PlusCircle size={30} color="#1D63DDB2" />,
      'Lead recuperado das reuniões atrasadas': (
        <ArrowCounterClockwise size={30} color="#1D63DDB2" />
      ),
      'Lead declinado': <CalendarX size={30} color="#1D63DDB2" />,
      'Tarefa realizada': <CheckSquareOffset size={30} color="#1D63DDB2" />,
      'Reunião roteada': <CalendarCheck size={30} color="#1D63DDB2" />,
      'Reunião comprada no pool de assessoria': (
        <ShoppingBag size={30} color="#1D63DDB2" />
      ),
      'Lead atendeu': <PhoneCall size={30} color="#1D63DDB2" />,
      'Lead não atendeu': <PhoneX size={30} color="#1D63DDB2" />,
      Alocado: <CheckCircle size={30} color="#1D63DDB2" />,
      'Retorno agendado': <Calendar size={30} color="#1D63DDB2" />,
      'Lead Transferido (Retirado)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
      'Lead Transferido (Responsável)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
      'Lead Transferido (Beneficiário)': (
        <PaperPlaneTilt size={30} color="#1D63DDB2" />
      ),
      'Ordem PJ2 IP criada': <Plus size={30} color="#1D63DDB2" />,
      'Ordem PJ2 AP criada': <Plus size={30} color="#1D63DDB2" />,
      'Lead declinado pois entrou no fluxo PJ2': (
        <CalendarX size={30} color="#1D63DDB2" />
      ),
    };

    return obj[eventType] || <Question size={30} color="#1D63DDB2" />;
  };

  return (
    <Flex
      flexDirection="column"
      maxH="calc(100vh - 300px)"
      backgroundColor="#fff"
      borderRadius="8px"
      padding={4}
    >
      <Text fontWeight="bold" fontSize={18} color="rgba(70, 70, 70, 1)" mb={4}>
        Acompanhamento do Lead
      </Text>
      <LeadDetailsHeader />
      <Flex
        flexDirection="column"
        overflowY="auto"
        maxH="calc(100vh - 300px)"
        className="thinScrollbar"
        fontSize="0.8rem"
        margin="0 0 10px 0"
      >
        {sortedHistory?.length === 0 && <Text>Sem movimentações</Text>}
        {sortedHistory &&
          sortedHistory.map((item, index) => {
            if ('from' in item) {
              return (
                <Box key={item.btgCode} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.createdAt)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação (Nota do Cliente)
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.content)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            if ('msg' in item) {
              return (
                <Box key={item.btgCode} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.created_at)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação (Nota da Ordem Ilha Forte)
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.msg)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            if ('content' in item) {
              return (
                <Box key={item.id} width="100%">
                  <Flex gap={3} alignItems="center">
                    <Flex flexDirection="column" alignItems="center">
                      <Chat size={30} color="#1D63DDB2" />
                      <Flex border="1px solid #b0b0b0" h="45px" />
                    </Flex>

                    <Flex flexDirection="column">
                      <Text fontSize="sm">
                        {formateDateAndTime(item.created_at)}
                      </Text>
                      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)">
                        Anotação
                      </Text>
                      <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                        {parser(item.content)}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              );
            }

            return (
              <Box key={index} width="100%">
                <Flex gap={3} alignItems="center">
                  <Flex flexDirection="column" alignItems="center">
                    {handleGetIcon(item.eventType)}

                    <Flex border="1px solid #b0b0b0" h="45px" />
                  </Flex>

                  <Flex flexDirection="column">
                    <Text fontSize="small" mt={-6} fontWeight="semibold">
                      {formateDateAndTime(item.created_at)}
                    </Text>
                    <Text
                      fontWeight="bold"
                      color="rgba(70, 70, 70, 1)"
                      fontSize="16px"
                    >
                      {item.eventType}{' '}
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: 'gray',
                          fontWeight: 'lighter',
                          fontSize: '16px',
                        }}
                      >
                        por {TextUtils.toCapitalize(item.employeeName)}
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
