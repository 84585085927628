import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel } from 'components/elements/forms';
import { TitleWithSideLine } from 'components/elements/texts';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  IGeneralWalletMibs,
  IGeneralWalletName,
  IHandleGeneralWalletMibsOpearation,
} from '../dtos';
import { GeneralWalletModal } from './GeneralWalletModal';
import { SensetiveTransactionGeneralWalletModal } from './SensetiveTransactionGeneralWalletModal';
import { WalletInfoBox } from './WalletInfoBox';

interface IProps {
  handleGeneralMibsOperation: IHandleGeneralWalletMibsOpearation;
  setIsOpenTransferBetweenGeneralWalletModal(value: boolean): void;
  generalWalletMibs: IGeneralWalletMibs[];
}

export const TransferBetweenGeneralWalletModal: React.FC<IProps> = ({
  setIsOpenTransferBetweenGeneralWalletModal,
  generalWalletMibs,
  handleGeneralMibsOperation,
}) => {
  const [sourceWallet, setSourceWallet] = useState<IGeneralWalletName>('EWZ');
  const [mibsQuantity, setMibsQuantity] = useState<string>('');
  const [
    isOpenSensitiveTransactionGeneralWalletModal,
    setIsOpenSensitiveTransactionGeneralWalletModal,
  ] = useState<boolean>(false);

  const destinationWallet = sourceWallet === 'EWZ' ? 'Mundo Invest' : 'EWZ';

  const handleModalSubmit = () => {
    if (mibsQuantity === '' || Number(mibsQuantity) === 0) {
      return toast.dark('Coloque uma quantidade valida de MIBs.');
    }

    const wallet =
      sourceWallet === 'EWZ' ? generalWalletMibs[0] : generalWalletMibs[1];

    if (Number(mibsQuantity) > wallet.mibs) {
      return toast.dark('Quantidade de MIBs insuficiente.');
    }

    setIsOpenSensitiveTransactionGeneralWalletModal(true);
  };

  return (
    <>
      <DefaultModalContentWrapper
        maxW={{ base: '95vw', md: '540px', lg: '900px' }}
        minH="400px"
        justifyContent="space-between"
      >
        <Flex justifyContent="center">
          <TitleWithSideLine fontSize={20}>
            Transferências entre Carteiras
          </TitleWithSideLine>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" gap={3} w="40%">
            <Flex flexDirection="column" gap={1}>
              <Text mb="6px" fontSize="sm" color="white">
                Carteira Origem *
              </Text>
              <GeneralWalletModal setSelectItem={setSourceWallet} />
            </Flex>

            <Flex flexDirection="column" gap={1}>
              <Text mb="6px" fontSize="sm" color="white">
                Carteira Destino
              </Text>
              <Text
                borderRadius={4}
                backgroundColor="background.400"
                color="white"
                fontWeight="medium"
                fontSize="sm"
                px="2"
                py="1"
                w="90%"
                justifyContent="center"
              >
                {destinationWallet}
              </Text>
            </Flex>

            <InputWithLabel
              w="90%"
              label="Quantidade de MIBs *"
              placeholder="100"
              value={mibsQuantity}
              onChange={e => setMibsQuantity(e.target.value)}
            />
          </Flex>
          <Flex w="60%" gap={5}>
            <WalletInfoBox
              mibs={generalWalletMibs && generalWalletMibs[0]?.mibs}
              walletName="Banco Central EWZ"
              showReaisValue
            />
            <WalletInfoBox
              mibs={generalWalletMibs && generalWalletMibs[1].mibs}
              walletName="Banco Central MI"
              showReaisValue
            />
          </Flex>
        </Flex>

        <Flex justifyContent="end" gap={3}>
          <SecondaryButton
            w="100px"
            onClick={() => setIsOpenTransferBetweenGeneralWalletModal(false)}
          >
            Sair
          </SecondaryButton>
          <PrimaryButton onClick={handleModalSubmit}>Confirmar</PrimaryButton>
        </Flex>
      </DefaultModalContentWrapper>
      <ModalWrapper
        isOpen={isOpenSensitiveTransactionGeneralWalletModal}
        onClose={() =>
          setIsOpenSensitiveTransactionGeneralWalletModal(
            !isOpenSensitiveTransactionGeneralWalletModal
          )
        }
      >
        <SensetiveTransactionGeneralWalletModal
          setMibsQuantity={setMibsQuantity}
          mibsQuantity={mibsQuantity}
          sourceWallet={sourceWallet}
          handleGeneralMibsOperation={handleGeneralMibsOperation}
          setIsOpenSensitiveTransactionGeneralWalletModal={
            setIsOpenSensitiveTransactionGeneralWalletModal
          }
        />
      </ModalWrapper>
    </>
  );
};
