import { formatToCapitalized } from 'brazilian-values';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatNameToCaptalize = (name: string) => {
  return formatToCapitalized(name, {
    trimTrailingWhiteSpaces: false,
  });
};

//
