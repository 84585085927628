import React from 'react';
import { useRadio, UseRadioProps } from '@chakra-ui/radio';
import { Box } from '@chakra-ui/react';

const ButtonRadio: React.FC<UseRadioProps> = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      color="white"
      w="50%"
      textAlign="center"
      mr="2px"
      borderRadius="5px"
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor="transparent"
        borderRadius="0"
        bg="background.500"
        fontSize="small"
        _checked={{
          bg: 'background.500',
          color: 'white',
          borderColor: 'primary.500',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={2}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ButtonRadio;
