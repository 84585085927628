import { HubApi } from '../index';

class HubApiDashboards extends HubApi {
  async listTablesComparison(date: string) {
    const response = await this.axios.get(`leads/table/${date}`);
    return response.data;
  }

  async listDashOrders() {
    const response = await this.axios.get(`/graphics/dashorders`);
    return response.data;
  }

  async listBigNumbers() {
    const response = await this.axios.get(`/graphics/bignumber`);
    return response.data;
  }

  async listCaptation() {
    const response = await this.axios.get(`/graphics/captation`);
    return response.data;
  }

  async listRv() {
    const response = await this.axios.get(`/graphics/dashorders/rv`);
    return response.data;
  }
}

export const hubApiDashboards = new HubApiDashboards();
