import React, { useEffect, useState } from 'react';
import { Flex, Stack, Box, ButtonGroup } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import { KanbanBaseTaskProps } from 'shared/interfaces';
import { hubApiRv } from 'services/hubApi/classes/HubApiRv';
import { DisplayInformation, Dropdown } from 'components/elements/others';
import { DefaultModalContentWrapper } from 'components/elements/wrappers';
import { SecondaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import formatValue from 'utils/formatValue';
import SharesDetails from '../../../SharesDetails';

interface OrderProps extends KanbanBaseTaskProps {
  assessorName?: string;
  employeebtgcode?: string;
  phone?: string;
  clientEmail?: string;
  information?: string;
  clientName?: string;
  clientBtgAccount?: string;
  value?: string;
  risk?: string;
  segment?: string;
  type?: string;
  strategy?: {
    operation?: string;
    ticker?: string;
  };
  sheres?: string;
}

interface IEmployeeProps {
  id: string;
  name: string;
  orderMKCRMMkt: boolean;
}

interface ComponentProps {
  order: OrderProps;
  handleToggleModal: () => void;
}

export const BoardOrderInfo: React.FC<ComponentProps> = ({
  order,
  handleToggleModal,
}) => {
  const [employeeSelectedId, setEmployeeSelectedId] = useState('');
  const [employeesOptions, setEmployessOptions] = useState<IEmployeeProps[]>(
    []
  );

  useEffect(() => {
    hubApiRv
      .listEmployeesWithRvPermission()
      .then(response => {
        setEmployessOptions(response);
      })
      .catch(error => {
        errorHandler(error);
      });
  }, []);

  const handleTransfered = () => {
    const { id, type } = order;

    hubApiRv
      .transferedTo(type, {
        orderId: id,
        toEmployeeId: employeeSelectedId,
      })
      .then(() => {
        toast.dark('Ordem transferida com suceso');
        handleToggleModal();
      })
      .catch(error => {
        errorHandler(error);
      });
  };

  const handleChangeEmployeeSelected = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setEmployeeSelectedId(event.target.value);
  };

  return (
    <DefaultModalContentWrapper>
      {order.type === 'STRUCTURED' ? (
        <ModalTitle>
          {order.strategy?.operation} de {order.strategy?.ticker}
        </ModalTitle>
      ) : (
        <ModalTitle>{order.segment}</ModalTitle>
      )}

      <Stack spacing="3">
        {order.assessorName && (
          <DisplayInformation
            label="Nome do Assessor"
            info={order.assessorName}
          />
        )}
        {order.clientName && (
          <DisplayInformation label="Nome do cliente" info={order.clientName} />
        )}
        {order.clientBtgAccount && (
          <DisplayInformation
            label="Código do cliente"
            info={order.clientBtgAccount}
          />
        )}

        {order.clientEmail && (
          <DisplayInformation
            label="E-mail do cliente"
            info={order.clientEmail}
          />
        )}

        {order.value && (
          <DisplayInformation
            label="Financeiro"
            info={formatValue(order.value)}
          />
        )}

        {order.risk && <DisplayInformation label="Risco" info={order.risk} />}

        {order.type === 'SPECIFIC' && <SharesDetails shares={order.sheres} />}

        <Flex>
          <Box marginRight="5">
            <Dropdown
              placeholder="Selecione um usuario"
              mb={{ base: '2', md: '4' }}
              onChange={handleChangeEmployeeSelected}
            >
              {employeesOptions.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Dropdown>
          </Box>

          {employeeSelectedId && (
            <SecondaryButton onClick={handleTransfered}>
              Transferir
            </SecondaryButton>
          )}
        </Flex>
      </Stack>

      {/*
      {showChat === true && (
        <ModalWrapper>
          <Chat setShowChat={setShowChat} order={order} />
        </ModalWrapper>
      )} */}

      {/* <Flex width="100%" mt="1">
        <SecondaryButton onClick={handleAnotIn}>
          Anotação Interna
        </SecondaryButton>
        <SecondaryButton onClick={handleChat}>
          Chat
        </SecondaryButton>
      </Flex> */}

      {/* <ModalWrapper isOpen={showIntern} onClose={handleAnotIn}>
        <Intern setShowIntern={setShowIntern} order={order} />
      </ModalWrapper> */}

      <ButtonGroup width="100%" justifyContent="flex-end">
        <SecondaryButton type="button" onClick={handleToggleModal}>
          Fechar
        </SecondaryButton>
      </ButtonGroup>
    </DefaultModalContentWrapper>
  );
};

export default BoardOrderInfo;
