import { Flex, Stack } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { DisplayInformation } from 'components/elements/others';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import { leftClientsManager } from 'pages/GenerationalVariables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';

interface OrderInfoLossClientsProps {
  onClose: () => void;
  selectedLeftClient: leftClientsManager;
  checkManagerLeftClients: () => Promise<void>;
}

export const OrderInfoLossClients: React.FC<OrderInfoLossClientsProps> = ({
  onClose,
  selectedLeftClient,
  checkManagerLeftClients,
}) => {
  return (
    <>
      <DefaultModalContentWrapper>
        <ModalTitle>
          {formatNameToCaptalize(selectedLeftClient.name || '')}
        </ModalTitle>

        <Flex
          mb="2"
          width="100%"
          flexDir={{ base: 'column', md: 'row' }}
          gridGap="6"
        >
          <Stack width="100%" spacing="3">
            <DisplayInformation
              label="Conta do cliente"
              info={selectedLeftClient.btgAccount || ''}
            />
            <DisplayInformation
              label="Nome do assessor"
              info={formatNameToCaptalize(
                selectedLeftClient.employeeName || ''
              )}
            />

            <DisplayInformation
              label="Origem"
              info={`${selectedLeftClient.fromType || ''} - ${
                selectedLeftClient.fromTypeLead || ''
              }`}
            />

            {selectedLeftClient.nps !== null && (
              <DisplayInformation
                label="NPS"
                info={selectedLeftClient.nps || '0'}
              />
            )}

            <DisplayInformation
              label="PL"
              info={formatValue(selectedLeftClient.pl || '0')}
            />

            <DisplayInformation
              label="Dinheiro parado"
              info={formatValue(selectedLeftClient.todayBalance || '0')}
            />

            <DisplayInformation
              label="Motivo"
              info={
                selectedLeftClient.additionalInformation ||
                'O Assessor ainda não mandou o feedBack sobre a saida desse cliente '
              }
            />
          </Stack>
        </Flex>

        <BaseButtonGroup>
          <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
          {selectedLeftClient.additionalInformation.trim() === '' ||
            (selectedLeftClient.status !== 'confirmed deletion' && (
              <PrimaryButton onClick={checkManagerLeftClients}>
                Reconheço a saida deste cliente
              </PrimaryButton>
            ))}
        </BaseButtonGroup>
      </DefaultModalContentWrapper>
    </>
  );
};
