/* eslint-disable radix */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { errorHandler } from 'utils';
import history from 'services/history';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { WhereDidThisCustomerComeFrom } from 'pages/MyClients/components/elements';
import { ModalWrapper, PageWrapper } from 'components/elements/wrappers';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import {
  Alocation,
  Contact,
  Notes,
  Orders,
  Profile,
  Revenue,
} from 'pages/MyClients/components/modules/clientPanels';
import { ClientInfosType, OrdersPanelData } from 'pages/MyClients/interfaces';
import formatStringInTitle from 'utils/formatStringInTitle';
import { IGetTags } from 'pages/MyClients/interfaces/ProfilePanelData';
import { SaveNoteModal } from 'pages/MyClients/components/elements/SaveNoteModal';
import { BackPageButton } from 'components/elements/buttons';
import { pageSections } from './data';

type ClientNotes = {
  notes: string;
};

export const ClientPanels: React.FC = (): JSX.Element => {
  const { account } = useParams<{ account: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [showModalIsClient, setShowModalIsClient] = useState(false);
  const [showModalSaveNote, setShowModalSaveNote] = useState<boolean>(false);
  const [showBackPageButton, setShowBackPageButton] = useState(true);
  const [clientOrders, setClientOrders] = useState([{} as OrdersPanelData]);
  const [clientInfos, setClientInfos] = useState({} as ClientInfosType);
  const [allTags, setAllTags] = useState<IGetTags[]>([]);
  const [tagClient, setTagClient] = useState<IGetTags[]>([]);
  const [clientNotes, setClientNotes] = useState<ClientNotes[]>([]);
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [noteIsSaved, setNoteIsSaved] = useState<boolean>(false);
  const [haveNewNote, setHaveNewNote] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [onlyVizMode, setOnlyVizMode] = useState(false);

  const getClientPanelsData = async (accountNumber: string) => {
    try {
      const response = await hubApiUser.getClientsContactsFromType(
        accountNumber
      );

      if (response.fromType) {
        setShowModalIsClient(true);
      }

      setClientInfos(response);

      const clientOrdersResponse = await hubApiUser.listClientsOrders(
        response.btgAccount
      );
      setClientOrders(clientOrdersResponse);

      const getAllTags = await hubApiUser.getAllTags();
      const getAllTagsClients = await hubApiUser.getAllClientsTags(
        response.contactId
      );

      setTagClient(getAllTagsClients);

      setAllTags(getAllTags);
    } catch (error) {
      errorHandler(error);
    }
    setIsLoading(false);
  };

  const pieData = [
    {
      id: 'Conta Corrente',
      label: 'Conta Corrente',
      value: parseInt(clientInfos.account),
      color: '#2E93fA',
    },
    {
      id: 'Derivativos',
      label: 'Derivativos',
      value: parseInt(clientInfos.deriv),
      color: '#66DA26',
    },
    {
      id: 'Fundos',
      label: 'Fundos',
      value: parseInt(clientInfos.funds),
      color: '#546E7A',
    },
    {
      id: 'Previdência',
      label: 'Previdência',
      value: parseInt(clientInfos.prev),
      color: '#E91E63',
    },
    {
      id: 'Renda Fixa',
      label: 'Renda Fixa',
      value: parseInt(clientInfos.rf),
      color: '#FF9800',
    },
    {
      id: 'Renda Variável',
      label: 'Renda Variável',
      value: parseInt(clientInfos.rv),
      color: '#9C27B0',
    },
  ];

  const alocationPanelData = {
    clientProfile: clientInfos.perfil,
    investorType: clientInfos.investType,
    chartValues: pieData.filter(item => item.value > 0),
  };

  const contactPanelData = [
    {
      label: 'Nome do Cliente',
      value:
        clientInfos.completeName &&
        formatStringInTitle(clientInfos.completeName),
    },
    {
      label: 'Código BTG',
      value: clientInfos.btgAccount,
    },
    {
      label: 'Telefone',
      value: clientInfos.phone,
    },
    {
      label: 'E-mail',
      value: clientInfos.email && clientInfos.email.toLowerCase(),
    },
    {
      label: 'Profissão',
      value: clientInfos.profession,
    },
    {
      label: 'Perfil',
      value: clientInfos.perfil && formatStringInTitle(clientInfos.perfil),
    },
    {
      label: 'Tipo de investidor',
      value: clientInfos.investType,
    },
  ];

  const handleBackPage = () => history.goBack();

  const handleGetClientNotes = async () => {
    try {
      const response: ClientNotes[] = await hubApiUser.getClientNotes(
        clientInfos.contactId
      );

      if (response.length < 1) {
        return;
      }

      setClientNotes(response);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar as anotações deste cliente, recarregue a página em alguns minutos'
      );
    }
  };

  useEffect(() => {
    const searchClientsContext = window.sessionStorage.getItem(
      'searchClientContext'
    );

    if (searchClientsContext) {
      setOnlyVizMode(true);
      window.sessionStorage.removeItem('searchClientContext');
    }
    getClientPanelsData(account);
  }, [account]);

  useEffect(() => {
    if (clientInfos.id) {
      handleGetClientNotes();
    }
  }, [clientInfos]);

  const handleNoteIsSaved = async () => {
    // note !== '<p><br></p>' Significa que o usuario escreveu algo mas apagou em seguida, portanto nao temos notas a salvar
    if (haveNewNote && noteIsSaved === false && note !== '<p><br></p>') {
      setShowModalSaveNote(true);
    } else {
      history.goBack();
    }
  };

  return (
    <PageWrapper
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      mt={[5, 0]}
    >
      {showBackPageButton && onlyVizMode && <BackPageButton />}

      {showBackPageButton && !onlyVizMode && (
        <BackPageButton
          checkIfClientNoteIsSavedBeforeGoBack={handleNoteIsSaved}
        />
      )}

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <MainPageTitle color="white" align="center" fontSize={['sm', 'md']}>
            {clientInfos.completeName}
          </MainPageTitle>
          <Tabs
            orientation={isMobile ? 'horizontal' : 'vertical'}
            p="1"
            w={['100%', '85%']}
            variant="unstyled"
            isLazy
          >
            <TabPanels
              background="background.600"
              p="4"
              borderRadius="5"
              maxW={['100%', '85%']}
              width={['100%', 'auto']}
              height="100%"
              maxHeight={['65vh', 'calc(100vh - 190px)']}
            >
              <TabPanel p="0px">
                <Contact
                  clientId={clientInfos.contactId}
                  basicData={contactPanelData}
                  typeClient={clientInfos}
                  setTypeClient={setClientInfos}
                  onlyVizMode={onlyVizMode}
                  setShowBackPageButton={setShowBackPageButton}
                />
              </TabPanel>

              <TabPanel h="100%" p="0px">
                <Notes
                  onlyVizMode={onlyVizMode}
                  note={note}
                  setNote={setNote}
                  previousNotes={clientNotes}
                  clientId={clientInfos.contactId}
                  setNoteIsSaved={setNoteIsSaved}
                  setHaveNewNote={setHaveNewNote}
                />
              </TabPanel>

              <TabPanel p="0px">
                <Orders
                  orders={clientOrders}
                  showBackPageButton={showBackPageButton}
                  setShowBackPageButton={setShowBackPageButton}
                  clientBTGAccount={account}
                />
              </TabPanel>

              <TabPanel p="0px">
                <Alocation
                  clientProfile={alocationPanelData.clientProfile}
                  investorType={alocationPanelData.investorType}
                  chartValues={alocationPanelData.chartValues}
                  showBackPageButton={showBackPageButton}
                  setShowBackPageButton={setShowBackPageButton}
                />
              </TabPanel>
              <TabPanel p="0px">
                <Revenue clientBTGAccount={clientInfos.btgAccount} />
              </TabPanel>
              <TabPanel p="0px">
                <Profile
                  onlyVizMode={onlyVizMode}
                  clientInfos={clientInfos}
                  tagClient={tagClient}
                  allTags={allTags}
                  setTagClient={setTagClient}
                  setClientInfos={setClientInfos}
                  showBackPageButton={showBackPageButton}
                  setShowBackPageButton={setShowBackPageButton}
                />
              </TabPanel>
            </TabPanels>

            <TabList overflowX={['auto', 'unset']} mt={[2, 0]}>
              {pageSections?.map((section, index) => (
                <Tab
                  key={index}
                  _selected={{
                    color: 'white',
                    bg: 'background.200',
                    border: '1px',
                    borderColor: 'gray.400',
                  }}
                  border="1px"
                  borderColor="background.600"
                  borderRadius="7px"
                  bg="background.600"
                  ml="3"
                  mb="2"
                  p="5"
                  isDisabled={section === 'InvestAí'}
                >
                  {section}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </>
      )}

      <ModalWrapper
        isOpen={showModalIsClient}
        onClose={() => setShowModalIsClient(!showModalIsClient)}
        closeOnOverlayClick={false}
      >
        <WhereDidThisCustomerComeFrom
          clientInfos={clientInfos}
          setClientInfos={setClientInfos}
          handleBackPage={handleBackPage}
          onClose={() => setShowModalIsClient(!showModalIsClient)}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={showModalSaveNote}
        onClose={() => setShowModalSaveNote(!showModalSaveNote)}
        closeOnOverlayClick={false}
      >
        <SaveNoteModal note={note} clientId={clientInfos.contactId} />
      </ModalWrapper>
    </PageWrapper>
  );
};
