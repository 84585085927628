import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { MainPageTitle } from 'components/elements/texts';
import { DefaultPageWrapper } from 'components/elements/wrappers';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { errorHandler } from 'utils';
import { AssignmentProps } from 'pages/Home/interfaces';
import { hubApiHome } from 'services/hubApi/classes/HubApiHome';

import { format } from 'date-fns';

import { Dropdown } from 'components/elements/others';
import { InputWithLabel } from 'components/elements/forms';
import { IContactCalendar } from 'pages/MyClients/interfaces/CalendarTypes';

import { ContentCalendar } from 'pages/MyClients/components/others/ContentCalendar';
import { TaskTypes } from 'pages/Home/interfaces/assignments/Assignment';

export const ContactCalendar: React.FC = () => {
  const [pendencySelected, setPendencySelected] = useState('');
  const [date, setDate] = useState('');
  const [allArrays, setAllArrays] = useState<any[]>([]);
  const [allArraysCopy, setAllArraysCopy] = useState<any[]>([]);
  const handleGetPendecies = async () => {
    try {
      const response = await hubApiHome.listHomeAssignments();

      const filteredListByStatus = response.filter(
        (item: AssignmentProps) =>
          (item.typetask === TaskTypes.birthday ||
            item.typetask === TaskTypes.personalizedMeeting ||
            item.typetask === TaskTypes.nps ||
            item.typetask === TaskTypes.periodicMeeting ||
            item.typetask === TaskTypes.preNps) &&
          item.realized === false
      );

      const dataAtual = new Date();

      const filterByDateIfIsMoreThanToday = filteredListByStatus.filter(
        (item: any) => {
          const data = new Date(item.created_at_pendents)
            .toISOString()
            .slice(0, 10);

          if (data >= dataAtual.toISOString().slice(0, 10)) {
            return item;
          }
        }
      );

      try {
        const responses: IContactCalendar =
          await hubApiUser.getAllClientsAndMeet();

        responses.clients.forEach(client => {
          const hasAnnotation = responses.anotations.filter(
            annotationClient => annotationClient.clientId === client.id
          );

          let lastDateMeeting: Date;
          let frequency = 182;

          if (client.frequencyM && client.frequencyM !== null) {
            const [date, lastContact] = client.frequencyM.split(' | ');

            lastDateMeeting = new Date(lastContact);
            frequency = Number(date);
            lastDateMeeting = new Date(
              lastDateMeeting.setDate(lastDateMeeting.getDate() + frequency)
            );
          } else if (hasAnnotation.length > 0) {
            hasAnnotation.sort((pevItem, nexItem) => {
              let result = -1;

              if (pevItem.updated_at > nexItem.updated_at) {
                result = 1;
              } else if (pevItem.updated_at === nexItem.updated_at) {
                result = 0;
              }

              return result;
            });

            const lastAnnotation = hasAnnotation[hasAnnotation.length - 1];

            lastDateMeeting = new Date(lastAnnotation.updated_at);

            lastDateMeeting = new Date(
              lastDateMeeting.setDate(lastDateMeeting.getDate() + frequency)
            );
          } else {
            let startDate = new Date(client.startDate);

            while (startDate < new Date()) {
              startDate = new Date(
                startDate.setDate(startDate.getDate() + frequency)
              );
            }

            lastDateMeeting = startDate;
          }

          responses.anotations.push({
            btgAccount: client.btgAccount,
            clientId: client.id,
            created_at: client.created_at,
            dataRetorno: lastDateMeeting,
            frequencyM: client.frequencyM || '',
            id: '',
            msg: `Reunião periódica do cliente ${client.name} - ${client.btgAccount}`,
            name: client.name,
            realized: false,
            title: 'REUNIÃO PERIÓDICA',
            type: 'REUNIÃO PERIÓDICA',
            updated_at: new Date(),
          });
        });

        const anotatationRealizedFalse = responses.anotations.filter(
          annotationClient =>
            annotationClient.realized === false && annotationClient.id === ''
        );

        const filteredByDate = anotatationRealizedFalse.filter((item: any) => {
          if (item.dataRetorno >= dataAtual) {
            return item;
          }
        });

        const arrayToAdd = [
          ...filterByDateIfIsMoreThanToday,
          ...filteredByDate,
        ];
        setAllArrays(arrayToAdd);
        filterByDate(arrayToAdd);
      } catch (err) {
        errorHandler(err);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const filterByDate = (arrayToAdd: any[]) => {
    const arraayTest: any[] = [];

    arrayToAdd.forEach(item => {
      if (item.id && item.created_at_pendents) {
        const findItem = arraayTest.findIndex(
          subItem =>
            subItem.data === format(new Date(item.created_at_pendents), 'P')
        );

        if (findItem === -1) {
          arraayTest.push({
            data: format(new Date(item.created_at_pendents), 'P'),
            content: [item],
          });
        }

        if (findItem !== -1) {
          arraayTest[findItem].content.push(item);
        }
      } else {
        const findItem = arraayTest.findIndex(
          subItem => subItem.data === format(new Date(item.dataRetorno), 'P')
        );

        if (findItem === -1) {
          arraayTest.push({
            data: format(new Date(item.dataRetorno), 'P'),
            content: [item],
          });
        }

        if (findItem !== -1) {
          arraayTest[findItem].content.push(item);
        }
      }
    });

    arraayTest.sort((item, proxItem) => {
      let result = -1;

      if (new Date(item.data).getTime() > new Date(proxItem.data).getTime()) {
        result = 1;
      } else if (
        new Date(item.data).getTime() === new Date(proxItem.data).getTime()
      ) {
        result = 0;
      }

      return result;
    });

    setAllArraysCopy(arraayTest);
  };

  const handleFilterPendencies = () => {
    const newFilterArray: any = [];

    if (pendencySelected === '' && !date) {
      filterByDate(allArrays);
      return;
    }

    if (pendencySelected && !date) {
      allArrays.forEach(item => {
        if (item.id && item.typetask === pendencySelected) {
          newFilterArray.push(item);
        }
        if (pendencySelected === 'periodicMeeting') {
          if (item.title === 'REUNIÃO PERIÓDICA') {
            newFilterArray.push(item);
          }
        }
      });

      filterByDate(newFilterArray);
    }

    if (date && pendencySelected === '') {
      allArrays.forEach(item => {
        if (item.id && item.created_at_pendents.slice(0, 7) === date) {
          newFilterArray.push(item);
        }

        if (item.dataRetorno) {
          if (item.dataRetorno.toISOString().slice(0, 7) === date) {
            newFilterArray.push(item);
          }
        }
      });

      filterByDate(newFilterArray);
    }

    if (pendencySelected && date) {
      allArrays.forEach(item => {
        if (item.id) {
          if (item.typetask === pendencySelected && item.created_at_pendents) {
            newFilterArray.push(item);
          }
        } else if (pendencySelected === 'periodicMeeting') {
          if (item.title === 'REUNIÃO PERIÓDICA')
            if (item.dataRetorno) {
              if (item.dataRetorno.toISOString().slice(0, 7) === date) {
                newFilterArray.push(item);
              }
            }
        }
      });

      filterByDate(newFilterArray);
    }
  };

  useEffect(() => {
    handleFilterPendencies();
  }, [pendencySelected, date]);

  useEffect(() => {
    handleGetPendecies();
  }, []);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle color="white" textAlign="center">
        Calendário de contato
      </MainPageTitle>

      <Flex
        mb="3"
        w="100%"
        maxW="1080px"
        justify="flex-end"
        align="center"
        gridGap={3}
        flexDir={['column', 'column', 'row']}
      >
        <Dropdown
          placeholder="Todas as pendências"
          value={pendencySelected}
          mt="2"
          onChange={e => setPendencySelected(e.target.value)}
        >
          <option value="birthday">Aniversário</option>

          <option value="personalizedMeeting">Reuniões Personalizadas</option>
          <option value="periodicMeeting">Reuniões Periódicas</option>
          <option value="pre-nps">Pre-Nps</option>
          <option value="nps">NPS</option>
          <option value="inative">Cliente inativo</option>
        </Dropdown>

        <InputWithLabel
          label=""
          type="month"
          value={date}
          onChange={e => setDate(e.target.value)}
        />
      </Flex>

      <ContentCalendar allArraysCopy={allArraysCopy} />
    </DefaultPageWrapper>
  );
};
