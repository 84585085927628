import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <Button
      background="white"
      color="rgba(70, 70, 70, 1)"
      border="1px solid #1d63ddb2"
      fontWeight="semibold"
      size="sm"
      _hover={{ bg: '#1d63ddd5', color: 'white' }}
      {...rest}
    >
      {children}
    </Button>
  );
};
