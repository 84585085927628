import { Flex, Text } from '@chakra-ui/react';
import { Input } from 'componentsV2/elements/input';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ClientPendencyType } from 'sharedV2/types/client';
import { useRemoveHomeTask } from '../../../hooks/useRemoveHomeTask';
import { useUpdateClientLeftReason } from '../../../hooks/useUpdateClientLeftReason';

type UseGetComponentForClientLeftTaskResponseType = {
  component: JSX.Element;
  action: () => void;
  isLoadingUpdateClientLeftReason: boolean;
};

export const useGetComponentForClientLeftTask = (
  task: ClientPendencyType
): UseGetComponentForClientLeftTaskResponseType => {
  const [additionalInformations, setAdditionalInformations] =
    useState<string>('');

  const { mutateAsync: removeHomeTask } = useRemoveHomeTask();

  const {
    mutateAsync: updateClientLeftReason,
    isLoading: isLoadingUpdateClientLeftReason,
  } = useUpdateClientLeftReason();

  const component = (
    <Flex flexDirection="column" gap={1}>
      <Input.Root>
        <Input.Label
          text={`Informe o motivo da saída do cliente ${task.client.name?.toUpperCase()}:`}
        />
        <Input.TextAreaField
          value={additionalInformations}
          onChange={event => setAdditionalInformations(event.target.value)}
        />
      </Input.Root>
      <Text
        fontSize="sm"
        w="100%"
        textAlign="right"
        color="rgba(70, 70, 70, 1)"
      >
        Cod. BTG: {task.client.btgCode}
      </Text>
    </Flex>
  );

  const action = async () => {
    if (!additionalInformations)
      return toast.dark('Por favor informe o motivo da saída do cliente');

    // await updateClientLeftReason({
    //   additionalInformation: additionalInformations,
    //   id: task.id,
    //   status: 'confirmed deletion',
    // });

    await removeHomeTask(task.id);
  };

  return {
    component,
    action,
    isLoadingUpdateClientLeftReason,
  };
};
