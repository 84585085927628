import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export enum MeetingActionEnum {
  ROUTED = 'routed',
  RETURN = 'return', // SH CONTEXT
  NO_ANSWER = 'no_answer',
  ANSWER = 'answer',
  DECLINED = 'declined',
  OPEN_ACCOUNT = 'open_account',
  SCHEDULE = 'schedule',
  SCHEDULE_CALLBACK = 'schedule_callback', // AAI CONTEXT
  ALLOCATED = 'allocated',
  RECOVER_FROM_PIPE = 'recover_from_pipe',
}

export type MeetingActionPropsType = {
  action: MeetingActionEnum;
  leadType: LeadTypeEnum;
  meetingId?: string;
  leadId?: string;
  date?: Date | string;
  complement?: string;
  declinedReason?: string;
  btgAccount?: string;
  employeeIdParticipant?: string;
  local?: string;
  status?: MeetingStatusEnum;
  isFromProspectPage?: boolean;
};

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?: (error: unknown) => void;
};

export const useMeetingActions = (
  props?: PropsType
): UseMutationResult<unknown, unknown, MeetingActionPropsType, unknown> => {
  return useMutation({
    mutationKey: ['meetingActions'],
    mutationFn: async (data: MeetingActionPropsType) => {
      const response = await hubApiCaptation.meetingAction(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });
};
