import { Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import { BiAddToQueue } from 'react-icons/bi';
import { EventFilterSwitchButton } from './EventFilterSwitchButton';

interface IHeaderProps {
  handleChangeTypeOfEvents: (e: string) => void;
  whatTypeEventIsActive: string;
  setShowCreateEventModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header: React.FC<IHeaderProps> = ({
  handleChangeTypeOfEvents,
  whatTypeEventIsActive,
  setShowCreateEventModal,
}) => {
  return (
    <>
      <Flex justifyContent="flex-end" mb={2}>
        <PrimaryButton onClick={() => setShowCreateEventModal(true)}>
          <BiAddToQueue color="white" size={22} />
          <Text as="span" ml={2}>
            Criar novo evento
          </Text>
        </PrimaryButton>
      </Flex>

      <Flex align="center" justify="center" mb="5">
        <EventFilterSwitchButton
          handleChangeTypeOfEvents={handleChangeTypeOfEvents}
          whatTypeEventIsActive={whatTypeEventIsActive}
        />
      </Flex>
    </>
  );
};
