import React from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';

interface DonwloadInputProps {
  label: string;
  setFile: React.Dispatch<React.SetStateAction<any>>;
}

const DownloadInput: React.FC<DonwloadInputProps> = ({
  label,
  setFile,
  ...rest
}) => {
  return (
    <Flex
      direction="row"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      pos="relative"
      borderRadius="5px"
      w="100%"
      maxH="220px"
      border="1px solid"
      borderColor="#1d63ddb2"
      color="rgba(70, 70, 70, 1)Alpha.800"
      mt="15px"
      p="8px"
      _hover={{ borderColor: 'primary.500' }}
    >
      <Text
        m="0"
        color="rgba(70, 70, 70, 1)Alpha.800"
        fontSize="xs"
        style={{ cursor: 'pointer' }}
        textAlign="center"
      >
        {label}
      </Text>
      <Input
        h="100%"
        w="100%"
        pos="absolute"
        top="0"
        right="0"
        opacity="0"
        cursor="pointer"
        type="file"
        onChange={(e: any) => setFile(e.target.files[0])}
        {...rest}
      />
    </Flex>
  );
};

export default DownloadInput;
