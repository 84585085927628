import React from 'react';

import { BackPageButton } from 'components/elements/buttons';
import CardToLeads from './components/CardToLeads';
import Leads from './components/Leads';

import lead_db from './Lead';

import LeadTransferContainer from './styles';

const LeadTransfer = () => {
  return (
    <LeadTransferContainer>
      <div className="page-leads-column">
        <BackPageButton />

        <div className="title-leads">
          <h4>Transferência de Leads </h4>
        </div>

        <div className="page-lead-content">
          <div className="leads-transfer">
            {lead_db.map((item, index) => (
              <CardToLeads item={item} key={index} Modal={Leads} />
            ))}
          </div>
        </div>
      </div>
    </LeadTransferContainer>
  );
};

export default LeadTransfer;
