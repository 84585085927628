import { Flex } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { TextareaWithLabel } from 'components/elements/forms';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { ModalTitle } from 'components/elements/texts';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import {
  MeetingActionEnum,
  useMeetingAction,
} from 'pages/Captation/hooks/useMeetingAction';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';

type PropsType = {
  lead: LeadMundoInvestType;
  handleToggleOpenLeadScheduleReturnModal: (value: boolean) => void;
  afterOperationCompletedCallbackFunction?: () => void;
};

export const LeadScheduleReturn: React.FC<PropsType> = ({
  lead,
  handleToggleOpenLeadScheduleReturnModal,
  afterOperationCompletedCallbackFunction,
}): JSX.Element => {
  const [returnDate, setReturnDate] = useState<string>('');
  const [complement, setComplement] = useState<string>('');

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingAction();

  const handleOnChangeReturnDate = (date: React.SetStateAction<string>) => {
    setReturnDate(date);
  };

  const isReturnDateInPast =
    new Date(returnDate).getTime() < new Date().getTime();

  const handleScheduleReturn = async () => {
    if (!returnDate) {
      return toast.dark('Por favor escolha uma data');
    }

    if (isReturnDateInPast) {
      return toast.dark('Escolha uma data futura');
    }

    await meetingAction({
      action: MeetingActionEnum.RETURN,
      leadId: lead.id,
      date: returnDate,
      complement,
    });

    if (afterOperationCompletedCallbackFunction) {
      afterOperationCompletedCallbackFunction();
    }

    handleToggleOpenLeadScheduleReturnModal(false);
  };

  return (
    <DefaultModalContentWrapper maxW="460px">
      <ModalTitle>Agendar retorno</ModalTitle>

      <Flex flexDirection="column" gap={5}>
        <DateAndHourPicker
          onChange={date => handleOnChangeReturnDate(date)}
          label="Data"
        />

        <TextareaWithLabel
          label="Complemento:"
          placeholder="Informações sobre a reunião"
          name="Complement"
          mb="2.5"
          rows={4}
          onChange={event => setComplement(event.target.value)}
          value={complement}
        />
      </Flex>

      <BaseButtonGroup>
        <SecondaryButton
          onClick={() => handleToggleOpenLeadScheduleReturnModal(false)}
        >
          Cancelar
        </SecondaryButton>
        <PrimaryButton
          isLoading={isLoadingMeetingAction}
          onClick={handleScheduleReturn}
        >
          Agendar
        </PrimaryButton>
      </BaseButtonGroup>
    </DefaultModalContentWrapper>
  );
};
