import React, { useState, ChangeEvent, useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import UploadButton from 'components/elements/forms/UploadButton';
import DatePicker from 'components/elements/others/DatePicker';

import { PrimaryButton, BackPageButton } from 'components/elements/buttons';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { File } from 'pages/Financials/interfaces';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';

import history from 'services/history';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import { initialFileValue } from './data';

export const FinancialCommissioningReportUpload: React.FC = () => {
  const initialDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const initialMonth = initialDate.toISOString().slice(0, 4);
  const initialYear = initialDate.toISOString().slice(5, 7);

  const [file, setFile] = useState(initialFileValue);
  const [date, setDate] = useState(`${initialMonth}-${initialYear}`);

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const handleSendFile = async () => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const formData = new FormData();
    formData.append('file', file as File);
    formData.append('referring_month', `${month}-${year}`);

    await hubApiFinancial
      .postIncome(formData)
      .then(() => toast.dark('Arquivo enviado com sucesso.'))
      .catch(() =>
        toast.dark('Ocorreu um erro ao enviar arquivo, tente novamente.')
      );
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="white"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Upload do Relatório de Comissionamento
      </MainPageTitle>

      <Flex w="100%" maxWidth="1080px" flexDir="column">
        <Flex mb="12px" flexDir="column" alignItems="center">
          <Box maxWidth="200px" mb="44px">
            <UploadButton
              label={file.name}
              accept=".csv"
              onChange={handlePickFile}
            />
          </Box>

          <Box ml="4px" maxWidth="200px" mb="44px">
            <DatePicker
              label="Pagamento referente à"
              handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
              value={date}
              fullColum
              type="month"
            />
          </Box>

          <Box maxWidth="150px">
            <PrimaryButton py="17px" onClick={handleSendFile}>
              Enviar Arquivo
            </PrimaryButton>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
