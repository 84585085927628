import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useQuery } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadSearchType } from 'sharedV2/types/leadSearch';
import { showToast } from 'utilsV2/showToast';
import { LeadsSearchCard } from './components/leadCard';
import { LeadsSearchSkeletonLoading } from './components/skeletonLoading';

export const LeadsSearch = (): JSX.Element => {
  const {
    filterValue: searchLeadsFilterValue,
    handleChangeFilterValue: handleChangeSearchLeadsFilterValue,
  } = useSimpleQueryFilter('filterValue');

  const {
    data: leads,
    refetch: refetchLeadsSearch,
    isRefetching: isRefetchingLeadsSearch,
    isLoading: isLoadingLeadsSearch,
  } = useQuery<LeadSearchType[]>({
    queryKey: ['leadsSearch'],
    queryFn: async () => {
      const response = await hubApiCaptation.getAllLeads(
        searchLeadsFilterValue
      );
      return response;
    },
    onError: () =>
      showToast('Erro ao buscar leads. Contacte os desenvolvedores!'),
    enabled: false,
  });

  const isLoading = isLoadingLeadsSearch || isRefetchingLeadsSearch;
  const shouldLoadSkeleton = !leads && isLoading;
  const thereIsNoLeadToDisplay = !leads && !isLoading;

  const handleSearchLeads = () => {
    refetchLeadsSearch();
  };

  return (
    <PageWrapper paddingTop="2rem" backPageButton>
      <MainPageTitle>Pesquisa de Leads</MainPageTitle>

      <Flex flexDirection="column" gap={10}>
        <Flex justifyContent="center" gap={2} h="40px">
          <Input.Root w="35vw">
            <Input.TextField
              value={searchLeadsFilterValue}
              onChange={event =>
                handleChangeSearchLeadsFilterValue(event.target.value)
              }
              style={{ flex: 1 }}
              placeholder="Nome, Email ou Telefone"
            />
          </Input.Root>

          <Button.Primary
            h="100%"
            onClick={handleSearchLeads}
            isLoading={isLoading}
          >
            Pesquisar
          </Button.Primary>
        </Flex>

        <Flex justifyContent="center">
          {thereIsNoLeadToDisplay && (
            <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
              Pesquise um lead por Nome, Email ou Telefone
            </Text>
          )}

          <Flex
            overflowY="auto"
            maxH="calc(100vh - 290px)"
            flexDirection="column"
            alignItems="center"
            gap={3}
            className="thinScrollbar"
          >
            {shouldLoadSkeleton && <LeadsSearchSkeletonLoading />}

            {leads &&
              leads.map((lead, index) => (
                <LeadsSearchCard key={index} lead={lead} />
              ))}
          </Flex>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
