import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Stack, useDisclosure } from '@chakra-ui/react';

import { MainPageTitle } from 'components/elements/texts';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { OutlineButton, PrimaryButton } from 'components/elements/buttons';
import { CreateOrEditFrequency } from 'pages/GenerationalVariables/components/elements/wrappers/CreateOrEditFrequency';
import { FrequencyCard } from 'pages/GenerationalVariables/components/elements/cards/FrequencyCard';
import { LoadingSpinner } from 'components/elements/others';
import {
  DefaultCardWrapper,
  ModalWrapper,
  DefaultPageWrapper,
} from 'components/elements/wrappers';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

import { InitialFrequencyDate } from '../../interfaces';

export const LeadFrequencyManagement: React.FC = () => {
  const [frequencys, setFrequencys] = useState<InitialFrequencyDate[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getFrequency();
    }
  }, [lastAuth]);

  const getFrequency = async () => {
    try {
      const response = await hubApiCaptation.getFrequency();
      setFrequencys(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark(`Erro ao buscar as frequências`);
    }
  };

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">
        Gestão de frequência dos leads
      </MainPageTitle>

      <DefaultCardWrapper
        bg="white"
        flexDir="column"
        maxH="calc(100vh - 220px)"
        w="100%"
        p="3"
      >
        <Flex justifyContent="flex-end">
          <PrimaryButton onClick={onOpen}>Criar Frequência</PrimaryButton>
        </Flex>

        <Flex pr="1" mt="2" overflow="auto" flexDir="column">
          <Stack>
            {isLoading ? (
              <Flex align="center" justify="center" w="100%" h="500px">
                <LoadingSpinner />
              </Flex>
            ) : (
              <>
                {frequencys.map((frequency, key) => (
                  <FrequencyCard
                    key={key}
                    frequency={frequency}
                    setFrequencys={setFrequencys}
                    frequencys={frequencys}
                    getFrequency={getFrequency}
                  />
                ))}
              </>
            )}
          </Stack>
        </Flex>

        <ModalWrapper isOpen={isOpen} onClose={onClose}>
          <CreateOrEditFrequency
            onClose={onClose}
            getFrequency={getFrequency}
          />
        </ModalWrapper>
      </DefaultCardWrapper>
    </DefaultPageWrapper>
  );
};
