import * as yup from 'yup';

export default yup.object().shape({
  title: yup.string().required('Digite um titulo.'),
  rating: yup.string().required('Informe o rating.'),
  minimumInvestment: yup.string().required('Informa o investimento minimo.'),
  ceilingRate: yup.string().required('Informe a taxa teto'),
  maturityOfTheOperation: yup
    .string()
    .required('Informe o vencimento da operação'),
  targetAudience: yup.string().required('Informe o público'),
  amortization: yup
    .string()
    .required('Informe a amortização da oferta pública'),
  interest: yup.string().required('Informe os juros'),
});
