import { Flex, Text } from '@chakra-ui/react';
import { Box } from 'componentsV2/elements/boxes';
import { useEffect, useState } from 'react';
import { ClientMeetingStatusEnum, ClientType } from 'sharedV2/types/client';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsCommentedAreas: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const [commentedAreas, setCommentedAreas] = useState<string[]>([]);

  const meetingThatAlreadyHaveBeenConcluded = client.meetings.filter(
    meeting => meeting.status === ClientMeetingStatusEnum.CONCLUDED
  );

  useEffect(() => {
    const array: string[] = [];

    meetingThatAlreadyHaveBeenConcluded.forEach(meeting => {
      meeting.commentedAreas.forEach(area => {
        array.push(area);
      });
    });

    const set = new Set(array);

    setCommentedAreas(Array.from(set));
  }, []);

  return (
    <Flex flexDirection="column" gap={3} w="100%" p={2}>
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Áreas Comentadas
      </Text>
      <Flex
        w="100%"
        flexWrap="wrap"
        gap={3}
        overflowY="auto"
        className="thinScrollbar"
      >
        {meetingThatAlreadyHaveBeenConcluded.length === 0 && (
          <Text textAlign="center" fontStyle="italic" color="#999999">
            Nenhuma área comentada até o momento
          </Text>
        )}
        {commentedAreas.map((area, index) => (
          <Flex
            bgColor="#EDEDED"
            borderRadius={5}
            borderBottom="2px solid rgba(159, 159, 159, 0.698)"
            key={index}
            w="fit-content"
            px={2}
            py={1}
          >
            <Text>{area}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
