import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

import { IChartPieData } from 'shared/interfaces/AAI';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { transformFundsArrayToChartDataPie } from 'utils/trasformDataPieChart';

interface InputDatasProps extends FlexProps {
  label: string;
  dateValue: string;
  valueItem: string;
  setDataChartPie: React.Dispatch<React.SetStateAction<IChartPieData[]>>;
  id: string;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}

export const InputDatas: React.FC<InputDatasProps> = ({
  dateValue,
  valueItem,
  setDataChartPie,
  label,
  setTotal,
  id,
  ...rest
}: InputDatasProps) => {
  const handleSetDataChartPie = async (date: string) => {
    const dataChartMonth = await hubApiFinancial.getDataMonthFinancialEmployee(
      date,
      id
    );
    const dataTransformation = transformFundsArrayToChartDataPie(
      dataChartMonth.data.incomes
    );
    setDataChartPie(dataTransformation);
    setTotal(dataChartMonth.data.total);
  };

  return (
    <Flex
      borderBottomColor="gray.900"
      borderBottomWidth="1px"
      p="4px 8px"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Text
        fontSize="sm"
        color="rgba(70, 70, 70, 1)"
        textTransform="capitalize"
      >
        {label}
      </Text>
      <Flex alignItems="center">
        <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
          {valueItem}
        </Text>
        <MdInfoOutline
          size={16}
          color="rgba(70, 70, 70, 1)"
          cursor="pointer"
          style={{ marginLeft: '12px' }}
          onClick={() => handleSetDataChartPie(dateValue)}
        />
      </Flex>
    </Flex>
  );
};
