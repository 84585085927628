import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import hubApi from 'services/hubApi';
import { PageWrapper } from 'components/elements/wrappers';
import { BackPageButton } from 'components/elements/buttons';
import { EmployeesProps } from 'pages/SearchAllUser/interfaces';
import { MenuDetailUser } from './components/texts';
import { TabItens } from './components/modules';

const DashboardDetailsUser: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [employee, setEmployee] = useState<EmployeesProps>(
    {} as EmployeesProps
  );

  const searchEmployeeData = async (userId?: string) => {
    let employeeData: EmployeesProps;
    if (userId) {
      employeeData = await hubApi.getEmployeeInfos(userId);
    } else {
      employeeData = await hubApi.getEmployeeInfos(employeeId);
    }
    setEmployee(employeeData);
  };

  useEffect(() => {
    searchEmployeeData();
  }, []);

  return (
    <PageWrapper
      flexDir="column"
      alignItems="center"
      minH="calc(100vh - 80px)"
      position="relative"
      overflow="hidden"
      padding="36px 20px 0"
    >
      <BackPageButton />
      <MenuDetailUser
        color="rgba(70, 70, 70, 1)"
        textTransform="capitalize"
        fontSize={['xl', '2xl']}
      >
        {employee?.name}
      </MenuDetailUser>
      {employee.id && (
        <TabItens employee={employee} searchEmployeeData={searchEmployeeData} />
      )}
    </PageWrapper>
  );
};

export default DashboardDetailsUser;
