import { Flex, Text } from '@chakra-ui/react';

export type LeadInformationBoxRequestType = {
  label: string;
  value: string | string[] | number | undefined;
};

export const LeadInfomationBox: React.FC<LeadInformationBoxRequestType> = ({
  label,
  value,
}): JSX.Element => {
  return (
    <Flex
      background="#fff"
      borderLeft="3px solid #1D63DDB2"
      borderRadius={6}
      flexDirection="column"
      p={3}
      gap={2}
      minW="calc(50% - 4px)"
      style={{ flex: 1 }}
    >
      <Text
        isTruncated
        maxW="100%"
        color="rgba(70, 70, 70, 1)"
        fontSize="0.9rem"
        fontWeight="bold"
      >
        {label}
      </Text>
      {Array.isArray(value) ? (
        <Flex flexDirection="column">
          {value.map((item, index) => (
            <Text
              key={index}
              color="rgba(70, 70, 70, 1)"
              isTruncated
              maxW="100%"
              fontSize="0.8rem"
            >
              {item}
            </Text>
          ))}
        </Flex>
      ) : (
        <Text
          color="rgba(70, 70, 70, 1)"
          isTruncated
          maxW="100%"
          fontSize="0.8rem"
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};
