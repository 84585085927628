/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { IBaseMeeting } from 'pages/Captation/interfaces';
import { basicDate, formateDateAndTimeNoTimezone } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import history from 'services/history';

interface ComponentProps {
  meeting: IBaseMeeting;
  type: string;
  isGeralManagement: boolean;
}

export const MeetingsSummaryCard: React.FC<ComponentProps> = ({
  meeting,
  type,
  isGeralManagement,
}) => {
  const formatedDate = meeting.date
    ? basicDate(meeting.date).split('/').slice(0, 2).join('/')
    : meeting.dataretorno
    ? basicDate(meeting.dataretorno).split('/').slice(0, 2).join('/')
    : '';

  const formatedDateForTooltip = meeting.date
    ? basicDate(meeting.date)
    : meeting.dataretorno
    ? basicDate(meeting.dataretorno)
    : '';

  const formatedHour = meeting.date
    ? formateDateAndTimeNoTimezone(meeting.date).split(' às ')[1]
    : meeting.dataretorno
    ? formateDateAndTimeNoTimezone(meeting.dataretorno).split(' às ')[1]
    : '';

  let labelColor;

  switch (type) {
    case 'lateMeetings':
      labelColor = 'red.700';
      break;
    case 'todayMeetings':
      labelColor = 'green.600';
      break;
    default:
      labelColor = 'blue.600';
  }

  const className = 'triangulo';

  const sendToMeeting = () => {
    let url;
    if (meeting.dataretorno) {
      url = `/user/users_clients/new/${meeting.btgaccount}`;
    } else {
      window.sessionStorage.setItem(
        'showMeeting',
        `${meeting.leadId}/${meeting.status}/${meeting.isPipe}`
      );
      url = '/captation/meetings/';
    }

    history.push(url);
  };

  const formatMeetingStatus = (status: string) => {
    switch (status) {
      case 'suitability':
        return 'Suitability';
      case 'presentation':
        return 'Carteira';
      case 'callback':
        return 'Retorno';
      case 'CALLBACK':
        return 'Retorno';
      case 'account':
        return 'Alocação';
      case 'allocation':
        return 'Ag. Abertura de conta';
      case 'alocado':
        return 'Alocado';
      default:
        return 'Prospecção';
    }
  };

  return (
    <Flex
      w="100%"
      minH="60px"
      backgroundColor="background.700"
      borderRadius={4}
    >
      <Flex
        w="76px"
        backgroundColor={labelColor}
        borderLeftRadius={4}
        flexDir="column"
        alignItems="flex-start"
        justify="center"
        title={formatedDateForTooltip}
        className={className}
        px={1}
      >
        <Flex
          w="70px"
          minH="56px"
          backgroundColor="background.900"
          borderLeftRadius={4}
          flexDir="column"
          alignItems="flex-start"
          justify="center"
          title={formatedDateForTooltip}
          className={className}
          px={2.5}
        >
          <Text fontSize="sm" fontWeight="bold" fontStyle="italic">
            {formatedDate}
          </Text>
          <Text fontSize="xs" fontStyle="italic">
            {formatedHour}
          </Text>
        </Flex>
      </Flex>
      <Flex
        align="center"
        ml={2}
        gap={6}
        w="100%"
        justifyContent="space-between"
      >
        <Text maxW="150px" isTruncated>
          {formatNameToCaptalize(meeting.name)}
        </Text>
        <Flex gap={4} alignItems="center">
          <Flex p={1} backgroundColor="background.400" borderRadius={4} px={2}>
            <Text fontSize="xs">
              {meeting.dataretorno
                ? 'R. Periódica'
                : formatMeetingStatus(meeting.status)}
            </Text>
          </Flex>
          <Flex
            minW="90px"
            justifyContent="center"
            mr={isGeralManagement ? 4 : 0}
          >
            <Text alignSelf="center" fontSize="xs">
              {meeting.local || 'Ligação'}
            </Text>
          </Flex>
          {!isGeralManagement && (
            <Flex mr={6} onClick={sendToMeeting} cursor="pointer">
              <Button
                size="xs"
                color="white"
                background="primary.700"
                px={2}
                _hover={{ bg: 'primary.800' }}
                h="20px"
              >
                {}
                <Text fontSize="x-small">Ver mais</Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
