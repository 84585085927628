import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export const LinkExpired: React.FC = () => {
  return (
    <Flex flexDir="column">
      <Text fontSize="lg" color=" white" mb="18px">
        Link Expirado!
      </Text>
      <Text fontSize="md" color=" white" mb="18px">
        Solicite um novo link através do envio de e-mail.
      </Text>
    </Flex>
  );
};
