import React, { useState } from 'react';
import { Box, Stack, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { PrimaryButton } from 'components/elements/buttons';
import CopyToClipboardBtn from 'components/elements/buttons/CopyToClipboardBtn';
import { ModalWrapper, DefaultCardWrapper } from 'components/elements/wrappers';
import { ModalTitle } from 'components/elements/texts';
import { DisplayInformation } from 'components/elements/others';
import { IStructured } from 'pages/RV/subpages/StructuredsOnDemand/interface';
import RequestModal from '../RequestModal';

interface ComponentProps {
  structured: IStructured;
}

const StructuredCard: React.FC<ComponentProps> = ({ structured }) => {
  let structredPerformanceSize = 0;
  if (structured.performance) {
    structredPerformanceSize = structured.performance.length;
  }
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  return (
    <DefaultCardWrapper
      flexDir="column"
      maxW="450px"
      minW="450px"
      marginBottom="20px"
    >
      <ModalTitle>
        {structured.operation
          ? `${structured.operation} de ${structured.ticker}`
          : 'Estruturada'}
      </ModalTitle>
      <Stack>
        {structured.fee && (
          <DisplayInformation label="fee" info={`${structured.fee}%`} />
        )}
        {structured.cupons &&
          structured.cupons.map(
            cupon =>
              cupon.title !== '' &&
              String(cupon.value) !== '' && (
                <DisplayInformation
                  label={cupon.title}
                  info={String(cupon.value)}
                />
              )
          )}
        <DisplayInformation
          label="Estratégia"
          info={structured.strategy && structured.strategy}
          borderBottom="none"
        />
      </Stack>
      {structredPerformanceSize !== 0 && (
        <Table color="white" variant="unstyled" marginTop="10px">
          <Thead>
            <Tr
              borderBottom="1px solid"
              borderColor="background.100"
              color="gray.300"
              fontSize="xs"
            >
              <Th
                color="gray.300"
                fontSize="xs"
                textAlign="center"
              >{`Performance ${structured.ticker}`}</Th>
              <Th color="gray.300" fontSize="xs" textAlign="center">
                Rendimento
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {structured.performance.map((item, index) => (
              <Tr
                borderBottom="1px solid"
                borderColor="background.100"
                key={index}
                maxW="100%"
                color="white"
                fontWeight="medium"
                fontSize="xs"
              >
                <Td textAlign="center">{`${item.performance}%`}</Td>
                <Td textAlign="center">{`${item.income}%`}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <Box d="flex" justifyContent="space-between" marginTop="15px">
        <CopyToClipboardBtn
          textToCopy={structured.whatsMessage ? structured.whatsMessage : ''}
          buttonIcon="whatsapp"
        />
        <PrimaryButton onClick={handleToggleModal}>Solicitar</PrimaryButton>
      </Box>
      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <RequestModal
          structured={structured}
          handleToggleModal={handleToggleModal}
        />
      </ModalWrapper>
    </DefaultCardWrapper>
  );
};

export default StructuredCard;
