import { FormEvent, useEffect, useState } from 'react';
import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle } from 'components/elements/texts';
import {
  DefaultCardWrapper,
  ModalWrapper,
  PageWrapper,
} from 'components/elements/wrappers';
import { TagsCard } from 'pages/MyClients/components/modules/TagsCard';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';

export const ManagementTagClients: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setAllTags] = useState<
    { id: string; employeeId: string; name: string }[]
  >([]);
  const [newTag, setNewTag] = useState<string>('');

  const handleGetAlltags = async () => {
    setIsLoading(true);
    try {
      // const response = await hubApiUser.getAllTags();
      const response = await hubApiClients.getEmployeeTags();

      setAllTags(response);
    } catch (err) {
      toast.dark('Erro ao buscar as tags');
    }
    setIsLoading(false);
  };

  const handleCreateTags = async (e: FormEvent) => {
    e.preventDefault();
    try {
      // const existsTag = tags.includes();
      // if (existsTag) return toast.dark('Tags não podem ter o mesmo nome!');
      // const tagToSend = [{ tag: newTag }];
      // await hubApiUser.postAddNewTags(tagToSend);
      const response = await hubApiClients.createTag(newTag);
      setAllTags([...tags, response]);
      onClose();
      toast.dark('Tag criada com sucesso!');
    } catch (err) {
      toast.dark(
        'Ocorreu um erro ao criar a tag! Tente novamente em alguns minutos ou entre em contato com o suporte.'
      );
    }
  };

  useEffect(() => {
    handleGetAlltags();
  }, []);

  return (
    <PageWrapper flexDir="column" pt="6" align="center">
      <Flex
        justify="space-between"
        mb="3"
        alignItems="center"
        w="100%"
        maxW="1080px"
        mt="3"
      >
        <MainPageTitle marginTop="10px" mb="0" fontSize={['20px', 'xl']}>
          Gestão das Tags dos clientes
        </MainPageTitle>
        <BackPageButton />
      </Flex>

      <DefaultCardWrapper
        w="100%"
        maxW="1080px"
        bg="inherit"
        flexDir="column"
        maxH="calc(100vh - 180px)"
        p="3"
        pr="1.5"
      >
        <Flex justify="flex-end" w="100%">
          <PrimaryButton onClick={onOpen}>Criar uma nova tag</PrimaryButton>
        </Flex>

        <Flex mt="2" overflow="auto" flexDir="column">
          <Stack>
            {isLoading ? (
              <Flex align="center" justify="center" w="100%" h="500px">
                <LoadingSpinner />
              </Flex>
            ) : (
              <TagsCard tags={tags} />
            )}
          </Stack>
        </Flex>
      </DefaultCardWrapper>

      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <BaseModalFormCard
          maxW={['95vw', '480px']}
          isLoading={isLoading}
          title="Adicionar tag"
          handleFormSubmit={handleCreateTags}
          handleToggleModal={onClose}
          primaryButtonText="Confirmar"
          isModal
        >
          <Stack spacing="2">
            <InputWithLabel
              label="Nome da tag:"
              placeholder="Digite o nome para esta tag"
              value={newTag}
              maxLength={30}
              onChange={e => setNewTag(e.target.value)}
            />
          </Stack>
        </BaseModalFormCard>
      </ModalWrapper>
    </PageWrapper>
  );
};
