import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;

  position: relative;

  .title-and-dropdown {
    padding: 2rem;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    width: 100%;
    padding-bottom: 0;

    @media screen and (max-width: 768px) {
      /* padding: 0; */
      text-align: center;
    }
  }

  .dropdown {
    margin: 0 auto;
    width: 67%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  .cards {
    margin: 0 auto;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    @media screen and (max-width: 1000px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const CampaignContent = styled.div`
  width: 400px;
  height: 584px;
  border: 2px solid #3071e4;
  border-radius: 4px;
  padding: 15px;

  .title-content {
    text-align: center;
    margin-bottom: 10px;

    p {
      font-size: var(--smaller-text);
    }
  }

  .leads {
    border: 2px solid #3071e4;
    height: 111px;
    border-radius: 4px;
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;

    .img-group {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list-leads {
      font-size: 12px;
      .single-lead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        border-bottom: 1px solid white;

        /* strong {
                    line-height:;
                } */
      }
    }
  }

  .leads-and-negative-data {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: var(--smaller-text);
    }

    div {
      width: 175px;
      height: 200px;
      border: 2px solid #3071e4;
      border-radius: 4px;
      padding: 10px;

      h6 {
        text-align: center;
      }

      .executation-leads {
        width: 100%;
        height: 95%;
        padding: 0;
        border: 0;

        .single-executation-lead {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: flex-start;
          border: 0;
          margin-bottom: 3px;

          .callback-info {
            border: 1px solid red;
            border: 0;
            width: 175px;
            height: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* flex-direction: column; */
            /* border: 1px solid red; */
          }

          strong {
            font-size: 9px;
            line-height: 11px;
            display: block;
            margin-left: 5px;
          }

          p {
            font-size: 9px;
            line-height: 11px;
            text-align: center;
          }
        }
      }
    }
  }

  .media-and-conversation {
    margin-top: 10px;
    border: 2px solid #3071e4;
    border-radius: 4px;
    width: 365px;
    height: 80px;
    padding: 8px;

    display: grid;
    grid-template-columns: 1fr 3fr;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .media-title {
      border-right: 1px solid white;
      font-size: 12px;
    }

    .media-infos {
      div {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        font-size: 10px;

        p {
          text-align: center;
        }

        strong {
          font-size: 13px;
        }
      }
    }
  }
`;
