import * as Yup from 'yup';

const schema = Yup.object().shape({
  project: Yup.string().required('Informe o Nome do projeto.'),
  title: Yup.string().required('Informe o título da arte.'),
  formatting: Yup.string().required('Formatação é obrigatório.'),
  goals: Yup.string().required('Digite o objetivo.'),
});

export default schema;
