import { AssignmentProps } from 'pages/Home/interfaces';
import { useState } from 'react';
import { MdMoneyOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { ClientFromTypeTaskIcon } from 'shared/assets/icons/ClientFromTypeTaskIcon';
import { ClientLeftTaskIcon } from 'shared/assets/icons/ClientLeftTaskIcon';
import { PeriodicMeetingTaskIcon } from 'shared/assets/icons/PeriodicMeetingTaskIcon';
import { PreNPSTaskIcon } from 'shared/assets/icons/PreNPSTaskIcon';
import { SharedContentTaskIcon } from 'shared/assets/icons/SharedContentTaskIcon';
import { StuckMoneyTaskIcon } from 'shared/assets/icons/StuckMoneyTaskIcon';
import { colors } from 'sharedV2/colors';
import {
  ClientPendencyType,
  ClientPendencyTypeEnum,
} from 'sharedV2/types/client';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';
import { useRemoveHomeTask } from '../../../hooks/useRemoveHomeTask';
import { useGetComponentForClientLeftTask } from '../../tasks/hooks/useGetComponentForClientLeftTask';
import { useGetComponentForClientOriginTask } from '../../tasks/hooks/useGetComponentForClientOriginTask';
import { useGetComponentForStuckMoneyTask } from '../../tasks/hooks/useGetComponentForStuckMoneyTask';

type ButtonType = {
  text: string;
  action: () => void;
  isLoading?: boolean;
};

export type TaskCardModalSettings = {
  title: string;
  subtitle?: string;
  primaryButton: ButtonType;
  customComponent?: React.ReactNode;
};

type TaskCardConfig = {
  title: string;
  borderColor: string;
  icon: React.ReactNode;
  mibsQuantity?: number;
  modalSettings: TaskCardModalSettings;
  subtitle: string;
};

type TaskCardConfigs = Record<string, TaskCardConfig>;

type UseEachTaskPropertiesResponseType = {
  taskCardConfig: TaskCardConfig;
  getSubtitle: (task: AssignmentProps) => string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

export const useEachTaskProperties = (
  task: ClientPendencyType
): UseEachTaskPropertiesResponseType => {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { mutateAsync: removeHomeTask, isLoading: isLoadingRemoveHomeTask } =
    useRemoveHomeTask();

  const {
    component: ClientOriginCustomComponent,
    action: clientOriginAction,
    isLoadingUpdateClient,
  } = useGetComponentForClientOriginTask(task, setIsModalOpen);

  const {
    component: ClientLeftCustomComponent,
    action: clientLeftAction,
    isLoadingUpdateClientLeftReason,
  } = useGetComponentForClientLeftTask(task);

  const { component: StuckMoneyCustomComponent } =
    useGetComponentForStuckMoneyTask(task);

  const getSubtitle = () => {
    // if (task.type === 'periodicMeeting') {
    //   return formatNameToCaptalize(task.clientName || task.name);
    // }

    // if (task.typetask === 'pre-nps') {
    //   return `Cliente ${formatNameToCaptalize(task.clientName || task.name)} (${
    //     task.btgAccount
    //   }) receberá em um mês a pesquisa NPS`;
    // }

    // if (task.typetask === 'nps') {
    //   return `Cliente ${formatNameToCaptalize(task.clientName || task.name)} (${
    //     task.btgAccount
    //   }) recebeu uma pesquisa de NPS`;
    // }

    if (task.type === ClientPendencyTypeEnum.CLIENT_LEFT) {
      return `Cliente ${formatNameToCaptalize(task.client.name)} saiu`;
    }

    if (task.type === ClientPendencyTypeEnum.MISSING_ORIGIN) {
      return `O Cliente ${formatNameToCaptalize(task.client.name)} (${
        task.client.btgCode
      }) acabou de entrar! Identifique a origem do cliente`;
    }

    // if (task.typetask === 'sharedContent') {
    //   const { employeeWhoSharedContent } = JSON.parse(task.extra || '');
    //   return `${formatNameToCaptalize(
    //     employeeWhoSharedContent
    //   )} compartilhou um conteúdo com você!`;
    // }

    if (task.type === ClientPendencyTypeEnum.STUCK_MONEY) {
      // const { name, stuckMoneyAmount } = JSON.parse(task.extra || '');

      const clientName =
        task.client.name.length >= 30
          ? `${task.client.name.slice(0, 30)}...`
          : task.client.name;

      return `Cliente ${clientName} está com ${formatValue(
        task.client.currentBalance || 0
      )} parado!`;
    }

    if (task.type === ClientPendencyTypeEnum.NEGATIVE_BALANCE) {
      return `Cliente ${task.client.name} está com ${
        Number((task?.client.currentBalance || 0) < 0) ? '' : '-'
      }${formatValue(task?.client.currentBalance || 0)} de saldo em sua conta!`;
    }

    return `Unable to get a subtitle for this typetask ${task.type}`;
  };

  const taskCardConfigs: TaskCardConfigs = {
    periodicMeeting: {
      title: 'Reunião Periodica',
      subtitle: getSubtitle(),
      borderColor: '#39DDA2',
      icon: <PeriodicMeetingTaskIcon />,
      modalSettings: {
        title: 'Deseja acessar essa reunião periodica?',
        subtitle:
          'Ao acessar a reunião periodica o card será removido da sua lista de tarefas',
        primaryButton: {
          text: 'Sim',
          action: async () => {
            await removeHomeTask(task.id);
            history.push(`/user/users_clients/new/${task.client.btgCode}`);
          },
          isLoading: isLoadingRemoveHomeTask,
        },
      },
    },
    sharedContent: {
      title: 'Conteúdo comp.',
      borderColor: '#1D63DD',
      subtitle: getSubtitle(),
      icon: <SharedContentTaskIcon />,
      modalSettings: {
        title: 'Deseja acessar o conteúdo?',
        subtitle:
          'Ao acessar o conteúdo o card será removido da sua lista de tarefas',
        primaryButton: {
          text: 'Sim',
          action: async () => {
            removeHomeTask(task.id);

            // const { contentId } = JSON.parse(task.extra || '');
            // history.push(`/contents/content/${contentId}`);
          },
          isLoading: isLoadingRemoveHomeTask,
        },
      },
    },
    stuckMoney: {
      title: 'Dinheiro parado',
      borderColor: '#E0B55A',
      subtitle: getSubtitle(),
      icon: <StuckMoneyTaskIcon />,
      mibsQuantity: 5,
      modalSettings: {
        title: 'Dinheiro parado',
        customComponent: StuckMoneyCustomComponent,
        primaryButton: {
          text: 'Sim',
          action: async () => {
            await removeHomeTask(task.id);

            setIsModalOpen(false);
          },
          isLoading: isLoadingRemoveHomeTask,
        },
      },
    },
    'pre-nps': {
      title: 'Pré-NPS',
      subtitle: getSubtitle(),
      borderColor: '#A342ED',
      icon: <PreNPSTaskIcon />,
      modalSettings: {
        title: 'Pesquisa PRÉ NPS',
        subtitle: `Você confirma que o(a) cliente ${task.client.name?.toUpperCase()} foi avisado(a) sobre a pesquisa?`,
        primaryButton: {
          text: 'Sim',
          action: async () => {
            await removeHomeTask(task.id);
            setIsModalOpen(false);
          },
          isLoading: isLoadingRemoveHomeTask,
        },
      },
    },
    nps: {
      title: 'NPS',
      subtitle: getSubtitle(),
      borderColor: '#A342ED',
      icon: <PreNPSTaskIcon />,
      modalSettings: {
        title: 'Pesquisa NPS',
        subtitle: `Você confirma que o(a) cliente ${task.client.name?.toUpperCase()} foi avisado(a) sobre a pesquisa?`,
        primaryButton: {
          text: 'Sim',
          action: async () => {
            await removeHomeTask(task.id);
            setIsModalOpen(false);
          },
          isLoading: isLoadingRemoveHomeTask,
        },
      },
    },
    clientLeft: {
      title: 'Saída de Cliente',
      subtitle: getSubtitle(),
      borderColor: '#FC8D62',
      icon: <ClientLeftTaskIcon />,
      mibsQuantity: 5,
      modalSettings: {
        title: 'Saída de cliente',
        customComponent: ClientLeftCustomComponent,
        primaryButton: {
          text: 'Confirmar',
          action: async () => clientLeftAction(),
          isLoading: isLoadingUpdateClientLeftReason || isLoadingRemoveHomeTask,
        },
      },
    },
    missingOrigin: {
      title: 'Origem de Cliente',
      subtitle: getSubtitle(),
      borderColor: '#E377C2',
      icon: <ClientFromTypeTaskIcon />,
      mibsQuantity: 10,
      modalSettings: {
        title: `Como o(a) cliente ${task.client.name?.toUpperCase()} foi captado(a) ?`,
        customComponent: ClientOriginCustomComponent,
        primaryButton: {
          text: 'Confirmar',
          isLoading: isLoadingUpdateClient || isLoadingRemoveHomeTask,
          action: () => clientOriginAction(),
        },
      },
    },
    negativeBalance: {
      title: 'Cliente Negativo',
      subtitle: getSubtitle(),
      borderColor: colors.RED,
      icon: <MdMoneyOff color={colors.RED} />,
      mibsQuantity: 5,
      modalSettings: {
        title: `Cliente Negativo`,
        subtitle: `Você confirma que o(a) cliente ${
          task.client.name?.toUpperCase() || 'desconhecido'
        } não está negativo(a)?`,
        primaryButton: {
          text: 'Confirmar',
          isLoading: isLoadingRemoveHomeTask,
          action: async () => {
            await removeHomeTask(task.id);
          },
        },
      },
    },
  };

  return {
    taskCardConfig: taskCardConfigs[task.type],
    getSubtitle,
    isModalOpen,
    setIsModalOpen,
  };
};
