import { Flex, Text } from '@chakra-ui/react';

interface AbstractValuesProps {
  getAllPendencies: () => {
    fromType: number;
    birthday: number;
    negative: number;
    nps: number;
    inative: number;
    meetPendency: number;
  };
}
export const AbstractValues: React.FC<AbstractValuesProps> = ({
  getAllPendencies,
}) => {
  return (
    <Flex
      w="100%"
      mt="2"
      align="center"
      flexDir={['column', 'column', 'column', 'row']}
      justify="space-between"
    >
      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          Origem do cliente:{' '}
          <Text as="span" fontWeight="semibold">
            {getAllPendencies().fromType}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          Aniversário:{' '}
          <Text fontWeight="semibold" as="span">
            {getAllPendencies().birthday}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          Saldo negativo:{' '}
          <Text fontWeight="semibold" as="span">
            {getAllPendencies().negative}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          NPS:{' '}
          <Text fontWeight="semibold" as="span">
            {getAllPendencies().nps}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          Reuniões pendentes:{' '}
          <Text fontWeight="semibold" as="span">
            {getAllPendencies().meetPendency}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Text fontSize="sm" color="white" fontWeight="bold">
          Cliente inativo:{' '}
          <Text fontWeight="semibold" as="span">
            {getAllPendencies().inative}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
