import { useMutation, UseMutationResult } from 'react-query';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';

export type CreateMeetingPropsType = {
  leadId: string;
  leadType: LeadTypeEnum;
  local: string;
  date: string | Date;
  employeeId: string;
  status: MeetingStatusEnum;
  filialId?: string;
  filialName?: string;
  complement?: string;
};

type PropsType = {
  onSuccess?: (result: any) => void;
  onError?: (error: unknown) => void;
};

export const useCreateMeeting = (
  props?: PropsType
): UseMutationResult<unknown, unknown, CreateMeetingPropsType, unknown> => {
  return useMutation({
    mutationKey: ['createMeeting'],
    mutationFn: async (data: CreateMeetingPropsType) => {
      const response = await hubApiCaptation.createMeeting(data);
      return response;
    },
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });
};
