import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import { TextareaWithLabel } from 'components/elements/forms/TextareaWithLabel';
import { BaseModalFormCard } from 'components/elements/cards/BaseModalFormCard';
import { Dropdown } from 'components/elements/others';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { InputWithLabel } from 'components/elements/forms';

interface IReminderModal {
  setShowReminder: () => void;
  status: boolean;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IListOptions {
  created_at: string;
  id: string;
  managerId: string;
  name: string;
  officeId: string;
  updated_at: string;
}

interface IListFilial {
  cep: string;
  city: string;
  created_at: string;
  id: string;
  number: string;
  street: string;
  uf: string;
  updated_at: string;
  zona: string;
}

export const NewNoticeModal: React.FC<IReminderModal> = ({
  setShowReminder,
  status,
  setStatus,
}) => {
  const [reminderType, setReminderType] = useState('Selecione um tipo');
  const [reminderTitleMsg, setReminderTitleMsg] = useState('');
  const [reminderMsg, setReminderMsg] = useState('');
  const [reminderDateMsg, setReminderDateMsg] = useState('');
  const [tableName, setTableName] = useState('Selecione uma mesa');
  const [FilialName, setFilialName] = useState('Selecione uma Filial');
  const [listOptions, setListOptions] = useState<IListOptions[]>([]);
  const [listFilial, setListFilial] = useState<IListFilial[]>([]);
  const [tabelId, setTableId] = useState('');
  const [filialId, setFilialId] = useState('');

  const typesOptions = [
    { title: 'Mesa' },
    { title: 'Filial' },
    { title: 'Todos' },
  ];

  const handleChangeType = (item: any) => {
    setReminderType(item);
  };

  const handleChangeTable = (item: any) => {
    setTableId(item);
    setTableName(item.name);
  };

  const handleChangeFilial = (item: any) => {
    setFilialName(item.city);
    setFilialId(item);
  };

  useEffect(() => {
    hubApiOfficeTables.listTablesOffice().then(response => {
      setListOptions(response);
    });
  }, []);

  useEffect(() => {
    hubApiOfficeTables.listFilial().then(response => {
      setListFilial(response);
    });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    hubApiUser
      .postReminderManager({
        forId: reminderType === 'Mesa' ? tabelId : filialId,
        type: reminderType,
        title: reminderTitleMsg,
        msg: reminderMsg,
        data: reminderDateMsg,
      })
      .then(() => {
        toast.dark('Anotação criada com sucesso.');
        setStatus(!status);
        setShowReminder();
      });
  };

  return (
    <BaseModalFormCard
      isModal
      maxW={['95vw', '440px']}
      title="Criar aviso"
      handleFormSubmit={handleSubmit}
      handleToggleModal={setShowReminder}
      isLoading={false}
      primaryButtonText="Criar"
      secondaryButtonText="Fechar"
    >
      <Stack spacing="3">
        <Dropdown
          mt="5"
          placeholder={reminderType}
          onChange={event => handleChangeType(event.target.value)}
        >
          {typesOptions.map((typesOption, index: number) => (
            <option key={index} value={typesOption.title}>
              {typesOption.title}
            </option>
          ))}
        </Dropdown>
        {reminderType === 'Mesa' && (
          <Dropdown
            mt="5"
            placeholder={tableName}
            onChange={event => handleChangeTable(event.target.value)}
          >
            {listOptions.map((listOption, index: number) => (
              <option key={index} value={listOption.id}>
                {listOption.name}
              </option>
            ))}
          </Dropdown>
        )}
        {reminderType === 'Filial' && (
          <Dropdown
            mt="5"
            placeholder={FilialName}
            onChange={event => handleChangeFilial(event.target.value)}
          >
            {listFilial.map((filial, index: number) => (
              <option key={index} value={filial.id}>
                {`${filial.city} - ${filial.zona}`}
              </option>
            ))}
          </Dropdown>
        )}
        <InputWithLabel
          label="Título do lembrete"
          onChange={e => setReminderTitleMsg(e.target.value)}
        />
        <InputWithLabel
          label="Data"
          type="datetime-local"
          onChange={e => setReminderDateMsg(e.target.value)}
        />

        <TextareaWithLabel
          label="Mensagem do lembrete"
          onChange={e => setReminderMsg(e.target.value)}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
