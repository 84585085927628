import { TableBase, TableRow, TableData } from 'components/elements/table';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { Text } from '@chakra-ui/react';
import formatValue from 'utils/formatValue';

interface IIncome {
  value: number;
  fieldName: string;
}

interface CommissionTableProps {
  tableHeaders: string[];
  incomes: IIncome[];
}

export const CommissionTable: React.FC<CommissionTableProps> = ({
  tableHeaders,
  incomes,
}) => {
  return (
    <TableBase
      headData={tableHeaders}
      w="100%"
      maxW={{ base: '1080px', '2xl': '1240px' }}
      maxH="380px"
      overflow="auto"
      p={0}
      mt={2}
      color="rgba(70, 70, 70, 1)"
    >
      {incomes.length === 0 ? (
        <Text color="background.100" textAlign="center" pt={4} pb={4}>
          Sem comissões para este período
        </Text>
      ) : (
        incomes.map((income, index) => (
          <TableRow
            key={`${income.fieldName}-${index}`}
            _hover={{ background: 'background.500' }}
            cursor="pointer"
            transition="400ms"
          >
            <TableData w="300px">
              <Text isTruncated>{formatNameToCaptalize(income.fieldName)}</Text>
            </TableData>
            <TableData w="300px">
              <Text textAlign="end" isTruncated>
                {formatValue(income.value)}
              </Text>
            </TableData>
          </TableRow>
        ))
      )}
    </TableBase>
  );
};
