import { Flex } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { TableBase } from 'componentsV2/elements/table/base';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { LeadProspectType } from 'sharedV2/types/leadProspect';
import { LeadProspectTableRow } from '../tableRow';

type PropsType = {
  filteredLeads: LeadProspectType[] | undefined;
  handleRefetchLeadsProspect: () => void;
  isFetchingNextPageLeadsProspect: boolean;
};

export const LeadsProspectTable: React.FC<PropsType> = ({
  filteredLeads,
  handleRefetchLeadsProspect,
  isFetchingNextPageLeadsProspect,
}): JSX.Element => {
  const tableHeadData = [
    {
      columnName: 'Nome',
      widthPercentage: 140,
    },
    {
      columnName: 'Telefone',
      widthPercentage: 100,
    },
    {
      columnName: 'Último Contato',
      widthPercentage: 100,
    },
    {
      columnName: 'Origem',
      widthPercentage: 70,
    },
    {
      columnName: 'Tentativas de Contato',
      widthPercentage: 90,
    },
    {
      columnName: '',
      widthPercentage: 30,
    },
  ];

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetchingNextPageLeadsProspect) {
      handleRefetchLeadsProspect();
    }
  }, [inView, handleRefetchLeadsProspect]);

  return (
    <Flex flexDirection="column" alignItems="center" gap={2}>
      <TableBase
        headData={tableHeadData}
        overflowY="auto"
        maxH="calc(100vh - 250px)"
        className="thinScrollbar"
        tableRef={tableContainerRef}
      >
        {filteredLeads &&
          filteredLeads.map((lead, index) => (
            <LeadProspectTableRow
              key={index}
              lead={lead}
              tableHeadData={tableHeadData}
            />
          ))}

        {!isFetchingNextPageLeadsProspect && (
          <div ref={ref} style={{ visibility: 'hidden' }}>
            texto para renderizar a div
          </div>
        )}

        {isFetchingNextPageLeadsProspect && (
          <Flex justifyContent="center">
            <LoadingSpinner h="35px" w="35px" mt={5} />
          </Flex>
        )}
      </TableBase>
    </Flex>
  );
};
