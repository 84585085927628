import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Divider, Flex, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import hubApi from 'services/hubApi';
import { useCaptationMeetingsContext } from 'hooks';
import { ModalTitle } from 'components/elements/texts';
import { BaseButtonGroup, ModalWrapper } from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import {
  OutlineButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import {
  IBaseLeadPJ,
  IBaseMeeting,
  IQuestionProps,
} from 'pages/Captation/interfaces';
import { Textarea } from 'components/elements/forms';
import { errorHandler } from 'utils';
import { RecoverLeadModal } from 'pages/Captation/components/modules/meetings';
import { ContactAttempts } from 'pages/Captation/components/elements/others';
import { LoadingSpinner } from 'components/elements/others';
import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import { ContentDividerTitle } from 'pages/Captation/components/elements/texts';
import { useNavigation } from 'hooksV2/useNavigation';
import { LeadUtils } from 'utilsV2/Lead';
import { LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { MeetingQuestions } from '../MeetingQuestions';
import { MeetingSHInfos } from '../MeetingSHInfos';
import {
  LeadBillingRange,
  LeadComplement,
  LeadEmailsContent,
  LeadPjHeadContent,
  LeadsPhonesContent,
  LeadTags,
} from '../../leads';
import {
  ClientOpenAccount,
  ConfirmLeadMissCall,
  DeclineOffer,
  ScheduleNextSaleMeeting,
  ScheduleReturn,
} from '../../modals';
import { LeadOthersContent } from '../../leads/LeadOthersContent';
import { getMIPatrimony } from '../../utils';
import { MeetingComplement } from '../../leads/MeetingComplement';
import {
  IMeetingsInfoFromMI,
  MeetingsInfoFromMI,
} from '../MeetingsInfosFromMI';
import { LeadPricePaid } from '../../leads/LeadPricePaid';

interface MeetingDetailsContentProps {
  meetingInfos: IBaseMeeting;
  segment:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'pipe'
    | 'declined';
  handleChangeMeetingMenu: (
    selectedSegment:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'declined',
    selectedTitle: string,
    selectedType: string
  ) => void;
  leadAnswered: boolean;
  setLeadAnswered: React.Dispatch<React.SetStateAction<boolean>>;
  handleLeadAnsweredCall: () => Promise<void>;
  totalNumOfLeads: number;
  updateTotalNumOfLeadsInPipe: () => void;
}

export interface IQuestion {
  created_at: string;
  id: string;
  idQuestion: string;
  isPrimary: boolean;
  leadId: string;
  updated_at: string;
  question: string;
  answer: string;
}

export const MeetingDetailsContent: React.FC<MeetingDetailsContentProps> = ({
  meetingInfos,
  segment,
  handleChangeMeetingMenu,
  leadAnswered,
  setLeadAnswered,
  handleLeadAnsweredCall,
  updateTotalNumOfLeadsInPipe,
  totalNumOfLeads,
}) => {
  const { redirect } = useNavigation();
  const [isMobile] = useMediaQuery('(max-width: 30em)');
  const [decline, setDecline] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [returnModal, setReturnModal] = useState<boolean>(false);
  const [showRecoverModal, setShowRecoverModal] = useState<boolean>(false);
  const [openedBTGAccount, setOpenedBTGAccount] = useState<boolean>(false);
  const [missCall, setMissCall] = useState<boolean>(false);
  const [meetingResponsible, setMeetingResponsible] = useState<string>('');
  const [taskResponse, setTaskResponse] = useState<IQuestionProps[]>([]);
  const [lead, setLead] = useState({} as IBaseLeadPJ);
  const [questionItem, setQuestionItem] = useState({
    question: 'informacoesadicionaistextarea',
  } as IQuestion);
  const [updatedContactAttempts, setUpdatedContactAttempts] = useState(0);
  const [meetingInfoFromMI, setMeetingInfoFromMI] =
    useState<IMeetingsInfoFromMI>({
      id: '',
      age: 0,
      dependents: 0,
      activity: '-',
      maritalStatus: '-',
      monthlyIncome: 0,
      monthlyExpense: 0,
      ageToRetire: 0,
      desiredIncome: 0,
      incomeType: '',
      investPerMonth: 0,
      investorProfile: '',
      lifeMoment: '',
      created_at: '',
    });
  const [isLoading, setIsLoading] = useState(false);
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const handleAddTextAreaQuestion = async () => {
    if (questionItem.id) {
      try {
        await hubApiCaptation.editQuestion({
          id: questionItem.idQuestion,
          question: questionItem.question,
          leadId: questionItem.leadId,
        });

        await hubApiCaptation.attQuestionResponse({
          id: questionItem.id,
          answer: questionItem.answer ? questionItem.answer : ' ',
        });
        toast.dark('Informações atualizadas com sucesso!');
      } catch (err) {
        errorHandler(err);
      }
    } else {
      try {
        if (!questionItem.answer) return;
        const response = await hubApiCaptation.postNewQuestion({
          question: questionItem.question,
          status: 'standard',
        });

        await hubApiCaptation.postResponse({
          data: [
            {
              answer: questionItem.answer,
              leadId: lead.id,
              idQuestion: response.id,
            },
          ],
        });

        handleGetLeadData();
        toast.dark('Informações atualizadas com sucesso!');
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const handleChangeTextAreaValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuestionItem({ ...questionItem, answer: e.target.value });
  };
  const [handleShowContactModal, setHandleShowContactModal] =
    useState<boolean>(false);

  const handleGetLeadData = async () => {
    setIsLoading(true);
    try {
      const leadData = await hubApiCaptation.getLeadById(
        meetingInfos.leadType,
        meetingInfos.leadId || meetingInfos.id
      );
      setLead(leadData);

      if (leadData?.meetings) {
        setLeadAnswered(
          leadData.meetings[leadData.meetings.length - 1].confirm
        );
      }

      const questionsResponse: IQuestion[] =
        await hubApiCaptation.getQuestionResponse(leadData.id);

      const existAddInfo = questionsResponse.filter(
        questionsRespons => questionsRespons.question === questionItem.question
      );

      if (existAddInfo.length > 0) {
        setQuestionItem(existAddInfo[0]);
      }

      const anotherQuestions = questionsResponse.filter(
        questionsRespons => questionsRespons.question !== questionItem.question
      );

      setTaskResponse(anotherQuestions);
    } catch (err) {
      toast.dark('Não foi possível buscar dados do lead');
    }
    setIsLoading(false);
  };

  const handleDisplayModal = () => setDisplayModal(!displayModal);
  const handleReturnModal = () => setReturnModal(!returnModal);
  const handleDecline = () => setDecline(!decline);
  const handleOpenReturnMeeting = () => handleReturnModal();
  const handleOpenScheduleMeeting = () => handleDisplayModal();
  const handleToggleMissCall = () => setMissCall(!missCall);

  const handleOpenBTGAccountOpened = () => {
    setOpenedBTGAccount(!openedBTGAccount);
  };

  const handleToggleContactModal = () => {
    setHandleShowContactModal(!handleShowContactModal);
  };

  const postTasks = async () => {
    await hubApiCaptation.postNewQuestion(taskResponse);
  };

  const handleGetResponsibleSH = async () => {
    try {
      const responsible = await hubApi.getEmployeeInfos(
        meetingInfos.employeeId
      );
      if (responsible) setMeetingResponsible(responsible.name);
    } catch (err) {
      return toast.dark(
        'Não foi possivel encontrar o SH responsavel. Tente novamente mais tarde.'
      );
    }
  };

  const handleClientAllocated = async () => {
    try {
      await handleAddTextAreaQuestion();
      await hubApiCaptation.pathMetting({
        status: 'alocado',
        id: meetingInfos.id,
        leadId: meetingInfos.leadId,
        leadType: meetingInfos.leadType,
        date: new Date(),
        local: '',
        complement: '',
        employeeId: meetingInfos.employeeId,
        employeeIdParticipant: meetingInfos.employeeIdParticipant,
        feedback: '',
        isPipe: false,
      });

      await handleAddTextAreaQuestion();
      await handleGetSpecificLeadTypeArr('pipe', true);
      await handleGetSpecificLeadTypeArr('account', true);
      toast.dark('Cliente alocado com sucesso.');
      updateTotalNumOfLeadsInPipe();
    } catch {
      toast.dark('Ocorreu um erro ao definir cliente como alocado');
    }
  };

  const handleShowRecoverModal = () => {
    setShowRecoverModal(true);
  };

  const handleGetMeetingsInfoFromMI = async () => {
    const response = await hubApi.getMeetingInfoFromMI(
      lead.email?.[0]?.email as string
    );

    setMeetingInfoFromMI(response.clients[0]);
  };

  useEffect(() => {
    if (lead.email?.[0]?.email !== undefined) handleGetMeetingsInfoFromMI();
  }, [lead]);

  useEffect(() => {
    handleGetResponsibleSH();
  }, []);

  useEffect(() => {
    handleGetLeadData();
  }, [meetingInfos.leadType, meetingInfos.id]);

  return (
    <Flex flexDir="column" justifyContent="space-between" height="100%">
      <Flex height="100%" justifyContent="inherit" flexDir={['column', 'row']}>
        <Flex
          flexDir="column"
          width={['100%', '70%']}
          height={['30%', '100%']}
          maxH="calc(100vh - 200px)"
          overflowY="auto"
          pr={2}
        >
          {lead.type === 'leadspj' ? (
            <LeadPjHeadContent
              cnpj={lead.CNPJ}
              corporateName={lead.razaoSocial}
              role={lead.cargo}
            />
          ) : (
            <ModalTitle mb="1">{lead.name?.toUpperCase()}</ModalTitle>
          )}
          <SecondaryButton
            mt={2}
            p={2}
            w="50%"
            h="25px"
            onClick={() =>
              redirect(
                `/v2/lead/${LeadUtils.transformLeadType(
                  meetingInfos.leadType
                )}/${lead.id || meetingInfos.leadId}`
              )
            }
          >
            Ir para a pagina do lead
          </SecondaryButton>
          <Stack spacing="3.5" mt="3.5" mb={2}>
            {lead?.phone && <LeadsPhonesContent phones={lead.phone} />}

            {lead?.others && <LeadOthersContent others={lead.others} />}
            {lead?.email && lead.email.length > 0 && (
              <LeadEmailsContent emails={lead.email} />
            )}
            {meetingResponsible.trim() !== '' &&
              meetingInfos.leadType === 'publics' && (
                <>
                  <LeadComplement
                    title="SH responsável"
                    complement={formatNameToCaptalize(meetingResponsible)}
                  />
                  <LeadComplement
                    title="Patrimônio (Mundo Invest)"
                    complement={getMIPatrimony({ lead })}
                  />
                </>
              )}
            {lead.faixaFaturamento && (
              <LeadBillingRange billingRange={lead.faixaFaturamento} />
            )}
            {lead.meetings && lead.meetings[0].complement !== '' && (
              <LeadComplement complement={lead.meetings[0].complement} />
            )}
            {lead.tags?.length > 0 && <LeadTags tags={lead.tags} />}
            {lead?.questions?.length > 0 && (
              <MeetingSHInfos
                questions={lead.questions[0]}
                meetingInfos={lead.meetings[0]}
              />
            )}
            {lead.price && (
              <LeadPricePaid
                leadPrice={
                  lead.meetings.length > 0 ? lead.meetingPrice ?? 0 : lead.price
                }
              />
            )}
            {lead.origin !== 'unknown' && (
              <ContentWithVertDividerWrapper>
                <ContentDividerTitle mb="1">Origem do Lead</ContentDividerTitle>
                <Flex flexWrap="wrap" gridGap="1.5">
                  <Text fontSize="xs">{lead.origin}</Text>
                </Flex>
              </ContentWithVertDividerWrapper>
            )}

            {(meetingInfos.leadType === 'publics' ||
              meetingInfos.leadType === LeadTypeEnum.MUNDO_INVEST) &&
              meetingInfoFromMI !== undefined &&
              meetingInfoFromMI?.id !== '' && (
                <MeetingsInfoFromMI meetingsInfos={meetingInfoFromMI} />
              )}

            {lead?.questions?.length > 0 && lead?.questions[0]?.disclaimer && (
              <LeadComplement
                title="Disclaimer"
                complement={lead.questions[0]?.disclaimer || ''}
              />
            )}
            {meetingInfos.complement !== '' &&
              meetingInfos.complement !== null && (
                <MeetingComplement
                  complement={meetingInfos.complement}
                  title="Complemento da reunião"
                />
              )}
          </Stack>
          <ContactAttempts
            id={meetingInfos.meetingId || meetingInfos.id}
            leadId={meetingInfos.leadId || meetingInfos.id}
            setUpdatedContactAttempts={setUpdatedContactAttempts}
            currentAttempts={meetingInfos.contactAttempts}
            meetingStatus={meetingInfos.status}
            leadType={meetingInfos.leadType as any}
            setDecline={setDecline}
          />
        </Flex>
        {isMobile ? (
          <Divider
            my={3}
            orientation="horizontal"
            borderColor="background.100"
          />
        ) : (
          <Divider mx={3} orientation="vertical" borderColor="background.100" />
        )}

        <Flex flexDir="column" w="100%" height={['70%', 'auto']}>
          <Flex w="100%" mb={2} justify="space-between" alignItems="end">
            <Text fontSize="sm" color="white" lineHeight="initial">
              Observações:
            </Text>
            <OutlineButton
              h="6"
              fontSize="xs"
              onClick={handleAddTextAreaQuestion}
            >
              Salvar observações
            </OutlineButton>
          </Flex>

          <Textarea
            mb={['1', '3']}
            name="answer"
            minH={['115px', '60%']}
            maxH={['115px', '60%']}
            value={questionItem ? questionItem.answer : ''}
            onChange={handleChangeTextAreaValue}
          />

          <MeetingQuestions
            lead={lead}
            meeting={meetingInfos}
            questions={taskResponse}
          />
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <BaseButtonGroup spacing={-1}>
              {(meetingInfos.leadType === 'publics' ||
                meetingInfos.leadType === LeadTypeEnum.MUNDO_INVEST) &&
                !leadAnswered &&
                !missCall &&
                lead.origin !== 'leadPurchased' &&
                lead.status !== 'reserved' &&
                meetingInfos.complement !==
                  'Lead abriu conta no Mundo Invest pelo seu link.' && (
                  <>
                    <OutlineButton
                      gridGap="1"
                      alignItems="center"
                      w="130px"
                      onClick={handleToggleContactModal}
                    >
                      <AiOutlineClose color="#fff" size={14} />
                      Não atendeu
                    </OutlineButton>
                    <OutlineButton
                      gridGap="1"
                      alignItems="center"
                      w="130px"
                      onClick={async () => {
                        setIsLoading(true);
                        await handleLeadAnsweredCall();
                        setIsLoading(false);
                      }}
                    >
                      {!isLoading && <AiOutlineCheck color="#fff" size={14} />}
                      {isLoading ? <LoadingSpinner size="sm" /> : 'Atendeu'}
                    </OutlineButton>
                  </>
                )}

              {meetingInfos.status === 'account' ? (
                <PrimaryButton onClick={handleClientAllocated}>
                  Alocado
                </PrimaryButton>
              ) : (
                <>
                  {(leadAnswered ||
                    (meetingInfos.leadType !== 'publics' &&
                      meetingInfos.leadType !== LeadTypeEnum.MUNDO_INVEST) ||
                    meetingInfos.complement ===
                      'Lead abriu conta no Mundo Invest pelo seu link.' ||
                    lead.origin === 'leadPurchased' ||
                    lead.status === 'reserved') && (
                    <>
                      {meetingInfos.status !== 'declined' && (
                        <SecondaryButton
                          onClick={() => {
                            handleAddTextAreaQuestion();
                            handleDecline();
                          }}
                        >
                          Declínio da oferta
                        </SecondaryButton>
                      )}

                      {!meetingInfos.isPipe && (
                        <SecondaryButton
                          onClick={() => {
                            handleAddTextAreaQuestion();
                            handleOpenReturnMeeting();
                          }}
                          disabled={
                            meetingInfos.status === 'declined' &&
                            totalNumOfLeads >= 130
                          }
                        >
                          Retornar
                        </SecondaryButton>
                      )}

                      {(meetingInfos.status as any) !== 'account' &&
                      segment !== 'allocation' ? (
                        <SecondaryButton
                          onClick={() => {
                            handleAddTextAreaQuestion();
                            handleOpenBTGAccountOpened();
                          }}
                        >
                          Abriu conta
                        </SecondaryButton>
                      ) : (
                        <PrimaryButton
                          onClick={() => {
                            handleAddTextAreaQuestion();
                            handleOpenBTGAccountOpened();
                          }}
                        >
                          Abriu conta
                        </PrimaryButton>
                      )}

                      {meetingInfos.status !== 'allocation' && (
                        <PrimaryButton
                          onClick={() => {
                            if (
                              meetingInfos.status !== 'declined' &&
                              !meetingInfos.isPipe
                            ) {
                              handleAddTextAreaQuestion();
                              handleOpenScheduleMeeting();
                            } else {
                              handleAddTextAreaQuestion();
                              handleShowRecoverModal();
                            }
                          }}
                          disabled={
                            meetingInfos.status === 'declined' &&
                            totalNumOfLeads >= 130
                          }
                        >
                          Agendar Reunião
                        </PrimaryButton>
                      )}
                    </>
                  )}
                </>
              )}
            </BaseButtonGroup>
          )}
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={showRecoverModal}
        onClose={() => setShowRecoverModal(false)}
      >
        <RecoverLeadModal
          selectedLead={meetingInfos}
          closeModal={setShowRecoverModal}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={decline} onClose={handleDecline}>
        <DeclineOffer
          updateTotalNumOfLeadsInPipe={updateTotalNumOfLeadsInPipe}
          handleShowModal={handleDecline}
          id={meetingInfos.meetingId || meetingInfos.id}
          leadId={lead.id}
          employeeId={
            meetingInfos.employeeId || meetingInfos.employeeIdParticipant
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          leadType={meetingInfos.leadType as any}
          segment={segment}
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
          isMeeting
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleNextLead={() => {}}
          tooMuchContactAttempts={updatedContactAttempts >= 5}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={displayModal} onClose={handleDisplayModal}>
        <ScheduleNextSaleMeeting
          meetingInfos={lead.meetings ? lead.meetings[0] : meetingInfos}
          handleShowModal={handleDisplayModal}
          meetingId={
            lead.meetings
              ? lead.meetings[0].id
              : meetingInfos.meetingId || meetingInfos.id
          }
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
          currentSegment={segment}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={openedBTGAccount}
        onClose={handleOpenBTGAccountOpened}
      >
        <ClientOpenAccount
          handleShowModal={handleOpenBTGAccountOpened}
          meetingInfos={{ ...meetingInfos, nomeListaPool: lead.NomeListaPool }}
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={returnModal} onClose={handleReturnModal}>
        <ScheduleReturn
          id={lead.id}
          leadType={meetingInfos.leadType}
          meetingId={meetingInfos.meetingId || meetingInfos.id}
          employeeId={
            meetingInfos.employeeId || meetingInfos.employeeIdParticipant
          }
          postTasks={postTasks}
          status={meetingInfos.status}
          localMeeting={meetingInfos.local}
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
          handleShowModal={handleReturnModal}
        />
      </ModalWrapper>

      <ModalWrapper
        isOpen={handleShowContactModal}
        onClose={handleToggleContactModal}
      >
        <ConfirmLeadMissCall
          lead={lead}
          meeting={meetingInfos}
          handleToggleModal={handleToggleContactModal}
          handleAddTextAreaQuestion={handleAddTextAreaQuestion}
          handleToggleMissCall={handleToggleMissCall}
          handleChangeMeetingMenu={handleChangeMeetingMenu}
        />
      </ModalWrapper>
    </Flex>
  );
};
