import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { IconType } from 'react-icons';
import { Box, Checkbox, Flex, Stack, Text } from '@chakra-ui/react';
import { formatToPhone } from 'brazilian-values';

import hubApi from 'services/hubApi';
import { Dropdown } from 'components/elements/others';
import { BaseLabelText, ModalTitle } from 'components/elements/texts';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { InputWithLabel, TextareaWithLabel } from 'components/elements/forms';
import { hubApiExchange } from 'services/hubApi/classes/HubApiCambio';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
} from 'components/elements/wrappers';
import {
  coinsOptions,
  legalPersonOptions,
  physicalPersonOptions,
} from 'pages/Exchange/data';

import { InputWithLabelAndFormatingToMoney } from 'components/elements/forms/InputWithLabelAndFormatingToMoney';
import { exchangeSchema } from './schema';

interface IOption {
  title: string;
  value: string;
  description?: string;
  icon?: IconType;
}

interface ComponentProps {
  selectedPerson: IOption;
  handleShowModal: () => void;
}

export const ExchangeForm: React.FC<ComponentProps> = ({
  handleShowModal,
  selectedPerson,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(true);
  const [formInfos, setFormInfos] = useState({
    name: '',
    phone: '',
    email: '',
    value: '',
    description: '',
    intention: '',
    currency: '',
    targetCurrency: '',
    clientCode: '',
    isClient,
  });

  let personOptions;

  if (selectedPerson.value === 'legal') {
    personOptions = legalPersonOptions;
  } else {
    personOptions = physicalPersonOptions;
  }

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };
  const handleChangeMoneyValue = (e: string) => {
    setFormInfos({ ...formInfos, value: e });
  };
  const handleCheckClient = () => {
    // setFormInfos({
    //   ...formInfos,
    //   name: '',
    //   email: '',
    //   isClient: !formInfos.isClient,
    //   clientCode: '',
    // });

    setIsClient(preventValue => !preventValue);
  };

  const handleBlurClient = async (e: React.FocusEvent<HTMLInputElement>) => {
    await hubApi
      .searchForClientCode(e.target.value)
      .then(response => {
        setFormInfos({
          ...formInfos,
          clientCode: e.target.value,
          name: response.name,
          email: response.email,
          phone: response.phone,
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        } else {
          toast.dark(
            'Ocorreu um erro ao tentar enviar formulário. Tente novamente mais tarde'
          );
        }
      });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (isClient && formInfos.clientCode === '') {
      setIsLoading(false);
      return toast.dark('Preencha o código do cliente');
    }

    if (formInfos.intention === '') {
      setIsLoading(false);
      return toast.dark('Preencha a modalidade');
    }

    if (formInfos.currency === '' || formInfos.targetCurrency === '') {
      setIsLoading(false);
      return toast.dark('Preencha as informações de câmbio');
    }

    if (formInfos.currency === formInfos.targetCurrency) {
      setIsLoading(false);
      return toast.dark(
        'As moedas de destino e de origem não podem ser iguais'
      );
    }

    if (formInfos.value === '') {
      setIsLoading(false);
      return toast.dark('Preencha o volume da operação');
    }

    const newOrder = {
      ...formInfos,
      phone: formInfos.phone?.replace(/\D/g, ''),
      type: selectedPerson.description,
      status: 'mosaico',
      isClient,
    };

    await exchangeSchema
      .validate(newOrder)
      .then(async () => {
        await hubApiExchange
          .createOrder(newOrder)
          .then(() => {
            handleShowModal();
            toast.dark('Solicitação enviada com sucesso');
          })
          .catch(error => {
            if (error.response) {
              if (error.response?.data.message !== 'Internal server error') {
                toast.dark(error.response?.data.message);
              }
              setIsLoading(false);
            } else {
              toast.dark(
                'Ocorreu um erro ao tentar enviar formulário. Tente novamente mais tarde'
              );
            }
            setIsLoading(false);
          });
      })
      .catch(err => {
        toast.dark(err.errors[0]);
      });
    setIsLoading(false);
  };

  return (
    <DefaultModalContentWrapper
      flexDir="column"
      width="100%"
      maxW={['95%', '95%', '95%', '860px']}
    >
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <ModalTitle color="rgba(70, 70, 70, 1)">
          {selectedPerson.title}
        </ModalTitle>
        <Flex color="white.300">
          <Checkbox
            borderColor="rgba(70, 70, 70, 1)"
            size="md"
            onChange={handleCheckClient}
          />
          <Text as="label" fontSize="xs" pl="8px" color="rgba(70, 70, 70, 1)">
            Não é cliente EWZ
          </Text>
        </Flex>
      </Flex>

      <form onSubmit={handleFormSubmit}>
        <Flex gridGap="6" flexDir={{ base: 'column', md: 'row' }}>
          <Stack
            width="100%"
            direction="column"
            spacing="3"
            maxHeight={['200px', '500px']}
            overflowY={['auto', 'unset']}
          >
            <Flex align="center" justify="space-between">
              {isClient && (
                <InputWithLabel
                  label="Código do cliente:"
                  placeholder={isClient ? 'Código BTG do cliente' : ''}
                  name="clientCode"
                  onBlur={handleBlurClient}
                  onChange={handleChangeValue}
                  value={isClient ? formInfos.clientCode : ''}
                />
              )}
            </Flex>
            <InputWithLabel
              label="Nome do cliente:"
              placeholder="Nome do cliente"
              name="name"
              value={formInfos.name}
              onChange={handleChangeValue}
              isDisabled={isClient}
              width={['95%', '100%']}
            />
            <InputWithLabel
              label="E-mail do cliente:"
              placeholder="E-mail do cliente"
              name="email"
              value={formInfos.email}
              onChange={handleChangeValue}
              width={['95%', '100%']}
            />
            <InputWithLabel
              label="Telefone do cliente:"
              placeholder="Somente números"
              name="phone"
              value={formInfos.phone && formatToPhone(formInfos.phone)}
              onChange={handleChangeValue}
              width={['95%', '100%']}
            />

            <TextareaWithLabel
              label="Informações"
              placeholder="Informações importantes para a solicitação"
              name="description"
              rows={5}
              value={formInfos.description}
              onChange={handleChangeValue}
              width={['95%', '100%']}
            />
          </Stack>

          <Stack width="100%" direction="column" spacing="3">
            <Box>
              <BaseLabelText color="rgba(70, 70, 70, 1)" mb="7px">
                Modalidade:
              </BaseLabelText>
              <Dropdown
                onChange={e =>
                  setFormInfos({ ...formInfos, intention: e.target.value })
                }
                placeholder="Selecione a modalidade"
              >
                {personOptions?.map((item, index) => (
                  <option key={index} value={item.intention}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Box>

            <Box>
              <BaseLabelText color="rgba(70, 70, 70, 1)" mb="7px">
                Moeda origem:
              </BaseLabelText>
              <Dropdown
                onChange={e =>
                  setFormInfos({ ...formInfos, currency: e.target.value })
                }
                placeholder="Selecione a moeda origem"
              >
                {coinsOptions?.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Box>
            <Box>
              <BaseLabelText color="rgba(70, 70, 70, 1)" mb="7px">
                Moeda destino:
              </BaseLabelText>
              <Dropdown
                onChange={e =>
                  setFormInfos({ ...formInfos, targetCurrency: e.target.value })
                }
                placeholder="Selecione a moeda destino"
              >
                {coinsOptions?.map((item, index) => (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </Dropdown>
            </Box>

            {formInfos.currency !== formInfos.targetCurrency && (
              <InputWithLabelAndFormatingToMoney
                label="Volume:"
                name="value"
                value={formInfos.value}
                placeholder={`Volume da ordem em ${
                  formInfos.currency || 'moeda origem'
                }`}
                onChange={handleChangeMoneyValue}
              />
            )}
          </Stack>
        </Flex>

        <BaseButtonGroup mt={[6, 0]}>
          <SecondaryButton type="button" onClick={handleShowModal}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={isLoading}
            type="submit"
            style={{ marginLeft: '0' }}
          >
            Solicitar
          </PrimaryButton>
        </BaseButtonGroup>
      </form>
    </DefaultModalContentWrapper>
  );
};
