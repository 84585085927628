import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Flex, Box, Text } from '@chakra-ui/react';
import { IChartPieData } from 'shared/interfaces/AAI';
import { formatValueForSI } from 'utils/formatValueForSI';

interface IPieChart {
  data: IChartPieData[];
  total?: number;
}

export const PieClientsChart: React.FC<IPieChart> = ({ data, total = 0 }) => {
  const color = data.map(item => {
    return item.color;
  });

  return (
    <Flex w="100%" h="35vh" textAlign="center" position="relative">
      <ResponsivePie
        data={data}
        margin={{ top: -5, right: 190, bottom: 5, left: 10 }}
        innerRadius={0.7}
        padAngle={1}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={2}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        colors={color}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        legends={[
          {
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 36,
            itemsSpacing: 0,
            itemWidth: 107,
            itemHeight: 25,
            itemTextColor: 'black',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 17,
            symbolShape: 'circle',
          },
        ]}
      />
      <Box
        pos="absolute"
        top="10"
        right="210px"
        bottom={['50px', '75px']}
        left="30px"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        pointerEvents="none"
        color="white"
      >
        {total !== 0 && (
          <>
            <Text fontSize="xs" fontWeight="semibold">
              Total
            </Text>
            <Text fontSize={['md', 'xl']} fontWeight="bold">
              {formatValueForSI(total)}
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};
