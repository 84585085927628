import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Checkbox } from '@chakra-ui/react';
import { Dropdown } from 'components/elements/others';
import {
  DefaultPageWrapper,
  DefaultModalContentWrapper,
  BaseButtonGroup,
  ModalWrapper,
} from 'components/elements/wrappers';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';

interface Offices {
  id: string;
  city: string;
}

type Table = {
  id: string;
  name: string;
};

interface SelectTablesModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSelectedTable: (
    tableId: string,
    tableName: string,
    isChecked: boolean
  ) => void;
  showDashboardCarousel: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTables: Table[];
  showTablesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectTablesModal: React.FC<SelectTablesModalProps> = ({
  isOpen,
  onClose,
  handleSelectedTable,
  showDashboardCarousel,
  selectedTables,
  showTablesModal,
}) => {
  const [offices, setOffices] = useState<Offices[]>([] as Offices[]);
  const [selectedOfficeId, setSelectedOfficeId] = useState('');
  const [tables, setTables] = useState<Table[]>([]);

  const getOffices = async () => {
    const data = await hubApiOfficeTables.listFilial();
    setOffices(data);
  };

  const getTablesByOffice = async (): Promise<void> => {
    try {
      if (selectedOfficeId !== '') {
        const data = await hubApiOfficeTables.listTablesByOffice(
          selectedOfficeId
        );
        setTables(data);
      }
    } catch (error) {
      toast.dark(
        'Não foi possível buscar as mesas deste escritório, tente novamente em alguns minutos'
      );
    }
  };

  const handleGoToDashboard = () => {
    if (selectedTables.length < 1) {
      toast.dark('Selecione ao menos uma mesa para exibir dashboard');
      return;
    }

    if (selectedTables.length > 5) {
      toast.dark('Limite de mesas atingido, selecione no máximo 5 mesas');
      return;
    }

    showDashboardCarousel(true);
    showTablesModal(false);
  };

  useEffect(() => {
    getOffices();
  }, []);

  useEffect(() => {
    getTablesByOffice();
  }, [selectedOfficeId]);

  return (
    <DefaultPageWrapper
      maxW="none"
      maxH="94vh"
      gridGap={6}
      backPageButton={false}
      pb={7}
    >
      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <DefaultModalContentWrapper h="380px">
          <ModalTitle textAlign="center">Selecione até 5 mesas</ModalTitle>

          <Flex justifyContent="center">
            <Dropdown
              placeholder="Selecione um escritório"
              w="200px"
              onChange={(e: any) => {
                setSelectedOfficeId(e.target.value);
              }}
            >
              {offices.map(office => (
                <option
                  key={office.id}
                  value={office.id}
                  selected={office.id === selectedOfficeId}
                >
                  {office.city}
                </option>
              ))}
            </Dropdown>
          </Flex>

          <Flex
            flexDirection="column"
            alignItems={tables.length < 5 ? 'center' : 'initial'}
            flexWrap="wrap"
            maxH="200px"
            mt={6}
          >
            {tables.length > 0 &&
              tables.map(table => (
                <Checkbox
                  key={table.id}
                  value={table.id}
                  onChange={e =>
                    handleSelectedTable(
                      table.id,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore: Object is possibly 'null'.
                      e.target.labels[0].innerText,
                      e.target.checked
                    )
                  }
                >
                  {table.name}
                </Checkbox>
              ))}
          </Flex>

          <BaseButtonGroup mt="auto">
            <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
            <PrimaryButton onClick={handleGoToDashboard}>
              Ir para o Dashboard
            </PrimaryButton>
          </BaseButtonGroup>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </DefaultPageWrapper>
  );
};
