import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Modal } from 'componentsV2/elements/modal';
import { MIBSLogo } from 'shared/assets/icons/MIBSLogo';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { ClientPendencyType } from 'sharedV2/types/client';
import { useEachTaskProperties } from './hooks/useEachTaskProperties';

type PropsType = {
  task: ClientPendencyType;
};

export const TaskCard: React.FC<PropsType> = ({ task }): JSX.Element => {
  const { taskCardConfig, isModalOpen, setIsModalOpen } =
    useEachTaskProperties(task);

  return (
    <Flex
      background="#fff"
      borderRadius={5}
      borderLeft={`4px solid ${taskCardConfig?.borderColor || 'black'}`}
      p={4}
      w="310px"
      h="150px"
      cursor="pointer"
      onClick={() => setIsModalOpen(true)}
      _hover={useCommonHover()}
    >
      <Flex flexDirection="column" w="100%" justifyContent="space-between">
        <Flex flexDirection="column">
          <Flex alignItems="center" gap={1}>
            {taskCardConfig?.icon}
            <Text color="rgba(70, 70, 70, 1)" fontSize={18} fontWeight="bold">
              {taskCardConfig?.title || `Contact developer | ${task.type}`}
            </Text>
          </Flex>

          <Text flexWrap="wrap" color="rgba(70, 70, 70, 1)">
            {taskCardConfig?.subtitle}
          </Text>
        </Flex>
        {taskCardConfig?.mibsQuantity && (
          <Flex justifyContent="end" w="100%" alignItems="center" gap={1}>
            <MIBSLogo />
            <Text color="#DFB958">{taskCardConfig.mibsQuantity}</Text>
          </Flex>
        )}
      </Flex>

      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title={taskCardConfig?.modalSettings.title} />
            <Modal.CloseIcon
              onClick={() => setIsModalOpen(false)}
              enabled={!taskCardConfig?.modalSettings.primaryButton.isLoading}
            />
          </Modal.Header>

          {taskCardConfig?.modalSettings.customComponent && (
            <>{taskCardConfig.modalSettings.customComponent}</>
          )}

          {taskCardConfig?.modalSettings.subtitle && (
            <Text color="rgba(70, 70, 70, 1)">
              {taskCardConfig?.modalSettings.subtitle}
            </Text>
          )}

          <Modal.Actions>
            <Modal.Action
              text={taskCardConfig?.modalSettings.primaryButton.text}
              isLoading={taskCardConfig?.modalSettings.primaryButton.isLoading}
              onClick={taskCardConfig?.modalSettings.primaryButton.action}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </Flex>
  );
};
