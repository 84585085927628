import { Flex, Text } from '@chakra-ui/react';
import { Dropdown } from 'componentsV2/elements/dropdown';
import { BaseDropdownOptionsType } from 'componentsV2/elements/dropdown/components/dropdownOptions';
import { Input } from 'componentsV2/elements/input';
import { useUpdateClient } from 'hooksV2/useUpdateClient';
import {
  hubLeadType,
  indicationLeadType,
  leadOrigin,
  prospectLeadType,
} from 'pages/Home/components/modules/modals/OriginPendencieModal/data';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { ClientPendencyType } from 'sharedV2/types/client';
import { DropdownUtils } from 'utilsV2/Dropdown';
import { useRemoveHomeTask } from '../../../hooks/useRemoveHomeTask';

type UseGetComponentForClientOriginTaskResponseType = {
  component: JSX.Element;
  action: () => void;
  isLoadingUpdateClient: boolean;
};

type LeadType =
  | 'Leads Hub'
  | 'Indicação'
  | 'Prospecção independente'
  | 'Lead Mundo Invest'
  | 'Não sei';

type HubLeadType =
  | 'Lead Mundo Invest'
  | 'Lead Minerado'
  | 'Lead Pessoal'
  | 'Lead PJ';

type IndicationLeadType =
  | 'Amigos, família'
  | 'Código do responsável'
  | 'Outro cliente'
  | 'Código do responsável';

type ProspectLeadType = 'LinkedIn' | 'Evento' | 'Instagram/Facebook' | 'Outro';

type Answer = HubLeadType | IndicationLeadType | ProspectLeadType;

export const useGetComponentForClientOriginTask = (
  task: ClientPendencyType,
  setIsModalOpen: (value: boolean) => void
): UseGetComponentForClientOriginTaskResponseType => {
  const [fromTypeDropdownValue, setFromTypeDropdownValue] =
    useState<LeadType>();

  const [answer, setAnswer] = useState<Answer>();

  const [additionalInformations, setAdditionalInformations] =
    useState<string>('');

  const { mutateAsync: updateClient, isLoading: isLoadingUpdateClient } =
    useUpdateClient();

  const { mutateAsync: removeHomeTask } = useRemoveHomeTask();

  const { data: clients } = useQuery({
    queryKey: ['clients'],
    queryFn: async () => {
      const response = await hubApiUser.listMyCLientsByClient('all', 'pl');

      return response;
    },
    enabled: answer === 'Amigos, família' || answer === 'Outro cliente',
  });

  const component = (
    <Flex flexDirection="column">
      <Text w="100%" textAlign="right" color="rgba(70, 70, 70, 1)">
        Cod. BTG: {task.client.btgCode}
      </Text>
      <Flex justifyContent="space-between" gap={5} mt={5}>
        <Dropdown.Root
          w="50%"
          value={fromTypeDropdownValue}
          onChange={event =>
            setFromTypeDropdownValue(event.target.value as LeadType)
          }
        >
          <Dropdown.Options
            options={leadOrigin.map(item =>
              DropdownUtils.toBaseOptions({
                label: item.name,
                value: item.name,
              })
            )}
            placeholder="Escolha uma das opções"
          />
        </Dropdown.Root>

        {fromTypeDropdownValue === 'Leads Hub' && (
          <Dropdown.Root
            w="40%"
            onChange={event => setAnswer(event.target.value as Answer)}
          >
            <Dropdown.Options
              options={hubLeadType.map(item =>
                DropdownUtils.toBaseOptions({
                  label: item.name,
                  value: item.name,
                })
              )}
              placeholder="Qual o tipo do lead"
            />
          </Dropdown.Root>
        )}

        {fromTypeDropdownValue === 'Indicação' && (
          <Flex flexDir="column" w="50%" gap={2}>
            <Dropdown.Root
              w="100%"
              onChange={event => setAnswer(event.target.value as Answer)}
            >
              <Dropdown.Options
                options={indicationLeadType.map(item =>
                  DropdownUtils.toBaseOptions({
                    label: item.name,
                    value: item.name,
                  })
                )}
                placeholder="Escolha o tipo de indicação"
              />
            </Dropdown.Root>

            {(answer === 'Amigos, família' || answer === 'Outro cliente') && (
              <Dropdown.Root
                w="100%"
                onChange={event =>
                  setAdditionalInformations(event.target.value)
                }
              >
                <Dropdown.Options
                  options={
                    clients?.map(item =>
                      DropdownUtils.toBaseOptions({
                        label: `${item.name} - ${item.btgAccount}`,
                        value: `${item.name}-${item.btgAccount}`,
                      })
                    ) as BaseDropdownOptionsType[]
                  }
                  placeholder="Escolha o cliente"
                />
              </Dropdown.Root>
            )}

            {answer === 'Código do responsável' && (
              <Input.Root>
                <Input.Label text="Codigo BTG" />
                <Input.TextField
                  value={additionalInformations}
                  onChange={event =>
                    setAdditionalInformations(event.target.value)
                  }
                />
              </Input.Root>
            )}
          </Flex>
        )}

        {fromTypeDropdownValue === 'Prospecção independente' && (
          <Flex flexDir="column" gap={2}>
            <Dropdown.Root
              w="100%"
              onChange={e => setAnswer(e.target.value as Answer)}
            >
              <Dropdown.Options
                options={prospectLeadType.map(item =>
                  DropdownUtils.toBaseOptions({
                    label: item.name,
                    value: item.name,
                  })
                )}
                placeholder="Escolha um tipo"
              />
            </Dropdown.Root>

            {answer === 'Evento' && (
              <Input.Root>
                <Input.Label text="Qual o evento?" />
                <Input.TextField
                  value={additionalInformations}
                  onChange={event =>
                    setAdditionalInformations(event.target.value)
                  }
                />
              </Input.Root>
            )}

            {answer === 'Outro' && (
              <Input.Root>
                <Input.Label text="Como esse cliente foi captado?" />
                <Input.TextField
                  value={additionalInformations}
                  onChange={event =>
                    setAdditionalInformations(event.target.value)
                  }
                />
              </Input.Root>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  const action = async () => {
    if (fromTypeDropdownValue === 'Não sei') {
      await updateClient({
        ...task.client,
        origin: 'Outro',
      });

      await removeHomeTask(task.id);
      setIsModalOpen(false);
      return;
    }

    if (fromTypeDropdownValue && !answer) {
      await updateClient({
        ...task.client,
        origin: 'Lead Mundo Invest',
      });

      await removeHomeTask(task.id);
      setIsModalOpen(false);
      return;
    }

    if (!answer || !fromTypeDropdownValue) {
      return toast.dark('Preencha todas as informações');
    }

    await updateClient({
      ...task.client,
      origin: answer,
    });

    await removeHomeTask(task.id);
    setIsModalOpen(false);
  };

  return {
    component,
    action,
    isLoadingUpdateClient,
  };
};
