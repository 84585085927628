import { Flex, Text } from '@chakra-ui/react';
import { TableData, TableRow } from 'components/elements/table';
import { IManagementCaptationMI } from 'pages/GenerationalVariables/interfaces';
import { formatDate } from 'utils/formatDate';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import formatValue from 'utils/formatValue';

interface TableRowShProps {
  managementCaptationMi: IManagementCaptationMI;
}

export const TableRowMICaptation: React.FC<TableRowShProps> = ({
  managementCaptationMi,
}) => {
  return (
    <>
      <TableRow _hover={{ background: 'background.300' }} transition="600ms">
        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatNameToCaptalize(managementCaptationMi.btgAccount)}
            </Text>
          </Flex>
        </TableData>

        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatNameToCaptalize(managementCaptationMi.name)}
            </Text>
          </Flex>
        </TableData>

        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatDate(managementCaptationMi.date)}
            </Text>
          </Flex>
        </TableData>

        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatNameToCaptalize(managementCaptationMi.employeeName)}
            </Text>
          </Flex>
        </TableData>

        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatValue(managementCaptationMi.pl)}
            </Text>
          </Flex>
        </TableData>

        <TableData color="white" fontWeight="bold" fontSize="small" p="3">
          <Flex align="center" justify="center">
            <Text maxW="186px" isTruncated>
              {formatValue(managementCaptationMi.cap)}
            </Text>
          </Flex>
        </TableData>
      </TableRow>
    </>
  );
};
