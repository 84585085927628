import { Flex } from '@chakra-ui/react';

export const WrapperBigNumbersMiPl: React.FC = ({ children }) => {
  return (
    <Flex
      py="3"
      px="2"
      fontSize="md"
      borderRadius="3"
      bg="background.600"
      flexDir="column"
      align="center"
      justify="center"
      w="200px"
      color="whiteAlpha.900"
    >
      {children}
    </Flex>
  );
};
