import { HubApi } from '../index';

class HubApiNotifications extends HubApi {
  async getAllNotifications() {
    const response = await this.axios.get('notifications/me');
    return response.data;
  }

  async getUnreadNotifications() {
    const response = await this.axios.get('notifications/me', {
      params: {
        status: 'unread',
      },
    });
    return response.data;
  }

  async checkNotificationAsRead(notificationId: string) {
    await this.axios.patch(`notifications/${notificationId}`);
  }
}

export const hubApiNotifications = new HubApiNotifications();
