export const marketingColumns = [
  {
    id: '1',
    title: 'Aguardando',
    tasks: [],
  },
  {
    id: '2',
    title: 'Deadline Passado',
    tasks: [],
  },
  {
    id: '3',
    title: 'Em Execução',
    tasks: [],
  },
  {
    id: '4',
    title: 'Em Aprovação',
    tasks: [],
  },
  {
    id: '5',
    title: 'Aguardando Gráfica',
    tasks: [],
  },
  {
    id: '6',
    title: 'Entregue/Cancelado',
    tasks: [],
  },
];
