import { CardToLink } from 'components/elements/cards';
import { SectionOptionsList } from 'components/elements/others';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { remunerationOptions } from './data';

const Remuneration: React.FC = () => {
  return (
    <PageWrapper alignItems="center" paddingTop="2.5rem">
      <SectionOptionsList title="Comissionamento">
        {remunerationOptions.map((option, index) => (
          <>
            <CardToLink
              key={index}
              title={option.title}
              link={option.path}
              Icon={option.icon}
            />
          </>
        ))}
      </SectionOptionsList>
    </PageWrapper>
  );
};

export default Remuneration;
