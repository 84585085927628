import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Input } from 'componentsV2/elements/input';
import { Modal } from 'componentsV2/elements/modal';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { LeadParsedType, LeadTypeEnum } from 'sharedV2/types/leadTypes';
import { formateDateAndTime } from 'utils/formatDate';
import { showToast } from 'utilsV2/showToast';

type PropsType = {
  lead: LeadParsedType;
  leadType: LeadTypeEnum;
};

export const RouteMeetingModal: React.FC<PropsType> = ({
  lead,
  leadType,
}): JSX.Element => {
  const { getParam, setParam, deleteParam } = useQueryParams();

  const { goBack } = useNavigation();

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: async () => {
        showToast('Reunião roteada com sucesso!');
        goBack();

        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
    });

  const routeMeetingComplement = getParam('routeMeetingComplement');

  const handleRouteMeeting = async () => {
    await meetingAction({
      leadType,
      action: MeetingActionEnum.ROUTED,
      meetingId: lead.meeting?.id as string,
    });
  };

  return (
    <Modal.Root>
      <Modal.Header>
        <Modal.Title title="Rotear reunião" />
        <Modal.CloseIcon
          onClick={() => deleteParam('isOpenRouteMeetingModal')}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={2}>
        <Text>
          Você confirma que deseja rotear essa reunião para{' '}
          {formateDateAndTime(lead?.meeting?.date.toString() as string)}
        </Text>

        <Input.Root>
          <Input.Label text="Complemento" />
          <Input.TextAreaField
            value={routeMeetingComplement}
            onChange={event =>
              setParam('routeMeetingComplement', event.target.value)
            }
          />
        </Input.Root>
      </Flex>

      <Modal.Actions>
        <Modal.Action
          text="Rotear"
          onClick={handleRouteMeeting}
          isLoading={isLoadingMeetingAction}
        />
      </Modal.Actions>
    </Modal.Root>
  );
};
