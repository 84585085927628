import { Flex } from '@chakra-ui/react';
import { InfoBox } from 'componentsV2/elements/boxes/components/info';
import { IAgendaData } from 'pages/UserAgenda';
import { useEmployeeAgendaHeaderData } from './hooks/useEmployeeAgendaHeaderData';

type PropsType = {
  agendaData: IAgendaData | undefined;
};

export const EmployeeAgendaHeader: React.FC<PropsType> = ({
  agendaData,
}): JSX.Element => {
  const { employeeAgendaHeaderData } = useEmployeeAgendaHeaderData(agendaData);

  return (
    <Flex gap={2} h="40px">
      {employeeAgendaHeaderData.map((item, index) => (
        <InfoBox name={item.name} value={item.value} key={index} />
      ))}
    </Flex>
  );
};
