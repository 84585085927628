import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
} from './actionTypes';

export function signInRequest(email, password, callback) {
  return {
    type: SIGN_IN_REQUEST,
    payload: {
      email,
      password,
      callback,
    },
  };
}

export function signInSuccess(token, employee, signDate) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token, employee, signDate },
  };
}

export function signInFailure() {
  return {
    type: SIGN_IN_FAILURE,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}
