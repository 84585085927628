export const commissionByIlhaForte = [
  { title: 'Câmbio - 20%  da comissão', value: 'No mesmo mês' },
  { title: 'Vida- 20%  da comissão', value: 'Pagamento contínuo ' },
  { title: 'Saúde - 10%  da comissão', value: '' },
  { title: 'Corporate - 20%  da comissão', value: 'Pagamento contínuo' },
  {
    title: 'Seguros Alternativos - 20%  da comissão',
    value: 'Pagamento contínuo',
  },
];
