import { toast } from 'react-toastify';

// string format: 'yyyy/mm/d'
export const periodValidator = (from: string, to: string): boolean => {
  const currentDate = new Date();
  const currentYear = Number(currentDate.getFullYear());
  const currentMonth = Number(currentDate.getMonth() + 1);
  const currentDay = Number(currentDate.getDate());

  const startDate = from;
  const selectedStartDateYear = Number(startDate.split('-')[0]);
  const selectedStartDateMonth = Number(startDate.split('-')[1]);
  const selectedStartDateDay = Number(startDate.split('-')[2]);

  const endDate = to;
  const selectedEndDateYear = Number(endDate.split('-')[0]);
  const selectedEndDateMonth = Number(endDate.split('-')[1]);
  const selectedEndDateDay = Number(endDate.split('-')[2]);

  const isFuturisticDate =
    selectedEndDateYear > currentYear ||
    (selectedEndDateYear === currentYear &&
      selectedEndDateMonth > currentMonth) ||
    (selectedEndDateYear === currentYear &&
      selectedEndDateMonth === currentMonth &&
      selectedEndDateDay > currentDay);

  if (isFuturisticDate) {
    toast.dark('Não é possível selecionar uma data futura');
    return false;
  }

  const isEndDateSmallerThanStartDate =
    selectedEndDateYear < selectedStartDateYear ||
    (selectedEndDateYear === selectedStartDateYear &&
      selectedEndDateMonth < selectedStartDateMonth) ||
    (selectedEndDateYear === selectedStartDateYear &&
      selectedEndDateMonth === selectedStartDateMonth &&
      selectedEndDateDay < selectedStartDateDay);

  if (isEndDateSmallerThanStartDate) {
    toast.dark('Período inválido, verifique as datas selecionadas');
    return false;
  }

  return true;
};
