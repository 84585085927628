import { queryClient } from 'App';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { SecondaryButton } from '../../../../components/elements/buttons';

export const LeadDetailsAnswer = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: async () => {
        await queryClient.invalidateQueries(['leadDetails', lead?.id]);
        await queryClient.invalidateQueries(['leadHistory', lead?.id]);
      },
    });
  const isLoading = isLoadingMeetingAction;

  const handleLeadAnswer = async () => {
    await meetingAction({
      action: MeetingActionEnum.ANSWER,
      leadType,
      meetingId: lead?.meeting?.id as string,
    });
  };

  return (
    <>
      <SecondaryButton onClick={handleLeadAnswer} isLoading={isLoading}>
        Atendeu
      </SecondaryButton>
    </>
  );
};
