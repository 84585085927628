import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Stack, useDisclosure } from '@chakra-ui/react';

import { errorHandler } from 'utils';
import { MainPageTitle } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import { PrimaryButton } from 'components/elements/buttons';
import { GroupCard } from 'pages/GenerationalVariables/components/elements/cards/GroupCard';
import { CreateGroup } from 'pages/GenerationalVariables/components/elements/cards/CreateGroup';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { IGroups } from 'pages/GenerationalVariables/interfaces';
import {
  DefaultCardWrapper,
  DefaultPageWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

export const ManagementGroups: React.FC = () => {
  const [groups, setGroups] = useState<IGroups[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getGroups();
    }
  }, [lastAuth]);

  const getGroups = async () => {
    function customSort(a: any, b: any) {
      const priority: { [k: string]: number } = {
        AAI: 6,
        Closers: 5,
        'SH N1 MI': 4,
        'SH N2 MI': 3,
        'SH N3 MI': 2,
        'MESA ALPHA': 1,
      };

      return (priority[b.name] || 0) - (priority[a.name] || 0);
    }

    try {
      const response: any[] = await hubApiCaptation.getGroups();

      response.sort(customSort);

      setGroups(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark(`Erro ao buscar os grupos`);
    }
  };

  const handleNotShowGroup = async (group: IGroups) => {
    const groupToUpdate = group;

    groupToUpdate.status = 'inativo';

    try {
      await hubApiCaptation.pathUpdateStatusGroup(groupToUpdate);
      toast.dark('Grupo deletado com sucesso');
    } catch (err) {
      errorHandler(err);
    }

    const usersWitouthUserDeleted = groups.filter(
      employee => employee.id !== group.id
    );
    setGroups(usersWitouthUserDeleted);
  };

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center">Gestão de Grupos</MainPageTitle>

      <DefaultCardWrapper
        w="100%"
        bg="white"
        flexDir="column"
        maxH="calc(100vh - 220px)"
        p="3"
      >
        <Flex justifyContent="flex-end" gap={2}>
          <PrimaryButton onClick={onOpen}>Criar Grupo</PrimaryButton>
        </Flex>

        <Flex pr="1.5" mt="2" overflow="auto" flexDir="column">
          <Stack>
            {isLoading ? (
              <Flex align="center" justify="center" w="100%" h="500px">
                <LoadingSpinner />
              </Flex>
            ) : (
              <>
                {groups.map((group, key) => (
                  <GroupCard
                    group={group}
                    key={key}
                    handleNotShowGroup={handleNotShowGroup}
                    groups={groups}
                    setGroups={setGroups}
                    getGroups={getGroups}
                  />
                ))}
              </>
            )}
          </Stack>
        </Flex>

        <ModalWrapper isOpen={isOpen} onClose={onClose}>
          <CreateGroup
            onClose={onClose}
            setGroups={setGroups}
            groups={groups}
            getGroups={getGroups}
          />
        </ModalWrapper>
      </DefaultCardWrapper>
    </DefaultPageWrapper>
  );
};
