import { Flex, Text } from '@chakra-ui/react';
import { useBuildLeadData } from 'pagesV2/LeadDetails/hooks/useBuildLeadData';
import { LeadInfomationBox } from 'pagesV2/LeadDetails/components/leadInformationBox';
import {
  LeadDetailsDesiredFlowEnum,
  useLeadDetailsContext,
} from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useQuery } from 'react-query';
import { Menu } from 'componentsV2/elements/menu';
import { Box } from 'componentsV2/elements/boxes';
import { SalesHunterPoolLeadActions } from 'pagesV2/SalesHunterPool/components/leadActions';
import {
  LeadMundoInvestStatusEnum,
  LeadMundoInvestType,
} from 'sharedV2/types/leadMundoInvest';
import { LeadBussinessLogic } from 'bussinessLogicV2/Lead';
import { HouseLine } from 'phosphor-react';
import { ModalWrapper } from 'components/elements/wrappers';
import {
  BasicForm,
  RealEstateAndFleetForm,
} from 'pages/IP/components/modules/forms';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { useState } from 'react';
import { ipProducts } from 'pages/IP/data';
import { useNavigation } from 'hooksV2/useNavigation';
import { IPOrderType } from 'services/hubApi';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { LeadDetailsTransferLeadToAAI } from '../transferLeadToAAI';
import { LeadDetailsHistory } from '../leadHistory';
import { LeadDetailsQuestionsForm } from '../leadQuestionsForm';
import { LeadDetailsObservations } from '../leadObservations';
import { LeadDetailsPipeValue } from '../pipeValue';
import { LeadMundoInvestInformation } from '../leadMundoInvestInformation';
import { LeadDetailsOpenAccount } from '../openAccount';

export const LeadInformations = (): JSX.Element => {
  const { data } = useBuildLeadData();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalAp, setShowModalAp] = useState<boolean>(false);
  const [isHoveredIlhaForte, setIsHoveredIlhaForte] = useState(false);
  const handleIlhaForteMouseEnter = () => setIsHoveredIlhaForte(true);
  const handleIlhaForteMouseLeave = () => setIsHoveredIlhaForte(false);
  const { desiredFlow, lead, leadType } = useLeadDetailsContext();
  const handleToggleModal = () => setShowModal(!showModal);
  const handleToggleModalAP = () => setShowModalAp(!showModalAp);

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions();

  const { mutateAsync: updateLead, isLoading: isLoadingUpdateLead } =
    useUpdateLead();
  const { goBack } = useNavigation();
  const isLoading = isLoadingUpdateLead || isLoadingMeetingAction;

  const { data: ipOrders } = useQuery<IPOrderType[]>({
    queryKey: ['ipOrders', lead.id],
    queryFn: async () => {
      const response = await hubApiIp.getOrderIpByLeadId(lead.id);
      return response;
    },
  });
  const thereIsAIpOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'vida'
    ).length > 0;

  const thereIsAAPOrderActive =
    ipOrders &&
    ipOrders.filter(
      order => order.finished === false && order.segment === 'imobiliario'
    ).length > 0;

  const leadBussinessLogic = new LeadBussinessLogic(lead);

  const isSalesHunterDesiredFlow =
    desiredFlow === LeadDetailsDesiredFlowEnum.SALES_HUNTER_POOL;

  const handleTakeOffLeadAction = async () => {
    if (lead.meeting) {
      await meetingAction({
        action: MeetingActionEnum.DECLINED,
        leadType,
        meetingId: lead?.meeting?.id as string,
        declinedReason: 'PJ2',
      });

      return goBack();
    }

    await updateLead({
      type: leadType,
      leadId: lead.id,
      status: LeadMundoInvestStatusEnum.FINALIZADO,
      declinedReason: 'PJ2',
    });

    goBack();
  };

  return (
    <Flex w="100%" gap={5} style={{ flex: 1 }}>
      <Flex flexDirection="column" gap={4} w="60%">
        {!isSalesHunterDesiredFlow && <LeadDetailsPipeValue />}
        <Text fontWeight={700} fontSize="lg" color="#464646">
          Informações do lead
        </Text>
        <Flex
          gap={2}
          wrap="wrap"
          overflowY="auto"
          maxHeight="calc(100vh - 310px)"
          className="thinScrollbar"
        >
          {data.map((item, index) => (
            <LeadInfomationBox
              key={index}
              label={item.label}
              value={item.value}
            />
          ))}

          {leadBussinessLogic.shouldDisplayLeadActions() && (
            <Box.Opaque
              style={{ flex: 1 }}
              minW="calc(50% - 4px)"
              p={3}
              gap={2}
            >
              <Text color="rgba(70, 70, 70, 1)">Tarefas para hoje</Text>
              <SalesHunterPoolLeadActions lead={lead as LeadMundoInvestType} />
            </Box.Opaque>
          )}

          <LeadMundoInvestInformation />

          {isSalesHunterDesiredFlow && <LeadDetailsQuestionsForm />}
        </Flex>
      </Flex>

      <Flex flexDirection="column" w="70%" gap={2}>
        <Flex w="100%" justifyContent="space-between">
          <Menu.Root>
            {!isSalesHunterDesiredFlow && (
              <>
                <LeadDetailsTransferLeadToAAI />
                {(!thereIsAAPOrderActive || !thereIsAIpOrderActive) && (
                  <>
                    <Menu.Button
                      background="#1E1A75"
                      color="#FFFFFF"
                      border="1px solid #1d63ddb2"
                      fontWeight="semibold"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexBasis="33%"
                      onMouseEnter={handleIlhaForteMouseEnter}
                      onMouseLeave={handleIlhaForteMouseLeave}
                      _hover={{
                        bg: '#FFFFFF',
                        color: '#1E1A75',
                      }}
                    >
                      <Flex alignItems="center" justifyContent="center">
                        <HouseLine
                          className="icon"
                          color={isHoveredIlhaForte ? '#1E1A75' : '#FFFFFF'}
                          size={15}
                        />
                        <Text fontSize="0.8rem" ml={2}>
                          Ilha Forte
                        </Text>
                      </Flex>
                    </Menu.Button>

                    <Menu.List>
                      {!thereIsAIpOrderActive && (
                        <Menu.Item onClick={() => setShowModal(true)}>
                          Inteligência Patrimonial
                        </Menu.Item>
                      )}

                      {!thereIsAAPOrderActive && (
                        <Menu.Item onClick={() => setShowModalAp(true)}>
                          Alavancagem Patrimonial
                        </Menu.Item>
                      )}
                    </Menu.List>
                  </>
                )}
              </>
            )}
            {lead?.meeting && <LeadDetailsOpenAccount />}
          </Menu.Root>
        </Flex>

        <LeadDetailsObservations />
        <LeadDetailsHistory />
      </Flex>

      <ModalWrapper isOpen={showModal} onClose={handleToggleModal}>
        <BasicForm
          isLoadingTakeOffLead={isLoading}
          handleShowModal={handleToggleModal}
          title={ipProducts[0].title}
          segment={ipProducts[0].segment || ''}
          description={ipProducts[0].description || ''}
          initialFormData={{
            email: lead.email as string,
            name: lead.name,
            phone: lead.phone || (lead.phones?.at(0) as string),
          }}
          leadId={lead.id}
          leadType={leadType}
          takeOffLeadAction={handleTakeOffLeadAction}
        />
      </ModalWrapper>

      <ModalWrapper isOpen={showModalAp} onClose={handleToggleModalAP}>
        <RealEstateAndFleetForm
          isLoadingTakeOffLead={isLoading}
          takeOffLeadAction={handleTakeOffLeadAction}
          handleShowModal={handleToggleModalAP}
          title={ipProducts[2].title}
          segment={ipProducts[2].segment || ''}
          description={ipProducts[2].description || ''}
          initialFormData={{
            email: lead.email as string,
            name: lead.name,
            phone: lead.phone || (lead.phones?.at(2) as string),
          }}
          leadType={leadType}
          leadId={lead.id}
        />
      </ModalWrapper>
    </Flex>
  );
};
