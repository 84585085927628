import { Box } from '@chakra-ui/react';

export const BorderWidthSmallBorderLeftAndRigth: React.FC = () => {
  return (
    <Box
      borderTop="1px solid gray"
      mb="3"
      borderLeft="1px solid gray"
      w="100%"
      borderRight="1px solid gray"
      h="1.5"
    />
  );
};
