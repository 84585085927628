/* eslint no-unused-vars:off */
import React, { useCallback, useState } from 'react';
import { Flex, FlexProps, Text, Stack } from '@chakra-ui/react';
import { BiTrash, BiEditAlt } from 'react-icons/bi';
import { MdSyncDisabled, MdSync } from 'react-icons/md';

import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';

interface ComponentProps extends FlexProps {
  title: string;
  productId: string;
  handleRemoveProduct: (id: string) => void;
  handleEditProduct: (id: string) => void;
  handleDisableProductCaptation?: (id: string) => void;
  handleActiveProductCaptation?: (id: string) => void;
  isActive?: boolean;
}

export const ProductManagementCard: React.FC<ComponentProps> = ({
  title,
  productId,
  handleEditProduct,
  handleRemoveProduct,
  handleDisableProductCaptation,
  handleActiveProductCaptation,
  isActive,
  ...rest
}) => {
  const [activate, setActivate] = useState(false);
  const [showDisableCaptationModal, setShowDisableCaptationModal] =
    useState(false);
  const [showActiveCaptationModal, setShowActiveCaptationModal] =
    useState(false);

  const handleRemoveItem = useCallback((id: string) => {
    setActivate(false);
    handleRemoveProduct(id);
  }, []);

  return (
    <>
      <Flex
        width="100%"
        padding="4"
        background="#f2f2f2"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="md"
        {...rest}
      >
        {!handleActiveProductCaptation && (
          <Text
            maxW="90%"
            color="rgba(70, 70, 70, 1)"
            fontWeight="semibold"
            isTruncated
          >
            {title}
          </Text>
        )}
        {handleActiveProductCaptation && (
          <Flex gridGap={4} alignItems="center">
            <Text
              maxW="90%"
              color="rgba(70, 70, 70, 1)"
              fontWeight="semibold"
              isTruncated
            >
              {title}
            </Text>
            {isActive === true && (
              <Flex
                h="13px"
                w="13px"
                borderRadius="50%"
                backgroundColor="#29a337"
                title="Captação ativada"
              />
            )}
            {isActive === false && (
              <Flex
                h="13px"
                w="13px"
                borderRadius="50%"
                backgroundColor="red.500"
                title="Captação desativada"
              />
            )}
          </Flex>
        )}
        <Flex gridGap="12px">
          {isActive === true && (
            <MdSyncDisabled
              size={24}
              color="#fff"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowDisableCaptationModal(true)}
              title="Desativar captação"
            />
          )}

          {isActive === false && (
            <MdSync
              size={24}
              color="rgba(70, 70, 70, 1)"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowActiveCaptationModal(true)}
              title="Ativar captação"
            />
          )}
          <BiEditAlt
            size={20}
            color="rgba(70, 70, 70, 1)"
            style={{ cursor: 'pointer' }}
            onClick={() => handleEditProduct(productId)}
          />
          <BiTrash
            size={20}
            color="rgba(70, 70, 70, 1)"
            style={{ cursor: 'pointer' }}
            onClick={() => setActivate(!activate)}
          />
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={activate}
        onClose={() => {
          setActivate(false);
        }}
      >
        <DefaultModalContentWrapper maxW="410px">
          <Stack spacing={5}>
            <Text
              fontSize="md"
              color="rgba(70, 70, 70, 1)"
            >{`Deseja remover o item ${title}?`}</Text>

            <BaseButtonGroup>
              <SecondaryButton onClick={() => handleRemoveItem(productId)}>
                Sim
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  setActivate(false);
                }}
              >
                Não
              </PrimaryButton>
            </BaseButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showDisableCaptationModal}
        onClose={() => {
          setShowDisableCaptationModal(false);
        }}
      >
        <DefaultModalContentWrapper maxW="410px">
          <Stack spacing={5}>
            <Flex flexDirection="column">
              <Text fontSize="md" color="rgba(70, 70, 70, 1)">
                {`Deseja desativar a captação do produto ${title}?`}
              </Text>
              <Text color="gray.600" fontSize="sm" mt={2} fontStyle="italic">
                Esta ação fará com que o produto NÃO seja mais exibido no
                calendário da Home, mas você poderá reverter isto no futuro,
                reativando a captação
              </Text>
            </Flex>

            <BaseButtonGroup>
              <SecondaryButton
                onClick={() => {
                  handleDisableProductCaptation &&
                    handleDisableProductCaptation(productId);
                  setShowDisableCaptationModal(false);
                }}
              >
                Sim
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  setShowDisableCaptationModal(false);
                }}
              >
                Não
              </PrimaryButton>
            </BaseButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={showActiveCaptationModal}
        onClose={() => {
          setShowActiveCaptationModal(false);
        }}
      >
        <DefaultModalContentWrapper maxW="410px">
          <Stack spacing={5}>
            <Flex flexDirection="column">
              <Text fontSize="md" color="rgba(70, 70, 70, 1)">
                {`Deseja ativar a captação do produto ${title}?`}
              </Text>
              <Text color="gray.600" fontSize="sm" mt={2} fontStyle="italic">
                Esta ação fará com que o produto seja exibido no calendário da
                Home como &quot;aberto à captação&quot;
              </Text>
            </Flex>

            <BaseButtonGroup>
              <SecondaryButton
                onClick={() => {
                  handleActiveProductCaptation &&
                    handleActiveProductCaptation(productId);
                  setShowActiveCaptationModal(false);
                }}
              >
                Sim
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  setShowActiveCaptationModal(false);
                }}
              >
                Não
              </PrimaryButton>
            </BaseButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
