import { FormEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';

import { formatarMoeda } from 'utils/formatValueWithoutRS';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { TitleWithBorder } from 'pages/GenerationalVariables/components/elements/others/TitleWithBorder';
import { InformationAndBox } from 'pages/GenerationalVariables/components/elements/others/InformationAndBox';
import { IAaiGeneral } from 'pages/GenerationalVariables/interfaces';
import { PrimaryButton } from 'components/elements/buttons';
import { LoadingSpinner } from 'components/elements/others';

export const BonificationControl: React.FC = () => {
  const [aaiGeneral, setAaiGeneral] = useState<IAaiGeneral>({} as IAaiGeneral);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getIPCASH();
  }, []);

  const getIPCASH = async () => {
    try {
      const response = await hubApiCaptation.getControlAAI();

      setAaiGeneral(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar as informações');
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAaiGeneral({
      ...aaiGeneral,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateValues = useCallback(
    async (e: FormEvent) => {
      setIsLoading(true);
      e.preventDefault();
      const sendToBackEnd = {
        ...aaiGeneral,
        aai1: aaiGeneral.aai1.replace(/[^0-9]/g, ''),
        aai2: aaiGeneral.aai2.replace(/[^0-9]/g, ''),
        aai3: aaiGeneral.aai3.replace(/[^0-9]/g, ''),
        aai4: aaiGeneral.aai4.replace(/[^0-9]/g, ''),
        aai5: aaiGeneral.aai5.replace(/[^0-9]/g, ''),
        debit: aaiGeneral.debit.replace(/[^0-9]/g, ''),
      };

      try {
        const response = await hubApiCaptation.patchControlAAi(sendToBackEnd);
        setAaiGeneral(response);
        setIsLoading(false);
        toast.dark('Classificação AAI e bonificações foram atualizadas');
      } catch (err) {
        toast.dark('Erro ao atualizar as variáveis de IPCA SH');
      }
      setIsLoading(false);
    },
    [aaiGeneral, isLoading]
  );

  return (
    <Flex gridGap={6}>
      <Flex flexDir="column" flex="1">
        <TitleWithBorder label="Controle de Bonificações" />

        <Flex flexDir="column">
          <Text
            fontSize="xs"
            textAlign="center"
            color="white"
            fontWeight="bold"
          >
            Pontos Distribuidos
          </Text>

          <InformationAndBox
            label="Débito"
            value={formatarMoeda(aaiGeneral.debit)}
            name="debit"
            handleChangeValue={handleChangeValue}
          />

          <InformationAndBox
            label="Bonificação Reunião Aberta"
            value={aaiGeneral.bonus}
            name="bonus"
            handleChangeValue={handleChangeValue}
            isPorcent
          />
          <InformationAndBox
            label="Tempo máximo de Atraso"
            value={aaiGeneral.maxDelay}
            name="maxDelay"
            handleChangeValue={handleChangeValue}
            stringWith="(min)"
          />

          <InformationAndBox
            label="Penalização Reunião Esquecida"
            value={aaiGeneral.penalty}
            name="penalty"
            handleChangeValue={handleChangeValue}
            isPorcent
          />
        </Flex>
        <Flex align="center" justifyContent="flex-end" mt="2">
          <PrimaryButton size="xs" onClick={handleUpdateValues}>
            {isLoading ? (
              <LoadingSpinner size="xs" padding="12px 18px" />
            ) : (
              'Atualizar'
            )}
          </PrimaryButton>
        </Flex>
      </Flex>

      <Flex flex="1" flexDir="column">
        <TitleWithBorder label="Classificação AAI" />

        <InformationAndBox
          label="Periodo NNM médio"
          value={aaiGeneral.averageDays}
          name="averageDays"
          handleChangeValue={handleChangeValue}
          stringWith="(D.U)"
        />

        <Flex align="center" justifyContent="flex-end" mt="2">
          <PrimaryButton size="xs" onClick={handleUpdateValues}>
            {isLoading ? (
              <LoadingSpinner size="xs" padding="12px 18px" />
            ) : (
              'Atualizar'
            )}
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
