import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

import { IReduxState } from 'shared/interfaces';
import { hubApiMarketing } from 'services/hubApi/classes/HubApiMarketing';
import { BaseModalFormCard } from 'components/elements/cards';
import { errorHandler } from 'utils';

interface ComponentProps {
  title: string;
  handleShowModal: () => void;
}

const PhotoRequest: React.FC<ComponentProps> = ({ handleShowModal, title }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useSelector((state: IReduxState) => state.user.profile);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const newArray = {
      name: user.name,
      type: title,
      phone: user.phone,
      email: user.email,
      position: null,
      cardType: null,
      publication: null,
      informations: null,
      goals: null,
      title: null,
      text: null,
      project: null,
      formatting: null,
      finished: false,
      transferred: false,
    };

    await hubApiMarketing
      .postMarketingInfos(newArray)
      .then(() => {
        setIsLoading(false);
        toast.dark('Solicitação enviada com sucesso');
        handleShowModal();
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.status === 409) {
          toast.dark(
            'Já existe uma ordem deste tipo em aberto, verifique o andamento com o responsável'
          );
        } else {
          errorHandler(error);
        }
      });

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title={title}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      isLoading={isLoading}
      primaryButtonText="Solicitar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW={['95vw', '510px']}
    >
      <Text color="white" mb="3">
        Gostaria de fazer fotos para as redes sociais?
      </Text>

      <Text color="gray.300" fontSize="sm">
        Obs: A equipe entrará em contato assim que possível para agendar sua
        consultoria!
      </Text>
    </BaseModalFormCard>
  );
};

export default PhotoRequest;
