import { MoneyBagIcon } from 'components/elements/others/MoneyBagIcon';
import styled from 'styled-components';

const BoxIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  animation: go-back 1.8s infinite alternate;
  transition: 0.1s;

  @keyframes go-back {

    from {
      transform: rotate(18deg);
    }
    to {
      transform: rotate(-18deg);
    }
  }
`;

export const MoneyBagAnimation: React.FC = () => {
  return (
    <BoxIcon>
      <MoneyBagIcon width="20px" />
    </BoxIcon>
  );
};
