import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';

import permissionsCods from 'shared/constants/permissionsCods';
import { verifyPermission } from 'utils/verifyPermission';
import { IReduxState, IRulesRedux } from 'shared/interfaces';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { Dropdown } from 'components/elements/others';
import {
  Permissions,
  SubPermissions,
} from 'pages/SearchUserDetails/interfaces';
import {
  BaseButtonGroup,
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { AllowedPagesItem } from '../../modules';

interface ICardDetailAcess {
  value: Permissions;
  user: IReduxState;
  permissionsAll: Permissions[];
  idUser: string;
  updateCards: () => Promise<void>;
}

export const CardDetailAcess: React.FC<ICardDetailAcess> = ({
  value,
  user,
  permissionsAll,
  idUser,
  updateCards,
}) => {
  const [editPermissions, setEditPermissions] = useState(false);
  const [deletePermissions, setDeletePermissions] = useState(false);
  const [newPermission, setNewPermission] = useState<string>('');
  const [itensToSelected, setItensToSelected] = useState<SubPermissions[]>([]);

  const handleSelectedPermission = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewPermission(event.target.value);
  };

  const addNewPermission = async () => {
    if (newPermission !== value.permissions[0].ruleId) {
      try {
        await hubApiUser.setPermission(idUser, newPermission);
        toast.dark('Permissão atualizada com sucesso!');
        setEditPermissions(!editPermissions);
        await updateCards();
      } catch (error) {
        toast.dark('Ocorreu um erro na atualização de permissão!');
      }
    } else {
      toast.dark('Selecione uma permissão!');
    }
  };

  const handleDeletePermission = async () => {
    try {
      await hubApiUser.deletePermission(idUser, value.permissions[0].ruleId);
      toast.dark('Permissão excluída com sucesso!');
      setDeletePermissions(!deletePermissions);
      await updateCards();
    } catch (error) {
      toast.dark('Ocorreu um erro na exclusão de permissão!');
    }
  };

  const setPermissionNoSelected = () => {
    const itemSelected = permissionsAll.findIndex(
      item => item.cod === value.cod
    );

    if (itemSelected !== -1) {
      setItensToSelected(
        permissionsAll[itemSelected].permissions.filter(
          item =>
            value.permissions.findIndex(perm => perm.cod !== item.cod) !== -1
        )
      );
    }
  };

  useEffect(() => {
    setPermissionNoSelected();
  }, [value, permissionsAll]);
  return (
    <>
      <Flex flexDir="column" bg="#f2f2f2" w="100%" p={4} borderRadius="4px">
        <Flex alignItems="flex-start" justifyContent="space-between" mb={4}>
          <Flex flexDir="column">
            <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" fontSize="md">
              Área: {value.name}
            </Text>
            {value.permissions.map(permissionUser => (
              <Text
                key={permissionUser.id}
                fontSize="sm"
                color="rgba(70, 70, 70, 1)"
              >
                Permissão: {permissionUser.name}
              </Text>
            ))}
          </Flex>

          {(user.position === permissionsCods.MASTER ||
            verifyPermission(user.rules as IRulesRedux[], value.cod)) && (
            <ButtonGroup spacing={1}>
              <Button
                size="sm"
                bg="transparent"
                p="0"
                _hover={{ background: 'transparent' }}
                onClick={() => setEditPermissions(!editPermissions)}
              >
                <FaEdit size={14} color="rgba(70, 70, 70, 1)" />
              </Button>
              <Button
                size="sm"
                bg="transparent"
                p="0"
                _hover={{ background: 'transparent' }}
                onClick={() => setDeletePermissions(!deletePermissions)}
              >
                <FaRegTrashAlt size={14} color="rgba(70, 70, 70, 1)" />
              </Button>
            </ButtonGroup>
          )}
        </Flex>
        <Divider />
        <Text color="rgba(70, 70, 70, 1)" fontSize="sm" margin="12px 0px">
          Páginas permitidas nesse nível de acesso:
        </Text>
        <Flex w="100%" flexDir="row" flexWrap="wrap" gridGap="2">
          {value.permissions.map(pages => {
            return pages.pages.map((map, index) => (
              <AllowedPagesItem key={index}>{map}</AllowedPagesItem>
            ));
          })}
        </Flex>
      </Flex>

      <ModalWrapper
        isOpen={editPermissions}
        onClose={() => setEditPermissions(!editPermissions)}
      >
        <DefaultModalContentWrapper maxW="460px">
          <Stack spacing={4}>
            <Text
              color="rgba(70, 70, 70, 1)"
              fontSize="large"
              fontWeight="semibold"
            >
              Altere as permissões de {value.name} deste usuário
            </Text>

            {itensToSelected.length > 0 ? (
              <Dropdown
                bg="transparent"
                placeholder="Selecione a area"
                onChange={e => handleSelectedPermission(e)}
              >
                {itensToSelected.map((item, index) => {
                  return (
                    <option key={index} value={item.ruleId}>
                      {item.name}
                    </option>
                  );
                })}
              </Dropdown>
            ) : (
              <Text>
                Não possui mais atribuições de permissão para esse usuário
              </Text>
            )}

            {itensToSelected.length > 0 ? (
              <ButtonGroup justifyContent="flex-end">
                <SecondaryButton
                  onClick={() => setEditPermissions(!editPermissions)}
                >
                  Cancelar
                </SecondaryButton>
                <PrimaryButton onClick={addNewPermission}>Salvar</PrimaryButton>
              </ButtonGroup>
            ) : (
              <ButtonGroup justifyContent="flex-end">
                <SecondaryButton
                  onClick={() => setEditPermissions(!editPermissions)}
                >
                  Ok
                </SecondaryButton>
              </ButtonGroup>
            )}
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>

      <ModalWrapper
        isOpen={deletePermissions}
        onClose={() => setDeletePermissions(!deletePermissions)}
      >
        <DefaultModalContentWrapper maxW="460px">
          <Stack spacing={4}>
            <Text color="white" fontSize="large" fontWeight="semibold">
              Deseja excluir as permissões de {value.name} deste usuário?
            </Text>

            <BaseButtonGroup>
              <SecondaryButton
                onClick={() => setDeletePermissions(!deletePermissions)}
              >
                Não
              </SecondaryButton>
              <PrimaryButton onClick={handleDeletePermission}>
                Sim
              </PrimaryButton>
            </BaseButtonGroup>
          </Stack>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </>
  );
};
