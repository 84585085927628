import * as yup from 'yup';

export const createAndUpdateSchema = yup.object().shape({
  eventName: yup.string().required('Defina o nome do evento.'),
  eventType: yup.string().required('Defina o tipo do evento.'),
  eventDateAndHour: yup
    .string()
    .required('Defina o horário e a data do evento.'),
  description: yup.string().required('Preencha a descrição do evento.'),
  participants: yup
    .array()
    .min(1, 'Escolha pelo menos um participante para esse evento.')
    .required('Escolha pelo menos um participante para esse evento.')
    .nullable(),
});
