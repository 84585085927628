import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Stack, Text } from '@chakra-ui/react';

import { Dropdown, LoadingSpinner } from 'components/elements/others';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { BackPageButton, SecondaryButton } from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { Input } from 'components/elements/forms';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { useQuery } from 'react-query';
import UserCard from './components/elements/UserCard';

import hubApi from '../../services/hubApi';
import { hubApiOfficeBranches } from '../../services/hubApi/classes/HubApiOfficeBranches';
import { hubApiOfficeTables } from '../../services/hubApi/classes/HubApiOfficeTables';
import { agrementsPendents, permissionsUsers, searchOptions } from './data';

import { EmployeesProps } from './interfaces';

interface IOptionsProps {
  title: string;
  fieldName: string;
  fieldValue: string;
}

interface IOfficeProps {
  id: string;
  city: string;
  zona: string;
}

interface ITableProps {
  id: string;
  name: string;
  officeId: string;
}

interface IOfficeProps {
  cep: string;
  city: string;
  id: string;
  number: string;
  street: string;
  zona: string;
}

const SearchAllUser: React.FC = () => {
  const [offices, setOffices] = useState<IOfficeProps[]>([]);
  const [tables, setTables] = useState<ITableProps[]>([]);
  const [employeesList, setEmployeesList] = useState<EmployeesProps[]>([]);
  const [employeesFiltered, setEmployeesFiltered] = useState<EmployeesProps[]>(
    []
  );
  const userSelector = useSelector((state: IReduxState) => state.user.profile);

  const [searchMethod, setSearchMethod] = useState<IOptionsProps>({
    title: 'Selecione uma opção',
    fieldName: '',
    fieldValue: '',
  } as IOptionsProps);

  const {
    refetch: refetchGetUsers,
    isLoading,
    isRefetching: isRefetchingGetUsers,
  } = useQuery({
    queryKey: ['allUsers'],
    queryFn: async () => {
      if (userSelector.position === 'MASTER') {
        const response = await hubApiUser.getAllUsers();

        setEmployeesFiltered(response);
        setEmployeesList(response);
        return;
      }
      if (
        verifyEmployeePermission({
          user: userSelector,
          areaCod: ['ASS'],
          permissionCod: ['GTM'],
        })
      ) {
        const response = await hubApiOfficeTables.listUsersByTable(
          userSelector.tableId
        );
        setEmployeesFiltered(response);
        setEmployeesList(response);
      } else {
        const response = await hubApiUser.getAllUsers();

        setEmployeesList(response);
      }
    },
    refetchOnWindowFocus: false,
  });

  async function getOffices() {
    try {
      const response = await hubApiOfficeBranches.listBranchOffices();
      setOffices(response);
    } catch (err) {
      toast.dark('Erro ao buscar as filias');
    }
  }

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getOffices();
      refetchGetUsers();
    }
  }, [lastAuth]);

  const handleSelectSearchMethod = (
    option: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearchMethod({
      title: option.target[option.target.selectedIndex].title,
      fieldName: option.target.value,
      fieldValue: '',
    });
  };

  const handleSelectFilterEmployees = (option: string) => {
    if (option === 'pendents') {
      setEmployeesList(
        employeesList.filter(
          item => item.agreements !== 'ok' && item.agreements !== null
        )
      );
    } else {
      setEmployeesList(employeesFiltered);
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMethod({ ...searchMethod, [e.target.name]: e.target.value });
  };

  const handleSelectOffice = async (
    office: React.MouseEvent<HTMLSelectElement, MouseEvent>
  ) => {
    try {
      const tablesResponse = await hubApiOfficeTables.listTablesByOffice(
        office.currentTarget.value
      );
      setTables(tablesResponse);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar mesas da filial');
    }
  };

  const handleSelectTable = async (
    table: React.MouseEvent<HTMLSelectElement, MouseEvent>
  ) => {
    setSearchMethod({ ...searchMethod, fieldValue: table.currentTarget.value });
  };

  const handleSelectJob = async (value: string) => {
    setSearchMethod({ ...searchMethod, fieldValue: value });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchMethod.title === searchOptions[3].title) {
      if (searchMethod.fieldValue === '') {
        setEmployeesFiltered([]);
        setEmployeesList([]);
        await refetchGetUsers();
        return;
      }
      const filter = employeesList.filter(item => {
        const permissions = item.permissions.map(item => {
          return item.permissions.name.toLowerCase();
        });

        return permissions.includes(searchMethod.fieldValue.toLowerCase());
      });

      setEmployeesList(filter);
      setEmployeesFiltered(filter);
      return;
    }

    if (
      searchMethod.title === searchOptions[2].title &&
      searchMethod.fieldValue.trim() === ''
    ) {
      toast.dark('Escolha uma mesa para procurar colaboradores');
      return;
    }

    if (searchMethod.fieldValue.trim() === '') {
      toast.dark('Digite a informação necessária no campo de texto');
      return;
    }

    if (searchMethod.title === searchOptions[2].title) {
      await hubApiOfficeTables
        .listUsersByTable(searchMethod.fieldValue)
        .then(response => {
          setEmployeesList(response);
          setEmployeesFiltered(response);
        })
        .catch(error => {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        });
      return;
    }

    await hubApi
      .filterEmployees(searchMethod.fieldName, searchMethod.fieldValue)
      .then(response => {
        if (response.length === 0) {
          toast.dark('Não encontramos nenhum colaborador correspondente');
        } else {
          setEmployeesList(response);
          setEmployeesFiltered(response);
        }
      })
      .catch(error => {
        if (error.response?.data.message !== 'Internal server error') {
          toast.dark(error.response?.data.message);
        }
      });
  };

  return (
    <PageWrapper
      justifyContent="center"
      minH="calc(100vh - 80px)"
      position="relative"
      padding={['20px 20px 0', '48px 20px 0']}
    >
      <BackPageButton />
      <Flex flexDir="column" alignItems="center" w="100%" maxW="960px">
        <MainPageTitle>Pesquisar Usuários</MainPageTitle>
        {userSelector.position === 'MASTER' && (
          <>
            <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
              <Flex flexDir="column" w="100%" mb="12px">
                <Text mb="6px" fontSize="small" color="rgba(70, 70, 70, 1)">
                  Escolha como deseja procurar:{' '}
                </Text>

                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  gap={[3, 0]}
                  flexDirection={['column', 'row']}
                >
                  <Dropdown
                    w="350px"
                    onChange={handleSelectSearchMethod}
                    placeholder="Selecione uma opção"
                  >
                    {searchOptions.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.fieldName}
                          title={item.title}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </Dropdown>

                  <Dropdown
                    w="350px"
                    onChange={e => handleSelectFilterEmployees(e.target.value)}
                    placeholder="Selecione filtro"
                  >
                    {agrementsPendents.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item.value}
                          title={item.title}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Flex>
              </Flex>

              {searchMethod.title === searchOptions[0].title ||
              searchMethod.title === searchOptions[1].title ? (
                <Flex flexDir="row" gridGap="10px" w="100%">
                  <Input
                    w="100%"
                    placeholder={`${searchMethod.title} do colaborador`}
                    name="fieldValue"
                    value={searchMethod.fieldValue}
                    onChange={handleChangeInput}
                  />
                  <SecondaryButton type="submit" p="20px 12px" margin="0">
                    Procurar
                  </SecondaryButton>
                </Flex>
              ) : (
                searchMethod.title === searchOptions[2].title && (
                  <Stack flexDir="row" spacing={0} gridGap="12px" w="100%">
                    <Dropdown
                      w="400px"
                      title="selectedOffice"
                      onClick={handleSelectOffice}
                      placeholder="Selecione a filial"
                      bg="background.500"
                    >
                      {offices.map(item => {
                        return (
                          <option
                            key={item.id}
                            value={item.id}
                          >{`${item.city} - ${item.zona}`}</option>
                        );
                      })}
                    </Dropdown>

                    <Dropdown
                      w="350px"
                      title="selectedTable"
                      onClick={handleSelectTable}
                      placeholder="Selecione a mesa"
                    >
                      {tables.map(item => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Dropdown>
                    <SecondaryButton type="submit" w="20%">
                      Procurar
                    </SecondaryButton>
                  </Stack>
                )
              )}
              {searchMethod.title === searchOptions[3].title && (
                <Flex w="100%" justifyContent="space-between">
                  <Dropdown
                    w="350px"
                    title="selectedTable"
                    onChange={e => handleSelectJob(e.target.value)}
                    placeholder="Selecione a função"
                  >
                    {permissionsUsers.map(item => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.title}
                        </option>
                      );
                    })}
                  </Dropdown>
                  <SecondaryButton type="submit" w="20%">
                    Procurar
                  </SecondaryButton>
                </Flex>
              )}
            </form>
          </>
        )}

        <DefaultCardWrapper
          w="100%"
          mt="10px"
          flexDir="column"
          borderRadius="2"
          p="2"
          minH="calc(100vh - 280px)"
          maxH="calc(100vh - 300px)"
          overflowY="auto"
        >
          {(isLoading || isRefetchingGetUsers) && <LoadingSpinner />}
          {!isLoading && employeesList.length === 0 && (
            <Text>
              Escolha uma das opções de busca para encontrar um colaborador.
            </Text>
          )}

          {employeesList.map((user, index) => (
            <UserCard key={index} user={user} />
          ))}
        </DefaultCardWrapper>
      </Flex>
    </PageWrapper>
  );
};

export default SearchAllUser;
