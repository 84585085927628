import React, { useState, useEffect, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { BiInfoCircle } from 'react-icons/bi';

import history from 'services/history';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import formatValue from 'utils/formatValue';
import { BackPageButton } from 'components/elements/buttons/BackPageButton';
import { PageWrapper } from 'components/elements/wrappers';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { MainPageTitle } from 'components/elements/texts';
import { DatePickerChackra } from 'components/elements/others/DatePickerChackra';
import { CommissionTable } from 'pages/Remuneration/components/modules/CommissioningReport/CommissionTable';

interface IIncome {
  value: number;
  fieldName: string;
}

interface IExtra {
  value: number;
  fieldName: string;
}

const CommissioningReport: React.FC = () => {
  const todayDate = new Date().toISOString();
  const year = todayDate.slice(0, 4);
  const month = todayDate.slice(5, 7);
  const [date, setDate] = useState(`${month}-${year}`);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [incomes, setIncomes] = useState([]);
  const [extra, setExtra] = useState<IExtra[]>([]);

  const tableHeaders = ['Categoria', ''];

  const getIncomes = async () => {
    try {
      const allIncomes = await hubApiFinancial.getIncomes(date);

      const nonZeroIncomes = allIncomes.incomes.filter(
        (income: IIncome) => income.value !== 0
      );

      if (allIncomes.extra) {
        setExtra(allIncomes.extra);
      }

      setIncomes(nonZeroIncomes);
      setTotalIncomes(allIncomes.total);
    } catch (error) {
      toast.dark(
        'Não foi possível buscar as comissões, tente novamente em alguns minutos'
      );
    }
  };

  const handleSelectDate = (e: ChangeEvent<HTMLInputElement>) => {
    const yearSlice = e.target.value.slice(0, 4);
    const monthSlice = e.target.value.slice(5, 7);
    setDate(`${monthSlice}-${yearSlice}`);
  };

  const freeAuthLimitInMinutes = 5;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getIncomes();
    }
  }, [lastAuth, date]);

  return (
    <PageWrapper>
      <Flex flexDir="column" w="100vw" maxW="780px" mx="auto">
        <BackPageButton />

        <MainPageTitle mt={20} textAlign="center">
          Relatório de Comissionamento
        </MainPageTitle>

        <Flex justifyContent="flex-end">
          <DatePickerChackra
            value={`${date.split('-')[1]}-${date.split('-')[0]}`}
            handlePickDate={e => handleSelectDate(e)}
            type="month"
            style={{
              width: 'fit-content',
            }}
          />
        </Flex>

        <CommissionTable tableHeaders={tableHeaders} incomes={incomes} />

        <Text textAlign="end" mt={2} mr={5} color="rgba(70, 70, 70, 1)">
          TOTAL:{' '}
          <Text as="span" color="rgba(70, 70, 70, 1)">
            {formatValue(totalIncomes)}
          </Text>
        </Text>

        {extra.length > 0 && (
          <Flex justifyContent="right" alignItems="baseline">
            <Flex
              title="Equity não contemplada no total acima e apenas sobre PJ1 (Assessoria)"
              cursor="pointer"
              mr={1}
            >
              <BiInfoCircle size={12} color="white" />
            </Flex>
            <Text textAlign="end" mt={2} mr={5}>
              {extra[0].fieldName.toUpperCase()}:{' '}
              <Text as="span" color="white">
                {formatValue(extra[0].value)}
              </Text>
            </Text>
          </Flex>
        )}
      </Flex>
    </PageWrapper>
  );
};

export default CommissioningReport;
