export const SEND_EMPLOYEE_PERMISSIONS =
  '@employeeForm/SEND_EMPLOYEE_PERMISSIONS';

export const SEND_EMPLOYEE_CONTRACT = '@employeeForm/SEND_EMPLOYEE_CONTRACT';

export const CONFIRM_EMPLOYEE_DATA_BY_MANAGER =
  '@employeeForm/CONFIRM_EMPLOYEE_FORM_BY_MANAGER';

export const DENIE_EMPLOYEE_FORM_BY_MANAGER =
  '@employeeForm/DENIE_EMPLOYEE_FORM_BY_MANAGER';

export const SEND_EMPLOYEE_FORM_BY_MANAGER =
  '@employeeForm/SEND_EMPLOYEE_FORM_BY_MANAGER';

export const RH_FEEDBACK_ABOUT_EMPLOYEE_FORM =
  '@employeeForm/RH_FEEDBACK_ABOUT_EMPLOYEE_FORM';

export const RESET_EMPLOYEE_STATE = '@employeeForm/RESET_EMPLOYEE_STATE';
