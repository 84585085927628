import React, { FormEvent, useState } from 'react';

import { toast } from 'react-toastify';
import { Stack, Checkbox, Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { InputWithLabel } from 'components/elements/forms';
import { BasicFieldsProps } from 'pages/Sign/Register/interfaces';
import { showToast } from 'utils';
import { basicFieldsSchema } from '../schemas';

interface formInfoBasicFields {
  name: string;
  rg: string;
  cpf: string;
  email: string;
  phone: string;
  sendMail: boolean;
}

const BasicFields: React.FC<BasicFieldsProps> = ({
  step,
  handleNextPage,
  setMainForm,
  mainForm,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formInfos, setFormInfos] = useState<formInfoBasicFields>({
    sendMail: true,
  } as formInfoBasicFields);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleChangeCheckBox = () => {
    showToast('É necessário concordar com esse campo para continuar');
    setFormInfos({ ...formInfos, sendMail: true });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const validateForm = await basicFieldsSchema
      .validate(formInfos)
      .catch(err => {
        err.errors.map((error: string) => toast.dark(error));
        setIsLoading(false);
      });

    if (validateForm) {
      setMainForm({ ...mainForm, ...formInfos });
      handleNextPage(25);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal={false}
      title={`Passo ${step} - Dados Básicos`}
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      primaryButtonText="Próximo"
      hideSecondaryButton
    >
      <Stack>
        <InputWithLabel
          label="Nome"
          placeholder="Nome completo"
          name="name"
          value={formInfos.name}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="RG"
          placeholder="Somente números"
          name="rg"
          value={formInfos.rg}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="CPF"
          placeholder="Somente números"
          name="cpf"
          value={formInfos.cpf}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="E-mail"
          placeholder="E-mail corporativo"
          name="email"
          value={formInfos.email}
          onChange={handleChangeValue}
        />
        <InputWithLabel
          label="Celular"
          placeholder="(99)99999-9999"
          name="phone"
          value={formInfos.phone}
          onChange={handleChangeValue}
        />

        <Checkbox
          style={{ marginTop: '16px' }}
          name="sendMail"
          onChange={handleChangeCheckBox}
          isChecked={formInfos.sendMail || true}
        >
          <Text color="white">Quero receber emails da EWZ</Text>
        </Checkbox>
      </Stack>
    </BaseModalFormCard>
  );
};

export default BasicFields;
