import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';

import {
  useVisualizationMode,
  VisualizationModeEnum,
} from 'hooksV2/useVisualizationMode';
import { useInfiniteQuery } from 'react-query';
import {
  GetLeadsProspectPoolResponseType,
  hubApiCaptation,
} from 'services/hubApi/classes/HubApiCaptation';
import { AddNewLeadManualModaLeadsProspect } from './components/addNewLeadManualModalLeadsProspect';
import { LeadsProspectFilters } from './components/filters';
import { LeadsProspectMosaic } from './components/mosaic';
import { LeadsProspectPoolSkeletonLoading } from './components/skeletonLoading';
import { LeadsProspectTable } from './components/table';
import { useFilterLeadsProspect } from './hooks/useFilterLeadsProspect';

export const LeadsProspectPool = (): JSX.Element => {
  const {
    filterValue: filterLeadsByNameOrPhoneOrListNameValue,
    handleChangeFilterValue: handleChangeFilterLeadsByNameOrPhoneOrListName,
  } = useSimpleQueryFilter('filterLeadsByNameOrPhoneOrListNameValue');

  const {
    filterValue: filterLeadsByContactAttempts,
    handleChangeFilterValue: handleChangeFilterLeadsByContactAttempts,
  } = useSimpleQueryFilter('filterLeadsByContactAttempts');

  const {
    filterValue: filterLeadsByType,
    handleChangeFilterValue: handleChangeFilterLeadsByType,
  } = useSimpleQueryFilter('filterLeadsByType');

  const {
    filterValue: shouldFetchDeclinedLeads,
    handleChangeFilterValue: handleChangeShouldFetchDeclinedLeads,
  } = useSimpleQueryFilter('shouldFetchDeclinedLeads');

  const {
    data: leadsProspect,
    fetchNextPage: fetchNextPageLeadsProspect,
    isLoading: isLoadingLeadsProspect,
    hasNextPage: hasNextPageLeadsProspect,
    isFetchingNextPage: isFetchingNextPageLeadsProspect,
  } = useInfiniteQuery<GetLeadsProspectPoolResponseType>({
    queryKey: [
      'leadsProspect',
      filterLeadsByNameOrPhoneOrListNameValue,
      filterLeadsByContactAttempts,
      filterLeadsByType,
      shouldFetchDeclinedLeads,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await hubApiCaptation.getLeadsProspectPool({
        page: pageParam,
        filterValue: filterLeadsByNameOrPhoneOrListNameValue,
        contactAttempts: filterLeadsByContactAttempts,
        leadType: filterLeadsByType,
        shouldFetchDeclinedLeads,
      });

      return response;
    },
    getNextPageParam: lastPage => lastPage.nextPage,
    getPreviousPageParam: lastPage => lastPage.previousPage,
  });

  const { handleChangeVisualizationMode, visualizationModeValue } =
    useVisualizationMode();

  const { filteredLeads, handleSortLeadsProspect, sortLeadsProspectValue } =
    useFilterLeadsProspect(leadsProspect?.pages.flatMap(page => page.leads));

  const handleRefetchLeadsProspect = () => {
    if (hasNextPageLeadsProspect) return fetchNextPageLeadsProspect();
  };

  if (isLoadingLeadsProspect) {
    return <LeadsProspectPoolSkeletonLoading />;
  }

  return (
    <PageWrapper paddingTop="1rem">
      <MainPageTitle color="rgba(70, 70, 70, 1)">
        Leads Prospecção
      </MainPageTitle>

      <Flex flexDirection="column" gap={4} h="100%">
        <LeadsProspectFilters
          handleChangeShouldFetchDeclinedLeads={
            handleChangeShouldFetchDeclinedLeads
          }
          shouldFetchDeclinedLeads={shouldFetchDeclinedLeads}
          filterLeadsByType={filterLeadsByType}
          handleChangeFilterLeadsByType={handleChangeFilterLeadsByType}
          filterLeadsByNameOrPhoneOrListNameValue={
            filterLeadsByNameOrPhoneOrListNameValue
          }
          sortLeadsProspectValue={sortLeadsProspectValue}
          filterLeadsByContactAttempts={filterLeadsByContactAttempts}
          handleSortLeadsProspect={handleSortLeadsProspect}
          handleChangeFilterLeadsByContactAttempts={
            handleChangeFilterLeadsByContactAttempts
          }
          handleChangeVisualizationMode={handleChangeVisualizationMode}
          visualizationModeValue={visualizationModeValue}
          handleChangeFilterLeadsByNameOrPhoneOrListName={
            handleChangeFilterLeadsByNameOrPhoneOrListName
          }
        />

        {visualizationModeValue === VisualizationModeEnum.MOSAIC &&
          filteredLeads &&
          filteredLeads?.length > 0 && (
            <LeadsProspectMosaic
              isFetchingNextPageLeadsProspect={isFetchingNextPageLeadsProspect}
              filteredLeads={filteredLeads}
              handleRefetchLeadsProspect={handleRefetchLeadsProspect}
            />
          )}

        {visualizationModeValue === VisualizationModeEnum.TABLE &&
          filteredLeads &&
          filteredLeads?.length > 0 && (
            <LeadsProspectTable
              isFetchingNextPageLeadsProspect={isFetchingNextPageLeadsProspect}
              filteredLeads={filteredLeads}
              handleRefetchLeadsProspect={handleRefetchLeadsProspect}
            />
          )}

        {filteredLeads && filteredLeads?.length === 0 && (
          <Text textAlign="center" fontStyle="italic" mt="10%">
            Sem leads para serem exibidos
          </Text>
        )}
      </Flex>
      <AddNewLeadManualModaLeadsProspect />
    </PageWrapper>
  );
};
