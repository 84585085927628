import React, { FormEvent } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Stack } from '@chakra-ui/react';

import { BaseModalFormCard } from 'components/elements/cards';
import { IAnnoucementManegement } from 'pages/RH/interfaces';
import { InputWithLabel } from 'components/elements/forms';

interface IAnnouncementFormProps {
  handleFormSubmit: (
    event: FormEvent,
    isEdit?: boolean,
    announcement?: IAnnoucementManegement
  ) => void;
  announcement?: IAnnoucementManegement;
  isLoading: boolean;
  formInfos?: {
    message: string;
    date: string;
    title: string;
    employeeId: string;
    anonymous: boolean;
  };
  handleChangeTitleValue: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleChangeValue: (message: any) => void;
}

const AnnouncementForm: React.FC<IAnnouncementFormProps> = ({
  handleFormSubmit,
  formInfos,
  handleChangeValue,
  handleChangeTitleValue,
  announcement,
}) => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <BaseModalFormCard
      isModal
      title="Criar Comunicado - HUB"
      handleFormSubmit={e => handleFormSubmit(e, !!announcement, announcement)}
      isLoading={false}
      hideSecondaryButton
      primaryButtonText="Confirmar"
      flexDir="column"
      width="100%"
      maxW={['90vw', '510px']}
    >
      <Stack spacing="4">
        <InputWithLabel
          label="Título"
          placeholder="Título do comunicado"
          name="title"
          value={announcement ? announcement.title : formInfos?.title}
          onChange={handleChangeTitleValue}
        />

        <ReactQuill
          modules={modules}
          theme="snow"
          placeholder=""
          onChange={handleChangeValue}
          value={announcement ? announcement.message : formInfos?.message}
        />
      </Stack>
    </BaseModalFormCard>
  );
};

export default AnnouncementForm;
