import { formatValue } from 'react-currency-input-field';
import { Flex, Text } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { IPOprops } from 'pages/Home/interfaces';
import { formatDate } from 'utils/formatDate';
import { ModalTitle } from 'components/elements/texts';
import { useEffect, useState } from 'react';
import { CardInfo } from 'components/elements/others/CardInfo';
import { LayoutModal } from 'components/elements/others/LayoutModal';

interface IPOmodalContentProps {
  productInfos: IPOprops;
  handleToggleModal: () => void;
}

export const IPOmodalContent: React.FC<IPOmodalContentProps> = ({
  productInfos,
  handleToggleModal,
}) => {
  const [productType, setProductType] = useState('');

  const bookingDeadline = formatDate(productInfos.bookingDeadlines);
  const disclosureOfResults = formatDate(productInfos.disclosureOfResults);
  const startOfNegotiations = formatDate(productInfos.startOfNegotiations);
  const financialSettlements = formatDate(productInfos.financialSettlements);
  const minPrice = formatValue({
    value: productInfos.priceMin,
    prefix: 'R$ ',
    decimalSeparator: ',',
    intlConfig: {
      locale: 'pt-BR',
      currency: 'BRL',
    },
  });
  const maxPrice = formatValue({
    value: productInfos.priceMax,
    prefix: 'R$ ',
    decimalSeparator: ',',
    intlConfig: {
      locale: 'pt-BR',
      currency: 'BRL',
    },
  });

  const pricePerQuote = formatValue({
    value: productInfos.pricePerQuote,
    prefix: 'R$ ',
    decimalSeparator: ',',
    intlConfig: {
      locale: 'pt-BR',
      currency: 'BRL',
    },
  });

  const marketPrice = formatValue({
    value: productInfos.marketQuote,
    prefix: 'R$ ',
    decimalSeparator: ',',
    intlConfig: {
      locale: 'pt-BR',
      currency: 'BRL',
    },
  });

  const verifyProductType = () => {
    if (productInfos.title === 'undefined') {
      setProductType('SUB');
    }

    if (productInfos.title !== 'undefined') {
      setProductType('IPO');
    }
  };

  useEffect(() => {
    verifyProductType();
  }, []);
  return (
    <>
      <Flex justifyContent="center" alignItems="baseline">
        <Flex position="absolute" top="10px" right="10px">
          <AiOutlineClose
            size={24}
            color="white"
            cursor="pointer"
            onClick={handleToggleModal}
          />
        </Flex>
        <ModalTitle
          fontSize={['md', 'xl']}
          textAlign="center"
          maxWidth={['50%', '80%']}
          isTruncated
        >
          {productType === 'IPO'
            ? `IPO: ${productInfos.title.replace(/ipo/i, '')}`
            : `Subscrição: ${productInfos.ticker}`}
        </ModalTitle>

        {productType === 'IPO' && (
          <Text fontStyle="italic" ml={[1, 2]} textAlign="center">
            ({productInfos.ticker})
          </Text>
        )}
      </Flex>

      <LayoutModal
        description={
          productType === 'IPO'
            ? productInfos?.strategy
            : productType === 'SUB'
            ? productInfos?.description
            : ''
        }
        isStrategy={productType === 'IPO' && true}
        bonusMessage={productInfos.bonusMessage}
      >
        {productType === 'SUB' && (
          <CardInfo title="Oferta" info={productInfos.offer} />
        )}

        <CardInfo title="Reservas até" info={bookingDeadline} />

        {productType === 'IPO' && (
          <>
            <CardInfo
              title="Divulgação de resultados"
              info={disclosureOfResults}
            />
            <CardInfo
              title="Início das negociações"
              info={startOfNegotiations}
            />
          </>
        )}

        <CardInfo title="Liquidações financeiras" info={financialSettlements} />

        {productType === 'IPO' && (
          <CardInfo title="Preço" info={`de ${minPrice} a ${maxPrice}`} />
        )}

        {productType === 'SUB' && (
          <>
            <CardInfo title="Preço por cota" info={pricePerQuote} />
            <CardInfo title="Cota à mercado" info={marketPrice} />
            <CardInfo title="Desconto" info={`${productInfos.discount}%`} />
            <CardInfo
              title="DY (últimos 12 meses)"
              info={`${productInfos.dividendYeld}%`}
            />
          </>
        )}
      </LayoutModal>
    </>
  );
};
