import React, { createContext, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { errorHandler } from 'utils';
import { IReduxState } from 'shared/interfaces';
import { IAgreement } from 'pages/SearchUserDetails/interfaces/Agremments';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

export interface IAgreementContext {
  allAgreements: IAgreement[];
  acceptContract: (id: string) => void;
  showModal: boolean;
  handleShowModal: () => void;
}

const AgreementContext = createContext({} as IAgreementContext);

const AgreementProvider: React.FC = ({ children }) => {
  const [allAgreements, setAllAgreements] = useState<IAgreement[]>([]);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: IReduxState) => state.user.profile);
  const auth = useSelector((state: IReduxState) => state.auth);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleGetAllAgreements = useCallback(async () => {
    try {
      const response = await hubApiUser.getAllAgreement(user.id);

      setAllAgreements(response);

      setShowModal(
        response.filter((agreement: IAgreement) => !agreement.accept).length > 0
      );
    } catch (err) {
      errorHandler(err);
    }
  }, [user]);

  const acceptContract = async (id: string) => {
    try {
      const response = await hubApiUser.patchAgreement(id);

      const allAgreementWithoutAccepted = allAgreements.filter(
        agreement => agreement.id !== id && !agreement.accept
      );

      if (allAgreementWithoutAccepted.length === 0) {
        setShowModal(false);
      }

      const agreementsWithoutUpdated = allAgreements.findIndex(
        agreement => agreement.id === id
      );

      setAllAgreements(
        allAgreements.map((item, index) => {
          const result = item;
          if (index === agreementsWithoutUpdated) {
            result.title = response.title;
            result.agreement = response.agreement;
            result.accept = response.accept;
          }

          return result;
        })
      );

      toast.dark('Acordo aceito com sucesso');
    } catch (err) {
      toast.dark('Erro ao aceitar acordo');
    }
  };

  useEffect(() => {
    if (auth.signed && user) handleGetAllAgreements();
  }, [user]);

  return (
    <AgreementContext.Provider
      value={{ allAgreements, acceptContract, handleShowModal, showModal }}
    >
      {children}
    </AgreementContext.Provider>
  );
};

export { AgreementContext, AgreementProvider };
