import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => {
  return (
    <ButtonChakra
      bgColor="#242424"
      size="sm"
      color="white"
      padding={{ sm: '12px 16px' }}
      fontWeight="semibold"
      border="1px solid gray"
      _hover={{ bg: '#353535' }}
      {...rest}
    >
      {children}
    </ButtonChakra>
  );
};
