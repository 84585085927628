import { Flex, Text } from '@chakra-ui/react';
import { SatelliteAreas } from 'pages/DashboardTables/interfaces';

interface BottomSectionSatelliteAreasProps {
  satelliteAreasData: SatelliteAreas;
}

export const BottomSectionSatelliteAreas: React.FC<BottomSectionSatelliteAreasProps> =
  ({ satelliteAreasData }) => {
    return (
      <Flex
        w="40%"
        bg="background.600"
        flexDirection="column"
        py={8}
        gridGap={4}
        borderRadius={8}
        alignItems="center"
      >
        <Text w="100%" color="white" textAlign="center" fontSize="4xl">
          Áreas Satélites
        </Text>

        <Flex
          px={12}
          py={4}
          alignItems="center"
          flexDirection="column"
          bg="background.700"
          borderRadius={8}
          w="60%"
        >
          <Text fontSize="2xl">Indicações Câmbio</Text>
          <Text fontSize="4xl" color="white" fontWeight="bold">
            {satelliteAreasData.exchangeCount || 0}/mês
          </Text>
        </Flex>

        <Flex
          px={12}
          py={4}
          alignItems="center"
          flexDirection="column"
          bg="background.700"
          borderRadius={8}
          w="60%"
        >
          <Text fontSize="2xl">Indicações IP</Text>
          <Text fontSize="4xl" color="white" fontWeight="bold">
            {satelliteAreasData.ipCount || 0}/mês
          </Text>
        </Flex>
      </Flex>
    );
  };
