import { FlexProps, Text } from '@chakra-ui/react';
import * as S from '../../styles';

type PropsType = {
  name: string;
  value: string | number;
} & FlexProps;

export const InfoBox: React.FC<PropsType> = ({
  name,
  value,
  ...rest
}): JSX.Element => {
  return (
    <S.BoxGlass {...rest}>
      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)" fontSize="0.9rem">
        {name}
      </Text>
      <Text fontWeight="bold" color="rgba(70, 70, 70, 1)" fontSize="0.9rem">
        {value}
      </Text>
    </S.BoxGlass>
  );
};
