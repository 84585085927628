import { useMediaQuery } from '@chakra-ui/react';

export enum CommonBreakPointsEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export const useCommonMediaQueries = (): CommonBreakPointsEnum => {
  const [isSmallNotebook, isMediumNotebook] = useMediaQuery([
    '(max-width: 1280px) and (max-height: 800px) ',
    '(max-width: 1728px) and (max-height: 1085px)',
  ]);

  if (isSmallNotebook) {
    return CommonBreakPointsEnum.SMALL;
  }

  if (isMediumNotebook) return CommonBreakPointsEnum.MEDIUM;

  return CommonBreakPointsEnum.BIG;
};
