import { Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import {
  IGeneralWalletName,
  IHandleGeneralWalletMibsOpearation,
} from '../dtos';

interface IProps {
  handleGeneralMibsOperation: IHandleGeneralWalletMibsOpearation;
  setIsOpenSensitiveTransactionGeneralWalletModal(value: boolean): void;
  mibsQuantity: string;
  sourceWallet: IGeneralWalletName;
  setMibsQuantity(value: string): void;
}

export const SensetiveTransactionGeneralWalletModal: React.FC<IProps> = ({
  setIsOpenSensitiveTransactionGeneralWalletModal,
  handleGeneralMibsOperation,
  sourceWallet,
  mibsQuantity,
  setMibsQuantity,
}) => {
  const user = useSelector(
    (state: IReduxState) => state.user.profile
  ) as IUserSelector;

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    Promise.all([
      handleGeneralMibsOperation.mutateAsync({
        mibsQuantity,
        name: sourceWallet,
        operation: 'WITHDRAW',
        employeeId: user.id,
        sourceAction: 'MIBS_EXCHANGE',
        transactionResponsible: user.id,
      }),
      handleGeneralMibsOperation.mutateAsync({
        mibsQuantity,
        name: sourceWallet === 'EWZ' ? 'MI' : 'EWZ',
        operation: 'DEPOSIT',
        employeeId: user.id,
        sourceAction: 'MIBS_EXCHANGE',
        transactionResponsible: user.id,
      }),
    ]);

    setMibsQuantity('');
    setIsOpenSensitiveTransactionGeneralWalletModal(false);
  };

  return (
    <BaseModalFormCard
      title="Deseja confirmar essa operação?"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={() =>
        setIsOpenSensitiveTransactionGeneralWalletModal(false)
      }
      isModal
      primaryButtonText="Confirmar"
      isLoading={handleGeneralMibsOperation.isLoading}
    >
      <Text color="#fff">
        Essa operação é irreversível. Somente confirme se tiver certeza!
      </Text>
    </BaseModalFormCard>
  );
};
