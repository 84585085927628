import React, { useState, useEffect, FormEvent } from 'react';
import { Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { Dropdown } from 'components/elements/others';
import { InputWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import {
  IOffices,
  OfficeTablesProps,
} from 'pages/manager/subpages/OfficeTables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface IManager {
  areasCod: string;
  employeesId: string;
  name: string;
  permissionsCod: string;
}

interface NewBranchProps {
  offices: IOffices[];
  table: OfficeTablesProps;
  managersList: IManager[];
  handleToggleModal: () => void;
  setTables: React.Dispatch<React.SetStateAction<OfficeTablesProps[]>>;
}

export const UpdateTable: React.FC<NewBranchProps> = ({
  handleToggleModal,
  setTables,
  offices,
  table,
  managersList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState({
    name: table.name,
    officeId: table.officeId,
    managerId: table.managerId,
  });
  const handleSelectManager = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTableData({ ...tableData, managerId: e.target.value });
  };

  const handleSelectOffice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTableData({ ...tableData, officeId: e.target.value });
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableData({ ...tableData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      await hubApiOfficeTables.editTableOffice(table.id, tableData);
      toast.dark('Mesa atualizada com sucesso.');

      const response = await hubApiOfficeTables.listTablesOffice();
      setTables(response);

      handleToggleModal();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.dark('Erro ao tentar criar uma mesa.');
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Atualizar Mesa"
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleToggleModal}
      isLoading={isLoading}
      primaryButtonText="Salvar"
      secondaryButtonText="Cancelar"
      maxW="510px"
    >
      <Stack spacing="4">
        <InputWithLabel
          label="Nome"
          placeholder="Nome da mesa"
          name="name"
          value={tableData.name}
          onChange={handleChangeValue}
        />

        <Dropdown
          onChange={handleSelectOffice}
          defaultValue={tableData.officeId}
        >
          {offices.map(item => (
            <option key={item.id} value={item.id}>
              {formatNameToCaptalize(item.city)} -{' '}
              {formatNameToCaptalize(item.zona)}
            </option>
          ))}
        </Dropdown>

        <Dropdown
          onChange={handleSelectManager}
          defaultValue={tableData.managerId}
        >
          {managersList.map(item => (
            <option key={item.employeesId} value={item.employeesId}>
              {formatNameToCaptalize(item.name)}
            </option>
          ))}
        </Dropdown>
      </Stack>
    </BaseModalFormCard>
  );
};
