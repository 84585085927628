import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers/ModalWrapper';
import { DefaultModalContentWrapper } from 'components/elements/wrappers/DefaultModalContentWrapper';
import { InputWithLabel } from 'components/elements/forms/InputWithLabel';
import { SecondaryButton, PrimaryButton } from 'components/elements/buttons';
import { ModalTitle } from 'components/elements/texts/ModalTitle';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';

import history from 'services/history';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';

export const WraperEletronicSignaure: React.FC = () => {
  const [formInfos, setFormInfos] = useState({
    passwordEletronic: '',
  });

  const handleLastAuth = () => {
    localStorage.removeItem('lastAuth');

    const currentTime = Date.now();

    localStorage.setItem('lastAuth', currentTime.toString());
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await hubApiUser
      .postCompareteSignature(formInfos)
      .then(() => {
        toast.dark('Autenticado');
        handleLastAuth();
        window.history.back();
      })
      .catch(() => {
        toast.dark('Senha incorreta');
      });
  };

  const handleCancel = () => {
    history.push('/user/control_panel');
  };
  return (
    <PageWrapper alignItems="center" paddingTop="2.5rem">
      <ModalWrapper isOpen onClose={() => {}}>
        <DefaultModalContentWrapper width={['95vw', '490px']}>
          <ModalTitle>Assinatura eletrônica</ModalTitle>
          <form onSubmit={handleFormSubmit}>
            <InputWithLabel
              label="Senha"
              type="password"
              placeholder="Digite sua assinatura"
              name="passwordEletronic"
              value={formInfos.passwordEletronic}
              onChange={handleChangeValue}
            />
            <Flex marginTop="20px" justifyContent="flex-end">
              <SecondaryButton onClick={handleCancel} marginRight="20px">
                Cancelar
              </SecondaryButton>

              <PrimaryButton type="submit">Confirmar</PrimaryButton>
            </Flex>
          </form>
        </DefaultModalContentWrapper>
      </ModalWrapper>
    </PageWrapper>
  );
};
