import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BiLock } from 'react-icons/bi';
import { styledTheme } from 'shared/styles/themes';

interface ISelectPoolButtonProps {
  isAllowed: boolean;
  handleLeadRange: (range: string, allowedPool: boolean) => void;
  range: string;
  currentRange: string;
}

export const SelectPoolButton: React.FC<ISelectPoolButtonProps> = ({
  isAllowed,
  handleLeadRange,
  range,
  currentRange,
}) => {
  return (
    <Flex
      flexDir="row"
      alignItems="center"
      gridGap="2"
      borderRadius="3px"
      bg={isAllowed ? 'white' : 'background.500'}
      color="rgba(70, 70, 70, 1)"
      width="100%"
      maxW="150px"
      justify="center"
      shadow="md"
      // _hover={{ textColor: isAllowed ? 'white.300' : 'inherit' }}
      cursor="pointer"
      onClick={() => handleLeadRange(range, isAllowed)}
    >
      {!isAllowed && (
        <BiLock size={18} color={styledTheme.colors.background300} />
      )}
      <Text
        fontSize="lg"
        fontWeight="semibold"
        transition="color 300ms"
        color={
          // eslint-disable-next-line no-nested-ternary
          isAllowed && range === currentRange
            ? '#FFB32C'
            : isAllowed && range !== currentRange
            ? 'inherit'
            : 'background.300'
        }
      >
        {range}
      </Text>
    </Flex>
  );
};
