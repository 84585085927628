import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';

import { errorHandler } from 'utils';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { MosaicLeadCard } from 'pages/Captation/components/elements/cards';
import { TitleWithSideLine } from 'components/elements/texts';
import { LoadingSpinner } from 'components/elements/others';
import { ModalWrapper } from 'components/elements/wrappers';
import { IBaseLead } from 'pages/Captation/interfaces';
import { DropDownContactAttempts } from 'pages/Captation/components/elements/others/DropDownContactAttempts';
import { CreateManualLead } from '../../modals';
import {
  MosaicCardsWrapper,
  MosaicCardWrapper,
} from '../../../elements/wrappers';

interface IComponentProps {
  leadType: string;
  handleShowLead: (lead: IBaseLead) => void;
}

export const ManualLeadsMosaic: React.FC<IComponentProps> = ({
  leadType,
  handleShowLead,
}) => {
  const [manualLeads, setManualLeads] = useState<IBaseLead[]>([]);
  const [loading, setLoading] = useState(true);
  const [addLead, setAddLead] = useState(false);
  const [filterManual, setFilterManual] = useState(manualLeads);
  const [attemptsFilterValue, setAttemptsFilterValue] = useState<number>(-1);
  const filterLeads =
    attemptsFilterValue === -1
      ? manualLeads
      : manualLeads.filter(item => {
          const numberContactAttempts = Number(
            item.contactAttempts ? item.contactAttempts : 0
          );

          if (numberContactAttempts === attemptsFilterValue) {
            return item;
          }

          if (numberContactAttempts >= 5 && attemptsFilterValue === 5) {
            return item;
          }
        });

  const handleAddNewLead = () => {
    setAddLead(!addLead);
  };

  const handleGetLeads = async () => {
    try {
      const leadsArr = await hubApiCaptation.getLead(leadType);
      setManualLeads(leadsArr);
      setFilterManual(leadsArr);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetLeads();
  }, [leadType]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex gridGap={3}>
        <TitleWithSideLine>Leads Manuais</TitleWithSideLine>

        <DropDownContactAttempts
          setAttemptsFilterValue={setAttemptsFilterValue}
        />
      </Flex>
      <MosaicCardsWrapper mt="3">
        <MosaicCardWrapper
          alignItems="center"
          justifyContent="center"
          onClick={handleAddNewLead}
          bg="background.400"
          color="white.300"
          _hover={{ bg: 'background.300', color: 'white' }}
          border="none"
        >
          <Flex alignItems="center" gridGap="1">
            <BiAddToQueue size={32} />
            <Text>Adicionar novo lead</Text>
          </Flex>
        </MosaicCardWrapper>

        {filterLeads?.map((item, index) => {
          let phone = '';
          let email = '';

          if (item.phone === null || item.email === null) {
            return (
              <MosaicLeadCard
                key={index}
                name={item.name}
                phone={phone || ''}
                email={email || ''}
                onClick={() => handleShowLead(item)}
                contactAttempts={item.contactAttempts}
              />
            );
          }

          const phoneParsed =
            typeof item.phone[0] === 'string'
              ? JSON.parse(item.phone[0])
              : item.phone[0];

          const emailParsed =
            typeof item.email[0] === 'string'
              ? JSON.parse(item.email[0])
              : item.email[0];

          if (phoneParsed && phoneParsed?.phone?.trim() !== '') {
            phone = phoneParsed.phone;
          } else if (phoneParsed?.phone && phoneParsed.phone?.trim() === '') {
            phone = '';
          } else {
            phone =
              typeof item.phone[0] === 'string'
                ? JSON.parse(item.phone[0])
                : item.email[0];
          }

          if (emailParsed?.email && emailParsed.email?.trim() !== '') {
            email = emailParsed.email;
          } else if (emailParsed?.email === '') {
            email = '';
          } else {
            email =
              typeof item.email[0] === 'string'
                ? JSON.parse(item.email[0])
                : item.email[0];
          }

          return (
            <MosaicLeadCard
              key={index}
              name={item.name}
              phone={phone || ''}
              email={email || ''}
              onClick={() => handleShowLead(item)}
              contactAttempts={item.contactAttempts}
            />
          );
        })}
      </MosaicCardsWrapper>

      <ModalWrapper isOpen={addLead} onClose={handleAddNewLead}>
        <CreateManualLead
          leadType={leadType}
          handleToggleModal={handleAddNewLead}
          manualLeads={manualLeads}
          setManualLeads={setManualLeads}
          setFilterManual={setFilterManual}
        />
      </ModalWrapper>
    </>
  );
};
