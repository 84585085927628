import { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FiTrash2 } from 'react-icons/fi';

import { Input } from 'components/elements/forms';
import { Task } from 'pages/Captation/interfaces';

interface QuestionItemProps {
  question: Task;
  setResponse: (id: string, res: string) => void;
  handleDeleteQuestion: (task: Task) => void;
}

export const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  setResponse,
  handleDeleteQuestion,
}) => {
  const [formInfos, setFormInfos] = useState({
    value: '',
  });

  function formatarMoeda(e: HTMLInputElement) {
    const elemento = e;
    let valor = elemento.value;
    valor += '';
    valor = parseInt(valor.replace(/[\D]+/g, '')).toString();
    valor += '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');
    if (valor.length > 6 && valor.length <= 9) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    if (valor.length > 9) {
      valor = valor.replace(/(\d{1})(\d{3})(\d{3})/g, '$1.$2.$3');
    }

    elemento.value = valor;
    if (valor == 'NaN') elemento.value = '';

    setFormInfos({
      ...formInfos,
      value: valor,
    });
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" p="0">
      {question.question !== 'informacoesadicionaistextarea' ? (
        <Text fontSize="sm" color="white" fontWeight="semibold">
          {question.question}
        </Text>
      ) : (
        <></>
      )}

      <Flex
        width="100%"
        maxW="50%"
        background="background.500"
        alignItems="center"
        pr="1"
      >
        {question.question !== 'informacoesadicionaistextarea' ? (
          <>
            <Input
              px="2"
              background="transparent"
              size="sm"
              type="text"
              maxH="32px"
              placeholder={question.question}
              maxLength={question.question === 'Patrimônio' ? 12 : 120}
              onChange={e => {
                if (question.question === 'Patrimônio') {
                  formatarMoeda(e.target);
                }
                setResponse(question.id, e.target.value);
              }}
            />
            {!question.isPrimary && (
              <FiTrash2
                color="#fff"
                style={{ cursor: 'pointer' }}
                size={14}
                onClick={() => handleDeleteQuestion(question)}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  );
};
