import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { InputWithEmployeeDropdown } from 'components/customElements/InputWithDropdown';
import { PrimaryButton, SecondaryButton } from 'components/elements/buttons';
import { TitleWithSideLine } from 'components/elements/texts';
import {
  DefaultModalContentWrapper,
  ModalWrapper,
} from 'components/elements/wrappers';
import { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'shared/interfaces';
import { IUserSelector } from 'shared/interfaces/userFromSelector';
import { toast } from 'react-toastify';
import { RiCoinsLine } from 'react-icons/ri';
import { formatMibs } from 'utils/formatMibs';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {
  IGeneralWalletMibs,
  IHandleGeneralWalletMibsOpearation,
  IHandleMibsOperation,
} from '../dtos';
import { MIBS_QUERIES_KEYS } from '../hooks/MIBS_QUERIES_KEYS';
import { useEmployeeMibs } from '../hooks/useEmployeeMibs';
import { useGeneralWalletMibs } from '../hooks/useGeneralWalletMibs';
import { EditEWZWalletMibsModal } from './EditEWZWalletMibsModal';
import { TransferBetweenGeneralWalletModal } from './TransferBetweenGeneralWalletModal';
import { WalletInfoBox } from './WalletInfoBox';
import { EmployeesMultiselectionModal } from '../../../components/elements/others';
import { InputWithLabel } from '../../../components/elements/forms';
import { useSendMibsInMass } from '../hooks/useSendMibsInMass';
import { useTotalSystemMibs } from '../hooks/useTotalSystemMibs';

interface IProps {
  handleMibsOperation: IHandleMibsOperation;
  handleGeneralMibsOperation: IHandleGeneralWalletMibsOpearation;

  setIsOpenManagerModal(value: boolean): void;
}

export type EmployeeToSelect = {
  employeeId: string;
  name: string;
};

export const ManagerModal: React.FC<IProps> = ({
  setIsOpenManagerModal,
  handleMibsOperation,
  handleGeneralMibsOperation,
}) => {
  const user = useSelector(
    (state: IReduxState) => state.user.profile
  ) as IUserSelector;
  const [employeeId, setEmployeeId] = useState<string>(user.id);
  const [employeeName, setEmployeeName] = useState<string>('');
  const [isOpenEditMibsModal, setIsOpenEditMibsModal] =
    useState<boolean>(false);
  const [
    isOpenTransferBetweenGeneralWalletModal,
    setIsOpenTransferBetweenGeneralWalletModal,
  ] = useState<boolean>(false);
  const [isOpenMultiselectionModal, setIsOpenMultiselectionModal] =
    useState<boolean>(false);
  const [mibsQuantityToSendInMass, setMibsQuantityToSendInMass] =
    useState<string>('');
  const [employeeListToReceiveMIBsInMass, setEmployeeListToReceiveMIBsInMass] =
    useState<EmployeeToSelect[]>([]);
  const [failedTransactionsEmployees, setFailedTransactionsEmployees] =
    useState<string[]>([]);
  const [isFromEWZGeneralWallet, setIsFromEWZGeneralWallet] =
    useState<boolean>(false);
  const [isFromMIGeneralWallet, setIsFromMIGeneralWallet] =
    useState<boolean>(false);

  const {
    data: employeeMibs,
    isLoading: isLoadingEmployeeMibs,
    refetch: refetchEmployeeMibsQuery,
  } = useEmployeeMibs(employeeId, MIBS_QUERIES_KEYS.individualEmployeeMibs);

  const { data: generalWalletMibs, isLoading: isLoadingGeneralWalletMibs } =
    useGeneralWalletMibs();

  const { data: totalSystemMibs } = useTotalSystemMibs();

  const {
    mutateAsync: handleSendMibsInMass,
    isLoading: isLoadingSendMibsInMass,
  } = useSendMibsInMass();

  const handleMIBsSentInMass = async (e: FormEvent) => {
    e.preventDefault();

    if (employeeListToReceiveMIBsInMass.length < 1) {
      return toast.dark(
        'Selecione pelo menos um colaborador para receber os MIBs.'
      );
    }

    if (mibsQuantityToSendInMass === '') {
      return toast.dark('Defina a quantidade de MIBs a ser enviado');
    }

    if (!isFromEWZGeneralWallet && !isFromMIGeneralWallet) {
      return toast.dark('Selecione uma carteira de origem.');
    }

    try {
      const response = await handleSendMibsInMass({
        employeeListToReceiveMIBsInMass,
        mibsQuantityToSendInMass: Number(mibsQuantityToSendInMass),
        generalWalletName: isFromEWZGeneralWallet ? 'EWZ' : 'MI',
      });

      setFailedTransactionsEmployees(response);

      if (response.length > 0) {
        setEmployeeListToReceiveMIBsInMass([]);
        setMibsQuantityToSendInMass('');
        return toast.dark(
          'MIBs enviados, porém alguns usuários não puderam receber, verifique acima quem são e fale com o suporte'
        );
      }

      cleanModalData();
      setIsOpenMultiselectionModal(false);
      toast.dark('MIBs enviados com sucesso!');
    } catch (error) {
      if (error.response.data.error.message === 'Insufficient funds') {
        toast.dark('Saldo insuficiente no BACEN MI');
      } else if (failedTransactionsEmployees.length > 0) {
        toast.dark(
          'Não foi possível enviar os MIBs para os assessores selecionados, tente novamente em alguns minutos'
        );
      } else {
        toast.dark(
          'Não foi possível enviar os MIBs, tente novamente em alguns minuto ou entre em contato com o suporte'
        );
      }
    }
  };

  const cleanModalData = () => {
    if (failedTransactionsEmployees.length > 0) {
      setEmployeeListToReceiveMIBsInMass([]);
      setFailedTransactionsEmployees([]);
      setMibsQuantityToSendInMass('');
    }
  };

  const mibsToSendInMassInputElement = (
    <Flex w="100%" gap={5} flexDirection="column">
      <Flex alignItems="end" gap={1}>
        <InputWithLabel
          label="Quantidade de MIBs:"
          placeholder="100"
          value={mibsQuantityToSendInMass}
          onChange={e => setMibsQuantityToSendInMass(e.target.value)}
        />
        <RiCoinsLine size={24} color="#FFB32C" />
      </Flex>

      <Flex flexDirection="column" gap={2}>
        <Text mb="6px" fontSize="sm" color="rgba(70, 70, 70, 1)">
          Carteira de Origem:
        </Text>
        <Flex gap={1}>
          <Checkbox
            size="md"
            isChecked={isFromMIGeneralWallet}
            onChange={() => {
              setIsFromMIGeneralWallet(!isFromMIGeneralWallet);
              setIsFromEWZGeneralWallet(false);
            }}
            borderColor="rgba(70, 70, 70, 1)"
          />
          <Text fontSize="xs">
            Banco Central MI (
            {generalWalletMibs && formatMibs(generalWalletMibs[1].mibs)})
          </Text>
        </Flex>
        <Flex gap={1}>
          <Checkbox
            size="md"
            isChecked={isFromEWZGeneralWallet}
            onChange={() => {
              setIsFromEWZGeneralWallet(!isFromEWZGeneralWallet);
              setIsFromMIGeneralWallet(false);
            }}
            borderColor="rgba(70, 70, 70, 1)"
          />
          <Text fontSize="xs">
            Banco Central EWZ (
            {generalWalletMibs && formatMibs(generalWalletMibs[0].mibs)})
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );

  const handleCloseSendMIBsInMassModal = () => {
    setIsOpenMultiselectionModal(false);
    setEmployeeListToReceiveMIBsInMass([]);
    setFailedTransactionsEmployees([]);
    setMibsQuantityToSendInMass('');
  };

  useEffect(() => {
    if (employeeId === '') return;
    refetchEmployeeMibsQuery();
  }, [employeeId]);

  return (
    <DefaultModalContentWrapper
      maxW={{ base: '95vw', md: '540px', lg: '900px' }}
      minH="400px"
      justifyContent="space-between"
    >
      <Flex justifyContent="center">
        <TitleWithSideLine fontSize={20}>Painel do Gestor</TitleWithSideLine>
      </Flex>
      <Flex gap={2} mt={8}>
        <InputWithEmployeeDropdown
          customTermToSearch={employeeName}
          setCustomTermToSearch={setEmployeeName}
          setSelectItem={setEmployeeId}
        />
      </Flex>

      {employeeName && (
        <Text mt={4} fontSize={20} fontWeight="bold" color="gray.200">
          {employeeName}
        </Text>
      )}
      <Flex gap={5} mt={5}>
        <WalletInfoBox
          mibs={employeeMibs ? employeeMibs[1]?.mibs : 0}
          walletName="Carteira Pessoal"
          isLoading={isLoadingEmployeeMibs}
          showReaisValue
        />
        <WalletInfoBox
          mibs={employeeMibs ? employeeMibs[0]?.mibs : 0}
          walletName="Carteira EWZ"
          isLoading={isLoadingEmployeeMibs}
          isManager
          setIsOpenEditMibsModal={setIsOpenEditMibsModal}
          showReaisValue
        />
      </Flex>

      <Flex mt={5} alignItems="center" gap={1}>
        <TitleWithSideLine fontSize={20}>Carteiras Gerais</TitleWithSideLine>
        <Tooltip
          hasArrow
          label={`O total de MIBs em circulação no HUB é de ${formatMibs(
            totalSystemMibs?.systemMibs ?? 0
          )} MIBs`}
          color="white"
          bg="background.200"
        >
          <Box>
            <AiOutlineInfoCircle size={20} color="orange" />
          </Box>
        </Tooltip>
      </Flex>
      <Flex gap={5} mt={5}>
        <WalletInfoBox
          mibs={generalWalletMibs ? generalWalletMibs[0]?.mibs : 0}
          walletName="Banco Central EWZ"
          isLoading={isLoadingGeneralWalletMibs}
          showReaisValue
        />
        <WalletInfoBox
          mibs={generalWalletMibs ? generalWalletMibs[1].mibs : 0}
          walletName="Banco Central MI"
          isLoading={isLoadingGeneralWalletMibs}
          isManager
          setIsOpenEditMibsModal={setIsOpenEditMibsModal}
          showReaisValue
        />
      </Flex>
      <Flex mt={5} gap={2}>
        <PrimaryButton
          onClick={() => setIsOpenTransferBetweenGeneralWalletModal(true)}
        >
          Transferir entre Carteiras
        </PrimaryButton>
        <SecondaryButton onClick={() => setIsOpenMultiselectionModal(true)}>
          Envio de MIBs em massa
        </SecondaryButton>
        <SecondaryButton
          w="100px"
          onClick={() => setIsOpenManagerModal(false)}
          ml="auto"
        >
          Sair
        </SecondaryButton>
      </Flex>
      <ModalWrapper
        isOpen={isOpenEditMibsModal}
        onClose={() => setIsOpenEditMibsModal(!isOpenEditMibsModal)}
      >
        <EditEWZWalletMibsModal
          handleMibsOperation={handleMibsOperation}
          setIsOpenEditMibsModal={setIsOpenEditMibsModal}
          employeeId={employeeId}
          generalWalletMibs={generalWalletMibs as IGeneralWalletMibs[]}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenMultiselectionModal}
        onClose={() => setIsOpenMultiselectionModal(false)}
      >
        <EmployeesMultiselectionModal
          customElement={mibsToSendInMassInputElement}
          handleFormSubmit={handleMIBsSentInMass}
          employeeList={
            failedTransactionsEmployees.length > 0
              ? failedTransactionsEmployees
              : employeeListToReceiveMIBsInMass
          }
          setEmployeeList={setEmployeeListToReceiveMIBsInMass}
          onClose={handleCloseSendMIBsInMassModal}
          isLoading={isLoadingSendMibsInMass}
          modalTitle="Envio de MIBs"
          secondaryTitle={
            failedTransactionsEmployees.length > 0
              ? 'Usuários que NÃO receberam os MIBs'
              : 'Usuários selecionados para receber MIBs'
          }
          secondaryTitleColor={
            failedTransactionsEmployees.length > 0
              ? 'red.400'
              : 'rgba(70, 70, 70, 1)'
          }
          primaryButtonText="Enviar MIBs"
          actionOnUserSelection={cleanModalData}
        />
      </ModalWrapper>
      <ModalWrapper
        isOpen={isOpenTransferBetweenGeneralWalletModal}
        onClose={() =>
          setIsOpenTransferBetweenGeneralWalletModal(
            !isOpenTransferBetweenGeneralWalletModal
          )
        }
      >
        <TransferBetweenGeneralWalletModal
          handleGeneralMibsOperation={handleGeneralMibsOperation}
          setIsOpenTransferBetweenGeneralWalletModal={
            setIsOpenTransferBetweenGeneralWalletModal
          }
          generalWalletMibs={generalWalletMibs as IGeneralWalletMibs[]}
        />
      </ModalWrapper>
    </DefaultModalContentWrapper>
  );
};
