import {
  IDeleteOrder,
  IPostAnotations,
  IPostOrderIp,
  ITransferOrderToEmployee,
  IUpdateOrder,
  IUpdateOrderStatus,
} from 'pages/IP/interfaces';
import { HubApi } from '../index';

class HubApiIp extends HubApi {
  async postOrderIp(orderData: IPostOrderIp) {
    const response = await this.axios.post('/orders/ip', orderData);
    return response.data;
  }

  async listOrderIp() {
    const response = await this.axios.get('/orders/ip/transferred/no');
    return response.data;
  }

  async getOrderIpByLeadId(leadId: string) {
    const response = await this.axios.get(`/orders/ip/${leadId}`);
    return response.data;
  }

  async listHealthValues() {
    const response = await this.axios.get('/orders/managementip/health');
    return response.data;
  }

  async listHealthEmployes() {
    const response = await this.axios.get(
      '/orders/managementip/health/employee'
    );
    return response.data;
  }

  async listImobEmployes() {
    const response = await this.axios.get('/orders/managementip/imob/employee');
    return response.data;
  }

  async listLifeEmployes() {
    const response = await this.axios.get('/orders/managementip/life/employee');
    return response.data;
  }

  async listImobValues() {
    const response = await this.axios.get('/orders/managementip/imob');
    return response.data;
  }

  async listAllInfosManager() {
    const response = await this.axios.get('/orders/ip/management');
    return response.data;
  }

  async listMsgInt(id: string) {
    const response = await this.axios.get(`/orders/anotations/${id}`);
    return response.data;
  }

  async postAnotations(data: IPostAnotations) {
    const response = await this.axios.post('/orders/anotations/', data);
    return response.data;
  }

  async listLifeValues() {
    const response = await this.axios.get('/orders/managementip/life');
    return response.data;
  }

  async listFrotaValues() {
    const response = await this.axios.get('/orders/managementip/frota');
    return response.data;
  }

  async listFrotaEmployees() {
    const response = await this.axios.get(
      '/orders/managementip/frota/employee'
    );
    return response.data;
  }

  async listUsersWithIpPermissions() {
    const response = await this.axios.get('/employees/area/IP');
    return response.data;
  }

  async listEmployeeByPermission(permissionCod: string) {
    const response = await this.axios.get(
      `/employees/permissions/${permissionCod}`
    );
    return response.data;
  }

  async transferOrderToEmployee(data: ITransferOrderToEmployee) {
    const response = await this.axios.post('/orders/ip/transfer', data);
    return response.data;
  }

  async transferOrderByType({
    employeeId,
    orderId,
    type,
  }: {
    type: string;
    employeeId: string;
    orderId: string;
  }) {
    const response = await this.axios.patch('/orders/transferOrder', {
      employeeId,
      orderId,
      type,
    });
    return response.data;
  }

  async listUserOrdersBySegment(segment: string) {
    const response = await this.axios.get(`/crm/ip/me/${segment}`);
    return response.data;
  }

  async attNotification(id: string) {
    const response = await this.axios.patch(`/notifications/orderid/${id}`);
    return response.data;
  }

  async updateOrderStatus(orderId: string, statusData: IUpdateOrderStatus) {
    const response = await this.axios.patch(
      `/crm/ip/order/status/${orderId}`,
      statusData
    );
    return response.data;
  }

  async deleteOrder(orderId: string, data: IDeleteOrder) {
    await this.axios.patch(`/orders/ip/${orderId}`, data);
  }

  async listIpManager(segment: string, employeeId: string) {
    const response = await this.axios.get(
      `/crm/ip/employee/${segment}/${employeeId}`
    );

    return response.data;
  }

  async updateOrderIp(orderId: string, data: IUpdateOrder) {
    const response = await this.axios.patch(
      `/orders/ip/update/${orderId}`,
      data
    );
    return response.data;
  }

  async saveFileCRM(orderId: string, clientId: string, formData: any) {
    const response = await this.axios.post(
      `/crm/ip/file?orderId=${orderId}&clientId=${clientId}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data;
  }

  async getFilesByClientId(clientId: string) {
    const response = await this.axios.get(
      `/crm/ip/getFile?clientId=${clientId}`
    );
    return response.data;
  }

  async createIPMeeting(data: {
    orderId: string;
    date?: string;
    status: string;
    segment: string;
    employeeId: string;
  }) {
    const response = await this.axios.post(
      `/orders/ip/${data.orderId}/meetings`,
      data
    );
    return response.data;
  }
}

export const hubApiIp = new HubApiIp();
