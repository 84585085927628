import { BaseModalFormCard } from 'components/elements/cards';
import { TextareaWithLabel } from 'components/elements/forms';
import { useState } from 'react';

type PropsType = {
  isLoading: boolean;
  handleAddPowerBIDescription: (
    event: React.FormEvent<HTMLFormElement>,
    value: string
  ) => void;
  handleToggleIsOpenAddPowerBIDescriptionModal: (value: boolean) => void;
};

export const AddPowerBIDescriptionModal: React.FC<PropsType> = ({
  handleAddPowerBIDescription,
  isLoading,
  handleToggleIsOpenAddPowerBIDescriptionModal,
}): JSX.Element => {
  const [description, setDescription] = useState<string>('');

  return (
    <BaseModalFormCard
      isModal
      title="Adicionar descrição"
      handleFormSubmit={event =>
        handleAddPowerBIDescription(event, description)
      }
      handleToggleModal={() =>
        handleToggleIsOpenAddPowerBIDescriptionModal(false)
      }
      isLoading={isLoading}
      primaryButtonText="Adicionar"
      secondaryButtonText="Cancelar"
      flexDir="column"
      width="100%"
      maxW="500px"
    >
      <TextareaWithLabel
        label="Descrição"
        rows={3}
        value={description}
        onChange={event => setDescription(event.target.value)}
      />
    </BaseModalFormCard>
  );
};
