import React, { createContext, useState } from 'react';

import { IBaseMeeting, IMenuCounter } from 'pages/Captation/interfaces';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { errorHandler } from 'utils';
import { checkIfThereIsAlreadyTwoDaysDifference } from 'utils/formatDate';
import { toast } from 'react-toastify';

export interface ICaptationMeetingsContext {
  returns: IBaseMeeting[];
  suitability: IBaseMeeting[];
  presentation: IBaseMeeting[];
  openAccount: IBaseMeeting[];
  allocation: IBaseMeeting[];
  pipe: IBaseMeeting[];
  menuCounters: IMenuCounter;
  handleGetCaptationMenuCounters: () => Promise<void>;
  handleGetAllPipe: () => Promise<void>;
  setPipe: React.Dispatch<React.SetStateAction<IBaseMeeting[]>>;

  handleRemoveMeeting: (
    segment:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'tables'
      | 'publics'
      | 'employee'
      | 'declined'
      | 'pj'
      | 'pipe',
    meetingId: string
  ) => void;
  handleGetSpecificLeadTypeArr: (
    leadType:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'declined'
      | 'created'
      | 'pipe'
      | 'waiting_feedback'
      | 'reserved',
    isUpdate?: boolean
  ) => Promise<void>;
}

const CaptationMeetingContext = createContext({} as ICaptationMeetingsContext);

const CaptationMeetingsProvider: React.FC = ({ children }) => {
  const [pipe, setPipe] = useState<IBaseMeeting[]>([]);
  const [returns, setReturns] = useState<IBaseMeeting[]>([]);
  const [suitability, setSuitability] = useState<IBaseMeeting[]>([]);
  const [presentation, setPresentation] = useState<IBaseMeeting[]>([]);
  const [openAccount, setOpenAccount] = useState<IBaseMeeting[]>([]);
  const [allocation, setAllocation] = useState<IBaseMeeting[]>([]);
  const [menuCounters, setMenuCounters] = useState<IMenuCounter>(
    {} as IMenuCounter
  );

  const handleGetCaptationMenuCounters = async () => {
    if (menuCounters?.conversions) return;

    try {
      const counterData = await hubApiCaptation.getLeadStatus();
      setMenuCounters(counterData);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleGetAllPipe = async () => {
    try {
      const response = await hubApiCaptation.getMeetingsByPipe();

      setPipe(response);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleGetSpecificLeadTypeArr = async (
    leadType:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'declined'
      | 'created'
      | 'pipe'
      | 'waiting_feedback'
      | 'reserved',
    isUpdate?: boolean
  ) => {
    if (leadType === 'callback' && !isUpdate && returns.length > 0) return;
    if (leadType === 'suitability' && !isUpdate && suitability.length > 0) {
      return;
    }
    if (leadType === 'presentation' && !isUpdate && presentation.length > 0)
      return;
    if (leadType === 'account' && !isUpdate && openAccount.length > 0) return;
    if (leadType === 'allocation' && !isUpdate && allocation.length > 0) return;

    try {
      let numLeadsSentToPipe = 0;
      const leadArr = await hubApiCaptation.allMeetings(leadType);

      if (leadType !== 'account' && leadType !== 'allocation') {
        await Promise.all(
          leadArr.map(async (meeting: any) => {
            if (
              checkIfThereIsAlreadyTwoDaysDifference(meeting) &&
              !meeting.isPipe
            ) {
              await hubApiCaptation.changeToPipe({
                meetingToBeUpdated: meeting,
                statusPipe: true,
              });
              numLeadsSentToPipe += 1;

              Object.assign(meeting, { showMeet: false });
              return;
            }
            Object.assign(meeting, { showMeet: true });
          })
        );

        const leadsFiltred = leadArr.filter(
          (item: any) => item.isPipe === false && item.showMeet
        );

        if (leadType === 'callback') setReturns(leadsFiltred);
        if (leadType === 'suitability') setSuitability(leadsFiltred);
        if (leadType === 'presentation') setPresentation(leadsFiltred);

        handleGetAllPipe();

        if (numLeadsSentToPipe > 0) {
          toast.dark(
            `${numLeadsSentToPipe} ${
              numLeadsSentToPipe > 1
                ? 'leads foram enviados'
                : 'lead foi enviado'
            } para o pipe de leads`
          );
        }
      }
      if (leadType === 'account') setOpenAccount(leadArr);
      if (leadType === 'allocation') setAllocation(leadArr);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleRemoveMeeting = (
    segment:
      | 'callback'
      | 'suitability'
      | 'presentation'
      | 'allocation'
      | 'account'
      | 'tables'
      | 'publics'
      | 'employee'
      | 'declined'
      | 'pj'
      | 'pipe',
    meetingId: string
  ) => {
    switch (segment) {
      case 'suitability': {
        const foundMeetingIdx = suitability.findIndex(
          meeting => meeting.id === meetingId
        );

        if (foundMeetingIdx >= 0) {
          const newSuitabilityArr = [...suitability];
          newSuitabilityArr.splice(foundMeetingIdx, 1);
          setSuitability([...newSuitabilityArr]);
        }
        break;
      }
      case 'presentation': {
        const foundPresentationIdx = presentation.findIndex(
          meeting => meeting.id === meetingId
        );

        if (foundPresentationIdx >= 0) {
          const newPresentationArr = [...presentation];
          newPresentationArr.splice(foundPresentationIdx, 1);
          setPresentation([...newPresentationArr]);
        }
        break;
      }
      case 'account': {
        const foundAccountIdx = openAccount.findIndex(
          meeting => meeting.id === meetingId
        );

        if (foundAccountIdx >= 0) {
          const newAccountArr = [...openAccount];
          newAccountArr.splice(foundAccountIdx, 1);
          setOpenAccount([...newAccountArr]);
        }
        break;
      }
      case 'allocation': {
        const foundAllocationIdx = allocation.findIndex(
          meeting => meeting.id === meetingId
        );

        if (foundAllocationIdx >= 0) {
          const newAllocationArr = [...allocation];
          newAllocationArr.splice(foundAllocationIdx, 1);
          setAllocation([...newAllocationArr]);
        }
        break;
      }

      default: {
        const foundReturnIdx = returns.findIndex(
          meeting => meeting.id === meetingId
        );
        if (foundReturnIdx >= 0) {
          const newReturnsArr = [...returns];
          newReturnsArr.splice(foundReturnIdx, 1);
          setReturns([...newReturnsArr]);
        }
      }
    }
  };

  return (
    <CaptationMeetingContext.Provider
      value={{
        returns,
        suitability,
        presentation,
        openAccount,
        allocation,
        menuCounters,
        pipe,
        handleGetSpecificLeadTypeArr,
        handleGetCaptationMenuCounters,
        handleRemoveMeeting,
        handleGetAllPipe,
        setPipe,
      }}
    >
      {children}
    </CaptationMeetingContext.Provider>
  );
};

export { CaptationMeetingContext, CaptationMeetingsProvider };
