import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { InputWithLabel } from 'components/elements/forms';

import { Container } from './styles';

interface IOrder {
  id: number;
  performance: string;
  income: string;
  type: string;
  delAbled: boolean;
}

interface IPerfomaceRowValueProps {
  order: IOrder;
  index: number;
  handleDeleteOrder: (removedOrderId: number) => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexOf: number
  ) => void;
  ticker: string;
}

const PerformanceRowValue: React.FC<IPerfomaceRowValueProps> = ({
  order,
  index,
  handleDeleteOrder,
  handleChange,
  ticker,
}) => {
  return (
    <Container rowIndex={index}>
      <div className="order-row">
        <div className={`order-inputs ${!order.delAbled && 'order-disabled'}`}>
          <InputWithLabel
            label={`Performance ${ticker}`}
            value={order.performance}
            placeholder="%"
            name="performance"
            type="number"
            onChange={e => handleChange(e, index)}
          />
          <InputWithLabel
            label="Rendimento"
            type="number"
            placeholder="%"
            value={order.income}
            name="income"
            onChange={e => handleChange(e, index)}
          />
        </div>

        {order.delAbled && (
          <div
            className="delete-order-container"
            onClick={() => handleDeleteOrder(order.id)}
          >
            <div className="delete-order-content">
              <AiOutlineDelete size={16} />
              <span>Deletar</span>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default PerformanceRowValue;
