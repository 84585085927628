import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import { Box } from 'componentsV2/elements/boxes';
import { Chart } from 'componentsV2/elements/charts';
import { useCommonMediaQueries } from 'hooksV2/useCommonMediaQueries';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';

type PropsType = {
  client: ClientType;
};

type DataItem = {
  id: string;
  label: string;
  value: number | null;
  color: string;
};

export const ClientDetailsAllocationSubPage: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const breakPoint = useCommonMediaQueries();

  const breakPoints = {
    small: -125,
    medium: -150,
    big: -200,
  };

  const data: DataItem[] = [
    {
      id: 'Conta Corrente',
      label: 'Conta Corrente',
      value: client.currentBalance,
      color: '#2E93fA',
    },
    {
      id: 'Derivativos',
      label: 'Derivativos',
      value: client.netWorth.derivatives,
      color: '#66DA26',
    },
    {
      id: 'Fundos',
      label: 'Fundos',
      value: client.netWorth.founds,
      color: '#546E7A',
    },
    {
      id: 'Renda Fixa',
      label: 'Renda Fixa',
      value: client.netWorth.fixedIncome,
      color: '#FF9800',
    },
    {
      id: 'Renda Variável',
      label: 'Renda Variável',
      value: client.netWorth.variableIncome,
      color: '#9C27B0',
    },
  ];

  function isDataItemWithDefinedValue(
    item: DataItem
  ): item is DataItem & { value: string } {
    return item.value !== null && item.value > 0;
  }

  const pieData = data.filter(isDataItemWithDefinedValue);

  console.log(pieData);

  return (
    <Flex h="100%" w="100%" gap={5} flexDirection="column" alignItems="center">
      <Flex w="100%" h="200px">
        <ResponsivePie
          data={pieData}
          colors={pieData.map(item => item.color)}
          theme={{
            legends: {
              text: {
                fontSize: '14px',
                fontWeight: 'bolder',
                color: 'rgba(70, 70, 70, 1)',
              },
            },
          }}
          innerRadius={0.6}
          padAngle={1}
          cornerRadius={0.5}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          arcLinkLabelsColor={{ from: 'color' }}
          valueFormat={value => CurrencyUtils.format(value)}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          // margin={{
          //   top: 40,
          //   bottom: 40,
          // }}
          layers={[
            'arcs',
            'arcLabels',
            'arcLinkLabels',
            'legends',
            Chart.CenterText,
          ]}
          // legends={[
          //   {
          //     anchor: 'top-right',
          //     direction: 'column',
          //     justify: false,
          //     translateX: breakPoints[breakPoint],
          //     translateY: 50,
          //     itemsSpacing: 14,
          //     itemWidth: 100,
          //     itemHeight: 18,
          //     itemTextColor: 'rgba(70, 70, 70, 1)',
          //     itemDirection: 'left-to-right',
          //     itemOpacity: 1,
          //     symbolSize: 18,
          //     symbolShape: 'circle',
          //     data: pieData.map(legend => ({
          //       id: legend.id,
          //       color: legend.color,
          //       label: `${legend.label} (${CurrencyUtils.format(
          //         legend.value
          //       )})`,
          //     })),
          //   },
          // ]}
        />
      </Flex>

      <Flex gap={1} w="100%">
        <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
          Tipo do investidor:
        </Text>
        <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
          {client.investorProfile}
        </Text>
      </Flex>

      <Flex flexDir="column" w="100%" alignItems="start" gap={2}>
        {pieData.map(item => (
          <Flex
            bgColor="#e2e2e2"
            p={1}
            borderRadius={5}
            alignItems="center"
            gap={1}
          >
            <Flex w="10px" h="10px" bgColor={item.color} borderRadius="50%" />
            <Text>
              {item.label} ({CurrencyUtils.format(item.value)})
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
