export const rhColumns = [
  {
    id: '1',
    title: 'Aguardando',
    status: 'aguardando',
    tasks: [],
  },
  {
    id: '2',
    title: 'Em Execução',
    status: 'execucao',
    tasks: [],
  },
  {
    id: '3',
    title: 'Cancelado/Finalizado',
    status: 'finalizado',
    tasks: [],
  },
];
