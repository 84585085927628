import { ModalWrapper } from 'components/elements/wrappers';
import { Button } from 'componentsV2/elements/buttons';
import { Button as ButtonChakra, Text } from '@chakra-ui/react';
import { OpenAccountModal } from 'componentsV2/modules/captation/openAccountModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { useState } from 'react';
import { Check } from 'phosphor-react';

export const LeadDetailsOpenAccount = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();
  const [isHovered, setIsHovered] = useState(false);

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenLeadOpenAccountModal =
    getParam('isOpenLeadOpenAccountModal') === 'true';

  return (
    <>
      {lead?.meeting?.status === MeetingStatusEnum.ALLOCATION ? (
        <Button.Primary
          onClick={() => setParam('isOpenLeadOpenAccountModal', 'true')}
          fontSize="0.8rem"
        >
          Abriu Conta
        </Button.Primary>
      ) : (
        <ButtonChakra
          background="#BDD9AB"
          color="#254114"
          border="1px solid #254114"
          fontWeight="semibold"
          size="lg"
          _hover={{ bg: '#254114', color: '#BDD9AB' }}
          onClick={() => setParam('isOpenLeadOpenAccountModal', 'true')}
          fontSize="0.8rem"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          justifyContent="center"
          flexBasis="33%"
        >
          <Check color={isHovered ? '#fff' : '#692525'} size={15} />
          <Text ml={2}>Abrir Conta</Text>
        </ButtonChakra>
      )}

      <ModalWrapper
        isOpen={isOpenLeadOpenAccountModal}
        onClose={() => deleteParam('isOpenLeadOpenAccountModal')}
      >
        <OpenAccountModal lead={lead} leadType={leadType} />
      </ModalWrapper>
    </>
  );
};
