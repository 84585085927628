import { Flex, Text } from '@chakra-ui/react';

import { IBaseMeeting } from 'pages/Captation/interfaces';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { TitleWithSideLine } from 'components/elements/texts';
import { verifyAndFormatPl } from 'utils/verifyAndFormatPl';
import { LoadingSpinner } from 'components/elements/others';
import { useDeclinedLeads } from './hooks/useDeclinedLeads';

interface IComponentProps {
  handleShowMeeting: (lead: IBaseMeeting) => void;
}

export const DeclinedLeadsList: React.FC<IComponentProps> = ({
  handleShowMeeting,
}) => {
  const { data: declinedLeads, isLoading } = useDeclinedLeads();

  const verifyAndFormatLeadType = (leadType: string) => {
    let formatedLeadType = '';

    switch (leadType) {
      case 'publics':
        formatedLeadType = 'Mundo Invest';
        break;
      case 'public':
        formatedLeadType = 'Mundo Invest';
        break;
      case 'pj':
        formatedLeadType = 'Pessoa Jurídica';
        break;
      case 'leadspj':
        formatedLeadType = 'Pessoa Jurídica';
        break;

      default:
        formatedLeadType = 'Lead minerado';
    }

    return formatedLeadType;
  };

  const formatedDeclineReason = (declineReason: string) => {
    let formated;

    switch (declineReason) {
      case 'btg_client':
        formated = 'Já é cliente BTG';
        break;
      case 'no_interest':
        formated = 'Sem interesse';
        break;
      case 'no_money':
        formated = 'Sem dinheiro';
        break;
      case 'lgpd':
        formated = 'LGPD';
        break;
      default:
        formated = '-';
    }

    return formated;
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Flex gridGap={3}>
        <TitleWithSideLine>Leads declinados</TitleWithSideLine>
      </Flex>

      {declinedLeads && declinedLeads.length > 0 && (
        <TableBase
          headData={['Nome', 'Origem', 'Pl', 'Motivo']}
          p="0"
          w="100%"
          overflowY="auto"
          my={3}
        >
          {declinedLeads.map(meeting => (
            <>
              <TableRow
                key={meeting.id}
                bg="background.500"
                _hover={{
                  background: 'background.300',
                  cursor: 'pointer',
                }}
                transition="ease-in-out 0.2s"
                onClick={() => {
                  handleShowMeeting(meeting);
                }}
              >
                <TableData maxW="180px" isTruncated>
                  {formatNameToCaptalize(meeting.name)}
                </TableData>

                <TableData maxW="180px" isTruncated>
                  {verifyAndFormatLeadType(meeting.leadType)}
                </TableData>

                <TableData>
                  {meeting.leadType === 'leadspj' || meeting.leadType === 'pj'
                    ? verifyAndFormatPl(meeting.faixaFaturamento)
                    : verifyAndFormatPl(meeting.pl || null)}
                </TableData>

                <TableData maxW="180px" isTruncated>
                  {formatedDeclineReason(meeting.declineReason)}
                </TableData>
              </TableRow>
            </>
          ))}
        </TableBase>
      )}

      {declinedLeads && declinedLeads.length === 0 && (
        <Text alignSelf="center" mt="20%" fontStyle="italic">
          Sem leads declinados para exibir
        </Text>
      )}
    </>
  );
};
