import { Td, TableCellProps } from '@chakra-ui/react';
import React from 'react';

export const TableData: React.FC<TableCellProps> = ({ children, ...rest }) => {
  return (
    <Td
      textAlign="center"
      fontSize="smaller"
      {...rest}
      isTruncated
      color="rgba(70, 70, 70, 1)"
    >
      {children}
    </Td>
  );
};
