import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { DefaultCardWrapper, ModalWrapper } from 'components/elements/wrappers';
import React, { useState } from 'react';
import { OfficesProps } from '../../interfaces';
import { UpdateFilial } from '../UpateFilial';

interface ItemProps {
  office: OfficesProps;
  handleDeleteOffice: (officeId: string) => void;
  setOffices: React.Dispatch<React.SetStateAction<OfficesProps[]>>;
}

export const Item: React.FC<ItemProps> = (office, setOffices) => {
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const handleToggleModal = () => {
    setShowModalUpdate(!showModalUpdate);
  };

  return (
    <DefaultCardWrapper
      justifyContent="space-between"
      bg="background.300"
      mb="5px"
      p="3"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text color="white" fontSize="14px">
          {office.office.city} - {office.office.zona}
        </Text>

        <Flex justifyContent="flex-end" alignItems="center">
          {/* <EditIcon
            mr="15px"
            w="20px"
            h="20px"
            cursor="pointer"
            color="white"
            onClick={handleToggleModal}
          /> */}

          <DeleteIcon
            onClick={() => office.handleDeleteOffice(office.office.id)}
            w="20px"
            h="20px"
            cursor="pointer"
            color="white"
          />

          <ModalWrapper isOpen={showModalUpdate} onClose={handleToggleModal}>
            <Flex justifyContent="center" alignItems="center" h="100vh">
              <h1>teste</h1>
              <UpdateFilial
                office={office.office}
                handleToggleModal={handleToggleModal}
                setOffices={setOffices}
              />
            </Flex>
          </ModalWrapper>
        </Flex>
      </Flex>
    </DefaultCardWrapper>
  );
};
