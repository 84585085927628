import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivatePageLayout from 'components/layouts/PrivatePageLayout';

export const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  if (!Component) return null;

  const userAuthenticated = useSelector((state: any) => state.auth);

  if (!userAuthenticated.signed) {
    return <Redirect to="/v2/login" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <PrivatePageLayout>
          <Component {...props} />
        </PrivatePageLayout>
      )}
    />
  );
};
