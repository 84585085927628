import styled from 'styled-components';

interface Props {
  rowIndex: number;
}

export const Container = styled.div`
  width: 100%;

  &:not(:first-child) {
    margin-top: 7px;
  }
  .order-row {
    display: flex;

    .order-inputs {
      display: flex;
      width: 100%;
      margin-bottom: 0px;

      label {
        display: ${({ rowIndex }: Props) => rowIndex !== 0 && 'none'};
      }

      &:nth-child(2) {
        border: 1px solid blue;
      }
    }

    .order-disabled {
      padding-top: -10px;
      margin-right: 76px;
      display: flex;
      flex-direction: row;

      label:first-child {
        opacity: 100%;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;

      div:nth-child(1) {
        margin-right: 6px;
      }
    }
  }
`;

interface PropsTeste2 {
  delAble: boolean;
}

export const DeleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 25px;
  font-size: var(--smaller-text);
  opacity: ${({ delAble }: PropsTeste2) => (delAble ? '1' : '0')};
  pointer-events: ${({ delAble }) => (delAble ? 'inherit' : 'none')};
  cursor: pointer;

  .delete-order-content {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.white200};
    margin: 0;

    span {
      margin-left: 2px;
    }
  }

  @media screen and (max-width: 820px) {
    margin-top: 12px;
  }
`;
