import { Flex, Text } from '@chakra-ui/react';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { useState } from 'react';
import * as S from './style';
import { BaseDropdownOptionsType } from './dropdownOptions';

type PropsType = {
  placeholder: string;
  options: BaseDropdownOptionsType[] | undefined;
  selectedItem?: string;
  onSelectDropdownItem: (value: string) => void;
};

export const DropdownOptionsWithTextFilter: React.FC<PropsType> = ({
  options,
  placeholder,
  onSelectDropdownItem,
  selectedItem,
}): JSX.Element => {
  const [shouldShowOptions, setShouldShowOptions] = useState<boolean>(false);
  const [dropdownFilterValue, setDropdownFilterValue] = useState<string>(
    selectedItem || ''
  );

  const filteredOptions = options?.filter(
    option =>
      option.label.toLowerCase().includes(dropdownFilterValue.toLowerCase()) ||
      option.value.toLowerCase().includes(dropdownFilterValue.toLowerCase())
  );

  const handleOnClickDropdownItem = (option: BaseDropdownOptionsType) => {
    setShouldShowOptions(false);
    setDropdownFilterValue(option.label);

    onSelectDropdownItem(option.value);
  };

  return (
    <Flex flexDirection="column" w="100%">
      <S.DropdownWithTextFilterInput
        placeholder={placeholder}
        value={dropdownFilterValue}
        onChange={event => {
          setShouldShowOptions(true);
          setDropdownFilterValue(event.target.value);
        }}
        onClick={() => setShouldShowOptions(!shouldShowOptions)}
        isDropdownItemsOpen={shouldShowOptions}
      />

      {shouldShowOptions && (
        <S.DropdownItems>
          {filteredOptions?.length === 0 && (
            <Text fontStyle="italic" fontSize="sm" textAlign="center">
              Sem resultados
            </Text>
          )}

          {filteredOptions?.map(option => (
            <Flex
              cursor="pointer"
              _hover={useCommonHover()}
              p={2}
              borderRadius="6px"
              onClick={() => handleOnClickDropdownItem(option)}
            >
              <Text>{option.label}</Text>
            </Flex>
          ))}
        </S.DropdownItems>
      )}
    </Flex>
  );
};
