const lifeColumns = [
  {
    id: '1',
    title: 'Sem Contato',
    status: 'noContact',
    tasks: [],
  },
  {
    id: '2',
    title: 'Tentativa de contato',
    status: 'attemptContact',
    tasks: [],
  },
  {
    id: '3',
    title: 'Call Marcada',
    status: 'scheduledCall',
    tasks: [],
  },
  {
    id: '4',
    title: 'Fazendo Estudo',
    status: 'studying',
    tasks: [],
  },
  {
    id: '5',
    title: 'Aguardando Apresentação',
    status: 'waitingPresentation',
    tasks: [],
  },
  {
    id: '6',
    title: 'Apresentação Realizada',
    status: 'donePresentation',
    tasks: [],
  },

  {
    id: '7',
    title: 'Fechado/ Cancelado',
    status: 'closed',
    tasks: [],
  },
];

export { lifeColumns };
