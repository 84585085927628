/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Flex, Badge, Text, Stack } from '@chakra-ui/react';

interface CardProductsList {
  title?: string;
  risck: string;
}

export const CardInvestmentFundListBase: React.FC<CardProductsList> = ({
  title,
  children,
  risck,
}) => {
  return (
    <Box
      w="100%"
      maxW="880px"
      flexDirection="column"
      mb="12px"
      borderRadius="2"
      color="rgba(70, 70, 70, 1)"
      bg="#f2f2f2"
      p={7}
      boxShadow="7px 4px 26px -3px rgba(0, 0, 0, 0.25)"
    >
      <Stack flexDir="row" spacing={0} mb="24px" justifyContent="space-between">
        <Text color="rgba(70, 70, 70, 1)" fontWeight="bold" fontSize="lg">
          {title}
        </Text>
        <Flex alignItems="center">
          <Text mr="4px">Risco:</Text>
          {risck === 'Alto' && <Badge colorScheme="red">{risck}</Badge>}
          {risck === 'Baixo' && <Badge colorScheme="green">{risck}</Badge>}
          {risck === 'Moderado' && <Badge colorScheme="yellow">{risck}</Badge>}
        </Flex>
      </Stack>
      {children}
    </Box>
  );
};
