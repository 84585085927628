import { VStack, Flex, Divider } from '@chakra-ui/react';

import { DisplayInformationInline } from 'components/elements/others';
import { formatCalendaryDate, formatDate } from 'utils/formatDate';
import { TitleWithSideLine } from 'components/elements/texts';

import { User } from 'pages/User/interfaces';

import { DefaultCardWrapper } from 'components/elements/wrappers';

import { formatNameToCaptalize } from 'utils/captalizeNames';

interface UserInfosCardProps {
  user: User;
}

export const UserInfosCard: React.FC<UserInfosCardProps> = ({ user }) => {
  return (
    <DefaultCardWrapper
      width="100%"
      p="3"
      maxH={['65vh', 'calc(100vh - 286px)']}
    >
      <Flex px="1.5" w="100%" overflowY="auto" gridGap="6" flexDir="column">
        <Flex flexDir="row" w="100%">
          <Flex gridGap="3" w="100%" flexDirection={['column', 'row']}>
            <VStack alignItems="start" spacing={3} w="100%">
              <TitleWithSideLine>Informações gerais</TitleWithSideLine>

              {user.name && (
                <DisplayInformationInline
                  w="100%"
                  label="Nome"
                  info={formatNameToCaptalize(user.name)}
                />
              )}

              {user.rg && (
                <DisplayInformationInline w="100%" label="RG" info={user.rg} />
              )}

              {user.cpf && (
                <DisplayInformationInline
                  w="100%"
                  label="CPF"
                  info={user.cpf}
                />
              )}

              {user.cnpj && (
                <DisplayInformationInline
                  w="100%"
                  label="CNPJ"
                  info={user.cnpj as string}
                />
              )}

              {user.email && (
                <DisplayInformationInline
                  w="100%"
                  label="E-mail"
                  info={user.email}
                />
              )}

              {user.phone && (
                <DisplayInformationInline
                  w="100%"
                  label="Telefone"
                  info={user.phone}
                />
              )}

              {user.dateOfBirth && (
                <DisplayInformationInline
                  w="100%"
                  label="Nascimento"
                  info={formatCalendaryDate(user.dateOfBirth)}
                />
              )}

              {user.motherName && (
                <DisplayInformationInline
                  w="100%"
                  label="Nome da Mãe"
                  info={formatNameToCaptalize(user.motherName as string)}
                />
              )}

              {user.dadName && (
                <DisplayInformationInline
                  w="100%"
                  label="Nome do Pai"
                  info={formatNameToCaptalize(user.dadName as string)}
                />
              )}

              {user.sex && (
                <DisplayInformationInline
                  w="100%"
                  label="Sexo"
                  info={formatNameToCaptalize(user.sex)}
                />
              )}

              {user.maritalStatus && (
                <DisplayInformationInline
                  w="100%"
                  label="Estado Civil"
                  info={formatNameToCaptalize(user.maritalStatus)}
                />
              )}

              {user.educationLevel && (
                <DisplayInformationInline
                  w="100%"
                  label="Nível de Educação"
                  info={formatNameToCaptalize(user.educationLevel as string)}
                />
              )}
            </VStack>
            <Divider orientation="vertical" borderColor="rgba(70, 70, 70, 1)" />
            <VStack alignItems="start" spacing={3} w="100%">
              {user.address && (
                <>
                  <TitleWithSideLine>Endereço</TitleWithSideLine>

                  <DisplayInformationInline
                    w="100%"
                    label="CEP"
                    info={user.address.cep}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Rua"
                    info={user.address.street}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Número"
                    info={user.address.number}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Bairro"
                    info={user.address.district}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Cidade"
                    info={user.address.city}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="UF"
                    info={user.address.uf}
                  />
                </>
              )}
            </VStack>
          </Flex>
        </Flex>

        <Flex flexDir="row" w="100%">
          <Flex gridGap="3" w="100%" flexDirection={['column', 'row']}>
            <VStack alignItems="start" spacing={4} w="100%">
              {user.bank && (
                <>
                  <TitleWithSideLine>Dados bancários</TitleWithSideLine>

                  <DisplayInformationInline
                    w="100%"
                    label="Banco"
                    info={user.bank.bank}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Agência"
                    info={user.bank.agency}
                  />
                  <DisplayInformationInline
                    w="100%"
                    label="Número da conta"
                    info={user.bank.accountNumber}
                  />
                </>
              )}
            </VStack>
            <Divider orientation="vertical" borderColor="rgba(70, 70, 70, 1)" />
            <VStack alignItems="start" spacing={4} w="100%">
              <TitleWithSideLine>Contrato</TitleWithSideLine>

              {user.position && (
                <DisplayInformationInline
                  w="100%"
                  label="Posição"
                  info={user.position}
                />
              )}

              {user.contractType && (
                <DisplayInformationInline
                  w="100%"
                  label="Contrato"
                  info={user.contractType}
                />
              )}

              {user.admissionDate && (
                <DisplayInformationInline
                  w="100%"
                  label="Data de admissão"
                  info={formatDate(user.admissionDate)}
                />
              )}
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </DefaultCardWrapper>
  );
};
