import { Flex, Text } from '@chakra-ui/react';
import { queryClient } from 'App';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { useState } from 'react';
import { useMutation } from 'react-query';
import {
  CreateNoteRequestType,
  hubApiClients,
} from 'services/hubApi/classes/HubApiClients';
import { ClientType } from 'sharedV2/types/client';

type PropsType = {
  client: ClientType;
};

export const ClientDetailsNotesTextArea: React.FC<PropsType> = ({
  client,
}): JSX.Element => {
  const [note, setNote] = useState<string>('');

  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useMutation({
      mutationKey: ['createNote'],
      mutationFn: async (data: CreateNoteRequestType) => {
        const response = await hubApiClients.createNote(data);
        return response;
      },
      onSuccess: async () => {
        setNote('');

        await queryClient.invalidateQueries(['clientDetails', client.btgCode]);
        await queryClient.invalidateQueries(['clientHistory', client.btgCode]);
      },
    });

  const handleSaveNote = async () => {
    await createNote({
      btgCode: client.btgCode,
      content: note,
    });
  };

  return (
    <Flex w="100%" flexDirection="column" gap={1} p={2}>
      <Text color="rgba(70, 70, 70, 1)" fontWeight="bold">
        Insira uma Nova Nota
      </Text>

      <Input.Root h="100%">
        <Input.TextAreaField
          placeholder="Anotações:"
          h="100%"
          value={note}
          onChange={event => setNote(event.target.value)}
        />
      </Input.Root>
      <Button.Primary onClick={handleSaveNote} isLoading={isLoadingCreateNote}>
        Publicar
      </Button.Primary>
    </Flex>
  );
};
