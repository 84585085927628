import { IPostOrderIp, IPTransferOrdersProps } from 'pages/IP/interfaces';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { hubApiIp } from 'services/hubApi/classes/HubApiIp';
import { KanbanBaseTaskProps } from 'shared/interfaces';

export async function listUserOrders(segment: string, columns: any) {
  await hubApiIp
    .listUserOrdersBySegment(segment)
    .then(response => {
      for (const column of columns) {
        const tasks = response.filter(
          (order: IPTransferOrdersProps) =>
            (order.status === 'noContact' && column.title === 'Sem Contato') ||
            (order.status === 'r1' && column.title === 'R1') ||
            (order.status === 'homeEquity' &&
              column.title === 'Atendimento Home Equity') ||
            (order.status === 'financing' &&
              column.title === 'Atendimento Financiamento') ||
            (order.status === 'inProcess' && column.title === 'Em Processo') ||
            (order.status === 'requestedData' &&
              column.title === 'Dados Solicitados') ||
            (order.status === 'r2' && column.title === 'R2') ||
            (order.status === 'studying' &&
              column.title === 'Fazendo Estudo') ||
            (order.status === 'sentOffer' &&
              column.title === 'Proposta Enviada') ||
            (order.status === 'closed' &&
              column.title === 'Fechado/ Cancelado') ||
            (order.status === 'scheduledCall' &&
              column.title === 'Call Marcada') ||
            (order.status === 'doneCall' &&
              column.title === 'Call realizada') ||
            (order.status === 'waitingDocuments' &&
              column.title === 'Aguardando documentos') ||
            (order.status === 'validation' && column.title === 'Validação') ||
            (order.status === 'followUp' && column.title === 'Follow Up') ||
            (order.status === 'boleted' && column.title === 'Boletado') ||
            (order.status === 'attemptContact' &&
              column.title === 'Tentativa de contato') ||
            (order.status === 'waitingPresentation' &&
              column.title === 'Aguardando Apresentação') ||
            (order.status === 'donePresentation' &&
              column.title === 'Apresentação Realizada') ||
            (order.status === 'waiting' && column.title === 'Fechamento') ||
            (order.status === 'consortium' &&
              column.title === 'Atendimento Consórcio') ||
            (order.status === 'contemplatedLetter' &&
              column.title === 'Atendimento Carta Comtemplada') ||
            (order.status === 'boleted' && column.title === 'Boletado Hoje')
        );
        column.tasks = sortOrderListByNextDate(tasks);
      }
    })
    .catch(error => {
      error.response?.data.message !== 'Internal server error' &&
        toast.dark(error.response?.data.message);
    });
}

export const handleBlurCLient = async (
  event: React.FocusEvent<HTMLInputElement>,
  setFormInfos: React.Dispatch<React.SetStateAction<IPostOrderIp>>,
  formInfos: IPostOrderIp
) => {
  await hubApiClients
    .getClientByBtgCode(event.target.value)
    .then(response => {
      setFormInfos({
        ...formInfos,
        clientCode: event.target.value,
        name: response.name,
        email: response.email,
        phone: response.phone,
      });
    })
    .catch(error => {
      error.response?.data.message !== 'Internal server error' &&
        toast.dark(error.response?.data.message);
    });
};

export const sortOrderListByNextDate = (taskList: KanbanBaseTaskProps[]) => {
  const withContactDate = taskList
    .filter(item => !!item.nextContact)
    .sort((cur, nex) => {
      if (!cur.nextContact || !nex.nextContact) {
        return 0;
      }
      if (cur.nextContact < nex.nextContact) {
        return -1;
      }
      if (cur.nextContact > nex.nextContact) {
        return 1;
      }
      return 0;
    });

  const withoutContactDate = taskList.filter(item => !item.nextContact);

  return [...withContactDate, ...withoutContactDate];
};

export const sumTotalValue = (
  columnList: KanbanBaseTaskProps[]
): number | null => {
  return columnList.length > 0
    ? columnList.reduce((acc, cur) => acc + Number(cur.value), 0)
    : null;
};

export const getOrderTypeBySegment = (segment: string): string => {
  switch (segment) {
    case 'saude':
      return 'ip';
    case 'vida':
      return 'ip';
    case 'frota':
      return 'ip';
    case 'imobiliario':
      return 'ip';
    default:
      return 'ip';
  }
};
