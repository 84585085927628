import React, { BaseSyntheticEvent, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Stack } from '@chakra-ui/react';

import { errorHandler } from 'utils/errorHandler';
import { LeadTypeReturn } from 'utils';
import { checkIfDateIsPast, formatDateToISO } from 'utils/formatDate';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import DateAndHourPicker from 'components/elements/others/DateAndHourPicker';
import { TextareaWithLabel } from 'components/elements/forms';
import { BaseModalFormCard } from 'components/elements/cards';
import { useCaptationMeetingsContext } from 'hooks';

interface ScheduleReturnProps {
  id: string;
  postTasks: () => Promise<void>;
  handleAddTextAreaQuestion?: () => Promise<void>;
  employeeId?: string;
  leadType:
    | 'leadstable'
    | 'leadspublic'
    | 'publics'
    | 'public'
    | 'leadsmanual'
    | 'leadspj'
    | 'pj'
    | 'leadspersonal'
    | 'declined'
    | 'tables'
    | 'pipe'
    | 'leadspurchased'
    | 'pool';
  meetingId?: string;
  status:
    | 'callback'
    | 'suitability'
    | 'presentation'
    | 'allocation'
    | 'account'
    | 'declined'
    | 'created'
    | 'reserved';
  handleChangeToNextLead?: () => void;
  handleShowModal: () => void;
  handleRemoveMinedLeadById?: (leadId: string) => void;
  contactAttempts?: string;
  handleRemoveListLeadById?: (leadId: string) => void;
  localMeeting?: string;
}

export const ScheduleReturn: React.FC<ScheduleReturnProps> = ({
  handleChangeToNextLead,
  handleShowModal,
  id,
  postTasks,
  leadType,
  meetingId,
  status,
  handleAddTextAreaQuestion,
  handleRemoveMinedLeadById,
  contactAttempts = '0',
  handleRemoveListLeadById,
  localMeeting,
  employeeId,
}) => {
  const user = useSelector((state: RootStateOrAny) => state.user.profile);
  const { handleGetSpecificLeadTypeArr } = useCaptationMeetingsContext();

  const [scheduling, setScheduling] = useState('');
  const [complement, setComplement] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMeetingDate: any = (value: string) => {
    const parsed = formatDateToISO(value);

    setScheduling(parsed);
  };

  const handleFormSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const dateIsPast = checkIfDateIsPast(scheduling);
    if (dateIsPast || scheduling.trim() === '') {
      setIsLoading(false);
      toast.dark('Escolha uma data futura para marcar um retorno.');
      return;
    }

    try {
      const LeadType = LeadTypeReturn(leadType);
      let newStatus: string;

      const dataToActionsHistory = {
        employeeId: user.id,
        leadId: id || '',
        leadType: LeadType,
        return: true,
      };

      await hubApiCaptation.patchEmployeeActionsHistory(dataToActionsHistory);

      if ((status as string) === 'ok' || status === 'declined') {
        newStatus = 'callback';
      } else {
        newStatus = status;
      }

      const meetingData = {
        leadId: id,
        employeeId: employeeId ?? user.id,
        employeeIdParticipant: user.id,
        type: meetingId ? 'meeting' : 'callback',
        local: localMeeting || '',
        leadType: LeadType,
        complement,
        date: scheduling,
        status: newStatus,
        feedback: '',
        contactAttempts,
      };

      if (meetingId) {
        await hubApiCaptation.pathMetting({ ...meetingData, id: meetingId });
      } else {
        await hubApiCaptation.postMetting(meetingData);
      }

      if (leadType === 'leadspublic') {
        await hubApiCaptation.pathLeadsUpdate(LeadType, {
          id,
        });
      } else {
        await hubApiCaptation.pathLeadsUpdate(LeadType, {
          id,
          status: 'finalizado',
        });
      }

      if (postTasks) {
        postTasks();
      }

      if (handleChangeToNextLead) handleChangeToNextLead();
      if (handleAddTextAreaQuestion) handleAddTextAreaQuestion();
      toast.dark('Retorno marcado com sucesso.');

      if (handleRemoveMinedLeadById) {
        handleRemoveMinedLeadById(id);
      }

      if (handleRemoveListLeadById) {
        handleRemoveListLeadById(id);
      }

      handleShowModal();

      const isUpdateArr = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((status as any) === 'ocupado' || (status as any) === 'ok') {
        await handleGetSpecificLeadTypeArr('callback', isUpdateArr);
      } else {
        await handleGetSpecificLeadTypeArr(status, isUpdateArr);
      }
    } catch (err) {
      errorHandler(err);
      if (
        err.response?.data.error.message ===
        'Already exists a meeting register for this lead'
      ) {
        toast.dark(
          'Já existe uma reunião para este lead, verifique com o gestor'
        );
      } else {
        toast.dark(
          'Não foi possível agendar a reunião, tente novamente em alguns minutos'
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <BaseModalFormCard
      maxW="510px"
      title="Agendar retorno"
      isLoading={isLoading}
      handleFormSubmit={handleFormSubmit}
      handleToggleModal={handleShowModal}
      primaryButtonText="Agendar retorno"
      isModal
    >
      <Stack spacing="3">
        <DateAndHourPicker onChange={handleChangeMeetingDate} label="Data" />
        <TextareaWithLabel
          label="Complemento"
          placeholder="Informações sobre a reunião"
          name="Complement"
          rows={4}
          value={complement}
          onChange={e => setComplement(e.target.value)}
        />
      </Stack>
    </BaseModalFormCard>
  );
};
