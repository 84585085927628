import { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { MainPageTitle } from 'components/elements/texts';
import { InputWithLabel } from 'components/elements/forms';
import { LoadingSpinner } from 'components/elements/others';
import { SecondaryButton } from 'components/elements/buttons';
import {
  DefaultCardWrapper,
  DefaultPageWrapper,
} from 'components/elements/wrappers';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { ManagementLeadsStatic } from 'pages/GenerationalVariables/components/modules/ManagementLeadsStatic';
import { ManagementLeadsCurrency } from 'pages/GenerationalVariables/components/modules/ManagementLeadsCurrecy';
import { RankingOfSH } from 'pages/GenerationalVariables/components/modules/RankingOfSH';
import { IPCASH } from 'pages/GenerationalVariables/components/modules/IPCASH';
import {
  IDateFilter,
  IIManagementLeads,
} from 'pages/GenerationalVariables/interfaces';

import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';

export const GenerationalVariablesSH: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({} as IDateFilter);
  const [managementLeadsStatic, setManagementLeadsStatic] = useState(
    {} as IIManagementLeads
  );

  let sumOfValues;
  if (managementLeadsStatic.declined) {
    sumOfValues =
      Number(managementLeadsStatic.declined.no_money) +
      Number(managementLeadsStatic.declined.btg_client) +
      Number(managementLeadsStatic.declined.no_interest) +
      Number(managementLeadsStatic.declined.lgpd) +
      Number(managementLeadsStatic.declined.no_exist);
  }

  const handleUpdateDateValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateFilter({
      ...dateFilter,
      [e.target.name]: e.target.value,
    });
  };

  const getVariablesShByPeriod = async () => {
    try {
      if (
        dateFilter.initDate === undefined ||
        dateFilter.endDate === undefined
      ) {
        return toast.dark('Preencha todas as datas');
      }
      setIsLoading(true);
      const response = await hubApiCaptation.getManagementLeadsByPeriod(
        dateFilter.initDate,
        dateFilter.endDate
      );

      setManagementLeadsStatic(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark(`erro ao buscar as os valores estáticos`);
      setIsLoading(false);
    }
  };

  const getManagementLeadsStatic = async () => {
    try {
      setIsLoading(true);
      const response = await hubApiCaptation.getManagementLeads();

      setManagementLeadsStatic(response);
      setIsLoading(false);
    } catch (err) {
      toast.dark('Ocorreu um erro ao buscar as informações');
      setIsLoading(false);
    }
  };

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      getManagementLeadsStatic();
    }
  }, [lastAuth]);

  return (
    <DefaultPageWrapper backPageButton>
      <MainPageTitle textAlign="center" mb={1}>
        Variáveis Geracionais Mundo Invest - SH
      </MainPageTitle>

      <Flex
        w="100%"
        gridGap={[0, 2]}
        align="flex-end"
        flexDir={['column', 'column', 'row']}
        justify="flex-end"
        mb={2}
      >
        <Text alignSelf="flex-end">Período:</Text>
        <InputWithLabel
          label=""
          w="200px"
          p="2"
          name="initDate"
          color="white"
          onChange={handleUpdateDateValues}
          fontSize="small"
          type="date"
        />

        <Text alignSelf="flex-end">até</Text>
        <InputWithLabel
          label=""
          w="200px"
          p="2"
          name="endDate"
          onChange={handleUpdateDateValues}
          color="white"
          fontSize="small"
          type="date"
        />

        <SecondaryButton
          mt={[2, 0]}
          type="submit"
          onClick={getVariablesShByPeriod}
          // w="90%"
        >
          Pesquisar
        </SecondaryButton>
      </Flex>

      <DefaultCardWrapper
        flexDir={['column', 'column', 'column', 'row']}
        w="100%"
        maxW="1080px"
        p="5"
        bg="background.700"
        justifyContent="space-between"
        maxH="calc(100vh - 240px)"
        h="100vh"
        overflow="auto"
      >
        {isLoading ? (
          <Flex align="center" justify="center" w="250px" h="50vh">
            <LoadingSpinner />
          </Flex>
        ) : (
          <Flex
            width="100%"
            maxWidth="296px"
            flexDir="column"
            gridGap="2.5"
            ml="6"
            pr="2"
            overflow="auto"
          >
            <ManagementLeadsStatic
              managementLeadsStatic={managementLeadsStatic}
              sumOfValues={sumOfValues}
            />

            <ManagementLeadsCurrency
              managementLeadsStatic={managementLeadsStatic}
            />
          </Flex>
        )}
        <RankingOfSH />

        <IPCASH />
      </DefaultCardWrapper>
    </DefaultPageWrapper>
  );
};
