import { useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { FcOk } from 'react-icons/fc';
import formatStringInTitle from 'utils/formatStringInTitle';
import { Reminder } from 'pages/MyClients/interfaces';
import { formateDateAndTime } from 'utils/formatDate';
import { LastContactsSectionModal } from './LastContactsSectionModal';

interface LastContactsSectionCardProps {
  reminder: Reminder;
}

export const LastContactsSectionCard: React.FC<LastContactsSectionCardProps> =
  ({ reminder }) => {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
      setShowModal(false);
    };

    return (
      <>
        <Flex
          minW="99%"
          width={'99%'}
          flexDir="column"
          p="10px"
          background="background.400"
          _hover={{ background: 'background.500' }}
          border="1px"
          borderColor="background.600"
          borderRadius={4}
          position="relative"
          cursor="pointer"
          transition="400ms"
          onClick={() => setShowModal(true)}
        >
          <Box position="absolute" top="6px" right="6px">
            <FcOk size={22} />
          </Box>

          <Text
            fontSize="sm"
            color="white.300"
            whiteSpace="nowrap"
            mb={2}
            isTruncated
            maxW="80%"
          >
            {formatStringInTitle(reminder.type)}
          </Text>
          <Text fontSize="xs">
            Data do contato: <br /> {formateDateAndTime(reminder.updated_at)}{' '}
          </Text>
        </Flex>

        <LastContactsSectionModal
          isOpen={showModal}
          closeModal={handleCloseModal}
          reminder={reminder}
        />
      </>
    );
  };
