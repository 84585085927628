import { toast } from 'react-toastify';

export const showToast = (
  message: string,
  type?: 'success' | 'warn' | 'error'
): void => {
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'warn':
      toast.warn(message);
      break;
    case 'error':
      toast.error(message);
      break;
    default:
      toast.dark(message);
  }
};
