/* eslint no-shadow: off */
/* eslint no-unused-vars: off */
export const advancedOrdersHeader = [
  'Cliente/Solicitante',
  'Área',
  'Data da solicitação',
  'Status',
];

export enum hubAreasCode {
  EXCHANGE = 'EX',
  RH = 'RH',
  IP = 'IP',
  MK = 'MK',
  TI = 'TI',
}

export const hubAreasOptions = [
  { title: 'Todas', value: 'all' },
  { title: 'Câmbio', value: 'EX' },
  { title: 'Recursos Humanos', value: 'RH' },
  { title: 'Inteligência Patrimonial', value: 'IP' },
  { title: 'Marketing', value: 'MK' },
  { title: 'Suporte', value: 'TI' },
];
