import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { Textarea } from 'components/elements/forms';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { Box, Flex, Text } from '@chakra-ui/react';
import { BackPageButton, PrimaryButton } from 'components/elements/buttons';
import { hubApiRH } from 'services/hubApi/classes/HubApiRH';

interface IFormInfo {
  information: string;
  anonymous: boolean;
}

const SolicitationForm: React.FC = () => {
  const [formInfos, setFormInfos] = useState<IFormInfo>({
    information: '',
    anonymous: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInfos({ ...formInfos, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (formInfos.information.trim() === '') {
      setIsLoading(false);
      return toast.dark('Você deve preencher o campo de informações');
    }

    await hubApiRH
      .createOrdeRH(formInfos)
      .then(() => {
        toast.dark('Solicitação enviada com sucesso');
        setFormInfos({ ...formInfos, information: '' });
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          if (error.response?.data.message !== 'Internal server error') {
            toast.dark(error.response?.data.message);
          }
        } else {
          toast.dark(
            'Ocorreu um erro no servidor ao tentar enviar formulário. Tente novamente mais tarde'
          );
        }
      });
    setIsLoading(false);
  };

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDir="column"
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />
      <DefaultCardWrapper flexDir="column" p="6" maxW={['95vw', 'auto']}>
        <Box>
          <Text color="white" fontSize="lg" fontWeight="bold">
            Solicitações e Dúvidas - R.H
          </Text>
        </Box>

        <form onSubmit={handleFormSubmit}>
          <Textarea
            rows={8}
            marginY="4"
            color="white"
            w={['100%', 'lg']}
            placeholder="Descreva sua solicitação ou dúvida..."
            name="information"
            value={formInfos.information}
            onChange={handleChangeValue}
          />

          <Flex alignItems="flex-end" justifyContent="flex-end">
            <PrimaryButton isLoading={isLoading} type="submit" size="sm">
              Enviar solicitação
            </PrimaryButton>
          </Flex>
        </form>
      </DefaultCardWrapper>
    </PageWrapper>
  );
};

export default SolicitationForm;
