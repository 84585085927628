import permissionsCods from 'shared/constants/permissionsCods';
import { IRulesRedux } from 'shared/interfaces';

export function verifyPermission(user: IRulesRedux[], cod: string): boolean {
  const isManager = user.findIndex(rule => {
    return (
      rule.area_cod === cod && rule.permission_cod === permissionsCods.GESTOR
    );
  });

  return isManager !== -1;
}
