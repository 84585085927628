import { LeadMundoInvestType } from 'sharedV2/types/leadMundoInvest';
import { create } from 'zustand';

type UseSalesHunterPoolContextType = {
  leads: LeadMundoInvestType[];
  setLeads: (leads: LeadMundoInvestType[]) => void;
};

export const useSalesHunterPoolContext = create<UseSalesHunterPoolContextType>(
  set => ({
    leads: [] as LeadMundoInvestType[],

    setLeads: (leads: LeadMundoInvestType[]) => set(() => ({ leads })),
  })
);
