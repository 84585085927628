import React from 'react';

import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';
import { IEmail } from 'pages/Captation/interfaces';

interface ComponentProps {
  emails: Partial<IEmail | undefined>[];
}

export const LeadEmailsContent: React.FC<ComponentProps> = ({
  emails = [],
}) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle>E-mail</ContentDividerTitle>
      {emails[0]?.email ? (
        emails?.map((email, index) => (
          <ContentDividerText key={index}>{email?.email}</ContentDividerText>
        ))
      ) : (
        <ContentDividerText mb={1} fontSize="inherit">
          A confirmar
        </ContentDividerText>
      )}
    </ContentWithVertDividerWrapper>
  );
};
