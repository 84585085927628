import React from 'react';

import { ContentWithVertDividerWrapper } from 'pages/Captation/components/elements/wrappers';
import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';

interface ComponentProps {
  billingRange: string;
}

export const LeadBillingRange: React.FC<ComponentProps> = ({
  billingRange,
}) => {
  return (
    <ContentWithVertDividerWrapper>
      <ContentDividerTitle>Faixa de faturamento</ContentDividerTitle>
      <ContentDividerText>{billingRange}</ContentDividerText>
    </ContentWithVertDividerWrapper>
  );
};
