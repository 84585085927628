import { Flex, Text } from '@chakra-ui/react';
import { DeclineAndFrequencyDataBox } from './DeclineAndFrequencyDataBox';

interface FrequencyDatasetProps {
  frequencyData: {
    aguardando: string;
    realizada: string;
  };
  frequencyComputedPercentage: string;
}

export const FrequencyDataset: React.FC<FrequencyDatasetProps> = ({
  frequencyData,
  frequencyComputedPercentage,
}): JSX.Element => {
  return (
    <Flex align="center">
      <Text pr="16px">Frequência</Text>

      <Flex width="100%" direction="row" gridGap="2">
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Tarefas geradas"
          value={frequencyData.aguardando}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Tarefas executadas"
          value={frequencyData.realizada}
        />
        <DeclineAndFrequencyDataBox
          isSmallVersion
          label="Percentual"
          value={`${frequencyComputedPercentage} %`}
        />
      </Flex>
    </Flex>
  );
};
