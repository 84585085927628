import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';

import history from 'services/history';

interface ComponentProps {
  link?: string;
  checkIfClientNoteIsSavedBeforeGoBack?: () => void;
}

export const BackPageButton: React.FC<ComponentProps> = ({
  link,
  checkIfClientNoteIsSavedBeforeGoBack,
}) => {
  const handleBackPage = () => history.goBack();

  if (link) {
    return (
      <Flex
        position="absolute"
        left={{ base: '3%', md: '4%' }}
        top="48px"
        cursor="pointer"
      >
        <Link to={link}>
          <IoChevronBack size={26} color="rgba(70, 70, 70, 1)" />
        </Link>
      </Flex>
    );
  }

  if (checkIfClientNoteIsSavedBeforeGoBack) {
    return (
      <Flex
        onClick={checkIfClientNoteIsSavedBeforeGoBack}
        position="absolute"
        left={{ base: '3%', md: '4%' }}
        top={['20px', '48px']}
        cursor="pointer"
      >
        <IoChevronBack size={26} color="rgba(70, 70, 70, 1)" />
      </Flex>
    );
  }

  return (
    <Flex
      onClick={handleBackPage}
      position="absolute"
      left={{ base: '3%', md: '4%' }}
      top={['20px', '48px']}
      cursor="pointer"
    >
      <IoChevronBack size={26} color="rgba(70, 70, 70, 1)" />
    </Flex>
  );
};
