import { Button } from 'componentsV2/elements/buttons';
import {
  MeetingActionEnum,
  useMeetingActions,
} from 'hooksV2/useMeetingActions';
import { useNavigation } from 'hooksV2/useNavigation';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { showToast } from 'utils';

export const LeadDetailsAllocated = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { redirect } = useNavigation();

  const { mutateAsync: meetingAction, isLoading: isLoadingMeetingAction } =
    useMeetingActions({
      onSuccess: () => {
        showToast('Lead alocado com sucesso!');
        redirect('/');
      },
    });

  const handleAllocatedLead = async () => {
    await meetingAction({
      action: MeetingActionEnum.ALLOCATED,
      leadType,
      meetingId: lead?.meeting?.id as string,
    });
  };

  return (
    <>
      <Button.Primary
        onClick={handleAllocatedLead}
        isLoading={isLoadingMeetingAction}
      >
        Alocado
      </Button.Primary>
    </>
  );
};
