import { Flex, Text } from '@chakra-ui/react';
import { MainPageTitle } from 'components/elements/texts';
import { Button } from 'componentsV2/elements/buttons';
import { Input } from 'componentsV2/elements/input';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useQuery } from 'react-query';
import { hubApiClients } from 'services/hubApi/classes/HubApiClients';
import { showToast } from 'utilsV2/showToast';
import { ClientSearchCard } from './components/clientCard';
import { ClientsSearchSkeletonLoading } from './components/skeletonLoading';

export const ClientsSearch = (): JSX.Element => {
  const {
    filterValue: searchClientsFilterValue,
    handleChangeFilterValue: handleChangeSearchClientsFilterValue,
  } = useSimpleQueryFilter('filterValue');

  const {
    data: clients,
    refetch: refetchClientsSearch,
    isRefetching: isRefetchingClientsSearch,
    isLoading: isLoadingClientsSearch,
  } = useQuery({
    queryKey: ['clientsSearch'],
    queryFn: async () => {
      const response = await hubApiClients.getAllClients({
        filterValue: searchClientsFilterValue,
      });
      return response;
    },
    onError: () =>
      showToast('Erro ao buscar clientes. Contacte os desenvolvedores!'),
    enabled: false,
  });

  const isLoading = isLoadingClientsSearch || isRefetchingClientsSearch;
  const shouldLoadSkeleton = !clients && isLoading;
  const thereIsNoClientToDisplay = !clients && !isLoading;

  const handleSearchLeads = () => {
    refetchClientsSearch();
  };

  return (
    <PageWrapper paddingTop="2rem" backPageButton>
      <MainPageTitle>Pesquisa de Clientes</MainPageTitle>

      <Flex flexDirection="column" gap={10}>
        <Flex justifyContent="center" gap={2} h="40px">
          <Input.Root w="35vw">
            <Input.TextField
              value={searchClientsFilterValue}
              onChange={event =>
                handleChangeSearchClientsFilterValue(event.target.value)
              }
              style={{ flex: 1 }}
              placeholder="Nome, Email, Código BTG ou Telefone"
            />
          </Input.Root>

          <Button.Primary
            h="100%"
            onClick={handleSearchLeads}
            isLoading={isLoading}
          >
            Pesquisar
          </Button.Primary>
        </Flex>

        <Flex justifyContent="center">
          {thereIsNoClientToDisplay && (
            <Text fontStyle="italic" color="rgba(70, 70, 70, 1)">
              Pesquise um cliente por Nome, Email ou Telefone
            </Text>
          )}

          <Flex
            overflowY="auto"
            maxH="calc(100vh - 290px)"
            flexDirection="column"
            alignItems="center"
            gap={3}
            className="thinScrollbar"
          >
            {shouldLoadSkeleton && <ClientsSearchSkeletonLoading />}

            {clients &&
              clients.map((client, index) => (
                <ClientSearchCard key={index} client={client} />
              ))}
          </Flex>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
