import { Flex, Text } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/elements/others';
import { MainPageTitle, TitleWithSideLine } from 'components/elements/texts';
import { PageWrapper } from 'componentsV2/elements/wrappers/page';
import { PowerBIPageCard } from 'pages/Dashboards/components/powerBIPageCard';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import hubApi from 'services/hubApi';

export const Dashboards = (): JSX.Element => {
  const {
    data: allPowerBIsDashboards,
    isLoading: isLoadingAllPowerBIsDashboards,
  } = useQuery({
    queryKey: ['allPowerBIsDashboards'],
    queryFn: async () => {
      const response = await hubApi.getPowerBIDashboards({});
      return response;
    },
  });

  return (
    <PageWrapper alignItems="center" paddingTop="2rem">
      <Flex flexDirection="column" w="80%" alignItems="center">
        <MainPageTitle color="rgba(70, 70, 70, 1)">Dashboards</MainPageTitle>
        {isLoadingAllPowerBIsDashboards && <LoadingSpinner />}

        {allPowerBIsDashboards?.dashboards.length === 0 && (
          <Text color="rgba(70, 70, 70, 1)">Sem Dashboards</Text>
        )}

        <Flex
          flexDirection="column"
          w="100%"
          h="80vh"
          gap={10}
          overflowY="auto"
          className="thinScrollbar"
          pr={4}
        >
          {allPowerBIsDashboards &&
            allPowerBIsDashboards.dashboards.map(item => {
              return (
                <Flex flexDirection="column" gap={4} key={item.powerBIName}>
                  <TitleWithSideLine fontSize={16}>
                    {item.powerBIName.toUpperCase()}
                  </TitleWithSideLine>

                  <Flex wrap="wrap" gap={5}>
                    {item.pages.map(page => {
                      return (
                        <PowerBIPageCard
                          page={page}
                          key={page.id}
                          minH="220px"
                          border="1px solid #3071e4"
                        />
                      );
                    })}
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
