import React, { ChangeEvent, useEffect, useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import formatValue from 'utils/formatValue';
import UploadButton from 'components/elements/forms/UploadButton';
import DatePicker from 'components/elements/others/DatePicker';

import {
  PrimaryButton,
  SecondaryButton,
  BackPageButton,
} from 'components/elements/buttons';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TableBase, TableData, TableRow } from 'components/elements/table';
import { File, NegativeCustomerProps } from 'pages/Financials/interfaces';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { today, negativeCustomerHead, initialFileValue } from './data';

export const FinancialNegativeCustomer: React.FC = () => {
  const [infosTable, setInfosTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewReady, setPreviewReady] = useState(false);
  const [date, setDate] = useState(today);
  const [file, setFile] = useState(initialFileValue);

  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const handleSendPreview = async () => {
    const formData = new FormData();
    formData.append('btg', file as File);

    setIsLoading(true);

    await hubApiFinancial
      .postBalanceFile(formData, String(date))
      .then(async () => {
        await hubApiFinancial.getBalancePreview().then(response => {
          setInfosTable(response);
          setIsLoading(false);
          setPreviewReady(true);
        });
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao fazer o upload, tente novamente.');
        setIsLoading(false);
      });
  };

  const handleConfirmFile = async () => {
    const alreadySendFile =
      await hubApiFinancial.checkIfAlreadySendFileNegativeCustomer(date);

    if (alreadySendFile)
      return toast.dark('Já existe um arquivo enviado nesta data.');

    setIsLoading(true);

    await hubApiFinancial
      .attBalanceFile({ status: 'importado' })
      .then(() => {
        setInfosTable([]);
        setPreviewReady(false);
        setFile(initialFileValue);
        setIsLoading(false);
        toast.dark('Arquivo enviado com sucesso.');
      })
      .catch(() => {
        toast.dark('Ocorreu um erro ao enviar arquivo, tente novamente.');
        setIsLoading(false);
      });
  };

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="white"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Financeiro - Saldo negativo
      </MainPageTitle>

      <Flex
        py="24px"
        px="16px"
        borderRadius="5px"
        bg="background.500"
        w="100%"
        maxWidth={['95vw', '780px']}
        flexDir="column"
      >
        <Flex
          mb="12px"
          flexDir={['column', 'row']}
          justifyContent="left"
          alignItems={['flex-start', 'flex-end']}
          gridGap={[2, 0]}
        >
          <Flex alignItems={'flex-end'} justifyContent={'center'}>
            <Box maxWidth="200px">
              <UploadButton
                label={file.name}
                accept=".csv"
                onChange={handlePickFile}
              />
            </Box>

            <Box ml={['0px', '4px']} maxWidth="150px">
              <DatePicker
                handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
                  setDate(e.target.value)
                }
                value={date}
                full
                fullColum
                style
                type="date"
                directionRow
              />
            </Box>
          </Flex>
          <Box maxWidth="150px">
            <SecondaryButton
              isLoading={isLoading}
              py="17px"
              onClick={handleSendPreview}
            >
              Enviar Preview
            </SecondaryButton>
          </Box>
        </Flex>

        <Box mb="22px" mt="12px">
          <TableBase
            headData={negativeCustomerHead}
            minWidth="100%"
            minHeight="200px"
            width={['100%', 'fit-content']}
          >
            {infosTable.map((info: NegativeCustomerProps, index) => (
              <TableRow key={index}>
                {Object.keys(info).map((key, idx) =>
                  key === 'balance' ? (
                    <TableData key={idx}>
                      {formatValue(Number(info[key]))}
                    </TableData>
                  ) : (
                    key !== 'created_at' &&
                    key !== 'date' &&
                    key !== 'id' &&
                    key !== 'status' &&
                    key !== 'updated_at' && (
                      <TableData key={idx}>{info[key]}</TableData>
                    )
                  )
                )}
              </TableRow>
            ))}
          </TableBase>
        </Box>

        <Flex justifyContent="flex-end">
          {previewReady && (
            <PrimaryButton
              mt="2"
              isLoading={isLoading}
              onClick={handleConfirmFile}
            >
              Confirmar e enviar arquivo
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
