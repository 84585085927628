import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';

import { PageWrapper } from 'components/elements/wrappers';
import CreateIPOForm from 'pages/products/components/modules/ipo/CreateIPOForm';

import { IPOProps } from 'pages/products/interfaces';

import { LoadingSpinner } from 'components/elements/others';
import { Flex } from '@chakra-ui/react';
import schema from './schema';

interface RouteProps {
  id: string;
}

export const UpdateIPO: React.FC = () => {
  const history = useHistory();
  const { id } = useParams() as RouteProps;
  const [prospect, setProspect] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [productTitle, setProductTitle] = useState('');
  const [ipoData, setIpoData] = useState<IPOProps>({} as IPOProps);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateIpo = useCallback(
    async e => {
      e.preventDefault();

      setIsLoading(true);

      if (Number(ipoData.priceMax) < Number(ipoData.priceMin)) {
        setIsLoading(false);
        return toast.dark(
          'O valor máximo da aplicação deve ser maior que o valor mínimo.'
        );
      }

      const formData = new FormData();
      if (prospect) {
        formData.append('prospect', prospect);
      }

      try {
        await schema.validate(ipoData, {
          abortEarly: false,
        });

        formData.append('title', ipoData.title);
        formData.append('ticker', ipoData.ticker);
        formData.append('bookingDeadlines', ipoData.bookingDeadlines);
        formData.append('financialSettlements', ipoData.financialSettlements);
        formData.append('priceMax', ipoData.priceMax);
        formData.append('priceMin', ipoData.priceMin);
        formData.append('disclosureOfResults', ipoData.disclosureOfResults);
        formData.append('ramboMessage', ipoData.ramboMessage);
        formData.append('startOfNegotiations', ipoData.startOfNegotiations);
        formData.append('strategy', ipoData.strategy);
        formData.append('whatsMessage', ipoData.whatsMessage);
        formData.append('offer', ipoData.offer);
        formData.append('pricePerQuote', ipoData.pricePerQuote);
        formData.append('marketQuote', ipoData.marketQuote);
        formData.append('discount', ipoData.discount);
        formData.append('dividendYeld', ipoData.dividendYeld);
        formData.append('description', ipoData.description);
        formData.append('bonusMessage', ipoData.bonusMessage || '');

        if (ipoData.title === 'undefined' && productTitle !== ipoData.ticker) {
          await hubApiProducts.updateCard(ipoData.id, {
            productTitle: ipoData.ticker,
          });
        }

        if (ipoData.title !== 'undefined' && productTitle !== ipoData.title) {
          await hubApiProducts.updateCard(ipoData.id, {
            productTitle: ipoData.title,
          });
        }

        hubApiProducts
          .putUpdateIpo(id, formData)
          .then(() => {
            toast.dark('IPO Atualizado com sucesso.');
            history.goBack();
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            if (error.response.status === 400) {
              toast.dark(error.response?.data.message);
            } else {
              toast.dark(
                'Ocorreu um erro ao atualizaroo   IPO, tente novamente.'
              );
            }
          });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            toast.dark(err.message);
          });
        } else {
          toast.dark(
            'Ocorreu um erro ao criar o COE, por favor tente novamente.'
          );
        }
      }
    },
    [ipoData, history, id, prospect]
  );

  useEffect(() => {
    hubApiProducts.getIpoById(id).then(data => {
      setIpoData(data);
      setLoading(false);
      if (data.title === 'undefined') {
        setProductTitle(data.ticker);
      } else {
        setProductTitle(data.title);
      }
    });
  }, [id]);

  return (
    <PageWrapper>
      {loading ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100vh"
        >
          <LoadingSpinner />
        </Flex>
      ) : (
        <CreateIPOForm
          isLoading={isLoading}
          ipoData={ipoData}
          handleSubmitForm={handleUpdateIpo}
          setIPOProspectFile={setProspect}
          setValue={setIpoData}
          title="IPO EWZ Capital"
          nameButton="Atualizar IPO"
        />
      )}
    </PageWrapper>
  );
};
