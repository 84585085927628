import { Flex, Text } from '@chakra-ui/react';
import { Modal } from 'componentsV2/elements/modal';
import { HeadDataType, TableBase } from 'componentsV2/elements/table/base';
import { TableRow } from 'componentsV2/elements/table/row';
import { UseSimpleFilterResponseType } from 'hooksV2/useSimpleQueryFilter';
import { useQuery } from 'react-query';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';
import { DateUtils } from 'utilsV2/Date';

type PropsType = {
  client: ClientType;
  revenueDate: string;
  isOpenRevenueDetailsModalFilter: UseSimpleFilterResponseType;
};

export const ClientDetailsRevenueDetailsModal: React.FC<PropsType> = ({
  client,
  isOpenRevenueDetailsModalFilter,
  revenueDate,
}): JSX.Element => {
  // When user reload the page we losse the "revenueDate" (it's a state),
  // therefore we need to close the modal to prevent black screen
  if (!revenueDate) {
    isOpenRevenueDetailsModalFilter.handleDeleteParam();

    return <></>;
  }

  const { data } = useQuery({
    queryKey: ['revenueDetails', revenueDate, client.btgCode],
    queryFn: async () => {
      const response = await hubApiFinancial.getMonthlyCommissionDetails(
        client.btgCode,
        revenueDate
      );

      return response;
    },
    enabled: revenueDate !== undefined,
  });

  const tableHeadData: HeadDataType[] = [
    {
      columnName: 'Produto',
      widthPercentage: 100,
    },
    {
      columnName: 'Ativo',
      widthPercentage: 100,
    },
    {
      columnName: 'Comissão AAI',
      widthPercentage: 100,
    },
  ];

  const totalRevenue = data?.reduce((acc, item) => acc + Number(item.final), 0);

  return (
    <Modal.Root w="1000px">
      <Modal.Header>
        <Modal.Title title={DateUtils.format(revenueDate, "MMMM 'de' yyyy")} />
        <Modal.CloseIcon
          onClick={() => isOpenRevenueDetailsModalFilter.handleDeleteParam()}
        />
      </Modal.Header>

      <Flex flexDirection="column" gap={3}>
        <TableBase
          headData={tableHeadData}
          maxH="calc(100vh - 300px)"
          overflowY="auto"
          className="thinScrollbar"
        >
          {data &&
            data.map((item, index) => (
              <TableRow headData={tableHeadData} key={index}>
                <Text color="rgba(70, 70, 70, 1)">{item.product}</Text>
                <Text color="rgba(70, 70, 70, 1)">{item.asset}</Text>
                <Text color="rgba(70, 70, 70, 1)">
                  {CurrencyUtils.format(item.final)}
                </Text>
              </TableRow>
            ))}
        </TableBase>

        <Text
          w="100%"
          textAlign="right"
          color="rgba(70, 70, 70, 1)"
          fontWeight="bold"
        >
          Total:{' '}
          {totalRevenue
            ? CurrencyUtils.format(totalRevenue.toString())
            : 'Não informado'}
        </Text>
      </Flex>
    </Modal.Root>
  );
};
