import { Flex } from '@chakra-ui/react';

export const WrapperCardContact: React.FC = ({ children }) => {
  return (
    <Flex
      w="100%"
      align="center"
      justify="center"
      flexDir="column"
      gridGap="2"
      fontWeight="semibold"
      p="3"
      // borderBottom="1px solid white"
      mb="5"
    >
      {children}
    </Flex>
  );
};
