import {
  IMonthlyCommissionTotal,
  IRemunerationDetails,
} from 'pages/Remuneration/interfaces';
import { HubApi } from '../index';

interface IStatus {
  status: string;
}

class HubApiFinancial extends HubApi {
  async getClientsPreview() {
    const response = await this.axios.get('/clients');
    return response.data;
  }

  async getIncomes(date: string) {
    const response = await this.axios.get(`/financial/income/${date}`);
    return response.data;
  }

  async postIncome(data: FormData) {
    const response = await this.axios.post('/financial/income/upload', data);
    return response.data;
  }

  async postRemuneration(data: any) {
    const response = await this.axios.post(`/commission/`, data);
    return response.data;
  }

  async postClientsFile(data: FormData, info: string) {
    const response = await this.axios.post(`/clients/${info}`, data);
    return response.data;
  }

  async attClientsFile(data: IStatus) {
    const response = await this.axios.patch('/clients/confirm', data);
    return response.data;
  }

  async getBalancePreview() {
    const response = await this.axios.get('/clients/balance');
    return response.data;
  }

  async postBalanceFile(data: FormData, info: string) {
    const response = await this.axios.post(`/clients/balance/${info}`, data);
    return response.data;
  }

  async attBalanceFile(data: IStatus) {
    const response = await this.axios.patch('/clients/balance/confirm/', data);
    return response.data;
  }

  async sendRevenueFilePreview(uploadData: FormData) {
    const response = await this.axios.post(
      '/financial/revenue/upload/review',
      uploadData
    );
    return response.data;
  }

  async sendRevenueFileConfirm(uploadData: FormData) {
    const response = await this.axios.post(
      '/financial/revenue/upload',
      uploadData
    );
    return response.data;
  }

  async sendCaptationFilePreview(fileData: FormData) {
    const response = await this.axios.post('/nnm', fileData);
    return response.data;
  }

  async getCaptationFilePreview() {
    const response = await this.axios.get('/nnm');
    return response.data;
  }

  async sendCaptationFileConfirm(data: IStatus) {
    const response = await this.axios.patch('/nnm/confirm', data);
    return response.data;
  }

  async checkIfAlreadySendFile(date: string) {
    const response = await this.axios.get(`/clients/exist/${date}`);
    return response.data;
  }

  async checkIfAlreadySendFileNegativeCustomer(date: string) {
    const response = await this.axios.get(`/clients/balance/exist/${date}`);
    return response.data;
  }

  async checkUserPermission(employeeId: string) {
    const response = await this.axios.get(`/employees/financial/${employeeId}`);
    return response.data;
  }

  async getCommissionDetails(date: string, ids: string[]) {
    const response = await this.axios.get(`/commission/employee/${date}`, {
      params: { ids },
    });
    return response.data;
  }

  async getMonthlyCommission(clientBTGCode: string) {
    const response = await this.axios.get<IMonthlyCommissionTotal[]>(
      `/commission/by/client/${clientBTGCode}`
    );
    return response.data;
  }

  async getMonthlyCommissionDetails(clientBTGCode: string, date: string) {
    const response = await this.axios.get<IRemunerationDetails[]>(
      `/commission/by/client/${clientBTGCode}/${date}`
    );
    return response.data;
  }

  async attFromType(data: any, id: string) {
    const response = await this.axios.patch(
      `/clients/contact/origin/${id}`,
      data
    );
    return response.data;
  }

  async getDataMonthFinancialEmployee(date: string, id: string) {
    const response = await this.axios.get(`/financial/income/${id}/${date}`);

    return response;
  }

  async getComissionByBtg() {
    const response = await this.axios.get(`/financial/area`);

    return response.data;
  }

  async getDataYearFinancialEmployee(year: string, id: string) {
    const response = await this.axios.get(
      `/financial/income/year/${id}/${year}`
    );

    return response;
  }
}

export const hubApiFinancial = new HubApiFinancial();
