import * as yup from 'yup';

export default yup.object().shape({
  type: yup.string().required('Selecione um tipo para esse Fundo.'),
  name: yup.string().required('Digite um nome para esse Fundo.'),
  cnpj: yup.string().required('Digite o CNPJ desse Fundo.'),
  nameTec: yup.string().required('Digite um nome técnico para esse Fundo.'),
  aplication: yup.string().required('Digite o valor mínimo da Aplicação.'),
  liquidation: yup.string().required('Digite a Liquidação de resgate.'),
  quotation: yup.string().required('Digite a Cotização de resgate.'),
  risck: yup.string().required('Selecione um risco.'),
  taxaAdm: yup.string().required('Digite o valor da taxa de admistração.'),
  taxaActing: yup.string().required('Digite o valor da taxa de Performance.'),
  profitability: yup.object().shape({
    month: yup.string().required('Digite a rentabilidade ao Mês desse fundo.'),
    yaer: yup.string().required('Digite a rentabilidade ao Ano desse fundo.'),
    twelveMonth: yup
      .string()
      .required('Digite a rentabilidade nos últimos 12 meses desse fundo.'),
  }),
});
