import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from 'components/elements/wrappers';
import { IReduxState } from 'shared/interfaces';
import verifyEmployeePermission from 'utils/verifyEmployeePermission';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import { hubApiUser } from 'services/hubApi/classes/HubApiUser';
import { hubApiOfficeTables } from 'services/hubApi/classes/HubApiOfficeTables';
import { ITables, IEmployees } from './interfaces';
import { MosaicOfMeetings } from './components/MosaicOfMeetings';
import { Header } from './components/Header';

export const TeamMeetings: React.FC = () => {
  const [tables, setTables] = useState<ITables[]>([]);
  const [selectedTableId, setSelectedTableId] = useState('');
  const [employees, setEmployees] = useState<IEmployees[]>([]);
  const [employeeId, setEmployeeId] = useState('');
  const [selectViewedMeetings, setSelectViewedMeetings] = useState(false);

  const user: IReduxState = useSelector(
    (state: IReduxState) => state.user.profile
  );
  const isLoggedUserManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTR', 'MASTER'],
    areaCod: ['ASS'],
  });

  const isLoggedUserTableManager = verifyEmployeePermission({
    user,
    permissionCod: ['GTM'],
    areaCod: ['ASS'],
  });

  const getAllTablesOrEmployees = async () => {
    if (isLoggedUserManager) {
      const allTables = await hubApiCaptation.getAllTables();
      return setTables(allTables);
    }
    if (isLoggedUserTableManager) {
      const employees = await hubApiOfficeTables.listUsersByTable(
        user.tableId ?? ''
      );
      return setEmployees(employees);
    }
  };

  const getEmployees = async (tableId: string) => {
    const response = await hubApiUser.getEmployeesByTable(tableId);
    setEmployees(response);
  };

  useEffect(() => {
    if (selectedTableId !== '') {
      getEmployees(selectedTableId);
    }
  }, [selectedTableId]);

  useEffect(() => {
    getAllTablesOrEmployees();
  }, [user.tableId]);

  return (
    <PageWrapper alignItems="center" flexDir="column" pt="48px">
      <Header
        setSelectedTableId={setSelectedTableId}
        tables={tables}
        employees={employees}
        setEmployeeId={setEmployeeId}
        setSelectViewedMeetings={setSelectViewedMeetings}
        selectViewedMeetings={selectViewedMeetings}
        isLoggedUserManager={isLoggedUserManager}
      />
      <MosaicOfMeetings
        employeeId={employeeId}
        selectViewedMeetings={selectViewedMeetings}
        isLoggedUserManager={isLoggedUserManager}
      />
    </PageWrapper>
  );
};
