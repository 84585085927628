import { SelectProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import * as S from './style';

type PropsType = {
  w?: string;
} & S.SelectPropsType &
  SelectProps;

export const DropdownRoot = forwardRef<HTMLDivElement, PropsType>(
  (props, ref) => {
    const IS_DROPDOWN_WITH_TEXT_FILTER = (props.children?.valueOf() as any)
      ?.props?.onSelectDropdownItem;

    if (IS_DROPDOWN_WITH_TEXT_FILTER) {
      return (
        <div style={{ width: props.w }} ref={ref}>
          {props.children}
        </div>
      );
    }

    return (
      <div style={{ width: props.w }} ref={ref}>
        <S.Select {...props}>{props.children}</S.Select>
      </div>
    );
  }
);
