import { ModalWrapper } from 'components/elements/wrappers';
import { TransferLeadModal } from 'componentsV2/modules/captation/transferLeadModal';
import { useSimpleQueryFilter } from 'hooksV2/useSimpleQueryFilter';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { TransferToEnum } from 'services/hubApi/classes/HubApiCaptation';
import { Button, Text } from '@chakra-ui/react';
import { CurrencyCircleDollar } from 'phosphor-react';
import { useState } from 'react';

export const LeadDetailsTransferLeadToAAI = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();
  const [isHoveredTransferAAI, setIsHoveredTransferAAI] = useState(false);

  const {
    filterValue: isOpenTransferLeadToAAIModalFilterValue,
    handleChangeFilterValue: handleChangeIsOpenTransferLeadToAAIModalValue,
    handleDeleteParam: handleDeleteIsOpenTransferLeadToAAIModalFilter,
  } = useSimpleQueryFilter('isOpenTransferLeadToAAIModal');

  const isOpenTransferLeadToAAIModal =
    isOpenTransferLeadToAAIModalFilterValue === 'true';

  return (
    <>
      <Button
        onClick={() => handleChangeIsOpenTransferLeadToAAIModalValue('true')}
        fontSize="0.8rem"
        size="lg"
        justifyContent="center"
        backgroundColor="#FFF6E0"
        border="1px solid transparent"
        color="#464646"
        _hover={{
          bg: '#464646',
          color: '#FFF6E0',
        }}
        flexBasis="33%"
        onMouseLeave={() => setIsHoveredTransferAAI(false)}
        onMouseEnter={() => setIsHoveredTransferAAI(true)}
      >
        <CurrencyCircleDollar
          color={isHoveredTransferAAI ? '#FFF6E0' : '#E0B55A'}
          size={15}
        />
        <Text ml={2}>Transferir Lead para AAI</Text>
      </Button>

      <ModalWrapper
        isOpen={isOpenTransferLeadToAAIModal}
        onClose={() => handleDeleteIsOpenTransferLeadToAAIModalFilter()}
      >
        <TransferLeadModal
          lead={lead}
          leadType={leadType}
          transferTo={TransferToEnum.AAI}
        />
      </ModalWrapper>
    </>
  );
};
