import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';

interface ComponentProps {
  linkToShow: string;
  handleSubmitConfirmBasket: () => void;
  handleCloseModalConfirmBasket: () => void;
  isLoadingModalDownloadOrder: boolean;
}

const ModalDownloadOrder: React.FC<ComponentProps> = ({
  linkToShow,
  handleSubmitConfirmBasket,
  handleCloseModalConfirmBasket,
  isLoadingModalDownloadOrder,
}) => {
  return (
    <BaseModalFormCard
      maxW="560px"
      title="Download realizado com sucesso!"
      isLoading={isLoadingModalDownloadOrder}
      handleFormSubmit={handleSubmitConfirmBasket}
      handleToggleModal={handleCloseModalConfirmBasket}
      isModal
      primaryButtonText="Confirmar basket"
      secondaryButtonText="Erro no arquivo"
    >
      <Stack>
        <Text fontSize="md" color="white" marginTop="20px">
          Confirme após enviar a basket.
        </Text>
        <Text fontSize="md" color="white">
          Código da basket : <strong>{linkToShow}</strong>
        </Text>
      </Stack>
    </BaseModalFormCard>
  );
};
export default ModalDownloadOrder;
