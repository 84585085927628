import React, { useState, ChangeEvent, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Flex, Box } from '@chakra-ui/react';

import UploadButton from 'components/elements/forms/UploadButton';
import DatePicker from 'components/elements/others/DatePicker';

import { PageWrapper } from 'components/elements/wrappers';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons';
import { MainPageTitle } from 'components/elements/texts';
import { hubApiFinancial } from 'services/hubApi/classes/HubApiFinancial';
import { File } from 'pages/Financials/interfaces';
import { errorHandler } from 'utils/errorHandler';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { getMinutes } from 'date-fns';
import { AiOutlineDownload } from 'react-icons/ai';
import { initialFileValue } from './data';

export const FinancialRemunerationUpload: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const initialDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const initialMonth = initialDate.toISOString().slice(0, 4);
  const initialYear = initialDate.toISOString().slice(5, 7);

  const [file, setFile] = useState(initialFileValue);
  const [date, setDate] = useState(`${initialMonth}-${initialYear}`);
  const handlePickFile = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length === 1) {
      setFile(event.target.files?.[0]);
    }
  };

  const downloadStandardFile = () => {
    window.location.replace(
      'https://hub-api.s3.amazonaws.com/ComissionamentoDetalhadoExemplo.xlsx'
    );
  };

  const handleSendFile = async () => {
    setLoading(true);
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const formData = new FormData();
    formData.append('btg', file as File);
    const formattedDate = `${year}-${month}`;

    formData.append('date', formattedDate);

    try {
      const response = await hubApiFinancial.postRemuneration(formData);
      toast.dark(
        `Arquivo enviado com sucesso! e demorará em torno de ${getMinutes(
          response.numLeads * 10
        )} minuto(s) `
      );

      setTimeout(() => setLoading(false), response.numLeads * 10);
    } catch (err) {
      toast.dark('Arquivo enviado com sucesso.');
    }
  };

  const freeAuthLimitInMinutes = 15;

  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    }
  }, [lastAuth]);
  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle
        color="white"
        py="32px"
        px="64px"
        fontSize={['md', 'lg']}
        textAlign="center"
      >
        Upload do Detalhe de Comissionamento
      </MainPageTitle>

      <Flex w="100%" maxWidth="1080px" flexDir="column">
        <Flex mb="12px" flexDir="column" alignItems="center">
          <Flex maxWidth="200px" mb="44px">
            <SecondaryButton
              w="100%"
              fontSize="xs"
              fontWeight="normal"
              onClick={downloadStandardFile}
            >
              <AiOutlineDownload size={20} style={{ marginRight: '14px' }} />
              Baixar o padrão do Excel
            </SecondaryButton>
          </Flex>

          <Box maxWidth="200px" mb="44px">
            <UploadButton
              label={file.name}
              accept="xlsx"
              onChange={handlePickFile}
            />
          </Box>

          <Box ml="4px" maxWidth="200px" mb="44px">
            <DatePicker
              label="Pagamento referente à"
              handlePickDate={(e: ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
              value={date}
              fullColum
              type="month"
            />
          </Box>

          <Box maxWidth="150px">
            <PrimaryButton
              py="17px"
              onClick={handleSendFile}
              isLoading={loading}
            >
              Enviar Arquivo
            </PrimaryButton>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};
