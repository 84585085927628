import styled from 'styled-components';

const CardToLeadsContent = styled.div`
  .lead-container {
    margin: 0;
    background: var(--gray-color-600);
    border-radius: 5px;
    padding: 8px 12px;
    color: var(--white-color-100);
    cursor: pointer;

    .lead-container-title {
      color: var(--white-color-100);
      font-size: var(--normal-text);
      margin-bottom: 4px;
    }

    .lead-infos {
      display: flex;
      flex-direction: column;
    }

    .lead-container-info,
    .lead-container-value {
      font-size: var(--smaller-text);
      color: var(--white-color-300);
    }
  }
`;
export default CardToLeadsContent;
