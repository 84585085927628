import React from 'react';

import { KanbanColumnBase } from 'components/modules/kanbanBases';
import { KanbanColumnProps } from 'shared/interfaces';
import { Task } from '../Task';

interface ComponentProps {
  columnId: string;
  column: KanbanColumnProps;
  setColumns: React.Dispatch<React.SetStateAction<KanbanColumnProps[]>>;
}

const Column: React.FC<ComponentProps> = ({ column, columnId, setColumns }) => {
  return (
    <KanbanColumnBase column={column} columnId={columnId}>
      {column.tasks.map((task, index) => (
        <Task
          key={task.id}
          index={index}
          task={task}
          setColumns={setColumns}
          column={column as any}
        />
      ))}
    </KanbanColumnBase>
  );
};

export default Column;
