import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BackPageButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/elements/buttons/';

import { hubApiProducts } from 'services/hubApi/classes/HubApiProducts';
import { DefaultCardWrapper, PageWrapper } from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { Flex, Text } from '@chakra-ui/react';
import { checkIfMeetingIsNear as getDifferenceInMinutes } from 'utils/formatDate';
import history from 'services/history';
import { Item } from './components/item';
import { NoticeProps } from './interfaces';

const Notices: React.FC = () => {
  const history = useHistory();
  const [notices, setNotices] = useState<NoticeProps[]>([]);
  const [handleNotice, setHandleNotice] = useState({});

  const handleModalUpdate = (notice: NoticeProps) => {
    setHandleNotice(notice);
  };

  const handleClickCreatenoticeButton = useCallback(() => {
    history.push('/notices/create');
  }, [history]);

  // const handleClickEditnoticeButton = useCallback(
  //     (id) => {
  //         history.push(`/notice/${id}`);
  //     },
  //     [history]
  // );

  const handleDeleteNotice = (id: string) => {
    hubApiProducts
      .deleteNotice(id)
      .then(response => {
        setNotices(notices.filter(notice => notice.id !== id));
        toast.dark('Notícia Deleteada com sucesso.');
      })
      .catch(err => {
        toast.dark('Ocorreu um erro ao deletar a Notícia.');
      });
  };

  const freeAuthLimitInMinutes = 15;
  const lastAuth = localStorage.getItem('lastAuth');
  let elapsedMinutesSinceLastAuth = 0;

  if (lastAuth) {
    elapsedMinutesSinceLastAuth =
      getDifferenceInMinutes(new Date(+lastAuth).toISOString()) * -1;
  }

  useEffect(() => {
    if (!lastAuth || elapsedMinutesSinceLastAuth >= freeAuthLimitInMinutes) {
      history.push('/eletronicsignature');
    } else {
      hubApiProducts
        .getNotices()
        .then(response => {
          setNotices(response);
        })
        .catch(err => {
          toast.dark(
            'Ocorreu um erro ao buscar todas notícias, por favor tente novamente.'
          );
        });
    }
  }, [lastAuth, handleNotice]);

  return (
    <PageWrapper
      alignItems="center"
      justifyContent="flex-start"
      position="relative"
      flexDir={{ base: 'column', md: 'column' }}
      gridGap={{ base: '32px', md: '48px' }}
    >
      <BackPageButton />
      <Flex
        width={'80vw'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={'40px'}
      >
        <MainPageTitle textAlign="center">Listar Notícias</MainPageTitle>
        <Flex w="100%" flexDir="column">
          <Flex alignItems="flex-end" justifyContent="flex-end">
            <PrimaryButton onClick={handleClickCreatenoticeButton}>
              Criar uma nova Notícia
            </PrimaryButton>
          </Flex>

          <Flex
            flexDir="column"
            minH="180px"
            p="8px"
            maxH="440px"
            overflowY="auto"
            mt="16px"
          >
            {notices.length !== 0 ? (
              notices.map((notice: NoticeProps, key: number) => (
                <Item
                  notice={notice}
                  key={key}
                  handleModalUpdate={handleModalUpdate}
                  handleDeleteNotice={handleDeleteNotice}
                />
              ))
            ) : (
              <Text fontStyle={'italic'} textAlign="center">
                Nenhuma notícia foi criada
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default Notices;
