import React from 'react';
import { Flex } from '@chakra-ui/react';
import { formatToPhone } from 'brazilian-values';

import {
  ContentDividerText,
  ContentDividerTitle,
} from 'pages/Captation/components/elements/texts';
import { IPhone } from 'pages/Captation/interfaces';

interface ComponentProps {
  index: number;
  phone: Partial<IPhone>;
}

export const LeadPhoneItem: React.FC<ComponentProps> = ({ phone, index }) => {
  const phoneWithoutBrazilCountryCode =
    phone.phone && phone.phone[0] === '5' && phone.phone[1] === '5'
      ? phone.phone.slice(2)
      : phone.phone;
  return (
    <Flex flexDir="column">
      <ContentDividerTitle>{`Telefone ${index + 1}`}</ContentDividerTitle>
      <ContentDividerText>
        {phone.phone && formatToPhone(phoneWithoutBrazilCountryCode as string)}
      </ContentDividerText>
    </Flex>
  );
};
