type ILeadTypeRequest =
  | 'leadspublic'
  | 'publics'
  | 'public'
  | 'leadstable'
  | 'leadsmanual'
  | 'leadspj'
  | 'declined'
  | 'pj'
  | 'leadspersonal'
  | 'tables'
  | 'leadspurchased'
  | 'pipe'
  | 'pool';

export default (leadType: ILeadTypeRequest): string => {
  let leadTypeReturn = '';
  switch (leadType) {
    case 'leadsmanual':
      leadTypeReturn = 'employee';
      break;
    case 'leadspj':
      leadTypeReturn = 'pj';
      break;

    case 'pj':
      leadTypeReturn = 'pj';
      break;
    case 'leadspublic':
      leadTypeReturn = 'publics';
      break;
    case 'publics':
      leadTypeReturn = 'publics';
      break;
    case 'leadstable':
      leadTypeReturn = 'tables';
      break;
    case 'pipe':
      leadTypeReturn = 'pipe';
      break;
    default:
      leadTypeReturn = leadType;
      break;
  }

  return leadTypeReturn;
};
