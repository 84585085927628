import React from 'react';
import { Flex, FlexProps, Spinner } from '@chakra-ui/react';

interface ComponentProps extends FlexProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const LoadingSpinner: React.FC<ComponentProps> = ({
  size = 'lg',
  ...rest
}) => {
  return (
    <Flex
      height="calc(100vh - 120px)"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Spinner size={size} speed="0.6s" color="primary.500" />
    </Flex>
  );
};
