import React, { useState } from 'react';
import { FcApproval, FcHighPriority } from 'react-icons/fc';

import { ModalWrapper } from 'components/elements/wrappers';
import { AssignmentProps } from 'pages/Home/interfaces';
import { ConfirmationModal } from 'components/modules/Pendencies';
import history from 'services/history';
import { AssignmentContentCard } from '../AssignmentContentCard';

interface ComponentProps {
  assignment: AssignmentProps;
  handleRemoveItem: (idTask: string) => void;
  isCalendarContact?: boolean;
}

export const PeriodicMeeting: React.FC<ComponentProps> = ({
  handleRemoveItem,
  assignment,
  isCalendarContact,
}) => {
  const handleToggleModal = () => {
    history.push(`/user/users_clients/new/${assignment.btgAccount}`);
  };

  return (
    <>
      <AssignmentContentCard
        IconElement={FcApproval}
        title="Reunião Periódica"
        secondaryText={`Reunião hoje com o cliente:  `}
        thirdText={`${
          assignment.clientName
            ? assignment.clientName.toUpperCase()
            : assignment.name.toUpperCase()
        }`}
        onClick={() => {
          handleToggleModal();
          handleRemoveItem(assignment.idTask);
        }}
        isCalendarContact={isCalendarContact}
      />
      {/*
      <ModalWrapper isOpen={toggleModal} onClose={handleToggleModal}>
        <ConfirmationModal
          title="Reunião Periódica"
          message={` Você confirma que a reunião com o(a) cliente ${assignment.name.toUpperCase()}, ocorreu com sucesso?`}
          btgAccount={assignment.btgAccount}
          idTask={assignment.idTask}
          handleCloseModal={handleToggleModal}
          handleRemoveItem={handleRemoveItem}
        />
      </ModalWrapper> */}
    </>
  );
};
