import {
  GET_UNREAD_NOTIFICATIONS_REQUEST,
  GET_UNREAD_NOTIFICATIONS_SUCCESS,
  SET_CURRENT_NOTIFICATION,
} from './actionTypes';

export function getUnreadNotificationsRquest() {
  return {
    type: GET_UNREAD_NOTIFICATIONS_REQUEST,
  };
}

export function getUnreadNotificationsSuccess(unreadNotifications) {
  return {
    type: GET_UNREAD_NOTIFICATIONS_SUCCESS,
    payload: { unreadNotifications },
  };
}

export function setCurrentNotification(notificationId) {
  return {
    type: SET_CURRENT_NOTIFICATION,
    payload: notificationId,
  };
}
