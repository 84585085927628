import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { queryClient } from 'App';
import { PrimaryButton } from 'components/elements/buttons';
import { BaseModalFormCard } from 'components/elements/cards';
import { TableData, TableRow } from 'components/elements/table';
import { ModalWrapper } from 'components/elements/wrappers';
import { ScheduleMeeting } from 'pages/Captation/components/modules/modals';
import { useEmployeeMibs } from 'pages/MyMIBs/hooks/useEmployeeMibs';
import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetPoolMIHistoryResponseType } from 'services/hubApi/classes/HubApiCaptation';
import { IReduxState } from 'shared/interfaces';
import { LeadMundoInvestStatusEnum } from 'sharedV2/types/leadMundoInvest';
import { MeetingStatusEnum } from 'sharedV2/types/meeting';
import { formatNameToCaptalize } from 'utils/captalizeNames';
import { transformReaisInMibs } from 'utils/transformReaisInMibs';

type PropsType = {
  history: GetPoolMIHistoryResponseType;
  leadAction: (leadId: string) => void;
};

export const LeadsPoolMundoInvestHistoryRow: React.FC<PropsType> = ({
  history,
  leadAction,
}): JSX.Element => {
  const user = useSelector((state: IReduxState) => state.user.profile);

  const [showScheduleMeetingModal, setShowScheduleMeetingModal] =
    useState<boolean>(false);

  const { data: employeeMibs } = useEmployeeMibs(user.id);

  const leadHaveAMeeting =
    history.lead.meeting &&
    history.lead.meeting.status !== MeetingStatusEnum.DECLINED;

  const leadHaveAMeetingWithYou =
    history.lead.meeting && history.lead.meeting.employeeId === user.id;

  const isRecoverLeadButtonDisable =
    (history.lead.status === LeadMundoInvestStatusEnum.RESERVED &&
      history.lead.employeeId !== user.id) ||
    leadHaveAMeeting ||
    (leadHaveAMeeting && leadHaveAMeetingWithYou);

  const [isOpenMibsAlertModal, setIsOpenMibsAlertModal] =
    useState<boolean>(false);

  function maskPhone(phone: string) {
    const cleanPhone = phone.replace(/\D/g, '');

    if (cleanPhone.length < 10) {
      return 'Número de telefone inválido';
    }

    const ddd = cleanPhone.substring(2, 4);
    const lastDigits = cleanPhone.substring(cleanPhone.length - 4);

    const maskedDigits = cleanPhone
      .substring(2, cleanPhone.length - 4)
      .replace(/\d/g, '*');

    return `(${ddd}) ${maskedDigits}${lastDigits}`;
  }

  const formatMeetingStatus = (status: string) => {
    switch (status) {
      case 'suitability':
        return 'Suitability';
      case 'presentation':
        return 'Carteira';
      case 'callback':
        return 'Retorno';
      case 'CALLBACK':
        return 'Retorno';
      case 'account':
        return 'Alocação';
      case 'allocation':
        return 'Ag. Abertura de conta';
      case 'alocado':
        return 'Alocado';
      default:
        return 'Dispon. Prospeção';
    }
  };

  const statusConfig: Record<string, string> = {
    ok: 'Dispon. Prospeção',
    reserved: 'Reservado',
    finalizado: 'Declínio',
    no_answer: 'Não Atendeu',
    returned: 'Retorno',
    callback: 'Retorno',
  };

  const handleGetUpdatedLeadStatus = (): string => {
    if (leadHaveAMeetingWithYou) {
      return formatMeetingStatus(history.lead?.meeting?.status ?? '');
    }

    if (
      history.lead.status === LeadMundoInvestStatusEnum.RESERVED &&
      history.lead.employeeId === user.id
    ) {
      return statusConfig.ok;
    }

    return statusConfig[history.lead.status];
  };

  const handleTooltipText = (): string => {
    if (leadHaveAMeeting && leadHaveAMeetingWithYou) {
      return 'Esse lead já está no seu pipe de reuniões';
    }

    if (leadHaveAMeeting) {
      return 'Esse lead está no pipe de reuniões de outro colaborador';
    }

    if (history.lead.status === LeadMundoInvestStatusEnum.RESERVED) {
      return 'Botão desativado pois outro colaborador já está vendo esse lead';
    }

    return 'Tooltip text not predicted';
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowScheduleMeetingModal(true);
  };

  return (
    <>
      <TableRow
        minH="30px"
        bg="white"
        _hover={{
          background: '#f2f2f2',
        }}
        transition="ease-in-out 0.2s"
      >
        <TableData maxW="190px" isTruncated>
          <Tooltip
            hasArrow
            label={formatNameToCaptalize(history.lead.name)}
            placement="top"
            bg="background.200"
            cursor="pointer"
          >
            {formatNameToCaptalize(history.lead.name)}
          </Tooltip>
        </TableData>
        <TableData>{maskPhone(history.lead.phone)}</TableData>
        <TableData>{statusConfig[history.status]}</TableData>
        <TableData>{handleGetUpdatedLeadStatus()}</TableData>
        <TableData>{history?.frequency}</TableData>
        <TableData>
          <Flex gap={1} alignItems="center">
            <PrimaryButton
              size="xs"
              disabled={isRecoverLeadButtonDisable}
              onClick={() => {
                if (
                  employeeMibs &&
                  employeeMibs[0].mibs <
                    transformReaisInMibs(history.lead.price || 0) * 0.5
                ) {
                  return toast.dark(
                    'Saldo de MIBs insuficiente para realizar esta ação'
                  );
                }
                setIsOpenMibsAlertModal(true);
              }}
            >
              Recuperar Lead
            </PrimaryButton>
            {isRecoverLeadButtonDisable && (
              <Tooltip
                hasArrow
                label={handleTooltipText()}
                placement="top"
                color="white"
                bg="background.200"
              >
                <Box>
                  <AiOutlineInfoCircle size={14} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        </TableData>
      </TableRow>
      <ModalWrapper
        isOpen={isOpenMibsAlertModal}
        onClose={() => setIsOpenMibsAlertModal(false)}
      >
        <BaseModalFormCard
          isModal
          handleFormSubmit={handleFormSubmit}
          isLoading={false}
          secondaryButtonEventFunction={() => setIsOpenMibsAlertModal(false)}
          title="Essa ação implica custo de MIBs"
          primaryButtonText="Sim"
          secondaryButtonText="Não"
        >
          <Flex>
            <Text>Deseja continuar?</Text>
          </Flex>
        </BaseModalFormCard>
      </ModalWrapper>
      <ModalWrapper
        isOpen={showScheduleMeetingModal}
        onClose={() => setShowScheduleMeetingModal(false)}
        closeOnOverlayClick={false}
      >
        <ScheduleMeeting
          updateTotalNumOfLeadsInPipe={() => {}}
          setResponse={() => {}}
          leadType="leadspublic"
          postTasks={'' as any}
          handleShowModal={() => setShowScheduleMeetingModal(false)}
          id={history.lead.id}
          handleSaveLeadInfos={(() => {}) as any}
          handleNextLeadFromPool={leadAction}
          afterOperationCompleteCallback={async () => {
            setIsOpenMibsAlertModal(false);
            await queryClient.invalidateQueries([
              'leadsPoolMundoInvestHistory',
            ]);
          }}
        />
      </ModalWrapper>
    </>
  );
};
