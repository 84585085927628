import { toast } from 'react-toastify';
import hubApi from '../../services/hubApi';

export async function handleOnBlurClient(
  clientCode,
  setSelectedClient,
  setFormData,
  formData
) {
  await hubApi
    .searchForClientCode(clientCode)
    .then(response => {
      setSelectedClient(response);
      setFormData({ ...formData, clientId: response.id });
    })
    .catch(() =>
      toast.dark(
        'Não foi possível encontrar o cliente. Verifique o código informado.'
      )
    );
}
