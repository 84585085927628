/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  ModalWrapper,
  PageWrapper,
  TransferOrdersCardsWrapper,
} from 'components/elements/wrappers';
import { MainPageTitle } from 'components/elements/texts';
import { TransferOrderCard } from 'components/elements/cards';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { BackPageButton } from 'components/elements/buttons';
import HubApi from 'services/hubApi';

import EmployeeProfile from 'pages/RH/components/elements/EmployeeProfile';
import { LoadingSpinner } from 'components/elements/others';
import { useQuery } from 'react-query';

type EmployeeType = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

const ContractBoard: React.FC = () => {
  const [contractSelected, setContractSelected] = useState<EmployeeType>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: contracts, isLoading } = useQuery<EmployeeType[]>({
    queryKey: ['rhContracts'],
    queryFn: async () => {
      const rhOrders = await HubApi.filterEmployees('status', 'pending');

      return rhOrders;
    },
  });

  return (
    <PageWrapper paddingTop="8" flexDir="column" alignItems="center">
      <BackPageButton />

      <MainPageTitle color="rgba(70, 70, 70, 1)" textAlign="center">
        Contratações - R.H
      </MainPageTitle>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TransferOrdersCardsWrapper>
          {contracts &&
            contracts.map((item, index: number) => (
              <TransferOrderCard
                key={index}
                mainText={item.name ?? 'Nome não informado'}
                secondaryText="Pendente"
                onClick={() => {
                  setContractSelected(item);
                  onOpen();
                }}
              />
            ))}
        </TransferOrdersCardsWrapper>
      )}
      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        <Flex alignItems="center" h="100vh" justifyContent="center">
          <EmployeeProfile
            employeeId={(contractSelected && contractSelected.id) || ''}
            handleCloseFatherModal={onClose}
          />
        </Flex>
      </ModalWrapper>
    </PageWrapper>
  );
};

export default ContractBoard;
