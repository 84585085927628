import { Text } from '@chakra-ui/react';
import { BaseModalFormCard } from 'components/elements/cards';
import { useUpdateIPMeeting } from 'pages/IPLife/hooks/useUpdateIPMeeting';
import { IPMeetingType } from 'services/hubApi';

type PropsType = {
  meeting: IPMeetingType;
  annualizedPremium: string | undefined;
  setIsOpenRemoveOrderFromCRMModal: (value: boolean) => void;
  setIsOpenMeetingBoletedModal: (value: boolean) => void;
  handleToggleOpenMeetingDetailsModal: (value: boolean) => void;
};

export const RemoveOrderFromCRM: React.FC<PropsType> = ({
  meeting,
  annualizedPremium,
  setIsOpenMeetingBoletedModal,
  setIsOpenRemoveOrderFromCRMModal,
  handleToggleOpenMeetingDetailsModal,
}): JSX.Element => {
  const { mutateAsync: updateMeeting, isLoading } = useUpdateIPMeeting();

  const formSubmit = async (meetingStatus: string) => {
    await updateMeeting({
      meetingId: meeting.id,
      annualizedPremium: Number(annualizedPremium?.replaceAll(',', '.')),
      status: meetingStatus,
    });

    handleToggleOpenMeetingDetailsModal(false);
    setIsOpenRemoveOrderFromCRMModal(false);
    setIsOpenMeetingBoletedModal(false);
  };

  return (
    <BaseModalFormCard
      isModal
      title="Retirar ordem do CRM?"
      handleFormSubmit={() => formSubmit('boletedNoShow')}
      isLoading={isLoading}
      primaryButtonText="Sim"
      secondaryButtonText="Não"
      secondaryButtonEventFunction={() => formSubmit('boleted')}
      flexDir="column"
      width="100%"
      maxW="600px"
    >
      <Text>Deseja retirar ordem do CRM?</Text>
    </BaseModalFormCard>
  );
};
