import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { differenceInMonths } from 'date-fns';
import { TableData, TableRow } from 'components/elements/table';
import { IGestaoSH } from 'pages/GenerationalVariables/interfaces';
import { formatNameToCaptalize } from 'utils/captalizeNames';

interface TableRowShProps {
  tableContent: IGestaoSH;
  initDate: string;
}

export const TableRowSH: React.FC<TableRowShProps> = ({
  tableContent,
  initDate,
}) => {
  const history = useHistory();

  const convForRR =
    tableContent.meetingsMarked === 0
      ? 0
      : // eslint-disable-next-line no-nested-ternary
        (Number(tableContent.ConversionForRealizedMeetings) === 0
          ? 1
          : Number(tableContent.ConversionForRealizedMeetings) /
              Number(tableContent.meetingsRouter) ===
            0
          ? 1
          : Number(tableContent.meetingsRouter)) * 100;

  const tasks = tableContent.tasks - tableContent.tasksPending;

  const tasksExec = (tasks / tableContent.tasks) * 100;

  const porcentInMeetingsRoutedOnMeetingsMarked =
    (Number(tableContent.meetingsRouter) /
      Number(tableContent.meetingsMarked)) *
    100;

  const timeOfSH = differenceInMonths(
    new Date(),
    new Date(tableContent.created_at)
  );

  const RNA =
    tableContent.meetingsMarked === 0
      ? 0
      : (Number(tableContent.returned === 0 ? 1 : tableContent.returned) /
          Number(tableContent.meetingsRouter) ===
        0
          ? 1
          : Number(tableContent.meetingsRouter)) * 100;

  const handleClickedRow = (shId: string) => {
    history.push(`/generatinal_variables/shNumbers/${shId}/${initDate}`);
  };

  const porcentMeetingsMarked =
    (Number(tableContent.meetingsMarked) / Number(tableContent.leads)) * 100;

  const porcentMeetingsRealizedOnMeetingsRouter =
    (Number(tableContent.meetingsRealized) /
      Number(tableContent.meetingsRouter)) *
    100;

  return (
    <TableRow
      onClick={() => handleClickedRow(tableContent.id)}
      cursor="pointer"
      _hover={{ background: 'background.300' }}
      transition="600ms"
    >
      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        <Box
          whiteSpace="nowrap"
          w="150px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {formatNameToCaptalize(tableContent.name)}
        </Box>
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {timeOfSH < 1 ? `${timeOfSH} Mês` : `${timeOfSH} Meses`}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {Number(tableContent.leads) || 0}
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {Number(tableContent.meetingsMarked) || 0} (
        {porcentMeetingsMarked.toFixed(2) || 0}%)
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {Number(tableContent.meetingsRouter) || 0} (
        {porcentInMeetingsRoutedOnMeetingsMarked.toFixed(2) || 0}%)
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {Number(tableContent.meetingsRealized) || 0} (
        {porcentMeetingsRealizedOnMeetingsRouter.toFixed(2) || 0}%)
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {tableContent.ConversionForRealizedMeetings} (
        {Number(convForRR.toFixed(2)) || 0}%)
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {tableContent.returned} ({Number(RNA.toFixed(2)) || 0}%)
      </TableData>

      <TableData color="white" fontWeight="bold" fontSize="small" p="3">
        {tasks} ({Number(tasksExec.toFixed(2)) || 0}%)
      </TableData>
    </TableRow>
  );
};
