export const thingsToCreate = [
  {
    label: 'Lead',
    value: 'leads',
  },
];

export const locationToCreate: Record<
  string,
  Array<{ label: string; value: string }>
> = {
  leads: [
    {
      label: 'Pool SH',
      value: 'SH_POOL',
    },
    {
      label: 'Pool AAI',
      value: 'AAI_POOL',
    },
    {
      label: 'Painel de Reuniões',
      value: 'MEETINGS_PIPE',
    },
  ],
};
