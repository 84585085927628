import React, { ChangeEvent } from 'react';
import { Flex, Text, Input } from '@chakra-ui/react';

interface ComponentProps {
  label: string;
  accept?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton: React.FC<ComponentProps> = ({
  label,
  accept,
  onChange,
  ...rest
}) => {
  return (
    <Flex
      direction="row"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      pos="relative"
      borderRadius="5px"
      w="100%"
      maxH="220px"
      border="1px solid"
      borderColor="background.100"
      color="rgba(70, 70, 70, 1)"
      mt="15px"
      p="8px"
      _hover={{ borderColor: 'primary.500' }}
    >
      <Text
        m="0"
        color="rgba(70, 70, 70, 1)"
        fontSize="xs"
        style={{ cursor: 'pointer' }}
        textAlign="center"
        isTruncated
      >
        {label}
      </Text>
      <Input
        h="100%"
        w="100%"
        pos="absolute"
        top="0"
        right="0"
        opacity="0"
        cursor="pointer"
        type="file"
        accept={accept}
        onChange={onChange}
        {...rest}
      />
    </Flex>
  );
};

export default UploadButton;
