import { HubApi } from '../index';

class HubApiGuru extends HubApi {
  async createOrder(data: any) {
    const response = await this.axios.post('/orders/guru', data);
    return response.data;
  }

  async listUserOrders() {
    const response = await this.axios.get('/orders/guru');
    return response.data;
  }

  async getSingleOrder(orderId: string) {
    const response = await this.axios.get(`/orders/guru/${orderId}`);
    return response.data;
  }

  async updateOrder(orderId: string, data: any) {
    const response = await this.axios.put(`/orders/guru/${orderId}`, data);
    return response.data;
  }

  async listNotTrasferredOrders() {
    const response = await this.axios.get('/orders/guru/transferred/no');
    return response.data;
  }

  async transferOrderToEmployee(data: any) {
    const response = await this.axios.post(`/orders/guru/transfer`, data);
    return response.data;
  }

  async listUserWithCRMPermission() {
    const response = await this.axios.get('/employees/by/permission/guru');
    return response.data;
  }
}

export const hubApiGuru = new HubApiGuru();
