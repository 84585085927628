import { forwardRef } from 'react';
import InputCurrencyField, {
  CurrencyInputProps,
} from 'react-currency-input-field';

export const InputCurrency = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (props, ref) => {
    return (
      <InputCurrencyField
        className="currencyInput"
        groupSeparator="."
        decimalSeparator=","
        allowNegativeValue={false}
        prefix="R$"
        decimalScale={2}
        ref={ref}
        {...props}
      />
    );
  }
);
